import React, {useState} from 'react';

import classes from './LogicBuilder.module.css';

const FunctionBlockContent = (props) => {
	
	return (
		<div className={classes.function_block_content}>

			<p style={{marginLeft: '0.5em', marginTop: '2em'}}>Email</p>
			<p style={{marginLeft: '0.5em'}}>Checkout</p>

			<button className={classes.close_dropdown_btn} onClick={props.onClose}>
				<span>
					<svg xmlns="http://www.w3.org/2000/svg" fill="red" className="bi bi-x" viewBox="0 0 16 16" style={{width: '2vw', height: '2vh'}}>
					  	<path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
					</svg>
				</span>
			</button>

		</div>
	);
};

export default FunctionBlockContent;