const mainBody = document.createElement('div');

// for bootstrap icons in social media
const cdn = document.createElement('link');
cdn.setAttribute('rel', 'stylesheet');
cdn.setAttribute('href', 'https://cdn.jsdelivr.net/npm/bootstrap-icons@1.9.1/font/bootstrap-icons.css');
mainBody.appendChild(cdn);

const header = document.createElement('div');
header.setAttribute('class', 'header');
mainBody.appendChild(header);

header.innerHTML = `
	<div>
        <img src="/templates/images/redSlingLogo.png" />
      </div>
      <div class="header-right-section1">
        <p>Admin</p>
        <img src="/templates/images/Vector.png" />
    </div>
`;

const whiteWaveOverlay1 = document.createElement('div');
whiteWaveOverlay1.setAttribute('class', 'whiteWaveOverlay1');
mainBody.appendChild(whiteWaveOverlay1);

whiteWaveOverlay1.innerHTML = `
	<img src="/templates/images/Ellipse 6.png" />
    <img class="whiteWave2" src="/templates/images/white waves 2.png" />
`;

const checkoutDiv = document.createElement('div');
checkoutDiv.setAttribute('class', 'checkoutDiv');
mainBody.appendChild(checkoutDiv);

checkoutDiv.innerHTML = `
	<img class="checkout-d-bb" src="/templates/images/darkBubble.png" />

      <!-- checkout bread -->
    <div class="checkout-bread">
        <div>
          <p class="circle1"></p>
          <p class="h-line"></p>
          <p class="circle2"></p>
          <p class="h-line"></p>
          <p class="circle2"></p>
        </div>
        <div>
          <p>Cart</p>
          <p>Details</p>
          <p>Payment</p>
        </div>
    </div>

      <!-- main content -->

    <div class="checkout-content">
        <div>
          <img src="/templates/images/unknownImg.png" />
          <div class="checkout-product">
            <p>Product 1</p>
            <div class="checkout-button">
              <p>-</p>
              <p>1</p>
              <p>+</p>
            </div>
           
          </div>
        </div>
        <p>₹1,450</p>       
    </div>

    <div class="checkout-content">
        <div>
          <img src="/templates/images/unknownImg.png" />
          <div class="checkout-product">
            <p>Product 2</p>

            <div class="checkout-button">
              <p>-</p>
              <p>1</p>
              <p>+</p>
            </div>           
	        </div>
	    </div>

    	<p>₹849</p>       
    </div>

    <div class="checkout-content">
        <div>
          <img src="/templates/images/unknownImg.png" />
          <div class="checkout-product">
            <p>Product 3</p>
            <div class="checkout-button">
              <p>-</p>
              <p>1</p>
              <p>+</p>
            </div>
          </div>
        </div>
        <p>₹1,268</p>        
    </div>
      
    <div class="checkout-coupon">
        <p>Have a coupon code? <a href="#">Apply Here</a></p>
    </div>
    <div class="priceDesc">
        <p>Tax</p>
        <p>₹50</p>
    </div>
    <div class="priceDesc">
        <p>Shipping</p>
        <p>₹0</p>
    </div>
    <div class="priceDesc">
        <p>Total Amount</p>
        <p>₹3,617</p>
    </div>

    <div class="checkoutButton">
        <button>Proceed To Checkout</button>
    </div>

    <style>
    	@media (max-width: 768px) {
    		.header {
	          display: flex;
	          justify-content: space-between;
	          align-items: center;
	          position: relative;
	          padding: 2vh 2.5vw;
	        }
	        .header-right-section1 {
	          display: flex;
	          justify-content: space-evenly;
	          align-items: center;
	          width: initial;
	          z-index: 10;
	        }
	        .header-right-section1 img {
	          width: 4vw;
	          height: 3vh;
	          margin-left: 5%;
	        }
	        .header-right-section1 p {
	          font-family: Mont-Regular;
	          font-size: 1rem;
	          margin-right: 5%;
	        }
	        .whiteWaveOverlay1 {
	          position: absolute;
	          top: 0;
	          right: 0;
	        }
	        .whiteWaveOverlay1 img:first-child {
	          width: 50vw;
	          position: absolute;
	          top: 0;
	          right: 0;
	        }
	        .whiteWave2 {
	          width: 90vw;
	          position: absolute;
	          top: 0;
	          right: 0;
	        }
	        .checkoutDiv {
			    padding-left: 4vw;
			    padding-right: 4vw;
			}
			.checkout-d-bb {
			    display: none;
			}
			.checkout-bread {
			    margin-top: 4vh;
			}
			.circle1 {
			    width: 4vw;
			    height: 3vh;
			}
			.circle2 {
			    width: 4vw;
			    height: 2.5vh;
			    border: 4px solid rgba(237, 28, 36, 0.4);
			}
			.checkout-content img {
			    height: 8vh;
			}
			.checkout-product {
			    display: flex;
			    flex-direction: column;
			    height: inherit;
			    justify-content: space-between;
			    align-items: center;
			    font-family: Mont-Regular;
			    padding: 1vh 0 2vh 0;
			}
			.checkout-button {
			    display: flex;
			    align-items: center;
			    margin-top: 8%;
			}
			.checkout-button p:first-child {
			    font-size: 1.5rem;
			    border: none;
			}
			.checkout-button p:last-child {
			    font-size: 1.5rem;
			    border: none;
			}
			.checkout-button p {
			    border: 1px solid #ed1c24;
			    border-radius: 8px;
			    font-family: Mont-Regular;
			    padding: 0.8vh 3vw;
			    color: #657581;
			}
			.checkoutButton button {
			    padding: 2vh 2vw;
			    font-family: Mont-Regular;
			    font-size: 1rem;
			    background-color: red;
			    color: white;
			    border: none;
			    border-radius: 20px;
			    width: 100%;
			}
    	}
    </style>
`;

const templateCheckoutPage = mainBody.outerHTML;
export { templateCheckoutPage };