import React, { useEffect, useState } from 'react'
import { getAllFunctionsOfProject } from '../../Redux/Actions/functionActions'
import { connect } from 'react-redux'

function Test(props) {
    const [ result, setResult ] = useState('');

    useEffect(() => {
        const project_id = localStorage.getItem("projectId")
        if(project_id) {
            props.getAllFunctionsOfProject(project_id).then(res => {
                console.log(props.functions)
                const fn = props.functions.find(func => func.name === 'calculateSalary');
                if (fn) {
                    // Convert the function string to a function object using eval()
                    const fnObject = eval(`(${fn.code})`);
              
                    // Call the function with some test data
                    const netPay = fnObject(40, 25);
              
                    setResult(`Net pay: $${netPay.toFixed(2)}`);
                  } else {
                    setResult('Function not found');
                  }
            })
        }
        
    }, [])
  return (
    <div>
            <p>{result}</p>
        
    </div>
  )
}

const mapStateToProps = state => {
    console.log(state)
    return {
        createFunc: state.createNewFunc,
        selected_project: state.selectedProject,
        functions: state.functions.functions
    }
}

export default connect(mapStateToProps, { getAllFunctionsOfProject })(Test)

