import React, { Component } from "react";
import ErrorScreen from "./Screens/ErrorScreen";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error("Error caught by boundary:", error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return <ErrorScreen />;
    } else {
      // default behavior
      return this.props.children;
    }
  }
}

export default ErrorBoundary;
