import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getBuildByProjectId, generateBuildByProjectId, downloadBuild } from "../Redux/Actions/buildActions";
import { getAllProject } from "../Redux/Actions/projectActions";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "./Loader";
import { Button, Grid, Typography, Modal, TextField } from "@mui/material";
import darkBubble from "./component_assets/images/darkBubble.png";
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import lightBubble from "./component_assets/images/lightBubble.png";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import "./style.css";
// import errorSymbol from './component_assets/images/details_close.png'
import errorSymbol from "./component_assets/images/errorSymbol.svg";
// import btnGrp from './component_assets/images/details_close.png'
import btnGrp from "./component_assets/images/btnGrpRed.svg";
// import errorWave from './component_assets/images/details_close.png'
import errorWave from "./component_assets/images/errorWave.svg";
import DeleteIcon from "@mui/icons-material/Delete";
import RateReviewIcon from "@mui/icons-material/RateReview";
import { ToastContainer } from "react-toastify";
import {
  updatePage,
  copyPageAction,
  deletePage,
} from "../Redux/Actions/pageActions";
import CloseIcon from "@mui/icons-material/Close";
import HomeIcon from "@mui/icons-material/Home";
import WysiwygIcon from "@mui/icons-material/Wysiwyg";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";

function DownloadProject() {
  let dispatch = useDispatch();
  let navigate = useNavigate();

  const { projectId, secretToken } = useParams();
  const { buildByProjectData, loading, error, emptyPage } = useSelector(
    (state) => state.buildByProject_id
  );
  const { generateBuildByProjectData, genLoading, genError, genEmptyPage, genSuccess } = useSelector(
    (state) => state.generateBuildByProject_id
  );
  const { allProjectData } = useSelector((state) => state.allProjectData);
  const { delPage } = useSelector((state) => state.delPage);
  const { success } = useSelector((state) => state.updatedPage);
  const { copyPageData } = useSelector((state) => state.copyPage);

  const [modalData, setModalData] = useState({});
  const [open, setOpen] = useState(false);
  const [name, setName] = useState("");
  const [metaDesc, setMetaDesc] = useState("");
  const [metaKey, setMetaKey] = useState("");
  const [metaTag, setMetaTag] = useState("");
  const [seo, setSeo] = useState("");
  const [openCopyModal, setOpenCopyModal] = useState(false);
  const [copyPageName, setCopyPageName] = useState("");
  const [copyPageId, setCopyPageId] = useState();
  const [isProjectAdmin, setIsProjectAdmin] = useState(false);
  const userInfo = localStorage.getItem("userInfo")
    ? JSON.parse(localStorage.getItem("userInfo"))
    : null;

  useEffect(() => {
    dispatch(getBuildByProjectId(projectId));
    dispatch(getAllProject());
  }, [success, delPage, copyPageData, genSuccess]);

  const projectData = allProjectData?.find((x) => x._id === projectId);

  const downloadBuildHandler = (userInfo, id) => {
    dispatch(downloadBuild(projectId, secretToken, id, userInfo));
  };

  useEffect(() => {
    const userInfoFromStorage = JSON.parse(localStorage.getItem("userInfo"));
    if (projectData && userInfoFromStorage) {
      if (projectData.admin === userInfoFromStorage.name) {
        setIsProjectAdmin(true);
      }
    }
  }, [projectData]);

  const columns = [
    { field: "id", headerName: "ID", width: 90 },
    {
      field: "build_id",
      headerName: "Build ID",
      width: 300,
      editable: true,
    },
    {
      field: "build_status",
      headerName: "Build Status",
      width: 300,
      editable: true,
    },
    {
      field: "build_type",
      headerName: "Build Type",
      width: 300,
      editable: true,
    },
    {
      field: "createdAt",
      headerName: "Created At",
      width: 300,
      editable: true,
    },
    {
      field: "editPage",
      headerName: "Download",
      width: 300,
      renderCell: (params) => {
        return (
          <Typography
            onClick={(e) => downloadBuildHandler(userInfo, params.row.build_id, e)}
            sx={{
              color: "#9333ea",
              "&:hover": { color: "#c084fc", scale: "125%" },
            }}
          >
            <DownloadForOfflineIcon fontSize="medium" />
          </Typography>
        );
      },
    },
  ];
  
  let rows;
  if(buildByProjectData?.length > 0){
    rows = buildByProjectData?.map((x) => {
          return {
            id: x._id,
            project: x.project,
            build_id: x.build_id,
            build_status: x.build_status,
            build_type: x.build_type,
            createdAt: x.createdAt,
          };
        });
  }else{
    rows = {};
  }
  
  const genBuild = (projectId, secretToken, userInfo) => {
    dispatch(generateBuildByProjectId(projectId, secretToken, userInfo));
  }

  const handleRowEventHandler = (params) => {
    console.log("params", params);
    setModalData({
      project: params.row.project,
      build_id: params.row.build_id,
      build_status: params.row.build_status,
      build_type: params.row.build_type,
      createdAt: params.row.createdAt,
    });
    setOpen(true);
  };

  return (
    <div className="">
      {loading ? (
        <Loader />
      ) : emptyPage ? (
        <Grid
          sx={{
            height: "87.5vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            position: "relative",
            overflow: "hidden",
          }}
        >
          <Grid
            width={"80vw"}
            height={"80%"}
            sx={{
              padding: "2vh 0 2vh 3vw",
              borderRadius: "8px",
              backdropFilter: "blur(25px)",
              boxShadow: 20,
              zIndex: 20,
            }}
          >
            <Grid
              xs={12}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography
                sx={{ fontFamily: "Mont-SemiBold", fontSize: "1.5rem" }}
              >
                Pages Created
              </Typography>
              <Grid
                xs={10}
                sx={{ backgroundColor: "red", height: "0.6vh" }}
              ></Grid>
            </Grid>
            <Grid sx={{ display: "flex", justifyContent: "center", mt: 6 }}>
              <img src={errorSymbol} style={{ height: "30vh" }} />
            </Grid>
            <Typography
              sx={{
                textAlign: "center",
                mt: 4,
                fontSize: "1.2rem",
                fontFamily: "Mont-SemiBold",
              }}
            >
              Sorry! No pages have been created yet.{" "}
            </Typography>
            <Grid sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
              <Button
                onClick={ ()=> genBuild(projectId, secretToken, userInfo)}
                variant="contained"
                sx={{
                  backgroundColor: "white",
                  color: "black",
                  fontFamily: "Mont-Regular",
                }}
              >
                <span style={{ marginRight: "1vw" }}>Create New Build</span>
                <img src={btnGrp} style={{ height: "4vh" }} />
              </Button>
            </Grid>
          </Grid>
          <Grid sx={{ position: "absolute", bottom: "-80vh", right: "-20vw" }}>
            <img src={lightBubble} style={{ height: "160vh" }} />
          </Grid>
          <Grid sx={{ position: "absolute", top: "-12vh", right: "0" }}>
            <img src={errorWave} style={{ height: "180vh" }} />
          </Grid>
        </Grid>
      ) : (
        <Grid
          sx={{ position: "relative", overflow: "hidden", height: "100vh" }}
        >
          <Grid
            sx={{
              position: "absolute",
              top: "-12vh",
              left: "-30vw",
              zIndex: "-10",
            }}
          >
            <img style={{ width: "55vw", height: "90%" }} src={darkBubble} />
          </Grid>

          <Grid sx={{ position: "absolute", bottom: "-60%", right: "-15%" }}>
            <img style={{ width: "55vw", height: "90%" }} src={lightBubble} />
          </Grid>

          <Grid
            sx={{
              display: "flex",
              justifyContent: "center",
              paddingTop: "10vh",
            }}
          >
            <Box
              sx={{
                minHeight: "58vh",
                width: "90%",
                backdropFilter: "blur(25)",
                backgroundColor: "",
              }}
            >
              <Grid
                xs={12}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
                container
              >
                <Typography
                  sx={{ fontFamily: "Mont-SemiBold", fontSize: "1.8rem" }}
                >
                  Project Name : {projectData?.project_name}
                </Typography>
                <Grid
                  xs={8}
                  sx={{ height: "1.5vh", backgroundColor: "red" }}
                  item
                ></Grid>
              </Grid>
              <Grid sx={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                  onClick={() => genBuild(projectId, secretToken, userInfo)}
                  variant="contained"
                  sx={{
                    fontFamily: "Mont-Regular",
                    backgroundColor: "red",
                    marginBottom: "3vh",
                    "&:hover": { color: "red", backgroundColor: "white" },
                    transitionDuration: "0.5s",
                  }}
                >
                  Create New Build
                </Button>
              </Grid>
              <DataGrid
                rows={rows}
                columns={columns}
                pageSize={5}
                onRowClick={(params, event) =>
                  handleRowEventHandler(params, event)
                }
                error={error}
                rowsPerPageOptions={[5]}
                checkboxSelection
                disableSelectionOnClick
                experimentalFeatures={{ newEditingApi: true }}
              />
            </Box>
          </Grid>
        </Grid>
      )}
      <ToastContainer autoClose={2000} />
    </div>
  );
}

export default DownloadProject;
