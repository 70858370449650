import React, { useEffect, useState } from 'react'
import './style.css'
import darkBubble from './component_assets/images/darkBubble.png'
// import darkBubble from './component_assets/images/details_close.png'
// import lightBubble from './component_assets/images/details_close.png'
import lightBubble from './component_assets/images/lightBubble.png'
import addProjectVector from './component_assets/images/details_close.png'
// import addProjectVector from './component_assets/images/addProjectVector.png'
import addProjectVector1 from './component_assets/images/details_close.png'
// import addProjectVector1 from './component_assets/images/addProjectVector1.png'
import addProjectBin from './component_assets/images/details_close.png'
// import addProjectBin from './component_assets/images/addProjectBin.png'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { Button, Paper, Typography } from '@mui/material'
import { getAllProject } from '../Redux/Actions/projectActions'
import { Box } from '@mui/system'
import QueueIcon from '@mui/icons-material/Queue';

import classes from './ScreensStylings/DashboardScreen.module.css';


function DashboardScreen() {

    let navigate = useNavigate()
    let dispatch = useDispatch()

    const { userInfo } = useSelector(state => state.userLogin)
    const { allProjectData } = useSelector(state => state.allProjectData)
    const is_mobile = useSelector(state => state.is_mobile)

    console.log("is_mobile", is_mobile);

    useEffect(() => {
        if (!allProjectData) {
            dispatch(getAllProject())
        }
    }, [])


    const createProjectHandler = () => {
        navigate('/createProject')
    }

    const viewProjectHandler = () => {
        navigate('/projectList')
    }




    return (
        <div style={{ marginTop: '2vh' }}>
            <div className="hero">
                <img className="addProject-d-bb" src={darkBubble} />
                <img className="addProject-l-bb" src={lightBubble} />
                <div className="addNewProjectWrapper">
                    <p className="addProjectUserText">Welcome, {userInfo?.name}!</p>
                    <div className="addProjectInnerDiv">
                        <div className="">

                            {/* <div className="addProjectFlowWrapper addProjectBread">
                                <div onClick={createProjectHandler} className="flowWrapperInnerDiv">
                                    <p className="flowWrapperInnerText1">Create New Project</p>
                                    <p id="circle1" className="flowWrapperCircle1"></p>
                                </div>
                                <div className="flowWrapperInnerDiv">
                                    <p className="flowWrapperInnerText">Create New Page</p>
                                    <p id="circle2" className="flowWrapperCircle"></p>
                                </div>
                                <div className="flowWrapperInnerDiv">
                                    <p style={{ textAlign: 'center' }} className="flowWrapperInnerText newflowWrapperInnerText">
                                        Build the <br />
                                        Page Functionality
                                    </p>
                                    <p style={{ textAlign: 'center' }} className="flowWrapperInnerText oldflowWrapperInnerText">
                                        Build the
                                        Page Functionality
                                    </p>
                                    <p id="circle3" className="flowWrapperCircle"></p>
                                </div>
                                <div className="flowWrapperInnerDiv">
                                    <p className="flowWrapperInnerText">Download</p>
                                    <p id="circle4" className="flowWrapperCircle"></p>
                                </div>
                                <div className="flowWrapperInnerDiv">
                                    <p className="flowWrapperInnerText">Publish to your Domain</p>
                                    <p id="circle5" className="flowWrapperCircle"></p>
                                </div>
                                <div id="emptyBread" className="BreadFlowEmptyDiv"></div>
                            </div>

                            <p className="addProjectMainText">Steps to set up your shop</p> */}

                            <Box sx={{ display: "flex", justifyContent: "center", alignItems: 'center', mt: 9, mb: 10, }}>
                                <Paper elevation={4} sx={{ padding: '0.5vh 2.5vw', border: "1px solid red" }}>
                                    <Typography sx={{ textAlign: 'center', fontSize: '4rem', fontFamily: "Mont-SemiBold", color: 'red' }}>What do you want to build?</Typography>
                                </Paper>
                            </Box>

                            <Box sx={{ display: 'flex', justifyContent: "center", alignItems: 'center', mb: 10 }}>
                                <Button onClick={viewProjectHandler} variant='contained' sx={{ mr: 4, display: 'flex', justifyContent: 'center', alignItems: 'center', textTransform: 'none', backdropFilter: 'blur(25)', backgroundColor: 'red', color: 'white', '&:hover': { backgroundColor: 'white', color: 'red' }, transitionDuration: '0.6s', padding: '1.5vh 2vw' }}>
                                    <Typography sx={{ fontFamily: 'Mont-Regular', fontSize: '1.4rem', mr: 1 }}>View All Projects</Typography>

                                </Button>
                                <Button onClick={createProjectHandler} variant='contained' sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', textTransform: 'none', backdropFilter: 'blur(25)', backgroundColor: 'red', color: 'white', '&:hover': { backgroundColor: 'white', color: 'red' }, transitionDuration: '0.6s', padding: '1.5vh 2vw' }}>
                                    <Typography sx={{ fontFamily: 'Mont-Regular', fontSize: '1.4rem', mr: 1 }}>Create New Project</Typography>
                                    <QueueIcon fontSize="large" />
                                </Button>
                            </Box>


                            <div className="footerMobProject">
                                <p className="footerHeadText">Project Created</p>
                                <p className="footerBoldText">{allProjectData ? allProjectData.length : `00`}</p>
                                <div className="footerMobAbsolute">

                                </div>
                            </div>

                            <div className="footerMobFlex">
                                <div className="leftFooter">
                                    <p className="footerHeadText1">Project Created</p>
                                    <p className="footerBoldText1">11</p>
                                    <div className="leftFooterAbsolute"></div>
                                </div>
                                <div className="leftFooter">
                                    <p className="footerHeadText1">Project Created</p>
                                    <p className="footerBoldText1">11</p>
                                    <div className="leftFooterAbsolute"></div>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DashboardScreen;