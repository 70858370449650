import React, { useRef, useState, useEffect } from "react";
import { Bar, Line, Pie, Doughnut, Radar,PolarArea } from "react-chartjs-2";
import { CategoryScale } from 'chart.js';
import Chart from 'chart.js/auto';
import { Button, FormControl, FormControlLabel, FormLabel, Grid, InputLabel, MenuItem, Radio, RadioGroup, Select, Typography } from "@mui/material";
import { Box } from "@mui/system";
import './chartStyle.css'
// Chart.register(CategoryScale);

function CustomChart(props) {
    const initialDates = [
        "january",
        "februray",
        "march",
        "april",
        "may",
        "june",
        "july",
        "august",
        "september",
        "october",
        "november",
        "december"
    ];
    const initialDataPoints = [100, 200, 300, 400, 500, 600, 700, 400, 500, 800, 1200, 200];

    const [dates, setDates] = useState(initialDates);
    const [dataPoints, setDataPoints] = useState(initialDataPoints);
    const [startMonth, setStartMonth] = useState('');
    const [endMonth, setEndMonth] = useState('');
    const [ChartType, setChartType] = useState(Line)
    const [area, setArea] = useState(false)




    const handleStartChange = (event) => {
        setStartMonth(event.target.value);
        console.log('start', event.target.value)
    };

    const handleEndChange = (event) => {
        setEndMonth(event.target.value);
    };

    function filterData() {
        const dates2 = [...initialDates];
        const dataPoints2 = [...initialDataPoints];
        const indexstartdate = dates2.indexOf(startMonth.toLocaleLowerCase());
        const indexenddate = dates2.indexOf(endMonth.toLocaleLowerCase());

        //slice the array
        const filterDate = dates2.slice(indexstartdate, indexenddate + 1);
        const filterDataPoints = dataPoints2.slice(
            indexstartdate,
            indexenddate + 1
        );
        setDates(filterDate);
        setDataPoints(filterDataPoints);
        console.log(dates, dataPoints);
    }

    const chartTypeHandler = (e) => {
        if (e.target.value === 'Line') {
            setChartType(Line)
            setArea(false)
        }

        if (e.target.value === 'Area') {
            setChartType(Line)
            setArea(true)
        }

        if (e.target.value === 'Pie') {
            setChartType(Pie)
        }

        if (e.target.value === 'Doughnut') {
            setChartType(Doughnut)
        }

        if (e.target.value === 'Bar') {
            setChartType(Bar)
        }
    }


    useEffect(() => {
        if (props.type === 1) {
            setChartType(Bar)
        } else if (props.type === 2) {
            setChartType(Pie)
        } else if (props.type === 3) {
            setChartType(Line)
            setArea(true)
        } else if (props.type === 5) {
            setChartType(PolarArea)
        }
    }, [])



    return (

        <Grid className="morphism" sx={{ height: '100%', borderRadius: "6px", pt: 2 }}>

            {/* chart type selection */}
            <FormControl sx={{ display: props.type === 3 || props.type === 5 ? 'none' : 'flex', justifyContent: 'center', alignItems: 'center', mb: 2 }}>
                <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="Line"
                    name="radio-buttons-group"
                    row
                >
                    <FormControlLabel value="Line" control={<Radio onChange={(e) => chartTypeHandler(e)} sx={{
                        color: '#eab308',
                        '&.Mui-checked': {
                            color: '#eab308',
                        },
                    }} />} label={<Typography sx={{ color: 'white' }}>Line Chart</Typography>} />
                    <FormControlLabel value="Bar" control={<Radio onChange={(e) => chartTypeHandler(e)} sx={{
                        color: '#eab308',
                        '&.Mui-checked': {
                            color: '#eab308',
                        },
                    }} />} label={<Typography sx={{ color: 'white' }}>Bar Chart</Typography>} />
                    <FormControlLabel value="Area" control={<Radio onChange={(e) => chartTypeHandler(e)} sx={{
                        color: '#eab308',
                        '&.Mui-checked': {
                            color: '#eab308',
                        },
                    }} />} label={<Typography sx={{ color: 'white' }}>Area Chart</Typography>} />
                    <FormControlLabel value="Pie" control={<Radio onChange={(e) => chartTypeHandler(e)} sx={{
                        color: '#eab308',
                        '&.Mui-checked': {
                            color: '#eab308',
                        },
                    }} />} label={<Typography sx={{ color: 'white' }}>Pie Chart</Typography>} />
                    <FormControlLabel value="Doughnut" control={<Radio onChange={(e) => chartTypeHandler(e)} sx={{
                        color: '#eab308',
                        '&.Mui-checked': {
                            color: '#eab308',
                        },
                    }} />} label={<Typography sx={{ color: 'white' }}>Doughnut Chart</Typography>} />
                </RadioGroup>
            </FormControl>

            <Box sx={{ display: props.type === 3 || props.type === 5 ? 'none' : 'flex', alignItems: 'center', justifyContent: 'center', mb: 2, scale: '85%' }}>
                <FormControl sx={{ width: '20vw' }}>
                    <InputLabel sx={{ color: 'white' }} id="demo-simple-select-label1">Start Month</InputLabel>
                    <Select
                        labelId="demo-simple-select-label1"
                        id="demo-simple-select1"
                        value={startMonth}
                        label={<Typography sx={{ color: 'white' }}>Start Month</Typography>}
                        sx={{ '&.MuiInput-root': { color: "white", borderColor: 'white' } }}

                        onChange={handleStartChange}
                    >
                        <MenuItem value={'January'}>January</MenuItem>
                        <MenuItem value={'Februray'}>Februray</MenuItem>
                        <MenuItem value={'March'}>March</MenuItem>
                        <MenuItem value={'April'}>April</MenuItem>
                        <MenuItem value={'May'}>May</MenuItem>
                        <MenuItem value={'June'}>June</MenuItem>
                        <MenuItem value={'July'}>July</MenuItem>
                        <MenuItem value={'August'}>August</MenuItem>
                        <MenuItem value={'September'}>September</MenuItem>
                        <MenuItem value={'October'}>October</MenuItem>
                        <MenuItem value={'November'}>November</MenuItem>
                        <MenuItem value={'December'}>December</MenuItem>
                    </Select>
                </FormControl>
                <FormControl sx={{ width: '20vw', margin: '0 2vw' }}>
                    <InputLabel sx={{ color: 'white' }} id="demo-simple-select-label">End Month</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={endMonth}
                        label={<Typography sx={{ color: 'white' }}>End Month</Typography>}
                        onChange={handleEndChange}
                    >
                        <MenuItem value={'January'}>January</MenuItem>
                        <MenuItem value={'Februray'}>Februray</MenuItem>
                        <MenuItem value={'March'}>March</MenuItem>
                        <MenuItem value={'April'}>April</MenuItem>
                        <MenuItem value={'May'}>May</MenuItem>
                        <MenuItem value={'June'}>June</MenuItem>
                        <MenuItem value={'July'}>July</MenuItem>
                        <MenuItem value={'August'}>August</MenuItem>
                        <MenuItem value={'September'}>September</MenuItem>
                        <MenuItem value={'October'}>October</MenuItem>
                        <MenuItem value={'November'}>November</MenuItem>
                        <MenuItem value={'December'}>December</MenuItem>
                    </Select>
                </FormControl>
                <Button onClick={filterData} disabled={startMonth && endMonth ? false : true} variant="contained" sx={{ padding: '1vh 2vw', border: '2px solid #65a30d', color: '#65a30d !important', textDecoration: 'none' }}>Filter</Button>
            </Box>

            <Grid sx={{ padding: '2rem' }}>
                <ChartType
                    id="myChart"
                    data={{
                        labels: dates,
                        datasets: [
                            {
                                fill: ChartType === Line && area ? true : false,
                                label: "Invoice Details",
                                data: dataPoints,
                                backgroundColor: [
                                    "rgba(255, 99, 132, 0.2)",
                                    "rgba(54, 162, 235, 0.2)",
                                    "rgba(255, 206, 86, 0.2)",
                                    "rgba(75, 192, 192, 0.2)",
                                    "rgba(153, 102, 255, 0.2)",
                                    "rgba(255, 159, 64, 0.2)",
                                ],
                                borderColor: [
                                    "rgba(255, 99, 132, 1)",
                                    "rgba(54, 162, 235, 1)",
                                    "rgba(255, 206, 86, 1)",
                                    "rgba(75, 192, 192, 1)",
                                    "rgba(153, 102, 255, 1)",
                                    "rgba(255, 159, 64, 1)",
                                ],
                                borderWidth: 1,
                            },
                        ],
                    }}
                    height={props.height}
                    width={props.width}
                    options={{
                        maintainAspectRatio: false,

                    }}
                />
            </Grid>
        </Grid >
    );
}

export default CustomChart;







