import { cmdId, keyBlocklyXml } from "./consts";
//import {
//    parseCode
//} from './utils/js2blocks';
import BlocklyEditor from "./blocklyEditor";

export default (editor, opts = {}) => {
  const cm = editor.Commands;
  const md = editor.Modal;
  const domc = editor.Components;
  const {
    modalTitle,
    codeViewOptions,
    commandBlocklyScript,
    blocklyOptions,
    blocklyTypesSupport,
    toolbarIcon,
    onRun,
    onError,
    starter,
  } = opts;

  let blocklyEditor = null;
  let content = null;
  let initialComponentValue = "";
  const projectId = window.location.search.split("=")[1];

  const appendToContent = (target, content) => {
    if (content instanceof HTMLElement) {
      target.appendChild(content);
    } else if (content) {
      target.insertAdjacentHTML("beforeend", content);
    }
  };

  // Add icons to specified component types
  blocklyTypesSupport &&
    blocklyTypesSupport.forEach((type) => {
      const typeOpt = domc.getType(type).model;
      domc.addType(type, {
        model: {
          initToolbar() {
            typeOpt.prototype.initToolbar.apply(this, arguments);
            const tb = this.get("toolbar");
            const tbExists = tb.some((item) => item.command === cmdId);

            if (!tbExists) {
              tb.unshift({
                command: cmdId,
                label: toolbarIcon,
                ...opts.toolbarBtnBlockly,
              });
              this.set("toolbar", tb);
            }
          },
        },
      });
    });

  // Add the blockly command
  cm.add(cmdId, {
    keyBlocklyXml,

    async run(editor, sender, opts = {}) {
      this.editor = editor;
      this.options = opts;
      this.target = opts.target || editor.getSelected();
      const target = this.target;

      let projectPages = await this.fetchProjectPages();

      console.log("projectPages::::::::::commands::::", projectPages);

      console.log("cmdId::::::::::");

      if (target && projectPages) {
        this.showCustomCode(target, projectPages);
      }
    },

    fetchProjectPages() {
      return new Promise((resolve, reject) => {
        const userInfo = JSON.parse(localStorage.getItem("userInfo"));
        // const apihost = "http://127.0.0.1:8085/";
        
        const apihost = "https://backend1.redsling.com/";

        // const apihost = "https://staging-backend1.redsling.com/";

        fetch(`${apihost}pages/projectPage/${projectId}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userInfo.token}`,
          },
        })
          .then((response) => response.json())
          .then((data) => {
            try {
              const blockTypes = data.map((x) => ({
                name: x.name,
                id: x._id,
              }));

              resolve(blockTypes);
            } catch(e) {
              console.error(e);
            }            
          })
          .catch((error) => {
            console.error("API call error:", error);
            reject(error);
          });
      });
    },

    stop(editor) {
      //blocklyEditor && blocklyEditor.hide();
      blocklyEditor.workspace.clear();
      md.close();
    },

    /**
     * Method which tells how to show the custom code
     * @param  {Component} target
     */
    showCustomCode(target, projectPages) {
      const { editor, options } = this;
      const title = options.title || "redSling";
      if (!content) content = this.getContent();
      const code = target.getScriptString();
      md.open({
        title,
        content,
      })
        .getModel()
        .once("change:open", () => editor.stopCommand(this.id));
      //? mount code editors
      if (!blocklyEditor) {
        blocklyEditor = new BlocklyEditor(
          content.querySelector("#blockly"),
          blocklyOptions,
          editor,
          projectPages,
          projectId
        );
        blocklyEditor.workspace.addChangeListener(() => this.updateWorkspace());
      }

      console.log("target Blockly check", target);

      let xml;

      if (target.attributes.tagName === "input") {
        xml =
          target.get(keyBlocklyXml) ||
          `<xml xmlns="https://developers.google.com/blockly/xml"><block type="bi_var" id="OHq;p^O:()AR3;aG%CwD" x="90" y="30"><field name="var_type">let</field><field name="var">el</field><value name="val"><block type="bi_var_name" id="Je,,~AE%W2MCRA?7u.^0"><field name="NAME">${target.view.$el[0].value}</field></block></value></block></xml>`;
      } else if (target.attributes.tagName === "button") {
        xml =
          target.get(keyBlocklyXml) ||
          `<xml xmlns="https://developers.google.com/blockly/xml"><block type="bi_var" id="OHq;p^O:()AR3;aG%CwD" x="90" y="30"><field name="var_type">let</field><field name="var">el</field><value name="val"><block type="bi_var_name" id="Je,,~AE%W2MCRA?7u.^0"><field name="NAME">${target.view.$el[0].textContent}</field></block></value></block></xml>`;
      } else if (target.attributes.tagName === "ion-input") {
        xml =
          target.get(keyBlocklyXml) ||
          `<xml xmlns="https://developers.google.com/blockly/xml"><block type="bi_var" id="OHq;p^O:()AR3;aG%CwD" x="90" y="30"><field name="var_type">let</field><field name="var">el</field><value name="val"><block type="bi_var_name" id="Je,,~AE%W2MCRA?7u.^0"><field name="NAME">${target.view.$el[0].textContent}</field></block></value></block></xml>`;
      } else if (target.attributes.tagName === "ion-toggle") {
        xml =
          target.get(keyBlocklyXml) ||
          `<xml xmlns="https://developers.google.com/blockly/xml"><block type="bi_var" id="OHq;p^O:()AR3;aG%CwD" x="90" y="30"><field name="var_type">let</field><field name="var">el</field><value name="val"><block type="bi_var_name" id="Je,,~AE%W2MCRA?7u.^0"><field name="NAME">${target.view.$el[0].value}</field></block></value></block></xml>`;
      } else if (target.attributes.tagName === "input-select") {
        xml =
          target.get(keyBlocklyXml) ||
          `<xml xmlns="https://developers.google.com/blockly/xml"><block type="bi_var" id="OHq;p^O:()AR3;aG%CwD" x="90" y="30"><field name="var_type">let</field><field name="var">el</field><value name="val"><block type="bi_var_name" id="Je,,~AE%W2MCRA?7u.^0"><field name="NAME">${target.view.$el[0].firstChildElement.value}</field></block></value></block></xml>`;
      } else {
        xml =
          target.get(keyBlocklyXml) ||
          `<xml xmlns="https://developers.google.com/blockly/xml"><block type="bi_var" id="OHq;p^O:()AR3;aG%CwD" x="90" y="30"><field name="var_type">let</field><field name="var">el</field><value name="val"><block type="bi_var_name" id="Je,,~AE%W2MCRA?7u.^0"><field name="NAME">${target.view.$el[0].textContent}</field></block></value></block></xml>`;
      }

      //(code && parseCode(code)) || starter;
      // Blockly.Xml.domToWorkspace(Blockly.Xml.textToDom(xml), blocklyEditor.workspace);

      Blockly.Variables.allUsedVarModels_ = [];
      console.log("Blockly::::::::", Blockly);
      console.log(
        "Blockly.Variables.allUsedVarModels_",
        Blockly.Variables.allUsedVarModels_
      );

      // blocklyEditor.workspace.clear();
      Blockly.Xml.domToWorkspace(
        Blockly.utils.xml.textToDom(xml),
        blocklyEditor.workspace
      );
    },

    /**
     * Custom pre-content. Can be a simple string or an HTMLElement
     */
    getPreContent() {},

    /**
     * Custom post-content. Can be a simple string or an HTMLElement
     */
    getPostContent() {
      const postContent = document.createElement("div");
      postContent.id = "code-viewer";
      const codeViewer = this.getCodeViewer();
      codeViewer.refresh();
      setTimeout(() => codeViewer.focus(), 0);
      postContent.appendChild(codeViewer.getElement());

      console.log("postContent from LB:::::::::", postContent);

      return postContent; //blockly el
    },

    /**
     * Get all the content for the custom code
     * @return {HTMLElement}
     */
    getContent() {
      const { editor } = this;
      const content = document.createElement("div");
      const blocklyCont = document.createElement("div");
      blocklyCont.id = "blockly-cont";
      blocklyCont.innerHTML = `<div id="blockly" style="width:100%;height:500px;color:black"></div>`;
      const pfx = editor.getConfig("stylePrefix");
      content.className = `${pfx}inject-logic`;
      appendToContent(content, this.getPreContent());
      content.appendChild(blocklyCont);
      appendToContent(content, this.getPostContent());
      appendToContent(content, this.getContentActions());
      console.log("content from LB:::::::::", content);

      return content;
    },

    /**
     * Get the actions content. Can be a simple string or an HTMLElement
     * @return {HTMLElement|String}
     */
    getContentActions() {
      const { editor } = this;
      const actions = document.createElement("div");
      actions.id = "actns";
      actions.style = "position:absolute;bottom:260px;right:20px;z-index:2";
      const btn = document.createElement("button");
      const pfx = editor.getConfig("stylePrefix");
      btn.innerHTML = opts.buttonLabel;
      btn.className = `${pfx}btn-prim ${pfx}btn-save__inject-logic`;
      btn.onclick = () => this.handleSave();

      const runLogic = document.createElement("div");
      runLogic.id = "logic-toolbar";
      runLogic.className = "fa fa-bug";
      runLogic.style =
        "margin:5px;padding:10px;background:rgba(0,0,0,0.2);border-radius:3px;border:1px solid rgba(0,0,0,0.2);cursor:pointer";
      runLogic.onclick = () => this.runCode();

      actions.appendChild(runLogic);
      actions.appendChild(btn);
      console.log("actions from LB:::::::::", actions);
      return actions;
    },

    getResultFromBlockly() {
      const workspace = blocklyEditor.workspace;
      console.log("workspace inside blockly", workspace);
      const javascriptCode = Blockly.JavaScript.workspaceToCode(workspace);
      console.log("js code inside blockly", javascriptCode);

      try {
        // Execute the JavaScript code using eval
        const result = eval(javascriptCode);
        console.log("result inside blockly1", result);
        return result;
      } catch (error) {
        // Handle any errors that may occur during execution
        console.error("Error executing Blockly code:", error);
        return null; // Return null or handle the error as needed
      }
    },

    /**
     * Handle the main save task
     */
    handleSave() {
      const { editor, target } = this;
      console.log("script from LB:::::::::", target.get("script"));
      console.log(
        "keyBlocklyXml script from LB:::::::::",
        target.get(keyBlocklyXml)
      );

      // window.editor = editor;

      // blocklyEditor.workspace.clear();
      const code = this.getCodeViewer().getContent();
      console.log("code inside blockly", typeof code);
      const xml = Blockly.Xml.workspaceToDom(blocklyEditor.workspace);
      console.log("Blockly.javascript", Blockly.javascript);
      console.log("xml inside blockly", xml);
      console.log("target inside blockly", target);

      const wrappedCode = `
      // SPACE_TO_AVOID_ADDITIONAL_PARENTHESIS
     ${code}
  `;

      // function findComponentsByTypeRecursive(
      //   rootComponent,
      //   type,
      //   foundComponents = []
      // ) {
      //   if (rootComponent.get("type") === type) {
      //     foundComponents.push(rootComponent);
      //   }

      //   const components = rootComponent.get("components");

      //   if (components) {
      //     components.forEach((childComponent) => {
      //       findComponentsByTypeRecursive(
      //         childComponent,
      //         type,
      //         foundComponents
      //       );
      //     });
      //   }

      //   return foundComponents;
      // }

      // function findComponentsByType(type) {
      //   const allComponents = window.top.editor.DomComponents.getComponents();
      //   const foundComponents = [];
      //   allComponents.forEach((rootComponent) => {
      //     findComponentsByTypeRecursive(rootComponent, type, foundComponents);
      //   });
      //   return foundComponents;
      // }
      // const componentTypeToFind = "custom-table-container";
      // const foundComponents = findComponentsByType(componentTypeToFind);
      // if (foundComponents.length > 0) {
      //   let barChartCompInstance = foundComponents[0];
      //   barChartCompInstance.attributes.script = null;
      // } else {
      //   console.log("No  components found");
      // }

      target.set("script", wrappedCode);
      target.set(keyBlocklyXml, Blockly.Xml.domToText(xml));

      const blocklyResult = this.getResultFromBlockly();

      editor.Modal.close();
    },

    /**
     * Return the code viewer instance
     * @return {CodeViewer}
     */
    getCodeViewer() {
      const { editor } = this;

      if (!this.codeViewer) {
        this.codeViewer = editor.CodeManager.createViewer({
          codeName: "javascript",
          theme: "hopscotch",
          readOnly: 1,
          autoBeautify: 1,
          ...codeViewOptions,
        });
      }

      return this.codeViewer;
    },

    /**
     * Toggle between blockly and code viewer
     */
    toggleCodeViewer() {
      const blocklyStyle = content.querySelector("#blockly").style;
      const codeViewerStyle = content.querySelector("#code-viewer").style;
      const hidden = blocklyStyle.height == "0px" || blocklyStyle.height == "0";
      if (hidden) {
        blocklyStyle.height = "500px";
        codeViewerStyle.display = "none";
      } else {
        blocklyStyle.height = "0";
        codeViewerStyle.display = "block";
      }
    },

    /**
     * Update code when blocks change
     */
    updateWorkspace(e) {
      var workspaceContainer = blocklyEditor.workspace.getBubbleCanvas();
      workspaceContainer.style.opacity = 0.5;
      const blockly_code = Blockly.JavaScript.workspaceToCode(
        Blockly.mainWorkspace
      );

      console.log("blockly_code from LB:::::::::", blockly_code);
      try {
        // set readonly from generated
        this.getCodeViewer().setContent(blockly_code);
      } catch (e) {
        // readonly not found.
      }
    },

    /**
     * Syncronize blockly when code changes
     */
    sync() {
      const code = this.getCodeViewer().getContent();
      //parseCode(code);
    },

    /**
     * Evaluate code syntax
     */
    runCode() {
      //console.log("run")
      try {
        const code = this.getCodeViewer().getContent();
        Function('"use strict";' + code)(); // final code
        onRun && onRun();
      } catch (err) {
        console.log("error", err);
        onError && onError(err);
      }
    },

    ...commandBlocklyScript,
  });
};
