import React, {useState} from 'react';

import classes from './LogicBuilder.module.css';

import EmailPanel from './EmailPanel';

import Canvas from '../Testing/Test3/canvas/Canvas';

import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { NewCanvas1 } from './NewCanvas1';
import { Dustbin } from '../LogicBuilder4/Dustbin';
import { NewCanvas } from './NewCanvas';


const WorkSpace = (props) => {
	const [emailPanelOpen, setEmailPanelOpen] = useState(false);

	function emailPanelOpenHandler() {
		setEmailPanelOpen(true);
	}

	function emailPanelCloseHandler() {
		setEmailPanelOpen(false);
	}

	return (
		// <DndProvider backend={HTML5Backend}>
			<div className={classes.workspace}>
				{/* the dragging area */}
				{/* <Canvas /> */}
				{/*<Dustbin /> */}
				{/* <NewCanvas1 workspaceUndoBtn={props.undoParent} /> */}
				<NewCanvas />
				{/* open/close email button */}
				<button className={classes.toggle_email_btn} onClick={emailPanelOpenHandler}>
					<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className={"bi bi-caret-right-fill" + classes.caret} viewBox="0 0 16 16">
						<path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z"/>
					</svg>
				</button>

				{/* open email-panel */}
				{emailPanelOpen && <EmailPanel />}

				{/* close email-panel */}
				{emailPanelOpen && 
					<button className={classes.collapse_email_btn} onClick={emailPanelCloseHandler}>
						<span>
							<svg xmlns="http://www.w3.org/2000/svg" fill="red" className="bi bi-x" viewBox="0 0 16 16" style={{width: '80%', height: '80%'}}>
								<path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
							</svg>
						</span>
					</button> 
				}
			</div>
		// </DndProvider>
	);
};

export default WorkSpace;