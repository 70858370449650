
import { USER_LOGIN_FAILED, USER_LOGIN_LOADING, USER_LOGIN_SUCCESS, USER_LOGOUT, USER_REGISTER_FAILED, USER_REGISTER_LOADING, USER_REGISTER_SUCCESS } from "../Constants/userConstants";



export const loginReducer = (state = {}, action) => {
    switch (action.type) {
        case USER_LOGIN_LOADING:
            return { loading: true }
        case USER_LOGIN_SUCCESS:
            return { loading: false, userInfo: action.payload }
        case USER_LOGIN_FAILED:
            return { loading: false, error: action.payload }
        case USER_LOGOUT:
            return {}
        default:
            return state

    }
}

export const registerUserReducer = (state = {}, action) => {
    switch (action.type) {
        case USER_REGISTER_LOADING:
            return { loading: true }
        case USER_REGISTER_SUCCESS:
            return { loading: false, user: action.payload }
        case USER_REGISTER_FAILED:
            return { loading: false, error: action.payload }
        default:
            return state;
    }
}