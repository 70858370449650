export function showGrowingSpinner() {
    // Create spinner container if it doesn't exist
    let spinnerContainer = document.getElementById('spinner-container');
    if (!spinnerContainer) {
        spinnerContainer = document.createElement('div');
        spinnerContainer.id = 'spinner-container';
        spinnerContainer.style.position = 'fixed';
        spinnerContainer.style.bottom = '20px';
        spinnerContainer.style.right = '20px';
        spinnerContainer.style.zIndex = '9999';
        document.body.appendChild(spinnerContainer);
    }

    // Create spinner element
    const spinner = document.createElement('div');
    spinner.className = 'spinner';
    spinner.style.width = '1.5vw'; // Smaller size
    spinner.style.height = '2vh'; // Smaller size
    spinner.style.borderRadius = '50%';
    spinner.style.backgroundColor = '#ee2129'; // Color of the pulse
    spinner.style.animation = 'pulse 1.5s infinite'; // Pulsating animation

    spinnerContainer.innerHTML = ''; // Clear previous spinners
    spinnerContainer.appendChild(spinner);

    // Add spinner animation styles
    const style = document.createElement('style');
    style.textContent = `
        @keyframes pulse {
            0% {
                transform: scale(1);
                opacity: 1;
            }
            50% {
                transform: scale(1.5);
                opacity: 0.5;
            }
            100% {
                transform: scale(1.5);
                opacity: 0;
            }
        }
    `;
    document.head.appendChild(style);
}

export function hideGrowingSpinner() {
    const spinnerContainer = document.getElementById('spinner-container');
    if (spinnerContainer) {
        spinnerContainer.remove();
    }
}
