import { useState } from 'react'
import { useDrop } from 'react-dnd'
import { ItemTypes } from './ItemTypes.js'
function getStyle(backgroundColor) {
  return {
    border: '1px solid rgba(0,0,0,0.2)',
    minHeight: '8rem',
    minWidth: '8rem',
    color: 'white',
    backgroundColor,
    padding: '2rem',
    paddingTop: '1rem',
    margin: '1rem',
    textAlign: 'center',
    float: 'left',
    fontSize: '1rem',
  }
}
export const Dustbin = ({ greedy, children, name }) => {
  const [hasDropped, setHasDropped] = useState(false)
  const [hasDroppedOnChild, setHasDroppedOnChild] = useState(false)
  const [items, addItems] = useState([])
  const [{ isOver, isOverCurrent }, drop] = useDrop(
    () => ({
      accept: ItemTypes.BOX,
      drop: (_item, monitor) => {
        const didDrop = monitor.didDrop()
        console.log("name - ", name)
        console.log("Greeedy ", greedy)
        console.log("Did drop  ", didDrop)
        if (didDrop && !greedy) {
            // console.log("not greedy")
            // console.log("has Dropped ", hasDropped)
            // console.log("has Dropped on Children ", hasDroppedOnChild)
            return
        }
        // console.log("Setting has dropped to true")
        setHasDropped(true)
        // console.log("Setting has dropped on child to true")
        setHasDroppedOnChild(didDrop)
        if(items.length > 0) {
            
            addItems(prevState => {
                    // console.log("Previtems ---- ", prevState)
                    let initItems = [...prevState]
                    return [...initItems, ItemTypes.BOX]
                }
            )
        } else {
            // console.log("Zerroooo")
            addItems([ItemTypes.BOX])
        }
        
      },
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        // didDrop: monitor.didDrop(),
        isOverCurrent: monitor.isOver({ shallow: true }),
      }),
    }),
    [greedy, hasDropped, hasDroppedOnChild, items.length],
  )
  const text = greedy ? 'greedy' : 'not greedy'
  let backgroundColor = 'rgba(0, 0, 0, .5)'
  if (isOverCurrent || (isOver && greedy)) {
    backgroundColor = 'darkgreen'
  }

//   console.log("HAS Dropped on CHiled == ", hasDroppedOnChild)
  return (
    <div ref={drop} style={getStyle(backgroundColor)}>
      {text}
      {items.length > 0 ? items : ""}
      <br />
      {/* 
        Mainly used if you want to show that item has been dropped in a child. 
        This for the first one in the documentation */}
      {hasDropped && <p>dropped {hasDroppedOnChild && ' on child'}</p>}

      <div>{children}</div>
    </div>
  )
}
