import { API_HOST } from "../../api_utils";

const customCardFH_new = (editor, opts = {}) => {
  console.log("customCardFH_new options", opts);
  const btnClickHandler = function () {
    console.log("clicked");

    // const selectBtns = document.querySelectorAll('.select-btn');
    const selectBtns = this;
    // Define the original content of the button
    const originalContent = selectBtns.innerHTML;
    console.log("clicked selectBtns", selectBtns, originalContent);

    let selectedBtn = null;

    const tickSvg = `

      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">

        <path fill="currentColor" d="M20.285 2.486l-9.585 9.812-3.524-3.586-2.122 2.171 5.646 5.759 11.707-11.965z"/>

      </svg>

    `;

    // const applySelectedStyles = (btn) => {

    //   btn.style.border = "none";

    //   btn.style.outline = "none";

    //   btn.style.fontSize = "0";

    //   btn.innerHTML = tickSvg;

    // };

    // const resetStyles = (btn) => {

    //   btn.style.border = "";

    //   btn.style.outline = "";

    //   btn.style.fontSize = "";

    //   btn.textContent = 'Select';

    // };

    // selectBtns.forEach(btn => {

    //   btn.addEventListener('click', () => {

    //     if (selectedBtn) {

    //       resetStyles(selectedBtn);

    //     }

    //     applySelectedStyles(btn);

    //     selectedBtn = btn;

    //   });

    // });

    // Track the button state
    let isSvgApplied = false;

    // Function to apply the SVG
    const applySvg = () => {
      this.innerHTML = tickSvg;
      isSvgApplied = true;
    };

    // Function to reset the button content
    const resetContent = () => {
      this.innerHTML = originalContent;
      isSvgApplied = false;
    };

    selectBtns.addEventListener("click", function () {
      console.log("clicked 2");

      // selectBtns.style.border = "none";

      // selectBtns.style.outline = "none";

      // selectBtns.style.fontSize = "0";
      // selectBtns.style.textAlign = "center";
      // selectBtns.style.color = "white";

      // selectBtns.innerHTML = tickSvg;

      if (isSvgApplied) {
        // Reset to original content
        resetContent();
      } else {
        // Apply the SVG
        applySvg();
      }
    });
  };

  const selectOneBtnAtATime = function (props) {
    console.log("+customCardElementWrapperFH", this, props);

    let wrapper = this;
    // Get all buttons with class 'select-btn'
    const buttons = wrapper.querySelectorAll(".select-btn");
    console.log("customCardElementWrapperFH buttons", buttons);

    const tickSvg = `
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
          <path fill="currentColor" d="M20.285 2.486l-9.585 9.812-3.524-3.586-2.122 2.171 5.646 5.759 11.707-11.965z"/>
      </svg>
  `;

    // Function to apply tickSvg to the clicked button and reset other buttons
    function applyTick(event) {
      console.log("customCardElementWrapperFH clicked");
      // Reset all buttons to their original content
      const buttons = wrapper.querySelectorAll(".select-btn");
      buttons.forEach((button) => {
        if (button) {
          button.textContent = "Select";
          console.log("customCardElementWrapperFH reset");
        }
      });

      // Apply tickSvg to the clicked button
      const clickedButton = event.target;
      clickedButton.innerHTML = tickSvg;
      clickedButton.style.color = "white";

      const cardId = clickedButton.getAttribute("model_id");
      const carouselId = clickedButton.getAttribute("carouselId");
      if (cardId && carouselId) {
        localStorage.setItem("FH_Carousel_" + carouselId, cardId);
      }

      console.log("customCardElementWrapperFH button clicked", clickedButton);
    }

    // Add click event listener to each button
    // buttons.forEach(button => {
    //     if (button) {
    //       button.addEventListener('click', applyTick);
    //       console.log('customCardElementWrapperFH button', button);
    //     }
    // });

    wrapper.addEventListener("click", (event) => {
      if (event.target.classList.contains("select-btn")) {
        applyTick(event);
      }
    });
  };

  const parentScript = function (props) {
    console.log("+parentScript", this, props);

    let nextBtn = this.getElementsByClassName("sliderNextActionButtonFH")[0];
    let prevBtn = this.getElementsByClassName("sliderPrevActionButtonFH")[0];

    nextBtn.onclick = function (event) {
      let cardCarouselSliderCount = JSON.parse(
        localStorage.getItem("cardCarouselSliderCount")
      );

      let itemsPerSlide = localStorage.getItem("itemsPerSlide");

      let totalPages = JSON.parse(
        localStorage.getItem("cardCarouselDataForSlider")
      ).length;

      let parentNode = event.target.parentNode.parentNode.parentNode.parentNode;

      let wrapperParent = parentNode.querySelector(".slidesWrapperParent");
      let cardsPerPage = itemsPerSlide;
      let cardWidth = 370;

      if (cardCarouselSliderCount < totalPages) {
        const translation =
          -(cardCarouselSliderCount + 1) * cardWidth * cardsPerPage;

        // console.log("translation", translation);

        wrapperParent.style.transform = `translateX(${translation}px)`;

        localStorage.setItem(
          "cardCarouselSliderCount",
          JSON.stringify(cardCarouselSliderCount + 1)
        );
      }
    };

    prevBtn.onclick = function (event) {
      let cardCarouselSliderCount = JSON.parse(
        localStorage.getItem("cardCarouselSliderCount")
      );

      let itemsPerSlide = localStorage.getItem("itemsPerSlide");

      let totalPages = JSON.parse(
        localStorage.getItem("cardCarouselDataForSlider")
      ).length;

      let parentNode = event.target.parentNode.parentNode.parentNode.parentNode;

      let wrapperParent = parentNode.querySelector(".slidesWrapperParent");
      let cardsPerPage = itemsPerSlide;
      let cardWidth = 370;

      if (cardCarouselSliderCount > 0) {
        const translation =
          -(cardCarouselSliderCount - 1) * cardWidth * cardsPerPage;

        // console.log("translation", translation);

        wrapperParent.style.transform = `translateX(${translation}px)`;

        localStorage.setItem(
          "cardCarouselSliderCount",
          JSON.stringify(cardCarouselSliderCount - 1)
        );
      }
    };
  };

  editor.DomComponents.addType("customCardElementWrapperFH", {
    model: {
      defaults: {
        // attributes: {class: "customCardElementWrapperFH"},
        script: selectOneBtnAtATime,
        components: [
          {
            tagName: "ion-card",
            type: "default",
            attributes: {
              name: "ionCard",
              card_id: "6524014b8ea8a1e5deca5bc5",
              class: "ionCardWrapper",
            },
            style: {
              position: "relative",
              "z-index": "2",
              width: "33.33%",
              padding: "0.5rem",
              "box-shadow":
                "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
            },
            components: [
              {
                type: "image",
                tagName: "img",
                attributes: {
                  src: "https://ionicframework.com/docs/img/demos/card-media.png",
                  alt: "Silhouette of mountains",
                },
                style: {
                  width: "100%",
                  height: "350px",
                },
              },
              {
                type: "default",
                tagName: "div",
                style: {
                  "gap-y": "10px",
                  "padding-top": "5px",
                  "padding-bottom": "5px",
                },
                components: [
                  {
                    type: "text",
                    content: `Card Title`,
                    style: {
                      "font-size": "18px",
                      "font-weight": "600",
                      width: "100%",
                    },
                  },
                  {
                    type: "text",
                    content: `Card Sub-Title`,
                    style: {
                      "font-size": "12px",
                      "font-weight": "400",
                      width: "100%",
                    },
                  },
                  {
                    type: "text",
                    content: `Card Desciption goes here .....`,
                    style: {
                      "font-size": "12px",
                      "font-weight": "400",
                      width: "100%",
                    },
                  },
                ],
              },
              {
                type: "default",
                tagName: "div",
                style: {
                  width: "100%",
                  "padding-top": "20px",
                  "padding-bottom": "20px",
                  display: "flex",
                  "justify-content": "start",
                  "align-items": "center",
                },

                components: [
                  {
                    type: "default",
                    tagName: "button",
                    style: {
                      width: "50%",
                      "padding-top": "8px",
                      "padding-bottom": "8px",
                      "background-color": "#179C7D",
                      color: "white",
                      "border-radius": "10px",
                    },
                    attributes: {
                      model_id: "1",
                      class: "viewModelBtn",
                    },
                    content: `Open Model`,
                  },
                  {
                    type: "default",
                    tagName: "button",
                    style: {
                      width: "50%",
                      "padding-top": "8px",
                      "padding-bottom": "8px",
                      "background-color": "#179C7D",
                      color: "white",
                      "border-radius": "10px",
                      "margin-left": "10px",
                    },
                    attributes: {
                      model_id: "1",
                      class: "editCardBtn",
                    },
                    content: `Edit Data`,
                  },
                ],
              },
            ],
          },
          {
            tagName: "ion-card",
            type: "default",
            attributes: {
              name: "ionCard",
              card_id: "2",
              class: "ionCardWrapper",
            },
            style: {
              position: "relative",
              "z-index": "2",
              width: "33.33%",
              padding: "0.5rem",
              "box-shadow":
                "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
            },
            components: [
              {
                type: "image",
                tagName: "img",
                attributes: {
                  src: "https://ionicframework.com/docs/img/demos/card-media.png",
                  alt: "Silhouette of mountains",
                },
                style: {
                  width: "100%",
                  height: "350px",
                },
              },
              {
                type: "default",
                tagName: "div",
                style: {
                  "gap-y": "10px",
                  "padding-top": "5px",
                  "padding-bottom": "5px",
                },
                components: [
                  {
                    type: "text",
                    content: `Card Title`,
                    style: {
                      "font-size": "18px",
                      "font-weight": "600",
                      width: "100%",
                    },
                  },
                  {
                    type: "text",
                    content: `Card Sub-Title`,
                    style: {
                      "font-size": "12px",
                      "font-weight": "400",
                      width: "100%",
                    },
                  },
                  {
                    type: "text",
                    content: `Card Desciption goes here .....`,
                    style: {
                      "font-size": "12px",
                      "font-weight": "400",
                      width: "100%",
                    },
                  },
                ],
              },
              {
                type: "default",
                tagName: "div",
                style: {
                  width: "100%",
                  "padding-top": "20px",
                  "padding-bottom": "20px",
                  display: "flex",
                  "justify-content": "start",
                  "align-items": "center",
                },

                components: [
                  {
                    type: "default",
                    tagName: "button",
                    style: {
                      width: "50%",
                      "padding-top": "8px",
                      "padding-bottom": "8px",
                      "background-color": "#179C7D",
                      color: "white",
                      "border-radius": "10px",
                    },
                    attributes: {
                      model_id: "1",
                      class: "viewModelBtn",
                    },
                    content: `Open Model`,
                  },
                  {
                    type: "default",
                    tagName: "button",
                    style: {
                      width: "50%",
                      "padding-top": "8px",
                      "padding-bottom": "8px",
                      "background-color": "#179C7D",
                      color: "white",
                      "border-radius": "10px",
                      "margin-left": "10px",
                    },
                    attributes: {
                      model_id: "1",
                      class: "editCardBtn",
                    },
                    content: `Edit Data`,
                  },
                ],
              },
            ],
          },
          {
            tagName: "ion-card",
            type: "default",
            attributes: {
              name: "ionCard",
              card_id: "3",
              class: "ionCardWrapper",
            },
            style: {
              position: "relative",
              "z-index": "2",
              width: "33.33%",
              padding: "0.5rem",
              "box-shadow":
                "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
            },
            components: [
              {
                type: "image",
                tagName: "img",
                attributes: {
                  src: "https://ionicframework.com/docs/img/demos/card-media.png",
                  alt: "Silhouette of mountains",
                },
                style: {
                  width: "100%",
                  height: "350px",
                },
              },
              {
                type: "default",
                tagName: "div",
                style: {
                  "gap-y": "10px",
                  "padding-top": "5px",
                  "padding-bottom": "5px",
                },
                components: [
                  {
                    type: "text",
                    content: `Card Title`,
                    style: {
                      "font-size": "18px",
                      "font-weight": "600",
                      width: "100%",
                    },
                  },
                  {
                    type: "text",
                    content: `Card Sub-Title`,
                    style: {
                      "font-size": "12px",
                      "font-weight": "400",
                      width: "100%",
                    },
                  },
                  {
                    type: "text",
                    content: `Card Desciption goes here .....`,
                    style: {
                      "font-size": "12px",
                      "font-weight": "400",
                      width: "100%",
                    },
                  },
                ],
              },
              {
                type: "default",
                tagName: "div",
                style: {
                  width: "100%",
                  "padding-top": "20px",
                  "padding-bottom": "20px",
                  display: "flex",
                  "justify-content": "start",
                  "align-items": "center",
                },

                components: [
                  {
                    type: "default",
                    tagName: "button",
                    style: {
                      width: "50%",
                      "padding-top": "8px",
                      "padding-bottom": "8px",
                      "background-color": "#179C7D",
                      color: "white",
                      "border-radius": "10px",
                    },
                    attributes: {
                      model_id: "1",
                      class: "viewModelBtn",
                    },
                    content: `Open Model`,
                  },
                  {
                    type: "default",
                    tagName: "button",
                    style: {
                      width: "50%",
                      "padding-top": "8px",
                      "padding-bottom": "8px",
                      "background-color": "#179C7D",
                      color: "white",
                      "border-radius": "10px",
                      "margin-left": "10px",
                    },
                    attributes: {
                      model_id: "1",
                      class: "editCardBtn",
                    },
                    content: `Edit Data`,
                  },
                ],
              },
            ],
          },
        ],

        API_HOST_NEW: "abc",
        changeProp: true,
        "script-props": ["API_HOST_NEW"],
      },
    },
    view: {
      events: {
        "click .editCardBtn": "openCardEditModel",
        "click .viewModelBtn": "openThreeDModel",
      },

      openCardEditModel(event) {
        event.stopPropagation();
        const cardId = event.target.getAttribute("model_id");
        const carouselId = event.target.getAttribute("carouselId");
        if (cardId && carouselId) {
          localStorage.setItem("FH_Carousel_" + carouselId, cardId);
        }

        // let cardCarouselDataForSlider = JSON.parse(
        //   localStorage.getItem("cardCarouselDataForSlider")
        // );

        // const cardData = cardCarouselDataForSlider.find(
        //   (item) => item._id === cardId
        // );

        // let targetName = event.target.getAttribute("name");

        // if (cardCarouselDataForSlider.length) {
        //   const cardId = event.target.getAttribute("model_id");

        //   const cardData = cardCarouselDataForSlider.find(
        //     (item) => item._id === cardId
        //   );

        //   if (!cardData) {
        //     return;
        //   }

        //   const modal = document.createElement("div");
        //   modal.className = "modal";

        //   const modalContent = document.createElement("div");
        //   modalContent.style.display = "flex";
        //   modalContent.style.justifyContent = "center";
        //   modalContent.style.alignItems = "center";
        //   modalContent.style.background = "rgba(0, 0, 0, 0.5)";
        //   modalContent.style.width = "100%";
        //   modalContent.style.height = "100%";

        //   const innerModalWrapper = document.createElement("div");
        //   innerModalWrapper.style.backgroundColor = "white";
        //   innerModalWrapper.style.width = "80%";
        //   innerModalWrapper.style.paddingTop = "2rem";
        //   innerModalWrapper.style.paddingBottom = "2rem";
        //   innerModalWrapper.style.paddingRight = "2rem";
        //   innerModalWrapper.style.paddingLeft = "2rem";
        //   innerModalWrapper.style.borderRadius = "12px";
        //   innerModalWrapper.style.display = "flex";
        //   innerModalWrapper.style.justifyContent = "center";
        //   innerModalWrapper.style.alignItems = "center";
        //   innerModalWrapper.style.flexDirection = "column";

        //   modalContent.appendChild(innerModalWrapper);

        //   const titleWrapper = document.createElement("div");
        //   titleWrapper.style.display = "flex";
        //   titleWrapper.style.width = "100%";
        //   titleWrapper.style.justifyContent = "center";
        //   titleWrapper.style.alignItems = "center";
        //   titleWrapper.style.position = "relative";
        //   const modalTitle = document.createElement("h2");
        //   modalTitle.textContent = "Edit Data";
        //   titleWrapper.appendChild(modalTitle);

        //   const closeModal = document.createElement("span");
        //   closeModal.className = "close";
        //   closeModal.innerHTML = "&times;";

        //   closeModal.addEventListener("click", () => {
        //     modal.style.display = "none";
        //   });

        //   closeModal.style.position = "absolute";
        //   closeModal.style.top = "5px";
        //   closeModal.style.right = "5px";
        //   closeModal.style.cursor = "pointer";
        //   titleWrapper.appendChild(closeModal);

        //   innerModalWrapper.appendChild(titleWrapper);

        //   function createInputFields(data, parentKey) {
        //     for (const key in data) {
        //       if (key !== "_id" && key !== "__v") {
        //         const field = data[key];
        //         const fieldKey = parentKey ? `${parentKey}.${key}` : key;

        //         if (typeof field === "object") {
        //           // If the field is an object, recursively create input fields for its properties
        //           createInputFields(field, fieldKey);
        //         } else {
        //           const wrapperDiv = document.createElement("div");
        //           wrapperDiv.style.display = "flex";
        //           wrapperDiv.style.justifyContent = "start";
        //           wrapperDiv.style.alignItems = "center";
        //           wrapperDiv.style.marginTop = "15px";

        //           const label = document.createElement("label");
        //           label.style.width = "12rem";
        //           label.setAttribute("for", fieldKey);
        //           label.textContent = fieldKey;

        //           const input = document.createElement("input");
        //           input.style.width = "35rem";
        //           input.style.border = "1px solid gray";
        //           input.style.borderRadius = "8px";
        //           input.style.padding = "8px";
        //           input.style.marginLeft = "10px";
        //           input.setAttribute("type", "text");
        //           input.setAttribute("id", fieldKey);
        //           input.setAttribute("value", field);

        //           wrapperDiv.appendChild(label);
        //           wrapperDiv.appendChild(input);
        //           innerModalWrapper.appendChild(wrapperDiv);
        //         }
        //       }
        //     }
        //   }

        //   // Call the function to create input fields for cardData
        //   createInputFields(cardData);

        //   const saveChangesButton = document.createElement("button");
        //   saveChangesButton.style.backgroundColor = "#0284c7";
        //   saveChangesButton.style.padding = "8px 16px";
        //   saveChangesButton.style.marginTop = "3rem";
        //   saveChangesButton.style.color = "white";
        //   saveChangesButton.style.border = "none";
        //   saveChangesButton.style.borderRadius = "12px";

        //   saveChangesButton.setAttribute("id", "saveChanges");
        //   saveChangesButton.textContent = "Save Changes";

        //   saveChangesButton.addEventListener("click", () => {
        //     const updatedCardData = { ...cardData };
        //     let updatedId;
        //     const updatedData = { ...cardData };

        //     function updateNestedProperties(
        //       updatedData,
        //       data,
        //       inputField,
        //       key
        //     ) {
        //       const inputFieldId = inputField.id;
        //       if (inputFieldId.startsWith(`${key}.`)) {
        //         const nestedKey = inputFieldId.substr(key.length + 1);
        //         if (!updatedData[key]) {
        //           updatedData[key] = {};
        //         }
        //         updatedData[key][nestedKey] = parseFloat(inputField.value);
        //       } else {
        //         // Handle non-nested properties
        //         updatedData[key] =
        //           inputField.type === "number"
        //             ? parseFloat(inputField.value)
        //             : inputField.value;
        //       }
        //     }

        //     for (const key in updatedCardData) {
        //       if (key !== "_id" && key !== "__v") {
        //         const inputField = innerModalWrapper.querySelector(`#${key}`);
        //         if (inputField) {
        //           updateNestedProperties(
        //             updatedData,
        //             updatedCardData,
        //             inputField,
        //             key
        //           );
        //         }
        //       } else if (key === "_id") {
        //         updatedId = updatedCardData["_id"];
        //       }
        //     }

        //     let userInfo = JSON.parse(localStorage.getItem("userInfo"));
        //     const url = localStorage.getItem("API_UPDATE_3D_MODEL");
        //     //alert(url);
        //     //let urltrait = localStorage.getItem("urlTrait");
        //     //fetch(`${API_HOST}FH/api/sensorFH/${updatedId}`, {
        //     fetch(`${url}/${updatedId}`, {
        //       //fetch(`${urltrait}/${updatedId}`, {
        //       method: "PUT",
        //       headers: {
        //         "Content-Type": "application/json",
        //         Authorization: `Bearer ${userInfo.token}`,
        //       },
        //       body: JSON.stringify(updatedData),
        //     })
        //       .then((response) => response.json())
        //       .then((data) => {
        //         alert("Card Item Updated Successfully!!!!");

        //         modal.style.display = "none";
        //       })
        //       .catch((error) => {
        //         console.error("Error updating card data:", error);
        //       });
        //   });

        //   innerModalWrapper.appendChild(saveChangesButton);

        //   modal.appendChild(modalContent);

        //   document.body.appendChild(modal);

        //   modal.style.display = "block";
        // }
      },

      openThreeDModel(event) {
        let cardId = event.target.getAttribute("model_id");

        let cardCarouselDataForSlider = JSON.parse(
          localStorage.getItem("cardCarouselDataForSlider")
        );

        let cardData = cardCarouselDataForSlider.find(
          (card) => card._id == cardId
        );

        if (cardData) {
          //let newUrl = `${API_HOST}`;
          // let newUrl = window.location.href;
          // let newBase = newUrl.split(':')[0];
          // //newBase = newBase.replace("ht", "");
          // console.log("newBase", newBase);
          // let url = `${newBase}:9080/js/3D/urdf/?model=${cardData.modelNumber}`;

          const url = window.location.href;
          // Remove the protocol from the URL.
          const urlWithoutProtocol = url.replace(/^http?:\/\//, "");

          // Split the URL on the first slash.
          const urlParts = urlWithoutProtocol.split("/");

          // Get the first part of the URL, which is the domain name.
          let domainName = urlParts[0];
          domainName = domainName.replace(":3000", "");

          const finalUrl = `http://${domainName}:9080/js/3D/urdf/?model=${cardData.modelNumber}`;

          window.open(finalUrl, "_blank");
        }
      },
    },
  });

  editor.DomComponents.addType("customCardParentFH", {
    model: {
      defaults: {
        script: parentScript,
        components: [
          {
            type: "customCardElementWrapperFH",
            tagName: "div",
            script: selectOneBtnAtATime,
            attributes: {
              class: "slidesWrapperParent",
            },
            style: {
              position: "relative",
              "z-index": "2",
              "min-width": "max-content",
              display: "flex",
              "gap-x": "8px",
              "justify-content": "center",
              "align-items": "center",
              overflow: "hidden",
            },
            "style-default": {
              position: "relative",
              "z-index": "2",
              "min-width": "max-content",
              display: "flex",
              "gap-x": "8px",
              "justify-content": "center",
              "align-items": "center",
              overflow: "hidden",
            },
          },
          {
            tagName: "div",
            type: "default",
            style: {
              width: "40px",
              height: "40px",
              position: "absolute",
              top: "50%",
              left: "10px",
              "z-index": "3",
              "box-shadow": "1px 1px 10px 1px rgba(0,0,0,0.75)",
            },
            attributes: {
              name: "sliderPrevActionButtonFH",
              class: "sliderPrevActionButtonFH",
            },
            components: [
              {
                type: "svg",
                tagName: "svg",
                attributes: {
                  xmlns: "http://www.w3.org/2000/svg",
                  width: "38",
                  height: "38",
                  name: "prevSlideCardCarouselFH",
                  "view-box": "0 0 16 16",
                  fill: "currentColor",
                },
                style: {
                  color: "#179C7D",
                },
                content: ` <g transform="scale(2)"> 
              <path d="m3.86 8.753 5.482 4.796c.646.566 1.658.106 1.658-.753V3.204a1 1 0 0 0-1.659-.753l-5.48 4.796a1 1 0 0 0 0 1.506z" />
           </g>`,
              },
            ],
          },
          {
            tagName: "div",
            type: "default",
            style: {
              width: "40px",
              height: "40px",
              position: "absolute",
              top: "50%",
              right: "10px",
              "z-index": "3",
              "box-shadow": "1px 1px 10px 1px rgba(0,0,0,0.75)",
            },
            attributes: {
              name: "sliderNextActionButtonFH",
              class: "sliderNextActionButtonFH",
            },
            components: [
              {
                type: "svg",
                tagName: "svg",
                attributes: {
                  xmlns: "http://www.w3.org/2000/svg",
                  width: "38",
                  height: "38",
                  name: "nextSlideCardCarouselFH",
                  "view-box": "0 0 36 36",
                  fill: "currentColor",
                },
                style: {
                  color: "#179C7D",
                },
                content: `<g transform="scale(2)"> 
              <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
           </g>  `,
              },
            ],
          },
        ],

        API_HOST: "abc",
        changeProp: true,
        "script-props": ["API_HOST"],
      },
    },
    view: {
      events: {
        "click .sliderNextActionButtonFH": "showNextSlide",
        "click .sliderPrevActionButtonFH": "showPrevSlide",
      },

      showNextSlide(event) {
        let cardCarouselSliderCount = JSON.parse(
          localStorage.getItem("cardCarouselSliderCount")
        );

        let itemsPerSlide = localStorage.getItem("itemsPerSlide");

        let totalPages = JSON.parse(
          localStorage.getItem("cardCarouselDataForSlider")
        ).length;

        let parentNode =
          event.target.parentNode.parentNode.parentNode.parentNode;

        let wrapperParent = parentNode.querySelector(".slidesWrapperParent");
        let cardsPerPage = itemsPerSlide;
        let cardWidth = 370;

        if (cardCarouselSliderCount < totalPages) {
          const translation =
            -(cardCarouselSliderCount + 1) * cardWidth * cardsPerPage;

          // console.log("translation", translation);

          wrapperParent.style.transform = `translateX(${translation}px)`;

          localStorage.setItem(
            "cardCarouselSliderCount",
            JSON.stringify(cardCarouselSliderCount + 1)
          );
        }
      },

      showPrevSlide(event) {
        let cardCarouselSliderCount = JSON.parse(
          localStorage.getItem("cardCarouselSliderCount")
        );

        let itemsPerSlide = localStorage.getItem("itemsPerSlide");

        let totalPages = JSON.parse(
          localStorage.getItem("cardCarouselDataForSlider")
        ).length;

        let parentNode =
          event.target.parentNode.parentNode.parentNode.parentNode;

        let wrapperParent = parentNode.querySelector(".slidesWrapperParent");
        let cardsPerPage = itemsPerSlide;
        let cardWidth = 370;

        if (cardCarouselSliderCount > 0) {
          const translation =
            -(cardCarouselSliderCount - 1) * cardWidth * cardsPerPage;

          // console.log("translation", translation);

          wrapperParent.style.transform = `translateX(${translation}px)`;

          localStorage.setItem(
            "cardCarouselSliderCount",
            JSON.stringify(cardCarouselSliderCount - 1)
          );
        }
      },
    },
  });

  const plotCard = async function (props) {
    console.log("plotCard props", props, this);

    // base url bearer token taken directly from local storage after getting downloaded
    let baseUrlData,
      dynamicBaseUrlBearerToken,
      baseUrl1BearerToken,
      baseUrl2BearerToken,
      baseUrl3BearerToken,
      baseUrl4BearerToken,
      baseUrl5BearerToken;
    // getting baseUrl data from local storage for run-time access
    let baseUrlDataFromStorage = localStorage.getItem("baseUrlData");
    if (baseUrlDataFromStorage) {
      baseUrlData = JSON.parse(baseUrlDataFromStorage);
    }
    // extracting token values from base URLs from baseUrlData
    if (baseUrlData) {
      try {
        if (`baseUrl1_${props.projectId}` in baseUrlData) {
          baseUrl1BearerToken =
            baseUrlData[`baseUrl1_${props.projectId}`].token;
        }
        if (`baseUrl2_${props.projectId}` in baseUrlData) {
          baseUrl2BearerToken =
            baseUrlData[`baseUrl2_${props.projectId}`].token;
        }
        if (`baseUrl3_${props.projectId}` in baseUrlData) {
          baseUrl3BearerToken =
            baseUrlData[`baseUrl3_${props.projectId}`].token;
        }
        if (`baseUrl4_${props.projectId}` in baseUrlData) {
          baseUrl4BearerToken =
            baseUrlData[`baseUrl4_${props.projectId}`].token;
        }
        if (`baseUrl5_${props.projectId}` in baseUrlData) {
          baseUrl5BearerToken =
            baseUrlData[`baseUrl5_${props.projectId}`].token;
        }
      } catch (e) {
        // statements
        console.log(e);
      }
    }

    let model = this;

    const componentId = model.id ? model.id : "horizontalCard";

    let {
      url,
      BaseUrl,
      APIPath,
      itemsPerSlide,
      titleFromResponse,
      subTitleFromResponse,
      API_Key,
      More_Headers,
      descFromResponse,
      imageFromResponse,
      valueForLb,
      buttonText,
    } = props;
    let itemsTarget = itemsPerSlide;
    let titleTarget = titleFromResponse;
    let subTitleTarget = subTitleFromResponse;
    let API_KeyTarget = API_Key;
    let more_HeadersTarget = More_Headers;
    let descTarget = descFromResponse;
    let imageTarget = imageFromResponse;

    localStorage.setItem("itemsPerSlide", itemsTarget);

    // const urlTrait = model.getTrait("url");
    // // console.log("urlTrait", urlTrait);
    // const url = urlTrait.attributes.value;

    // const itemsPerPageTrait = model.getTrait("itemsPerSlide");
    // const itemsTarget = itemsPerPageTrait.attributes.value;

    // const cardTitleFromResponse = model.getTrait("titleFromResponse");
    // const titleTarget = cardTitleFromResponse.attributes.value;

    // const cardSubTitleFromResponse = model.getTrait(
    //   "subTitleFromResponse"
    // );
    // const subTitleTarget = cardSubTitleFromResponse.attributes.value;

    // const cardAPI_Key = model.getTrait("API_Key");
    // const API_KeyTarget = cardAPI_Key.attributes.value;

    // const more_Headers = model.getTrait("More_Headers");
    // const more_HeadersTarget = more_Headers.attributes.value;

    // const cardDescFromResponse = model.getTrait("descFromResponse");
    // const descTarget = cardDescFromResponse.attributes.value;

    // const cardImageFromResponse = model.getTrait("imageFromResponse");
    // const imageTarget = cardImageFromResponse.attributes.value;

    let countItems;

    if (itemsTarget) {
      countItems = itemsTarget;
    } else {
      countItems = 3;
    }

    console.log(
      "url, titleTarget, subTitleTarget, descTarget, imageTarget, itemsTarget, API_KeyTarget, buttonText, valueForLb",
      url,
      titleTarget,
      subTitleTarget,
      descTarget,
      imageTarget,
      itemsTarget,
      API_KeyTarget,
      buttonText,
      valueForLb
    );

    // when only api is provided
    if (
      url &&
      !BaseUrl &&
      !APIPath &&
      titleTarget &&
      subTitleTarget &&
      descTarget &&
      imageTarget &&
      itemsTarget &&
      API_KeyTarget &&
      valueForLb &&
      buttonText
    ) {
      try {
        var options = {};
        console.log("API_KeyTarget", API_KeyTarget);

        // if (API_KeyTarget) {
        //   options.headers = {
        //     "Content-type": "application/json",
        //     Authorization: `Bearer ${API_KeyTarget}`,
        //   };
        // }

        if (
          more_HeadersTarget &&
          more_HeadersTarget.length > 0 &&
          API_KeyTarget &&
          API_KeyTarget.length > 0
        ) {
          console.log("more_HeadersTarget", more_HeadersTarget);
          options.headers = {
            "Content-type": "application/json",
            Authorization: `Bearer ${API_KeyTarget}`,
          };

          const additionalHeaders = more_HeadersTarget.split(",");
          console.log("additionalHeaders", additionalHeaders);

          additionalHeaders.forEach((header) => {
            const [headerName, headerValue] = header
              .split(":")
              .map((str) => str.trim());
            if (
              headerName &&
              headerName.length > 0 &&
              headerValue &&
              headerValue.length > 0
            ) {
              options.headers[headerName] = headerValue;
            }
          });
          console.log(
            "options.headers , more headers + api ",
            JSON.stringify(options.headers)
          );
        } else if (more_HeadersTarget && more_HeadersTarget.length > 0) {
          const additionalHeaders = more_HeadersTarget.split(",");

          additionalHeaders.forEach((header) => {
            const [headerName, headerValue] = header
              .split(":")
              .map((str) => str.trim());
            options.headers[headerName] = headerValue;
          });
          console.log(
            "options.headers only more headers",
            JSON.stringify(options.headers)
          );
        } else if (API_KeyTarget) {
          options.headers = {
            "Content-type": "application/json",
            Authorization: `Bearer ${API_KeyTarget}`,
          };
        }

        localStorage.setItem("API_UPDATE_3D_MODEL", url);
        const response = await fetch(url, options);
        const responseData = await response.json();
        if (
          Array.isArray(responseData) &&
          responseData.length > 0 &&
          typeof responseData[0] === "object"
        ) {
          // console.log("component", component);
          // console.log("responseData", responseData);
          // console.log("titleTarget", titleTarget);
          // console.log("descTarget", descTarget);
          // console.log("imageTarget", imageTarget);
          // console.log("itemsTarget", itemsTarget);
          localStorage.setItem(
            "cardCarouselDataForSlider",
            JSON.stringify(responseData)
          );

          localStorage.setItem("cardCarouselSliderCount", JSON.stringify(0));

          let cardElementWrapper = model.getElementsByClassName(
            "slidesWrapperParent"
          )[0];
          let oneItemWidth = 100 / countItems;
          cardElementWrapper.innerHTML = "";
          console.log("+cardElementWrapper+", cardElementWrapper);

          let progressbar = model.querySelector(
            "[name='customCardProgressBar']"
          );
          console.log("+progressbar+", progressbar);

          let percentProgress =
            (Number(countItems) / responseData.length) * 100;

          if (progressbar) {
            progressbar.innerHTML = "";
            let percentProgressDiv = document.createElement("div");
            percentProgressDiv.style.width = "100%";
            percentProgressDiv.style.width = "10px";
            percentProgressDiv.style.position = "relative";

            let pTag1 = document.createElement("p");
            pTag1.style.width = `${percentProgress}%`;
            pTag1.style.backgroundColor = "#06b6d4";
            pTag1.style.borderRadius = "12px";
            pTag1.style.zIndex = "3";
            pTag1.style.height = "10px";
            pTag1.style.position = "relative";

            let pTag2 = document.createElement("p");
            pTag2.style.top = `0`;
            pTag2.style.right = "10px";
            pTag2.style.fontSize = "10px";
            pTag2.style.width = "100%";
            pTag2.style.display = "flex";
            pTag2.style.justifyContent = "center";
            pTag2.style.alignItems = "center";
            pTag2.style.height = "100%";
            pTag2.style.zIndex = "4";
            pTag2.style.position = "absolute";
            pTag2.style.innerHTML = `${percentProgress}%`;

            percentProgressDiv.appendChild(pTag1);
            percentProgressDiv.appendChild(pTag2);

            progressbar.appendChild(percentProgressDiv);
          }

          responseData.forEach((rowData, index) => {
            // console.log("rowData", rowData);
            // console.log("rowData1", rowData[`${imageTarget}`]);

            let ionCard = document.createElement("ion-card");
            ionCard.setAttribute("data-gjs-type", "default");
            ionCard.setAttribute("card_id", `${rowData._id}`);
            ionCard.setAttribute("name", `ionCard`);
            ionCard.setAttribute("class", `ionCardWrapper`);
            ionCard.style.position = "relative";
            ionCard.style.zIndex = "2";
            ionCard.style.width = `${oneItemWidth}%`;
            ionCard.style.padding = "0.5rem";
            ionCard.style.boxShadow =
              "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px";

            let cardImage = document.createElement("img");
            cardImage.setAttribute("data-gjs-type", "image");
            cardImage.setAttribute("src", `${rowData[`${imageTarget}`]}`);
            cardImage.setAttribute("alt", `Silhouette of mountains`);
            cardImage.style.width = "100%";
            cardImage.style.height = "350px";

            ionCard.appendChild(cardImage);

            let cardTitle = document.createElement("div");
            cardTitle.setAttribute("data-gjs-type", "default");
            Object.assign(cardTitle.style, {
              "gap-y": "10px",
              "padding-top": "5px",
              "padding-bottom": "5px",
            });

            let cardTitleText = document.createElement("p");
            cardTitleText.setAttribute("data-gjs-type", "text");
            Object.assign(cardTitleText.style, {
              "font-size": "18px",
              "font-weight": "600",
              width: "100%",
            });
            cardTitleText.textContent = `${rowData[`${titleTarget}`].substring(
              0,
              10
            )}`;

            cardTitle.appendChild(cardTitleText);

            let cardSubTitleText = document.createElement("p");
            cardSubTitleText.setAttribute("data-gjs-type", "text");
            Object.assign(cardSubTitleText.style, {
              "font-size": "12px",
              "font-weight": "400",
              width: "100%",
            });
            cardSubTitleText.textContent = `${rowData[
              `${subTitleTarget}`
            ].substring(0, 20)}`;

            cardTitle.appendChild(cardSubTitleText);

            let cardDescText = document.createElement("p");
            cardDescText.setAttribute("data-gjs-type", "text");
            Object.assign(cardDescText.style, {
              "font-size": "12px",
              "font-weight": "400",
              width: "100%",
            });
            cardDescText.textContent = `${String(rowData[descTarget]).substring(
              0,
              20
            )}`;

            cardTitle.appendChild(cardDescText);

            ionCard.appendChild(cardTitle);

            let cardBtnContainer = document.createElement("div");
            cardBtnContainer.setAttribute("data-gjs-type", "default");
            Object.assign(cardBtnContainer.style, {
              width: "100%",
              "padding-top": "20px",
              "padding-bottom": "20px",
              display: "flex",
              "justify-content": "start",
              "align-items": "center",
            });

            let viewMdlBtn = document.createElement("button");
            viewMdlBtn.setAttribute("data-gjs-type", "default");
            viewMdlBtn.setAttribute("model_id", `${rowData._id}`);
            viewMdlBtn.setAttribute("class", "viewModelBtn");
            Object.assign(viewMdlBtn.style, {
              width: "50%",
              "padding-top": "8px",
              "padding-bottom": "8px",
              "background-color": "#179C7D",
              color: "white",
              "border-radius": "10px",
            });
            viewMdlBtn.innerHTML = "Open Model";

            cardBtnContainer.appendChild(viewMdlBtn);

            let editCardBtn = document.createElement("button");
            editCardBtn.setAttribute("data-gjs-type", "default");
            editCardBtn.setAttribute("model_id", `${rowData[valueForLb]}`);
            editCardBtn.setAttribute("carouselId", `${componentId}`);
            editCardBtn.classList.add("editCardBtn", "select-btn");
            Object.assign(editCardBtn.style, {
              width: "50%",
              "padding-top": "8px",
              "padding-bottom": "8px",
              "background-color": "#179C7D",
              color: "white",
              "border-radius": "10px",
              "margin-left": "10px",
            });
            editCardBtn.innerHTML = `${buttonText}`;

            cardBtnContainer.appendChild(editCardBtn);

            ionCard.appendChild(cardBtnContainer);

            cardElementWrapper.appendChild(ionCard);
          });
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    } else if (
      !url &&
      BaseUrl &&
      APIPath &&
      titleTarget &&
      subTitleTarget &&
      descTarget &&
      imageTarget &&
      itemsTarget &&
      valueForLb &&
      buttonText
    ) {
      try {
        let options = {};

        let url, baseUrlBearerToken;
        if (BaseUrl === "baseUrl1") {
          url = props.options.baseUrl1 + APIPath;
          baseUrlBearerToken = baseUrl1BearerToken;
        } else if (BaseUrl === "baseUrl2") {
          url = props.options.baseUrl2 + APIPath;
          baseUrlBearerToken = baseUrl2BearerToken;
        } else if (BaseUrl === "baseUrl3") {
          url = props.options.baseUrl3 + APIPath;
          baseUrlBearerToken = baseUrl3BearerToken;
        } else if (BaseUrl === "baseUrl4") {
          url = props.options.baseUrl4 + APIPath;
          baseUrlBearerToken = baseUrl4BearerToken;
        } else if (BaseUrl === "baseUrl5") {
          url = props.options.baseUrl5 + APIPath;
          baseUrlBearerToken = baseUrl5BearerToken;
        }
        console.log("url, baseUrlBearerToken", url, baseUrlBearerToken);

        // if (API_KeyTarget) {
        //   options.headers = {
        //     "Content-type": "application/json",
        //     Authorization: `Bearer ${API_KeyTarget}`,
        //   };
        // }

        if (
          more_HeadersTarget &&
          more_HeadersTarget.length > 0 &&
          baseUrlBearerToken &&
          baseUrlBearerToken.length > 0
        ) {
          console.log("more_HeadersTarget", more_HeadersTarget);
          options.headers = {
            "Content-type": "application/json",
            Authorization: `Bearer ${baseUrlBearerToken}`,
          };

          const additionalHeaders = more_HeadersTarget.split(",");
          console.log("additionalHeaders", additionalHeaders);

          additionalHeaders.forEach((header) => {
            const [headerName, headerValue] = header
              .split(":")
              .map((str) => str.trim());
            if (
              headerName &&
              headerName.length > 0 &&
              headerValue &&
              headerValue.length > 0
            ) {
              options.headers[headerName] = headerValue;
            }
          });
          console.log(
            "options.headers , more headers + api ",
            JSON.stringify(options.headers)
          );
        } else if (more_HeadersTarget && more_HeadersTarget.length > 0) {
          const additionalHeaders = more_HeadersTarget.split(",");

          additionalHeaders.forEach((header) => {
            const [headerName, headerValue] = header
              .split(":")
              .map((str) => str.trim());
            options.headers[headerName] = headerValue;
          });
          console.log(
            "options.headers only more headers",
            JSON.stringify(options.headers)
          );
        } else if (baseUrlBearerToken) {
          options.headers = {
            "Content-type": "application/json",
            Authorization: `Bearer ${baseUrlBearerToken}`,
          };
        }

        localStorage.setItem("API_UPDATE_3D_MODEL", url);
        const response = await fetch(url, options);
        const responseData = await response.json();
        if (
          Array.isArray(responseData) &&
          responseData.length > 0 &&
          typeof responseData[0] === "object"
        ) {
          // console.log("component", component);
          // console.log("responseData", responseData);
          // console.log("titleTarget", titleTarget);
          // console.log("descTarget", descTarget);
          // console.log("imageTarget", imageTarget);
          // console.log("itemsTarget", itemsTarget);
          localStorage.setItem(
            "cardCarouselDataForSlider",
            JSON.stringify(responseData)
          );

          localStorage.setItem("cardCarouselSliderCount", JSON.stringify(0));

          let cardElementWrapper = model.getElementsByClassName(
            "slidesWrapperParent"
          )[0];
          let oneItemWidth = 100 / countItems;
          cardElementWrapper.innerHTML = "";

          let progressbar = model.querySelector(
            "[name='customCardProgressBar']"
          );
          console.log("+progressbar+", progressbar);

          let percentProgress =
            (Number(countItems) / responseData.length) * 100;

          if (progressbar) {
            progressbar.innerHTML = "";
            let percentProgressDiv = document.createElement("div");
            percentProgressDiv.style.width = "100%";
            percentProgressDiv.style.width = "10px";
            percentProgressDiv.style.position = "relative";

            let pTag1 = document.createElement("p");
            pTag1.style.width = `${percentProgress}%`;
            pTag1.style.backgroundColor = "#06b6d4";
            pTag1.style.borderRadius = "12px";
            pTag1.style.zIndex = "3";
            pTag1.style.height = "10px";
            pTag1.style.position = "relative";

            let pTag2 = document.createElement("p");
            pTag2.style.top = `0`;
            pTag2.style.right = "10px";
            pTag2.style.fontSize = "10px";
            pTag2.style.width = "100%";
            pTag2.style.display = "flex";
            pTag2.style.justifyContent = "center";
            pTag2.style.alignItems = "center";
            pTag2.style.height = "100%";
            pTag2.style.zIndex = "4";
            pTag2.style.position = "absolute";
            pTag2.style.innerHTML = `${percentProgress}%`;

            percentProgressDiv.appendChild(pTag1);
            percentProgressDiv.appendChild(pTag2);

            progressbar.appendChild(percentProgressDiv);
          }

          responseData.forEach((rowData, index) => {
            // console.log("rowData", rowData);
            // console.log("rowData1", rowData[`${imageTarget}`]);

            let ionCard = document.createElement("ion-card");
            ionCard.setAttribute("data-gjs-type", "default");
            ionCard.setAttribute("card_id", `${rowData._id}`);
            ionCard.setAttribute("name", `ionCard`);
            ionCard.setAttribute("class", `ionCardWrapper`);
            ionCard.style.position = "relative";
            ionCard.style.zIndex = "2";
            ionCard.style.width = `${oneItemWidth}%`;
            ionCard.style.padding = "0.5rem";
            ionCard.style.boxShadow =
              "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px";

            let cardImage = document.createElement("img");
            cardImage.setAttribute("data-gjs-type", "image");
            cardImage.setAttribute("src", `${rowData[`${imageTarget}`]}`);
            cardImage.setAttribute("alt", `Silhouette of mountains`);
            cardImage.style.width = "100%";
            cardImage.style.height = "350px";

            ionCard.appendChild(cardImage);

            let cardTitle = document.createElement("div");
            cardTitle.setAttribute("data-gjs-type", "default");
            Object.assign(cardTitle.style, {
              "gap-y": "10px",
              "padding-top": "5px",
              "padding-bottom": "5px",
            });

            let cardTitleText = document.createElement("p");
            cardTitleText.setAttribute("data-gjs-type", "text");
            Object.assign(cardTitleText.style, {
              "font-size": "18px",
              "font-weight": "600",
              width: "100%",
            });
            cardTitleText.textContent = `${rowData[`${titleTarget}`].substring(
              0,
              10
            )}`;

            cardTitle.appendChild(cardTitleText);

            let cardSubTitleText = document.createElement("p");
            cardSubTitleText.setAttribute("data-gjs-type", "text");
            Object.assign(cardSubTitleText.style, {
              "font-size": "12px",
              "font-weight": "400",
              width: "100%",
            });
            cardSubTitleText.textContent = `${rowData[
              `${subTitleTarget}`
            ].substring(0, 20)}`;

            cardTitle.appendChild(cardSubTitleText);

            let cardDescText = document.createElement("p");
            cardDescText.setAttribute("data-gjs-type", "text");
            Object.assign(cardDescText.style, {
              "font-size": "12px",
              "font-weight": "400",
              width: "100%",
            });
            cardDescText.textContent = `${String(rowData[descTarget]).substring(
              0,
              20
            )}`;

            cardTitle.appendChild(cardDescText);

            ionCard.appendChild(cardTitle);

            let cardBtnContainer = document.createElement("div");
            cardBtnContainer.setAttribute("data-gjs-type", "default");
            Object.assign(cardBtnContainer.style, {
              width: "100%",
              "padding-top": "20px",
              "padding-bottom": "20px",
              display: "flex",
              "justify-content": "start",
              "align-items": "center",
            });

            let viewMdlBtn = document.createElement("button");
            viewMdlBtn.setAttribute("data-gjs-type", "default");
            viewMdlBtn.setAttribute("model_id", `${rowData._id}`);
            viewMdlBtn.setAttribute("class", "viewModelBtn");
            Object.assign(viewMdlBtn.style, {
              width: "50%",
              "padding-top": "8px",
              "padding-bottom": "8px",
              "background-color": "#179C7D",
              color: "white",
              "border-radius": "10px",
            });
            viewMdlBtn.innerHTML = "Open Model";

            cardBtnContainer.appendChild(viewMdlBtn);

            let editCardBtn = document.createElement("button");
            editCardBtn.setAttribute("data-gjs-type", "default");
            editCardBtn.setAttribute("model_id", `${rowData[valueForLb]}`);
            editCardBtn.setAttribute("carouselId", `${componentId}`);
            editCardBtn.classList.add("editCardBtn", "select-btn");
            Object.assign(editCardBtn.style, {
              width: "50%",
              "padding-top": "8px",
              "padding-bottom": "8px",
              "background-color": "#179C7D",
              color: "white",
              "border-radius": "10px",
              "margin-left": "10px",
            });
            editCardBtn.innerHTML = `${buttonText}`;

            cardBtnContainer.appendChild(editCardBtn);

            ionCard.appendChild(cardBtnContainer);

            cardElementWrapper.appendChild(ionCard);
          });
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    } else {
      return;
    }
  };

  editor.DomComponents.addType("customCardWrapperFH_new", {
    model: {
      defaults: {
        tagName: "div",
        type: "default",
        script: plotCard,

        options: {
          baseUrl1: opts.baseUrl1,
          baseUrl2: opts.baseUrl2,
          baseUrl3: opts.baseUrl3,
          baseUrl4: opts.baseUrl4,
          baseUrl5: opts.baseUrl5,
        },

        projectId: localStorage.getItem("project_id")
          ? localStorage.getItem("project_id")
          : "",

        traits: [
          {
            type: "number",
            label: "Items per Slide",
            name: "itemsPerSlide",
            changeProp: true,
          },
          {
            type: "text",
            label: "URL",
            name: "url",
            changeProp: true,
          },
          {
            type: "select",
            name: "BaseUrl",
            label: "Base URL",
            options: [
              { id: "baseUrl1", name: "Base URL #1" },
              { id: "baseUrl2", name: "Base URL #2" },
              { id: "baseUrl3", name: "Base URL #3" },
              { id: "baseUrl4", name: "Base URL #4" },
              { id: "baseUrl5", name: "Base URL #5" },
              { id: "null", name: "No Base URL" },
            ],
            changeProp: true,
          },
          {
            type: "text",
            name: "APIPath",
            label: "API Path",
            placeholder: "Don't start with '/'",
            changeProp: true,
          },
          {
            type: "text",
            label: "image from response",
            name: "imageFromResponse",
            changeProp: true,
          },
          {
            type: "text",
            label: "Title from response",
            name: "titleFromResponse",
            changeProp: true,
          },
          {
            type: "text",
            label: "Sub-Title from response",
            name: "subTitleFromResponse",
            changeProp: true,
          },
          {
            type: "text",
            label: "Desc from response",
            name: "descFromResponse",
            changeProp: true,
          },

          {
            type: "text",
            name: "Username",
            label: "Username",
            changeProp: true,
          },
          {
            type: "text",
            name: "Password",
            label: "Password",
            changeProp: true,
          },
          {
            type: "text",
            name: "API_Key",
            label: "API Key",
            changeProp: true,
          },
          {
            type: "text",
            name: "More_Headers",
            label: "More Headers",
            changeProp: true,
          },
          {
            type: "text",
            name: "TargetKey",
            label: "Key To Plot",
            changeProp: true,
          },
          {
            type: "number",
            label: "Page Size",
            name: "pageSize",
            changeProp: true,
          },
          {
            type: "text",
            label: "Value To Set",
            name: "valueForLb",
            changeProp: true,
          },
          {
            type: "text",
            label: "Button Text",
            name: "buttonText",
            changeProp: true,
          },
        ],

        "script-props": [
          "url",
          "BaseUrl",
          "APIPath",
          "Username",
          "Password",
          "API_Key",
          "More_Headers",
          "TargetKey",
          "itemsPerSlide",
          "imageFromResponse",
          "titleFromResponse",
          "subTitleFromResponse",
          "descFromResponse",
          "valueForLb",
          "buttonText",
          "options",
          "projectId",
        ],
        components: [
          // TOP PANEL GOES HERE :::::::::::::
          {
            type: "customCardParentFH",
            tagName: "div",
            style: {
              width: "95vw",
              "max-width": "95vw",
              position: "relative",
              "overflow-x": "hidden",
            },
          },
        ],
      },
    },
    view: {
      async onRender({ el, model }) {
        // console.log("model", model);
        let url = model.get("url");
        // console.log("url", url);
        // console.log("el", el);

        if (el.getAttribute("data-gjs-type") === "customCardWrapperFH_new") {
          // const componentId = this.getId();
          const componentId = model.getId();
          if (componentId) {
            this.plotCard(model, componentId);
          } else {
            this.plotCard(model, "horizontalCard");
          }
          //this.plotCard(model, "horizontalCard");
        }
      },

      async plotCard(model, componentId) {
        console.log("recalling plotCard", model);

        let {
          url,
          BaseUrl,
          APIPath,
          itemsPerSlide,
          titleFromResponse,
          subTitleFromResponse,
          API_Key,
          More_Headers,
          descFromResponse,
          imageFromResponse,
          valueForLb,
          buttonText,
        } = model.props();
        let itemsTarget = itemsPerSlide;
        let titleTarget = titleFromResponse;
        let subTitleTarget = subTitleFromResponse;
        let API_KeyTarget = API_Key;
        let more_HeadersTarget = More_Headers;
        let descTarget = descFromResponse;
        let imageTarget = imageFromResponse;

        // const urlTrait = model.getTrait("url");
        // // console.log("urlTrait", urlTrait);
        // const url = urlTrait.attributes.value;

        // const itemsPerPageTrait = model.getTrait("itemsPerSlide");
        // const itemsTarget = itemsPerPageTrait.attributes.value;

        // const cardTitleFromResponse = model.getTrait("titleFromResponse");
        // const titleTarget = cardTitleFromResponse.attributes.value;

        // const cardSubTitleFromResponse = model.getTrait(
        //   "subTitleFromResponse"
        // );
        // const subTitleTarget = cardSubTitleFromResponse.attributes.value;

        // const cardAPI_Key = model.getTrait("API_Key");
        // const API_KeyTarget = cardAPI_Key.attributes.value;

        // const more_Headers = model.getTrait("More_Headers");
        // const more_HeadersTarget = more_Headers.attributes.value;

        // const cardDescFromResponse = model.getTrait("descFromResponse");
        // const descTarget = cardDescFromResponse.attributes.value;

        // const cardImageFromResponse = model.getTrait("imageFromResponse");
        // const imageTarget = cardImageFromResponse.attributes.value;

        let countItems;

        if (itemsTarget) {
          countItems = itemsTarget;
        } else {
          countItems = 3;
        }

        console.log(
          "url, titleTarget, subTitleTarget, descTarget, imageTarget, itemsTarget, API_KeyTarget, buttonText, valueForLb",
          url,
          titleTarget,
          subTitleTarget,
          descTarget,
          imageTarget,
          itemsTarget,
          API_KeyTarget,
          buttonText,
          valueForLb
        );

        // when only api is provided
        if (
          url &&
          !BaseUrl &&
          !APIPath &&
          titleTarget &&
          subTitleTarget &&
          descTarget &&
          imageTarget &&
          itemsTarget &&
          API_KeyTarget &&
          valueForLb &&
          buttonText
        ) {
          try {
            var options = {};
            console.log("API_KeyTarget", API_KeyTarget);

            // if (API_KeyTarget) {
            //   options.headers = {
            //     "Content-type": "application/json",
            //     Authorization: `Bearer ${API_KeyTarget}`,
            //   };
            // }

            if (
              more_HeadersTarget &&
              more_HeadersTarget.length > 0 &&
              API_KeyTarget &&
              API_KeyTarget.length > 0
            ) {
              console.log("more_HeadersTarget", more_HeadersTarget);
              options.headers = {
                "Content-type": "application/json",
                Authorization: `Bearer ${API_KeyTarget}`,
              };

              const additionalHeaders = more_HeadersTarget.split(",");
              console.log("additionalHeaders", additionalHeaders);

              additionalHeaders.forEach((header) => {
                const [headerName, headerValue] = header
                  .split(":")
                  .map((str) => str.trim());
                if (
                  headerName &&
                  headerName.length > 0 &&
                  headerValue &&
                  headerValue.length > 0
                ) {
                  options.headers[headerName] = headerValue;
                }
              });
              console.log(
                "options.headers , more headers + api ",
                JSON.stringify(options.headers)
              );
            } else if (more_HeadersTarget && more_HeadersTarget.length > 0) {
              const additionalHeaders = more_HeadersTarget.split(",");

              additionalHeaders.forEach((header) => {
                const [headerName, headerValue] = header
                  .split(":")
                  .map((str) => str.trim());
                options.headers[headerName] = headerValue;
              });
              console.log(
                "options.headers only more headers",
                JSON.stringify(options.headers)
              );
            } else if (API_KeyTarget) {
              options.headers = {
                "Content-type": "application/json",
                Authorization: `Bearer ${API_KeyTarget}`,
              };
            }

            localStorage.setItem("API_UPDATE_3D_MODEL", url);
            const response = await fetch(url, options);
            const responseData = await response.json();
            if (
              Array.isArray(responseData) &&
              responseData.length > 0 &&
              typeof responseData[0] === "object"
            ) {
              // console.log("component", component);
              // console.log("responseData", responseData);
              // console.log("titleTarget", titleTarget);
              // console.log("descTarget", descTarget);
              // console.log("imageTarget", imageTarget);
              // console.log("itemsTarget", itemsTarget);
              localStorage.setItem(
                "cardCarouselDataForSlider",
                JSON.stringify(responseData)
              );

              localStorage.setItem(
                "cardCarouselSliderCount",
                JSON.stringify(0)
              );

              let cardElementWrapper = model.findType(
                "customCardElementWrapperFH"
              )[0];
              let oneItemWidth = 100 / countItems;
              cardElementWrapper.components().reset();

              let progressbar = model
                .components()
                .models[0].components()
                .models.find(
                  (x) =>
                    x.attributes.attributes.name === "customCardProgressBar"
                );

              let percentProgress =
                (Number(countItems) / responseData.length) * 100;

              if (progressbar) {
                progressbar.components().reset();
                progressbar.components().add({
                  tagName: "div",
                  style: {
                    width: "100%",
                    height: "10px",
                    position: "relative",
                  },
                  components: [
                    {
                      tagName: "p",
                      style: {
                        width: `${percentProgress}%`,
                        "background-color": "#06b6d4",
                        "border-radius": "12px",
                        "z-index": "3",
                        height: "10px",
                        position: "relative",
                      },
                    },
                    {
                      tagName: "p",
                      style: {
                        position: "absolute",
                        top: "0",
                        right: "10px",
                        "font-size": "10px",
                        width: "100%",
                        display: "flex",
                        "justify-content": "center",
                        "align-items": "center",
                        height: "100%",
                        "z-index": "4",
                      },
                      content: `${percentProgress}%`,
                    },
                  ],
                });
              }

              responseData.forEach((rowData, index) => {
                // console.log("rowData", rowData);
                // console.log("rowData1", rowData[`${imageTarget}`]);

                cardElementWrapper.components().add({
                  tagName: "ion-card",
                  type: "default",
                  attributes: {
                    card_id: `${rowData._id}`,
                    name: "ionCard",
                    class: "ionCardWrapper",
                  },
                  style: {
                    position: "relative",
                    "z-index": "2",
                    width: `${oneItemWidth}%`,
                    padding: "0.5rem",
                    "box-shadow":
                      "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
                  },
                  components: [
                    {
                      type: "image",
                      tagName: "img",
                      attributes: {
                        src: `${rowData[`${imageTarget}`]}`,
                        alt: "Silhouette of mountains",
                      },
                      style: {
                        width: "100%",
                        height: "350px",
                      },
                    },
                    {
                      type: "default",
                      tagName: "div",
                      style: {
                        "gap-y": "10px",
                        "padding-top": "5px",
                        "padding-bottom": "5px",
                      },
                      components: [
                        {
                          type: "text",
                          content: `${rowData[`${titleTarget}`].substring(
                            0,
                            10
                          )}`,
                          style: {
                            "font-size": "18px",
                            "font-weight": "600",
                            width: "100%",
                          },
                        },
                        {
                          type: "text",
                          content: `${rowData[`${subTitleTarget}`].substring(
                            0,
                            20
                          )}`,
                          style: {
                            "font-size": "12px",
                            "font-weight": "400",
                            width: "100%",
                          },
                        },
                        {
                          type: "text",
                          content: `${String(rowData[descTarget]).substring(
                            0,
                            20
                          )}`,
                          style: {
                            "font-size": "12px",
                            "font-weight": "400",
                            width: "100%",
                          },
                        },
                      ],
                    },
                    {
                      type: "default",
                      tagName: "div",
                      style: {
                        width: "100%",
                        "padding-top": "20px",
                        "padding-bottom": "20px",
                        display: "flex",
                        "justify-content": "start",
                        "align-items": "center",
                      },

                      components: [
                        {
                          type: "default",
                          tagName: "button",
                          style: {
                            width: "50%",
                            "padding-top": "8px",
                            "padding-bottom": "8px",
                            "background-color": "#179C7D",
                            color: "white",
                            "border-radius": "10px",
                          },
                          attributes: {
                            model_id: `${rowData._id}`,
                            class: "viewModelBtn",
                          },
                          content: `Open Model`,
                        },
                        {
                          type: "default",
                          tagName: "button",
                          style: {
                            width: "50%",
                            "padding-top": "8px",
                            "padding-bottom": "8px",
                            "background-color": "#179C7D",
                            color: "white",
                            "border-radius": "10px",
                            "margin-left": "10px",
                          },
                          // script: btnClickHandler,
                          attributes: {
                            model_id: `${rowData[valueForLb]}`,
                            carouselId: `${componentId}`,
                            class: "editCardBtn select-btn",
                          },
                          content: `${buttonText}`,
                        },
                      ],
                    },
                  ],
                });
              });
            }
          } catch (error) {
            console.error("Error fetching data:", error);
          }
        } else if (
          !url &&
          BaseUrl &&
          APIPath &&
          titleTarget &&
          subTitleTarget &&
          descTarget &&
          imageTarget &&
          itemsTarget &&
          !API_KeyTarget &&
          valueForLb &&
          buttonText
        ) {
          try {
            var options = {};

            let url, baseUrlBearerToken;
            if (BaseUrl === "baseUrl1") {
              url = opts.baseUrl1 + APIPath;
              baseUrlBearerToken = opts.baseUrl1BearerToken;
            } else if (BaseUrl === "baseUrl2") {
              url = opts.baseUrl2 + APIPath;
              baseUrlBearerToken = opts.baseUrl2BearerToken;
            } else if (BaseUrl === "baseUrl3") {
              url = opts.baseUrl3 + APIPath;
              baseUrlBearerToken = opts.baseUrl3BearerToken;
            } else if (BaseUrl === "baseUrl4") {
              url = opts.baseUrl4 + APIPath;
              baseUrlBearerToken = opts.baseUrl4BearerToken;
            } else if (BaseUrl === "baseUrl5") {
              url = opts.baseUrl5 + APIPath;
              baseUrlBearerToken = opts.baseUrl5BearerToken;
            }
            console.log("url, baseUrlBearerToken", url, baseUrlBearerToken);

            // if (API_KeyTarget) {
            //   options.headers = {
            //     "Content-type": "application/json",
            //     Authorization: `Bearer ${API_KeyTarget}`,
            //   };
            // }

            if (
              more_HeadersTarget &&
              more_HeadersTarget.length > 0 &&
              baseUrlBearerToken &&
              baseUrlBearerToken.length > 0
            ) {
              console.log("more_HeadersTarget", more_HeadersTarget);
              options.headers = {
                "Content-type": "application/json",
                Authorization: `Bearer ${baseUrlBearerToken}`,
              };

              const additionalHeaders = more_HeadersTarget.split(",");
              console.log("additionalHeaders", additionalHeaders);

              additionalHeaders.forEach((header) => {
                const [headerName, headerValue] = header
                  .split(":")
                  .map((str) => str.trim());
                if (
                  headerName &&
                  headerName.length > 0 &&
                  headerValue &&
                  headerValue.length > 0
                ) {
                  options.headers[headerName] = headerValue;
                }
              });
              console.log(
                "options.headers , more headers + api ",
                JSON.stringify(options.headers)
              );
            } else if (more_HeadersTarget && more_HeadersTarget.length > 0) {
              const additionalHeaders = more_HeadersTarget.split(",");

              additionalHeaders.forEach((header) => {
                const [headerName, headerValue] = header
                  .split(":")
                  .map((str) => str.trim());
                options.headers[headerName] = headerValue;
              });
              console.log(
                "options.headers only more headers",
                JSON.stringify(options.headers)
              );
            } else if (baseUrlBearerToken) {
              options.headers = {
                "Content-type": "application/json",
                Authorization: `Bearer ${baseUrlBearerToken}`,
              };
            }

            localStorage.setItem("API_UPDATE_3D_MODEL", url);
            const response = await fetch(url, options);
            const responseData = await response.json();
            if (
              Array.isArray(responseData) &&
              responseData.length > 0 &&
              typeof responseData[0] === "object"
            ) {
              // console.log("component", component);
              // console.log("responseData", responseData);
              // console.log("titleTarget", titleTarget);
              // console.log("descTarget", descTarget);
              // console.log("imageTarget", imageTarget);
              // console.log("itemsTarget", itemsTarget);
              localStorage.setItem(
                "cardCarouselDataForSlider",
                JSON.stringify(responseData)
              );

              localStorage.setItem(
                "cardCarouselSliderCount",
                JSON.stringify(0)
              );

              let cardElementWrapper = model.findType(
                "customCardElementWrapperFH"
              )[0];
              let oneItemWidth = 100 / countItems;
              cardElementWrapper.components().reset();

              let progressbar = model
                .components()
                .models[0].components()
                .models.find(
                  (x) =>
                    x.attributes.attributes.name === "customCardProgressBar"
                );

              let percentProgress =
                (Number(countItems) / responseData.length) * 100;

              if (progressbar) {
                progressbar.components().reset();
                progressbar.components().add({
                  tagName: "div",
                  style: {
                    width: "100%",
                    height: "10px",
                    position: "relative",
                  },
                  components: [
                    {
                      tagName: "p",
                      style: {
                        width: `${percentProgress}%`,
                        "background-color": "#06b6d4",
                        "border-radius": "12px",
                        "z-index": "3",
                        height: "10px",
                        position: "relative",
                      },
                    },
                    {
                      tagName: "p",
                      style: {
                        position: "absolute",
                        top: "0",
                        right: "10px",
                        "font-size": "10px",
                        width: "100%",
                        display: "flex",
                        "justify-content": "center",
                        "align-items": "center",
                        height: "100%",
                        "z-index": "4",
                      },
                      content: `${percentProgress}%`,
                    },
                  ],
                });
              }

              responseData.forEach((rowData, index) => {
                // console.log("rowData", rowData);
                // console.log("rowData1", rowData[`${imageTarget}`]);

                cardElementWrapper.components().add({
                  tagName: "ion-card",
                  type: "default",
                  attributes: {
                    card_id: `${rowData._id}`,
                    name: "ionCard",
                    class: "ionCardWrapper",
                  },
                  style: {
                    position: "relative",
                    "z-index": "2",
                    width: `${oneItemWidth}%`,
                    padding: "0.5rem",
                    "box-shadow":
                      "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
                  },
                  components: [
                    {
                      type: "image",
                      tagName: "img",
                      attributes: {
                        src: `${rowData[`${imageTarget}`]}`,
                        alt: "Silhouette of mountains",
                      },
                      style: {
                        width: "100%",
                        height: "350px",
                      },
                    },
                    {
                      type: "default",
                      tagName: "div",
                      style: {
                        "gap-y": "10px",
                        "padding-top": "5px",
                        "padding-bottom": "5px",
                      },
                      components: [
                        {
                          type: "text",
                          content: `${rowData[`${titleTarget}`].substring(
                            0,
                            10
                          )}`,
                          style: {
                            "font-size": "18px",
                            "font-weight": "600",
                            width: "100%",
                          },
                        },
                        {
                          type: "text",
                          content: `${rowData[`${subTitleTarget}`].substring(
                            0,
                            20
                          )}`,
                          style: {
                            "font-size": "12px",
                            "font-weight": "400",
                            width: "100%",
                          },
                        },
                        {
                          type: "text",
                          content: `${String(rowData[descTarget]).substring(
                            0,
                            20
                          )}`,
                          style: {
                            "font-size": "12px",
                            "font-weight": "400",
                            width: "100%",
                          },
                        },
                      ],
                    },
                    {
                      type: "default",
                      tagName: "div",
                      style: {
                        width: "100%",
                        "padding-top": "20px",
                        "padding-bottom": "20px",
                        display: "flex",
                        "justify-content": "start",
                        "align-items": "center",
                      },

                      components: [
                        {
                          type: "default",
                          tagName: "button",
                          style: {
                            width: "50%",
                            "padding-top": "8px",
                            "padding-bottom": "8px",
                            "background-color": "#179C7D",
                            color: "white",
                            "border-radius": "10px",
                          },
                          attributes: {
                            model_id: `${rowData._id}`,
                            class: "viewModelBtn",
                          },
                          content: `Open Model`,
                        },
                        {
                          type: "default",
                          tagName: "button",
                          style: {
                            width: "50%",
                            "padding-top": "8px",
                            "padding-bottom": "8px",
                            "background-color": "#179C7D",
                            color: "white",
                            "border-radius": "10px",
                            "margin-left": "10px",
                          },
                          // script: btnClickHandler,
                          attributes: {
                            model_id: `${rowData[valueForLb]}`,
                            carouselId: `${componentId}`,
                            class: "editCardBtn select-btn",
                          },
                          content: `${buttonText}`,
                        },
                      ],
                    },
                  ],
                });
              });
            }
          } catch (error) {
            console.error("Error fetching data:", error);
          }
        } else {
          return;
        }
      },
    },
  });

  editor.BlockManager.add("customCardWrapperFH_new", {
    label: "Carousel FH (v2)",
    content: { type: "customCardWrapperFH_new" },
    category: "Basic Card",
    media: `<svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" viewBox="0 0 16 16">
            <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm15 2h-4v3h4V4zm0 4h-4v3h4V8zm0 4h-4v3h3a1 1 0 0 0 1-1v-2zm-5 3v-3H6v3h4zm-5 0v-3H1v2a1 1 0 0 0 1 1h3zm-4-4h4V8H1v3zm0-4h4V4H1v3zm5-3v3h4V4H6zm4 4H6v3h4V8z"/>
        </svg>`,
    select: true,
    draggable: true,
  });

  editor.on("component:update:traits", async (component) => {
    if (component && component.attributes.type === "customCardWrapperFH_new") {
      console.log("FH CARD UPDATE TRIGGERED");
      const urlTrait = component.getTrait("url");
      const componentId = component.getId();
      // console.log("urlTrait", urlTrait);
      const url = urlTrait.attributes.value;

      const itemsPerPageTrait = component.getTrait("itemsPerSlide");
      const itemsTarget = itemsPerPageTrait.attributes.value;
      localStorage.setItem("itemsPerSlide", itemsTarget);

      const cardTitleFromResponse = component.getTrait("titleFromResponse");
      const titleTarget = cardTitleFromResponse.attributes.value;

      const cardSubTitleFromResponse = component.getTrait(
        "subTitleFromResponse"
      );
      const subTitleTarget = cardSubTitleFromResponse.attributes.value;

      const cardAPI_Key = component.getTrait("API_Key");
      const API_KeyTarget = cardAPI_Key.attributes.value;

      const more_Headers = component.getTrait("More_Headers");
      const more_HeadersTarget = more_Headers.attributes.value;

      const cardDescFromResponse = component.getTrait("descFromResponse");
      const descTarget = cardDescFromResponse.attributes.value;

      const cardImageFromResponse = component.getTrait("imageFromResponse");
      const imageTarget = cardImageFromResponse.attributes.value;

      const valueForLbResponse = component.getTrait("valueForLb");
      const valueForLb = valueForLbResponse.attributes.value;

      const buttonTextResponse = component.getTrait("buttonText");
      const buttonText = buttonTextResponse.attributes.value;

      const baseUrlResponse = component.getTrait("BaseUrl");
      const BaseUrl = baseUrlResponse.attributes.value;

      const aPIPathResponse = component.getTrait("APIPath");
      const APIPath = aPIPathResponse.attributes.value;

      let countItems;

      if (itemsTarget) {
        countItems = itemsTarget;
      } else {
        countItems = 3;
      }

      // when only api is provided
      if (
        url &&
        titleTarget &&
        subTitleTarget &&
        descTarget &&
        imageTarget &&
        itemsTarget &&
        API_KeyTarget &&
        buttonText &&
        valueForLb
      ) {
        try {
          var options = {};
          console.log("API_KeyTarget", API_KeyTarget);

          // if (API_KeyTarget) {
          //   options.headers = {
          //     "Content-type": "application/json",
          //     Authorization: `Bearer ${API_KeyTarget}`,
          //   };
          // }

          if (
            more_HeadersTarget &&
            more_HeadersTarget.length > 0 &&
            API_KeyTarget &&
            API_KeyTarget.length > 0
          ) {
            console.log("more_HeadersTarget", more_HeadersTarget);
            options.headers = {
              "Content-type": "application/json",
              Authorization: `Bearer ${API_KeyTarget}`,
            };

            const additionalHeaders = more_HeadersTarget.split(",");
            console.log("additionalHeaders", additionalHeaders);

            additionalHeaders.forEach((header) => {
              const [headerName, headerValue] = header
                .split(":")
                .map((str) => str.trim());
              if (
                headerName &&
                headerName.length > 0 &&
                headerValue &&
                headerValue.length > 0
              ) {
                options.headers[headerName] = headerValue;
              }
            });
            console.log(
              "options.headers , more headers + api ",
              JSON.stringify(options.headers)
            );
          } else if (more_HeadersTarget && more_HeadersTarget.length > 0) {
            const additionalHeaders = more_HeadersTarget.split(",");

            additionalHeaders.forEach((header) => {
              const [headerName, headerValue] = header
                .split(":")
                .map((str) => str.trim());
              options.headers[headerName] = headerValue;
            });
            console.log(
              "options.headers only more headers",
              JSON.stringify(options.headers)
            );
          } else if (API_KeyTarget) {
            options.headers = {
              "Content-type": "application/json",
              Authorization: `Bearer ${API_KeyTarget}`,
            };
          }

          localStorage.setItem("API_UPDATE_3D_MODEL", url);
          const response = await fetch(url, options);
          const responseData = await response.json();
          if (
            Array.isArray(responseData) &&
            responseData.length > 0 &&
            typeof responseData[0] === "object"
          ) {
            // console.log("component", component);
            // console.log("responseData", responseData);
            // console.log("titleTarget", titleTarget);
            // console.log("descTarget", descTarget);
            // console.log("imageTarget", imageTarget);
            // console.log("itemsTarget", itemsTarget);
            localStorage.setItem(
              "cardCarouselDataForSlider",
              JSON.stringify(responseData)
            );

            localStorage.setItem("cardCarouselSliderCount", JSON.stringify(0));

            let cardElementWrapper = component.findType(
              "customCardElementWrapperFH"
            )[0];
            let oneItemWidth = 100 / countItems;
            cardElementWrapper.components().reset();

            let progressbar = component
              .components()
              .models[0].components()
              .models.find(
                (x) => x.attributes.attributes.name === "customCardProgressBar"
              );

            console.log("+progressbar+", progressbar);

            let percentProgress =
              (Number(countItems) / responseData.length) * 100;

            if (progressbar) {
              progressbar.components().reset();
              progressbar.components().add({
                tagName: "div",
                style: {
                  width: "100%",
                  height: "10px",
                  position: "relative",
                },
                components: [
                  {
                    tagName: "p",
                    style: {
                      width: `${percentProgress}%`,
                      "background-color": "#06b6d4",
                      "border-radius": "12px",
                      "z-index": "3",
                      height: "10px",
                      position: "relative",
                    },
                  },
                  {
                    tagName: "p",
                    style: {
                      position: "absolute",
                      top: "0",
                      right: "10px",
                      "font-size": "10px",
                      width: "100%",
                      display: "flex",
                      "justify-content": "center",
                      "align-items": "center",
                      height: "100%",
                      "z-index": "4",
                    },
                    content: `${percentProgress}%`,
                  },
                ],
              });
            }

            responseData.forEach((rowData, index) => {
              // console.log("rowData", rowData);
              // console.log("rowData1", rowData[`${imageTarget}`]);

              cardElementWrapper.components().add({
                tagName: "ion-card",
                type: "default",
                attributes: {
                  card_id: `${rowData._id}`,
                  name: "ionCard",
                  class: "ionCardWrapper",
                },
                style: {
                  position: "relative",
                  "z-index": "2",
                  width: `${oneItemWidth}%`,
                  padding: "0.5rem",
                  "box-shadow":
                    "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
                },
                components: [
                  {
                    type: "image",
                    tagName: "img",
                    attributes: {
                      src: `${rowData[`${imageTarget}`]}`,
                      alt: "Silhouette of mountains",
                    },
                    style: {
                      width: "100%",
                      height: "350px",
                    },
                  },
                  {
                    type: "default",
                    tagName: "div",
                    style: {
                      "gap-y": "10px",
                      "padding-top": "5px",
                      "padding-bottom": "5px",
                    },
                    components: [
                      {
                        type: "text",
                        content: `${rowData[`${titleTarget}`].substring(
                          0,
                          10
                        )}`,
                        style: {
                          "font-size": "18px",
                          "font-weight": "600",
                          width: "100%",
                        },
                      },
                      {
                        type: "text",
                        content: `${rowData[`${subTitleTarget}`].substring(
                          0,
                          20
                        )}`,
                        style: {
                          "font-size": "12px",
                          "font-weight": "400",
                          width: "100%",
                        },
                      },
                      {
                        type: "text",
                        content: `${String(rowData[descTarget]).substring(
                          0,
                          20
                        )}`,
                        style: {
                          "font-size": "12px",
                          "font-weight": "400",
                          width: "100%",
                        },
                      },
                    ],
                  },
                  {
                    type: "default",
                    tagName: "div",
                    style: {
                      width: "100%",
                      "padding-top": "20px",
                      "padding-bottom": "20px",
                      display: "flex",
                      "justify-content": "start",
                      "align-items": "center",
                    },

                    components: [
                      {
                        type: "default",
                        tagName: "button",
                        style: {
                          width: "50%",
                          "padding-top": "8px",
                          "padding-bottom": "8px",
                          "background-color": "#179C7D",
                          color: "white",
                          "border-radius": "10px",
                        },
                        attributes: {
                          model_id: `${rowData._id}`,
                          class: "viewModelBtn",
                        },
                        content: `Open Model`,
                      },
                      {
                        type: "default",
                        tagName: "button",
                        style: {
                          width: "50%",
                          "padding-top": "8px",
                          "padding-bottom": "8px",
                          "background-color": "#179C7D",
                          color: "white",
                          "border-radius": "10px",
                          "margin-left": "10px",
                        },
                        // script: btnClickHandler,
                        attributes: {
                          model_id: `${rowData[valueForLb]}`,
                          carouselId: `${componentId}`,
                          class: "editCardBtn select-btn",
                        },
                        content: `${buttonText}`,
                      },
                    ],
                  },
                ],
              });
            });
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      } else if (
        !url &&
        BaseUrl &&
        APIPath &&
        titleTarget &&
        subTitleTarget &&
        descTarget &&
        imageTarget &&
        itemsTarget &&
        !API_KeyTarget &&
        valueForLb &&
        buttonText
      ) {
        try {
          var options = {};

          let url, baseUrlBearerToken;
          if (BaseUrl === "baseUrl1") {
            url = opts.baseUrl1 + APIPath;
            baseUrlBearerToken = opts.baseUrl1BearerToken;
          } else if (BaseUrl === "baseUrl2") {
            url = opts.baseUrl2 + APIPath;
            baseUrlBearerToken = opts.baseUrl2BearerToken;
          } else if (BaseUrl === "baseUrl3") {
            url = opts.baseUrl3 + APIPath;
            baseUrlBearerToken = opts.baseUrl3BearerToken;
          } else if (BaseUrl === "baseUrl4") {
            url = opts.baseUrl4 + APIPath;
            baseUrlBearerToken = opts.baseUrl4BearerToken;
          } else if (BaseUrl === "baseUrl5") {
            url = opts.baseUrl5 + APIPath;
            baseUrlBearerToken = opts.baseUrl5BearerToken;
          }
          console.log("url, baseUrlBearerToken", url, baseUrlBearerToken);

          // if (API_KeyTarget) {
          //   options.headers = {
          //     "Content-type": "application/json",
          //     Authorization: `Bearer ${API_KeyTarget}`,
          //   };
          // }

          if (
            more_HeadersTarget &&
            more_HeadersTarget.length > 0 &&
            baseUrlBearerToken &&
            baseUrlBearerToken.length > 0
          ) {
            console.log("more_HeadersTarget", more_HeadersTarget);
            options.headers = {
              "Content-type": "application/json",
              Authorization: `Bearer ${baseUrlBearerToken}`,
            };

            const additionalHeaders = more_HeadersTarget.split(",");
            console.log("additionalHeaders", additionalHeaders);

            additionalHeaders.forEach((header) => {
              const [headerName, headerValue] = header
                .split(":")
                .map((str) => str.trim());
              if (
                headerName &&
                headerName.length > 0 &&
                headerValue &&
                headerValue.length > 0
              ) {
                options.headers[headerName] = headerValue;
              }
            });
            console.log(
              "options.headers , more headers + api ",
              JSON.stringify(options.headers)
            );
          } else if (more_HeadersTarget && more_HeadersTarget.length > 0) {
            const additionalHeaders = more_HeadersTarget.split(",");

            additionalHeaders.forEach((header) => {
              const [headerName, headerValue] = header
                .split(":")
                .map((str) => str.trim());
              options.headers[headerName] = headerValue;
            });
            console.log(
              "options.headers only more headers",
              JSON.stringify(options.headers)
            );
          } else if (baseUrlBearerToken) {
            options.headers = {
              "Content-type": "application/json",
              Authorization: `Bearer ${baseUrlBearerToken}`,
            };
          }

          localStorage.setItem("API_UPDATE_3D_MODEL", url);
          const response = await fetch(url, options);
          const responseData = await response.json();
          if (
            Array.isArray(responseData) &&
            responseData.length > 0 &&
            typeof responseData[0] === "object"
          ) {
            // console.log("component", component);
            // console.log("responseData", responseData);
            // console.log("titleTarget", titleTarget);
            // console.log("descTarget", descTarget);
            // console.log("imageTarget", imageTarget);
            // console.log("itemsTarget", itemsTarget);
            localStorage.setItem(
              "cardCarouselDataForSlider",
              JSON.stringify(responseData)
            );

            localStorage.setItem("cardCarouselSliderCount", JSON.stringify(0));

            let cardElementWrapper = component.findType(
              "customCardElementWrapperFH"
            )[0];
            let oneItemWidth = 100 / countItems;
            cardElementWrapper.components().reset();

            let progressbar = component
              .components()
              .models[0].components()
              .models.find(
                (x) => x.attributes.attributes.name === "customCardProgressBar"
              );

            let percentProgress =
              (Number(countItems) / responseData.length) * 100;

            if (progressbar) {
              progressbar.components().reset();
              progressbar.components().add({
                tagName: "div",
                style: {
                  width: "100%",
                  height: "10px",
                  position: "relative",
                },
                components: [
                  {
                    tagName: "p",
                    style: {
                      width: `${percentProgress}%`,
                      "background-color": "#06b6d4",
                      "border-radius": "12px",
                      "z-index": "3",
                      height: "10px",
                      position: "relative",
                    },
                  },
                  {
                    tagName: "p",
                    style: {
                      position: "absolute",
                      top: "0",
                      right: "10px",
                      "font-size": "10px",
                      width: "100%",
                      display: "flex",
                      "justify-content": "center",
                      "align-items": "center",
                      height: "100%",
                      "z-index": "4",
                    },
                    content: `${percentProgress}%`,
                  },
                ],
              });
            }

            responseData.forEach((rowData, index) => {
              // console.log("rowData", rowData);
              // console.log("rowData1", rowData[`${imageTarget}`]);

              cardElementWrapper.components().add({
                tagName: "ion-card",
                type: "default",
                attributes: {
                  card_id: `${rowData._id}`,
                  name: "ionCard",
                  class: "ionCardWrapper",
                },
                style: {
                  position: "relative",
                  "z-index": "2",
                  width: `${oneItemWidth}%`,
                  padding: "0.5rem",
                  "box-shadow":
                    "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
                },
                components: [
                  {
                    type: "image",
                    tagName: "img",
                    attributes: {
                      src: `${rowData[`${imageTarget}`]}`,
                      alt: "Silhouette of mountains",
                    },
                    style: {
                      width: "100%",
                      height: "350px",
                    },
                  },
                  {
                    type: "default",
                    tagName: "div",
                    style: {
                      "gap-y": "10px",
                      "padding-top": "5px",
                      "padding-bottom": "5px",
                    },
                    components: [
                      {
                        type: "text",
                        content: `${rowData[`${titleTarget}`].substring(
                          0,
                          10
                        )}`,
                        style: {
                          "font-size": "18px",
                          "font-weight": "600",
                          width: "100%",
                        },
                      },
                      {
                        type: "text",
                        content: `${rowData[`${subTitleTarget}`].substring(
                          0,
                          20
                        )}`,
                        style: {
                          "font-size": "12px",
                          "font-weight": "400",
                          width: "100%",
                        },
                      },
                      {
                        type: "text",
                        content: `${String(rowData[descTarget]).substring(
                          0,
                          20
                        )}`,
                        style: {
                          "font-size": "12px",
                          "font-weight": "400",
                          width: "100%",
                        },
                      },
                    ],
                  },
                  {
                    type: "default",
                    tagName: "div",
                    style: {
                      width: "100%",
                      "padding-top": "20px",
                      "padding-bottom": "20px",
                      display: "flex",
                      "justify-content": "start",
                      "align-items": "center",
                    },

                    components: [
                      {
                        type: "default",
                        tagName: "button",
                        style: {
                          width: "50%",
                          "padding-top": "8px",
                          "padding-bottom": "8px",
                          "background-color": "#179C7D",
                          color: "white",
                          "border-radius": "10px",
                        },
                        attributes: {
                          model_id: `${rowData._id}`,
                          class: "viewModelBtn",
                        },
                        content: `Open Model`,
                      },
                      {
                        type: "default",
                        tagName: "button",
                        style: {
                          width: "50%",
                          "padding-top": "8px",
                          "padding-bottom": "8px",
                          "background-color": "#179C7D",
                          color: "white",
                          "border-radius": "10px",
                          "margin-left": "10px",
                        },
                        // script: btnClickHandler,
                        attributes: {
                          model_id: `${rowData[valueForLb]}`,
                          carouselId: `${componentId}`,
                          class: "editCardBtn select-btn",
                        },
                        content: `${buttonText}`,
                      },
                    ],
                  },
                ],
              });
            });
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      } else {
        return;
      }
    }
  });

};

export default customCardFH_new;
