import React from 'react'
import './style.css'

function ErrorPage() {
    return (
        <div className='ErrorBody'>

            <div>
                <p>404</p>
                <p>Page Not Found</p>
            </div>

        </div>
    )
}

export default ErrorPage