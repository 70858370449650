const mainBody = document.createElement('div');

// for bootstrap icons in social media
const cdn = document.createElement('link');
cdn.setAttribute('rel', 'stylesheet');
cdn.setAttribute('href', 'https://cdn.jsdelivr.net/npm/bootstrap-icons@1.9.1/font/bootstrap-icons.css');
mainBody.appendChild(cdn);

const animations = document.createElement('link');
animations.setAttribute('rel', 'stylesheet');
animations.setAttribute('href', 'templateAnimations.css');
mainBody.appendChild(animations);

const wrapperDiv = document.createElement('div');
wrapperDiv.setAttribute('class', 'wrapper');
mainBody.appendChild(wrapperDiv);

wrapperDiv.innerHTML = `
                    <nav class="navigation">
                        <ul>
                            <li><a href="#" target="_blank">About</a></li>
                            <li><a href="#" target="_blank">Pricing</a></li>
                            <a href="#"><img class="redsling-logo" alt='logo image' src="/templates/images/redSlingLogo.png"></a>
                            <li><a href="#" target="_blank">Training</a></li>
                            <li><a href="#modal-btn">Contact Us</a></li>
                        </ul>
                        <button class="login-signup"><a href="#" target="_blank">log in/sign up</a></button>
                    </nav>
                `;

const bodyDiv = document.createElement('div');
mainBody.appendChild(bodyDiv);

bodyDiv.innerHTML = `
                    <img class="d-bb" alt="dark bubble in background" src="/templates/images/darkBubble.png">
                    <!-- floating features -->
                    <div class="floaters left-position">
                        <img id="stats" alt="stats" src="/templates/images/stats.png">
                        <img id="contacts" alt="contacts" src="/templates/images/contacts.png">
                        <img id="message" alt="message" src="/templates/images/message.png">
                        <img id="graphs" alt="graphs" src="/templates/images/graphs.png">
                    </div>
                    <div class="floaters right-position">
                        <img id="text-box" alt="text-box" src="/templates/images/text box.png">
                        <img id="settings" alt="settings" src="/templates/images/settings.png">
                        <img id="domain" alt="domain" src="/templates/images/domain.png">
                    </div>
                    <!-- main heading (UPPERCASED in css file) -->
                    <h1><span>experience</span> the power<br>of <span>no-code</span></h1>
                    <h2>the easiest app builder there is</h2>
                    <br>
                    <br>
                    <br>
                    <br>
                    <img class="white-waves" id="id-ww-23" alt="white waves" src="/templates/images/white waves 2 3.png">
                    <!-- bg-image for mobile view -->
                    <img class="context-img" alt="mobile icon" src="/templates/images/mobile 1.png">
                    <!-- watch tutorial button -->
                    <div class="watch-tutorial">
                        <button class="play-btn">
                            <div></div>
                        </button>
                        watch tutorial
                    </div>
                    <img class="l-bb" alt="light bubble in background" src="/templates/images/lightBubble.png">
                    <h1>WHY BUILD WITH <span>redSling</span>?</h1>
                    <br>
                    <div class="gray-area">
                    <img class="white-waves ww-21" alt="white waves p2" src="/templates/images/white waves 2 1.png">
                    <img alt="tmp2 1" class="tmp21" src="/templates/images/tmp2 1.png">
                    <div class="description">
                        <h2>Templates that fit your every imagination</h2>
                        <p>Choose from a set of powerful ready-made section template designs instead of old-fashioned whole website templates.</p>
                    </div>
                    </div>
                    <div class="white-area">
                        <div class="description">
                            <h2>Analytics Enabled Dashboard</h2>
                            <p>One dashboard for everything. No need to keep track of different dashboards, find the whole website analytics right on your Master Dashboard.</p>
                        </div>
                        <img alt="dashboard1 1" class="dashboard11" src="/templates/images/dashbrd1 1.png">
                    </div>
                    <div class="gray-area">
                        <img class="white-waves ww-22" alt="white waves 22" src="/templates/images/white waves 2 2.png">
                        <img id="ssl" alt="ssl" src="/templates/images/ssl 1.png">
                        <div class="description">
                            <h2>Security is Our Priority</h2>
                            <p>SSL-secured environment with masked database.</p>
                        </div>
                        <img class="white-waves ww-23" alt="white waves 23" src="/templates/images/white waves 2 3.png">
                    </div>
                    <div class="white-area">
                        <div class="description">
                            <h2>Easy to build Forms</h2>
                            <p>A simple drag and drop enables builder powered by no-code that can be called onto the website using a short-code.</p>
                        </div>
                        <img alt="form21" class="form21" src="/templates/images/form2 1.png">
                    </div>
                    <div class="white-area kf">
                    <h1>key features</h1>
                    <div class="key-features">
                        <br>
                        <ul>
                            <li>
                                <img id="no-code" alt="no code" class="no-code2" src="/templates/images/no code 2.png">
                                <h3>Completely No-Code Environment</h3>
                                <p>You don't need any programming knowledge to build websites, mobile apps and more.</p>
                            </li>
                            <li>
                                <img alt="no plug" class="no-plug" src="/templates/images/no plug.png">
                                <h3>No Plugins</h3>
                                <p>No additional plugins are needed to support the website build.</p>
                            </li>
                            <li>
                                <img alt="digital marketing" class="digital-marketing" src="/templates/images/digital-marketing 1.png">
                                <h3>Digital Marketing Enabled</h3>
                                <p>Build SEO friendly website with keywords, titles and description capturing at building phase.</p>
                            </li>
                            <li>
                                <img id="downloadable" src="/templates/images/downloadable.png">
                                <h3>Completely Downloadable</h3>
                                <p>Websites can be downloaded and hosted on different servers without interfering with the design.</p>
                            </li>
                        </ul>
                        <ul>
                            <li>
                                <img alt="integration" class="integration1" src="/templates/images/integration 1.png">
                                <h3>Integration Friendly</h3>
                                <p>Integrate as many softwares and systems using API.</p>
                            </li>
                            <li>
                                <img alt="distance" class="distance1" src="/templates/images/distance 1.png">
                                <h3>Separate Environments</h3>
                                <p>Separate development and LIVE environments help in change request management.</p>
                            </li>
                            <li>
                                <img alt="equalizer" class="equalizer" src="/templates/images/equalizer 1 1.png">
                                <h3>Fully Customizable</h3>
                                <p>Each and every element can be completely customized.</p>
                            </li>
                            <li>
                                <img alt="multi users icon" class="multi-users" src="/templates/images/multi-users 1.png">
                                <h3>User Access Control</h3>
                                <p>Multi-access user controlled environment that allows multiple users to work on projects simultaneously.</p>
                            </li>
                        </ul>
                    </div>
                </div>
                <!-- to make a little gap without using css -->
                <br>
                <h1>get started for free</h1>
                <h3>Test your creativity and start building web and mobile apps, websites and more.</h3>
                <button id="modal-btn" class="modal-btn">Reach out to us</button>

                <!-- PLACE FOR DIALOG MODAL -->
                <dialog class="modal">
                    <button class="close-modal">&#215</button>
                    <h2>Reach out to us</h2>
                    <p>Ask your questions,<br>Share your opinions/feedback or<br>Send us a message</p>
                    <hr>
                    <form class="form">
                        <label for="name">Name</label>
                        <input type="text" id="name" name="name" placeholder="Enter your name" required autofocus autocomplete="on">
                        <label for="email">Email ID</label>
                        <input type="email" id="email" name="email" placeholder="Enter your email address" required autocomplete="on">
                        <label for="company">Company</label>
                        <input type="text" id="company" name="company" placeholder="[OPTIONAL] Enter the company you work at" autocomplete="on">
                        <label for="country">Country</label>
                        <input type="text" id="country" name="country" placeholder="[OPTIONAL] Enter the country you live in" autocomplete="on">
                        <label for="subject">Subject</label>
                        <textarea type="text" id="subject" name="subject" placeholder="[OPTIONAL] Enter the subject of your message"></textarea>
                        <label for="form-message">Message</label>
                        <textarea type="text" id="form-message" name="form-message" placeholder="[OPTIONAL] Enter your message"></textarea>
                        <button class="send-btn" type="submit">Send</button>
                    </form>
                </dialog>

                <img class="rS-arc" src="/templates/images/arc.png">
                <footer>
                    <form class="subscription-form">
                        <label for="subscribe">Never miss an update from us</label>
                        <input type="email" id="subscribe" name="subscribe" placeholder="Enter an email address" size="50" autocomplete="on" required>
                        <button class="subs-btn">subscribe now</button>
                    </form>
                    <br>
                    <br>
                    <div class="footer-content">
                        <div>
                            <img alt="redsling logo" class="redSlingLogo" src="/templates/images/redSlingLogo.png">
                            <br>
                            <!-- for '© 2022 redSling. All rights reserved.' -->
                            <p>© 2022 redSling. All rights reserved.</p>
                            <a href="#" target="_blank">
                                <p>Terms of Service</p>
                            </a>
                            <a href="#" target="_blank">
                                <p>Privacy Policy</p>
                            </a>
                        </div>
                        <div>
                            <div id="col">
                                <p><b>Quick Links</b></p>
                                <p>Features</p>
                                <p>FAQs</p>
                                <p>Pricing</p>
                                <p>How to build?</p>
                            </div>
                            <div id="col1">
                                <p><b>Company</b></p>
                                <p>About</p>
                                <p>Leadership</p>
                                <p>Contact us</p>
                            </div>
                        </div>
                    </div>
                    <br>
                    <br>
                    <div class="social-medias">
                        <!-- icons from bootstrap imported in <head> -->
                        <a href="#" target="_blank"><i class="bi bi-facebook"></i></a>
                        <a href="#" target="_blank"><i class="bi bi-twitter"></i></a>
                        <a href="#" target="_blank"><i class="bi bi-linkedin"></i></a>
                        <a href="#" target="_blank"><i class="bi bi-youtube"></i></a>
                        <a href="#" target="_blank"><i class="bi bi-instagram"></i></a>
                    </div>
                    <br>
                </footer>

                <script>
                    // FOR "Reach out to us" MODAL
                    const modal = document.querySelector('.modal');
                    const openModal = document.querySelector('.modal-btn');
                    const closeModal = document.querySelector('.close-modal');

                    openModal.addEventListener('click', () => {
                        modal.showModal();
                    });

                    closeModal.addEventListener('click', () => {
                        modal.close();
                    });

                    // FOR FOOTER COPYRIGHT
                    const crStatement = document.querySelector('.footer-content div:nth-child(1) img+p');
                    console.log(crStatement.innerHTML);

                    const d = new Date();
                    let year = d.getFullYear(); // getting the current year

                    crStatement.innerHTML = © year redSling. All rights reserved.;
                </script>

                    <style>
                        /* for all mobile screens */
                        @media (max-width: 500px) {
                            .wrapper {
                                display: none;
                            }

                            #id-ww-24 {
                                z-index: -1;
                                display: block;
                                top: -13em;
                                right: 0;
                            }

                            .hamburger-menu {
                                width: auto;
                                height: auto;
                                display: inline;
                                position: absolute;
                                top: -4em;
                                right: 5%;

                                /*outline: 1px solid blue;*/
                            }

                            .bi {
                                color: black;
                                font-size: 2em;
                            }

                            .hamburger-menu ul {
                                display: none;
                            }

                            .hamburger-menu:hover ul {
                                position: absolute;
                                background-color: white;
                                display: flex;
                                flex-direction: column;
                                justify-content: space-evenly;
                                align-items: flex-end;
                                top: 40%;
                                right: 0;
                                width: auto;
                                font-family: 'Mont-Book';
                                font-style: normal;
                                box-shadow: 0 0 10px -5px black;
                                border-radius: 10px;
                            }

                            .hamburger-menu:hover ul li {
                                color: black;
                                font-size: 0.5em;
                                margin: 0.5em;
                            }

                            .wrapper+a {
                                display: inline;
                                position: absolute;
                                top: -9em;
                                left: 2%;
                            }

                            /* left dark bubble */
                            .d-bb,
                            .floaters,
                            .l-bb {
                                display: none;
                            }

                            .floaters+h1 {
                                margin-top: 20vh;
                            }

                            .floaters+h1 span {
                                color: #ED1C24;
                                font-size: 1.3em;
                            }

                            .floaters+h1+h2,
                            .login-signup a {
                                color: #FFFFFF;
                            }

                            #id-ww-23 {
                                z-index: -2;
                                display: block;
                                width: 60em;
                                top: 10em;
                                right: 22em;
                                transform: rotate(-161.14deg);
                            }

                            .context-img {
                                display: block;
                                width: 60vw;
                                margin-top: -15%;
                                margin-bottom: 10%;
                                margin-left: auto;
                                margin-right: auto;
                            }

                            .mobile {
                                display: block;
                            }

                            .login-signup {
                                position: unset;
                                margin-left: 50%;
                                margin-top: 10%;
                                transform: translate(-50%);
                            }

                            .login-signup a {
                                color: black;
                            }

                            .l-bb+h1 span {
                                color: #ED1C24;
                            }

                            .gray-area {
                                flex-direction: column;
                                justify-content: space-between;
                            }

                            .gray-area .white-waves+img {
                                width: 80vw;
                            }

                            .ww-21 {
                                top: 35%;
                            }

                            .description {
                                width: 80vw;
                            }

                            .description h2 {
                                text-align: center;
                            }

                            .description p {
                                text-align: center;
                            }

                            .gray-area #ssl {
                                width: 30vw;
                            }

                            .ww-23 {
                                width: 90vw;
                            }

                            .white-area {
                                flex-direction: column-reverse;
                                justify-content: space-between;
                            }

                            .white-area img {
                                width: 80vw;
                            }

                            .kf {
                                /* in .white-area class, mobile view, it was changed to flex-direction:column-reverse to bring the images before text. Now here we're overriding it back to normal to bring the text brfore image */
                                flex-direction: column;
                            }

                            .kf .key-features ul {
                                max-width: 100%;
                                flex-wrap: wrap;
                            }

                            .kf .key-features ul li {
                                width: 47%;
                                margin: 0;
                            }

                            .modal {
                                width: 80%;
                            }

                            .subscription-form {
                                width: 80%;
                                display: flex;
                                flex-direction: column;
                                justify-content: space-between;
                                align-items: center;
                            }

                            .subscription-form #subscribe,
                            .subs-btn,
                            label {
                                margin-bottom: 1em;
                                margin-left: auto;
                                margin-right: auto;
                            }

                            .subscription-form #subscribe {
                                width: 80%;
                            }

                            .footer-content {
                                width: 90%;
                                flex-direction: column;

                            }

                            .footer-content div:nth-child(1) img {
                                width: 30vw;
                                margin-left: 50%;
                                transform: translate(-50%);
                            }

                            .footer-content div:nth-child(1) p {
                                text-align: center;
                            }

                            .footer-content div:nth-child(2) #col {
                                align-items: center;
                                margin: 2em;
                            }

                            .social-medias {
                                max-width: 70%;
                                flex-wrap: wrap;
                            }
                        }

                        /* animations */
                        @keyframes pulsate {
                            0% {
                                transform: scale(1.0);
                            }

                            50% {
                                transform-origin: center;
                                transform: scale(1.25);
                            }

                            100% {
                                opacity: 0;
                            }
                        }

                        @keyframes fade-in {
                            from {
                                transform: translateY(5%);
                            }

                            to {
                                opacity: 1;
                                transform: translateY(0);
                            }
                        }

                        @keyframes float {
                            0% {
                                transform: translateY(0);
                            }

                            50% {
                                transform: translateY(2%);
                            }

                            100% {
                                transform: translateY(0);
                            }
                        }
                    </style>
                `;

const templateRedsling = mainBody.outerHTML;
export { templateRedsling };