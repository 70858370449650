import uniqid from "uniqid";
import { API_HOST } from "../api_utils/index";

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const CustomAccordionPlugin = (editor, options) => {
	console.log('CustomAccordionPlugin options', options);

	const notifyTheme = {
		position: "top-right",
		autoClose: 7500,
		hideProgressBar: false,
		closeOnClick: true,
		pauseOnHover: true,
		draggable: true,
		progress: undefined,
		theme: "light",
	};

	const notifyTheme2 = {
		position: "top-right",
		autoClose: 3000,
		hideProgressBar: false,
		closeOnClick: true,
		pauseOnHover: true,
		draggable: true,
		progress: undefined,
		theme: "light",
	};

	const successTheme = {
	  position: "top-right",
	  autoClose: 5000,
	  hideProgressBar: false,
	  closeOnClick: true,
	  pauseOnHover: true,
	  draggable: true,
	  progress: undefined,
	  theme: "light",
	};

	// toast notification for empty tables
	const infoTheme = {
	  position: "top-right",
	  autoClose: 7500,
	  hideProgressBar: false,
	  closeOnClick: true,
	  pauseOnHover: true,
	  draggable: true,
	  progress: undefined,
	  theme: "light",
	};

    if (editor !== null || editor !== undefined) {
    	editor.Blocks.add("custom-basic-accordion-container", {
	        label: "Custom Basic Accordion",
	        category: "Accordion",
	        select: true,
	        media: `<svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="currentColor" viewBox="0 0 16 16"><path d="M0 1.5A1.5 1.5 0 0 1 1.5 0h13A1.5 1.5 0 0 1 16 1.5v2A1.5 1.5 0 0 1 14.5 5h-13A1.5 1.5 0 0 1 0 3.5zM1.5 1a.5.5 0 0 0-.5.5v2a.5.5 0 0 0 .5.5h13a.5.5 0 0 0 .5-.5v-2a.5.5 0 0 0-.5-.5z"/><path d="M2 2.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5m10.823.323-.396-.396A.25.25 0 0 1 12.604 2h.792a.25.25 0 0 1 .177.427l-.396.396a.25.25 0 0 1-.354 0M0 8a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm1 3v2a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2zm14-1V8a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v2zM2 8.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5m0 4a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5"/></svg>`,
	        content: { type: "custom-basic-accordion-container" },
	    });

	    editor.DomComponents.addType("custom-basic-accordion-container", {
	    	model: {
	    		defaults: {
	    			tagName: "div",
	    			type: "custom-basic-accordion-container",
	    			attributes: {
	    				class: "custom-basic-accordion-container",
	    			},

	    			TotalNumOfAcc: 2,

	    			changeProp: true,
	    			"script-props": ["TotalNumOfAcc"],

	    			style: {
	    				padding: "0.5em",
	    			},

	    			"style-default": {
	    				padding: "0.5em",
	    			},

	    			components: [
	    				{
	    					tagName: "div",
	    					type: "accordion",
	    					attributes: {
	    						class: "accordion",
	    						id: "accordionExample",
	    					},

	    					components: [
	    						{
	    							tagName: "div",
	    							type: "accordion-item",
			    					attributes: {
			    						class: "accordion-item",
			    					},

			    					components: [
			    						{
			    							tagName: "h2",
			    							type: "accordion-header",
					    					attributes: {
					    						class: "accordion-header",
					    						// id: "headingOne",
					    					},

					    					components: [
					    						{
					    							tagName: "div",
					    							type: "accordion-header-component-container",
							    					attributes: {
							    						class: "accordion-header-component-container",
							    					},

							    					style: {
					    								padding: "0.5%",
					    								width: "100%",
					    							},

					    							"style-default": {
					    								padding: "0.5%",
					    								width: "100%",
					    							},

					    							components: [
							    						{
							    							tagName: "button",
							    							type: "accordion-button",
									    					attributes: {
									    						class: "accordion-button",
									    						type: "button",
									    						"data-bs-toggle": "collapse",
									    						"data-bs-target": "#collapseOne",
									    						"aria-expanded": "true",
									    						"aria-controls": "collapseOne",
									    					},

									    					components: [
									    						{
									    							tagName: "div",
									    							type: "accordion-button-component-container",
									    							attributes: {
									    								class: "accordion-button-component-container",
									    							},

									    							style: {
									    								padding: "0.5%",
									    								width: "100%",
									    							},

									    							"style-default": {
									    								padding: "0.5%",
									    								width: "100%",
									    							},

									    							components: {
														                tagName: "p",
														                type: "text",

														                style: {
										    								margin: "0",
										    							},

										    							"style-default": {
										    								margin: "0",
										    							},
														
														                components: {
														                    type: "textnode",
														                    content: "Accordion Item #1",
														                },
														            },
									    						},
									    					],
							    						},
							    					],
					    						},
					    					],
			    						}, {
			    							tagName: "div",
			    							type: "accordion-collapse",
					    					attributes: {
					    						class: "accordion-collapse collapse show",
					    						id: "collapseOne",
					    						// "aria-labelledby": "headingOne",
					    						"data-bs-parent": "#accordionExample",
					    					},

					    					components: [
					    						{
					    							tagName: "div",
					    							type: "accordion-body",
							    					attributes: {
							    						class: "accordion-body",
							    					},

							    					components: [
							    						{
							    							tagName: "div",
							    							type: "accordion-body-component-container",
							    							attributes: {
							    								class: "accordion-body-component-container",
							    							},

							    							style: {
							    								padding: "1%",
							    							},

							    							"style-default": {
							    								padding: "1%",
							    							},

							    							components: {
												                tagName: "p",
												                type: "text",
												  
												                components: {
												                    type: "textnode",
												                    content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce nec nulla vestibulum, sollicitudin dui cursus, suscipit massa. Mauris eu magna auctor enim fermentum bibendum quis et nulla. Mauris euismod, lectus nec pellentesque cursus, massa sapien porttitor felis, ac ornare quam ipsum a eros.",
												                },
												            },
							    						},
							    					],
					    						},
					    					],
			    						},
			    					],
	    						}, {
	    							tagName: "div",
	    							type: "accordion-item",
			    					attributes: {
			    						class: "accordion-item",
			    					},

			    					components: [
			    						{
			    							tagName: "h2",
			    							type: "accordion-header",
					    					attributes: {
					    						class: "accordion-header",
					    						// id: "headingTwo",
					    					},

					    					components: [
					    						{
					    							tagName: "div",
					    							type: "accordion-header-component-container",
							    					attributes: {
							    						class: "accordion-header-component-container",
							    					},

							    					style: {
					    								padding: "0.5%",
					    								width: "100%",
					    							},

					    							"style-default": {
					    								padding: "0.5%",
					    								width: "100%",
					    							},

					    							components: [
							    						{
							    							tagName: "button",
							    							type: "accordion-button",
									    					attributes: {
									    						class: "accordion-button collapsed",
									    						type: "button",
									    						"data-bs-toggle": "collapse",
									    						"data-bs-target": "#collapseTwo",
									    						"aria-expanded": "false",
									    						"aria-controls": "collapseTwo",
									    					},

									    					components: [
									    						{
									    							tagName: "div",
									    							type: "accordion-button-component-container",
									    							attributes: {
									    								class: "accordion-button-component-container",
									    							},

									    							style: {
									    								padding: "0.5%",
									    								width: "100%",
									    							},

									    							"style-default": {
									    								padding: "0.5%",
									    								width: "100%",
									    							},

									    							components: {
														                tagName: "p",
														                type: "text",

														                style: {
										    								margin: "0",
										    							},

										    							"style-default": {
										    								margin: "0",
										    							},
														
														                components: {
														                    type: "textnode",
														                    content: "Accordion Item #2",
														                },
														            },
									    						},
									    					],
							    						},
							    					],
					    						},
					    					],
			    						}, {
			    							tagName: "div",
			    							type: "accordion-collapse",
					    					attributes: {
					    						class: "accordion-collapse collapse",
					    						id: "collapseTwo",
					    						// "aria-labelledby": "headingTwo",
					    						"data-bs-parent": "#accordionExample",
					    					},

					    					components: [
					    						{
					    							tagName: "div",
					    							type: "accordion-body",
							    					attributes: {
							    						class: "accordion-body",
							    					},

							    					components: [
							    						{
							    							tagName: "div",
							    							type: "accordion-body-component-container",
							    							attributes: {
							    								class: "accordion-body-component-container",
							    							},

							    							style: {
							    								padding: "1%",
							    							},

							    							"style-default": {
							    								padding: "1%",
							    							},

							    							components: {
												                tagName: "p",
												                type: "text",
												  
												                components: {
												                    type: "textnode",
												                    content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce nec nulla vestibulum, sollicitudin dui cursus, suscipit massa. Mauris eu magna auctor enim fermentum bibendum quis et nulla. Mauris euismod, lectus nec pellentesque cursus, massa sapien porttitor felis, ac ornare quam ipsum a eros.",
												                },
												            },
							    						},
							    					],
					    						},
					    					],
			    						},
			    					],
	    						},
	    					],
	    				},
	    			],
	    		},
	    	},
	    	
	    	view: {
	    		events: {
					"click .custom-basic-accordion-form-btn": "openModal",
					"click .refresh-accordion-id-btn": "refreshAccID",
				},

				openModal(event) {
					event.stopPropagation();

					const form = document.createElement("div");
					form.setAttribute("class", "custom-basic-accordion-form");

					form.innerHTML = `
						<ul class="nav nav-tabs" id="myTab" role="tablist">
							<li class="nav-item" role="presentation">
							    <button class="nav-link active" id="accordion-settings-tab" data-bs-toggle="tab" data-bs-target="#accordion-settings-tab-pane" type="button" role="tab" aria-controls="accordion-settings-tab-pane" aria-selected="true">Accordion Settings</button>
							</li>
						</ul>

						<div class="tab-content" id="myTabContent">
							<div class="tab-pane fade show active" id="accordion-settings-tab-pane" role="tabpanel" aria-labelledby="accordion-settings-tab" tabindex="0">
								<br>
								
								<div>
									<div class="left-col" id="left-col">
										<!-- total accordions -->
				                        <div>
				                        	<span>
				                            	<label for="total-num-acc" title="The total number of accordion items to render in this accordion component along with the ones already present in the editor canvas right now.">Total Number of Accordion Items</label>
				                            </span>
				                            <input type="number" name="total-num-acc" id="total-num-acc" class="total-num-acc" placeholder="2" min="1">
				                        </div>

				                        <!-- save button -->
				                        <button class="save-accordion-settings-btn">Save</button>
									</div>

									<div class="right-col" id="right-col"></div>
								</div>								
							</div>
						</div>
					`;

					editor.Modal.open({
						title: "Custom Basic Accordion",
						content: `${form.outerHTML}`,
						attributes: {
							thisModelRef: this.model,
						},
					});
				},

				// handling IDs of accordions, so that there can be multiple accordions in a page and they don't affect each other.
				refreshAccID(event) {
					event.stopPropagation();

					try {
						let allAccItems = this.model.find('.accordion-item');
						if (allAccItems.length > 0) {
							allAccItems.forEach((ai, idx) => {
								let uniqueId = uniqid();

								let accBtn = ai.find('.accordion-button')[0];
								accBtn.setAttributes({
									class: "accordion-button collapsed",
		    						type: "button",
		    						"data-bs-toggle": "collapse",
									"data-bs-target": `#collapse${uniqueId}`,
									"aria-expanded": (idx === 0) ? "true" : "false",
		    						"aria-controls": `collapse${uniqueId}`,
								});

								let accCol = ai.find('.accordion-collapse')[0];
								accCol.setAttributes({
									class: "accordion-collapse collapse",
									id: `collapse${uniqueId}`,
									"data-bs-parent": "#accordionExample",
								});
							});
						}

						let uniqueId = uniqid();
						let accordion = this.model.find('.accordion')[0];
						if (accordion) accordion.setAttributes({id: `accordionExample${uniqueId}`});

						let accCols = this.model.find('.accordion-collapse');
						if (accCols.length > 0) {
							accCols.forEach(accCol => {
								accCol.addAttributes({"data-bs-parent": `#accordionExample${uniqueId}`});
							});
						}

						// this.render();
						this.model.view.render();
						toast.info("The accordion IDs have been set. Please proceed.", successTheme);
					} catch(e) {
						console.log(e);
						toast.error("Error in setting accordion IDs. Please check if any element from the accordion has been removed or not.", infoTheme);
					}
				},

	    		onRender({ el, model }) {
	    			console.log('el', el);

	    			if (!window.top.location.href.includes("large_preview/")) {

	    				let customizeBasicAccordionBtn = document.createElement("button");
						customizeBasicAccordionBtn.setAttribute("class", "custom-basic-accordion-form-btn");
						customizeBasicAccordionBtn.setAttribute("title", "The edit button allows you to customize this component with more setting.");
						customizeBasicAccordionBtn.style.marginRight = '1%';
						customizeBasicAccordionBtn.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pen" viewBox="0 0 16 16"><path d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001m-.644.766a.5.5 0 0 0-.707 0L1.95 11.756l-.764 3.057 3.057-.764L14.44 3.854a.5.5 0 0 0 0-.708l-1.585-1.585z"/></svg>';

						el.getElementsByClassName("accordion")[0].insertAdjacentElement("beforebegin", customizeBasicAccordionBtn);

	    				// button to unify the id of all accordion items so that they don't affect each other
	    				let refreshAccIDBtn = document.createElement("button");
						refreshAccIDBtn.setAttribute("class", "refresh-accordion-id-btn");
						refreshAccIDBtn.setAttribute("title", "Click this reload button to make the IDs of the accordion items equal to prevent conflicts among multiple accordions in the same page. Note: Due to change in IDs by clicking this button, all the custom styling of this component can be lost so its recommended to never click this button after providing custom styling to your component.");
						// customizeCalendarBtn.style.marginLeft = '5%';
						refreshAccIDBtn.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-clockwise" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2z"/><path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466"/></svg>';

						el.getElementsByClassName("accordion")[0].insertAdjacentElement("beforebegin", refreshAccIDBtn);
	    			}
	    		},
	    	},
	    });

		editor.on("modal", (props) => {
			if (document.getElementsByClassName("gjs-mdl-content")[0] !== undefined && document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("custom-basic-accordion-form")[0] !== undefined) {
				const totalNumAcc = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("total-num-acc")[0];
				
				const saveAccordionSettingsButton = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("save-accordion-settings-btn")[0];

				// reattaching values in the modal's input fields for the user, so that they know what values they had entered previously
				try {
					totalNumAcc.value = props.attributes.thisModelRef.attributes.TotalNumOfAcc;
				} catch(e) {
					console.log(e);
				}

				// adding event listeners
				try {
					saveAccordionSettingsButton.addEventListener("click", (event) => {
						if (totalNumAcc.value) {
							createAccordions(editor, props.attributes.thisModelRef, totalNumAcc.value);
						}
					});
				} catch(e) {
					console.log(e);
				}
			}
		});

		// function to render total number of accordions mentioned
		function createAccordions(editorRef, thisModelRef, totalNumofAcc) {
			try {
				thisModelRef.set({
		    		TotalNumOfAcc: Number(totalNumofAcc),
		    	});

		    	// To render accordions: Check the total number of accordions and according to that either add or remove accordions according to how many are already present in the editor canvas
		    	let totalPresentAccs;
		    	totalPresentAccs = thisModelRef.find('.accordion-item').length;

		    	if (totalPresentAccs < totalNumofAcc) {
		    		// Logic for adding more accordion items if needed
		    		let accordionContainer = thisModelRef.find('.accordion')[0];
    				for (let acc = totalPresentAccs + 1; acc <= totalNumofAcc; acc++) {
    					let newAccItm = {
							tagName: "div",
							type: "accordion-item",
	    					attributes: {
	    						class: "accordion-item",
	    					},

	    					components: [
	    						{
	    							tagName: "h2",
	    							type: "accordion-header",
			    					attributes: {
			    						class: "accordion-header",
			    						// id: `heading${acc}`,
			    					},

			    					components: [
			    						{
			    							tagName: "div",
			    							type: "accordion-header-component-container",
					    					attributes: {
					    						class: "accordion-header-component-container",
					    					},

					    					style: {
			    								padding: "0.5%",
			    								width: "100%",
			    							},

			    							"style-default": {
			    								padding: "0.5%",
			    								width: "100%",
			    							},

			    							components: [
					    						{
					    							tagName: "button",
					    							type: "accordion-button",
							    					attributes: {
							    						class: "accordion-button collapsed",
							    						type: "button",
							    						"data-bs-toggle": "collapse",
							    						"data-bs-target": `#collapse${acc}`,
							    						"aria-expanded": "false",
							    						"aria-controls": `collapse${acc}`,
							    					},

							    					components: [
							    						{
							    							tagName: "div",
							    							type: "accordion-button-component-container",
							    							attributes: {
							    								class: "accordion-button-component-container",
							    							},

							    							style: {
							    								padding: "0.5%",
							    								width: "100%",
							    							},

							    							"style-default": {
							    								padding: "0.5%",
							    								width: "100%",
							    							},

							    							components: {
												                tagName: "p",
												                type: "text",

												                style: {
								    								margin: "0",
								    							},

								    							"style-default": {
								    								margin: "0",
								    							},
												
												                components: {
												                    type: "textnode",
												                    content: `Accordion Item #${acc}`,
												                },
												            },
							    						},
							    					],
					    						},
					    					],
			    						},
			    					],
	    						}, {
	    							tagName: "div",
	    							type: "accordion-collapse",
			    					attributes: {
			    						class: "accordion-collapse collapse",
			    						id: `collapse${acc}`,
			    						// "aria-labelledby": `heading${acc}`,
			    						"data-bs-parent": "#accordionExample",
			    					},

			    					components: [
			    						{
			    							tagName: "div",
			    							type: "accordion-body",
					    					attributes: {
					    						class: "accordion-body",
					    					},

					    					components: [
					    						{
					    							tagName: "div",
					    							type: "accordion-body-component-container",
					    							attributes: {
					    								class: "accordion-body-component-container",
					    							},

					    							style: {
					    								padding: "1%",
					    							},

					    							"style-default": {
					    								padding: "1%",
					    							},

					    							components: {
										                tagName: "p",
										                type: "text",
										  
										                components: {
										                    type: "textnode",
										                    content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce nec nulla vestibulum, sollicitudin dui cursus, suscipit massa. Mauris eu magna auctor enim fermentum bibendum quis et nulla. Mauris euismod, lectus nec pellentesque cursus, massa sapien porttitor felis, ac ornare quam ipsum a eros.",
										                },
										            },
					    						},
					    					],
			    						},
			    					],
	    						},
	    					],
						};

    					accordionContainer.append(newAccItm);
    				}
		    	} else {
		    		// Remove the extra accordion-items from the end
		    		for (let acc = totalPresentAccs - 1; acc >= totalNumofAcc; acc--) {
		    			thisModelRef.find('.accordion-item')[acc].remove({ at: acc });
		    		}
		    	}

		    	toast.info("The accordions have been rendered. Please proceed.", successTheme);
		    	console.log('saveAccordionSettingsButton thisModelRef', thisModelRef);

		    	editorRef.Modal.close();
			} catch(e) {
				// statements
				console.error(e);
				toast.error("Error in rendering accordions. Please check the provided values.", successTheme);
			}
		}
    }
};

export default CustomAccordionPlugin;