export default function manageTableTagTraits(editor, columnData) {

    console.log('columnData111111::::::::::', columnData)

    // Description: when the editor space is loaded or updated, all the componenets in the current state of the editor space is collected and added traits to and then shown in the traits manager
    const getAllComponents = (model, result = []) => {
        result.push(model);
        model.components().each(mod => getAllComponents(mod, result))
        return result;
    }
    const allComps = getAllComponents(editor.DomComponents.getWrapper());

    let columnDataFilter = [];
    if (columnData) {
        for (let columnName of Object.keys(columnData)) {
            let idTrim = columnName.trim()

            let arr = {
                id: `${idTrim}`,
                name: `${columnName}`
            }
            console.log("columnDataFilter:::::::::::", arr.id);
            columnDataFilter.push(arr)
        }

        allComps.forEach((ele) => {
            console.log('ele.attributes:111111', ele.attributes);
            console.log('tagname:1111111111', ele.attributes.tagName);


            if (ele.attributes.tagName === 'table' || ele.attributes.type === 'table') {
                // check if traits are already present, if not then .addtrait, otherwise show the already present ones
                let setNewProp1 = true;
                const traits = ele.get('traits');

                traits.forEach(trait => {
                    const curProps = trait.props();
                    console.log('props:1111', curProps);
                    if (curProps.label === 'Sort-Table') {
                        setNewProp1 = false;
                    }
                });

                if (setNewProp1 !== false) {
                    ele.addTrait({ name: 'name' }, { at: 0 });


                    ele.addTrait({
                        type: 'select',
                        label: 'Sort By Column',
                        name: 'sortByColumn',
                        options: columnDataFilter
                        // onclick: `${selectHandler(event)}`
                    }, { at: 1 });


                    ele.addTrait({
                        type: 'select',
                        label: 'Sorting Order',
                        name: 'sortTrait',
                        options: [
                            { id: 'ascending', name: 'ascending' },
                            { id: 'descending', name: 'descending' },
                        ],
                    }, { at: 2 });

                }

            }
        })

    }


}