import React, {
  useState,
  useEffect,
  useRef,
  Component,
  useMemo,
  useCallback,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "./Loader";
import {
  Button,
  Grid,
  Typography,
  Modal,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Divider,
} from "@mui/material";
import darkBubble from "./component_assets/images/darkBubble.png";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DoDisturbOnIcon from "@mui/icons-material/DoDisturbOn";
// import darkBubble from './component_assets/images/details_close.png'
// import lightBubble from './component_assets/images/details_close.png'
import lightBubble from "./component_assets/images/lightBubble.png";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import "./style.css";
import DeleteIcon from "@mui/icons-material/Delete";
import RateReviewIcon from "@mui/icons-material/RateReview";
import CloseIcon from "@mui/icons-material/Close";
import { updateProject } from "../Redux/Actions/projectActions";
// import errorSymbol from './component_assets/images/details_close.png'
import errorSymbol from "./component_assets/images/errorSymbol.svg";
// import btnGrp from './component_assets/images/details_close.png'
import btnGrp from "./component_assets/images/btnGrpRed.svg";
// import errorWave from './component_assets/images/details_close.png'
import errorWave from "./component_assets/images/errorWave.svg";
import DownloadIcon from "@mui/icons-material/Download";
import { getAllModels, updateModal } from "../Redux/Actions/customTableActions";
import { API_HOST } from "../api_utils";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import handleFile from "../handleImports";
import { Tooltip } from "@mui/material";
import { CopyToClipboard } from "react-copy-to-clipboard";

import classes from "./ScreensStylings/AllCustomModels.module.css";
import { FiSearch } from "react-icons/fi";

const notifyTheme = {
  position: "top-center",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "colored",
};

const successTheme = {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "colored",
};

// const user = JSON.parse(localStorage.getItem('userInfo')).email;
// const isAllowedUser = user === 'demo@redsling.com' || user === 'yeodw@bizeni.com' || user === 'jihyun.bae@bizeni.com';

let user = "";
const userInfo = localStorage.getItem("userInfo");
if (userInfo) {
  const parsedUserInfo = JSON.parse(userInfo);
  if (parsedUserInfo && parsedUserInfo.email) {
    user = parsedUserInfo.email;
  }
}

const allowedUsers = [
  "demo@redsling.com",
  "yeodw@bizeni.com",
  "jihyun.bae@bizeni.com",
];
const isAllowedUser = allowedUsers.includes(user);

// toast notification for empty tables
const infoTheme = {
  position: "top-right",
  autoClose: 10000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "colored",
};

export default function AllCustomModels() {
  let dispatch = useDispatch();
  let navigate = useNavigate();

  const { customTables, loading, error, emptyCustomTables } = useSelector(
    (state) => state.customTables
  );
  const { allProjectData } = useSelector((state) => state.allProjectData);

  const { updatedModalData } = useSelector((state) => state.updateModal);
  const [tableReferenceList, setTableReferenceList] = useState([]);
  const [modalData, setModalData] = useState({
    tableName: "",
    fieldName: "",
    fieldType: "",
    fieldType1: "",
    fields: [],
    success: false,
    referenceTables: [],
    referenceTableId: "",
  });
  const [open, setOpen] = useState(false);
  const [tableName, setTableName] = useState("");
  const [fieldType, setFieldType] = useState("");
  const [fieldName, setFieldName] = useState();
  const [customTableName, setCustomTableName] = useState("");
  const [modal_field_type, setModalFieldType] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const searchFilterOptionsShowNickname = useRef(true);
  const [customTableNameFilter, setCustomTableNameFilter] = useState("");
  const [selectedCustomTableName, setSelectedCustomTableName] = useState("");
  const [
    finalCustomTableNameToFilterData,
    setFinalCustomTableNameToFilterData,
  ] = useState("");

  const [tableNameModal, setTableNameModal] = useState(false);
  const tableNameRef = useRef(null);
  const [projectName, setProjectName] = useState("");

  const { project } = useParams();

  //console.log('project:', project);

  const xlFileInputRef = useRef(null);

  useEffect(() => {
    dispatch(getAllModels(project));
  }, [updatedModalData]);

  const setFields = () => {
    let savingFields = [
      ...modalData.fields,
      { field_name: "", field_type: "", fieldType1: "" },
    ];
    //let referenceFields = [...modalData.referenceTables, { referenceTableId: "" }]

    setModalData((prevState) => ({
      ...prevState,
      fields: savingFields,
      //referenceTables: referenceFields
    }));
  };

  const handleReferenceChange = (e, index) => {
    //console.log("indexRef",e,index);
    //console.log("modalData.referenceTables" + JSON.stringify(modalData));

    if (
      modalData &&
      modalData.fields &&
      index >= 0 &&
      index < modalData.fields.length
    ) {
      const addNewFieldType = modalData.fields.find((x, i) => i === index);
      //console.log("addNewFieldType: e.target.value" + JSON.stringify(addNewFieldType));
      addNewFieldType.referenceTableId = e.target.value;

      if (addNewFieldType) {
        const updatedFields = modalData.fields.map((x, i) => {
          const newField = {
            ...addNewFieldType,
            referenceTableId: e.target.value,
          };
          //console.log("referenceTableId: e.target.value" + JSON.stringify(newField));

          if (i === index) {
            return newField;
          } else {
            return x;
          }
        });
        //console.log("updatedFields" + updatedFields);

        setModalData((prevState) => ({
          ...prevState,
          referenceTables: updatedFields,
        }));
      }
      //console.log("handleReferenceChange modalData" + JSON.stringify(modalData));
    } else {
      //console.log("handleReferenceChange out of bounds");
    }
  };

  useEffect(() => {
    if (customTables.length) {
      const tableInsideProject = customTables.filter(
        (x) => x.project === project
      );
      let tableName = [];
      if (tableInsideProject?.length) {
        tableName = tableInsideProject.map((x) => ({
          name: x.table_name,
          value: x._id,
        }));
      }
      // let tableName = tableInsideProject?.map((x) => {
      //     console.log("tableInsideProject?.map((x)"+ JSON.stringify(x));
      //     let arr = {
      //         name: x.table_name,
      //         value: x._id
      //     }
      //     return arr;
      // })
      tableName?.push({
        name: "none",
        value: "none",
      });
      setTableReferenceList(tableName);
    }
  }, [customTables, project]);

  const handleClose = () => {
    setOpen(false);
  };

  const createTable = () => {
    navigate(`/create_modal/${project}`);
  };

  const externalDataHandler = () => {
    navigate(`/externaltables/${project}`);
  };

  const handleRowEventHandler = (params) => {
    //console.log("params::::", params);
    setModalData({
      _id: params.row.id,
      table_name: params.row.customTableNameOriginal,
      table_name1: params.row.customTableName,
      fields: params.row.fields,
      userToken: params.row.userToken,
      api: params.row.api,
    });
    setOpen(true);
  };

  // Define a custom component for rendering the copy button
  const CopyButton = ({ text }) => (
    <CopyToClipboard text={text}>
      <Button
        variant="contained"
        color="primary"
        sx={{ marginRight: "0.5vw", backgroundColor: "red" }}>
        Copy
      </Button>
    </CopyToClipboard>
  );

  useEffect(() => {
    if (customTables && !customTableNameFilter) {
      setFilteredData(customTables);
    }
  }, [customTables, customTableNameFilter]);

  const columns = [
    { field: "id", headerName: "ID", width: 90 },
    {
      field: "customTableName",
      headerName: "Table Name",
      width: 180,
      editable: false,
    },
    {
      field: "fields",
      headerName: "Fields",
      width: 500,
      renderCell: (params) => {
        return (
          <Box
            sx={{
              padding: "1rem 0.5rem",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "start",
            }}>
            {params.row.fields.length > 1 ? (
              params.row.fields.map((x) => (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "start",
                    width: "100%",
                  }}>
                  <Typography sx={{ width: "60%" }}>
                    <strong>Field Name</strong>: {x.field_name}
                  </Typography>
                  <Typography>
                    <strong>Field Type</strong> :{x.field_type}
                  </Typography>
                </div>
              ))
            ) : (
              <>
                {" "}
                <Typography>
                  <strong>Field Name</strong>: {params.row.fields[0].field_name}
                </Typography>
                <Typography>
                  <strong>Field Type</strong> :{params.row.fields[0].field_type}
                </Typography>
              </>
            )}
          </Box>
        );
      },
    },
    {
      field: "userToken",
      headerName: "User Token",
      width: 200,
      editable: false,
      wrapText: true,
      renderCell: (params) => (
        <Tooltip title={params.value}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}>
            <CopyButton text={params.value} />
            <Typography>{params.value}</Typography>
          </Box>
        </Tooltip>
      ),
    },
    {
      field: "api",
      headerName: "API",
      width: 200,
      editable: false,
      renderCell: (params) => (
        <Tooltip title={params.value}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}>
            <CopyButton text={params.value} />
            <Typography>{params.value}</Typography>
          </Box>
        </Tooltip>
      ),
    },
    {
      field: "Details",
      headerName: "Update",
      width: 90,
      renderCell: (params) => {
        return (
          <Typography
            onClick={(event) => {
              handleRowEventHandler(params);
            }}
            sx={{
              color: "#e11d48",
              "&:hover": { color: "#fb7185", scale: "125%" },
            }}>
            <RateReviewIcon fontSize="medium" />
          </Typography>
        );
      },
    },
    {
      field: "Actions",
      headerName: "Actions",
      width: 90,
      renderCell: (params) => {
        return (
          <Typography
            //  onClick={(event) => {
            //     event.stopPropagation(); deleteProjectHandler(params.id)
            // }}
            sx={{
              color: "#e11d48",
              "&:hover": { color: "#fb7185", scale: "125%" },
            }}>
            <DeleteIcon fontSize="medium" />
          </Typography>
        );
      },
    },
  ];

  let rows =
    filteredData.length > 0
      ? filteredData?.map((x) => {
          let user = JSON.parse(localStorage.getItem("userInfo"));
          //console.log('user', user)
          let apiEndPoint = `${API_HOST}customroutes/data_grid/${x.table_name}`;
          //console.log('apiEndPoint', apiEndPoint)
          //console.log('x.fields', x.fields)
          return {
            id: x._id,
            customTableName:
              x.table_name.indexOf("_") !== -1
                ? x.table_name.substring(x.table_name.indexOf("_") + 1)
                : x.table_name,
            customTableNameOriginal: x.table_name,
            fields: x.fields,
            userToken: user.token,
            api: apiEndPoint,
          };
        })
      : [];

  const updateModelHandler = () => {
    const fields = modalData?.fields;
    const referenceTables = modalData?.referenceTables;
    //console.log("model id", modalData._id);
    dispatch(
      updateModal({
        fields,
        table_name: tableName,
        model_id: modalData._id,
        referenceTables: referenceTables,
      })
    );
    setOpen(false);
  };

  const tableNameSubmitHandler = () => {
    //console.log('tableNameRef.current.value:', tableNameRef.current.value);
    const table_name = tableNameRef.current.value;

    if (table_name) {
      // Trigger the file input element to open the file dialog
      xlFileInputRef.current.click();

      // Listen for changes in the file input
      xlFileInputRef.current.addEventListener("change", (e) => {
        const file = e.target.files[0];

        if (file) {
          // Create a FormData object to send the file
          const formData = new FormData();
          formData.append("file", file); // Use the same field name 'file' as expected on the server

          // Determine the file type (Excel or CSV)
          if (
            file.type ===
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          ) {
            // Handle Excel file (XLSX)
            // handleExcelFile(file, editor);

            // Now, make a fetch call to send the file to the backend
            fetch(`${API_HOST}imports/upload/${project}/${table_name}`, {
              method: "POST",
              body: formData,
            })
              .then((response) => response.json())
              .then((data) => {
                // check for the message if it includes the word "Added", then proceed
                if (data.message.includes("Added")) {
                  //console.log('data:', data); // Handle the response from the server
                  toast.success(
                    `File is imported in the database, successfully.`,
                    successTheme
                  );
                } else {
                  toast.warning(
                    `This table doesn't exist.\nPlease try again.`,
                    successTheme
                  );
                }
              })
              .catch((error) => {
                console.error("Error:", error);
                toast.error(`${error}`, successTheme);
              });

            //console.log('sent excel file to backend');
          } else if (file.type === "text/csv") {
            // Handle CSV file
            // handleCsvFile(file, editor);

            // Now, make a fetch call to send the file to the backend
            fetch(`${API_HOST}imports/upload/${project}/${table_name}`, {
              method: "POST",
              body: formData,
            })
              .then((response) => response.json())
              .then((data) => {
                // check for the message if it includes the word "Added", then proceed
                if (data.message.includes("Added")) {
                  //console.log('data:', data); // Handle the response from the server
                  toast.success(
                    `File is imported in the database, successfully.`,
                    successTheme
                  );
                } else {
                  toast.warning(
                    `This table doesn't exist.\nPlease try again.`,
                    successTheme
                  );
                }
              })
              .catch((error) => {
                console.error("Error:", error);
                toast.error(`${error}`, successTheme);
              });
            //console.log('sent csv file to backend');
          } else {
            // Handle other file types or show an error
            console.error("Unsupported file type");
          }
        }
      });
    }

    setTableNameModal(false);
  };

  const importHandler = () => {
    setTableNameModal(true);
  };

  const handleCustomerNicknameChange = useCallback((e) => {
    setCustomTableNameFilter(e.target.value);
  }, []);

  useEffect(() => {
    if (project && allProjectData.length) {
      let currentProject = allProjectData.find((x) => x._id === project);
      if (currentProject) {
        setProjectName(currentProject.project_name);
      }
    }
  }, [allProjectData]);

  const filteredNicknameOptions = useMemo(() => {
    if (customTableNameFilter && !selectedCustomTableName) {
      console.log("project:::::", project);

      let projectNameFilterNew;

      if (projectName.includes("'s")) {
        let projectNameNew = projectName.replace(/'s/g, "s");
        projectNameNew = projectNameNew.replace(/\s/g, "");
        projectNameFilterNew = `${projectNameNew}_${customTableNameFilter}`;
      } else {
        let projectNameNew = projectName.replace(/\s/g, "");
        projectNameFilterNew = `${projectNameNew}_${customTableNameFilter}`;
      }

      console.log("projectNameFilterNew:::::", projectNameFilterNew);

      const searchTerm = projectNameFilterNew.toLowerCase();
      const optionsStartingWithSearchTerm = [];

      customTables.forEach((option) => {
        console.log("option:::", option.table_name);

        if (
          option.table_name &&
          option.table_name.toLowerCase().startsWith(searchTerm)
        ) {
          optionsStartingWithSearchTerm.push(option);
        }
      });

      optionsStartingWithSearchTerm.sort((a, b) =>
        a.table_name.localeCompare(b.table_name, undefined, {
          sensitivity: "base",
        })
      );

      return optionsStartingWithSearchTerm;
    } else {
      if (selectedCustomTableName) {
        setFinalCustomTableNameToFilterData(selectedCustomTableName);
        setSelectedCustomTableName("");

        searchFilterOptionsShowNickname.current = false;
      } else {
        searchFilterOptionsShowNickname.current = true;
      }

      return [];
    }
  }, [customTableNameFilter, selectedCustomTableName]);

  useEffect(() => {
    if (finalCustomTableNameToFilterData) {
      let newFilteredData = customTables.filter(
        (item) => item.table_name === finalCustomTableNameToFilterData
      );
      setFilteredData(newFilteredData);
    }

    return () => {
      setFinalCustomTableNameToFilterData("");
    };
  }, [finalCustomTableNameToFilterData]);

  return (
    <div className="" style={{ minHeight: "" }}>
      {loading ? (
        <Loader />
      ) : emptyCustomTables ? (
        <Grid
          sx={{
            height: "87.5vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            position: "relative",
            overflow: "hidden",
          }}>
          <Grid
            width={"80vw"}
            height={"80%"}
            sx={{
              padding: "2vh 0 2vh 3vw",
              borderRadius: "8px",
              backdropFilter: "blur(25px)",
              boxShadow: 20,
              zIndex: 20,
            }}>
            <Grid
              xs={12}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}>
              <Typography
                sx={{ fontFamily: "Mont-SemiBold", fontSize: "1.5rem" }}>
                Projects Created
              </Typography>
              <Grid
                xs={9}
                sx={{ backgroundColor: "red", height: "0.6vh" }}></Grid>
            </Grid>
            <Grid sx={{ display: "flex", justifyContent: "center", mt: 6 }}>
              <img src={errorSymbol} style={{ height: "30vh" }} />
            </Grid>
            <Typography
              sx={{
                textAlign: "center",
                mt: 4,
                fontSize: "1.2rem",
                fontFamily: "Mont-SemiBold",
              }}>
              Sorry! No Projects have been created yet.{" "}
            </Typography>
            <Grid sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
              <Button
                onClick={createTable}
                variant="contained"
                sx={{
                  backgroundColor: "white",
                  color: "black",
                  fontFamily: "Mont-Regular",
                }}>
                <span style={{ marginRight: "1vw" }}>Create New Project</span>
                <img src={btnGrp} style={{ height: "4vh" }} />
              </Button>
            </Grid>
          </Grid>
          <Grid sx={{ position: "absolute", bottom: "-80vh", right: "-20vw" }}>
            <img src={lightBubble} style={{ height: "160vh" }} />
          </Grid>
          <Grid sx={{ position: "absolute", top: "-12vh", right: "0" }}>
            <img src={errorWave} style={{ height: "180vh" }} />
          </Grid>
        </Grid>
      ) : (
        <Grid
          sx={{ position: "relative", overflow: "hidden", height: "100vh" }}>
          <Grid
            sx={{
              position: "absolute",
              top: "-12vh",
              left: "-30vw",
              zIndex: "-10",
            }}>
            <img style={{ width: "55vw", height: "90%" }} src={darkBubble} />
          </Grid>

          <Grid sx={{ position: "absolute", bottom: "-60%", right: "-15%" }}>
            <img style={{ width: "55vw", height: "90%" }} src={lightBubble} />
          </Grid>

          <Grid
            sx={{
              display: "flex",
              justifyContent: "center",
              paddingTop: "10vh",
            }}>
            <Box
              sx={{
                minHeight: "58vh",
                width: "90%",
                backdropFilter: "blur(25)",
                backgroundColor: "",
              }}>
              <Grid
                xs={12}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
                container>
                <Typography
                  sx={{ fontFamily: "Mont-SemiBold", fontSize: "1.8rem" }}>
                  Data Models
                </Typography>
                <Grid
                  xs={10}
                  sx={{ height: "1.5vh", backgroundColor: "red" }}
                  item></Grid>
              </Grid>
              <Grid sx={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ position: "relative", width: "50%" }}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      borderRadius: "10px",
                      border: "1.5px solid #c1c4c8",
                      paddingLeft: "0.5rem",
                      backgroundColor: "white",
                    }}>
                    <FiSearch fontSize="x-large" />
                    <input
                      type="text"
                      value={customTableNameFilter}
                      placeholder="Search By Table Name"
                      onInput={(e) => {
                        console.log("e.target::::::", e.target.value);
                        handleCustomerNicknameChange(e);
                      }}
                      style={{
                        outline: "none",
                        border: "none",
                        width: "80%",
                        paddingTop: "0.5rem",
                        paddingBottom: "0.5rem",
                        marginLeft: "0.5rem",
                      }}
                    />
                  </div>

                  {filteredNicknameOptions.length > 0 && (
                    <div
                      style={{ zIndex: 11, top: "3rem" }}
                      className={
                        searchFilterOptionsShowNickname.current
                          ? "position-absolute  lg-max-h-12rem  xl-max-h-12rem  2xl-max-h-13rem overflow-auto w-100 rounded-lg shadow d-block "
                          : "d-none"
                      }>
                      {filteredNicknameOptions.map((option, index) => (
                        <div
                          className="custom-search-filter"
                          onClick={() => {
                            setSelectedCustomTableName(option.table_name);
                            setCustomTableName(option.table_name);
                          }}
                          key={index}>
                          {option.table_name}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
                {tableNameModal && (
                  <dialog className={classes.tableNameModal}>
                    <label htmlFor="table-name">Table Name:</label>
                    <input
                      type="text"
                      id="table-name"
                      name="table-name"
                      className={classes.tableNameModalInput}
                      ref={tableNameRef}
                    />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        columnGap: "1em",
                      }}>
                      <button
                        onClick={tableNameSubmitHandler}
                        type="submit"
                        variant="contained"
                        className={classes.tableNameModalSubmit}>
                        Submit
                      </button>
                      <button
                        onClick={() => setTableNameModal(false)}
                        variant="contained"
                        className={classes.tableNameModalSubmit}>
                        Close
                      </button>
                    </div>
                  </dialog>
                )}
                <input
                  type="file"
                  id="file-input"
                  accept=".csv, .xlsx"
                  ref={xlFileInputRef}
                  style={{ display: "none" }} // Hide the input element
                />
                {isAllowedUser && (
                  <Button
                    title="External Data APIs"
                    onClick={externalDataHandler}
                    variant="contained"
                    sx={{
                      fontFamily: "Mont-Regular",
                      backgroundColor: "red",
                      marginBottom: "3vh",
                      marginRight: "1em",
                      "&:hover": { color: "red", backgroundColor: "white" },
                      transitionDuration: "0.5s",
                    }}>
                    External Data APIs
                  </Button>
                )}

                <div style={{ display: "flex" }}>
                  <Button
                    title="Import excel/CSV file"
                    onClick={importHandler}
                    variant="contained"
                    sx={{
                      fontFamily: "Mont-Regular",
                      backgroundColor: "red",
                      marginBottom: "3vh",
                      marginRight: "1em",
                      "&:hover": { color: "red", backgroundColor: "white" },
                      transitionDuration: "0.5s",
                    }}>
                    Import File
                  </Button>

                  <Button
                    onClick={createTable}
                    variant="contained"
                    sx={{
                      fontFamily: "Mont-Regular",
                      backgroundColor: "red",
                      marginBottom: "3vh",
                      "&:hover": { color: "red", backgroundColor: "white" },
                      transitionDuration: "0.5s",
                    }}>
                    Create New Table
                  </Button>
                </div>
              </Grid>
              <DataGrid
                rows={rows}
                columns={columns}
                pageSize={5}
                error={error}
                rowsPerPageOptions={[5]}
                // rowHeight={80}
                checkboxSelection
                disableSelectionOnClick
                getRowHeight={() => "auto"}
                localeText={{ noRowsLabel: "No Tables created" }}
              />
            </Box>
          </Grid>
        </Grid>
      )}

      <Modal
        open={open}
        onClose={handleClose}
        sx={{
          border: "none",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}>
        <Box
          sx={{
            overflowY: "scroll",
            maxHeight: "80vh",
            width: { lg: "80vw" },
            padding: "2vh 2vw 6vh 2vw",
            outline: "none",
            borderRadius: "6px",
            backgroundColor: "white",
            position: "relative",
          }}>
          <Grid sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              onClick={handleClose}
              sx={{ boxShadow: 2, color: "red", "&:hover": { boxShadow: 5 } }}>
              <CloseIcon />
            </Button>
          </Grid>
          <Grid
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mt: 2,
            }}>
            <Typography
              sx={{ fontFamily: "Mont-SemiBold", fontSize: "1.3rem" }}>
              Update Data Model
            </Typography>
            <Grid
              item
              xs={9}
              sx={{ backgroundColor: "red", height: "1vh" }}></Grid>
          </Grid>

          <Grid container sx={{ mt: 4 }}>
            <Grid item sx={{ width: "100%" }}>
              <TextField
                onChange={(e) => setTableName(e.target.value)}
                id="outlined-basic"
                label="Table Name"
                variant="outlined"
                sx={{ width: "100%" }}
                disabled
                defaultValue={modalData?.table_name1}
              />
            </Grid>
          </Grid>
          <Divider sx={{ mt: 2, border: "1px solid gray !important" }} />
          <Grid
            container
            sx={{ display: "flex", justifyContent: "space-between", mb: 3 }}>
            {modalData?.fields.map((x, index) => (
              <>
                <Grid item xs={5} sx={{ mt: 3 }}>
                  <TextField
                    onChange={(e) => {
                      const updatedFields = [...modalData.fields];
                      updatedFields[index].field_name = e.target.value;
                      setModalData({ ...modalData, fields: updatedFields });
                    }}
                    id="outlined-basic"
                    label="Field Name"
                    variant="outlined"
                    sx={{ width: "100%" }}
                    defaultValue={x.field_name}
                  />
                </Grid>
                {x.field_type === "Reference" && (
                  <Grid item xs={2.5} sx={{ mt: 1.5 }}>
                    {" "}
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Field Type
                      </InputLabel>
                      <Select
                        key={index}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={x.field_type}
                        label="Field Type"
                        onChange={(e) => {
                          const updatedFields = [...modalData.fields];
                          updatedFields[index].field_type = e.target.value;
                          setModalData({ ...modalData, fields: updatedFields });
                        }}>
                        <MenuItem value={"String"}>String</MenuItem>
                        <MenuItem value={"Boolean"}>Boolean</MenuItem>
                        <MenuItem value={"Number"}>Number</MenuItem>
                        <MenuItem value={"Reference"}>Reference</MenuItem>
                        <MenuItem value={"Date-Time"}>Date-Time</MenuItem>
                        <MenuItem value={"Time"}>Time</MenuItem>
                        <MenuItem value={"File"}>File</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                )}
                {x.field_type !== "Reference" && (
                  <Grid item xs={5} sx={{ mt: 3 }}>
                    {" "}
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Field Type
                      </InputLabel>
                      <Select
                        key={index}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={x.field_type}
                        label="Field Type"
                        onChange={(e) => {
                          const updatedFields = [...modalData.fields];
                          updatedFields[index].field_type = e.target.value;
                          setModalData({ ...modalData, fields: updatedFields });
                        }}>
                        <MenuItem value={"String"}>String</MenuItem>
                        <MenuItem value={"Boolean"}>Boolean</MenuItem>
                        <MenuItem value={"Number"}>Number</MenuItem>
                        <MenuItem value={"Reference"}>Reference</MenuItem>
                        <MenuItem value={"Date-Time"}>Date-Time</MenuItem>
                        <MenuItem value={"Time"}>Time</MenuItem>
                        <MenuItem value={"File"}>File</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                )}

                {x.field_type === "Reference" && (
                  <Grid item xs={2.5} sx={{ mt: 1.5 }}>
                    <FormControl fullWidth>
                      <InputLabel>Reference Table</InputLabel>
                      <Select
                        labelId={`demo-simple-select-label`}
                        id={`demo-simple-select`}
                        label="Select Reference Table"
                        value={x?.referenceTableId || ""}
                        onChange={(e) => {
                          if (
                            index >= 0 &&
                            index < tableReferenceList?.length
                          ) {
                            handleReferenceChange(e, index);
                          } else {
                            //console.log("handleReferenceChange(e, index) out of bounds");
                          }
                        }}>
                        {tableReferenceList.length
                          ? tableReferenceList.map((y, i) => (
                              <MenuItem
                                key={`${y.value}-${i}`}
                                data-id={i}
                                data-category={y.name}
                                value={y.value}>
                                {y.name}
                              </MenuItem>
                            ))
                          : ""}

                        {/* Add the options for the reference select */}
                      </Select>
                    </FormControl>
                  </Grid>
                )}
                <Grid xs={1}>
                  {index === modalData?.fields.length - 1 ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "42px ",
                        fontSize: "50px",
                        marginLeft: "8px",
                        color: "red",
                      }}
                      onClick={() => setFields(index)}>
                      <AddCircleIcon fontSize="1rem" />
                    </div>
                  ) : (
                    <div></div>
                  )}{" "}
                </Grid>
              </>
            ))}
          </Grid>
          <Button
            onClick={updateModelHandler}
            variant="contained"
            sx={{
              backgroundColor: "red",
              "&:hover": { color: "red", backgroundColor: "white" },
            }}>
            Save Changes
          </Button>
          {/* <Grid sx={{ mt: 4, display: 'flex', justifyContent: 'space-between' }}>

                    </Grid> */}
        </Box>
      </Modal>

      <ToastContainer autoClose={2000} />
    </div>
  );
}

// const mapStateToProps = (state) => {
//     return {
//         customTables: state.customTables
//     }
// }

// const mapDispatchToProps = (dispatch) => {
//     return {
//         getAllModels: (project_id) => dispatch(getAllModels(project_id)),
//     }
// }
// export const withRouter = (Component) => {
//     const Wrapper = (props) => {
//         const navigate = useNavigate();

//         return (
//             <Component
//                 navigate={navigate}
//                 {...props}
//                 params={useParams()}
//             />
//         );
//     };

//     return Wrapper;
// };
// const shareLocationWithRouter = withRouter(AllCustomModels)
// export default AllCustomModels
// export default connect(mapStateToProps, mapDispatchToProps)((props) => {
//     return (
//         <AllCustomModels  {...props}
//             params={useParams()}
//             navigate={useNavigate()}
//         />
//     )
// })
