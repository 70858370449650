// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ProjectAnalytics_projectAnalytics__Oy\\+-R {
  margin: 20px;
}

.ProjectAnalytics_tableContainer__1pdGW {
  overflow-x: auto;
}

.ProjectAnalytics_dataTable__Eg-aS {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #ddd;
}

.ProjectAnalytics_dataTable__Eg-aS th,
.ProjectAnalytics_dataTable__Eg-aS td {
  padding: 10px;
  text-align: center;
  border: 1px solid #ddd;
}

.ProjectAnalytics_dataTable__Eg-aS th {
  background-color: #f5f5f5;
  font-weight: bold;
}

.ProjectAnalytics_dataTable__Eg-aS tbody tr:nth-child(even) {
  background-color: #f9f9f9;
}
`, "",{"version":3,"sources":["webpack://./src/Screens/ScreensStylings/ProjectAnalytics.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;AACd;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,yBAAyB;EACzB,sBAAsB;AACxB;;AAEA;;EAEE,aAAa;EACb,kBAAkB;EAClB,sBAAsB;AACxB;;AAEA;EACE,yBAAyB;EACzB,iBAAiB;AACnB;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".projectAnalytics {\n  margin: 20px;\n}\n\n.tableContainer {\n  overflow-x: auto;\n}\n\n.dataTable {\n  width: 100%;\n  border-collapse: collapse;\n  border: 1px solid #ddd;\n}\n\n.dataTable th,\n.dataTable td {\n  padding: 10px;\n  text-align: center;\n  border: 1px solid #ddd;\n}\n\n.dataTable th {\n  background-color: #f5f5f5;\n  font-weight: bold;\n}\n\n.dataTable tbody tr:nth-child(even) {\n  background-color: #f9f9f9;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"projectAnalytics": `ProjectAnalytics_projectAnalytics__Oy+-R`,
	"tableContainer": `ProjectAnalytics_tableContainer__1pdGW`,
	"dataTable": `ProjectAnalytics_dataTable__Eg-aS`
};
export default ___CSS_LOADER_EXPORT___;
