const mainBody = document.createElement('div');

// for bootstrap icons in social media
const cdn = document.createElement('link');
cdn.setAttribute('rel', 'stylesheet');
cdn.setAttribute('href', 'https://cdn.jsdelivr.net/npm/bootstrap-icons@1.9.1/font/bootstrap-icons.css');
mainBody.appendChild(cdn);

const header = document.createElement('div');
header.setAttribute('class', 'header');
mainBody.appendChild(header);

header.innerHTML = `
	<div>
        <img src="/templates/images/redSlingLogo.png" />
      </div>
      <div class="header-right-section1">
        <p>Admin</p>
        <img src="/templates/images/Vector.png" />
    </div>
`;

const whiteWaveOverlay1 = document.createElement('div');
whiteWaveOverlay1.setAttribute('class', 'whiteWaveOverlay1');
mainBody.appendChild(whiteWaveOverlay1);

whiteWaveOverlay1.innerHTML = `
	<img src="/templates/images/Ellipse 6.png" />
    <img class="whiteWave2" src="/templates/images/white waves 2.png" />
`;

const checkoutDiv = document.createElement('div');
checkoutDiv.setAttribute('class', 'checkoutDiv');
mainBody.appendChild(checkoutDiv);

checkoutDiv.innerHTML = `
	<!-- dark bubble -->
      <img class="checkout-d-bb" src="/templates/images/darkBubble.png" />

      <!-- checkout bread -->
      <div class="checkout-bread">
        <div>
          <p class="circle1"></p>
          <p class="h-line"></p>
          <p class="circle2"></p>
          <p class="h-line"></p>
          <p class="circle2"></p>
        </div>
        <div>
          <p>Cart</p>
          <p>Details</p>
          <p>Payment</p>
        </div>
      </div>

      <div class="detail-Main">
        <p class="shippingAdd">Saved Payment Methods</p>

        <div class="savedDebitCard">
          <div>
            <img src="/templates/images/atm_debit.png" />
            <input placeholder="4125 XXXX XXXX 8742" type="text" />
          </div>
          <p></p>
        </div>

        <p class="shippingAdd">Add New Method</p>
        <div class="savedDebitCard2">
          <div>
            <img src="/templates/images/atm_debit.png" />
            <input placeholder="ATM/Debit Card" type="text" />
          </div>
          <p></p>
        </div>

        <div class="savedDebitCard1">
          <div>
            <img src="/templates/images/CreditCard1.png" />
            <input placeholder="Credit Card" type="text" />
          </div>
          <p></p>
        </div>
        <div class="savedDebitCard1">
          <div>
            <img src="/templates/images/emi.png" />
            <input placeholder="EMI" type="text" />
          </div>
          <p></p>
        </div>
        <div class="savedDebitCard1">
          <div>
            <img src="/templates/images/bankVector.png" />
            <input placeholder="Internet Banking" type="text" />
          </div>
          <p></p>
        </div>
        <div class="savedDebitCard1">
          <div>
            <img src="/templates/images/upi.png" />
            <input placeholder="Google Pay/UPI" type="text" />
          </div>
          <p></p>
        </div>
        <div class="savedDebitCard1">
          <div>
            <img src="/templates/images/wallet1.png" />
            <input placeholder="Wallets" type="text" />
          </div>
          <p></p>
        </div>
        <div class="savedDebitCard1">
          <div>
            <img src="/templates/images/internationalCards.png" />
            <input placeholder="International Cards" type="text" />
          </div>
          <p></p>
        </div>
        <div class="savedDebitCard3">
          <div>
            <img src="/templates/images/atm_debit.png" />
            <input placeholder="Gift Card" type="text" />
          </div>
          <p></p>
        </div>
        <p class="paymentNote">
          Note: You will be asked to enter your details on proceeding with the
          selected payment method
        </p>
        <div class="paymentBottomButtom">
          <button>Proceed To Pay</button>
        </div>
      </div>

      <style>
      @media (max-width: 768px) {
	    	.header {
			    display: flex;
			    justify-content: space-between;
			    align-items: center;
			    position: relative;
			    padding: 2vh 2.5vw;
				}
				.header-right-section1 img {
				    width: 4vw;
				    height: 3vh;
				    margin-left: 5%;
				  }
				  .header-right-section1 p {
				    font-family: Mont-Regular;
				    font-size: 1rem;
				    margin-right: 5%;
				  }
				  .whiteWaveOverlay1 img:first-child {
				    width: 50vw;
				    position: absolute;
				    top: 0;
				    right: 0;
				  }
				  .whiteWave2 {
				    width: 90vw;
				    position: absolute;
				    top: 0;
				    right: 0;
				  }
				  .checkoutDiv {
				    padding-left: 4vw;
				    padding-right: 4vw;
				  }
				  .checkout-d-bb {
				    display: none;
				  }
				  .checkout-bread {
				    margin-top: 4vh;
				  }

				  .checkout-bread div:last-child {
				    font-size: 1.2rem;
				  }

				  .checkout-bread div:last-child p {
				    padding-left: 5vw;
				    text-align: end;
				  }

				  .circle1 {
				    width: 2em;
				    height: 2em;
				  }

				  .circle2 {
				    width: 2em;
				    height: 2em;
				    border: 4px solid rgba(237, 28, 36, 0.4);
				  }
				  .detail-Main {
				    padding-left: 4.6vw;
				    padding-bottom: 4vh;
				    margin-top: 12%;
				  }
				  .shippingAdd {
				    font-family: Mont-SemiBold;
				    font-size: 1.2rem;
				  }
				  .savedDebitCard {
				    width: 95%;
				    padding-right: 2vw;
				    margin-bottom: 4vh;
				  }

				  .savedDebitCard div {
				    width: 95%;
				  }

				  .savedDebitCard input {
				    width: 80%;
				  }

				  .savedDebitCard p {
				    height: 1em;
				    width: 1em;
				  }
				  .savedDebitCard2 {
				    width: 95%;
				  }

				  .savedDebitCard2 div {
				    width: 95%;
				  }

				  .savedDebitCard2 input {
				    width: 80%;
				  }

				  .savedDebitCard2 p {
				    height: 1em;
				    width: 1em;
				  }
				  .savedDebitCard1 {
				    width: 95%;
				    padding-right: 2vw;
				  }

				  .savedDebitCard1 div {
				    width: 95%;
				  }

				  .savedDebitCard1 input {
				    width: 80%;
				  }

				  .savedDebitCard1 p {
				    width: 1em;
				  }
				  .savedDebitCard3 {
				    width: 95%;
				  }

				  .savedDebitCard3 div {
				    width: 95%;
				  }

				  .savedDebitCard3 input {
				    width: 80%;
				  }

				  .savedDebitCard3 p {
				    width: 1em;
				  }
				  .paymentNote {
				    margin-top: 6%;
				    line-height: 150%;
				  }

				  .paymentBottomButtom {
				    margin-top: 12%;
				  }

				  .paymentBottomButtom button {
				    width: 100%;
				  }
				}
      </style>
`;

const templatePaymentPage = mainBody.outerHTML;
export { templatePaymentPage };