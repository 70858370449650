// reading json data

// const data = null;

// fetch('./analyticsData.json')
//     .then((response) => response.json())
//     .then((json) => {
//         // console.log(json);
//     })

const data = [{
        "S. No.": 1,
        "SKU": 2562,
        "Date Created": "11\/16\/2021",
        "Product Title": "Divi Engine String Bag (Big Logo)",
        "Product Type": "Handbag",
        "Shipping time": "2 days",
        "Material": "Leather",
        "Size": "NA",
        "Fit": "NA",
        "Color": "Red",
        "Style": "Printed",
        "Pattern": "NA",
        "Package Includes": "1 N",
        "Origin": "India",
        "Size Variants": "NA",
        "Color Variants": "NA",
        "Selling Price": 499,
        "Shipping Cost": 250,
        // "MRP": 749,
        "Quantity": 100,
        "Total Units Sold": 50,
        // "Total Sale Value": 24950,
        "Total Visitors": 2500,
        "New Visitors": 998,
        // "Returning Visitors": 1502,
        // "Conversion Rate": 0.02,
        "Added To Cart": 156, // "Initiated Checkout": 81,
        // "Completed Checkout": 56,
        // "Total Abandoned Checkouts": 25
    },
    {
        "S. No.": 2,
        "SKU": 5155,
        "Date Created": "11\/16\/2021",
        "Product Title": "Divi Engine String Bag (Small Logos)",
        "Product Type": "Handbag",
        "Shipping time": "2 days",
        "Material": "Leather",
        "Size": "NA",
        "Fit": "NA",
        "Color": "Blue",
        "Style": "Printed",
        "Pattern": "NA",
        "Package Includes": "1 N",
        "Origin": "India",
        "Size Variants": "NA",
        "Color Variants": "NA",
        "Selling Price": 499,
        "Shipping Cost": 250,
        // "MRP": 749,
        "Quantity": 100,
        "Total Units Sold": 8,
        // "Total Sale Value": 3992,
        "Total Visitors": 3000,
        "New Visitors": 1000,
        // "Returning Visitors": 2000,
        // "Conversion Rate": 0.0026666666666666666,
        "Added To Cart": 125,
        // "Initiated Checkout": 50,
        // "Completed Checkout": 25,
        // "Total Abandoned Checkouts": 25
    },
    {
        "S. No.": 3,
        "SKU": 5425,
        "Date Created": "11\/16\/2021",
        "Product Title": "Brand Buttons",
        "Product Type": "Button",
        "Shipping time": "2 days",
        "Material": "Metal",
        "Size": "Small",
        "Fit": "NA",
        "Color": "Yellow",
        "Style": "Printed",
        "Pattern": "NA",
        "Package Includes": "1 N",
        "Origin": "India",
        "Size Variants": "NA",
        "Color Variants": "NA",
        "Selling Price": 499,
        "Shipping Cost": 250,
        // "MRP": 749,
        "Quantity": 120,
        "Total Units Sold": 9,
        // "Total Sale Value": 4491,
        "Total Visitors": 1566,
        "New Visitors": 1200,
        // "Returning Visitors": 366,
        // "Conversion Rate": 0.005747126436781609,
        "Added To Cart": 256,
        // "Initiated Checkout": 181,
        // "Completed Checkout": 156,
        // "Total Abandoned Checkouts": 25
    },
    {
        "S. No.": 4,
        "SKU": 1546,
        "Date Created": "11\/16\/2021",
        "Product Title": "Brand Buttons - Divi",
        "Product Type": "Button",
        "Shipping time": "2 days",
        "Material": "Metal",
        "Size": "Small",
        "Fit": "NA",
        "Color": "White",
        "Style": "Printed",
        "Pattern": "NA",
        "Package Includes": "1 N",
        "Origin": "India",
        "Size Variants": "NA",
        "Color Variants": "NA",
        "Selling Price": 299,
        "Shipping Cost": 250,
        // "MRP": 549,
        "Quantity": 80,
        "Total Units Sold": 5,
        // "Total Sale Value": 1495,
        "Total Visitors": 3500,
        "New Visitors": 1569,
        // "Returning Visitors": 1931,
        // "Conversion Rate": 0.0014285714285714286,
        "Added To Cart": 243,
        // "Initiated Checkout": 168,
        // "Completed Checkout": 143,
        // "Total Abandoned Checkouts": 25
    },
    {
        "S. No.": 5,
        "SKU": 1524,
        "Date Created": "11\/16\/2021",
        "Product Title": "Brand Buttons - Divi Engine",
        "Product Type": "Button",
        "Shipping time": "6 days",
        "Material": "Metal",
        "Size": "Small",
        "Fit": "NA",
        "Color": "Blue",
        "Style": "Printed",
        "Pattern": "NA",
        "Package Includes": "1 N",
        "Origin": "India",
        "Size Variants": "NA",
        "Color Variants": "NA",
        "Selling Price": 299,
        "Shipping Cost": 250,
        // "MRP": 549,
        "Quantity": 106,
        "Total Units Sold": 10,
        // "Total Sale Value": 2990,
        "Total Visitors": 8900,
        "New Visitors": 1456,
        // "Returning Visitors": 7444,
        // "Conversion Rate": 0.0011235955056179776,
        "Added To Cart": 289,
        // "Initiated Checkout": 214,
        // "Completed Checkout": 189,
        // "Total Abandoned Checkouts": 25
    },
    {
        "S. No.": 6,
        "SKU": 3540,
        "Date Created": "11\/16\/2021",
        "Product Title": "Brand Buttons - WooCommerce",
        "Product Type": "Button",
        "Shipping time": "2 days",
        "Material": "Metal",
        "Size": "Small",
        "Fit": "NA",
        "Color": "Yellow",
        "Style": "Printed",
        "Pattern": "NA",
        "Package Includes": "1 N",
        "Origin": "India",
        "Size Variants": "NA",
        "Color Variants": "NA",
        "Selling Price": 299,
        "Shipping Cost": 250,
        // "MRP": 549,
        "Quantity": 220,
        "Total Units Sold": 11,
        // "Total Sale Value": 3289,
        "Total Visitors": 2564,
        "New Visitors": 1256,
        // "Returning Visitors": 1308,
        // "Conversion Rate": 0.004290171606864275,
        "Added To Cart": 267,
        // "Initiated Checkout": 192,
        // "Completed Checkout": 167,
        // "Total Abandoned Checkouts": 25
    },
    {
        "S. No.": 7,
        "SKU": 8482,
        "Date Created": "11\/16\/2021",
        "Product Title": "Brand Buttons - WordPress",
        "Product Type": "Button",
        "Shipping time": "2 days",
        "Material": "Metal",
        "Size": "Small",
        "Fit": "NA",
        "Color": "Yellow",
        "Style": "Printed",
        "Pattern": "NA",
        "Package Includes": "1 N",
        "Origin": "India",
        "Size Variants": "NA",
        "Color Variants": "NA",
        "Selling Price": 269,
        "Shipping Cost": 250,
        // "MRP": 519,
        "Quantity": 150,
        "Total Units Sold": 12,
        // "Total Sale Value": 3228,
        "Total Visitors": 4896,
        "New Visitors": 1236,
        // "Returning Visitors": 3660,
        // "Conversion Rate": 0.0024509803921568627,
        "Added To Cart": 125,
        // "Initiated Checkout": 50,
        // "Completed Checkout": 25,
        // "Total Abandoned Checkouts": 25
    },
    {
        "S. No.": 8,
        "SKU": 3563,
        "Date Created": "11\/16\/2021",
        "Product Title": "Lanyard",
        "Product Type": "Rope",
        "Shipping time": "1 day",
        "Material": "Nylon",
        "Size": "NA",
        "Fit": "NA",
        "Color": "Blue",
        "Style": "NA",
        "Pattern": "NA",
        "Package Includes": "1 N",
        "Origin": "India",
        "Size Variants": "NA",
        "Color Variants": "NA",
        "Selling Price": 269,
        "Shipping Cost": 250,
        // "MRP": 519,
        "Quantity": 110,
        "Total Units Sold": 10,
        // "Total Sale Value": 2690,
        "Total Visitors": 258,
        "New Visitors": 250,
        // "Returning Visitors": 8,
        // "Conversion Rate": 0.03875968992248062,
        "Added To Cart": 148,
        // "Initiated Checkout": 73,
        // "Completed Checkout": 48,
        // "Total Abandoned Checkouts": 25
    },
    {
        "S. No.": 9,
        "SKU": 2365,
        "Date Created": "11\/16\/2021",
        "Product Title": "Divi Engine Tee",
        "Product Type": "T-Shirt",
        "Shipping time": "4 days",
        "Material": "Cotton",
        "Size": "Small",
        "Fit": "Regular",
        "Color": "Blue",
        "Style": "Printed",
        "Pattern": "Geometric",
        "Package Includes": "1 N",
        "Origin": "India",
        "Size Variants": "NA",
        "Color Variants": "NA",
        "Selling Price": 499,
        "Shipping Cost": 250,
        // "MRP": 749,
        "Quantity": 102,
        "Total Units Sold": 90,
        // "Total Sale Value": 44910,
        "Total Visitors": 8900,
        "New Visitors": 1236,
        // "Returning Visitors": 7664,
        // "Conversion Rate": 0.010112359550561797,
        "Added To Cart": 146,
        // "Initiated Checkout": 71,
        // "Completed Checkout": 46,
        // "Total Abandoned Checkouts": 25
    },
    {
        "S. No.": 10,
        "SKU": 6526,
        "Date Created": "11\/16\/2021",
        "Product Title": "Divi Engine Tee - Blue, Large",
        "Product Type": "T-Shirt",
        "Shipping time": "2 days",
        "Material": "Cotton",
        "Size": "Large",
        "Fit": "Regular",
        "Color": "Blue",
        "Style": "Printed",
        "Pattern": "Geometric",
        "Package Includes": "1 N",
        "Origin": "India",
        "Size Variants": "NA",
        "Color Variants": "NA",
        "Selling Price": 499,
        "Shipping Cost": 250,
        // "MRP": 749,
        "Quantity": 108,
        "Total Units Sold": 50,
        // "Total Sale Value": 24950,
        "Total Visitors": 8900,
        "New Visitors": 1589,
        // "Returning Visitors": 7311,
        // "Conversion Rate": 0.0056179775280898875,
        "Added To Cart": 189,
        // "Initiated Checkout": 114,
        // "Completed Checkout": 89,
        // "Total Abandoned Checkouts": 25
    },
    {
        "S. No.": 11,
        "SKU": 5536,
        "Date Created": "11\/16\/2021",
        "Product Title": "Divi Engine Tee - White, Large",
        "Product Type": "T-Shirt",
        "Shipping time": "4 days",
        "Material": "Cotton",
        "Size": "Large",
        "Fit": "Regular",
        "Color": "White",
        "Style": "Printed",
        "Pattern": "Geometric",
        "Package Includes": "1 N",
        "Origin": "India",
        "Size Variants": "NA",
        "Color Variants": "NA",
        "Selling Price": 499,
        "Shipping Cost": 250,
        // "MRP": 749,
        "Quantity": 140,
        "Total Units Sold": 40,
        // "Total Sale Value": 19960,
        "Total Visitors": 8563,
        "New Visitors": 1236,
        // "Returning Visitors": 7327,
        // "Conversion Rate": 0.004671260072404531,
        "Added To Cart": 136,
        // "Initiated Checkout": 61,
        // "Completed Checkout": 36,
        // "Total Abandoned Checkouts": 25
    },
    {
        "S. No.": 12,
        "SKU": 6265,
        "Date Created": "11\/16\/2021",
        "Product Title": "Divi Engine Tee - Yellow, Large",
        "Product Type": "T-Shirt",
        "Shipping time": "4 days",
        "Material": "Cotton",
        "Size": "Large",
        "Fit": "Regular",
        "Color": "Yellow",
        "Style": "Printed",
        "Pattern": "Geometric",
        "Package Includes": "1 N",
        "Origin": "India",
        "Size Variants": "NA",
        "Color Variants": "NA",
        "Selling Price": 499,
        "Shipping Cost": 250,
        // "MRP": 749,
        "Quantity": 150,
        "Total Units Sold": 60,
        // "Total Sale Value": 29940,
        "Total Visitors": 4625,
        "New Visitors": 1896,
        // "Returning Visitors": 2729,
        // "Conversion Rate": 0.012972972972972972,
        "Added To Cart": 189,
        // "Initiated Checkout": 114,
        // "Completed Checkout": 89,
        // "Total Abandoned Checkouts": 25
    },
    {
        "S. No.": 13,
        "SKU": 2566,
        "Date Created": "11\/16\/2021",
        "Product Title": "Divi Engine Tee - Blue, Medium",
        "Product Type": "T-Shirt",
        "Shipping time": "4 days",
        "Material": "Cotton",
        "Size": "Medium",
        "Fit": "Regular",
        "Color": "Blue",
        "Style": "Printed",
        "Pattern": "Geometric",
        "Package Includes": "1 N",
        "Origin": "India",
        "Size Variants": "NA",
        "Color Variants": "NA",
        "Selling Price": 499,
        "Shipping Cost": 250,
        // "MRP": 749,
        "Quantity": 160,
        "Total Units Sold": 50,
        // "Total Sale Value": 24950,
        "Total Visitors": 2358,
        "New Visitors": 1456,
        // "Returning Visitors": 902,
        // "Conversion Rate": 0.021204410517387615,
        "Added To Cart": 124,
        // "Initiated Checkout": 49,
        // "Completed Checkout": 24,
        // "Total Abandoned Checkouts": 25
    },
    {
        "S. No.": 14,
        "SKU": 5214,
        "Date Created": "11\/16\/2021",
        "Product Title": "Divi Engine Tee - White, Medium",
        "Product Type": "T-Shirt",
        "Shipping time": "2 days",
        "Material": "Cotton",
        "Size": "Medium",
        "Fit": "Regular",
        "Color": "White",
        "Style": "Printed",
        "Pattern": "Geometric",
        "Package Includes": "1 N",
        "Origin": "India",
        "Size Variants": "NA",
        "Color Variants": "NA",
        "Selling Price": 499,
        "Shipping Cost": 250,
        // "MRP": 749,
        "Quantity": 130,
        "Total Units Sold": 20,
        // "Total Sale Value": 9980,
        "Total Visitors": 1256,
        "New Visitors": 968,
        // "Returning Visitors": 288,
        // "Conversion Rate": 0.01592356687898089,
        "Added To Cart": 365,
        // "Initiated Checkout": 290,
        // "Completed Checkout": 265,
        // "Total Abandoned Checkouts": 25
    },
    {
        "S. No.": 15,
        "SKU": 5285,
        "Date Created": "11\/16\/2021",
        "Product Title": "Divi Engine Tee - Yellow, Medium",
        "Product Type": "T-Shirt",
        "Shipping time": "2 days",
        "Material": "Cotton",
        "Size": "Medium",
        "Fit": "Regular",
        "Color": "Yellow",
        "Style": "Printed",
        "Pattern": "Geometric",
        "Package Includes": "1 N",
        "Origin": "India",
        "Size Variants": "NA",
        "Color Variants": "NA",
        "Selling Price": 499,
        "Shipping Cost": 250,
        // "MRP": 749,
        "Quantity": 105,
        "Total Units Sold": 30,
        // "Total Sale Value": 14970,
        "Total Visitors": 8965,
        "New Visitors": 1256,
        // "Returning Visitors": 7709,
        // "Conversion Rate": 0.0033463469046291134,
        "Added To Cart": 387,
        // "Initiated Checkout": 312,
        // "Completed Checkout": 287,
        // "Total Abandoned Checkouts": 25
    },
    {
        "S. No.": 16,
        "SKU": 6552,
        "Date Created": "11\/16\/2021",
        "Product Title": "Divi Engine Tee - Blue, Small",
        "Product Type": "T-Shirt",
        "Shipping time": "2 days",
        "Material": "Cotton",
        "Size": "Small",
        "Fit": "Regular",
        "Color": "Blue",
        "Style": "Printed",
        "Pattern": "Geometric",
        "Package Includes": "1 N",
        "Origin": "India",
        "Size Variants": "NA",
        "Color Variants": "NA",
        "Selling Price": 499,
        "Shipping Cost": 250,
        // "MRP": 749,
        "Quantity": 130,
        "Total Units Sold": 80,
        // "Total Sale Value": 39920,
        "Total Visitors": 45632,
        "New Visitors": 25498,
        // "Returning Visitors": 20134,
        // "Conversion Rate": 0.0017531556802244039,
        "Added To Cart": 397,
        // "Initiated Checkout": 322,
        // "Completed Checkout": 297,
        // "Total Abandoned Checkouts": 25
    },
    {
        "S. No.": 17,
        "SKU": 2354,
        "Date Created": "11\/16\/2021",
        "Product Title": "Divi Engine Tee - White, Small",
        "Product Type": "T-Shirt",
        "Shipping time": "6 days",
        "Material": "Cotton",
        "Size": "Small",
        "Fit": "Regular",
        "Color": "White",
        "Style": "Printed",
        "Pattern": "Geometric",
        "Package Includes": "1 N",
        "Origin": "India",
        "Size Variants": "NA",
        "Color Variants": "NA",
        "Selling Price": 499,
        "Shipping Cost": 250,
        // "MRP": 749,
        "Quantity": 110,
        "Total Units Sold": 60,
        // "Total Sale Value": 29940,
        "Total Visitors": 2569,
        "New Visitors": 2563,
        // "Returning Visitors": 6,
        // "Conversion Rate": 0.023355391202802646,
        "Added To Cart": 258,
        // "Initiated Checkout": 183,
        // "Completed Checkout": 158,
        // "Total Abandoned Checkouts": 25
    },
    {
        "S. No.": 18,
        "SKU": 5614,
        "Date Created": "11\/16\/2021",
        "Product Title": "Divi Engine Tee - Yellow, Small",
        "Product Type": "T-Shirt",
        "Shipping time": "6 days",
        "Material": "Cotton",
        "Size": "Small",
        "Fit": "Regular",
        "Color": "Yellow",
        "Style": "Printed",
        "Pattern": "Geometric",
        "Package Includes": "1 N",
        "Origin": "India",
        "Size Variants": "NA",
        "Color Variants": "NA",
        "Selling Price": 499,
        "Shipping Cost": 250,
        // "MRP": 749,
        "Quantity": 101,
        "Total Units Sold": 90,
        // "Total Sale Value": 44910,
        "Total Visitors": 2145,
        "New Visitors": 1254,
        // "Returning Visitors": 891,
        // "Conversion Rate": 0.04195804195804196,
        "Added To Cart": 289,
        // "Initiated Checkout": 214,
        // "Completed Checkout": 189,
        // "Total Abandoned Checkouts": 25
    },
    {
        "S. No.": 19,
        "SKU": 5468,
        "Date Created": "11\/16\/2021",
        "Product Title": "Divi Tee",
        "Product Type": "T-Shirt",
        "Shipping time": "6 days",
        "Material": "Cotton",
        "Size": "Small",
        "Fit": "Regular",
        "Color": "White",
        "Style": "Printed",
        "Pattern": "Geometric",
        "Package Includes": "1 N",
        "Origin": "India",
        "Size Variants": "NA",
        "Color Variants": "NA",
        "Selling Price": 499,
        "Shipping Cost": 250,
        // "MRP": 749,
        "Quantity": 120,
        "Total Units Sold": 40,
        // "Total Sale Value": 19960,
        "Total Visitors": 7896,
        "New Visitors": 3659,
        // "Returning Visitors": 4237,
        // "Conversion Rate": 0.005065856129685917,
        "Added To Cart": 268,
        // "Initiated Checkout": 193,
        // "Completed Checkout": 168,
        // "Total Abandoned Checkouts": 25
    },
    {
        "S. No.": 20,
        "SKU": 8565,
        "Date Created": "11\/16\/2021",
        "Product Title": "Divi Tee - Large",
        "Product Type": "T-Shirt",
        "Shipping time": "2 days",
        "Material": "Cotton",
        "Size": "Large",
        "Fit": "Regular",
        "Color": "White",
        "Style": "Printed",
        "Pattern": "Geometric",
        "Package Includes": "1 N",
        "Origin": "India",
        "Size Variants": "NA",
        "Color Variants": "NA",
        "Selling Price": 499,
        "Shipping Cost": 250,
        // "MRP": 749,
        "Quantity": 103,
        "Total Units Sold": 50,
        // "Total Sale Value": 24950,
        "Total Visitors": 7635,
        "New Visitors": 1256,
        // "Returning Visitors": 6379,
        // "Conversion Rate": 0.006548788474132285,
        "Added To Cart": 248,
        // "Initiated Checkout": 173,
        // "Completed Checkout": 148,
        // "Total Abandoned Checkouts": 25
    }
]

const jsonData = JSON.parse(JSON.stringify(data));
// // console.log(jsonData);

// function getProductRating(product) {
//     const productRating = product.rating.rate;
//     return productRating;
// }

// function getProductSoldQuantity(product) {
//     const productSoldQuantity = product.rating.count;
//     return productSoldQuantity;
// }

// // find the most & least sold product : O(N) time complexity
// function getMostSoldProduct(dataset) {
//     let itemID = null; // to be most sold item's ID
//     let max = 0;
//     for (item of dataset) {
//         let itemSoldQuantity = getProductSoldQuantity(item);
//         if (itemSoldQuantity >= max) {
//             max = itemSoldQuantity;
//             itemID = item.id;
//         }
//     }
//     return itemID;
// }

// function getLeastSoldProduct(dataset) {
//     let itemID = dataset[0].id; // to be least sold item's ID
//     let min = getProductSoldQuantity(dataset[0]);
//     for (item of dataset) {
//         let itemSoldQuantity = getProductSoldQuantity(item);
//         if (itemSoldQuantity <= min) {
//             min = itemSoldQuantity;
//             itemID = item.id;
//         }
//     }
//     return itemID;
// }

// // console.log('Most bought product ID:', getMostSoldProduct(jsonData));
// // console.log('Least bought product ID:', getLeastSoldProduct(jsonData));



const shippingCost = 250
// calculate: mrp = selling price + 250 (shipping cost)
export function getMRP(product) {
    // should pass an SKU id as 'product' argument
    const sku = Number(product);
    let mrp = null;

    for (let item of jsonData) {
        if (item.SKU === sku) {
            if (!("MRP" in item)) {
                mrp = item["Selling Price"] + shippingCost;
                item.MRP = mrp;
                break;
            } else {
                mrp = item.MRP;
                return mrp;
            }
        }
    }
    return mrp;
}
// console.log('MRP:', getMRP(2562));



// calculate: total sale value = selling price * total units sold
export function getTotalSaleValue(product) {
    // should pass an SKU id as 'product' argument
    const sku = Number(product);
    let totalSaleValue = null;

    for (let item of jsonData) {
        if (item.SKU === sku) {
            if (!("Total Sale Value" in item)) {
                totalSaleValue = item["Selling Price"] * item["Total Units Sold"];
                item["Total Sale Value"] = totalSaleValue;
                break;
            } else {
                totalSaleValue = item["Total Sale Value"];
                return totalSaleValue;
            }
        }
    }
    return totalSaleValue;
}
// console.log('Total sale value:', getTotalSaleValue(2562));



// calculate: returning visitor = total visitors - new visitors
export function getNumOfReturningVisitors(product) {
    // should pass an SKU id as 'product' argument
    const sku = Number(product);
    let numOfReturningVisitors = null;

    for (let item of jsonData) {
        if (item.SKU === sku) {
            if (!("Returning Visitors" in item)) {
                numOfReturningVisitors = item["Total Visitors"] - item["New Visitors"];
                item["Returning Visitors"] = numOfReturningVisitors;
                break;
            } else {
                numOfReturningVisitors = item["Returning Visitors"];
                return numOfReturningVisitors;
            }
        }
    }
    return numOfReturningVisitors;
}
// console.log('Returning Visitors:', getNumOfReturningVisitors(2562));



// calculate: conversion rate = total units sold / total visitors
export function getConversionRate(product) {
    // should pass an SKU id as 'product' argument
    const sku = Number(product);
    let conversionRate = null;

    for (let item of jsonData) {
        if (item.SKU === sku) {
            if (!("Conversion Rate" in item)) {
                conversionRate = item["Total Units Sold"] / item["Total Visitors"];
                item["Conversion Rate"] = conversionRate
                break;
            } else {
                conversionRate = item["Conversion Rate"];
                return conversionRate;
            }
        }
    }
    return conversionRate;
}
// console.log('Conversion rate:', getConversionRate(2562));



// calculate: initiated checkouts = added to cart - 75
export function getNumOfInitiatedCheckouts(product) {
    // should pass an SKU id as 'product' argument
    const sku = Number(product);
    let numOfInitiatedCheckouts = null;

    for (let item of jsonData) {
        if (item.SKU === sku) {
            if (!("Initiated Checkout" in item)) {
                numOfInitiatedCheckouts = item["Added To Cart"] - 75;
                item["Initiated Checkout"] = numOfInitiatedCheckouts;
                break;
            } else {
                numOfInitiatedCheckouts = item["Initiated Checkout"];
                return numOfInitiatedCheckouts;
            }
        }
    }
    return numOfInitiatedCheckouts;
}
// console.log('Initiated checkouts:', getNumOfInitiatedCheckouts(2562));



// calculate: completed checkouts = initiated checkouts - 25
export function getNumOfCompletedCheckouts(product) {
    // should pass an SKU id as 'product' argument
    const sku = Number(product);
    let numOfCompletedCheckouts = null;

    for (let item of jsonData) {
        if (item.SKU === sku) {
            if (!("Completed Checkout" in item)) {
                if ("Initiated Checkout" in item) {
                    numOfCompletedCheckouts = item["Initiated Checkout"] - 25;
                    item["Completed Checkout"] = numOfCompletedCheckouts;
                    break;
                }
                // else
                    // console.log('Initiated Checkout, not calculated');
            } else {
                numOfCompletedCheckouts = item["Completed Checkout"];
                return numOfCompletedCheckouts;
            }
        }
    }
    return numOfCompletedCheckouts;
}
// console.log('Completed checkouts:', getNumOfCompletedCheckouts(2562));



// calculate: total abandoned checkouts = initiated checkouts - completed checkouts
export function getNumOfAbandonedCheckouts(product) {
    // should pass an SKU id as 'product' argument
    const sku = Number(product);
    let numOfAbandonedCheckouts = null;

    for (let item of jsonData) {
        if (item.SKU === sku) {
            if (!("Total Abandoned Checkouts" in item)) {
                if (("Initiated Checkout" in item) && ("Completed Checkout" in item)) {
                    numOfAbandonedCheckouts = item["Initiated Checkout"] - item["Completed Checkout"];
                    item["Total Abandoned Checkouts"] = numOfAbandonedCheckouts;
                    break;
                } 
                // else
                    // console.log('Initiated Checkout and Completed Checkout, not calculated');
            } else {
                numOfAbandonedCheckouts = item["Total Abandoned Checkouts"];
                return numOfAbandonedCheckouts;
            }
        }
    }
    return numOfAbandonedCheckouts;
}
// console.log('Total Abandoned checkouts:', getNumOfAbandonedCheckouts(2562));



// calculate: checkout conversion rate
export function getCheckoutConversionRates(product) {
    // should pass an SKU id as 'product' argument
    const sku = Number(product);
    let addedToCartCR = null;
    let initiatedCheckoutCR = null;
    let completedCheckoutCR = null;

    for (let item of jsonData) {
        if (item.SKU === sku) {
            if (!("Added To Cart Conversion Rate" in item)) {
                addedToCartCR = (item["Added To Cart"] / item["Total Visitors"]) * 100;
                item["Added To Cart Conversion Rate"] = addedToCartCR;
            }

            if (!("Initiated Checkout Conversion Rate" in item)) {
                if (("Initiated Checkout" in item) && ("Added To Cart" in item)) {
                    initiatedCheckoutCR = (item["Initiated Checkout"] / item["Added To Cart"]) * 100;
                    item["Initiated Checkout Conversion Rate"] = initiatedCheckoutCR;
                }
                // else
                    // console.log('Initiated Checkout and Added To Cart, not calculated');
            }

            if (!("Completed Checkout Conversion Rate" in item)) {
                if (("Initiated Checkout" in item) && ("Completed Checkout" in item)) {
                    completedCheckoutCR = (item["Completed Checkout"] / item["Initiated Checkout"]) * 100;
                    item["Completed Checkout Conversion Rate"] = completedCheckoutCR;
                } 
                // else
                    // console.log('Initiated Checkout and Completed Checkout, not calculated');
            }
            break;
        }
    }
    return [addedToCartCR, initiatedCheckoutCR, completedCheckoutCR];
}

// console.log('addedToCartCR initiatedCheckoutCR completedCheckoutCR:', getCheckoutConversionRates(2562));