import axios from "axios"
import {
    DELETE_PROJECT_FAILED,
    DELETE_PROJECT_LOADING,
    DELETE_PROJECT_SUCCESS,
    GET_ALL_PROJECT_BY_USER_EMPTY,
    GET_ALL_PROJECT_BY_USER_FAILED,
    GET_ALL_PROJECT_BY_USER_LOADING,
    GET_ALL_PROJECT_BY_USER_SUCCESS,
    UPDATE_PROJECT_FAILED,
    ADD_NEW_PROJECT_LOADING,
    ADD_NEW_PROJECT_SUCCESS,
    ADD_NEW_PROJECT_FAILED,
    UPDATE_PROJECT_LOADING,
    UPDATE_PROJECT_SUCCESS
} from "../Constants/ProjectConstants"
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"
import { API_HOST } from "../../api_utils";




const errorTheme = {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
}


export const createProject = (userData) => async (dispatch, getState) => {

    try {
        dispatch({ type: ADD_NEW_PROJECT_LOADING })
        const { userLogin: { userInfo } } = getState()
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }
        const { data } = await axios.post(`${API_HOST}projects`, userData, config)
        dispatch({ type: ADD_NEW_PROJECT_SUCCESS, payload: data })
        toast.success(`Project Successfully Created`, errorTheme)

    } catch (error) {
        dispatch({ type: ADD_NEW_PROJECT_FAILED, payload: 'project already exists' })
        toast.error(`something went wrong`, errorTheme)
    }
}


// DONE

export const getAllProject = () => async (dispatch, getState) => {
    try {
        dispatch({ type: GET_ALL_PROJECT_BY_USER_LOADING })
        const { userLogin: { userInfo } } = getState()
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        // console.log("config",config);
        const { data } = await axios.get(`${API_HOST}projects`, config)
        if (!data.length || typeof data === 'undefined' || typeof data === null) {
            dispatch({ type: GET_ALL_PROJECT_BY_USER_EMPTY })
        } else {
            dispatch({ type: GET_ALL_PROJECT_BY_USER_SUCCESS, payload: data })
        }

    } catch (error) {
        dispatch({ type: GET_ALL_PROJECT_BY_USER_FAILED, payload: 'something went wrong' })
        toast.error(`something went wrong`, errorTheme)
    }
}

export const updateProject = (projectData) => async (dispatch, getState) => {
    try {
        dispatch({ type: UPDATE_PROJECT_LOADING })
        const { userLogin: { userInfo } } = getState()
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }
        const { data } = await axios.put(`${API_HOST}projects`, projectData, config)
        if (!data || typeof data === 'undefined' || typeof data === null) {
            dispatch({ type: GET_ALL_PROJECT_BY_USER_EMPTY })
        } else {
            dispatch({ type: UPDATE_PROJECT_SUCCESS, payload: data })
        }

    } catch (error) {
        dispatch({ type: UPDATE_PROJECT_FAILED, error: 'Something went wrong' })
    }
}

export const deleteProject = (projectId) => async (dispatch, getState) => {
    try {
        dispatch({ type: DELETE_PROJECT_LOADING })
        const { userLogin: { userInfo } } = getState()
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }
        const { data } = await axios.delete(`${API_HOST}projects/${projectId}`, config)
        dispatch({ type: DELETE_PROJECT_SUCCESS, payload: data })
        toast.success('project deleted successfully', errorTheme)

    } catch (error) {
        dispatch({ type: DELETE_PROJECT_FAILED, error: 'Something went wrong' })
        toast.error('something went wrong', errorTheme)

    }
}