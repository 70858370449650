import { Grid, Typography } from '@mui/material';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { Box } from '@mui/system'
import React from 'react'
import CustomChart from '../CustomChart';


function Main() {

    const top100Films = [
        { label: 'The Shawshank Redemption', year: 1994 },
        { label: 'The Godfather', year: 1972 },
        { label: 'The Godfather: Part II', year: 1974 },
        { label: 'The Dark Knight', year: 2008 },
        { label: '12 Angry Men', year: 1957 },
        { label: "Schindler's List", year: 1993 },
        { label: 'Pulp Fiction', year: 1994 },
        {
            label: 'The Lord of the Rings: The Return of the King',
            year: 2003,
        },
        { label: 'The Good, the Bad and the Ugly', year: 1966 },
        { label: 'Fight Club', year: 1999 },
        {
            label: 'The Lord of the Rings: The Fellowship of the Ring',
            year: 2001,
        },
        {
            label: 'Star Wars: Episode V - The Empire Strikes Back',
            year: 1980,
        },
        { label: 'Forrest Gump', year: 1994 },
        { label: 'Inception', year: 2010 },
        {
            label: 'The Lord of the Rings: The Two Towers',
            year: 2002,
        },
        { label: "One Flew Over the Cuckoo's Nest", year: 1975 },
        { label: 'Goodfellas', year: 1990 },
        { label: 'The Matrix', year: 1999 },
        { label: 'Seven Samurai', year: 1954 },
        {
            label: 'Star Wars: Episode IV - A New Hope',
            year: 1977,
        },
    ]
    return (

        <Grid sx={{ backgroundColor: "#0f172a", padding: '0 0 2rem 0' }}>


            {/* HEAD GOES HERE */}
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '1rem 2rem 0.5rem 2rem' }}>
                <Typography sx={{ color: 'white', fontFamily: 'Mont-Semibold', fontSize: '1.7rem', letterSpacing: '2px' }}>ANALYTICS DASHBOARD</Typography>
                <Grid>
                    <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={top100Films}
                        sx={{ width: 250, border: '2px solid white', borderRadius: '5px', color: 'white', '.MuiSvgIcon-root': { color: 'white' } }}
                        renderInput={(params) => <TextField {...params} />}
                    />
                </Grid>
            </Box>

            {/* FIRST FLEX DIV */}

            <Grid sx={{ display: 'flex', padding: '0 2rem 2rem 2rem', backgroundColor: '#0f172a' }}>
                <Grid sx={{ width: '50%', height: '450px', mr: 2 }}>
                    <CustomChart height={220} width={200} type={1} />
                </Grid>
                <Grid sx={{ width: '50%', height: '450px' }}>
                    <CustomChart height={220} width={200} type={2} />

                </Grid>
            </Grid>

            {/* SECOND BLOCK DIV */}

            <Grid sx={{ height: '350px' }}>
                <CustomChart height={270} width={200} type={3} />
            </Grid>

            {/* HEAD GOES HERE */}
            <Box sx={{ padding: '0.8rem 2rem' }}>
                <Typography sx={{ color: 'white', fontFamily: 'Mont-Semibold', fontSize: '2rem', letterSpacing: '2px' }}>Top Sales Opportunities of the Week</Typography>

            </Box>

            {/* THIRD FLEX DIV */}
            <Grid sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>

                <Grid sx={{ height: '350px', width: '65%', mr: 2 }}>
                    <CustomChart height={150} width={200} type={4} />
                </Grid>
                <Grid sx={{ height: '350px', width: '35%' }}>
                    <CustomChart height={350} width={100} type={5} />

                </Grid>
            </Grid>
        </Grid>
    )
}

export default Main