import React, {useState, useEffect} from 'react';

import classes from './LogicBuilder.module.css';

import FunctionBlockContent from './FunctionBlockContent';
import axios from 'axios';
// import { API_HOST } from '../../../api_utils';
import { API_HOST } from '../api_utils';
import { useDrag } from 'react-dnd'
import { connect } from 'react-redux';
import { DragBlocks } from '../Testing/Test3/blocks/DragBlocks';

import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'



const LogicalOperatorPanel = (props) => {
	const [isLogicalBlockOpen, setIsLogicalBlockOpen] = useState(null);

	const [allFuncs, loadFunctions] = useState([]);
    const [tempVars, loadTempVars] = useState([])
    // useEffect(() => {
	// to open the .function-block-content if the label is clicked
	
	
    const mathFunctions = [
        {
            id: "logical1",
            name: "AND",
            type: "logicalOperator",
            
        },
        {
            id: "logical2",
            name: "OR",
            type: "logicalOperator",
            
        },
        {
            id: "logical3",
            name: "NOT",
            type: "logicalOperator",
            
        }, 
        {
            id: "logical4",
            name: "GREATER THAN",
            type: "logicalOperator"
        },
        {
            id: "logical5",
            name: "LESS THAN",
            type: "logicalOperator"
        },
        {
            id: "logical6",
            name: "EQUALS",
            type: "logicalOperator"
        }
    ]
	function logicalBlockContentOpenHandler(index) {
		setIsLogicalBlockOpen(index);
	}

	// to close the .function-block-content if the button is clicked
	function logicalBlockContentCloseHandler() {
		setIsLogicalBlockOpen(null);
	}

	// (instead of statements array); here is where statement grapesjs-blocks will come with blockManager.add({});
	// const functions = [
	// 	'New Function 1',
	// 	'New Function 2',
	// 	'New Function 3',
	// 	'New Function 4',
	// 	'New Function 5'
	// ];

	return (
		// <DndProvider backend={HTML5Backend}>
		<div className={classes.function_panel}>

			<h3 style={{color: '#B6B5B5', marginTop: '5%'}}>Logical Operators</h3>

			<div className={classes.function_holder}>
				{/* showing the blocks with forloop just for display, untill real blocks are added here */}
				{mathFunctions.map((item, index) => (
					<div key={index} className={classes.function_block}>
						
						<div className={classes.function_block_label} onClick={() => logicalBlockContentOpenHandler(index)}>
							{/* <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="red" className="bi bi-caret-right-fill" viewBox="0 0 16 16">
							  	<path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z"/>
							</svg>
							<p>{func}</p> */}
							<DragBlocks key={item.id} dragItem={item} />
						</div>

						{ isLogicalBlockOpen == index && <FunctionBlockContent onClose={logicalBlockContentCloseHandler} /> }

					</div>
				))}
			</div>

			{/* receiving the statementPanelCloseHandler() through props and executing it here when onClick event is triggered */}
			<button className={classes.collapse_function_btn} onClick={props.onClose}>
				<span className="collapse-function-btn-icon">
					<svg xmlns="http://www.w3.org/2000/svg" fill="red" className="bi bi-x" viewBox="0 0 16 16" style={{width: '80%', height: '80%'}}>
					  	<path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
					</svg>
				</span>
			</button>

		</div>
		// </DndProvider>
	);
};


const mapStateToProps = (state) => {
    // console.log(state)
    return {
        projectData: state.allProjectData.allProjectData,
        user: state.userLogin.userInfo
    };
}

export default connect(mapStateToProps, {})(LogicalOperatorPanel);