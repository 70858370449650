const customTablePlugin = (editor, opts = {}) => {
  editor.DomComponents.addType("headerText", {
    model: {
      defaults: {
        content: "Dummy Header",
        attributes: { contenteditable: "true" },
      },
    },
  });

  editor.DomComponents.addType("bodyText", {
    model: {
      defaults: {
        components: [
          {
            type: "text",
            content: "Dummy Text",
          },
        ],
      },
    },
  });

  editor.DomComponents.addType("customIcons", {
    model: {
      defaults: {
        components: [
          {
            type: "svg",
            tagName: "svg",
            attributes: {
              xmlns: "http://www.w3.org/2000/svg",
              width: "16",
              height: "16",
              fill: "currentColor",
              name: "hideColumn",
              "view-box": "0 0 16 16",
              class: "hideColumn",
            },
            style: {
              color: "black",
            },
            content: ` <path d="m10.79 12.912-1.614-1.615a3.5 3.5 0 0 1-4.474-4.474l-2.06-2.06C.938 6.278 0 8 0 8s3 5.5 8 5.5a7.029 7.029 0 0 0 2.79-.588zM5.21 3.088A7.028 7.028 0 0 1 8 2.5c5 0 8 5.5 8 5.5s-.939 1.721-2.641 3.238l-2.062-2.062a3.5 3.5 0 0 0-4.474-4.474L5.21 3.089z" />
                         <path d="M5.525 7.646a2.5 2.5 0 0 0 2.829 2.829l-2.83-2.829zm4.95.708-2.829-2.83a2.5 2.5 0 0 1 2.829 2.829zm3.171 6-12-12 .708-.708 12 12-.708.708z" />`,
          },
          {
            type: "svg",
            tagName: "svg",
            attributes: {
              xmlns: "http://www.w3.org/2000/svg",
              width: "16",
              height: "16",
              fill: "currentColor",
              name: "sortColumn",
              "view-box": "0 0 16 16",
              class: "sortColumn",
            },
            style: {
              color: "black",
            },
            content: `<path fill-rule="evenodd" d="M11.5 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L11 2.707V14.5a.5.5 0 0 0 .5.5zm-7-14a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L4 13.293V1.5a.5.5 0 0 1 .5-.5z" />`,
          },
        ],
      },
    },
    view: {
      events: {
        "click .hideColumn": "openCardEditModel",
        "click .sortColumn": "openThreeDModel",
      },

      openCardEditModel(event) {
        const icon = event.currentTarget;
        const table = this.em.getSelected();

        console.log("icon::::::::", icon);
        console.log("table::::::::::", table);
        console.log("icon parent::::::::::");

        let iconParent = icon.closest("td").closest("table");

        if (table) {
          const columnIndex = Array.from(
            icon.closest("td").parentElement.children
          ).indexOf(icon.closest("td"));

          const rows = Array.from(iconParent.querySelectorAll("tr"));

          console.log("columnIndex::::::::", columnIndex);
          console.log("rows::::::::", rows);
          // Hide the header cell (first row in the thead)
          // rows[0].children[columnIndex].style.display = "none";

          // Hide the corresponding body cell in each row (excluding the header row)
          rows.forEach((row) => {
            if (row.children.length > columnIndex) {
              row.deleteCell(columnIndex);
            }
          });

          console.log("editor::::::::", editor);

          editor.StorageManager.get("remote").store({
            my_components: editor.getComponents(),
            my_css: editor.getCss(),
            my_html: editor.getHtml(),
            my_js: editor.getJs(),
          });
        }
      },
    },
  });

  editor.DomComponents.addType("customTd", {
    model: {
      defaults: {
        type: "default",
        traits: [],

        components: [
          {
            type: "headerText",
            tagName: "p",
            style: {
              width: "70%",
              "text-align": "center",
              "font-size": "24px",
              "font-weight": "800",
            },
          },
          {
            type: "customIcons",
            tagName: "div",
            style: {
              width: "20%",
              display: "flex",
              gap: "10px",
              "justify-content": "between",
              "align-items": "center",
            },
          },
        ],
      },
    },
  });

  editor.DomComponents.addType("customTdWrapper", {
    model: {
      defaults: {
        type: "cell",
        components: [
          {
            type: "customTd",
            tagName: "div",
            style: {
              display: "flex",
              "align-items": "center",
              gap: "4px",
            },
          },
        ],
      },
    },
  });

  editor.DomComponents.addType("customPagination", {
    model: {
      defaults: {
        components: [
          {
            tagName: "button",
            type: "default",
            content: "1",
            style: {
              "padding-right": "0.5rem",
              "padding-left": "0.5rem",
              "padding-top": "0.3rem",
              "padding-bottom": "0.3rem",
              color: "black",
              "border-radius": "6px",
              "background-color": "#e2e8f0",
            },
          },
          {
            tagName: "button",
            type: "default",
            content: "2",
            style: {
              "padding-right": "0.5rem",
              "padding-left": "0.5rem",
              "padding-top": "0.3rem",
              "padding-bottom": "0.3rem",
              color: "black",
              "border-radius": "6px",
              "background-color": "#e2e8f0",
            },
          },
          {
            tagName: "button",
            type: "default",
            content: "3",
            style: {
              "padding-right": "0.5rem",
              "padding-left": "0.5rem",
              "padding-top": "0.3rem",
              "padding-bottom": "0.3rem",
              color: "black",
              "border-radius": "6px",
              "background-color": "#e2e8f0",
            },
          },
          {
            tagName: "button",
            type: "default",
            content: "4",
            style: {
              "padding-right": "0.5rem",
              "padding-left": "0.5rem",
              "padding-top": "0.3rem",
              "padding-bottom": "0.3rem",
              color: "black",
              "border-radius": "6px",
              "background-color": "#e2e8f0",
            },
          },
          {
            tagName: "button",
            type: "default",
            content: "5",
            style: {
              "padding-right": "0.5rem",
              "padding-left": "0.5rem",
              "padding-top": "0.3rem",
              "padding-bottom": "0.3rem",
              color: "black",
              "border-radius": "6px",
              "background-color": "#e2e8f0",
            },
          },
        ],
      },
    },
  });

  editor.DomComponents.addType("customToolbar", {
    model: {
      defaults: {
        components: [
          {
            tagName: "input",
            type: "default",
            attributes: {
              class: "search_filter",
              placeholder: "Enter Search Query",
              type: "text",
            },
            style: {
              height: "36px",
              width: "40%",
              "border-radius": "8px",
              "align-items": "center",
              "box-shadow": "1px 1px 10px 1px rgba(0,0,0,0.75)",
            },
          },
          {
            tagName: "button",
            type: "default",
            content: "Show All Columns",
            attributes: {
              class: "showAll",
            },
            style: {
              padding: "5px 12px",
              "border-radius": "10px",
              "background-color": "#0891b2",
              color: "white",
            },
          },
          {
            tagName: "button",
            type: "default",
            content: "Dark Theme",
            attributes: {
              class: "darkTheme",
            },
            style: {
              padding: "5px 12px",
              "border-radius": "10px",
              "background-color": "#171717",
              color: "white",
            },
          },
          {
            tagName: "button",
            type: "default",
            content: "Light Theme",
            attributes: {
              class: "lightTheme",
            },
            style: {
              padding: "5px 12px",
              "border-radius": "10px",
              "background-color": "#16a34a",
              color: "white",
            },
          },
        ],
      },
    },
  });

  editor.DomComponents.addType("customTableWrapper", {
    model: {
      defaults: {
        // BaseVars: opts,
        traits: [
          {
            type: "text",
            name: "CustomUrl",
            label: "Custom URL",
            changeProp: true,
          },
          {
            type: "select",
            name: "BaseUrl",
            label: "Base URL",
            options: [
              { id: "baseUrl1", name: "Base URL #1" },
              { id: "baseUrl2", name: "Base URL #2" },
              { id: "baseUrl3", name: "Base URL #3" },
              { id: "baseUrl4", name: "Base URL #4" },
              { id: "baseUrl5", name: "Base URL #5" },
              { id: "null", name: "No Base URL" },
            ],
            changeProp: true,
          },
          {
            type: "text",
            name: "APIPath",
            label: "API Path",
            placeholder: "Don't start with '/'",
            changeProp: true,
          },
          // {
          //   type: "text",
          //   label: "URL",
          //   name: "url",
          //   changeProp: true,
          // },
          {
            type: "text",
            name: "Username",
            label: "Username",
            changeProp: true,
          },
          {
            type: "text",
            name: "Password",
            label: "Password",
            changeProp: true,
          },
          {
            type: "text",
            name: "API_Key",
            label: "API Key",
            changeProp: true,
          },
          {
            type: "text",
            name: "More_Headers",
            label: "More Headers",
            changeProp: true,
          },
          {
            type: "text",
            name: "TargetKey",
            label: "Key To Plot",
            changeProp: true,
          },
          {
            type: "number",
            label: "Page Size",
            name: "pageSize",
            changeProp: true,
          },
        ],

        "script-props": [
          "url",
          "Username",
          "Password",
          "API_Key",
          "More_Headers",
          "TargetKey",
          "APIPath",
          "BaseUrl",
          "CustomUrl",
          "BaseVars",
        ],
        components: [
          // TOP PANEL GOES HERE :::::::::::::
          {
            type: "customToolbar",
            tagName: "div",
            style: {
              "margin-top": "1rem",
              width: "100%",
              display: "flex",
              "justify-content": "center",
              "align-items": "center",
              gap: "6px",
            },
          },

          // TABLE GOES HERE::::::::::::::::
          {
            tagName: "div",
            style: {
              width: "100%",
              "margin-top": "2rem",
              overflow: "auto",
              "padding-right": "10px",
              "padding-left": "10px",
            },
            components: [
              {
                type: "table",
                tagName: "table",
                style: {
                  "box-shadow": "0px 0px 10px 0px rgba(0,0,0,0.75)",
                  "margin-left": "0px",
                  "margin-top": "0px",
                  width: "100%",
                },

                components: [
                  {
                    type: "thead",
                    tagName: "thead",
                    components: [
                      {
                        type: "row",
                        tagName: "tr",
                        style: {
                          height: "50px",
                          border: "1px solid gray",
                        },
                        components: [
                          {
                            type: "customTdWrapper",
                            tagName: "td",
                            style: {
                              outline: "none",
                              "font-size": "24px",
                              "font-weight": "800",
                              "text-align": "center",
                              border: "1px solid gray",
                              "max-width": "fit-content",
                            },
                          },
                          {
                            type: "customTdWrapper",
                            tagName: "td",
                            style: {
                              outline: "none",
                              "font-size": "24px",
                              "font-weight": "800",
                              "text-align": "center",
                              border: "1px solid gray",
                              "max-width": "fit-content",
                            },
                          },
                        ],
                      },
                    ],
                  },
                  {
                    type: "tbody",
                    tagName: "tbody",

                    components: [
                      {
                        type: "row",
                        tagName: "tr",
                        components: [
                          {
                            type: "cell",
                            tagName: "td",
                            style: {
                              outline: "none",
                              border: "1px solid gray",
                              "align-items": "center",
                              "justify-content": "center",
                              "text-align": "center",
                              padding: "1rem",
                            },
                            components: [
                              {
                                type: "bodyText",
                                tagName: "p",
                                style: {
                                  "text-align": "center",
                                  "font-size": "20px",
                                },
                              },
                            ],
                          },
                          {
                            type: "cell",
                            tagName: "td",
                            style: {
                              outline: "none",
                              border: "1px solid gray",
                              "align-items": "center",
                              "justify-content": "center",
                              "text-align": "center",
                              padding: "1rem",
                            },
                            components: [
                              {
                                type: "bodyText",
                                tagName: "p",
                                style: {
                                  "text-align": "center",
                                  "font-size": "20px",
                                },
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },

          // PAGINATION GOES HERE::::::::::::::::::
          {
            type: "customPagination",
            tagName: "div",
            style: {
              display: "flex",
              "justify-content": "flex-end",
              "align-items": "center",
              gap: "10px",
              "padding-right": "1rem",
            },
          },
        ],
      },
    },
    view: {
      async onRender({ el, model }) {
        // console.log("model", model);
        let url = model.get("url");
        // console.log("url", url);
        // console.log("el", el);
      },
    },
  });

  editor.BlockManager.add("customTableWrapper", {
    label: "Table",
    content: { type: "customTableWrapper" },
    category: "Data-Table",
    media: `<svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" viewBox="0 0 16 16">
            <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm15 2h-4v3h4V4zm0 4h-4v3h4V8zm0 4h-4v3h3a1 1 0 0 0 1-1v-2zm-5 3v-3H6v3h4zm-5 0v-3H1v2a1 1 0 0 0 1 1h3zm-4-4h4V8H1v3zm0-4h4V4H1v3zm5-3v3h4V4H6zm4 4H6v3h4V8z"/>
        </svg>`,
    select: true,
    draggable: true,
  });

  // FUNCTION TO UPDATE BODY CONTENT OF THE TABLE :
  function updateTableBody(component, rowData) {
    let tbody = component.findType("tbody")[0];
    console.log("update table body");
    console.log("tbody", tbody);
    const rows = rowData.map((row) => Object.values(row));
    if (tbody) {
      // Remove existing body rows
      tbody.components().reset();

      // Create a new body row for each data row
      rows.forEach((rowData) => {
        const bodyRow = tbody.components().add({
          type: "row",
          tagName: "tr",
          style: {
            height: "20px",
            border: "1px solid gray",
          },
        });

        rowData.forEach((cellData) => {
          // Create a cell for each data cell
          const cell = bodyRow.components().add({
            type: "cell",
            tagName: "td",
            style: {
              outline: "none",
              border: "1px solid gray",
              "align-items": "center",
              "justify-content": "center",
              "text-align": "center",
              padding: "1rem",
            },
          });

          // Create a text component inside the cell
          const text = cell.components().add({
            type: "text",
            tagName: "p",
            content: cellData,
          });
        });
      });
    }
  }

  // Function to update table body based on search input
  function updateTableBodyWithSearch(component, rowData, searchText) {
    let tbody = component.findType("tbody")[0];
    const rows = rowData.map((row) => Object.values(row));
    const filteredRows = rows.filter((row) => {
      // Join all row values into a single string for searching
      const rowText = row.join(" ").toLowerCase();
      const searchRegex = new RegExp(searchText, "i"); // Case-insensitive regex search
      return searchRegex.test(rowText);
    });

    if (tbody) {
      // Remove existing body rows
      tbody.components().reset();

      // Create a new body row for each filtered data row
      filteredRows.forEach((rowData) => {
        const bodyRow = tbody.components().add({
          type: "row",
          tagName: "tr",
          style: {
            height: "20px",
            border: "1px solid gray",
          },
        });

        rowData.forEach((cellData) => {
          // Create a cell for each data cell
          const cell = bodyRow.components().add({
            type: "cell",
            tagName: "td",
            style: {
              outline: "none",
              border: "1px solid gray",
              "align-items": "center",
              "justify-content": "center",
              "text-align": "center",
              padding: "1rem",
            },
          });

          // Create a text component inside the cell
          const text = cell.components().add({
            type: "text",
            tagName: "p",
            content: cellData,
          });
        });
      });
    }
  }

  editor.on("component:update:traits", async (component) => {
    if (component && component.attributes.type === "customTableWrapper") {
      console.log("baseVarFrom flexi table:::::::", opts);
      // const urlTrait = component.getTrait("url");
      // const url = urlTrait.attributes.value;

      const customUrlTrait = component.getTrait("CustomUrl");
      const customUrl = customUrlTrait.attributes.value;

      let url = customUrl;

      const baseUrlTrait = component.getTrait("BaseUrl");
      const BaseUrl = baseUrlTrait.attributes.value;

      const apiPathTrait = component.getTrait("APIPath");
      const APIPath = apiPathTrait.attributes.value;

      const usernameKeyTrait = component.getTrait("Username");
      const username = usernameKeyTrait.attributes.value;

      const passwordKeyTrait = component.getTrait("Password");
      const password = passwordKeyTrait.attributes.value;

      const aPIKeyKeyTrait = component.getTrait("API_Key");
      const apiKey = aPIKeyKeyTrait.attributes.value;

      const moreHeadersKeyTrait = component.getTrait("More_Headers");
      const moreHeaders = moreHeadersKeyTrait.attributes.value;

      const targetKeyTrait = component.getTrait("TargetKey");
      const targetKey = targetKeyTrait.attributes.value;

      if (url && (!BaseUrl || BaseUrl === "null")) {
        // when only api is provided
        if (url && !username && !password && !apiKey && !targetKey) {
          try {
            let config = {
              headers: {},
            };
            if (moreHeaders) {
              moreHeaders.split(",").forEach((header) => {
                let key, val;
                [key, val] = header.split(":");
                console.log("[key, val]:\n", key, val);

                config.headers[key] = val;
              });
            }
            const response = await fetch(url);
            const responseData = await response.json();
            if (
              Array.isArray(responseData) &&
              responseData.length > 0 &&
              typeof responseData[0] === "object"
            ) {
              const columns = Object.keys(responseData[0]);
              localStorage.setItem(
                "flexTableData",
                JSON.stringify(responseData)
              );

              // Find the thead component
              let thead = component.findType("thead")[0];
              if (thead) {
                // Remove existing header rows
                thead.components().reset();

                // Create a new header row with customTdWrapper components
                const headerRow = thead.components().add({
                  type: "row",
                  tagName: "tr",
                  style: {
                    height: "20px",
                    border: "1px solid gray",
                  },
                });

                columns.forEach((column) => {
                  // Create a customTdWrapper component for each column
                  const customTdWrapper = headerRow.components().add({
                    type: "customTdWrapper",
                    tagName: "td",
                    style: {
                      outline: "none",
                      "font-size": "24px",
                      "font-weight": "800",
                      "text-align": "center",
                      border: "1px solid gray",
                      "mix-width": "300px",
                    },
                  });

                  // Set the header text
                  customTdWrapper
                    .components()
                    .models[0].components()
                    .models[0].set("content", column);
                });
              }

              let tbody = component.findType("tbody")[0];

              if (tbody) {
                tbody.components().reset();

                // Initial rendering of the first page with 5 items
                const startIndex = 0;
                const endIndex = Math.min(startIndex + 5, responseData.length);
                const initialPageData = responseData.slice(
                  startIndex,
                  endIndex
                );
                const rows = initialPageData.map((row) => Object.values(row));

                // Create a new body row for each data row
                rows.forEach((rowData) => {
                  const bodyRow = tbody.components().add({
                    type: "row",
                    tagName: "tr",
                    style: {
                      height: "20px",
                      border: "1px solid gray",
                    },
                  });

                  rowData.forEach((cellData) => {
                    // Create a cell for each data cell
                    const cell = bodyRow.components().add({
                      type: "cell",
                      tagName: "td",
                      style: {
                        outline: "none",
                        border: "1px solid gray",
                        "align-items": "center",
                        "justify-content": "center",
                        "text-align": "center",
                        padding: "1rem",
                      },
                    });

                    // Create a text component inside the cell
                    const text = cell.components().add({
                      type: "text",
                      tagName: "p",
                      content: cellData,
                    });
                  });
                });
              }

              // items per page
              let itemsPerPage = 5;

              // PAGINATION GOES HERE::::::::::::::::::
              const totalPages = Math.ceil(responseData.length / itemsPerPage);
              const paginationContainer =
                component.findType("customPagination")[0];

              // Clear existing pagination buttons
              paginationContainer.components().reset();

              // Generate pagination buttons dynamically
              for (let i = 1; i <= totalPages; i++) {
                // Create a button for each page number
                const button = paginationContainer.components().add({
                  tagName: "button",
                  content: i,
                  style: {
                    "padding-right": "0.5rem",
                    "padding-left": "0.5rem",
                    "padding-top": "0.3rem",
                    "padding-bottom": "0.3rem",
                    cursor: "pointer", // Add cursor pointer for clickability
                  },
                });

                // Attach an onClick event to each button
                button.on("click", () => {
                  // Calculate the range of data to display for the selected page
                  const startIndex = (i - 1) * itemsPerPage;
                  const endIndex = Math.min(
                    startIndex + itemsPerPage,
                    responseData.length
                  );

                  // Update the table's body with the data for the selected page
                  updateTableBody(
                    component,
                    responseData.slice(startIndex, endIndex)
                  );
                });
              }
            }
          } catch (error) {
            console.error("Error fetching data:", error);
          }
        }

        // when api, username and password is provided but not api key
        else if (url && username && password && !apiKey && !targetKey) {
          let config = {
            headers: {
              Authorization: "Basic " + btoa(username + ":" + password),
            },
          };
          // ['a:b', "c:d"]
          // extracting headers from More_Headers trait
          if (moreHeaders) {
            moreHeaders.split(",").forEach((header) => {
              let key, val;
              [key, val] = header.split(":");
              console.log("[key, val]:\n", key, val);

              config.headers[key] = val;
            });
          }

          fetch(url, config)
            .then((response) => response.json())
            .then((responseData) => {
              if (
                Array.isArray(responseData) &&
                responseData.length > 0 &&
                typeof responseData[0] === "object"
              ) {
                const columns = Object.keys(responseData[0]);
                localStorage.setItem(
                  "flexTableData",
                  JSON.stringify(responseData)
                );

                // Find the thead component
                let thead = component.findType("thead")[0];
                if (thead) {
                  // Remove existing header rows
                  thead.components().reset();

                  // Create a new header row with customTdWrapper components
                  const headerRow = thead.components().add({
                    type: "row",
                    tagName: "tr",
                    style: {
                      height: "20px",
                      border: "1px solid gray",
                    },
                  });
                  const columnWidth = 100 / columns.length + "%";

                  columns.forEach((column) => {
                    // Create a customTdWrapper component for each column
                    const customTdWrapper = headerRow.components().add({
                      type: "customTdWrapper",
                      tagName: "td",
                      style: {
                        outline: "none",
                        "font-size": "24px",
                        "font-weight": "800",
                        "text-align": "center",
                        border: "1px solid gray",
                        "min-width": "300px",
                      },
                    });

                    // Set the header text
                    customTdWrapper
                      .components()
                      .models[0].components()
                      .models[0].set("content", column);
                  });
                }

                let tbody = component.findType("tbody")[0];

                if (tbody) {
                  tbody.components().reset();

                  // Initial rendering of the first page with 5 items
                  const startIndex = 0;
                  const endIndex = Math.min(
                    startIndex + 5,
                    responseData.length
                  );
                  const initialPageData = responseData.slice(
                    startIndex,
                    endIndex
                  );
                  const rows = initialPageData.map((row) => Object.values(row));

                  // Create a new body row for each data row
                  rows.forEach((rowData) => {
                    const bodyRow = tbody.components().add({
                      type: "row",
                      tagName: "tr",
                      style: {
                        height: "20px",
                        border: "1px solid gray",
                      },
                    });

                    rowData.forEach((cellData) => {
                      // Create a cell for each data cell
                      const cell = bodyRow.components().add({
                        type: "cell",
                        tagName: "td",
                        style: {
                          outline: "none",
                          border: "1px solid gray",
                          "align-items": "center",
                          "justify-content": "center",
                          "text-align": "center",
                          padding: "1rem",
                        },
                      });

                      // Create a text component inside the cell
                      const text = cell.components().add({
                        type: "text",
                        tagName: "p",
                        content: cellData,
                      });
                    });
                  });
                }

                // items per page
                let itemsPerPage = 5;

                // PAGINATION GOES HERE::::::::::::::::::
                const totalPages = Math.ceil(
                  responseData.length / itemsPerPage
                );
                const paginationContainer =
                  component.findType("customPagination")[0];

                // Clear existing pagination buttons
                paginationContainer.components().reset();

                // Generate pagination buttons dynamically
                for (let i = 1; i <= totalPages; i++) {
                  // Create a button for each page number
                  const button = paginationContainer.components().add({
                    tagName: "button",
                    content: i,
                    style: {
                      "padding-right": "0.5rem",
                      "padding-left": "0.5rem",
                      "padding-top": "0.3rem",
                      "padding-bottom": "0.3rem",
                      cursor: "pointer", // Add cursor pointer for clickability
                    },
                  });

                  // Attach an onClick event to each button
                  button.on("click", () => {
                    // Calculate the range of data to display for the selected page
                    const startIndex = (i - 1) * itemsPerPage;
                    const endIndex = Math.min(
                      startIndex + itemsPerPage,
                      responseData.length
                    );

                    // Update the table's body with the data for the selected page
                    updateTableBody(
                      component,
                      responseData.slice(startIndex, endIndex)
                    );
                  });
                }
              }
            })
            .catch((error) => {
              console.error("Error fetching data:", error);
            });
        } else if (url && !username && !password && apiKey && targetKey) {
          let config = {
            headers: {
              "X-API-KEY": `${apiKey}`,
            },
          };
          // ['a:b', "c:d"]
          // extracting headers from More_Headers trait
          if (moreHeaders) {
            moreHeaders.split(",").forEach((header) => {
              let key, val;
              [key, val] = header.split(":");
              console.log("[key, val]:\n", key, val);

              config.headers[key] = val;
            });
          }

          fetch(url, config)
            .then((response) => response.json())
            .then((responseData) => {
              if (
                Object.prototype.toString.call(responseData) ===
                "[object Object]"
              ) {
                if (
                  Array.isArray(responseData[targetKey]) &&
                  responseData[targetKey].length > 0 &&
                  typeof responseData[targetKey][0] === "object"
                ) {
                  const columns = Object.keys(responseData[targetKey][0]);
                  localStorage.setItem(
                    "flexTableData",
                    JSON.stringify(responseData[targetKey])
                  );

                  // Find the thead component
                  let thead = component.findType("thead")[0];
                  if (thead) {
                    // Remove existing header rows
                    thead.components().reset();

                    // Create a new header row with customTdWrapper components
                    const headerRow = thead.components().add({
                      type: "row",
                      tagName: "tr",
                      style: {
                        height: "20px",
                        border: "1px solid gray",
                      },
                    });
                    const columnWidth = 100 / columns.length + "%";

                    columns.forEach((column) => {
                      // Create a customTdWrapper component for each column
                      const customTdWrapper = headerRow.components().add({
                        type: "customTdWrapper",
                        tagName: "td",
                        style: {
                          outline: "none",
                          "font-size": "24px",
                          "font-weight": "800",
                          "text-align": "center",
                          border: "1px solid gray",
                          "min-width": "300px",
                        },
                      });

                      // Set the header text
                      customTdWrapper
                        .components()
                        .models[0].components()
                        .models[0].set("content", column);
                    });
                  }

                  let tbody = component.findType("tbody")[0];

                  if (tbody) {
                    tbody.components().reset();

                    // Initial rendering of the first page with 5 items
                    const startIndex = 0;
                    const endIndex = Math.min(
                      startIndex + 5,
                      responseData[targetKey].length
                    );
                    const initialPageData = responseData[targetKey].slice(
                      startIndex,
                      endIndex
                    );
                    const rows = initialPageData.map((row) =>
                      Object.values(row)
                    );

                    // Create a new body row for each data row
                    rows.forEach((rowData) => {
                      const bodyRow = tbody.components().add({
                        type: "row",
                        tagName: "tr",
                        style: {
                          height: "20px",
                          border: "1px solid gray",
                        },
                      });

                      rowData.forEach((cellData) => {
                        // Create a cell for each data cell
                        const cell = bodyRow.components().add({
                          type: "cell",
                          tagName: "td",
                          style: {
                            outline: "none",
                            border: "1px solid gray",
                            "align-items": "center",
                            "justify-content": "center",
                            "text-align": "center",
                            padding: "1rem",
                          },
                        });

                        // Create a text component inside the cell
                        const text = cell.components().add({
                          type: "text",
                          tagName: "p",
                          content: cellData,
                        });
                      });
                    });
                  }

                  // items per page
                  let itemsPerPage = 5;

                  // PAGINATION GOES HERE::::::::::::::::::
                  const totalPages = Math.ceil(
                    responseData[targetKey].length / itemsPerPage
                  );
                  const paginationContainer =
                    component.findType("customPagination")[0];

                  // Clear existing pagination buttons
                  paginationContainer.components().reset();

                  // Generate pagination buttons dynamically
                  for (let i = 1; i <= totalPages; i++) {
                    // Create a button for each page number
                    const button = paginationContainer.components().add({
                      tagName: "button",
                      content: i,
                      style: {
                        "padding-right": "0.5rem",
                        "padding-left": "0.5rem",
                        "padding-top": "0.3rem",
                        "padding-bottom": "0.3rem",
                        cursor: "pointer", // Add cursor pointer for clickability
                      },
                    });

                    // Attach an onClick event to each button
                    button.on("click", () => {
                      // Calculate the range of data to display for the selected page
                      const startIndex = (i - 1) * itemsPerPage;
                      const endIndex = Math.min(
                        startIndex + itemsPerPage,
                        responseData[targetKey].length
                      );

                      // Update the table's body with the data for the selected page
                      updateTableBody(
                        component,
                        responseData[targetKey].slice(startIndex, endIndex)
                      );
                    });
                  }
                }
              }
            })
            .catch((error) => {
              console.error("Error fetching data:", error);
            });
        } else if (url && !username && !password && !apiKey && targetKey) {
          fetch(url)
            .then((response) => response.json())
            .then((responseData) => {
              if (
                Object.prototype.toString.call(responseData) ===
                "[object Object]"
              ) {
                if (
                  Array.isArray(responseData[targetKey]) &&
                  responseData[targetKey].length > 0 &&
                  typeof responseData[targetKey][0] === "object"
                ) {
                  const columns = Object.keys(responseData[targetKey][0]);
                  localStorage.setItem(
                    "flexTableData",
                    JSON.stringify(responseData[targetKey])
                  );

                  // Find the thead component
                  let thead = component.findType("thead")[0];
                  if (thead) {
                    // Remove existing header rows
                    thead.components().reset();

                    // Create a new header row with customTdWrapper components
                    const headerRow = thead.components().add({
                      type: "row",
                      tagName: "tr",
                      style: {
                        height: "20px",
                        border: "1px solid gray",
                      },
                    });
                    const columnWidth = 100 / columns.length + "%";

                    columns.forEach((column) => {
                      // Create a customTdWrapper component for each column
                      const customTdWrapper = headerRow.components().add({
                        type: "customTdWrapper",
                        tagName: "td",
                        style: {
                          outline: "none",
                          "font-size": "24px",
                          "font-weight": "800",
                          "text-align": "center",
                          border: "1px solid gray",
                          "min-width": "300px",
                        },
                      });

                      // Set the header text
                      customTdWrapper
                        .components()
                        .models[0].components()
                        .models[0].set("content", column);
                    });
                  }

                  let tbody = component.findType("tbody")[0];
                  console.log("table body", tbody);

                  if (tbody) {
                    tbody.components().reset();

                    // Initial rendering of the first page with 5 items
                    const startIndex = 0;
                    const endIndex = Math.min(
                      startIndex + 5,
                      responseData[targetKey].length
                    );
                    const initialPageData = responseData[targetKey].slice(
                      startIndex,
                      endIndex
                    );
                    const rows = initialPageData.map((row) =>
                      Object.values(row)
                    );

                    // Create a new body row for each data row
                    rows.forEach((rowData) => {
                      const bodyRow = tbody.components().add({
                        type: "row",
                        tagName: "tr",
                        style: {
                          height: "20px",
                          border: "1px solid gray",
                        },
                      });

                      rowData.forEach((cellData) => {
                        // Create a cell for each data cell
                        const cell = bodyRow.components().add({
                          type: "cell",
                          tagName: "td",
                          style: {
                            outline: "none",
                            border: "1px solid gray",
                            "align-items": "center",
                            "justify-content": "center",
                            "text-align": "center",
                            padding: "1rem",
                          },
                        });

                        // Create a text component inside the cell
                        const text = cell.components().add({
                          type: "text",
                          tagName: "p",
                          content: cellData,
                        });
                      });
                    });
                  }

                  // items per page
                  let itemsPerPage = 5;

                  // PAGINATION GOES HERE::::::::::::::::::
                  const totalPages = Math.ceil(
                    responseData[targetKey].length / itemsPerPage
                  );
                  const paginationContainer =
                    component.findType("customPagination")[0];

                  // Clear existing pagination buttons
                  paginationContainer.components().reset();

                  // Generate pagination buttons dynamically
                  for (let i = 1; i <= totalPages; i++) {
                    // Create a button for each page number
                    const button = paginationContainer.components().add({
                      tagName: "button",
                      content: i,
                      style: {
                        "padding-right": "0.5rem",
                        "padding-left": "0.5rem",
                        "padding-top": "0.3rem",
                        "padding-bottom": "0.3rem",
                        cursor: "pointer", // Add cursor pointer for clickability
                      },
                    });

                    // Attach an onClick event to each button
                    button.on("click", () => {
                      // Calculate the range of data to display for the selected page
                      const startIndex = (i - 1) * itemsPerPage;
                      const endIndex = Math.min(
                        startIndex + itemsPerPage,
                        responseData[targetKey].length
                      );

                      // Update the table's body with the data for the selected page
                      updateTableBody(
                        component,
                        responseData[targetKey].slice(startIndex, endIndex)
                      );
                    });
                  }
                }
              }
            })
            .catch((error) => {
              console.error("Error fetching data:", error);
            });
        } else if (url && username && password && apiKey && targetKey) {
          let config = {
            headers: {
              Authorization: "Basic " + btoa(username + ":" + password),
              "X-API-KEY": `${apiKey}`,
            },
          };
          // ['a:b', "c:d"]
          // extracting headers from More_Headers trait
          if (moreHeaders) {
            moreHeaders.split(",").forEach((header) => {
              let key, val;
              [key, val] = header.split(":");

              config.headers[key] = val;
            });
          }
          fetch(url)
            .then((response) => response.json())
            .then((responseData) => {
              if (
                Object.prototype.toString.call(responseData) ===
                "[object Object]"
              ) {
                if (
                  Array.isArray(responseData[targetKey]) &&
                  responseData[targetKey].length > 0 &&
                  typeof responseData[targetKey][0] === "object"
                ) {
                  const columns = Object.keys(responseData[targetKey][0]);
                  localStorage.setItem(
                    "flexTableData",
                    JSON.stringify(responseData[targetKey])
                  );

                  // Find the thead component
                  let thead = component.findType("thead")[0];
                  if (thead) {
                    // Remove existing header rows
                    thead.components().reset();

                    // Create a new header row with customTdWrapper components
                    const headerRow = thead.components().add({
                      type: "row",
                      tagName: "tr",
                      style: {
                        height: "20px",
                        border: "1px solid gray",
                      },
                    });
                    const columnWidth = 100 / columns.length + "%";

                    columns.forEach((column) => {
                      // Create a customTdWrapper component for each column
                      const customTdWrapper = headerRow.components().add({
                        type: "customTdWrapper",
                        tagName: "td",
                        style: {
                          outline: "none",
                          "font-size": "24px",
                          "font-weight": "800",
                          "text-align": "center",
                          border: "1px solid gray",
                          "min-width": "300px",
                        },
                      });

                      // Set the header text
                      customTdWrapper
                        .components()
                        .models[0].components()
                        .models[0].set("content", column);
                    });
                  }

                  let tbody = component.findType("tbody")[0];

                  if (tbody) {
                    tbody.components().reset();

                    // Initial rendering of the first page with 5 items
                    const startIndex = 0;
                    const endIndex = Math.min(
                      startIndex + 5,
                      responseData[targetKey].length
                    );
                    const initialPageData = responseData[targetKey].slice(
                      startIndex,
                      endIndex
                    );
                    const rows = initialPageData.map((row) =>
                      Object.values(row)
                    );

                    // Create a new body row for each data row
                    rows.forEach((rowData) => {
                      const bodyRow = tbody.components().add({
                        type: "row",
                        tagName: "tr",
                        style: {
                          height: "20px",
                          border: "1px solid gray",
                        },
                      });

                      rowData.forEach((cellData) => {
                        // Create a cell for each data cell
                        const cell = bodyRow.components().add({
                          type: "cell",
                          tagName: "td",
                          style: {
                            outline: "none",
                            border: "1px solid gray",
                            "align-items": "center",
                            "justify-content": "center",
                            "text-align": "center",
                            padding: "1rem",
                          },
                        });

                        // Create a text component inside the cell
                        const text = cell.components().add({
                          type: "text",
                          tagName: "p",
                          content: cellData,
                        });
                      });
                    });
                  }

                  // items per page
                  let itemsPerPage = 5;

                  // PAGINATION GOES HERE::::::::::::::::::
                  const totalPages = Math.ceil(
                    responseData[targetKey].length / itemsPerPage
                  );
                  const paginationContainer =
                    component.findType("customPagination")[0];

                  // Clear existing pagination buttons
                  paginationContainer.components().reset();

                  // Generate pagination buttons dynamically
                  for (let i = 1; i <= totalPages; i++) {
                    // Create a button for each page number
                    const button = paginationContainer.components().add({
                      tagName: "button",
                      content: i,
                      style: {
                        "padding-right": "0.5rem",
                        "padding-left": "0.5rem",
                        "padding-top": "0.3rem",
                        "padding-bottom": "0.3rem",
                        cursor: "pointer", // Add cursor pointer for clickability
                      },
                    });

                    // Attach an onClick event to each button
                    button.on("click", () => {
                      // Calculate the range of data to display for the selected page
                      const startIndex = (i - 1) * itemsPerPage;
                      const endIndex = Math.min(
                        startIndex + itemsPerPage,
                        responseData[targetKey].length
                      );

                      // Update the table's body with the data for the selected page
                      updateTableBody(
                        component,
                        responseData[targetKey].slice(startIndex, endIndex)
                      );
                    });
                  }
                }
              }
            })
            .catch((error) => {
              console.error("Error fetching data:", error);
            });
        } else if (url && !username && !password && apiKey && !targetKey) {
          let config = {
            headers: {
              Authorization: `Bearer ${apiKey}`,
            },
          };
          // ['a:b', "c:d"]
          // extracting headers from More_Headers trait
          if (moreHeaders) {
            moreHeaders.split(",").forEach((header) => {
              let key, val;
              [key, val] = header.split(":");

              config.headers[key] = val;
            });
          }
          fetch(url, config)
            .then((response) => response.json())
            .then((responseData) => {
              if (
                Array.isArray(responseData) &&
                responseData.length > 0 &&
                typeof responseData[0] === "object"
              ) {
                const columns = Object.keys(responseData[0]);
                localStorage.setItem(
                  "flexTableData",
                  JSON.stringify(responseData)
                );

                // Find the thead component
                let thead = component.findType("thead")[0];
                if (thead) {
                  // Remove existing header rows
                  thead.components().reset();

                  // Create a new header row with customTdWrapper components
                  const headerRow = thead.components().add({
                    type: "row",
                    tagName: "tr",
                    style: {
                      height: "20px",
                      border: "1px solid gray",
                    },
                  });
                  const columnWidth = 100 / columns.length + "%";

                  columns.forEach((column) => {
                    // Create a customTdWrapper component for each column
                    const customTdWrapper = headerRow.components().add({
                      type: "customTdWrapper",
                      tagName: "td",
                      style: {
                        outline: "none",
                        "font-size": "24px",
                        "font-weight": "800",
                        "text-align": "center",
                        border: "1px solid gray",
                        "min-width": "300px",
                      },
                    });

                    // Set the header text
                    customTdWrapper
                      .components()
                      .models[0].components()
                      .models[0].set("content", column);
                  });
                }

                let tbody = component.findType("tbody")[0];

                if (tbody) {
                  tbody.components().reset();

                  // Initial rendering of the first page with 5 items
                  const startIndex = 0;
                  const endIndex = Math.min(
                    startIndex + 5,
                    responseData.length
                  );
                  const initialPageData = responseData.slice(
                    startIndex,
                    endIndex
                  );
                  const rows = initialPageData.map((row) => Object.values(row));

                  // Create a new body row for each data row
                  rows.forEach((rowData) => {
                    const bodyRow = tbody.components().add({
                      type: "row",
                      tagName: "tr",
                      style: {
                        height: "20px",
                        border: "1px solid gray",
                      },
                    });

                    rowData.forEach((cellData) => {
                      // Create a cell for each data cell
                      const cell = bodyRow.components().add({
                        type: "cell",
                        tagName: "td",
                        style: {
                          outline: "none",
                          border: "1px solid gray",
                          "align-items": "center",
                          "justify-content": "center",
                          "text-align": "center",
                          padding: "1rem",
                        },
                      });

                      // Create a text component inside the cell
                      const text = cell.components().add({
                        type: "text",
                        tagName: "p",
                        content: cellData,
                      });
                    });
                  });
                }

                // items per page
                let itemsPerPage = 5;

                // PAGINATION GOES HERE::::::::::::::::::
                const totalPages = Math.ceil(
                  responseData.length / itemsPerPage
                );
                const paginationContainer =
                  component.findType("customPagination")[0];

                // Clear existing pagination buttons
                paginationContainer.components().reset();

                // Generate pagination buttons dynamically
                for (let i = 1; i <= totalPages; i++) {
                  // Create a button for each page number
                  const button = paginationContainer.components().add({
                    tagName: "button",
                    content: i,
                    style: {
                      "padding-right": "0.5rem",
                      "padding-left": "0.5rem",
                      "padding-top": "0.3rem",
                      "padding-bottom": "0.3rem",
                      cursor: "pointer", // Add cursor pointer for clickability
                    },
                  });

                  // Attach an onClick event to each button
                  button.on("click", () => {
                    // Calculate the range of data to display for the selected page
                    const startIndex = (i - 1) * itemsPerPage;
                    const endIndex = Math.min(
                      startIndex + itemsPerPage,
                      responseData.length
                    );

                    // Update the table's body with the data for the selected page
                    updateTableBody(
                      component,
                      responseData.slice(startIndex, endIndex)
                    );
                  });
                }
              }
            })
            .catch((error) => {
              console.error("Error fetching data:", error);
            });
        }
      } else if (BaseUrl && APIPath && !url) {
        // concatenating base url and its remaining part
        let baseUrls, baseUrlBearerToken;
        if (BaseUrl === "baseUrl1") {
          baseUrls = opts.baseUrl1 + APIPath;
          baseUrlBearerToken = opts.baseUrl1BearerToken;
        } else if (BaseUrl === "baseUrl2") {
          baseUrls = opts.baseUrl2 + APIPath;
          baseUrlBearerToken = opts.baseUrl2BearerToken;
        } else if (BaseUrl === "baseUrl3") {
          baseUrls = opts.baseUrl3 + APIPath;
          baseUrlBearerToken = opts.baseUrl3BearerToken;
        } else if (BaseUrl === "baseUrl4") {
          baseUrls = opts.baseUrl4 + APIPath;
          baseUrlBearerToken = opts.baseUrl4BearerToken;
        } else if (BaseUrl === "baseUrl5") {
          baseUrls = opts.baseUrl5 + APIPath;
          baseUrlBearerToken = opts.baseUrl5BearerToken;
        }

        console.log("baseUrlBearerToken::::::::::::", baseUrlBearerToken);
        // console.log("url, baseUrlBearerToken", url, baseUrlBearerToken);

        // when only api is provided
        if (
          baseUrls &&
          !username &&
          !password &&
          !apiKey &&
          !targetKey &&
          !baseUrlBearerToken
        ) {
          try {
            let config = {
              headers: {},
            };
            if (moreHeaders) {
              moreHeaders.split(",").forEach((header) => {
                let key, val;
                [key, val] = header.split(":");
                console.log("[key, val]:\n", key, val);

                config.headers[key] = val;
              });
            }
            const response = await fetch(baseUrls);
            const responseData = await response.json();
            if (
              Array.isArray(responseData) &&
              responseData.length > 0 &&
              typeof responseData[0] === "object"
            ) {
              const columns = Object.keys(responseData[0]);
              localStorage.setItem(
                "flexTableData",
                JSON.stringify(responseData)
              );

              // Find the thead component
              let thead = component.findType("thead")[0];
              if (thead) {
                // Remove existing header rows
                thead.components().reset();

                // Create a new header row with customTdWrapper components
                const headerRow = thead.components().add({
                  type: "row",
                  tagName: "tr",
                  style: {
                    height: "20px",
                    border: "1px solid gray",
                  },
                });

                columns.forEach((column) => {
                  // Create a customTdWrapper component for each column
                  const customTdWrapper = headerRow.components().add({
                    type: "customTdWrapper",
                    tagName: "td",
                    style: {
                      outline: "none",
                      "font-size": "24px",
                      "font-weight": "800",
                      "text-align": "center",
                      border: "1px solid gray",
                      "mix-width": "300px",
                    },
                  });

                  // Set the header text
                  customTdWrapper
                    .components()
                    .models[0].components()
                    .models[0].set("content", column);
                });
              }

              let tbody = component.findType("tbody")[0];

              if (tbody) {
                tbody.components().reset();

                // Initial rendering of the first page with 5 items
                const startIndex = 0;
                const endIndex = Math.min(startIndex + 5, responseData.length);
                const initialPageData = responseData.slice(
                  startIndex,
                  endIndex
                );
                const rows = initialPageData.map((row) => Object.values(row));

                // Create a new body row for each data row
                rows.forEach((rowData) => {
                  const bodyRow = tbody.components().add({
                    type: "row",
                    tagName: "tr",
                    style: {
                      height: "20px",
                      border: "1px solid gray",
                    },
                  });

                  rowData.forEach((cellData) => {
                    // Create a cell for each data cell
                    const cell = bodyRow.components().add({
                      type: "cell",
                      tagName: "td",
                      style: {
                        outline: "none",
                        border: "1px solid gray",
                        "align-items": "center",
                        "justify-content": "center",
                        "text-align": "center",
                        padding: "1rem",
                      },
                    });

                    // Create a text component inside the cell
                    const text = cell.components().add({
                      type: "text",
                      tagName: "p",
                      content: cellData,
                    });
                  });
                });
              }

              // items per page
              let itemsPerPage = 5;

              // PAGINATION GOES HERE::::::::::::::::::
              const totalPages = Math.ceil(responseData.length / itemsPerPage);
              const paginationContainer =
                component.findType("customPagination")[0];

              // Clear existing pagination buttons
              paginationContainer.components().reset();

              // Generate pagination buttons dynamically
              for (let i = 1; i <= totalPages; i++) {
                // Create a button for each page number
                const button = paginationContainer.components().add({
                  tagName: "button",
                  content: i,
                  style: {
                    "padding-right": "0.5rem",
                    "padding-left": "0.5rem",
                    "padding-top": "0.3rem",
                    "padding-bottom": "0.3rem",
                    cursor: "pointer", // Add cursor pointer for clickability
                  },
                });

                // Attach an onClick event to each button
                button.on("click", () => {
                  // Calculate the range of data to display for the selected page
                  const startIndex = (i - 1) * itemsPerPage;
                  const endIndex = Math.min(
                    startIndex + itemsPerPage,
                    responseData.length
                  );

                  // Update the table's body with the data for the selected page
                  updateTableBody(
                    component,
                    responseData.slice(startIndex, endIndex)
                  );
                });
              }
            }
          } catch (error) {
            console.error("Error fetching data:", error);
          }
        }

        // when api, username and password is provided but not api key
        else if (baseUrls && username && password && !apiKey && !targetKey) {
          let config = {
            headers: {
              Authorization: "Basic " + btoa(username + ":" + password),
            },
          };
          // ['a:b', "c:d"]
          // extracting headers from More_Headers trait
          if (moreHeaders) {
            moreHeaders.split(",").forEach((header) => {
              let key, val;
              [key, val] = header.split(":");
              console.log("[key, val]:\n", key, val);

              config.headers[key] = val;
            });
          }

          fetch(baseUrls, config)
            .then((response) => response.json())
            .then((responseData) => {
              if (
                Array.isArray(responseData) &&
                responseData.length > 0 &&
                typeof responseData[0] === "object"
              ) {
                const columns = Object.keys(responseData[0]);
                localStorage.setItem(
                  "flexTableData",
                  JSON.stringify(responseData)
                );

                // Find the thead component
                let thead = component.findType("thead")[0];
                if (thead) {
                  // Remove existing header rows
                  thead.components().reset();

                  // Create a new header row with customTdWrapper components
                  const headerRow = thead.components().add({
                    type: "row",
                    tagName: "tr",
                    style: {
                      height: "20px",
                      border: "1px solid gray",
                    },
                  });
                  const columnWidth = 100 / columns.length + "%";

                  columns.forEach((column) => {
                    // Create a customTdWrapper component for each column
                    const customTdWrapper = headerRow.components().add({
                      type: "customTdWrapper",
                      tagName: "td",
                      style: {
                        outline: "none",
                        "font-size": "24px",
                        "font-weight": "800",
                        "text-align": "center",
                        border: "1px solid gray",
                        "min-width": "300px",
                      },
                    });

                    // Set the header text
                    customTdWrapper
                      .components()
                      .models[0].components()
                      .models[0].set("content", column);
                  });
                }

                let tbody = component.findType("tbody")[0];

                if (tbody) {
                  tbody.components().reset();

                  // Initial rendering of the first page with 5 items
                  const startIndex = 0;
                  const endIndex = Math.min(
                    startIndex + 5,
                    responseData.length
                  );
                  const initialPageData = responseData.slice(
                    startIndex,
                    endIndex
                  );
                  const rows = initialPageData.map((row) => Object.values(row));

                  // Create a new body row for each data row
                  rows.forEach((rowData) => {
                    const bodyRow = tbody.components().add({
                      type: "row",
                      tagName: "tr",
                      style: {
                        height: "20px",
                        border: "1px solid gray",
                      },
                    });

                    rowData.forEach((cellData) => {
                      // Create a cell for each data cell
                      const cell = bodyRow.components().add({
                        type: "cell",
                        tagName: "td",
                        style: {
                          outline: "none",
                          border: "1px solid gray",
                          "align-items": "center",
                          "justify-content": "center",
                          "text-align": "center",
                          padding: "1rem",
                        },
                      });

                      // Create a text component inside the cell
                      const text = cell.components().add({
                        type: "text",
                        tagName: "p",
                        content: cellData,
                      });
                    });
                  });
                }

                // items per page
                let itemsPerPage = 5;

                // PAGINATION GOES HERE::::::::::::::::::
                const totalPages = Math.ceil(
                  responseData.length / itemsPerPage
                );
                const paginationContainer =
                  component.findType("customPagination")[0];

                // Clear existing pagination buttons
                paginationContainer.components().reset();

                // Generate pagination buttons dynamically
                for (let i = 1; i <= totalPages; i++) {
                  // Create a button for each page number
                  const button = paginationContainer.components().add({
                    tagName: "button",
                    content: i,
                    style: {
                      "padding-right": "0.5rem",
                      "padding-left": "0.5rem",
                      "padding-top": "0.3rem",
                      "padding-bottom": "0.3rem",
                      cursor: "pointer", // Add cursor pointer for clickability
                    },
                  });

                  // Attach an onClick event to each button
                  button.on("click", () => {
                    // Calculate the range of data to display for the selected page
                    const startIndex = (i - 1) * itemsPerPage;
                    const endIndex = Math.min(
                      startIndex + itemsPerPage,
                      responseData.length
                    );

                    // Update the table's body with the data for the selected page
                    updateTableBody(
                      component,
                      responseData.slice(startIndex, endIndex)
                    );
                  });
                }
              }
            })
            .catch((error) => {
              console.error("Error fetching data:", error);
            });
        } else if (baseUrls && !username && !password && apiKey && targetKey) {
          let config = {
            headers: {
              "X-API-KEY": `${apiKey}`,
            },
          };
          // ['a:b', "c:d"]
          // extracting headers from More_Headers trait
          if (moreHeaders) {
            moreHeaders.split(",").forEach((header) => {
              let key, val;
              [key, val] = header.split(":");
              console.log("[key, val]:\n", key, val);

              config.headers[key] = val;
            });
          }

          fetch(baseUrls, config)
            .then((response) => response.json())
            .then((responseData) => {
              if (
                Object.prototype.toString.call(responseData) ===
                "[object Object]"
              ) {
                if (
                  Array.isArray(responseData[targetKey]) &&
                  responseData[targetKey].length > 0 &&
                  typeof responseData[targetKey][0] === "object"
                ) {
                  const columns = Object.keys(responseData[targetKey][0]);
                  localStorage.setItem(
                    "flexTableData",
                    JSON.stringify(responseData[targetKey])
                  );

                  // Find the thead component
                  let thead = component.findType("thead")[0];
                  if (thead) {
                    // Remove existing header rows
                    thead.components().reset();

                    // Create a new header row with customTdWrapper components
                    const headerRow = thead.components().add({
                      type: "row",
                      tagName: "tr",
                      style: {
                        height: "20px",
                        border: "1px solid gray",
                      },
                    });
                    const columnWidth = 100 / columns.length + "%";

                    columns.forEach((column) => {
                      // Create a customTdWrapper component for each column
                      const customTdWrapper = headerRow.components().add({
                        type: "customTdWrapper",
                        tagName: "td",
                        style: {
                          outline: "none",
                          "font-size": "24px",
                          "font-weight": "800",
                          "text-align": "center",
                          border: "1px solid gray",
                          "min-width": "300px",
                        },
                      });

                      // Set the header text
                      customTdWrapper
                        .components()
                        .models[0].components()
                        .models[0].set("content", column);
                    });
                  }

                  let tbody = component.findType("tbody")[0];

                  if (tbody) {
                    tbody.components().reset();

                    // Initial rendering of the first page with 5 items
                    const startIndex = 0;
                    const endIndex = Math.min(
                      startIndex + 5,
                      responseData[targetKey].length
                    );
                    const initialPageData = responseData[targetKey].slice(
                      startIndex,
                      endIndex
                    );
                    const rows = initialPageData.map((row) =>
                      Object.values(row)
                    );

                    // Create a new body row for each data row
                    rows.forEach((rowData) => {
                      const bodyRow = tbody.components().add({
                        type: "row",
                        tagName: "tr",
                        style: {
                          height: "20px",
                          border: "1px solid gray",
                        },
                      });

                      rowData.forEach((cellData) => {
                        // Create a cell for each data cell
                        const cell = bodyRow.components().add({
                          type: "cell",
                          tagName: "td",
                          style: {
                            outline: "none",
                            border: "1px solid gray",
                            "align-items": "center",
                            "justify-content": "center",
                            "text-align": "center",
                            padding: "1rem",
                          },
                        });

                        // Create a text component inside the cell
                        const text = cell.components().add({
                          type: "text",
                          tagName: "p",
                          content: cellData,
                        });
                      });
                    });
                  }

                  // items per page
                  let itemsPerPage = 5;

                  // PAGINATION GOES HERE::::::::::::::::::
                  const totalPages = Math.ceil(
                    responseData[targetKey].length / itemsPerPage
                  );
                  const paginationContainer =
                    component.findType("customPagination")[0];

                  // Clear existing pagination buttons
                  paginationContainer.components().reset();

                  // Generate pagination buttons dynamically
                  for (let i = 1; i <= totalPages; i++) {
                    // Create a button for each page number
                    const button = paginationContainer.components().add({
                      tagName: "button",
                      content: i,
                      style: {
                        "padding-right": "0.5rem",
                        "padding-left": "0.5rem",
                        "padding-top": "0.3rem",
                        "padding-bottom": "0.3rem",
                        cursor: "pointer", // Add cursor pointer for clickability
                      },
                    });

                    // Attach an onClick event to each button
                    button.on("click", () => {
                      // Calculate the range of data to display for the selected page
                      const startIndex = (i - 1) * itemsPerPage;
                      const endIndex = Math.min(
                        startIndex + itemsPerPage,
                        responseData[targetKey].length
                      );

                      // Update the table's body with the data for the selected page
                      updateTableBody(
                        component,
                        responseData[targetKey].slice(startIndex, endIndex)
                      );
                    });
                  }
                }
              }
            })
            .catch((error) => {
              console.error("Error fetching data:", error);
            });
        } else if (baseUrls && !username && !password && !apiKey && targetKey) {
          fetch(baseUrls)
            .then((response) => response.json())
            .then((responseData) => {
              if (
                Object.prototype.toString.call(responseData) ===
                "[object Object]"
              ) {
                if (
                  Array.isArray(responseData[targetKey]) &&
                  responseData[targetKey].length > 0 &&
                  typeof responseData[targetKey][0] === "object"
                ) {
                  const columns = Object.keys(responseData[targetKey][0]);
                  localStorage.setItem(
                    "flexTableData",
                    JSON.stringify(responseData[targetKey])
                  );

                  // Find the thead component
                  let thead = component.findType("thead")[0];
                  if (thead) {
                    // Remove existing header rows
                    thead.components().reset();

                    // Create a new header row with customTdWrapper components
                    const headerRow = thead.components().add({
                      type: "row",
                      tagName: "tr",
                      style: {
                        height: "20px",
                        border: "1px solid gray",
                      },
                    });
                    const columnWidth = 100 / columns.length + "%";

                    columns.forEach((column) => {
                      // Create a customTdWrapper component for each column
                      const customTdWrapper = headerRow.components().add({
                        type: "customTdWrapper",
                        tagName: "td",
                        style: {
                          outline: "none",
                          "font-size": "24px",
                          "font-weight": "800",
                          "text-align": "center",
                          border: "1px solid gray",
                          "min-width": "300px",
                        },
                      });

                      // Set the header text
                      customTdWrapper
                        .components()
                        .models[0].components()
                        .models[0].set("content", column);
                    });
                  }

                  let tbody = component.findType("tbody")[0];
                  console.log("table body", tbody);

                  if (tbody) {
                    tbody.components().reset();

                    // Initial rendering of the first page with 5 items
                    const startIndex = 0;
                    const endIndex = Math.min(
                      startIndex + 5,
                      responseData[targetKey].length
                    );
                    const initialPageData = responseData[targetKey].slice(
                      startIndex,
                      endIndex
                    );
                    const rows = initialPageData.map((row) =>
                      Object.values(row)
                    );

                    // Create a new body row for each data row
                    rows.forEach((rowData) => {
                      const bodyRow = tbody.components().add({
                        type: "row",
                        tagName: "tr",
                        style: {
                          height: "20px",
                          border: "1px solid gray",
                        },
                      });

                      rowData.forEach((cellData) => {
                        // Create a cell for each data cell
                        const cell = bodyRow.components().add({
                          type: "cell",
                          tagName: "td",
                          style: {
                            outline: "none",
                            border: "1px solid gray",
                            "align-items": "center",
                            "justify-content": "center",
                            "text-align": "center",
                            padding: "1rem",
                          },
                        });

                        // Create a text component inside the cell
                        const text = cell.components().add({
                          type: "text",
                          tagName: "p",
                          content: cellData,
                        });
                      });
                    });
                  }

                  // items per page
                  let itemsPerPage = 5;

                  // PAGINATION GOES HERE::::::::::::::::::
                  const totalPages = Math.ceil(
                    responseData[targetKey].length / itemsPerPage
                  );
                  const paginationContainer =
                    component.findType("customPagination")[0];

                  // Clear existing pagination buttons
                  paginationContainer.components().reset();

                  // Generate pagination buttons dynamically
                  for (let i = 1; i <= totalPages; i++) {
                    // Create a button for each page number
                    const button = paginationContainer.components().add({
                      tagName: "button",
                      content: i,
                      style: {
                        "padding-right": "0.5rem",
                        "padding-left": "0.5rem",
                        "padding-top": "0.3rem",
                        "padding-bottom": "0.3rem",
                        cursor: "pointer", // Add cursor pointer for clickability
                      },
                    });

                    // Attach an onClick event to each button
                    button.on("click", () => {
                      // Calculate the range of data to display for the selected page
                      const startIndex = (i - 1) * itemsPerPage;
                      const endIndex = Math.min(
                        startIndex + itemsPerPage,
                        responseData[targetKey].length
                      );

                      // Update the table's body with the data for the selected page
                      updateTableBody(
                        component,
                        responseData[targetKey].slice(startIndex, endIndex)
                      );
                    });
                  }
                }
              }
            })
            .catch((error) => {
              console.error("Error fetching data:", error);
            });
        } else if (baseUrls && username && password && apiKey && targetKey) {
          let config = {
            headers: {
              Authorization: "Basic " + btoa(username + ":" + password),
              "X-API-KEY": `${apiKey}`,
            },
          };
          // ['a:b', "c:d"]
          // extracting headers from More_Headers trait
          if (moreHeaders) {
            moreHeaders.split(",").forEach((header) => {
              let key, val;
              [key, val] = header.split(":");

              config.headers[key] = val;
            });
          }
          fetch(baseUrls)
            .then((response) => response.json())
            .then((responseData) => {
              if (
                Object.prototype.toString.call(responseData) ===
                "[object Object]"
              ) {
                if (
                  Array.isArray(responseData[targetKey]) &&
                  responseData[targetKey].length > 0 &&
                  typeof responseData[targetKey][0] === "object"
                ) {
                  const columns = Object.keys(responseData[targetKey][0]);
                  localStorage.setItem(
                    "flexTableData",
                    JSON.stringify(responseData[targetKey])
                  );

                  // Find the thead component
                  let thead = component.findType("thead")[0];
                  if (thead) {
                    // Remove existing header rows
                    thead.components().reset();

                    // Create a new header row with customTdWrapper components
                    const headerRow = thead.components().add({
                      type: "row",
                      tagName: "tr",
                      style: {
                        height: "20px",
                        border: "1px solid gray",
                      },
                    });
                    const columnWidth = 100 / columns.length + "%";

                    columns.forEach((column) => {
                      // Create a customTdWrapper component for each column
                      const customTdWrapper = headerRow.components().add({
                        type: "customTdWrapper",
                        tagName: "td",
                        style: {
                          outline: "none",
                          "font-size": "24px",
                          "font-weight": "800",
                          "text-align": "center",
                          border: "1px solid gray",
                          "min-width": "300px",
                        },
                      });

                      // Set the header text
                      customTdWrapper
                        .components()
                        .models[0].components()
                        .models[0].set("content", column);
                    });
                  }

                  let tbody = component.findType("tbody")[0];

                  if (tbody) {
                    tbody.components().reset();

                    // Initial rendering of the first page with 5 items
                    const startIndex = 0;
                    const endIndex = Math.min(
                      startIndex + 5,
                      responseData[targetKey].length
                    );
                    const initialPageData = responseData[targetKey].slice(
                      startIndex,
                      endIndex
                    );
                    const rows = initialPageData.map((row) =>
                      Object.values(row)
                    );

                    // Create a new body row for each data row
                    rows.forEach((rowData) => {
                      const bodyRow = tbody.components().add({
                        type: "row",
                        tagName: "tr",
                        style: {
                          height: "20px",
                          border: "1px solid gray",
                        },
                      });

                      rowData.forEach((cellData) => {
                        // Create a cell for each data cell
                        const cell = bodyRow.components().add({
                          type: "cell",
                          tagName: "td",
                          style: {
                            outline: "none",
                            border: "1px solid gray",
                            "align-items": "center",
                            "justify-content": "center",
                            "text-align": "center",
                            padding: "1rem",
                          },
                        });

                        // Create a text component inside the cell
                        const text = cell.components().add({
                          type: "text",
                          tagName: "p",
                          content: cellData,
                        });
                      });
                    });
                  }

                  // items per page
                  let itemsPerPage = 5;

                  // PAGINATION GOES HERE::::::::::::::::::
                  const totalPages = Math.ceil(
                    responseData[targetKey].length / itemsPerPage
                  );
                  const paginationContainer =
                    component.findType("customPagination")[0];

                  // Clear existing pagination buttons
                  paginationContainer.components().reset();

                  // Generate pagination buttons dynamically
                  for (let i = 1; i <= totalPages; i++) {
                    // Create a button for each page number
                    const button = paginationContainer.components().add({
                      tagName: "button",
                      content: i,
                      style: {
                        "padding-right": "0.5rem",
                        "padding-left": "0.5rem",
                        "padding-top": "0.3rem",
                        "padding-bottom": "0.3rem",
                        cursor: "pointer", // Add cursor pointer for clickability
                      },
                    });

                    // Attach an onClick event to each button
                    button.on("click", () => {
                      // Calculate the range of data to display for the selected page
                      const startIndex = (i - 1) * itemsPerPage;
                      const endIndex = Math.min(
                        startIndex + itemsPerPage,
                        responseData[targetKey].length
                      );

                      // Update the table's body with the data for the selected page
                      updateTableBody(
                        component,
                        responseData[targetKey].slice(startIndex, endIndex)
                      );
                    });
                  }
                }
              }
            })
            .catch((error) => {
              console.error("Error fetching data:", error);
            });
        } else if (
          baseUrls &&
          !username &&
          !password &&
          baseUrlBearerToken &&
          !targetKey &&
          !apiKey
        ) {
          console.log("baseUrlBearerToken123::::::::::::", baseUrlBearerToken);
          let config = {
            headers: {
              Authorization: `Bearer ${baseUrlBearerToken}`,
            },
          };
          // ['a:b', "c:d"]
          // extracting headers from More_Headers trait
          if (moreHeaders) {
            moreHeaders.split(",").forEach((header) => {
              let key, val;
              [key, val] = header.split(":");

              config.headers[key] = val;
            });
          }
          fetch(baseUrls, config)
            .then((response) => response.json())
            .then((responseData) => {
              if (
                Array.isArray(responseData) &&
                responseData.length > 0 &&
                typeof responseData[0] === "object"
              ) {
                const columns = Object.keys(responseData[0]);
                localStorage.setItem(
                  "flexTableData",
                  JSON.stringify(responseData)
                );

                // Find the thead component
                let thead = component.findType("thead")[0];
                if (thead) {
                  // Remove existing header rows
                  thead.components().reset();

                  // Create a new header row with customTdWrapper components
                  const headerRow = thead.components().add({
                    type: "row",
                    tagName: "tr",
                    style: {
                      height: "20px",
                      border: "1px solid gray",
                    },
                  });
                  const columnWidth = 100 / columns.length + "%";

                  columns.forEach((column) => {
                    // Create a customTdWrapper component for each column
                    const customTdWrapper = headerRow.components().add({
                      type: "customTdWrapper",
                      tagName: "td",
                      style: {
                        outline: "none",
                        "font-size": "24px",
                        "font-weight": "800",
                        "text-align": "center",
                        border: "1px solid gray",
                        "min-width": "300px",
                      },
                    });

                    // Set the header text
                    customTdWrapper
                      .components()
                      .models[0].components()
                      .models[0].set("content", column);
                  });
                }

                let tbody = component.findType("tbody")[0];

                if (tbody) {
                  tbody.components().reset();

                  // Initial rendering of the first page with 5 items
                  const startIndex = 0;
                  const endIndex = Math.min(
                    startIndex + 5,
                    responseData.length
                  );
                  const initialPageData = responseData.slice(
                    startIndex,
                    endIndex
                  );
                  const rows = initialPageData.map((row) => Object.values(row));

                  // Create a new body row for each data row
                  rows.forEach((rowData) => {
                    const bodyRow = tbody.components().add({
                      type: "row",
                      tagName: "tr",
                      style: {
                        height: "20px",
                        border: "1px solid gray",
                      },
                    });

                    rowData.forEach((cellData) => {
                      // Create a cell for each data cell
                      const cell = bodyRow.components().add({
                        type: "cell",
                        tagName: "td",
                        style: {
                          outline: "none",
                          border: "1px solid gray",
                          "align-items": "center",
                          "justify-content": "center",
                          "text-align": "center",
                          padding: "1rem",
                        },
                      });

                      // Create a text component inside the cell
                      const text = cell.components().add({
                        type: "text",
                        tagName: "p",
                        content: cellData,
                      });
                    });
                  });
                }

                // items per page
                let itemsPerPage = 5;

                // PAGINATION GOES HERE::::::::::::::::::
                const totalPages = Math.ceil(
                  responseData.length / itemsPerPage
                );
                const paginationContainer =
                  component.findType("customPagination")[0];

                // Clear existing pagination buttons
                paginationContainer.components().reset();

                // Generate pagination buttons dynamically
                for (let i = 1; i <= totalPages; i++) {
                  // Create a button for each page number
                  const button = paginationContainer.components().add({
                    tagName: "button",
                    content: i,
                    style: {
                      "padding-right": "0.5rem",
                      "padding-left": "0.5rem",
                      "padding-top": "0.3rem",
                      "padding-bottom": "0.3rem",
                      cursor: "pointer", // Add cursor pointer for clickability
                    },
                  });

                  // Attach an onClick event to each button
                  button.on("click", () => {
                    // Calculate the range of data to display for the selected page
                    const startIndex = (i - 1) * itemsPerPage;
                    const endIndex = Math.min(
                      startIndex + itemsPerPage,
                      responseData.length
                    );

                    // Update the table's body with the data for the selected page
                    updateTableBody(
                      component,
                      responseData.slice(startIndex, endIndex)
                    );
                  });
                }
              }
            })
            .catch((error) => {
              console.error("Error fetching data:", error);
            });
        }
      }
    }
  });

  editor.on("component:update", async (component) => {
    // click events on pagination buttons
    if (component && component.attributes.type === "customPagination") {
      let itemsPerPage = 5;
      let responseFromApi = JSON.parse(localStorage.getItem("flexTableData"));

      component.components().models.forEach((btn) => {
        btn.getEl().addEventListener("click", (event) => {
          const pageNumber = parseInt(event.target.textContent);

          const startIndex = (pageNumber - 1) * itemsPerPage;
          const endIndex = Math.min(
            startIndex + itemsPerPage,
            responseFromApi.length
          );

          updateTableBody(
            component.parent(),
            responseFromApi.slice(startIndex, endIndex)
          );
        });
      });
    }

    // click events on search bar
    if (component && component.attributes.type === "customToolbar") {
      component.components().models.forEach((comp) => {
        if (comp.attributes.tagName === "input") {
          comp.getEl().addEventListener("input", (event) => {
            const searchText = event.target.value.trim();
            const responseFromApi = JSON.parse(
              localStorage.getItem("flexTableData")
            );

            if (searchText === "") {
              const startIndex = 0;
              const endIndex = Math.min(startIndex + 5, responseFromApi.length);
              const initialPageData = responseFromApi.slice(
                startIndex,
                endIndex
              );

              updateTableBody(component.parent(), initialPageData);
            } else {
              updateTableBodyWithSearch(
                component.parent(),
                responseFromApi,
                searchText
              );
            }
          });
        }

        if (comp.attributes.tagName === "button") {
          console.log("here comp", comp);
          // grab show all column button and write the logic
          if (comp.attributes.classes.models[0].name === "showAll") {
          }

          // grab dark theme  button and write the logic
          if (comp.attributes.classes.models[0].name === "darkTheme") {
          }

          // grab light theme  button and write the logic
          if (comp.attributes.classes.models[0].name === "lightTheme") {
          }
        }
      });
    }

    // click events on hide/show icons
    // if (component && component.attributes.type === "customIcons") {
    //   component.components().models.forEach((comp) => {
    //     if (comp.attributes.attributes.name === "hideColumn") {
    //       comp.getEl().addEventListener("click", (event) => {
    //         alert("clicked");
    //       });
    //     }
    //   });
    // }
  });
};

export default customTablePlugin;
