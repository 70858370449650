import uniqid from "uniqid";
import UIkit from "uikit";
import $ from "jquery";
import { API_HOST } from "../api_utils/index";

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const { jsPDF } = require("jspdf");
const { html2canvas } = require("html2canvas");

const CustomPDFPlugin = (editor, options) => {
	console.log('CustomPDFPlugin options', options);

	const notifyTheme = {
		position: "top-right",
		autoClose: 7500,
		hideProgressBar: false,
		closeOnClick: true,
		pauseOnHover: true,
		draggable: true,
		progress: undefined,
		theme: "light",
	};

	const notifyTheme2 = {
		position: "top-right",
		autoClose: 3000,
		hideProgressBar: false,
		closeOnClick: true,
		pauseOnHover: true,
		draggable: true,
		progress: undefined,
		theme: "light",
	};

	const successTheme = {
	  position: "top-right",
	  autoClose: 5000,
	  hideProgressBar: false,
	  closeOnClick: true,
	  pauseOnHover: true,
	  draggable: true,
	  progress: undefined,
	  theme: "light",
	};

	// toast notification for empty tables
	const infoTheme = {
	  position: "top-right",
	  autoClose: 7500,
	  hideProgressBar: false,
	  closeOnClick: true,
	  pauseOnHover: true,
	  draggable: true,
	  progress: undefined,
	  theme: "light",
	};

    if (editor !== null && editor !== undefined) {
    	editor.Blocks.add("pdf-downloader-container", {
	        label: "Generate PDF",
	        category: "Buttons",
	        select: true,
	        media: `<svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="currentColor" class="bi bi-filetype-pdf" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M14 4.5V14a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5zM1.6 11.85H0v3.999h.791v-1.342h.803q.43 0 .732-.173.305-.175.463-.474a1.4 1.4 0 0 0 .161-.677q0-.375-.158-.677a1.2 1.2 0 0 0-.46-.477q-.3-.18-.732-.179m.545 1.333a.8.8 0 0 1-.085.38.57.57 0 0 1-.238.241.8.8 0 0 1-.375.082H.788V12.48h.66q.327 0 .512.181.185.183.185.522m1.217-1.333v3.999h1.46q.602 0 .998-.237a1.45 1.45 0 0 0 .595-.689q.196-.45.196-1.084 0-.63-.196-1.075a1.43 1.43 0 0 0-.589-.68q-.396-.234-1.005-.234zm.791.645h.563q.371 0 .609.152a.9.9 0 0 1 .354.454q.118.302.118.753a2.3 2.3 0 0 1-.068.592 1.1 1.1 0 0 1-.196.422.8.8 0 0 1-.334.252 1.3 1.3 0 0 1-.483.082h-.563zm3.743 1.763v1.591h-.79V11.85h2.548v.653H7.896v1.117h1.606v.638z"/></svg>`,
	        content: { type: "pdf-downloader-container" },
	    });

	    const downloadPDFScript = function(props) {
		    console.log('pdf-downloader-container props', props);

		    let pdfDownloaderContainer = this;
		    let pdfDownloaderButton = pdfDownloaderContainer.getElementsByClassName("pdf-downloader-button")[0];

		    function initLib(props) {
		        let { StringifiedFragment, pdfFormat, pdfOrientation, pdfUnit, pdfContentScale, pdfFileName, allowDownload } = props;

		        const parser = new DOMParser();
		        const frag = parser.parseFromString(StringifiedFragment, 'text/html');
		        console.log('Parsed fragment:', frag);

		        // Function to apply CSS rules as inline styles
		        function applyInlineStyles(element, cssRules) {
		            cssRules.forEach(rule => {
		                if (rule.type === CSSRule.STYLE_RULE) {
		                    const { selectorText, style } = rule;
		                    try {
		                        if (element.matches(selectorText)) {
		                            for (let i = 0; i < style.length; i++) {
		                                const styleName = style[i];
		                                if (styleName === 'background-image') {
		                                    const url = style.getPropertyValue(styleName);
		                                    element.style[styleName] = url.replace(/&quot;/g, '"');
		                                } else {
		                                    element.style[styleName] = style[styleName];
		                                }
		                            }
		                        }
		                    } catch (e) {
		                        console.warn(`Invalid selector: ${selectorText}`);
		                    }
		                }
		            });

		            Array.from(element.children).forEach(child => applyInlineStyles(child, cssRules));
		        }

		        // Extract the CSS rules from the style element
		        const styleSheets = Array.from(frag.styleSheets);
		        const cssRules = [];
		        styleSheets.forEach(sheet => {
		            try {
		                Array.from(sheet.cssRules).forEach(rule => {
		                    cssRules.push(rule);
		                });
		            } catch (e) {
		                console.warn('Access to stylesheet rules is restricted', e);
		            }
		        });

		        // Apply the inline styles
		        const emailTemplateWrapper = frag.querySelector('.pdf-downloader-wrapper');
		        if (emailTemplateWrapper) {
		            applyInlineStyles(emailTemplateWrapper, cssRules);
		        }

		        if (allowDownload) {
		            pdfDownloaderButton.onclick = async function(e) {
		                console.log('Download button clicked');
		                const { jsPDF } = window.jspdf;

		                // Creating a temporary element to hold the new HTML content
		                // const tempDiv = document.createElement('div');
		                // tempDiv.style.position = 'absolute';
		                // tempDiv.style.left = '-9999px';

		                // if (emailTemplateWrapper) {
		                //     tempDiv.appendChild(emailTemplateWrapper.cloneNode(true));
		                // } else {
		                //     console.error('pdf-downloader-wrapper not found');
		                //     return;
		                // }

		                document.body.appendChild(emailTemplateWrapper);

		                try {
		                    // Capture the content with html2canvas
		                    const canvas = await html2canvas(emailTemplateWrapper, {
		                        scale: 2, // Adjust the scale to improve image quality
		                        dpi: 300,
		                        useCORS: true,
		                        letterRendering: true,
		                        scrollX: -window.scrollX,
		                        scrollY: -window.scrollY,
		                    });

		                    // Calculate dimensions for the PDF
		                    const canvasWidth = canvas.width;
		                    const canvasHeight = canvas.height;

		                    const pdfWidth = canvasWidth / (pdfContentScale ? Number(pdfContentScale) : 3.5); // Adjust the divisor for desired scaling
		                    const pdfHeight = canvasHeight / (pdfContentScale ? Number(pdfContentScale) : 3.5); // Adjust the divisor for desired scaling

		                    const pdf = new jsPDF({
		                        orientation: pdfOrientation ? pdfOrientation : 'portrait',
		                        unit: pdfUnit ? pdfUnit : 'pt',
		                        format: pdfFormat ? pdfFormat : [pdfWidth, pdfHeight],
		                    });

		                    // Add the image to the PDF
		                    const imgData = canvas.toDataURL('image/png', 0.6); // Adjust the quality (0.0 to 1.0)
		                    pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);

		                    // Download the PDF
		                    pdf.save(pdfFileName ? pdfFileName + '.pdf' : 'redSling_pdf_export.pdf');

		                    console.log('PDF saved');
		                } catch (error) {
		                    console.error('Error generating PDF:', error);
		                } finally {
		                    document.body.removeChild(emailTemplateWrapper);
		                }
		            };
		        } else {
		            pdfDownloaderButton.onclick = null;
		        }
		    }

		    const loadScript = (src, callback) => {
		        const script = document.createElement('script');
		        script.src = src;
		        script.onload = callback;
		        script.onerror = () => console.error(`Error loading script: ${src}`);
		        document.body.appendChild(script);
		    };

		    if (typeof window.jspdf === "undefined" || typeof window.html2canvas === "undefined") {
		        loadScript("https://cdnjs.cloudflare.com/ajax/libs/html2canvas/1.4.1/html2canvas.min.js", () => {
		            loadScript("https://cdnjs.cloudflare.com/ajax/libs/jspdf/2.5.1/jspdf.umd.min.js", () => {
		                console.log("Libraries loaded");
		                initLib(props);
		            });
		        });
		    } else {
		        console.log("Libraries already loaded");
		        initLib(props);
		    }
		};

	    editor.DomComponents.addType("pdf-downloader-container", {
	    	model: {
		        defaults: {
		            tagName: "div",
		            attributes: {
		              	class: "pdf-downloader-container",
		            },
		            type: "pdf-downloader-container",
		            script: downloadPDFScript,

		            // traits
		            API_HOST: API_HOST,
		            PDFTemplate: "",
		            StringifiedFragment: "",
		            projectId: localStorage.getItem("project_id") ? localStorage.getItem("project_id") : "",

		            pdfFormat: "a4",
		            pdfOrientation: "portrait",
		            pdfUnit: "pt",
		            pdfContentScale: 3.5,
					pdfFileName: `redSling_pdf_export`,
					allowDownload: false,
		            DisplayComponent: false,

					traits: [
						{
		            		type: "select",
		            		name: "PDFTemplate",
		            		label: "Template Fragment",
		            		changeProp: true,
		            		options: options.allFragData ? options.allFragData?.map((template) => {
		            			const obj = {
		            				name: template.fragment_name,
		            				value: template.fragment_name,
		            			};
		            			return obj;
		            		}) : "",
		            	}, {
		            		type: "select",
		            		name: "pdfOrientation",
		            		label: "PDF Orientation",
		            		changeProp: true,
		            		options: [
				                { value: "portrait", name: "Portrait" },
				                { value: "landscape", name: "Landscape" },
			                ],
		            	}, {
		            		type: "select",
		            		name: "pdfUnit",
		            		label: "PDF Unit",
		            		changeProp: true,
		            		options: [
				                { value: "pt", name: "pt" },
				                { value: "mm", name: "mm" },
				                { value: "cm", name: "cm" },
				                { value: "in", name: "in" },
				                { value: "px", name: "px" },
				                { value: "pc", name: "pc" },
				                { value: "em", name: "em" },
				                { value: "ex", name: "ex" },
			                ],
		            	}, {
		            		type: "select",
		            		name: "pdfFormat",
		            		label: "PDF Format",
		            		changeProp: true,
		            		options: [
				                 { value: "a0", name: "a0" },
				                 { value: "a1", name: "a1" },
				                 { value: "a2", name: "a2" },
				                 { value: "a3", name: "a3" },
				                 { value: "a4", name: "a4" },
				                 { value: "a5", name: "a5" },
				                 { value: "a6", name: "a6" },
				                 { value: "a7", name: "a7" },
				                 { value: "a8", name: "a8" },
				                 { value: "a9", name: "a9" },
				                 { value: "a10", name: "a10" },
				                 { value: "b0", name: "b0" },
				                 { value: "b1", name: "b1" },
				                 { value: "b2", name: "b2" },
				                 { value: "b3", name: "b3" },
				                 { value: "b4", name: "b4" },
				                 { value: "b5", name: "b5" },
				                 { value: "b6", name: "b6" },
				                 { value: "b7", name: "b7" },
				                 { value: "b8", name: "b8" },
				                 { value: "b9", name: "b9" },
				                 { value: "b10", name: "b10" },
				                 { value: "c0", name: "c0" },
				                 { value: "c1", name: "c1" },
				                 { value: "c2", name: "c2" },
				                 { value: "c3", name: "c3" },
				                 { value: "c4", name: "c4" },
				                 { value: "c5", name: "c5" },
				                 { value: "c6", name: "c6" },
				                 { value: "c7", name: "c7" },
				                 { value: "c8", name: "c8" },
				                 { value: "c9", name: "c9" },
				                 { value: "c10", name: "c10" },
				                 { value: "letter", name: "Letter" },
				                 { value: "government-letter", name: "Government-Letter" },
				                 { value: "legal", name: "Legal" },
				                 { value: "junior-legal", name: "Junior-Legal" },
				                 { value: "ledger", name: "Ledger" },
				                 { value: "tabloid", name: "Tabloid" },
				                 { value: "credit-card", name: "Credit-Card" },
			                ],
		            	},
						{
							type: 'number',
							min: 0,
							label: "PDF Content Scale",
							placeholder: "Min: 0",
							name: "pdfContentScale",
							changeProp: true,
						},
						{
							type: 'text',
							label: "PDF FileName",
							name: "pdfFileName",
							changeProp: true,
						},
						{
							type: 'checkbox',
							label: "Allow Download",
							name: "allowDownload",
							changeProp: true,
						},
						{
			                type: "checkbox",
			                name: "DisplayComponent",
			                label: "Hide/Show Component",
			                changeProp: true,
			            },
					],

					changeProp: true,
					"script-props": ["API_HOST", "PDFTemplate", "StringifiedFragment", "projectId", "pdfFormat", "pdfOrientation", "pdfUnit", "pdfContentScale", "pdfFileName", "allowDownload", "DisplayComponent"],

		            components: [
			            {
			                tagName: "button",
			                type: "pdf-downloader-button",
			                attributes: { class: "pdf-downloader-button" },
			                editable: true,
			  
			                "style-default": {
				                width: "max-content",
				                padding: "0.7%",
				                "border-radius": "10px",
				                "background-color": "white",
				                border: "2px solid red",
				                color: "red",
				                cursor: "pointer",

				                display: 'flex',
				                'align-items': 'center',
				                'justify-content': 'center',
				                gap: '3%',
			                },
			  
			                style: {
				                width: "max-content",
				                padding: "0.7%",
				                "border-radius": "10px",
				                "background-color": "white",
				                border: "2px solid red",
				                color: "red",
				                cursor: "pointer",

				                display: 'flex',
				                'align-items': 'center',
				                'justify-content': 'center',
				                gap: '3%',
			                },
			  
			                components: [
			                	`<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-filetype-pdf" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M14 4.5V14a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5zM1.6 11.85H0v3.999h.791v-1.342h.803q.43 0 .732-.173.305-.175.463-.474a1.4 1.4 0 0 0 .161-.677q0-.375-.158-.677a1.2 1.2 0 0 0-.46-.477q-.3-.18-.732-.179m.545 1.333a.8.8 0 0 1-.085.38.57.57 0 0 1-.238.241.8.8 0 0 1-.375.082H.788V12.48h.66q.327 0 .512.181.185.183.185.522m1.217-1.333v3.999h1.46q.602 0 .998-.237a1.45 1.45 0 0 0 .595-.689q.196-.45.196-1.084 0-.63-.196-1.075a1.43 1.43 0 0 0-.589-.68q-.396-.234-1.005-.234zm.791.645h.563q.371 0 .609.152a.9.9 0 0 1 .354.454q.118.302.118.753a2.3 2.3 0 0 1-.068.592 1.1 1.1 0 0 1-.196.422.8.8 0 0 1-.334.252 1.3 1.3 0 0 1-.483.082h-.563zm3.743 1.763v1.591h-.79V11.85h2.548v.653H7.896v1.117h1.606v.638z"/></svg>`,
				                {
					                tagName: "p",
					                type: "text",
					  
					                components: {
					                    type: "textnode",
					                    content: "Generate PDF",
					                },
					  
					                "style-default": {
					                    margin: "auto",
					                    "text-align": "left",
					                    color: "inherit",
					                },
					  
					                style: {
					                    margin: "auto",
					                    "text-align": "left",
					                    color: "inherit",
					                },
				                }
			                ],
			            }
		            ],
		        },

	    		init() {
	    			this.on("change:PDFTemplate", this.pdfTemplateHandler);
	    			this.on("change:DisplayComponent", this.displayComponentHandler);
	    		},

	    		async pdfTemplateHandler() {
	    			let {API_HOST, PDFTemplate, projectId} = this.props();

	    			if (PDFTemplate !== "") {
	    				try {
		    				// make an api call
		    				const response = await fetch(`${API_HOST}template_fragment/get_all_fragments`, {
		    					method: "POST",
		    					headers: {
		    						"Content-Type": "application/json",
		    						"Authorization": `Bearer ${JSON.parse(localStorage.getItem('userInfo'))['token']}`,
		    					},
		    					body: JSON.stringify({projectId: projectId}),
		    				});

		    				let responseData = await response.json();

		    				const selectedFragment = responseData.filter((temp) => temp.fragment_name === PDFTemplate);
		    				console.log('selectedFragment', selectedFragment);

		    				const parser = new DOMParser();

		    				// parse html document from graepsjs string
		    				const htmlDocument = parser.parseFromString(selectedFragment[0]['fragment_content']['my-html'], 'text/html');
		    				console.log('htmlDocument', htmlDocument);

		    				// Create an iframe to execute the script in a sandboxed environment
					        const iframe = document.createElement('iframe');
					        iframe.style.display = 'none';
					        document.body.appendChild(iframe);

					        // Write the HTML, CSS, and JS into the iframe
					        const iframeDoc = iframe.contentDocument || iframe.contentWindow.document;
					        iframeDoc.open();
					        iframeDoc.write('<html><head></head><body></body></html>');
					        iframeDoc.close();

					        // Append CDN links to the iframe's head
					        const cdnLinks = [
					            'https://cdn.quilljs.com/1.3.6/quill.snow.css', // CSS CDN
					            'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css', // CSS CDN
					            "https://cdnjs.cloudflare.com/ajax/libs/uikit/2.27.5/css/uikit.min.css",
					            "https://cdnjs.cloudflare.com/ajax/libs/uikit/2.27.5/css/uikit.almost-flat.min.css",
					            "https://cdnjs.cloudflare.com/ajax/libs/uikit/2.27.5/css/uikit.gradient.min.css",
					            "https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/css/bootstrap.min.css",

					            'https://code.jquery.com/jquery-3.7.1.min.js', // JS CDN
					            'https://unpkg.com/xlsx/dist/xlsx.full.min.js', // JS CDN
					            "https://unpkg.com/vis-graph3d@latest/dist/vis-graph3d.min.js",
					            "https://cdn.jsdelivr.net/npm/chart.js",
								"https://cdnjs.cloudflare.com/ajax/libs/uikit/2.27.5/js/uikit.min.js",
					            "https://cdnjs.cloudflare.com/ajax/libs/uikit/2.27.5/js/components/pagination.min.js",
								"https://cdn.quilljs.com/1.3.6/quill.js",
								"https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/js/bootstrap.min.js",
								"https://cdn.jsdelivr.net/npm/@popperjs/core@2.11.6/dist/umd/popper.min.js",
					            // Add more CDN links as needed
					        ];

					        const appendResource = (link) => {
				                return new Promise((resolve, reject) => {
				                    const isCss = link.endsWith('.css');
				                    const element = iframeDoc.createElement(isCss ? 'link' : 'script');
				                    if (isCss) {
				                        element.rel = 'stylesheet';
				                        element.href = link;
				                        element.onload = () => {
				                            console.log(`Loaded CSS: ${link}`);
				                            resolve();
				                        };
				                        element.onerror = (error) => {
				                            console.error(`Failed to load CSS: ${link}`, error);
				                            reject(error);
				                        };
				                    } else {
				                        element.src = link;
				                        element.onload = () => {
				                            console.log(`Loaded JS: ${link}`);
				                            resolve();
				                        };
				                        element.onerror = (error) => {
				                            console.error(`Failed to load JS: ${link}`, error);
				                            reject(error);
				                        };
				                    }
				                    iframeDoc.head.appendChild(element);
				                    console.log(`Appending ${isCss ? 'CSS' : 'JS'}: ${link}`);
				                });
				            };

				            const jqueryLink = 'https://code.jquery.com/jquery-3.7.1.min.js';
				            await appendResource(jqueryLink);

				            const cdnPromises = cdnLinks.filter(link => link !== jqueryLink).map(link => appendResource(link));

				            const stylePromise = new Promise((resolve, reject) => {
				                try {
				                    const styleElement = iframeDoc.createElement('style');
				                    styleElement.textContent = selectedFragment[0]['fragment_content']['my-css'];
				                    iframeDoc.head.appendChild(styleElement);
				                    console.log("Appended style element");
				                    resolve();
				                } catch (error) {
				                    console.error("Failed to append style element", error);
				                    reject(error);
				                }
				            });

				            console.log("Appending HTML content...");
				            iframeDoc.body.innerHTML = htmlDocument.body.innerHTML;

				            // Wait for all resources to load
				            console.log("Waiting for all resources to load...");
				            await Promise.all([...cdnPromises, stylePromise]);

				            // Append the script element after resources have loaded
				            const scriptElement = iframeDoc.createElement('script');
				            scriptElement.setAttribute('type', 'text/javascript');
				            scriptElement.textContent = `
				                try {
				                    console.log("Inside script element before executing custom JS.");
				                    ${selectedFragment[0]['fragment_content']['my-js']}
				                    console.log("Inside script element after executing custom JS.");
				                } catch (error) {
				                    console.error("Error executing script element", error);
				                }
				            `;
				            iframeDoc.body.appendChild(scriptElement);
				            console.log("Script element executed");

				            // Add a small delay to ensure all scripts have executed
				            await new Promise(resolve => setTimeout(resolve, 5000));

				            // Extract the CSS rules from the style element
				            const styleSheets = Array.from(iframeDoc.styleSheets);
				            const cssRules = [];
				            styleSheets.forEach(sheet => {
				                try {
				                    Array.from(sheet.cssRules).forEach(rule => {
				                        cssRules.push(rule);
				                    });
				                } catch (e) {
				                    console.warn('Access to stylesheet rules is restricted', e);
				                }
				            });

				            // Function to apply CSS rules as inline styles
				            function applyInlineStyles(element, cssRules) {
				                cssRules.forEach(rule => {
				                    if (rule.type === CSSRule.STYLE_RULE) {
				                        const { selectorText, style } = rule;
				                        try {
				                            if (element.matches(selectorText)) {
				                                for (let i = 0; i < style.length; i++) {
				                                    const styleName = style[i];
				                                    if (styleName === 'background-image') {
				                                        const url = style.getPropertyValue(styleName);
				                                        element.style[styleName] = url.replace(/&quot;/g, '"');
				                                    } else {
				                                        element.style[styleName] = style[styleName];
				                                    }
				                                }
				                            }
				                        } catch (e) {
				                            console.warn(`Invalid selector: ${selectorText}`);
				                        }
				                    }
				                });

				                Array.from(element.children).forEach(child => applyInlineStyles(child, cssRules));
				            }

				            let fragmentStringified = '';
				            const emailTemplateWrapper = iframeDoc.querySelector('.pdf-downloader-wrapper');

				            if (emailTemplateWrapper) {
				                const emailTemplateInnerWrapper = emailTemplateWrapper.querySelector('.pdf-downloader-inner-wrapper');
				                if (emailTemplateInnerWrapper) {
				                    applyInlineStyles(emailTemplateWrapper, cssRules);
				                    // Serialize the email-template-wrapper and its inner HTML with inline styles
				                    fragmentStringified = new XMLSerializer().serializeToString(emailTemplateWrapper);
				                }
				            }

				            console.log('fragmentStringified', fragmentStringified);

				            this.set('StringifiedFragment', fragmentStringified);
				            document.body.removeChild(iframe);

				            if (fragmentStringified === "") {
				                toast.error("This template fragment did not render properly in the PDF. Please check if this template fragment has been created properly.", notifyTheme);
				            } else {
				                toast.success(`This template fragment has rendered.`, successTheme);
				            }
				        } catch (e) {
				            console.error(e);
				        }
				    }
				},

	    		displayComponentHandler() {
	    			let {DisplayComponent} = this.props();

	    			if (DisplayComponent) {
	    				this.setStyle({ display: 'none' });
	    			} else {
	    				this.setStyle({ display: 'block' });
	    			}
	    		},
	        },
	    });
    }
};

export default CustomPDFPlugin;