// import { GET_ALL_CUSTOMTABLES_BY_USER_EMPTY, GET_ALL_CUSTOMTABLES_BY_USER_FAILED, GET_ALL_CUSTOMTABLES_BY_USER_SUCCESS } from "../Constants/customTablesConstants";


// export const getAllModels = () => async (dispatch, getState) => {
//     try {
//         dispatch({ type: GET_ALL_PROJECT_BY_USER_LOADING })
//         const { userLogin: { userInfo } } = getState()
//         const config = {
//             headers: {
//                 'Content-Type': 'application/json',
//                 Authorization: `Bearer ${userInfo.token}`
//             }
//         }
//         const { data } = await axios.get(`${API_HOST}custommodels`, config)
//         if (!data.length || typeof data === 'undefined' || typeof data === null) {
//             dispatch({ type: GET_ALL_CUSTOMTABLES_BY_USER_EMPTY })
//         } else {
//             dispatch({ type: GET_ALL_CUSTOMTABLES_BY_USER_SUCCESS, payload: data })
//         }

//     } catch (error) {
//         dispatch({ type: GET_ALL_CUSTOMTABLES_BY_USER_FAILED , payload: 'something went wrong' })
//         toast.error(`something went wrong`, errorTheme)
//     }
// }

import { toast } from "react-toastify";
import { GET_ALL_CUSTOMTABLES_OF_PROJECT, GET_ALL_CUSTOMTABLES_OF_PROJECT_FAILED, GET_ALL_CUSTOMTABLES_OF_PROJECT_SUCCESS } from "../Constants/customTablesConstants";
import { API_HOST } from "../../api_utils";
import axios from "axios";
import { UPDATE_MODAL_TABLE_ERROR, UPDATE_MODAL_TABLE_LOADING, UPDATE_MODAL_TABLE_SUCCESS } from "../Constants/modalTableConstants";

export const getAllModels = (project_id) => async (dispatch, getState) => {
    try {
        // dispatch({ type: GET_ALL_PROJECT_BY_USER_LOADING })
        const { userLogin: { userInfo } } = getState()
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.post(`${API_HOST}customroutes/get`, { projectId: project_id }, config)
        //console.log('data:::::customTableActions', data)
        if (!data.length || typeof data === 'undefined' || typeof data === null) {

            dispatch({ type: GET_ALL_CUSTOMTABLES_OF_PROJECT_FAILED })
        } else {
            if (data.message) {
                dispatch({ type: GET_ALL_CUSTOMTABLES_OF_PROJECT_FAILED })
                toast.success(`${data.message}`)
            }
            else {
                dispatch({ type: GET_ALL_CUSTOMTABLES_OF_PROJECT_SUCCESS, payload: data })
            }

        }
    } catch (error) {
        dispatch({
            type: GET_ALL_CUSTOMTABLES_OF_PROJECT_FAILED
        })

    }
}

export const updateModal = (modelData) => async (dispatch, getState) => {
    try {

        //console.log("modalData::::::", modelData);

        dispatch({ type: UPDATE_MODAL_TABLE_LOADING })
        const { userLogin: { userInfo } } = getState()
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }
        const fieldsToBeUpdated = {
            fields: modelData.fields,
            table_name: modelData.table_name,
            referenceTables: modelData.referenceTables
        }

        const { model_id } = modelData
        const { data } = await axios.put(`${API_HOST}customroutes/${model_id}`, fieldsToBeUpdated, config)

        dispatch({ type: UPDATE_MODAL_TABLE_SUCCESS, payload: data })

    } catch (error) {
        dispatch({ type: UPDATE_MODAL_TABLE_ERROR, payload: 'something went wrong' })
    }
}

