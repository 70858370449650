import { APPROVE_REQUEST_LIST_FAILED, APPROVE_REQUEST_LIST_LOADING, APPROVE_REQUEST_LIST_SUCCESS, DELETE_LIST_OF_USER_UNDER_COMPANY_FAILED, DELETE_LIST_OF_USER_UNDER_COMPANY_LOADING, DELETE_LIST_OF_USER_UNDER_COMPANY_SUCCESS, DELETE_REQUEST_LIST_FAILED, DELETE_REQUEST_LIST_LOADING, DELETE_REQUEST_LIST_SUCCESS, GET_LIST_OF_USER_UNDER_COMPANY_FAILED, GET_LIST_OF_USER_UNDER_COMPANY_LOADING, GET_LIST_OF_USER_UNDER_COMPANY_SUCCESS, GET_REQUEST_LIST_FAILED, GET_REQUEST_LIST_LOADING, GET_REQUEST_LIST_SUCCESS, UPDATE_LIST_OF_USER_UNDER_COMPANY_FAILED, UPDATE_LIST_OF_USER_UNDER_COMPANY_LOADING, UPDATE_LIST_OF_USER_UNDER_COMPANY_SUCCESS } from "../Constants/adminScreenConstants";




export const getRequestListReducer = (state = {}, action) => {
    switch (action.type) {
        case GET_REQUEST_LIST_LOADING:
            return { getReqLoading: true }
        case GET_REQUEST_LIST_SUCCESS:
            return { getReqLoading: false, getListData: action.payload }
        case GET_REQUEST_LIST_FAILED:
            return { getReqLoading: false, getListError: action.payload }
        default:
            return state;
    }
}

export const updateRequestListReducer = (state = {}, action) => {
    switch (action.type) {
        case APPROVE_REQUEST_LIST_LOADING:
            return { updateReqLoading: true }
        case APPROVE_REQUEST_LIST_SUCCESS:
            return { updateReqLoading: false, updatedList: action.payload }
        case APPROVE_REQUEST_LIST_FAILED:
            return { updateReqLoading: false, updateError: action.payload }
        default:
            return state;
    }
}

export const deleteRequestListReducer = (state = {}, action) => {
    switch (action.type) {
        case DELETE_REQUEST_LIST_LOADING:
            return { delReqLoading: true }
        case DELETE_REQUEST_LIST_SUCCESS:
            return { delReqLoading: false, deleteSuccess: action.payload }
        case DELETE_REQUEST_LIST_FAILED:
            return { delReqLoading: false, deleteError: action.payload }
        default:
            return state;
    }
}

export const getUsersUnderCompanyReducer = (state = { userListData: [] }, action) => {
    switch (action.type) {
        case GET_LIST_OF_USER_UNDER_COMPANY_LOADING:
            return { userListLoading: true, userListData: [] }
        case GET_LIST_OF_USER_UNDER_COMPANY_SUCCESS:
            return { userListLoading: false, userListData: action.payload }
        case GET_LIST_OF_USER_UNDER_COMPANY_FAILED:
            return { userListLoading: false, userListError: action.payload, userListData: [] }
        default:
            return state;
    }
}

export const updateUsersUnderCompanyReducer = (state = {}, action) => {
    switch (action.type) {
        case UPDATE_LIST_OF_USER_UNDER_COMPANY_LOADING:
            return { updateUserListLoading: true }
        case UPDATE_LIST_OF_USER_UNDER_COMPANY_SUCCESS:
            return { updateUserListLoading: false, updateUserData: action.payload }
        case UPDATE_LIST_OF_USER_UNDER_COMPANY_FAILED:
            return { updateUserListLoading: false, updateUserError: action.payload }
        default:
            return state;
    }
}

export const deleteUsersUnderCompanyReducer = (state = {}, action) => {
    switch (action.type) {
        case DELETE_LIST_OF_USER_UNDER_COMPANY_LOADING:
            return { deleteUserListLoading: true }
        case DELETE_LIST_OF_USER_UNDER_COMPANY_SUCCESS:
            return { deleteUserListLoading: false, deleteUserData: action.payload }
        case DELETE_LIST_OF_USER_UNDER_COMPANY_FAILED:
            return { deleteUserListLoading: false, deleteUserError: action.payload }
        default:
            return state;
    }
}