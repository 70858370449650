import { GET_BUILD_BY_PROJECT_ID_EMPTY, GET_BUILD_BY_PROJECT_ID_FAILED, GET_BUILD_BY_PROJECT_ID_LOADING, GET_BUILD_BY_PROJECT_ID_SUCCESS,GENERATE_BUILD_BY_PROJECT_ID_EMPTY, GENERATE_BUILD_BY_PROJECT_ID_FAILED, GENERATE_BUILD_BY_PROJECT_ID_LOADING, GENERATE_BUILD_BY_PROJECT_ID_SUCCESS, RESET_BUILD_BY_PROJECT_ID } from "../Constants/buildConstants";

export const buildByProjectIdReducer = (state = { emptyBuild: '', buildByProjectData: [] }, action) => {

    switch (action.type) {
        case GET_BUILD_BY_PROJECT_ID_LOADING:
            return { loading: true }
        case GET_BUILD_BY_PROJECT_ID_SUCCESS:
            return { loading: false, buildByProjectData: action.payload }
        case GET_BUILD_BY_PROJECT_ID_FAILED:
            return { loading: false, error: action.payload }
        case GET_BUILD_BY_PROJECT_ID_EMPTY:
            return { loading: false, emptyBuild: true }
        case RESET_BUILD_BY_PROJECT_ID:
            return { emptyBuild: '', buildByProjectData: [] }
        default:
            return state;
    }
}

export const generateBuildByProjectIdReducer = (state = { emptyBuild: '', generateBuildByProjectData: [] }, action) => {

    switch (action.type) {
        case GENERATE_BUILD_BY_PROJECT_ID_LOADING:
            return { genLoading: true, genSuccess: false }
        case GENERATE_BUILD_BY_PROJECT_ID_SUCCESS:
            return { genLoading: false, generateBuildByProjectData: action.payload, genSuccess: true }
        case GENERATE_BUILD_BY_PROJECT_ID_FAILED:
            return { genLoading: false, genError: action.payload }
        case GENERATE_BUILD_BY_PROJECT_ID_EMPTY:
            return { genLoading: false, genEmptyBuild: true }
        case RESET_BUILD_BY_PROJECT_ID:
            return { genEmptyBuild: '', generateBuildByProjectData: [] }
        default:
            return state;
    }
}
