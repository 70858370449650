import React, { useEffect, useState } from 'react'
// import darkBubble from './component_assets/images/darkBubble.png'
// import darkBubble from './component_assets/images/details_close.png'
// import lightBubble from './component_assets/images/details_close.png'
// import lightBubble from './component_assets/images/lightBubble.png'
import viewProjectIcon from './component_assets/images/viewProjectIcon.png'
import createProjectIcon from './component_assets/images/createProjectIcon.png'
import desktopImage from './component_assets/images/desktopImage.png'
import mobileImage from './component_assets/images/mobileImage.png'
import redSquare from './component_assets/images/redSquare.png'
import cyanSquare from './component_assets/images/cyanSquare.png'
import { logout } from '../Redux/Actions/userActions';
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { getAllProject } from '../Redux/Actions/projectActions'
import { Button, Paper, Typography } from '@mui/material'
import { Box } from '@mui/system'
import QueueIcon from '@mui/icons-material/Queue';

// import './style.css'
import classes from './ScreensStylings/IndexScreen.module.css';


function IndexScreen() {

    let navigate = useNavigate();
    let dispatch = useDispatch();


    const { userInfo } = useSelector(state => state.userLogin);
    const { allProjectData } = useSelector(state => state.allProjectData);

    const mobHandler = () => {
        localStorage.setItem('is_mobile', JSON.stringify(true))
        navigate('/dashboard')

    }

    const webHandler = () => {
        localStorage.setItem('is_mobile', JSON.stringify(false))
        navigate('/dashboard')
    }

    useEffect(() => {
        if (!allProjectData) {
            dispatch(getAllProject())
        }
    }, [])


    const createProjectHandler = (app) => {
        navigate(`/createProject/${app}`)
    }

    const viewProjectHandler = (app) => {
        navigate(`/projectList/${app}`)
    }

    const logOutHandler = () => {
        dispatch(logout())
    }

    useEffect(() => {
        if (!userInfo?.token) {
            navigate('/login')
        }
    }, [userInfo])

    // return (
    //     <div style={{ marginTop: '2vh', height: '86vh', overflow: 'hidden' }}>
    //         <div className="hero">
    //             <img className="addProject-d-bb" style={{ top: "-20%", scale: '80%' }} src={darkBubble} />
    //             <img className="addProject-l-bb" style={{ top: "10%", scale: '80%' }} src={lightBubble} />
    //             <div className="addNewProjectWrapper">
    //                 <p className="addProjectUserText">Welcome, {userInfo?.name}!</p>
    //                 <div className="addProjectInnerDiv">
    //                     <div className="">


    //                         <Box sx={{ display: "flex", justifyContent: "center", alignItems: 'center', mt: 15, mb: 10, }}>
    //                             <Paper elevation={4} sx={{ padding: '0.5vh 2.5vw', border: "1px solid red" }}>
    //                                 <Typography sx={{ textAlign: 'center', fontSize: '4rem', fontFamily: "Mont-SemiBold", color: 'red' }}>Let's Get Started</Typography>
    //                             </Paper>
    //                         </Box>

    //                         <Box sx={{ display: 'flex', justifyContent: "center", alignItems: 'center' }}>
    //                             <Button onClick={mobHandler} variant='contained' sx={{ mr: 4, display: 'flex', justifyContent: 'center', alignItems: 'center', textTransform: 'none', backdropFilter: 'blur(25)', backgroundColor: 'red', color: 'white', '&:hover': { backgroundColor: 'white', color: 'red' }, transitionDuration: '0.6s', padding: '1.5vh 2vw' }}>
    //                                 <Typography sx={{ fontFamily: 'Mont-Regular', fontSize: '1.4rem', mr: 1 }}>Create Mobile App</Typography>
    //                                 <QueueIcon fontSize="large" />
    //                             </Button>
    //                             <Button onClick={webHandler} variant='contained' sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', textTransform: 'none', backdropFilter: 'blur(25)', backgroundColor: 'red', color: 'white', '&:hover': { backgroundColor: 'white', color: 'red' }, transitionDuration: '0.6s', padding: '1.5vh 2vw' }}>
    //                                 <Typography sx={{ fontFamily: 'Mont-Regular', fontSize: '1.4rem', mr: 1 }}>Create Web App</Typography>
    //                                 <QueueIcon fontSize="large" />
    //                             </Button>
    //                         </Box>

    //                     </div>
    //                 </div>
    //             </div>
    //         </div>
    //     </div>
    // );




    return (
        <div className={classes['wrapper']}>
            <img className={classes['top-redSquare']} src={redSquare} />
            <img className={classes['top-cyanSquare']} src={cyanSquare} />

            <img className={classes['bottom-redSquare']} src={redSquare} />
            <img className={classes['bottom-cyanSquare']} src={cyanSquare} />

            <div className={classes['header']}>
                <h2>Welcome, {userInfo?.name}!</h2>
                <button className={classes['red-gradient-button']} onClick={logOutHandler}>Logout</button>
            </div>

            <br />

            <h2>let's get started</h2>

            <section className={classes['create-section']}>
                <div className={classes['create-app']}>
                    <h3>create web app</h3>

                    <div className={classes['create-card']}>
                        <img src={viewProjectIcon} />
                        <button className={classes['red-gradient-button']} onClick={()=>viewProjectHandler('web')}>view all projects</button>
                    </div>

                    <div className={classes['create-card']}>
                        <img src={createProjectIcon} />
                        <button className={classes['red-gradient-button']} onClick={()=>createProjectHandler('web')}>create a new project</button>
                    </div>

                    <img className={classes['desktopImage']} src={desktopImage} />
                </div>

                <div className={classes['create-app']}>
                    <h3>create mobile app</h3>

                    <img className={classes['mobileImage']} src={mobileImage} />

                    <div className={classes['create-card']}>
                        <img src={viewProjectIcon} />
                        <button className={classes['red-gradient-button']} onClick={()=>viewProjectHandler('mobile')}>view all projects</button>
                    </div>

                    <div className={classes['create-card']}>
                        <img src={createProjectIcon} />
                        <button className={classes['red-gradient-button']} onClick={()=>createProjectHandler('mobile')}>create a new project</button>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default IndexScreen