import { toast } from "react-toastify";
import { 
    CREATE_MATH_FUNC,
    CREATE_NEW_FUNC_NAME,
    SAVE_MATH_PARAMS,
    ASSIGN_VARIABLES, 
    SAVE_NEW_FUNC
} from "../Constants/createFunctionsConstants";
import { CLEAR_NEW_FUNC } from "../Constants/functionsConstants";


export const createFunctionsReducer = (state = { isMathFunction: false, droppedItems: [], execute: [] }, action) => {
    console.log(action.type)
    switch(action.type) {
        case CREATE_NEW_FUNC_NAME: 
            // return {...state, droppedItems: [...state.droppedItems, {"name": action.payload} ]};
            return { ...state, funcName: action.payload }
        case ASSIGN_VARIABLES:
            console.log(action.payload)
            const funcName = action.payload.funcName;
            let newDroppedItems = state.droppedItems.map(funcItem => {
                // execute will contain any existing functions if any
                // execute: [{ assignVariableObj : {mathFuncName, params}]
                
                // execute: [{ typeOfOperation: ..., params: ... }]
                
                // if Math operation: 
                // execute: [...execute, { typeOfOperation: ...., params: ..., returnValue: ....}]
                
                // if IF operation (mulitlevel false): 
                // execute: [...execute, { typeOfOperation: ...., condition: ..., multilevel: false, trueOperation: .... , falseOperation: ....  }]

                // if IF operation (multilevel true):
                // execute: [... execute, { typeOfOperation: ...., condition: ..., 
                // multilevel: true,
                // numOfIF: Number,
                // operation: { condition: ..., true: {..., condition: ... , true: { ...}, {false: ...}, false: {....}}, false: {...} } 
                // trueOperation: .... , falseOperation: ....   } ]

                // if SWITCH operation :
                // execute: [...execute, { typeOfOperation: {...},  cases: [..., ...], operation: { ..} }]

                // if(funcItem.name === funcName) {
                //     if(funcItem.execute) {
                //         Object.assign(funcItem, {...funcItem, execute: [...funcItem.execute, action.payload.execute]})
                //     }
                //     else {
                //         // Object.assign(item, {...item,  "execute": [ action.payload.execute ]})
                //         toast({"message": "All mathematical functions should be assigned to a variable" })
                //     }
                // }
            }) 
            return { 
                ...state, parameters: action.payload }
        // case CREATE_MATH_FUNC: 
        //     const funcName_assign = action.payload.name;
        //     let newDroppedItems_assign = state.droppedItems.map(item => {
        //         // if(item.name === funcName_assign) {
        //         //     Object.assign
        //         // }
                
        //     })
        case SAVE_NEW_FUNC:
            console.log(action.payload)
            break;
           
        case CREATE_MATH_FUNC:
            console.log(action.payload)
            console.log(state.execute)
            let currState = structuredClone(state.execute);
            console.log(state.execute)
            console.log(currState.length)
            console.log("after curr state")
            let newState;
            if(currState.length > 0) {
                console.log("LENGHT > 0")
                newState = [...currState, action.payload]
            } else {
                newState = [action.payload]
            }
            
            // break;
            console.log("After new state")
            console.log(newState)
            return {
                ...state, execute: [...newState]
            }
            // let data = {}
        case CLEAR_NEW_FUNC:
            console.log("FROM REDUCER CLEAR ALL")
            return {
                ...state, funcName: "", isMathFunction: false, droppedItems: [], execute: [] 
            }
        default: 
            return state;
    }
}