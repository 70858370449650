
import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom'
import { Button, Divider, Grid, Modal, Typography } from '@mui/material'
import { Box } from '@mui/system'
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
// import redSlingLogo from './component_assets/images/details_close.png'
import redSlingLogo from './component_assets/images/redSlingLogo.png'
import MenuIcon from '@mui/icons-material/Menu';
import DashboardIcon from '@mui/icons-material/Dashboard';
import LayersIcon from '@mui/icons-material/Layers';
import PersonIcon from '@mui/icons-material/Person';
import AddchartIcon from '@mui/icons-material/Addchart';
import PushPinIcon from '@mui/icons-material/PushPin';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import { logout } from '../Redux/Actions/userActions';
import { useDispatch, useSelector } from 'react-redux'
import { ToastContainer } from "react-toastify";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";

function PrivateRoute(props) {
    let dispatch = useDispatch()
    let navigate = useNavigate()
    const { Component } = props
    const [open, setOpen] = useState(false)
    const [hoverStart, setHoverStart] = useState(false)
    const [pinSidebar, setPinSideBar] = useState(false)
    const [colorOnClick, setColorOnClick] = useState({
        analytics: false,
        dashboard: true,
        projects: false,
        pages: false,
        profile: false
    })
    const [isLogicBuild, setIsLogicBuild] = useState(false);

    const { userInfo } = useSelector(state => state.userLogin)


    useEffect(() => {
        if (!userInfo?.token) {
            navigate('/login')
        }
    }, [userInfo])


    const handleOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    const hoverSidebarHandler = () => {
        if (!pinSidebar) {
            setHoverStart(true)
        }
    }

    const hoverExistHandler = () => {
        if (!pinSidebar) {
            setHoverStart(false)
        }
    }

    const stopHoverEventHandler = () => {
        setPinSideBar(!pinSidebar)
    }

    const goToAnalyticsHandler = () => {
        setColorOnClick({
            analytics: true,
            dashboard: false,
            projects: false,
            pages: false,
            profile: false
        })
    }

    const goToProjectsHandler = () => {
        setColorOnClick({
            analytics: false,
            dashboard: false,
            projects: true,
            pages: false,
            profile: false
        })
        navigate(`/`)
    }

    const goToDashboardHandler = () => {
        setColorOnClick({
            analytics: false,
            dashboard: true,
            projects: false,
            pages: false,
            profile: false
        })
        navigate('/')
    }

    const goToPagesHandler = () => {
        setColorOnClick({
            analytics: false,
            dashboard: false,
            projects: false,
            pages: true,
            profile: false
        })
        navigate('/pageList')
    }

    const goToProfileHandler = () => {
        setColorOnClick({
            analytics: false,
            dashboard: false,
            projects: false,
            pages: false,
            profile: true
        })
        navigate('/userProfile')
    }

    const logOutHandler = () => {
        dispatch(logout())
    }

    useEffect(() => {
        if (window.location.pathname === '/' || window.location.pathname === '/createProject' || window.location.pathname.includes('addNewPage')) {
            setColorOnClick({
                analytics: false,
                dashboard: true,
                projects: false,
                pages: false,
                profile: false
            })
        }

        if (window.location.pathname === '/pageList') {
            setColorOnClick({
                analytics: false,
                dashboard: false,
                projects: false,
                pages: true,
                profile: false
            })
        }

        if (window.location.pathname === '/projectList') {
            setColorOnClick({
                analytics: false,
                dashboard: false,
                projects: true,
                pages: false,
                profile: false
            })
        }

        if (window.location.pathname === '/userProfile') {
            setColorOnClick({
                analytics: false,
                dashboard: false,
                projects: false,
                pages: false,
                profile: true
            })
        }

        if (window.location.pathname === '/analytics') {
            setColorOnClick({
                analytics: true,
                dashboard: false,
                projects: false,
                pages: false,
                profile: true
            })
        }

        if (window.location.pathname === '/buildLogic') {
            setIsLogicBuild(true);
        }
    }, [window.location.pathname])

    return (

        <div >

            {isLogicBuild ? '' :
                <>
                    <Grid sx={{ display: "flex", justifyContent: "flex-start", alignItems: 'center', columnGap: '2vw', width: '100vw', boxShadow: 6, padding: { md: '1vh 2vw', xs: '1vh 4vw', position: 'fixed', top: '0', zIndex: 30, backgroundColor: 'white' } }}>
                        <KeyboardBackspaceIcon onClick={() => navigate(-1)} sx={{ mt: 2, color: "gray", cursor: "pointer", "&:hover": { color: "orange" } }} /> <Typography sx={{ cursor: 'pointer' }} onClick={() => navigate('/')}><img src={redSlingLogo} /></Typography>
                        <Box sx={{ display: { xs: 'none', md: 'flex' }, alignItems: "center", marginLeft: 'auto' }}>
                            <Button onClick={logOutHandler} variant='contained' sx={{ marginLeft: '1.5vw', color: 'white', '&:hover': { backgroundColor: 'white', color: 'red', fontFamily: 'Mont-Regular' }, backgroundColor: 'red' }}>Logout</Button>
                        </Box>
                        <Typography onClick={handleOpen} sx={{ fontSize: 'large', display: { md: 'none' } }}>
                            <MenuIcon />
                        </Typography>
                        <Modal
                            open={open}
                            onClose={handleClose}

                            sx={{ display: { md: 'none' }, border: 'none' }}
                        >
                            <Box sx={{ width: { xs: '90vw', sm: '50vw' }, margin: '15vh auto', padding: '2vh 2vw', outline: 'none', border: '0.5px solid white', borderRadius: '6px' }}>
                                <Grid sx={{ display: "flex", justifyContent: 'center' }}>
                                    <Button sx={{ color: "white" }}>Analytics</Button>
                                </Grid>
                                <Divider sx={{ border: '0.5px solid white' }} />
                                <Grid sx={{ display: "flex", justifyContent: 'center' }}>
                                    <Button sx={{ color: "white" }}>Project</Button>
                                </Grid>
                                <Divider sx={{ border: '0.5px solid white' }} />
                                <Grid sx={{ display: "flex", justifyContent: 'center' }}>
                                    <Button sx={{ color: "white" }}>Pages</Button>
                                </Grid>
                                <Divider sx={{ border: '0.5px solid white' }} />
                                <Grid sx={{ display: "flex", justifyContent: 'center' }}>
                                    <Button sx={{ color: "white" }}>Profile</Button>
                                </Grid>
                                <Divider sx={{ border: '0.5px solid white' }} />
                                <Grid sx={{ display: "flex", justifyContent: 'center' }}>
                                    <Button sx={{ color: "white" }}>Logout</Button>
                                </Grid>
                                <Divider sx={{ border: '0.5px solid white' }} />
                                <Grid sx={{ display: "flex", justifyContent: 'center' }}>
                                    <Button sx={{ color: "white" }}>Settings</Button>
                                </Grid>
                            </Box>
                        </Modal>

                    </Grid>

                    <Grid container xs={12} sx={{ display: 'flex' }}>
                        <Grid id="targetHover" onMouseOut={hoverExistHandler} onMouseOver={hoverSidebarHandler} xs={hoverStart ? 1.5 : 0.48} item sx={{ boxShadow: 21, justifyContent: 'center', overflow: 'hidden', transitionDuration: '0.5s', minHeight: '90vh', zIndex: 20, mt: 11, position: 'fixed', left: '0', top: '0', backgroundColor: 'white' }}>
                            <Box onClick={stopHoverEventHandler} id="sliderPin" sx={{ display: hoverStart ? 'flex' : 'none', justifyContent: "flex-end", margin: '2vh 0 4vh 0', paddingRight: '1vw' }}>
                                <Typography sx={{ padding: '0.5vh', borderRadius: '6px', color: pinSidebar ? 'red' : '#b6b5b5', boxShadow: 2 }}>
                                    <PushPinIcon fontSize="medium" />
                                </Typography>
                            </Box>
                            <Divider sx={{ border: '0.5px solid gray', marginTop: hoverStart ? '0' : '10.5vh' }} />
                            {/* <Box onClick={goToAnalyticsHandler} sx={{ display: 'flex', cursor: 'pointer', justifyContent: 'start', alignItems: 'center', padding: '1vh 2.5vw 1vh 1vw', color: colorOnClick.analytics ? 'red' : '#b6b5b5', '&:hover': { color: 'red', backgroundColor: '#e5e7eb' } }}>
                                <QueryStatsIcon fontSize='large' />
                                <Typography id="analyTics" sx={{ fontFamily: "Mont-Regular", fontSize: '1.2rem', marginLeft: '10px' }}>Analytics</Typography>
                            </Box> */}
                            {/* <Divider sx={{ border: '0.5px solid gray' }} /> */}
                            {/* <Box onClick={goToProjectsHandler} sx={{ display: 'flex', cursor: 'pointer', justifyContent: 'start', alignItems: 'center', padding: '1vh 0 1vh 1vw', color: colorOnClick.projects ? 'red' : '#b6b5b5', '&:hover': { color: 'red', backgroundColor: '#e5e7eb' } }}>
                                <AddchartIcon fontSize='large' />
                                <Typography id="Projects" sx={{ fontFamily: "Mont-Regular", fontSize: '1.2rem', marginLeft: '10px' }}>Projects</Typography>
                            </Box> */}
                            <Divider sx={{ border: '0.5px solid gray' }} />
                            <Box onClick={goToDashboardHandler} sx={{ display: 'flex', cursor: 'pointer', justifyContent: 'start', alignItems: 'center', padding: '1vh 0 1vh 1vw', color: colorOnClick.dashboard ? 'red' : '#b6b5b5', '&:hover': { color: 'red', backgroundColor: '#e5e7eb' } }}>
                                <DashboardIcon fontSize='large' />
                                <Typography id="Projects" sx={{ fontFamily: "Mont-Regular", fontSize: '1.2rem', marginLeft: '10px' }}>Dashboard</Typography>
                            </Box>
                            <Divider sx={{ border: '0.5px solid gray' }} />
                            <Box onClick={goToPagesHandler} sx={{ display: 'flex', cursor: 'pointer', justifyContent: 'start', alignItems: 'center', padding: '1vh 0 1vh 1vw', color: colorOnClick.pages ? 'red' : '#b6b5b5', '&:hover': { color: 'red', backgroundColor: '#e5e7eb' } }}>
                                <LayersIcon fontSize='large' />
                                <Typography id="Pages" sx={{ fontFamily: "Mont-Regular", fontSize: '1.2rem', marginLeft: '10px' }}>Pages</Typography>
                            </Box>
                            <Divider sx={{ border: '0.5px solid gray' }} />
                            <Box onClick={goToProfileHandler} sx={{ display: 'flex', cursor: 'pointer', justifyContent: 'start', alignItems: 'center', padding: '1vh 0 1vh 1vw', color: colorOnClick.profile ? 'red' : '#b6b5b5', '&:hover': { color: 'red', backgroundColor: '#e5e7eb' } }}>
                                <PersonIcon fontSize='large' />
                                <Typography id="Profile" sx={{ fontFamily: "Mont-Regular", fontSize: '1.2rem', marginLeft: '10px' }}>Profile</Typography>
                            </Box>
                            <Divider sx={{ border: '0.5px solid gray' }} />

                        </Grid>

                        {/* Component */}
                        {/* scrollable screen just increase the height  */}
                        <Grid xs={hoverStart ? 10.8 : 11.52} sx={{ transitionDuration: '0.5s', position: 'relative', overflowX: 'hidden', mt: 11, transform: hoverStart ? 'translateX(12.4vw)' : 'translateX(4vw)', transitionDuration: '0.5s' }} item>
                            <Component hover={hoverStart}></Component>
                        </Grid>
                    </Grid>
                </>}

            <ToastContainer autoClose={2000} />
        </div>

    )
}

export default PrivateRoute