import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux'
import axios from 'axios';
import { DragBlocks } from '../Testing/Test3/blocks/DragBlocks';
import { API_HOST } from '../api_utils';
import { useSelector } from 'react-redux';
import { Button, Grid, Typography, Modal, TextField } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';

import classes from './LogicBuilder.module.css';
import { toast } from 'react-toastify';

const errorTheme = {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    zIndex: 10,
    theme: "colored",
}

const VariablesPanel = (props) => {
	// (instead of statements array); here is where statement grapesjs-blocks will come with blockManager.add({});
	const statements = ['if', 'else if', 'else', 'for', 'while', 'switch'];
    const [tempVars, loadTempVars] = useState([])
	const { userInfo } = useSelector(state => state.userLogin);

    const [open, setModalOpen] = useState(false);
    const [name, setName] = useState("")
    const [defaultValue, setDefaultValue] = useState("")
    const [typeOfVar, setTypeOfVar] = useState("")
    const [currentValue, setCurrentValue] = useState("")
    const [errorMessage, setErrorMessage] = useState("")

    useEffect(() => {
        if(props.projectData.length > 0) {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${userInfo.token}`
                }
            }
            const data = {
                "user": props.user,
                "project": localStorage.getItem("projectId")
            }
           

            axios.post(`${API_HOST}temp_vars/`, data, config).then(res => {
                console.log(res.data)
                loadTempVars([...res.data])
            })
        }
    }, [tempVars.length])

    const addNewTempVarModal = (e) => {
        e.preventDefault();
        console.log("Addnew temp var modal")
        setModalOpen(true)
    }

    const handleClose = () => {
        console.log("Hand leclase")      
        setModalOpen(false)
        setName("")
        setTypeOfVar("")
        setDefaultValue("")
        setCurrentValue("")

    }

    const addNewTempVar = (e) => {
        e.preventDefault();
        console.log("Addnew tremp var")
        if(name.trim().length > 0 && typeOfVar.trim().length === 0) {
            console.log("Inerrr")
            setErrorMessage("Please enter the datatype")
            toast.error("Please enter the datatype", errorTheme)
        } else if (typeOfVar.trim().length > 0 && name.trim().length === 0) {
            console.log("Pleawe enter name")
            setErrorMessage("Please enter name")
            toast.error("Please enter name", errorTheme)
        } else {
            setErrorMessage("")
            const createObj = {
                name: name,
                typeOfVar: typeOfVar,
                currentValue: currentValue,
                defaultValue: defaultValue,
                project: localStorage.getItem("projectId"),
                user: userInfo.token
            }
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${userInfo.token}`
                }
            }

            console.log("ads ", createObj)
            axios.post(`${API_HOST}temp_vars/create`, createObj, config).then(res => console.log("Done"))
        }
    }

	return (
		<div className={classes.statement_panel}>

			<h3 style={{color: '#B6B5B5', marginTop: '5%'}}>Variables</h3>

			<div className={classes.statement_holder}>
				{/* showing the blocks with forloop just for display, untill real blocks are added here */}
				{
                    tempVars.length > 0 && tempVars.map((item, index) => {
					// <div key={index} className={classes.statement_block}>{block}</div>
                    Object.assign(item, { ...item, "type": "parameters" })
					return (<div key={index} className={classes.statement_block}>
						<DragBlocks dragItem={item} key={item.id} />
					</div>)
                    })
                }
                    <div className={classes.statement_block}>
                        <DragBlocks dragItem={{name: "Text box", "type": "parameters"}}  />
                    </div>

                    
                    <div className={classes.statement_block} 
                        style={{ cursor: "pointer" }} onClick={addNewTempVarModal}>
                        <div> + </div>
                    </div>
			</div>

			{/* receiving the statementPanelCloseHandler() through props and executing it here when onClick event is triggered */}
			<button className={classes.collapse_btn} onClick={props.onClose}>
				<span className={classes.collapse_btn_icon}>
					<svg xmlns="http://www.w3.org/2000/svg" fill="red" className="bi bi-x" viewBox="0 0 16 16" style={{width: '80%', height: '80%'}}>
					  	<path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
					</svg>
				</span>
			</button>

            <Modal
                open={open}
                onClose={handleClose}
                
                sx={{ border: 'none', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >
               
                <Box sx={{ width: { lg: '80vw' }, padding: '2vh 2vw 6vh 2vw', outline: 'none', borderRadius: '6px', backgroundColor: 'white', position: 'relative' }}>
                    <Grid sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button onClick={handleClose} sx={{ boxShadow: 2, color: 'red', '&:hover': { boxShadow: 5 } }}>
                            <CloseIcon />
                        </Button>
                    </Grid>
                    <Grid sx={{ display: "flex", justifyContent: 'space-between', alignItems: 'center', mt: 2 }} >
                        <Typography sx={{ fontFamily: "Mont-SemiBold", fontSize: '1.3rem' }}>Add New Temporary Variables</Typography>
                        <Grid item xs={10.2} sx={{ backgroundColor: 'red', height: "1vh" }}></Grid>
                    </Grid>
                    <Grid sx={errorMessage.trim().length > 0 ? { display: "flex"} : {display: "none"}}>
                        <Typography sx={{ fontFamily: "Mont-SemiBold", fontSize: '1rem', color: "red" }}>
                            {errorMessage}
                        </Typography>
                    </Grid>
                    <Grid sx={{ mt: 4, display: 'flex', justifyContent: 'space-between' }} >
                        <TextField onChange={(e) => setName(e.target.value)} id="outlined-basic" 
                            label="Variable Name *" variant="outlined" sx={{ width: "36vw" }} 
                            value={name} />
                        <TextField onChange={(e) => setTypeOfVar(e.target.value)} 
                            value={typeOfVar} id="outlined-basic" 
                            label="DataType of Variable *" variant="outlined" sx={{ width: "36vw" }} />
                    </Grid>
                    <Grid sx={{ mt: 4, display: 'flex', justifyContent: 'space-between' }}>
                        <TextField onChange={(e) => setDefaultValue(e.target.value)} 
                            value={defaultValue} id="outlined-basic" 
                            label="Default Value" variant="outlined" sx={{ width: "36vw" }} />
                        <TextField onChange={(e) => setCurrentValue(e.target.value)} 
                            value={currentValue} id="outlined-basic" 
                            label="Value" variant="outlined" sx={{ width: "36vw" }} />
                    </Grid>
                    <Button onClick={addNewTempVar} 
                        variant='contained' sx={{ backgroundColor: 'red', '&:hover': { color: 'red', backgroundColor: 'white' } }}
                        style={{ marginTop: "20px" }}
                        >
                            
                        Save Changes</Button>


                </Box>
            </Modal>

		</div>
	);
};

const mapStateToProps = (state) => {
    return {
        projectData: state.allProjectData.allProjectData,
        user: state.userLogin.userInfo
    }
}


export default connect(mapStateToProps, null)(VariablesPanel);