import React, { createRef, useCallback, useEffect, useState } from 'react';
import { useDrop } from 'react-dnd'
import ParameterDroppable from './ParameterDroppable';
import { connect } from 'react-redux';
import { useDispatch } from 'react-redux';
import { assignParameters, clearAll, createMathFunction } from '../../../Redux/Actions/createFunctionAction';
import { setPerformingOperations2, getPerformingOperations, getAssignVariable, setAssignVariable, getItemsInFunction, setItemsInFunction } from '../Math/math';
import ParameterDropped from './ParameterDropped';

/**
 * ASSIGNING  PARAMETERS IN REDUX
 */

const MakeDroppable = (props) => {
    let dispatch = useDispatch();
    const [droppedItems, setDrop] = useState();
    const [refId, assignRef] = useState();
    const [performingOperations, setPerformingOperations ] = useState(false)
    const [params, setParams] = useState([]);
    const [paramDropValCheck, setparamDropValCheck] = useState(false)
    const [initObj, setInitObj] = useState()

    const [{ isOver, canDrop }, drop] = useDrop(() => ({
        
        accept: ["conditional", "parameters"],
        // drop: (item) => addToCanvas(item),
        drop: (item) => addToCanvas2(item),
        canDrop: (item) => checkBeforeAddingToCanvas2(item),
        // canDrop: true,
        collect: (monitor) => ({
            isOver: !!monitor.isOver(),
            canDrop: !!monitor.canDrop()
        })
    }))

    const checkBeforeAddingToCanvas =  (val=false) => {
        console.log("==CHECK BEFORE ADDING TO CANVAS")
        console.log(val)
        if(val===true) {
            setparamDropValCheck(true);
            return false;
        } else {
            setparamDropValCheck(false)
            return true
        }
    }

    const checkBeforeAddingToCanvas2 =  async (item) => {
        // console.log("==CECK VDEF 222222222")
        // console.log(item)
        // console.log(performingOperations)
        // if(performingOperations) {
        //     return false;
        // }
        // else {
        //     return true;
        // }
        // if(droppedItems === null || droppedItems === undefined ) {
        //     console.log("UDFSFDF")
        //     console.log(droppedItems)
        //     setPerformingOperations(true);
        //     return true;
        // } else 
        const itemsInFunction = getItemsInFunction();
        const newPerformingOperations = getPerformingOperations();
        const assignVariableObj2 =  getAssignVariable();
        // console.log("ASSINGG  ___", assignVariableObj2)
        
        if(itemsInFunction.length === 0) {
            // setAssignVariable(false);
            console.log("HERERERR")
            setPerformingOperations2(true)
            setStatePerformingOperations(true)
            return true;
        }else if(item.isMathFunction === true) {
            console.log("ERERER")
            // setPerformingOperations(true);
            setPerformingOperations2(true)
            return true;
            // return false;
        }
        
        if(newPerformingOperations === false) {
            return false;
        }
        // else if(newPerformingOperations === true) {
        //     return false;
        // }
        
        // else {
        //     console.log("sdfsdfsdf")
        //     // setPerformingOperations(false)
        //     return false;
        // }

    }

    const addToCanvas2 = (item) => {
        console.log(item)
        // return true;
        return item;
    }

    const addToCanvas = (item) => {
        console.log(item);
        const performingOperations2 = getPerformingOperations();
        // const returnedVal = checkBeforeAddingToCanvas()
        if((performingOperations2 ===true && item.isMathFunction) || (performingOperations2 ===true && !item.isMathFunction)) {
            console.log("Value returning true")
            console.log(performingOperations2)
            
            setDrop(prevState => {
                
                if(prevState !== undefined && prevState !== null) {
                    if(prevState.isMathFunction !== true || item.isMathFunction ) {
                        if(Object.keys(prevState, 'droppedItems')) {
                            const currItems = prevState.droppedItems
                            if(item.isMathFunction) {
                                let newRef;
                                console.log("CREATE MATH 1")
                                let project_id = props.selected_project.selected_project._id
                            
                                dispatch(createMathFunction(project_id, item)).then(res => {
                                    console.log(res)
                                    // assignRef(res)
                                    Object.assign(item, {refId: res})
                                    setPerformingOperations2(false)
                                    setItemsInFunction([item]);
                                        
                                })
                                return Object.assign({
                                    ...prevState,
                                    isMathFunction: true,
                                    droppedItems: [...currItems, item]
                                })
                                // return newRef;
                                
                                
                            } else {
                                console.log("NOT CREATE MATH 1")
                                return Object.assign({
                                    ...prevState,
                                    isMathFunction: false,
                                    droppedItems: [...currItems, item]
                                })
                            }
                            
                        }
                        else {
                            if(item.isMathFunction) {
                                console.log("CREATE MATH 2")
                                let project_id = props.selected_project.selected_project._id
                                setPerformingOperations(true)
                                setPerformingOperations2(false)
                                setItemsInFunction([item]);
                                dispatch(createMathFunction(project_id, item)).then(res => {
                                    console.log(res)
                                    // assignRef(res)
                                    Object.assign(item, {refId: res})
                                    Object.assign({...prevState, isMathFunction: true, droppedItems: [item]})    
                                    return prevState;
                                })
                                // return Object.assign({
                                //     ...prevState,
                                //     isMathFunction: false,
                                //     droppedItems: [ item]
                                // })
                                
                            }
                            // Object.assign({...prevState, isMathFunction: false, droppedItems: [item]})
                            // return prevState;
                        }
                        
                    } else {
                        return prevState;
                    }
                    // console.log(Object.keys(prevState, 'droppedItems'))
                        
                }
                else {
                    if(item.isMathFunction) {
                        console.log("CREATE MATH 3")
                        let project_id = props.selected_project.selected_project._id
                        setPerformingOperations(true)
                        setPerformingOperations2(false)
                        setItemsInFunction([item]);
                        dispatch(createMathFunction(project_id, item)).then(res =>{ 
                            console.log(res)
                            // assignRef(res)
                            Object.assign(item, {refId: res})
                            return { isMathFunction: true, droppedItems: [item] }  
                        })
                    
                        // return Object.assign({
                        //     ...prevState,
                        //     isMathFunction: false,
                        //     droppedItems: [item]
                        // })
                    }
                    console.log("------iuyoiuoiuio----")
                    setAssignVariable(false)
                    return { isMathFunction: false, droppedItems: [item] }
                }
                
            })
        }else if(item.isMathFunction) {
            console.log("PERFOMRING TO TRUE")
            setPerformingOperations(true);
            return;
        }
        
    }
    
    // console.log(props.height)
    console.log(droppedItems)
    // console.log(performingOperations)
    // console.log(getPerformingOperations())
    const receiveParameters = (param, paramRefId) => {
        // console.log(param1, param2)
        // Before this check createnewfunction from reducer or savenewfunction from reducer
        let valChanged = false;
        // console.log(paramRefId)
        // console.log(refId)
        // if(paramRefId === refId) {
            if(params.length > 0) {
                let updatedDroppedItems = params.map((item, index) => 
                {
                    console.log(paramRefId)
                    console.log(refId)
                    if(item.refId === paramRefId) {
                        if (item._id === param._id){
                            valChanged = true;
                            return param; 
                        }
                    }
                    
                    // return item; // else return unmodified item 
                });
                if(valChanged) {
                    setParams(updatedDroppedItems);
                    let project_id = props.selected_project.selected_project._id
                    // dispatch(assignParameters(project_id, updatedDroppedItems))
                    // props.defineFinalFunction(updatedDroppedItems, droppedItems[0])
                } else {
                    setParams(prevState => [...prevState, param])
                    let project_id = props.selected_project.selected_project._id
                    // props.defineFinalFunction([...params, param], droppedItems[0])
                    
                    // dispatch(assignParameters(project_id, [...params, param]))
    
                }
                
            } else {
                console.log("==PARAMAKLFLKNALNL====")
                setParams(prevState => [...prevState, param])
                let project_id = props.selected_project.selected_project._id
                // props.defineFinalFunction(param, droppedItems[0])
                // dispatch(assignParameters(project_id, param))
    
            }
        // } 
        
       
    }

    const mathFunctionSave = (paramRefId) => {
        console.log("Math function save")
        console.log(droppedItems)
        console.log(params)
        let mathFunction;
        let returnVal;
        (droppedItems.droppedItems.filter(item => {
            if(item.isMathFunction === true && item.refId === paramRefId) {
                mathFunction = item.name
            } else if(item.initialize === true) {
                returnVal = item
            }
        } ))
        let mathFunc = {
            name: "",
            mathFunction,
            returnVal,
            parameters: params.filter(item => item.refId === paramRefId)
        }
        console.log(mathFunc)
        // props.defineFinalFunction(mathFunc)
    }

    
    const receiveParameters2 = (obj, paramRefId) => {
        // console.log
        // if(obj.id)
        // CHECK VALUES WITH DROPPED ITEMS? AND THEN CHANGE THOSE VALUES HERE?
        console.log(obj)
        console.log(paramRefId)
        // console.log(droppedItems?.droppedItems)
        // console.log(params)
        if(params.length === 0) {
            console.log("paams lenght ---0")
            // console.log(paramRefId)
            // console.log(obj)
            setParams([obj])
            console.log(droppedItems)
            let newObj;
            let newDroppedItems = droppedItems.droppedItems.filter(item => {
               if(item.refId === paramRefId ) {
                    console.log("___ITEM__", item)
                    
                    if(Object.keys["params"] === undefined ) {
                        
                        newObj = Object.assign({ ...item, params: [obj] })
                    } else {
                        let currParams = item.params
                        if(currParams.find(item._id === obj._id && item.refId === paramRefId)) {
                            item.currentValue = obj.currentValue
                            newObj = {...item};
                        } else {
                            newObj = Object.assign({ ...item, params: [...currParams, obj] })    
                        }
                        
                    }
                    
                    // console.log(newObj)
                    // return newObj;
               }
            })
            console.log(newObj)
            setDrop((prevState) => {
                let currDropItems = droppedItems.droppedItems.map(x => {
                    if(x.refId === newObj.refId) {
                        return newObj;
                    } 
                    return x;
                })
            
                return {
                    ...prevState,
                    droppedItems: [...currDropItems]
                }
            })

        } else {
            // let updatedDroppedItems = params.map(item => {
            //     if(item._id === obj._id && item.refId === paramRefId) {
            //         return obj;
            //     }
            //     return item;
            // })

            // setParams(updatedDroppedItems)
            // if (params.filter(item => item._id === obj._id && item.refId === paramRefId).length > 0) {
            //     /* vendors contains the element we're looking for */
            //         console.log()
            // }
            // console.log(params.find(item => (item._id === obj._id && item.refId === paramRefId)));
            if (params.find(item => (item._id === obj._id && item.refId === paramRefId))) {
                console.log("Found")
                let newArr = params.find(item => item._id === obj._id && item.refId === paramRefId);
                newArr.currentValue = obj.currentValue;
                // setParams(params => {
                let updatedParams = params.map(item => {
                        if(item._id === obj._id && item.refId === paramRefId) {
                            return newArr;
                        }
                        return item;
                    } )
                    // return ([...params, newArr])
                // })
                setParams(updatedParams)
                console.log("____DROPPED ITEMS ____", droppedItems.droppedItems)
                let newDroppedItems = droppedItems.droppedItems.map(item2 => {
                    if(item2.refId === paramRefId ) {
                         console.log("___ITEM__", item2)
                         let newObj;
                         let currKeys = Object.keys(item2)
                         console.log(currKeys)
                         console.log(typeof(currKeys))
                        //  if(currKeys?.find(keyItem => (keyItem === 'params')) === false) {
                        if(!item2.hasOwnProperty('params')) {
                        //  if(Object.keys["params"] === undefined ) {
                             
                             newObj = Object.assign({ ...item2, params: [obj] })
                             console.log("NEWOBJ1----- ", newObj);
                             return newObj;
                         } else {
                             let currParams = item2.params
                             console.log(item2._id)
                             console.log(obj._id)
                             console.log(item2.refId)
                             console.log(paramRefId)
                             let newParams = currParams.map(item3 => {
                                if((item3._id === obj._id) && (item3.refId === paramRefId)) {
                                    item3.currentValue = obj.currentValue;
                                    // newObj = {...item3}
                                    // console.log("NEWOBJ2----- ", newObj)
                                    // return newObj   
                                    return item3;
                                }
                                else {
                                    // newObj = Object.assign({ ...item3, params: [...currParams, obj] })  
                                    console.log("NEWOB3 -----" )  
                                    // return newObj;
                                    return item3;
                                }
                             })

                             item2 = Object.assign({...item2, params: newParams })

                            //  if((item2._id === obj._id) && (item2.refId === paramRefId))  {
                                
                            //      item2.currentValue = obj.currentValue
                            //      newObj = {...item2};
                            //      console.log("NEWOBJ2----- ", newObj)
                            //      return newObj
                            //  } else {
                            //      newObj = Object.assign({ ...item2, params: [...currParams, obj] })  
                            //      console.log("NEWOB3 -----", newObj)  
                            //      return newObj;
                            //  }
                             
                         }
                         
                        //  console.log(newObj)
                        return item2;
                    } else {
                        return item2;
                    }
                    
                 })
                 console.log("STTTING DROPPPP-----", newDroppedItems)
                 setDrop(prevState => {
                    return {
                        ...prevState,
                        droppedItems: newDroppedItems
                    }
                 })
                
                 
            } else {
                // SET PARAMS FOR THE OBJECT OF MATH
                setParams([...params, obj])
                console.log("____DROPPED ITEMS ____2", droppedItems.droppedItems)
                let newDroppedItems = droppedItems.droppedItems.map(item2 => {
                    if(item2.refId === paramRefId ) {
                         console.log("___ITEM__2", item2)
                         let newObj;
                         let currKeys = Object.keys(item2)
                         console.log(currKeys)
                         console.log(typeof(currKeys))
                        //  if(currKeys?.find(keyItem => (keyItem === 'params')) === false) {
                        if(!item2.hasOwnProperty('params')) {
                        //  if(Object.keys["params"] === undefined ) {
                             
                             newObj = Object.assign({ ...item2, params: [obj] })
                             console.log("NEWOBJ1----- 2", newObj);
                             return newObj;
                         } else {
                            let currParams = item2.params
                            //  console.log(item2._id)
                            //  console.log(obj._id)
                            //  console.log(item2.refId)
                            //  console.log(paramRefId)
                            let paramChanged = false;
                             let newParams = currParams.map(item3 => {
                                console.log(item3._id)
                                console.log(obj._id)
                                console.log(item3.refId)
                                console.log(paramRefId)
                                if((item3._id === obj._id) && (item3.refId === paramRefId)) {
                                    console.log("----- TREUEUUEUEUEE_----")
                                    item3.currentValue = obj.currentValue;
                                    // newObj = {...item3}
                                    console.log("NEWOBJ2----- 2")
                                    paramChanged = true;
                                    // return newObj   
                                    return item3;
                                } 
                                // else if(obj.refId === paramRefId) {
                                //     // newObj = Object.assign({ params: [...currParams, obj] })
                                //     newObj = {...obj}
                                //     console.log("NEWOB3 ----- 2 ", newObj)  
                                //     return newObj;
                                // }
                                else {
                                    return item3;
                                    // return newObj;
                                }
                             })
                             if(!paramChanged) {
                                item2 = Object.assign({...item2, params: [...newParams, obj]})
                             } else {
                                item2 = Object.assign({...item2, params: newParams })
                             }
                             
                            //  let currParams = item2.params
                            //  console.log(item2._id)
                            //  console.log(obj._id)
                            //  console.log(item2.refId)
                            //  console.log(paramRefId)
                            //  if((item2._id === obj._id) && (item2.refId === paramRefId))  {
                            //      item2.currentValue = obj.currentValue
                            //      newObj = {...item2};
                            //      console.log("NEWOBJ2----- 2", newObj)
                            //      return newObj
                            //  } else {
                            //      newObj = Object.assign({ ...item2, params: [...currParams, obj] })  
                            //      console.log("NEWOB3 ----- 2", newObj)  
                            //      return newObj;
                            //  }
                             
                         }
                         
                         console.log(newObj)
                         return item2;
                    } else {
                        return item2;
                    }
                    
                 })

                console.log("STTTING DROPPPP----- 2", newDroppedItems)
                setDrop(prevState => {
                    return {
                        ...prevState,
                        droppedItems: newDroppedItems
                    }
                 })
                
            }
        }
        // else if()
        // console.log
    }

    console.log(params)
    console.log(droppedItems)
    // let performingOperations2;
    const [statePerformingOperations, setStatePerformingOperations] = useState(false)


    useEffect(() => {
        let performingOperations2 = getPerformingOperations();
        setPerformingOperations(performingOperations2)
    }, [statePerformingOperations])
    
    return (
        <div>
            {/* <div > <p style={{color: "#000000"}}> {performingOperations ? "true" : "false" }</p> </div>     */}
            { statePerformingOperations ?  
            (<div
            ref={drop}
                key="performingOperations"
                style={ props.height ?  {width: "150px", marginLeft: "10px", height: "600px", backgroundColor: "yellow"} : 
                { marginTop: "10px", width: "150px", marginLeft: "10px", height: "100px", backgroundColor: "yellow"} 
            }
            >
                
                {droppedItems?.droppedItems?.length > 0 ? 
                    // droppedItems[0].name : ""
                    droppedItems?.droppedItems?.map((item, i) => {
                        // console.log(item);
                        if(item.isMathFunction) {
                            return (
                            <div key={item._id} style={{maringTop: "10px"}}> 
                                <div style={{ display: "flex", width: "100%", justifyContent: "space-between" }}> 
                                    {item.name}
                                    <div
                                        style={{ display: "flex", justifyContent: "center", cursor: "pointer", alignItems: "center", textAlign: "center", width: "40px", height: "20px", color: "#ffffff", backgroundColor: "red", borderRadius: "4px" }}
                                         onClick={() => mathFunctionSave(item.refId)}>
                                        Save
                                    </div>
                                </div> 
                                {/* { item.params?.length > 0 ? ( */}
                                {/* { item.params?.length > 0 ? item.params.map((param) => {
                                    console.log("PARRRRRRRRR ----", param)
                                    return (
                                        <ParameterDropped 
                                            key={i+"a"} 
                                            params={param} 
                                            refId={item.refId} 
                                            setAddingToCanvas={checkBeforeAddingToCanvas2} receiveParameters={receiveParameters} receiveParameters2={receiveParameters2} /> 
                                    )
                                })  : ( */}
                                {/* ( */}
                                    <div>
                                      <ParameterDroppable key={i+"a"} refId={item.refId} setAddingToCanvas={checkBeforeAddingToCanvas2} receiveParameters={receiveParameters} receiveParameters2={receiveParameters2} /> 
                                     <ParameterDroppable key={i+"b"} refId={item.refId} setAddingToCanvas={checkBeforeAddingToCanvas2} receiveParameters={receiveParameters} receiveParameters2={receiveParameters2} /> 
                                    </div>
                                {/* )  */}
                                {/* } */}
                                   
                               
                                
                             </div>)
                        }
                        else if(item.type=== "conditional") {
                            return (<div key={item._id+"notDroppable"}> {item.name} </div>)
                        }
                         else {
                            Object.assign(item, {...item, "initialize": true})
                            return (<div key={item._id+"notDroppable"}> {item.name} = </div> )
                        }
                        
                    }): ""
                
                }
            </div> 
                ) : 
                
            (
                <div> </div>
            )
            }
            
            
        </div>
    )
}

const mapStateToProps = state => {
    console.log(state)
    return {
        createFunc: state.createNewFunc,
        selected_project: state.selectedProject
    }
}

export default connect(mapStateToProps, null)(MakeDroppable)