


export default function manageButtonTagTraits(editor, pageByProjectData) {
    // console.log('pageRef:::::::::::::::::', pageByProjectData)
    // const PageData = pageByProjectData.map((x) => {
    //     const arr = {
    //         id: x._id,
    //         name: x.name
    //     }
    //     return arr
    // })

    // if (PageData) {

    //     const getAllComponents = (model, result = []) => {
    //         result.push(model);
    //         model.components().each(mod => getAllComponents(mod, result))
    //         return result;
    //     }
    //     const allComps = getAllComponents(editor.DomComponents.getWrapper());
    //     allComps.forEach((ele) => {
    //         if (ele.attributes.tagName === 'button') {
    //             let setNewProp7 = true;
    //             const traits = ele.get('traits');
    //             traits.forEach(trait => {
    //                 const curProps = trait.props();
    //                 console.log('props:', curProps);
    //                 if (curProps.label === 'pages') {
    //                     setNewProp7 = false;
    //                 }
    //             });

    //             // if (setNewProp7 !== false) {
    //             //     ele.addTrait({ name: 'name' }, { at: 0 });
    //             //     ele.addTrait({
    //             //         type: 'select',
    //             //         label: 'pages',
    //             //         name: 'buttonHref',
    //             //         options: PageData,
    //             //     }, { at: 1 });
    //             // }
    //         }
    //     });
    // }
}