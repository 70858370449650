import uniqid from "uniqid";
import UIkit from "uikit";
import $ from "jquery";
import { API_HOST } from "../api_utils/index";

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const CustomPopupPlugin = (editor, options) => {
	console.log("CustomEmailPlugin options", options);

	const notifyTheme = {
		position: "top-right",
		autoClose: 7500,
		hideProgressBar: false,
		closeOnClick: true,
		pauseOnHover: true,
		draggable: true,
		progress: undefined,
		theme: "light",
	};

	const notifyTheme2 = {
		position: "top-right",
		autoClose: 2000,
		hideProgressBar: false,
		closeOnClick: true,
		pauseOnHover: true,
		draggable: true,
		progress: undefined,
		theme: "light",
	};

	const successTheme = {
	  position: "top-right",
	  autoClose: 5000,
	  hideProgressBar: false,
	  closeOnClick: true,
	  pauseOnHover: true,
	  draggable: true,
	  progress: undefined,
	  theme: "light",
	};

	// toast notification for empty tables
	const infoTheme = {
	  position: "top-right",
	  autoClose: 7500,
	  hideProgressBar: false,
	  closeOnClick: true,
	  pauseOnHover: true,
	  draggable: true,
	  progress: undefined,
	  theme: "light",
	};

	editor.Blocks.add("button-popup-new-v3", {
		label: "Popup Button (v3)",
		category: "Buttons",
		select: true,
		media: `<svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="currentColor" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"/></svg>`,
		content: { type: "button-popup-new-v3" },
	});

	editor.Blocks.add("popup-close-button-container", {
		label: "Close Popup Button",
		category: "Buttons",
		select: true,
		media: `<svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="currentColor" viewBox="0 0 16 16"><path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708"/></svg>`,
		content: { type: "popup-close-button-container" },
	});

	if (editor !== null && editor !== undefined) {
		const popupbuttonV3Script = function (props) {
			let popupBtn = this.getElementsByClassName('popup-button-new')[0];
			let popupCloseBtn = this.getElementsByClassName('btn-close')[0];

			console.log('popupBtn, popupCloseBtn', popupBtn, popupCloseBtn);

			if (popupBtn) {
				popupBtn.addEventListener("click", (event) => {
	                event.preventDefault();
	                // Your modal opening logic here
	            });
			}
            
			if (popupCloseBtn) {
				popupCloseBtn.addEventListener("click", (event) => {
	                event.preventDefault();
	                // Your modal opening logic here
	            });
			}

			// Listen for changes to the body element's class list. If "model-open" class is attached to <body> tag then set overflow: hidden !important so that the page doesn't scroll when the modal is open
		    const observer = new MutationObserver((mutationsList) => {
		        mutationsList.forEach(mutation => {
		            if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
		                const body = document.body;
		                if (body.classList.contains('modal-open')) {
		                    // Add overflow: hidden !important to body
		                    body.style.overflow = 'hidden';
		                    body.style.setProperty('overflow', 'hidden', 'important');
		                }
		            }
		        });
		    });

		    // Start observing the body element for attribute changes (class changes)
		    observer.observe(document.body, { attributes: true });
		};

		editor.DomComponents.addType("button-popup-new-v3", {
			// modal: {
			//   defaults: {
			//     tagName: "div",
			//     // type: "button-popup-new",
			//     attributes: {
			//       class: "button-popup-new",
			//     },

			//     components: [
			//       {
			//         tagName: "button",
			//         type: "default",
			//         editable: true,
			//         attributes: {
			//           type: "button",
			//           class: "btn btn-primary button-popup",
			//           'data-bs-toggle': "modal",
			//           'data-bs-target': "#staticBackdrop",
			//         },

			//         "style-default": {
			//           width: "max-content",
			//           padding: "0.7%",
			//           "border-radius": "25px",
			//           "background-color": "#1991E7",
			//           border: "2px solid #1991E7",
			//           color: "white",
			//           cursor: "pointer",
			//         },

			//         style: {
			//           width: "max-content",
			//           padding: "0.7%",
			//           "border-radius": "25px",
			//           "background-color": "#1991E7",
			//           border: "2px solid #1991E7",
			//           color: "white",
			//           cursor: "pointer",
			//         },

			//         components: {
			//           tagName: "p",
			//           type: "text",

			//           components: {
			//             type: "textnode",
			//             content: "Open",
			//           },

			//           "style-default": {
			//             margin: "auto",
			//             "text-align": "center",
			//           },

			//           style: {
			//             margin: "auto",
			//             "text-align": "center",
			//           },
			//         },
			//       }, {
			//         tagName: "div",
			//         type: "default",
			//         attributes: {
			//           class: "modal fade extra-popup-class",
			//           id: "staticBackdrop",
			//           'data-bs-backdrop': "static",
			//           'data-bs-keyboard': "false",
			//           tabindex: "-1",
			//           'aria-labelledby': "staticBackdropLabel",
			//           'aria-hidden': "true",
			//         },

			//         components: [
			//           {
			//             tagName: "div",
			//             type: "default",
			//             attributes: {
			//               class: "modal-dialog",
			//             },

			//             components: [
			//               {
			//                 tagName: "div",
			//                 type: "default",
			//                 attributes: {
			//                   class: "modal-content",
			//                 },

			//                 components: [
			//                   {
			//                     tagName: "div",
			//                     type: "default",
			//                     attributes: {
			//                       class: "modal-header",
			//                     },

			//                     components: [
			//                       {
			//                         tagName: "h1",
			//                         attributes: {
			//                           class: "modal-title fs-5",
			//                         },

			//                         components: {
			//                           tagName: "p",
			//                           type: "text",
			//                           editable: true,

			//                           components: {
			//                             type: "textnode",
			//                             content: "Modal title",
			//                           },
			//                         },
			//                       }, {
			//                         tagName: "button",
			//                         type: "default",
			//                         attributes: {
			//                           type: "button",
			//                           class: "btn-close",
			//                           'data-bs-dismiss': "modal",
			//                           'aria-label': "Close",
			//                         },
			//                       },
			//                     ],
			//                   }, {
			//                     tagName: "div",
			//                     type: "default",
			//                     attributes: {
			//                       class: "modal-body",
			//                     },
			//                   }, {
			//                     tagName: "div",
			//                     type: "default",
			//                     attributes: {
			//                       class: "modal-footer",
			//                     },

			//                     components: [
			//                       {
			//                         tagName: "button",
			//                         type: "default",
			//                         attributes: {
			//                           type: "button",
			//                           class: "btn btn-secondary",
			//                           'data-bs-dismiss': "modal",
			//                         },

			//                         components: {
			//                           tagName: "p",
			//                           type: "text",
			//                           editable: true,

			//                           components: {
			//                             type: "textnode",
			//                             content: "Close",
			//                           },
			//                         },
			//                       }, {
			//                         tagName: "button",
			//                         attributes: {
			//                           type: "button",
			//                           class: "btn btn-primary",
			//                         },

			//                         components: {
			//                           tagName: "p",
			//                           type: "text",
			//                           editable: true,

			//                           components: {
			//                             type: "textnode",
			//                             content: "Action",
			//                           },
			//                         },
			//                       },
			//                     ],
			//                   },
			//                 ],
			//               },
			//             ],
			//           },
			//         ],
			//       },
			//     ],
			//   },

			//   init() {
			//     console.log('popup-button-new');
			//   },
			// },

			model: {
				defaults: {
					tagName: "span",

					attributes: {
						class: "contain-popup-button-new",
					},

					script: popupbuttonV3Script,

					// traits
					blockModal: false,

					traits: [
						{
							type: "checkbox",
							label: "Block Modal",
							name: "blockModal",
							changeProp: true,
						},
					],

					changeProp: true,
					"script-props": ["blockModal"],

					components: [
						{
							// editable: true,
							tagName: "popup-button-btn-new",
							type: "default",
							editable: true,
							contenteditable: "true",
							// components: `<button class="btn popup-button" id="popup-button" data-bs-toggle= "modal" data-bs-target= "#exampleModal">Open</button> `,
							components: [
								{
									tagName: "button",
									attributes: {
										class: "btn popup-button-new",
										// id: "popup-button",
										"data-bs-toggle": "modal",
										"data-bs-target": "#exampleModal",
									},

									"style-default": {
										"background-color": "transparent",
										width: "max-content",
										height: "max-content",
										padding: "2%",
										color: "inherit", // so that child text can inherit from the upper parent
									},

									style: {
										"background-color": "transparent",
										width: "max-content",
										height: "max-content",
										padding: "2%",
										color: "inherit", // so that child text can inherit from the upper parent
									},

									components: {
										tagName: "p",
										type: "text",
										editable: true,

										"style-default": {
											margin: "auto",
											color: "inherit",
										},

										style: {
											margin: "auto",
											color: "inherit",
										},

										components: {
											type: "textnode",
											content: "Open",
										},
									},
								},
							],

							"style-default": {
								display: "block",
								width: "max-content",
								padding: "0.7%",
								"border-radius": "5px",
								"background-color": "#1991E7",
								border: "2px solid #1991E7",
								color: "white",
								cursor: "pointer",
								"text-align": "center",
							},

							style: {
								display: "block",
								width: "max-content",
								padding: "0.7%",
								"border-radius": "5px",
								"background-color": "#1991E7",
								border: "2px solid #1991E7",
								color: "white",
								cursor: "pointer",
								"text-align": "center",
							},
						},
						{
							tagName: "div",
							type: "default",
							attributes: {
								class: "modal fade popup-button-modal-new",

								id: "exampleModal",

								tabindex: "-1",

								"aria-labelledby": "exampleModalLabel",

								"aria-hidden": "true",
							},

							"style-default": {
								position: "fixed",

								width: "100vw",

								height: "100vh",

								padding: "0.5em",

								border: "none",

								top: "0",

								bottom: "0",

								left: "0",

								right: "0",
							},

							style: {
								position: "fixed",

								width: "100vw",

								height: "100vh",

								padding: "0.5em",

								border: "none",

								top: "0",

								bottom: "0",

								left: "0",

								right: "0",
							},

							components: {
								tagName: "div",
								type: "default",
								attributes: { class: "modal-dialog modal-dialog-centered modal-dialog-scrollable" },

								components: {
									tagName: "div",
									type: "default",
									attributes: { class: "modal-content" },

									"style-default": {
										padding: "0.5em",
									},

									style: {
										padding: "0.5em",
									},

									components: [
										{
											tagName: "div",
											type: "default",
											attributes: { class: "modal-header" },

											components: [
												{
													tagName: "button",
													type: "default",
													attributes: {
														class: "btn-close",

														"data-bs-dismiss": "modal",

														"aria-label": "Close",
													},

													traits: ["data-bs-dismiss"],
												},
											],
										},
										{
											tagName: "div",
											type: "default",
											attributes: { class: "modal-body" },
										},
										{
											tagName: "div",
											type: "default",
											attributes: { class: "modal-footer" },
										},
									],
								},
							},
						},
					],
				},

				removed() {
					console.log("this removed", this);
					let modal = this.find(".modal.fade.popup-button-modal-new")[0];

					if (modal) {
						// remove the added items of this component from LS
						localStorage.removeItem(`data_bs_target_for_${modal.getAttributes()["id"]}`);
					}
				},

				init() {
					this.on("change:blockModal", this.handlePropChange);
				},

				handlePropChange() {
					let { blockModal } = this.props();
					let mainContainer = this;
					let popupBtn = mainContainer.find(".btn.popup-button-new")[0];
					let modal = mainContainer.find(".modal.fade.popup-button-modal-new")[0];

					let popupBtnDataBsTarget = modal ? modal.getAttributes()["id"] : "";

					// modal.setAttributes('id', uniqueId);
					// popupBtn.setAttributes('data-bs-target', `#${uniqueId}`);

					if (blockModal) {
						if (popupBtn) {
							popupBtn.setAttributes({
								class: "btn popup-button-new",
								// id: "popup-button",
								"data-bs-toggle": "",
								"data-bs-target": `#${popupBtnDataBsTarget}`,
							});
							console.log("popupBtn blocked");
						}
					}
					if (!blockModal) {
						if (popupBtn) {
							popupBtn.setAttributes({
								class: "btn popup-button-new",
								// id: "popup-button",
								"data-bs-toggle": "modal",
								"data-bs-target": `#${popupBtnDataBsTarget}`,
							});							

							console.log("popupBtn, popupBtnDataBsTarget, modal", popupBtn, modal.getAttributes()["id"], modal);
						}
					}
				},
			},

			view: {
				// init({ model }) {
				// 	this.listenTo(model, "change:blockModal", this.handlePropChange);
				// },

				// handlePropChange() {
				// 	let blockModal = this.model.props();
				// 	let modal = this.model.find('.popup-button-modal-new');
				// 	let button = this.model.find('.popup-button-new');
				// 	console.log('modal, button', modal, button);

				// 	if (blockModal) {
				// 		if (modal.length > 0) {
				// 			modal[0].setStyle({ display: 'none' });
				// 			console.log('modal btn blocked');
				// 		}
				// 	}
				// },

				onRender({ el, model }) {
					if (el.className === "contain-popup-button-new") {
						let uniqueId = uniqid("modal");
						console.log("this new popup", el, uniqueId);

						let mainContainer = model;
						let popupBtn = mainContainer.find("popup-button-btn-new")[0].find(".popup-button-new")[0];
						let modal = mainContainer.find(".modal.fade.popup-button-modal-new")[0];

						console.log("modal.getAttributes(), popupBtn", modal.getAttributes(), popupBtn.getAttributes());

						if (!("id" in modal.getAttributes())) {
							modal.addAttributes({ id: uniqueId });

							popupBtn.setAttributes({
								"data-bs-target": `#${uniqueId}`,
								// reappend other attributes also even if they are not updating, because setAttributes will otherwise remove these and only set that one which is mentioned in this method
								class: "btn popup-button-new",
								"data-bs-toggle": "modal",
							});

							// store in local storage also to preserve it during enabling/disabling popup button for editing
							localStorage.setItem(`data_bs_target_for_${uniqueId}`, `#${uniqueId}`);
							console.log("modified 1 and saved in local storage");
						}

						// if the modal element has ID but it doesn't start with substring 'modal', then update it
						else if ("id" in modal.getAttributes() && !modal.getAttributes().id.startsWith("modal")) {
							modal.setAttributes({
								// reappend other attributes also even if they are not updating, because setAttributes will otherwise remove these and only set that one which is mentioned in this method
								class: "modal fade popup-button-modal-new",
								id: uniqueId,
								tabindex: "-1",
								"aria-labelledby": `${uniqueId}Label`,
								"aria-hidden": "true",
							});
							// modal.attributes.attributes['class'] = "modal fade popup-button-modal-new";

							popupBtn.setAttributes({
								"data-bs-target": `#${uniqueId}`,
								// reappend other attributes also even if they are not updating, because setAttributes will otherwise remove these and only set that one which is mentioned in this method
								class: "btn popup-button-new",
								"data-bs-toggle": "modal",
							});
							// popupBtn.attributes.attributes['class'] = "btn popup-button-new";

							// store in local storage also to preserve it during enabling/disabling popup button for editing
							localStorage.setItem(`data_bs_target_for_${uniqueId}`, `#${uniqueId}`);
							console.log("modified 2 and saved in local storage");
						}

						console.log(popupBtn, modal);
					}
				},
			},
		});

		const popupCloseBtnScript = function (props) {
			let popupCloseBtn = this.getElementsByClassName('popup-close-button')[0];
			console.log('popupCloseBtn', popupCloseBtn);

			if (popupCloseBtn) {
				popupCloseBtn.addEventListener("click", (event) => {
	                event.preventDefault();
	                // Your modal opening logic here
	            });
			}            
		};

		editor.DomComponents.addType("popup-close-button-container", {
			model: {
				defaults: {
					tagName: "div",
					type: "popup-close-button-container",
					attributes: {
						class: "popup-close-button-container",
					},

					script: popupCloseBtnScript,

					enableClose: true,

					traits: [
						{
							type: "checkbox",
							label: "Enable Close",
							name: "enableClose",
							changeProp: true,
						},
					],

					changeProp: true,
					"script-props": ["enableClose"],

					components: [
						{
							tagName: "button",
							type: "popup-close-button",
							attributes: {
								type: "button",
								class: "btn popup-close-button",
								"data-bs-dismiss": "modal",
							},

							"style-default": {
								"background-color": "gray",
								"border-radius": "10px",
								width: "max-content",
								height: "max-content",
								padding: "2%",
								color: "white",
							},

							style: {
								"background-color": "gray",
								"border-radius": "10px",
								width: "max-content",
								height: "max-content",
								padding: "2%",
								color: "white",
							},

							components: {
								tagName: "p",
								type: "text",
								editable: true,

								"style-default": {
									margin: "auto",
									color: "inherit",
								},

								style: {
									margin: "auto",
									color: "inherit",
								},

								components: {
									type: "textnode",
									content: "Close",
								},
							},
						},
					],
				},

				init() {
					this.on("change:enableClose", this.enableCloseHandler);
				},

				enableCloseHandler() {
					let { enableClose } = this.props();

					let buttonContainer = this;
					let closeBtn = buttonContainer.find(".popup-close-button")[0];

					// if trait is off, don't let the button close popup
					if (!enableClose) {
						closeBtn.setAttributes({
							type: "button",
							class: "btn popup-close-button",
							"data-bs-dismiss": "",
						});
					} else {
						closeBtn.setAttributes({
							type: "button",
							class: "btn popup-close-button",
							"data-bs-dismiss": "modal",
						});
					}
				},
			},
		});
	}
};

export default CustomPopupPlugin;
