// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.GCModal_gcModal__iS3qv {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.GCModal_gcModalBackdrop__NHuAt {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
}

.GCModal_gcModalContent__NU086 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 1%;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); /* Shadow effect */
  width: 50vw;
  max-width: 70vw;
  max-height: 70vh;
  overflow: auto;
}

.GCModal_gcModalClose__oorDi {
  position: absolute;
  top: auto;
  bottom: auto;
  right: 2%;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 100%;
  color: #666;
  transition: all 100ms linear;
}

.GCModal_gcModalClose__oorDi:hover {
  color: #000;
}`, "",{"version":3,"sources":["webpack://./src/Screens/ScreensStylings/GCModal.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,MAAM;EACN,OAAO;EACP,WAAW;EACX,YAAY;AACd;;AAEA;EACE,kBAAkB;EAClB,MAAM;EACN,OAAO;EACP,WAAW;EACX,YAAY;EACZ,oCAAoC,EAAE,2BAA2B;AACnE;;AAEA;EACE,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,gCAAgC;EAChC,uBAAuB;EACvB,WAAW;EACX,kBAAkB;EAClB,uCAAuC,EAAE,kBAAkB;EAC3D,WAAW;EACX,eAAe;EACf,gBAAgB;EAChB,cAAc;AAChB;;AAEA;EACE,kBAAkB;EAClB,SAAS;EACT,YAAY;EACZ,SAAS;EACT,gBAAgB;EAChB,YAAY;EACZ,eAAe;EACf,eAAe;EACf,WAAW;EACX,4BAA4B;AAC9B;;AAEA;EACE,WAAW;AACb","sourcesContent":[".gcModal {\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n}\n\n.gcModalBackdrop {\n  position: absolute;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black */\n}\n\n.gcModalContent {\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  background-color: white;\n  padding: 1%;\n  border-radius: 5px;\n  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); /* Shadow effect */\n  width: 50vw;\n  max-width: 70vw;\n  max-height: 70vh;\n  overflow: auto;\n}\n\n.gcModalClose {\n  position: absolute;\n  top: auto;\n  bottom: auto;\n  right: 2%;\n  background: none;\n  border: none;\n  cursor: pointer;\n  font-size: 100%;\n  color: #666;\n  transition: all 100ms linear;\n}\n\n.gcModalClose:hover {\n  color: #000;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"gcModal": `GCModal_gcModal__iS3qv`,
	"gcModalBackdrop": `GCModal_gcModalBackdrop__NHuAt`,
	"gcModalContent": `GCModal_gcModalContent__NU086`,
	"gcModalClose": `GCModal_gcModalClose__oorDi`
};
export default ___CSS_LOADER_EXPORT___;
