import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  useCallback,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllProject } from "../../Redux/Actions/projectActions";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../Loader";
import {
  Button,
  Grid,
  Typography,
  Modal,
  TextField,
  Divider,
} from "@mui/material";
import darkBubble from "../component_assets/images/darkBubble.png";
import lightBubble from "../component_assets/images/lightBubble.png";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import "../style.css";
import DeleteIcon from "@mui/icons-material/Delete";
import RateReviewIcon from "@mui/icons-material/RateReview";
import BarChartIcon from "@mui/icons-material/BarChart";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import CloseIcon from "@mui/icons-material/Close";
import { updateProject } from "../../Redux/Actions/projectActions";
// import errorSymbol from './component_assets/images/details_close.png'
import errorSymbol from "../component_assets/images/errorSymbol.svg";
// import btnGrp from './component_assets/images/details_close.png'
import btnGrp from "../component_assets/images/btnGrpRed.svg";
// import errorWave from './component_assets/images/details_close.png'
import errorWave from "../component_assets/images/errorWave.svg";
import DownloadIcon from "@mui/icons-material/Download";
import { deleteProject } from "../../Redux/Actions/projectActions";

import WysiwygIcon from "@mui/icons-material/Wysiwyg";
import RouteIcon from "@mui/icons-material/Route";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { FiSearch } from "react-icons/fi";
import { IoBody, IoClose } from "react-icons/io5";

import { API_HOST } from "../../api_utils";
import { HiTemplate } from "react-icons/hi";

import classes from "../ScreensStylings/AllProjects.module.css";
import KeyIcon from "@mui/icons-material/Key";
import NotificationAddIcon from "@mui/icons-material/NotificationAdd";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";

function NotificationList() {
  let dispatch = useDispatch();
  let navigate = useNavigate();
  const { project } = useParams();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [emptyNotification, setEmptyNotification] = useState(false);
  const [modalData, setModalData] = useState({});
  const [open, setOpen] = useState(false);

  const [filteredData, setFilteredData] = useState([]);

  const getAllNotificationHandler = async () => {
    setLoading(true);
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await axios.get(
      `${API_HOST}mobile_push_config/get_all_config/${project}`,
      config
    );

    if (data.message) {
      setLoading(false);
      toast.error(data.message, errorTheme);
    } else {
      setLoading(false);
      setFilteredData(data);
      if (!data.length) {
        setEmptyNotification(true);
      }
    }
  };

  useEffect(() => {
    getAllNotificationHandler();

    return () => {
      setLoading(false);
    };
  }, []);

  const errorTheme = {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
  };

  const successTheme = {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
  };

  const updateNotificationHandler = async (id) => {
    console.log("notification id::::::::::", id);
    let modalDataRef = filteredData.find((x) => x._id === id);
    if (modalDataRef) {
      setModalData(modalDataRef);
      setOpen(true);
    }
  };

  const columns = [
    { field: "id", headerName: "ID", width: 100 },
    {
      field: "title",
      headerName: "Title",
      flex: 1,
      editable: true,
    },
    {
      field: "body",
      headerName: "Body",
      flex: 2,
      editable: true,
    },
    {
      field: "url",
      headerName: "Url",
      flex: 2,
      editable: true,
    },
    {
      field: "icon",
      headerName: "Icon",
      flex: 2,
      editable: true,
    },

    {
      field: "Actions",
      headerName: "",
      flex: 1,
      renderCell: (params) => {
        return (
          <Typography
            onClick={(event) => {
              event.stopPropagation();
              console.log(event);
              // deleteProjectHandler(params.id)
              // console.log('params', params);
              updateNotificationHandler(params.id);
            }}
            sx={{
              color: "#e11d48",
              "&:hover": { color: "#fb7185", scale: "125%" },
            }}>
            <RateReviewIcon fontSize="medium" />
          </Typography>
        );
      },
    },
  ];

  const rows = filteredData
    ? filteredData.map((x) => {
        // console.log('x', x);
        return {
          id: x._id,
          title: x.title,
          body: x.body !== "undefined" ? x.body : "---",
          url: x.url !== "undefined" ? x.url : "---",
          icon: x.icon !== "undefined" ? x.icon : "---",
        };
      })
    : [
        {
          id: 1,
          projectName: "Snow",
          facebookId: "Jon",
          twitterId: "twitterId",
          linkedInId: "linkedInId",
          instagramId: "instagramId",
          analyticsCode: "analyticsCode",
        },
        {
          id: 2,
          projectName: "Snow",
          facebookId: "Jon",
          twitterId: "twitterId",
          linkedInId: "linkedInId",
          instagramId: "instagramId",
          analyticsCode: "analyticsCode",
        },
        {
          id: 3,
          projectName: "Snow",
          facebookId: "Jon",
          twitterId: "twitterId",
          linkedInId: "linkedInId",
          instagramId: "instagramId",
          analyticsCode: "analyticsCode",
        },
        {
          id: 4,
          projectName: "Snow",
          facebookId: "Jon",
          twitterId: "twitterId",
          linkedInId: "linkedInId",
          instagramId: "instagramId",
          analyticsCode: "analyticsCode",
        },
        {
          id: 5,
          projectName: "Snow",
          facebookId: "Jon",
          twitterId: "twitterId",
          linkedInId: "linkedInId",
          instagramId: "instagramId",
          analyticsCode: "analyticsCode",
        },
      ];

  const updateConfigHandler = async () => {
    setLoading(true);
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.put(
      `${API_HOST}mobile_push_config/update_config/${modalData?._id}`,
      modalData,
      config
    );

    if (data.message === "Config Updated Successfully !!!") {
      setLoading(false);
      toast.success(data.message, errorTheme);
      setOpen(false);
    } else {
      toast.error("Something went wrong !!!");
    }
  };

  const uploadIconHandler = async (files) => {
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    let file = files[0];
    let formData = new FormData();
    formData.append("file", file);
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${userInfo?.token}`,
      },
    };

    const { data } = await axios.post(
      `${API_HOST}uploadRoutes/upload`,
      formData,
      config
    );
    if (data) {
      console.log("data::::::", data);
      toast.success("Icon Uploaded Successfully!!!");
      setModalData((prevState) => ({
        ...prevState,
        icon: data.fileLocation,
      }));
    }
  };

  return (
    <div className="">
      {loading ? (
        <Loader />
      ) : emptyNotification || filteredData.length === 0 ? (
        <Grid
          sx={{
            height: "87.5vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            position: "relative",
            overflow: "hidden",
          }}>
          <Grid
            width={"80vw"}
            height={"80%"}
            sx={{
              padding: "2vh 0 2vh 3vw",
              borderRadius: "8px",
              backdropFilter: "blur(25px)",
              boxShadow: 20,
              zIndex: 20,
            }}>
            <Grid
              xs={12}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}>
              <Typography
                sx={{ fontFamily: "Mont-SemiBold", fontSize: "1.5rem" }}>
                Notifications
              </Typography>
              <Grid
                xs={9}
                sx={{ backgroundColor: "red", height: "0.6vh" }}></Grid>
            </Grid>
            <Grid sx={{ display: "flex", justifyContent: "center", mt: 6 }}>
              <img src={errorSymbol} style={{ height: "30vh" }} />
            </Grid>
            <Typography
              sx={{
                textAlign: "center",
                mt: 4,
                fontSize: "1.2rem",
                fontFamily: "Mont-SemiBold",
              }}>
              Sorry! No Notifications have been created yet.{" "}
            </Typography>
            <Grid sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
              <Button
                onClick={() =>
                  navigate(`/mobile_notification/create_new_config/${project}`)
                }
                variant="contained"
                sx={{
                  backgroundColor: "white",
                  color: "black",
                  fontFamily: "Mont-Regular",
                }}>
                <span style={{ marginRight: "1vw" }}>
                  Create New Notification
                </span>
                <img src={btnGrp} style={{ height: "4vh" }} />
              </Button>
            </Grid>
          </Grid>
          <Grid sx={{ position: "absolute", bottom: "-80vh", right: "-20vw" }}>
            <img src={lightBubble} style={{ height: "160vh" }} />
          </Grid>
          <Grid sx={{ position: "absolute", top: "-12vh", right: "0" }}>
            <img src={errorWave} style={{ height: "180vh" }} />
          </Grid>
        </Grid>
      ) : (
        <Grid
          sx={{ position: "relative", overflow: "hidden", height: "100vh" }}>
          <Grid
            sx={{
              position: "absolute",
              top: "-12vh",
              left: "-30vw",
              zIndex: "-10",
            }}>
            <img style={{ width: "55vw", height: "90%" }} src={darkBubble} />
          </Grid>

          <Grid sx={{ position: "absolute", bottom: "-60%", right: "-15%" }}>
            <img style={{ width: "55vw", height: "90%" }} src={lightBubble} />
          </Grid>

          <Grid
            sx={{
              display: "flex",
              justifyContent: "center",
              paddingTop: "5vh",
            }}>
            <Box
              sx={{
                minHeight: "67vh",
                width: "90%",
                backdropFilter: "blur(25)",
                backgroundColor: "",
              }}>
              <Grid
                xs={12}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
                container>
                <Typography
                  sx={{ fontFamily: "Mont-SemiBold", fontSize: "1.8rem" }}>
                  Notifications
                </Typography>
                <Grid
                  xs={9}
                  sx={{ height: "1.5vh", backgroundColor: "red" }}
                  item></Grid>
              </Grid>
              <Grid
                sx={{
                  display: "flex",
                  justifyContent: "end",
                  mt: 1,
                  marginBottom: "3vh",
                }}>
                <Button
                  onClick={() =>
                    navigate(
                      `/mobile_notification/create_new_config/${project}`
                    )
                  }
                  variant="contained"
                  sx={{
                    backgroundColor: "red",
                    color: "white",
                    fontFamily: "Mont-Regular",
                    "&:hover": { backgroundColor: "white", color: "red" },
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}>
                  <span style={{ marginRight: "1vw" }}>
                    Create New Notification
                  </span>
                  <img src={btnGrp} style={{ height: "4vh" }} />
                </Button>
              </Grid>
              <DataGrid
                rows={rows}
                columns={columns}
                pageSize={10}
                error={error}
                rowsPerPageOptions={[10]}
                checkboxSelection
                disableSelectionOnClick
                initialState={{
                  sorting: {
                    sortModel: [{ field: "lastEdit", sort: "desc" }],
                  },
                }}
              />
            </Box>
          </Grid>
        </Grid>
      )}

      <Modal
        open={open}
        onClose={() => setOpen(false)}
        sx={{
          border: "none",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}>
        <Box
          sx={{
            overflowY: "scroll",
            maxHeight: "80vh",
            width: { lg: "80vw" },
            padding: "2vh 2vw 6vh 2vw",
            outline: "none",
            borderRadius: "6px",
            backgroundColor: "white",
            position: "relative",
          }}>
          <Grid sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              onClick={() => setOpen(false)}
              sx={{ boxShadow: 2, color: "red", "&:hover": { boxShadow: 5 } }}>
              <CloseIcon />
            </Button>
          </Grid>
          <Grid
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mt: 2,
            }}>
            <Typography
              sx={{ fontFamily: "Mont-SemiBold", fontSize: "1.3rem" }}>
              Update Configuration
            </Typography>
            <Grid
              item
              xs={9}
              sx={{ backgroundColor: "red", height: "1vh" }}></Grid>
          </Grid>

          <Divider sx={{ mt: 2, border: "1px solid gray !important" }} />
          <Grid
            container
            sx={{ display: "flex", justifyContent: "space-between", mb: 3 }}>
            <Grid item sx={{ mt: 3, width: "40%" }}>
              <TextField
                onChange={(e) =>
                  setModalData((prevState) => ({
                    ...prevState,
                    title: e.target.value,
                  }))
                }
                id="outlined-basic"
                label="Title"
                variant="outlined"
                sx={{ width: "100%" }}
                value={modalData?.title}
              />
            </Grid>
            <Grid item sx={{ mt: 3, width: "40%" }}>
              <TextField
                onChange={(e) =>
                  setModalData((prevState) => ({
                    ...prevState,
                    body: e.target.value,
                  }))
                }
                id="outlined-basic"
                label="Message"
                variant="outlined"
                sx={{ width: "100%" }}
                value={modalData?.body}
              />
            </Grid>
          </Grid>
          <Grid
            container
            sx={{ display: "flex", justifyContent: "space-between", mb: 3 }}>
            <Grid item sx={{ mt: 3, width: "40%" }}>
              <TextField
                onChange={(e) =>
                  setModalData((prevState) => ({
                    ...prevState,
                    url: e.target.value,
                  }))
                }
                id="outlined-basic"
                label="Url"
                variant="outlined"
                sx={{ width: "100%" }}
                value={modalData?.url}
              />
            </Grid>
            <Grid item sx={{ mt: 3, width: "40%" }}>
              <TextField
                onChange={(e) =>
                  setModalData((prevState) => ({
                    ...prevState,
                    blb_endpoint: e.target.value,
                  }))
                }
                id="outlined-basic"
                label="Dynamic route"
                variant="outlined"
                sx={{ width: "100%" }}
                value={modalData?.blb_endpoint}
              />
            </Grid>
          </Grid>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}>
            <div
              style={{
                display: "flex",
                justifyContent: "start",
                marginBottom: "2rem",
              }}>
              <label
                htmlFor="uploadIcon"
                style={{
                  padding: "0.5rem 1rem",
                  borderRadius: "27.5px",
                  color: "white",
                  backgroundColor: "#a855f7",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}>
                <span>
                  <DriveFolderUploadIcon sx={{ fontSize: "2rem" }} />
                </span>
                <span
                  style={{
                    fontSize: "1.2rem",
                    letterSpacing: "0.1rem",
                    marginTop: "0.3rem",
                    marginLeft: "1rem",
                    display: "block",
                  }}>
                  Upload Icon
                </span>
              </label>
              <input
                id="uploadIcon"
                type="file"
                style={{ display: "none" }}
                onChange={(e) => uploadIconHandler(e.target.files)}
              />
            </div>
          </div>
          <Button
            onClick={updateConfigHandler}
            variant="contained"
            sx={{
              backgroundColor: "red",
              "&:hover": { color: "red", backgroundColor: "white" },
            }}>
            Save Changes
          </Button>
          {/* <Grid sx={{ mt: 4, display: 'flex', justifyContent: 'space-between' }}>

                    </Grid> */}
        </Box>
      </Modal>
    </div>
  );
}

export default NotificationList;
