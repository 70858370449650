import React, { useEffect, useState } from "react";
import Blockly from "blockly";
import "blockly/blocks";
import "blockly/javascript";

import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../Loader";
import {
  Button,
  Grid,
  Typography,
  Modal,
  TextField,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  Divider,
} from "@mui/material";
import darkBubble from "../component_assets/images/darkBubble.png";
import lightBubble from "../component_assets/images/lightBubble.png";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import "../style.css";

import CloseIcon from "@mui/icons-material/Close";
import ScheduleIcon from '@mui/icons-material/Schedule';
import btnGrp from "../component_assets/images/btnGrpRed.svg";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import RateReviewIcon from "@mui/icons-material/RateReview";
import { API_HOST } from "../../api_utils";
import { Tooltip } from "@mui/material";
import { CopyToClipboard } from "react-copy-to-clipboard";

function DynamicRouteLb() {
  let navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [dynamicRouteData, setDynamicRouteData] = useState([]);
  const [showUserTokenModal, setShowUserTokenModal] = useState(false);

  const { userInfo } = useSelector((state) => state.userLogin);
  let { project } = useParams();

  const [userKeysDropdown, setUserKeysDropdown] = useState([]);
  const [selectedUserKey, setSelectedUserKey] = useState("");
  const [searchedUserKey, setSearchedUserKey] = useState("");
  const [userKeyAndTokenData, setUserKeyAndTokenData] = useState([]);

  const CopyButton = ({ text }) => (
    <CopyToClipboard text={text}>
      <Button
        variant="contained"
        color="primary"
        sx={{ marginRight: "0.5vw", backgroundColor: "red" }}>
        Copy
      </Button>
    </CopyToClipboard>
  );

  // get all user keys:
  const getAllUserKeys = async (projectId) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(
      `${API_HOST}create_custom_route/get_user_keys/${projectId}`,
      config
    );

    if (data.message) {
      toast.error(data.message);
    } else {
      console.log("userKeys::::::::", data);
      setUserKeysDropdown(data);
    }
  };

  //get all user tokens

  // get all routes data:
  const getAllRoutes = async (projectId) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(
      `${API_HOST}create_custom_route/${projectId}`,
      config
    );

    if (data.message) {
      toast.warn(data.message);
    } else {
      console.log("data:::::bckendLB", data);
      let filteredData = data.filter((x) => x?.is_type !== "Middleware");
      setDynamicRouteData(filteredData);
    }
  };

  useEffect(() => {
    if (project) {
      getAllRoutes(project);
      getAllUserKeys(project);
    }
  }, []);

  const columns = [
    { field: "id", headerName: "ID", width: 150 },
    {
      field: "controllerName",
      headerName: "Controller Name",
      width: 150,
      editable: true,
    },
    {
      field: "controllerLogic",
      headerName: "Controller Logic",
      width: 300,
      editable: true,
    },
    {
      field: "method",
      headerName: "Method",
      width: 100,
      editable: true,
    },
    {
      field: "createdBy",
      headerName: "Created By",
      width: 150,
      editable: true,
    },
    {
      field: "Detail",
      headerName: "Detail",
      width: 70,
      renderCell: (params) => {
        return (
          <Typography
            onClick={() => {
              console.log("params", params);
              navigate(
                `/create_new_route/${project}?edit_modal&route=${params.row.id}`
              );
            }}
            sx={{
              color: "#9333ea",
              "&:hover": { color: "#c084fc", scale: "125%" },
            }}>
            <RateReviewIcon fontSize="medium" />
          </Typography>
        );
      },
    },
    {
      field: "userToken",
      headerName: "User Token",
      width: 200,
      editable: false,
      wrapText: true,
      renderCell: (params) =>
        params.value === "show_popup" ? (
          <Tooltip title="Click to Grab User Tokens">
            <Button
              onClick={() => setShowUserTokenModal(true)}
              sx={{
                p: 1,
                borderRadius: "6px",
                color: "white",
                backgroundColor: "red",
              }}>
              Get Tokens
            </Button>
          </Tooltip>
        ) : (
          <Tooltip title={params.value}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}>
              <CopyButton text={params.value} />
              <Typography>{params.value}</Typography>
            </Box>
          </Tooltip>
        ),
    },
    {
      field: "api",
      headerName: "API",
      width: 200,
      editable: false,
      renderCell: (params) => (
        <Tooltip title={params.value}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}>
            <CopyButton text={params.value} />
            <Typography>{params.value}</Typography>
          </Box>
        </Tooltip>
      ),
    },
  ];

  const rows = dynamicRouteData
    ? dynamicRouteData.map((x) => {
        const hostParts = API_HOST.split(":");
        let previewAddress;
        if (window.location.origin === "https://builder.redsling.com") {
          previewAddress = "https://preview1.redsling.com/";
        } else {
          previewAddress = hostParts[0] + ":" + hostParts[1] + ":9090/";
        }

        // console.log('x', x);
        return {
          id: x._id,
          route: x.route,
          controllerName:
            x.controllerName !== "undefined" ? x.controllerName : "---",
          controllerLogic:
            x.controllerLogic !== "undefined" ? x.controllerLogic : "---",
          method: x.method !== "undefined" ? x.method : "---",
          createdBy: x.created_by !== "undefined" ? x.created_by : "---",
          api: `${previewAddress}create_custom_route/execute_dynamic_routenew/${project}/${x.controllerName}`,
          userToken:
            x?.is_type === "Protected" ? "show_popup" : userInfo?.token,
        };
      })
    : [
        {
          id: 1,
          projectName: "Snow",
          facebookId: "Jon",
          twitterId: "twitterId",
          linkedInId: "linkedInId",
          instagramId: "instagramId",
          analyticsCode: "analyticsCode",
        },
        {
          id: 2,
          projectName: "Snow",
          facebookId: "Jon",
          twitterId: "twitterId",
          linkedInId: "linkedInId",
          instagramId: "instagramId",
          analyticsCode: "analyticsCode",
        },
        {
          id: 3,
          projectName: "Snow",
          facebookId: "Jon",
          twitterId: "twitterId",
          linkedInId: "linkedInId",
          instagramId: "instagramId",
          analyticsCode: "analyticsCode",
        },
        {
          id: 4,
          projectName: "Snow",
          facebookId: "Jon",
          twitterId: "twitterId",
          linkedInId: "linkedInId",
          instagramId: "instagramId",
          analyticsCode: "analyticsCode",
        },
        {
          id: 5,
          projectName: "Snow",
          facebookId: "Jon",
          twitterId: "twitterId",
          linkedInId: "linkedInId",
          instagramId: "instagramId",
          analyticsCode: "analyticsCode",
        },
      ]; 

  const viewCronHandler = (id) => {
    navigate(`/view_crons/${id}`);
  };    

  const getGeneratedTokens = async () => {
    let payload;

    if (selectedUserKey) {
      if (searchedUserKey) {
        payload = {
          userKey: selectedUserKey,
          userKeyValue: searchedUserKey,
        };
      } else {
        payload = {
          userKey: selectedUserKey,
        };
      }

      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.post(
        `${API_HOST}create_custom_route/generate_token/${project}`,
        payload,
        config
      );

      if (data.message) {
        toast.error(data.message);
      } else {
        setUserKeyAndTokenData(data);
      }
    } else {
      toast.warn("Make sure to select a key !!!");
    }
  };

  const tokenModalColumns = [
    {
      field: "id",
      headerName: "ID",
      hide: true,
      editable: true,
    },

    {
      field: "SelectedKeyValue",
      headerName: "Selected Key Value",
      editable: true,
      flex: 1,
    },
    {
      field: "Token",
      headerName: "Generated Token",
      editable: true,
      wrapText: true,
      flex: 1,
      renderCell: (params) => (
        <Tooltip title={params.value}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}>
            <CopyButton text={params.value} />
            <Typography>{params.value}</Typography>
          </Box>
        </Tooltip>
      ),
    },
  ];

  const tokenModalRows = userKeyAndTokenData
    ? userKeyAndTokenData.map((x) => {
        return {
          id: x.id,
          SelectedKeyValue: x.keyValue,
          Token: x.tokenValue !== "undefined" ? x.tokenValue : "---",
        };
      })
    : [
        {
          id: 1,
          projectName: "Snow",
          facebookId: "Jon",
          twitterId: "twitterId",
          linkedInId: "linkedInId",
          instagramId: "instagramId",
          analyticsCode: "analyticsCode",
        },
        {
          id: 2,
          projectName: "Snow",
          facebookId: "Jon",
          twitterId: "twitterId",
          linkedInId: "linkedInId",
          instagramId: "instagramId",
          analyticsCode: "analyticsCode",
        },
        {
          id: 3,
          projectName: "Snow",
          facebookId: "Jon",
          twitterId: "twitterId",
          linkedInId: "linkedInId",
          instagramId: "instagramId",
          analyticsCode: "analyticsCode",
        },
        {
          id: 4,
          projectName: "Snow",
          facebookId: "Jon",
          twitterId: "twitterId",
          linkedInId: "linkedInId",
          instagramId: "instagramId",
          analyticsCode: "analyticsCode",
        },
        {
          id: 5,
          projectName: "Snow",
          facebookId: "Jon",
          twitterId: "twitterId",
          linkedInId: "linkedInId",
          instagramId: "instagramId",
          analyticsCode: "analyticsCode",
        },
      ];

  return (
    <div className="">
      {loading ? (
        <Loader />
      ) : (
        // : emptyProject || appFlowData.length === 0 ? (
        // <Grid
        //   sx={{
        //     height: "87.5vh",
        //     display: "flex",
        //     justifyContent: "center",
        //     alignItems: "center",
        //     flexDirection: "column",
        //     position: "relative",
        //     overflow: "hidden",
        //   }}>
        //   <Grid
        //     width={"80vw"}
        //     height={"80%"}
        //     sx={{
        //       padding: "2vh 0 2vh 3vw",
        //       borderRadius: "8px",
        //       backdropFilter: "blur(25px)",
        //       boxShadow: 20,
        //       zIndex: 20,
        //     }}>
        //     <Grid
        //       xs={12}
        //       sx={{
        //         display: "flex",
        //         alignItems: "center",
        //         justifyContent: "space-between",
        //       }}>
        //       <Typography
        //         sx={{ fontFamily: "Mont-SemiBold", fontSize: "1.5rem" }}>
        //         Projects Created
        //       </Typography>
        //       <Grid
        //         xs={9}
        //         sx={{ backgroundColor: "red", height: "0.6vh" }}></Grid>
        //     </Grid>
        //     <Grid sx={{ display: "flex", justifyContent: "center", mt: 6 }}>
        //       <img src={errorSymbol} style={{ height: "30vh" }} />
        //     </Grid>
        //     <Typography
        //       sx={{
        //         textAlign: "center",
        //         mt: 4,
        //         fontSize: "1.2rem",
        //         fontFamily: "Mont-SemiBold",
        //       }}>
        //       Sorry! No Projects have been created yet.{" "}
        //     </Typography>
        //     <Grid sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
        //       <Button
        //         onClick={createProjectHandler}
        //         variant="contained"
        //         sx={{
        //           backgroundColor: "white",
        //           color: "black",
        //           fontFamily: "Mont-Regular",
        //         }}>
        //         <span style={{ marginRight: "1vw" }}>Create New Project</span>
        //         <img src={btnGrp} style={{ height: "4vh" }} />
        //       </Button>
        //     </Grid>
        //   </Grid>
        //   <Grid sx={{ position: "absolute", bottom: "-80vh", right: "-20vw" }}>
        //     <img src={lightBubble} style={{ height: "160vh" }} />
        //   </Grid>
        //   <Grid sx={{ position: "absolute", top: "-12vh", right: "0" }}>
        //     <img src={errorWave} style={{ height: "180vh" }} />
        //   </Grid>
        // </Grid>
        // )
        <Grid
          sx={{ position: "relative", overflow: "hidden", height: "100vh" }}>
          <Grid
            sx={{
              position: "absolute",
              top: "-12vh",
              left: "-30vw",
              zIndex: "-10",
            }}>
            <img style={{ width: "55vw", height: "90%" }} src={darkBubble} />
          </Grid>

          <Grid sx={{ position: "absolute", bottom: "-60%", right: "-15%" }}>
            <img style={{ width: "55vw", height: "90%" }} src={lightBubble} />
          </Grid>

          <Grid
            sx={{
              display: "flex",
              justifyContent: "center",
              paddingTop: "5vh",
            }}>
            <Box
              sx={{
                minHeight: "67vh",
                width: "90%",
                backdropFilter: "blur(25)",
                backgroundColor: "",
              }}>
              <Grid
                xs={12}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
                container>
                <Typography
                  sx={{ fontFamily: "Mont-SemiBold", fontSize: "1.8rem" }}>
                  Dynamic Routes Created
                </Typography>
                <Grid
                  xs={9}
                  sx={{ height: "1.5vh", backgroundColor: "red" }}
                  item></Grid>
              </Grid>
              <Grid
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  mt: 1,
                  marginBottom: "3vh",
                }}>
                  <Button
                  onClick={() =>
                    viewCronHandler(project)
                  }
                  variant="contained"
                  sx={{
                    backgroundColor: "red",
                    color: "white",
                    fontFamily: "Mont-Regular",
                    "&:hover": { backgroundColor: "white", color: "red" },
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    right : "5px"
                  }}>
                  <span style={{ marginRight: "1vw" }}>Crons</span>
                  <ScheduleIcon />
                </Button>
                <Button
                  onClick={() =>
                    navigate(`/create_new_route/${project}?create_new`)
                  }
                  variant="contained"
                  sx={{
                    backgroundColor: "red",
                    color: "white",
                    fontFamily: "Mont-Regular",
                    "&:hover": { backgroundColor: "white", color: "red" },
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}>
                  <span style={{ marginRight: "1vw" }}>Create New Route</span>
                  <img src={btnGrp} style={{ height: "4vh" }} />
                </Button>
              </Grid>

              <DataGrid
                rows={rows}
                columns={columns}
                pageSize={10}
                // error={"No table Data Found"}
                rowsPerPageOptions={[10]}
                checkboxSelection
                disableSelectionOnClick
                initialState={{
                  sorting: {
                    sortModel: [{ field: "lastEdit", sort: "desc" }],
                  },
                }}
              />
            </Box>
          </Grid>
        </Grid>
      )}

      <Modal
        open={open}
        onClose={() => setOpen(false)}
        sx={{
          border: "none",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}>
        <Box
          sx={{
            width: { lg: "80vw" },
            padding: "2vh 2vw 6vh 2vw",
            outline: "none",
            borderRadius: "6px",
            backgroundColor: "white",
            position: "relative",
          }}>
          <Grid sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              onClick={() => setOpen(false)}
              sx={{ boxShadow: 2, color: "red", "&:hover": { boxShadow: 5 } }}>
              <CloseIcon />
            </Button>
          </Grid>
          <Grid
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mt: 2,
            }}>
            <Typography
              sx={{ fontFamily: "Mont-SemiBold", fontSize: "1.3rem" }}>
              Update Project
            </Typography>
            <Grid
              item
              xs={10.2}
              sx={{ backgroundColor: "red", height: "1vh" }}></Grid>
          </Grid>

          <br />

          {/* bootstrap tabs and its classes */}
          <ul className="nav nav-tabs" id="myTab" role="tablist">
            <li className="nav-item" role="presentation">
              <button
                className="nav-link active"
                id="project-name-tab"
                data-bs-toggle="tab"
                data-bs-target="#project-name-tab-pane"
                type="button"
                role="tab"
                aria-controls="project-name-tab-pane"
                aria-selected="true">
                Project Name
              </button>
            </li>

            <li className="nav-item" role="presentation">
              <button
                className="nav-link"
                id="project-base-urls-tab"
                data-bs-toggle="tab"
                data-bs-target="#project-base-urls-tab-pane"
                type="button"
                role="tab"
                aria-controls="project-base-urls-tab-pane"
                aria-selected="false">
                Project Base URLs
              </button>
            </li>

            <li className="nav-item" role="presentation">
              <button
                className="nav-link"
                id="project-analytics-tab"
                data-bs-toggle="tab"
                data-bs-target="#project-analytics-tab-pane"
                type="button"
                role="tab"
                aria-controls="project-analytics-tab-pane"
                aria-selected="false">
                Project Analytics
              </button>
            </li>

            <li className="nav-item" role="presentation">
              <button
                className="nav-link"
                id="social-media-ids-tab"
                data-bs-toggle="tab"
                data-bs-target="#social-media-ids-tab-pane"
                type="button"
                role="tab"
                aria-controls="social-media-ids-tab-pane"
                aria-selected="false">
                Social Media IDs
              </button>
            </li>

            <li className="nav-item" role="presentation">
              <button
                className="nav-link"
                id="project-chatbot-tab"
                data-bs-toggle="tab"
                data-bs-target="#project-chatbot-tab-pane"
                type="button"
                role="tab"
                aria-controls="project-chatbot-tab-pane"
                aria-selected="false">
                Project Chatbot
              </button>
            </li>
          </ul>
          <br />
          <div className="tab-content" id="myTabContent">
            {/* <div
              className="tab-pane fade show active"
              id="project-name-tab-pane"
              role="tabpanel"
              aria-labelledby="project-name-tab"
              tabindex="0">
              <TextField
                disabled
                onChange={(e) => setProjectName(e.target.value)}
                id="outlined-basic"
                label="Project Name"
                variant="outlined"
                sx={{ width: "36vw", color: "gray" }}
                title="changing projects' names is disabled temporarily"
                defaultValue={modalData?.project_name}
              />
            </div> */}
          </div>
          <br />
          <Button
            // onClick={}
            variant="contained"
            sx={{
              backgroundColor: "red",
              "&:hover": { color: "red", backgroundColor: "white" },
            }}>
            Save Changes
          </Button>
        </Box>
      </Modal>

      {/* USER TOKEN MODAL */}
      <Modal
        open={showUserTokenModal}
        onClose={() => setShowUserTokenModal(false)}
        sx={{
          border: "none",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}>
        <Box
          sx={{
            width: { lg: "80vw" },
            padding: "2vh 2vw 6vh 2vw",
            outline: "none",
            borderRadius: "6px",
            backgroundColor: "white",
            position: "relative",
          }}>
          <Grid sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              onClick={() => setShowUserTokenModal(false)}
              sx={{ boxShadow: 2, color: "red", "&:hover": { boxShadow: 5 } }}>
              <CloseIcon />
            </Button>
          </Grid>
          <Grid
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mt: 2,
            }}>
            <Typography
              sx={{ fontFamily: "Mont-SemiBold", fontSize: "1.3rem" }}>
              Get User Token's Based On User Info
            </Typography>
            <Grid
              item
              xs={8.5}
              sx={{ backgroundColor: "red", height: "1vh" }}></Grid>
          </Grid>

          <Grid
            sx={{
              mt: 2,
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              mb: 3,
            }}>
            <FormControl sx={{ width: "50%" }}>
              <InputLabel id="UserKey">Select Key To Be Displayed</InputLabel>
              <Select
                labelId="UserKey"
                id="UserKey"
                value={selectedUserKey}
                label=" Select Key To Be Displayed"
                onChange={(e) => setSelectedUserKey(e.target.value)}>
                {userKeysDropdown.length
                  ? userKeysDropdown.map((x, index) => (
                      <MenuItem value={x} key={index}>
                        {x}
                      </MenuItem>
                    ))
                  : ""}
              </Select>
            </FormControl>

            <TextField
              onChange={(e) => {
                setSearchedUserKey(e.target.value);
              }}
              value={searchedUserKey}
              id="userKeySearch"
              label="Search A User Based On Selected Key..."
              variant="outlined"
              sx={{ width: "50%", ml: 4 }}
            />
          </Grid>

          <Grid
            sx={{
              mt: 2,
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              mb: 3,
            }}>
            <Button
              onClick={() => getGeneratedTokens()}
              variant="contained"
              sx={{
                backgroundColor: "red",
                "&:hover": { color: "red", backgroundColor: "white" },
              }}>
              Get Tokens
            </Button>
          </Grid>

          <Divider />

          <Grid
            sx={
              userKeyAndTokenData.length
                ? {
                    height: "400px",
                    overflow: "scroll",
                    display: "block",
                    mt: "1.5rem",
                  }
                : { display: "none" }
            }>
            <DataGrid
              rows={tokenModalRows}
              columns={tokenModalColumns}
              pageSize={10}
              // error={"No table Data Found"}
              rowsPerPageOptions={[10]}
              disableSelectionOnClick
            />
          </Grid>
        </Box>
      </Modal>
    </div>
  );
}

export default DynamicRouteLb;
