import React, { useState, useEffect } from 'react'
// import './style.css'
// import darkBubble from './component_assets/images/darkBubble.png'
// import darkBubble from './component_assets/images/details_close.png'
// import lightBubble from './component_assets/images/details_close.png'
// import lightBubble from './component_assets/images/lightBubble.png'

import fileImage from './component_assets/images/fileImage.png'
import redSquare from './component_assets/images/redSquare.png'
import cyanSquare from './component_assets/images/cyanSquare.png'
import { ToastContainer } from "react-toastify";
import { logout } from '../Redux/Actions/userActions';
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"
import axios from 'axios'
import { API_HOST } from '../api_utils'
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";

import classes from './ScreensStylings/AddNewPageScreen.module.css';


function AddNewPageScreen() {
    let navigate = useNavigate()
    let dispatch = useDispatch()
    const { projectId } = useParams()

    const { userInfo } = useSelector(state => state.userLogin)
    const is_mobile = JSON.parse(localStorage.getItem('is_mobile'))


    // states 
    const [pageName, setPageName] = useState('')
    const [seo, setSeo] = useState('')
    const [metaDesc, setMetaDesc] = useState('')
    const [metaKey, setMetaKey] = useState('')
    const [metaTag, setMetaTag] = useState('')
    const [createdPage, setCreatedPage] = useState()


    const errorTheme = {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
    }

    const createPageHandler = async () => {
        if (pageName && seo && metaDesc && metaKey && metaTag) {
            try {

                const pageData = { projectId: projectId, name: pageName, seo_title: seo, meta_description: metaDesc, meta_keywords: metaKey, meta_tags: metaTag, is_home: true, is_mobile: is_mobile }
                const config = {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${userInfo.token}`
                    }
                }
                const response = await axios.post(`${API_HOST}pages/`, pageData, config);
                
                if(response.data.message === "First character of page name cannot be a number"){
                    toast.error(`${response.data.message}`, errorTheme);
                    //alert(response.data.message);
                }else {
                    setCreatedPage(response.data)
                    toast.success(`Page Successfully Created`, errorTheme)
                }
                
            } catch (error) {
                toast.error(`${error.response.data.message}`, errorTheme)
            }
        } else if (!pageName && !seo && !metaDesc && !metaKey && !metaTag) {
            toast.error("fields can't be empty !!!", errorTheme)
        }
    }

    const logOutHandler = () => {
        dispatch(logout())
    }

    const cancelHandler = () => {
        navigate('/pageList')
    }

    useEffect(() => {
        if (createdPage) {
            navigate(`/project_page/${projectId}`)
        }
    }, [createdPage])



    // return (
    //     <div>
    //         <div className="flex-container">


    //             <div className="hero">

    //                 <img className="login-d-bb" src={darkBubble} />


    //                 <img className="login-l-bb" src={lightBubble} />



    //                 <div className="profile-wrapper-form">
    //                     <div className="morph-div-profile">
    //                         <p className="morph-project-text">{`Projects > New Page`}</p>
    //                         <div className="morph-header-project">
    //                             <p className="morph-head-text">ADD NEW PAGE</p>
    //                             <p className="morph-head-project"></p>
    //                         </div>

    //                         <div className="wrap-project-form">
    //                             <form className="project-form">
    //                                 <div className="user-form-first">
    //                                     <div className="form-element">
    //                                         <p>
    //                                             <label for="firstName">Page Title</label>
    //                                         </p>
    //                                         <input value={pageName} onChange={(e) => setPageName(e.target.value)} id="firstName" placeholder="Add Page Name" />
    //                                     </div>
    //                                     <div className="form-element">
    //                                         <p>
    //                                             <label for="lastName">SEO Title</label>
    //                                         </p>
    //                                         <input
    //                                             type="text"
    //                                             id="lastName"
    //                                             placeholder="Add SEO Title"
    //                                             onChange={(e) => setSeo(e.target.value)}
    //                                             value={seo}
    //                                         />
    //                                     </div>
    //                                 </div>

    //                                 <div className="user-form-first" style={{ marginTop: "8px" }}>
    //                                     <div className="form-element">
    //                                         <p>
    //                                             <label for="date">Meta Description</label>
    //                                         </p>
    //                                         <input
    //                                             type="text"
    //                                             id="date"
    //                                             placeholder="Meta Description"
    //                                             onChange={(e) => setMetaDesc(e.target.value)}
    //                                             value={metaDesc}
    //                                         />
    //                                     </div>
    //                                     <div className="form-element">
    //                                         <p>
    //                                             <label for="gender">Meta Keywords</label>
    //                                         </p>
    //                                         <input
    //                                             type="text"
    //                                             id="gender"
    //                                             placeholder="Meta Keywords"
    //                                             onChange={(e) => setMetaKey(e.target.value)}
    //                                             value={metaKey}
    //                                         />
    //                                     </div>
    //                                 </div>

    //                                 <div className="user-form-first" style={{ marginTop: "8px" }}>
    //                                     <div className="form-element">
    //                                         <p>
    //                                             <label for="email">Meta Tags</label>
    //                                         </p>
    //                                         <input id="email" type="text" placeholder="Meta Tags" onChange={(e) => setMetaTag(e.target.value)} value={metaTag} />

    //                                     </div>
    //                                 </div>
    //                             </form>
    //                             <div className="project-form-last" style={{ marginTop: "8px" }}>
    //                                 <div className="submit-div-project">
    //                                     <button onClick={createPageHandler} style={{ padding: '1rem 3rem' }} className="project-form-submit">
    //                                         Create Page
    //                                     </button>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //         </div>
    //     </div>
    // );




    return (
        <div className={classes['wrapper']}>
            <img className={classes['top-redSquare']} src={redSquare} />
            <img className={classes['top-cyanSquare']} src={cyanSquare} />

            <img className={classes['bottom-redSquare']} src={redSquare} />
            <img className={classes['bottom-cyanSquare']} src={cyanSquare} />

            <div className={classes['header']}>
                <KeyboardBackspaceIcon onClick={() => navigate(-1)} sx={{ mt: 2, color: "gray", cursor: "pointer", zIndex: "100", "&:hover": { color: "orange" } }} />
                <button className={classes['red-gradient-button']} onClick={logOutHandler}>Logout</button>
            </div>

            <br/>

            <section className={classes['add-new-page-form-section']}>
                <div className={classes['form-header']}>
                    adding a new page
                    <img src={fileImage} />
                </div>

                <form className={classes['add-new-page-form']}>
                    
                    <div className={classes['add-new-page-form-field']}>
                        <label className={classes['add-new-page-form-field-label']} htmlFor="firstName">Page Title <span className={classes['required-field']}>(Required)</span></label>
                        <input
                            type="text"
                            id="firstName"
                            placeholder="Enter your page's name"
                            onChange={(e) => setPageName(e.target.value)}
                            value={pageName}
                            required
                            autoFocus
                        />
                    </div>
                    
                    <div className={classes['add-new-page-form-field']}>
                        <label className={classes['add-new-page-form-field-label']} htmlFor="lastName">SEO'ed Title <span className={classes['required-field']}>(Required)</span></label>
                        <input
                            type="text"
                            id="lastName"
                            placeholder="Enter your SEO'ed title"
                            onChange={(e) => setSeo(e.target.value)}
                            value={seo}
                        />
                    </div>
                    
                    <div className={classes['add-new-page-form-field']}>
                        <label className={classes['add-new-page-form-field-label']} htmlFor="date">Meta Description <span className={classes['required-field']}>(Required)</span></label>
                        <input
                            type="text"
                            id="date"
                            placeholder="Enter page content description"
                            onChange={(e) => setMetaDesc(e.target.value)}
                            value={metaDesc}
                        />
                    </div>
                    
                    <div className={classes['add-new-page-form-field']}>
                        <label className={classes['add-new-page-form-field-label']} htmlFor="gender">Meta Keywords <span className={classes['required-field']}>(Required)</span></label>
                        <input
                            type="text"
                            id="gender"
                            placeholder="Meta Keywords"
                            onChange={(e) => setMetaKey(e.target.value)}
                            value={metaKey}
                        />
                    </div>
                    
                    <div className={classes['add-new-page-form-field']}>
                        <label className={classes['add-new-page-form-field-label']} htmlFor="email">Other Meta Tags <span className={classes['required-field']}>(Required)</span></label>
                        <input
                            type="text"
                            id="email"
                            placeholder="Enter other meta tags"
                            onChange={(e) => setMetaTag(e.target.value)}
                            value={metaTag}
                        />
                    </div>

                </form>

                <div className={classes['form-footer']}>
                    <button className={classes['red-gradient-button']} onClick={createPageHandler}>create page</button>
                    <button className={classes['white-gradient-button']} onClick={cancelHandler}>cancel</button>
                </div>
            </section>
            <ToastContainer autoClose={3000} />
        </div>
    );
}

export default AddNewPageScreen