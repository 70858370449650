import React from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

import { Container } from './components/Container'

export default function Test2index() {
  return (
    <div className='Test2Index'>
        <DndProvider backend={HTML5Backend}>
                <Container />
        </DndProvider>
    </div>
  )
}
