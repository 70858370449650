import React, { useState, useEffect } from 'react'
import darkBubble from './component_assets/images/darkBubble.png'
// import darkBubble from './component_assets/images/details_close.png'
// import lightBubble from './component_assets/images/details_close.png'
import lightBubble from './component_assets/images/lightBubble.png'
import { useNavigate, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"
import { Tooltip } from '@mui/material'
import axios from 'axios'
import { API_HOST } from '../api_utils'
import { Button, Grid, Typography, Modal, TextField, Select, MenuItem } from '@mui/material'

function CreateCronJob() {

    let navigate = useNavigate()

    const { userInfo } = useSelector(state => state.userLogin)
    const { projectId } = useParams()
    const [userList, setUserList] = useState([])
    const [jobName, setJobName] = useState('')
    const [frequencyCount, setFrequencyCount] = useState('')
    const [jobType, setJobType] = useState('')
    const [frequency, setFrequency] = useState('')
    const [functionList, setFunctionList] = useState([])
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')
    const [selectedFunction, setSelectedFunction] = useState('')

    const errorTheme = {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
    }

    const createCronJobHandler = async () => {
        if (jobName.length && jobType.length && jobType !== 'none' && startDate && endDate && frequency.length && frequencyCount.length) {

            if (selectedFunction.length && selectedFunction !== 'none') {
                const cronJobData = { name: jobName, type_of_job: jobType, project: projectId, start_date: startDate,frequency: frequency,frequency_count: frequencyCount, end_date: endDate, function_to_be_executed: selectedFunction }
                const config = {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${userInfo.token}`
                    }
                }
                const { data } = await axios.post(`${API_HOST}cron_job/`, cronJobData, config)
                if (data.message) {
                    toast.error(data.message, errorTheme)
                } else {
                    navigate(`/cronJob/${projectId}`)
                }
            } else {
                const cronJobData = { name: jobName, type_of_job: jobType,frequency: frequency,frequency_count: frequencyCount, project: projectId, start_date: startDate, end_date: endDate }
                const config = {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${userInfo.token}`
                    }
                }
                const { data } = await axios.post(`${API_HOST}cron_job/`, cronJobData, config)
                if (data.message) {
                    toast.error(data.message, errorTheme)
                } else {
                    navigate(`/cronJob/${projectId}`)
                }
            }


        } else {
            toast.warn('Job Name And Type Is Required !!!', errorTheme)
        }
    }

    const getCustomFunctions = async () => {
        const userInfo = JSON.parse(localStorage.getItem('userInfo'))
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        let payload = {
            project: projectId
        }

        const { data } = await axios.post(`${API_HOST}custom_functions/`, payload, config)
        if (data.message) {
            toast.error(data.message)
        } else {
            setFunctionList(data)
        }
    }

    useEffect(() => {
        getCustomFunctions()
    }, [])


    return (
        <div>
            <div className="flex-container">

                <div className="hero">

                    <img className="login-d-bb" src={darkBubble} />


                    <img className="login-l-bb" src={lightBubble} />

                    <div className="profile-wrapper-form">
                        <div className="morph-div-profile">
                            <p className="morph-project-text">{`Projects `}</p>
                            <div className="morph-header-project">
                                <p style={{ fontSize: '1.8rem' }} className="morph-head-text">Add Cron Job</p>
                                <p className="morph-head-project"></p>
                            </div>

                            <div style={{ marginTop: '2rem', padding: '0  2rem' }}>
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <div >
                                        <p>
                                            <label style={{ fontFamily: "Mont-Regular", fontSize: '1.2rem' }} for="firstName">Name</label>
                                        </p>

                                        <input style={{ outline: 'none', borderRadius: '8px', padding: '0.5rem', border: '1px solid gray', width: '30rem' }} value={jobName} onChange={(e) => setJobName(e.target.value)} placeholder="Enter Cron Job Name" />

                                    </div>
                                    <div >
                                        <p>
                                            <label style={{ fontFamily: "Mont-Regular", fontSize: '1.2rem' }}>Type Of Job</label>
                                        </p>
                                        <Select value={jobType} onChange={(e) => setJobType(e.target.value)} sx={{ width: '30rem', height: '3rem' }} >
                                            <MenuItem value="none">None</MenuItem>
                                            <MenuItem value="time-based">Time Based</MenuItem>
                                            <MenuItem value="event-based">Event Based</MenuItem>
                                        </Select>
                                    </div>
                                </div>

                                <div style={{marginTop: "8px",  display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <div>
                                        <p>
                                            <label style={{ fontFamily: "Mont-Regular", fontSize: '1.2rem' }}>Frequency</label>
                                        </p>
                                        <Select value={frequency} onChange={(e) => setFrequency(e.target.value)} sx={{ width: '30rem', height: '3rem' }} >
                                            <MenuItem value="none">None</MenuItem>
                                            <MenuItem value="hourly">hourly</MenuItem>
                                            <MenuItem value="daily">daily</MenuItem>
                                            <MenuItem value="weekly">weekly</MenuItem>
                                            <MenuItem value="monthly">monthly</MenuItem>
                                        </Select>
                                    </div>
                                    <div >
                                        <p>
                                            <label style={{ fontFamily: "Mont-Regular", fontSize: '1.2rem' }} for="frequencyCount">Frequency Count</label>
                                        </p>

                                        <input type="number" style={{ outline: 'none', borderRadius: '8px', padding: '0.5rem', border: '1px solid gray', width: '30rem' }} value={frequencyCount} onChange={(e) => setFrequencyCount(e.target.value)} placeholder="Enter Frequency Count" />

                                    </div>
                                </div>

                                <div style={{ marginTop: "8px", display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    {/* <div >
                                        <p>
                                            <label style={{ fontFamily: "Mont-Regular", fontSize: '1.2rem' }} for="date">Custom Functions</label>
                                        </p>
                                        <Select value={selectedFunction} onChange={(e) => setSelectedFunction(e.target.value)} sx={{ width: '30rem', height: '3rem' }} >
                                            <MenuItem value="none">None</MenuItem>
                                            {
                                                functionList.length ? functionList.map((x) => <MenuItem value={x._id}>{x.name}</MenuItem>) : ''
                                            }

                                        </Select>
                                    </div> */}

                                    <div >
                                        <p>
                                            <label style={{ fontFamily: "Mont-Regular", fontSize: '1.2rem' }} for="date">Start Date and Time</label>
                                        </p>
                                        <Tooltip title="add later" arrow>
                                            <input
                                                type="datetime-local"
                                                id="date"
                                                style={{ outline: 'none', borderRadius: '8px', padding: '0.5rem', border: '1px solid gray', width: '30rem' }}
                                                placeholder="Start Date"
                                                value={startDate}
                                                onChange={(e) => setStartDate(e.target.value)}
                                            />
                                        </Tooltip>
                                    </div>
                                    <div >
                                        <p>
                                            <label style={{ fontFamily: "Mont-Regular", fontSize: '1.2rem' }} for="date">End Date and Time</label>
                                        </p>
                                        <Tooltip title="add later" arrow>
                                            <input
                                                type="datetime-local"
                                                id="date2"
                                                style={{ outline: 'none', borderRadius: '8px', padding: '0.5rem', border: '1px solid gray', width: '30rem' }}
                                                placeholder="End Date"
                                                value={endDate}
                                                onChange={(e) => setEndDate(e.target.value)}
                                            />
                                        </Tooltip>
                                    </div>

                                </div>

                                <div style={{ marginTop: "8px", display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>

                                    

                                </div>

                                <div style={{ marginTop: '4rem', width: '95%' }}>
                                    <button onClick={createCronJobHandler} style={{ padding: '1rem 3rem' }} className="project-form-submit">
                                        {`Create Cron Job`}
                                    </button>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default CreateCronJob