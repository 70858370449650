// reference - https://www.youtube.com/watch?v=8HDZmKU0Dy8

// NOTE - This approach works for all charts except doughnut and pie charts

const DrillDownBarChartV2 = (editor, options) => {
	console.log('options >>', options);

    const Chart = require("chart.js");

	// adding its block in the block manager
	editor.Blocks.add('drill-down-bar-chart', {
		label: "Drill-Down Bar Chart",
        category: "Charts",
        select: true,
        media: '<svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" viewBox="0 0 16 16"><path d="M10 13.5a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-6a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v6zm-2.5.5a.5.5 0 0 1-.5-.5v-4a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-.5.5h-1zm-3 0a.5.5 0 0 1-.5-.5v-2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5h-1z"/><path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5v2z"/></svg>',
        content: `<canvas id="drill-down-bar-chart" width="100" height="100" data-gjs-type="drill-down-bar-chart"></canvas>`,
	});

	if (editor !== null && editor !== undefined) {
	    const defaultType = editor.DomComponents.getType("default");
	    const defaultModel = defaultType.model;
	    const defaultView = defaultType.view;

	    editor.DomComponents.addType("drill-down-bar-chart", {
	        model: {
		        defaults: {
		            script: function (props) {
			            const initLib = async function () {
			            	// helper function to check if a variable is an array-of-objects or not
			            	function isArrayofObjects(variable) {
								// Check if the variable is an array
								if (!Array.isArray(variable)) {
								    return false;
								}

								// Check if all elements in the array are objects
								for (const element of variable) {
								    if (typeof element !== 'object' || Array.isArray(element) || element === null) {
								      return false;
								    }
								}

								return true;
							}

			                if (props.Graph_API && props.BearerToken) {
				                try {
				                    console.log("props:", props);

				                    // this will track the number of clicks and will reset to 0 once the reset button is clicked. the purpose of this is to call the api from the array of apis by a certain number of click
				                    let clickCount = 0;
				                    console.log("clickCount:", clickCount);

				                    // const labels = [
				                    //   "January",
				                    //   "February",
				                    //   "March",
				                    //   "April",
				                    //   "May",
				                    //   "June",
				                    // ];

				                    // ex - https://...,https://...,
				                    const apis = props.Graph_API.split(',');
				                    const abscissas = props.Abscissa.split(',');
				                    const ordinates = props.Ordinate.split(',');
				                    const chartTypes = props.ChartTypes.split(',');

				                    let apiDATA;
				                    // first plot the chart for initial data (clickCount = 0)
				                    const response = await fetch(`${apis[clickCount]}`, {
				                    	headers: {
				                    		"Content-type": "application/json",
                                			Authorization: `Bearer ${props.BearerToken}`,
				                    	},
				                    });
				                    const responseData = await response.json();
				                    apiDATA = responseData;

				                	const coordinates = {
				                		top: 0,
				                		bottom: 0,
				                		left: 0,
				                		right: 0,
				                	};
				                    // const browserData = [{x: 10, y: 20, z: [{a: 1, b: 2}, {a: 1, b: 2}]}, {x: 15, y: null, z: [{a: 1, b: 2}, {a: 1, b: 2}]}, {x: 20, y: 10, z: [{a: 1, b: 2}, {a: 1, b: 2}]}];

				                    const data = {
					                    // x-axis
					                    // labels: xAxisData,
					                    datasets: [
					                        {
					                        	label: 'toy data',
						                        // label: props.Abscissa,
						                        // backgroundColor: ['red', 'green', 'blue'],
						                        // backgroundColor: !props.Bar_Colors ? ["red", "green", "blue"] : props.Bar_Colors.split(","),
						                        // borderColor: !props.Border_Color ? "rgb(255, 99, 132)" : props.Border_Color,
						                        // borderWidth: !props.Border_Width ? 2 : props.Border_Width,
						                        // y-axis
						                        data: isArrayofObjects(apiDATA) ? apiDATA : '',
						                        // color: ['red', 'green', 'blue'],
					                        },
					                    ],
				                    };

				                    // drawing reset button in the chart canvas
				                    const resetButton = {
				                    	id: 'resetButton',
				                    	beforeDraw(chart, args, options) {
				                    		// so that the back button doesn't show in first chart screen
				                    		if (myChart.config.data.datasets[0].label !== 'toy data') {
					                    		const { ctx, chartArea: {top, bottom, left, right, width, height} } = chart;
					                    		ctx.save();

					                    		// the button
					                    		const text = 'Back';
					                    		const textWidth = ctx.measureText(text).width;
					                    		const thickBorder = 3;

					                    		// Note - draw the background before the text so that in the UI the background has a z-index after the text and so that the text comes in front of the background fill and not get hidden
					                    		// draw the background, of the button
					                    		ctx.fillStyle = 'rgba(255, 26, 104, 0.2)';
					                    		ctx.fillRect(right - (textWidth + 1 + 10), 5, textWidth + 10, 20);

					                    		// draw the text, into the button
					                    		ctx.fillStyle = '#666';
					                    		ctx.font = '12px Arial';

					                    		// ctx.fillText(textname, xLeft, yTop);
					                    		// ctx.fillText('text', 600, 15);
					                    		ctx.fillText(text, right - (textWidth + 1 + 5), 15);

					                    		// draw the border
					                    		ctx.lineWidth = thickBorder + 'px';
					                    		ctx.strokeStyle = 'rgba(255, 26, 104, 1)';

					                    		// ctx.strokeRect(x, y, xWidth, yWidth);
					                    		// ctx.strokeRect(600, 5, 100, 15);
					                    		ctx.strokeRect(right - (textWidth + 1 + 10), 5, textWidth + 10, 20);

					                    		coordinates.top = 5;
					                    		coordinates.bottom = 25;
					                    		coordinates.left = right - (textWidth + 1 + 10);
					                    		coordinates.right = right;

					                    		// so that when the dimension of the chart change the value of top, bottom, etc will also change so everytime they need to be restored
					                    		ctx.restore();
					                    	}
				                    	},
				                    };

				                    const config = {
					                    type: "bar",
					                    data: data,
					                    options: {
					                    	onHover: (event, chartEle) => {
					                    		// console.log('chartEle[0]:', chartEle[0]);
					                    		event.native.target.style.cursor = chartEle[0] ? 'pointer' : 'default';
					                    	},
					                    	parsing: {
						                    	xAxisKey: props.Abscissa ? abscissas[clickCount] : '',
						                    	yAxisKey: props.Ordinate ? ordinates[clickCount] : '',
						                    },
						                    // scales: {
						                    // 	y: {
						                    // 		beginsAtZero: true,
						                    // 	},
						                    // },
					                        // stacked: false,
					                        responsive: true,
					                    },

					                    // to draw stuff on chart canvas, use plugins that will load elements before everything else
					                    plugins: [resetButton],
				                    };

				                    const ctx = document.getElementById("drill-down-bar-chart");
				                    const myChart = new Chart(ctx, config);

				                    async function changeChart(value) {
				                    	console.log("value:", value);
				                    	console.log("clickCount (changeChart):", clickCount);

				                    	let apiDATA;
					                    // plot api by clickCount
					                    const response = await fetch(`${apis[clickCount]}`, {
					                    	headers: {
					                    		"Content-type": "application/json",
	                                			Authorization: `Bearer ${props.BearerToken}`,
					                    	},
					                    });
					                    const responseData = await response.json();
					                    apiDATA = responseData;

					                    console.log('apiDATA (changeChart):', apiDATA);
					                    console.log('props (changeChart):', props);

				                    	// re-assigning items
				                    	myChart.config.options.parsing.xAxisKey = props.Abscissa ? abscissas[clickCount] : '';
				                    	myChart.config.options.parsing.yAxisKey = props.Ordinate ? ordinates[clickCount] : '';

				                    	const vUsers = [];
				                    	const vLabels = [];

				                    	apiDATA.forEach(val => {
				                    		vUsers.push(val[`${abscissas[clickCount]}`]);
				                    		vLabels.push(val[`${ordinates[clickCount]}`]);
				                    	});

				                    	console.log('vUsers, vLabels:', vUsers, vLabels);

				                    	// update the chart
				                    	myChart.config.type = (props.ChartTypes && chartTypes.length === apis.length) ? chartTypes[clickCount] : "bar";
				                    	// myChart.config.data.datasets[0].data = isArrayofObjects(apiDATA) ? apiDATA : '';
				                    	myChart.config.data.datasets[0].data = vUsers;
				                    	myChart.config.data.labels = vLabels;
				                    	myChart.update();
				                    }

				                    function clickHandler(event) {
				                    	const bar = myChart.getElementsAtEventForMode(event, 'nearest', { intersect: true }, true);
				                    	console.log('bar:', bar);

				                    	if (bar.length) {
				                    		// increment the clickCount counter evertime a bar/datapoint is clicked
				                    		clickCount += 1;
				                    		// console.log(bar[0].index);
				                    		changeChart(bar[0].index);
				                    	}
				                    }

				                    async function resetChart() {
				                    	console.log('updating chart');
				                    	// reset the clicks also
				                    	clickCount = 0;
				                    	console.log("clickCount (changeChart):", clickCount);

				                    	let apiDATA;
					                    // plot api by clickCount
					                    const response = await fetch(`${apis[clickCount]}`, {
					                    	headers: {
					                    		"Content-type": "application/json",
	                                			Authorization: `Bearer ${props.BearerToken}`,
					                    	},
					                    });
					                    const responseData = await response.json();
					                    apiDATA = responseData;

					                    console.log('apiDATA (changeChart):', apiDATA);
					                    console.log('props (changeChart):', props);

				                    	// re-assigning items
				                    	myChart.config.options.parsing.xAxisKey = props.Abscissa ? abscissas[clickCount] : '';
				                    	myChart.config.options.parsing.yAxisKey = props.Ordinate ? ordinates[clickCount] : '';

				                    	const vUsers = [];
				                    	const vLabels = [];

				                    	apiDATA.forEach(val => {
				                    		vUsers.push(val[`${abscissas[clickCount]}`]);
				                    		vLabels.push(val[`${ordinates[clickCount]}`]);
				                    	});

				                    	console.log('vUsers, vLabels:', vUsers, vLabels);

				                    	// update the chart
				                    	myChart.config.type = (props.ChartTypes && chartTypes.length === apis.length) ? chartTypes[clickCount] : "bar";
				                    	// myChart.config.data.datasets[0].data = isArrayofObjects(apiDATA) ? apiDATA : '';
				                    	myChart.config.data.datasets[0].data = vUsers;
				                    	myChart.config.data.labels = vLabels;
				                    	myChart.update();
				                    }

				                    function mousemoveHandler(ctx, mousemove) {
				                    	const x = mousemove.offsetX;
				                    	const y = mousemove.offsetY;
				                    	// console.log('mousemove, x, y:', mousemove, x, y);
				                    	// console.log('coordinates:', coordinates);

				                    	// if (myChart.config.data.datasets[0].label !== 'toy data') {
					                    	if (x > coordinates.left && x < coordinates.right && y > coordinates.top && y < coordinates.bottom) {
					                    		ctx.style.cursor = 'pointer';
					                    	} else {
					                    		ctx.style.cursor = 'default';
					                    	}
					                    // }
				                    }

				                    function clickButtonHandler(ctx, click) {
				                    	const x = click.offsetX;
				                    	const y = click.offsetY;
				                    	// console.log('click, x, y:', click, x, y);
				                    	// console.log('coordinates:', coordinates);

				                    	if (x > coordinates.left && x < coordinates.right && y > coordinates.top && y < coordinates.bottom) {
				                    		resetChart();
				                    	}
				                    }

				                    ctx.onclick = clickHandler;

				                    // to add event to 'Back' button and change pointer icon
				                    ctx.addEventListener('mousemove', (event) => {
				                    	// so that if somehow the size of the chart is changed by someone, then the pixel values/measurements of the chart elements like hover and clicks also get changed
				                    	myChart.resize();
				                    	mousemoveHandler(ctx, event);
				                    });

				                    // to add event to 'Back' button and chandle actual click
				                    ctx.addEventListener('click', (event) => {
				                    	// so that if somehow the size of the chart is changed by someone, then the pixel values/measurements of the chart elements like hover and clicks also get changed
				                    	myChart.resize();
				                    	clickButtonHandler(ctx, event);
				                    });
				                } catch (e) {
				                    console.log(e);
				                }
				            }
			            };

			            if (typeof Chart == "undefined") {
			                const script = document.createElement("script");
			                script.onload = initLib;
			                script.src = "https://cdn.jsdelivr.net/npm/chart.js";
			                document.body.appendChild(script);
			            } else {
			                console.log("InitLib");
			                initLib();
			            }
		            },

		            Graph_API: "",
		            BearerToken: "",
		            Abscissa: "",
		            Ordinate: "",
		            ChartTypes: "bar",
		            // Fill: "",

		            traits: [
		              {
		                type: "text",
		                name: "Graph_API",
		                label: "Graph API",
		                changeProp: true,
		              },
		              {
		                type: "text",
		                name: "BearerToken",
		                label: "Bearer Token",
		                placeholder: "Bearer",
		                changeProp: true,
		              },
		              {
		                type: "text",
		                name: "Abscissa",
		                label: "Abscissa",
		                changeProp: true,
		              },
		              {
		                type: "text",
		                name: "Ordinate",
		                label: "Ordinates",
		                changeProp: true,
		              },
		              {
		                type: "text",
		                name: "ChartTypes",
		                label: "Chart Types",
		                changeProp: true,
		              },
		              // {
		              //     type: 'checkbox',
		              //     name: 'Fill',
		              //     label: 'Fill Area',
		              //     changeProp: true,
		              // },
		            ],

		            changeProp: true,

		            "script-props": [
		              "Graph_API",
		              "BearerToken",
		              "Abscissa",
		              "Ordinate",
		              "ChartTypes",
		            ],
		        },
	        },

	        isComponent: (el) => {
		        if (el.getAttribute && el.getAttribute("data-gjs-type") == "drill-down-bar-chart") {
		            return "drill-down-bar-chart";
		        }
	        },

	        view: defaultView.extend({
	          	init({ model }) {},
	        }),
	    });
    }
};

export default DrillDownBarChartV2;