const customCardListPlugin = (editor, opts = {}) => {
  editor.DomComponents.addType("listCardHeader", {
    model: {
      defaults: {
        components: [
          {
            type: "text",
            style: {
              width: "100%",
              "font-size": "18px",
            },
            attributes: { contenteditable: "true" },
            content: "Card Title",
          },
          {
            type: "text",
            style: {
              width: "100%",
              "font-size": "12px",
              "margin-top": "1rem",
            },
            attributes: { contenteditable: "true" },
            content: "Card Sub Title",
          },
        ],
      },
    },
  });

  editor.DomComponents.addType("listCardWrapper", {
    model: {
      defaults: {
        components: [
          {
            tagName: "div",
            type: "default",
            style: {
              width: "100%",
              "box-shadow":
                "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
              display: "flex",
              "justify-content": "space-between",
              "align-items": "center",
              "padding-top": "0.5rem",
              "padding-bottom": "0.5rem",
              "padding-left": "0.5rem",
              "padding-right": "1rem",
              "margin-top": "1rem",
            },
            components: [
              {
                type: "image",
                tagName: "img",
                attributes: {
                  src: "https://ionicframework.com/docs/img/demos/card-media.png",
                  alt: "Silhouette of mountains",
                },
                style: {
                  width: "80px",
                  height: "80px",
                },
              },
              {
                type: "text",
                attributes: { contenteditable: "true" },
                content: "List Item",
                style: {
                  "margin-left": "2rem",
                  "flex-grow": "1",
                },
              },
            ],
          },
          {
            tagName: "div",
            type: "default",
            style: {
              position: "relative",
              width: "100%",
              "box-shadow":
                "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
              display: "flex",
              "justify-content": "space-between",
              "align-items": "center",
              "padding-top": "0.5rem",
              "padding-bottom": "0.5rem",
              "padding-left": "0.5rem",
              "padding-right": "1rem",
              "margin-top": "1rem",
            },
            components: [
              {
                type: "image",
                tagName: "img",
                attributes: {
                  src: "https://ionicframework.com/docs/img/demos/card-media.png",
                  alt: "Silhouette of mountains",
                },
                style: {
                  width: "80px",
                  height: "80px",
                },
              },
              {
                type: "text",
                attributes: { contenteditable: "true" },
                content: "List Item",
                style: {
                  "margin-left": "2rem",
                  "flex-grow": "1",
                },
              },
            ],
          },
          {
            tagName: "div",
            type: "default",
            style: {
              position: "relative",
              width: "100%",
              "box-shadow":
                "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
              display: "flex",
              "justify-content": "space-between",
              "align-items": "center",
              "padding-top": "0.5rem",
              "padding-bottom": "0.5rem",
              "padding-left": "0.5rem",
              "padding-right": "1rem",
              "margin-top": "1rem",
            },
            components: [
              {
                type: "image",
                tagName: "img",
                attributes: {
                  src: "https://ionicframework.com/docs/img/demos/card-media.png",
                  alt: "Silhouette of mountains",
                },
                style: {
                  width: "80px",
                  height: "80px",
                },
              },
              {
                type: "text",
                attributes: { contenteditable: "true" },
                content: "List Item",
                style: {
                  "margin-left": "2rem",
                  "flex-grow": "1",
                },
              },
            ],
          },
        ],
      },
    },
  });

  editor.DomComponents.addType("customListCard", {
    model: {
      defaults: {
        // script: listCard,
        tagName: "div",

        traits: [
          {
            type: "text",
            label: "URL",
            name: "url",
            changeProp: true,
          },
          {
            type: "text",
            label: "Bearer Token",
            name: "bearerToken",
            changeProp: true,
          },
          {
            type: "text",
            label: "image from response",
            name: "imageFromResponse",
            changeProp: true,
          },
          {
            type: "text",
            label: "Desc from response",
            name: "descFromResponse",
            changeProp: true,
          },
        ],

        style: {
          padding: "0.5rem",
        },

        "script-props": ["url", "bearerToken", "imageFromResponse", "descFromResponse"],

        components: [
          // HEADER:::::::::::::::
          {
            tagName: "div",
            type: "listCardHeader",
            style: {
              width: "100%",
            },
          },

          // CARD IMAGE AND LIST NAME::::::::::::::::::::
          {
            type: "listCardWrapper",
            tagName: "div",
            style: {
              width: "100%",
              display: "flex",
              "flex-direction": "column",
              "justify-content": "start",
              "align-items": "start",
              "margin-top": "3rem",
              "padding-top": "0.5rem",
              "padding-bottom": "0.5rem",
              "padding-left": "0.5rem",
              "padding-right": "1rem",
            },
          },
        ],
      },
    },
  });

  editor.BlockManager.add("customListCard", {
    label: "List Card",
    content: { type: "customListCard" },
    category: "Draggable Card",
    select: true,
    media: `<svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" viewBox="0 0 16 16">
            <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm15 2h-4v3h4V4zm0 4h-4v3h4V8zm0 4h-4v3h3a1 1 0 0 0 1-1v-2zm-5 3v-3H6v3h4zm-5 0v-3H1v2a1 1 0 0 0 1 1h3zm-4-4h4V8H1v3zm0-4h4V4H1v3zm5-3v3h4V4H6zm4 4H6v3h4V8z"/>
        </svg>`,
    draggable: true,
  });

  editor.on("component:update:traits", async (component) => {
    // console.log("component:::::::::", component);
    if (component && component.attributes.type === "customListCard") {
      const urlTrait = component.getTrait("url");
      const url = urlTrait.attributes.value;
      const bearerTokenTrait = component.getTrait("bearerToken");
      const bearerToken =  bearerTokenTrait.attributes.value;
      const cardDescFromResponse = component.getTrait("descFromResponse");
      const descTarget = cardDescFromResponse.attributes.value;

      const cardImageFromResponse = component.getTrait("imageFromResponse");
      const imageTarget = cardImageFromResponse.attributes.value;

      let imageTargetValue;
      let fieldValues = [];

      const createAndPopulateModal = (responseKeys, responseData) => {
        // Create the modal element
        const modal = document.createElement("div");
        modal.className = "modal";
        modal.style.display = "flex";
        modal.style.justifyContent = "center";
        modal.style.alignItems = "center";
        modal.style.background = "rgba(0, 0, 0, 0.5)";
        modal.style.flexDirection = "column";

        const innerModalWrapper = document.createElement("div");
        innerModalWrapper.style.paddingTop = "1.5rem";
        innerModalWrapper.style.paddingRight = "1.5rem";
        innerModalWrapper.style.paddingLeft = "1.5rem";
        innerModalWrapper.style.paddingBottom = "5rem";
        innerModalWrapper.style.borderRadius = "10px";
        innerModalWrapper.style.backgroundColor = "white";
        innerModalWrapper.style.position = "relative";

        // Create a select element
        const imgWrapper = document.createElement("div");
        imgWrapper.style.display = "flex";
        imgWrapper.style.justifyContent = "center";
        imgWrapper.style.alignItems = "center";
        const labelImg = document.createElement("label");
        labelImg.setAttribute("for", "imgSelect");
        labelImg.innerHTML = `Image Key from response`;
        labelImg.style.width = "15rem";
        labelImg.style.marginRight = "1rem";
        const select = document.createElement("select");
        select.style.width = "20rem";

        // Populate the select element with response data keys
        responseKeys.forEach((key) => {
          const option = document.createElement("option");
          option.value = key;
          option.textContent = key;
          select.appendChild(option);
        });

        imgWrapper.appendChild(labelImg);
        imgWrapper.appendChild(select);

        select.addEventListener("change", (event) => {
          // console.log("image val::::::::::", event.target.value);
          imageTargetValue = event.target.value;
        });

        // Create an input element of type number
        const fieldWrapper = document.createElement("div");
        fieldWrapper.style.display = "flex";
        fieldWrapper.style.justifyContent = "center";
        fieldWrapper.style.alignItems = "center";
        const labelField = document.createElement("label");
        labelField.setAttribute("for", "imgSelect");
        labelField.innerHTML = `No. of text fields required`;
        labelField.style.width = "15rem";
        labelField.style.marginRight = "1rem";
        const inputNumber = document.createElement("input");
        inputNumber.type = "number";
        inputNumber.style.width = "20rem";

        fieldWrapper.appendChild(labelField);
        fieldWrapper.appendChild(inputNumber);

        inputNumber.addEventListener("input", () => {
          const numberOfFields = parseInt(inputNumber.value, 10);

          while (fieldWrapper.children.length > 2) {
            fieldWrapper.removeChild(fieldWrapper.lastChild);
          }

          for (let i = 0; i < numberOfFields; i++) {
            const fieldWrapperNew = document.createElement("div");
            fieldWrapperNew.style.display = "flex";
            fieldWrapperNew.style.justifyContent = "center";
            fieldWrapperNew.style.alignItems = "center";
            const label = document.createElement("label");
            label.setAttribute("for", `select${i}`);
            label.innerHTML = `Select Element ${i + 1}`;
            label.style.width = "15rem";
            label.style.marginRight = "1rem";

            const select = document.createElement("select");
            select.style.width = "20rem";

            responseKeys.forEach((key) => {
              const option = document.createElement("option");
              option.value = key;
              option.textContent = key;
              select.appendChild(option);
            });

            select.addEventListener("change", (event) => {
              // console.log("event:::::", event.target.value);
              fieldValues.push(event.target.value);
            });

            fieldWrapperNew.appendChild(label);
            fieldWrapperNew.appendChild(select);
            innerModalWrapper.appendChild(fieldWrapperNew);
          }
        });

        const buttonWrapper = document.createElement("div");
        buttonWrapper.style.position = "absolute";
        buttonWrapper.style.bottom = "0px";
        buttonWrapper.style.width = "100%";
        buttonWrapper.style.display = "flex";
        buttonWrapper.style.justifyContent = "center";
        buttonWrapper.style.alignItems = "center";
        const buttonField = document.createElement("button");
        buttonField.innerHTML = "Submit";
        buttonField.style.padding = "8px 16px";
        buttonField.style.color = "white";
        buttonField.style.backgroundColor = "#06b6d4";
        buttonField.style.border = "none";

        buttonField.addEventListener("click", () => {
          if (fieldValues.length && imageTargetValue) {
            let cardElementWrapper = component.findType("listCardWrapper")[0];
            cardElementWrapper.components().reset();

            responseData.forEach((rowData, index) => {
              const selectedImageKey = imageTargetValue; // Get the selected image key
              const selectedImageSrc = rowData[selectedImageKey]; // Get the image source from the response data

              const textFields = []; // Array to hold text field components

              // Create text field components based on the selected values
              fieldValues.forEach((selectedValue) => {
                const textContent = rowData[selectedValue];
                textFields.push({
                  type: "text",
                  attributes: { contenteditable: "true" },
                  content: textContent,
                  style: {
                    "margin-top": "5px",
                  },
                });
              });

              const textFieldsWrapper = {
                type: "default",
                tagName: "div",
                style: {
                  display: "flex",
                  "flex-direction": "column",
                  "margin-left": "2rem",
                  "flex-grow": "1",
                },
                components: textFields,
              };

              const cardComponents = [
                {
                  type: "image",
                  tagName: "img",
                  attributes: {
                    src: selectedImageSrc,
                    alt: "Image",
                  },
                  style: {
                    width: "80px",
                    height: "80px",
                  },
                },
                textFieldsWrapper,
              ];

              cardElementWrapper.components().add({
                tagName: "div",
                type: "default",
                style: {
                  width: "100%",
                  "box-shadow":
                    "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
                  display: "flex",
                  "justify-content": "space-between",
                  "align-items": "center",
                  "padding-top": "0.5rem",
                  "padding-bottom": "0.5rem",
                  "padding-left": "0.5rem",
                  "padding-right": "1rem",
                  "margin-top": "1rem",
                },
                components: cardComponents,
              });
            });

            modal.style.display = "none";
          }
        });

        buttonWrapper.appendChild(buttonField);

        innerModalWrapper.appendChild(imgWrapper);
        innerModalWrapper.appendChild(fieldWrapper);
        innerModalWrapper.appendChild(buttonWrapper);
        modal.appendChild(innerModalWrapper);

        document.body.appendChild(modal);
      };

      if (url) {
        try {          
          const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${bearerToken}`
          };

          const response = await fetch(url, {
            method: 'GET',
            headers: headers
          });

          const responseData = await response.json();
          if (
            Array.isArray(responseData) &&
            responseData.length > 0 &&
            typeof responseData[0] === "object"
          ) {
            const responseKeys = Object.keys(responseData[0]);

            createAndPopulateModal(responseKeys, responseData);
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      } else {
        return;
      }
    }
  });
};

export default customCardListPlugin;

// cardElementWrapper.components().reset();

// responseData.forEach((rowData, index) => {
//   cardElementWrapper.components().add({
//     tagName: "div",
//     type: "default",
//     style: {
//       width: "100%",
//       "box-shadow":
//         "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
//       display: "flex",
//       "justify-content": "space-between",
//       "align-items": "center",
//       "padding-top": "0.5rem",
//       "padding-bottom": "0.5rem",
//       "padding-left": "0.5rem",
//       "padding-right": "1rem",
//       "margin-top": "1rem",
//     },
//     components: [
//       {
//         type: "image",
//         tagName: "img",
//         attributes: {
//           src: `${rowData[`${imageTarget}`]}`,
//           alt: "Silhouette of mountains",
//         },
//         style: {
//           width: "80px",
//           height: "80px",
//         },
//       },
//       {
//         type: "text",
//         attributes: { contenteditable: "true" },
//         content: `${rowData[`${imageTarget}`]}`,
//         style: {
//           "margin-left": "2rem",
//           "flex-grow": "1",
//         },
//       },
//     ],
//   });
// });
