import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import { API_HOST } from "../../api_utils";

import {
  GET_BUILD_BY_PROJECT_ID_LOADING,
  GET_BUILD_BY_PROJECT_ID_FAILED,
  GET_BUILD_BY_PROJECT_ID_SUCCESS,
  GET_BUILD_BY_PROJECT_ID_EMPTY,
  GENERATE_BUILD_BY_PROJECT_ID_LOADING,
  GENERATE_BUILD_BY_PROJECT_ID_FAILED,
  GENERATE_BUILD_BY_PROJECT_ID_SUCCESS,
  GENERATE_BUILD_BY_PROJECT_ID_EMPTY,
} from "../Constants/buildConstants";

export const getBuildByProjectId = (projectId) => async (dispatch, getState) => {
  
 
  try {
    dispatch({ type: GET_BUILD_BY_PROJECT_ID_LOADING });
    const {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await axios.get(
      `${API_HOST}GenerateBuild/getByProjectId/${projectId}`,
      config
    );
    console.log("#@*$%#($ data:", data);
    dispatch({ type: GET_BUILD_BY_PROJECT_ID_SUCCESS, payload: data });
  } catch (error) {
    if (error.response.data.message) {
      dispatch({ type: GET_BUILD_BY_PROJECT_ID_EMPTY });
    } else {
      dispatch({
        type: GET_BUILD_BY_PROJECT_ID_FAILED,
        paylaod: error.response.data.message,
      });
    }
  }
};

export const generateBuildByProjectId = (projectId, secretToken, userInfo1) => async (dispatch, getState) => {
  console.log(projectId + secretToken + "token" + userInfo1.token);
  try {
    dispatch({ type: GENERATE_BUILD_BY_PROJECT_ID_LOADING});
    const {
      userLogin: { userInfo },
    }
     = getState();
  
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo1.token}`,
      },
    };
  
    const data  = await axios.post(
      `${API_HOST}GenerateBuild/docker/${secretToken}`,
      { projectId: projectId },
      config
    );
    console.log("generate response1" +JSON.stringify(data));
    if(data){
      dispatch({ type: GENERATE_BUILD_BY_PROJECT_ID_SUCCESS, payload: data });
    }else{
      window.location.reload();
    }
    console.log("generate response" +data);
  } catch (error) {
    console.error("Error:", error);
  }
  
};

export const downloadBuild = (projectId, secretToken, buildId, userInfo1) => async (dispatch, getState) => {
  
  try {
    const {
      userLogin: { userInfo },
    }
     = getState();
     console.log(userInfo1);
     const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo1.token}`,
      },
      responseType: 'blob'
    };
     
      //fetch(`http://ec2-35-171-163-95.compute-1.amazonaws.com:8080/${API_HOST}GenerateBuild/downloadDockerBuild/${secretToken}/${buildId}/${projectId}`,{
        fetch(`${API_HOST}GenerateBuild/downloadDockerBuild/${secretToken}/${buildId}/${projectId}`,config).then((transfer) => {
        return transfer.blob();                 
    }).then((bytes) => {
        let elm = document.createElement('a');  
        elm.href = URL.createObjectURL(bytes);  
        elm.setAttribute('download', `${buildId}.zip`); 
        elm.click()                             
    }).catch((error) => {
        console.log(error);                  
    })

  } catch (error) {
    console.error("Error:", error);
  }
  
  
};

