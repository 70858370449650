import Blockly from "blockly";
import { javascriptGenerator as JavaScript } from "blockly/javascript";
import axios from "axios";
import { API_HOST } from "../../api_utils";
import { Javascript } from "@mui/icons-material";

let tableName = [];
let notificationDetail = [];
let allNotifications = [];
let isRouteScreen = false;

const getAllTables = async (projectId) => {
  const userInfoFromStorage = JSON.parse(localStorage.getItem("userInfo"));

  if (userInfoFromStorage) {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfoFromStorage.token}`,
      },
    };

    const payload = {
      projectId: projectId,
    };

    const { data } = await axios.post(
      `${API_HOST}customroutes/get`,
      payload,
      config
    );

    if (!data.message) {
      let tableNameNew = data.map((x) => {
        return x.table_name;
      });

      tableName = tableNameNew.map((name) => [name, name]);
    }
  } else {
    if (window.location.pathname !== "/login" && !userInfoFromStorage) {
      window.location.pathname = "/login";
    }
  }
};

const getAllNotifications = async (projectId) => {
  const userInfoFromStorage = JSON.parse(localStorage.getItem("userInfo"));

  if (userInfoFromStorage) {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfoFromStorage.token}`,
      },
    };

    const { data } = await axios.get(
      `${API_HOST}mobile_push_config/get_all_config/${projectId}`,
      config
    );

    if (!data.message) {
      let tableNameNew = data.map((x) => {
        return x;
      });

      allNotifications = data;

      notificationDetail = tableNameNew.map((name) => [name.title, name._id]);
    }
  } else {
    if (window.location.pathname !== "/login" && !userInfoFromStorage) {
      window.location.pathname = "/login";
    }
  }
};

setInterval(() => {
  if (isRouteScreen === false) {
    if (window.location.pathname.includes("/create_new_route")) {
      let projectId = window.location.href.split("create_new_route/")[1];
      let newProject = projectId.split("?")[0];
      getAllTables(newProject);
      getAllNotifications(newProject);
      isRouteScreen = true;
    } else {
      isRouteScreen = false;
    }
  }
}, 500);

// CUSTOM BLOCKS
Blockly.Blocks["async_call"] = {
  init: function () {
    this.appendDummyInput().appendField("(req, res) => {");
    this.appendStatementInput("CODE").setCheck(null);
    this.appendDummyInput().appendField("}");
    this.setColour("#f43f5e");
    this.setTooltip("");
    this.setHelpUrl("");
  },
};

JavaScript["async_call"] = function (block) {
  var statements_code = JavaScript.statementToCode(block, "CODE");
  // Generate JavaScript code
  var code = `(req, res) => {\n${statements_code}};\n`;
  return code;
};

Blockly.Blocks["bi_for"] = {
  init: function () {
    //    this.appendDummyInput()
    //        .appendField('for');
    this.appendStatementInput("init").appendField("for init");
    this.appendValueInput("test").appendField("test");
    this.appendStatementInput("update").appendField("update");
    this.appendStatementInput("chain").appendField("loop").setCheck(null);
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    //this.setInputsInline(true);
    this.setColour("#facc15");
    this.setTooltip("");
    this.setHelpUrl("http://www.example.com/");
  },
};

// LOOP BLOCKS

JavaScript["bi_for"] = function (block) {
  Blockly.Generator.prototype.STATEMENT_PREFIX = ", ";
  var statement_init = JavaScript.statementToCode(block, "init");
  Blockly.Generator.prototype.STATEMENT_PREFIX = null;
  var value_test = JavaScript.valueToCode(
    block,
    "test",
    JavaScript.ORDER_ATOMIC
  );
  Blockly.Generator.prototype.STATEMENT_PREFIX = ", ";
  var statement_update = JavaScript.statementToCode(block, "update");
  Blockly.Generator.prototype.STATEMENT_PREFIX = null;
  var statement_chain = JavaScript.statementToCode(block, "chain");
  // g on this REGEX means match all ocurences
  statement_init = statement_init
    .replace(", ", "")
    .replace(/\n {2}/g, "")
    .trim();
  statement_update = statement_update
    .replace(", ", "")
    .replace(/\n {2}/g, "")
    .trim();
  var code =
    "for( " +
    statement_init +
    "; " +
    value_test +
    "; " +
    statement_update +
    " ){\n" +
    statement_chain +
    "}\n";
  return code;
};

// MATH BLOCKS
Blockly.Blocks["bi_parenthesis"] = {
  init: function init() {
    this.appendValueInput("expression").setCheck(null).appendField("(_)");
    this.setOutput(true, null);
    this.setColour(230);
    this.setTooltip("");
    this.setHelpUrl("http://www.example.com/");
  },
};

Blockly.Blocks["bi_unary"] = {
  init: function () {
    this.appendValueInput("expression")
      .setCheck(null)
      .appendField(new Blockly.FieldTextInput("++"), "operator");
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    //this.setOutput(true, null);
    this.setColour("#f43f5e");
    this.setTooltip("");
    this.setHelpUrl("http://www.example.com/");
  },
};

Blockly.Blocks["bi_unary_return"] = {
  init: function () {
    this.appendValueInput("expression")
      .setCheck(null)
      .appendField(new Blockly.FieldTextInput("++"), "operator");
    //    this.setPreviousStatement(true, null);
    //    this.setNextStatement(true, null);
    this.setOutput(true, null);
    this.setColour("#f43f5e");
    this.setTooltip("");
    this.setHelpUrl("http://www.example.com/");
  },
};

// TODO: JCOA Make a drop down list of unary operators
Blockly.Blocks["bi_unary_postfix"] = {
  init: function () {
    this.appendValueInput("expression")
      .appendField("postfix")
      .setCheck(null)
      .appendField(new Blockly.FieldTextInput("++"), "operator");
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    //this.setOutput(true, null);
    this.setColour("#f43f5e");
    this.setTooltip("");
    this.setHelpUrl("http://www.example.com/");
  },
};

Blockly.Blocks["bi_unary_postfix_return"] = {
  init: function () {
    this.appendValueInput("expression")
      .appendField("postfix")
      .setCheck(null)
      .appendField(new Blockly.FieldTextInput("++"), "operator");
    //    this.setPreviousStatement(true, null);
    //    this.setNextStatement(true, null);
    this.setOutput(true, null);
    this.setColour("#f43f5e");
    this.setTooltip("");
    this.setHelpUrl("http://www.example.com/");
  },
};

JavaScript["bi_unary"] = function (block) {
  function isLetter(c) {
    return c.toLowerCase() !== c.toUpperCase();
  }
  var value_expression = JavaScript.valueToCode(
    block,
    "expression",
    JavaScript.ORDER_ATOMIC
  );
  var text_operator = block.getFieldValue("operator");
  if (isLetter(text_operator[0])) {
    text_operator += " ";
  }
  var code = text_operator + value_expression + "\n";
  //return [code, Blockly.JavaScript.ORDER_ATOMIC];
  return code;
};

JavaScript["bi_unary_return"] = function (block) {
  function isLetter(c) {
    return c.toLowerCase() !== c.toUpperCase();
  }
  var value_expression = JavaScript.valueToCode(
    block,
    "expression",
    JavaScript.ORDER_ATOMIC
  );
  var text_operator = block.getFieldValue("operator");
  if (isLetter(text_operator[0])) {
    text_operator += " ";
  }
  var code = text_operator + value_expression;
  return [code, JavaScript.ORDER_ATOMIC];
  //return code;
};

JavaScript["bi_unary_postfix"] = function (block) {
  function isLetter(c) {
    return c.toLowerCase() !== c.toUpperCase();
  }
  var value_expression = JavaScript.valueToCode(
    block,
    "expression",
    JavaScript.ORDER_ATOMIC
  );
  var text_operator = block.getFieldValue("operator");
  if (isLetter(text_operator[0])) {
    text_operator += " ";
  }
  var code = value_expression + text_operator + "\n";
  //return [code, Blockly.JavaScript.ORDER_ATOMIC];
  return code;
};

JavaScript["bi_unary_postfix_return"] = function (block) {
  function isLetter(c) {
    return c.toLowerCase() !== c.toUpperCase();
  }
  var value_expression = JavaScript.valueToCode(
    block,
    "expression",
    JavaScript.ORDER_ATOMIC
  );
  var text_operator = block.getFieldValue("operator");
  if (isLetter(text_operator[0])) {
    text_operator += " ";
  }
  var code = value_expression + text_operator;
  return [code, JavaScript.ORDER_ATOMIC];
  //return code;
};

// TEXT BLOCKS

Blockly.Blocks["concate_string"] = {
  init: function () {
    this.appendDummyInput().appendField("Concate Strings to Variable");
    this.appendValueInput("ITEM").appendField("First String");
    this.appendValueInput("PROPERTY").appendField("Add Variable");
    this.setOutput(true, null);
    this.setColour("#004d40");
    this.setTooltip("Concate String To Variable");
    this.setHelpUrl("");
  },
};

JavaScript["concate_string"] = function (block) {
  var string1 = JavaScript.valueToCode(block, "ITEM", JavaScript.ORDER_ATOMIC);

  var variable = JavaScript.valueToCode(
    block,
    "PROPERTY",
    JavaScript.ORDER_ATOMIC
  );
  var code = `${string1}  + ${variable} `;
  return [code, JavaScript.ORDER_ATOMIC];
};

// OBJECT BLOCKS

Blockly.Blocks["create_object"] = {
  init: function () {
    this.appendDummyInput().appendField("Create Object with Key-Value Pairs");

    this.appendDummyInput()
      .appendField("Number of Key-Value Pairs:")
      .appendField(
        new Blockly.FieldDropdown(
          [
            ["1", "1"],
            ["2", "2"],
            ["3", "3"],
            ["4", "4"],
            ["5", "5"],
            ["6", "6"],
            ["7", "7"],
            ["8", "8"],
            ["9", "9"],
            ["10", "10"],
            ["11", "11"],
            ["12", "12"],
            ["13", "13"],
            ["14", "14"],
            ["15", "15"],
            ["16", "16"],
            ["17", "17"],
            ["18", "18"],
            ["19", "19"],
            ["20", "20"],
            ["21", "21"],
            ["22", "22"],
            ["23", "23"],
            ["24", "24"],
            ["25", "25"],
            ["26", "26"],
            ["27", "27"],
            ["28", "28"],
            ["29", "29"],
            ["30", "30"],
          ],
          function (value) {
            // Update the block when dropdown changes
            this.getSourceBlock().updateShape_(value);
          }
        ),
        "PAIRS"
      );

    this.setOutput(true, null);
    this.setColour("#FF5733");
    this.setTooltip("Create an object with key-value pairs");
    this.setHelpUrl("");

    // Initialize with 1 pair
    this.updateShape_("1");
  },

  // Function to update the shape (add or remove fields)
  updateShape_: function (numberOfPairs) {
    this.inputList.slice(2).forEach((input) => {
      this.removeInput(input.name);
    });

    for (let i = 1; i <= numberOfPairs; i++) {
      this.appendValueInput(`KEY${i}`)
        .setCheck(null)
        .setAlign(Blockly.ALIGN_RIGHT)
        .appendField(`Key ${i}`);

      this.appendValueInput(`VALUE${i}`)
        .setCheck(null)
        .setAlign(Blockly.ALIGN_RIGHT)
        .appendField(`Value ${i}`);
    }
  },
};

JavaScript["create_object"] = function (block) {
  const numberOfPairs = block.getFieldValue("PAIRS");

  let code = "{";

  for (let i = 1; i <= numberOfPairs; i++) {
    const keyBlock = block.getInputTargetBlock(`KEY${i}`);
    const valueBlock = block.getInputTargetBlock(`VALUE${i}`);

    // Get the generated code for the key and value blocks
    const keyCode = keyBlock ? JavaScript.blockToCode(keyBlock)[0] : "";
    const valueCode = valueBlock ? JavaScript.blockToCode(valueBlock)[0] : "";

    if (keyCode !== "" && valueCode !== "") {
      code += `${i > 1 ? ", " : ""}${keyCode}: ${valueCode}`;
    }
  }

  code += "}";
  return [code, JavaScript.ORDER_ATOMIC];
};

// DB METHODS:::

//GRAB PROPERTIES FROM RES BODY AND PARAMS
Blockly.Blocks["grab_property_from_response_body"] = {
  init: function () {
    this.appendDummyInput().appendField(
      "Grab Property From Response Body(Payload):"
    );
    this.appendValueInput("PROPERTY_NAME")
      .setCheck("String")
      .appendField("Name Of Property :");
    this.appendValueInput("RESPONSE_BODY_VAR")
      .setCheck("Variable")
      .appendField("Store Property In A Variable:");
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour("#004d40");
    this.setTooltip("Access Response Body");
    this.setHelpUrl("");
  },
};

JavaScript["grab_property_from_response_body"] = function (block) {
  var resBody_var = JavaScript.valueToCode(
    block,
    "RESPONSE_BODY_VAR",
    JavaScript.ORDER_ASSIGNMENT
  );
  var property = JavaScript.valueToCode(
    block,
    "PROPERTY_NAME",
    JavaScript.ORDER_ATOMIC
  );

  var code = `
   ${resBody_var}=req.body[${property}]
  // SPACE FOR NEXT CODE
  `;

  return code;
};

Blockly.Blocks["grab_data_from_database"] = {
  init: function () {
    this.appendDummyInput().appendField("Grab Data From Database");
    this.appendDummyInput("Custom_Model")
      .appendField("Select Custom Model:")
      .appendField(new Blockly.FieldDropdown(tableName), "arrayName");
    this.appendDummyInput("FILTER_METHOD")
      .appendField("Filter Method:")
      .appendField(
        new Blockly.FieldDropdown([
          ["find", "find"],
          ["findById", "findById"],
          ["findOne", "findOne"],
        ]),
        "method"
      );
    this.appendValueInput("property")
      .appendField("Property from object:")
      .setCheck(["String", "Number", "Boolean"]);
    this.appendValueInput("value").appendField("Value");
    this.appendValueInput("RESPONSE_BODY_VAR")
      .setCheck("Variable")
      .appendField("Store Property In A Variable:");
    this.appendStatementInput("SUCCESS")
      .setCheck(null)
      .appendField("on Success:");
    this.appendStatementInput("FAILURE")
      .setCheck(null)
      .appendField("on Failure:");
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour("#004d40");
    this.setTooltip("Mongo Array Method");
    this.setHelpUrl("");
  },
};

JavaScript["grab_data_from_database"] = function (block) {
  var arrayName = block.getFieldValue("arrayName");
  var method = block.getFieldValue("method");
  var resBody_var = JavaScript.valueToCode(
    block,
    "RESPONSE_BODY_VAR",
    JavaScript.ORDER_ASSIGNMENT
  );
  var property = JavaScript.valueToCode(
    block,
    "property",
    JavaScript.ORDER_ATOMIC
  );
  var value = JavaScript.valueToCode(block, "value", JavaScript.ORDER_ATOMIC);

  var successCode = JavaScript.statementToCode(block, "SUCCESS");
  var failureCode = JavaScript.statementToCode(block, "FAILURE");

  var code;

  if (method === "find" && !property && !value) {
    code = `
    try {
     ${resBody_var} = await ${arrayName}.find();
      ${successCode}
    } catch (error) {
      ${failureCode}
    }
    `;
  } else {
    code = `
    try {
      ${resBody_var} = await ${arrayName}.${method}({${property}:${value}});
      ${successCode}
    } catch (error) {
      ${failureCode}
    }
    `;
  }

  return code;
};

Blockly.Blocks["otp_verfication__twilio"] = {
  init: function () {
    this.appendDummyInput().appendField("OTP Based AUTH Using Twilio :");
    this.appendValueInput("accountSid")
      .appendField("Insert Twilio Account SID :")
      .setCheck(["String", "Number", "Variable"]);
    this.appendValueInput("authToken")
      .appendField("Insert Twilio Account Auth Token :")
      .setCheck(["String", "Number", "Variable"]);
    this.appendValueInput("phoneNumber")
      .appendField("Insert Twilio Account Phone Number :")
      .setCheck(["String", "Number", "Variable"]);
    this.appendValueInput("bodyMessage")
      .appendField("Insert Message To Be Sent :")
      .setCheck(["String", "Number", "Variable"]);
    this.appendValueInput("userMobNumber")
      .appendField("Insert Mobile Number To Send Otp :")
      .setCheck(["String", "Number", "Variable"]);
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour("#004d40");
    this.setTooltip("OTP AUTH");
    this.setHelpUrl("");
  },
};

JavaScript["otp_verfication__twilio"] = function (block) {
  var accountSid = JavaScript.valueToCode(
    block,
    "accountSid",
    JavaScript.ORDER_ASSIGNMENT
  );
  var authToken = JavaScript.valueToCode(
    block,
    "authToken",
    JavaScript.ORDER_ASSIGNMENT
  );
  var phoneNumber = JavaScript.valueToCode(
    block,
    "phoneNumber",
    JavaScript.ORDER_ASSIGNMENT
  );

  var bodyMessage = JavaScript.valueToCode(
    block,
    "bodyMessage",
    JavaScript.ORDER_ASSIGNMENT
  );

  var userMobNumber = JavaScript.valueToCode(
    block,
    "userMobNumber",
    JavaScript.ORDER_ASSIGNMENT
  );

  var code = `
  let client = twilio(${accountSid}, ${authToken});

  await client.messages.create({
    body: ${bodyMessage},
    from: ${phoneNumber},
    to: ${userMobNumber},
  });
  `;

  return code;
};

function updateServiceInputs(service, block) {
  if (block.getInput("authType")) {
    block.removeInput("authType");
  }

  if (block.getInput("clientId")) {
    block.removeInput("clientId");
  }

  if (block.getInput("secretKey")) {
    block.removeInput("secretKey");
  }

  if (block.getInput("refreshToken")) {
    block.removeInput("refreshToken");
  }

  if (block.getInput("HOST")) {
    block.removeInput("HOST");
  }

  if (block.getInput("PORT")) {
    block.removeInput("PORT");
  }

  if (block.getInput("SUCCESS")) {
    block.removeInput("SUCCESS");
  }

  if (block.getInput("FAILURE")) {
    block.removeInput("FAILURE");
  }

  if (service === "gmail") {
    block
      .appendValueInput("authType")
      .appendField("Auth Type  :")
      .setCheck(["String", "Number", "Variable"]);
    block
      .appendValueInput("clientId")
      .appendField("Auth Client ID  :")
      .setCheck(["String", "Number", "Variable"]);
    block
      .appendValueInput("secretKey")
      .appendField("Auth Client Secret Key  :")
      .setCheck(["String", "Number", "Variable"]);
    block
      .appendValueInput("refreshToken")
      .appendField("Auth Client Refresh Token  :")
      .setCheck(["String", "Number", "Variable"]);
    block
      .appendValueInput("redirectLink")
      .appendField("Redirect Link If Using For Verify  :")
      .setCheck(["String", "Number", "Variable"]);
    block
      .appendValueInput("redirectTextRef")
      .appendField("Redirect Link Text To Be Shown  :")
      .setCheck(["String", "Number", "Variable"]);
    block
      .appendStatementInput("SUCCESS")
      .setCheck(null)
      .appendField("on Success:");
    block
      .appendStatementInput("FAILURE")
      .setCheck(null)
      .appendField("on Failure:");
  } else if (service === "outlook") {
    block
      .appendValueInput("HOST")
      .appendField("Insert SMTP host  :")
      .setCheck(["String", "Number", "Variable"]);
    block
      .appendValueInput("PORT")
      .appendField("Insert SMTP Port :")
      .setCheck(["String", "Number", "Variable"]);
    block
      .appendStatementInput("SUCCESS")
      .setCheck(null)
      .appendField("on Success:");
    block
      .appendStatementInput("FAILURE")
      .setCheck(null)
      .appendField("on Failure:");
  }
}

Blockly.Blocks["email_handler"] = {
  init: function () {
    this.appendDummyInput().appendField("Generate Email With SMTP Settings :");
    this.appendDummyInput("SERVICE")
      .appendField("Service Provider:")
      .appendField(
        new Blockly.FieldDropdown(
          [
            ["gmail", "gmail"],
            ["outlook", "outlook"],
          ],
          function (service) {
            // Function to update the block when service is changed
            updateServiceInputs(service, this.sourceBlock_);
          }
        ),
        "service"
      );
    this.appendValueInput("AUTHUSER")
      .appendField("Insert SMTP User E-mail:")
      .setCheck(["String", "Number", "Variable"]);
    this.appendValueInput("AUTHPASS")
      .appendField("Insert SMTP User Password :")
      .setCheck(["String", "Number", "Variable"]);
    this.appendValueInput("FROM")
      .appendField("from :")
      .setCheck(["String", "Number", "Variable"]);
    this.appendValueInput("TO")
      .appendField("to :")
      .setCheck(["String", "Number", "Variable"]);
    this.appendValueInput("SUBJECT")
      .appendField("Subject :")
      .setCheck(["String", "Number", "Variable"]);
    this.appendValueInput("MESSAGE")
      .appendField("Message :")
      .setCheck(["String", "Number", "Variable"]);

    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour("#004d40");
    this.setTooltip("Send Email");
    this.setHelpUrl("");
  },
};

JavaScript["email_handler"] = function (block) {
  var service = block.getFieldValue("service");

  var authUser = JavaScript.valueToCode(
    block,
    "AUTHUSER",
    JavaScript.ORDER_ASSIGNMENT
  );

  var authPass = JavaScript.valueToCode(
    block,
    "AUTHPASS",
    JavaScript.ORDER_ASSIGNMENT
  );

  var host = JavaScript.valueToCode(block, "HOST", JavaScript.ORDER_ASSIGNMENT);
  var port = JavaScript.valueToCode(block, "PORT", JavaScript.ORDER_ASSIGNMENT);
  var authType = JavaScript.valueToCode(
    block,
    "authType",
    JavaScript.ORDER_ASSIGNMENT
  );
  var clientId = JavaScript.valueToCode(
    block,
    "clientId",
    JavaScript.ORDER_ASSIGNMENT
  );
  var secretKey = JavaScript.valueToCode(
    block,
    "secretKey",
    JavaScript.ORDER_ASSIGNMENT
  );
  var refreshToken = JavaScript.valueToCode(
    block,
    "refreshToken",
    JavaScript.ORDER_ASSIGNMENT
  );
  var refreshLink = JavaScript.valueToCode(
    block,
    "redirectLink",
    JavaScript.ORDER_ASSIGNMENT
  );
  var refreshText = JavaScript.valueToCode(
    block,
    "redirectTextRef",
    JavaScript.ORDER_ASSIGNMENT
  );

  var from = JavaScript.valueToCode(block, "FROM", JavaScript.ORDER_ASSIGNMENT);
  var toField = JavaScript.valueToCode(
    block,
    "TO",
    JavaScript.ORDER_ASSIGNMENT
  );
  var subject = JavaScript.valueToCode(
    block,
    "SUBJECT",
    JavaScript.ORDER_ASSIGNMENT
  );

  var message = JavaScript.valueToCode(
    block,
    "MESSAGE",
    JavaScript.ORDER_ASSIGNMENT
  );

  var successCode = JavaScript.statementToCode(block, "SUCCESS");
  var failureCode = JavaScript.statementToCode(block, "FAILURE");

  var code;

  if (service === "gmail") {
    code = `
    let transporter = nodemailer.createTransport({
      service: "${service}",
      auth: {
        type: ${authType},
        user: ${authUser},
        pass: ${authPass},
        clientId: ${clientId},
        clientSecret: ${secretKey},
        refreshToken: ${refreshToken},
      },
    });
  

  let mailOptions;

  if (req.body.isTemplateEmail) {
    let htmlContent = juice(${message});
    mailOptions = {
      from: ${from},
      to: Array.isArray(${toField}) ? ${toField}.join(', ') : ${toField},
      subject: ${subject},
      html: htmlContent,
      cc: req.body.cc ? req.body.cc :'' ,
      bcc: req.body.bcc ? req.body.bcc : '',
    };
  }else if(req.body.isVerify){
    mailOptions = {
      from: ${from},
      to: Array.isArray(${toField}) ? ${toField}.join(', ') : ${toField},
      subject: ${subject},
      html:'<p>' + 
              '<span style="display:block">' + ${message} + '</span>' + 
              '<a href="' + ${refreshLink} + '">' + ${refreshText} + '</a>' + 
              '</p>',
      cc: req.body.cc ? req.body.cc : '',
      bcc: req.body.bcc ? req.body.bcc : '',
    };
  } else {
    mailOptions = {
      from: ${from},
      to: Array.isArray(${toField}) ? ${toField}.join(', ') : ${toField},
      subject: ${subject},
      html:'<p>'+${message}+'</p>',
      cc: req.body.cc ? req.body.cc : '',
      bcc: req.body.bcc ? req.body.bcc : '' ,
    };
  }
  
  transporter.sendMail(mailOptions, (error, info) => {
    if (error) {
        return ${failureCode}
    }
  
    ${successCode}
   
  });
    `;
  } else {
    code = `
    let transporter = nodemailer.createTransport({
      service: "${service}",
      host: ${host},
      port: ${port},
      tls: {
        ciphers: "SSLv3",
        rejectUnauthorized: false,
      },
      auth: {
        user: ${authUser},
        pass: ${authPass},
      },
    });
  
    let mailOptions = {
      from: ${from},
      to: ${toField},
      subject: ${subject},
      html: '<p>'+${message}+'</p>'
  };
  
  transporter.sendMail(mailOptions, (error, info) => {
    if (error) {
        return ${failureCode}
    }
  
    ${successCode}
   
  });
    `;
  }

  return code;
};

Blockly.Blocks["grab_and_store_data"] = {
  init: function () {
    this.appendDummyInput().appendField(
      "Grab Data From Database And Store In Variable"
    );
    this.appendDummyInput("Custom_Model")
      .appendField("Select Custom Model:")
      .appendField(new Blockly.FieldDropdown(tableName), "arrayName");
    this.appendDummyInput("FILTER_METHOD")
      .appendField("Filter Method:")
      .appendField(
        new Blockly.FieldDropdown([
          ["find", "find"],
          ["findById", "findById"],
          ["findOne", "findOne"],
        ]),
        "method"
      );
    this.appendValueInput("property")
      .appendField("Property from object:")
      .setCheck(["String", "Number", "Boolean"]);
    this.appendValueInput("value").appendField("Value");
    this.appendValueInput("RESPONSE_BODY_VAR")
      .setCheck("Variable")
      .appendField("Store Property In A Variable:");
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour("#004d40");
    this.setTooltip("Mongo Array Method");
    this.setHelpUrl("");
  },
};

JavaScript["grab_and_store_data"] = function (block) {
  var arrayName = block.getFieldValue("arrayName");
  var method = block.getFieldValue("method");
  var resBody_var = JavaScript.valueToCode(
    block,
    "RESPONSE_BODY_VAR",
    JavaScript.ORDER_ASSIGNMENT
  );
  var property = JavaScript.valueToCode(
    block,
    "property",
    JavaScript.ORDER_ATOMIC
  );
  var value = JavaScript.valueToCode(block, "value", JavaScript.ORDER_ATOMIC);

  var code;

  if (method === "find" && !property && !value) {
    code = `
     ${resBody_var} = await ${arrayName}.find();
    `;
  } else {
    code = `
      ${resBody_var} = await ${arrayName}.${method}({${property}:${value}});
    
    
    `;
  }

  return code;
};

// CREATE NEW ENTRY IN DATABASE
Blockly.Blocks["create_new_entry"] = {
  init: function () {
    this.appendDummyInput().appendField("Create New Entry In a Table");
    this.appendStatementInput("NEW_ENTRY")
      .setCheck(null)
      .appendField("Entry Creation Logic:");

    this.appendStatementInput("SUCCESS")
      .setCheck(null)
      .appendField("on Success:");
    this.appendStatementInput("FAILURE")
      .setCheck(null)
      .appendField("on Failure:");
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour("#004d40");
    this.setTooltip("Mongo Array Method");
    this.setHelpUrl("");
  },
};

JavaScript["create_new_entry"] = function (block) {
  var successCode = JavaScript.statementToCode(block, "SUCCESS");
  var creationLogic = JavaScript.statementToCode(block, "NEW_ENTRY");
  var failureCode = JavaScript.statementToCode(block, "FAILURE");

  var code = `
    try {
      ${creationLogic}
      ${successCode}
    } catch (error) {
      ${failureCode}
    }
  `;

  return code;
};

// UPDATE ENTRY IN DATABASE
Blockly.Blocks["update_entry_database"] = {
  init: function () {
    this.appendDummyInput().appendField("Update Entry In Database");
    this.appendDummyInput("Custom_Model")
      .appendField("Select Custom Model:")
      .appendField(new Blockly.FieldDropdown(tableName), "arrayName");
    this.appendValueInput("filterKey").appendField(
      "Insert grabbed Id from Payload:::"
    );
    this.appendValueInput("RESPONSE_BODY_VAR")
      .setCheck("Variable")
      .appendField("Store Doc To be Updated In Variable:");
    this.appendValueInput("PAYLOAD_VAR").appendField(
      "Insert Payload Variable :"
    );
    this.appendStatementInput("UPDATE_LOGIC")
      .setCheck(null)
      .appendField("inject update logic:");
    this.appendStatementInput("SUCCESS")
      .setCheck(null)
      .appendField("on Success:");
    this.appendStatementInput("FAILURE")
      .setCheck(null)
      .appendField("on Failure:");
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour("#004d40");
    this.setTooltip("Mongo Array Method");
    this.setHelpUrl("");
  },
};

JavaScript["update_entry_database"] = function (block) {
  var arrayName = block.getFieldValue("arrayName");
  var filter_key_id = JavaScript.valueToCode(
    block,
    "filterKey",
    JavaScript.ORDER_ASSIGNMENT
  );
  var resBody_var = JavaScript.valueToCode(
    block,
    "RESPONSE_BODY_VAR",
    JavaScript.ORDER_ASSIGNMENT
  );
  var payload_var = JavaScript.valueToCode(
    block,
    "PAYLOAD_VAR",
    JavaScript.ORDER_ATOMIC
  );

  var successCode = JavaScript.statementToCode(block, "SUCCESS");
  var failureCode = JavaScript.statementToCode(block, "FAILURE");
  var updateCode = JavaScript.statementToCode(block, "UPDATE_LOGIC");

  var code = `
    try {
      ${resBody_var}=await ${arrayName}.findById(${filter_key_id});
      if(${resBody_var}){
        ${updateCode}
        ${successCode}
      } else {
        ${failureCode}
      }
    } catch (error) {
      ${failureCode}
    }
  `;

  return code;
};

Blockly.Blocks["delete_entry_database"] = {
  init: function () {
    this.appendDummyInput().appendField("Delete Entry From Database");
    this.appendDummyInput("Custom_Model")
      .appendField("Select Custom Model:")
      .appendField(new Blockly.FieldDropdown(tableName), "arrayName");
    this.appendDummyInput("DELETE_METHOD")
      .appendField("Choose Delete Method:")
      .appendField(
        new Blockly.FieldDropdown([
          ["Hard_Delete", "Hard_Delete"],
          ["Soft_Delete", "Soft_Delete"],
        ]),
        "deleteMethod"
      );
    this.appendValueInput("filterKey").appendField(
      "Insert grabbed Id from Payload:::"
    );
    this.appendStatementInput("DELETE_LOGIC")
      .setCheck(null)
      .appendField("Logic If Method Is Soft Delete:");
    this.appendStatementInput("SUCCESS")
      .setCheck(null)
      .appendField("on Success:");
    this.appendStatementInput("FAILURE")
      .setCheck(null)
      .appendField("on Failure:");
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour("#004d40");
    this.setTooltip("Mongo Array Method");
    this.setHelpUrl("");
  },
};

JavaScript["delete_entry_database"] = function (block) {
  var arrayName = block.getFieldValue("arrayName");
  var method = block.getFieldValue("deleteMethod");
  var filter_key_id = JavaScript.valueToCode(
    block,
    "filterKey",
    JavaScript.ORDER_ASSIGNMENT
  );

  var successCode = JavaScript.statementToCode(block, "SUCCESS");
  var delLogic = JavaScript.statementToCode(block, "DELETE_LOGIC");
  var failureCode = JavaScript.statementToCode(block, "FAILURE");

  var code;

  if (method === "Hard_Delete") {
    code = `
    try {
      const result = await ${arrayName}.findByIdAndDelete(${filter_key_id});
      if (result) {
        ${successCode}
      } else {
        ${failureCode}
      }
    } catch (error) {
      ${failureCode}
    }
  `;
  } else {
    code = `
    try {
      ${delLogic}
        
      ${successCode}
     
    } catch (error) {
      ${failureCode}
    }
  `;
  }

  return code;
};

// RESPONSE STATUS
Blockly.Blocks["assign_response_status"] = {
  init: function () {
    this.appendDummyInput().appendField("Assign Response Status");
    this.appendDummyInput("Status Code")
      .appendField("Select Status Code:")
      .appendField(
        new Blockly.FieldDropdown([
          ["200", "200"],
          ["201", "201"],
          ["400", "400"],
          ["500", "500"],
        ]),
        "statusCode"
      );
    this.appendDummyInput("STATUS_FORMAT")
      .appendField("Status Format:")
      .appendField(
        new Blockly.FieldDropdown([
          ["json", "json"],
          ["text", "text"],
        ]),
        "format"
      );
    this.appendValueInput("value").appendField("Response To Be Sent:");
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour("#004d40");
    this.setTooltip("Mongo Array Method");
    this.setHelpUrl("");
  },
};

JavaScript["assign_response_status"] = function (block) {
  var statusCode = block.getFieldValue("statusCode");
  var format = block.getFieldValue("format");
  var value = JavaScript.valueToCode(block, "value", JavaScript.ORDER_ATOMIC);

  var code;

  if (format === "json") {
    code = `
    res.status(${statusCode}).json(${value})

    `;
  } else {
    code = `
    res.status(${statusCode}).send(${value})

    `;
  }

  return code;
};

Blockly.Blocks["get_property"] = {
  init: function () {
    this.appendValueInput("ITEM").setCheck(null).appendField("Get Property of");
    this.appendValueInput("PROPERTY")
      .setCheck("String")
      .appendField("Property Name:");
    this.setOutput(true, null);
    this.setColour("#FF5733");
    this.setTooltip("Get a property value from an object.");
    this.setHelpUrl("");
  },
};

JavaScript["get_property"] = function (block) {
  const item = JavaScript.valueToCode(block, "ITEM", JavaScript.ORDER_ATOMIC);
  const property = JavaScript.valueToCode(
    block,
    "PROPERTY",
    JavaScript.ORDER_ATOMIC
  );

  // Generate code to access the property value from the item
  const getPropertyCode = `${item}[${property}]`;

  return [getPropertyCode, JavaScript.ORDER_ATOMIC];
};

Blockly.Blocks["create_entry_with_object"] = {
  init: function () {
    this.appendDummyInput("Custom_Model")
      .appendField("Select Custom Model:")
      .appendField(new Blockly.FieldDropdown(tableName), "arrayName");
    this.appendValueInput("variableA")
      .setCheck(null)
      .appendField("Insert Document To Be Added To Database :");
    this.appendValueInput("RESPONSE_BODY_VAR")
      .setCheck("Variable")
      .appendField("Store Property In A Variable:");
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(330);
    this.setTooltip("Create New Entry To Database");
    this.setHelpUrl("");
  },
};

JavaScript["create_entry_with_object"] = function (block) {
  var arrayName = block.getFieldValue("arrayName");
  var resBody_var = JavaScript.valueToCode(
    block,
    "RESPONSE_BODY_VAR",
    JavaScript.ORDER_ASSIGNMENT
  );

  var variableA =
    JavaScript.valueToCode(block, "variableA", JavaScript.ORDER_NONE) || "''";

  var code = `${resBody_var}=await ${arrayName}.create(${variableA})
  
  `;

  return code;
};

Blockly.Blocks["assignment_key"] = {
  init: function () {
    this.appendValueInput("variableA").setCheck(null).appendField("Assign");
    this.appendValueInput("variableB").setCheck(null).appendField("to");
    this.setInputsInline(true);
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(330);
    this.setTooltip("Assignment Operation");
    this.setHelpUrl("");
  },
};

JavaScript["assignment_key"] = function (block) {
  var variableA =
    JavaScript.valueToCode(block, "variableA", JavaScript.ORDER_NONE) || "''";
  var variableB =
    JavaScript.valueToCode(block, "variableB", JavaScript.ORDER_NONE) || "''";

  var code = `${variableA}=${variableB};
  
  `;
  return code;
};

Blockly.Blocks["save_updated_doc"] = {
  init: function () {
    this.appendValueInput("variableA")
      .setCheck(null)
      .appendField("Variable Name To Be Saved:");
    this.setInputsInline(true);
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(330);
    this.setTooltip("Save Document To Database");
    this.setHelpUrl("");
  },
};

JavaScript["save_updated_doc"] = function (block) {
  var variableA =
    JavaScript.valueToCode(block, "variableA", JavaScript.ORDER_NONE) || "''";

  var code = `
  await ${variableA}.save()
  
  `;
  return code;
};

// TRY/CATCH

Blockly.Blocks["try_catch_block"] = {
  init: function () {
    this.appendStatementInput("TRY_STATEMENTS")
      .setCheck(null)
      .appendField("Try/Catch Block:");
    this.appendDummyInput().appendField("Catch");
    this.appendValueInput("RESPONSE_BODY_VAR")
      .setCheck("Variable")
      .appendField("Store Error In A Variable:");
    this.appendStatementInput("CATCH_STATEMENTS")
      .setCheck(null)
      .appendField("Logic For Error Handling:");
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour("#1976D2");
    this.setTooltip("Try-Catch block");
    this.setHelpUrl("");
  },
};

JavaScript["try_catch_block"] = function (block) {
  var tryStatements = JavaScript.statementToCode(block, "TRY_STATEMENTS");

  var errorVar = JavaScript.valueToCode(
    block,
    "RESPONSE_BODY_VAR",
    JavaScript.ORDER_ASSIGNMENT
  );
  var catchStatements = JavaScript.statementToCode(block, "CATCH_STATEMENTS");

  var code =
    "try {\n" +
    tryStatements +
    "} catch (" +
    errorVar +
    ") {\n" +
    catchStatements +
    "}\n";
  return code;
};

// SWITCH/BREAK
Blockly.Blocks["switch_block"] = {
  init: function () {
    this.appendValueInput("EXPRESSION")
      .setCheck(null)
      .appendField("Switch (Expression):");
    this.appendStatementInput("CASES").setCheck(null).appendField("Cases:");
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour("#FF5722");
    this.setTooltip("Switch statement");
    this.setHelpUrl("");
  },
};

Blockly.Blocks["case_block"] = {
  init: function () {
    this.appendValueInput("CASE_VALUE")
      .setCheck(null)
      .appendField("Case Value:");
    this.appendStatementInput("STATEMENTS")
      .setCheck(null)
      .appendField("Statements:");
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour("#FF5722");
    this.setTooltip("Case statement");
    this.setHelpUrl("");
  },
};

Blockly.Blocks["break_block"] = {
  init: function () {
    this.appendDummyInput().appendField("Break;");
    this.setPreviousStatement(true, null);
    this.setColour("#FF5722");
    this.setTooltip("Break statement");
    this.setHelpUrl("");
  },
};

// Generator for Switch Block
JavaScript["switch_block"] = function (block) {
  var expression =
    JavaScript.valueToCode(block, "EXPRESSION", JavaScript.ORDER_NONE) ||
    "null";
  var cases = JavaScript.statementToCode(block, "CASES");

  var code = "switch (" + expression + ") {\n" + cases + "}\n";
  return code;
};

// Generator for Case Block
JavaScript["case_block"] = function (block) {
  var caseValue =
    JavaScript.valueToCode(block, "CASE_VALUE", JavaScript.ORDER_NONE) ||
    "null";
  var statements = JavaScript.statementToCode(block, "STATEMENTS");

  var code = "case " + caseValue + ":\n" + statements + "  break;\n";
  return code;
};

// Generator for Break Block
JavaScript["break_block"] = function (block) {
  return "break;\n";
};

// Array ForEach Method
Blockly.Blocks["array_foreach"] = {
  init: function () {
    this.appendDummyInput().appendField("For Each Method on Array");
    this.appendValueInput("array_attached")
      .setCheck(null)
      .appendField("Provide an Array(List):");
    this.appendValueInput("itemInside")
      .setCheck(null)
      .appendField("Store Doc within Each Iteration In Variable:");
    this.appendDummyInput("FILTER_METHOD")
      .appendField("Use Async/Await:")
      .appendField(
        new Blockly.FieldDropdown([
          ["yes", "yes"],
          ["no", "no"],
        ]),
        "method"
      );
    this.appendStatementInput("Logic_FOREACH")
      .setCheck(null)
      .appendField("Per Iteration Logic:");
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour("#004d40");
    this.setTooltip("For Each Array Method");
    this.setHelpUrl("");
  },
};

JavaScript["array_foreach"] = function (block) {
  var arrayName = block.getFieldValue("FILTER_METHOD");
  var item = JavaScript.valueToCode(
    block,
    "itemInside",
    JavaScript.ORDER_ASSIGNMENT
  );
  var arrayList = JavaScript.valueToCode(
    block,
    "array_attached",
    JavaScript.ORDER_ASSIGNMENT
  );

  var forEachLogic = JavaScript.statementToCode(block, "Logic_FOREACH");

  var code;

  if (arrayName === "no") {
    code = `
    ${arrayList}.forEach((${item})=>{
      ${forEachLogic}
    })
    
    `;
  } else {
    code = `
    ${arrayList}.forEach(async(${item})=>{
      ${forEachLogic}
    })

    `;
  }

  return code;
};

// TYPECASTING

Blockly.Blocks["to_integer"] = {
  init: function () {
    this.appendValueInput("VALUE").setCheck(null).appendField("To Integer:");
    this.setOutput(true, "Number");
    this.setColour("#9C27B0");
    this.setTooltip("Converts the value to an integer");
    this.setHelpUrl("");
  },
};

JavaScript["to_integer"] = function (block) {
  var value = JavaScript.valueToCode(block, "VALUE", JavaScript.ORDER_ATOMIC);
  var code = "parseInt(" + value + ")";
  return [code, JavaScript.ORDER_FUNCTION_CALL];
};

Blockly.Blocks["to_string"] = {
  init: function () {
    this.appendValueInput("VALUE").setCheck(null).appendField("To String:");
    this.setOutput(true, "String");
    this.setColour("#2196F3");
    this.setTooltip("Converts the value to a string");
    this.setHelpUrl("");
  },
};

JavaScript["to_string"] = function (block) {
  var value = JavaScript.valueToCode(block, "VALUE", JavaScript.ORDER_ATOMIC);
  var code = "String(" + value + ")";
  return [code, JavaScript.ORDER_FUNCTION_CALL];
};

Blockly.Blocks["to_boolean"] = {
  init: function () {
    this.appendValueInput("VALUE").setCheck(null).appendField("To Boolean:");
    this.setOutput(true, "Boolean");
    this.setColour("#4CAF50");
    this.setTooltip("Converts the value to a boolean");
    this.setHelpUrl("");
  },
};

JavaScript["to_boolean"] = function (block) {
  var value = JavaScript.valueToCode(block, "VALUE", JavaScript.ORDER_ATOMIC);
  var code = "Boolean(" + value + ")";
  return [code, JavaScript.ORDER_FUNCTION_CALL];
};

// DATE-TIME
Blockly.Blocks["current_date"] = {
  init: function () {
    this.appendDummyInput().appendField("Grab Current Date");
    this.appendValueInput("RESULT_VAR")
      .setCheck("Variable")
      .appendField("Store Current Date In Variable:");
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour("#1976D2");
    this.setTooltip("get current date");
  },
};

JavaScript["current_date"] = function (block) {
  var variable_result = JavaScript.valueToCode(
    block,
    "RESULT_VAR",
    JavaScript.ORDER_ASSIGNMENT
  );

  let code = `
  ${variable_result}=new Date()
  `;

  return code;
};

Blockly.Blocks["increment_decrement_date"] = {
  init: function () {
    this.appendDummyInput()
      .appendField("Increment/Decrement Date :")
      .appendField(
        new Blockly.FieldDropdown([
          ["increment", "INCREMENT"],
          ["decrement", "DECREMENT"],
        ]),
        "OPERATION"
      );
    this.appendDummyInput()
      .appendField("Choose The Unit :")
      .appendField(
        new Blockly.FieldDropdown([
          ["days", "days"],
          ["weeks", "weeks"],
          ["months", "months"],
        ]),
        "PART"
      );
    this.appendValueInput("DAYS_HOURS")
      .appendField("Insert Increment/Decrement Value :")
      .setCheck("Number");
    this.appendValueInput("DATE").setCheck(null).appendField("Provided Date :");
    this.appendValueInput("RESULT_VAR")
      .setCheck("Variable")
      .appendField("Store New Date In Variable:");
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour("#1976D2");
    this.setTooltip("");
    this.setHelpUrl("");
  },
};

JavaScript["increment_decrement_date"] = function (block) {
  var dropdown_operation = block.getFieldValue("OPERATION");
  var dateOrTime = block.getFieldValue("PART");
  var value_days_hours = JavaScript.valueToCode(
    block,
    "DAYS_HOURS",
    JavaScript.ORDER_ATOMIC
  );
  var value_date = JavaScript.valueToCode(
    block,
    "DATE",
    JavaScript.ORDER_ATOMIC
  );
  var variable_result = JavaScript.valueToCode(
    block,
    "RESULT_VAR",
    JavaScript.ORDER_ASSIGNMENT
  );

  var code = "";
  if (dropdown_operation == "INCREMENT") {
    code = `
    ${variable_result} = new Date(${value_date});
    `;
    if (dateOrTime === "days") {
      code += `
      ${variable_result}.setDate(${variable_result}.getDate() + ${value_days_hours});
      `;
    } else if (dateOrTime === "weeks") {
      code += `
      ${variable_result}.setDate(${variable_result}.getDate() + ${
        value_days_hours * 7
      });
      `;
    } else if (dateOrTime === "months") {
      code += `
      ${variable_result}.setMonth(${variable_result}.getMonth() + ${value_days_hours});
      `;
    }
  } else {
    code = `
    ${variable_result} = new Date(${value_date});
    `;
    if (dateOrTime === "days") {
      code += `
      ${variable_result}.setDate(${variable_result}.getDate() - ${value_days_hours});
      `;
    } else if (dateOrTime === "weeks") {
      code += `
      ${variable_result}.setDate(${variable_result}.getDate() - ${
        value_days_hours * 7
      });
      `;
    } else if (dateOrTime === "months") {
      code += `
      ${variable_result}.setMonth(${variable_result}.getMonth() - ${value_days_hours});
      `;
    }
  }
  return code;
};

Blockly.Blocks["increment_decrement_date_current"] = {
  init: function () {
    this.appendDummyInput()
      .appendField("Increment/Decrement Current Date :")
      .appendField(
        new Blockly.FieldDropdown([
          ["increment", "INCREMENT"],
          ["decrement", "DECREMENT"],
        ]),
        "OPERATION"
      );
    this.appendDummyInput()
      .appendField("Choose The Unit :")
      .appendField(
        new Blockly.FieldDropdown([
          ["days", "days"],
          ["weeks", "weeks"],
          ["months", "months"],
        ]),
        "PART"
      );
    this.appendValueInput("DAYS_HOURS")
      .appendField("Insert Increment/Decrement Value :")
      .setCheck("Number");
    this.appendValueInput("RESULT_VAR")
      .setCheck("Variable")
      .appendField("Store New Date In Variable:");
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour("#1976D2");
    this.setTooltip("");
    this.setHelpUrl("");
  },
};

JavaScript["increment_decrement_date_current"] = function (block) {
  var dropdown_operation = block.getFieldValue("OPERATION");
  var dateOrTime = block.getFieldValue("PART");
  var value_days_hours = JavaScript.valueToCode(
    block,
    "DAYS_HOURS",
    JavaScript.ORDER_ATOMIC
  );
  var variable_result = JavaScript.valueToCode(
    block,
    "RESULT_VAR",
    JavaScript.ORDER_ASSIGNMENT
  );

  var code = "";
  if (dropdown_operation == "INCREMENT") {
    code = `
    ${variable_result} = new Date();
    `;
    if (dateOrTime === "days") {
      code += `
      ${variable_result}.setDate(${variable_result}.getDate() + ${value_days_hours});
      `;
    } else if (dateOrTime === "weeks") {
      code += `
      ${variable_result}.setDate(${variable_result}.getDate() + ${
        value_days_hours * 7
      });
      `;
    } else if (dateOrTime === "months") {
      code += `
      ${variable_result}.setMonth(${variable_result}.getMonth() + ${value_days_hours});
      `;
    }
  } else {
    code = `
    ${variable_result} = new Date();
    `;
    if (dateOrTime === "days") {
      code += `
      ${variable_result}.setDate(${variable_result}.getDate() - ${value_days_hours});
      `;
    } else if (dateOrTime === "weeks") {
      code += `
      ${variable_result}.setDate(${variable_result}.getDate() - ${
        value_days_hours * 7
      });
      `;
    } else if (dateOrTime === "months") {
      code += `
      ${variable_result}.setMonth(${variable_result}.getMonth() - ${value_days_hours});
      `;
    }
  }
  return code;
};

Blockly.Blocks["increment_decrement_time"] = {
  init: function () {
    this.appendDummyInput()
      .appendField("Increment/Decrement Time :")
      .appendField(
        new Blockly.FieldDropdown([
          ["increment", "INCREMENT"],
          ["decrement", "DECREMENT"],
        ]),
        "OPERATION"
      );
    this.appendDummyInput()
      .appendField("Choose The Unit :")
      .appendField(
        new Blockly.FieldDropdown([
          ["hours", "hours"],
          ["minutes", "minutes"],
          ["seconds", "seconds"],
        ]),
        "PART"
      );
    this.appendValueInput("DAYS_HOURS")
      .appendField("Insert Increment/Decrement Value :")
      .setCheck("Number");
    this.appendValueInput("DATE").setCheck(null).appendField("Provided Date :");
    this.appendValueInput("RESULT_VAR")
      .setCheck("Variable")
      .appendField("Store New Time In Variable:");
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour("#1976D2");
    this.setTooltip("");
    this.setHelpUrl("");
  },
};

JavaScript["increment_decrement_time"] = function (block) {
  var dropdown_operation = block.getFieldValue("OPERATION");
  var timeUnit = block.getFieldValue("PART");
  var value_time = JavaScript.valueToCode(
    block,
    "DAYS_HOURS",
    JavaScript.ORDER_ATOMIC
  );
  var variable_result = JavaScript.valueToCode(
    block,
    "RESULT_VAR",
    JavaScript.ORDER_ASSIGNMENT
  );

  var valueDate = JavaScript.valueToCode(
    block,
    "DATE",
    JavaScript.ORDER_ASSIGNMENT
  );

  var code = "";
  if (dropdown_operation == "INCREMENT") {
    code = `
    ${variable_result} = new Date(${valueDate});
    `;
    if (timeUnit === "hours") {
      code += `
       ${variable_result}.setHours( ${variable_result}.getHours() + ${value_time});
      `;
    } else if (timeUnit === "minutes") {
      code += `
       ${variable_result}.setMinutes( ${variable_result}.getMinutes() + ${value_time});
      `;
    } else if (timeUnit === "seconds") {
      code += `
       ${variable_result}.setSeconds( ${variable_result}.getSeconds() + ${value_time});
      `;
    }
  } else {
    code = `
    ${variable_result} = new Date(${valueDate});
    `;
    if (timeUnit === "hours") {
      code += `
       ${variable_result}.setHours( ${variable_result}.getHours() - ${value_time});
      `;
    } else if (timeUnit === "minutes") {
      code += `
       ${variable_result}.setMinutes( ${variable_result}.getMinutes() - ${value_time});
      `;
    } else if (timeUnit === "seconds") {
      code += `
       ${variable_result}.setSeconds( ${variable_result}.getSeconds() - ${value_time});
      `;
    }
  }
  return code;
};

Blockly.Blocks["increment_decrement_time_current"] = {
  init: function () {
    this.appendDummyInput()
      .appendField("Increment/Decrement Current Time :")
      .appendField(
        new Blockly.FieldDropdown([
          ["increment", "INCREMENT"],
          ["decrement", "DECREMENT"],
        ]),
        "OPERATION"
      );
    this.appendDummyInput()
      .appendField("Choose The Unit :")
      .appendField(
        new Blockly.FieldDropdown([
          ["hours", "hours"],
          ["minutes", "minutes"],
          ["seconds", "seconds"],
        ]),
        "PART"
      );
    this.appendValueInput("DAYS_HOURS")
      .appendField("Insert Increment/Decrement Value :")
      .setCheck("Number");
    this.appendValueInput("RESULT_VAR")
      .setCheck("Variable")
      .appendField("Store New Date In Variable:");
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour("#1976D2");
    this.setTooltip("");
    this.setHelpUrl("");
  },
};

JavaScript["increment_decrement_time_current"] = function (block) {
  var dropdown_operation = block.getFieldValue("OPERATION");
  var timeUnit = block.getFieldValue("PART");
  var value_time = JavaScript.valueToCode(
    block,
    "DAYS_HOURS",
    JavaScript.ORDER_ATOMIC
  );
  var variable_result = JavaScript.valueToCode(
    block,
    "RESULT_VAR",
    JavaScript.ORDER_ASSIGNMENT
  );

  var code = "";
  if (dropdown_operation == "INCREMENT") {
    code = `
    ${variable_result} = new Date();
    `;
    if (timeUnit === "hours") {
      code += `
       ${variable_result}.setHours( ${variable_result}.getHours() + ${value_time});
      `;
    } else if (timeUnit === "minutes") {
      code += `
       ${variable_result}.setMinutes( ${variable_result}.getMinutes() + ${value_time});
      `;
    } else if (timeUnit === "seconds") {
      code += `
       ${variable_result}.setSeconds( ${variable_result}.getSeconds() + ${value_time});
      `;
    }
  } else {
    code = `
    ${variable_result} = new Date();
    `;
    if (timeUnit === "hours") {
      code += `
       ${variable_result}.setHours( ${variable_result}.getHours() - ${value_time});
      `;
    } else if (timeUnit === "minutes") {
      code += `
       ${variable_result}.setMinutes( ${variable_result}.getMinutes() - ${value_time});
      `;
    } else if (timeUnit === "seconds") {
      code += `
       ${variable_result}.setSeconds( ${variable_result}.getSeconds() - ${value_time});
      `;
    }
  }
  return code;
};

Blockly.Blocks["convert_to_date_object"] = {
  init: function () {
    this.appendDummyInput().appendField("Convert Date String To Date Object");
    this.appendValueInput("DATE_STR")
      .setCheck(null)
      .appendField("Insert A Date String :");
    this.appendValueInput("RESULT_VAR")
      .setCheck("Variable")
      .appendField("Store Converted Date In Variable:");
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour("#1976D2");
    this.setTooltip("");
    this.setHelpUrl("");
  },
};

JavaScript["convert_to_date_object"] = function (block) {
  var variable_result = JavaScript.valueToCode(
    block,
    "RESULT_VAR",
    JavaScript.ORDER_ASSIGNMENT
  );
  var dateVar = JavaScript.valueToCode(
    block,
    "DATE_STR",
    JavaScript.ORDER_ASSIGNMENT
  );

  let code = `
  ${variable_result}=new Date(${dateVar})
  `;

  return code;
};

// OTP GENERATION BLOCK:

Blockly.Blocks["random_number_generator"] = {
  init: function () {
    this.appendDummyInput().appendField("Generate A Random Number :");
    this.appendValueInput("LENGTH_NUM")
      .setCheck("Number")
      .appendField("Number Of Digits Needed :");
    this.appendValueInput("RESULT_VAR")
      .setCheck("Variable")
      .appendField("Store Generated Random Number In Variable:");
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour("#1976D2");
    this.setTooltip("");
    this.setHelpUrl("");
  },
};

JavaScript["random_number_generator"] = function (block) {
  var variable_result = JavaScript.valueToCode(
    block,
    "RESULT_VAR",
    JavaScript.ORDER_ASSIGNMENT
  );
  var dateVar = JavaScript.valueToCode(
    block,
    "LENGTH_NUM",
    JavaScript.ORDER_ASSIGNMENT
  );

  let code = `
let min${variable_result} = Math.pow(10, ${dateVar} - 1);
let max${variable_result} = Math.pow(10, ${dateVar}) - 1;
let randomNumber${variable_result} = Math.floor(Math.random() * (max${variable_result} - min${variable_result} + 1)) + min${variable_result};

${variable_result} = Math.round(randomNumber${variable_result} / 10) * 10;
  `;

  return code;
};

Blockly.Blocks["azzure_oauth"] = {
  init: function () {
    this.appendDummyInput().appendField("OAUTH using Azure :");
    this.appendValueInput("CLINET_ID").appendField("Enter Client Id:");
    this.appendValueInput("CLIENT_SECRET").appendField("Enter Client Secret:");
    this.appendValueInput("TENANT_ID").appendField("Enter Tenant Id:");
    this.appendValueInput("REDIRECT_URI").appendField("Enter Redirect Url:");
    this.appendStatementInput("SUCCESS")
      .setCheck(null)
      .appendField("on Success:");
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour("#1976D2");
    this.setTooltip("");
    this.setHelpUrl("");
  },
};

JavaScript["azzure_oauth"] = function (block) {
  var clientId = JavaScript.valueToCode(
    block,
    "CLINET_ID",
    JavaScript.ORDER_ASSIGNMENT
  );
  var clientSecret = JavaScript.valueToCode(
    block,
    "CLIENT_SECRET",
    JavaScript.ORDER_ASSIGNMENT
  );
  var tenantId = JavaScript.valueToCode(
    block,
    "TENANT_ID",
    JavaScript.ORDER_ASSIGNMENT
  );
  var redirectUri = JavaScript.valueToCode(
    block,
    "REDIRECT_URI",
    JavaScript.ORDER_ASSIGNMENT
  );

  var successCode = JavaScript.statementToCode(block, "SUCCESS");

  tenantId = tenantId.replace(/^['"]|['"]$/g, "");

  let code = `
  const msalConfig = {
    auth: {
      clientId: ${clientId},
      clientSecret: ${clientSecret},
      authority: 'https://login.microsoftonline.com/${tenantId}',
      redirectUri:${redirectUri}
    },
    system: {
      loggerOptions: {
        loggerCallback(loglevel, message, containsPii) {
          console.log(message);
        },
        piiLoggingEnabled: false,
        logLevel: LogLevel.Info
      }
    }
  };
  
  const msalClient = new PublicClientApplication(msalConfig);

  const authCodeUrlParameters = {
    scopes: ['openid', 'profile', 'offline_access', 'Calendars.Read'],
    redirectUri: ${redirectUri}
  };

  const authUrl = await msalClient.getAuthCodeUrl(authCodeUrlParameters);

  res.redirect(authUrl);

  ${successCode}
  `;

  return code;
};

Blockly.Blocks["grab_current_user_data_after_auth"] = {
  init: function () {
    this.appendDummyInput().appendField(
      "Grab Current User Data After Auth Success :"
    );
    this.appendValueInput("JWT_SECRET").appendField(
      "Store Data In a Variable :"
    );
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour("#1976D2");
    this.setTooltip("");
    this.setHelpUrl("");
  },
};

JavaScript["grab_current_user_data_after_auth"] = function (block) {
  var jwtSecret = JavaScript.valueToCode(
    block,
    "JWT_SECRET",
    JavaScript.ORDER_ASSIGNMENT
  );

  let code = `
${jwtSecret}=req.user
  
  `;

  return code;
};

Blockly.Blocks["generate_hash"] = {
  init: function () {
    this.appendDummyInput().appendField("Generate Hash :");
    this.appendValueInput("KEY_REF").appendField("Enter Key to be Hashed:");
    this.appendValueInput("RES_VAR").appendField(
      "Store Hashed Key In Variable:"
    );
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour("#1976D2");
    this.setTooltip("");
    this.setHelpUrl("");
  },
};

JavaScript["generate_hash"] = function (block) {
  var hashKey = JavaScript.valueToCode(
    block,
    "KEY_REF",
    JavaScript.ORDER_ASSIGNMENT
  );
  var resVar = JavaScript.valueToCode(
    block,
    "RES_VAR",
    JavaScript.ORDER_ASSIGNMENT
  );

  let code = `
  let saltRounds${resVar} = 10;
 ${resVar} = await bcrypt.hash(${hashKey}, saltRounds${resVar})

 
  `;

  return code;
};

Blockly.Blocks["compare_hash"] = {
  init: function () {
    this.appendDummyInput().appendField("Compare Hash Values :");
    this.appendValueInput("KEY_REF").appendField("Enter Key To Be Compared :");
    this.appendValueInput("RES_VAR").appendField("Enter Stored Hashed Key:");
    this.appendStatementInput("SUCCESS")
      .setCheck(null)
      .appendField("Logic If Both Key Match :");
    this.appendStatementInput("FAILURE")
      .setCheck(null)
      .appendField("Logic If Both Key Do not Match:");
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour("#1976D2");
    this.setTooltip("");
    this.setHelpUrl("");
  },
};

JavaScript["compare_hash"] = function (block) {
  var hashKey = JavaScript.valueToCode(
    block,
    "KEY_REF",
    JavaScript.ORDER_ASSIGNMENT
  );
  var resVar = JavaScript.valueToCode(
    block,
    "RES_VAR",
    JavaScript.ORDER_ASSIGNMENT
  );

  var successCode = JavaScript.statementToCode(block, "SUCCESS");
  var failureCode = JavaScript.statementToCode(block, "FAILURE");

  let code = `
  bcrypt.compare(${hashKey}, ${resVar}, (err, result) => {
  if (err) {
    console.error('Error comparing password:', err);
  } else {
    if (result) {
      ${successCode}
    } else {
      ${failureCode}
    }
  }
});

 
  `;

  return code;
};

Blockly.Blocks["generate_token"] = {
  init: function () {
    this.appendDummyInput().appendField(
      "Generate Bearer Token For Middleware Flow :"
    );
    this.appendValueInput("KEY_REF").appendField(
      "Enter Key Used To Create Token:"
    );
    this.appendValueInput("RES_VAR").appendField(
      "Store Generated Token In Variable"
    );
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour("#1976D2");
    this.setTooltip("");
    this.setHelpUrl("");
  },
};

JavaScript["generate_token"] = function (block) {
  var hashKey = JavaScript.valueToCode(
    block,
    "KEY_REF",
    JavaScript.ORDER_ASSIGNMENT
  );
  var resVar = JavaScript.valueToCode(
    block,
    "RES_VAR",
    JavaScript.ORDER_ASSIGNMENT
  );

  let code = `
  
  ${resVar} = generateToken(${hashKey});

 
  `;

  return code;
};

Blockly.Blocks["verify_token"] = {
  init: function () {
    this.appendDummyInput().appendField("Verify Jwt Token For Instant Use :");
    this.appendValueInput("KEY_REF").appendField(
      "Enter Key Used To Create Token:"
    );
    this.appendValueInput("TOKEN_REF").appendField(
      "Enter Token Value To Compare:"
    );
    this.appendValueInput("RES_VAR").appendField(
      "Store Resulting Data In Variable"
    );
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour("#1976D2");
    this.setTooltip("");
    this.setHelpUrl("");
  },
};

JavaScript["verify_token"] = function (block) {
  var secretKey = JavaScript.valueToCode(
    block,
    "KEY_REF",
    JavaScript.ORDER_ASSIGNMENT
  );
  var tokenRef = JavaScript.valueToCode(
    block,
    "TOKEN_REF",
    JavaScript.ORDER_ASSIGNMENT
  );
  var resVar = JavaScript.valueToCode(
    block,
    "RES_VAR",
    JavaScript.ORDER_ASSIGNMENT
  );

  let code = `
  
  ${resVar} = jwt.verify(${tokenRef}, ${secretKey});

 
  `;

  return code;
};

Blockly.Blocks["generate_token_inst"] = {
  init: function () {
    this.appendDummyInput().appendField("Generate Jwt Token For Instant Use :");
    this.appendValueInput("KEY_REF").appendField(
      "Enter Key For Creating Token (unique):"
    );
    this.appendValueInput("TOKEN_REF").appendField(
      "Enter Id Of User For Which Token Is Generated :"
    );
    this.appendValueInput("DURATION").appendField(
      "Enter Duration for Token Expiry :"
    );
    this.appendValueInput("RES_VAR").appendField(
      "Store Resulting Data In Variable"
    );
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour("#1976D2");
    this.setTooltip("");
    this.setHelpUrl("");
  },
};

JavaScript["generate_token_inst"] = function (block) {
  var secretKey = JavaScript.valueToCode(
    block,
    "KEY_REF",
    JavaScript.ORDER_ASSIGNMENT
  );
  var tokenRef = JavaScript.valueToCode(
    block,
    "TOKEN_REF",
    JavaScript.ORDER_ASSIGNMENT
  );
  var durationRef = JavaScript.valueToCode(
    block,
    "DURATION",
    JavaScript.ORDER_ASSIGNMENT
  );
  var resVar = JavaScript.valueToCode(
    block,
    "RES_VAR",
    JavaScript.ORDER_ASSIGNMENT
  );

  let code = `
  
  ${resVar} = jwt.sign({ id: ${tokenRef} }, ${secretKey}, { expiresIn: ${durationRef} });
 
  `;

  return code;
};

Blockly.Blocks["push_notification_firebase_admin"] = {
  init: function () {
    this.appendDummyInput().appendField("Mobile Notification Firebase Setup:");
    this.appendDummyInput("Notification_Detail")
      .appendField("Select Notification Detail:")
      .appendField(new Blockly.FieldDropdown(notificationDetail), "arrayName");
    this.appendStatementInput("SUCCESS")
      .setCheck(null)
      .appendField("Logic If Both Key Match :");
    this.appendStatementInput("FAILURE")
      .setCheck(null)
      .appendField("Logic If Both Key Do not Match:");
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour("#004d40");
    this.setTooltip("Mobile Notification Firebase Setup for firebase admin");
    this.setHelpUrl("");
  },
};

JavaScript["push_notification_firebase_admin"] = function (block) {
  var notificationId = block.getFieldValue("arrayName");

  console.log("notificationId::::", notificationId);

  let notificationSelection;

  if (notificationId) {
    notificationSelection = allNotifications.find(
      (x) => x._id === notificationId
    );
  }

  var successCode = JavaScript.statementToCode(block, "SUCCESS");
  var failureCode = JavaScript.statementToCode(block, "FAILURE");

  let code;

  if (notificationSelection) {
    code = `
     
     const registrationToken = req.body.registrationToken;
     
     const message = {
       notification: {
         title: "${notificationSelection.title}",
         body: "${notificationSelection.body}",
       },
       webpush:{
          fcm_options:{
          link:"${notificationSelection.url}"
          },
            notification: {
        icon: "https://redsling.s3.amazonaws.com/1721903659292-Screenshot%202024-07-24%20094433.png",
      },
       },
       token: registrationToken
     };
     
     admin.messaging().send(message)
       .then((response) => {
         ${successCode}
       })
       .catch((error) => {
         ${failureCode}
       });
     
      
       `;
  } else {
    code = `
  
    let admin = require('firebase-admin');
   let serviceAccount = require('./service-account.json');
   
   admin.initializeApp({
     credential: admin.credential.cert(serviceAccount)
   });
   
   const registrationToken = req.body.registrationToken;
   
   const message = {
     notification: {
       title: 'Hello!',
       body: 'This is a test notification from Firebase'
     },
     token: registrationToken
   };
   
   admin.messaging().send(message)
     .then((response) => {
        ${successCode}
     })
     .catch((error) => {
        ${failureCode}
     });
   
    
     `;
  }

  return code;
};

Blockly.Blocks["masked_text_input"] = {
  init: function () {
    this.appendDummyInput()
      .appendField("Input:")
      .appendField(
        new Blockly.FieldTextInput("default", this.validator.bind(this)),
        "INPUT"
      );
    this.setOutput(true, "String");
    this.setColour(230);
    this.setTooltip("Enter text and toggle masking");
    this.setHelpUrl("");
    this.actualValue = "default";
  },

  validator: function (newValue) {
    this.actualValue = newValue;
    var maskedValue = newValue.replace(/./g, "*");
    return maskedValue;
  },
};

JavaScript["masked_text_input"] = function (block) {
  var actualText = block.actualValue;
  var code = `'${actualText}'`;
  return [code, JavaScript.ORDER_ATOMIC];
};

// WILL BE USED FOR ARRAY METHODS
// Blockly.Blocks["grab_data_from_database"] = {
//   init: function () {
//     this.appendDummyInput().appendField("Grab Filtered Data From Database");
//     this.appendDummyInput("Custom_Model")
//       .appendField("Select Custom Model:")
//       .appendField(new Blockly.FieldDropdown(tableName), "arrayName");
//     this.appendDummyInput("FILTER_METHOD")
//       .appendField("Filter Method:")
//       .appendField(
//         new Blockly.FieldDropdown([
//           ["map", "map"],
//           ["filter", "filter"],
//           ["find", "find"],
//           ["reduce", "reduce"],
//         ]),
//         "method"
//       );
//     this.appendValueInput("property")
//       .appendField("Property from object:")
//       .setCheck(["String", "Number", "Boolean"]);
//     this.appendDummyInput("Operator")
//       .appendField("Select Operator:")
//       .appendField(
//         new Blockly.FieldDropdown([
//           ["=", "==="],
//           ["<", "<"],
//           [">", ">"],
//           ["<=", "<="],
//           [">=", ">="],
//           ["!=", "!="],
//         ]),
//         "comparison"
//       );
//     this.appendValueInput("value")
//       .appendField("Value")
//       .setCheck(["String", "Number", "Boolean"]);
//     this.setOutput(true, null);
//     this.setColour("#004d40");
//     this.setTooltip("Mongo Array Method");
//     this.setHelpUrl("");
//   },
// };

// JavaScript["grab_data_from_database"] = function (block) {
//   var arrayName = block.getFieldValue("arrayName");
//   var method = block.getFieldValue("method");
//   var property = JavaScript.valueToCode(
//     block,
//     "property",
//     JavaScript.ORDER_ATOMIC
//   );
//   var comparison = block.getFieldValue("comparison");
//   var value = JavaScript.valueToCode(block, "value", JavaScript.ORDER_ATOMIC);

//   var code;

//   if (method !== "reduce") {
//     code = `
//     ${arrayName}.${method}((arg) => arg[${property}] ${comparison} ${value})

//     `;
//   } else {
//     code = `

//     `;
//   }

//   return [code, JavaScript.ORDER_FUNCTION_CALL];
// };
