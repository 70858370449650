import { LOAD_FUNCTIONS_FAILED, LOAD_FUNCTIONS_SUCCESS, LOAD_FUNCTIONS_LOADING } from "../Constants/functionsConstants";

export const getFunctionsReducer = (state={}, action) => {
    switch(action.type) {
        case LOAD_FUNCTIONS_LOADING: 
            return { getReqLoading: true }
        case LOAD_FUNCTIONS_SUCCESS: 
            return { getReqLoading: false, functions: action.payload }
        case LOAD_FUNCTIONS_FAILED: 
            return { getReqLoading: false, functions: [], getFunctionsError: action.payload }
        default:
            return state
    }
}