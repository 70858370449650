import * as XLSX from "xlsx";
import Papa from 'papaparse';
import { API_HOST } from "./api_utils/index";

export default function handleFile(file, editor, table_name, project_id) {
  // Create a FormData object to send the file
  const formData = new FormData();
  formData.append('file', file); // Use the same field name 'file' as expected on the server

  // Determine the file type (Excel or CSV)
  if (file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
    // Handle Excel file (XLSX)
    // handleExcelFile(file, editor);

    // Now, make a fetch call to send the file to the backend
    fetch(`${API_HOST}imports/upload/${project_id}/${table_name}`, {
      method: 'POST',
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        // check for the message if it includes the word "Added", then proceed
        if (data.message.includes('Added')) {
          console.log('data:', data); // Handle the response from the server

          handleExcelFile(file, editor, table_name);
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      });

    console.log('sent excel file to backend');
  } else if (file.type === "text/csv") {
    // Handle CSV file
    // handleCsvFile(file, editor);

    // Now, make a fetch call to send the file to the backend
    fetch(`${API_HOST}imports/upload/${project_id}/${table_name}`, {
      method: 'POST',
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        // check for the message if it includes the word "Added", then proceed
        if (data.message.includes('Added')) {
          console.log('data:', data); // Handle the response from the server

          handleCsvFile(file, editor, table_name);
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      });
    console.log('sent csv file to backend');
  } else {
    // Handle other file types or show an error
    console.error("Unsupported file type");
  }
}

function handleExcelFile(file, editor, table_name) {
  // const reader = new FileReader();

  // reader.onload = (e) => {
  //   const data = e.target.result;
  //   const workbook = XLSX.read(data, { type: "binary" });
  //   const sheetName = workbook.SheetNames[0];
  //   const worksheet = workbook.Sheets[sheetName];
  //   const jsonData = XLSX.utils.sheet_to_json(worksheet);

  //   console.log('excel file jsonData:', jsonData);

  //   // Process jsonData and add it to the GrapesJS editor
  //   // You can create and append new elements to the canvas here

  //   if (jsonData) {
  //     // jsonData.forEach((item) => {
  //       // Create and append elements to the GrapesJS editor canvas
  //       // const newElement = editor.DomComponents.getWrapper().append(item.tag);
  //       // const newElement = editor.getSelected().append(item.tag);
        
  //       // Create the table element
  //       const table = document.createElement("table");
  //       table.style.width = "100%";
  //       table.style.borderCollapse = "collapse";
  //       table.style.margin = "0 auto";

  //       // Create the table header
  //       const tableHeader = document.createElement("thead");
  //       const headerRow = document.createElement("tr");

  //       for (const key in jsonData[0]) {
  //         const th = document.createElement("th");
  //         th.innerHTML = `<p style="font-size: 0.8em">${key}</p>`;
  //         headerRow.appendChild(th);
  //       }

  //       tableHeader.appendChild(headerRow);
  //       table.appendChild(tableHeader);

  //       // Create the table body
  //       const tableBody = document.createElement("tbody");

  //       jsonData.forEach((item) => {
  //         const row = document.createElement("tr");

  //         for (const key in item) {
  //           const cell = document.createElement("td");
  //           cell.innerHTML = `<p style="font-size: 0.8em">${item[key]}</p>`;
  //           row.appendChild(cell);
  //         }

  //         tableBody.appendChild(row);
  //       });

  //       table.appendChild(tableBody);

  //       // Set CSS for table cells and headers
  //       const tableCells = table.querySelectorAll("td, th");
  //       tableCells.forEach((cell) => {
  //         cell.style.padding = "8px";
  //         cell.style.textAlign = "left";
  //         cell.style.borderBottom = "1px solid #ddd";
  //       });

  //       // Create a container div for the table
  //       const tableWrapper = document.createElement("div");
  //       tableWrapper.style.overflowX = "auto"; // Enable horizontal scrolling
  //       tableWrapper.appendChild(table);

  //       // Append the table to the container
  //       editor.DomComponents.getWrapper().append(tableWrapper.outerHTML);
  //       console.log('excel file data appended');
  //     // });
  //   }
  // };

  // reader.readAsBinaryString(file);
  // console.log('read this excel file');



  // CREATING TABLE, PAGINATING DATA, DISPLAYING IN EDITOR
  // Assuming you have a container to render the table
  const tableContainer = editor.DomComponents.getWrapper().find('#table-container')[0].getEl();
  const nextButton = editor.DomComponents.getWrapper().find('#next-button')[0].getEl();
  const prevButton = editor.DomComponents.getWrapper().find('#prev-button')[0].getEl();

  console.log('tableContainer, nextButton, prevButton:', tableContainer, nextButton, prevButton);

  let currentPage = 1;
  const itemsPerPage = 5;

  // Function to render the table
  function renderTable(data) {
    // Clear the table container
    let tableContainer = editor.DomComponents.getWrapper().find('#table-container')[0].getEl();
    tableContainer.innerHTML = '';

    console.log('tableContainer:', tableContainer);

    const table = document.createElement('table');
    table.style.width = '100%';
    table.style.borderCollapse = 'collapse';
    table.style.margin = "0 auto";
    table.scrollIntoView({ behavior: 'smooth' });

    // Create table header (TH)
    const headerRow = document.createElement('tr');
    for (const key in data[0]) {
      const th = document.createElement('th');
      th.innerHTML = `<p style="font-size: 0.8em">${key}</p>`;
      headerRow.appendChild(th);
    }
    table.appendChild(headerRow);

    // Calculate the start and end index for pagination
    // const startIdx = (currentPage - 1) * itemsPerPage;
    // const endIdx = currentPage * itemsPerPage;

    // Create table rows (TR) and cells (TD)
    // for (let i = startIdx; i < endIdx && i < data.length; i++) {
    //   const item = data[i];
    //   const row = document.createElement('tr');
    //   for (const key in item) {
    //     const cell = document.createElement('td');
    //     cell.style.padding = "8px";
    //     cell.style.textAlign = "left";
    //     cell.style.borderBottom = "1px solid #ddd";
    //     cell.innerHTML = `<p style="font-size: 0.8em">${item[key]}</p>`;
        
    //     row.appendChild(cell);
    //   }
    //   table.appendChild(row);
    // }

    data.forEach((x) => {
      const row = document.createElement('tr');
      for (const key in x) {
        const cell = document.createElement('td');
        cell.style.padding = "8px";
        cell.style.textAlign = "left";
        cell.style.borderBottom = "1px solid #ddd";
        cell.innerHTML = `<p style="font-size: 0.8em">${x[key]}</p>`;
        
        row.appendChild(cell);
      }
      table.appendChild(row);
    });

    // tableContainer.appendChild(table);
    tableContainer.innerHTML = table.outerHTML;
  }

  let data;
  // Function to handle pagination
  function handlePagination(action) {
    // Check if we should increment or decrement the page
    if (action === 'next' && data.next) {
      currentPage = data.next.page;
    } else if (action === 'prev' && data.previous) {
      currentPage = data.previous.page;
    }

    // Make a fetch call to your API with pagination parameters
    fetch(`${API_HOST}imports/getExcelData?tableName=${table_name}&page=${currentPage}&limit=${itemsPerPage}`)
      .then((response) => response.json())
      .then((responseData) => {
        data = responseData; // Update the global data variable

        // Enable/Disable pagination buttons based on data
        nextButton.disabled = !data.next;
        prevButton.disabled = !data.previous;

        // Render the table with the received data
        renderTable(data.results);

        // Update the current page
        // currentPage = data.next ? data.next.page : data.previous ? data.previous.page : currentPage;

        // // Scroll to the top of the table
        // table.scrollIntoView({ behavior: 'smooth' });
      })
      .catch((error) => console.error(error));
  }

  // Event listeners for pagination buttons
  nextButton.addEventListener('click', () => {
    if (nextButton.disabled) return; // Do nothing if the button is disabled
    handlePagination('next');
  });

  prevButton.addEventListener('click', () => {
    if (prevButton.disabled) return; // Do nothing if the button is disabled
    handlePagination('prev');
  });

  // Initial call to load the table and set pagination
  handlePagination();
}

function handleCsvFile(file, editor, table_name) {
  // const reader = new FileReader();

  // reader.onload = async (e) => {
  //   const data = e.target.result;
  //   const results = [];

  //   // Use Papaparse to parse the CSV data
  //   Papa.parse(data, {
  //     header: true, // Assuming the first row is the header
  //     skipEmptyLines: true, // Skip empty lines
  //     complete: (parsedData) => {
  //       results.push(...parsedData.data);
  //       console.log('csv file results:', results);

  //       // Process results and add them to the GrapesJS editor
  //       // You can create and append new elements to the canvas here
  //     },
  //   });

  //   console.log('csv file results:', results);

  //   if (results) {
  //     // Create the table element
  //       const table = document.createElement("table");
  //       table.style.width = "100%";
  //       table.style.borderCollapse = "collapse";
  //       table.style.margin = "0 auto";

  //       // Create the table header
  //       const tableHeader = document.createElement("thead");
  //       const headerRow = document.createElement("tr");

  //       for (const key in results[0]) {
  //         const th = document.createElement("th");
  //         th.innerHTML = `<p style="font-size: 0.8em">${key}</p>`;
  //         headerRow.appendChild(th);
  //       }

  //       tableHeader.appendChild(headerRow);
  //       table.appendChild(tableHeader);

  //       // Create the table body
  //       const tableBody = document.createElement("tbody");

  //       results.forEach((item) => {
  //         const row = document.createElement("tr");

  //         for (const key in item) {
  //           const cell = document.createElement("td");
  //           cell.innerHTML = `<p style="font-size: 0.8em">${item[key]}</p>`;
  //           row.appendChild(cell);
  //         }

  //         tableBody.appendChild(row);
  //       });

  //       table.appendChild(tableBody);

  //       // Set CSS for table cells and headers
  //       const tableCells = table.querySelectorAll("td, th");
  //       tableCells.forEach((cell) => {
  //         cell.style.padding = "8px";
  //         cell.style.textAlign = "left";
  //         cell.style.borderBottom = "1px solid #ddd";
  //       });

  //       // Create a container div for the table
  //       const tableWrapper = document.createElement("div");
  //       tableWrapper.style.overflowX = "auto"; // Enable horizontal scrolling
  //       tableWrapper.appendChild(table);

  //       // Append the table to the container
  //       editor.DomComponents.getWrapper().append(tableWrapper.outerHTML);
  //       console.log('csv file data appended');
  //   }

  //   // Process results and add them to the GrapesJS editor
  //   // You can create and append new elements to the canvas here
  // };

  // reader.readAsText(file);
  // console.log('read this csv file');



  // CREATING TABLE, PAGINATING DATA, DISPLAYING IN EDITOR
  // Assuming you have a container to render the table
  const tableContainer = editor.DomComponents.getWrapper().find('#table-container')[0].getEl();
  const nextButton = editor.DomComponents.getWrapper().find('#next-button')[0].getEl();
  const prevButton = editor.DomComponents.getWrapper().find('#prev-button')[0].getEl();

  console.log('tableContainer, nextButton, prevButton:', tableContainer, nextButton, prevButton);

  let currentPage = 1;
  const itemsPerPage = 5;

  // Function to render the table
  function renderTable(data) {
    // Clear the table container
    let tableContainer = editor.DomComponents.getWrapper().find('#table-container')[0].getEl();
    tableContainer.innerHTML = '';

    console.log('tableContainer:', tableContainer);

    const table = document.createElement('table');
    table.style.width = '100%';
    table.style.borderCollapse = 'collapse';
    table.style.margin = "0 auto";
    table.scrollIntoView({ behavior: 'smooth' });

    // Create table header (TH)
    const headerRow = document.createElement('tr');
    for (const key in data[0]) {
      const th = document.createElement('th');
      th.innerHTML = `<p style="font-size: 0.8em">${key}</p>`;
      headerRow.appendChild(th);
    }
    table.appendChild(headerRow);

    // Calculate the start and end index for pagination
    // const startIdx = (currentPage - 1) * itemsPerPage;
    // const endIdx = currentPage * itemsPerPage;

    // Create table rows (TR) and cells (TD)
    // for (let i = startIdx; i < endIdx && i < data.length; i++) {
    //   const item = data[i];
    //   const row = document.createElement('tr');
    //   for (const key in item) {
    //     const cell = document.createElement('td');
    //     cell.style.padding = "8px";
    //     cell.style.textAlign = "left";
    //     cell.style.borderBottom = "1px solid #ddd";
    //     cell.innerHTML = `<p style="font-size: 0.8em">${item[key]}</p>`;
        
    //     row.appendChild(cell);
    //   }
    //   table.appendChild(row);
    // }

    data.forEach((x) => {
      const row = document.createElement('tr');
      for (const key in x) {
        const cell = document.createElement('td');
        cell.style.padding = "8px";
        cell.style.textAlign = "left";
        cell.style.borderBottom = "1px solid #ddd";
        cell.innerHTML = `<p style="font-size: 0.8em">${x[key]}</p>`;
        
        row.appendChild(cell);
      }
      table.appendChild(row);
    });

    // tableContainer.appendChild(table);
    tableContainer.innerHTML = table.outerHTML;
  }

  let data;
  // Function to handle pagination
  function handlePagination(action) {
    // Check if we should increment or decrement the page
    if (action === 'next' && data.next) {
      currentPage = data.next.page;
    } else if (action === 'prev' && data.previous) {
      currentPage = data.previous.page;
    }

    // Make a fetch call to your API with pagination parameters
    fetch(`${API_HOST}imports/getExcelData?tableName=${table_name}&page=${currentPage}&limit=${itemsPerPage}`)
      .then((response) => response.json())
      .then((responseData) => {
        data = responseData; // Update the global data variable

        // Enable/Disable pagination buttons based on data
        nextButton.disabled = !data.next;
        prevButton.disabled = !data.previous;

        // Render the table with the received data
        renderTable(data.results);

        // Update the current page
        // currentPage = data.next ? data.next.page : data.previous ? data.previous.page : currentPage;

        // // Scroll to the top of the table
        // table.scrollIntoView({ behavior: 'smooth' });
      })
      .catch((error) => console.error(error));
  }

  // Event listeners for pagination buttons
  nextButton.addEventListener('click', () => {
    if (nextButton.disabled) return; // Do nothing if the button is disabled
    handlePagination('next');
  });

  prevButton.addEventListener('click', () => {
    if (prevButton.disabled) return; // Do nothing if the button is disabled
    handlePagination('prev');
  });

  // Initial call to load the table and set pagination
  handlePagination();
}

export function viewImport(editor, table_name) {
  // CREATING TABLE, PAGINATING DATA, DISPLAYING IN EDITOR
  // Assuming you have a container to render the table
  const tableContainer = editor.DomComponents.getWrapper().find('#table-container')[0].getEl();
  const nextButton = editor.DomComponents.getWrapper().find('#next-button')[0].getEl();
  const prevButton = editor.DomComponents.getWrapper().find('#prev-button')[0].getEl();

  console.log('tableContainer, nextButton, prevButton:', tableContainer, nextButton, prevButton);

  let currentPage = 1;
  const itemsPerPage = 5;

  // Function to render the table
  function renderTable(data) {
    // Clear the table container
    let tableContainer = editor.DomComponents.getWrapper().find('#table-container')[0].getEl();
    tableContainer.innerHTML = '';

    console.log('tableContainer:', tableContainer);

    const table = document.createElement('table');
    table.style.width = '100%';
    table.style.borderCollapse = 'collapse';
    table.style.margin = "0 auto";
    table.scrollIntoView({ behavior: 'smooth' });

    // Create table header (TH)
    const headerRow = document.createElement('tr');
    for (const key in data[0]) {
      const th = document.createElement('th');
      th.innerHTML = `<p style="font-size: 0.8em">${key}</p>`;
      headerRow.appendChild(th);
    }
    table.appendChild(headerRow);

    // Calculate the start and end index for pagination
    // const startIdx = (currentPage - 1) * itemsPerPage;
    // const endIdx = currentPage * itemsPerPage;

    // Create table rows (TR) and cells (TD)
    // for (let i = startIdx; i < endIdx && i < data.length; i++) {
    //   const item = data[i];
    //   const row = document.createElement('tr');
    //   for (const key in item) {
    //     const cell = document.createElement('td');
    //     cell.style.padding = "8px";
    //     cell.style.textAlign = "left";
    //     cell.style.borderBottom = "1px solid #ddd";
    //     cell.innerHTML = `<p style="font-size: 0.8em">${item[key]}</p>`;
        
    //     row.appendChild(cell);
    //   }
    //   table.appendChild(row);
    // }

    data.forEach((x) => {
      const row = document.createElement('tr');
      for (const key in x) {
        const cell = document.createElement('td');
        cell.style.padding = "8px";
        cell.style.textAlign = "left";
        cell.style.borderBottom = "1px solid #ddd";
        cell.innerHTML = `<p style="font-size: 0.8em">${x[key]}</p>`;
        
        row.appendChild(cell);
      }
      table.appendChild(row);
    });

    // tableContainer.appendChild(table);
    tableContainer.innerHTML = table.outerHTML;
  }

  let data;
  // Function to handle pagination
  function handlePagination(action) {
    // Check if we should increment or decrement the page
    if (action === 'next' && data.next) {
      currentPage = data.next.page;
    } else if (action === 'prev' && data.previous) {
      currentPage = data.previous.page;
    }

    // Make a fetch call to your API with pagination parameters
    fetch(`${API_HOST}imports/getExcelData?tableName=${table_name}&page=${currentPage}&limit=${itemsPerPage}`)
      .then((response) => response.json())
      .then((responseData) => {
        data = responseData; // Update the global data variable

        // Enable/Disable pagination buttons based on data
        nextButton.disabled = !data.next;
        prevButton.disabled = !data.previous;

        // Render the table with the received data
        renderTable(data.results);

        // Update the current page
        // currentPage = data.next ? data.next.page : data.previous ? data.previous.page : currentPage;

        // // Scroll to the top of the table
        // table.scrollIntoView({ behavior: 'smooth' });
      })
      .catch((error) => console.error(error));
  }

  // Event listeners for pagination buttons
  nextButton.addEventListener('click', () => {
    if (nextButton.disabled) return; // Do nothing if the button is disabled
    handlePagination('next');
  });

  prevButton.addEventListener('click', () => {
    if (prevButton.disabled) return; // Do nothing if the button is disabled
    handlePagination('prev');
  });

  // Initial call to load the table and set pagination
  handlePagination();
}