import { Avatar, Button, Grid, Modal, Paper, TextField, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React, { useState, useEffect } from 'react'
import profileHead from './component_assets/images/details_close.png'
// import profileHead from './component_assets/images/profileHeadScreen.svg'
import userProfileImg from './component_assets/images/details_close.png'
// import userProfileImg from './component_assets/images/profileUser1.svg'
import userWave from './component_assets/images/details_close.png'
// import userWave from './component_assets/images/userWave1.svg'
import BorderColorIcon from '@mui/icons-material/BorderColor';
import LayersIcon from '@mui/icons-material/Layers';
import ShareIcon from '@mui/icons-material/Share';
import ReviewsIcon from '@mui/icons-material/Reviews';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import PersonIcon from '@mui/icons-material/Person';
import { getUserProfile, updateProfile } from '../Redux/Actions/userProfileActions'
import { useSelector, useDispatch } from 'react-redux'
import unknownUser from './component_assets/images/details_close.png'
// import unknownUser from './component_assets/images/unknownUserProfile.jpg'
import EditIcon from '@mui/icons-material/Edit';
import { getAllProject } from '../Redux/Actions/projectActions'
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import CircularProgress from '@mui/material/CircularProgress';
import { API_HOST } from '../api_utils'
import { toast, ToastContainer } from 'react-toastify'
import "react-toastify/dist/ReactToastify.css"



const errorTheme = {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
}



function UserProfileScreen(props) {
    let dispatch = useDispatch()
    let navigate = useNavigate()
    let hoverEffect = props.hover
    const { userProfile } = useSelector(state => state.userProfile)
    const { allProjectData } = useSelector(state => state.allProjectData)
    const { userInfo } = useSelector(state => state.userLogin)
    const [open, setOpen] = useState(false)
    const [coverSuccess, setCoverSuccess] = useState(false)
    const [coverLoading, setCoverLoading] = useState(false)
    const [profileSuccess, setProfileSuccess] = useState(false)
    const [profileLoading, setProfileLoading] = useState(false)
    const [name, setName] = useState(userInfo?.name)
    const [email, setEmail] = useState(userInfo?.email)
    const [pass, setPass] = useState('')
    const [newPass, setNewPass] = useState('')
    const [allowSubmit, setAllowSubmit] = useState(false)


    useEffect(() => {
        dispatch(getAllProject())
    }, [])

    const handleClose = () => {
        setOpen(false)
    }

    const coverImageHandler = async (files) => {
        setCoverLoading(true)

        let file = files[0]
        let formData = new FormData()
        formData.append('image', file)
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${userInfo?.token}`
            }
        }

        const { data } = await axios.post(`${API_HOST}uploadRoutes/cover/${userInfo?._id}/cover`, formData, config);
        if (data) {
            setCoverLoading(false)
            setCoverSuccess(true)

        }

    }

    const profileImageHandler = async (files) => {
        setProfileLoading(true)

        let file = files[0]
        let formData = new FormData()
        formData.append('image', file)
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${userInfo?.token}`
            }
        }

        const { data } = await axios.post(`${API_HOST}uploadRoutes/cover/${userInfo?._id}/profile`, formData, config);
        if (data) {
            setProfileLoading(false)
            setProfileSuccess(true)

        }

    }

    const updateProdileHandler = () => {
        if (name.length && email.length && !pass.length && !newPass.length) {
            dispatch(updateProfile({ name: name, email: email }))
            setOpen(false)
        }

        if (pass.length && newPass.length) {
            if (pass === newPass) {
                dispatch(updateProfile({ password: pass }))
                setOpen(false)
            } else {
                toast.error('passwords do not match', errorTheme)
            }
        }
        if (pass.length && !newPass.length) {
            toast.error('Please Confirm the password', errorTheme)
        }

        if (!name.length || !email.length) {
            toast.error("Name and Email Can't be empty", errorTheme)
        }
    }

    useEffect(() => {
        dispatch(getUserProfile())
    }, [coverSuccess, profileSuccess])

    const handleProjectDetail = (id) => {
        navigate(`/projectList/${id}`)
    }



    return (
        <Grid sx={{ position: 'relative', height: "fit-content" }}>
            <Grid xs={12} sx={{ height: '65vh', overflow: 'hidden', position: "relative", transitionDuration: '0.6s' }}>
                {
                    coverLoading ? <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', overflow: 'hidden', position: "relative", transitionDuration: '0.6s', height: '60vh' }}> < CircularProgress color="secondary" /></Box> :
                        <img src={userProfile?.cover_image ? userProfile?.cover_image : profileHead} style={{ width: "100%", height: '100%', objectFit: 'cover' }} />
                }
                <Box sx={{ opacity: 0, '&:hover': { opacity: '50%' }, transitionDuration: '0.8s' }}>
                    <Box sx={{ position: 'absolute', top: 0, height: '65vh', width: '100%', backgroundColor: 'black' }}>
                    </Box>
                    <Box sx={{ position: 'absolute', top: 0, height: '60vh', width: '100%', zIndex: 50 }}>
                        <label htmlFor='cover' variant='outlined' style={{ position: 'absolute', top: '50%', right: '25%', zIndex: 20, borderRadius: '27.5px', border: '2px solid white', color: 'white', padding: '1% 3%', '&:hover': { border: '2px solid white' }, fontFamily: 'Mont-Regular' }}>Add Cover Image</label>
                        <input onChange={(e) => coverImageHandler(e.target.files)} name="image" type="file" id="cover" style={{ display: 'none' }} />
                    </Box>
                </Box>
            </Grid>
            <Grid sx={{ height: '100%', backgroundColor: 'white', position: 'absolute', top: '0', left: "5vw", width: '25vw', boxShadow: 15 }}>
                <Grid sx={{ position: 'relative', overflow: "hidden" }}>
                    <Grid sx={{ position: 'relative', mt: '30vh', display: "flex", justifyContent: 'center' }}>
                        {
                            profileLoading ? <Box sx={{ width: '14vw', height: '30vh', zIndex: 20, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>< CircularProgress color="secondary" /></Box> : <Avatar src={userProfile?.profile_image ? userProfile?.profile_image : unknownUser} sx={{ width: '14vw', height: '30vh', zIndex: 20 }} />
                        }
                        <Box sx={{ width: '14vw', height: '30vh', zIndex: 20, backgroundColor: 'black', opacity: 0, '&:hover': { opacity: '70%' }, position: 'absolute', top: 0, borderRadius: '50% 50%', transitionDuration: '0.8s', easing: 'ease-in-out', display: 'flex', justifyContent: "center", alignItems: 'center', cursor: 'pointer' }} >
                            <Box sx={{ alignItems: 'center', display: 'flex', justifyContent: 'center', border: '2px solid white', padding: '0vh 1vw', borderRadius: '10px' }}>
                                <Typography sx={{ color: 'white', mr: '0.5vw', fontSize: '0.8rem' }}>
                                    <EditIcon />
                                </Typography>
                                <label htmlFor='edit' style={{ fontFamily: "Mont-Regular", color: 'white', fontSize: '1.2rem', marginTop: '10px' }} >Edit</label>
                                <input onChange={(e) => profileImageHandler(e.target.files)} type="file" id="edit" style={{ "display": 'none' }} />
                            </Box>
                        </Box>
                    </Grid>
                    <Box sx={{ position: 'absolute', top: '10vh', right: '-13vw', scale: '125%' }}>
                        <img src={userWave} />
                    </Box>
                    <Box onClick={() => setOpen(!open)} sx={{ display: "flex", justifyContent: 'center', mt: 4 }}>
                        <Button variant='contained' sx={{ backgroundColor: 'red', borderRadius: '27.5px', padding: '0.5rem 1vw', textTransform: 'none', }}>
                            <Typography sx={{ mr: 1 }}>
                                <BorderColorIcon />
                            </Typography>
                            <Typography sx={{ fontFamily: 'Mont-Regular' }}>Edit your Profile</Typography>
                        </Button>
                    </Box>
                    <Typography sx={{ textAlign: 'center', fontFamily: 'Mont-Regular', color: '#657581', mt: 30 }}>Privacy Policy</Typography>
                    <Typography sx={{ textAlign: 'center', fontFamily: 'Mont-Regular', color: '#657581', mt: 2 }}>Terms and Conditions</Typography>
                </Grid>
            </Grid>
            <Grid sx={{ display: 'flex' }}>
                <Grid xs={4.1}></Grid>
                <Grid sx={{ mt: 2, width: '100%', padding: '0 6vw 0 9vw' }}>
                    <Typography sx={{ fontFamily: 'Mont-SemiBold', fontSize: '2rem' }}>{userInfo?.name}</Typography>
                    <Typography sx={{ fontFamily: 'Mont-Regular' }}>{userInfo?.email}</Typography>
                    <Box sx={{ display: "flex", aligItems: 'center', padding: '1vh 0 3vh 0', mt: 4 }}>
                        <Paper onClick={() => navigate('/projectList')} elevation={2} sx={{ width: '12vw', height: '24vh', mr: 4, display: "flex", justifyContent: "center", alignItems: "center", color: 'red', '&:hover': { boxShadow: 20 } }} >
                            <LayersIcon style={{ fontSize: '4.5rem' }} />
                        </Paper >
                        <Paper sx={{ width: '12vw', height: '24vh', mr: 4, display: "flex", justifyContent: "center", alignItems: "center", color: 'red', '&:hover': { boxShadow: 20 } }}>
                            <ShareIcon style={{ fontSize: '4.5rem' }} />
                        </Paper>

                        <Paper sx={{ width: '12vw', height: '24vh', display: "flex", justifyContent: "center", alignItems: "center", color: 'red', '&:hover': { boxShadow: 20 } }}>
                            <HelpOutlineIcon style={{ fontSize: '4.5rem' }} />
                        </Paper>
                    </Box>

                    <Box sx={{ mt: 8 }}>
                        {
                            allProjectData ? allProjectData.slice(0, 2).map((x) => <Paper onClick={() => handleProjectDetail(x._id)} elevation={3} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '2vh 1vw', mb: 2, '&:hover': { boxShadow: 20 }, cursor: 'pointer' }}>
                                <Typography sx={{ display: "flex", justifyContent: 'center', alignItems: 'center', color: 'red' }}>
                                    <LayersIcon style={{ fontSize: '4.5rem' }} />
                                </Typography>
                                <Box>
                                    <Typography sx={{ fontFamily: 'Mont-Regular', fontSize: '1rem' }}>{x.project_name}</Typography>
                                    <Typography sx={{ fontFamily: 'Mont-Regular', fontSize: '1rem' }}><strong>ID :</strong>{x._id.substring(0, 10)}...</Typography>
                                    <Typography sx={{ fontFamily: 'Mont-Regular', fontSize: '1rem' }}><strong>Facebook Id:</strong>{x.facebook_id}</Typography>
                                </Box>
                                <Box sx={{ display: "flex", flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                    <Typography sx={{ fontFamily: 'Mont-Regular', fontSize: '1rem' }}>Author</Typography>
                                    <Typography sx={{ color: 'red' }}>
                                        <PersonIcon />
                                    </Typography >
                                    <Typography sx={{ fontFamily: 'Mont-Regular', fontSize: '1rem' }}>{userInfo?.name}</Typography>
                                </Box>
                                <Box>
                                    <Typography sx={{ fontFamily: 'Mont-Regular', fontSize: '1rem' }}>Twitter Id:</Typography>
                                    <Typography sx={{ fontFamily: 'Mont-Regular', fontSize: '1rem' }}>LinkedIn Id:</Typography>
                                    <Typography sx={{ fontFamily: 'Mont-Regular', fontSize: '1rem' }}>Instagram Id:</Typography>
                                </Box>
                                <Box>
                                    <Typography sx={{ fontFamily: 'Mont-Regular', fontSize: '1rem' }}>{x.twitter_id}</Typography>
                                    <Typography sx={{ fontFamily: 'Mont-Regular', fontSize: '1rem' }}>{x.linkedIn_id}</Typography>
                                    <Typography sx={{ fontFamily: 'Mont-Regular', fontSize: '1rem' }}>{x.instagram_id}</Typography>
                                </Box>
                                <Box>
                                    <Typography sx={{ fontFamily: 'Mont-Regular', fontSize: '1rem' }}>Last Edited</Typography>
                                    <Typography sx={{ fontFamily: 'Mont-Regular', fontSize: '1rem' }}>{x.updatedAt}</Typography>
                                </Box>
                                <Box>

                                </Box>
                            </Paper>

                            ) : <Paper elevation={2} sx={{ width: '40vw', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', padding: '2vh 0' }}>
                                <Typography sx={{ fontFamily: 'Mont-Regular', fontSize: '1.2rem', mb: 4 }}>You Don't Have any projects yet!!!</Typography>
                                <Button onClick={() => navigate('/createProject')} sx={{ textTransform: 'none', fontFamily: 'Mont-Regular', fontSize: '1.2rem', backgroundColor: 'red', color: 'white', '&:hover': { backgroundColor: 'white', color: 'red', boxShadow: 10 }, transitionDuration: '0.5s' }}>Create Project</Button>
                            </Paper>
                        }
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: "center", alignItems: 'center', mb: 8, mt: 4 }}>
                        {
                            allProjectData?.length ? <Button onClick={() => navigate('/projectList')} variant='contained' sx={{ backgroundColor: 'red', color: 'white', '&:hover': { color: 'red', backgroundColor: 'white' }, transitionDuration: '0.6s' }}>
                                View All
                            </Button> : ''
                        }
                    </Box>

                </Grid>
            </Grid>



            <Modal
                open={open}
                onClose={handleClose}

                sx={{ border: 'none', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >

                <Box sx={{ width: { lg: '80vw' }, padding: '2vh 2vw 6vh 2vw', outline: 'none', borderRadius: '6px', backgroundColor: 'white', position: 'relative' }}>
                    <Grid sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button onClick={handleClose} sx={{ boxShadow: 2, color: 'red', '&:hover': { boxShadow: 5 } }}>
                            <CloseIcon />
                        </Button>
                    </Grid>
                    <Grid sx={{ display: "flex", justifyContent: 'space-between', alignItems: 'center', mt: 2 }} >
                        <Typography sx={{ fontFamily: "Mont-SemiBold", fontSize: '1.3rem' }}>Update Profile</Typography>
                        <Grid item xs={10.2} sx={{ backgroundColor: 'red', height: "1vh" }}></Grid>
                    </Grid>
                    <Grid sx={{ mt: 4, display: 'flex', justifyContent: 'space-between', flexDirection: 'column' }}>
                        <TextField id="outlined-basic" multiline label="Name" variant="outlined" sx={{ width: '100%' }} value={name} onChange={(e) => {
                            setName(e.target.value)
                            setAllowSubmit(true)
                        }} />
                        <TextField id="outlined-basic" multiline label="Email" variant="outlined" sx={{ width: '100%', mt: 2 }} value={email} onChange={(e) => {
                            setEmail(e.target.value)
                            setAllowSubmit(true)
                        }} />
                        <TextField id="outlined-basic" multiline label="Change Password" variant="outlined" sx={{ width: '100%', mt: 2 }} value={pass} onChange={(e) => {
                            setPass(e.target.value)
                            setAllowSubmit(true)
                        }} />
                        <TextField id="outlined-basic" multiline label="Confirm Password" variant="outlined" sx={{ width: '100%', mt: 2 }} value={newPass} onChange={(e) => {
                            setNewPass(e.target.value)
                            setAllowSubmit(true)
                        }} />
                        <Grid sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Button disabled={!allowSubmit} onClick={updateProdileHandler} variant='contained' sx={{ backgroundColor: 'red', '&:hover': { color: 'red', backgroundColor: 'white' }, padding: '1.5vh 2vw', textTransform: 'none', fontFamily: 'Mont-Regular', mt: 4 }}>Save Changes</Button>
                        </Grid>
                    </Grid>

                </Box>

            </Modal>

            <ToastContainer autoClose={2000} />




        </Grid>
    )
}

export default UserProfileScreen