import * as Blockly from "blockly";
import "./CustomBlocks";

const categoryStyles = {
  logic_category: {
    color: "#f43f5e",
  },
  loop_category: {
    color: "#facc15",
  },
  list_category: {
    color: "#fde68a",
  },
  math_category: {
    color: "#f43f5e",
  },
  text_category: {
    color: "#84cc16",
  },
  variable_category: {
    colour: "#01579b",
  },
  procedure_category: {
    colour: "#4a148c",
  },
  custom_category: {
    colour: "#f43f5e",
  },
};

const defaultBlockStyles = {
  logic_blocks: {
    colourPrimary: "#f43f5e",
    colourSecondary: "#fda4af",
    colourTertiary: "#ffe4e6",
  },
  loop_blocks: {
    colourPrimary: "#33691e",
    colourSecondary: "#9AFF78",
    colourTertiary: "#E1FFD7",
  },
  math_blocks: {
    colourPrimary: "#1a237e",
    colourSecondary: "#8A9EFF",
    colourTertiary: "#DCE2FF",
  },
  procedure_blocks: {
    colourPrimary: "#006064",
    colourSecondary: "#77E6EE",
    colourTertiary: "#CFECEE",
  },
  text_blocks: {
    colourPrimary: "#004d40",
    colourSecondary: "#5ae27c",
    colourTertiary: "#D2FFDD",
  },
  variable_blocks: {
    colourPrimary: "#880e4f",
    colourSecondary: "#FF73BE",
    colourTertiary: "#FFD4EB",
  },
  custom_blocks: {
    colourPrimary: "#f43f5e",
    colourSecondary: "#fda4af",
    colourTertiary: "#ffe4e6",
  },
  list_blocks: {
    colourPrimary: "#fbbf24",
    colourSecondary: "#fcd34d",
    colourTertiary: "#fde68a",
  },
};

export const toolbox = {
  kind: "categoryToolbox",
  contents: [
    {
      kind: "category",
      name: "Logic",
      categorystyle: "logic_category",
      contents: [
        {
          kind: "category",
          name: "If",
          contents: [
            {
              kind: "block",
              type: "controls_if",
              mutation: {
                elseif: "1",
                else: "1",
              },
            },
            {
              kind: "block",
              type: "switch_block",
            },
            {
              kind: "block",
              type: "case_block",
            },
            {
              kind: "block",
              type: "break_block",
            },
            {
              kind: "block",
              type: "try_catch_block",
            },
          ],
        },
        {
          kind: "category",
          name: "Boolean",
          contents: [
            {
              kind: "block",
              type: "logic_compare",
            },
            {
              kind: "block",
              type: "logic_operation",
            },
            {
              kind: "block",
              type: "logic_negate",
            },
            {
              kind: "block",
              type: "logic_boolean",
            },
            {
              kind: "block",
              type: "logic_null",
            },
            {
              kind: "block",
              type: "logic_ternary",
            },
          ],
        },
      ],
    },
    {
      kind: "category",
      name: "Loops",
      categorystyle: "loop_category",
      contents: [
        {
          kind: "block",
          type: "controls_repeat_ext",
          inputs: {
            TIMES: {
              shadow: {
                type: "math_number",
                fields: {
                  NUM: 10,
                },
              },
            },
          },
        },
        {
          kind: "block",
          type: "controls_whileUntil",
        },
        {
          kind: "block",
          type: "bi_for",
        },
        {
          kind: "block",
          type: "controls_for",
          fields: {
            VAR: "i",
          },
          inputs: {
            FROM: {
              shadow: {
                type: "math_number",
                fields: {
                  NUM: 1,
                },
              },
            },
            TO: {
              shadow: {
                type: "math_number",
                fields: {
                  NUM: 10,
                },
              },
            },
            BY: {
              shadow: {
                type: "math_number",
                fields: {
                  NUM: 1,
                },
              },
            },
          },
        },
        {
          kind: "block",
          type: "controls_forEach",
        },
        {
          kind: "block",
          type: "controls_flow_statements",
        },
      ],
    },

    {
      kind: "category",
      name: "Math",
      categorystyle: "math_category",
      contents: [
        {
          kind: "block",
          type: "math_number",
          fields: {
            NUM: 123,
          },
        },
        {
          kind: "block",
          type: "random_number_generator",
        },
        {
          kind: "block",
          type: "bi_parenthesis",
        },
        {
          kind: "block",
          type: "bi_unary",
        },
        {
          kind: "block",
          type: "bi_unary_return",
        },
        {
          kind: "block",
          type: "bi_unary_postfix",
        },
        {
          kind: "block",
          type: "bi_unary_postfix_return",
        },
        {
          kind: "block",
          type: "math_arithmetic",
        },
        {
          kind: "block",
          type: "math_single",
        },
        {
          kind: "block",
          type: "math_trig",
        },
        {
          kind: "block",
          type: "math_constant",
        },
        {
          kind: "block",
          type: "math_number_property",
        },
        {
          kind: "block",
          type: "math_round",
        },
        {
          kind: "block",
          type: "math_on_list",
        },
        {
          kind: "block",
          type: "math_modulo",
        },
        {
          kind: "block",
          type: "math_constrain",
          inputs: {
            LOW: {
              shadow: {
                type: "math_number",
                fields: {
                  NUM: 1,
                },
              },
            },
            HIGH: {
              shadow: {
                type: "math_number",
                fields: {
                  NUM: 100,
                },
              },
            },
          },
        },
        {
          kind: "block",
          type: "math_random_int",
          inputs: {
            FROM: {
              shadow: {
                type: "math_number",
                fields: {
                  NUM: 1,
                },
              },
            },
            TO: {
              shadow: {
                type: "math_number",
                fields: {
                  NUM: 100,
                },
              },
            },
          },
        },
        {
          kind: "block",
          type: "math_random_float",
        },
        {
          kind: "block",
          type: "math_atan2",
        },
      ],
    },
    {
      kind: "category",
      name: "Lists",
      categorystyle: "list_category",
      contents: [
        {
          kind: "block",
          type: "lists_create_empty",
        },
        {
          kind: "block",
          type: "lists_create_with",
        },
        {
          kind: "block",
          type: "lists_repeat",
          inputs: {
            NUM: {
              shadow: {
                type: "math_number",
                fields: {
                  NUM: 5,
                },
              },
            },
          },
        },
        {
          kind: "block",
          type: "lists_length",
        },
        {
          kind: "block",
          type: "lists_isEmpty",
        },
        {
          kind: "block",
          type: "lists_indexOf",
        },
        {
          kind: "block",
          type: "lists_getIndex",
        },
        {
          kind: "block",
          type: "lists_setIndex",
        },
        {
          kind: "block",
          type: "array_foreach",
        },
      ],
    },

    {
      kind: "category",
      name: "Text",
      categorystyle: "text_category",
      contents: [
        {
          kind: "block",
          type: "concate_string",
        },
        {
          kind: "block",
          type: "text",
        },
        {
          kind: "block",
          type: "masked_text_input",
        },
        {
          kind: "block",
          type: "text_join",
        },
        {
          kind: "block",
          type: "text_append",
          inputs: {
            TEXT: {
              shadow: {
                type: "text",
              },
            },
          },
        },
        {
          kind: "block",
          type: "text_length",
          inputs: {
            VALUE: {
              shadow: {
                type: "text",
                fields: {
                  TEXT: "abc",
                },
              },
            },
          },
        },
        {
          kind: "block",
          type: "text_isEmpty",
          inputs: {
            VALUE: {
              shadow: {
                type: "text",
              },
            },
          },
        },
        {
          kind: "block",
          type: "text_indexOf",
          inputs: {
            VALUE: {
              kind: "block",
              type: "variables_get",
              fields: {
                VAR: "{textVariable}",
              },
            },
            FIND: {
              shadow: {
                type: "text",
                fields: {
                  TEXT: "abc",
                },
              },
            },
          },
        },
        {
          kind: "block",
          type: "text_charAt",
          inputs: {
            VALUE: {
              kind: "block",
              type: "variables_get",
              fields: {
                VAR: "{textVariable}",
              },
            },
          },
        },
        {
          kind: "block",
          type: "text_getSubstring",
          inputs: {
            STRING: {
              kind: "block",
              type: "variables_get",
              fields: {
                VAR: "{textVariable}",
              },
            },
          },
        },
        {
          kind: "block",
          type: "text_changeCase",
          inputs: {
            TEXT: {
              shadow: {
                type: "text",
                fields: {
                  TEXT: "abc",
                },
              },
            },
          },
        },
        {
          kind: "block",
          type: "text_trim",
          inputs: {
            TEXT: {
              shadow: {
                type: "text",
                fields: {
                  TEXT: "abc",
                },
              },
            },
          },
        },
        {
          kind: "block",
          type: "text_print",
          inputs: {
            TEXT: {
              shadow: {
                type: "text",
                fields: {
                  TEXT: "abc",
                },
              },
            },
          },
        },
        {
          kind: "block",
          type: "text_prompt_ext",
          inputs: {
            TEXT: {
              shadow: {
                type: "text",
                fields: {
                  TEXT: "abc",
                },
              },
            },
          },
        },
      ],
    },

    {
      kind: "category",
      name: "Variables",
      categorystyle: "variable_category",
      custom: "VARIABLE",
    },
    {
      kind: "category",
      name: "Functions",
      categorystyle: "procedure_category",
      custom: "PROCEDURE",
    },
    {
      kind: "category",
      name: "typeCasting",
      categorystyle: "procedure_category",
      contents: [
        {
          kind: "block",
          type: "to_integer",
        },
        {
          kind: "block",
          type: "to_string",
        },
        {
          kind: "block",
          type: "to_boolean",
        },
      ],
    },

    {
      kind: "category",
      name: "Traverse Object",
      categorystyle: "object_category",
      contents: [
        {
          kind: "block",
          type: "create_object",
        },
        {
          kind: "block",
          type: "get_property",
        },
      ],
    },
    {
      kind: "category",
      name: "Database Methods",
      categorystyle: "dbMethod_category",
      contents: [
        {
          kind: "block",
          type: "grab_data_from_database",
        },
        {
          kind: "block",
          type: "grab_property_from_response_body",
        },
        {
          kind: "block",
          type: "create_new_entry",
        },
        {
          kind: "block",
          type: "update_entry_database",
        },
        {
          kind: "block",
          type: "delete_entry_database",
        },
        {
          kind: "block",
          type: "assign_response_status",
        },
        {
          kind: "block",
          type: "assignment_key",
        },
        {
          kind: "block",
          type: "save_updated_doc",
        },
        {
          kind: "block",
          type: "create_entry_with_object",
        },
        {
          kind: "block",
          type: "grab_and_store_data",
        },
        {
          kind: "block",
          type: "grab_current_user_data_after_auth",
        },
      ],
    },
    {
      kind: "category",
      name: "Date-Time",
      categorystyle: "dbMethod_category",
      contents: [
        {
          kind: "block",
          type: "current_date",
        },
        {
          kind: "block",
          type: "convert_to_date_object",
        },
        {
          kind: "block",
          type: "increment_decrement_date",
        },
        {
          kind: "block",
          type: "increment_decrement_date_current",
        },
        {
          kind: "block",
          type: "increment_decrement_time",
        },
        {
          kind: "block",
          type: "increment_decrement_time_current",
        },
      ],
    },
    {
      kind: "category",
      name: "Authentication",
      categorystyle: "auth_category",
      contents: [
        {
          kind: "block",
          type: "otp_verfication__twilio",
        },
        {
          kind: "block",
          type: "email_handler",
        },
        {
          kind: "block",
          type: "azzure_oauth",
        },
        {
          kind: "block",
          type: "generate_hash",
        },
        {
          kind: "block",
          type: "compare_hash",
        },
        {
          kind: "block",
          type: "generate_token",
        },
        {
          kind: "block",
          type: "generate_token_inst",
        },
        {
          kind: "block",
          type: "verify_token",
        },

        // {
        //   kind: "block",
        //   type: "otp_verfication_via_email_only",
        // },
      ],
    },
    {
      kind: "category",
      name: "Mobile Notification",
      categorystyle: "notification_category",
      contents: [
        {
          kind: "block",
          type: "push_notification_firebase_admin",
        },
      ],
    },
  ],
};

export const customTheme = Blockly.Theme.defineTheme("redSlingTheme", {
  base: Blockly.Themes.Classic,
  componentStyles: {
    workspaceBackgroundColour: "#1e1e1e",
    toolboxBackgroundColour: "#333",
    toolboxForegroundColour: "#fff",
    flyoutBackgroundColour: "#252526",
    flyoutForegroundColour: "#ccc",
    flyoutOpacity: 1,
    scrollbarColour: "#797979",
    insertionMarkerColour: "#fff",
    insertionMarkerOpacity: 0.3,
    scrollbarOpacity: 0.4,
    cursorColour: "#d0d0d0",
    blackBackground: "#333",
  },
  categoryStyles: categoryStyles,
  blockStyles: defaultBlockStyles,
});
