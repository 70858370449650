import axios from "axios"
import { API_HOST } from "../../api_utils";
import { GET_ALL_MODAL_TABLE_ERROR, GET_ALL_MODAL_TABLE_LOADING, GET_ALL_MODAL_TABLE_SUCCESS } from "../Constants/modalTableConstants";




export const getAllModal = (project_id) => async (dispatch, getState) => {
    try {
        dispatch({ type: GET_ALL_MODAL_TABLE_LOADING })
        const { userLogin: { userInfo } } = getState()
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }
        console.log("hereeeeeeeeee");
        const modalData = {
            projectId: project_id
        }
        const { data } = await axios.get(`${API_HOST}customroutes/get`, modalData, config)
        console.log("here1");
        dispatch({ type: GET_ALL_MODAL_TABLE_SUCCESS, payload: data })
    } catch (error) {

        dispatch({ type: GET_ALL_MODAL_TABLE_ERROR, paylaod: error.response.data.message })

    }
}