import uniqid from "uniqid";
import UIkit from "uikit";
import $ from "jquery";
import { API_HOST } from "../api_utils/index";

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const CustomEmailPlugin = (editor, options) => {
	console.log('CustomEmailPlugin options', options);

	const notifyTheme = {
	  position: "top-center",
	  autoClose: 5000,
	  hideProgressBar: false,
	  closeOnClick: true,
	  pauseOnHover: true,
	  draggable: true,
	  progress: undefined,
	  theme: "colored",
	};

	const successTheme = {
	  position: "top-right",
	  autoClose: 5000,
	  hideProgressBar: false,
	  closeOnClick: true,
	  pauseOnHover: true,
	  draggable: true,
	  progress: undefined,
	  theme: "light",
	};

	// toast notification for empty tables
	const infoTheme = {
	  position: "top-right",
	  autoClose: 10000,
	  hideProgressBar: false,
	  closeOnClick: true,
	  pauseOnHover: true,
	  draggable: true,
	  progress: undefined,
	  theme: "colored",
	};

	editor.Blocks.add("send-email-button-container", {
        label: "Custom Email",
        category: "Buttons",
        select: true,
        media: `<svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="currentColor" viewBox="0 0 16 16"><path d="M8.941.435a2 2 0 0 0-1.882 0l-6 3.2A2 2 0 0 0 0 5.4v.314l6.709 3.932L8 8.928l1.291.718L16 5.714V5.4a2 2 0 0 0-1.059-1.765zM16 6.873l-5.693 3.337L16 13.372v-6.5Zm-.059 7.611L8 10.072.059 14.484A2 2 0 0 0 2 16h12a2 2 0 0 0 1.941-1.516M0 13.373l5.693-3.163L0 6.873z"/></svg>`,
        content: { type: "send-email-button-container" },
    });

    if (editor !== null && editor !== undefined) {
    	const sendEmailScript = function(props) {
    		console.log('$props', props, this);

    		// For LB download manipulation
	        // checking if the url or script is not running in the redsling editor, only then run the script to plot checkboxes
	        let idEl=this.id
	        const url = window.top.location.href;
	        const isSubstringPresent = (url.indexOf('/editor/') !== -1 && url.indexOf('?projectId=') !== -1) || (url.indexOf('/large_preview/') !== -1) || (url.indexOf('/tab_preview/') !== -1) || (url.indexOf('/mobile_preview/') !== -1) || (url.indexOf('/fragment_editor/') !== -1);
	        if (!isSubstringPresent) {
	          setInterval(function () {
	            Object.keys(props).forEach(function (key) {
	              if (window[`${key}${idEl}`]) {
	                props[key] = window[`${key}${idEl}`];
	              }
	            });
	          }, 500);
	        }

    		// setting email status in local storage. Statues - inprogress, success, failure, inactive
    		let containerId = this.id;
    		let emailStatus = localStorage.getItem(`emailStatus_${containerId}`);
    		if (!emailStatus) {
    			emailStatus = 'inactive';
    			localStorage.setItem(`emailStatus_${containerId}`, emailStatus);
    		}

    		// to create a popup notification
    		function showNotification(isSuccess, message) {
			    // Create a notification element
			    const notification = document.createElement('div');

			    // Apply styles directly
			    notification.style.display = 'block';
			    notification.style.position = 'fixed';
			    notification.style.top = '20px';
			    notification.style.right = '20px';
			    notification.style.padding = '20px';
			    notification.style.backgroundColor = 'white';
			    notification.style.border = '2px solid';
			    notification.style.borderRadius = '10px';
			    notification.style.boxShadow = '0 0 10px rgba(0,0,0,0.1)';
			    notification.style.zIndex = '1000';
			    notification.style.maxWidth = '300px';
			    notification.style.textAlign = 'left';
			    
			    // Set the message
			    notification.textContent = message;

			    // Apply border color based on success or failure
			    notification.style.borderColor = isSuccess ? '#4CAF50' : '#F44336';

			    // Append the notification to the document body
			    document.body.appendChild(notification);

			    // Hide and remove the notification after 3 seconds
			    setTimeout(() => {
			        document.body.removeChild(notification);
			    }, 4000);
			}

    		let sendEmailButtonContainer;
    		let sendEmailButton;

    		// don't uncomment this if-boolean condition because this removes the event listener onclick
    		// if (this.className.includes('send-email-button-container ')) {
	    		sendEmailButtonContainer = this;
	    		sendEmailButton = sendEmailButtonContainer.getElementsByClassName("send-email-button")[0];
    		// }    		

    		console.log('++', sendEmailButtonContainer, sendEmailButton);
    		
    		let selectedFile;

    		const sendEmailHandler = function() {
    			// reason - because this script was getting called multiple times, the dialog element was getting added multiple times so this code will be removing all the previously appended ones and then create a new one on-the-go
	        	if (sendEmailButtonContainer) {
		        	let modalAlreadyExists = document.querySelectorAll(`#custom-email-modal-${sendEmailButtonContainer.id}`);
		        	modalAlreadyExists.forEach(modal => {
		        		console.log('removing modal 2', modal);
		        		modal.remove();
		        	});
	        	

		            // Create a dialog modal with styling
		            const dynamicEmailSendingModal = document.createElement("dialog");
		            dynamicEmailSendingModal.setAttribute('class', `custom-email-modal`);
		            dynamicEmailSendingModal.setAttribute('id', `custom-email-modal-${sendEmailButtonContainer.id}`);
		            Object.assign(dynamicEmailSendingModal.style, {
		                margin: 'auto',
		                width: '35vw',
		                overflow: 'auto',
		                backgroundColor: "white",
		                boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
		                border: "1px solid #ccc",
		                borderRadius: "20px",
		                zIndex: "1000",
		                padding: "0 0 1% 0",
		            });

		            // modal UI header & footer
		            let modalHeader = document.createElement("div");
		            Object.assign(modalHeader.style, {
		                // backgroundColor: "#1991E7",
		                backgroundImage: 'linear-gradient(180deg, #62C4FB, #066FAB)',
		                paddingTop: "0.8%",
		                paddingBottom: "0.8%",
		                paddingLeft: "5%",
		                paddingRight: "5%",
		                color: "white",
		                fontSize: "1vw",
		                display: "flex",
		                justifyContent: "space-between",
		                alignItems: "center",
		            });
		            modalHeader.innerHTML = 'Compose Message';

		            const modalFooter = document.createElement("div");
		            Object.assign(modalFooter.style, {
		                backgroundColor: "transparent",
		                paddingTop: "0.8%",
		                paddingBottom: "0.8%",
		                paddingLeft: "5%",
		                paddingRight: "5%",
		                display: "flex",
		                justifyContent: "space-between",
		                alignItems: "center",
		            });

		            // Create a form element
		            const emailForm = document.createElement('form');
		            Object.assign(emailForm.style, {
		            	padding: "3% 6%",
		                display: "flex",
		                flexDirection: "column",
		            });

		            // Check if EmailFromDynamicCheckbox is true and then create an input field for it in the modal
		            if (props.EmailFromDynamicCheckbox) {
		            	const emailFromDiv = document.createElement("div");
		            	Object.assign(emailFromDiv.style, {
		                    display: 'flex',
		                    alignItems: "center",
		                    justifyContent: "space-between",
		                    columnGap: "5%",
		                });

		            	const emailFromLabel = document.createElement("label");
		            	emailFromLabel.innerHTML = 'From';
		                // Create an input email field for the sender's email address with styling
		                const emailFromField = document.createElement("input");
		                Object.assign(emailFromField.style, {
		                    marginBottom: "10px",
		                    padding: "5px",
		                    width: "80%",
		                    borderRadius: "5px",
		                    backgroundColor: "transparent",
		                	boxShadow: '0 3px 6px rgba(0, 0, 0, 0.3)',
		                });
		                Object.assign(emailFromField, {
		                    type: 'email',
		                    id: `emailFrom-${sendEmailButtonContainer.id}`,
		                    name: 'emailFrom',
		                    placeholder: 'Sender\'s Email Address',
		                });
		                const emailFromRequiredFieldLabel = document.createElement('span');
		                emailFromRequiredFieldLabel.innerHTML = "(required)";
		                Object.assign(emailFromRequiredFieldLabel.style, {
		                    color: 'red',
					        fontStyle: 'italic',
					        fontSize: '0.9vw',
					        marginLeft: '1%',
		                });

		                // Append the email input field to the form
		                emailFromDiv.appendChild(emailFromLabel);
		                emailFromDiv.appendChild(emailFromField);
		                emailFromDiv.appendChild(emailFromRequiredFieldLabel);
		                emailForm.appendChild(emailFromDiv);
		            }
		            // // else take directly from props
		            // else {}

		            // Check if EmailToDynamicCheckbox is true and then create an input field for it in the modal
		            if (props.EmailToDynamicCheckbox) {
		            	const emailToDiv = document.createElement("div");
		            	Object.assign(emailToDiv.style, {
		                    display: 'flex',
		                    alignItems: "center",
		                    justifyContent: "space-between",
		                    columnGap: "5%",
		                });

		            	const emailToLabel = document.createElement("label");
		            	emailToLabel.innerHTML = 'To';
		                // Create an input email field for the receiver's email address with styling
		                const emailToField = document.createElement("input");
		                Object.assign(emailToField.style, {
		                    marginBottom: "10px",
		                    padding: "5px",
		                    width: "80%",
		                    borderRadius: "5px",
		                    backgroundColor: "transparent",
		                	boxShadow: '0 3px 6px rgba(0, 0, 0, 0.3)',
		                });
		                Object.assign(emailToField, {
		                    type: 'text',
		                    id: `emailTo-${sendEmailButtonContainer.id}`,
		                    name: 'emailTo',
		                    placeholder: 'Receiver\'s Email Addresses (comma separated)',
		                });
		                const emailToRequiredFieldLabel = document.createElement('span');
		                emailToRequiredFieldLabel.innerHTML = "(required)";
		                Object.assign(emailToRequiredFieldLabel.style, {
		                    color: 'red',
					        fontStyle: 'italic',
					        fontSize: '0.9vw',
					        marginLeft: '1%',
		                });

		                // Append the email input field to the form
		                emailToDiv.appendChild(emailToLabel);
		                emailToDiv.appendChild(emailToField);
		                emailToDiv.appendChild(emailToRequiredFieldLabel);
		                emailForm.appendChild(emailToDiv);
		            }
		            // // else take directly from props
		            // else {}
		            
		            // Check if EmailSubjectDynamicCheckbox is true and then create an input field for it in the modal
		            if (props.EmailSubjectDynamicCheckbox) {
		            	const emailSubjectDiv = document.createElement("div");
		            	Object.assign(emailSubjectDiv.style, {
		                    display: 'flex',
		                    alignItems: "center",
		                    justifyContent: "space-between",
		                    columnGap: "5%",
		                });

		            	const emailSubjectLabel = document.createElement("label");
		            	emailSubjectLabel.innerHTML = 'Subject';
		                // Create an input email field for the receiver's email address with styling
		                const emailSubjectField = document.createElement("input");
		                Object.assign(emailSubjectField.style, {
		                    marginBottom: "10px",
		                    padding: "5px",
		                    width: "80%",
		                    borderRadius: "5px",
		                    backgroundColor: "transparent",
		                	boxShadow: '0 3px 6px rgba(0, 0, 0, 0.3)',
		                });
		                Object.assign(emailSubjectField, {
		                    type: 'text',
		                    id: `emailSubject-${sendEmailButtonContainer.id}`,
		                    name: 'emailSubject',
		                    placeholder: 'Email\'s subject',
		                });
		                const emailSubjectRequiredFieldLabel = document.createElement('span');
		                emailSubjectRequiredFieldLabel.innerHTML = "(required)";
		                Object.assign(emailSubjectRequiredFieldLabel.style, {
		                    color: 'red',
					        fontStyle: 'italic',
					        fontSize: '0.9vw',
					        marginLeft: '1%',
		                });

		                // Append the email input field to the form
		                emailSubjectDiv.appendChild(emailSubjectLabel);
		                emailSubjectDiv.appendChild(emailSubjectField);
		                emailSubjectDiv.appendChild(emailSubjectRequiredFieldLabel);
		                emailForm.appendChild(emailSubjectDiv);
		            }
		            // // else take directly from props
		            // else {}
		            
		            // Check if EmailBodyDynamicCheckbox is true and then create an input field for it in the modal
		            if (props.EmailBodyDynamicCheckbox) {
		            	const emailBodyDiv = document.createElement("div");
		            	Object.assign(emailBodyDiv.style, {
		                    display: 'flex',
		                    alignItems: "center",
		                    justifyContent: "space-between",
		                    columnGap: "5%",
		                });

		            	const emailBodyLabel = document.createElement("label");
		            	emailBodyLabel.innerHTML = 'Body';
		                // Create an input email field for the receiver's email address with styling
		                const emailBodyField = document.createElement("textarea");
		                Object.assign(emailBodyField.style, {
		                    marginBottom: "10px",
		                    padding: "5px",
		                    width: "80%",
		                    borderRadius: "5px",
		                    backgroundColor: "transparent",
		                	boxShadow: '0 3px 6px rgba(0, 0, 0, 0.3)',
		                	resize: "both !important",
  							overflow: "auto",
		                });
		                Object.assign(emailBodyField, {
		                    rows: '5',
		                    cols: '10',
		                    id: `emailBody-${sendEmailButtonContainer.id}`,
		                    name: 'emailBody',
		                    placeholder: 'Email\'s body',
		                });
		                const emailBodyRequiredFieldLabel = document.createElement('span');
		                emailBodyRequiredFieldLabel.innerHTML = "(required)";
		                Object.assign(emailBodyRequiredFieldLabel.style, {
		                    color: 'red',
					        fontStyle: 'italic',
					        fontSize: '0.9vw',
					        marginLeft: '1%',
		                });

		                // Append the email input field to the form
		                emailBodyDiv.appendChild(emailBodyLabel);
		                emailBodyDiv.appendChild(emailBodyField);
		                emailBodyDiv.appendChild(emailBodyRequiredFieldLabel);
		                emailForm.appendChild(emailBodyDiv);
		            }
		            // // else take directly from props
		            // else {}
		            
		            // Check if FileAttachmentDynamicCheckbox is true and then create an input field for it in the modal
		            if (props.FileAttachmentDynamicCheckbox) {
		                // Create an input email field for the receiver's email address with styling
		                const fileAttachmentField = document.createElement("input");
		                Object.assign(fileAttachmentField.style, {
		                    // marginBottom: "10px",
		                    // padding: "5px",
		                    // width: "80%",
		                    // borderRadius: "5px",
		                    // backgroundColor: "transparent",
		                	// boxShadow: '0 3px 6px rgba(0, 0, 0, 0.3)',
		                	display: 'none',
		                });
		                Object.assign(fileAttachmentField, {
		                    type: 'file',
		                    id: `fileAttachment-${sendEmailButtonContainer.id}`,
		                    name: 'fileAttachment',
		                });

		                // Add event listener for the file input field change event
						fileAttachmentField.addEventListener('change', (event) => {
						    selectedFile = event.target.files[0];
						    console.log('Selected file:', selectedFile);
						    // You can do further processing with the selected file here
						});

		                // Create a label for the file input button
						const fileAttachmentLabel = document.createElement("label");
						fileAttachmentLabel.setAttribute('for', 'fileAttachmentInput'); // Set the 'for' attribute to match the input field's id
						fileAttachmentLabel.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-paperclip" viewBox="0 0 16 16"><path d="M4.5 3a2.5 2.5 0 0 1 5 0v9a1.5 1.5 0 0 1-3 0V5a.5.5 0 0 1 1 0v7a.5.5 0 0 0 1 0V3a1.5 1.5 0 1 0-3 0v9a2.5 2.5 0 0 0 5 0V5a.5.5 0 0 1 1 0v7a3.5 3.5 0 1 1-7 0z"/></svg>';
						Object.assign(fileAttachmentLabel.style, {
						    cursor: "pointer",
						});

						// Attach click event to the label
						fileAttachmentLabel.addEventListener('click', () => {
						    fileAttachmentField.click();
						});

		                // Append the email input field to the form
		                modalFooter.appendChild(fileAttachmentLabel);
		                modalFooter.appendChild(fileAttachmentField);
		            }
		            // // else take directly from props
		            // else {}

		            // Get the previously entered values from local storage
				    const storedEmailFrom = localStorage.getItem(`emailFrom_${containerId}`);
				    const storedEmailTo = localStorage.getItem(`emailTo_${containerId}`);
				    const storedEmailSubject = localStorage.getItem(`emailSubject_${containerId}`);
				    const storedEmailBody = localStorage.getItem(`emailBody_${containerId}`);

				    // Initialize the input fields with the stored values if available
				    const emailFromField = emailForm.querySelector(`input[id="emailFrom-${sendEmailButtonContainer.id}"]`);
				    const emailToField = emailForm.querySelector(`input[id="emailTo-${sendEmailButtonContainer.id}"]`);
				    const emailSubjectField = emailForm.querySelector(`input[id="emailSubject-${sendEmailButtonContainer.id}"]`);
				    const emailBodyField = emailForm.querySelector(`textarea[id="emailBody-${sendEmailButtonContainer.id}"]`);

				    if (storedEmailFrom) emailFromField.value = storedEmailFrom;
				    if (storedEmailTo) emailToField.value = storedEmailTo;
				    if (storedEmailSubject) emailSubjectField.value = storedEmailSubject;
				    if (storedEmailBody) emailBodyField.value = storedEmailBody;

		            // Create a submit button
		            const submitButton = document.createElement('button');
		            Object.assign(submitButton.style, {
		                // marginBottom: "3%",
		                // marginRight: "4%",
		                alignSelf: "flex-end",
		                padding: "1.5% 3%",
		                border: "none",
		                borderRadius: "25px",
		                // backgroundColor: "#007bff",
		                backgroundImage: 'linear-gradient(180deg, #62C4FB, #066FAB)',
		                color: "white",
		                cursor: "pointer",
		            });
		            submitButton.textContent = 'Send';
		            submitButton.type = 'submit';

		            // Add event listener for form submission
		            submitButton.addEventListener('click', async (event) => {
		                event.preventDefault(); // Prevent form submission

		                // updating emailStatus in local storage
                        emailStatus = 'inprogress';
						localStorage.setItem(`emailStatus_${containerId}`, emailStatus);

		                // Get the entered email values
		                const emailFromValue = emailForm.querySelector(`input[id="emailFrom-${sendEmailButtonContainer.id}"]`) ? emailForm.querySelector(`input[id="emailFrom-${sendEmailButtonContainer.id}"]`).value : props.EmailFrom;
		                let emailToValue = emailForm.querySelector(`input[id="emailTo-${sendEmailButtonContainer.id}"]`) ? emailForm.querySelector(`input[id="emailTo-${sendEmailButtonContainer.id}"]`).value : props.EmailTo;
		                emailToValue = emailToValue.includes(',') ? emailToValue.split(/\s*,\s*/) : emailToValue;
		                const emailSubjectValue = emailForm.querySelector(`input[id="emailSubject-${sendEmailButtonContainer.id}"]`) ? emailForm.querySelector(`input[id="emailSubject-${sendEmailButtonContainer.id}"]`).value : props.EmailSubject;
		                const emailBodyValue = emailForm.querySelector(`textarea[id="emailBody-${sendEmailButtonContainer.id}"]`) ? emailForm.querySelector(`textarea[id="emailBody-${sendEmailButtonContainer.id}"]`).value : props.EmailBody;
		                const fileAttachmentValue = emailForm.querySelector(`input[id="fileAttachment-${sendEmailButtonContainer.id}"]`) ? emailForm.querySelector(`input[id="fileAttachment-${sendEmailButtonContainer.id}"]`).value : props.FileAttachment;

		                // to preserve line breaks in email body
		                const formattedEmailBodyValue = emailBodyValue.replace(/\n/g, '<br>');

		                console.log('form values', emailFromValue, emailToValue, emailSubjectValue, emailBodyValue, formattedEmailBodyValue, fileAttachmentValue);
		                console.log('selectedFile', selectedFile);

		                // Create a FormData object
					    const formData = new FormData();

					    // Append form data to FormData object
					    formData.append('emailFrom', emailFromValue);
					    formData.append('emailTo', emailToValue);
					    formData.append('emailSubject', emailSubjectValue);
					    formData.append('emailBody', formattedEmailBodyValue);
					    formData.append('fileAttachment', selectedFile);

					    // Save the entered values to local storage for future use
				        localStorage.setItem(`emailFrom_${containerId}`, emailFromValue);
				        localStorage.setItem(`emailTo_${containerId}`, emailToValue);
				        localStorage.setItem(`emailSubject_${containerId}`, emailSubjectValue);
				        localStorage.setItem(`emailBody_${containerId}`, emailBodyValue);

		                // Make a fetch call to the API with the entered email address
		                try {
		                	// send email if email - to, subject, body are provided
		                	if (emailFromValue !== "" && emailToValue !== "" && emailSubjectValue !== "" && emailBodyValue !== "") {
			                	const response = await fetch(`${props.API_HOST}user/custom_email`, {
			                        method: 'POST',
			                        body: formData,
			                    });

			                    if (response.ok) {
			                        console.log('Email sent successfully!');

			                        // updating emailStatus in local storage
			                        emailStatus = 'success';
									localStorage.setItem(`emailStatus_${containerId}`, emailStatus);

			                        showNotification(true, 'Email sent successfully!');

			                        // Close the modal after form submission
		               	 			dynamicEmailSendingModal.close();
			                    } else {
			                        console.error('Failed to send email.');

			                        // updating emailStatus in local storage
			                        emailStatus = 'failure';
									localStorage.setItem(`emailStatus_${containerId}`, emailStatus);

			                        showNotification(false, 'Failed to send email. Please check the provided values.');

			                        // Close the modal after form submission
		               	 			dynamicEmailSendingModal.close();
			                    }
		                	} else {
		                        // updating emailStatus in local storage
		                        emailStatus = 'failure';
								localStorage.setItem(`emailStatus_${containerId}`, emailStatus);

		                		showNotification(false, 'Failed to send email. Please check if all the values were provided.');
		                	}
		                } catch (error) {
		                    console.error('Error sending email:', error);

	                        // updating emailStatus in local storage
	                        emailStatus = 'failure';
							localStorage.setItem(`emailStatus_${containerId}`, emailStatus);
							
		                    showNotification(false, 'Error sending email. Please check the provided values.');

		                    // Close the modal after form submission
		               	 	dynamicEmailSendingModal.close();
		                }	                
		            });

		            // Create a close button
		            const closeButton = document.createElement('button');
		            closeButton.setAttribute('class', 'custom-email-modal-close');
		            closeButton.setAttribute('id', `custom-email-modal-close-${sendEmailButtonContainer.id}`);
		            Object.assign(closeButton.style, {
		                border: "none",
		                borderRadius: "3px",
		                backgroundColor: "transparent",
		                color: "white",
		                cursor: "pointer",
		            });
		            closeButton.textContent = 'X';
		            closeButton.type = 'button';

		            // Add event listener for closing the modal
		            closeButton.addEventListener('click', () => {
		                dynamicEmailSendingModal.close();
		            });

		            // Append the form and close button to the modal
		            dynamicEmailSendingModal.appendChild(modalHeader);
		            dynamicEmailSendingModal.appendChild(emailForm);
		            modalHeader.appendChild(closeButton);
		            modalFooter.appendChild(submitButton);
		            dynamicEmailSendingModal.appendChild(modalFooter);

		            // Append the modal to the document body
		            document.body.appendChild(dynamicEmailSendingModal);

		            // Show the modal
		            dynamicEmailSendingModal.showModal();
	            }
	        };

    		// execute the whole modal-process only if the email fields are dynamic (otherwise 2 modals open up)
    		if (props.EmailFromDynamicCheckbox || props.EmailToDynamicCheckbox || props.EmailSubjectDynamicCheckbox || props.EmailBodyDynamicCheckbox || props.FileAttachmentDynamicCheckbox) {

		        // sendEmailButton.removeEventListener('click', sendEmailHandler);
		        if (sendEmailButton) {
			        // sendEmailButton.onclick = null;
		        	console.log("removed eL 2");

		        	// sendEmailButton.addEventListener('click', sendEmailHandler);
		        	sendEmailButton.onclick = sendEmailHandler;
		        }
		    }

		    // else take the predefined values and send the email if none of them are dynamic then on clicking the button directly, send the email
		    else if (!props.EmailFromDynamicCheckbox && !props.EmailToDynamicCheckbox && !props.EmailSubjectDynamicCheckbox && !props.EmailBodyDynamicCheckbox && !props.FileAttachmentDynamicCheckbox) {

		    	if (sendEmailButtonContainer) {
			    	// reason - making sure to remove any modals manually if the user had allowed modal creation by setting some dynamic prop checkbox on previously
			    	let modalAlreadyExists = document.querySelectorAll(`#custom-email-modal-${sendEmailButtonContainer.id}`);
		        	modalAlreadyExists.forEach(modal => {
		        		console.log('removing modal 3', modal);
		        		modal.remove();
		        	});

			    	let EmailFrom = props.EmailFrom;
			    	let EmailTo = props.EmailTo.includes(',') ? props.EmailTo.split(/\s*,\s*/) : props.EmailTo;
			    	let EmailSubject = props.EmailSubject;
			    	let EmailBody = props.EmailBody;
			    	// let FileAttachment = props.FileAttachment;

			    	console.log('selectedFile 3', EmailFrom, EmailTo, EmailSubject, EmailBody);

			    	// sendEmailButton.removeEventListener('click', sendEmailHandler);
			    	if (sendEmailButton) {
				    	// sendEmailButton.onclick = null;
			        	console.log("removed eL 3");
			    		
			    		// make the API call
			    		sendEmailButton.onclick = async function(event) {
			    			console.log('sending email...');

			    			// updating emailStatus in local storage
	                        emailStatus = 'inprogress';
							localStorage.setItem(`emailStatus_${containerId}`, emailStatus);

			    			// Make a fetch call to the API with the entered email address
			                try {
			                	// send email if email - to, subject, body are provided
			                	if (EmailFrom !== "" && EmailTo !== "" && EmailSubject !== "" && EmailBody !== "") {
			                		// to preserve line breaks in email body
			                		const formattedEmailBody = EmailBody.replace(/\n/g, '<br>');
			                		console.log('formattedEmailBody', formattedEmailBody);

				                	const response = await fetch(`${props.API_HOST}user/custom_email_without_formData`, {
				                        method: 'POST',
				                        headers: {
									      	'Content-Type': 'application/json'
									    },
				                        body: JSON.stringify({
				                        	emailFrom: EmailFrom,
				                        	emailTo: EmailTo,
				                        	emailSubject: EmailSubject,
				                        	emailBody: formattedEmailBody,
				                        }),
				                    });

				                    if (response.ok) {
				                        console.log('Email sent successfully!');

				                        // updating emailStatus in local storage
				                        emailStatus = 'success';
    									localStorage.setItem(`emailStatus_${containerId}`, emailStatus);

				                        showNotification(true, 'Email sent successfully!');
				                    } else {
				                        console.error('Failed to send email.');

				                        // updating emailStatus in local storage
				                        emailStatus = 'failure';
    									localStorage.setItem(`emailStatus_${containerId}`, emailStatus);

				                        showNotification(false, 'Failed to send email. Please check the provided values.');
				                    }
			                	} else {
			                        // updating emailStatus in local storage
			                        emailStatus = 'failure';
									localStorage.setItem(`emailStatus_${containerId}`, emailStatus);

			                		showNotification(false, 'Failed to send email. Please check if all the values were provided.');
			                	}
			                } catch (error) {
			                    console.error('Error sending email:', error);

			                    // updating emailStatus in local storage
		                        emailStatus = 'failure';
								localStorage.setItem(`emailStatus_${containerId}`, emailStatus);

			                    showNotification(false, 'Error sending email. Please check the provided values.');
			                }
			    		};
			    	}
		    	}		    	
		    }
    	};

    	editor.DomComponents.addType("send-email-button-container", {
	        model: {
		        defaults: {
		            tagName: "div",
		            attributes: {
		              class: "send-email-button-container",
		            },
		            script: sendEmailScript,

		            components: [
		              {
		                tagName: "button",
		                attributes: { class: "send-email-button" },
		                editable: true,
		  
		                "style-default": {
		                  width: "max-content",
		                  padding: "0.7%",
		                  "border-radius": "25px",
		                  "background-color": "#1991E7",
		                  border: "2px solid #1991E7",
		                  color: "white",
		                  cursor: "pointer",
		                },
		  
		                style: {
		                  width: "max-content",
		                  padding: "0.7%",
		                  "border-radius": "25px",
		                  "background-color": "#1991E7",
		                  border: "2px solid #1991E7",
		                  color: "white",
		                  cursor: "pointer",
		                },
		  
		                components: {
		                  tagName: "p",
		                  type: "text",
		  
		                  components: {
		                    type: "textnode",
		                    content: "Send Email",
		                  },
		  
		                  "style-default": {
		                    margin: "auto",
		                    "text-align": "center",
		                    color: "white",
		                  },
		  
		                  style: {
		                    margin: "auto",
		                    "text-align": "center",
		                    color: "white",
		                  },
		                },
		              }
		            ],

		            // traits
		            API_HOST: API_HOST,
		            
		            EmailFromDynamicCheckbox: false,
		            EmailFrom: "",
		            
		            EmailToDynamicCheckbox: false,
		            EmailTo: "",
		            
		            EmailSubjectDynamicCheckbox: false,
		            EmailSubject: "",
		            
		            EmailBodyDynamicCheckbox: false,
		            EmailBody: "",
		            
		            FileAttachmentDynamicCheckbox: false,
		            FileAttachment: null,

		            changeProp: true,
		            "script-props": [
		            	"API_HOST",
		            	"EmailFromDynamicCheckbox", 
		            	"EmailFrom", 
		            	"EmailToDynamicCheckbox", 
		            	"EmailTo", 
		            	"EmailSubjectDynamicCheckbox", 
		            	"EmailSubject", 
		            	"EmailBodyDynamicCheckbox", 
		            	"EmailBody", 
		            	"FileAttachmentDynamicCheckbox",
		            	"FileAttachment",
		            ],
		        },

		        removed() {
			    	localStorage.removeItem(`emailStatus_${this.getAttributes()['id']}`);
			    },
	        },

	        view: {
	        	events: {
					'click .customize-email-btn': 'openModal',
				},

				openModal(event) {
					event.stopPropagation();

					// console.log('model', this.model);

					const form = document.createElement("div");
		            form.setAttribute("class", "custom-email-form");

                    form.innerHTML = `
                    	<ul class="nav nav-tabs" id="custom-email-form" role="tablist">
							<li class="nav-item" role="presentation">
							    <button class="nav-link active" id="email-button-functionality-tab" data-bs-toggle="tab" data-bs-target="#email-button-functionality-tab-pane" type="button" role="tab" aria-controls="email-button-functionality-tab-pane" aria-selected="true">Email Button Functionality</button>
							</li>
						</ul>

						<div class="tab-content" id="custom-email-form-content">
							<div class="tab-pane fade show active" id="email-button-functionality-tab-pane" role="tabpanel" aria-labelledby="email-button-functionality-tab-pane" tabindex="0">
							  	<br>

							  	<!-- should the emailFrom be dynamic or not? -->
							  	<div>
							  		<span>
		                        		<label for="" title="Note: Turn on the checkbox to make the 'Email From' dynamic">Make 'Email From' Dynamic</label>
		                        	</span>
		                            <input type="checkbox" name="email-from-dynamic" id="email-from-dynamic" class="email-from-dynamic">
		                            <input type="email" name="email-from" id="email-from" class="email-from" placeholder="Sender's email address">
							  		<span class="required-field">(required)</span>

							  		<!-- submit button -->
		                        	<!-- <button class="email-from-btn">Save</button> -->
							  	</div>

							  	<!-- should the emailTo be dynamic or not? -->
							  	<div>
							  		<span>
		                        		<label for="" title="Note: Turn on the checkbox to make the 'Email To' dynamic. If it's not going to be dynamic, provide the trait field because it is a required field. (For multiple IDs, separate them with a comma (,))">Make 'Email To' Dynamic</label>
		                        	</span>
		                            <input type="checkbox" name="email-to-dynamic" id="email-to-dynamic" class="email-to-dynamic">
		                            <input type="text" name="email-to" id="email-to" class="email-to" placeholder="Receiver's email addresses (for multiples, separate them with a comma (,))">
		                            <span class="required-field">(required)</span>
							  		
							  		<!-- submit button -->
		                        	<!-- <button class="email-to-btn">Save</button> -->
							  	</div>

							  	<!-- should the emailSubject be dynamic or not? -->
							  	<div>
							  		<span>
		                        		<label for="" title="Note: Turn on the checkbox to make the 'Email Subject' dynamic. If it's not going to be dynamic, provide the trait field because it is a required field.">Make 'Email Subject' Dynamic</label>
		                        	</span>
		                            <input type="checkbox" name="email-subject-dynamic" id="email-subject-dynamic" class="email-subject-dynamic">
		                            <input type="text" name="email-subject" id="email-subject" class="email-subject" placeholder="Email's subject">
							  		 <span class="required-field">(required)</span>

							  		<!-- submit button -->
		                        	<!-- <button class="email-subject-btn">Save</button> -->
							  	</div>

							  	<!-- should the emailBody be dynamic or not? -->
							  	<div>
							  		<span>
		                        		<label for="" title="Note: Turn on the checkbox to make the 'Email Body' dynamic. If it's not going to be dynamic, provide the trait field because it is a required field.">Make 'Email Body' Dynamic</label>
		                        	</span>
		                            <input type="checkbox" name="email-body-dynamic" id="email-body-dynamic" class="email-body-dynamic">
		                            <textarea cols="45" rows="4" name="email-body" id="email-body" class="email-body" placeholder="Email's body"></textarea>
							  		 <span class="required-field">(required)</span>

							  		<!-- submit button -->
		                        	<!-- <button class="email-body-btn">Save</button> -->
							  	</div>

							  	<!-- should the fileAttachment be dynamic or not? -->
							  	<div>
							  		<span>
		                        		<label for="" title="Note: Turn on the checkbox to make the 'File Attachment' dynamic.">Make 'File Attachment' Dynamic</label>
		                        	</span>
		                            <input type="checkbox" name="file-attachment-dynamic" id="file-attachment-dynamic" class="file-attachment-dynamic">
		                            <input style="display: none;" type="file" name="file-attachment" id="file-attachment" class="file-attachment">
							  		
							  		<!-- submit button -->
		                        	<!-- <button class="file-attachment-btn">Save</button> -->
							  	</div>

							  	<!-- one submit button for all -->
							  	<div class="save-email-field-btn-container" title="Note: Save only when you have made changes to input fields, not checkboxes.">
							  		<button class="save-email-field-btn">Save</button>
							  	</div>
							</div>
						</div>
                    `;

                    editor.Modal.open({
		              	title: "Custom Email Form",
		              	content: `${form.outerHTML}`,
		              	attributes: {
		              		thisModelRef: this.model,
		              		// thisTableRef: this.model.components().models.find(comp => comp.attributes.type === "custom-table"),
		              	},
		            });
				},

	        	onRender({el, model}) {
	        		console.log('onRender el, model', el, model);

	        		// to avoid showing in preview
	        		if (!window.top.location.href.includes('large_preview/')) {
	        			if (el.className === "send-email-button-container") {
		        			let customizeEmailBtn = document.createElement('button');
		        			customizeEmailBtn.style.marginRight = '1%';
	        				customizeEmailBtn.setAttribute('class', "customize-email-btn");
	        				customizeEmailBtn.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pen" viewBox="0 0 16 16"><path d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001m-.644.766a.5.5 0 0 0-.707 0L1.95 11.756l-.764 3.057 3.057-.764L14.44 3.854a.5.5 0 0 0 0-.708l-1.585-1.585z"/></svg>';

	        				// Insert the customizeEmailBtn element next to the custom-email element
	        				el.getElementsByClassName('send-email-button')[0].insertAdjacentElement('beforebegin', customizeEmailBtn);
	        			}
	        		}
	        	},
	        },
	    });

		// listening to modal opening
		editor.on("modal", (props) => {
			console.log('modal props', props);

			if (document.getElementsByClassName("gjs-mdl-content")[0] !== undefined && document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("custom-email-form")[0] !== undefined ) {
				// get the values
				const emailFromDynamicCheckbox = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("email-from-dynamic")[0];
				const emailFrom = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("email-from")[0];
				// const emailFromBtn = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("email-from-btn")[0];

				const emailToDynamicCheckbox = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("email-to-dynamic")[0];
				const emailTo = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("email-to")[0];
				// const emailToBtn = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("email-to-btn")[0];

				const emailSubjectDynamicCheckbox = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("email-subject-dynamic")[0];
				const emailSubject = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("email-subject")[0];
				// const emailSubjectBtn = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("email-subject-btn")[0];

				const emailBodyDynamicCheckbox = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("email-body-dynamic")[0];
				const emailBody = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("email-body")[0];
				// const emailBodyBtn = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("email-body-btn")[0];

				const fileAttachmentDynamicCheckbox = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("file-attachment-dynamic")[0];
				const fileAttachment = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("file-attachment")[0];
				// const fileAttachmentBtn = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("file-attachment-btn")[0];
				let selectedFile;

				const saveEmailFieldsBtn = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("save-email-field-btn")[0];
			
				console.log('AEB', emailFromDynamicCheckbox, emailFrom, emailToDynamicCheckbox, emailTo, emailSubjectDynamicCheckbox, emailSubject, emailBodyDynamicCheckbox, emailBody, fileAttachmentDynamicCheckbox, fileAttachment, saveEmailFieldsBtn);

				// reassign the values
				try {
					emailFromDynamicCheckbox.checked = props.attributes.thisModelRef.attributes.EmailFromDynamicCheckbox;
					emailFrom.value = props.attributes.thisModelRef.attributes.EmailFrom;

					emailToDynamicCheckbox.checked = props.attributes.thisModelRef.attributes.EmailToDynamicCheckbox;
					emailTo.value = props.attributes.thisModelRef.attributes.EmailTo;

					emailSubjectDynamicCheckbox.checked = props.attributes.thisModelRef.attributes.EmailSubjectDynamicCheckbox;
					emailSubject.value = props.attributes.thisModelRef.attributes.EmailSubject;

					emailBodyDynamicCheckbox.checked = props.attributes.thisModelRef.attributes.EmailBodyDynamicCheckbox;
					emailBody.value = props.attributes.thisModelRef.attributes.EmailBody;

					fileAttachmentDynamicCheckbox.checked = props.attributes.thisModelRef.attributes.FileAttachmentDynamicCheckbox;
					// fileAttachment.value = props.attributes.thisModelRef.attributes.FileAttachment.name;
				} catch(e) {
					// statements
					console.log(e);
				}

				try {
					// update the props according to the checkbox
					emailFromDynamicCheckbox.addEventListener("change", () => {
			        	if (emailFromDynamicCheckbox.checked) {
			        		// props.attributes.thisModelRef.set('EmailFromDynamicCheckbox', true);
			        		// // and clean the EmailFrom prop - to make sure its not saved for script and is taken from the user input
			        		// props.attributes.thisModelRef.set('EmailFrom', "");

			        		// setting these prperties/traits together makes the sendEmailScript run only once (as needed)
			        		props.attributes.thisModelRef.set({
			        			EmailFromDynamicCheckbox: true,
			        			EmailFrom: "",
			        		});
			        		console.log('checked');
			        	} else {
			        		// props.attributes.thisModelRef.set('EmailFromDynamicCheckbox', false);
			        		// // if its not dynamic, it will take set-input so set it if the value is provided
			        		// if (emailFrom.value) {
			        		// 	props.attributes.thisModelRef.set('EmailFrom', emailFrom.value);
			        		// }

			        		props.attributes.thisModelRef.set({
			        			EmailFromDynamicCheckbox: false,
			        			EmailFrom: emailFrom.value ? emailFrom.value : "",
			        		});
			        		console.log('unchecked');
			        	}

			        	console.log('props.attributes.thisModelRef', props.attributes.thisModelRef);

			        	// close the modal on clicking the button, at the end
			        	// editor.Modal.close();

			        	// After updating, trigger a re-render so that the pencil icon comes back and rerun sendEmailScript
			    		// props.attributes.thisModelRef.view.render();
			        });

			        // // updates also according to the input field separately if the user initially also doesn't check the checkbox
			        // emailFromBtn.addEventListener("click", () => {
			        // 	if (!emailFromDynamicCheckbox.checked) {
			        // 		// if its not dynamic, it will take set-input so set it if the value is provided
			        // 		if (emailFrom.value) {
			        // 			props.attributes.thisModelRef.set('EmailFrom', emailFrom.value);
			        // 		}
			        // 	}

			        // 	console.log('props.attributes.thisModelRef', props.attributes.thisModelRef);

			        // 	// close the modal on clicking the button, at the end
			        // 	editor.Modal.close();
			        	
			        // 	// After updating, trigger a re-render so that the pencil icon comes back
			    	// 	props.attributes.thisModelRef.view.render();
			        // });

			        // update the props according to the checkbox
					emailToDynamicCheckbox.addEventListener("change", () => {
			        	if (emailToDynamicCheckbox.checked) {
			        		// props.attributes.thisModelRef.set('EmailToDynamicCheckbox', true);
			        		// // and clean the EmailTo prop - to make sure its not saved for script and is taken from the user input
			        		// props.attributes.thisModelRef.set('EmailTo', "");

			        		// setting these prperties/traits together makes the sendEmailScript run only once (as needed)
			        		props.attributes.thisModelRef.set({
			        			EmailToDynamicCheckbox: true,
			        			EmailTo: "",
			        		});
			        	} else {
			        		// props.attributes.thisModelRef.set('EmailToDynamicCheckbox', false);
			        		// // if its not dynamic, it will take set-input so set it if the value is provided
			        		// if (emailTo.value) {
			        		// 	props.attributes.thisModelRef.set('EmailTo', emailTo.value);
			        		// }

			        		props.attributes.thisModelRef.set({
			        			EmailToDynamicCheckbox: false,
			        			EmailTo: emailTo.value ? emailTo.value : "",
			        		});
			        	}

			        	console.log('props.attributes.thisModelRef', props.attributes.thisModelRef);

			        	// close the modal on clicking the button, at the end
			        	// editor.Modal.close();

			        	// After updating, trigger a re-render so that the pencil icon comes back
			    		// props.attributes.thisModelRef.view.render();
			        });

			        // // updates also according to the input field separately if the user initially also doesn't check the checkbox
			        // emailToBtn.addEventListener("click", () => {
			        // 	if (!emailToDynamicCheckbox.checked) {
			        // 		// if its not dynamic, it will take set-input so set it if the value is provided
			        // 		if (emailTo.value) {
			        // 			props.attributes.thisModelRef.set('EmailTo', emailTo.value);
			        // 		}
			        // 	}

			        // 	console.log('props.attributes.thisModelRef', props.attributes.thisModelRef);

			        // 	// close the modal on clicking the button, at the end
			        // 	editor.Modal.close();
			        	
			        // 	// After updating, trigger a re-render so that the pencil icon comes back
			    	// 	props.attributes.thisModelRef.view.render();
			        // });

			        // update the props according to the checkbox
					emailSubjectDynamicCheckbox.addEventListener("change", () => {
			        	if (emailSubjectDynamicCheckbox.checked) {
			        		// props.attributes.thisModelRef.set('EmailSubjectDynamicCheckbox', true);
			        		// // and clean the EmailSubject prop - to make sure its not saved for script and is taken from the user input
			        		// props.attributes.thisModelRef.set('EmailSubject', "");

			        		// setting these prperties/traits together makes the sendEmailScript run only once (as needed)
			        		props.attributes.thisModelRef.set({
			        			EmailSubjectDynamicCheckbox: true,
			        			EmailSubject: "",
			        		});
			        	} else {
			        		// props.attributes.thisModelRef.set('EmailSubjectDynamicCheckbox', false);
			        		// // if its not dynamic, it will take set-input so set it if the value is provided
			        		// if (emailSubject.value) {
			        		// 	props.attributes.thisModelRef.set('EmailSubject', emailSubject.value);
			        		// }

			        		props.attributes.thisModelRef.set({
			        			EmailSubjectDynamicCheckbox: false,
			        			EmailSubject: emailSubject.value ? emailSubject.value : "",
			        		});
			        	}

			        	console.log('props.attributes.thisModelRef', props.attributes.thisModelRef);

			        	// close the modal on clicking the button, at the end
			        	// editor.Modal.close();

			        	// After updating, trigger a re-render so that the pencil icon comes back
			    		// props.attributes.thisModelRef.view.render();
			        });

			        // // updates also according to the input field separately if the user initially also doesn't check the checkbox
			        // emailSubjectBtn.addEventListener("click", () => {
			        // 	if (!emailSubjectDynamicCheckbox.checked) {
			        // 		// if its not dynamic, it will take set-input so set it if the value is provided
			        // 		if (emailSubject.value) {
			        // 			props.attributes.thisModelRef.set('EmailSubject', emailSubject.value);
			        // 		}
			        // 	}

			        // 	console.log('props.attributes.thisModelRef', props.attributes.thisModelRef);

			        // 	// close the modal on clicking the button, at the end
			        // 	editor.Modal.close();
			        	
			        // 	// After updating, trigger a re-render so that the pencil icon comes back
			    	// 	props.attributes.thisModelRef.view.render();
			        // });

			        // update the props according to the checkbox
					emailBodyDynamicCheckbox.addEventListener("change", () => {
			        	if (emailBodyDynamicCheckbox.checked) {
			        		// props.attributes.thisModelRef.set('EmailBodyDynamicCheckbox', true);
			        		// // and clean the EmailBody prop - to make sure its not saved for script and is taken from the user input
			        		// props.attributes.thisModelRef.set('EmailBody', "");

			        		// setting these prperties/traits together makes the sendEmailScript run only once (as needed)
			        		props.attributes.thisModelRef.set({
			        			EmailBodyDynamicCheckbox: true,
			        			EmailBody: "",
			        		});
			        	} else {
			        		// props.attributes.thisModelRef.set('EmailBodyDynamicCheckbox', false);
			        		// // if its not dynamic, it will take set-input so set it if the value is provided
			        		// if (emailBody.value) {
			        		// 	props.attributes.thisModelRef.set('EmailBody', emailBody.value);
			        		// }

			        		props.attributes.thisModelRef.set({
			        			EmailBodyDynamicCheckbox: false,
			        			EmailBody: emailBody.value ? emailBody.value : "",
			        		});
			        	}

			        	console.log('props.attributes.thisModelRef', props.attributes.thisModelRef);

			        	// close the modal on clicking the button, at the end
			        	// editor.Modal.close();

			        	// After updating, trigger a re-render so that the pencil icon comes back
			    		// props.attributes.thisModelRef.view.render();
			        });

			        // // updates also according to the input field separately if the user initially also doesn't check the checkbox
			        // emailBodyBtn.addEventListener("click", () => {
			        // 	if (!emailBodyDynamicCheckbox.checked) {
			        // 		// if its not dynamic, it will take set-input so set it if the value is provided
			        // 		if (emailBody.value) {
			        // 			props.attributes.thisModelRef.set('EmailBody', emailBody.value);
			        // 		}
			        // 	}

			        // 	console.log('props.attributes.thisModelRef', props.attributes.thisModelRef);

			        // 	// close the modal on clicking the button, at the end
			        // 	editor.Modal.close();
			        	
			        // 	// After updating, trigger a re-render so that the pencil icon comes back
			    	// 	props.attributes.thisModelRef.view.render();
			        // });

			        // update the props according to the checkbox
					fileAttachmentDynamicCheckbox.addEventListener("change", () => {
			        	if (fileAttachmentDynamicCheckbox.checked) {
			        		// props.attributes.thisModelRef.set('FileAttachmentDynamicCheckbox', true);
			        		// // and clean the FileAttachment prop - to make sure its not saved for script and is taken from the user input
			        		// props.attributes.thisModelRef.set('FileAttachment', "");

			        		// setting these prperties/traits together makes the sendEmailScript run only once (as needed)
			        		props.attributes.thisModelRef.set({
			        			FileAttachmentDynamicCheckbox: true,
			        			FileAttachment: null,
			        		});
			        	} else {
			        		// props.attributes.thisModelRef.set('FileAttachmentDynamicCheckbox', false);
			        		// // if its not dynamic, it will take set-input so set it if the value is provided
			        		// if (fileAttachment.value) {
			        		// 	props.attributes.thisModelRef.set('FileAttachment', fileAttachment.value);
			        		// }

			        		props.attributes.thisModelRef.set({
			        			FileAttachmentDynamicCheckbox: false,
			        			FileAttachment: selectedFile ? selectedFile : null,
			        		});
			        	}

			        	console.log('props.attributes.thisModelRef', props.attributes.thisModelRef);

			        	// close the modal on clicking the button, at the end
			        	// editor.Modal.close();

			        	// After updating, trigger a re-render so that the pencil icon comes back
			    		// props.attributes.thisModelRef.view.render();
			        });

	        		fileAttachment.addEventListener('change', (event) => {
					    selectedFile = event.target.files[0];
					    console.log('Selected file 1:', selectedFile);
					    // You can do further processing with the selected file here
					});

			        // // updates also according to the input field separately if the user initially also doesn't check the checkbox
			        // fileAttachmentBtn.addEventListener("click", () => {
			        // 	if (!fileAttachmentDynamicCheckbox.checked) {
			        // 		// if its not dynamic, it will take set-input so set it if the value is provided
			        // 		if (fileAttachment.value) {
			        // 			props.attributes.thisModelRef.set('FileAttachment', fileAttachment.value);
			        // 		}
			        // 	}

			        // 	console.log('props.attributes.thisModelRef', props.attributes.thisModelRef);

			        // 	// close the modal on clicking the button, at the end
			        // 	editor.Modal.close();
			        	
			        // 	// After updating, trigger a re-render so that the pencil icon comes back
			    	// 	props.attributes.thisModelRef.view.render();
			        // });

			        // updates also according to the input field separately if the user initially also doesn't check the checkbox
			        // saveEmailFieldsBtn.addEventListener("click", () => {
			        // 	let emailFields = {};

			        // 	if (!emailFromDynamicCheckbox.checked) {
			        // 		// if its not dynamic, it will take set-input so set it if the value is provided
			        // 		if (emailFrom.value) {
			        // 			// emailFields.emailFromVal = emailFrom.value;
			        // 			props.attributes.thisModelRef.set('EmailFrom', emailFrom.value);
			        // 		} else {
			        // 			emailFields.emailFromVal = '';
			        // 		}
			        // 	}

			        // 	if (!emailToDynamicCheckbox.checked) {
			        // 		// if its not dynamic, it will take set-input so set it if the value is provided
			        // 		if (emailTo.value) {
			        // 			// emailFields.emailToVal = emailTo.value;
			        // 			props.attributes.thisModelRef.set('EmailTo', emailTo.value);
			        // 		} else {
			        // 			emailFields.emailToVal = '';
			        // 		}
			        // 	}

			        // 	if (!emailSubjectDynamicCheckbox.checked) {
			        // 		// if its not dynamic, it will take set-input so set it if the value is provided
			        // 		if (emailSubject.value) {
			        // 			// emailFields.emailSubjectVal = emailSubject.value;
			        // 			props.attributes.thisModelRef.set('EmailSubject', emailSubject.value);
			        // 		} else {
			        // 			emailFields.emailSubjectVal = '';
			        // 		}
			        // 	}

			        // 	if (!emailBodyDynamicCheckbox.checked) {
			        // 		// if its not dynamic, it will take set-input so set it if the value is provided
			        // 		if (emailBody.value) {
			        // 			// emailFields.emailBodyVal = emailBody.value;
			        // 			props.attributes.thisModelRef.set('EmailBody', emailBody.value);
			        // 		} else {
			        // 			emailFields.emailBodyVal = '';
			        // 		}
			        // 	}

			        // 	if (!fileAttachmentDynamicCheckbox.checked) {
			        // 		// if its not dynamic, it will take set-input so set it if the value is provided
			        // 		if (selectedFile) {
			        // 			// emailFields.selectedFileVal = selectedFile;
			        // 			props.attributes.thisModelRef.set('FileAttachment', selectedFile);
			        // 			console.log('selectedFile 2', selectedFile);
			        // 		} else {
			        // 			emailFields.selectedFileVal = null;
			        // 		}
			        // 	}

			        // 	// props.attributes.thisModelRef.set({
			        // 	// 	EmailFrom: emailFields.emailFromVal,
			        // 	// 	EmailTo: emailFields.emailToVal,
			        // 	// 	EmailSubject: emailFields.emailSubjectVal,
			        // 	// 	EmailBody: emailFields.emailBodyVal,
			        // 	// 	FileAttachment: emailFields.selectedFileVal,
			        // 	// });

			        // 	console.log('props.attributes.thisModelRef', props.attributes.thisModelRef);

			        // 	if (emailFrom.value && emailTo.value && emailSubject.value && emailBody.value) {
				    //     	// close the modal on clicking the button, at the end only if all the values are provided
				    //     	editor.Modal.close();
			        // 	} else {
			        // 		toast.error(`Please either provide all the (required) fields or make them dynamic with provided checkboxes.`, successTheme );
			        // 	}
			        	
			        	
			        // 	// After updating, trigger a re-render so that the pencil icon comes back
			    	// 	// console.log('rerunning sendEmailScript 1')
			    	// 	// props.attributes.thisModelRef.attributes.script.call(props.attributes.thisModelRef.getEl(), props.attributes.thisModelRef.props());
			    	// 	// console.log('rerunning sendEmailScript 2')
			        // });
			        saveEmailFieldsBtn.onclick = () => {
			        	let emailFields = {};

			        	if (!emailFromDynamicCheckbox.checked) {
			        		// if its not dynamic, it will take set-input so set it if the value is provided
			        		if (emailFrom.value) {
			        			// emailFields.emailFromVal = emailFrom.value;
			        			props.attributes.thisModelRef.set('EmailFrom', emailFrom.value);
			        		} else {
			        			emailFields.emailFromVal = '';
			        		}
			        	}

			        	if (!emailToDynamicCheckbox.checked) {
			        		// if its not dynamic, it will take set-input so set it if the value is provided
			        		if (emailTo.value) {
			        			// emailFields.emailToVal = emailTo.value;
			        			props.attributes.thisModelRef.set('EmailTo', emailTo.value);
			        		} else {
			        			emailFields.emailToVal = '';
			        		}
			        	}

			        	if (!emailSubjectDynamicCheckbox.checked) {
			        		// if its not dynamic, it will take set-input so set it if the value is provided
			        		if (emailSubject.value) {
			        			// emailFields.emailSubjectVal = emailSubject.value;
			        			props.attributes.thisModelRef.set('EmailSubject', emailSubject.value);
			        		} else {
			        			emailFields.emailSubjectVal = '';
			        		}
			        	}

			        	if (!emailBodyDynamicCheckbox.checked) {
			        		// if its not dynamic, it will take set-input so set it if the value is provided
			        		if (emailBody.value) {
			        			// emailFields.emailBodyVal = emailBody.value;
			        			props.attributes.thisModelRef.set('EmailBody', emailBody.value);
			        		} else {
			        			emailFields.emailBodyVal = '';
			        		}
			        	}

			        	if (!fileAttachmentDynamicCheckbox.checked) {
			        		// if its not dynamic, it will take set-input so set it if the value is provided
			        		if (selectedFile) {
			        			// emailFields.selectedFileVal = selectedFile;
			        			props.attributes.thisModelRef.set('FileAttachment', selectedFile);
			        			console.log('selectedFile 2', selectedFile);
			        		} else {
			        			emailFields.selectedFileVal = null;
			        		}
			        	}

			        	// props.attributes.thisModelRef.set({
			        	// 	EmailFrom: emailFields.emailFromVal,
			        	// 	EmailTo: emailFields.emailToVal,
			        	// 	EmailSubject: emailFields.emailSubjectVal,
			        	// 	EmailBody: emailFields.emailBodyVal,
			        	// 	FileAttachment: emailFields.selectedFileVal,
			        	// });

			        	console.log('props.attributes.thisModelRef', props.attributes.thisModelRef);

			        	if (emailFrom.value && emailTo.value && emailSubject.value && emailBody.value) {
				        	// close the modal on clicking the button, at the end only if all the values are provided
				        	editor.Modal.close();
			        	} else {
			        		toast.error(`Please either provide all the (required) fields or make them dynamic with provided checkboxes.`, successTheme );
			        	}			        	
			        	
			        	// After updating, trigger a re-render so that the pencil icon comes back
			    		// console.log('rerunning sendEmailScript 1')
			    		// props.attributes.thisModelRef.attributes.script.call(props.attributes.thisModelRef.getEl(), props.attributes.thisModelRef.props());
			    		// console.log('rerunning sendEmailScript 2')
			        }
				} catch(e) {
					// statements
					console.log(e);
				}
			}
		});
    }
};

export default CustomEmailPlugin;