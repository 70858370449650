import React, { useEffect, useRef, useState } from "react";
import grapesjs from "grapesjs";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
// mockup images:
import tabPrev from "../assets/tabPrev.png";
import redSlingLogo from "../Screens/component_assets/images/redSlingLogo.png";
// import redSlingLogo from '../Screens/component_assets/images/details_close.png'
import { Button, Divider, Grid, Modal, Typography } from "@mui/material";
import { API_HOST } from "../api_utils";
import {
  deviceManager,
  layerManager,
  panels,
  selectorManager,
  storageManager,
  styleManager,
  traitManager,
  parserCss,
} from "../api_utils/geditor_utils";
import $ from "jquery";
import gjsBlockBasic from "grapesjs-blocks-basic";
import grapesjsBlockBootstrap from "grapesjs-blocks-bootstrap4";

import grapesjsPluginExport from "grapesjs-plugin-export";
import grapesjsStyleBg from "grapesjs-style-bg";
import grapesjsGradient from "grapesjs-style-gradient";
import grapesjsCkEditor from "grapesjs-plugin-ckeditor";
import grapesjsTuiImageEditor from "grapesjs-tui-image-editor";
import CustomTablePlugin from "../Screens/CustomPlugins/CustomTablePlugin";

import BusinessDashboardTemplate from "../templates/templatePlugins/BusinessDashboardTemplate";

import PatchupProviderPlugin from "../plugins/PatchupProviderPlugin";

function readTextFile(file) {
  var rawFile = new XMLHttpRequest();
  rawFile.open("GET", file, false);
  rawFile.onreadystatechange = function () {
    if (rawFile.readyState === 4) {
      if (rawFile.status === 200 || rawFile.status == 0) {
        var allText = rawFile.responseText;
        // alert(allText);
        // return allText;
      }
    }
  };
  rawFile.send(null);
  console.log("rawfile.responseText:", rawFile.responseText);
  return rawFile.responseText;
}

function Tab() {
  const { id } = useParams();
  const { userInfo } = useSelector((state) => state.userLogin);

  const editorRef = useRef(null);
  const [content, setContent] = useState({});

  const getPageContent = async () => {
    const { data } = await axios.get(`${API_HOST}pages/${id}/content`);
    console.log("data", data);
    if (data.message) {
      alert(`${data.message}`);
    } else {
      console.log("data", data);
      setContent(data);
    }
  };

  useEffect(() => {
    getPageContent();
  }, []);

  grapesjs.plugins.add("customRemote", (editor) => {
    editor.Storage.add("customRemote", {
      async load() {
        console.log("pageId during loading:", id);
        // return await axios.get(`${API_HOST}pages/${pageId}/content`);
        const response = await fetch(`${API_HOST}pages/${id}/content`);
        const data = await response.json();
        console.log("load data ->", data);
        // Process the data if needed

        if (!data.message) {
          return data;
        }
        // else {
        //     const content = {};
        //     content["my-html"] = editor.getHtml();
        //     content["my-components"] = JSON.stringify(editor.getComponents())
        //     content["my-css"] = editor.getCss();
        //     return await axios.post(`${API_HOST}pages/${id}/content`, content);
        // }
      },

      // async store(data) {
      //     console.log('pageId during storing:', id);
      //     console.log('data:', data);

      //     // Create the dynamic content object
      //     const content = {};

      //     // Check if "my-html" exists in the original data
      //     // if (data.hasOwnProperty("my-html")) {
      //     content["my-html"] = editor.getHtml();
      //     // }

      //     // Check if "my-components" exists in the original data
      //     // if (data.hasOwnProperty("my-components")) {
      //     content["my-components"] = JSON.stringify(editor.getComponents());
      //     // }

      //     // Check if "assets" exists in the original data
      //     if (data.hasOwnProperty("assets")) {
      //         content["my-assets"] = JSON.stringify(data["assets"]);
      //     }

      //     // Check if "my-css" exists in the original data
      //     // if (data.hasOwnProperty("my-css")) {
      //     content["my-css"] = editor.getCss();
      //     // }

      //     // Check if "styles" exists in the original data
      //     if (data.hasOwnProperty("styles")) {
      //         content["my-styles"] = JSON.stringify(data["styles"]);
      //     }

      //     console.log("content:", content);

      //     return await axios.post(`${API_HOST}pages/${id}/content`, content);
      // },
    });

    editor.Storage.getStorageOptions(
      "remote"
    ).urlStore = `${API_HOST}pages/${id}/content`;
    editor.Storage.getStorageOptions(
      "remote"
    ).urlLoad = `${API_HOST}pages/${id}/content`;

    console.log(
      'editor.Storage.getStorageOptions("remote"):',
      editor.Storage.getStorageOptions("remote")
    );
  });

  useEffect(() => {
    if (content) {
      console.log("content", content);
      const editor = grapesjs.init({
        container: editorRef.current,

        width: "100%",

        height: "100% ",

        overflowY: "scroll",

        allowScripts: 1,

        modal: {
          title: "Initialize",
          content: "",
          backdrop: false,
        },

        assetManager: {
          // Upload endpoint, set `false` to disable upload, default `false`

          uploadFile: function (e) {
            console.log(e);
            var files = e.dataTransfer ? e.dataTransfer.files : e.target.files;
            // ...send somewhere
            console.log(files);

            var formData = new FormData();

            for (var i in files) {
              console.log("iiii", i);
              formData.append("file", files[i]);
            }

            $.ajax({
              url: `${API_HOST}assets/uploadphoto/${id}`,
              type: "POST",
              data: formData,
              contentType: false,
              crossDomain: true,
              mimeType: "multipart/form-data",
              processData: false,
              success: function (result) {
                console.log(result);
                if (result === "Please upload an Image less than 5 mb") {
                  return;
                } else {
                  var images = result;
                  editor.AssetManager.add({
                    src: images,
                    name: "Uploaded Image",
                  });
                  //
                  // made a change
                  e.target.value = `${images}`;
                }
              },
            });
          },

          // The name used in POST to pass uploaded files, default: `'files'`
          uploadName: "file",
          // Text on upload input
          uploadText: "Drop files here or click to upload",
          // Label for the add button
          addBtnText: "Add image",
          assets: [
            "http://placehold.it/350x250/78c5d6/fff/image1.jpg",
            // Pass an object with your properties
            {
              type: "image",
              src: "http://placehold.it/350x250/459ba8/fff/image2.jpg",
              height: 350,
              width: 250,
              name: "displayName",
            },
            {
              // As the 'image' is the base type of assets, omitting it will
              // be set as `image` by default
              src: "http://placehold.it/350x250/79c267/fff/image3.jpg",
              height: 350,
              width: 250,
              name: "displayName",
            },
          ],
          multiUpload: true,
        },

        storageManager: {
          type: "customRemote",
          autosave: false,
          autoload: true,
          // stepsBeforeSave: 1,
          contentTypeJson: true,
          storeComponents: true,
          storeStyles: false,
          storeHtml: false,
          storeCss: false,
          id: "my-",
          // urlStore: `${API_HOST}pages/${pageId}/content`,
          // urlLoad: `${API_HOST}pages/${pageId}/content`,
          // headers: {
          //     'Content-Type': 'application/json'
          // },
        },

        parser: {
          parserCss,
        },

        styleManager: styleManager,

        layerManager: layerManager,

        traitManager: traitManager,

        selectorManager: selectorManager,

        panels: panels,

        deviceManager: deviceManager,

        plugins: [
          "customRemote",
          gjsBlockBasic,
          // myNewComponentTypes,
          // tailwindComponent,
          grapesjsBlockBootstrap,
          grapesjsPluginExport,
          grapesjsStyleBg,
          grapesjsGradient,
          grapesjsCkEditor,
          grapesjsTuiImageEditor,
          CustomTablePlugin,
          BusinessDashboardTemplate,
          PatchupProviderPlugin,
        ],

        pluginsOpts: {
          [gjsBlockBasic]: {
            labelImage: "  ",
            labelVideo: "  ",
            showBasic: false,
            labelColumn1: "",
            labelColumn2: "",
            labelColumn37: "",
            labelText: "",
            labelLink: "",
            labelMap: "",
            labelColumn3: "",
          },
          tailwindComponent: {},
          // plugin: [plugin],
          swiperComponent: {},
          [grapesjsBlockBootstrap]: {
            blockCategories: {
              forms: false,
              layout: false,
            },

            blocks: {
              alert: false,
              card: false,
              card_container: true,
              collapse: false,
              tabs: false,
              dropdown: false,
            },

            labels: {
              image: "Group Image Component",
              video: "Group Video Component",
            },
          },
          [grapesjsPluginExport]: {
            root: {
              css: {
                "main.css": (editor) => editor.getCss(),
              },

              js: {
                "main.js": (editor) => editor.getJs(),
              },
              bootstrap: {
                "bootstrap.min.css": (editor) =>
                  readTextFile("/bootstrap/css/bootstrap.min.css"),
                "bootstrap.bundle.min.js": (editor) =>
                  readTextFile("/bootstrap/js/bootstrap.bundle.min.js"),
                "jquery-3.5.1.slim.min.js": (editor) =>
                  readTextFile("/bootstrap/jquery/jquery-3.5.1.slim.min.js"),
              },
              swiper: {
                "swiper-bundle.min.css": (editor) =>
                  readTextFile("/swiper/css/swiper-bundle.min.css"),
                "swiper-bundle.min.js": (editor) =>
                  readTextFile("/swiper/js/swiper-bundle.min.js"),
              },
              "index.html": (editor) =>
                `<!doctype html>
                            <html lang="en">
                              <head>
                                <meta charset="utf-8">
                                <meta name="viewport" content="width=device-width, initial-scale=1.0">
                                
                                <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.2.1/css/all.min.css" integrity="sha512-MV7K8+y+gLIBoVD59lQIYicR65iaqukzvf/nwasF0nqhPay5w/9lJmVM2hMDcnK1OnMGCdVK+iQrJ7lzPJQd1w==" crossorigin="anonymous" referrerpolicy="no-referrer" />
                                <script src="./bootstrap/jquery-3.5.1.slim.min.js"></script>
                                <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-rbsA2VBKQhggwzxH7pPCaAqO46MgnOM80zW1RWuH61DGLwZJEdK2Kadq2F9CUG65" crossorigin="anonymous">
                                <link rel="stylesheet" href="./bootstrap/bootstrap.min.css">
                                <script src="./bootstrap/bootstrap.bundle.min.js"></script>   
                                <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/js/bootstrap.min.js" integrity="sha384-cuYeSxntonz0PPNlHhBs68uyIAVpIIOZZ5JqeqvYYIcEL727kskC66kF92t6Xl2V" crossorigin="anonymous"></script>                             
                                <script src="https://cdn.jsdelivr.net/npm/@popperjs/core@2.11.6/dist/umd/popper.min.js" integrity="sha384-oBqDVmMz9ATKxIep9tiCxS/Z9fNfEXiDAYTujMAeBAsjFuCZSmKbSSUnQlmh/jp3" crossorigin="anonymous"></script>
                                <link rel="stylesheet" href="./swiper/swiper-bundle.min.css">
                                <script src="./swiper/swiper-bundle.min.js"></script>
                                <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/@ionic/core/css/ionic.bundle.css" />
                                <script nomodule src="https://cdn.jsdelivr.net/npm/@ionic/core/dist/ionic/ionic.js"></script>
                                <script type="module" src="https://cdn.jsdelivr.net/npm/@ionic/core/dist/ionic/ionic.esm.js"></script>
                                <link rel="stylesheet" href="https://unpkg.com/tailwindcss@^1.0/dist/tailwind.min.css">  
                                <script src="https://cdnjs.cloudflare.com/ajax/libs/Chart.js/4.2.1/chart.min.js" integrity="sha512-v3ygConQmvH0QehvQa6gSvTE2VdBZ6wkLOlmK7Mcy2mZ0ZF9saNbbk19QeaoTHdWIEiTlWmrwAL4hS8ElnGFbA==" crossorigin="anonymous" referrerpolicy="no-referrer"></script>
                                
                                <!-- <link rel="stylesheet" href="path/to/grapick.min.css"> -->
                                <!-- <script src="path/to/grapick.min.js"></script> -->   
                                
                                <link rel="stylesheet" href="./css/main.css">
                                <script type="text/javascript" src="./js/main.js" defer></script>       
                              </head>
                              <body>
                                    ${editor.getHtml()}                                 
                              </body>
                            </html>`,
            },
            filenamePfx: `redSling_bundle_${id}`,
          },
          grapesjsStyleBg: {},
          grapesjsCkEditor: {
            options: {
              language: "en",
              toolbarGroups: [
                { name: "document", groups: ["mode", "document", "doctools"] },
                { name: "clipboard", groups: ["clipboard", "undo"] },
                {
                  name: "editing",
                  groups: ["find", "selection", "spellchecker", "editing"],
                },
                { name: "forms", groups: ["forms"] },
                "/",
                { name: "basicstyles", groups: ["basicstyles", "cleanup"] },
                {
                  name: "paragraph",
                  groups: [
                    "list",
                    "indent",
                    "blocks",
                    "align",
                    "bidi",
                    "paragraph",
                  ],
                },
                { name: "links", groups: ["links"] },
                { name: "insert", groups: ["insert"] },
                "/",
                { name: "styles", groups: ["styles"] },
                { name: "colors", groups: ["colors"] },
                { name: "tools", groups: ["tools"] },
                { name: "others", groups: ["others"] },
                { name: "about", groups: ["about"] },
              ],
              removeButtons: "NewPage",
            },
          },
          grapesjsTuiImageEditor: {
            config: {
              includeUI: {
                initMenu: "filter",
              },
            },
            width: "80vw !important",
            script: [
              "https://uicdn.toast.com/tui.code-snippet/v1.5.2/tui-code-snippet.min.js",
              "https://uicdn.toast.com/tui-color-picker/v2.2.7/tui-color-picker.min.js",
              "https://uicdn.toast.com/tui-image-editor/v3.15.2/tui-image-editor.min.js",
            ],
            style: [
              "https://uicdn.toast.com/tui-color-picker/v2.2.7/tui-color-picker.min.css",
              "https://uicdn.toast.com/tui-image-editor/v3.15.2/tui-image-editor.min.css",
            ],
          },

          BusinessDashboardTemplate: {},
          PatchupProviderPlugin: {},
        },
        canvas: {
          styles: [
            "https://unpkg.com/swiper@8/swiper-bundle.min.css",
            "https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/css/bootstrap.min.css",
            "https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.2/font/bootstrap-icons.css",
            "https://cdn.jsdelivr.net/npm/@ionic/core/css/ionic.bundle.css",
            "/styles/main.scss",

            "https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap",
            "https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap",
            "https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap",
            "https://fonts.googleapis.com/css2?family=Montserrat:wght@300&display=swap",
            "https://fonts.googleapis.com/css2?family=Noto+Sans:wght@300&display=swap",
            "https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@300&display=swap",
            "https://fonts.googleapis.com/css2?family=Lato:wght@300&display=swap",
            "https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@300&display=swap",
            "https://fonts.googleapis.com/css2?family=Raleway:wght@300&display=swap",
            "https://fonts.googleapis.com/css2?family=Bacasime+Antique&display=swap",
            "https://fonts.googleapis.com/css2?family=League+Spartan:wght@300&display=swap",
            "https://fonts.googleapis.com/css2?family=Manrope:wght@300&display=swap",
            "https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap",
            "https://fonts.googleapis.com/css2?family=Marcellus&display=swap",
            "https://fonts.googleapis.com/css2?family=Ubuntu:wght@300&display=swap",

            "https://cdn.jsdelivr.net/npm/gridjs/dist/theme/mermaid.min.css",

            "https://cdn.jsdelivr.net/npm/tailwindcss@2.2.19/dist/tailwind.min.css",

            "/assets/css/datatables.css",
            "/assets/css/datatable-extension.css",
            "/assets/css/bootstrap.css",
            "/assets/css/bootstrap.css.map",

            "/assets/css/fontawesome.css",
            "/assets/css/fontawesome.css.map",
            "/assets/css/ionic-icon.css",
            "/assets/css/ionic-icon.css.map",
            "/assets/css/themify.css",
            "/assets/css/themify.css.map",
            "/assets/css/icofont.css",
            "/assets/css/icofont.css.map",
            "/assets/css/simple-line-icon.css",
            "/assets/css/simple-line-icon.css.map",
            "/assets/css/material-design-icon.css",
            "/assets/css/material-design-icon.css.map",
            "/assets/css/pe7-icon.css",
            "/assets/css/pe7-icon.css.map",
            "/assets/css/typicon-icon.css",
            "/assets/css/typicon-icon.css.map",

            "/assets/css/style.css",
            "/assets/css/style.css.map",
            "/assets/css/chartist.css",
            "/assets/css/prism.css",
            "/assets/css/color-1.css",
            "/assets/css/responsive.css",
            "/korebotComponents/sdk/UI/libs/jquery-ui.min.css",
            "/korebotComponents/sdk/UI/chatWindow.css",
            "/korebotComponents/sdk/libs/emojione.sprites.css",
            "/korebotComponents/sdk/libs/purejscarousel.css",
            "/korebotComponents/sdk/UI/custom/customTemplate.css",
          ],
          scripts: [
            "https://cdn.jsdelivr.net/npm/@popperjs/core@2.10.2/dist/umd/popper.min.js",
            "https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/js/bootstrap.min.js",
            "https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/js/bootstrap.bundle.min.js",
            "https://cdn.jsdelivr.net/npm/@ionic/core/dist/ionic/ionic.js",
            "https://cdn.jsdelivr.net/npm/@ionic/core/dist/ionic/ionic.esm.js",
            "https://unpkg.com/swiper@8/swiper-bundle.min.js",
            "https://www.jsdelivr.com/package/npm/chart.js?path=dist",

            // "plotly-2.20.0.min.js",
            "http://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit",
            "https://cdn.jsdelivr.net/npm/gridjs/dist/gridjs.umd.js",

            "https://smtpjs.com/v3/smtp.js",

            "/assets/js/jquery-3.5.1.min.js",
            "/assets/js/datatable/datatables/jquery.dataTables.min.js",
            "/bootstrap/js/bootstrap.bundle.min.js",
            "/assets/js/bootstrap/popper.min.js",
            "/assets/js/bootstrap/bootstrap.js",
            "/korebotComponents/sdk/UI/libs/jquery.js",
            "/korebotComponents/sdk/UI/libs/jquery.tmpl.min.js",
            "/korebotComponents/sdk/UI/libs/jquery-ui.min.js",
            "/korebotComponents/sdk/UI/libs/moment.js",
            "/korebotComponents/sdk/libs/lodash.min.js",
            "/korebotComponents/sdk/libs/d3.v4.min.js",
            "/korebotComponents/sdk/libs/KoreGraphAdapter.js",
            "/korebotComponents/sdk/libs/anonymousassertion.js",
            "/korebotComponents/sdk/kore-bot-sdk-client.js",
            "/korebotComponents/sdk/UI/chatWindow.js",
            "/korebotComponents/sdk/libs/emoji.js",
            "/korebotComponents/sdk/libs/recorder.js",
            "/korebotComponents/sdk/libs/recorderWorker.js",
            "/korebotComponents/sdk/libs/purejscarousel.js",
            "/korebotComponents/sdk/UI/custom/customTemplate.js",
            "/korebotComponents/sdk/libs/perfect-scrollbar.js",
            "/korebotComponents/sdk/UI/kore-config.js",
            "/korebotComponents/sdk/UI/kore-main.js",
            "../src/api_utils/index.js",

            "/assets/js/icons/feather-icon/feather.min.js",
            "/assets/js/icons/feather-icon/feather-icon.js",
            "/assets/js/sidebar-menu.js",
            "/assets/js/config.js",
            "/assets/js/chart/chartist/chartist.js",
            "/assets/js/chart/chartist/chartist-plugin-tooltip.js",
            "/assets/js/chart/apex-chart/apex-chart.js",
            "/assets/js/chart/apex-chart/stock-prices.js",
            "/assets/js/prism/prism.min.js",
            "/assets/js/clipboard/clipboard.min.js",
            "/assets/js/counter/jquery.waypoints.min.js",
            "/assets/js/counter/jquery.counterup.min.js",
            "/assets/js/counter/counter-custom.js",
            "/assets/js/custom-card/custom-card.js",
            "/assets/js/notify/bootstrap-notify.min.js",
            "/assets/js/dashboard/default.js",
            "/assets/js/notify/index.js",
            "/assets/js/tooltip-init.js",
            "/assets/js/script.js",
            "/assets/js/theme-customizer/customizer.js",
          ],
        },
      });
      editor.setStyle(` .cv-canvas {
                display:block;
                }`);

      editor.setComponents(content);

      editor.on("load", () => {
        const components = editor.DomComponents.getComponents();
        components.forEach((component) => {
          const el = component.view.el;
          el.style.pointerEvents = "none";
          el.style.resize = "none";
        });
      });
    }
  }, [content]);

  return (
    <>
      <Grid
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          width: "100vw",
          boxShadow: 6,
          padding: {
            md: "1vh 2vw",
            xs: "1vh 4vw",
            position: "fixed",
            top: "0",
            zIndex: 30,
            backgroundColor: "white",
          },
        }}>
        <Typography
          sx={{ cursor: "pointer", borderRight: "1px solid #27272a" }}>
          <img src={redSlingLogo} />
        </Typography>

        <Typography
          sx={{
            color: "#27272a",
            fontSize: "1.5rem",
            fontWeight: 600,
            marginTop: "1.2rem",
            marginLeft: "0.5rem",
          }}>
          Tablet Preview
        </Typography>
      </Grid>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "90vh",
          width: "100vw",
          marginTop: "5rem",
          backgroundColor: "#fef2f2",
        }}>
        <div
          style={{
            position: "relative",
            maxWidth: "100%",
            height: "100vh",
            marginTop: "8rem",
          }}>
          <img
            src={tabPrev}
            style={{
              width: "100%",
              height: "80%",
              objectFit: "contain",
              zIndex: 20,
              backgroundColor: "transparent",
            }}
            alt="iPhone Mock"
          />
          <div
            style={{
              position: "absolute",
              top: "4.5%",
              left: "4.3%",
              width: "92%",
              height: "71%",
              objectFit: "contain",
              borderRadius: "12px",
              zIndex: 1,
              overflowY: "scroll",
            }}>
            <div ref={editorRef} />
          </div>
        </div>
      </div>
    </>
  );
}

export default Tab;
