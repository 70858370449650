import React from 'react';
import { ItemTypes } from '../LogicBuilder4/ItemTypes';
import { DragBlocks } from '../Testing/Test3/blocks/DragBlocks';

import classes from './LogicBuilder.module.css';

const StatementPanel = (props) => {
	// (instead of statements array); here is where statement grapesjs-blocks will come with blockManager.add({});
	const statements = ['if', 'else if', 'else', 'for', 'while', 'switch'];

	const components = [
        {
            id: 1,
            name: 'if',
            // type: "ALL"
            type: ItemTypes.BOX,
            droppable: true,
            dropped: false

        },
        {
            id: 2,
            name: 'else if',
            type: "ALL"
        },
        {
            id: 3,
            name: 'else',
            type: "ALL"
        },
        {
            id: 4,
            name: 'switch',
            type: "ALL"
        },
        {
            id: 5,
            name: 'for',
            type: "ALL"
        }, 
        {
            id: 6,
            name: "while",
            type: "ALL"
        },
        {
            id: 7,
            name: "Variables",
            type: "ALL"
        }, 
        {
            id: '8',
            name: "NEW FUNCTION",
            type: "New_Function",
            isFunction: true
        },
        {
            id: '9',
            name: "Block",
            type: "blocks"
        },
        {
            id: '10',
            name: "Return",
            type: "return"
        }
    ]
    
    return (
		<div className={classes.statement_panel}>

			<h3 style={{color: '#B6B5B5', marginTop: '5%'}}>Statements</h3>

			<div className={classes.statement_holder}>
				{/* showing the blocks with forloop just for display, untill real blocks are added here */}
				{components.map((item, index) => (
					// <div key={index} className={classes.statement_block}>{block}</div>
					<div key={index} className={classes.statement_block}>
						<DragBlocks dragItem={item} key={item.id} />
					</div>
				))}
			</div>

			{/* receiving the statementPanelCloseHandler() through props and executing it here when onClick event is triggered */}
			<button className={classes.collapse_btn} onClick={props.onClose}>
				<span className={classes.collapse_btn_icon}>
					<svg xmlns="http://www.w3.org/2000/svg" fill="red" className="bi bi-x" viewBox="0 0 16 16" style={{width: '80%', height: '80%'}}>
					  	<path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
					</svg>
				</span>
			</button>

		</div>
	);
};

export default StatementPanel;