import { dividerClasses } from "@mui/material";
import uniqid from "uniqid";
import $ from "jquery";

import { dynamicApiCall } from "./Editor";
import { parserCss } from "./api_utils/geditor_utils";
import { API_HOST } from "./api_utils/index";

export function createTable(editor) {
  console.log("inside createTable");
  // const table = blockManager.get('diy-table');
  // console.log(JSON.parse(JSON.stringify(table)).content);
  // const diyTable = JSON.parse(JSON.stringify(table)).content;
  // event.preventDefault();
  // const tableContainer = document.createElement('span');
  // tableContainer.style.padding = '0.5em';
  // tableContainer.style.width = '100%';
  // tableContainer.style.height = 'auto';
  const diyTable = document.createElement("table");
  diyTable.setAttribute("id", "diytable");
  // diyTable.setAttribute("width", "100%");
  // diyTable.setAttribute("cellspacing", "50");
  // diyTable.setAttribute("border", "1");
  diyTable.style.borderSpacing = "100px";
  diyTable.style.maxWidth = "100%";
  diyTable.style.margin = "auto";

  const tableBody = document.createElement("tbody");

  const numRows = document.getElementById("numRows").value;
  const numCols = document.getElementById("numCols").value;

  console.log(numRows, numCols);

  for (let i = 0; i < numRows; i++) {
    let row = document.createElement("tr");
    row.style.maxWidth = "100%";

    for (let j = 0; j < numCols; j++) {
      let cell = document.createElement("td");
      cell.style.maxWidth = "5em";
      cell.style.wordWrap = "break-word";
      cell.innerHTML = `<p style='margin: auto;'>cell${i}${j}</p>`;
      row.appendChild(cell);
    }
    tableBody.appendChild(row);
  }

  // for (let i = 0; i < numRows; i++) {
  //     const row = diyTable.insertRow(-1);
  //     for (let j = 0; j < numCols; j++) {
  //         const cell = row.insertCell(j);
  //         cell.innerHTML = `<p style="width: auto;">cell${i}${j}</p>`;
  //     }
  // }
  // tableContainer.appendChild(diyTable);
  // console.log(diyTable, typeof diyTable, JSON.stringify(diyTable), diyTable.outerHTML, typeof diyTable.outerHTML);

  // blockManager.add('diytable', {
  //     label: 'Customized Table',
  //     category: 'Table',
  //     content: diyTable.outerHTML,
  // });

  // console.log(editor);

  diyTable.appendChild(tableBody);

  document.getElementById("numRows").value = "";
  document.getElementById("numCols").value = "";

  editor.getSelected().append(diyTable.outerHTML);
  editor.Modal.close(); // to close the modal automatically after creating the table

  // const table = blockManager.get('diy-table');
  // const tableContent = JSON.parse(JSON.stringify(table));
  // tableContent.content = diyTable.outerHTML;
  console.log("table appended");
}

function generateHTMLTable(data) {
  // Helper function to generate HTML table rows
  function generateRows(data) {
    let rows = "";
    if (Array.isArray(data)) {
      // Handle array data
      data.forEach((item) => {
        rows += `<tr>${generateCells(item)}</tr>`;
      });
    } else if (typeof data === "object" && data !== null) {
      // Handle object data
      rows += `<tr>${generateCells(data)}</tr>`;
    }
    return rows;
  }

  // Helper function to generate HTML table cells
  function generateCells(data) {
    let cells = "";
    for (let key in data) {
      if (
        Array.isArray(data[key]) ||
        (typeof data[key] === "object" && data[key] !== null)
      ) {
        // Recursively generate nested rows for arrays or objects
        cells += `<td><table>${generateRows(data[key])}</table></td>`;
      } else {
        // Generate table cell for non-nested values
        cells += `<td>${data[key]}</td>`;
      }
    }
    return cells;
  }

  // Generate the HTML table structure
  const table = `<table>${generateRows(data)}</table>`;

  return table;
}

export function createGrid(editor) {
  console.log("inside createGrid");

  const uniqueId = uniqid("gridTable-");
  console.log("gridTable ID for class:", uniqueId);

  const gridTable = document.createElement("table");
  gridTable.setAttribute("id", "gridTable");
  gridTable.setAttribute("class", "table");
  gridTable.classList.add(`${uniqueId}`);

  // gridTable.setAttribute("width", "100%");
  // gridTable.setAttribute("cellspacing", "50");
  // gridTable.setAttribute("border", "1");

  // gridTable.style.borderSpacing = '100px';
  // gridTable.style.maxWidth = '100%';
  // gridTable.style.margin = 'auto';

  const numRows = document.getElementById("numRows").value;
  const numCols = document.getElementById("numCols").value;

  console.log(numRows, numCols);

  let gridHead = document.createElement("thead");

  let gridHeadRow = document.createElement("tr");

  for (let col = 0; col < numCols; col++) {
    let th = document.createElement("th");
    th.setAttribute("scope", "col");
    th.innerHTML = `<p>#${col}</p>`;
    gridHeadRow.appendChild(th);
  }

  gridHead.appendChild(gridHeadRow);

  gridTable.appendChild(gridHead);

  let gridBody = document.createElement("tbody");

  for (let row = 1; row < numRows; row++) {
    let tr = document.createElement("tr");

    for (let col1 = 0; col1 < numCols; col1++) {
      let td = document.createElement("td");
      td.innerHTML = `<p>cell${row}${col1}</p>`;

      tr.appendChild(td);
    }

    gridBody.appendChild(tr);
  }

  gridTable.appendChild(gridBody);

  document.getElementById("numRows").value = "";
  document.getElementById("numCols").value = "";

  editor.getSelected().append(gridTable.outerHTML);
  editor.Modal.close(); // to close the modal automatically after creating the table

  // const table = blockManager.get('diy-table');
  // const tableContent = JSON.parse(JSON.stringify(table));
  // tableContent.content = gridTable.outerHTML;
  console.log("grid appended");
}

export function createNavBar(editor) {
  // so that affecting one accordion styling doesn't affect other accordions in the same page
  const uniqueId = uniqid("nav-bar-");
  // console.log("creating unique ID for class:", uniqueId);

  const mainDiv = document.createElement("div");
  mainDiv.style.display = "flex";
  mainDiv.style.alignItems = "center";
  mainDiv.style.justifyContent = "center";
  mainDiv.style.padding = "0.5em";

  mainDiv.innerHTML = `
        <nav class="nav-comp-div ${uniqueId}">
            <ul>
                <li><a href="#"><p>Home</p></a></li>
                <li><a href="#"><p>Contact us</p></a></li>
                <li><a href="#"><p>About us</p></a></li>
                <li><a href="#"><p>Pages</p></a></li>
            </ul>
        </nav>

        <style>
            .nav-comp-div {
                width: 100%;
            }

            .nav-comp-div ul {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: space-around;
                align-items: center;
                max-width: 90%;
                margin-left: auto;
                margin-right: auto;
                margin-top: auto;
                margin-bottom: auto;
            }

            .nav-comp-div ul li {
                list-style: none;
                margin: 0.5em;
            }

            @media (max-width: 480px) {
                .nav-comp-div {
                    display: none;
                }
            }
        </style>
    `;

  // editor.addComponents(mainDiv.outerHTML);
  // console.log("nav-bar appended");
  return mainDiv.outerHTML;
}

export function createNavBarMob(editor) {
  // so that affecting one accordion styling doesn't affect other accordions in the same page
  const uniqueId = uniqid("nav-mob-bar-");
  // console.log("creating unique ID for class:", uniqueId);

  const mainDiv = document.createElement("div");
  mainDiv.style.display = "flex";
  mainDiv.style.alignItems = "center";
  mainDiv.style.justifyContent = "flex-end";
  mainDiv.style.padding = "0.5em";

  mainDiv.innerHTML = `
        <nav id="nav-mob-bar" class="hamburger-nav ${uniqueId}">
            <img class="hamburger-nav-img" src="/templates/images/hamburger-menu.png">
            <input type="checkbox" id="toggle-nav" name="toggle-nav" required checked />
            <ul>
                <li><a href="#">Home</a></li>
                <li><a href="#">Contact us</a></li>
                <li><a href="#">About us</a></li>
                <li><a href="#">Pages</a></li>
            </ul>
        </nav>

        <style>
            .hamburger-nav {
                display: block;
                position: relative;
            }

            .hamburger-nav-img {
                margin-right: 3%;
                margin-top: 3%;
                width: 1.5em;
                float: right;
            }

            #toggle-nav {
                position: absolute;
                right: 0;
                top: 0;
                width: 2.5em;
                height: 2.5em;
                float: right;
                opacity: 0;
                z-index: 2;
            }

            #toggle-nav+ul {
                display: none;
                transition: all 100ms;
                
            }

            #toggle-nav+ul li {
                list-style: none;
                margin-bottom: 5%;
            }

            #toggle-nav:checked+ul {
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                align-items: flex-end;
                max-width: max-content;
                
                position: absolute;
                margin-top: 105%;
                right: 5%;
            }

            @media (min-width: 500px) {
                .hamburger-nav {
                    display: none;
                }
            }
        </style>
    `;

  // editor.addComponents(mainDiv.outerHTML);
  // console.log("nav-mob-bar appended");
  return mainDiv.outerHTML;
}

export function createBasicForm(editor) {
  console.log("inside createBasicForm");

  const basicForm = document.createElement("form");
  basicForm.setAttribute("id", "basicForm");
  basicForm.setAttribute("style", "width: 100%;");

  basicForm.style.display = "flex";
  basicForm.style.flexDirection = "column";
  basicForm.style.rowGap = "1em";
  basicForm.style.justifyContent = "center";
  basicForm.style.alignItems = "flex-start";
  basicForm.style.padding = "0.5em";

  basicForm.innerHTML = `
                <h3>Form subject</h3>
                <div class="childDiv">
                    <div class="innerDiv">
                        <label for="name">Name:</label>
                        <input type="text" id="name" name="name" placeholder="Type your name, here">
                    </div>

                    <div class="innerDiv">
                        <label for="emailID">Email ID:</label>
                        <input type="email" id="emailID" name="emailID" placeholder="Type your email ID, here">
                    </div>

                    <div class="innerDiv">
                        <label for="pwd">Password:</label>
                        <input type="password" id="pwd" name="pwd" placeholder="Type your password, here">
                    </div>
                </div>
                <div class="childDiv">
                    <input type="radio" id="option" name="option" value="option1">
                    <label for="option">option1</label><br>
                    <input type="radio" id="option" name="option" value="option2">
                    <label for="option">option2</label><br>
                    <input type="radio" id="option" name="option" value="option3">
                    <label for="option">option3</label>
                </div>

                <div class="childDiv">
                    <input type="checkbox" name="option4" value="option4">
                    <label for="option4">option4</label><br>
                    <input type="checkbox" name="option5" value="option5">
                    <label for="option5">option5</label><br>
                    <input type="checkbox" name="option6" value="option6">
                    <label for="option6">option6</label>
                </div>

                <div class="childDiv">
                    <label for="date">Date:</label><br>
                    <input type="date" id="date" name="date">
                </div>

                <div class="childDiv">
                    <label for="url">URL:</label><br>
                    <input type="url" id="url" name="url" placeholder="(http)https://www.google.com/">
                </div>
                <button type="submit">Submit</button>
            `;

  const childDiv = basicForm.querySelectorAll(".childDiv");
  for (var i = 0; i < childDiv.length; i++) {
    childDiv[i].style.display = "inline-flex";
    childDiv[i].style.flexDirection = "row";
    childDiv[i].style.flexWrap = "wrap";
    // childDiv[i].style.columnGap = '1em';
    childDiv[i].style.justifyContent = "flex-start";
    childDiv[i].style.alignItems = "center";
    childDiv[i].style.padding = "10px 20px";

    const innerDiv = childDiv[i].querySelectorAll(".innerDiv");
    for (var j = 0; j < innerDiv.length; j++) {
      innerDiv[j].style.display = "inline-flex";
      innerDiv[j].style.flexDirection = "column";
    }
  }

  const submitBtn = basicForm.getElementsByTagName("button");
  submitBtn[0].style.padding = "0.5em";

  // editor.addComponents(basicForm.outerHTML);
  // editor.Modal.close(); // to close the modal automatically after creating the form

  console.log("basicForm appended");
  return basicForm.outerHTML;
}

export function createTextRegularForm(editor) {
  console.log("inside createTextRegularForm");
}

export function createMobBasicForm(editor) {
  console.log("inside createMobBasicForm");

  const basicForm = document.createElement("form");
  basicForm.setAttribute("id", "basicForm");
  basicForm.setAttribute("style", "width: 100%;");

  basicForm.style.display = "flex";
  basicForm.style.flexDirection = "column";
  basicForm.style.rowGap = "1em";
  basicForm.style.justifyContent = "center";
  basicForm.style.alignItems = "flex-start";
  basicForm.style.padding = "0.5em";

  basicForm.innerHTML = `
                <h3 style="font-size: 50px;">Form subject</h3>
                <div class="childDiv">
                    <div class="innerDiv">
                        <label for="name">Name:</label>
                        <input type="text" id="name" name="name" placeholder="Type your name, here">
                    </div>

                    <div class="innerDiv">
                        <label for="emailID">Email ID:</label>
                        <input type="email" id="emailID" name="emailID" placeholder="Type your email ID, here">
                    </div>

                    <div class="innerDiv">
                        <label for="pwd">Password:</label>
                        <input type="password" id="pwd" name="pwd" placeholder="Type your password, here">
                    </div>
                </div>
                <div class="childDiv">
                    <input type="radio" id="option" name="option" value="option1">
                    <label for="option">option1</label><br>
                    <input type="radio" id="option" name="option" value="option2">
                    <label for="option">option2</label><br>
                    <input type="radio" id="option" name="option" value="option3">
                    <label for="option">option3</label>
                </div>

                <div class="childDiv">
                    <input type="checkbox" name="option4" value="option4">
                    <label for="option4">option4</label><br>
                    <input type="checkbox" name="option5" value="option5">
                    <label for="option5">option5</label><br>
                    <input type="checkbox" name="option6" value="option6">
                    <label for="option6">option6</label>
                </div>

                <div class="childDiv">
                    <label for="date">Date:</label><br>
                    <input type="date" id="date" name="date">
                </div>

                <div class="childDiv">
                    <label for="url">URL:</label><br>
                    <input type="url" id="url" name="url" placeholder="(http)https://www.google.com/">
                </div>
                <button type="submit">Submit</button>
            `;

  const childDiv = basicForm.querySelectorAll(".childDiv");
  for (var i = 0; i < childDiv.length; i++) {
    childDiv[i].style.display = "inline-flex !important";
    childDiv[i].style.flexDirection = "row";
    childDiv[i].style.flexWrap = "wrap";
    childDiv[i].style.width = "100%";
    // childDiv[i].style.columnGap = '1em';
    childDiv[i].style.justifyContent = "flex-start";
    childDiv[i].style.alignItems = "center";
    childDiv[i].style.padding = "10px 20px";

    const innerDiv = childDiv[i].querySelectorAll(".innerDiv");
    for (var j = 0; j < innerDiv.length; j++) {
      innerDiv[j].style.display = "inline-flex !important";
      innerDiv[j].style.flexDirection = "column";
    }
  }

  const submitBtn = basicForm.getElementsByTagName("button");
  submitBtn[0].style.padding = "0.5em";

  // editor.addComponents(basicForm.outerHTML);
  // editor.Modal.close(); // to close the modal automatically after creating the form

  console.log("mobBasicForm appended");
  return basicForm.outerHTML;
}

export function appendIcon(editor, icon) {
  // console.log(icon);
  let iCON = icon.querySelector("i");
  if (iCON === null) iCON = icon.querySelector("img");
  // iCON.style.fontSize = '1.5em';

  const iconDiv = document.createElement("span");
  iconDiv.setAttribute('data-gjs-type', 'icon-span');
  iconDiv.style.width = "max-content";
  iconDiv.style.height = "max-content";
  iconDiv.appendChild(iCON);

  editor.getSelected().append(iconDiv.outerHTML);
  editor.Modal.close();

  console.log("icon appended");
}

export function createCarousel(editor) {
  console.log("creating Carousel...");

  const numSlides = document.getElementById("numSlides").value;

  // const mainDiv = document.createElement('div');
  // mainDiv.style.display = 'flex';
  // mainDiv.style.alignItems = 'center';
  // mainDiv.style.justifyContent = 'center';

  // https://css-tricks.com/css-only-carousel/
  const slider = document.createElement("div");
  slider.setAttribute("class", "slider");

  // mainDiv.appendChild(slider);

  const slides = document.createElement("div");
  slides.setAttribute("class", "slides");

  for (var j = 0; j < numSlides; j++) {
    let slideDiv = document.createElement("div");
    slideDiv.setAttribute("id", `slide-${j}`);
    slideDiv.innerHTML = j;
    slides.appendChild(slideDiv);
  }

  slider.appendChild(slides);

  for (var i = 0; i < numSlides; i++) {
    let aTag = document.createElement("a");
    aTag.setAttribute("href", `#slide-${i}`);
    aTag.innerHTML = i;
    slider.appendChild(aTag);
  }

  const slideStyling = document.createElement("style");
  slideStyling.innerHTML = `
                .slider {
                  width: 300px;
                  text-align: center;
                  overflow: hidden;
                }

                .slides {
                  display: flex;                      
                  overflow-x: auto;
                  scroll-snap-type: x mandatory;



                  scroll-behavior: smooth;
                  -webkit-overflow-scrolling: touch;

                  /*
                  scroll-snap-points-x: repeat(300px);
                  scroll-snap-type: mandatory;
                  */
                }
                .slides::-webkit-scrollbar {
                  width: 10px;
                  height: 10px;
                }
                .slides::-webkit-scrollbar-thumb {
                  background: black;
                  border-radius: 10px;
                }
                .slides::-webkit-scrollbar-track {
                  background: transparent;
                }
                .slides > div {
                  scroll-snap-align: start;
                  flex-shrink: 0;
                  width: 300px;
                  height: 300px;
                  margin-right: 1px;
                  border-radius: 10px;
                  background: #eee;
                  transform-origin: center center;
                  transform: scale(1);
                  transition: transform 0.5s;
                  position: relative;

                  display: flex;
                  justify-content: center;
                  align-items: center;
                  font-size: 100px;
                }
                .slides > div:target {
                /*   transform: scale(0.8); */
                }
                .author-info {
                  background: rgba(0, 0, 0, 0.75);
                  color: white;
                  padding: 0.75rem;
                  text-align: center;
                  position: absolute;
                  bottom: 0;
                  left: 0;
                  width: 100%;
                  margin: 0;
                }
                .author-info a {
                  color: white;
                }
                img {
                  object-fit: cover;
                  position: absolute;
                  top: 0;
                  left: 0;
                  width: 100%;
                  height: 100%;
                }

                .slider > a {
                  display: inline-flex;
                  width: 1.5rem;
                  height: 1.5rem;
                  background: white;
                  text-decoration: none;
                  align-items: center;
                  justify-content: center;
                  border-radius: 50%;
                  margin: 0 0 0.5rem 0;
                  position: relative;
                }
                .slider > a:active {
                  top: 1px;
                }
                .slider > a:focus {
                  background: #000;
                }

                /* Don't need button navigation */
                @supports (scroll-snap-type) {
                  .slider > a {
                    display: none;
                  }
                }
            `;

  // mainDiv.appendChild(slideStyling);

  editor.getSelected().append(slider.outerHTML);
  editor.Modal.close();

  // so that it doesn't repeat carousel creation remembering the last input
  document.getElementById("numSlides").value = "";
  console.log("carousel appended");
}

export function createArrNavCarousel(editor) {
  console.log("creating Arr-Nav-Carousel...");

  // so that affecting one carousel styling doesn't affect other carousels in the same page
  const uniqueId = uniqid("carou-");
  console.log("creating unique ID for class:", uniqueId);

  const numArrSlides = document.getElementById("numArrSlides").value;

  // const mainDiv = document.createElement('div');
  // mainDiv.style.display = 'flex';
  // mainDiv.style.alignItems = 'center';
  // mainDiv.style.justifyContent = 'center';
  // mainDiv.style.padding = '0.5em';

  // https://getbootstrap.com/docs/5.2/components/carousel/
  if (numArrSlides <= 2) {
    const carousel = document.createElement("div");
    carousel.setAttribute("id", `carouselExampleControls-${uniqueId}`);
    carousel.classList.add("carousel", `${uniqueId}`);
    // to stop the slideshow effect on hover as well
    // carousel.setAttribute('data-bs-ride', 'carousel');
    carousel.setAttribute("data-bs-interval", "false");
    carousel.setAttribute("data-bs-wrap", "false");
    carousel.style.padding = "0.5em";
    carousel.style.maxWidth = "100%";
    // mainDiv.appendChild(carousel);

    const carouselInner = document.createElement("div");
    carouselInner.setAttribute("class", "carousel-inner");
    carouselInner.classList.add(`${uniqueId}`);

    carousel.appendChild(carouselInner);

    // slide1
    let carouselItem1 = document.createElement("div");
    carouselItem1.setAttribute("class", "carousel-item");
    carouselItem1.classList.add(`${uniqueId}`);
    carouselItem1.classList.add("active");
    carouselItem1.style.padding = "0.5em";
    carouselItem1.style.minHeight = "10em";

    let image1 = document.createElement("img");
    image1.setAttribute("class", "d-block");
    // image1.style.width = '50%';
    image1.setAttribute(
      "src",
      "https://t3.ftcdn.net/jpg/02/48/42/64/360_F_248426448_NVKLywWqArG2ADUxDq6QprtIzsF82dMF.jpg"
    );
    // image1.classList.add('figure-img', 'img-fluid', 'rounded');
    image1.setAttribute("alt", "...");
    // image1.style.margin = 'auto';

    carouselItem1.appendChild(image1);
    carouselInner.appendChild(carouselItem1);

    // slide2
    let carouselItem2 = document.createElement("div");
    carouselItem2.setAttribute("class", "carousel-item");
    carouselItem2.classList.add(`${uniqueId}`);
    carouselItem2.style.padding = "0.5em";
    carouselItem2.style.minHeight = "10em";

    let image2 = document.createElement("img");
    image2.setAttribute("class", "d-block");
    // image2.style.width = '50%';
    image2.setAttribute(
      "src",
      "https://t3.ftcdn.net/jpg/02/48/42/64/360_F_248426448_NVKLywWqArG2ADUxDq6QprtIzsF82dMF.jpg"
    );
    // image2.classList.add('figure-img', 'img-fluid', 'rounded');
    image2.setAttribute("alt", "...");
    // image2.style.margin = 'auto';

    carouselItem2.appendChild(image2);
    carouselInner.appendChild(carouselItem2);

    // prev button
    const prevBtn = document.createElement("button");
    prevBtn.setAttribute("class", "carousel-control-prev");
    prevBtn.setAttribute("type", "button");
    prevBtn.setAttribute(
      "data-bs-target",
      `#carouselExampleControls-${uniqueId}`
    );
    prevBtn.setAttribute("data-bs-slide", "prev");
    prevBtn.style.height = "max-content";
    prevBtn.style.top = "45%";

    carousel.appendChild(prevBtn);

    const carouselControlPrevIcon = document.createElement("span");
    carouselControlPrevIcon.setAttribute("class", "carousel-control-prev-icon");
    carouselControlPrevIcon.setAttribute("aria-hidden", "true");
    carouselControlPrevIcon.style.backgroundColor = "gray";

    prevBtn.appendChild(carouselControlPrevIcon);

    const prevVisuallyHidden = document.createElement("span");
    prevVisuallyHidden.setAttribute("class", "visually-hidden");
    prevVisuallyHidden.innerHTML = "Previous";

    prevBtn.appendChild(prevVisuallyHidden);

    // nxt button
    const nxtBtn = document.createElement("button");
    nxtBtn.setAttribute("class", "carousel-control-next");
    nxtBtn.setAttribute("type", "button");
    nxtBtn.setAttribute(
      "data-bs-target",
      `#carouselExampleControls-${uniqueId}`
    );
    nxtBtn.setAttribute("data-bs-slide", "next");
    nxtBtn.style.height = "max-content";
    nxtBtn.style.top = "45%";

    carousel.appendChild(nxtBtn);

    const carouselControlNxtIcon = document.createElement("span");
    carouselControlNxtIcon.setAttribute("class", "carousel-control-next-icon");
    carouselControlNxtIcon.setAttribute("aria-hidden", "true");
    carouselControlNxtIcon.style.backgroundColor = "gray";

    nxtBtn.appendChild(carouselControlNxtIcon);

    const nxtVisuallyHidden = document.createElement("span");
    nxtVisuallyHidden.setAttribute("class", "visually-hidden");
    nxtVisuallyHidden.innerHTML = "Next";

    nxtBtn.appendChild(nxtVisuallyHidden);

    editor.getSelected().append(carousel.outerHTML);
    editor.Modal.close();
  } else if (numArrSlides > 2) {
    const carousel = document.createElement("div");
    carousel.setAttribute("id", `carouselExampleControls-${uniqueId}`);
    carousel.classList.add("carousel", `${uniqueId}`);
    // to stop the slideshow effect on hover as well
    // carousel.setAttribute('data-bs-ride', 'carousel');
    carousel.setAttribute("data-bs-interval", "false");
    carousel.style.padding = "0.5em";
    carousel.style.maxWidth = "100%";
    // mainDiv.appendChild(carousel);

    const carouselInner = document.createElement("div");
    carouselInner.setAttribute("class", "carousel-inner");
    carouselInner.classList.add(`${uniqueId}`);

    carousel.appendChild(carouselInner);

    for (let i = 1; i <= numArrSlides; i++) {
      let carouselItem = document.createElement("div");
      carouselItem.setAttribute("class", "carousel-item");
      carouselItem.classList.add(`${uniqueId}`);
      if (i === 1) carouselItem.classList.add("active");
      carouselItem.style.padding = "0.5em";
      carouselItem.style.minHeight = "10em";

      let image = document.createElement("img");
      image.setAttribute("class", "d-block");
      // image.style.width = '50%';
      image.setAttribute(
        "src",
        "https://t3.ftcdn.net/jpg/02/48/42/64/360_F_248426448_NVKLywWqArG2ADUxDq6QprtIzsF82dMF.jpg"
      );
      // image.classList.add('figure-img', 'img-fluid', 'rounded');
      image.setAttribute("alt", "...");
      // image.style.margin = 'auto';

      carouselItem.appendChild(image);
      carouselInner.appendChild(carouselItem);
    }

    // prev button
    const prevBtn = document.createElement("button");
    prevBtn.setAttribute("class", "carousel-control-prev");
    prevBtn.setAttribute("type", "button");
    prevBtn.setAttribute(
      "data-bs-target",
      `#carouselExampleControls-${uniqueId}`
    );
    prevBtn.setAttribute("data-bs-slide", "prev");
    prevBtn.style.height = "max-content";
    prevBtn.style.top = "45%";

    carousel.appendChild(prevBtn);

    const carouselControlPrevIcon = document.createElement("span");
    carouselControlPrevIcon.setAttribute("class", "carousel-control-prev-icon");
    carouselControlPrevIcon.setAttribute("aria-hidden", "true");
    carouselControlPrevIcon.style.backgroundColor = "gray";

    prevBtn.appendChild(carouselControlPrevIcon);

    const prevVisuallyHidden = document.createElement("span");
    prevVisuallyHidden.setAttribute("class", "visually-hidden");
    prevVisuallyHidden.innerHTML = "Previous";

    prevBtn.appendChild(prevVisuallyHidden);

    // nxt button
    const nxtBtn = document.createElement("button");
    nxtBtn.setAttribute("class", "carousel-control-next");
    nxtBtn.setAttribute("type", "button");
    nxtBtn.setAttribute(
      "data-bs-target",
      `#carouselExampleControls-${uniqueId}`
    );
    nxtBtn.setAttribute("data-bs-slide", "next");
    nxtBtn.style.height = "max-content";
    nxtBtn.style.top = "45%";

    carousel.appendChild(nxtBtn);

    const carouselControlNxtIcon = document.createElement("span");
    carouselControlNxtIcon.setAttribute("class", "carousel-control-next-icon");
    carouselControlNxtIcon.setAttribute("aria-hidden", "true");
    carouselControlNxtIcon.style.backgroundColor = "gray";

    nxtBtn.appendChild(carouselControlNxtIcon);

    const nxtVisuallyHidden = document.createElement("span");
    nxtVisuallyHidden.setAttribute("class", "visually-hidden");
    nxtVisuallyHidden.innerHTML = "Next";

    nxtBtn.appendChild(nxtVisuallyHidden);

    editor.getSelected().append(carousel.outerHTML);
    editor.Modal.close();
  }

  // so that it doesn't repeat carousel creation remembering the last input
  document.getElementById("numArrSlides").value = "";
  console.log("arr-nav-carousel appended");
}

export function createCaptionCarousel(editor) {
  console.log("creating Caption-Carousel...");

  // so that affecting one carousel styling doesn't affect other carousels in the same page
  const uniqueId = uniqid("cap-slide-");
  console.log("creating unique ID for class:", uniqueId);

  const numCapSlides = document.getElementById("numCapSlides").value;

  // const mainDiv = document.createElement('div');
  // mainDiv.style.display = 'flex';
  // mainDiv.style.alignItems = 'center';
  // mainDiv.style.justifyContent = 'center';
  // mainDiv.style.padding = '0.5em';

  if (numCapSlides <= 2) {
    // https://getbootstrap.com/docs/5.2/components/carousel/
    const carousel = document.createElement("div");
    carousel.setAttribute("id", `carouselExampleCaptions-${uniqueId}`);
    // carousel.classList.add('carousel', 'slide', `${uniqueId}`);
    carousel.classList.add("carousel", `${uniqueId}`);
    // to stop the slideshow effect on hover as well
    // carousel.setAttribute('data-bs-ride', 'false');
    carousel.setAttribute("data-bs-interval", "false");
    carousel.setAttribute("data-bs-wrap", "false");
    carousel.style.padding = "0.5em";
    carousel.style.maxWidth = "100%";
    // mainDiv.appendChild(carousel);

    const carouselInner = document.createElement("div");
    carouselInner.setAttribute("class", "carousel-inner");
    carouselInner.classList.add(`${uniqueId}`);

    carousel.appendChild(carouselInner);

    // slide 1
    let carouselItem1 = document.createElement("div");
    carouselItem1.setAttribute("class", "carousel-item");
    carouselItem1.classList.add(`${uniqueId}`);
    carouselItem1.classList.add("active");
    carouselItem1.style.padding = "0.5em";
    carouselItem1.style.minHeight = "10em";

    let image1 = document.createElement("img");
    image1.setAttribute("class", "d-block");
    // image1.style.width = '50%';
    image1.setAttribute(
      "src",
      "https://t3.ftcdn.net/jpg/02/48/42/64/360_F_248426448_NVKLywWqArG2ADUxDq6QprtIzsF82dMF.jpg"
    );
    // image1.classList.add('figure-img', 'img-fluid', 'rounded');
    image1.setAttribute("alt", "...");
    // image1.style.margin = 'auto';

    carouselItem1.appendChild(image1);

    let carouselCaptionDiv1 = document.createElement("div");
    carouselCaptionDiv1.classList.add(
      "carousel-caption",
      "d-none",
      "d-md-block"
    );

    carouselItem1.appendChild(carouselCaptionDiv1);

    let h51 = document.createElement("h5");
    h51.innerHTML += `Slide Label ${i}`;
    h51.style.color = "black";

    carouselCaptionDiv1.appendChild(h51);

    let p1 = document.createElement("p");
    p1.innerHTML += `Some representative placeholder content for slide ${i}`;
    p1.style.color = "black";

    carouselCaptionDiv1.appendChild(p1);

    carouselInner.appendChild(carouselItem1);

    // slide 2
    let carouselItem2 = document.createElement("div");
    carouselItem2.setAttribute("class", "carousel-item");
    carouselItem2.classList.add(`${uniqueId}`);
    carouselItem2.style.padding = "0.5em";
    carouselItem2.style.minHeight = "10em";

    let image2 = document.createElement("img");
    image2.setAttribute("class", "d-block");
    // image1.style.width = '50%';
    image2.setAttribute(
      "src",
      "https://t3.ftcdn.net/jpg/02/48/42/64/360_F_248426448_NVKLywWqArG2ADUxDq6QprtIzsF82dMF.jpg"
    );
    // image1.classList.add('figure-img', 'img-fluid', 'rounded');
    image2.setAttribute("alt", "...");
    // image1.style.margin = 'auto';

    carouselItem2.appendChild(image1);

    let carouselCaptionDiv2 = document.createElement("div");
    carouselCaptionDiv2.classList.add(
      "carousel-caption",
      "d-none",
      "d-md-block"
    );

    carouselItem2.appendChild(carouselCaptionDiv2);

    let h52 = document.createElement("h5");
    h52.innerHTML += `Slide Label ${i}`;
    h52.style.color = "black";

    carouselCaptionDiv2.appendChild(h52);

    let p2 = document.createElement("p");
    p2.innerHTML += `Some representative placeholder content for slide ${i}`;
    p2.style.color = "black";

    carouselCaptionDiv2.appendChild(p1);

    carouselInner.appendChild(carouselItem2);

    // prev button
    const prevBtn = document.createElement("button");
    prevBtn.setAttribute("class", "carousel-control-prev");
    prevBtn.setAttribute("type", "button");
    prevBtn.setAttribute(
      "data-bs-target",
      `#carouselExampleCaptions-${uniqueId}`
    );
    prevBtn.setAttribute("data-bs-slide", "prev");
    prevBtn.style.height = "max-content";
    prevBtn.style.top = "45%";

    carousel.appendChild(prevBtn);

    const carouselControlPrevIcon = document.createElement("span");
    carouselControlPrevIcon.setAttribute("class", "carousel-control-prev-icon");
    carouselControlPrevIcon.setAttribute("aria-hidden", "true");
    carouselControlPrevIcon.style.backgroundColor = "gray";

    prevBtn.appendChild(carouselControlPrevIcon);

    const prevVisuallyHidden = document.createElement("span");
    prevVisuallyHidden.setAttribute("class", "visually-hidden");
    prevVisuallyHidden.innerHTML = "Previous";

    prevBtn.appendChild(prevVisuallyHidden);

    // nxt button
    const nxtBtn = document.createElement("button");
    nxtBtn.setAttribute("class", "carousel-control-next");
    nxtBtn.setAttribute("type", "button");
    nxtBtn.setAttribute(
      "data-bs-target",
      `#carouselExampleCaptions-${uniqueId}`
    );
    nxtBtn.setAttribute("data-bs-slide", "next");
    nxtBtn.style.height = "max-content";
    nxtBtn.style.top = "45%";

    carousel.appendChild(nxtBtn);

    const carouselControlNxtIcon = document.createElement("span");
    carouselControlNxtIcon.setAttribute("class", "carousel-control-next-icon");
    carouselControlNxtIcon.setAttribute("aria-hidden", "true");
    carouselControlNxtIcon.style.backgroundColor = "gray";

    nxtBtn.appendChild(carouselControlNxtIcon);

    const nxtVisuallyHidden = document.createElement("span");
    nxtVisuallyHidden.setAttribute("class", "visually-hidden");
    nxtVisuallyHidden.innerHTML = "Next";

    nxtBtn.appendChild(nxtVisuallyHidden);

    editor.getSelected().append(carousel.outerHTML);
    editor.Modal.close();
  }

  if (numCapSlides > 2) {
    // https://getbootstrap.com/docs/5.2/components/carousel/
    const carousel = document.createElement("div");
    carousel.setAttribute("id", `carouselExampleCaptions-${uniqueId}`);
    // carousel.classList.add('carousel', 'slide', `${uniqueId}`);
    carousel.classList.add("carousel", `${uniqueId}`);
    // to stop the slideshow effect on hover as well
    // carousel.setAttribute('data-bs-ride', 'false');
    carousel.setAttribute("data-bs-interval", "false");
    carousel.style.padding = "0.5em";
    carousel.style.maxWidth = "100%";
    // mainDiv.appendChild(carousel);

    const carouselInner = document.createElement("div");
    carouselInner.setAttribute("class", "carousel-inner");
    carouselInner.classList.add(`${uniqueId}`);

    carousel.appendChild(carouselInner);

    for (var i = 1; i <= numCapSlides; i++) {
      let carouselItem = document.createElement("div");
      carouselItem.setAttribute("class", "carousel-item");
      carouselItem.classList.add(`${uniqueId}`);
      if (i === 1) carouselItem.classList.add("active");
      carouselItem.style.padding = "0.5em";
      carouselItem.style.minHeight = "10em";

      let image = document.createElement("img");
      image.setAttribute("class", "d-block");
      // image.style.width = '50%';
      image.setAttribute(
        "src",
        "https://t3.ftcdn.net/jpg/02/48/42/64/360_F_248426448_NVKLywWqArG2ADUxDq6QprtIzsF82dMF.jpg"
      );
      // image.classList.add('figure-img', 'img-fluid', 'rounded');
      image.setAttribute("alt", "...");
      // image.style.margin = 'auto';

      carouselItem.appendChild(image);

      let carouselCaptionDiv = document.createElement("div");
      carouselCaptionDiv.classList.add(
        "carousel-caption",
        "d-none",
        "d-md-block"
      );

      carouselItem.appendChild(carouselCaptionDiv);

      let h5 = document.createElement("h5");
      h5.innerHTML += `Slide Label ${i}`;
      h5.style.color = "black";

      carouselCaptionDiv.appendChild(h5);

      let p = document.createElement("p");
      p.innerHTML += `Some representative placeholder content for slide ${i}`;
      p.style.color = "black";

      carouselCaptionDiv.appendChild(p);

      carouselInner.appendChild(carouselItem);
    }

    // prev button
    const prevBtn = document.createElement("button");
    prevBtn.setAttribute("class", "carousel-control-prev");
    prevBtn.setAttribute("type", "button");
    prevBtn.setAttribute(
      "data-bs-target",
      `#carouselExampleCaptions-${uniqueId}`
    );
    prevBtn.setAttribute("data-bs-slide", "prev");
    prevBtn.style.height = "max-content";
    prevBtn.style.top = "45%";

    carousel.appendChild(prevBtn);

    const carouselControlPrevIcon = document.createElement("span");
    carouselControlPrevIcon.setAttribute("class", "carousel-control-prev-icon");
    carouselControlPrevIcon.setAttribute("aria-hidden", "true");
    carouselControlPrevIcon.style.backgroundColor = "gray";

    prevBtn.appendChild(carouselControlPrevIcon);

    const prevVisuallyHidden = document.createElement("span");
    prevVisuallyHidden.setAttribute("class", "visually-hidden");
    prevVisuallyHidden.innerHTML = "Previous";

    prevBtn.appendChild(prevVisuallyHidden);

    // nxt button
    const nxtBtn = document.createElement("button");
    nxtBtn.setAttribute("class", "carousel-control-next");
    nxtBtn.setAttribute("type", "button");
    nxtBtn.setAttribute(
      "data-bs-target",
      `#carouselExampleCaptions-${uniqueId}`
    );
    nxtBtn.setAttribute("data-bs-slide", "next");
    nxtBtn.style.height = "max-content";
    nxtBtn.style.top = "45%";

    carousel.appendChild(nxtBtn);

    const carouselControlNxtIcon = document.createElement("span");
    carouselControlNxtIcon.setAttribute("class", "carousel-control-next-icon");
    carouselControlNxtIcon.setAttribute("aria-hidden", "true");
    carouselControlNxtIcon.style.backgroundColor = "gray";

    nxtBtn.appendChild(carouselControlNxtIcon);

    const nxtVisuallyHidden = document.createElement("span");
    nxtVisuallyHidden.setAttribute("class", "visually-hidden");
    nxtVisuallyHidden.innerHTML = "Next";

    nxtBtn.appendChild(nxtVisuallyHidden);

    editor.getSelected().append(carousel.outerHTML);
    editor.Modal.close();
  }

  // so that it doesn't repeat carousel creation remembering the last input
  document.getElementById("numCapSlides").value = "";
  console.log("caption-carousel appended");
}

export function createAccordion(editor) {
  console.log("creating Accordion...");

  // so that affecting one accordion styling doesn't affect other accordions in the same page
  const uniqueId = uniqid("acc-item-");
  console.log("creating unique ID for class:", uniqueId);

  const numAccordionItems = document.getElementById("numAccordionItems").value;

  const mainDiv = document.createElement("div");
  mainDiv.style.display = "flex";
  mainDiv.style.alignItems = "center";
  mainDiv.style.justifyContent = "flex-start";
  mainDiv.style.padding = "0.5em";

  const accordion = document.createElement("div");
  accordion.setAttribute("id", `accordionExample-${uniqueId}`);
  accordion.style.padding = "0.5em";

  mainDiv.appendChild(accordion);

  for (var i = 1; i <= numAccordionItems; i++) {
    let accordionItem = document.createElement("div");
    accordionItem.setAttribute("class", "accordion-item");

    let accordionHeader = document.createElement("h2");
    accordionHeader.setAttribute("class", "accordion-header");
    accordionHeader.setAttribute("id", `heading${i}`);

    let accordionBtn = document.createElement("button");
    accordionBtn.setAttribute("class", "accordion-button");
    accordionBtn.setAttribute("type", "button");
    accordionBtn.setAttribute("data-bs-toggle", "collapse");
    accordionBtn.setAttribute("data-bs-target", `#collapse${i}`);
    accordionBtn.setAttribute("aria-expanded", "true");
    accordionBtn.setAttribute("aria-expanded", "true");
    accordionBtn.setAttribute("aria-controls", `collapse${i}`);
    accordionBtn.innerHTML += `<p>Accordion Item #${i}</p>`;

    accordionHeader.appendChild(accordionBtn);
    accordionItem.appendChild(accordionHeader);

    let innerDiv = document.createElement("div");
    innerDiv.setAttribute("id", `collapse${i}`);
    innerDiv.classList.add("accordion-collapse", "collapse", "show");
    innerDiv.setAttribute("aria-labelledby", `heading${i}`);
    innerDiv.setAttribute("data-bs-parent", `#accordionExample-${uniqueId}`);

    let accordionBody = document.createElement("div");
    accordionBody.setAttribute("class", "accordion-body");
    accordionBody.innerHTML += `<p><strong>This is the ${i} item's accordion body.</strong> It is shown by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow.</p>`;

    innerDiv.appendChild(accordionBody);

    accordionItem.appendChild(innerDiv);

    accordion.appendChild(accordionItem);
  }

  editor.addComponents(mainDiv.outerHTML);
  editor.Modal.close();

  // so that it doesn't repeat carousel creation remembering the last input
  document.getElementById("numAccordionItems").value = "";
  console.log("accordion appended");
}

export function createMobBasicAccordion(editor) {
  console.log("creating mob-basic-accordion...");

  // so that affecting one accordion styling doesn't affect other accordions in the same page
  const uniqueId = uniqid("mob-basic-accordion-");
  console.log("creating unique ID for class:", uniqueId);

  const numAccordionItems = document.getElementById("numAccordionItems").value;

  // const mainDiv = document.createElement('div');
  // mainDiv.style.display = 'flex';
  // mainDiv.style.alignItems = 'center';
  // mainDiv.style.justifyContent = 'space-evenly';
  // mainDiv.style.padding = '0.5em';

  const ionAccordionGroup = document.createElement("ion-accordion-group");
  ionAccordionGroup.classList.add(`${uniqueId}`);
  ionAccordionGroup.style.padding = "0.5em";
  ionAccordionGroup.style.width = "100%";

  // mainDiv.appendChild(ionAccordionGroup);

  for (var i = 1; i <= numAccordionItems; i++) {
    let ionAccordion = document.createElement("ion-accordion");
    // ionAccordion.setAttribute("value", "first");

    let ionItem = document.createElement("ion-item");
    ionItem.classList.add(`item-${i}-${uniqueId}`);
    ionItem.setAttribute("slot", "header");
    ionItem.setAttribute("color", "light");

    let ionLabel = document.createElement("ion-label");
    ionLabel.innerHTML += `<p>Accordion #${i}</p>`;

    ionItem.appendChild(ionLabel);

    ionAccordion.appendChild(ionItem);

    let ionContent = document.createElement("div");
    ionContent.setAttribute("class", "ion-padding");
    ionContent.classList.add(`content-${i}-${uniqueId}`);
    ionContent.setAttribute("slot", "content");
    ionContent.innerHTML += `<p>Content #${i}</p>`;

    ionAccordion.appendChild(ionContent);

    ionAccordionGroup.appendChild(ionAccordion);
  }

  editor.getSelected().append(ionAccordionGroup.outerHTML);
  editor.Modal.close();

  // so that it doesn't repeat carousel creation remembering the last input
  document.getElementById("numAccordionItems").value = "";
  console.log("mob-basic-accordion appended");
}

export function createMobInsetXBasicAccordion(editor) {
  console.log("creating mob-insetx-basic-accordion...");

  // so that affecting one accordion styling doesn't affect other accordions in the same page
  const uniqueId = uniqid("mob-insetx-basic-accordion-");
  console.log("creating unique ID for class:", uniqueId);

  const numAccordionItems = document.getElementById("numAccordionItems").value;

  // const mainDiv = document.createElement('div');
  // mainDiv.style.display = 'flex';
  // mainDiv.style.alignItems = 'center';
  // mainDiv.style.justifyContent = 'space-evenly';
  // mainDiv.style.padding = '0.5em';

  const ionAccordionGroup = document.createElement("ion-accordion-group");
  ionAccordionGroup.setAttribute("expand", "inset");
  ionAccordionGroup.classList.add(`${uniqueId}`);
  ionAccordionGroup.style.padding = "0.5em";
  ionAccordionGroup.style.width = "100%";
  ionAccordionGroup.style.margin = "auto";

  // mainDiv.appendChild(ionAccordionGroup);

  for (var i = 1; i <= numAccordionItems; i++) {
    let ionAccordion = document.createElement("ion-accordion");
    ionAccordion.classList.add(`acc-${i}-${uniqueId}`);
    // ionAccordion.setAttribute("value", "first");

    let ionItem = document.createElement("ion-item");
    ionItem.setAttribute("slot", "header");
    ionItem.setAttribute("color", "light");
    ionItem.classList.add(`item-${i}-${uniqueId}`);

    let ionLabel = document.createElement("ion-label");
    ionLabel.innerHTML += `<p>Accordion #${i}</p>`;

    ionItem.appendChild(ionLabel);

    ionAccordion.appendChild(ionItem);

    let ionContent = document.createElement("div");
    ionContent.setAttribute("class", "ion-padding");
    ionContent.classList.add(`content-${i}-${uniqueId}`);
    ionContent.setAttribute("slot", "content");
    ionContent.innerHTML += `<p>Content #${i}</p>`;

    ionAccordion.appendChild(ionContent);

    ionAccordionGroup.appendChild(ionAccordion);
  }

  editor.getSelected().append(ionAccordionGroup.outerHTML);
  editor.Modal.close();

  // so that it doesn't repeat carousel creation remembering the last input
  document.getElementById("numAccordionItems").value = "";
  console.log("mob-insetx-basic-accordion appended");
}

export function createMobMultipleAccordion(editor) {
  console.log("creating mob-multiple-accordion...");

  // so that affecting one accordion styling doesn't affect other accordions in the same page
  const uniqueId = uniqid("mob-multiple-accordion-");
  console.log("creating unique ID for class:", uniqueId);

  const numAccordionItems = document.getElementById("numAccordionItems").value;

  // const mainDiv = document.createElement('div');
  // mainDiv.style.display = 'flex';
  // mainDiv.style.alignItems = 'center';
  // mainDiv.style.justifyContent = 'space-evenly';
  // mainDiv.style.padding = '0.5em';

  const ionAccordionGroup = document.createElement("ion-accordion-group");
  ionAccordionGroup.setAttribute("multiple", "true");
  ionAccordionGroup.classList.add(`${uniqueId}`);
  ionAccordionGroup.style.padding = "0.5em";
  ionAccordionGroup.style.width = "100%";

  // mainDiv.appendChild(ionAccordionGroup);

  for (var i = 1; i <= numAccordionItems; i++) {
    let ionAccordion = document.createElement("ion-accordion");
    ionAccordion.classList.add(`acc-${i}-${uniqueId}`);
    // ionAccordion.setAttribute("value", "first");

    let ionItem = document.createElement("ion-item");
    ionItem.setAttribute("slot", "header");
    ionItem.setAttribute("color", "light");
    ionItem.classList.add(`item-${i}-${uniqueId}`);

    let ionLabel = document.createElement("ion-label");
    ionLabel.classList.add(`label-${i}-${uniqueId}`);
    ionLabel.innerHTML += `<p>Accordion #${i}</p>`;

    ionItem.appendChild(ionLabel);

    ionAccordion.appendChild(ionItem);

    let ionContent = document.createElement("div");
    ionContent.setAttribute("class", "ion-padding");
    ionContent.classList.add(`content-${i}-${uniqueId}`);
    ionContent.setAttribute("slot", "content");
    ionContent.innerHTML += `<p>Content #${i}</p>`;

    ionAccordion.appendChild(ionContent);

    ionAccordionGroup.appendChild(ionAccordion);
  }

  editor.getSelected().append(ionAccordionGroup.outerHTML);
  editor.Modal.close();

  // so that it doesn't repeat carousel creation remembering the last input
  document.getElementById("numAccordionItems").value = "";
  console.log("mob-multiple-accordion appended");
}

export function createDropDown(editor) {
  console.log("creating dd");

  const uniqueId = uniqid("dd-");
  console.log("creating unique ID for class:", uniqueId);

  // NOTE: CSS for this is defined in css parser also

  const mainDiv = document.createElement("div");
  mainDiv.classList.add("dropdown", `${uniqueId}`);
  mainDiv.setAttribute("data-hovering", "false");
  mainDiv.style.display = "inline-block";
  mainDiv.style.position = "relative";
  mainDiv.style.padding = "0.5em !important";
  mainDiv.style.transition = "all 150ms linear";

  mainDiv.innerHTML = `
        <button class="btn-${uniqueId}"><p style="margin: auto;">Profile</p></button>
        <div class="dropdown-options opt-${uniqueId}">
            <a href="#">Dashboard</a>
            <a href="#">Setting</a>
            <a href="#">Logout</a>
        </div>

        <style>
            .dropdown button {
              border:none;
              border-radius: 5px;
              padding: 0.5em;
              margin-left: auto !important;
              margin-right: auto !important;
              font-size: 16px;
              cursor: pointer;
            }

            .dropdown button:hover{
              background-color: #ddd;
            }

            .dropdown .dropdown-options {
              display: none;
              position: absolute;
              background-color:#fff;
              border-radius:5px;
              box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.4);
            }

            .dropdown:hover .dropdown-options {
              display: block;
            }

            [data-hovering="true"] .dropdown-options {
              display: block;
            }

            .dropdown .dropdown-options a {
              display: block;
              color: #000000;
              padding: 5px;
              text-decoration: none;
              padding:20px 40px;
            }

            .dropdown .dropdown-options a:hover {
              color: #0a0a23;
              background-color: #ddd;
              border-radius:5px;
            }
        </style>
    `;

  editor.getSelected().append(mainDiv.outerHTML);
  console.log("dd appended");
  // return mainDiv.outerHTML;
}

export function createMobSelect(editor) {
  console.log("creating mob-select");

  const uniqueId = uniqid("ms-");
  console.log("creating unique ID for class:", uniqueId);

  // NOTE: CSS for this is defined in css parser also

  const mainDiv = document.createElement("div");
  mainDiv.setAttribute("class", `${uniqueId}`);
  mainDiv.style.position = "relative";
  mainDiv.style.padding = "0.5em !important";
  mainDiv.style.margin = "auto";

  mainDiv.innerHTML = `
        <button class="btn-${uniqueId}">Select a city</button>
        <dialog open class="dialog-${uniqueId}">
            <div>
                <input type="radio" id="option" name="option" value="option1">
                <label for="option">option1</label>
                <input type="radio" id="option" name="option" value="option2">
                <label for="option">option2</label>
            </div>
        </dialog>

        <script>
            let selectBtn = document.querySelector('button');
            console.log('btn:', selectBtn);
            
            let dialog = document.querySelector('dialog');
            
            selectBtn.addEventListener('click', (event) => {
                dialog.setAttribute('open', '');
            });
        </script>
    `;

  // const ionList = document.createElement('ion-list');

  // const ionItem = document.createElement('ion-item');

  // const ionSelect = document.createElement('ion-select');
  // ionSelect.setAttribute('placeholder', 'Select a city');
  // ionItem.appendChild(ionSelect);

  // const ionSelectOption1 = document.createElement('ion-select-option');
  // ionSelectOption1.setAttribute('value', 'Mumbai');
  // ionSelectOption1.setAttribute('data-gjs-highlightable', 'true');
  // ionSelectOption1.innerHTML = 'Mumbai';
  // ionSelect.appendChild(ionSelectOption1);

  // const ionSelectOption2 = document.createElement('ion-select-option');
  // ionSelectOption2.setAttribute('value', 'Mumbai');
  // ionSelectOption2.innerHTML = 'Delhi';
  // ionSelect.appendChild(ionSelectOption2);

  // const ionSelectOption3 = document.createElement('ion-select-option');
  // ionSelectOption3.setAttribute('value', 'Mumbai');
  // ionSelectOption3.innerHTML = 'Agra';
  // ionSelect.appendChild(ionSelectOption3);

  // ionList.appendChild(ionItem);

  editor.getSelected().append(mainDiv.outerHTML);
  console.log("mob-select appended");
  // return mainDiv.outerHTML;
}

export function createSegmentButton(editor) {
  console.log("creating mob-segment-button");

  const uniqueId = uniqid("msb-");
  console.log("creating unique ID for class:", uniqueId);

  const numSegmentBtn = document.getElementById("numSegmentBtn").value;

  // NOTE: CSS for this is defined in css parser also

  // NOTE: CSS for this is defined in css parser also

  const mainDiv = document.createElement("div");
  mainDiv.setAttribute("class", `${uniqueId}`);
  mainDiv.style.position = "relative";
  mainDiv.style.padding = "0.5em !important";
  mainDiv.style.margin = "auto";

  // mainDiv.innerHTML = `
  //     <ion-segment value="buttons" class="is-${uniqueId}">
  //         <p style="max-width: 30%; margin: 0;">
  //           <ion-segment-button value="default">
  //             <ion-label>Default</ion-label>
  //           </ion-segment-button>
  //         </p>
  //         <p style="max-width: 30%; margin: 0;">
  //           <ion-segment-button value="segment">
  //             <ion-label style="">Segment</ion-label>
  //           </ion-segment-button>
  //         </p>
  //         <p style="max-width: 30%; margin: 0;">
  //           <ion-segment-button value="buttons">
  //             <ion-label style="">Button</ion-label>
  //           </ion-segment-button>
  //         </p>
  //     </ion-segment>
  // `;

  const tab = document.createElement("div");
  tab.setAttribute("class", "tab");
  tab.classList.add(`${uniqueId}`);

  for (let i = 0; i < numSegmentBtn; i++) {
    let btn = document.createElement("button");
    btn.setAttribute("class", "tablinks");
    btn.classList.add(`tl-${i}-${uniqueId}`);
    btn.innerHTML = `Tab ${i}`;

    tab.appendChild(btn);
  }
  mainDiv.appendChild(tab);

  for (let j = 0; j < numSegmentBtn; j++) {
    let tabContent = document.createElement("div");
    tabContent.setAttribute("class", "tabcontent");
    tabContent.setAttribute("id", `tc-${j}-${uniqueId}`);
    tabContent.innerHTML = `
            <h3>tc-${j}-${uniqueId}</h3>
            <p>ID for this tab #${j} is tc-${j}-${uniqueId}</p>
        `;

    mainDiv.appendChild(tabContent);
  }

  const styling = document.createElement("style");
  styling.innerHTML = `
        .tab {
          overflow: hidden;
          border: 1px solid #ccc;
          background-color: #f1f1f1;
        }

        .tab button {
          background-color: inherit;
          float: left;
          border: none;
          outline: none;
          cursor: pointer;
          padding: 14px 16px;
          transition: 0.3s;
        }

        .tab button:hover {
          background-color: #ddd;
        }

        .tab button.active {
          background-color: #ccc;
        }

        .tabcontent {
          display: none;
          padding: 6px 12px;
          border: 1px solid #ccc;
          border-top: none;
        }
    `;

  mainDiv.appendChild(styling);

  // const scripting = document.createElement('script');

  // const scripting = document.createElement('script');

  mainDiv.innerHTML += `
    <script type="text/javascript">
        function openCity(evt, cityName) {
            console.log('cityName:', cityName);
          // Declare all variables
          var i, tabcontent, tablinks;

          // Get all elements with class="tabcontent" and hide them
          tabcontent = document.getElementsByClassName("tabcontent");
          for (i = 0; i < tabcontent.length; i++) {
            tabcontent[i].style.display = "none";
          }

          // Get all elements with class="tablinks" and remove the class "active"
          tablinks = document.getElementsByClassName("tablinks");
          for (i = 0; i < tablinks.length; i++) {
            tablinks[i].className = tablinks[i].className.replace(" active", "");
          }

          // Show the current tab, and add an "active" class to the button that opened the tab
          console.log('document.getElementById(cityName):', document.getElementById(cityName));
          document.getElementById(cityName).style.display = "block";
          evt.currentTarget.className += " active";
        }

        for (let i = 0; i < document.querySelectorAll('.${uniqueId} .tablinks').length; i++) {
            console.log('i: ', i);
            document.querySelectorAll('.tablinks')[i].addEventListener('click', (event) => {
                console.log('tab content:', document.querySelectorAll('.${uniqueId} .tabcontent'));
                openCity(event, document.querySelectorAll('.${uniqueId} .tabcontent')[i].firstChild.innerHTML);
            });
        }

        console.log('tablinks:', document.querySelectorAll('.${uniqueId} .tablinks'));
    </script>
    `;

  // mainDiv.appendChild(scripting);

  // mainDiv.innerHTML = `
  //     <div class="tab ${uniqueId}">
  //       <button class="tablinks">London</button>
  //       <button class="tablinks">Paris</button>
  //       <button class="tablinks">Tokyo</button>
  //     </div>

  //     <div id="London-${uniqueId}" class="tabcontent ${uniqueId}">
  //       <h3>London</h3>
  //       <p>London is the capital city of England.</p>
  //     </div>

  //     <div id="Paris-${uniqueId}" class="tabcontent ${uniqueId}">
  //       <h3>Paris</h3>
  //       <p>Paris is the capital of France.</p>
  //     </div>

  //     <div id="Tokyo-${uniqueId}" class="tabcontent ${uniqueId}">
  //       <h3>Tokyo</h3>
  //       <p>Tokyo is the capital of Japan.</p>
  //     </div>

  //     <style>
  //         .tab {
  //           overflow: hidden;
  //           border: 1px solid #ccc;
  //           background-color: #f1f1f1;
  //         }

  //         .tab button {
  //           background-color: inherit;
  //           float: left;
  //           border: none;
  //           outline: none;
  //           cursor: pointer;
  //           padding: 14px 16px;
  //           transition: 0.3s;
  //         }

  //         .tab button:hover {
  //           background-color: #ddd;
  //         }

  //         .tab button.active {
  //           background-color: #ccc;
  //         }

  //         .tabcontent {
  //           display: none;
  //           padding: 6px 12px;
  //           border: 1px solid #ccc;
  //           border-top: none;
  //         }
  //     </style>

  //     <script>
  //         function openCity(evt, cityName) {
  //           // Declare all variables
  //           var i, tabcontent, tablinks;

  //           // Get all elements with class="tabcontent" and hide them
  //           tabcontent = document.getElementsByClassName("tabcontent");
  //           for (i = 0; i < tabcontent.length; i++) {
  //             tabcontent[i].style.display = "none";
  //           }

  //           // Get all elements with class="tablinks" and remove the class "active"
  //           tablinks = document.getElementsByClassName("tablinks");
  //           for (i = 0; i < tablinks.length; i++) {
  //             tablinks[i].className = tablinks[i].className.replace(" active", "");
  //           }

  //           // Show the current tab, and add an "active" class to the button that opened the tab
  //           document.getElementById(cityName).style.display = "block";
  //           evt.currentTarget.className += " active";
  //         }

  //         document.querySelectorAll('.tablinks')[0].addEventListener('click', (event) => {
  //             openCity(event, 'London-${uniqueId}');
  //         });

  //         document.querySelectorAll('.tablinks')[1].addEventListener('click', (event) => {
  //             openCity(event, 'Paris-${uniqueId}');
  //         });

  //         document.querySelectorAll('.tablinks')[2].addEventListener('click', (event) => {
  //             openCity(event, 'Tokyo-${uniqueId}');
  //         });
  //     </script>
  // `;

  // mainDiv.appendChild(scripting);

  // mainDiv.innerHTML = `
  //     <div class="tab ${uniqueId}">
  //       <button class="tablinks">London</button>
  //       <button class="tablinks">Paris</button>
  //       <button class="tablinks">Tokyo</button>
  //     </div>

  //     <div id="London-${uniqueId}" class="tabcontent ${uniqueId}">
  //       <h3>London</h3>
  //       <p>London is the capital city of England.</p>
  //     </div>

  //     <div id="Paris-${uniqueId}" class="tabcontent ${uniqueId}">
  //       <h3>Paris</h3>
  //       <p>Paris is the capital of France.</p>
  //     </div>

  //     <div id="Tokyo-${uniqueId}" class="tabcontent ${uniqueId}">
  //       <h3>Tokyo</h3>
  //       <p>Tokyo is the capital of Japan.</p>
  //     </div>

  //     <style>
  //         .tab {
  //           overflow: hidden;
  //           border: 1px solid #ccc;
  //           background-color: #f1f1f1;
  //         }

  //         .tab button {
  //           background-color: inherit;
  //           float: left;
  //           border: none;
  //           outline: none;
  //           cursor: pointer;
  //           padding: 14px 16px;
  //           transition: 0.3s;
  //         }

  //         .tab button:hover {
  //           background-color: #ddd;
  //         }

  //         .tab button.active {
  //           background-color: #ccc;
  //         }

  //         .tabcontent {
  //           display: none;
  //           padding: 6px 12px;
  //           border: 1px solid #ccc;
  //           border-top: none;
  //         }
  //     </style>

  //     <script>
  //         function openCity(evt, cityName) {
  //           // Declare all variables
  //           var i, tabcontent, tablinks;

  //           // Get all elements with class="tabcontent" and hide them
  //           tabcontent = document.getElementsByClassName("tabcontent");
  //           for (i = 0; i < tabcontent.length; i++) {
  //             tabcontent[i].style.display = "none";
  //           }

  //           // Get all elements with class="tablinks" and remove the class "active"
  //           tablinks = document.getElementsByClassName("tablinks");
  //           for (i = 0; i < tablinks.length; i++) {
  //             tablinks[i].className = tablinks[i].className.replace(" active", "");
  //           }

  //           // Show the current tab, and add an "active" class to the button that opened the tab
  //           document.getElementById(cityName).style.display = "block";
  //           evt.currentTarget.className += " active";
  //         }

  //         document.querySelectorAll('.tablinks')[0].addEventListener('click', (event) => {
  //             openCity(event, 'London-${uniqueId}');
  //         });

  //         document.querySelectorAll('.tablinks')[1].addEventListener('click', (event) => {
  //             openCity(event, 'Paris-${uniqueId}');
  //         });

  //         document.querySelectorAll('.tablinks')[2].addEventListener('click', (event) => {
  //             openCity(event, 'Tokyo-${uniqueId}');
  //         });
  //     </script>
  // `;

  editor.getSelected().append(mainDiv.outerHTML);
  // return mainDiv.outerHTML;
  editor.Modal.close();

  // so that it doesn't repeat carousel creation remembering the last input
  document.getElementById("numSegmentBtn").value = "";
  console.log("mob-segment-button appended");
}

export function createIndividualSegmentButton(editor) {
  console.log("creating mob-individual-segment-button");

  const uniqueId = uniqid("misb-");
  console.log("creating unique ID for class:", uniqueId);

  // NOTE: CSS for this is defined in css parser also

  const pTag = document.createElement("p");
  pTag.style.maxWidth = "30%";
  pTag.style.margin = "0";
  pTag.innerHTML = `
        <ion-segment-button value="default" class="is-${uniqueId}">
            <ion-label>Default</ion-label>
        </ion-segment-button>
    `;

  editor.getSelected().append(pTag.outerHTML);
  console.log("mob-segment-button appended");
  // return mainDiv.outerHTML;
}

export function createMobTabs(editor) {
  console.log("creating mob-tabs");

  const uniqueId = uniqid("it-");
  console.log("creating unique ID for class:", uniqueId);

  // NOTE: CSS for this is defined in css parser also

  const ionTabs = document.createElement("ion-tabs");
  ionTabs.setAttribute("class", `${uniqueId}`);
  ionTabs.style.position = "absolute";
  ionTabs.style.padding = "0.5em !important";
  ionTabs.style.margin = "auto";

  ionTabs.innerHTML = `
        <ion-tab tab="home">
            <ion-nav id="home-nav">
                <div class="example-content">Listen now content</div>
            </ion-nav>
            <div id="home-page">
              <ion-header style="padding: 0.5em;">
                <ion-toolbar>
                  <ion-title>Listen now</ion-title>
                </ion-toolbar>
              </ion-header>             
            </div>
        </ion-tab>

        <ion-tab tab="radio">
            <ion-nav id="radio-nav">
                <div class="example-content">Radio content</div>
            </ion-nav>
            <div id="radio-page">
              <ion-header style="padding: 0.5em;">
                <ion-toolbar>
                  <ion-title>Radio</ion-title>
                </ion-toolbar>
              </ion-header>
            </div>
        </ion-tab>

        <ion-tab tab="library">
            <ion-nav id="library-nav">
                <div class="example-content">Library content</div>
            </ion-nav>
            <div id="library-page">
              <ion-header style="padding: 0.5em;">
                <ion-toolbar>
                  <ion-title>Library</ion-title>
                </ion-toolbar>
              </ion-header>
            </div>
        </ion-tab>

        <ion-tab tab="search">
            <ion-nav id="search-nav">
                <div class="example-content" data-gjs-type="text">Search content</div>
            </ion-nav>
            <div id="search-page">
              <ion-header style="padding: 0.5em;">
                <ion-toolbar>
                  <ion-title>Search</ion-title>
                </ion-toolbar>
              </ion-header>
            </div>
        </ion-tab>

        <ion-tab-bar slot="bottom">
            <ion-tab-button tab="home">
                <p style="margin: 0; display: flex; flex-direction: column; align-items: center;">
                  <ion-icon name="play-circle"></ion-icon>
                  Listen Now
                </p>
            </ion-tab-button>
            <ion-tab-button tab="radio">
                <p style="margin: 0; display: flex; flex-direction: column; align-items: center;">
                  <ion-icon name="radio"></ion-icon>
                  Radio
                </p>
            </ion-tab-button>
            <ion-tab-button tab="library">
                <p style="margin: 0; display: flex; flex-direction: column; align-items: center;">
                  <ion-icon name="library"></ion-icon>
                  Library
                </p>
            </ion-tab-button>
            <ion-tab-button tab="search">
                <p style="margin: 0; display: flex; flex-direction: column; align-items: center;">
                  <ion-icon name="search"></ion-icon>
                  Search
                </p>
            </ion-tab-button>
        </ion-tab-bar>

        <style>
            .example-content {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 100%;
            }
        </>

        <script>
            const homeNav = document.querySelector('#home-nav');
            const homePage = document.querySelector('#home-page');
            homeNav.root = homePage;

            const radioNav = document.querySelector('#radio-nav');
            const radioPage = document.querySelector('#radio-page');
            radioNav.root = radioPage;

            const libraryNav = document.querySelector('#library-nav');
            const libraryPage = document.querySelector('#library-page');
            libraryNav.root = libraryPage;

            const searchNav = document.querySelector('#search-nav');
            const searchPage = document.querySelector('#search-page');
            searchNav.root = searchPage;
        </>
    `;

  editor.getSelected().append(ionTabs.outerHTML);
  console.log("mob-tabs appended");
  // return ionTabs.outerHTML;
}

export function createMobToggle(editor) {
  console.log("creating mob-toggle");

  const uniqueId = uniqid("mt-");
  console.log("creating unique ID for class:", uniqueId);

  const editorCss = editor.getCss();
  console.log("EDITOR CSS:", editorCss);

  // NOTE: CSS for this is defined in css parser also

  parserCss(editorCss, editor, uniqueId);

  const mainDiv = document.createElement("div");
  mainDiv.setAttribute("class", `${uniqueId}`);
  mainDiv.style.position = "relative";
  mainDiv.style.padding = "0.5em !important";
  mainDiv.style.margin = "auto";

  mainDiv.innerHTML = `
        <ul class="ul-${uniqueId}">
            <li>
                <ion-label>Default Toggle</ion-label>
                <ion-toggle slot="end"></ion-toggle>
            </li>
            <li>
                <ion-label>Checked Toggle</ion-label>
                <ion-toggle slot="end" checked="true"></ion-toggle>
            </li>
        </ul>

        <style>
            .ul-${uniqueId} li {
                display: flex;
                align-items: center;
            }
        </style>
    `;

  editor.getSelected().append(mainDiv.outerHTML);
  console.log("mob-toggle appended");
  // return mainDiv.outerHTML;
}

export function createDirectionalDropDown(editor, uniqueId) {
  console.log("creating directional-dd");
  console.log("creating unique ID for class:", uniqueId);

  const editorCss = editor.getCss();
  console.log("EDITOR CSS:", editorCss);

  // so that setting of the traits of one dropdown doesn't affect the traits of another dropdown (of the same dragged component)
  parserCss(editorCss, editor, uniqueId);

  // NOTE: CSS for this is defined in css parser also
  // NOTE: here I make a custom attribute for drop direction: data-direction. And then set css style difinitions [key="value"] {...} for 4 values that it can have. Then I also provide those 4 possible values that it can have, in traitManager so that from there when the user selects a value then its effect is matched by the css rule and effect is applied.

  const mainDiv = document.createElement("div");
  mainDiv.classList.add("d-dropdown", `${uniqueId}`);
  // data-hovering attribute is here so that the user can select it and keep the hover on and options displaying continuously in the editor so that thwy can edit the options inside it
  mainDiv.setAttribute("data-hovering", "false");
  // data-hovering attribute is here so that user can decide the direction of dropping
  mainDiv.setAttribute("data-direction", "under");
  mainDiv.style.display = "inline-block";
  mainDiv.style.position = "relative";
  mainDiv.style.padding = "0.5em !important";
  mainDiv.style.transition = "all 150ms linear";

  // mainDiv.innerHTML = `
  //     <button class="btn-${uniqueId}"><p style="margin: auto;">Profile</p></button>
  //     <div class="directional-dropdown-options opt-${uniqueId}">
  //         <a href="#">Dashboard</a>
  //         <a href="#">Setting</a>
  //         <a href="#">Logout</a>
  //     </div>

  //     <style>
  //         .d-dropdown button {
  //           border:none;
  //           border-radius: 5px;
  //           padding: 0.5em;
  //           margin-left: auto !important;
  //           margin-right: auto !important;
  //           font-size: 16px;
  //           cursor: pointer;
  //         }

  //         .d-dropdown button:hover{
  //           background-color: #ddd;
  //         }

  //         .d-dropdown .directional-dropdown-options {
  //           display: none;
  //           position: absolute;
  //           background-color:#fff;
  //           border-radius:5px;
  //           box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.4);
  //         }

  //         .d-dropdown:hover .directional-dropdown-options {
  //           display: block;
  //         }

  //         .d-dropdown[data-hovering="true"] .directional-dropdown-options {
  //           display: block;
  //         }

  //         .d-dropdown[data-direction="under"] .directional-dropdown-options {
  //           top: 100%;
  //         }

  //         .d-dropdown[data-direction="over"] .directional-dropdown-options {
  //           bottom: 100%;
  //         }

  //         .d-dropdown[data-direction="right"] .directional-dropdown-options {
  //           left: 100%;
  //         }

  //         .d-dropdown[data-direction="left"] .directional-dropdown-options {
  //           right: 100%;
  //         }

  //         .d-dropdown .directional-dropdown-options a {
  //           display: block;
  //           color: #000000;
  //           padding: 5px;
  //           text-decoration: none;
  //           padding:20px 40px;
  //         }

  //         .d-dropdown .directional-dropdown-options a:hover {
  //           color: #0a0a23;
  //           background-color: #ddd;
  //           border-radius:5px;
  //         }
  //     </style>
  // `;

  const ddBtn = document.createElement("button");
  ddBtn.setAttribute("class", `btn-${uniqueId}`);
  ddBtn.style.marginLeft = "auto";
  ddBtn.style.marginRight = "auto";
  ddBtn.innerHTML = `
        <p style="margin: auto;">Profile</p>
        <span class="pointer-${uniqueId}"></span>
    `;
  mainDiv.appendChild(ddBtn);

  const optionsDiv = document.createElement("div");
  optionsDiv.classList.add("directional-dropdown-options", `opt-${uniqueId}`);
  optionsDiv.style.position = "absolute";

  const option1 = document.createElement("a");
  option1.setAttribute("href", "#");
  option1.innerHTML = "<p>Dashboard</p>";
  optionsDiv.appendChild(option1);

  const option2 = document.createElement("a");
  option2.setAttribute("href", "#");
  option2.innerHTML = "<p>Setting</p>";
  optionsDiv.appendChild(option2);

  const option3 = document.createElement("a");
  option3.setAttribute("href", "#");
  option3.innerHTML = "<p>Logout</p>";
  optionsDiv.appendChild(option3);

  mainDiv.appendChild(optionsDiv);

  const styling = document.createElement("style");
  styling.innerHTML = `
        .d-dropdown.${uniqueId} button.btn-${uniqueId} {
          border:none;
          border-radius: 5px;
          padding: 0.5em;
          font-size: 16px;
          cursor: pointer;
          display: flex;
          align-items: center;
        }

        .d-dropdown.${uniqueId} button.btn-${uniqueId}:hover{
          background-color: #ddd;
        }

        .d-dropdown.${uniqueId} button.btn-${uniqueId} span.pointer-${uniqueId} {
            margin-left: 0.5em;
            width: 0.5em;
            height: 0.5em;
            background-color: #ddd;
            clip-path: polygon(50% 100%, 0 0, 100% 0);
        }

        .d-dropdown.${uniqueId} button.btn-${uniqueId}:hover  span.pointer-${uniqueId} {
          background-color: white;
        }

        .d-dropdown.${uniqueId} .directional-dropdown-options.opt-${uniqueId} {
          display: none;
          position: absolute;
          background-color:#fff;
          border-radius:5px;
          box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.4);
        }

        .d-dropdown.${uniqueId}:hover .directional-dropdown-options.opt-${uniqueId} {
          display: block;
        }

        .d-dropdown.${uniqueId}[data-hovering="true"] .directional-dropdown-options.opt-${uniqueId} {
          display: block;
        }

        .d-dropdown.${uniqueId}[data-direction="under"] .directional-dropdown-options.opt-${uniqueId} {
          top: 100%;
          position: static;
        }

        .d-dropdown.${uniqueId}[data-direction="under"] button.btn-${uniqueId} span.pointer-${uniqueId} {
          clip-path: polygon(50% 100%, 0 0, 100% 0);
        }

        .d-dropdown.${uniqueId}[data-direction="over"] button.btn-${uniqueId} span.pointer-${uniqueId} {
          clip-path: polygon(50% 0%, 0% 100%, 100% 100%) !important;
        }

        .d-dropdown.${uniqueId}[data-direction="over"] .directional-dropdown-options.opt-${uniqueId} {
          bottom: 100%;
        }

        

        .d-dropdown.${uniqueId}[data-direction="right"] .directional-dropdown-options.opt-${uniqueId} {
          left: 100%;
          top: -10%;
        }

        .d-dropdown.${uniqueId}[data-direction="right"] button.btn-${uniqueId} span.pointer-${uniqueId} {
          clip-path: polygon(100% 50%, 0 0, 0 100%);
        }

        .d-dropdown.${uniqueId}[data-direction="left"] .directional-dropdown-options.opt-${uniqueId} {
          right: 100%;
          top: -10%;
        }

        .d-dropdown.${uniqueId}[data-direction="left"] button.btn-${uniqueId} span.pointer-${uniqueId} {
          clip-path: polygon(0 50%, 100% 0, 100% 100%);
        }

        .d-dropdown.${uniqueId} .directional-dropdown-options.opt-${uniqueId} a {
          display: block;
          color: #000000;
          padding: 5px;
          text-decoration: none;
          padding:20px 40px;
        }

        .d-dropdown.${uniqueId} .directional-dropdown-options.opt-${uniqueId} a:hover {
          color: #0a0a23;
          background-color: #ddd;
          border-radius:5px;
        }
    `;

  mainDiv.appendChild(styling);

  editor.getSelected().append(mainDiv.outerHTML);
  console.log("directional-dd appended");
  // return mainDiv.outerHTML;
}

export function createMLDropDown(editor) {
  console.log("creating multilevel-dd");

  const uniqueId = uniqid("mldd-");
  console.log("creating unique ID for class:", uniqueId);

  const mainDiv = document.createElement("div");
  mainDiv.style.display = "flex";
  mainDiv.style.alignItems = "center";
  mainDiv.style.justifyContent = "center";
  mainDiv.style.position = "relative";
  mainDiv.style.padding = "0.5em";

  // bootstrap dropdown
  // const ddDiv = document.createElement('div');
  // ddDiv.classList.add('btn-group', 'dropend');

  // const ddBtn = document.createElement('button');
  // ddBtn.classList.add('btn', 'btn-secondary', 'dropdown-toggle');
  // ddBtn.setAttribute('type', 'button');
  // ddBtn.setAttribute('id', `dropdownMenuButton-${uniqueId}`);
  // ddBtn.setAttribute('data-bs-toggle', 'dropdown');
  // ddBtn.setAttribute('aria-expanded', 'false');
  // ddBtn.innerHTML = '<p style="margin: auto;">Drop right</p>';

  // ddDiv.appendChild(ddBtn);

  // const ddMenu = document.createElement('ul');
  // ddMenu.setAttribute('class', 'dropdown-menu');

  // const ddMenuItem1 = document.createElement('li');

  // const ddMenuItem1Anchor1 = document.createElement('a');
  // ddMenuItem1Anchor1.setAttribute('class', 'dropdown-item');
  // ddMenuItem1Anchor1.setAttribute('href', '#');
  // ddMenuItem1Anchor1.innerHTML = "Action";
  // ddMenuItem1.appendChild(ddMenuItem1Anchor1);

  // ddMenu.appendChild(ddMenuItem1);

  // ddDiv.appendChild(ddMenu);

  // mainDiv.appendChild(ddDiv);

  // regular dropdown
  // mainDiv.innerHTML = `
  //     <button class='dropdown-btn'>Dropdown</button>
  //     <div id="myDropdown" class="dropdown-content">
  //         <a href="#">Link 1</a>
  //         <a href="#">Link 2</a>
  //         <a href="#">Link 3</a>
  //     </div>

  //     <script>
  //         const dropdownBtn = document.querySelector('.dropdown-btn');
  //         dropdownBtn.style.border = '1px solid black';
  //         dropdownBtn.style.borderRadius = '5px';
  //         dropdownBtn.style.padding = '0.5em';
  //         dropdownBtn.style.cursor = 'pointer';

  //         const dropdownContent = document.querySelector('.dropdown-content');
  //         dropdownContent.style.position = 'absolute';
  //         dropdownContent.style.top = '100%';
  //         dropdownContent.style.backgroundColor = '#fcfcfc';
  //         dropdownContent.style.minWidth = '5em';
  //         dropdownContent.style.padding = '0.5em';
  //         dropdownContent.style.display = 'none';
  //         dropdownContent.style.flexDirection = 'column';
  //         dropdownContent.style.alignItems = 'flex-start';
  //         dropdownContent.style.border = '1px solid black';
  //         dropdownContent.style.borderRadius = '5px';

  //         const dropdownContentItems = document.querySelectorAll('.dropdown-content a');
  //         for (let a = 0; a < dropdownContentItems.length; a++) {
  //             dropdownContentItems[a].style.width = '100%';
  //             dropdownContentItems[a].style.textDecoration = 'none';
  //         }

  //         dropdownBtn.addEventListener('mouseover', (event) => {
  //             dropdownContent.style.display = 'flex';
  //         });

  //         window.onclick = function(event) {
  //             if (!event.target.matches('.dropdownBtn')) {
  //                 var dropdowns = document.getElementsByClassName("dropdown-content");
  //                 var i;
  //                 for (i = 0; i < dropdowns.length; i++) {
  //                     var openDropdown = dropdowns[i];
  //                     if (openDropdown.style.display === 'flex') {
  //                         openDropdown.style.display = 'none';
  //                     }
  //                 }
  //             }
  //         }
  //     </script>
  // `;

  mainDiv.innerHTML = `
        <div class="btn-group dropend">
          <button type="button" class="btn btn-secondary">
            Split dropend
          </button>
          <button type="button" class="btn btn-secondary dropdown-toggle dropdown-toggle-split" data-bs-toggle="dropdown" aria-expanded="false">
            <span class="visually-hidden">Toggle Dropend</span>
          </button>
          <ul class="dropdown-menu">
            <li><a class="dropdown-item" href="#">Action</a></li>
            <li><a class="dropdown-item" href="#">Action two</a></li>
            <li><a class="dropdown-item" href="#">Action three</a></li>
          </ul>
        </div>
    `;

  // md-dropdown
  // const ddDiv = document.createElement('div');
  // ddDiv.setAttribute('class', 'dropdown');

  // const ddBtn = document.createElement('button');
  // ddBtn.classList.add('btn', 'btn-light', 'dropdown-toggle');
  // ddBtn.setAttribute('type', 'button');
  // ddBtn.setAttribute('id', `dropdownMenuButton-${uniqueId}`);
  // ddBtn.setAttribute('data-mdb-toggle', 'dropdown');
  // ddBtn.setAttribute('aria-expanded', 'false');
  // ddBtn.innerHTML = '<p style="margin: auto;">DD-Menu</p>';

  // ddDiv.appendChild(ddBtn);

  // const ulMenu = document.createElement('ul');
  // ulMenu.setAttribute('class', 'dropdown-menu');
  // ulMenu.setAttribute('aria-labelledby', `dropdownMenuButton-${uniqueId}`);

  // const ddLi1 = document.createElement('li');
  // const liItem1 = document.createElement('a');
  // liItem1.setAttribute('class', 'dropdown-item');
  // liItem1.setAttribute('href', '#');
  // liItem1.innerHTML = 'Action';
  // ddLi1.appendChild(liItem1);
  // ulMenu.appendChild(ddLi1);

  // const ddLi2 = document.createElement('li');
  // const liItem2 = document.createElement('a');
  // liItem2.setAttribute('class', 'dropdown-item');
  // liItem2.setAttribute('href', '#');
  // liItem2.innerHTML = 'Another action';
  // ddLi2.appendChild(liItem2);
  // ulMenu.appendChild(ddLi2);

  // ddDiv.appendChild(ulMenu);

  // mainDiv.appendChild(ddDiv);

  // const styling = document.createElement('style');
  // styling.innerHTML = `
  //     .dropdown-menu li {
  //         position: relative;
  //     }
  //     .dropdown-menu .dropdown-submenu {
  //         display: none;
  //         position: absolute;
  //         left: 100%;
  //         top: -7px;
  //     }
  //     .dropdown-menu .dropdown-submenu-left {
  //         right: 100%;
  //         left: auto;
  //     }
  //         .dropdown-menu > li:hover > .dropdown-submenu {
  //         display: block;
  //     }

  //     .dropdown-hover:hover>.dropdown-menu {
  //         display: inline-block;
  //     }

  //     .dropdown-hover>.dropdown-toggle:active {
  //         pointer-events: none;
  //     }
  // `;

  // mainDiv.appendChild(styling);

  editor.addComponents(mainDiv.outerHTML);
  console.log("multilevel-dd appended");
}

export function createFullCard1(editor) {
  console.log("creating full-card-one...");

  // so that affecting one accordion styling doesn't affect other accordions in the same page
  const uniqueId = uniqid("full-card-one-");
  console.log("creating unique ID for class:", uniqueId);

  // const mainDiv = document.createElement('div');
  // mainDiv.style.display = 'flex';
  // mainDiv.style.alignItems = 'center';
  // mainDiv.style.justifyContent = 'space-evenly';
  // mainDiv.style.padding = '0.5em';

  const cardDiv = document.createElement("div");
  cardDiv.setAttribute("class", "card");
  cardDiv.classList.add(`${uniqueId}`);
  // comment width so that the width is editable in the editor otherwise it fixees, cannot be edited in the editor
  // cardDiv.style.width = '20rem';
  cardDiv.style.padding = "0.5em";

  const cardImg = document.createElement("img");
  cardImg.setAttribute("class", "card-img-top");
  cardImg.classList.add(`card-img-${uniqueId}`);
  cardImg.setAttribute("alt", "alternative text");
  cardImg.setAttribute("src", "...");

  cardDiv.appendChild(cardImg);

  const cardBody = document.createElement("div");
  cardBody.setAttribute("class", "card-body");
  cardBody.classList.add(`card-body-${uniqueId}`);

  const cardTitle = document.createElement("h5");
  cardTitle.setAttribute("class", "card-title");
  cardTitle.classList.add(`card-title-${uniqueId}`);
  cardTitle.innerHTML += "Card Title";

  cardBody.appendChild(cardTitle);

  const cardText = document.createElement("p");
  cardText.setAttribute("class", "card-text");
  cardText.classList.add(`card-text-${uniqueId}`);
  cardText.innerHTML +=
    "Some quick example text to build on the card title and make up the bulk of the card's content.";

  cardBody.appendChild(cardText);

  const cardBtn = document.createElement("a");
  cardBtn.classList.add("btn", "btn-primary", `card-btn-${uniqueId}`);
  cardBtn.setAttribute("href", "#");
  cardBtn.innerHTML += "Go somewhere";

  cardBody.appendChild(cardBtn);

  cardDiv.appendChild(cardBody);

  // mainDiv.appendChild(cardDiv);

  editor.getSelected().append(cardDiv.outerHTML);
  console.log("full-card-one appended");
  // return mainDiv.outerHTML;
}

export function createTitleCard(editor) {
  console.log("creating title-card...");

  // so that affecting one accordion styling doesn't affect other accordions in the same page
  const uniqueId = uniqid("full-card-");
  console.log("creating unique ID for class:", uniqueId);

  // const mainDiv = document.createElement('div');
  // mainDiv.style.display = 'flex';
  // mainDiv.style.alignItems = 'center';
  // mainDiv.style.justifyContent = 'space-evenly';
  // mainDiv.style.padding = '0.5em';

  const cardDiv = document.createElement("div");
  cardDiv.setAttribute("class", "card");
  cardDiv.classList.add(`${uniqueId}`);
  // comment width so that the width is editable in the editor otherwise it fixees, cannot be edited in the editor
  // cardDiv.style.width = '20rem';
  cardDiv.style.padding = "0.5em";

  const cardBody = document.createElement("div");
  cardBody.setAttribute("class", "card-body");
  cardBody.classList.add(`card-body-${uniqueId}`);

  const cardTitle = document.createElement("h5");
  cardTitle.setAttribute("class", "card-title");
  cardTitle.classList.add(`card-title-${uniqueId}`);
  cardTitle.innerHTML += "Card Title";

  cardBody.appendChild(cardTitle);

  const cardSubTitle = document.createElement("h6");
  cardSubTitle.setAttribute("class", "card-subtitle");
  cardSubTitle.classList.add("mb-2", "text-muted", `card-subtitle-${uniqueId}`);
  cardSubTitle.innerHTML += "Card Subtitle";

  cardBody.appendChild(cardSubTitle);

  const cardText = document.createElement("p");
  cardText.setAttribute("class", "card-text");
  cardText.classList.add(`card-text-${uniqueId}`);
  cardText.innerHTML +=
    "Some quick example text to build on the card title and make up the bulk of the card's content.";

  cardBody.appendChild(cardText);

  const cardLink1 = document.createElement("a");
  cardLink1.classList.add("card-link", `card-link1-${uniqueId}`);
  cardLink1.setAttribute("href", "#");
  cardLink1.style.textDecoration = "none";
  cardLink1.style.color = "blue";
  cardLink1.innerHTML += "Card link";

  cardBody.appendChild(cardLink1);

  const cardLink2 = document.createElement("a");
  cardLink2.classList.add("card-link", `card-link2-${uniqueId}`);
  cardLink2.setAttribute("href", "#");
  cardLink2.style.textDecoration = "none";
  cardLink2.style.color = "blue";
  cardLink2.innerHTML += "Another link";

  cardBody.appendChild(cardLink2);

  cardDiv.appendChild(cardBody);

  // mainDiv.appendChild(cardDiv);

  editor.getSelected().append(cardDiv.outerHTML);
  console.log("title-card appended");
  // return cardDiv.outerHTML;
}

export function createMobTitleCard(editor) {
  console.log("creating mob-title-card...");

  // so that affecting one accordion styling doesn't affect other accordions in the same page
  const uniqueId = uniqid("mob-title-card-");
  console.log("creating unique ID for class:", uniqueId);

  // const mainDiv = document.createElement('div');
  // mainDiv.style.display = 'flex';
  // mainDiv.style.alignItems = 'center';
  // mainDiv.style.justifyContent = 'space-evenly';
  // mainDiv.style.padding = '0.5em';

  const ionCard = document.createElement("ion-card");
  // mainDiv.appendChild(ionCard);

  const ionCardHeader = document.createElement("ion-card-header");
  ionCard.appendChild(ionCardHeader);

  const ionCardTitle = document.createElement("ion-card-title");
  ionCardTitle.innerHTML += "Card Title";
  ionCardHeader.appendChild(ionCardTitle);

  const ionCardSubTitle = document.createElement("ion-card-subtitle");
  ionCardSubTitle.innerHTML += "Card Sub Title";
  ionCardHeader.appendChild(ionCardSubTitle);

  const ionCardContent = document.createElement("ion-card-content");
  ionCardContent.innerHTML +=
    "Here's a small text description for the card content. Nothing more, nothing less.";
  ionCard.appendChild(ionCardContent);

  const styling = document.createElement("style");
  styling.innerHTML = `
        /* iOS places the subtitle above the title */
        ion-card-header.ios {
            display: flex;
            flex-flow: column-reverse;
        }
    `;
  ionCard.appendChild(styling);

  editor.getSelected().append(ionCard.outerHTML);
  console.log("mob-title-card appended");
  // return mainDiv.outerHTML;
}

export function createButtonCard(editor) {
  // console.log("creating btn-card...");

  // // so that affecting one accordion styling doesn't affect other accordions in the same page
  // const uniqueId = uniqid('btn-card-');
  // console.log('creating unique ID for class:', uniqueId);

  const mainDiv = document.createElement("div");
  mainDiv.style.display = "flex";
  mainDiv.style.alignItems = "center";
  mainDiv.style.justifyContent = "space-evenly";
  mainDiv.style.padding = "0.5em";

  const ionCard = document.createElement("ion-card");
  mainDiv.appendChild(ionCard);

  const ionCardHeader = document.createElement("ion-card-header");
  ionCard.appendChild(ionCardHeader);

  const ionCardTitle = document.createElement("ion-card-title");
  ionCardTitle.innerHTML += "Card Title";
  ionCardHeader.appendChild(ionCardTitle);

  const ionCardSubTitle = document.createElement("ion-card-subtitle");
  ionCardSubTitle.innerHTML += "Card Sub Title";
  ionCardHeader.appendChild(ionCardSubTitle);

  const ionCardContent = document.createElement("ion-card-content");
  ionCardContent.innerHTML +=
    "Here's a small text description for the card content. Nothing more, nothing less.";
  ionCard.appendChild(ionCardContent);

  const ionButton1 = document.createElement("ion-button");
  ionButton1.setAttribute("fill", "clear");
  ionButton1.innerHTML += "<p style='margin: auto;'>Action 1</p>";
  ionCard.appendChild(ionButton1);

  const ionButton2 = document.createElement("ion-button");
  ionButton2.setAttribute("fill", "clear");
  ionButton2.innerHTML += "<p style='margin: auto;'>Action 2</p>";
  ionCard.appendChild(ionButton2);

  const styling = document.createElement("style");
  styling.innerHTML = `
        /* iOS places the subtitle above the title */
        ion-card-header.ios {
            display: flex;
            flex-flow: column-reverse;
        }
    `;
  mainDiv.appendChild(styling);

  // editor.addComponents(mainDiv.outerHTML);
  // console.log("btn-card appended");
  return mainDiv.outerHTML;
}

export function createListCard(editor) {
  console.log("creating list-card...");

  // // so that affecting one accordion styling doesn't affect other accordions in the same page
  // const uniqueId = uniqid('list-card-');
  // console.log('creating unique ID for class:', uniqueId);

  const mainDiv = document.createElement("div");
  mainDiv.style.display = "flex";
  mainDiv.style.alignItems = "center";
  mainDiv.style.justifyContent = "space-evenly";
  mainDiv.style.padding = "0.5em";

  const ionCard = document.createElement("ion-card");
  mainDiv.appendChild(ionCard);

  const ionCardHeader = document.createElement("ion-card-header");
  ionCard.appendChild(ionCardHeader);

  const ionCardTitle = document.createElement("ion-card-title");
  ionCardTitle.innerHTML += "Card Title";
  ionCardHeader.appendChild(ionCardTitle);

  const ionCardSubTitle = document.createElement("ion-card-subtitle");
  ionCardSubTitle.innerHTML += "Card Sub Title";
  ionCardHeader.appendChild(ionCardSubTitle);

  const ionCardContent = document.createElement("ion-card-content");
  ionCardContent.innerHTML +=
    "<small>Here's a small text description for the card content. Nothing more, nothing less.</small>";
  ionCard.appendChild(ionCardContent);

  const ionList = document.createElement("ion-list");
  ionCardContent.appendChild(ionList);

  // list item 1
  const ionItem1 = document.createElement("ion-item");
  ionList.appendChild(ionItem1);

  const ionThumbnail1 = document.createElement("ion-thumbnail");
  ionThumbnail1.setAttribute("slot", "start");
  ionItem1.appendChild(ionThumbnail1);

  const image1 = document.createElement("img");
  image1.setAttribute("alt", "Silhouette of mountains");
  image1.setAttribute(
    "src",
    "https://ionicframework.com/docs/img/demos/thumbnail.svg"
  );
  ionThumbnail1.appendChild(image1);

  const ionLabel1 = document.createElement("ion-label");
  ionLabel1.innerHTML += "Item";
  ionItem1.appendChild(ionLabel1);

  // list item 2
  const ionItem2 = document.createElement("ion-item");
  ionList.appendChild(ionItem2);

  const ionThumbnail2 = document.createElement("ion-thumbnail");
  ionThumbnail2.setAttribute("slot", "start");
  ionItem2.appendChild(ionThumbnail2);

  const image2 = document.createElement("img");
  image2.setAttribute("alt", "Silhouette of mountains");
  image2.setAttribute(
    "src",
    "https://ionicframework.com/docs/img/demos/thumbnail.svg"
  );
  ionThumbnail2.appendChild(image2);

  const ionLabel2 = document.createElement("ion-label");
  ionLabel2.innerHTML += "Item";
  ionItem2.appendChild(ionLabel2);

  // list item 3
  const ionItem3 = document.createElement("ion-item");
  ionItem3.setAttribute("lines", "none");
  ionList.appendChild(ionItem3);

  const ionThumbnail3 = document.createElement("ion-thumbnail");
  ionThumbnail3.setAttribute("slot", "start");
  ionItem3.appendChild(ionThumbnail3);

  const image3 = document.createElement("img");
  image3.setAttribute("alt", "Silhouette of mountains");
  image3.setAttribute(
    "src",
    "https://ionicframework.com/docs/img/demos/thumbnail.svg"
  );
  ionThumbnail3.appendChild(image3);

  const ionLabel3 = document.createElement("ion-label");
  ionLabel3.innerHTML += "Item";
  ionItem3.appendChild(ionLabel3);

  const styling = document.createElement("style");
  styling.innerHTML = `
        ion-item {
            --padding-start: 0;
        }

        /* iOS places the subtitle above the title */
        ion-card-header.ios {
            display: flex;
            flex-flow: column-reverse;
        }
    `;
  mainDiv.appendChild(styling);

  // editor.addComponents(mainDiv.outerHTML);
  console.log("list-card appended");
  return mainDiv.outerHTML;
}

// export function createBoundedRippleButton(editor) {
//     console.log('creating bounded-ripple-button...');

//     // so that affecting one accordion styling doesn't affect other accordions in the same page
//     const uniqueId = uniqid('bounded-ripple-button-');
//     console.log('creating unique ID for class:', uniqueId);

//     const mainDiv = document.createElement('div');
//     mainDiv.style.display = 'flex';
//     mainDiv.style.alignItems = 'center';
//     mainDiv.style.justifyContent = 'space-evenly';
//     mainDiv.style.padding = '0.5em';

//     const btnDiv = document.createElement('div');
//     btnDiv.classList.add("ion-activatable", "ripple-parent", "rounded-rectangle", `${uniqueId}`);
//     btnDiv.innerHTML = "Bounded Ripple Button";

//     mainDiv.appendChild(btnDiv);

//     const ionRippleEffect = document.createElement("ion-ripple-effect");
//     ionRippleEffect.setAttribute("type", "unbounded");
//     btnDiv.appendChild(ionRippleEffect);

//     const styling = document.createElement('style');
//     styling.innerHTML = `
//         .ripple-parent {
//             display: flex;
//             align-items: center;
//             justify-content: center;

//             position: relative;
//             overflow: hidden;

//             border: 1px solid #ddd;

//             user-select: none;
//           }

//           .rounded-rectangle {
//             width: 250px;
//             height: 75px;
//             border-radius: 8px;
//           }
//     `;
//     mainDiv.appendChild(styling);

//     editor.addComponents(mainDiv.outerHTML);
//     console.log('bounded-ripple-button appended');
// }

export function createFullCard2(editor) {
  console.log("creating full-card-two...");

  // // so that affecting one accordion styling doesn't affect other accordions in the same page
  // const uniqueId = uniqid('full-card-two-');
  // console.log('creating unique ID for class:', uniqueId);

  const mainDiv = document.createElement("div");
  mainDiv.style.display = "flex";
  mainDiv.style.alignItems = "center";
  mainDiv.style.justifyContent = "space-evenly";
  mainDiv.style.padding = "0.5em";

  const ionCard = document.createElement("ion-card");
  mainDiv.appendChild(ionCard);

  const image = document.createElement("img");
  image.setAttribute("alt", "Silhouette of mountains");
  image.setAttribute(
    "src",
    "https://ionicframework.com/docs/img/demos/card-media.png"
  );
  ionCard.appendChild(image);

  const ionCardHeader = document.createElement("ion-card-header");
  ionCard.appendChild(ionCardHeader);

  const ionCardTitle = document.createElement("ion-card-title");
  ionCardTitle.innerHTML += "Card Title";
  ionCardHeader.appendChild(ionCardTitle);

  const ionCardSubTitle = document.createElement("ion-card-subtitle");
  ionCardSubTitle.innerHTML += "Card Sub Title";
  ionCardHeader.appendChild(ionCardSubTitle);

  const ionCardContent = document.createElement("ion-card-content");
  ionCardContent.innerHTML +=
    "Here's a small text description for the card content. Nothing more, nothing less.";
  ionCard.appendChild(ionCardContent);

  const styling = document.createElement("style");
  styling.innerHTML = `
        ion-item {
            --padding-start: 0;
        }

        /* iOS places the subtitle above the title */
        ion-card-header.ios {
            display: flex;
            flex-flow: column-reverse;
        }
    `;
  mainDiv.appendChild(styling);

  // editor.addComponents(mainDiv.outerHTML);
  editor.getSelected().append(mainDiv.outerHTML);
  console.log("full-card-two appended");
  // return mainDiv.outerHTML;
}

export function createPageDataGrid(editor, keyArr, valueArr) {
  console.log("creating data-grid");
  console.log(keyArr, valueArr);

  const numRows = valueArr.length;
  const numCols = keyArr.length;

  const uniqueId = uniqid("pgg-");
  console.log("creating unique ID for class:", uniqueId);

  const mainDiv = document.createElement("div");
  mainDiv.style.display = "flex";
  mainDiv.style.alignItems = "center";
  mainDiv.style.justifyContent = "center";
  mainDiv.style.padding = "0.5em";

  const dataGrid = document.createElement("table");
  dataGrid.setAttribute("id", `dg-${uniqueId}`);
  dataGrid.setAttribute("width", "100%");
  dataGrid.style.padding = "0.5em";
  dataGrid.style.borderSpacing = "0.5em";

  // first row as table attributes/column headers
  let headerRow = document.createElement("tr");

  keyArr.forEach((ele) => {
    let header = document.createElement("th");
    header.style.fontSize = "0.9em";
    header.innerHTML += `${ele}`;
    headerRow.appendChild(header);
  });

  dataGrid.appendChild(headerRow);

  for (let i = 0; i < numRows; i++) {
    let dataRow = document.createElement("tr");

    // checking if number of keys are equal to number of values of any page received (in this case, first page => 0 index)
    // if (numRows === valueArr[0].length) {
    for (let j = 0; j < numCols; j++) {
      let cell = document.createElement("td");
      cell.style.fontSize = "0.7em";
      cell.style.width = "max-content";
      cell.innerHTML += `${valueArr[i][j]}`;
      dataRow.appendChild(cell);
    }
    // } else {
    //     console.log('Error: number of keys are not equal to number of values of any page received');
    //     break;
    // }
    dataGrid.appendChild(dataRow);
  }

  mainDiv.appendChild(dataGrid);

  editor.addComponents(mainDiv.outerHTML);
  editor.Modal.close();

  console.log("data-grid appended");
}

export function createProjectDataGrid(editor, keyArr, valueArr) {
  console.log("creating data-grid1111111");
  console.log(keyArr, valueArr);

  const numRows = valueArr.length;
  const numCols = keyArr.length;

  const uniqueId = uniqid("prg-");
  console.log("creating unique ID for class:", uniqueId);

  const mainDiv = document.createElement("div");
  mainDiv.style.display = "flex";
  mainDiv.style.alignItems = "center";
  mainDiv.style.justifyContent = "center";
  mainDiv.style.padding = "0.5em";

  const dataGrid = document.createElement("table");
  dataGrid.setAttribute("id", `dg-${uniqueId}`);
  dataGrid.setAttribute("width", "100%");
  dataGrid.style.padding = "0.5em";
  dataGrid.style.borderSpacing = "0.5em";

  // first row as table attributes/column headers
  let headerRow = document.createElement("tr");

  keyArr.forEach((ele) => {
    let header = document.createElement("th");
    header.style.fontSize = "0.9em";
    header.innerHTML += `${ele}`;
    headerRow.appendChild(header);
  });

  dataGrid.appendChild(headerRow);

  for (let i = 0; i < numRows; i++) {
    let dataRow = document.createElement("tr");

    // checking if number of keys are equal to number of values of any page received (in this case, first page => 0 index)
    // if (numRows === valueArr[0].length) {
    for (let j = 0; j < numCols; j++) {
      let cell = document.createElement("td");
      cell.style.fontSize = "0.7em";
      cell.style.width = "max-content";
      cell.innerHTML += `${valueArr[i][j]}`;
      dataRow.appendChild(cell);
    }
    // } else {
    //     console.log('Error: number of keys are not equal to number of values of any page received');
    //     break;
    // }
    dataGrid.appendChild(dataRow);
  }

  mainDiv.appendChild(dataGrid);

  editor.addComponents(mainDiv.outerHTML);
  editor.Modal.close();

  console.log("data-grid appended");
}

// to initialize a form, for adding new page from within editor space
export function createProjectTable(editor, data) {
  // Step 4
  console.log("creating projectTable for:", data);
  // console.log(projectTable);
  // console.log(editor.getSelected());
  // editor.getSelected().append({ type: 'dataGrid' });
  // console.log("hereeeeeeeeeeeeeeeeeeeeeee:::::::::::::::::");

  // creating table using data
  const uniqueId = uniqid("data-grid-");
  console.log("creating unique ID for class:", uniqueId);

  const mainDiv = document.createElement("div");
  mainDiv.style.display = "flex";
  mainDiv.style.alignItems = "center";
  mainDiv.style.justifyContent = "center";
  mainDiv.style.padding = "0.5em";

  const dataGrid = document.createElement("table");
  dataGrid.setAttribute("id", `${uniqueId}`);
  dataGrid.setAttribute("class", `${uniqueId}`);
  dataGrid.setAttribute("width", "100%");
  dataGrid.style.padding = "0.5em";
  dataGrid.style.margin = "auto";
  dataGrid.style.borderSpacing = "0.5em";

  // first row as table attributes/column headers
  let thead = document.createElement("thead");

  let headTr = document.createElement("tr");

  Object.keys(data[0]).forEach((colName) => {
    let th = document.createElement("th");
    th.style.textAlign = "center";
    th.style.fontSize = "1.5vw";
    th.style.fontWeight = "bold";
    th.innerHTML = colName;

    headTr.appendChild(th);
  });

  thead.appendChild(headTr);

  dataGrid.appendChild(thead);

  let tbody = document.createElement("tbody");

  data.forEach((obj) => {
    let tr = document.createElement("tr");

    Object.values(obj).forEach((val) => {
      let td = document.createElement("td");
      td.style.fontSize = "1.5vw";
      td.innerHTML = val;

      tr.appendChild(td);
    });

    tbody.appendChild(tr);
  });

  dataGrid.appendChild(tbody);

  mainDiv.appendChild(dataGrid);

  editor.Modal.close();
  editor.getSelected().append(mainDiv.outerHTML);

  console.log("projectTable appended");
}

// to initialize a form , for adding fields  inside a table
export function createFormTable(editor) {
  editor.getSelected().append({ type: "dynamicForm" });
  editor.Modal.close();
}

export function createTemplateFragmentCreator(editor) {
  editor.getSelected().append({ type: "templateFragment" });
  editor.Modal.close();
}

export function createPageDataForm(editor, keyArr) {
  console.log("creating data-grid form");
  console.log(keyArr);

  const numCols = keyArr.length;

  const uniqueId = uniqid("pggf-");
  console.log("creating unique ID for class:", uniqueId);

  const mainDiv = document.createElement("div");
  mainDiv.style.display = "flex";
  mainDiv.style.alignItems = "center";
  mainDiv.style.justifyContent = "flex-start";
  mainDiv.style.padding = "0.5em";

  // <form id="..." style="...">
  // ...
  // ...</form>
  const dataGrid = document.createElement("form");
  dataGrid.setAttribute("id", `pg-form-${uniqueId}`);
  dataGrid.setAttribute("style", "width: 100%;");
  dataGrid.style.display = "flex";
  dataGrid.style.flexDirection = "column";
  dataGrid.style.alignItems = "flex-start";
  dataGrid.style.justifyContent = "center";
  dataGrid.style.padding = "0.5em";
  dataGrid.style.rowGap = "0.5em";

  // looping thru' keyArr array to access its elements (which are form fields)
  keyArr.forEach((ele) => {
    let field = document.createElement("div");

    let label = document.createElement("label");
    label.setAttribute("for", `${ele}`);
    label.innerHTML += `${ele}:`;

    field.appendChild(label);

    let input = document.createElement("input");
    input.setAttribute("type", "text");
    input.setAttribute("id", `${ele}`);
    input.setAttribute("name", `${ele}`);

    field.appendChild(input);

    dataGrid.appendChild(field);
  });

  mainDiv.appendChild(dataGrid);

  editor.addComponents(mainDiv.outerHTML);
  editor.Modal.close();

  console.log("data-grid form appended");
}

// to initialize a form, for adding new project from within editor space
export function createProjectDataForm(editor, keyArr) {
  console.log("creating data-grid form");
  console.log(keyArr);

  const numCols = keyArr.length;

  const uniqueId = uniqid("prgf-");
  console.log("creating unique ID for class:", uniqueId);

  const mainDiv = document.createElement("div");
  mainDiv.style.display = "flex";
  mainDiv.style.alignItems = "center";
  mainDiv.style.justifyContent = "flex-start";
  mainDiv.style.padding = "0.5em";

  // <form id="..." style="...">
  // ...
  // ...</form>
  const dataGrid = document.createElement("form");
  dataGrid.setAttribute("id", `pg-form-${uniqueId}`);
  dataGrid.setAttribute("style", "width: 100%;");
  dataGrid.style.display = "flex";
  dataGrid.style.flexDirection = "column";
  dataGrid.style.alignItems = "flex-start";
  dataGrid.style.justifyContent = "center";
  dataGrid.style.padding = "0.5em";
  dataGrid.style.rowGap = "0.5em";

  // looping thru' keyArr array to access its elements (which are form fields)
  keyArr.forEach((ele) => {
    let field = document.createElement("div");

    let label = document.createElement("label");
    label.setAttribute("for", `${ele}`);
    label.innerHTML += `${ele}:`;

    field.appendChild(label);

    let input = document.createElement("input");
    input.setAttribute("type", "text");
    input.setAttribute("id", `${ele}`);
    input.setAttribute("name", `${ele}`);

    field.appendChild(input);

    dataGrid.appendChild(field);
  });

  mainDiv.appendChild(dataGrid);

  editor.addComponents(mainDiv.outerHTML);
  editor.Modal.close();

  console.log("data-grid form appended");
}

// 1t^D@7&3^G3k
export function authenticateUser(username, password) {
  var token = username + ":" + password;

  var hash = btoa(token);

  // return "Bearer " + hash;
  return "Basic " + hash;
}

export function generateAPIKEY(keyVal) {
  return "Basic " + keyVal;
}

export function dynamicApiCall2(editor, data) {
  console.log("URL=====", data);

  console.log("data:", data);
  const urlToSend = data.url;
  // const userToken = data.token;
  const userToken = data.bearer_token;

  const responseCode200 = data.responseCode200;
  const responseCode201 = data.responseCode201;
  const responseCode204 = data.responseCode204;
  const responseCode400 = data.responseCode400;
  const responseCode404 = data.responseCode404;
  const responseCode500 = data.responseCode500;

  const uniqueId = uniqid("ft-");

  const mainDiv = document.createElement("div");
  mainDiv.style.display = "flex";
  mainDiv.style.padding = "0.5em";
  mainDiv.style.width = "fit-content";
  mainDiv.setAttribute("id", uniqueId);

  const wrapperDiv = document.createElement("div");
  wrapperDiv.style.width = "100%";
  wrapperDiv.style.display = "flex";
  wrapperDiv.style.alignItems = "center";
  wrapperDiv.style.justifyContent = "center";
  wrapperDiv.style.flexDirection = "column";

  let formBtn = document.createElement("button");
  formBtn.innerHTML += "Submit";
  let btnId = uniqueId + "-custom";
  formBtn.setAttribute("id", btnId);
  formBtn.setAttribute("type", "basicButton");
  formBtn.style.backgroundColor = "red";
  formBtn.style.color = "white";
  formBtn.style.borderRadius = "6px";
  formBtn.style.padding = "12px 30px";
  formBtn.style.width = "100%";

  wrapperDiv.appendChild(formBtn);
  mainDiv.appendChild(wrapperDiv);

  var script = document.createElement("script");
  script.innerhtml = "<script> Your script here </script>";
  let uniqueIdBtn = uniqid + "btn";
  script.innerHTML += `
    // <script>
      document.querySelector("#${btnId}").addEventListener('click', function() {
          console.log("CHCICEeCDD")

          const inputs = document.querySelector('#${uniqueId}').getElementsByTagName('input')
          console.log('inputs',inputs)

          const to_insert = {}

          for(let i = 0; i < inputs.length; i++) {
              console.log('inputs[i].value:::::',inputs[i].value.length)
              console.log(inputs[i])
              console.log('inputs[i].type::::::::::::::::',inputs[i].type)

              if(inputs[i].type==='number'){
                console.log('inside number check:::::::::')

                let numberRegex = ${/^[-+]?\d*\.?\d+$/};
                let checkRegex= numberRegex.test(inputs[i].value);

                if(inputs[i].value.length>0) {
                  to_insert[inputs[i].name] = (typeof Number(inputs[i].value) !== NaN) ? Number(inputs[i].value) : "";
                 }else{
                  console.log('empty inputs[i].name',inputs[i].name)
                 }
              } else if(inputs[i].type==='text'){
                console.log('inside text check:::::::::')
                let textRegex = ${/^[a-zA-Z]+$/};
                let checkRegex= textRegex.test(inputs[i].value);
                 if(inputs[i].value.length>0){
                  console.log(inputs[i]);
                  to_insert[inputs[i].name] = inputs[i].value
                 }else{
                  console.log('empty inputs[i].name',inputs[i].name)
                 }
              }else{
                console.log('inside else check:::::::::')
               if(inputs[i].value.length>0){
                to_insert[inputs[i].name] = inputs[i].value
               }else{
                console.log('empty inputs[i].name',inputs[i].name)
               }
              }
              

            }

            console.log(to_insert)
            const to_insert2 = {
              "to_insert": to_insert
            }

            console.log('to_insert',to_insert);
        
          if(Object.keys(to_insert).length>0){
            
            fetch('${urlToSend}', {
              method: 'POST',
              headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer ${userToken}",
              },
              body: JSON.stringify(to_insert2)
            }).then(function (response) {
              if(response.ok) {
                console.log('response',response)
                
                if(response.status === 200 && ("${responseCode200}" !== "responseCode200")) {
                  console.log("sfdhkjsdhf")
                 
                  // console.log("${responseCode200}")
                  
                } else if(response.status === 200) {
                  alert("success")
   
                 
            
                } else if(response.status === 201 && ("${responseCode201}" !== "responseCode201")) {
                  parent.document.location = "${responseCode201}"
                } else if(response.status === 201) {
                  alert("201 Status");
                } else if(response.status === 204 && ("${responseCode204}" !== "responseCode204")) {
                  parent.document.location = "${responseCode204}"
                } else if(response.status === 204) {
                  alert("204 status");
                } else if(response.status === 400 && ("${responseCode400}" !== "responseCode400") ) {
                  parent.document.location = "${responseCode400}";
                } else if(response.status === 400) {
                  alert("400 status")
                } else if(response.status === 404 && ("${responseCode404}" !== "responseCode404")) {
                  parent.document.location = "${responseCode404}";
                } else if(response.status === 404) {
                  alert("404 status")
                } else if(response.status === 500 && ("${responseCode500}" !== "responseCode500") ) {
                  parent.document.location = "${responseCode500}"
                } else if(response.status === 500) {
                  alert("500 status")
                }
                return response.json();
              }
              throw new Error ("Something went wrong");
            }).then(function (responseJSON) {
              console.log(responseJSON.object.globalUhid)
              localStorage.setItem('global_id',responseJSON.object.globalUhid)

              parent.document.location = "${responseCode200}"
              
              
            }).catch(function (error) {
              console.warn('Something went wrong.', error);
              if(error.statusCode === 400 && ("${responseCode400}" !== "responseCode400") ) {
                parent.document.location = "${responseCode400}";
              } else if(error.statusCode === 400) {

                alert("400 status")
              } else if(error.statusCode === 404 && ("${responseCode404}" !== "responseCode404")) {
                parent.document.location = "${responseCode404}";
              } else if(error.statusCode === 404) {
                alert("404 status")
              } else if(error.statusCode === 500 && ("${responseCode500}" !== "responseCode500") ) {
                parent.document.location = "${responseCode500}"
              } else if(error.statusCode === 500) {
                alert("500 status")
              }
            });
          }
          else{
            alert('please provide valid input fields')
          }
       })
      console.log("AFdsaDFDF");
    // </script>
  `;

  editor.addComponents(mainDiv.outerHTML);
  editor.Modal.close();
  editor.addComponents(script.outerHTML);
  console.log("projectTable appended");
  // const mainDiv = document.createElement('div');
  // mainDiv.style.display = 'flex';
  // mainDiv.style.alignItems = 'center';
  // mainDiv.style.justifyContent = 'flex-start';
  // mainDiv.style.padding = '0.5em';

  // const dataGrid = document.createElement('table');
  // dataGrid.setAttribute("id", `conn-${uniqueId}`);

  // console.log('entries:', Object.entries(data["results"]));

  // mainDiv.appendChild(dataGrid);

  // editor.addComponents(mainDiv.outerHTML);
}

export function storeConnectionForm2(editor) {
  console.log("inside connectionFormAdd");

  // variables capturing the user values from connection modal form
  const urlInputValue = document.getElementById("urlInput").value;
  const methodSelectInputValue =
    document.getElementById("methodSelectInput").value;
  const bodyInputValue = document.getElementById("bodyInput").value;
  const contentTypeSelectInputValue = document.getElementById(
    "contentTypeSelectInput"
  ).value;
  const authUsernameValue = document.getElementById("authUsername").value;
  const authPasswordValue = document.getElementById("authPassword").value;
  const apiKeyValue = document.getElementById("apiKeyVal").value;
  const bearerTokenValue = document.getElementById("bearerTokenInput").value;

  const responseCode200 =
    document.getElementById("responseCode200").value || "responseCode200";
  const responseCode201 =
    document.getElementById("responseCode201").value || "responseCode201";
  const responseCode204 =
    document.getElementById("responseCode204").value || "responseCode204";
  const responseCode400 =
    document.getElementById("responseCode400").value || "responseCode400";
  const responseCode404 =
    document.getElementById("responseCode404").value || "responseCode404";
  const responseCode500 =
    document.getElementById("responseCode500").value || "responseCode500";

  console.log(
    "urldata:",
    urlInputValue,
    methodSelectInputValue,
    bodyInputValue,
    contentTypeSelectInputValue,
    authUsernameValue,
    authPasswordValue,
    apiKeyValue,
    bearerTokenValue,
    responseCode200,
    responseCode201,
    responseCode204,
    responseCode400,
    responseCode404,
    responseCode500
  );

  editor.Modal.close(); // to close the modal automatically after creating the table

  console.log("creating a token from username & password, if provided");

  let token = authenticateUser(authUsernameValue, authPasswordValue);
  console.log("token:", token);

  // let apiKeyVal2 = generateAPIKEY(apiKeyValue);
  let urlData;
  if (authUsernameValue !== "" && authPasswordValue != "") {
    urlData = {
      auth_type: "basic auth",
      method: methodSelectInputValue,
      url: urlInputValue,
      body: bodyInputValue,
      username: authUsernameValue,
      password: authPasswordValue,
      contentType: contentTypeSelectInputValue,
      // token: "Basic cHJlX2FjbF91c2VyOkBDTDA5MTI=",
      token: token,
      api_key: apiKeyValue,
      bearer_token: bearerTokenValue,
      responseCode200: responseCode200,
      responseCode400: responseCode400,
      responseCode404: responseCode404,
      responseCode500: responseCode500,
    };
  } else {
    urlData = {
      auth_type: "basic auth",
      method: methodSelectInputValue,
      url: urlInputValue,
      body: bodyInputValue,
      username: authUsernameValue,
      password: authPasswordValue,
      contentType: contentTypeSelectInputValue,
      // token: "Basic cHJlX2FjbF91c2VyOkBDTDA5MTI=",
      api_key: apiKeyValue,
      bearer_token: bearerTokenValue,
      responseCode200: responseCode200,
      responseCode400: responseCode400,
      responseCode404: responseCode404,
      responseCode500: responseCode500,
      // api_key: apiKeyValue,
    };
  }

  // dynamicApiCall(urlData);

  console.log("connection form values stored");
  return urlData;
}

export function storeConnectionForm(editor) {
  console.log("inside connectionForm");

  // variables capturing the user values from connection modal form
  const urlInputValue = document.getElementById("urlInput").value;
  const methodSelectInputValue =
    document.getElementById("methodSelectInput").value;
  const bodyInputValue = document.getElementById("bodyInput").value;
  const contentTypeSelectInputValue = document.getElementById(
    "contentTypeSelectInput"
  ).value;
  const authUsernameValue = document.getElementById("authUsername").value;
  const authPasswordValue = document.getElementById("authPassword").value;
  const apiKeyValue = document.getElementById("apiKeyVal").value;
  const bearerTokenValue = document.getElementById("bearerTokenInput").value;

  console.log(
    "urldata:",
    urlInputValue,
    methodSelectInputValue,
    bodyInputValue,
    contentTypeSelectInputValue,
    authUsernameValue,
    authPasswordValue,
    apiKeyValue,
    bearerTokenValue
  );

  // getting file input
  let formDataFile;
  try {
    const formDataVal = document.getElementById("formDataVal");
    formDataVal.onchange = async () => {
      formDataFile = await formDataVal.files[0];
      console.log("<<<< file:", formDataFile);
    };
  } catch (e) {
    console.log("error => ", e);
  }

  //     console.log('urldata:', urlInputValue, methodSelectInputValue, bodyInputValue, contentTypeSelectInputValue, authUsernameValue, authPasswordValue, apiKeyValue, ', selected file:', formDataFile);
  // } catch {
  //       console.log('no files');
  // }
  editor.Modal.close(); // to close the modal automatically after creating the table

  console.log("creating a token from username & password, if provided");

  let token = authenticateUser(authUsernameValue, authPasswordValue);
  console.log("token:", token);

  // let apiKeyVal2 = generateAPIKEY(apiKeyValue);

  const urlData = {
    auth_type: "basic auth",
    method: methodSelectInputValue,
    url: urlInputValue,
    body: bodyInputValue,
    username: authUsernameValue,
    password: authPasswordValue,
    contentType: contentTypeSelectInputValue,
    // token: "Basic cHJlX2FjbF91c2VyOkBDTDA5MTI=",
    token: token,
    api_key: apiKeyValue,
    bearer_token: bearerTokenValue,
    form_data_file: formDataFile,
  };
  // dynamicApiCall(urlData);

  console.log("connection form values stored");
  return urlData;
}

// helper functions
function createTableFromObject(data, uniqueId) {
  const wrapper = document.createElement("div");
  wrapper.classList.add("table-responsive");

  const table = document.createElement("div");
  table.classList.add("table-container");
  table.setAttribute("id", uniqueId);
  table.setAttribute("class", `${uniqueId}-table-container`);
  table.setAttribute("style", "display: table; border-collapse: collapse;");

  const headerRow = document.createElement("div");
  headerRow.classList.add("table-header");
  headerRow.setAttribute("style", "display: table-row; font-weight: bold;");

  for (const key in data) {
    const th = document.createElement("div");
    th.classList.add("table-header-cell");
    th.setAttribute(
      "style",
      "display: table-cell; border: 1px solid #ddd; text-align: center;"
    );
    th.textContent = key;
    headerRow.appendChild(th);

    const value = data[key];
    if (typeof value === "object" && value !== null) {
      const td = document.createElement("div");
      td.classList.add("table-cell");
      td.setAttribute(
        "style",
        "display: table-cell; border: 1px solid #ddd; text-align: center;"
      );

      if (Array.isArray(value)) {
        const subTable = createTableFromArray(value, uniqueId);
        td.appendChild(subTable);
      } else {
        const subTable = createTableFromObject(value, uniqueId);
        td.appendChild(subTable);
      }

      headerRow.appendChild(td);
    } else {
      const td = document.createElement("div");
      td.classList.add("table-cell");
      td.setAttribute(
        "style",
        "display: table-cell; border: 1px solid #ddd; text-align: center;"
      );
      td.textContent = value;
      headerRow.appendChild(td);
    }
  }

  table.appendChild(headerRow);
  wrapper.appendChild(table);
  return wrapper;
}

function createTableFromArray(array, uniqueId) {
  const subTable = document.createElement("div");
  subTable.classList.add("sub-table");

  array.forEach((item) => {
    if (typeof item === "object" && item !== null) {
      const subTableRow = document.createElement("div");
      subTableRow.classList.add("table-row");
      subTableRow.setAttribute("style", "display: table-row;");

      for (const key in item) {
        const subTableCell = document.createElement("div");
        subTableCell.classList.add("table-cell");
        subTableCell.setAttribute(
          "style",
          "display: table-cell; border: 1px solid #ddd; text-align: center;"
        );
        subTableCell.textContent = item[key];
        subTableRow.appendChild(subTableCell);
      }

      subTable.appendChild(subTableRow);
    }
  });

  return subTable;
}

// creating table dynamically
export function createDynamicApiCallResponseTable(editor, data) {
  console.log("data:", data);

  const uniqueId = uniqid("prgf-");
  console.log("creating unique ID for class:", uniqueId);

  // create table element
  if (Array.isArray(data)) {
    // create table rows if its an array
    console.log("data is an array");
    // create table container
    const wrapper = document.createElement("div");
    wrapper.classList.add("table-responsive");
    const table = document.createElement("div");
    table.classList.add("table-container");
    table.setAttribute("id", uniqueId);
    // create table header row
    const headers = getHeaders(data[0]);
    const headerRow = document.createElement("div");
    headerRow.classList.add("table-header");
    headers.forEach((header) => {
      const th = document.createElement("div");
      th.classList.add("table-header-cell");
      th.appendChild(document.createTextNode(header));
      headerRow.appendChild(th);
    });
    table.appendChild(headerRow);

    data.forEach((row) => {
      const tr = document.createElement("div");
      tr.classList.add("table-row");
      headers.forEach((header) => {
        const td = document.createElement("div");
        td.classList.add("table-cell");
        if (header.includes(".")) {
          const keys = header.split(".");
          let value = row;
          keys.forEach((key) => {
            value = value[key];
          });
          if (Array.isArray(value)) {
            if (value.length === 0) {
              td.appendChild(document.createTextNode("[]"));
            } else {
              const subTable = createTable(value);
              td.appendChild(subTable);
            }
          } else if (typeof value === "object" && value !== null) {
            const subTable = createTable([value]);
            td.appendChild(subTable);
          } else {
            td.appendChild(document.createTextNode(value));
          }
        } else {
          td.appendChild(document.createTextNode(row[header]));
        }
        tr.appendChild(td);
      });
      table.appendChild(tr);
    });

    // set table container attributes
    table.setAttribute("class", `${uniqueId}-table-container`);
    table.setAttribute("style", "display: table; border-collapse: collapse;");

    // set header row attributes
    headerRow.setAttribute(
      "class",
      `.${uniqueId}-table-container table-header`
    );
    headerRow.setAttribute("style", "display: table-row; font-weight: bold;");

    // set header cell attributes
    const headerCells = headerRow.querySelectorAll(
      `.${uniqueId}-table-container .table-header-cell`
    );
    headerCells.forEach((cell) => {
      cell.setAttribute(
        "style",
        "display: table-cell; border: 1px solid #ddd; text-align: center;"
      );
    });

    // set table row attributes
    const rows = table.querySelectorAll(
      `.${uniqueId}-table-container .table-row`
    );
    rows.forEach((row, index) => {
      row.setAttribute("class", `.${uniqueId}-table-container table-row`);
      row.setAttribute(
        "style",
        `display: table-row; background-color: ${
          index % 2 === 0 ? "#f2f2f2" : "white"
        };`
      );
    });

    // set table cell attributes
    const cells = table.querySelectorAll(
      `.${uniqueId}-table-container .table-cell`
    );
    cells.forEach((cell) => {
      cell.setAttribute(
        "style",
        "display: table-cell; border: 1px solid #ddd;  text-align: center;"
      );
    });

    wrapper.appendChild(table);
    editor.getSelected().append(wrapper.outerHTML);
  }

  // if data is an object
  if (!!data && data.constructor === Object) {
    console.log("data is an object");
    const tableContainer = createTableFromObject(data, uniqueId);
    editor.getSelected().append(tableContainer.outerHTML);
  }
}

function getHeaders(data) {
  const headers = [];
  for (let key in data) {
    if (typeof data[key] === "object" && data[key] !== null) {
      const subHeaders = getHeaders(data[key]);
      subHeaders.forEach((subHeader) => {
        headers.push(key + "." + subHeader);
      });
    } else {
      headers.push(key);
    }
  }
  return headers;
}

// IONIC TABS
export function createIonicTabs(editor) {
  const mainDiv = document.createElement("div");
  mainDiv.style.display = "flex";
  mainDiv.style.justifyContent = "center";
  mainDiv.style.alignItems = "center";
  mainDiv.style.width = "100%";

  mainDiv.innerHTML += `
 
 <div class="div1Tab">
 <p>
   <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"  viewBox="0 0 16 16">
     <path d="M8.707 1.5a1 1 0 0 0-1.414 0L.646 8.146a.5.5 0 0 0 .708.708L8 2.207l6.646 6.647a.5.5 0 0   0 .708-.708L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293L8.707 1.5Z"/>
     <path d="m8 3.293 6 6V13.5a1.5 1.5 0 0 1-1.5 1.5h-9A1.5 1.5 0 0 1 2 13.5V9.293l6-6Z"/>
   </svg>
 </p>
 <p>
 HOME
 </p>
 </div>

 <div  class="div2Tab">
 <p>
 <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-music-note-beamed" viewBox="0 0 16 16">
 <path d="M6 13c0 1.105-1.12 2-2.5 2S1 14.105 1 13c0-1.104 1.12-2 2.5-2s2.5.896 2.5 2zm9-2c0 1.105-1.12 2-2.5 2s-2.5-.895-2.5-2 1.12-2 2.5-2 2.5.895 2.5 2z"/>
 <path fill-rule="evenodd" d="M14 11V2h1v9h-1zM6 3v10H5V3h1z"/>
 <path d="M5 2.905a1 1 0 0 1 .9-.995l8-.8a1 1 0 0 1 1.1.995V3L5 4V2.905z"/>
</svg>
 </p>
 <p>
 MUSIC
 </p>
 </div>

 <div class="div3Tab">
 <p>
 <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"  viewBox="0 0 16 16">
 <path d="M9.405 1.05c-.413-1.4-2.397-1.4-2.81 0l-.1.34a1.464 1.464 0 0 1-2.105.872l-.31-.17c-1.283-.698-2.686.705-1.987 1.987l.169.311c.446.82.023 1.841-.872 2.105l-.34.1c-1.4.413-1.4 2.397 0 2.81l.34.1a1.464 1.464 0 0 1 .872 2.105l-.17.31c-.698 1.283.705 2.686 1.987 1.987l.311-.169a1.464 1.464 0 0 1 2.105.872l.1.34c.413 1.4 2.397 1.4 2.81 0l.1-.34a1.464 1.464 0 0 1 2.105-.872l.31.17c1.283.698 2.686-.705 1.987-1.987l-.169-.311a1.464 1.464 0 0 1 .872-2.105l.34-.1c1.4-.413 1.4-2.397 0-2.81l-.34-.1a1.464 1.464 0 0 1-.872-2.105l.17-.31c.698-1.283-.705-2.686-1.987-1.987l-.311.169a1.464 1.464 0 0 1-2.105-.872l-.1-.34zM8 10.93a2.929 2.929 0 1 1 0-5.86 2.929 2.929 0 0 1 0 5.858z"/>
</svg>
 </p>
 <p>
 SETTINGS
 </p>
 </div>



 <style >
 .div1Tab{
  display:flex;
  justify-content:center;
  align-items:center;
  width:33%,
  flex-direction:column
  
 }

 .div2Tab{
  display:flex;
  justify-content:center;
  align-items:center;
  width:33%,
  flex-direction:column
 
 }

 .div3Tab{
  display:flex;
  justify-content:center;
  align-items:center;
  width:33%,
  flex-direction:column
  
 }
 </style>
`;

  editor.getSelected().append(mainDiv.outerHTML);
  // editor.addComponents(ionicTab.outerHTML)
}

// IONIC SEARCH-BAR
export function createIonicSearchBar(editor) {
  const ionicSearchBar = document.createElement("div");
  ionicSearchBar.style.display = "flex";
  ionicSearchBar.style.justifyContent = "start";
  ionicSearchBar.style.alignItems = "center";
  ionicSearchBar.style.width = "100%";

  ionicSearchBar.innerHTML += `
  
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-search" viewBox="0 0 16 16">
  <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
</svg>
  
  <input class="searchBarInput" placeholder="Search Field" type="text"/>

  <style>
  .searchBarInput{
    outline:none;
    flex-grow:1;
  }
  </style>
  `;

  editor.getSelected().append(ionicSearchBar.outerHTML);
}

// IONIC LIST
export async function createIonicList(editor) {
  const List = document.createElement("div");
  List.innerHTML += `
  <p>List Item 1</p>
  <p>List Item 2</p>
  <p>List Item 3</p>
  <p>List Item 4</p>
  `;

  const data1 = editor.getSelected().append(List.outerHTML);
}

// IONIC ITEM
// export function createIonicItem(editor) {

//   const ionicItem = document.createElement('ion-item');
//   ionicItem.style.minWidth = '100%'
//   ionicItem.style.minHeight = '50px'
//   const ioniclabel = document.createElement('ion-label');
//   ioniclabel.innerHTML += 'pokemon';
//   ionicItem.appendChild(ioniclabel)

//   editor.getSelected().append(ionicItem.outerHTML);

// }

// IONIC MENU
export function createIonicMenu(editor) {
  const wrapperDiv = document.createElement("div");
  wrapperDiv.style.height = "100%";
  wrapperDiv.style.position = "relative";
  wrapperDiv.innerHTML += `
  <div class="MenuHeadComp">
  <p class="MenuIconComp">
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-list" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"/>
</svg>
  </p>
  <p class="MenuHeadCompP">
    MENU
  </p>
  </div>
  <div class="MenuActionComp">
   <div class="MenuActionCloseWrapper">
   <p>Menu Content</p>
   
   <p class="MenuActionClose">
   <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
   <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>
 </svg>
   </p>
   </div>
   <p>Menu Content Item 1</p>
   <p>Menu Content Item 2</p>
   <p>Menu Content Item 3</p>
   <p>Menu Content Item 4</p>
  
  </div>

  <style>

  .MenuHeadComp{
    display:flex;
    justify-content:start;
    align-items:center;
  }

  .MenuHeadCompP{
    margin-left:10rem;
  }

  .MenuActionComp{
    display:none;
    height:100%;
    width:100%;
  }

  .MenuActionCloseWrapper{
    display:flex;
  justify-content:space-between;
  align-items:center;
  }


  </style>

  <script type="text/javascript">

  function closeMenu(){
    const menuBtn=document.querySelector('.MenuActionComp');
    menuBtn.style.display='none';
  }

  function openMenu(){
    const menuBtn=document.querySelector('.MenuActionComp');
    menuBtn.style.display='block';
    menuBtn.style.position='absolute';
    menuBtn.style.top='0';
    menuBtn.style.left='0';
    menuBtn.style.width='60%';
    menuBtn.style.backgroundColor='white';
    document.querySelector('.MenuActionClose').addEventListener('click',closeMenu);
    
  }
   
  document.querySelector('.MenuIconComp').addEventListener('click',openMenu);
  </script>
  `;

  editor.getSelected().append(wrapperDiv.outerHTML);
}

// IONIC ACTION
export function createIonicAction(editor) {
  const wrapperDiv = document.createElement("div");
  wrapperDiv.style.display = "flex";
  wrapperDiv.style.alignItems = "center";
  wrapperDiv.style.justifyContent = "center";
  wrapperDiv.style.height = "100%";
  wrapperDiv.style.position = "relative";

  wrapperDiv.innerHTML += `
  <p class="deleteBtn">Delete<p>
  <div class="confirmWrapper">
  <p>Are you sure you want to delete this item ?</p>
  <div class="buttonWrapper">
    <button class="actionBtn1">Confirm</button>
    <button class="actionBtn2">Cancel</button>
  </div>
</div>

<style>
.confirmWrapper{
  display:none;
  
}

.deleteBtn{
  padding:0.5rem 1rem;
  border-radius:6px;
  color:white;
  background-color:red;
}

.buttonWrapper{
 display:flex;
 justify-content:start;
 align-items:center;
}

.actionBtn1{
  padding:0.5rem;
  width:50%;
  border-radius:6px;
  color:white;
  background-color:red;
}

.actionBtn2{
  padding:0.5rem;
  width:50%;
  border-radius:6px;
  color:white;
  background-color:green;
}

</style>

<script type='text/javascript'>

function confirmDelete(){
      document.querySelector('.confirmWrapper').style.display = 'none';
      const pTag=document.createElement('p');
      pTag.innerHTML+='Confirm button is clicked'
      wrapperDiv.appendChild(pTag)

    }

function cancelDelete(){
      document.querySelector('.confirmWrapper').style.display = 'none';
      const pTag1=document.createElement('p');
      pTag1.innerHTML+='Cancel button is clicked'
      wrapperDiv.appendChild(pTag1)
    }

    function openModal(){
      console.log('clicked here')
      const wrapper=document.querySelector('.confirmWrapper');
      wrapper.style.display='block';
      wrapper.style.position = 'absolute';
      wrapper.style.top = '0';
      wrapper.style.padding = '1rem';
      wrapper.style.backgroundColor = 'white';
      wrapper.style.borderRadius = '6px';
    }

    document.querySelector('.deleteBtn').addEventListener('click',openModal)
    document.querySelector('.actionBtn1').addEventListener('click',confirmDelete);
    document.querySelector('.actionBtn2').addEventListener('click',cancelDelete);

  </script>

    `;

  editor.getSelected().append(wrapperDiv.outerHTML);
}

// IONIC DATE-TIME
export function createIonicDateTime(editor) {
  const wrapperDiv = document.createElement("div");
  wrapperDiv.style.marginLeft = "auto";
  wrapperDiv.style.marginRight = "auto";
  wrapperDiv.innerHTML += `
<p>
<ion-item>
<ion-label>Pick date</ion-label>
<ion-input
  id="date"
  class="ion-text-end"
></ion-input>
<ion-popover trigger="date" size="cover">
    <ion-datetime
      id="myCalendar"
    ></ion-datetime>
</ion-popover>
</ion-item>
</p>

<script type="text/javascript">

document.querySelector('#myCalendar').addEventListener('ionChange', (event) => {
  const selectedDate = event.detail.value;
  const newUtc=new Date(selectedDate)
  document.querySelector('#date').innerHTML=newUtc
});

</script>
  `;
  editor.getSelected().append(wrapperDiv.outerHTML);
}

// IONIC FLOATING ACTION BUTTON
export function createIonicFloatingBtn(editor) {
  const wrapperDiv = document.createElement("div");
  wrapperDiv.style.height = "100%";
  wrapperDiv.style.position = "relative";

  wrapperDiv.innerHTML += `
  <span class="floatingBtnTrigger">
<svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" class="bi bi-arrow-up-circle-fill" viewBox="0 0 16 16">
  <path d="M16 8A8 8 0 1 0 0 8a8 8 0 0 0 16 0zm-7.5 3.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V11.5z"/>
</svg>
</span>

<div class="floatingIconWrapper">
<span class="floatingIcon1">
<svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" class="bi bi-palette-fill" viewBox="0 0 16 16">
  <path d="M12.433 10.07C14.133 10.585 16 11.15 16 8a8 8 0 1 0-8 8c1.996 0 1.826-1.504 1.649-3.08-.124-1.101-.252-2.237.351-2.92.465-.527 1.42-.237 2.433.07zM8 5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm4.5 3a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zM5 6.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm.5 6.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z"/>
</svg>
</span>
<span class="floatingIcon2">
<svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" class="bi bi-gear-fill" viewBox="0 0 16 16">
  <path d="M9.405 1.05c-.413-1.4-2.397-1.4-2.81 0l-.1.34a1.464 1.464 0 0 1-2.105.872l-.31-.17c-1.283-.698-2.686.705-1.987 1.987l.169.311c.446.82.023 1.841-.872 2.105l-.34.1c-1.4.413-1.4 2.397 0 2.81l.34.1a1.464 1.464 0 0 1 .872 2.105l-.17.31c-.698 1.283.705 2.686 1.987 1.987l.311-.169a1.464 1.464 0 0 1 2.105.872l.1.34c.413 1.4 2.397 1.4 2.81 0l.1-.34a1.464 1.464 0 0 1 2.105-.872l.31.17c1.283.698 2.686-.705 1.987-1.987l-.169-.311a1.464 1.464 0 0 1 .872-2.105l.34-.1c1.4-.413 1.4-2.397 0-2.81l-.34-.1a1.464 1.464 0 0 1-.872-2.105l.17-.31c.698-1.283-.705-2.686-1.987-1.987l-.311.169a1.464 1.464 0 0 1-2.105-.872l-.1-.34zM8 10.93a2.929 2.929 0 1 1 0-5.86 2.929 2.929 0 0 1 0 5.858z"/>
</svg>
</span>
<span class="floatingIcon3">
<svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" class="bi bi-house-gear-fill" viewBox="0 0 16 16">
  <path d="M7.293 1.5a1 1 0 0 1 1.414 0L11 3.793V2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v3.293l2.354 2.353a.5.5 0 0 1-.708.708L8 2.207 1.354 8.854a.5.5 0 1 1-.708-.708L7.293 1.5Z"/>
  <path d="M11.07 9.047a1.5 1.5 0 0 0-1.742.26l-.02.021a1.5 1.5 0 0 0-.261 1.742 1.5 1.5 0 0 0 0 2.86 1.504 1.504 0 0 0-.12 1.07H3.5A1.5 1.5 0 0 1 2 13.5V9.293l6-6 4.724 4.724a1.5 1.5 0 0 0-1.654 1.03Z"/>
  <path d="m13.158 9.608-.043-.148c-.181-.613-1.049-.613-1.23 0l-.043.148a.64.64 0 0 1-.921.382l-.136-.074c-.561-.306-1.175.308-.87.869l.075.136a.64.64 0 0 1-.382.92l-.148.045c-.613.18-.613 1.048 0 1.229l.148.043a.64.64 0 0 1 .382.921l-.074.136c-.306.561.308 1.175.869.87l.136-.075a.64.64 0 0 1 .92.382l.045.149c.18.612 1.048.612 1.229 0l.043-.15a.64.64 0 0 1 .921-.38l.136.074c.561.305 1.175-.309.87-.87l-.075-.136a.64.64 0 0 1 .382-.92l.149-.044c.612-.181.612-1.049 0-1.23l-.15-.043a.64.64 0 0 1-.38-.921l.074-.136c.305-.561-.309-1.175-.87-.87l-.136.075a.64.64 0 0 1-.92-.382ZM12.5 14a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3Z"/>
</svg>
</span>
</div>

<style>
.floatingBtnTrigger{
  position:absolute;
  bottom:10px;
  right:10px;
}

.floatingIconWrapper{
  display:none;
}

.floatingIcon2{
  margin-top:10px;
  margin-bottom:10px;
}

</style>

<script type='text/javascript'>

function closeModal(){
      document.querySelector('.floatingIconWrapper').style.display = 'none';
    }

    function openModal(){
      if(document.querySelector('.floatingIconWrapper').style.display!=='none'){
        console.log('here1111')
        const wrapper=document.querySelector('.floatingIconWrapper');
        wrapper.style.display='none';
        
      }else{
        console.log("here2222222")
        const wrapper=document.querySelector('.floatingIconWrapper');
        wrapper.style.display='flex';
      wrapper.style.justifyContent='center';
      wrapper.style.alignItems='flex-end';
      wrapper.style.flexDirection='column';
      wrapper.style.position = 'absolute';
      wrapper.style.bottom = '70px';
      wrapper.style.right = '10px';
      wrapper.style.backgroundColor = 'white';
      wrapper.style.borderRadius = '6px';
        
        
      }
      
    }

    document.querySelector('.floatingBtnTrigger').addEventListener('click',openModal)
    document.querySelector('.floatingIcon1').addEventListener('click',closeModal);
    document.querySelector('.floatingIcon2').addEventListener('click',closeModal);
    document.querySelector('.floatingIcon3').addEventListener('click',closeModal);

  </script>
  `;

  editor.getSelected().append(wrapperDiv.outerHTML);
}

// IONIC MODAL
export function createIonicModal(editor) {
  const wrapperDiv = document.createElement("div");
  wrapperDiv.innerHTML += `
<ion-content class="ion-padding">
  <ion-button id="open-modal" expand="block">Open</ion-button>
  <p id="message">This modal example uses triggers to automatically open a modal when the button is clicked.</p>
  <ion-modal trigger="open-modal">
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-button class='closeBtn' >Cancel</ion-button>
        </ion-buttons>
        <ion-title>Welcome</ion-title>
        <ion-buttons slot="end">
          <ion-button class='confirmBtn' strong="true">Confirm</ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content class="ion-padding">
      <ion-item>
        <ion-label position="stacked">Enter your name</ion-label>
        <ion-input type="text" placeholder="Your name"></ion-input>
      </ion-item>
    </ion-content>
  </ion-modal>
</ion-content>

<script>
  var modal = document.querySelector('ion-modal');

  function cancel() {
    modal.dismiss(null, 'cancel');
  }

  function confirm() {
    const input = document.querySelector('ion-input');
    modal.dismiss(input.value, 'confirm');
  }
  
  document.querySelector('.confirmBtn').addEventListener('click', confirm);
  document.querySelector('.closeBtn').addEventListener('click', cancel);

  modal.addEventListener('willDismiss', (ev) => {
    if (ev.detail.role === 'confirm') {
      const message = document.querySelector('#message');
      message.textContent = 'Hello'+' '+ ev.detail.data + '!!!';
    }
  });
</script>
    `;
  editor.getSelected().append(wrapperDiv.outerHTML);
}

// IONIC MODAL
export function createIonicPopover(editor) {
  const wrapperDiv = document.createElement("div");
  wrapperDiv.innerHTML += `
    <ion-button id="click-trigger">Click Me</ion-button>
<ion-popover trigger="click-trigger" trigger-action="click">
  <ion-content class="ion-padding">Hello World!</ion-content>
</ion-popover>
    `;
  editor.getSelected().append(wrapperDiv.outerHTML);
}

// IONIC CHECKBOX
export function createIonicCheckbox(editor) {
  const wrapperDiv = document.createElement("ion-item");
  wrapperDiv.innerHTML += `
  <ion-checkbox slot="start"></ion-checkbox>
  <ion-label>I agree to the terms and conditions</ion-label>
    `;
  editor.getSelected().append(wrapperDiv.outerHTML);
}

// IONIC HEADER
export function createIonicHeader(editor) {
  const wrapperDiv = document.createElement("ion-header");
  wrapperDiv.innerHTML += `
    <ion-toolbar>
      <ion-title>
        Header
      </ion-title>
    </ion-toolbar>
    `;
  editor.getSelected().append(wrapperDiv.outerHTML);
}

// IONIC FOOTER
export function createIonicFooter(editor) {
  const wrapperDiv = document.createElement("ion-footer");
  wrapperDiv.innerHTML += `
    <ion-toolbar>
      <ion-title>
        Footer
      </ion-title>
    </ion-toolbar>
    `;
  editor.getSelected().append(wrapperDiv.outerHTML);
}

// IONIC CONTENT
export function createIonicContent(editor) {
  const wrapperDiv = document.createElement("ion-content");
  wrapperDiv.innerHTML += `
    <p>Here's a small text description for the content. Nothing more, nothing less.</p>
    `;
  editor.getSelected().append(wrapperDiv.outerHTML);
}

// IONIC TOGGLE
export function createIonicToggle(editor) {
  const wrapperDiv = document.createElement("span");
  wrapperDiv.innerHTML += `
    <ion-toggle slot="end"></ion-toggle>
    `;
  editor.getSelected().append(wrapperDiv.outerHTML);
}

// IONIC TOAST
export function createIonicToast(editor) {
  const wrapperDiv = document.createElement("div");
  wrapperDiv.innerHTML += `
    <ion-button class='toastBtn' expand="block" onclick="presentToast('top')">Present Toast At the Top</ion-button>

<script>
  async function presentToast(position) {
    const toast = await this.toastController.create({
      message: 'Hello World!',
      duration: 1500,
      position: position
    });

    await toast.present();
  }

  document.querySelector('.toastBtn').addEventListener('click', presentToast);
</script>
    `;
  editor.getSelected().append(wrapperDiv.outerHTML);
}

// IONIC ALERT
export function createIonicAlert(editor) {
  const wrapperDiv = document.createElement("div");
  wrapperDiv.style.display = "flex";
  wrapperDiv.style.alignItems = "center";
  wrapperDiv.style.justifyContent = "center";
  wrapperDiv.style.height = "100%";
  wrapperDiv.style.position = "relative";

  wrapperDiv.innerHTML += `
  <p class="mobAlertBtn">Alert Button<p>
  <div class="confirmAlertWrapper">
  <p>Enter Your Alert Message Here...</p>
  <div class="AlertbuttonWrapper">
    <button class="closeAlert">Close</button>
  </div>
</div>

<style>
.confirmAlertWrapper{
  display:none;
}

.mobAlertBtn{
  padding:0.5rem 1rem;
  border-radius:6px;
  color:white;
  background-color:yellow;
}

.AlertbuttonWrapper{
 display:flex;
 justify-content:end;
 align-items:center;
 marginTop:10rem;
}

.closeAlert{
  padding:0.5rem 1rem;
  border-radius:6px;
  color:white;
  background-color:blue;
}



</style>

<script type='text/javascript'>

function confirmDelete(){
      document.querySelector('.confirmAlertWrapper').style.display = 'none';
      const pTag=document.createElement('p');
      pTag.innerHTML+='Confirm button is clicked'
      wrapperDiv.appendChild(pTag)

    }

    function openModal(){
      const wrapper=document.querySelector('.confirmAlertWrapper');
      wrapper.style.display='block';
      wrapper.style.position = 'absolute';
      wrapper.style.top = '30%';
      wrapper.style.width = '80%';
      wrapper.style.padding = '1rem';
      wrapper.style.backgroundColor = 'white';
      wrapper.style.borderRadius = '6px';
    }

    document.querySelector('.mobAlertBtn').addEventListener('click',openModal)
    document.querySelector('.closeAlert').addEventListener('click',confirmDelete);

  </script>

    `;
  editor.getSelected().append(wrapperDiv.outerHTML);
}

// IONIC SEGMENT
export function createIonicSegment(editor) {
  const wrapperDiv = document.createElement("div");
  wrapperDiv.innerHTML += `
    <ion-segment scrollable="true" value="heart">
    <ion-segment-button value="home">
      <ion-icon name="home"></ion-icon>
    </ion-segment-button>
    <ion-segment-button value="heart">
      <ion-icon name="heart"></ion-icon>
    </ion-segment-button>
    <ion-segment-button value="pin">
      <ion-icon name="pin"></ion-icon>
    </ion-segment-button>
    <ion-segment-button value="star">
      <ion-icon name="star"></ion-icon>
    </ion-segment-button>
    <ion-segment-button value="call">
      <ion-icon name="call"></ion-icon>
    </ion-segment-button>
    <ion-segment-button value="globe">
      <ion-icon name="globe"></ion-icon>
    </ion-segment-button>
    <ion-segment-button value="basket">
      <ion-icon name="basket"></ion-icon>
    </ion-segment-button>
    <ion-segment-button value="barbell">
      <ion-icon name="barbell"></ion-icon>
    </ion-segment-button>
    <ion-segment-button value="trash">
      <ion-icon name="trash"></ion-icon>
    </ion-segment-button>
    <ion-segment-button value="person">
      <ion-icon name="person"></ion-icon>
    </ion-segment-button>
  </ion-segment>
    `;
  editor.getSelected().append(wrapperDiv.outerHTML);
}

// IONIC SELECT
export function createIonicSelect(editor) {
  const wrapperDiv = document.createElement("div");
  wrapperDiv.innerHTML += `
    <ion-list>
  <ion-item>
    <ion-select placeholder="Select fruit">
      <ion-select-option value="apples">Apples</ion-select-option>
      <ion-select-option value="oranges">Oranges</ion-select-option>
      <ion-select-option value="bananas">Bananas</ion-select-option>
    </ion-select>
  </ion-item>
</ion-list>
    `;
  editor.getSelected().append(wrapperDiv.outerHTML);
}

export function createFileUploadButton(editor) {
  console.log("creating upload-file");

  const uniqueId = uniqid("u_f_b_l_");
  console.log("creating unique ID for class:", uniqueId);

  let mainDiv = document.createElement("div");
  mainDiv.setAttribute("class", `FileUploadBtnDiv_${uniqueId}`);

  let labelForDnamicCall = document.createElement("span");
  labelForDnamicCall.setAttribute("id", `apiLabel`);
  labelForDnamicCall.innerHTML += "Name";
  mainDiv.appendChild(labelForDnamicCall);

  let mainLabel = document.createElement("label");
  mainLabel.setAttribute("for", "file_upload_btn_input");
  mainLabel.setAttribute("class", `file_upload_btn_label ${uniqueId}`);
  mainLabel.setAttribute("id", `file_upload_id`);

  mainLabel.style.display = "inline-block";
  mainLabel.style.padding = "0.5em";
  mainLabel.style.border = "1px solid black";
  mainLabel.style.borderRadius = "10px";
  mainLabel.style.cursor = "pointer";

  mainLabel.innerHTML += "Upload a file";

  // mainLabel.ondlbclick = function(event) {
  //     let inputFile = document.createElement('input');
  //     inputFile.type = 'file';
  //     inputFile.onchange = function(event) {
  //         let file = inputFile.files[0];
  //         let reader = new FileReader();
  //         reader.readAsDataURL(file);
  //         reader.onloadend = () => {
  //             alert(reader.result);
  //         };
  //     };
  //     mainDiv?.appendChild(inputFile);
  //     inputFile.click();
  // };

  mainDiv.appendChild(mainLabel);

  let input = document.createElement("input");
  input.setAttribute("type", "file");
  input.setAttribute("id", "file_upload_btn_input");
  input.setAttribute("name", "file_upload_btn_input");
  input.setAttribute("class", "file_upload_btn_input");

  input.style.display = "none";

  mainDiv.appendChild(input);

  const scripting = document.createElement("script");
  scripting.setAttribute("type", "text/javascript");

  scripting.innerHTML = `
      let label = document.querySelector('.file_upload_btn_label.${uniqueId}');
      let label1 = document.querySelector('#apiLabel');
      label.addEventListener('dblclick', (event) => {
          let container = document.querySelector('.FileUploadBtnDiv_${uniqueId}');
          let inputFile = document.createElement('input');
          inputFile.type = 'file';
          inputFile.addEventListener('change', async (event) => {
                let file = inputFile.files[0];

                console.log('file is:', file);
              
                let formData = new FormData();
                formData.append('file', file);

                try {
                    const response = await fetch('${API_HOST}uploadRoutes/upload', {
                        method: 'POST',
                        headers: {
                            // 'Content-Type': 'multipart/form-data',
                        },
                        body: formData,
                    });

                    if (response.ok) {
                        const data = await response.json();
                        label.setAttribute('data_file_url', data.fileLocation);
                        console.log('File uploaded:', data);
                    } else {
                        console.error('Error uploading file:', response.statusText);
                    }
                } catch (error) {
                    console.error('Error uploading file:', error);
                }
          });
          container?.appendChild(inputFile);
          inputFile.click();
      });
  `;

  mainDiv.appendChild(scripting);

  editor.getSelected().append(mainDiv.outerHTML);
  console.log("upload-file appended");
}
