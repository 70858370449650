import React from 'react';

import classes from './LogicBuilder.module.css';

const EmailPanel = () => {
	return (
		<div className={classes.email_panel}>
			<h3 style={{color: '#B6B5B5'}}>Email</h3>
			<form className={classes.form}>
				<div className={classes.inner_div}>
					<label htmlFor="email-to" className={classes.label}>To</label>
					<input type="text" id="email-to" name="email-to" className={classes.input} />
				</div>
				<div className={classes.inner_div}>
					<label htmlFor="email-cc" className={classes.label}>CC</label>
					<input type="text" id="email-cc" name="email-cc" className={classes.input} />
				</div>
				<div className={classes.inner_div}>
					<label htmlFor="email-bcc" className={classes.label}>BCC</label>
					<input type="text" id="email-bcc" name="email-bcc" className={classes.input} />
				</div>
				<div className={classes.inner_div}>
					<label htmlFor="email-subject" className={classes.label}>Sub</label>
					<input type="text" id="email-subject" name="email-subject" className={classes.input} />
				</div>
				<div className={classes.inner_div}>
					<label htmlFor="email-body" className={classes.label}>Body</label>
					<textarea className={classes.textarea} id="email-body" name="email-body" rows="5" cols="15"></textarea>
				</div>
			</form>
		</div>
	);
};

export default EmailPanel;