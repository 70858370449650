export const ADD_NEW_PROJECT_LOADING = 'ADD_NEW_PROJECT_LOADING'
export const ADD_NEW_PROJECT_SUCCESS = 'ADD_NEW_PROJECT_SUCCESS'
export const ADD_NEW_PROJECT_FAILED = 'ADD_NEW_PROJECT_FAILED'
export const ADD_NEW_PROJECT_SUCCESS_RESET = 'ADD_NEW_PROJECT_SUCCESS_RESET'

export const GET_ALL_PROJECT_BY_USER_LOADING = "GET_ALL_PROJECT_BY_USER_LOADING"
export const GET_ALL_PROJECT_BY_USER_SUCCESS = "GET_ALL_PROJECT_BY_USER_SUCCESS"
export const GET_ALL_PROJECT_BY_USER_FAILED = "GET_ALL_PROJECT_BY_USER_FAILED"
export const GET_ALL_PROJECT_BY_USER_EMPTY = "GET_ALL_PROJECT_BY_USER_EMPTY"
export const RESET_ALL_PROJECT_BY_USER = "RESET_ALL_PROJECT_BY_USER"



export const UPDATE_PROJECT_LOADING = "UPDATE_PROJECT_LOADING"
export const UPDATE_PROJECT_SUCCESS = "UPDATE_PROJECT_SUCCESS"
export const UPDATE_PROJECT_FAILED = "UPDATE_PROJECT_FAILED"
export const UPDATE_PROJECT_SUCCESS_RESET = 'UPDATE_PROJECT_SUCCESS_RESET'

export const DELETE_PROJECT_LOADING = "DELETE_PROJECT_LOADING"
export const DELETE_PROJECT_SUCCESS = "DELETE_PROJECT_SUCCESS"
export const DELETE_PROJECT_FAILED = "DELETE_PROJECT_FAILED"
export const DELETE_PROJECT_RESET = "DELETE_PROJECT_RESET"
