import { useState } from 'react'
import { useDrop } from 'react-dnd'
import { ItemTypes } from '../LogicBuilder4/ItemTypes.js'
function getStyle(backgroundColor) {
  return {
    border: '1px solid rgba(0,0,0,0.2)',
    minHeight: '2rem',
    minWidth: '8rem',
    color: 'white',
    backgroundColor: 'red',
    padding: '2rem',
    paddingTop: '1rem',
 //   margin: '1rem',
    textAlign: 'center',
    // float: 'left',
    fontSize: '1rem',
  }
}


function getStyle2(backgroundColor) {
  return {
    border: '1px solid rgba(0,0,0,0.2)',
    minHeight: '2rem',
    minWidth: '8rem',
    color: 'white',
    backgroundColor: 'green',
    padding: '2rem',
    paddingTop: '1rem',
 //   margin: '1rem',
    textAlign: 'center',
    // float: 'left',
    fontSize: '1rem',
  }
}

function isMathOperator(str) {
  // list of mathematical operators
  const operators = ['+', '-', '*', '/', '^', '%'];
  return operators.includes(str);
}

export const NewCanvas3 = ({ greedy, children, name, prevItem, setParametersDropped, setConditionDropped, setExecutionItemsDropped }) => {
  const [hasDropped, setHasDropped] = useState(false)
  const [hasDroppedOnChild, setHasDroppedOnChild] = useState(false)
  const [items, addItems] = useState([])
  const [textValue, setTextValue] = useState("")
  const [{ isOver, isOverCurrent }, drop] = useDrop(
    () => ({
      accept: ["parameters", "conditional", "logicalOperator"],
    //   accept: ['blocks'],
      drop: (_item, monitor) => {
        const didDrop = monitor.didDrop()
        // console.log("name - ", name)
        // console.log("Greeedy ", greedy)
        // console.log("Did drop  ", didDrop)
        // console.log("DROP RESULT ", _item)
        if (didDrop && !greedy) {
            // console.log("not greedy")
            // console.log("has Dropped ", hasDropped)
            // console.log("has Dropped on Children ", hasDroppedOnChild)
            return
        }
        // console.log("Setting has dropped to true")
        setHasDropped(true)
        // console.log("Setting has dropped on child to true")
        setHasDroppedOnChild(didDrop)
        // if(_item.name !== "Text box") {
            if(items.length > 0) {
                
                addItems(prevState => {
                        // console.log("Previtems ---- ", prevState)
                        let initItems = [...prevState]
                        if(setParametersDropped) {
                            // setConditionDropped(prevItem, _item.name)
                            setParametersDropped(prevItem, [...initItems, _item.name])
                        } else if(setExecutionItemsDropped && _item.name!=="Text box") {
                            console.log("Init items ==== ", initItems)
                            console.log("_ITem.name ==== ", _item.name)
                            console.log("Type of var ====== ", _item.typeOfVar)
                            if(_item.typeOfVar) 
                              setExecutionItemsDropped(prevItem,  _item.name, false, true)
                            else 
                              setExecutionItemsDropped(prevItem,  _item.name, false, false)
                        }
                        return [...initItems, _item.name]
                    }
                )
            } else {
                // console.log("Zerroooo")
                addItems([_item.name])
                console.log("item === ", prevItem)
                if(setParametersDropped) {
                    setParametersDropped(prevItem, [_item.name])
                    // setConditionDropped(prevItem)
                } else if(setExecutionItemsDropped && _item.name!=="Text box") {
                    console.log("INsdie set execution drop")
                    setExecutionItemsDropped(prevItem, [_item.name])
                }
                
            }
        // } else {

        // }
        
      },
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        // didDrop: monitor.didDrop(),
        isOverCurrent: monitor.isOver({ shallow: true }),
      }),
    }),
    [greedy, hasDropped, hasDroppedOnChild, items.length],
  )
  const text = greedy ? 'greedy' : 'not greedy'
  let backgroundColor = 'rgba(0, 0, 0, .5)'
  if (isOverCurrent || (isOver && greedy)) {
    backgroundColor = 'darkgreen'
  }

  const setValue = (val) => {
    console.log('New Canvas3 set value ', val)
    if(setExecutionItemsDropped) {
        if(items.length > 0) {
            let initItems = [...items]
            let newItems = initItems.filter(e => e!== "Text box")
            setExecutionItemsDropped(prevItem, parseFloat(val))
        }
    }
  }

  const undo = () => {
        addItems(prevItems => {
            let poppedItem = prevItems.pop();
            return prevItems;
        })
  }
//   console.log("HAS Dropped on CHiled == ", hasDroppedOnChild)
  return (
    <div ref={drop} style={getStyle(backgroundColor)}>
      {/* text */}
      
      {items.length > 0 ? (
        items.map((item, i) => {
          console.log("IETEMM S === ", item)
            if(item === "Text box") {
                return (
                    <div key={i}> 
                        <input type="number" value={textValue} 
                            onChange={(e) => setTextValue(e.target.value)} 
                            onBlur={(e) => setValue(e.target.value)}    
                        /> </div>
                )
            } else {
                return (
                    <div key={i}>{item} </div>
                )
            }
        })) : ""
    }
      <br />
      {/* 
        Mainly used if you want to show that item has been dropped in a child. 
        This for the first one in the documentation */}
      {/* hasDropped && <p>dropped {hasDroppedOnChild && ' on child'}</p> */}

      <div>{children}</div>
    </div>
  )
}
