import React, { useEffect, useRef, useState } from 'react';
import grapesjs from 'grapesjs';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
// mockup images:
import lappy from '../assets/lappyPrev.png'
import redSlingLogo from '../Screens/component_assets/images/redSlingLogo.png'
import { Button, Divider, Grid, Modal, Typography } from '@mui/material'
import { API_HOST } from '../api_utils';
import { deviceManager, layerManager, panels, selectorManager, storageManager, styleManager, traitManager, parserCss } from '../api_utils/geditor_utils';

function PageInModal(props) {
  const { id } = useParams();
  const { userInfo } = useSelector(state => state.userLogin);

  const editorRef = useRef(null);


  useEffect(() => {
    // initializing editor
    async function initializeEditor() {

        const editor = grapesjs.init({
            container: editorRef.current,

            width: '100%',

            allowScripts: 1,

            modal: {
                title: 'Initialize',
                content: '',
                backdrop: false,
            },

            assetManager: {
                // Upload endpoint, set `false` to disable upload, default `false`
                assets: [
                    'http://placehold.it/350x250/78c5d6/fff/image1.jpg',
                    // Pass an object with your properties
                    {
                        type: 'image',
                        src: 'http://placehold.it/350x250/459ba8/fff/image2.jpg',
                        height: 350,
                        width: 250,
                        name: 'displayName'
                    },
                    {
                        // As the 'image' is the base type of assets, omitting it will
                        // be set as `image` by default
                        src: 'http://placehold.it/350x250/79c267/fff/image3.jpg',
                        height: 350,
                        width: 250,
                        name: 'displayName'
                    },
                ],
                multiUpload: true,
            },



            storageManager: {
                type: 'remote',

                autoload: true,

                contentTypeJson: true,
                storeComponents: true,
                storeStyles: true,
                storeHtml: true,
                storeCss: true,
                id: 'my-',
                urlLoad: `${API_HOST}pages/${props.selectedPage}/content`,
                headers: {
                    'Content-Type': 'application/json',
                },

            },

            parser: {
                parserCss,
            },

            styleManager: styleManager,

            layerManager: layerManager,

            traitManager: traitManager,

            selectorManager: selectorManager,

            panels: panels,

            deviceManager: deviceManager,

            canvas: {
                styles: ["https://unpkg.com/tailwindcss@^1.0/dist/tailwind.min.css",
                    "https://unpkg.com/swiper@8/swiper-bundle.min.css",
                    "https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/css/bootstrap.min.css",
                    'https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.2/font/bootstrap-icons.css',
                    'https://cdn.jsdelivr.net/npm/@ionic/core/css/ionic.bundle.css',
                    'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.2.1/css/all.min.css',
                    "/styles/main.scss",
                    "https://fonts.googleapis.com/css2?family=Roboto:wght@300",
                    "https://cdn.jsdelivr.net/npm/gridjs/dist/theme/mermaid.min.css",
                    // "/Frutiger-LT-Com-45-Light.ttf",
                    // "/styles/Frutiger-LT-Com-45-Light.ttf",
                ],
                scripts: [
                    "https://cdn.jsdelivr.net/npm/@popperjs/core@2.10.2/dist/umd/popper.min.js",
                    "https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/js/bootstrap.min.js",
                    "https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/js/bootstrap.bundle.min.js",
                    'https://cdn.jsdelivr.net/npm/@ionic/core/dist/ionic/ionic.js',
                    'https://cdn.jsdelivr.net/npm/@ionic/core/dist/ionic/ionic.esm.js',
                    "https://unpkg.com/swiper@8/swiper-bundle.min.js",
                    "https://www.jsdelivr.com/package/npm/chart.js?path=dist",
                    "/bootstrap/js/bootstrap.bundle.min.js",
                    // "plotly-2.20.0.min.js",
                    "http://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit",
                    "https://cdn.jsdelivr.net/npm/gridjs/dist/gridjs.umd.js",
                    // "../newTest.js",
                ],
            }
        });




        // [Bug] fix reference: https://github.com/GrapesJS/grapesjs/issues/2834
        const wrapper = editor.getWrapper();
        wrapper.set('attributes', { id: 'grapes-iframe-body' });

        // Get the components from the editor
        editor.DomComponents.addType('bs-image', {
            isComponent: el => el.getAttribute('data-gjs-type') === 'bs-image',
            model: {
                defaults: {
                    // Default attributes
                    tagName: 'img',
                    // Add any other default attributes you need for the <img> tag
                },
            },
        });

        editor.load();

    }

    initializeEditor();
}, [])

  return (
    <>
      <Grid sx={{ display: "flex", justifyContent: "flex-start", alignItems: 'center', width: '100vw', boxShadow: 6, padding: { md: '1vh 2vw', xs: '1vh 4vw', position: 'fixed', top: '0', zIndex: 30, backgroundColor: 'white' } }}>
        <Typography sx={{ cursor: 'pointer', borderRight: '1px solid #27272a' }} ><img src={redSlingLogo} /></Typography>

        <Typography sx={{ color: '#27272a', fontSize: '1.5rem', fontWeight: 600, marginTop: '1.2rem', marginLeft: '0.5rem' }} >Desktop Preview</Typography>

      </Grid>

      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '5rem', backgroundColor: '#fef2f2' }}>
        <div ref={editorRef} />
      </div>

    </>
  )
}

export default PageInModal;