// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AllCustomModels_tableNameModal__ASwHM {
	width: 35vw;
	height: 20vh;
	background-color: white;
	border-radius: 10px;
	border: 1px solid gray;
	padding-left: 5%;
	z-index: 9999;

	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	row-gap: 1em;

	position: absolute;
	top: 30%;
	left: 30%;
}

.AllCustomModels_tableNameModalInput__lSs2h {
	padding: 0.5em;
	border-radius: 10px;
}

.AllCustomModels_tableNameModalSubmit__3kwUC {
	background-color: red;
	color: white;
	border: none;
	cursor: pointer;
	border-radius: 5px;
	padding: 0.5em 1em;
	box-shadow: 0 2px 5px 0 gray;
	transition: all 0.5s;
}

.AllCustomModels_tableNameModalSubmit__3kwUC:hover {
	background-color: white;
	color: red;
}`, "",{"version":3,"sources":["webpack://./src/Screens/ScreensStylings/AllCustomModels.module.css"],"names":[],"mappings":"AAAA;CACC,WAAW;CACX,YAAY;CACZ,uBAAuB;CACvB,mBAAmB;CACnB,sBAAsB;CACtB,gBAAgB;CAChB,aAAa;;CAEb,aAAa;CACb,sBAAsB;CACtB,uBAAuB;CACvB,uBAAuB;CACvB,YAAY;;CAEZ,kBAAkB;CAClB,QAAQ;CACR,SAAS;AACV;;AAEA;CACC,cAAc;CACd,mBAAmB;AACpB;;AAEA;CACC,qBAAqB;CACrB,YAAY;CACZ,YAAY;CACZ,eAAe;CACf,kBAAkB;CAClB,kBAAkB;CAClB,4BAA4B;CAC5B,oBAAoB;AACrB;;AAEA;CACC,uBAAuB;CACvB,UAAU;AACX","sourcesContent":[".tableNameModal {\n\twidth: 35vw;\n\theight: 20vh;\n\tbackground-color: white;\n\tborder-radius: 10px;\n\tborder: 1px solid gray;\n\tpadding-left: 5%;\n\tz-index: 9999;\n\n\tdisplay: flex;\n\tflex-direction: column;\n\talign-items: flex-start;\n\tjustify-content: center;\n\trow-gap: 1em;\n\n\tposition: absolute;\n\ttop: 30%;\n\tleft: 30%;\n}\n\n.tableNameModalInput {\n\tpadding: 0.5em;\n\tborder-radius: 10px;\n}\n\n.tableNameModalSubmit {\n\tbackground-color: red;\n\tcolor: white;\n\tborder: none;\n\tcursor: pointer;\n\tborder-radius: 5px;\n\tpadding: 0.5em 1em;\n\tbox-shadow: 0 2px 5px 0 gray;\n\ttransition: all 0.5s;\n}\n\n.tableNameModalSubmit:hover {\n\tbackground-color: white;\n\tcolor: red;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tableNameModal": `AllCustomModels_tableNameModal__ASwHM`,
	"tableNameModalInput": `AllCustomModels_tableNameModalInput__lSs2h`,
	"tableNameModalSubmit": `AllCustomModels_tableNameModalSubmit__3kwUC`
};
export default ___CSS_LOADER_EXPORT___;
