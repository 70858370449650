import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { registerUser } from '../Redux/Actions/userActions'
import './style.css'
import redSlingLogo from './component_assets/images/details_close.png'
// import redSlingLogo from './component_assets/images/redSlingLogo.png'
// import darkBubble from './component_assets/images/darkBubble.png'
import darkBubble from './component_assets/images/details_close.png'
import lightBubble from './component_assets/images/details_close.png'
// import lightBubble from './component_assets/images/lightBubble.png'
import { useNavigate } from 'react-router-dom'
import { toast, ToastContainer } from "react-toastify";
import { Button, Paper, TextField } from '@mui/material'
import { API_HOST } from '../api_utils'
import axios from 'axios'


function SignUp() {

    let navigate = useNavigate()
    let dispatch = useDispatch()
    const [email, setEmail] = useState('')
    const [name, setName] = useState('')
    const [pass, setPass] = useState('')
    const [repass, setRepass] = useState('')
    const [company, setCompany] = useState('')
    const { userInfo, loading, error } = useSelector(state => state.userLogin);

    const signUpHandler = async (e) => {
        e.preventDefault()
        if (email.includes('@') && pass.length && pass === repass && name.length && company.length) {
            const userData = {
                name: name,
                email: email,
                password: pass,
                company: company

            }

            const config = {
                headers: {
                    "Content-Type": "application/json"
                }
            }
            
            const data = await axios.post(`${API_HOST}user`, userData, config);
            toast.success("application sent !!!", errorTheme)
        } else if (!email.includes('@') && pass.length && pass === repass && name.length && company.length) {
            toast.error("email must include '@'", errorTheme)
        } else if (pass !== repass) {
            toast.error("passwords do not match", errorTheme)
        } else if (!name.length || !email.length || !pass.length || !repass.length || !company.length) {
            toast.error("fields can't be empty !!!", errorTheme)
        }

        setName('')
        setPass('')
        setEmail('')
        setRepass('')
        setCompany('')

    }

    const errorTheme = {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
    }

    const userInfoFromStorage = JSON.parse(localStorage.getItem('userInfo'))

    useEffect(() => {

        if (userInfoFromStorage?.token) {
            navigate('/')
        }
    }, [userInfoFromStorage])

    return (
        <div className="login-body">

            <div className="wrapperLogin">
                <nav>
                    <ul>
                        <li><a href="#" style={{ color: 'black', textDecoration: 'none' }} target="_blank">About</a></li>
                        <li><a href="#" style={{ color: 'black', textDecoration: 'none' }} target="_blank">Pricing</a></li>
                        <a href="#"><img src={redSlingLogo} /></a>
                        <li><a href="#" style={{ color: 'black', textDecoration: 'none' }} target="_blank">Training</a></li>
                        <li><a href="#modal-btn" style={{ color: 'black', textDecoration: 'none' }}>Contact Us</a></li>
                    </ul>
                </nav>
            </div>


            <a href="#"><img src={redSlingLogo} /></a>
            <div className="bi bi-list other-hamburger-menu">
                <ul>
                    <li><a href="#" target="_blank">About</a></li>
                    <li><a href="#" target="_blank">Pricing</a></li>
                    <li><a href="#" target="_blank">Training</a></li>
                    <li><a href="#modal-btn">Contact</a></li>
                </ul>
            </div>


            <img className="login-d-bb" src={darkBubble} />


            <img className="login-l-bb" src={lightBubble} />


            <div >
                <form style={{ marginTop: '7%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Paper elevation={4} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', padding: '1vh 2vw' }}>
                        <p className="header-text-signup" >Sign-Up</p>
                        {/* <input type="text" onChange={e => setName(e.target.value)} className="emailInput" value={name} placeholder="Name" />
                    <input type="email" onChange={e => setEmail(e.target.value)} className="emailInput" value={email} placeholder="Email" />
                    <input type="password" onChange={(e) => setPass(e.target.value)} value={pass} placeholder="Password" className="emailInput" />
                    <input
                        type="password"
                        className="emailInput"
                        placeholder="Re-Confirm Password"
                        value={repass}
                        onChange={e => setRepass(e.target.value)}
                    /> */}
                        <TextField onChange={(e) => setName(e.target.value)} id="outlined-basic" label="Name" variant="outlined" sx={{ width: "36vw" }} value={name} type="text" />
                        <TextField onChange={(e) => setEmail(e.target.value)} id="outlined-basic" label="Email" variant="outlined" sx={{ width: "36vw", mt: 2 }} value={email} type="email" />
                        <TextField onChange={(e) => setPass(e.target.value)} id="outlined-basic" label="password" variant="outlined" sx={{ width: "36vw", mt: 2 }} value={pass} type="password" />
                        <TextField onChange={(e) => setRepass(e.target.value)} id="outlined-basic" label="confirm Password" variant="outlined" sx={{ width: "36vw", mt: 2 }} value={repass} type="password" />
                        <TextField onChange={(e) => setCompany(e.target.value)} id="outlined-basic" label="Organisation Name" variant="outlined" sx={{ width: "36vw", mt: 2 }} value={company} type="text" />
                        <Button variant='contained' sx={{ width: '36vw', height: '7vh', backgroundColor: 'red', mt: 4, fontFamily: 'Mont-Regular', fontSize: '1.2rem', letterSpacing: '0.2rem', '&:hover': { backgroundColor: 'white', color: 'red' }, transitionDuration: '0.6s' }} onClick={signUpHandler} type="submit">Next</Button>
                        <p style={{ marginTop: '3%', fontFamily: 'Mont-Regular', fontSize: '1rem' }}>
                            Already have an account? <a href="/login" style={{ color: 'red', textDecoration: 'none' }}>Login</a>
                        </p>
                    </Paper>
                </form>
            </div>
            <ToastContainer autoClose={2000} />
        </div>
    )
}

export default SignUp