const customCardPlugin = (editor, opts = {}) => {
  editor.DomComponents.addType("customCardElementWrapper", {
    model: {
      defaults: {
        components: [
          {
            tagName: "ion-card",
            type: "default",
            style: {
              position: "relative",
              "z-index": "2",
              width: "33.33%",
              padding: "0.5rem",
              "box-shadow":
                "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
            },
            components: [
              {
                type: "image",
                tagName: "img",
                attributes: {
                  src: "https://ionicframework.com/docs/img/demos/card-media.png",
                  alt: "Silhouette of mountains",
                },
                style: {
                  width: "100%",
                  height: "350px",
                },
              },
              {
                type: "default",
                tagName: "div",
                style: {
                  "gap-y": "10px",
                  "padding-top": "5px",
                  "padding-bottom": "5px",
                },
                components: [
                  {
                    type: "text",
                    content: `Card Title`,
                    style: {
                      "font-size": "18px",
                      "font-weight": "600",
                      width: "100%",
                    },
                  },
                  {
                    type: "text",
                    content: `Card Sub-Title`,
                    style: {
                      "font-size": "12px",
                      "font-weight": "400",
                      width: "100%",
                    },
                  },
                  {
                    type: "text",
                    content: `Card Desciption goes here .....`,
                    style: {
                      "font-size": "12px",
                      "font-weight": "400",
                      width: "100%",
                    },
                  },
                ],
              },
            ],
          },
          {
            tagName: "ion-card",
            type: "default",
            style: {
              position: "relative",
              "z-index": "2",
              width: "33.33%",
              padding: "0.5rem",
              "box-shadow":
                "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
            },
            components: [
              {
                type: "image",
                tagName: "img",
                attributes: {
                  src: "https://ionicframework.com/docs/img/demos/card-media.png",
                  alt: "Silhouette of mountains",
                },
                style: {
                  width: "100%",
                  height: "350px",
                },
              },
              {
                type: "default",
                tagName: "div",
                style: {
                  "gap-y": "10px",
                  "padding-top": "5px",
                  "padding-bottom": "5px",
                },
                components: [
                  {
                    type: "text",
                    content: `Card Title`,
                    style: {
                      "font-size": "18px",
                      "font-weight": "600",
                      width: "100%",
                    },
                  },
                  {
                    type: "text",
                    content: `Card Sub-Title`,
                    style: {
                      "font-size": "12px",
                      "font-weight": "400",
                      width: "100%",
                    },
                  },
                  {
                    type: "text",
                    content: `Card Desciption goes here .....`,
                    style: {
                      "font-size": "12px",
                      "font-weight": "400",
                      width: "100%",
                    },
                  },
                ],
              },
            ],
          },
          {
            tagName: "ion-card",
            type: "default",
            style: {
              position: "relative",
              "z-index": "2",
              width: "33.33%",
              padding: "0.5rem",
              "box-shadow":
                "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
            },
            components: [
              {
                type: "image",
                tagName: "img",
                attributes: {
                  src: "https://ionicframework.com/docs/img/demos/card-media.png",
                  alt: "Silhouette of mountains",
                },
                style: {
                  width: "100%",
                  height: "350px",
                },
              },
              {
                type: "default",
                tagName: "div",
                style: {
                  "gap-y": "10px",
                  "padding-top": "5px",
                  "padding-bottom": "5px",
                },
                components: [
                  {
                    type: "text",
                    content: `Card Title`,
                    style: {
                      "font-size": "18px",
                      "font-weight": "600",
                      width: "100%",
                    },
                  },
                  {
                    type: "text",
                    content: `Card Sub-Title`,
                    style: {
                      "font-size": "12px",
                      "font-weight": "400",
                      width: "100%",
                    },
                  },
                  {
                    type: "text",
                    content: `Card Desciption goes here .....`,
                    style: {
                      "font-size": "12px",
                      "font-weight": "400",
                      width: "100%",
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
    },
  });

  editor.DomComponents.addType("customCardParent", {
    model: {
      defaults: {
        components: [
          {
            tagName: "div",
            type: "default",
            style: {
              width: "100%",
              padding: "0.8rem 0.5rem",
              "margin-bottom": "2rem",
            },
            attributes: { name: "customCardProgressBar" },
            components: [
              {
                tagName: "div",
                type: "default",
                style: {
                  width: "100%",
                  height: "10px",
                  position: "relative",
                },
                components: [
                  {
                    tagName: "p",
                    type: "default",
                    style: {
                      width: "10%",
                      "background-color": "#06b6d4",
                      "border-radius": "12px",
                      "z-index": "3",
                      height: "10px",
                      position: "relative",
                    },
                  },
                  {
                    tagName: "p",
                    type: "default",
                    style: {
                      position: "absolute",
                      top: "0",
                      right: "10px",
                      "font-size": "10px",
                      width: "100%",
                      display: "flex",
                      "justify-content": "center",
                      "align-items": "center",
                      height: "100%",
                      "z-index": "4",
                    },
                    content: `10%`,
                  },
                ],
              },
            ],
          },
          {
            type: "customCardElementWrapper",
            tagName: "div",
            style: {
              position: "relative",
              "z-index": "2",
              "min-width": "max-content",
              display: "flex",
              "gap-x": "8px",
              "justify-content": "center",
              "align-items": "center",
              overflow: "hidden",
            },
          },
          {
            tagName: "div",
            type: "default",
            style: {
              width: "40px",
              height: "40px",
              position: "absolute",
              top: "50%",
              left: "10px",
              "z-index": "3",
              "box-shadow": "1px 1px 10px 1px rgba(0,0,0,0.75)",
            },
            attributes: {
              name: "sliderPrevActionButton",
            },
            components: [
              {
                type: "svg",
                tagName: "svg",
                attributes: {
                  xmlns: "http://www.w3.org/2000/svg",
                  width: "38",
                  height: "38",
                  name: "prevSlideCardCarousel",
                  "view-box": "0 0 16 16",
                  fill: "currentColor",
                },
                style: {
                  color: "#0ea5e9",
                },
                content: ` <g transform="scale(2)"> 
              <path d="m3.86 8.753 5.482 4.796c.646.566 1.658.106 1.658-.753V3.204a1 1 0 0 0-1.659-.753l-5.48 4.796a1 1 0 0 0 0 1.506z" />
           </g>`,
              },
            ],
          },
          {
            tagName: "div",
            type: "default",
            style: {
              width: "40px",
              height: "40px",
              position: "absolute",
              top: "50%",
              right: "10px",
              "z-index": "3",
              "box-shadow": "1px 1px 10px 1px rgba(0,0,0,0.75)",
            },
            attributes: {
              name: "sliderNextActionButton",
            },
            components: [
              {
                type: "svg",
                tagName: "svg",
                attributes: {
                  xmlns: "http://www.w3.org/2000/svg",
                  width: "38",
                  height: "38",
                  name: "nextSlideCardCarousel",
                  "view-box": "0 0 36 36",
                  fill: "currentColor",
                },
                style: {
                  color: "#0ea5e9",
                },
                content: `<g transform="scale(2)"> 
              <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
           </g>  `,
              },
            ],
          },
        ],
      },
    },
  });

  editor.DomComponents.addType("customCardWrapper", {
    model: {
      defaults: {
        traits: [
          {
            type: "number",
            label: "Items per Slide",
            name: "itemsPerSlide",
            changeProp: true,
          },
          {
            type: "text",
            label: "URL",
            name: "url",
            changeProp: true,
          },
          {
            type: "text",
            label: "image from response",
            name: "imageFromResponse",
            changeProp: true,
          },
          {
            type: "text",
            label: "Title from response",
            name: "titleFromResponse",
            changeProp: true,
          },
          {
            type: "text",
            label: "Sub-Title from response",
            name: "subTitleFromResponse",
            changeProp: true,
          },
          {
            type: "text",
            label: "Desc from response",
            name: "descFromResponse",
            changeProp: true,
          },

          {
            type: "text",
            name: "Username",
            label: "Username",
            changeProp: true,
          },
          {
            type: "text",
            name: "Password",
            label: "Password",
            changeProp: true,
          },
          {
            type: "text",
            name: "API_Key",
            label: "API Key",
            changeProp: true,
          },
          {
            type: "text",
            name: "More_Headers",
            label: "More Headers",
            changeProp: true,
          },
          {
            type: "text",
            name: "TargetKey",
            label: "Key To Plot",
            changeProp: true,
          },
          {
            type: "number",
            label: "Page Size",
            name: "pageSize",
            changeProp: true,
          },
        ],

        "script-props": [
          "url",
          "Username",
          "Password",
          "API_Key",
          "More_Headers",
          "TargetKey",
          "itemsPerSlide",
          "imageFromResponse",
          "titleFromResponse",
          "subTitleFromResponse",
          "descFromResponse",
        ],
        components: [
          // TOP PANEL GOES HERE :::::::::::::
          {
            type: "customCardParent",
            tagName: "div",
            style: {
              width: "100%",
              position: "relative",
              "overflow-x": "hidden",
            },
          },
        ],
      },
    },
    view: {
      async onRender({ el, model }) {
        console.log("model", model);
        let url = model.get("url");
        console.log("url", url);
        console.log("el", el);
      },
    },
  });

  editor.BlockManager.add("customCardWrapper", {
    label: "Card Carousel",
    content: { type: "customCardWrapper" },
    category: "Basic Card",
    media: `<svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" viewBox="0 0 16 16">
            <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm15 2h-4v3h4V4zm0 4h-4v3h4V8zm0 4h-4v3h3a1 1 0 0 0 1-1v-2zm-5 3v-3H6v3h4zm-5 0v-3H1v2a1 1 0 0 0 1 1h3zm-4-4h4V8H1v3zm0-4h4V4H1v3zm5-3v3h4V4H6zm4 4H6v3h4V8z"/>
        </svg>`,
    select: true,
    draggable: true,
  });

  // Function to handle "Next" button click
  function handleNextButtonClick(component, responseData) {
    // const cardsPerPage = component.getTrait("itemsPerSlide");
    const cardsPerPage1 = component.getTrait("itemsPerSlide");
    const cardsPerPage = cardsPerPage1.attributes.value;

    let currentPage = JSON.parse(
      localStorage.getItem("cardCarouselSliderCount")
    );
    console.log("current page::::::", currentPage);

    // Calculate the start and end index for the next page
    const startIndex = Math.max(currentPage + 1, 0) * Number(cardsPerPage);
    const endIndex = Number(startIndex) + Number(cardsPerPage);

    let updatedCount;

    if (Number(currentPage) === 0) {
      updatedCount = Number(currentPage) + 2;
    } else {
      updatedCount = Number(currentPage) + 2;
    }

    // Update the displayed cards
    updateDisplayedCards(
      startIndex,
      endIndex,
      component,
      responseData,
      updatedCount,
      cardsPerPage
    );

    localStorage.setItem(
      "cardCarouselSliderCount",
      JSON.stringify(currentPage + 1)
    );
  }

  // Function to handle "Previous" button click
  function handlePrevButtonClick(component, responseData) {
    const cardsPerPage1 = component.getTrait("itemsPerSlide");
    const cardsPerPage = cardsPerPage1.attributes.value;

    let currentPage = JSON.parse(
      localStorage.getItem("cardCarouselSliderCount")
    );
    // console.log("currentPage", currentPage);

    // Calculate the start and end index for the previous page
    const startIndex =
      Math.max(Number(currentPage) - 1, 0) * Number(cardsPerPage);
    const endIndex = Number(startIndex) + Number(cardsPerPage);

    let updatedCount;

    if (Number(currentPage) === 0) {
      updatedCount = Number(currentPage) + 1;
    } else {
      updatedCount = Number(currentPage) - 1;
    }

    // Update the displayed cards
    updateDisplayedCards(
      startIndex,
      endIndex,
      component,
      responseData,
      updatedCount,
      cardsPerPage
    );

    if (currentPage !== 0) {
      localStorage.setItem(
        "cardCarouselSliderCount",
        JSON.stringify(currentPage - 1)
      );
    } else {
      localStorage.setItem("cardCarouselSliderCount", JSON.stringify(0));
    }
  }

  // Function to update the displayed cards based on the startIndex and endIndex
  function updateDisplayedCards(
    startIndex,
    endIndex,
    component,
    responseData,
    currentPage,
    cardsPerPage
  ) {
    // Remove all existing components from the wrapper

    let cardElementWrapper = component.findType("customCardElementWrapper")[0];

    let progressbar = component
      .components()
      .models[0].components()
      .models.find(
        (x) => x.attributes.attributes.name === "customCardProgressBar"
      );

    let percentProgress =
      ((Number(cardsPerPage) * Number(currentPage)) /
        Number(responseData.length)) *
      100;

    if (progressbar) {
      progressbar.components().reset();
      progressbar.components().add({
        tagName: "div",
        style: {
          width: "100%",
          height: "10px",
          position: "relative",
        },
        components: [
          {
            tagName: "p",
            style: {
              width: `${percentProgress}%`,
              "background-color": "#06b6d4",
              "border-radius": "12px",
              "z-index": "3",
              height: "10px",
              position: "relative",
            },
          },
          {
            tagName: "p",
            style: {
              position: "absolute",
              top: "0",
              right: "10px",
              "font-size": "10px",
              width: "100%",
              display: "flex",
              "justify-content": "center",
              "align-items": "center",
              height: "100%",
              "z-index": "4",
            },
            content: `${percentProgress}%`,
          },
        ],
      });
    }

    // console.log("progressBar::::::", progressBar);

    cardElementWrapper.components().reset();

    const itemsPerPageTrait = component.getTrait("itemsPerSlide");
    const itemsTarget = itemsPerPageTrait.attributes.value;

    const cardTitleFromResponse = component.getTrait("titleFromResponse");
    const titleTarget = cardTitleFromResponse.attributes.value;

    const cardSubTitleFromResponse = component.getTrait("subTitleFromResponse");
    const subTitleTarget = cardSubTitleFromResponse.attributes.value;

    const cardDescFromResponse = component.getTrait("descFromResponse");
    const descTarget = cardDescFromResponse.attributes.value;

    const cardImageFromResponse = component.getTrait("imageFromResponse");
    const imageTarget = cardImageFromResponse.attributes.value;

    for (let i = startIndex; i < endIndex; i++) {
      const rowData = responseData[i]; // Get the data for this card
      // console.log("row Data::::::", rowData);

      // Create and add a new card component
      cardElementWrapper.components().add({
        tagName: "ion-card",
        style: {
          position: "relative",
          "z-index": "2",
          width: `${100 / itemsTarget}%`,
          padding: "0.5rem",
          "box-shadow":
            "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
        },
        components: [
          {
            type: "image",
            tagName: "img",
            attributes: {
              src: `${rowData[`${imageTarget}`]}`,
              alt: "Silhouette of mountains",
            },
            style: {
              width: "100%",
              height: "350px",
            },
          },
          {
            type: "default",
            tagName: "div",
            style: {
              "gap-y": "10px",
              "padding-top": "5px",
              "padding-bottom": "5px",
            },
            components: [
              {
                type: "text",
                content: `${rowData[`${titleTarget}`].substring(0, 10)}`,
                style: {
                  "font-size": "18px",
                  "font-weight": "600",
                  width: "100%",
                },
              },
              {
                type: "text",
                content: `${rowData[`${subTitleTarget}`].substring(0, 20)}`,
                style: {
                  "font-size": "12px",
                  "font-weight": "400",
                  width: "100%",
                },
              },
              {
                type: "text",
                content: `${rowData[`${descTarget}`].substring(0, 20)}`,
                style: {
                  "font-size": "12px",
                  "font-weight": "400",
                  width: "100%",
                },
              },
            ],
          },
        ],
      });
    }
  }

  function calculateTranslation(currentPage, cardWidth, cardsPerPage) {
    return -currentPage * cardWidth * cardsPerPage;
  }

  function updateCarousel(
    cardParent,
    cardsPerPage,
    currentPage1,
    categoryName
  ) {
    let cardWidth = 370;
    let cardElementWrapper = cardParent.findType("customCardElementWrapper")[0];
    let currentPage;
    if (categoryName === "next") {
      currentPage = currentPage1 + 1;
    } else {
      currentPage = currentPage1 - 1;
    }

    const translation = calculateTranslation(
      currentPage,
      cardWidth,
      cardsPerPage
    );

    // console.log("translation", translation);
    cardElementWrapper.setStyle({
      position: "relative",
      "z-index": "2",
      "min-width": "max-content",
      display: "flex",
      "gap-x": "8px",
      "justify-content": "center",
      "align-items": "center",
      overflow: "hidden",
      transform: `translateX(${translation}px)`,
    });

    localStorage.setItem(
      "cardCarouselSliderCount",
      JSON.stringify(currentPage)
    );
  }

  editor.on("component:update:traits", async (component) => {
    // console.log("component:::::::::", component);
    if (component && component.attributes.type === "customCardWrapper") {
      const urlTrait = component.getTrait("url");
      // console.log("urlTrait", urlTrait);
      const url = urlTrait.attributes.value;

      const itemsPerPageTrait = component.getTrait("itemsPerSlide");
      const itemsTarget = itemsPerPageTrait.attributes.value;

      const cardTitleFromResponse = component.getTrait("titleFromResponse");
      const titleTarget = cardTitleFromResponse.attributes.value;

      const cardSubTitleFromResponse = component.getTrait(
        "subTitleFromResponse"
      );
      const subTitleTarget = cardSubTitleFromResponse.attributes.value;

      const cardAPI_Key = component.getTrait("API_Key");
      const API_KeyTarget = cardAPI_Key.attributes.value;

      const cardDescFromResponse = component.getTrait("descFromResponse");
      const descTarget = cardDescFromResponse.attributes.value;

      const cardImageFromResponse = component.getTrait("imageFromResponse");
      const imageTarget = cardImageFromResponse.attributes.value;

      let countItems;

      if (itemsTarget) {
        countItems = itemsTarget;
      } else {
        countItems = 3;
      }

      // when only api is provided
      if (
        url &&
        titleTarget &&
        subTitleTarget &&
        descTarget &&
        imageTarget &&
        itemsTarget &&
        API_KeyTarget
      ) {
        try {
          var options = {};
          // console.log("API_KeyTarget", API_KeyTarget);
          if (API_KeyTarget) {
            options.headers = {
              "Content-type": "application/json",
              Authorization: `Bearer ${API_KeyTarget}`,
            };
          }
          const response = await fetch(url, options);
          const responseData = await response.json();
          if (
            Array.isArray(responseData) &&
            responseData.length > 0 &&
            typeof responseData[0] === "object"
          ) {
            console.log("component", component);
            console.log("responseData", responseData);
            console.log("titleTarget", titleTarget);
            console.log("descTarget", descTarget);
            console.log("imageTarget", imageTarget);
            console.log("itemsTarget", itemsTarget);
            localStorage.setItem(
              "cardCarouselDataForSlider",
              JSON.stringify(responseData)
            );

            localStorage.setItem("cardCarouselSliderCount", JSON.stringify(0));

            let cardElementWrapper = component.findType(
              "customCardElementWrapper"
            )[0];
            let oneItemWidth = 100 / countItems;
            cardElementWrapper.components().reset();

            let progressbar = component
              .components()
              .models[0].components()
              .models.find(
                (x) => x.attributes.attributes.name === "customCardProgressBar"
              );

            let percentProgress =
              (Number(countItems) / responseData.length) * 100;

            if (progressbar) {
              progressbar.components().reset();
              progressbar.components().add({
                tagName: "div",
                style: {
                  width: "100%",
                  height: "10px",
                  position: "relative",
                },
                components: [
                  {
                    tagName: "p",
                    style: {
                      width: `${percentProgress}%`,
                      "background-color": "#06b6d4",
                      "border-radius": "12px",
                      "z-index": "3",
                      height: "10px",
                      position: "relative",
                    },
                  },
                  {
                    tagName: "p",
                    style: {
                      position: "absolute",
                      top: "0",
                      right: "10px",
                      "font-size": "10px",
                      width: "100%",
                      display: "flex",
                      "justify-content": "center",
                      "align-items": "center",
                      height: "100%",
                      "z-index": "4",
                    },
                    content: `${percentProgress}%`,
                  },
                ],
              });
            }

            responseData.forEach((rowData, index) => {
              console.log("rowData", rowData);
              console.log("rowData1", rowData[`${imageTarget}`]);
              cardElementWrapper.components().add({
                tagName: "ion-card",
                style: {
                  position: "relative",
                  "z-index": "2",
                  width: `${oneItemWidth}%`,
                  padding: "0.5rem",
                  "box-shadow":
                    "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
                  overflow: "visible",
                },
                components: [
                  {
                    type: "image",
                    tagName: "img",
                    attributes: {
                      src: `${rowData[`${imageTarget}`]}`,
                      alt: "Silhouette of mountains",
                    },
                    style: {
                      width: "100%",
                      height: "350px",
                    },
                  },
                  {
                    type: "default",
                    tagName: "div",
                    style: {
                      "gap-y": "10px",
                      "padding-top": "5px",
                      "padding-bottom": "5px",
                      overflow: "visible",
                    },
                    components: [
                      {
                        type: "text",
                        content: `${rowData[`${titleTarget}`].substring(
                          0,
                          10
                        )}`,
                        style: {
                          "font-size": "18px",
                          "font-weight": "600",
                          width: "100%",
                        },
                      },
                      {
                        type: "text",
                        content: `${rowData[`${subTitleTarget}`].substring(
                          0,
                          20
                        )}`,
                        style: {
                          "font-size": "12px",
                          "font-weight": "400",
                          width: "100%",
                        },
                      },
                      {
                        type: "text",
                        content: `${rowData[`${descTarget}`].substring(0, 20)}`,
                        style: {
                          "font-size": "12px",
                          "font-weight": "400",
                          width: "100%",
                        },
                      },
                    ],
                  },
                ],
              });
            });
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      } else {
        return;
      }
    }
  });

  editor.on("component:update", async (component) => {
    component.components().models.forEach((comp) => {
      if (comp.attributes.attributes.name === "sliderNextActionButton") {
        let cardCarouselDataForSlider = JSON.parse(
          localStorage.getItem("cardCarouselDataForSlider")
        );

        const itemsPerPageTrait = component.parent().getTrait("itemsPerSlide");
        const itemsTarget = itemsPerPageTrait.attributes.value;

        const totalPageCount = Math.floor(
          cardCarouselDataForSlider.length / Number(itemsTarget)
        );

        comp.components().models.forEach((innerComp) => {
          if (
            innerComp.attributes.attributes.name === "nextSlideCardCarousel"
          ) {
            const nextSlideClickHandler = async () => {
              let currentPage = JSON.parse(
                localStorage.getItem("cardCarouselSliderCount")
              );
              if (currentPage < totalPageCount - 1) {
                let categoryName = "next";
                updateCarousel(
                  component.parent(),
                  Number(itemsTarget),
                  currentPage,
                  categoryName
                );
              }
            };

            // Remove any existing click event listeners before adding a new one
            innerComp
              .getEl()
              .removeEventListener("click", nextSlideClickHandler);

            innerComp.getEl().addEventListener("click", nextSlideClickHandler);
          }
        });
      }

      if (comp.attributes.attributes.name === "sliderPrevActionButton") {
        let cardCarouselDataForSlider = JSON.parse(
          localStorage.getItem("cardCarouselDataForSlider")
        );

        const itemsPerPageTrait = component.parent().getTrait("itemsPerSlide");
        const itemsTarget = itemsPerPageTrait.attributes.value;

        const totalPageCount = Math.floor(
          cardCarouselDataForSlider.length / Number(itemsTarget)
        );

        comp.components().models.forEach((innerComp) => {
          if (
            innerComp.attributes.attributes.name === "prevSlideCardCarousel"
          ) {
            const prevSlideClickHandler = async () => {
              let currentPage = JSON.parse(
                localStorage.getItem("cardCarouselSliderCount")
              );
              if (currentPage < totalPageCount - 1) {
                let categoryName = "prev";
                updateCarousel(
                  component.parent(),
                  Number(itemsTarget),
                  currentPage,
                  categoryName
                );
              }
            };

            // Remove any existing click event listeners before adding a new one
            innerComp
              .getEl()
              .removeEventListener("click", prevSlideClickHandler);

            innerComp.getEl().addEventListener("click", prevSlideClickHandler);
          }
        });
      }
    });
  });
};

export default customCardPlugin;
