
import { DELETE_PROJECT_FAILED, DELETE_PROJECT_LOADING, DELETE_PROJECT_SUCCESS, GET_ALL_PROJECT_BY_USER_EMPTY, GET_ALL_PROJECT_BY_USER_FAILED, GET_ALL_PROJECT_BY_USER_LOADING, GET_ALL_PROJECT_BY_USER_SUCCESS, UPDATE_PROJECT_FAILED, UPDATE_PROJECT_LOADING, UPDATE_PROJECT_SUCCESS, RESET_ALL_PROJECT_BY_USER } from "../Constants/ProjectConstants";


// DONE
export const getAllProjectReducer = (state = { emptyProject: '', allProjectData: [] }, action) => {
    switch (action.type) {
        case GET_ALL_PROJECT_BY_USER_LOADING:
            return { loading: true }
        case GET_ALL_PROJECT_BY_USER_SUCCESS:
            return { loading: false, allProjectData: action.payload }
        case GET_ALL_PROJECT_BY_USER_FAILED:
            return { loading: false, error: action.payload }
        case GET_ALL_PROJECT_BY_USER_EMPTY:
            return { loading: false, emptyProject: true }
        case RESET_ALL_PROJECT_BY_USER:
            return { emptyProject: '', allProjectData: [] }
        default:
            return state
    }
}

// DONE
export const updateProjectReducer = (state = { success: '' }, action) => {
    switch (action.type) {
        case UPDATE_PROJECT_LOADING:
            return { loading: true }
        case UPDATE_PROJECT_SUCCESS:
            return { loading: false, updatedProject: action.payload, success: true }
        case UPDATE_PROJECT_FAILED:
            return { loading: false, error: action.payload }
        default:
            return state;
    }
}

// DONE
export const deleteProjectReducer = (state = {}, action) => {
    switch (action.type) {
        case DELETE_PROJECT_LOADING:
            return { loading: true }
        case DELETE_PROJECT_SUCCESS:
            return { loading: false, delProject: action.payload }
        case DELETE_PROJECT_FAILED:
            return { loading: false, delError: action.payload }
        default:
            return state;
    }
}