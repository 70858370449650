import { COPY_PAGE_FAILED, COPY_PAGE_LOADING, COPY_PAGE_SUCCESS, DELETE_PAGE_FAILED, DELETE_PAGE_LOADING, DELETE_PAGE_SUCCESS, GET_ALL_PAGES_EMPTY, GET_ALL_PAGES_LOADING, GET_ALL_PAGES_SUCCESS, GET_PAGE_BY_PROJECT_ID_EMPTY, GET_PAGE_BY_PROJECT_ID_FAILED, GET_PAGE_BY_PROJECT_ID_LOADING, GET_PAGE_BY_PROJECT_ID_SUCCESS, UPDATE_PAGE_FAILED, UPDATE_PAGE_LOADING, UPDATE_PAGE_SUCCESS, RESET_PAGE_BY_PROJECT_ID } from "../Constants/pageConstants";





// DONE
export const pageByProjectIdReducer = (state = { emptyPage: '', pageByProjectData: [] }, action) => {

    switch (action.type) {
        case GET_PAGE_BY_PROJECT_ID_LOADING:
            return { loading: true }
        case GET_PAGE_BY_PROJECT_ID_SUCCESS:
            return { loading: false, pageByProjectData: action.payload }
        case GET_PAGE_BY_PROJECT_ID_FAILED:
            return { loading: false, error: action.payload }
        case GET_PAGE_BY_PROJECT_ID_EMPTY:
            return { loading: false, emptyPage: true }
        case RESET_PAGE_BY_PROJECT_ID:
            return { emptyPage: '', pageByProjectData: [] }
        default:
            return state;
    }
}

// DONE
export const getAllPagesReducer = (state = { emptyPage: '' }, action) => {
    switch (action.type) {
        case GET_ALL_PAGES_LOADING:
            return { loading: true }
        case GET_ALL_PAGES_SUCCESS:
            return { loading: false, pageList: action.payload }
        case GET_ALL_PAGES_SUCCESS:
            return { loading: false, error: action.payload }
        case GET_ALL_PAGES_EMPTY:
            return { loading: false, emptyPage: true }
        default:
            return state;
    }
}


// DONE
export const updatePageReducer = (state = { success: '' }, action) => {
    switch (action.type) {
        case UPDATE_PAGE_LOADING:
            return { loading: true }
        case UPDATE_PAGE_SUCCESS:
            return { loading: false, updatedPage: action.payload, success: true }
        case UPDATE_PAGE_FAILED:
            return { loading: false, error: action.payload }
        default:
            return state;
    }
}


// DONE
export const deletePageReducer = (state = {}, action) => {
    switch (action.type) {
        case DELETE_PAGE_LOADING:
            return { loading: true }
        case DELETE_PAGE_SUCCESS:
            return { loading: false, delPage: action.payload }
        case DELETE_PAGE_FAILED:
            return { loading: false, delError: action.payload }
        default:
            return state;
    }
}

// DONE
export const copyPageReducer = (state = { success: '' }, action) => {
    switch (action.type) {
        case COPY_PAGE_LOADING:
            return { loading: true }
        case COPY_PAGE_SUCCESS:
            return { loading: false, copyPageData: action.payload, success: true }
        case COPY_PAGE_FAILED:
            return { loading: false, error: action.payload }
        default:
            return state;
    }
}