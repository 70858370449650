import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Box, Button, Grid, Paper, TextField, Typography, Modal, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import { getAdminRequest, getUsersUnderCompany, deleteAdminRequest, deleteUsersUnderCompany, updateAdminRequest, updateUsersUnderCompany } from '../Redux/Actions/adminScreenActions'
import CloseIcon from '@mui/icons-material/Close';

function CompanyAdminScreen() {
    const [value, setValue] = useState(0);
    const [modalData, setModalData] = useState({})
    const [open, setOpen] = useState(false)
    const [isAdmin, setIsAdmin] = useState()

    let dispatch = useDispatch()

    // states from store for tab panel 1 (user List)
    const { userListData } = useSelector((state) => state.getUserList)
    const { updateUserData } = useSelector((state) => state.updateUserList)
    const { deleteUserData } = useSelector((state) => state.deleteUserList)


    // states from store for tab panel 2 (user requests)
    const { getListData } = useSelector((state) => state.adminRequest)
    const { updatedList } = useSelector((state) => state.updateRequest)
    const { deleteSuccess } = useSelector((state) => state.deleteRequest)


    useEffect(() => {
        dispatch(getUsersUnderCompany())
    }, [updateUserData, deleteUserData])

    useEffect(() => {
        dispatch(getAdminRequest())
    }, [updatedList, deleteSuccess])

    function TabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box sx={{ p: 3 }}>
                        <Grid>{children}</Grid>
                    </Box>
                )}
            </div>
        );
    }

    TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.number.isRequired,
        value: PropTypes.number.isRequired,
    };

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
        console.log("newValue", newValue);
    };

    const deleteUserHandler = (user_id) => {
        dispatch(deleteUsersUnderCompany(user_id))
    }

    const columns = [
        { field: 'id', headerName: 'ID', width: 150 },
        {
            field: 'userName',
            headerName: 'User Name',
            width: 250,
            editable: false,
        },
        {
            field: 'userEmail',
            headerName: 'Email',
            width: 350,
            editable: false,
        },
        {
            field: 'isAdmin',
            headerName: 'is_admin',
            width: 150,
            editable: false,

        },
        {
            field: 'Actions',
            headerName: '',
            width: 100,
            renderCell: (params) => {
                return (
                    // <Button variant='contained' sx={{ color: '#fecdd3', backgroundColor: '#e11d48', '&:hover': { color: '#e11d48', backgroundColor: '#fecdd3' } }} >Delete</Button>
                    <Typography onClick={(e) => {
                        e.stopPropagation();
                        deleteUserHandler(params.id)
                    }} sx={{ color: '#e11d48', '&:hover': { color: '#fb7185', scale: '125%' } }
                    }>
                        <DeleteIcon fontSize="medium" />
                    </Typography>
                )
            }
        }
    ];


    const currentUser = JSON.parse(localStorage.getItem('userInfo'));

    const currentUserList = userListData?.length && userListData?.filter((x) => x._id !== currentUser._id)

    const rows = userListData?.length ? currentUserList?.map((x) => {

        return {
            id: x._id,
            userName: x.name,
            userEmail: x.email,
            isAdmin: x.company_admin,
        }


    }) : [
        { id: 1, userName: 'jon', userEmail: 'Jon@gmail.com', isAdmin: 'false' },
        { id: 2, userName: 'mike', userEmail: 'mike@gmail.com', isAdmin: 'false' },
        { id: 3, userName: 'marty', userEmail: 'marty@gmail.com', isAdmin: 'false' },
        { id: 4, userName: 'wendy', userEmail: 'wendy@gmail.com', isAdmin: 'false' },
        { id: 5, userName: 'ruth', userEmail: 'ruth@gmail.com', isAdmin: 'true' },
        { id: 6, userName: 'james', userEmail: 'James@gmail.com', isAdmin: 'false' },

    ];

    // const requestData = [
    //     {
    //         name: 'kailash',
    //         email: 'kailash.bhatt@123'
    //     },
    //     {
    //         name: 'randomUser',
    //         email: 'randomUser@123'
    //     },
    //     {
    //         name: 'testUser',
    //         email: 'johnWick@gmail.com'
    //     }
    // ]

    const deleteRequestHandler = (request_id) => {
        dispatch(deleteAdminRequest(request_id))
    }

    const updateRequestHandler = (request_id) => {
        dispatch(updateAdminRequest({ request_id: request_id }))
    }

    const reqListColumns = [
        { field: 'id', headerName: 'ID', width: 150 },
        {
            field: 'userName',
            headerName: 'User Name',
            width: 250,
            editable: false,
        },
        {
            field: 'userEmail',
            headerName: 'Email',
            width: 350,
            editable: false,
        },
        {
            field: 'Approve',
            headerName: '',
            width: 130,
            renderCell: (params) => {
                return (
                    // <Button variant='contained' sx={{ color: '#fecdd3', backgroundColor: '#e11d48', '&:hover': { color: '#e11d48', backgroundColor: '#fecdd3' } }} >Delete</Button>
                    <Button onClick={(e) => {
                        e.stopPropagation();
                        updateRequestHandler(params.id)
                    }} variant='contained' sx={{ color: '#ecfccb', backgroundColor: "#84cc16", '&:hover': { color: '#84cc16', backgroundColor: '#ecfccb' } }
                    }>
                        Approve
                    </Button>

                )
            }
        },
        {
            field: 'Delete',
            headerName: '',
            width: 130,
            renderCell: (params) => {
                return (
                    // <Button variant='contained' sx={{ color: '#fecdd3', backgroundColor: '#e11d48', '&:hover': { color: '#e11d48', backgroundColor: '#fecdd3' } }} >Delete</Button>
                    <Button onClick={(e) => {
                        e.stopPropagation();
                        deleteRequestHandler(params.id)
                    }} variant='contained' sx={{ color: '#ffe4e6', backgroundColor: "#e11d48", '&:hover': { color: '#e11d48', backgroundColor: '#ffe4e6' } }
                    }>
                        Decline
                    </Button>

                )
            }
        }
    ]

    const reqListRows = getListData ? getListData.map((x) => {
        return {
            id: x._id,
            userName: x.name,
            userEmail: x.email
        }
    }) : [
        { id: 1, userName: 'jon', userEmail: 'Jon@gmail.com' },
        { id: 2, userName: 'mike', userEmail: 'mike@gmail.com' },
        { id: 3, userName: 'marty', userEmail: 'marty@gmail.com' },
        { id: 4, userName: 'wendy', userEmail: 'wendy@gmail.com' },
        { id: 5, userName: 'ruth', userEmail: 'ruth@gmail.com' },
        { id: 6, userName: 'james', userEmail: 'James@gmail.com' },

    ];


    const handleRowEventHandler = (params) => {
        setModalData({
            name: params.row.userName,
            email: params.row.userEmail,
            _id: params.row.id,
            company_admin: params.row.isAdmin
        })
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    const isAdminOptionHandler = (event) => {
        if (event.target.value === 10) {
            setIsAdmin(true);
        } else {
            setIsAdmin(false);
        }

    };

    const updateUserDataHandler = (user_id) => {
        dispatch(updateUsersUnderCompany({ user_id, company_admin: isAdmin }))
        setOpen(false)
    }

    return (
        <>

            <Paper elevation={4} style={{ margin: '3% 5% 5% 5%', height: '60vh' }}>
                <Grid>
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example"
                        TabIndicatorProps={{
                            style: {
                                backgroundColor: "red",

                            }
                        }}>
                        <Tab label={<Paper elevation={4} sx={{ backgroundColor: value === 0 ? 'red' : 'white', color: value === 0 ? 'white' : 'red', padding: '1vh 2vw', fontFamily: 'Mont-Regular' }}>USER DATA</Paper>} {...a11yProps(0)} />
                        <Tab label={<Paper elevation={4} sx={{ backgroundColor: value === 1 ? 'red' : 'white', color: value === 1 ? 'white' : 'red', padding: '1vh 2vw', fontFamily: 'Mont-Regular' }}>USER REQUEST'S </Paper>} {...a11yProps(1)} />

                    </Tabs>
                </Grid>
                <TabPanel value={value} index={0} >

                    <Grid sx={{ height: '45vh' }}>
                        <DataGrid
                            rows={rows}
                            columns={columns}
                            pageSize={4}
                            onRowClick={(params) => handleRowEventHandler(params)}
                            // error={error}
                            rowsPerPageOptions={[4]}
                            checkboxSelection
                            disableSelectionOnClick
                            experimentalFeatures={{ newEditingApi: true }}
                            IsReadOnly="True"
                        />
                    </Grid>


                </TabPanel>
                <TabPanel value={value} index={1}>

                    {
                        getListData?.length > 0 ? <Grid sx={{ height: '45vh' }}>
                            <DataGrid
                                rows={reqListRows}
                                columns={reqListColumns}
                                pageSize={4}
                                // onRowClick={(params) => handleRowEventHandler(params)}
                                // error={error}
                                rowsPerPageOptions={[4]}
                                checkboxSelection
                                disableSelectionOnClick
                                experimentalFeatures={{ newEditingApi: true }}
                                IsReadOnly="True"
                            />
                        </Grid> : <Grid sx={{ height: '45vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Typography sx={{ fontSize: '1.5rem', fontFamily: 'Mont-Semibold' }}>Currently, You don't have Pending Requests .</Typography>
                        </Grid>}
                </TabPanel>

            </Paper>

            <Modal
                open={open}
                onClose={handleClose}

                sx={{ border: 'none', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >

                <Box sx={{ width: { lg: '80vw' }, padding: '2vh 2vw 6vh 2vw', outline: 'none', borderRadius: '6px', backgroundColor: 'white', position: 'relative' }}>
                    <Grid sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button onClick={handleClose} sx={{ boxShadow: 2, color: 'red', '&:hover': { boxShadow: 5 } }}>
                            <CloseIcon />
                        </Button>
                    </Grid>
                    <Grid sx={{ display: "flex", justifyContent: 'space-between', alignItems: 'center', mt: 2 }} >
                        <Typography sx={{ fontFamily: "Mont-SemiBold", fontSize: '1.3rem' }}>Update User</Typography>
                        <Grid item xs={10.2} sx={{ backgroundColor: 'red', height: "1vh" }}></Grid>
                    </Grid>
                    <Grid sx={{ mt: 4, display: 'flex', justifyContent: 'space-between' }}>
                        <TextField id="outlined-basic" label="User Name" variant="outlined" sx={{ width: "36vw" }} defaultValue={modalData?.name} disabled />
                        <TextField defaultValue={modalData?.email} id="outlined-basic" label="User Email" variant="outlined" sx={{ width: "36vw" }} disabled />
                    </Grid>
                    <Grid sx={{ mt: 4, display: 'flex', justifyContent: 'space-between' }}>

                        <FormControl sx={{ width: "36vw" }}>
                            <InputLabel id="demo-simple-select-label">grant admin access</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                defaultValue={modalData?.company_admin}
                                label="grant admin access"
                                onChange={isAdminOptionHandler}
                            >
                                <MenuItem value={10}>True</MenuItem>
                                <MenuItem value={20}>False</MenuItem>
                            </Select>
                        </FormControl>
                        <Button onClick={updateUserDataHandler} variant='contained' sx={{ backgroundColor: 'red', '&:hover': { color: 'red', backgroundColor: 'white' } }}>Save Changes</Button>
                    </Grid>

                </Box>
            </Modal>

        </>
    )
}

export default CompanyAdminScreen