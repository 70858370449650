const mainBody = document.createElement('div');

// for bootstrap icons in social media
const cdn = document.createElement('link');
cdn.setAttribute('rel', 'stylesheet');
cdn.setAttribute('href', 'https://cdn.jsdelivr.net/npm/bootstrap-icons@1.9.1/font/bootstrap-icons.css');
mainBody.appendChild(cdn);

const header = document.createElement('div');
header.setAttribute('class', 'header');
mainBody.appendChild(header);

header.innerHTML = `
	<div>
    	<img src="/templates/images/redSlingLogo.png" />
    </div>
    <div class="header-right-section">
        <p>Admin</p>
        <img src="/templates/images/Vector.png" />
    </div>
`;

const whiteWaveOverlay = document.createElement('div');
whiteWaveOverlay.setAttribute('class', 'whiteWaveOverlay');
mainBody.appendChild(whiteWaveOverlay);

whiteWaveOverlay.innerHTML = '<img src="/templates/images/whiteWave.png" />';

const mainDiv = document.createElement('div');
mainDiv.setAttribute('class', 'mainDiv');
mainBody.appendChild(mainDiv);

mainDiv.innerHTML = `
<!-- dark bubble -->
      <img class="addProject-d-bb" src="../assets/images/darkBubble.png" />

      <div class="mainDiv-head">
        <p>Products</p>
        <div>
          <p>Add New</p>
          <p>Import</p>
          <p>Export</p>
        </div>
      </div>

      <!-- table -->
      <div style="overflow-x: auto">
        <table>
          <tr>
            <th>
              <p class="box1"></p>
            </th>
            <th><p>Name</p></th>
            <th><p>SKU</p></th>
            <th><p>Stock</p></th>
            <th><p>Price</p></th>
            <th><p>Categories</p></th>
            <th><p>Tags</p></th>
          </tr>
          <tr>
            <td>
              <p class="box"></p>
            </td>
            <td><p>Product 1</p></td>
            <td><p></p></td>
            <td><p>In stock</p></td>
            <td><p></p></td>
            <td><p>Category 1</p></td>
            <td><p></p></td>
          </tr>
          <tr>
            <td>
              <p class="box"></p>
            </td>
            <td><p>Product 2</p></td>
            <td><p></p></td>
            <td><p>In stock</p></td>
            <td><p></p></td>
            <td><p>Category 2</p></td>
            <td><p></p></td>
          </tr>
          <tr>
            <td>
              <p class="box"></p>
            </td>
            <td><p>Product 3</p></td>
            <td><p></p></td>
            <td><p>In stock</p></td>
            <td><p></p></td>
            <td><p>Category 3</p></td>
            <td><p></p></td>
          </tr>
          <tr>
            <td>
              <p class="box"></p>
            </td>
            <td><p>Product 4</p></td>
            <td><p></p></td>
            <td><p>In stock</p></td>
            <td><p></p></td>
            <td><p>Category 4</p></td>
            <td><p></p></td>
          </tr>
          <tr>
            <td>
              <p class="box"></p>
            </td>
            <td><p>Product 5</p></td>
            <td><p></p></td>
            <td><p>In stock<</p>/td>
            <td><p></p></td>
            <td><p>Category 5</p></td>
            <td><p></p></td>
          </tr>
          <tr>
            <td>
              <p class="box"></p>
            </td>
            <td><p>Product 6</p></td>
            <td><p></p></td>
            <td><p>In stock</p></td>
            <td><p></p></td>
            <td><p>Category 6</p></td>
            <td><p></p></td>
          </tr>
          <tr>
            <td>
              <p class="box"></p>
            </td>
            <td><p>Product 7</p></td>
            <td><p></p></td>
            <td><p>In stock</p></td>
            <td><p></p></td>
            <td><p>Category 7</p></td>
            <td><p></p></td>
          </tr>
          <tr>
            <td>
              <p class="box"></p>
            </td>
            <td><p>Product 8</p></td>
            <td><p></p></td>
            <td><p>In stock</p></td>
            <td><p></p></td>
            <td><p>Category 8</p></td>
            <td><p></p></td>
          </tr>
          <tr>
            <td>
              <p class="box"></p>
            </td>
            <td><p>Product 9</p></td>
            <td><p></p></td>
            <td><p>In stock</p></td>
            <td><p></p></td>
            <td><p>Category 9</p></td>
            <td><p></p></td>
          </tr>
          <tr>
            <td>
              <p class="box"></p>
            </td>
            <td><p>Product 10</p></td>
            <td><p></p></td>
            <td><p>In stock</p></td>
            <td><p></p></td>
            <td><p>Category 10</p></td>
            <td><p></p></td>
          </tr>
        </table>
      </div>

      <div class="paginationTable">
        <p class="pageParaOne">&lt;</p>
        <div>
          <p>1</p>
          <p class="pageParaTwo">2</p>
          <p>.....</p>
          <p>14</p>
        </div>
        <p class="pageParaOne">&gt;</p>
      </div>

    <style>
    @media (max-width: 768px) {
    	.header {
		    display: flex;
		    justify-content: space-between;
		    align-items: center;
		    position: relative;
		    padding: 2vh 2.5vw;
		}

		  .header-right-section img {
		    width: 5vw;
		    height: 3vh;
		    margin-left: 18%;
		}
		  .header-right-section p {
		    font-family: Mont-Regular;
		    font-size: 1rem;
		    margin-right: 9%;
		}

		.whiteWaveOverlay {
		    position: absolute;
		    top: 0;
		    right: -8%;
		  }

		  .whiteWaveOverlay img {
		    height: 80vh;
		    width: 100vw;
		  }

		  .mainDiv {
		    overflow-x: hidden;
		  }

		  .addProject-d-bb {
		    display: none;
		  }

		  .mainDiv-head {
		    display: block;
		    justify-content: space-between;
		    align-items: center;
		    padding: 0vh 0 0 8vw;
		    position: relative;
		  }

		  .mainDiv-head p:first-child {
		    font-family: Mont-SemiBold;
		    font-style: normal;
		    font-weight: 800;
		    font-size: 2.8rem;
		    padding-left: 5%;
		  }
		  .mainDiv-head div {
		    display: flex;
		    width: initial;
		    margin-top: 2vh;
		    justify-content: space-between;
		    align-items: center;
		  }

		  table {
		    width: 100vw;
		    margin-top: 8vh;
		    margin-left: 8%;
		  }
		  th {
		    font-family: Mont-Heavy;
		    font-size: 1.2rem;
		    padding-left: 8vw;
		    padding-right: 8vw;
		  }

		  th:first-child {
		    padding-left: 0;
		  }

		  td {
		    text-align: center;
		    font-family: Mont-Regular;
		    font-size: 1rem;
		    padding: 1vh 0 1vh 0;
		  }

		  .box1 {
		    border: 2px solid red;
		    width: 3.5vw;
		    height: 1.5vh;
		    border-radius: 6px;
		  }
		  .box {
		    border: 2px solid red;
		    width: 4vw;
		    height: 2vh;
		    border-radius: 6px;
		  }

		  .paginationTable {
		    display: flex;
		    justify-content: flex-end;
		    align-items: center;
		    margin-top: 5vh;
		  }

		  .paginationTable div {
		    display: flex;
		    justify-content: center;
		    align-items: center;
		  }

		  .paginationTable div p {
		    font-family: Mont-Regular;
		    font-size: 1.2rem;
		  }

		  .pageParaTwo {
		    width: 4vw;
		    margin-left: 8%;
		    border: 2px solid red;
		    border-radius: 50% 50%;
		    text-align: center;
		    padding: 2%;
		  }

		  .pageParaOne {
		    width: 4vw;
		    border: 2px solid red;
		    text-align: center;
		    border-radius: 50% 50%;
		    color: white;
		    background-color: red;
		    font-size: 1rem;
		    font-weight: 800;
		    margin-right: 3vw;
		    margin-left: 2vw;
		  }
    }
    </style>
`;

const templateEcommProductPage = mainBody.outerHTML;
export { templateEcommProductPage };