import React, { useState, useEffect, useRef } from 'react'
import darkBubble from './component_assets/images/darkBubble.png'
// import darkBubble from './component_assets/images/details_close.png'
// import lightBubble from './component_assets/images/details_close.png'
import lightBubble from './component_assets/images/lightBubble.png'
import { useNavigate,useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Tooltip } from '@mui/material'
import axios from 'axios'
import { API_HOST } from '../api_utils'

import fileImage from './component_assets/images/fileImage.png'
import redSquare from './component_assets/images/redSquare.png'
import cyanSquare from './component_assets/images/cyanSquare.png'
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";

import { logout } from '../Redux/Actions/userActions';

import GCModal from './GCModal';

import classes from './ScreensStylings/CreateProjectScreen.module.css';

const notifyTheme = {
    position: "top-right",
    autoClose: 7500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
};

const notifyTheme2 = {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
};

const successTheme = {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "light",
};

// toast notification for empty tables
const infoTheme = {
  position: "top-right",
  autoClose: 7500,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "light",
};

function CreateProjectScreen() {
    let navigate = useNavigate()
    let dispatch = useDispatch()
    const {app}=useParams()

    const { userInfo } = useSelector(state => state.userLogin)

    // modal state
    const [isModal1Open, setIsModal1Open] = useState(false);
    const [isModal2Open, setIsModal2Open] = useState(false);

    const [projectName, setProjectName] = useState('')
    const [fbId, setFbId] = useState('')
    const [twitterId, setTwitterId] = useState('')
    const [linkedInId, setLinkedInId] = useState('')
    const [instaId, setInstaId] = useState('')
    const [analyticCode, setAnalyticCode] = useState('')
    const [koreClientID, setKoreClientID] = useState('')
    const [koreClientSecret, setKoreClientSecret] = useState('')
    const [koreBotInfoID, setKoreBotInfoID] = useState('')
    const [koreBotUserIdentity, setKoreBotUserIdentity] = useState('')
    const [koreBotBrandUrl, setKoreBotBrandUrl] = useState('')
    const [koreBotName, setKoreBotName] = useState('')
    const [koreBotInfoName, setKoreBotInfoName] = useState('')
    const [koreBotJWTUrl, setKoreBotJWTUrl] = useState('')
    const [koreBotAPIUrl, setKoreBotAPIUrl] = useState('')
    const [keyFileData, setKeyFileData] = useState('')
    const [keyFileName, setKeyFileName] = useState('')
    // const [gCloudServiceAccId, setGCloudServiceAccId] = useState('')
    // const [gCloudProjectId, setGCloudProjectId] = useState('')
    // const [gmailAccount, setGmailAccount] = useState('')
    const [projectCreated, setProjectCreated] = useState()

    // Base URL states
    const [baseUrl2, setBaseUrl2] = useState('');
    const [baseUrl3, setBaseUrl3] = useState('');
    const [baseUrl4, setBaseUrl4] = useState('');
    const [baseUrl5, setBaseUrl5] = useState('');

    // Global constants' states
    // NAVBAR -- CONSTANTS
    const [initializeNavBarInPages, setInitializeNavBarInPages] = useState(false);
    
    // TABLE PLUGIN -- CONSTANTS
    const [tableConstants, setTableConstants] = useState({});

    const tableTheadFontSizeRef = useRef('');
    const tableTheadFontColorRef = useRef('');
    const tableTheadFontFamilyRef = useRef('');
    const tableTheadRowBackgroundColorRef = useRef('');
    const tableTheadRowLinearGradient1Ref = useRef('');
    const tableTheadRowLinearGradient2Ref = useRef('');
    const tableTheadRowBorderTopWidthRef = useRef('');
    const tableTheadRowBorderTopTypeRef = useRef('');
    const tableTheadRowBorderTopColorRef = useRef('');
    const tableTheadRowBorderRightWidthRef = useRef('');
    const tableTheadRowBorderRightTypeRef = useRef('');
    const tableTheadRowBorderRightColorRef = useRef('');
    const tableTheadRowBorderBottomWidthRef = useRef('');
    const tableTheadRowBorderBottomTypeRef = useRef('');
    const tableTheadRowBorderBottomColorRef = useRef('');
    const tableTheadRowBorderLeftWidthRef = useRef('');
    const tableTheadRowBorderLeftTypeRef = useRef('');
    const tableTheadRowBorderLeftColorRef = useRef('');

    const tableTbodyFontSizeRef = useRef('');
    const tableTbodyFontColorRef = useRef('');
    const tableTbodyFontFamilyRef = useRef('');
    const tableTbodyTextAlignRef = useRef('');
    const tableTbodyRowOddBackgroundRef = useRef('');
    const tableTbodyRowEvenBackgroundRef = useRef('');
    const tableTbodyRowHoverColorRef = useRef('');
    const tableTbodyRowBorderTopWidthRef = useRef('');
    const tableTbodyRowBorderTopTypeRef = useRef('');
    const tableTbodyRowBorderTopColorRef = useRef('');
    const tableTbodyRowBorderRightWidthRef = useRef('');
    const tableTbodyRowBorderRightTypeRef = useRef('');
    const tableTbodyRowBorderRightColorRef = useRef('');
    const tableTbodyRowBorderBottomWidthRef = useRef('');
    const tableTbodyRowBorderBottomTypeRef = useRef('');
    const tableTbodyRowBorderBottomColorRef = useRef('');
    const tableTbodyRowBorderLeftWidthRef = useRef('');
    const tableTbodyRowBorderLeftTypeRef = useRef('');
    const tableTbodyRowBorderLeftColorRef = useRef('');

    const tableTfootFontSizeRef = useRef('');
    const tableTfootFontColorRef = useRef('');
    const tableTfootFontFamilyRef = useRef('');
    const tableTfootRowBackgroundColorRef = useRef('');
    const tableTfootRowLinearGradient1Ref = useRef('');
    const tableTfootRowLinearGradient2Ref = useRef('');
    const tableTfootRowBorderTopWidthRef = useRef('');
    const tableTfootRowBorderTopTypeRef = useRef('');
    const tableTfootRowBorderTopColorRef = useRef('');
    const tableTfootRowBorderRightWidthRef = useRef('');
    const tableTfootRowBorderRightTypeRef = useRef('');
    const tableTfootRowBorderRightColorRef = useRef('');
    const tableTfootRowBorderBottomWidthRef = useRef('');
    const tableTfootRowBorderBottomTypeRef = useRef('');
    const tableTfootRowBorderBottomColorRef = useRef('');
    const tableTfootRowBorderLeftWidthRef = useRef('');
    const tableTfootRowBorderLeftTypeRef = useRef('');
    const tableTfootRowBorderLeftColorRef = useRef('');

    // if (success) {
    //     dispatch(resetAddProjectSuccess())
    //     navigate(`/addNewPage/${projectDetails._id}`)
    // }

    useEffect(() => {
        if (projectCreated) {
            navigate(`/addNewPage/${projectCreated._id}`)
        }
    }, [projectCreated])

    const errorTheme = {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
    }

    // modal function handlers
    const handleModal1Open = () => {
        setIsModal1Open(true);
    };

    const handleModal1Close = () => {
        setIsModal1Open(false);
    };

    const handleModal2Open = () => {
        setIsModal2Open(true);
    };

    const handleModal2Close = () => {
        setIsModal2Open(false);
    };

    // to parse the icon string
    const parseIconString = (iconString) => {
      const parser = new DOMParser();
      const doc = parser.parseFromString(iconString, 'text/html');
      const icons = [];

      const sections = doc.querySelectorAll('section');
      sections.forEach((section) => {
        const iconsInSection = section.querySelectorAll('div');
        iconsInSection.forEach((icon) => {
          const className = icon.querySelector('i').getAttribute('class');
          const label = icon.textContent.trim().replace(/<[^>]*>/g, '');
          icons.push({ className, label });
        });
      });

      return icons;
    };

    // all the icons + added its cdn to /public/index.html file
    const iconString = `
            <p>Common Icons</p>
            <section>
                <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-bluetooth"></i> fa fa-bluetooth</div>
                <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-bluetooth-b"></i> fa fa-bluetooth-b</div>
                <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-codiepie"></i> fa fa-codiepie</div>
                <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-credit-card-alt"></i>fa fa-credit-card-alt</div>
                <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-edge"></i>fa fa-edge</div>
                <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-fort-awesome"></i>fa fa-fort-awesome</div>
                <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-hashtag"></i>fa fa-hashtag</div>
                <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-mixcloud"></i>fa fa-mixcloud</div>
                <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-modx"></i>fa fa-modx</div>
                <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-pause-circle"></i>fa fa-pause-circle</div>
                <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-pause-circle-o"></i>fa fa-pause-circle-o</div>
                <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-percent"></i>fa fa-percent</div>
                <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-product-hunt"></i>fa fa-product-hunt</div>
                <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-reddit-alien"></i>fa fa-reddit-alien</div>
                <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-scribd"></i>fa fa-scribd</div>
                <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-shopping-bag"></i>fa fa-shopping-bag</div>
                <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-shopping-basket"></i>fa fa-shopping-basket</div>
                <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-stop-circle"></i>fa fa-stop-circle</div>
                <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-stop-circle-o"></i>fa fa-stop-circle-o</div>
                <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-usb"></i>fa fa-usb</div>
            </section>

            <br>

            <p>Currency Icons</p>
            <section>
                <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-bitcoin"></i> fa-bitcoin<span class="text-muted">(alias)</span></div>
                <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-btc"></i> fa-btc</div>
                <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-cny"></i> fa-cny<span class="text-muted">(alias)</span></div>
                <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-dollar"></i> fa-dollar <span class="text-muted">(alias)</span></div>
                <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-eur"></i> fa-eur</div>
                <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-euro"></i> fa-euro <span class="text-muted">(alias)</span></div>
                <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-gbp"></i> fa-gbp</div>
                <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-inr"></i> fa-inr</div>
                <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-jpy"></i> fa-jpy</div>
                <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-krw"></i> fa-krw</div>
                <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-money"></i> fa-money</div>
                <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-rmb"></i> fa-rmb <span class="text-muted">(alias)</span></div>
                <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-rouble"></i> fa-rouble <span class="text-muted">(alias)</span></div>
                <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-rub"></i> fa-rub</div>
                <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-ruble"></i> fa-ruble <span class="text-muted">(alias)</span></div>
                <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-rupee"></i> fa-rupee <span class="text-muted">(alias)</span></div>
                <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-try"></i> fa-try</div>
                <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-turkish-lira"></i> fa-turkish-lira <span class="text-muted">(alias)</span></div>
                <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-usd"></i> fa-usd</div>
                <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-won"></i> fa-won <span class="text-muted">(alias)</span></div>
                <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-yen"></i> fa-yen <span class="text-muted">(alias)</span></div>
            </section>

            <br>

            <p>Web App Icons</p>
            <section>
                <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-adjust"></i> fa-adjust</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-anchor"></i> fa-anchor</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-archive"></i> fa-archive</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-arrows"></i> fa-arrows</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-arrows-h"></i> fa-arrows-h</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-arrows-v"></i> fa-arrows-v</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-asterisk"></i> fa-asterisk</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-automobile"></i> fa-automobile <span class="text-muted">(alias)</span></div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-ban"></i> fa-ban</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-bank"></i> fa-bank <span class="text-muted">(alias)</span></div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-bar-chart-o"></i> fa-bar-chart-o</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-barcode"></i> fa-barcode</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-bars"></i> fa-bars</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-beer"></i> fa-beer</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-bell"></i> fa-bell</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-bell-o"></i> fa-bell-o</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-bolt"></i> fa-bolt</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-bomb"></i> fa-bomb</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-book"></i> fa-book</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-bookmark"></i> fa-bookmark</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-bookmark-o"></i> fa-bookmark-o</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-briefcase"></i> fa-briefcase</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-bug"></i> fa-bug</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-building"></i> fa-building</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-building-o"></i> fa-building-o</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-bullhorn"></i> fa-bullhorn</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-bullseye"></i> fa-bullseye</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-cab"></i> fa-cab <span class="text-muted">(alias)</span></div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-calendar"></i> fa-calendar</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-calendar-o"></i> fa-calendar-o</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-camera"></i> fa-camera</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-camera-retro"></i> fa-camera-retro</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-car"></i> fa-car</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-caret-square-o-down"></i> fa-caret-square-o-down</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-caret-square-o-left"></i> fa-caret-square-o-left</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-caret-square-o-right"></i> fa-caret-square-o-right</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-caret-square-o-up"></i> fa-caret-square-o-up</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-certificate"></i> fa-certificate</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-check"></i> fa-check</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-check-circle"></i> fa-check-circle</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-check-circle-o"></i> fa-check-circle-o</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-check-square"></i> fa-check-square</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-check-square-o"></i> fa-check-square-o</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-child"></i> fa-child</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-circle"></i> fa-circle</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-circle-o"></i> fa-circle-o</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-circle-o-notch"></i> fa-circle-o-notch</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-circle-thin"></i> fa-circle-thin</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-clock-o"></i> fa-clock-o</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-cloud"></i> fa-cloud</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-cloud-download"></i> fa-cloud-download</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-cloud-upload"></i> fa-cloud-upload</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-code"></i> fa-code</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-code-fork"></i> fa-code-fork</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-coffee"></i> fa-coffee</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-cog"></i> fa-cog</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-cogs"></i> fa-cogs</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-comment"></i> fa-comment</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-comment-o"></i> fa-comment-o</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-comments"></i> fa-comments</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-comments-o"></i> fa-comments-o</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-compass"></i> fa-compass</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-credit-card"></i> fa-credit-card</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-crop"></i> fa-crop</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-crosshairs"></i> fa-crosshairs</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-cube"></i> fa-cube</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-cubes"></i> fa-cubes</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-cutlery"></i> fa-cutlery</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-dashboard"></i> fa-dashboard <span class="text-muted">(alias)</span></div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-database"></i> fa-database</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-desktop"></i> fa-desktop</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-dot-circle-o"></i> fa-dot-circle-o</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-download"></i> fa-download</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-edit"></i> fa-edit <span class="text-muted">(alias)</span></div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-ellipsis-h"></i> fa-ellipsis-h</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-ellipsis-v"></i> fa-ellipsis-v</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-envelope"></i> fa-envelope</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-envelope-o"></i> fa-envelope-o</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-envelope-square"></i> fa-envelope-square</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-eraser"></i> fa-eraser</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-exchange"></i> fa-exchange</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-exclamation"></i> fa-exclamation</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-exclamation-circle"></i> fa-exclamation-circle</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-exclamation-triangle"></i> fa-exclamation-triangle</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-external-link"></i> fa-external-link</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-external-link-square"></i> fa-external-link-square</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-eye"></i> fa-eye</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-eye-slash"></i> fa-eye-slash</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-fax"></i> fa-fax</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-female"></i> fa-female</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-fighter-jet"></i> fa-fighter-jet</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-file-archive-o"></i> fa-file-archive-o</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-file-audio-o"></i> fa-file-audio-o</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-file-code-o"></i> fa-file-code-o</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-file-excel-o"></i> fa-file-excel-o</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-file-image-o"></i> fa-file-image-o</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-file-movie-o"></i> fa-file-movie-o <span class="text-muted">(alias)</span></div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-file-pdf-o"></i> fa-file-pdf-o</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-file-photo-o"></i> fa-file-photo-o <span class="text-muted">(alias)</span></div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-file-picture-o"></i> fa-file-picture-o <span class="text-muted">(alias)</span></div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-file-powerpoint-o"></i> fa-file-powerpoint-o</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-file-sound-o"></i> fa-file-sound-o <span class="text-muted">(alias)</span></div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-file-video-o"></i> fa-file-video-o</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-file-word-o"></i> fa-file-word-o</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-file-zip-o"></i> fa-file-zip-o <span class="text-muted">(alias)</span></div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-film"></i> fa-film</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-filter"></i> fa-filter</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-fire"></i> fa-fire</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-fire-extinguisher"></i> fa-fire-extinguisher</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-flag"></i> fa-flag</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-flag-checkered"></i> fa-flag-checkered</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-flag-o"></i> fa-flag-o</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-flash"></i> fa-flash <span class="text-muted">(alias)</span></div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-flask"></i> fa-flask</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-folder"></i> fa-folder</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-folder-o"></i> fa-folder-o</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-folder-open"></i> fa-folder-open</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-folder-open-o"></i> fa-folder-open-o</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-frown-o"></i> fa-frown-o</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-gamepad"></i> fa-gamepad</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-gavel"></i> fa-gavel</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-gear"></i> fa-gear <span class="text-muted">(alias)</span></div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-gears"></i> fa-gears <span class="text-muted">(alias)</span></div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-gift"></i> fa-gift</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-glass"></i> fa-glass</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-globe"></i> fa-globe</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-graduation-cap"></i> fa-graduation-cap</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-group"></i> fa-group <span class="text-muted">(alias)</span></div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-hdd-o"></i> fa-hdd-o</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-headphones"></i> fa-headphones</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-heart"></i> fa-heart</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-heart-o"></i> fa-heart-o</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-history"></i> fa-history</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-home"></i> fa-home</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-image"></i> fa-image <span class="text-muted">(alias)</span></div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-inbox"></i> fa-inbox</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-info"></i> fa-info</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-info-circle"></i> fa-info-circle</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-institution"></i> fa-institution <span class="text-muted">(alias)</span></div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-key"></i> fa-key</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-keyboard-o"></i> fa-keyboard-o</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-language"></i> fa-language</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-laptop"></i> fa-laptop</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-leaf"></i> fa-leaf</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-legal"></i> fa-legal <span class="text-muted">(alias)</span></div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-lemon-o"></i> fa-lemon-o</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-level-down"></i> fa-level-down</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-level-up"></i> fa-level-up</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-life-bouy"></i> fa-life-bouy <span class="text-muted">(alias)</span></div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-life-ring"></i> fa-life-ring</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-life-saver"></i> fa-life-saver <span class="text-muted">(alias)</span></div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-lightbulb-o"></i> fa-lightbulb-o</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-location-arrow"></i> fa-location-arrow</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-lock"></i> fa-lock</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-magic"></i> fa-magic</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-magnet"></i> fa-magnet</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-mail-forward"></i> fa-mail-forward <span class="text-muted">(alias)</span></div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-mail-reply"></i> fa-mail-reply <span class="text-muted">(alias)</span></div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-mail-reply-all"></i> fa-mail-reply-all <span class="text-muted">(alias)</span></div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-male"></i> fa-male</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-map-marker"></i> fa-map-marker</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-meh-o"></i> fa-meh-o</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-microphone"></i> fa-microphone</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-microphone-slash"></i> fa-microphone-slash</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-minus"></i> fa-minus</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-minus-circle"></i> fa-minus-circle</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-minus-square"></i> fa-minus-square</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-minus-square-o"></i> fa-minus-square-o</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-mobile"></i> fa-mobile</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-mobile-phone"></i> fa-mobile-phone <span class="text-muted">(alias)</span></div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-money"></i> fa-money</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-moon-o"></i> fa-moon-o</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-mortar-board"></i> fa-mortar-board <span class="text-muted">(alias)</span></div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-music"></i> fa-music</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-navicon"></i> fa-navicon <span class="text-muted">(alias)</span></div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-paper-plane"></i> fa-paper-plane</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-paper-plane-o"></i> fa-paper-plane-o</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-paw"></i> fa-paw</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-pencil"></i> fa-pencil</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-pencil-square"></i> fa-pencil-square</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-pencil-square-o"></i> fa-pencil-square-o</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-phone"></i> fa-phone</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-phone-square"></i> fa-phone-square</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-photo"></i> fa-photo <span class="text-muted">(alias)</span></div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-picture-o"></i> fa-picture-o</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-plane"></i> fa-plane</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-plus"></i> fa-plus</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-plus-circle"></i> fa-plus-circle</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-plus-square"></i> fa-plus-square</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-plus-square-o"></i> fa-plus-square-o</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-power-off"></i> fa-power-off</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-print"></i> fa-print</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-puzzle-piece"></i> fa-puzzle-piece</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-qrcode"></i> fa-qrcode</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-question"></i> fa-question</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-question-circle"></i> fa-question-circle</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-quote-left"></i> fa-quote-left</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-quote-right"></i> fa-quote-right</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-random"></i> fa-random</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-recycle"></i> fa-recycle</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-refresh"></i> fa-refresh</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-reorder"></i> fa-reorder <span class="text-muted">(alias)</span></div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-reply"></i> fa-reply</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-reply-all"></i> fa-reply-all</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-retweet"></i> fa-retweet</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-road"></i> fa-road</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-rocket"></i> fa-rocket</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-rss"></i> fa-rss</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-rss-square"></i> fa-rss-square</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-search"></i> fa-search</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-search-minus"></i> fa-search-minus</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-search-plus"></i> fa-search-plus</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-send"></i> fa-send <span class="text-muted">(alias)</span></div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-send-o"></i> fa-send-o <span class="text-muted">(alias)</span></div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-share"></i> fa-share</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-share-alt"></i> fa-share-alt</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-share-alt-square"></i> fa-share-alt-square</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-share-square"></i> fa-share-square</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-share-square-o"></i> fa-share-square-o</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-shield"></i> fa-shield</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-shopping-cart"></i> fa-shopping-cart</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-sign-in"></i> fa-sign-in</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-sign-out"></i> fa-sign-out</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-signal"></i> fa-signal</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-sitemap"></i> fa-sitemap</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-sliders"></i> fa-sliders</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-smile-o"></i> fa-smile-o</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-sort"></i> fa-sort</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-sort-alpha-asc"></i> fa-sort-alpha-asc</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-sort-alpha-desc"></i> fa-sort-alpha-desc</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-sort-amount-asc"></i> fa-sort-amount-asc</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-sort-amount-desc"></i> fa-sort-amount-desc</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-sort-asc"></i> fa-sort-asc</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-sort-desc"></i> fa-sort-desc</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-sort-down"></i> fa-sort-down <span class="text-muted">(alias)</span></div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-sort-numeric-asc"></i> fa-sort-numeric-asc</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-sort-numeric-desc"></i> fa-sort-numeric-desc</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-sort-up"></i> fa-sort-up <span class="text-muted">(alias)</span></div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-space-shuttle"></i> fa-space-shuttle</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-spinner"></i> fa-spinner</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-spoon"></i> fa-spoon</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-square"></i> fa-square</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-square-o"></i> fa-square-o</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-star"></i> fa-star</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-star-half"></i> fa-star-half</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-star-half-empty"></i> fa-star-half-empty <span class="text-muted">(alias)</span></div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-star-half-full"></i> fa-star-half-full <span class="text-muted">(alias)</span></div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-star-half-o"></i> fa-star-half-o</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-star-o"></i> fa-star-o</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-suitcase"></i> fa-suitcase</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-sun-o"></i> fa-sun-o</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-support"></i> fa-support <span class="text-muted">(alias)</span></div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-tablet"></i> fa-tablet</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-tachometer"></i> fa-tachometer</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-tag"></i> fa-tag</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-tags"></i> fa-tags</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-tasks"></i> fa-tasks</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-taxi"></i> fa-taxi</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-terminal"></i> fa-terminal</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-thumb-tack"></i> fa-thumb-tack</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-thumbs-down"></i> fa-thumbs-down</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-thumbs-o-down"></i> fa-thumbs-o-down</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-thumbs-o-up"></i> fa-thumbs-o-up</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-thumbs-up"></i> fa-thumbs-up</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-ticket"></i> fa-ticket</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-times"></i> fa-times</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-times-circle"></i> fa-times-circle</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-times-circle-o"></i> fa-times-circle-o</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-tint"></i> fa-tint</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-toggle-down"></i> fa-toggle-down <span class="text-muted">(alias)</span></div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-toggle-left"></i> fa-toggle-left <span class="text-muted">(alias)</span></div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-toggle-right"></i> fa-toggle-right <span class="text-muted">(alias)</span></div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-toggle-up"></i> fa-toggle-up <span class="text-muted">(alias)</span></div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-trash-o"></i> fa-trash-o</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-tree"></i> fa-tree</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-trophy"></i> fa-trophy</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-truck"></i> fa-truck</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-umbrella"></i> fa-umbrella</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-university"></i> fa-university</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-unlock"></i> fa-unlock</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-unlock-alt"></i> fa-unlock-alt</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-unsorted"></i> fa-unsorted <span class="text-muted">(alias)</span></div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-upload"></i> fa-upload</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-user"></i> fa-user</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-users"></i> fa-users</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-video-camera"></i> fa-video-camera</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-volume-down"></i> fa-volume-down</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-volume-off"></i> fa-volume-off</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-volume-up"></i> fa-volume-up</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-warning"></i> fa-warning <span class="text-muted">(alias)</span></div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-wheelchair"></i> fa-wheelchair</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-wrench"></i> fa-wrench</div>
            </section>

            <br>

            <p>Spinner Icons</p>
            <section>
                <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-spin fa-circle-o-notch"></i> fa-circle-o-notch</div>
                <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-spin fa-cog"></i> fa-cog</div>
                <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-spin fa-gear"></i> fa-gear <span class="text-muted">(alias)</span></div>
                <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-spin fa-refresh"></i> fa-refresh</div>
                <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-spin fa-spinner"></i> fa-spinner</div>
            </section>

            <br>

            <p>Form Control Icons</p>
            <section>
                <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-check-square"></i> fa-check-square</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-check-square-o"></i> fa-check-square-o</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-circle"></i> fa-circle</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-circle-o"></i> fa-circle-o</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-dot-circle-o"></i> fa-dot-circle-o</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-minus-square"></i> fa-minus-square</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-minus-square-o"></i> fa-minus-square-o</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-plus-square"></i> fa-plus-square</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-plus-square-o"></i> fa-plus-square-o</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-square"></i> fa-square</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-square-o"></i> fa-square-o</div>
            </section>

            <br>

            <p>Text Editor Icons</p>
            <section>
                <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-align-center"></i> fa-align-center</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-align-justify"></i> fa-align-justify</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-align-left"></i> fa-align-left</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-align-right"></i> fa-align-right</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-bold"></i> fa-bold</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-chain"></i> fa-chain <span class="text-muted">(alias)</span></div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-chain-broken"></i> fa-chain-broken</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-clipboard"></i> fa-clipboard</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-columns"></i> fa-columns</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-copy"></i> fa-copy <span class="text-muted">(alias)</span></div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-cut"></i> fa-cut <span class="text-muted">(alias)</span></div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-dedent"></i> fa-dedent <span class="text-muted">(alias)</span></div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-eraser"></i> fa-eraser</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-file"></i> fa-file</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-file-o"></i> fa-file-o</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-file-text"></i> fa-file-text</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-file-text-o"></i> fa-file-text-o</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-files-o"></i> fa-files-o</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-floppy-o"></i> fa-floppy-o</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-font"></i> fa-font</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-header"></i> fa-header</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-indent"></i> fa-indent</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-italic"></i> fa-italic</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-link"></i> fa-link</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-list"></i> fa-list</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-list-alt"></i> fa-list-alt</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-list-ol"></i> fa-list-ol</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-list-ul"></i> fa-list-ul</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-outdent"></i> fa-outdent</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-paperclip"></i> fa-paperclip</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-paragraph"></i> fa-paragraph</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-paste"></i> fa-paste <span class="text-muted">(alias)</span></div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-repeat"></i> fa-repeat</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-rotate-left"></i> fa-rotate-left <span class="text-muted">(alias)</span></div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-rotate-right"></i> fa-rotate-right <span class="text-muted">(alias)</span></div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-save"></i> fa-save <span class="text-muted">(alias)</span></div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-scissors"></i> fa-scissors</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-strikethrough"></i> fa-strikethrough</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-subscript"></i> fa-subscript</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-superscript"></i> fa-superscript</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-table"></i> fa-table</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-text-height"></i> fa-text-height</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-text-width"></i> fa-text-width</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-th"></i> fa-th</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-th-large"></i> fa-th-large</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-th-list"></i> fa-th-list</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-underline"></i> fa-underline</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-undo"></i> fa-undo</div>
              <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-unlink"></i> fa-unlink <span class="text-muted">(alias)</span></div>
            </section>

            <br>

            <p>Directional Icons</p>
            <section>
                <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-angle-double-down"></i> fa-angle-double-down</div>
                  <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-angle-double-left"></i> fa-angle-double-left</div>
                  <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-angle-double-right"></i> fa-angle-double-right</div>
                  <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-angle-double-up"></i> fa-angle-double-up</div>
                  <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-angle-down"></i> fa-angle-down</div>
                  <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-angle-left"></i> fa-angle-left</div>
                  <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-angle-right"></i> fa-angle-right</div>
                  <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-angle-up"></i> fa-angle-up</div>
                  <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-arrow-circle-down"></i> fa-arrow-circle-down</div>
                  <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-arrow-circle-left"></i> fa-arrow-circle-left</div>
                  <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-arrow-circle-o-down"></i> fa-arrow-circle-o-down</div>
                  <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-arrow-circle-o-left"></i> fa-arrow-circle-o-left</div>
                  <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-arrow-circle-o-right"></i> fa-arrow-circle-o-right</div>
                  <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-arrow-circle-o-up"></i> fa-arrow-circle-o-up</div>
                  <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-arrow-circle-right"></i> fa-arrow-circle-right</div>
                  <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-arrow-circle-up"></i> fa-arrow-circle-up</div>
                  <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-arrow-down"></i> fa-arrow-down</div>
                  <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-arrow-left"></i> fa-arrow-left</div>
                  <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-arrow-right"></i> fa-arrow-right</div>
                  <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-arrow-up"></i> fa-arrow-up</div>
                  <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-arrows"></i> fa-arrows</div>
                  <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-arrows-alt"></i> fa-arrows-alt</div>
                  <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-arrows-h"></i> fa-arrows-h</div>
                  <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-arrows-v"></i> fa-arrows-v</div>
                  <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-caret-down"></i> fa-caret-down</div>
                  <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-caret-left"></i> fa-caret-left</div>
                  <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-caret-right"></i> fa-caret-right</div>
                  <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-caret-up"></i> fa-caret-up</div>
                  <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-caret-square-o-left"></i> fa-caret-square-o-left</div>
                  <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-caret-square-o-right"></i> fa-caret-square-o-right</div>
                  <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-caret-square-o-up"></i> fa-caret-square-o-up</div>
                  <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-caret-square-o-down"></i> fa-caret-square-o-down</div>
                  <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-chevron-circle-down"></i> fa-chevron-circle-down</div>
                  <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-chevron-circle-left"></i> fa-chevron-circle-left</div>
                  <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-chevron-circle-right"></i> fa-chevron-circle-right</div>
                  <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-chevron-circle-up"></i> fa-chevron-circle-up</div>
                  <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-chevron-down"></i> fa-chevron-down</div>
                  <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-chevron-left"></i> fa-chevron-left</div>
                  <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-chevron-right"></i> fa-chevron-right</div>
                  <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-chevron-up"></i> fa-chevron-up</div>
                  <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-hand-o-down"></i> fa-hand-o-down</div>
                  <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-hand-o-left"></i> fa-hand-o-left</div>
                  <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-hand-o-right"></i> fa-hand-o-right</div>
                  <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-hand-o-up"></i> fa-hand-o-up</div>
                  <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-long-arrow-down"></i> fa-long-arrow-down</div>
                  <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-long-arrow-left"></i> fa-long-arrow-left</div>
                  <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-long-arrow-right"></i> fa-long-arrow-right</div>
                  <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-long-arrow-up"></i> fa-long-arrow-up</div>
                  <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-toggle-down"></i> fa-toggle-down <span class="text-muted">(alias)</span></div>
                  <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-toggle-left"></i> fa-toggle-left <span class="text-muted">(alias)</span></div>
                  <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-toggle-right"></i> fa-toggle-right <span class="text-muted">(alias)</span></div>
                  <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-toggle-up"></i> fa-toggle-up <span class="text-muted">(alias)</span></div>
            </section>

            <br>

            <p>Video Player Icons</p>
            <section>
                <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-arrows-alt"></i> fa-arrows-alt</div>
                  <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-backward"></i> fa-backward</div>
                  <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-compress"></i> fa-compress</div>
                  <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-eject"></i> fa-eject</div>
                  <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-expand"></i> fa-expand</div>
                  <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-fast-backward"></i> fa-fast-backward</div>
                  <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-fast-forward"></i> fa-fast-forward</div>
                  <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-forward"></i> fa-forward</div>
                  <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-pause"></i> fa-pause</div>
                  <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-play"></i> fa-play</div>
                  <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-play-circle"></i> fa-play-circle</div>
                  <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-play-circle-o"></i> fa-play-circle-o</div>
                  <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-step-backward"></i> fa-step-backward</div>
                  <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-step-forward"></i> fa-step-forward</div>
                  <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-stop"></i> fa-stop</div>
                  <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-youtube-play"></i> fa-youtube-play</div>
            </section>

            <br>

            <p>Brand Icons</p>
            <section>
                <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-adn"></i> fa-adn</div>
                  <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-android"></i> fa-android</div>
                  <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-apple"></i> fa-apple</div>
                  <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-behance"></i> fa-behance</div>
                  <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-behance-square"></i> fa-behance-square</div>
                  <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-bitbucket"></i> fa-bitbucket</div>
                  <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-bitbucket-square"></i> fa-bitbucket-square</div>
                  <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-bitcoin"></i> fa-bitcoin <span class="text-muted">(alias)</span></div>
                  <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-btc"></i> fa-btc</div>
                  <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-codepen"></i> fa-codepen</div>
                  <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-css3"></i> fa-css3</div>
                  <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-delicious"></i> fa-delicious</div>
                  <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-deviantart"></i> fa-deviantart</div>
                  <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-digg"></i> fa-digg</div>
                  <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-dribbble"></i> fa-dribbble</div>
                  <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-dropbox"></i> fa-dropbox</div>
                  <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-drupal"></i> fa-drupal</div>
                  <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-empire"></i> fa-empire</div>
                  <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-facebook"></i> fa-facebook</div>
                  <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-facebook-square"></i> fa-facebook-square</div>
                  <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-flickr"></i> fa-flickr</div>
                  <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-foursquare"></i> fa-foursquare</div>
                  <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-ge"></i> fa-ge <span class="text-muted">(alias)</span></div>
                  <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-git"></i> fa-git</div>
                  <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-git-square"></i> fa-git-square</div>
                  <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-github"></i> fa-github</div>
                  <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-github-alt"></i> fa-github-alt</div>
                  <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-github-square"></i> fa-github-square</div>
                  <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-gittip"></i> fa-gittip</div>
                  <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-google"></i> fa-google</div>
                  <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-google-plus"></i> fa-google-plus</div>
                  <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-google-plus-square"></i> fa-google-plus-square</div>
                  <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-hacker-news"></i> fa-hacker-news</div>
                  <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-html5"></i> fa-html5</div>
                  <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-instagram"></i> fa-instagram</div>
                  <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-joomla"></i> fa-joomla</div>
                  <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-jsfiddle"></i> fa-jsfiddle</div>
                  <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-linkedin"></i> fa-linkedin</div>
                  <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-linkedin-square"></i> fa-linkedin-square</div>
                  <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-linux"></i> fa-linux</div>
                  <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-maxcdn"></i> fa-maxcdn</div>
                  <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-openid"></i> fa-openid</div>
                  <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-pagelines"></i> fa-pagelines</div>
                  <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-pied-piper"></i> fa-pied-piper</div>
                  <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-pied-piper-alt"></i> fa-pied-piper-alt</div>
                  <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-pinterest"></i> fa-pinterest</div>
                  <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-pinterest-square"></i> fa-pinterest-square</div>
                  <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-qq"></i> fa-qq</div>
                  <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-ra"></i> fa-ra <span class="text-muted">(alias)</span></div>
                  <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-rebel"></i> fa-rebel</div>
                  <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-reddit"></i> fa-reddit</div>
                  <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-reddit-square"></i> fa-reddit-square</div>
                  <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-renren"></i> fa-renren</div>
                  <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-share-alt"></i> fa-share-alt</div>
                  <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-share-alt-square"></i> fa-share-alt-square</div>
                  <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-skype"></i> fa-skype</div>
                  <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-slack"></i> fa-slack</div>
                  <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-soundcloud"></i> fa-soundcloud</div>
                  <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-spotify"></i> fa-spotify</div>
                  <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-stack-exchange"></i> fa-stack-exchange</div>
                  <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-stack-overflow"></i> fa-stack-overflow</div>
                  <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-steam"></i> fa-steam</div>
                  <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-steam-square"></i> fa-steam-square</div>
                  <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-stumbleupon"></i> fa-stumbleupon</div>
                  <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-stumbleupon-circle"></i> fa-stumbleupon-circle</div>
                  <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-tencent-weibo"></i> fa-tencent-weibo</div>
                  <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-trello"></i> fa-trello</div>
                  <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-tumblr"></i> fa-tumblr</div>
                  <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-tumblr-square"></i> fa-tumblr-square</div>
                  <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-twitter"></i> fa-twitter</div>
                  <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-twitter-square"></i> fa-twitter-square</div>
                  <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-vimeo-square"></i> fa-vimeo-square</div>
                  <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-vine"></i> fa-vine</div>
                  <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-vk"></i> fa-vk</div>
                  <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-wechat"></i> fa-wechat <span class="text-muted">(alias)</span></div>
                  <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-weibo"></i> fa-weibo</div>
                  <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-weixin"></i> fa-weixin</div>
                  <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-windows"></i> fa-windows</div>
                  <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-wordpress"></i> fa-wordpress</div>
                  <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-xing"></i> fa-xing</div>
                  <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-xing-square"></i> fa-xing-square</div>
                  <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-yahoo"></i> fa-yahoo</div>
                  <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-youtube"></i> fa-youtube</div>
                  <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-youtube-play"></i> fa-youtube-play</div>
                  <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-youtube-square"></i> fa-youtube-square</div>
            </section>

            <br>

            <p>Medical Icons</p>
            <section>
                <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-ambulance"></i> fa-ambulance</div>
                  <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-h-square"></i> fa-h-square</div>
                  <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-hospital-o"></i> fa-hospital-o</div>
                  <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-medkit"></i> fa-medkit</div>
                  <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-plus-square"></i> fa-plus-square</div>
                  <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-stethoscope"></i> fa-stethoscope</div>
                  <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-user-md"></i> fa-user-md</div>
                  <div class="col-sm-6 col-md-4 col-xl-3"><i class="fa fa-wheelchair"></i> fa-wheelchair</div>
            </section>
    `;

    const icons = parseIconString(iconString);


    const fileUploadHandler = (event) => {
        const serAccKeyFile = event.target.files[0];
        const serAccKeyFileName = event.target.files[0].name;
        console.log('reading json file data of,', serAccKeyFileName);

        if (serAccKeyFile) {
            const reader = new FileReader();
            
            reader.onload = (e) => {
                const fileContent = e.target.result; // The content of the file as a string
                console.log('fileContent:', fileContent);
                setKeyFileData(fileContent);
                setKeyFileName(serAccKeyFileName);
            };

            reader.readAsText(serAccKeyFile);
        }
    }

    // Function to update the `tableConstants` state
    const updateTableConstants = () => {
        try {
          const updatedTableConstants = {
            tableTheadFontSize: tableTheadFontSizeRef.current.value,
            tableTheadFontColor: tableTheadFontColorRef.current.value,
            tableTheadFontFamily: tableTheadFontFamilyRef.current.value,
            tableTheadRowBackgroundColor: tableTheadRowBackgroundColorRef.current.value,
            tableTheadRowLinearGradient1: tableTheadRowLinearGradient1Ref.current.value,
            tableTheadRowLinearGradient2: tableTheadRowLinearGradient2Ref.current.value,
            tableTheadRowBorderTopWidth: tableTheadRowBorderTopWidthRef.current.value,
            tableTheadRowBorderTopType: tableTheadRowBorderTopTypeRef.current.value,
            tableTheadRowBorderTopColor: tableTheadRowBorderTopColorRef.current.value,
            tableTheadRowBorderRightWidth: tableTheadRowBorderRightWidthRef.current.value,
            tableTheadRowBorderRightType: tableTheadRowBorderRightTypeRef.current.value,
            tableTheadRowBorderRightColor: tableTheadRowBorderRightColorRef.current.value,
            tableTheadRowBorderBottomWidth: tableTheadRowBorderBottomWidthRef.current.value,
            tableTheadRowBorderBottomType: tableTheadRowBorderBottomTypeRef.current.value,
            tableTheadRowBorderBottomColor: tableTheadRowBorderBottomColorRef.current.value,
            tableTheadRowBorderLeftWidth: tableTheadRowBorderLeftWidthRef.current.value,
            tableTheadRowBorderLeftType: tableTheadRowBorderLeftTypeRef.current.value,
            tableTheadRowBorderLeftColor: tableTheadRowBorderLeftColorRef.current.value,

            tableTbodyFontSize: tableTbodyFontSizeRef.current.value,
            tableTbodyFontColor: tableTbodyFontColorRef.current.value,
            tableTbodyFontFamily: tableTbodyFontFamilyRef.current.value,
            tableTbodyTextAlign: tableTbodyTextAlignRef.current.value,
            tableTbodyRowOddBackground: tableTbodyRowOddBackgroundRef.current.value,
            tableTbodyRowEvenBackground: tableTbodyRowEvenBackgroundRef.current.value,
            tableTbodyRowHoverColor: tableTbodyRowHoverColorRef.current.value,
            tableTbodyRowBorderTopWidth: tableTbodyRowBorderTopWidthRef.current.value,
            tableTbodyRowBorderTopType: tableTbodyRowBorderTopTypeRef.current.value,
            tableTbodyRowBorderTopColor: tableTbodyRowBorderTopColorRef.current.value,
            tableTbodyRowBorderRightWidth: tableTbodyRowBorderRightWidthRef.current.value,
            tableTbodyRowBorderRightType: tableTbodyRowBorderRightTypeRef.current.value,
            tableTbodyRowBorderRightColor: tableTbodyRowBorderRightColorRef.current.value,
            tableTbodyRowBorderBottomWidth: tableTbodyRowBorderBottomWidthRef.current.value,
            tableTbodyRowBorderBottomType: tableTbodyRowBorderBottomTypeRef.current.value,
            tableTbodyRowBorderBottomColor: tableTbodyRowBorderBottomColorRef.current.value,
            tableTbodyRowBorderLeftWidth: tableTbodyRowBorderLeftWidthRef.current.value,
            tableTbodyRowBorderLeftType: tableTbodyRowBorderLeftTypeRef.current.value,
            tableTbodyRowBorderLeftColor: tableTbodyRowBorderLeftColorRef.current.value,

            tableTfootFontSize: tableTfootFontSizeRef.current.value,
            tableTfootFontColor: tableTfootFontColorRef.current.value,
            tableTfootFontFamily: tableTfootFontFamilyRef.current.value,
            tableTfootRowBackgroundColor: tableTfootRowBackgroundColorRef.current.value,
            tableTfootRowLinearGradient1: tableTfootRowLinearGradient1Ref.current.value,
            tableTfootRowLinearGradient2: tableTfootRowLinearGradient2Ref.current.value,
            tableTfootRowBorderTopWidth: tableTfootRowBorderTopWidthRef.current.value,
            tableTfootRowBorderTopType: tableTfootRowBorderTopTypeRef.current.value,
            tableTfootRowBorderTopColor: tableTfootRowBorderTopColorRef.current.value,
            tableTfootRowBorderRightWidth: tableTfootRowBorderRightWidthRef.current.value,
            tableTfootRowBorderRightType: tableTfootRowBorderRightTypeRef.current.value,
            tableTfootRowBorderRightColor: tableTfootRowBorderRightColorRef.current.value,
            tableTfootRowBorderBottomWidth: tableTfootRowBorderBottomWidthRef.current.value,
            tableTfootRowBorderBottomType: tableTfootRowBorderBottomTypeRef.current.value,
            tableTfootRowBorderBottomColor: tableTfootRowBorderBottomColorRef.current.value,
            tableTfootRowBorderLeftWidth: tableTfootRowBorderLeftWidthRef.current.value,
            tableTfootRowBorderLeftType: tableTfootRowBorderLeftTypeRef.current.value,
            tableTfootRowBorderLeftColor: tableTfootRowBorderLeftColorRef.current.value,
          };

          setTableConstants(updatedTableConstants);
          toast.success('Table constants updated successfully.', notifyTheme2);
        } catch (error) {
          console.error('Error updating table constants:', error);
          toast.error('Failed to update table constants. Please try again.', successTheme);
        }
    };

    const createProjectHandler = async () => {
        if (projectName.length > 1) {
            try {
                console.log(projectName, analyticCode, keyFileName, keyFileData, app,baseUrl2, app,baseUrl3, app,baseUrl4, app,baseUrl5);

                // const formData = new FormData();
                // formData.append('project_name', projectName);
                // formData.append('facebook_id', fbId);
                // formData.append('instagram_id', instaId);
                // formData.append('twitter_id', twitterId);
                // formData.append('linkedIn_id', linkedInId);
                // formData.append('analytics_code', analyticCode);
                // formData.append('serAccKeyFile', serAccKeyFile);
                // formData.append('application_type', app);

                const userData = {
                    project_name: projectName,
                    facebook_id: fbId,
                    instagram_id: instaId,
                    twitter_id: twitterId,
                    linkedIn_id: linkedInId,
                    analytics_code: analyticCode,
                    keyFileName: keyFileName,
                    keyFileData: keyFileData,
                    // gCloudServiceAccId: gCloudServiceAccId,
                    // gCloudProjectId: gCloudProjectId,
                    // gmailAccount: gmailAccount,
                    baseUrl1: API_HOST,
                    baseUrl2: baseUrl2,
                    baseUrl3: baseUrl3,
                    baseUrl4: baseUrl4,
                    baseUrl5: baseUrl5,
                    application_type: app,
                    // table_constants: tableConstants,
                };

                // Conditionally add table_constants if not empty
                if (Object.keys(tableConstants).length > 0) {
                  userData.table_constants = tableConstants;
                }

                let koreBotData = {
                    koreAPIUrl: koreBotAPIUrl,
                    JWTUrl: koreBotJWTUrl,
                    koreAPIUrlbotInfoName: koreBotInfoName,
                    userIdentity: koreBotUserIdentity,
                    botInfoId: koreBotInfoID,
                    clientId: koreClientID,
                    clientSecret: koreClientSecret,
                    clientSecretbrandingAPIUrl: koreBotBrandUrl,
                    chatbot_name: koreBotName,
                };

                console.log('userData, koreBotData, tableConstants', userData, koreBotData, tableConstants);

                // formData.append('userData', JSON.stringify(userData));

                const config = {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${userInfo.token}`
                    }
                }

                const { data } = await axios.post(`${API_HOST}projects`, userData, config)
                setProjectCreated(data);
                toast.success(`Project Successfully Created`, errorTheme)
                if(data !== undefined && koreBotData.clientId !== undefined && koreBotData.clientId.length > 1 && koreBotInfoName !==undefined && koreBotInfoName.length > 1){
                    const fetchedProjectId = data._id;
                    koreBotData.projectId = fetchedProjectId;
                    localStorage.setItem("project_id", fetchedProjectId);
                    try {                        
                        const koreConfig = {
                            headers: {
                                'Content-Type': 'application/json',
                                Authorization: `Bearer ${userInfo.token}`,
                            }
                        }   
                        const { koreBotResdata } = await axios.post(`${API_HOST}chatbots/api/chatbots/`, koreBotData, koreConfig);

                    } catch (error) {
                        toast.error(`${error.response.data.message}`, errorTheme)
                    }
                }
            } catch (error) {
                toast.error(`${error.response.data.message}`, errorTheme)
            }

        } else if (!projectName) {
            toast.error("Project Name is required !!!", errorTheme)
        }
    }

    const logOutHandler = () => {
        dispatch(logout())
    }

    const cancelHandler = () => {
        navigate(`/`)
    }

    useEffect(() => {
        if (!userInfo?.token) {
            navigate('/login')
        }
    }, [userInfo])

    // return (
    //     <div>
    //         <div className="flex-container">

    //             <div className="hero">

    //                 <img className="login-d-bb" src={darkBubble} />


    //                 <img className="login-l-bb" src={lightBubble} />

    //                 <div className="profile-wrapper-form">
    //                     <div className="morph-div-profile">
    //                         <p className="morph-project-text">{`Projects `}</p>
    //                         <div className="morph-header-project">
    //                             <p style={{ fontSize: '1.8rem' }} className="morph-head-text">ADD NEW PROJECT</p>
    //                             <p className="morph-head-project"></p>
    //                         </div>

    //                         <div className="wrap-project-form" style={{ marginTop: '2rem' }}>
    //                             <form className="project-form">
    //                                 <div className="user-form-first">
    //                                     <div className="form-element">
    //                                         <p>
    //                                             <label for="firstName">Project Name</label>
    //                                         </p>
    //                                         <Tooltip title="Required" arrow >
    //                                             <input id="firstName" value={projectName} onChange={(e) => setProjectName(e.target.value)} placeholder="Enter Project Name" />
    //                                         </Tooltip>
    //                                     </div>
    //                                     <div className="form-element">
    //                                         <p>
    //                                             <label for="lastName">Facebook id</label>
    //                                         </p>
    //                                         <Tooltip title="add later" arrow>
    //                                             <input
    //                                                 type="text"
    //                                                 id="lastName"
    //                                                 placeholder="Enter your Facebook id"
    //                                                 value={fbId}
    //                                                 onChange={(e) => setFbId(e.target.value)}
    //                                             />
    //                                         </Tooltip>
    //                                     </div>
    //                                 </div>

    //                                 <div className="user-form-first" style={{ marginTop: "8px" }}>
    //                                     <div className="form-element">
    //                                         <p>
    //                                             <label for="date">Instagram id</label>
    //                                         </p>
    //                                         <Tooltip title="add later" arrow>
    //                                             <input
    //                                                 type="text"
    //                                                 id="date"
    //                                                 placeholder="Enter your Instagram id"
    //                                                 value={instaId}
    //                                                 onChange={(e) => setInstaId(e.target.value)}
    //                                             />
    //                                         </Tooltip>
    //                                     </div>
    //                                     <div className="form-element">
    //                                         <p>
    //                                             <label for="gender">Twitter id</label>
    //                                         </p>
    //                                         <Tooltip title="add later" arrow>
    //                                             <input
    //                                                 type="text"
    //                                                 id="gender"
    //                                                 placeholder="Enter your Twitter id"
    //                                                 value={twitterId}
    //                                                 onChange={(e) => setTwitterId(e.target.value)}
    //                                             />
    //                                         </Tooltip>
    //                                     </div>
    //                                 </div>

    //                                 <div className="user-form-first" style={{ marginTop: "8px" }}>
    //                                     <div className="form-element">
    //                                         <p>
    //                                             <label for="email">LinkedIn id</label>
    //                                         </p>
    //                                         <Tooltip title="add later" arrow>
    //                                             <input id="email" type="text" placeholder="Enter your LinkedIn id" value={linkedInId}
    //                                                 onChange={(e) => setLinkedInId(e.target.value)} />
    //                                         </Tooltip>
    //                                     </div>
    //                                 </div>
    //                             </form>
    //                             <div className="project-form-last" style={{ marginTop: "8px" }}>
    //                                 <div className="form-element-1">
    //                                     <p><label for="company">Google Analytics Code</label></p>
    //                                     <Tooltip title="add later" arrow>
    //                                         <textarea
    //                                             className="project-last-input"
    //                                             id="company"
    //                                             style={{ height: '12vh', padding: '1vh 1vw' }}
    //                                             placeholder="Google Analytics Code"
    //                                             name=">"
    //                                             rows="8"
    //                                             value={analyticCode}
    //                                             onChange={(e) => setAnalyticCode(e.target.value)}
    //                                         ></textarea>
    //                                     </Tooltip>
    //                                 </div>


    //                             </div>
    //                             <div className="project-form-last" style={{ marginTop: "8px" }}>
    //                                 <div className="form-element-1">
    //                                     <p><label for="company"> Analytics Code (other)</label></p>
    //                                     <Tooltip title="add later" arrow>
    //                                         <textarea
    //                                             className="project-last-input"
    //                                             id="company"
    //                                             style={{ height: '12vh', padding: '1vh 1vw' }}
    //                                             placeholder="Other Analytics Code"
    //                                             name=">"
    //                                             rows="8"
    //                                         ></textarea>
    //                                     </Tooltip>
    //                                 </div>
    //                                 <div className="">
    //                                     <button onClick={createProjectHandler} style={{ padding: '1rem 3rem' }} className="project-form-submit">
    //                                         {`Create Project`}
    //                                     </button>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //         </div>

    //     </div>
    // );


    return (
        <div className={classes['wrapper']}>
            <img className={classes['top-redSquare']} src={redSquare} />
            <img className={classes['top-cyanSquare']} src={cyanSquare} />

            <img className={classes['bottom-redSquare']} src={redSquare} />
            <img className={classes['bottom-cyanSquare']} src={cyanSquare} />

            <div className={classes['header']}>
                <KeyboardBackspaceIcon onClick={() => navigate(-1)} sx={{ mt: 2, color: "gray", cursor: "pointer", zIndex: "100", "&:hover": { color: "orange" } }} />
                <button className={classes['red-gradient-button']} onClick={logOutHandler}>Logout</button>
            </div>

            <br />

            <section className={classes['add-new-project-form-section']}>
                <div className={classes['form-header']}>
                    creating a new project
                    <img src={fileImage} />
                </div>

                {/* bootstrap tabs and its classes */}
                <ul class="nav nav-tabs" id="myTab" role="tablist">
                    <li class="nav-item" role="presentation">
                        <button class="nav-link active" id="project-name-tab" data-bs-toggle="tab" data-bs-target="#project-name-tab-pane" type="button" role="tab" aria-controls="project-name-tab-pane" aria-selected="true">Project Name</button>
                    </li>
                    
                    <li class="nav-item" role="presentation">
                        <button class="nav-link" id="project-base-urls-tab" data-bs-toggle="tab" data-bs-target="#project-base-urls-tab-pane" type="button" role="tab" aria-controls="project-base-urls-tab-pane" aria-selected="false">Project Base URLs</button>
                    </li>
                    
                    <li class="nav-item" role="presentation">
                        <button class="nav-link" id="project-analytics-tab" data-bs-toggle="tab" data-bs-target="#project-analytics-tab-pane" type="button" role="tab" aria-controls="project-analytics-tab-pane" aria-selected="false">Project Analytics</button>
                    </li>
                    
                    <li class="nav-item" role="presentation">
                        <button class="nav-link" id="social-media-ids-tab" data-bs-toggle="tab" data-bs-target="#social-media-ids-tab-pane" type="button" role="tab" aria-controls="social-media-ids-tab-pane" aria-selected="false">Social Media IDs</button>
                    </li>
                    
                    <li class="nav-item" role="presentation">
                        <button class="nav-link" id="project-chatbot-tab" data-bs-toggle="tab" data-bs-target="#project-chatbot-tab-pane" type="button" role="tab" aria-controls="project-chatbot-tab-pane" aria-selected="false">Project Chatbot</button>
                    </li>
                    
                    <li class="nav-item" role="presentation">
                        <button class="nav-link" id="global-constants-tab" data-bs-toggle="tab" data-bs-target="#global-constants-tab-pane" type="button" role="tab" aria-controls="global-constants-tab-pane" aria-selected="false">Global Constants</button>
                    </li>
                </ul>
                <div class="tab-content" id="myTabContent">
                    <div class="tab-pane fade show active" id="project-name-tab-pane" role="tabpanel" aria-labelledby="project-name-tab" tabindex="0">
                        <form className={classes['add-new-project-form']}>
                            <div className={classes['add-new-project-form-field']}>
                                <label className={classes['add-new-project-form-field-label']} htmlFor="firstName">Project Name <span className={classes['required-field']}>(Required)</span></label>
                                <input
                                    type="text"
                                    id="firstName"
                                    placeholder="Enter your project's name"
                                    onChange={(e) => setProjectName(e.target.value)}
                                    value={projectName}
                                    autoFocus
                                />
                            </div>
                        </form>
                    </div>

                    <div class="tab-pane fade" id="project-base-urls-tab-pane" role="tabpanel" aria-labelledby="project-base-urls-tab" tabindex="0">
                        <form className={classes['add-new-project-form']}>

                            <div className={classes['add-new-project-form-field']}>
                                <label className={classes['add-new-project-form-field-label']} htmlFor="base-url-1">Base URL #1</label>
                                <input
                                    type="text"
                                    id="base-url-1"
                                    placeholder="Enter a base URL"
                                    // onChange={(e) => setBaseUrl1(e.target.value)}
                                    value={API_HOST}
                                    disabled
                                    style={{color: "gray", cursor: "not-allowed"}}
                                />
                            </div>

                            <div className={classes['add-new-project-form-field']}>
                                <label className={classes['add-new-project-form-field-label']} htmlFor="base-url-2">Base URL #2</label>
                                <input
                                    type="text"
                                    id="base-url-2"
                                    placeholder="Enter a base URL"
                                    onChange={(e) => setBaseUrl2(e.target.value)}
                                    value={baseUrl2}
                                />
                            </div>

                            <div className={classes['add-new-project-form-field']}>
                                <label className={classes['add-new-project-form-field-label']} htmlFor="base-url-3">Base URL #3</label>
                                <input
                                    type="text"
                                    id="base-url-3"
                                    placeholder="Enter a base URL"
                                    onChange={(e) => setBaseUrl3(e.target.value)}
                                    value={baseUrl3}
                                />
                            </div>

                            <div className={classes['add-new-project-form-field']}>
                                <label className={classes['add-new-project-form-field-label']} htmlFor="base-url-4">Base URL #4</label>
                                <input
                                    type="text"
                                    id="base-url-4"
                                    placeholder="Enter a base URL"
                                    onChange={(e) => setBaseUrl4(e.target.value)}
                                    value={baseUrl4}
                                />
                            </div>

                            <div className={classes['add-new-project-form-field']}>
                                <label className={classes['add-new-project-form-field-label']} htmlFor="base-url-5">Base URL #5</label>
                                <input
                                    type="text"
                                    id="base-url-5"
                                    placeholder="Enter a base URL"
                                    onChange={(e) => setBaseUrl5(e.target.value)}
                                    value={baseUrl5}
                                />
                            </div>

                        </form>
                    </div>

                    <div class="tab-pane fade" id="project-analytics-tab-pane" role="tabpanel" aria-labelledby="project-analytics-tab" tabindex="0">
                        <form className={classes['add-new-project-form']}>
                            <div className={classes['add-new-project-form-field']}>
                                <label className={classes['add-new-project-form-field-label']} htmlFor="company">GA4 Property ID</label>
                                <input
                                    type="text"
                                    id="company"
                                    placeholder="Enter your GA4 Property ID"
                                    onChange={(e) => setAnalyticCode(e.target.value)}
                                    value={analyticCode}
                                />
                            </div>

                            <div className={classes['add-new-project-form-field']}>
                                <label className={classes['add-new-project-form-field-label']} htmlFor="company4">Service Account Key File</label>
                                <input
                                    type="file"
                                    accept=".json"
                                    id="company4"
                                    onChange={fileUploadHandler}
                                />
                            </div>

                            {/*<div className={classes['add-new-project-form-field']}>
                                <label className={classes['add-new-project-form-field-label']} htmlFor="company1">GCloud Service Account ID</label>
                                <input
                                    type="text"
                                    id="company1"
                                    placeholder="Enter your GCloud Service Account ID"
                                    onChange={(e) => setGCloudServiceAccId(e.target.value)}
                                    value={gCloudServiceAccId}
                                />
                            </div>*/}

                            {/*<div className={classes['add-new-project-form-field']}>
                                <label className={classes['add-new-project-form-field-label']} htmlFor="company2">GCloud Project ID</label>
                                <input
                                    type="text"
                                    id="company2"
                                    placeholder="Enter your GCloud Project ID"
                                    onChange={(e) => setGCloudProjectId(e.target.value)}
                                    value={gCloudProjectId}
                                />
                            </div>*/}

                            {/*<div className={classes['add-new-project-form-field']}>
                                <label className={classes['add-new-project-form-field-label']} htmlFor="company3">Email Address For Your Google Account</label>
                                <input
                                    type="email"
                                    id="company3"
                                    placeholder="Enter your email address for your Google account"
                                    onChange={(e) => setGmailAccount(e.target.value)}
                                    value={gmailAccount}
                                />
                            </div>*/}

                            <div className={classes['add-new-project-form-field']}>
                                <label className={classes['add-new-project-form-field-label']} htmlFor="other">Other Analytics Code</label>
                                
                                <textarea
                                    id="other"
                                    name=">"
                                    rows="4"
                                    placeholder="Enter any other Analytics Code"
                                    onChange={(e) => setAnalyticCode(e.target.value)}
                                />
                            </div>
                        </form>
                    </div>

                    <div class="tab-pane fade" id="social-media-ids-tab-pane" role="tabpanel" aria-labelledby="social-media-ids-tab" tabindex="0">
                        <form className={classes['add-new-project-form']}>
                            <div className={classes['add-new-project-form-field']}>
                                <label className={classes['add-new-project-form-field-label']} htmlFor="lastName">Facebook ID</label>
                                <input
                                    type="text"
                                    id="lastName"
                                    placeholder="Enter your Facebook accound/ID"
                                    onChange={(e) => setFbId(e.target.value)}
                                    value={fbId}
                                />
                            </div>

                            <div className={classes['add-new-project-form-field']}>
                                <label className={classes['add-new-project-form-field-label']} htmlFor="date">Instagram ID</label>
                                <input
                                    type="text"
                                    id="date"
                                    placeholder="Enter your Instagram accound/ID"
                                    onChange={(e) => setInstaId(e.target.value)}
                                    value={instaId}
                                />
                            </div>

                            <div className={classes['add-new-project-form-field']}>
                                <label className={classes['add-new-project-form-field-label']} htmlFor="gender">Twitter ID</label>
                                <input
                                    type="text"
                                    id="gender"
                                    placeholder="Enter your Twitter accound/ID"
                                    onChange={(e) => setTwitterId(e.target.value)}
                                    value={twitterId}
                                />
                            </div>

                            <div className={classes['add-new-project-form-field']}>
                                <label className={classes['add-new-project-form-field-label']} htmlFor="email">LinkedIn ID</label>
                                <input
                                    type="text"
                                    id="email"
                                    placeholder="Enter your LinkedIn accound/ID"
                                    onChange={(e) => setLinkedInId(e.target.value)}
                                    value={linkedInId}
                                />
                            </div>
                        </form>
                    </div>

                    <div class="tab-pane fade" id="project-chatbot-tab-pane" role="tabpanel" aria-labelledby="project-chatbot-tab" tabindex="0">
                        <form className={classes['add-new-project-form']}>
                            <div className={classes['add-new-project-form-field']}>
                                <label className={classes['add-new-project-form-field-label']} htmlFor="other">Kore.ai Bot Client ID</label>
                                <input
                                    type="text"
                                    id="botclientid"
                                    placeholder="Enter Client ID"
                                    onChange={(e) => setKoreClientID(e.target.value)}
                                    value={koreClientID}
                                />
                            </div>

                            <div className={classes['add-new-project-form-field']}>
                                <label className={classes['add-new-project-form-field-label']} htmlFor="other">Kore.ai Bot Client Secret</label>
                                <input
                                    type="text"
                                    id="botclientsecret"
                                    placeholder="Enter Client Secret"
                                    onChange={(e) => setKoreClientSecret(e.target.value)}
                                    value={koreClientSecret}
                                />
                            </div>

                            <div className={classes['add-new-project-form-field']}>
                                <label className={classes['add-new-project-form-field-label']} htmlFor="other">Kore.ai Bot Info ID</label>
                                <input
                                    type="text"
                                    id="botinfoid"
                                    placeholder="Enter Bot Info ID"
                                    onChange={(e) => setKoreBotInfoID(e.target.value)}
                                    value={koreBotInfoID}
                                />
                            </div>

                            <div className={classes['add-new-project-form-field']}>
                                <label className={classes['add-new-project-form-field-label']} htmlFor="other">Kore Bot User Identity</label>
                                <input
                                    type="text"
                                    id="botuseridentity"
                                    placeholder="Enter Kore Bot User Identity"
                                    onChange={(e) => setKoreBotUserIdentity(e.target.value)}
                                    value={koreBotUserIdentity}
                                />
                            </div>

                            <div className={classes['add-new-project-form-field']}>
                                <label className={classes['add-new-project-form-field-label']} htmlFor="other">Kore Bot Branding API URL</label>
                                <input
                                    type="text"
                                    id="botbrandurl"
                                    placeholder="Enter Branding API URL"
                                    onChange={(e) => setKoreBotBrandUrl(e.target.value)}
                                    value={koreBotBrandUrl}
                                />
                            </div>                    

                            <div className={classes['add-new-project-form-field']}>
                                <label className={classes['add-new-project-form-field-label']} htmlFor="other">Kore.ai Bot Name</label>
                                <input
                                    type="text"
                                    id="korebotname"
                                    placeholder="Enter Bot Name"
                                    onChange={(e) => setKoreBotName(e.target.value)}
                                    value={koreBotName}
                                />
                            </div>                   

                            <div className={classes['add-new-project-form-field']}>
                                <label className={classes['add-new-project-form-field-label']} htmlFor="other">Kore.ai Bot Info Name</label>
                                <input
                                    type="text"
                                    id="korebotinfoname"
                                    placeholder="Enter Bot Info Name"
                                    onChange={(e) => setKoreBotInfoName(e.target.value)}
                                    value={koreBotInfoName}
                                />
                            </div>                   

                            <div className={classes['add-new-project-form-field']}>
                                <label className={classes['add-new-project-form-field-label']} htmlFor="other">Kore.ai JWT URL</label>
                                <input
                                    type="text"
                                    id="korebotjwtURL"
                                    placeholder="Enter Bot JWT URL"
                                    onChange={(e) => setKoreBotJWTUrl(e.target.value)}
                                    value={koreBotJWTUrl}
                                />
                            </div>                  

                            <div className={classes['add-new-project-form-field']}>
                                <label className={classes['add-new-project-form-field-label']} htmlFor="other">Kore.ai API URL</label>
                                <input
                                    type="text"
                                    id="korebotapiURL"
                                    placeholder="Enter Bot API URL"
                                    onChange={(e) => setKoreBotAPIUrl(e.target.value)}
                                    value={koreBotAPIUrl}
                                />
                            </div>
                        </form>
                    </div>

                    <div class="tab-pane fade" id="global-constants-tab-pane" role="tabpanel" aria-labelledby="global-constants-tab" tabindex="0">
                        <form className={classes['add-new-project-form']}>
                            {/* NAVBAR -- CONSTANTS */}
                            <div className={classes['constants-block']} onClick={handleModal1Open}>
                                {/* icon */}
                                <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi bi-segmented-nav" viewBox="0 0 16 16">
                                    <path d="M0 6a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v2a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm6 3h4V5H6zm9-1V6a1 1 0 0 0-1-1h-3v4h3a1 1 0 0 0 1-1"/>
                                </svg>
                                <br/>
                                {/* block label */}
                                <p>Navbar Constants</p>
                            </div>
                            {/* modal content */}
                            <GCModal isOpen={isModal1Open} onClose={handleModal1Close}>
                                <div className={classes['add-new-project-form-field-with-divider']}>
                                    <label className={classes['add-new-project-form-field-label']} htmlFor="init-nav-bar" title="You can disable this constant for a particular page from, the page create/update sections.">Initialize a nav bar, in all pages of this project</label>
                                    <input
                                        type="checkbox"
                                        id="init-nav-bar"
                                        onChange={(e) => setInitializeNavBarInPages(e.target.checked)}
                                        value={initializeNavBarInPages}
                                        autoFocus
                                    />
                                </div>
                            </GCModal>

                            {/* TABLE PLUGIN -- CONSTANTS */}
                            <div className={classes['constants-block']} onClick={handleModal2Open}>
                                {/* icon */}
                                <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi bi-table" viewBox="0 0 16 16">
                                    <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm15 2h-4v3h4zm0 4h-4v3h4zm0 4h-4v3h3a1 1 0 0 0 1-1zm-5 3v-3H6v3zm-5 0v-3H1v2a1 1 0 0 0 1 1zm-4-4h4V8H1zm0-4h4V4H1zm5-3v3h4V4zm4 4H6v3h4z"/>
                                </svg>
                                <br/>
                                {/* block label */}
                                <p>Custom Table Constants</p>
                            </div>
                            {/* modal content */}
                            <GCModal isOpen={isModal2Open} onClose={handleModal2Close}>
                                <div className={classes['add-new-project-form-field-with-divider']}>
                                    <h3>Custom Table Head Constants</h3>
                                    <div className={classes['add-new-project-form-field-container']}>
                                        <div className={classes['add-new-project-form-field']}>
                                            <label className={classes['add-new-project-form-field-label']} htmlFor="table-thead-font-size" title="The unit is (vw); E.g., 2vw, 5vw">Font size, for header text (in vw)</label>
                                            <input
                                                type="number"
                                                id="table-thead-font-size"
                                                ref={tableTheadFontSizeRef}
                                                // onChange={(e) => setInitializeNavBarInPages(e.target.checked)}
                                                // value={initializeNavBarInPages}
                                            />
                                        </div>

                                        <div className={classes['add-new-project-form-field']}>
                                            <label className={classes['add-new-project-form-field-label']} htmlFor="table-thead-font-color">Font color, for header text</label>
                                            <input
                                                type="color"
                                                id="table-thead-font-color"
                                                ref={tableTheadFontColorRef}
                                                // onChange={(e) => setInitializeNavBarInPages(e.target.checked)}
                                                // value={initializeNavBarInPages}
                                            />
                                        </div>

                                        <div className={classes['add-new-project-form-field']}>
                                            <label className={classes['add-new-project-form-field-label']} htmlFor="table-thead-font-family" title="Select Font Family">Font Family, for header text</label>
                                            <select 
                                                id="table-thead-font-family"
                                                ref={tableTheadFontFamilyRef}
                                                // onChange={(e) => setInitializeNavBarInPages(e.target.checked)}
                                                // value={initializeNavBarInPages}
                                            >
                                                <option value="Arial, sans-serif">Arial</option>
                                                <option value="Helvetica, sans-serif">Helvetica</option>
                                                <option value="Times New Roman, serif">Times New Roman</option>
                                                <option value="Times, serif">Times</option>
                                                <option value="Courier New, monospace">Courier New</option>
                                                <option value="Courier, monospace">Courier</option>
                                                <option value="Verdana, sans-serif">Verdana</option>
                                                <option value="Georgia, serif">Georgia</option>
                                                <option value="Palatino, serif">Palatino</option>
                                                <option value="Garamond, serif">Garamond</option>
                                                <option value="Bookman, serif">Bookman</option>
                                                <option value="Comic Sans MS, cursive">Comic Sans MS</option>
                                                <option value="Trebuchet MS, sans-serif">Trebuchet MS</option>
                                                <option value="Arial Black, sans-serif">Arial Black</option>
                                                <option value="Impact, sans-serif">Impact</option>
                                            </select>
                                        </div>

                                        <div className={classes['add-new-project-form-field']}>
                                            <label className={classes['add-new-project-form-field-label']} htmlFor="table-thead-row-background-color">Background color, for header row</label>
                                            <input
                                                type="color"
                                                id="table-thead-row-background-color"
                                                ref={tableTheadRowBackgroundColorRef}
                                                // onChange={(e) => setInitializeNavBarInPages(e.target.checked)}
                                                defaultValue="#D3D3D3"
                                            />
                                        </div>

                                        <div className={classes['add-new-project-form-field']}>
                                            <label className={classes['add-new-project-form-field-label']} htmlFor="table-thead-row-linear-gradient-1">Linear gradient, for header row</label>
                                            <input
                                                type="color"
                                                id="table-thead-row-linear-gradient-1"
                                                ref={tableTheadRowLinearGradient1Ref}
                                                // onChange={(e) => setInitializeNavBarInPages(e.target.checked)}
                                                defaultValue="#714BEE"
                                            />
                                            <input
                                                type="color"
                                                id="table-thead-row-linear-gradient-2"
                                                ref={tableTheadRowLinearGradient2Ref}
                                                // onChange={(e) => setInitializeNavBarInPages(e.target.checked)}
                                                defaultValue="#005C8F"
                                            />
                                        </div>

                                        <div className={classes['add-new-project-form-field']}>
                                            <label className={classes['add-new-project-form-field-label']} htmlFor="table-thead-row-border-top-width" title="The unit is (px); E.g., 2px, 5px">Top border width, for header row (in px)</label>
                                            <input
                                                type="number"
                                                id="table-thead-row-border-top-width"
                                                min="0"
                                                ref={tableTheadRowBorderTopWidthRef}
                                                // onChange={(e) => setInitializeNavBarInPages(e.target.checked)}
                                                // value={initializeNavBarInPages}
                                            />
                                            <select id="table-thead-row-border-top-type" ref={tableTheadRowBorderTopTypeRef}>
                                                <option value="solid">Solid</option>
                                                <option value="dotted">Dotted</option>
                                                <option value="dashed">Dashed</option>
                                                <option value="double">Double</option>
                                                <option value="groove">Groove</option>
                                                <option value="ridge">Ridge</option>
                                                <option value="inset">Inset</option>
                                                <option value="outset">Outset</option>
                                                <option value="none">None</option>
                                                <option value="hidden">Hidden</option>
                                            </select>
                                            <input
                                                type="color"
                                                id="table-thead-row-border-top-color"
                                                ref={tableTheadRowBorderTopColorRef}
                                                // onChange={(e) => setInitializeNavBarInPages(e.target.checked)}
                                                // value={initializeNavBarInPages}
                                            />
                                        </div>

                                        <div className={classes['add-new-project-form-field']}>
                                            <label className={classes['add-new-project-form-field-label']} htmlFor="table-thead-row-border-right-width" title="The unit is (px); E.g., 2px, 5px">Right border width, for header row (in px)</label>
                                            <input
                                                type="number"
                                                id="table-thead-row-border-right-width"
                                                min="0"
                                                ref={tableTheadRowBorderRightWidthRef}
                                                // onChange={(e) => setInitializeNavBarInPages(e.target.checked)}
                                                // value={initializeNavBarInPages}
                                            />
                                            <select id="table-thead-row-border-right-type" ref={tableTheadRowBorderRightTypeRef}>
                                                <option value="solid">Solid</option>
                                                <option value="dotted">Dotted</option>
                                                <option value="dashed">Dashed</option>
                                                <option value="double">Double</option>
                                                <option value="groove">Groove</option>
                                                <option value="ridge">Ridge</option>
                                                <option value="inset">Inset</option>
                                                <option value="outset">Outset</option>
                                                <option value="none">None</option>
                                                <option value="hidden">Hidden</option>
                                            </select>
                                            <input
                                                type="color"
                                                id="table-thead-row-border-right-color"
                                                ref={tableTheadRowBorderRightColorRef}
                                                // onChange={(e) => setInitializeNavBarInPages(e.target.checked)}
                                                // value={initializeNavBarInPages}
                                            />
                                        </div>

                                        <div className={classes['add-new-project-form-field']}>
                                            <label className={classes['add-new-project-form-field-label']} htmlFor="table-thead-row-border-bottom-width" title="The unit is (px); E.g., 2px, 5px">Bottom border width, for header row (in px)</label>
                                            <input
                                                type="number"
                                                id="table-thead-row-border-bottom-width"
                                                min="0"
                                                ref={tableTheadRowBorderBottomWidthRef}
                                                // onChange={(e) => setInitializeNavBarInPages(e.target.checked)}
                                                // value={initializeNavBarInPages}
                                            />
                                            <select id="table-thead-row-border-bottom-type" ref={tableTheadRowBorderBottomTypeRef}>
                                                <option value="solid">Solid</option>
                                                <option value="dotted">Dotted</option>
                                                <option value="dashed">Dashed</option>
                                                <option value="double">Double</option>
                                                <option value="groove">Groove</option>
                                                <option value="ridge">Ridge</option>
                                                <option value="inset">Inset</option>
                                                <option value="outset">Outset</option>
                                                <option value="none">None</option>
                                                <option value="hidden">Hidden</option>
                                            </select>
                                            <input
                                                type="color"
                                                id="table-thead-row-border-bottom-color"
                                                ref={tableTheadRowBorderBottomColorRef}
                                                // onChange={(e) => setInitializeNavBarInPages(e.target.checked)}
                                                // value={initializeNavBarInPages}
                                            />
                                        </div>

                                        <div className={classes['add-new-project-form-field']}>
                                            <label className={classes['add-new-project-form-field-label']} htmlFor="table-thead-row-border-left-width" title="The unit is (px); E.g., 2px, 5px">Left border width, for header row (in px)</label>
                                            <input
                                                type="number"
                                                id="table-thead-row-border-left-width"
                                                min="0"
                                                ref={tableTheadRowBorderLeftWidthRef}
                                                // onChange={(e) => setInitializeNavBarInPages(e.target.checked)}
                                                // value={initializeNavBarInPages}
                                            />
                                            <select id="table-thead-row-border-left-type" ref={tableTheadRowBorderLeftTypeRef}>
                                                <option value="solid">Solid</option>
                                                <option value="dotted">Dotted</option>
                                                <option value="dashed">Dashed</option>
                                                <option value="double">Double</option>
                                                <option value="groove">Groove</option>
                                                <option value="ridge">Ridge</option>
                                                <option value="inset">Inset</option>
                                                <option value="outset">Outset</option>
                                                <option value="none">None</option>
                                                <option value="hidden">Hidden</option>
                                            </select>
                                            <input
                                                type="color"
                                                id="table-thead-row-border-left-color"
                                                ref={tableTheadRowBorderLeftColorRef}
                                                // onChange={(e) => setInitializeNavBarInPages(e.target.checked)}
                                                // value={initializeNavBarInPages}
                                            />
                                        </div>
                                    </div>

                                    <h3>Custom Table Body Constants</h3>
                                    <div className={classes['add-new-project-form-field-container']}>
                                        <div className={classes['add-new-project-form-field']}>
                                            <label className={classes['add-new-project-form-field-label']} htmlFor="table-tbody-font-size" title="The unit is (vw); E.g., 2vw, 5vw">Font size, for body text (in vw)</label>
                                            <input
                                                type="number"
                                                id="table-tbody-font-size"
                                                ref={tableTbodyFontSizeRef}
                                                // onChange={(e) => setInitializeNavBarInPages(e.target.checked)}
                                                // value={initializeNavBarInPages}
                                            />
                                        </div>

                                        <div className={classes['add-new-project-form-field']}>
                                            <label className={classes['add-new-project-form-field-label']} htmlFor="table-tbody-font-color">Font color, for body text</label>
                                            <input
                                                type="color"
                                                id="table-tbody-font-color"
                                                ref={tableTbodyFontColorRef}
                                                // onChange={(e) => setInitializeNavBarInPages(e.target.checked)}
                                                // value={initializeNavBarInPages}
                                            />
                                        </div>

                                        <div className={classes['add-new-project-form-field']}>
                                            <label className={classes['add-new-project-form-field-label']} htmlFor="table-tbody-font-family" title="Select Font Family">Font Family, for body text</label>
                                            <select 
                                                id="table-tbody-font-family"
                                                ref={tableTbodyFontFamilyRef}
                                                // onChange={(e) => setInitializeNavBarInPages(e.target.checked)}
                                                // value={initializeNavBarInPages}
                                            >
                                                <option value="Arial, sans-serif">Arial</option>
                                                <option value="Helvetica, sans-serif">Helvetica</option>
                                                <option value="Times New Roman, serif">Times New Roman</option>
                                                <option value="Times, serif">Times</option>
                                                <option value="Courier New, monospace">Courier New</option>
                                                <option value="Courier, monospace">Courier</option>
                                                <option value="Verdana, sans-serif">Verdana</option>
                                                <option value="Georgia, serif">Georgia</option>
                                                <option value="Palatino, serif">Palatino</option>
                                                <option value="Garamond, serif">Garamond</option>
                                                <option value="Bookman, serif">Bookman</option>
                                                <option value="Comic Sans MS, cursive">Comic Sans MS</option>
                                                <option value="Trebuchet MS, sans-serif">Trebuchet MS</option>
                                                <option value="Arial Black, sans-serif">Arial Black</option>
                                                <option value="Impact, sans-serif">Impact</option>
                                            </select>
                                        </div>

                                        <div className={classes['add-new-project-form-field']}>
                                            <label className={classes['add-new-project-form-field-label']} htmlFor="table-tbody-row-text-align">Text alignment, for body row</label>
                                            <select
                                                id="table-tbody-row-text-align"
                                                ref={tableTbodyTextAlignRef}
                                            >
                                                <option value="left">Left</option>
                                                <option value="center">Center</option>
                                                <option value="right">Right</option>
                                            </select>
                                        </div>

                                        <div className={classes['add-new-project-form-field']}>
                                            <label className={classes['add-new-project-form-field-label']} htmlFor="table-tbody-row-odd-background-color">Background color, for odd index body row</label>
                                            <input
                                                type="color"
                                                id="table-tbody-row-odd-background-color"
                                                ref={tableTbodyRowOddBackgroundRef}
                                                // onChange={(e) => setInitializeNavBarInPages(e.target.checked)}
                                                defaultValue="#C3C8C8"
                                            />
                                        </div>

                                        <div className={classes['add-new-project-form-field']}>
                                            <label className={classes['add-new-project-form-field-label']} htmlFor="table-tbody-row-even-background-color">Background color, for even index body row</label>
                                            <input
                                                type="color"
                                                id="table-tbody-row-even-background-color"
                                                ref={tableTbodyRowEvenBackgroundRef}
                                                // onChange={(e) => setInitializeNavBarInPages(e.target.checked)}
                                                defaultValue="#EFF2F2"
                                            />
                                        </div>

                                        <div className={classes['add-new-project-form-field']}>
                                            <label className={classes['add-new-project-form-field-label']} htmlFor="table-tbody-row-hover-color">Hover color, for body row</label>
                                            <input
                                                type="color"
                                                id="table-tbody-row-hover-color"
                                                ref={tableTbodyRowHoverColorRef}
                                                // onChange={(e) => setInitializeNavBarInPages(e.target.checked)}
                                                defaultValue="#D3D3D3"
                                            />
                                        </div>

                                        <div className={classes['add-new-project-form-field']}>
                                            <label className={classes['add-new-project-form-field-label']} htmlFor="table-tbody-row-border-top-width" title="The unit is (px); E.g., 2px, 5px">Top border width, for body row (in px)</label>
                                            <input
                                                type="number"
                                                id="table-tbody-row-border-top-width"
                                                min="0"
                                                ref={tableTbodyRowBorderTopWidthRef}
                                                // onChange={(e) => setInitializeNavBarInPages(e.target.checked)}
                                                // value={initializeNavBarInPages}
                                            />
                                            <select id="table-tbody-row-border-top-type" ref={tableTbodyRowBorderTopTypeRef}>
                                                <option value="solid">Solid</option>
                                                <option value="dotted">Dotted</option>
                                                <option value="dashed">Dashed</option>
                                                <option value="double">Double</option>
                                                <option value="groove">Groove</option>
                                                <option value="ridge">Ridge</option>
                                                <option value="inset">Inset</option>
                                                <option value="outset">Outset</option>
                                                <option value="none">None</option>
                                                <option value="hidden">Hidden</option>
                                            </select>
                                            <input
                                                type="color"
                                                id="table-tbody-row-border-top-color"
                                                ref={tableTbodyRowBorderTopColorRef}
                                                // onChange={(e) => setInitializeNavBarInPages(e.target.checked)}
                                                // value={initializeNavBarInPages}
                                            />
                                        </div>

                                        <div className={classes['add-new-project-form-field']}>
                                            <label className={classes['add-new-project-form-field-label']} htmlFor="table-tbody-row-border-right-width" title="The unit is (px); E.g., 2px, 5px">Right border width, for body row (in px)</label>
                                            <input
                                                type="number"
                                                id="table-tbody-row-border-right-width"
                                                min="0"
                                                ref={tableTbodyRowBorderRightWidthRef}
                                                // onChange={(e) => setInitializeNavBarInPages(e.target.checked)}
                                                // value={initializeNavBarInPages}
                                            />
                                            <select id="table-tbody-row-border-right-type" ref={tableTbodyRowBorderRightTypeRef}>
                                                <option value="solid">Solid</option>
                                                <option value="dotted">Dotted</option>
                                                <option value="dashed">Dashed</option>
                                                <option value="double">Double</option>
                                                <option value="groove">Groove</option>
                                                <option value="ridge">Ridge</option>
                                                <option value="inset">Inset</option>
                                                <option value="outset">Outset</option>
                                                <option value="none">None</option>
                                                <option value="hidden">Hidden</option>
                                            </select>
                                            <input
                                                type="color"
                                                id="table-tbody-row-border-right-color"
                                                ref={tableTbodyRowBorderRightColorRef}
                                                // onChange={(e) => setInitializeNavBarInPages(e.target.checked)}
                                                // value={initializeNavBarInPages}
                                            />
                                        </div>

                                        <div className={classes['add-new-project-form-field']}>
                                            <label className={classes['add-new-project-form-field-label']} htmlFor="table-tbody-row-border-bottom-width" title="The unit is (px); E.g., 2px, 5px">Bottom border width, for body row (in px)</label>
                                            <input
                                                type="number"
                                                id="table-tbody-row-border-bottom-width"
                                                min="0"
                                                ref={tableTbodyRowBorderBottomWidthRef}
                                                // onChange={(e) => setInitializeNavBarInPages(e.target.checked)}
                                                // value={initializeNavBarInPages}
                                            />
                                            <select id="table-tbody-row-border-bottom-type" ref={tableTbodyRowBorderBottomTypeRef}>
                                                <option value="solid">Solid</option>
                                                <option value="dotted">Dotted</option>
                                                <option value="dashed">Dashed</option>
                                                <option value="double">Double</option>
                                                <option value="groove">Groove</option>
                                                <option value="ridge">Ridge</option>
                                                <option value="inset">Inset</option>
                                                <option value="outset">Outset</option>
                                                <option value="none">None</option>
                                                <option value="hidden">Hidden</option>
                                            </select>
                                            <input
                                                type="color"
                                                id="table-tbody-row-border-bottom-color"
                                                ref={tableTbodyRowBorderBottomColorRef}
                                                // onChange={(e) => setInitializeNavBarInPages(e.target.checked)}
                                                // value={initializeNavBarInPages}
                                            />
                                        </div>

                                        <div className={classes['add-new-project-form-field']}>
                                            <label className={classes['add-new-project-form-field-label']} htmlFor="table-tbody-row-border-left-width" title="The unit is (px); E.g., 2px, 5px">Left border width, for body row (in px)</label>
                                            <input
                                                type="number"
                                                id="table-tbody-row-border-left-width"
                                                min="0"
                                                ref={tableTbodyRowBorderLeftWidthRef}
                                                // onChange={(e) => setInitializeNavBarInPages(e.target.checked)}
                                                // value={initializeNavBarInPages}
                                            />
                                            <select id="table-tbody-row-border-left-type" ref={tableTbodyRowBorderLeftTypeRef}>
                                                <option value="solid">Solid</option>
                                                <option value="dotted">Dotted</option>
                                                <option value="dashed">Dashed</option>
                                                <option value="double">Double</option>
                                                <option value="groove">Groove</option>
                                                <option value="ridge">Ridge</option>
                                                <option value="inset">Inset</option>
                                                <option value="outset">Outset</option>
                                                <option value="none">None</option>
                                                <option value="hidden">Hidden</option>
                                            </select>
                                            <input
                                                type="color"
                                                id="table-tbody-row-border-left-color"
                                                ref={tableTbodyRowBorderLeftColorRef}
                                                // onChange={(e) => setInitializeNavBarInPages(e.target.checked)}
                                                // value={initializeNavBarInPages}
                                            />
                                        </div>
                                    </div>

                                    <h3>Custom Table Footer Constants</h3>
                                    <div className={classes['add-new-project-form-field-container']}>
                                        <div className={classes['add-new-project-form-field']}>
                                            <label className={classes['add-new-project-form-field-label']} htmlFor="table-tfoot-font-size" title="The unit is (vw); E.g., 2vw, 5vw">Font size, for footer text (in vw)</label>
                                            <input
                                                type="number"
                                                id="table-tfoot-font-size"
                                                ref={tableTfootFontSizeRef}
                                                // onChange={(e) => setInitializeNavBarInPages(e.target.checked)}
                                                // value={initializeNavBarInPages}
                                            />
                                        </div>

                                        <div className={classes['add-new-project-form-field']}>
                                            <label className={classes['add-new-project-form-field-label']} htmlFor="table-tfoot-font-color">Font color, for footer text</label>
                                            <input
                                                type="color"
                                                id="table-tfoot-font-color"
                                                ref={tableTfootFontColorRef}
                                                // onChange={(e) => setInitializeNavBarInPages(e.target.checked)}
                                                // value={initializeNavBarInPages}
                                            />
                                        </div>

                                        <div className={classes['add-new-project-form-field']}>
                                            <label className={classes['add-new-project-form-field-label']} htmlFor="table-tfoot-font-family" title="Select Font Family">Font Family, for footer text</label>
                                            <select 
                                                id="table-tfoot-font-family"
                                                ref={tableTfootFontFamilyRef}
                                                // onChange={(e) => setInitializeNavBarInPages(e.target.checked)}
                                                // value={initializeNavBarInPages}
                                            >
                                                <option value="Arial, sans-serif">Arial</option>
                                                <option value="Helvetica, sans-serif">Helvetica</option>
                                                <option value="Times New Roman, serif">Times New Roman</option>
                                                <option value="Times, serif">Times</option>
                                                <option value="Courier New, monospace">Courier New</option>
                                                <option value="Courier, monospace">Courier</option>
                                                <option value="Verdana, sans-serif">Verdana</option>
                                                <option value="Georgia, serif">Georgia</option>
                                                <option value="Palatino, serif">Palatino</option>
                                                <option value="Garamond, serif">Garamond</option>
                                                <option value="Bookman, serif">Bookman</option>
                                                <option value="Comic Sans MS, cursive">Comic Sans MS</option>
                                                <option value="Trebuchet MS, sans-serif">Trebuchet MS</option>
                                                <option value="Arial Black, sans-serif">Arial Black</option>
                                                <option value="Impact, sans-serif">Impact</option>
                                            </select>
                                        </div>

                                        <div className={classes['add-new-project-form-field']}>
                                            <label className={classes['add-new-project-form-field-label']} htmlFor="table-tfoot-row-background-color">Background color, for footer row</label>
                                            <input
                                                type="color"
                                                id="table-tfoot-row-background-color"
                                                ref={tableTfootRowBackgroundColorRef}
                                                // onChange={(e) => setInitializeNavBarInPages(e.target.checked)}
                                                defaultValue="#D3D3D3"
                                            />
                                        </div>

                                        <div className={classes['add-new-project-form-field']}>
                                            <label className={classes['add-new-project-form-field-label']} htmlFor="table-tfoot-row-linear-gradient-1">Linear gradient, for footer row</label>
                                            <input
                                                type="color"
                                                id="table-tfoot-row-linear-gradient-1"
                                                ref={tableTfootRowLinearGradient1Ref}
                                                // onChange={(e) => setInitializeNavBarInPages(e.target.checked)}
                                                defaultValue="#D3D3D3"
                                            />
                                            <input
                                                type="color"
                                                id="table-tfoot-row-linear-gradient-2"
                                                ref={tableTfootRowLinearGradient2Ref}
                                                // onChange={(e) => setInitializeNavBarInPages(e.target.checked)}
                                                defaultValue="#D3D3D3"
                                            />
                                        </div>

                                        <div className={classes['add-new-project-form-field']}>
                                            <label className={classes['add-new-project-form-field-label']} htmlFor="table-tfoot-row-border-top-width" title="The unit is (px); E.g., 2px, 5px">Top border width, for footer row (in px)</label>
                                            <input
                                                type="number"
                                                id="table-tfoot-row-border-top-width"
                                                min="0"
                                                ref={tableTfootRowBorderTopWidthRef}
                                                // onChange={(e) => setInitializeNavBarInPages(e.target.checked)}
                                                // value={initializeNavBarInPages}
                                            />
                                            <select id="table-tfoot-row-border-top-type" ref={tableTfootRowBorderTopTypeRef}>
                                                <option value="solid">Solid</option>
                                                <option value="dotted">Dotted</option>
                                                <option value="dashed">Dashed</option>
                                                <option value="double">Double</option>
                                                <option value="groove">Groove</option>
                                                <option value="ridge">Ridge</option>
                                                <option value="inset">Inset</option>
                                                <option value="outset">Outset</option>
                                                <option value="none">None</option>
                                                <option value="hidden">Hidden</option>
                                            </select>
                                            <input
                                                type="color"
                                                id="table-tfoot-row-border-top-color"
                                                ref={tableTfootRowBorderTopColorRef}
                                                // onChange={(e) => setInitializeNavBarInPages(e.target.checked)}
                                                // value={initializeNavBarInPages}
                                            />
                                        </div>

                                        <div className={classes['add-new-project-form-field']}>
                                            <label className={classes['add-new-project-form-field-label']} htmlFor="table-tfoot-row-border-right-width" title="The unit is (px); E.g., 2px, 5px">Right border width, for footer row (in px)</label>
                                            <input
                                                type="number"
                                                id="table-tfoot-row-border-right-width"
                                                min="0"
                                                ref={tableTfootRowBorderRightWidthRef}
                                                // onChange={(e) => setInitializeNavBarInPages(e.target.checked)}
                                                // value={initializeNavBarInPages}
                                            />
                                            <select id="table-tfoot-row-border-right-type" ref={tableTfootRowBorderRightTypeRef}>
                                                <option value="solid">Solid</option>
                                                <option value="dotted">Dotted</option>
                                                <option value="dashed">Dashed</option>
                                                <option value="double">Double</option>
                                                <option value="groove">Groove</option>
                                                <option value="ridge">Ridge</option>
                                                <option value="inset">Inset</option>
                                                <option value="outset">Outset</option>
                                                <option value="none">None</option>
                                                <option value="hidden">Hidden</option>
                                            </select>
                                            <input
                                                type="color"
                                                id="table-tfoot-row-border-right-color"
                                                ref={tableTfootRowBorderRightColorRef}
                                                // onChange={(e) => setInitializeNavBarInPages(e.target.checked)}
                                                // value={initializeNavBarInPages}
                                            />
                                        </div>

                                        <div className={classes['add-new-project-form-field']}>
                                            <label className={classes['add-new-project-form-field-label']} htmlFor="table-tfoot-row-border-bottom-width" title="The unit is (px); E.g., 2px, 5px">Bottom border width, for footer row (in px)</label>
                                            <input
                                                type="number"
                                                id="table-tfoot-row-border-bottom-width"
                                                min="0"
                                                ref={tableTfootRowBorderBottomWidthRef}
                                                // onChange={(e) => setInitializeNavBarInPages(e.target.checked)}
                                                // value={initializeNavBarInPages}
                                            />
                                            <select id="table-tfoot-row-border-bottom-type" ref={tableTfootRowBorderBottomTypeRef}>
                                                <option value="solid">Solid</option>
                                                <option value="dotted">Dotted</option>
                                                <option value="dashed">Dashed</option>
                                                <option value="double">Double</option>
                                                <option value="groove">Groove</option>
                                                <option value="ridge">Ridge</option>
                                                <option value="inset">Inset</option>
                                                <option value="outset">Outset</option>
                                                <option value="none">None</option>
                                                <option value="hidden">Hidden</option>
                                            </select>
                                            <input
                                                type="color"
                                                id="table-tfoot-row-border-bottom-color"
                                                ref={tableTfootRowBorderBottomColorRef}
                                                // onChange={(e) => setInitializeNavBarInPages(e.target.checked)}
                                                // value={initializeNavBarInPages}
                                            />
                                        </div>

                                        <div className={classes['add-new-project-form-field']}>
                                            <label className={classes['add-new-project-form-field-label']} htmlFor="table-tfoot-row-border-left-width" title="The unit is (px); E.g., 2px, 5px">Left border width, for footer row (in px)</label>
                                            <input
                                                type="number"
                                                id="table-tfoot-row-border-left-width"
                                                min="0"
                                                ref={tableTfootRowBorderLeftWidthRef}
                                                // onChange={(e) => setInitializeNavBarInPages(e.target.checked)}
                                                // value={initializeNavBarInPages}
                                            />
                                            <select id="table-tfoot-row-border-left-type" ref={tableTfootRowBorderLeftTypeRef}>
                                                <option value="solid">Solid</option>
                                                <option value="dotted">Dotted</option>
                                                <option value="dashed">Dashed</option>
                                                <option value="double">Double</option>
                                                <option value="groove">Groove</option>
                                                <option value="ridge">Ridge</option>
                                                <option value="inset">Inset</option>
                                                <option value="outset">Outset</option>
                                                <option value="none">None</option>
                                                <option value="hidden">Hidden</option>
                                            </select>
                                            <input
                                                type="color"
                                                id="table-tfoot-row-border-left-color"
                                                ref={tableTfootRowBorderLeftColorRef}
                                                // onChange={(e) => setInitializeNavBarInPages(e.target.checked)}
                                                // value={initializeNavBarInPages}
                                            />
                                        </div>
                                    </div>

                                    <button className={classes['custom-button']} onClick={updateTableConstants}>Save Table Constant</button>
                                </div>
                            </GCModal>                        
                        </form>
                    </div>
                </div>

                {/*<form className={classes['add-new-project-form']}></form>*/}

                <div className={classes['form-footer']}>
                    <button className={classes['red-gradient-button']} onClick={createProjectHandler}>create project</button>
                    <button className={classes['white-gradient-button']} onClick={cancelHandler}>cancel</button>
                </div>
            </section>

            <ToastContainer />
        </div>
    );
}

export default CreateProjectScreen