import React from 'react'
// import http from 'http'
import axios from 'axios';
// import { Http } from '@mui/icons-material';
// import cors_proxy from 'cors-anywhere'

class SAPTest extends React.Component {
    state = {
        res: ""
    }

    componentDidMount() {


        const getData2 = () => {

            axios.get("http://0.0.0.0:8080/183.82.1.220:8000/wem/customer_master?sap-client=800&ID=175", {
                // proxy: {
                //     host: "183.82.1.220",
                //     port: "8000",
                //     // auth: {username: proxyServer.username, password: proxyServer.password}
                // },
                headers: {
                    // 'User-Agent': 'Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/42.0.2311.90 Safari/537.36',            
                    Authorization: 'Basic ' + btoa("sltech1:Svsbsc_143")
                }
            }).then((response) => {
                console.log(response)
                this.setState(prevState => ({
                    ...prevState,
                    res: JSON.stringify(response.data)
                }))
            }).catch(err => {
                console.log(err.response.data)
            })
        }
        getData2();


    }


    render() {
        return (
            <div>
                <h2>CHeck</h2>
                {this.state.res}
            </div>
        )
    }
}

export default SAPTest