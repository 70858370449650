import React from 'react'
import redSlingLogo from './component_assets/images/redSlingLogo.png'
import vector from './component_assets/images/Vector.png'
// import AllSale from './component_assets/images/AllSale.png'
// import upGreen from './component_assets/images/upGreen.png'
// import dropdownDash from './component_assets/images/dropdownDash.png'
// import pinkDiv from './component_assets/images/pinkDiv.png'
// import AllStats from './component_assets/images/AllStats.png'
// import purpleDiv from './component_assets/images/purpleDiv.png'
// import unknownImg from './component_assets/images/unknownImg.png'
// import jam_smiley from './component_assets/images/jam_smiley.png'
// import smileGreen from './component_assets/images/smileGreen.png'
// import Group2 from './component_assets/images/Group 239.png'
// import confused from './component_assets/images/confused.png'
// import dashboardRect1 from './component_assets/images/dashboardRect1.png'

// import redSlingLogo from './component_assets/images/details_close.png'
// import vector from './component_assets/images/details_close.png'
import AllSale from './component_assets/images/details_close.png'
import upGreen from './component_assets/images/details_close.png'
import dropdownDash from './component_assets/images/details_close.png'
import pinkDiv from './component_assets/images/details_close.png'
import AllStats from './component_assets/images/details_close.png'
import purpleDiv from './component_assets/images/details_close.png'
import unknownImg from './component_assets/images/details_close.png'
import jam_smiley from './component_assets/images/details_close.png'
import smileGreen from './component_assets/images/details_close.png'
import Group2 from './component_assets/images/details_close.png'
import confused from './component_assets/images/details_close.png'
import dashboardRect1 from './component_assets/images/details_close.png'
import './style.css'

function AnalyticsScreen() {
    return (
        <div>

            <div className="DashHead">
                <img src={redSlingLogo} />
                <div>
                    <p>Admin</p>
                    <img src={vector} />
                </div>
            </div>

            <div className="dashHero">

                <div className="heroLeft">
                    <p>dashboard</p>
                    <p>Orders</p>
                    <p>Products</p>
                </div>


                <div className="heroRight">
                    <p className="heroRightTopText">Dashboard</p>


                    <div className="dashMainContent">

                        <div className="dashContentLeft">
                            <div className="dashContentLeftHead">
                                <button>Overview</button>
                                <button>Analytics</button>
                            </div>
                            <p className="dashContentLeftText">
                                Here’s an overview of what’s happening today
                            </p>
                            <div className="leftContentBlue">
                                <div className="leftContentBlueHead">
                                    <p>TOTAL SALES</p>
                                    <p>
                                        <img src={AllSale} />
                                    </p>
                                </div>
                                <p className="leftContentBlueText">$4,330,606</p>
                                <div className="leftContentBlueBottom">
                                    <p className="leftContentBottomLeft">
                                        <img src={upGreen} /> <span>124</span>
                                    </p>
                                    <div className="leftContentBottomMonth">
                                        <p>MONTHLY</p>
                                        <p><span>-</span>35343</p>
                                    </div>
                                    <div className="leftContentBottomMonth1">
                                        <p>WEEKLY</p>
                                        <p>+231</p>
                                    </div>
                                </div>
                            </div>

                            <div className="leftContentPink">
                                <div className="leftContentPinkHead">
                                    <p>Orders</p>
                                    <p className="pinkHead1">Ordered Items</p>
                                    <p className="pinkHead1">Returned Items</p>
                                    <p className="pinkHead1">Fulfilled Orders</p>
                                </div>
                                <div className="leftContentPinkText">
                                    <p>30 days</p>
                                    <img src="../assets/images/dropdownDash.png" />
                                </div>
                                <img className="pinkDivAbs" src={pinkDiv} />
                            </div>

                            <div className="leftContentStats">
                                <p>Order Statics</p>
                                <button>
                                    <img src={AllStats} />
                                </button>
                            </div>

                            <div className="leftContentPurple">
                                <div className="leftContentPurpleInner">
                                    <p>25</p>
                                    <p>Pending</p>
                                </div>
                                <div className="leftContentPurpleInner">
                                    <p>12</p>
                                    <p>Shipping</p>
                                </div>
                                <div className="leftContentPurpleInner">
                                    <p>23</p>
                                    <p>Completed</p>
                                </div>
                                <img
                                    src={purpleDiv}
                                    className="leftContentPurpleAbsolute"
                                />
                            </div>

                            <p className="leftMainContentInvoice">Invoices</p>

                            <div style={{ overflowX: 'auto' }} className="leftMainContentInvoiceWrapper">


                                <table className="dashBoardTable">
                                    <tr>
                                        <th className="dashBoardTh">
                                            <p>Invoice ID</p>
                                        </th>
                                        <th className="dashBoardTh">
                                            <p>Customer</p>
                                        </th>
                                        <th className="dashBoardTh">
                                            <p>Status</p>
                                        </th>
                                        <th className="dashBoardTh">
                                            <p>Due Date</p>
                                        </th>
                                        <th className="dashBoardTh">
                                            <p>Action</p>
                                        </th>
                                    </tr>
                                    <tr>
                                        <td className="dashboardTd">
                                            <p>INV-876543</p>
                                        </td>
                                        <td className="dashboardTd">
                                            <p>John Doe</p>
                                        </td>
                                        <td className="dashboardTd">
                                            <p className="contentP1">PAID</p>
                                        </td>
                                        <td className="dashboardTd">
                                            <p>24 November, 2022</p>
                                        </td>
                                        <td className="dashboardTd">
                                            <p><button>Details</button></p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="dashboardTd">
                                            <p>INV-876543</p>
                                        </td>
                                        <td className="dashboardTd">
                                            <p>John Doe</p>
                                        </td>
                                        <td className="dashboardTd">
                                            <p className="contentP1">PAID</p>
                                        </td>
                                        <td className="dashboardTd">
                                            <p>24 November, 2022</p>
                                        </td>
                                        <td className="dashboardTd">
                                            <p><button>Details</button></p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="dashboardTd">
                                            <p>INV-876543</p>
                                        </td>
                                        <td className="dashboardTd">
                                            <p>John Doe</p>
                                        </td>
                                        <td className="dashboardTd">
                                            <p className="contentP2">UNPAID</p>
                                        </td>
                                        <td className="dashboardTd">
                                            <p>24 November, 2022</p>
                                        </td>
                                        <td className="dashboardTd">
                                            <p><button>Details</button></p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="dashboardTd">
                                            <p>INV-876543</p>
                                        </td>
                                        <td className="dashboardTd">
                                            <p>John Doe</p>
                                        </td>
                                        <td className="dashboardTd">
                                            <p className="contentP1">PAID</p>
                                        </td>
                                        <td className="dashboardTd">
                                            <p>24 November, 2022</p>
                                        </td>
                                        <td className="dashboardTd">
                                            <p><button>Details</button></p>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>


                        <div className="dashContentRight">
                            <div className="dashContentRightHead">
                                <p>Top 5 Products</p>
                                <div>
                                    <p>October</p>
                                    <img src={dropdownDash} />
                                </div>
                            </div>
                            <div className="dashContentRightImgWrapper">
                                <div className="dashContentRightImg">
                                    <img src={unknownImg} />
                                    <p>Product 1</p>
                                </div>
                                <div className="dashContentRightImg">
                                    <img src={unknownImg} />
                                    <p>Product 1</p>
                                </div>
                                <div className="dashContentRightImg">
                                    <img src={unknownImg} />
                                    <p>Product 1</p>
                                </div>
                                <div className="dashContentRightImg">
                                    <img src={unknownImg} />
                                    <p>Product 1</p>
                                </div>
                                <div className="dashContentRightImg">
                                    <img src={unknownImg} />
                                    <p>Product 1</p>
                                </div>
                            </div>

                            <div className="dashContentRightBottom">
                                <p className="dashContentRightBottomHead">All Feedback</p>
                                <p className="dashContentRightBottomHead1">CUSTOMER FEEDBACK</p>
                                <div className="dashContentRightBottomSmiley">
                                    <img src={jam_smiley} />
                                    <p>95.5%</p>
                                </div>
                                <div className="dashContentRightBottomWrapper">
                                    <div className="dashContentRightBottomLeft">
                                        <div className="dashContentRightBottomLeft1">
                                            <p>Great product!</p>
                                            <img src={smileGreen} />
                                        </div>
                                        <div className="dashContentRightBottomLeft2">
                                            <p>Best solution</p>
                                            <p>
                                                <img src={Group2} />
                                            </p>
                                        </div>
                                        <div className="dashContentRightBottomLeft3">
                                            <p>Can’t wait to</p>
                                        </div>
                                    </div>
                                    <div className="dashContentRightBottomRight">
                                        <div className="dashContentRightBottomRight1">
                                            <p>The product looks better in pictures</p>
                                            <img src={confused} />
                                        </div>
                                        <div className="dashContentRightBottomRight2">
                                            <p>Amazing product! Absolutely loved it!</p>
                                            <p>
                                                <img src={smileGreen} />
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <p className="dashBottomRect">
                                    <img src={dashboardRect1} />
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AnalyticsScreen