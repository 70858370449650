import React from 'react'
import { useDrag } from 'react-dnd'
// import { ItemTypes } from '../Constants/Constants'

export const DragBlocks = function Box ({ dragItem }) {
    const [{ isDragging }, drag] = useDrag(() => ({
        // type: "ALL",
        type: dragItem.type ? dragItem.type : "ABC",
        item: dragItem,
        end: (item, monitor) => {
            const dropResult = monitor.getDropResult(item);
            // if(item && dropResult) {
            //     alert(`You dropped ${dragItem.name} into ${dropResult.name}`)
            // }
        },
        options: {
            dropEffect: 'copy' // adds a plus symbol
    },
        collect: (monitor) => ({
            isDragging: !!monitor.isDragging(),
            handlerId: monitor.getHandlerId(),
        })
    }))

    const opacity = isDragging ? 0.4 : 1
    // console.log(dragItem.type)
    return (
        <div
         ref={drag}
         key={dragItem.id ? dragItem.id : dragItem._id} 
         style={{ 
        //     display: "flex", 
        //     width: "50%", 
        //     justifyContent: "center", 
        //     alignItems: "center", 
        //     height: "50px", 
        //     borderRadius: "4px", 
        //     backgroundColor: "red",
        //     marginTop: "10px", 
            opacity }} 
            data-testid={`box`}
         >
            {dragItem.name}
        </div>
    )
}
