import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "./Loader";
import {
  Button,
  Grid,
  Typography,
  Modal,
  TextField,
  Select,
  MenuItem,
  Chip,
  FormControl,
  OutlinedInput,
  InputLabel,
  Box,
} from "@mui/material";
import darkBubble from "./component_assets/images/darkBubble.png";
// import darkBubble from './component_assets/images/details_close.png'
// import lightBubble from './component_assets/images/details_close.png'
import lightBubble from "./component_assets/images/lightBubble.png";
import "./style.css";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import axios from "axios";
import { API_HOST } from "../api_utils";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import errorSymbol from "./component_assets/images/details_close.png";
// import errorSymbol from './component_assets/images/errorSymbol.svg'
import btnGrp from "./component_assets/images/details_close.png";
// import btnGrp from './component_assets/images/btnGrpRed.svg'
import errorWave from "./component_assets/images/details_close.png";
// import errorWave from './component_assets/images/errorWave.svg'
import { DataGrid } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import VisibilityIcon from "@mui/icons-material/Visibility";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function CronJobScreen() {
  let navigate = useNavigate();

  const [userList, setUserList] = useState([]);
  //const [functionList, setFunctionList] = useState([]);
  const [personName, setPersonName] = useState([]);
  const [loading, setLoading] = useState(false);
  const [cronJobList, setCronJobList] = useState([]);
  const [success, setSuccess] = useState(false);
  const [modalData, setModalData] = useState({});
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(false);
  const [selectedValues, setSelectedValues] = useState({});

  const handleChange = (event, rowId) => {
    const { value } = event.target;
    setSelectedValues((prevSelectedValues) => ({
      ...prevSelectedValues,
      [rowId]: value,
    }));
  };

  const errorTheme = {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
  };

  const { projectId } = useParams();

  const getListOfUsersUnderCompany = async () => {
    setLoading(true);
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await axios.get(
      `${API_HOST}projects/user_under_company/${projectId}`,
      config
    );

    if (data.message) {
      setLoading(false);
      toast.error(data.message, errorTheme);
    } else {
      setLoading(false);
      setUserList(data);
    }
  };

  const getCronJobList = async () => {
    setLoading(true);
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await axios.get(
      `${API_HOST}cron_job/get/${projectId}`,
      config
    );

    if (data.message) {
      setLoading(false);
      setError(true);
      toast.error(data.message, errorTheme);
    } else {
      setLoading(false);
      setCronJobList(data);
    }
  };

  // const getCustomFunctions = async () => {
  //   const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  //   const config = {
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: `Bearer ${userInfo.token}`,
  //     },
  //   };

  //   let payload = {
  //     project: projectId,
  //   };

  //   const { data } = await axios.post(
  //     `${API_HOST}custom_functions/`,
  //     payload,
  //     config
  //   );
   
  //   if (data.message) {
  //     toast.error(data.message);
  //   } else {
  //     setFunctionList(data);
  //   }
  // };

  useEffect(() => {
    getListOfUsersUnderCompany();
    getCronJobList();
    //getCustomFunctions();
  }, [success]);

  useEffect(() => {
    if (cronJobList.length) {
      // const rows1 = cronJobList.map((user) => {
      //     return createData(user._id, user.name, user.users, user.function_to_be_executed, user.type_of_job, user.start_date, user.end_date)
      // })
      // setRows(rows1)
    }
  }, [cronJobList]);

  const columns = [
    { field: "id", headerName: "ID", width: 90 },
    {
      field: "cronJobName",
      headerName: "Name",
      width: 150,
      editable: false,
    },
    {
      field: "jobType",
      headerName: "Job Type",
      width: 130,
      editable: false,
    },
    {
      field: "function",
      headerName: "Custom Functions",
      width: 400,
      renderCell: (params) => {
        return (
          <Typography>
            Custom Functions In Use :{" "}
            {params.row.function ? params.row.function.length : 0}
          </Typography>
        );
      },
    },
    {
      field: "currentUsers",
      headerName: "Users",
      width: 180,
      renderCell: (params) => {
        return (
          <Typography>
            Total Users : {params.row.currentUsers.length}
          </Typography>
        );
      },
    },
    {
      field: "View Details",
      headerName: "View Details",
      width: 150,
      renderCell: (params) => {
        return (
          <Typography
            onClick={(event) => {
              navigate(`/cron_job_detail/${params.id}?project=${projectId}`);
            }}
            sx={{
              color: "#e11d48",
              "&:hover": { color: "#fb7185", scale: "125%" },
            }}>
            <VisibilityIcon fontSize="medium" />
          </Typography>
        );
      },
    },
  ];

  const rows = cronJobList
    ? cronJobList.map((x) => {
        return {
          id: x._id,
          cronJobName: x.name,
          jobType: x.type_of_job !== "undefined" ? x.type_of_job : "---",
          function:
            x.function_to_be_executed !== "undefined"
              ? x.function_to_be_executed
              : "Currently No Functions Added",
          currentUsers: x.users !== "undefined" ? x.users : "---",
          start_date: x.start_date !== "undefined" ? x.start_date : "---",
          end_date: x.end_date !== "undefined" ? x.end_date : "---",
        };
      })
    : [
        {
          id: 1,
          projectName: "Snow",
          facebookId: "Jon",
          twitterId: "twitterId",
          linkedInId: "linkedInId",
          instagramId: "instagramId",
          analyticsCode: "analyticsCode",
        },
        {
          id: 2,
          projectName: "Snow",
          facebookId: "Jon",
          twitterId: "twitterId",
          linkedInId: "linkedInId",
          instagramId: "instagramId",
          analyticsCode: "analyticsCode",
        },
        {
          id: 3,
          projectName: "Snow",
          facebookId: "Jon",
          twitterId: "twitterId",
          linkedInId: "linkedInId",
          instagramId: "instagramId",
          analyticsCode: "analyticsCode",
        },
        {
          id: 4,
          projectName: "Snow",
          facebookId: "Jon",
          twitterId: "twitterId",
          linkedInId: "linkedInId",
          instagramId: "instagramId",
          analyticsCode: "analyticsCode",
        },
        {
          id: 5,
          projectName: "Snow",
          facebookId: "Jon",
          twitterId: "twitterId",
          linkedInId: "linkedInId",
          instagramId: "instagramId",
          analyticsCode: "analyticsCode",
        },
      ];

  return (
    <div style={{ height: "100vh" }}>
      {!cronJobList.length ? (
        <Grid
          sx={{
            height: "87.5vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            position: "relative",
            overflow: "hidden",
          }}>
          <Grid
            width={"80vw"}
            height={"80%"}
            sx={{
              padding: "2vh 0 2vh 3vw",
              borderRadius: "8px",
              backdropFilter: "blur(25px)",
              boxShadow: 20,
              zIndex: 20,
            }}>
            <Grid
              xs={12}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}>
              <Typography
                sx={{ fontFamily: "Mont-SemiBold", fontSize: "1.5rem" }}>
                Cron Jobs Created
              </Typography>
              <Grid
                xs={9}
                sx={{ backgroundColor: "red", height: "0.6vh" }}></Grid>
            </Grid>
            <Grid sx={{ display: "flex", justifyContent: "center", mt: 6 }}>
              <img src={errorSymbol} style={{ height: "30vh" }} />
            </Grid>
            <Typography
              sx={{
                textAlign: "center",
                mt: 4,
                fontSize: "1.2rem",
                fontFamily: "Mont-SemiBold",
              }}>
              Sorry! No Cron Jobs have been created yet.{" "}
            </Typography>
            <Grid sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
              {/* <Button
                onClick={() => navigate(`/create_cron_job/${projectId}`)}
                variant="contained"
                sx={{
                  backgroundColor: "white",
                  color: "black",
                  fontFamily: "Mont-Regular",
                }}>
                <span style={{ marginRight: "1vw" }}>Create Cron Job</span>
                <img src={btnGrp} style={{ height: "4vh" }} />
              </Button> */}
            </Grid>
          </Grid>
          <Grid sx={{ position: "absolute", bottom: "-80vh", right: "-20vw" }}>
            <img src={lightBubble} style={{ height: "160vh" }} />
          </Grid>
          <Grid sx={{ position: "absolute", top: "-12vh", right: "0" }}>
            <img src={errorWave} style={{ height: "180vh" }} />
          </Grid>
        </Grid>
      ) : (
        <Grid
          sx={{ position: "relative", overflow: "hidden", minHeight: "100vh" }}>
          <Grid
            sx={{
              position: "absolute",
              top: "-12vh",
              left: "-30vw",
              zIndex: "-10",
            }}>
            <img style={{ width: "55vw", height: "90%" }} src={darkBubble} />
          </Grid>

          <Grid
            sx={{
              position: "absolute",
              bottom: "-60%",
              right: "-15%",
              zIndex: "-10",
            }}>
            <img style={{ width: "55vw", height: "90%" }} src={lightBubble} />
          </Grid>

          <Grid
            sx={{
              display: "flex",
              justifyContent: "center",
              paddingTop: "10vh",
            }}>
            <Grid
              xs={12}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "0 3.5rem",
              }}
              container>
              <Typography
                sx={{ fontFamily: "Mont-SemiBold", fontSize: "1.8rem" }}>
                Cron Job Data
              </Typography>
              <Grid
                xs={8.5}
                sx={{ height: "1.5vh", backgroundColor: "red" }}
                item></Grid>
            </Grid>
          </Grid>
          <Grid
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              mt: 1,
              marginBottom: "3vh",
              paddingRight: "3rem",
            }}>
            <Button
              onClick={() => navigate(`/create_cron_job/${projectId}`)}
              variant="contained"
              sx={{
                backgroundColor: "red",
                color: "white",
                fontFamily: "Mont-Regular",
                "&:hover": { backgroundColor: "white", color: "red" },
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}>
              <span style={{ marginRight: "1vw" }}>Create New Cron Job</span>
              <img src={btnGrp} style={{ height: "4vh" }} />
            </Button>
          </Grid>

          <Grid
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              zIndex: "20",
              padding: "0 3.5rem 5rem 3.5rem",
              mt: 4,
              height: "70vh",
            }}
            container>
            <DataGrid
              rows={rows}
              columns={columns}
              pageSize={5}
              error={error}
              rowsPerPageOptions={[5]}
              checkboxSelection
              disableSelectionOnClick
            />
            {/* <TableContainer component={Paper} sx={{ backgroundColor: 'rgba(255, 255, 255, 0.5)', backdropFilter: 'blur(5px)' }} >
                                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align="center" sx={{ fontWeight: 600, fontSize: '1rem' }}>Name</TableCell>
                                                <TableCell align="center" sx={{ fontWeight: 600, fontSize: '1rem' }}>User's</TableCell>
                                                <TableCell align="center" sx={{ fontWeight: 600, fontSize: '1rem' }}>function_to_be_executed</TableCell>
                                                <TableCell align="center" sx={{ fontWeight: 600, fontSize: '1rem' }}>type_of_job</TableCell>
                                                <TableCell align="center" sx={{ fontWeight: 600, fontSize: '1rem' }}>Add User</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {rows.length && rows.map((row, index) => (
                                                <TableRow
                                                    key={`${row.id}+${index}`}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell align="center" component="th" scope="row">
                                                        {row.name}
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        {
                                                            row?.users.length ? row?.users.map((user) => {
                                                                let userRef = userList.length && userList.find((x) => x._id === user.user)
                                                                if (userRef) {
                                                                    return <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', marginTop: '0.5rem', marginBottom: '0.5rem'}}>
                                                                        <p>User Name : {userRef.name}</p>
                                                                        <p>Access : {user.access}</p>
                                                                        <p>Role : {user.role}</p>
                                                                    </div>
                                                                }
                                                            }) : 'Currently No User !!!'
                                                        }
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        {
                                                            row?.function_to_be_executed?.length ? row?.function_to_be_executed.map((user) => {
                                                                let functionRef = functionList.find((x) => x._id === user.function)
                                                                if (functionRef) {
                                                                    return <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', marginTop: '0.5rem', marginBottom: '0.5rem' }}>
                                                                        <p>Function Name : {functionRef.name}</p>
                                                                    </div>
                                                                }
                                                            }) : 'Currently No Functions Added !!!'
                                                        }
                                                    </TableCell>
                                                    <TableCell align="center" component="th" scope="row">
                                                        {row.type_of_job}
                                                    </TableCell>
                                                    <TableCell align="center" component="th" scope="row">
                                                        
                                                    </TableCell>
                                                    <TableCell align="center" sx={{ display: "flex", justifyContent: 'center', alignItems: 'center' }}>
                                                        <Button onClick={() => addUserToCronJobHandler(row.id)} variant='contained' sx={{ fontFamily: 'Mont-Regular', backgroundColor: 'red', marginBottom: '3vh', '&:hover': { color: 'red', backgroundColor: 'white' }, transitionDuration: '0.5s', display: "flex", justifyContent: "space-between", alignItems: 'center', ml: 2 }}>
                                                            <span style={{ marginRight: '0.5rem' }}>Add User</span>
                                                            <PersonAddAlt1Icon />
                                                        </Button>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer> */}
          </Grid>
        </Grid>
      )}

      <ToastContainer autoClose={2000} />
    </div>
  );
}

export default CronJobScreen;