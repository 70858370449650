import React, { useEffect } from 'react'
import { useState } from 'react'
import { useDrop } from 'react-dnd'


export default function ParameterDropped(props) {
    const [droppedItems, setDrop] = useState();
    const [assignClicked, setChangeAssignClicked] = useState(true);
    const [inputValue, setInputValue] = useState("")
    const [paramAdded, setParamAdded] = useState(false);

    

   
    const checkDroppedItems = () => {
        console.log(droppedItems)
        if(droppedItems.length > 0) {
            console.log("in false")
            return false
        } else {
            return true
        }
    }

    const addToCanvas =  (item) => {
        console.log(item);
        console.log(droppedItems)
        // if(droppedItems.length === 0) {
        //    return setDrop(prevState => [...prevState, item])
        // }
        setDrop(prevState => { 
            if(prevState?.droppedItems?.length > 0) {
                return [...prevState.droppedItems, item]
            }
            else {
                return item
            }
            
         });
        setParamAdded(true);
        // props.setAddingToCanvas(true)
        
    }


    const showAssignmentInput = () => {
        setChangeAssignClicked(true);
    }

    const pushToFunctionDroppable = (updatedDroppedItems) => {
        console.log(updatedDroppedItems)
        console.log(updatedDroppedItems.length)
        // props.receiveParameters(updatedDroppedItems, props.refId)
        props.receiveParameters2(updatedDroppedItems, props.refId)
    }

    const valueAssigned = (newInputValue) => { 
        console.log("New value ", inputValue)
        
        // let updatedDroppedItems = droppedItems.map((item, index) => 
        //     {
        //     if (i=== index){
        //         return {...item, currentValue: inputValue}; //gets everything that was already in item, and updates "done"
        //     }
        //     return item; // else return unmodified item 
        //     });

        // setDrop(updatedDroppedItems);
        let updatedDroppedItems = {...droppedItems, currentValue: inputValue, refId: props.refId};
        console.log("UPDATED DROPPED ITEMS =======",updatedDroppedItems)
        setDrop(updatedDroppedItems)
        pushToFunctionDroppable(updatedDroppedItems)
    }

    // useEffect(() => {
    //     console.log("Changed")
    // }, [droppedItems])
    // console.log(droppedItems)
    
    
    // USE A VARIABLE HERE TO DETECT IF ITEM HAS BEEN ADDED.
    // PASS BACK TO THE PARENT STATING THAT ITEM HAS BEEN ADDED.
    // HENCE THE PARENT ARRAY SHOULD NOT ADD TO IT.
    console.log("PARSMSS === ", props.params)
    return (
        <div>
            {/* Fix the following statement to make sure only the parameters dropped inside shouldn't be dropped outside */}
        {/* {droppedItems===null || droppedItems===undefined ?  */}
            (<div 
                // ref={drop}
            style={ props.height ?  {width: "150px", marginLeft: "10px", height: "100px", backgroundColor: "yellow"} : 
            {width: "150px", marginLeft: "10px", height: "100px", backgroundColor: "green"} 
        }
         >
         
            { props.params !== undefined ? (
                <div key={props.params._id+"parameter"} >
                    <div > 
                        {props.params.name}  {props.params.currentValue} 
                    </div>
                    <div>
                        {/* <button onClick={showAssignmentInput} style={{ borderRadius: "4px", width: '75px', height: "50px" }}> Assign Value </button>  */}
                    </div>
                    
                    {assignClicked ? (<div><input type="text" value={inputValue} onChange={(e) => setInputValue(e.target.value)} /> <button onClick={() => valueAssigned()}>Change</button>  </div>) : "" }
                    
                </div>
                )   
             : "" }
             
        </div>) 
            
       
        </div>
  )
}
