

export const GET_PAGE_BY_PROJECT_ID_LOADING = "GET_PAGE_BY_PROJECT_ID_LOADING"
export const GET_PAGE_BY_PROJECT_ID_SUCCESS = "GET_PAGE_BY_PROJECT_ID_SUCCESS"
export const GET_PAGE_BY_PROJECT_ID_FAILED = "GET_PAGE_BY_PROJECT_ID_FAILED"
export const GET_PAGE_BY_PROJECT_ID_EMPTY = "GET_PAGE_BY_PROJECT_ID_EMPTY"
export const RESET_PAGE_BY_PROJECT_ID = "RESET_PAGE_BY_PROJECT_ID"
// export const GET_PAGE_BY_PROJECT_SUCCESS_RESET='GET_PAGE_BY_PROJECT_SUCCESS_RESET'

export const GET_ALL_PAGES_LOADING = "GET_ALL_PAGES_LOADING"
export const GET_ALL_PAGES_SUCCESS = "GET_ALL_PAGES_SUCCESS"
export const GET_ALL_PAGES_FAILED = "GET_ALL_PAGES_FAILED"
export const GET_ALL_PAGES_EMPTY = "GET_ALL_PAGES_EMPTY"
// export const GET_ALL_PAGES_RESET="GET_ALL_PAGES_RESET"

export const UPDATE_PAGE_LOADING = "UPDATE_PAGE_LOADING"
export const UPDATE_PAGE_SUCCESS = "UPDATE_PAGE_SUCCESS"
export const UPDATE_PAGE_FAILED = "UPDATE_PAGE_FAILED"
export const UPDATE_PAGE_RESET = 'UPDATE_PAGE_RESET'

export const DELETE_PAGE_LOADING = "DELETE_PAGE_LOADING"
export const DELETE_PAGE_SUCCESS = "DELETE_PAGE_SUCCESS"
export const DELETE_PAGE_FAILED = "DELETE_PAGE_FAILED"
export const DELETE_PAGE_RESET = 'DELETE_PAGE_RESET'

export const COPY_PAGE_LOADING = "COPY_PAGE_LOADING"
export const COPY_PAGE_SUCCESS = "COPY_PAGE_SUCCESS"
export const COPY_PAGE_FAILED = "COPY_PAGE_FAILED"

