import React, {useState, useEffect} from 'react';

import classes from './LogicBuilder.module.css';

import FunctionBlockContent from './FunctionBlockContent';

import axios from 'axios';
// import { API_HOST } from '../../../api_utils';
import { API_HOST } from '../api_utils';
import { useDrag } from 'react-dnd'
import { connect } from 'react-redux';
import { DragBlocks } from '../Testing/Test3/blocks/DragBlocks';

import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'


const FunctionPanel = (props) => {
	const [isFunctionBlockContentOpen, setIsFunctionBlockContentOpen] = useState(null);
	const [allFuncs, loadFunctions] = useState([]);
    const [tempVars, loadTempVars] = useState([])
    // useEffect(() => {
	// to open the .function-block-content if the label is clicked
	
	useEffect(() => {
		if(allFuncs.length === 0) {
			if(props.projectData.length > 0) {
				console.log("DHERHEE")
				const data = {
					"user": props.user,
					"project": localStorage.getItem("projectId")
				}
				axios.post(`${API_HOST}functions/`, data).then(res=> {
					console.log(res)
					if(res.status === 200) {
						if(res.data.length > 0) {
							loadFunctions([...res.data])
						}
						
					}
					
				}).catch((err) => {
					console.log("err", err)
				})
	
		}
        
            // axios.post(`${API_HOST}temp_vars/`, data).then(res => {
            //     loadTempVars([...res.data])
            // })
        }
        
    },[allFuncs])

	function functionBlockContentOpenHandler(index) {
		setIsFunctionBlockContentOpen(index);
	}

	// to close the .function-block-content if the button is clicked
	function functionBlockContentCloseHandler() {
		setIsFunctionBlockContentOpen(null);
	}

	// (instead of statements array); here is where statement grapesjs-blocks will come with blockManager.add({});

	const functions = [
		'New Function 1',
		'New Function 2',
		'New Function 3',
		'New Function 4',
		'New Function 5'
	];

	return (
		// <DndProvider backend={HTML5Backend}>

		<div className={classes.function_panel}>

			<h3 style={{color: '#B6B5B5', marginTop: '5%'}}>Functions</h3>

			<div className={classes.function_holder}>
				{/* showing the blocks with forloop just for display, untill real blocks are added here */}

				{allFuncs.map((func, index) => (
					<div key={index} className={classes.function_block}>
						
						<div className={classes.function_block_label} onClick={() => functionBlockContentOpenHandler(index)}>
							{/* <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="red" className="bi bi-caret-right-fill" viewBox="0 0 16 16">
							  	<path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z"/>
							</svg>
							<p>{func}</p> */}
							<DragBlocks key={func._id} dragItem={func} />
						</div>

						{ isFunctionBlockContentOpen == index && <FunctionBlockContent onClose={functionBlockContentCloseHandler} /> }

					</div>
				))}
			</div>

			{/* receiving the statementPanelCloseHandler() through props and executing it here when onClick event is triggered */}
			<button className={classes.collapse_function_btn} onClick={props.onClose}>
				<span className="collapse-function-btn-icon">
					<svg xmlns="http://www.w3.org/2000/svg" fill="red" clasName="bi bi-x" viewBox="0 0 16 16" style={{width: '80%', height: '80%'}}>
					  	<path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
					</svg>
				</span>
			</button>

		</div>
		// </DndProvider>
	);
};


const mapStateToProps = (state) => {
    // console.log(state)
    return {
        projectData: state.allProjectData.allProjectData,
        user: state.userLogin.userInfo
    };
}

export default connect(mapStateToProps, {})(FunctionPanel);