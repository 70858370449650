import React from 'react'
import { useDrag } from 'react-dnd'
import { ItemTypes } from '../Constants/Constants'

const style = {
    border: '1px dashed gray',
    backgroundColor: 'white',
    padding: '0.5rem 1rem',
    marginRight: '1.5rem',
    marginBottom: '1.5rem',
    cursor: 'move',
    float: 'left',
}

export const Box = function Box ({ name }) {
    const [{ isDragging }, drag] = useDrag(() => ({
        type: ItemTypes.BOX,
        item: { name },
        end: (item, monitor) => {
            const dropResult = monitor.getDropResult();
            if(item && dropResult) {
                alert(`You dropped ${item.name} into ${dropResult.name}`)
            }
        },
        options: {
            dropEffect: 'copy' // adds a plus symbol
    },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
            handlerId: monitor.getHandlerId(),
        })
    }))

    const opacity = isDragging ? 0.4 : 1

    return (
        <div
         ref={drag}
         style={{ ...style, opacity }} data-testid={`box`}
         >
            {name}
        </div>
    )
}
