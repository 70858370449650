export const LOAD_FUNCTIONS_LOADING = 'LOAD_FUNCTIONS_LOADING'
export const LOAD_FUNCTIONS_FAILED = 'LOAD_FUNCTIONS_FAILED'
export const LOAD_FUNCTIONS_SUCCESS = 'LOAD_FUNCTIONS_SUCCESS'


export const CREATE_FUNCTION_LOADING = 'CREATE_FUNCTION_LOADING'
export const CREATE_FUNCTION_FAILED = 'CREATE_FUNCTION_FAILED';
export const CREATE_FUNCTION_SUCCESS = 'CREATE_FUNCTION_SUCCESS'


export const CLEAR_NEW_FUNC = 'CLEAR_NEW_FUNC'