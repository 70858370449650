import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import Loader from './Loader'
import { Button, Grid, Typography, Modal, TextField } from '@mui/material'
import darkBubble from './component_assets/images/darkBubble.png'
// import darkBubble from './component_assets/images/details_close.png'
// import lightBubble from './component_assets/images/details_close.png'
import lightBubble from './component_assets/images/lightBubble.png'
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import './style.css'
// import errorSymbol from './component_assets/images/details_close.png'
import errorSymbol from './component_assets/images/errorSymbol.svg'
// import btnGrp from './component_assets/images/details_close.png'
import btnGrp from './component_assets/images/btnGrpRed.svg'
// import errorWave from './component_assets/images/details_close.png'
import errorWave from './component_assets/images/errorWave.svg'
import DeleteIcon from '@mui/icons-material/Delete';
import RateReviewIcon from '@mui/icons-material/RateReview';
import { ToastContainer } from "react-toastify";
import CloseIcon from '@mui/icons-material/Close';
import { getAllModal } from '../Redux/Actions/modalTableActions'



function ModalTable() {

  let dispatch = useDispatch()
  let navigate = useNavigate()

  const { allModalData, loading, error } = useSelector(state => state.allModal)

  const [modalData, setModalData] = useState({})
  const [open, setOpen] = useState(false)
  const [name, setName] = useState('')
  const [metaDesc, setMetaDesc] = useState('')
  const [metaKey, setMetaKey] = useState('')
  const [metaTag, setMetaTag] = useState('')
  const [seo, setSeo] = useState('')
  const { project } = useParams()



  useEffect(() => {
    // dispatch(getPageByProjectId(projectId))
    // dispatch(getAllProject())
    dispatch(getAllModal())
  }, [])



  const editPageHandler = (id) => {
    navigate(`/editor/${id}`)
  }

  const handleClose = () => {
    setOpen(false)
  }


  const deletePageHandler = (id, e) => {
    // dispatch(deletePage(id))
    // e.stopPropagation()
  }





  const columns = [
    { field: 'id', headerName: 'ID', width: 90 },

    {
      field: 'projectId',
      headerName: 'project Id',
      width: 150,
      editable: true,
    },
    {
      field: 'tableName',
      headerName: 'Table Name',
      width: 130,
      editable: true,
    },
    {
      field: 'fields',
      headerName: 'Fields',
      width: 300,
      editable: true,
      renderCell: (params) => {
        return (


          params.row.fields.map((x) =>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
              <Typography sx={{ display: 'block', marginBottom: '-3vh' }}>
                Field Name : {x.field_name}
              </Typography><br />
              <Typography >
                Field Type : {x.field_type}
              </Typography>
            </Box>
          )



        )
      }
    },
    {
      field: 'editPage',
      headerName: 'Edit ',
      width: 70,
      renderCell: (params) => {
        return (
          <Typography onClick={(e) => editPageHandler(params.id, e)} sx={{ color: '#9333ea', '&:hover': { color: '#c084fc', scale: '125%' } }}>
            <RateReviewIcon fontSize="medium" />
          </Typography>
        )
      }
    },
    {
      field: 'Actions',
      headerName: '',
      width: 60,
      renderCell: (params) => {
        return (
          <Typography onClick={(event) => {
            event.stopPropagation(); deletePageHandler(params.id)
          }} sx={{ color: '#e11d48', '&:hover': { color: '#fb7185', scale: '125%' } }}>
            <DeleteIcon fontSize="medium" />
          </Typography>

        )
      }
    }
  ];



  const rows = allModalData ? allModalData?.map((x) => {
    return {
      id: x._id,
      projectId: x.project !== 'undefined' ? x.project : '---',
      tableName: x.table_name !== 'undefined' ? x.table_name : '---',
      fields: x.fields !== 'undefined' ? x.fields : '---',
      // metaKeywords: x.meta_keywords !== 'undefined' ? x.meta_keywords : '---',
      // metaTags: x.meta_tags !== 'undefined' ? x.meta_tags : '---',
      // author: x.author !== 'undefined' ? x.author : '---',
      lastEdit: x.updatedAt !== 'undefined' ? x.updatedAt : '---',
      // isHome: x.is_home !== 'undefined' ? x.is_home : false,
    }
  }) : [
    { id: 1, projectName: 'Snow', facebookId: 'Jon', twitterId: 'twitterId', linkedInId: 'linkedInId', instagramId: 'instagramId', analyticsCode: 'analyticsCode' },
    { id: 2, projectName: 'Snow', facebookId: 'Jon', twitterId: 'twitterId', linkedInId: 'linkedInId', instagramId: 'instagramId', analyticsCode: 'analyticsCode' },
    { id: 3, projectName: 'Snow', facebookId: 'Jon', twitterId: 'twitterId', linkedInId: 'linkedInId', instagramId: 'instagramId', analyticsCode: 'analyticsCode' },
    { id: 4, projectName: 'Snow', facebookId: 'Jon', twitterId: 'twitterId', linkedInId: 'linkedInId', instagramId: 'instagramId', analyticsCode: 'analyticsCode' },
    { id: 5, projectName: 'Snow', facebookId: 'Jon', twitterId: 'twitterId', linkedInId: 'linkedInId', instagramId: 'instagramId', analyticsCode: 'analyticsCode' },



  ];

  const createPageHandler = () => {
    // navigate(`/addNewPage/${projectId}`)
  }

  const handleRowEventHandler = (params) => {
    console.log("params", params);
    setModalData({
      name: params.row.pageName,
      seo_title: params.row.seoTitle,
      meta_description: params.row.metaDescription,
      meta_keywords: params.row.metaKeywords,
      meta_tags: params.row.metaTags,
      pageId: params.row.id,
    })
    setOpen(true)

  }


  const updateChangeHandler = () => {
    // dispatch(updatePage({ pageId: modalData.pageId, seo_title: seo, meta_description: metaDesc, meta_keywords: metaKey, meta_tags: metaTag, name: name }))
    // setOpen(false)
  }



  return (
    <div className="">
      {
        loading ? <Loader />
          // : emptyPage ?

          //   <Grid sx={{ height: '87.5vh', display: 'flex', justifyContent: "center", alignItems: 'center', flexDirection: 'column', position: 'relative', overflow: "hidden" }}>
          //     <Grid width={'80vw'} height={'80%'} sx={{ padding: '2vh 0 2vh 3vw', borderRadius: '8px', backdropFilter: 'blur(25px)', boxShadow: 20, zIndex: 20 }}>
          //       <Grid xs={12} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          //         <Typography sx={{ fontFamily: 'Mont-SemiBold', fontSize: '1.5rem' }}>Pages Created</Typography>
          //         <Grid xs={10} sx={{ backgroundColor: 'red', height: '0.6vh' }}></Grid>
          //       </Grid>
          //       <Grid sx={{ display: 'flex', justifyContent: 'center', mt: 6 }}>
          //         <img src={errorSymbol} style={{ height: '30vh' }} />
          //       </Grid>
          //       <Typography sx={{ textAlign: 'center', mt: 4, fontSize: '1.2rem', fontFamily: 'Mont-SemiBold' }}>Sorry! No pages have been created yet. </Typography>
          //       <Grid sx={{ display: 'flex', justifyContent: "center", mt: 4 }}>
          //         <Button onClick={createPageHandler} variant='contained' sx={{ backgroundColor: 'white', color: 'black', fontFamily: "Mont-Regular" }}>
          //           <span style={{ marginRight: '1vw' }}>Create New Page</span>
          //           <img src={btnGrp} style={{ height: '4vh' }} />
          //         </Button>
          //       </Grid>
          //     </Grid>
          //     <Grid sx={{ position: 'absolute', bottom: '-80vh', right: '-20vw' }}>
          //       <img src={lightBubble} style={{ height: '160vh' }} />
          //     </Grid>
          //     <Grid sx={{ position: 'absolute', top: '-12vh', right: '0' }}>
          //       <img src={errorWave} style={{ height: '180vh' }} />
          //     </Grid>
          //   </Grid>
          :
          <Grid sx={{ position: 'relative', overflow: 'hidden', height: '100vh', }}>
            <Grid sx={{ position: 'absolute', top: '-12vh', left: '-30vw', zIndex: '-10' }}>
              <img style={{ width: '55vw', height: '90%' }} src={darkBubble} />
            </Grid>


            <Grid sx={{ position: 'absolute', bottom: '-60%', right: '-15%' }}>
              <img style={{ width: '55vw', height: '90%' }} src={lightBubble} />
            </Grid>

            <Grid sx={{ display: 'flex', justifyContent: "center", paddingTop: '10vh' }}>
              <Box sx={{ minHeight: '58vh', width: '90%', backdropFilter: 'blur(25)', backgroundColor: '' }}>
                <Grid xs={12} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} container>
                  {/* <Typography sx={{ fontFamily: 'Mont-SemiBold', fontSize: '1.8rem' }}>Project Name : {projectData?.project_name}</Typography> */}
                  <Grid xs={8} sx={{ height: '1.5vh', backgroundColor: 'red' }} item ></Grid>
                </Grid>
                <Grid sx={{ display: 'flex', justifyContent: "flex-end" }}>
                  <Button onClick={createPageHandler} variant='contained' sx={{ fontFamily: 'Mont-Regular', backgroundColor: 'red', marginBottom: '3vh', '&:hover': { color: 'red', backgroundColor: 'white' }, transitionDuration: '0.5s' }}>Create New Page</Button>
                </Grid>
                <DataGrid
                  rows={rows}
                  columns={columns}
                  pageSize={5}
                  onRowClick={(params, event) => handleRowEventHandler(params, event)}
                  error={error}
                  rowsPerPageOptions={[5]}
                  checkboxSelection
                  disableSelectionOnClick
                  experimentalFeatures={{ newEditingApi: true }}
                />
              </Box>
            </Grid>

          </Grid>


      }

      {/* <Modal
        open={open}
        onClose={handleClose}

        sx={{ border: 'none', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >

        <Box sx={{ width: { lg: '80vw' }, padding: '2vh 2vw 6vh 2vw', outline: 'none', borderRadius: '6px', backgroundColor: 'white' }}>
          <Grid sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button onClick={handleClose} sx={{ boxShadow: 2, color: 'red', '&:hover': { boxShadow: 5 } }}>
              <CloseIcon />
            </Button>
          </Grid>
          <Grid sx={{ display: "flex", justifyContent: 'space-between', alignItems: 'center', mt: 2 }} >
            <Typography sx={{ fontFamily: "Mont-SemiBold", fontSize: '1.3rem' }}>Update Page</Typography>
            <Grid item xs={10.2} sx={{ backgroundColor: 'red', height: "1vh" }}></Grid>
          </Grid>
          <Grid sx={{ mt: 4, display: 'flex', justifyContent: 'space-between' }}>
            <TextField onChange={(e) => setName(e.target.value)} id="outlined-basic" label="Name" variant="outlined" sx={{ width: "36vw" }} defaultValue={modalData?.name} />
            <TextField onChange={(e) => setSeo(e.target.value)} defaultValue={modalData?.seo_title} id="outlined-basic" label="Seo Title" variant="outlined" sx={{ width: "36vw" }} />
          </Grid>
          <Grid sx={{ mt: 4, display: 'flex', justifyContent: 'space-between' }}>
            <TextField onChange={(e) => setMetaDesc(e.target.value)} defaultValue={modalData?.meta_description} id="outlined-basic" label="Meta Description" variant="outlined" sx={{ width: "36vw" }} />
            <TextField onChange={(e) => setMetaTag(e.target.value)} defaultValue={modalData?.meta_tags} id="outlined-basic" label="Meta Tags" variant="outlined" sx={{ width: "36vw" }} />
          </Grid>
          <Grid sx={{ mt: 4, display: 'flex', justifyContent: 'space-between' }}>
            <TextField onChange={(e) => setMetaKey(e.target.value)} defaultValue={modalData?.meta_keywords} id="outlined-basic" label="Meta Keywords" variant="outlined" sx={{ width: "36vw" }} />
            <Button onClick={updateChangeHandler} variant='contained' sx={{ backgroundColor: 'red', '&:hover': { color: 'red', backgroundColor: 'white' } }}>Save Changes</Button>

          </Grid>

        </Box>

      </Modal> */}
      <ToastContainer autoClose={2000} />

    </div>
  )
}

export default ModalTable;










