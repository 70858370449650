import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  useCallback,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllPages } from "../Redux/Actions/pageActions";
import { useNavigate } from "react-router-dom";
import Loader from "./Loader";
import { Button, Grid, Typography, Modal, TextField } from "@mui/material";
import darkBubble from "./component_assets/images/darkBubble.png";
// import darkBubble from './component_assets/images/details_close.png'
// import lightBubble from './component_assets/images/details_close.png'
import lightBubble from "./component_assets/images/lightBubble.png";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import "./style.css";
import { updatePage } from "../Redux/Actions/pageActions";
import { ToastContainer } from "react-toastify";
import DeleteIcon from "@mui/icons-material/Delete";
import RateReviewIcon from "@mui/icons-material/RateReview";
// import errorSymbol from './component_assets/images/details_close.png'
import errorSymbol from "./component_assets/images/errorSymbol.svg";
// import btnGrp from './component_assets/images/details_close.png'
import btnGrp from "./component_assets/images/btnGrpRed.svg";
// import errorWave from './component_assets/images/details_close.png'
import errorWave from "./component_assets/images/errorWave.svg";
import CloseIcon from "@mui/icons-material/Close";
import { deletePage } from "../Redux/Actions/pageActions";

function AllPages() {
  let dispatch = useDispatch();
  let navigate = useNavigate();
  const { pageList, loading, error, emptyPage } = useSelector(
    (state) => state.allPagesList
  );
  const { success } = useSelector((state) => state.updatedPage);
  const { delPage } = useSelector((state) => state.delPage);
  const [open, setOpen] = useState(false);
  const [modalData, setModalData] = useState({});
  const [name, setName] = useState("");
  const [metaDesc, setMetaDesc] = useState("");
  const [metaKey, setMetaKey] = useState("");
  const [metaTag, setMetaTag] = useState("");
  const [seo, setSeo] = useState("");

  const [deletePageModal, setDeletePageModal] = useState(false);
  const [deletingId, setDelectingId] = useState(null);
  const [deletingName, setDelectingName] = useState(null);

  const [filteredData, setFilteredData] = useState([]);
  const searchFilterOptionsShowNickname = useRef(true);
  const [pageNameFilter, setPageNameFilter] = useState("");
  const [selectedPageName, setSelectedPageName] = useState("");
  const [finalPageNameToFilterData, setFinalPageNameToFilterData] =
    useState("");

  useEffect(() => {
    dispatch(getAllPages());
  }, [success, delPage]);

  const editPageHandler = (id) => {
    const pageObj = pageList.find((x) => x._id === id);
    console.log("pageObj::::::::::", pageObj);
    if (pageObj) {
      navigate(`/editor/${pageObj._id}?projectId=${pageObj.project}`);
    }
  };

  const deletePageHandler = (id) => {
    dispatch(deletePage(id));
  };

  console.log("abc");

  const columns = [
    { field: "id", headerName: "ID", width: 100 },
    {
      field: "projectName",
      headerName: "Project Name",
      width: 200,
      editable: false,
    },
    {
      field: "pageName",
      headerName: "Page Name",
      width: 200,
      editable: false,
    },
    // {
    //     field: 'seoTitle',
    //     headerName: 'Seo Title',
    //     width: 120,
    //     editable: false,
    // },
    // {
    //     field: 'metaDescription',
    //     headerName: 'Meta Description',
    //     width: 120,
    //     editable: false,
    // },
    // {
    //     field: 'metaTags',
    //     headerName: 'Meta Tags',
    //     width: 120,
    //     editable: false,
    // },
    // {
    //     field: 'metaKeywords',
    //     headerName: 'Meta Keywords',
    //     width: 120,
    //     editable: false,
    // },
    {
      field: "lastUpdated",
      headerName: "last Edit",
      width: 120,
    },
    {
      field: "editPage",
      headerName: "Edit",
      width: 70,
      renderCell: (params) => {
        return (
          // <Button variant='contained' sx={{ color: '#ecfccb', backgroundColor: '#84cc16',textTransform: "none", '&:hover': { color: '#84cc16', backgroundColor: '#ecfccb' } }} onClick={() => editPageHandler(params.id)}>Editor</Button>
          <Typography
            onClick={(event) => {
              event.stopPropagation();
              editPageHandler(params.id);
            }}
            sx={{
              color: "#9333ea",
              "&:hover": { color: "#c084fc", scale: "125%" },
            }}>
            <RateReviewIcon fontSize="medium" />
          </Typography>
        );
      },
    },
    {
      field: "Detail",
      headerName: "Detail",
      width: 70,
      renderCell: (params) => {
        return (
          <Typography
            onClick={() => {
              handleRowEventHandler(params);
            }}
            sx={{
              color: "#9333ea",
              "&:hover": { color: "#c084fc", scale: "125%" },
            }}>
            <RateReviewIcon fontSize="medium" />
          </Typography>
        );
      },
    },
    {
      field: "author",
      headerName: "Author",
      width: 120,
      editable: false,
    },
    {
      field: "Actions",
      headerName: "",
      width: 60,
      renderCell: (params) => {
        return (
          // <Button variant='contained' sx={{ color: '#fecdd3', backgroundColor: '#e11d48', '&:hover': { color: '#e11d48', backgroundColor: '#fecdd3' } }} >Delete</Button>
          <Typography
            onClick={(event) => {
              // event.stopPropagation();
              setOpen(false);
              setDeletePageModal(true);
              setDelectingId(params.id);
              setDelectingName(params.row.pageName);
              // deletePageHandler(params.id)
            }}
            sx={{
              color: "#e11d48",
              "&:hover": { color: "#fb7185", scale: "125%" },
            }}>
            <DeleteIcon fontSize="medium" />
          </Typography>
        );
      },
    },
  ];

  const rows = pageList
    ? pageList.map((x) => {
        return {
          id: x._id,
          projectName: x.project_name,
          pageName: x.name !== "undefined" ? x.name : "---",
          seoTitle: x.seo_title !== "undefined" ? x.seo_title : "---",
          metaDescription:
            x.meta_description !== "undefined" ? x.meta_description : "---",
          metaKeywords:
            x.meta_keywords !== "undefined" ? x.meta_keywords : "---",
          metaTags: x.meta_tags !== "undefined" ? x.meta_tags : "---",
          author: x.author !== "undefined" ? x.author : "---",
          lastUpdated: x.updatedAt !== "undefined" ? x.updatedAt : "---",
        };
      })
    : [
        {
          id: 1,
          projectName: "Snow",
          facebookId: "Jon",
          twitterId: "twitterId",
          linkedInId: "linkedInId",
          instagramId: "instagramId",
          analyticsCode: "analyticsCode",
        },
        {
          id: 2,
          projectName: "Snow",
          facebookId: "Jon",
          twitterId: "twitterId",
          linkedInId: "linkedInId",
          instagramId: "instagramId",
          analyticsCode: "analyticsCode",
        },
        {
          id: 3,
          projectName: "Snow",
          facebookId: "Jon",
          twitterId: "twitterId",
          linkedInId: "linkedInId",
          instagramId: "instagramId",
          analyticsCode: "analyticsCode",
        },
        {
          id: 4,
          projectName: "Snow",
          facebookId: "Jon",
          twitterId: "twitterId",
          linkedInId: "linkedInId",
          instagramId: "instagramId",
          analyticsCode: "analyticsCode",
        },
        {
          id: 5,
          projectName: "Snow",
          facebookId: "Jon",
          twitterId: "twitterId",
          linkedInId: "linkedInId",
          instagramId: "instagramId",
          analyticsCode: "analyticsCode",
        },
      ];

  const handleRowEventHandler = (params) => {
    console.log("params::", params);
    setModalData({
      name: params.row.pageName,
      seo_title: params.row.seoTitle,
      meta_description: params.row.metaDescription,
      meta_keywords: params.row.metaKeywords,
      meta_tags: params.row.metaTags,
      pageId: params.row.id,
    });
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const updateChangeHandler = () => {
    dispatch(
      updatePage({
        pageId: modalData.pageId,
        seo_title: seo,
        meta_description: metaDesc,
        meta_keywords: metaKey,
        meta_tags: metaTag,
        name: name,
      })
    );
    setOpen(false);
  };

  const createPageHandler = () => {
    navigate("/");
  };

  const handleCustomerNicknameChange = useCallback((e) => {
    setPageNameFilter(e.target.value);
  }, []);

  const filteredNicknameOptions = useMemo(() => {
    if (pageNameFilter && !selectedPageName) {
      const searchTerm = pageNameFilter.toLowerCase();
      const optionsStartingWithSearchTerm = [];

      pageList.forEach((option) => {
        if (option.name && option.name.toLowerCase().startsWith(searchTerm)) {
          optionsStartingWithSearchTerm.push(option);
        }
      });

      optionsStartingWithSearchTerm.sort((a, b) =>
        a.name.localeCompare(b.name, undefined, {
          sensitivity: "base",
        })
      );

      return optionsStartingWithSearchTerm;
    } else {
      if (selectedPageName) {
        setFinalPageNameToFilterData(selectedPageName);
        setSelectedPageName("");

        searchFilterOptionsShowNickname.current = false;
      } else {
        searchFilterOptionsShowNickname.current = true;
      }

      return [];
    }
  }, [pageNameFilter, selectedPageName]);

  useEffect(() => {
    if (finalPageNameToFilterData) {
      let newFilteredData = pageList.filter(
        (item) => item.name === finalPageNameToFilterData
      );
      setFilteredData(newFilteredData);
    }

    return () => {
      setFinalPageNameToFilterData("");
    };
  }, [finalPageNameToFilterData]);

  return (
    <div className="">
      {loading ? (
        <Loader />
      ) : emptyPage ? (
        <Grid
          sx={{
            height: "87.5vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            position: "relative",
            overflow: "hidden",
          }}>
          <Grid
            width={"80vw"}
            height={"80%"}
            sx={{
              padding: "2vh 0 2vh 3vw",
              borderRadius: "8px",
              backdropFilter: "blur(25px)",
              boxShadow: 20,
              zIndex: 20,
            }}>
            <Grid
              xs={12}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}>
              <Typography
                sx={{ fontFamily: "Mont-SemiBold", fontSize: "1.5rem" }}>
                Pages Created
              </Typography>
              <Grid
                xs={10}
                sx={{ backgroundColor: "red", height: "0.6vh" }}></Grid>
            </Grid>
            <Grid sx={{ display: "flex", justifyContent: "center", mt: 6 }}>
              <img src={errorSymbol} style={{ height: "30vh" }} />
            </Grid>
            <Typography
              sx={{
                textAlign: "center",
                mt: 4,
                fontSize: "1.2rem",
                fontFamily: "Mont-SemiBold",
              }}>
              Sorry! No pages have been created yet.{" "}
            </Typography>
            <Grid sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
              <Button
                onClick={createPageHandler}
                variant="contained"
                sx={{
                  backgroundColor: "white",
                  color: "black",
                  fontFamily: "Mont-Regular",
                }}>
                <span style={{ marginRight: "1vw" }}>Create New Page</span>
                <img src={btnGrp} style={{ height: "4vh" }} />
              </Button>
            </Grid>
          </Grid>
          <Grid sx={{ position: "absolute", bottom: "-80vh", right: "-20vw" }}>
            <img src={lightBubble} style={{ height: "160vh" }} />
          </Grid>
          <Grid sx={{ position: "absolute", top: "-12vh", right: "0" }}>
            <img src={errorWave} style={{ height: "180vh" }} />
          </Grid>
        </Grid>
      ) : (
        <Grid
          sx={{ position: "relative", overflow: "hidden", height: "100vh" }}>
          <Grid
            sx={{
              position: "absolute",
              top: "-12vh",
              left: "-30vw",
              zIndex: "-10",
            }}>
            <img style={{ width: "55vw", height: "90%" }} src={darkBubble} />
          </Grid>

          <Grid sx={{ position: "absolute", bottom: "-60%", right: "-15%" }}>
            <img style={{ width: "55vw", height: "90%" }} src={lightBubble} />
          </Grid>

          <Grid
            sx={{
              display: "flex",
              justifyContent: "center",
              paddingTop: "5vh",
            }}>
            <Box
              sx={{
                minHeight: "67vh",
                width: "90%",
                backdropFilter: "blur(25)",
                backgroundColor: "",
              }}>
              <Grid
                xs={12}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: "3vh",
                }}
                container>
                <Typography
                  sx={{ fontFamily: "Mont-SemiBold", fontSize: "1.8rem" }}>
                  Pages Created
                </Typography>
                <Grid
                  xs={9.5}
                  sx={{ height: "1.5vh", backgroundColor: "red" }}
                  item></Grid>
              </Grid>
              <DataGrid
                rows={rows}
                columns={columns}
                pageSize={10}
                onRowClick={(params) => handleRowEventHandler(params)}
                error={error}
                rowsPerPageOptions={[10]}
                checkboxSelection
                disableSelectionOnClick
                experimentalFeatures={{ newEditingApi: true }}
                IsReadOnly="True"
                initialState={{
                  sorting: {
                    sortModel: [{ field: "lastUpdated", sort: "desc" }],
                  },
                }}
              />
            </Box>
          </Grid>
        </Grid>
      )}

      {/* UPDATE PAGE MODAL*/}

      <Modal
        open={open}
        onClose={handleClose}
        sx={{
          border: "none",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}>
        <Box
          sx={{
            width: { lg: "80vw" },
            padding: "2vh 2vw 6vh 2vw",
            outline: "none",
            borderRadius: "6px",
            backgroundColor: "white",
          }}>
          <Grid sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              onClick={handleClose}
              sx={{ boxShadow: 2, color: "red", "&:hover": { boxShadow: 5 } }}>
              <CloseIcon />
            </Button>
          </Grid>
          <Grid
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mt: 2,
            }}>
            <Typography
              sx={{ fontFamily: "Mont-SemiBold", fontSize: "1.3rem" }}>
              Update Page
            </Typography>
            <Grid
              item
              xs={10.2}
              sx={{ backgroundColor: "red", height: "1vh" }}></Grid>
          </Grid>
          <Grid
            sx={{ mt: 4, display: "flex", justifyContent: "space-between" }}>
            <TextField
              onChange={(e) => setName(e.target.value)}
              id="outlined-basic"
              label="Name"
              variant="outlined"
              sx={{ width: "36vw" }}
              defaultValue={modalData?.name}
            />
            <TextField
              onChange={(e) => setSeo(e.target.value)}
              defaultValue={modalData?.seo_title}
              id="outlined-basic"
              label="Seo Title"
              variant="outlined"
              sx={{ width: "36vw" }}
            />
          </Grid>
          <Grid
            sx={{ mt: 4, display: "flex", justifyContent: "space-between" }}>
            <TextField
              onChange={(e) => setMetaDesc(e.target.value)}
              defaultValue={modalData?.meta_description}
              id="outlined-basic"
              label="Meta Description"
              variant="outlined"
              sx={{ width: "36vw" }}
            />
            <TextField
              onChange={(e) => setMetaTag(e.target.value)}
              defaultValue={modalData?.meta_tags}
              id="outlined-basic"
              label="Meta Tags"
              variant="outlined"
              sx={{ width: "36vw" }}
            />
          </Grid>
          <Grid
            sx={{ mt: 4, display: "flex", justifyContent: "space-between" }}>
            <TextField
              onChange={(e) => setMetaKey(e.target.value)}
              defaultValue={modalData?.meta_keywords}
              id="outlined-basic"
              label="Meta Keywords"
              variant="outlined"
              sx={{ width: "36vw" }}
            />
            <Button
              onClick={updateChangeHandler}
              variant="contained"
              sx={{
                backgroundColor: "red",
                "&:hover": { color: "red", backgroundColor: "white" },
              }}>
              Save Changes
            </Button>
          </Grid>
        </Box>
      </Modal>

      {/* delete modal */}
      {deletePageModal && (
        <div
          style={{
            position: "absolute",
            top: "0",
            right: "0",
            bottom: "0",
            left: "0",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          }}>
          <div
            style={{
              position: "absolute",
              top: "30%",
              left: "40%",
              width: "25%",
              height: "max-content",
              background: "white",
              borderRadius: "10px",
              paddingTop: "2em",
              paddingBottom: "2em",
              paddingLeft: "1em",
              paddingRight: "1em",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}>
            <h4 style={{ textAlign: "left", lineHeight: "0.5" }}>
              Delete Page -
            </h4>
            <p
              style={{
                textAlign: "left",
                borderBottom: "1px solid black",
                lineHeight: "3",
                width: "100%",
              }}>
              {deletingName}
            </p>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                columnGap: "1vw",
              }}>
              <button
                onClick={(e) => {
                  deletePageHandler(deletingId);
                  setDeletePageModal(false);
                }}
                style={{
                  border: "none",
                  color: "white",
                  borderRadius: "10px",
                  padding: "0.4em",
                  background: "red",
                }}>
                Delete
              </button>

              <button
                onClick={(e) => setDeletePageModal(false)}
                style={{
                  border: "none",
                  color: "white",
                  borderRadius: "10px",
                  padding: "0.4em",
                  background: "red",
                }}>
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}

      <ToastContainer autoClose={2000} />
    </div>
  );
}

export default AllPages;
