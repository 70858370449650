import { GET_PROFILE_DATA_FAILED, GET_PROFILE_DATA_LOADING, GET_PROFILE_DATA_SUCCESS, UPDATE_PROFILE_FAILED, UPDATE_PROFILE_LOADING, UPDATE_PROFILE_SUCCESS } from "../Constants/userProfileConstants"
import axios from 'axios'
import { USER_LOGIN_SUCCESS } from "../Constants/userConstants"
import { toast } from "react-toastify";
import { API_HOST } from "../../api_utils";


const errorTheme = {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
}


export const getUserProfile = () => async (dispatch, getState) => {
    try {
        dispatch({ type: GET_PROFILE_DATA_LOADING })

        const { userLogin: { userInfo } } = getState()
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }
        const { data } = await axios.get(`${API_HOST}profile`, config)
        console.log("data:::::::::", data)
        dispatch({ type: GET_PROFILE_DATA_SUCCESS, payload: data.userProfile[0] })
    } catch (error) {
        dispatch({ type: GET_PROFILE_DATA_FAILED, payload: error.response.data.message })
    }
}

export const updateProfile = (userData) => async (dispatch, getState) => {
    try {
        dispatch({ type: UPDATE_PROFILE_LOADING })

        const { userLogin: { userInfo } } = getState()
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }
        const { data } = await axios.put(`${API_HOST}profile`, userData, config)
        console.log("data::::", data);
        dispatch({ type: UPDATE_PROFILE_SUCCESS, payload: data })
        dispatch({ type: USER_LOGIN_SUCCESS, payload: data })
        localStorage.setItem('userInfo', JSON.stringify(data))
        toast.success(`Profile updated Successfully`, errorTheme)

    } catch (error) {
        dispatch({ type: UPDATE_PROFILE_FAILED, payload: error.response.data.message })
    }
}

