import uniqid from "uniqid";
import UIkit from "uikit";
import $ from "jquery";
import { API_HOST } from "../api_utils/index";

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const CustomEmailTemplatePlugin = (editor, options) => {
	console.log('CustomEmailTemplatePlugin options', options);

	const notifyTheme = {
		position: "top-right",
		autoClose: 7500,
		hideProgressBar: false,
		closeOnClick: true,
		pauseOnHover: true,
		draggable: true,
		progress: undefined,
		theme: "light",
	};

	const notifyTheme2 = {
		position: "top-right",
		autoClose: 3000,
		hideProgressBar: false,
		closeOnClick: true,
		pauseOnHover: true,
		draggable: true,
		progress: undefined,
		theme: "light",
	};

	const successTheme = {
	  position: "top-right",
	  autoClose: 5000,
	  hideProgressBar: false,
	  closeOnClick: true,
	  pauseOnHover: true,
	  draggable: true,
	  progress: undefined,
	  theme: "light",
	};

	// toast notification for empty tables
	const infoTheme = {
	  position: "top-right",
	  autoClose: 7500,
	  hideProgressBar: false,
	  closeOnClick: true,
	  pauseOnHover: true,
	  draggable: true,
	  progress: undefined,
	  theme: "light",
	};

    if (editor !== null && editor !== undefined) {
    	editor.Blocks.add("email-template-container", {
	        label: "Email Template Button",
	        category: "Buttons",
	        select: true,
	        media: `<svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="currentColor" viewBox="0 0 16 16"><path d="M8.941.435a2 2 0 0 0-1.882 0l-6 3.2A2 2 0 0 0 0 5.4v.314l6.709 3.932L8 8.928l1.291.718L16 5.714V5.4a2 2 0 0 0-1.059-1.765zM16 6.873l-5.693 3.337L16 13.372v-6.5Zm-.059 7.611L8 10.072.059 14.484A2 2 0 0 0 2 16h12a2 2 0 0 0 1.941-1.516M0 13.373l5.693-3.163L0 6.873z"/></svg>`,
	        content: { type: "email-template-container" },
	    });

	    const emailTemplateScript = function(props) {
	    	console.log('emailTemplateScript props', props);

	    	// to create a popup notification
    		function showNotification(isSuccess, message) {
			    // Create a notification element
			    const notification = document.createElement('div');

			    // Apply styles directly
			    notification.style.display = 'block';
			    notification.style.position = 'fixed';
			    notification.style.top = '20px';
			    notification.style.right = '20px';
			    notification.style.padding = '20px';
			    notification.style.backgroundColor = 'white';
			    notification.style.border = '2px solid';
			    notification.style.borderRadius = '10px';
			    notification.style.boxShadow = '0 0 10px rgba(0,0,0,0.1)';
			    notification.style.zIndex = '1000';
			    notification.style.maxWidth = '300px';
			    notification.style.textAlign = 'left';
			    
			    // Set the message
			    notification.textContent = message;

			    // Apply border color based on success or failure
			    notification.style.borderColor = isSuccess ? '#4CAF50' : '#F44336';

			    // Append the notification to the document body
			    document.body.appendChild(notification);

			    // Hide and remove the notification after 3 seconds
			    setTimeout(() => {
			        document.body.removeChild(notification);
			    }, 4000);
			}

			let containerId, emailStatus;

	    	// setting email status in local storage. Statues - inprogress, success, failure, inactive
    		if (this.tagName === 'DIV' && this.className === "email-template-container") {
	    		containerId = this.id;
	    		emailStatus = localStorage.getItem(`templateEmailStatus_${containerId}`);
	    		if (!emailStatus) {
	    			emailStatus = 'inactive';
	    			localStorage.setItem(`templateEmailStatus_${containerId}`, emailStatus);
	    		}
    		}    		

	        let apiData, API;

	    	let {API_HOST,
	    	projectId, 
	    	StringifiedFragment, 
	    	EmailFrom, 
	    	EmailTo, 
	    	EmailSubject, 
	    	EmailCC,
	    	EmailBCC,
	    	CustomUrl,
            BaseUrl,
            APIPath,
            BearerToken,
            Username,
            Password,
            MoreHeaders,
            APIMethod,
            APIBody} = props;

            if (APIPath) {
	          	API = APIPath;
	        }

	        let emailCC, emailBCC;

	        // 'a@b.com, c@d.com, a@b.com, c@d.com' --> ["a@b.com", "c@d.com", "a@b.com", "c@d.com"]
	        if (EmailCC) emailCC = EmailCC.split(',').map(str => str.trim());
	        if (EmailBCC) emailBCC = EmailBCC.split(',').map(str => str.trim());

	    	// base url bearer token taken directly from local storage after getting downloaded
	        let baseUrlData,
	          dynamicBaseUrlBearerToken,
	          baseUrl1BearerToken,
	          baseUrl2BearerToken,
	          baseUrl3BearerToken,
	          baseUrl4BearerToken,
	          baseUrl5BearerToken;
	        // getting baseUrl data from local storage for run-time access
	        let baseUrlDataFromStorage = localStorage.getItem("baseUrlData");
	        if (baseUrlDataFromStorage) {
	          baseUrlData = JSON.parse(baseUrlDataFromStorage);
	        }
	        // extracting token values from base URLs from baseUrlData
	        if (baseUrlData) {
	          try {
	            if (`baseUrl1_${projectId}` in baseUrlData) {
	              baseUrl1BearerToken = baseUrlData[`baseUrl1_${projectId}`].token;
	            }
	            if (`baseUrl2_${projectId}` in baseUrlData) {
	              baseUrl2BearerToken = baseUrlData[`baseUrl2_${projectId}`].token;
	            }
	            if (`baseUrl3_${projectId}` in baseUrlData) {
	              baseUrl3BearerToken = baseUrlData[`baseUrl3_${projectId}`].token;
	            }
	            if (`baseUrl4_${projectId}` in baseUrlData) {
	              baseUrl4BearerToken = baseUrlData[`baseUrl4_${projectId}`].token;
	            }
	            if (`baseUrl5_${projectId}` in baseUrlData) {
	              baseUrl5BearerToken = baseUrlData[`baseUrl5_${projectId}`].token;
	            }
	          } catch (e) {
	            // statements
	            console.log(e);
	          }
	        }

	    	let emailTemplateContainer = this;
	    	let emailTemplateButton = emailTemplateContainer.getElementsByClassName('email-template-button')[0];

	    	emailTemplateButton.onclick = async function(e) {
	    		e.preventDefault();

	    		// updating emailStatus in local storage
                emailStatus = 'inprogress';
				localStorage.setItem(`templateEmailStatus_${containerId}`, emailStatus);

	    		if (EmailFrom !== "" && EmailTo !== "" && EmailSubject !== "" && StringifiedFragment !== "") {
		    		// const response = await fetch(`${API_HOST}user/custom_email_without_formData`, {
	                //     method: 'POST',
	                //     headers: {
					//       	'Content-Type': 'application/json'
					//     },
	                //     body: JSON.stringify({
	                //     	isTemplateEmail: true,
	                //     	emailFrom: EmailFrom,
	                //     	emailTo: EmailTo,
	                //     	emailSubject: EmailSubject,
	                //     	emailBody: StringifiedFragment,
	                //     	emailCC: emailCC,
	                //     	emailBCC: emailBCC,
	                //     }),
	                // });

	                // if (response.ok) {
	                //     console.log('Email sent successfully!');
	                // } else {
	                //     console.error('Failed to send email.');
	                // }

	                // POST method
        			// if CustomUrl is provided, use only that
			        if (CustomUrl && (!BaseUrl || BaseUrl === "null") && APIMethod === "POST") {
			          // if nothing is provided for auth
			          if (!BearerToken && !Username && !Password) {
			            // add extra headers, if needed
			            let config = {
			              method: "POST",
			              headers: {
			              	"Content-Type": "application/json",
			              },
			              body: JSON.stringify({
		                    	isTemplateEmail: true,
		                    	emailFrom: EmailFrom,
		                    	emailTo: EmailTo,
		                    	emailSubject: EmailSubject,
		                    	emailBody: StringifiedFragment,
		                    	emailCC: emailCC,
		                    	emailBCC: emailBCC,
		                    }),
			            };

			            if (MoreHeaders) {
			              // ['a:b', "c:d"]
			              // extracting headers from More_Headers trait
			              MoreHeaders.split(",").forEach((header) => {
			                let key, val;
			                [key, val] = header.split(":");
			                console.log("[key, val]:\n", key, val);

			                config.headers[key] = val;
			              });
			            }

			            console.log("config:", config);

			            try {
			              // GET call to get the data
			              let response = await fetch(`${CustomUrl}`, config);
			              let responseData = await response.json();
			              apiData = responseData;

			              	if (response.ok) {
		                        console.log('Email sent successfully!');

		                        // updating emailStatus in local storage
		                        emailStatus = 'success';
								localStorage.setItem(`templateEmailStatus_${containerId}`, emailStatus);

			                    showNotification(true, 'Email sent successfully!');
		                    } else {
		                        console.error('Failed to send email.');

		                        // updating emailStatus in local storage
		                        emailStatus = 'failure';
								localStorage.setItem(`templateEmailStatus_${containerId}`, emailStatus);

			                    showNotification(false, 'Failed to send email. Please check the provided trait values.');
		                    }
			            } catch (e) {
			              // statements
			              console.log(e);
			            }
			          }

			          // if a bearer token is provided but not api_key or usr+pwd
			          else if (BearerToken && !Username && !Password) {
			            // add extra headers, if needed
			            let config = {
			              method: "POST",
			              headers: {
			                "Content-Type": "application/json",
			                Authorization: `Bearer ${BearerToken}`,
			              },
			              body: JSON.stringify({
		                    	isTemplateEmail: true,
		                    	emailFrom: EmailFrom,
		                    	emailTo: EmailTo,
		                    	emailSubject: EmailSubject,
		                    	emailBody: StringifiedFragment,
		                    	emailCC: emailCC,
		                    	emailBCC: emailBCC,
		                    }),
			            };

			            if (MoreHeaders) {
			              // ['a:b', "c:d"]
			              // extracting headers from More_Headers trait
			              MoreHeaders.split(",").forEach((header) => {
			                let key, val;
			                [key, val] = header.split(":");
			                console.log("[key, val]:\n", key, val);

			                config.headers[key] = val;
			              });
			            }

			            console.log("config:", config);

			            try {
			              // GET call to get the data
			              let response = await fetch(`${CustomUrl}`, config);
			              let responseData = await response.json();
			              apiData = responseData;

			              	if (response.ok) {
		                        console.log('Email sent successfully!');

		                        // updating emailStatus in local storage
		                        emailStatus = 'success';
								localStorage.setItem(`templateEmailStatus_${containerId}`, emailStatus);

								showNotification(true, 'Email sent successfully!');
		                    } else {
		                        console.error('Failed to send email.');

		                        // updating emailStatus in local storage
		                        emailStatus = 'failure';
								localStorage.setItem(`templateEmailStatus_${containerId}`, emailStatus);

								showNotification(false, 'Failed to send email. Please check the provided values.');
		                    }
			            } catch (e) {
			              // statements
			              console.log(e);
			            }
			          }

			          // if a bearer token and api_key not provided but usr+pwd are provided
			          else if (!BearerToken && Username && Password) {
			            // add extra headers, if needed
			            let config = {
			              method: "POST",
			              headers: {
			              	"Content-Type": "application/json",
			                Authorization: "Basic " + btoa(Username + ":" + Password),
			              },
			              body: JSON.stringify({
		                    	isTemplateEmail: true,
		                    	emailFrom: EmailFrom,
		                    	emailTo: EmailTo,
		                    	emailSubject: EmailSubject,
		                    	emailBody: StringifiedFragment,
		                    	emailCC: emailCC,
		                    	emailBCC: emailBCC,
		                    }),
			            };

			            if (MoreHeaders) {
			              // ['a:b', "c:d"]
			              // extracting headers from More_Headers trait
			              MoreHeaders.split(",").forEach((header) => {
			                let key, val;
			                [key, val] = header.split(":");
			                console.log("[key, val]:\n", key, val);

			                config.headers[key] = val;
			              });
			            }

			            console.log("config:", config);

			            try {
			              // GET call to get the data
			              let response = await fetch(`${CustomUrl}`, config);
			              let responseData = await response.json();
			              apiData = responseData;

			              	if (response.ok) {
		                        console.log('Email sent successfully!');

		                        // updating emailStatus in local storage
		                        emailStatus = 'success';
								localStorage.setItem(`templateEmailStatus_${containerId}`, emailStatus);

								showNotification(true, 'Email sent successfully!');
		                    } else {
		                        console.error('Failed to send email.');

		                        // updating emailStatus in local storage
		                        emailStatus = 'failure';
								localStorage.setItem(`templateEmailStatus_${containerId}`, emailStatus);

								showNotification(false, 'Failed to send email. Please check the provided values.');
		                    }
			            } catch (e) {
			              // statements
			              console.log(e);
			            }
			          }
			        }

			        // if base url is the choice and provided
			        if (BaseUrl && API && !CustomUrl && APIMethod === "POST" ) {
			          // concatenating base url and its remaining part
			          let url;
			          if (BaseUrl === "baseUrl1") {
			            url = options.baseUrl1 + API;
			            dynamicBaseUrlBearerToken = baseUrl1BearerToken;
			          } else if (BaseUrl === "baseUrl2") {
			            url = options.baseUrl2 + API;
			            dynamicBaseUrlBearerToken = baseUrl2BearerToken;
			          } else if (BaseUrl === "baseUrl3") {
			            url = options.baseUrl3 + API;
			            dynamicBaseUrlBearerToken = baseUrl3BearerToken;
			          } else if (BaseUrl === "baseUrl4") {
			            url = options.baseUrl4 + API;
			            dynamicBaseUrlBearerToken = baseUrl4BearerToken;
			          } else if (BaseUrl === "baseUrl5") {
			            url = options.baseUrl5 + API;
			            dynamicBaseUrlBearerToken = baseUrl5BearerToken;
			          }
			          console.log("url, dynamicBaseUrlBearerToken", url, dynamicBaseUrlBearerToken );

			          // if nothing is provided for auth
			          if (!BearerToken && !dynamicBaseUrlBearerToken && !Username && !Password ) {
			            // add extra headers, if needed
			            let config = {
			              method: "POST",
			              headers: {
			              	"Content-Type": "application/json",
			              },
			              body: JSON.stringify({
		                    	isTemplateEmail: true,
		                    	emailFrom: EmailFrom,
		                    	emailTo: EmailTo,
		                    	emailSubject: EmailSubject,
		                    	emailBody: StringifiedFragment,
		                    	emailCC: emailCC,
		                    	emailBCC: emailBCC,
		                    }),
			            };

			            if (MoreHeaders) {
			              // ['a:b', "c:d"]
			              // extracting headers from More_Headers trait
			              MoreHeaders.split(",").forEach((header) => {
			                let key, val;
			                [key, val] = header.split(":");
			                console.log("[key, val]:\n", key, val);

			                config.headers[key] = val;
			              });
			            }

			            console.log("config:", config);

			            try {
			              // GET call to get the data
			              let response = await fetch(`${url}`, config);
			              let responseData = await response.json();
			              apiData = responseData;

			              	if (response.ok) {
		                        console.log('Email sent successfully!');

		                        // updating emailStatus in local storage
		                        emailStatus = 'success';
								localStorage.setItem(`templateEmailStatus_${containerId}`, emailStatus);

								showNotification(true, 'Email sent successfully!');
		                    } else {
		                        console.error('Failed to send email.');

		                        // updating emailStatus in local storage
		                        emailStatus = 'failure';
								localStorage.setItem(`templateEmailStatus_${containerId}`, emailStatus);

			                    showNotification(false, 'Failed to send email. Please check the provided trait values.');
		                    }
			            } catch (e) {
			              // statements
			              console.log(e);
			            }
			          }

			          // if a bearer token is provided but not usr+pwd
			          else if (BearerToken && !Username && !Password) {
			            // add extra headers, if needed
			            let config = {
			              method: "POST",
			              headers: {
			                "Content-Type": "application/json",
			                Authorization: `Bearer ${BearerToken}`,
			              },
			              body: JSON.stringify({
		                    	isTemplateEmail: true,
		                    	emailFrom: EmailFrom,
		                    	emailTo: EmailTo,
		                    	emailSubject: EmailSubject,
		                    	emailBody: StringifiedFragment,
		                    	emailCC: emailCC,
		                    	emailBCC: emailBCC,
		                    }),
			            };

			            if (MoreHeaders) {
			              // ['a:b', "c:d"]
			              // extracting headers from More_Headers trait
			              MoreHeaders.split(",").forEach((header) => {
			                let key, val;
			                [key, val] = header.split(":");
			                console.log("[key, val]:\n", key, val);

			                config.headers[key] = val;
			              });
			            }

			            console.log("config:", config);

			            try {
			              // GET call to get the data
			              let response = await fetch(`${url}`, config);
			              let responseData = await response.json();
			              apiData = responseData;

			              	if (response.ok) {
		                        console.log('Email sent successfully!');

		                        // updating emailStatus in local storage
		                        emailStatus = 'success';
								localStorage.setItem(`templateEmailStatus_${containerId}`, emailStatus);

								showNotification(true, 'Email sent successfully!');
		                    } else {
		                        console.error('Failed to send email.');

		                        // updating emailStatus in local storage
		                        emailStatus = 'failure';
								localStorage.setItem(`templateEmailStatus_${containerId}`, emailStatus);

			                    showNotification(false, 'Failed to send email. Please check the provided trait values.');
		                    }
			            } catch (e) {
			              // statements
			              console.log(e);
			            }
			          }

			          // if a dynamicBaseUrlBearerToken is provided but not Custom Bearer Token and usr+pwd
			          else if (dynamicBaseUrlBearerToken && !BearerToken && !Username && !Password ) {
			            // add extra headers, if needed
			            let config = {
			              method: "POST",
			              headers: {
			                "Content-Type": "application/json",
			                Authorization: `Bearer ${dynamicBaseUrlBearerToken}`,
			              },
			              body: JSON.stringify({
		                    	isTemplateEmail: true,
		                    	emailFrom: EmailFrom,
		                    	emailTo: EmailTo,
		                    	emailSubject: EmailSubject,
		                    	emailBody: StringifiedFragment,
		                    	emailCC: emailCC,
		                    	emailBCC: emailBCC,
		                    }),
			            };

			            if (MoreHeaders) {
			              // ['a:b', "c:d"]
			              // extracting headers from More_Headers trait
			              MoreHeaders.split(",").forEach((header) => {
			                let key, val;
			                [key, val] = header.split(":");
			                console.log("[key, val]:\n", key, val);

			                config.headers[key] = val;
			              });
			            }

			            console.log("config@:", config);

			            try {
			              // GET call to get the data
			              let response = await fetch(`${url}`, config);
			              let responseData = await response.json();
			              apiData = responseData;

			              	if (response.ok) {
		                        console.log('Email sent successfully!');

		                        // updating emailStatus in local storage
		                        emailStatus = 'success';
								localStorage.setItem(`templateEmailStatus_${containerId}`, emailStatus);

								showNotification(true, 'Email sent successfully!');
		                    } else {
		                        console.error('Failed to send email.');

		                        // updating emailStatus in local storage
		                        emailStatus = 'failure';
								localStorage.setItem(`templateEmailStatus_${containerId}`, emailStatus);

			                    showNotification(false, 'Failed to send email. Please check the provided trait values.');
		                    }
			            } catch (e) {
			              // statements
			              console.log(e);
			            }
			          }

			          // if a bearer token is not provided but usr+pwd are provided
			          else if (!BearerToken && Username && Password) {
			            // add extra headers, if needed
			            let config = {
			              method: "POST",
			              headers: {
			              	"Content-Type": "application/json",
			                Authorization: "Basic " + btoa(Username + ":" + Password),
			              },
			              body: JSON.stringify({
		                    	isTemplateEmail: true,
		                    	emailFrom: EmailFrom,
		                    	emailTo: EmailTo,
		                    	emailSubject: EmailSubject,
		                    	emailBody: StringifiedFragment,
		                    	emailCC: emailCC,
		                    	emailBCC: emailBCC,
		                    }),
			            };

			            if (MoreHeaders) {
			              // ['a:b', "c:d"]
			              // extracting headers from More_Headers trait
			              MoreHeaders.split(",").forEach((header) => {
			                let key, val;
			                [key, val] = header.split(":");
			                console.log("[key, val]:\n", key, val);

			                config.headers[key] = val;
			              });
			            }

			            console.log("config:", config);

			            try {
			              // GET call to get the data
			              let response = await fetch(`${url}`, config);
			              let responseData = await response.json();
			              apiData = responseData;

			              	if (response.ok) {
		                        console.log('Email sent successfully!');

		                        // updating emailStatus in local storage
		                        emailStatus = 'success';
								localStorage.setItem(`templateEmailStatus_${containerId}`, emailStatus);

								showNotification(true, 'Email sent successfully!');
		                    } else {
		                        console.error('Failed to send email.');

		                        // updating emailStatus in local storage
		                        emailStatus = 'failure';
								localStorage.setItem(`templateEmailStatus_${containerId}`, emailStatus);

			                    showNotification(false, 'Failed to send email. Please check the provided trait values.');
		                    }
			            } catch (e) {
			              // statements
			              console.log(e);
			            }
			          }
			        }

	                // PUT method
        			// if CustomUrl is provided, use only that
			        if (CustomUrl && (!BaseUrl || BaseUrl === "null") && APIMethod === "PUT" ) {
			          // if nothing is provided for auth
			          if (!BearerToken && !Username && !Password) {
			            // add extra headers, if needed
			            let config = {
			              method: "PUT",
			              headers: {
			              	"Content-Type": "application/json",
			              },
			              body: JSON.stringify({
		                    	isTemplateEmail: true,
		                    	emailFrom: EmailFrom,
		                    	emailTo: EmailTo,
		                    	emailSubject: EmailSubject,
		                    	emailBody: StringifiedFragment,
		                    	emailCC: emailCC,
		                    	emailBCC: emailBCC,
		                    }),
			            };

			            if (MoreHeaders) {
			              // ['a:b', "c:d"]
			              // extracting headers from More_Headers trait
			              MoreHeaders.split(",").forEach((header) => {
			                let key, val;
			                [key, val] = header.split(":");
			                console.log("[key, val]:\n", key, val);

			                config.headers[key] = val;
			              });
			            }

			            console.log("config:", config);

			            try {
			              // GET call to get the data
			              let response = await fetch(`${CustomUrl}`, config);
			              let responseData = await response.json();
			              apiData = responseData;

			              	if (response.ok) {
		                        console.log('Email sent successfully!');

		                        // updating emailStatus in local storage
		                        emailStatus = 'success';
								localStorage.setItem(`templateEmailStatus_${containerId}`, emailStatus);

								showNotification(true, 'Email sent successfully!');
		                    } else {
		                        console.error('Failed to send email.');

		                        // updating emailStatus in local storage
		                        emailStatus = 'failure';
								localStorage.setItem(`templateEmailStatus_${containerId}`, emailStatus);

			                    showNotification(false, 'Failed to send email. Please check the provided trait values.');
		                    }
			            } catch (e) {
			              // statements
			              console.log(e);
			            }
			          }

			          // if a bearer token is provided but not api_key or usr+pwd
			          else if (BearerToken && !Username && !Password) {
			            // add extra headers, if needed
			            let config = {
			              method: "PUT",
			              headers: {
			                "Content-Type": "application/json",
			                Authorization: `Bearer ${BearerToken}`,
			              },
			              body: JSON.stringify({
		                    	isTemplateEmail: true,
		                    	emailFrom: EmailFrom,
		                    	emailTo: EmailTo,
		                    	emailSubject: EmailSubject,
		                    	emailBody: StringifiedFragment,
		                    	emailCC: emailCC,
		                    	emailBCC: emailBCC,
		                    }),
			            };

			            if (MoreHeaders) {
			              // ['a:b', "c:d"]
			              // extracting headers from More_Headers trait
			              MoreHeaders.split(",").forEach((header) => {
			                let key, val;
			                [key, val] = header.split(":");
			                console.log("[key, val]:\n", key, val);

			                config.headers[key] = val;
			              });
			            }

			            console.log("config:", config);

			            try {
			              // GET call to get the data
			              let response = await fetch(`${CustomUrl}`, config);
			              let responseData = await response.json();
			              apiData = responseData;

			              	if (response.ok) {
		                        console.log('Email sent successfully!');

		                        // updating emailStatus in local storage
		                        emailStatus = 'success';
								localStorage.setItem(`templateEmailStatus_${containerId}`, emailStatus);

								showNotification(true, 'Email sent successfully!');
		                    } else {
		                        console.error('Failed to send email.');

		                        // updating emailStatus in local storage
		                        emailStatus = 'failure';
								localStorage.setItem(`templateEmailStatus_${containerId}`, emailStatus);

			                    showNotification(false, 'Failed to send email. Please check the provided trait values.');
		                    }
			            } catch (e) {
			              // statements
			              console.log(e);
			            }
			          }

			          // if a bearer token and api_key not provided but usr+pwd are provided
			          else if (!BearerToken && Username && Password) {
			            // add extra headers, if needed
			            let config = {
			              method: "PUT",
			              headers: {
			              	"Content-Type": "application/json",
			                Authorization: "Basic " + btoa(Username + ":" + Password),
			              },
			              body: JSON.stringify({
		                    	isTemplateEmail: true,
		                    	emailFrom: EmailFrom,
		                    	emailTo: EmailTo,
		                    	emailSubject: EmailSubject,
		                    	emailBody: StringifiedFragment,
		                    	emailCC: emailCC,
		                    	emailBCC: emailBCC,
		                    }),
			            };

			            if (MoreHeaders) {
			              // ['a:b', "c:d"]
			              // extracting headers from More_Headers trait
			              MoreHeaders.split(",").forEach((header) => {
			                let key, val;
			                [key, val] = header.split(":");
			                console.log("[key, val]:\n", key, val);

			                config.headers[key] = val;
			              });
			            }

			            console.log("config:", config);

			            try {
			              // GET call to get the data
			              let response = await fetch(`${CustomUrl}`, config);
			              let responseData = await response.json();
			              apiData = responseData;

			              	if (response.ok) {
		                        console.log('Email sent successfully!');

		                        // updating emailStatus in local storage
		                        emailStatus = 'success';
								localStorage.setItem(`templateEmailStatus_${containerId}`, emailStatus);

								showNotification(true, 'Email sent successfully!');
		                    } else {
		                        console.error('Failed to send email.');

		                        // updating emailStatus in local storage
		                        emailStatus = 'failure';
								localStorage.setItem(`templateEmailStatus_${containerId}`, emailStatus);

			                    showNotification(false, 'Failed to send email. Please check the provided trait values.');
		                    }
			            } catch (e) {
			              // statements
			              console.log(e);
			            }
			          }
			        }

			        // if base url is the choice and provided
			        if (BaseUrl && API && !CustomUrl && APIMethod === "PUT" ) {
			          // concatenating base url and its remaining part
			          let url;
			          if (BaseUrl === "baseUrl1") {
			            url = options.baseUrl1 + API;
			            dynamicBaseUrlBearerToken = baseUrl1BearerToken;
			          } else if (BaseUrl === "baseUrl2") {
			            url = options.baseUrl2 + API;
			            dynamicBaseUrlBearerToken = baseUrl2BearerToken;
			          } else if (BaseUrl === "baseUrl3") {
			            url = options.baseUrl3 + API;
			            dynamicBaseUrlBearerToken = baseUrl3BearerToken;
			          } else if (BaseUrl === "baseUrl4") {
			            url = options.baseUrl4 + API;
			            dynamicBaseUrlBearerToken = baseUrl4BearerToken;
			          } else if (BaseUrl === "baseUrl5") {
			            url = options.baseUrl5 + API;
			            dynamicBaseUrlBearerToken = baseUrl5BearerToken;
			          }
			          console.log("url, dynamicBaseUrlBearerToken", url, dynamicBaseUrlBearerToken );

			          // if nothing is provided for auth
			          if (!BearerToken && !dynamicBaseUrlBearerToken && !Username && !Password ) {
			            // add extra headers, if needed
			            let config = {
			              method: "PUT",
			              headers: {
			              	"Content-Type": "application/json",
			              },
			              body: JSON.stringify({
		                    	isTemplateEmail: true,
		                    	emailFrom: EmailFrom,
		                    	emailTo: EmailTo,
		                    	emailSubject: EmailSubject,
		                    	emailBody: StringifiedFragment,
		                    	emailCC: emailCC,
		                    	emailBCC: emailBCC,
		                    }),
			            };

			            if (MoreHeaders) {
			              // ['a:b', "c:d"]
			              // extracting headers from More_Headers trait
			              MoreHeaders.split(",").forEach((header) => {
			                let key, val;
			                [key, val] = header.split(":");
			                console.log("[key, val]:\n", key, val);

			                config.headers[key] = val;
			              });
			            }

			            console.log("config:", config);

			            try {
			              // GET call to get the data
			              let response = await fetch(`${url}`, config);
			              let responseData = await response.json();
			              apiData = responseData;

			              	if (response.ok) {
		                        console.log('Email sent successfully!');

		                        // updating emailStatus in local storage
		                        emailStatus = 'success';
								localStorage.setItem(`templateEmailStatus_${containerId}`, emailStatus);

								showNotification(true, 'Email sent successfully!');
		                    } else {
		                        console.error('Failed to send email.');

		                        // updating emailStatus in local storage
		                        emailStatus = 'failure';
								localStorage.setItem(`templateEmailStatus_${containerId}`, emailStatus);

			                    showNotification(false, 'Failed to send email. Please check the provided trait values.');
		                    }
			            } catch (e) {
			              // statements
			              console.log(e);
			            }
			          }

			          // if a bearer token is provided but not usr+pwd
			          else if (BearerToken && !Username && !Password) {
			            // add extra headers, if needed
			            let config = {
			              method: "PUT",
			              headers: {
			                "Content-Type": "application/json",
			                Authorization: `Bearer ${BearerToken}`,
			              },
			              body: JSON.stringify({
		                    	isTemplateEmail: true,
		                    	emailFrom: EmailFrom,
		                    	emailTo: EmailTo,
		                    	emailSubject: EmailSubject,
		                    	emailBody: StringifiedFragment,
		                    	emailCC: emailCC,
		                    	emailBCC: emailBCC,
		                    }),
			            };

			            if (MoreHeaders) {
			              // ['a:b', "c:d"]
			              // extracting headers from More_Headers trait
			              MoreHeaders.split(",").forEach((header) => {
			                let key, val;
			                [key, val] = header.split(":");
			                console.log("[key, val]:\n", key, val);

			                config.headers[key] = val;
			              });
			            }

			            console.log("config:", config);

			            try {
			              // GET call to get the data
			              let response = await fetch(`${url}`, config);
			              let responseData = await response.json();
			              apiData = responseData;

			              	if (response.ok) {
		                        console.log('Email sent successfully!');

		                        // updating emailStatus in local storage
		                        emailStatus = 'success';
								localStorage.setItem(`templateEmailStatus_${containerId}`, emailStatus);

								showNotification(true, 'Email sent successfully!');
		                    } else {
		                        console.error('Failed to send email.');

		                        // updating emailStatus in local storage
		                        emailStatus = 'failure';
								localStorage.setItem(`templateEmailStatus_${containerId}`, emailStatus);

			                    showNotification(false, 'Failed to send email. Please check the provided trait values.');
		                    }
			            } catch (e) {
			              // statements
			              console.log(e);
			            }
			          }

			          // if a dynamicBaseUrlBearerToken is provided but not Custom Bearer Token and usr+pwd
			          else if (dynamicBaseUrlBearerToken && !BearerToken && !Username && !Password ) {
			            // add extra headers, if needed
			            let config = {
			              method: "PUT",
			              headers: {
			                "Content-Type": "application/json",
			                Authorization: `Bearer ${dynamicBaseUrlBearerToken}`,
			              },
			              body: JSON.stringify({
		                    	isTemplateEmail: true,
		                    	emailFrom: EmailFrom,
		                    	emailTo: EmailTo,
		                    	emailSubject: EmailSubject,
		                    	emailBody: StringifiedFragment,
		                    	emailCC: emailCC,
		                    	emailBCC: emailBCC,
		                    }),
			            };

			            if (MoreHeaders) {
			              // ['a:b', "c:d"]
			              // extracting headers from More_Headers trait
			              MoreHeaders.split(",").forEach((header) => {
			                let key, val;
			                [key, val] = header.split(":");
			                console.log("[key, val]:\n", key, val);

			                config.headers[key] = val;
			              });
			            }

			            console.log("config@:", config);

			            try {
			              // GET call to get the data
			              let response = await fetch(`${url}`, config);
			              let responseData = await response.json();
			              apiData = responseData;

			              	if (response.ok) {
		                        console.log('Email sent successfully!');

		                        // updating emailStatus in local storage
		                        emailStatus = 'success';
								localStorage.setItem(`templateEmailStatus_${containerId}`, emailStatus);

								showNotification(true, 'Email sent successfully!');
		                    } else {
		                        console.error('Failed to send email.');

		                        // updating emailStatus in local storage
		                        emailStatus = 'failure';
								localStorage.setItem(`templateEmailStatus_${containerId}`, emailStatus);

			                    showNotification(false, 'Failed to send email. Please check the provided trait values.');
		                    }
			            } catch (e) {
			              // statements
			              console.log(e);
			            }
			          }

			          // if a bearer token is not provided but usr+pwd are provided
			          else if (!BearerToken && Username && Password) {
			            // add extra headers, if needed
			            let config = {
			              method: "PUT",
			              headers: {
			              	"Content-Type": "application/json",
			                Authorization: "Basic " + btoa(Username + ":" + Password),
			              },
			              body: JSON.stringify({
		                    	isTemplateEmail: true,
		                    	emailFrom: EmailFrom,
		                    	emailTo: EmailTo,
		                    	emailSubject: EmailSubject,
		                    	emailBody: StringifiedFragment,
		                    	emailCC: emailCC,
		                    	emailBCC: emailBCC,
		                    }),
			            };

			            if (MoreHeaders) {
			              // ['a:b', "c:d"]
			              // extracting headers from More_Headers trait
			              MoreHeaders.split(",").forEach((header) => {
			                let key, val;
			                [key, val] = header.split(":");
			                console.log("[key, val]:\n", key, val);

			                config.headers[key] = val;
			              });
			            }

			            console.log("config:", config);

			            try {
			              // GET call to get the data
			              let response = await fetch(`${url}`, config);
			              let responseData = await response.json();
			              apiData = responseData;

			              	if (response.ok) {
		                        console.log('Email sent successfully!');

		                        // updating emailStatus in local storage
		                        emailStatus = 'success';
								localStorage.setItem(`templateEmailStatus_${containerId}`, emailStatus);

								showNotification(true, 'Email sent successfully!');
		                    } else {
		                        console.error('Failed to send email.');

		                        // updating emailStatus in local storage
		                        emailStatus = 'failure';
								localStorage.setItem(`templateEmailStatus_${containerId}`, emailStatus);

			                    showNotification(false, 'Failed to send email. Please check the provided trait values.');
		                    }
			            } catch (e) {
			              // statements
			              console.log(e);
			            }
			          }
			        }
	    		}	    		
	    	};
	    };

	    editor.DomComponents.addType('email-template-container', {
	    	model: {
	    		defaults: {
	    			tagName: "div",
	    			attributes: {
		              class: "email-template-container",
		            },
		            type: "email-template-container",
		            script: emailTemplateScript,

		            components: [
		              {
		                tagName: "button",
		                attributes: { class: "email-template-button" },
		                type: "email-template-button",
		                editable: true,
		  
		                "style-default": {
		                  width: "max-content",
		                  padding: "0.7%",
		                  "border-radius": "25px",
		                  "background-color": "#1991E7",
		                  border: "2px solid #1991E7",
		                  color: "white",
		                  cursor: "pointer",
		                },
		  
		                style: {
		                  width: "max-content",
		                  padding: "0.7%",
		                  "border-radius": "25px",
		                  "background-color": "#1991E7",
		                  border: "2px solid #1991E7",
		                  color: "white",
		                  cursor: "pointer",
		                },
		  
		                components: {
		                  tagName: "p",
		                  type: "text",
		  
		                  components: {
		                    type: "textnode",
		                    content: "Send Email",
		                  },
		  
		                  "style-default": {
		                    margin: "auto",
		                    "text-align": "center",
		                    color: "white",
		                  },
		  
		                  style: {
		                    margin: "auto",
		                    "text-align": "center",
		                    color: "white",
		                  },
		                },
		              }
		            ],

		            // traits
		            API_HOST: API_HOST,
		            EmailTemplate: "",
		            StringifiedFragment: "",
		            projectId: localStorage.getItem("project_id") ? localStorage.getItem("project_id") : "",

		            CustomUrl: "",
		            BaseUrl: "",
		            APIPath: "",
		            BearerToken: "",
		            Username: "",
		            Password: "",
		            MoreHeaders: "",
		            APIMethod: "POST",
		            // APIBody: "",

		            EmailFrom: "",
		            EmailTo: "",
		            EmailSubject: "",
		            EmailCC: "",
		            EmailBCC: "",
		            // EmailBody: "",

		            DisplayComponent: false,

		            traits: [
		            	{
		            		type: "select",
		            		name: "EmailTemplate",
		            		label: "Email Template",
		            		changeProp: true,
		            		options: options.allFragmentData ? options.allFragmentData?.map((template) => {
		            			const obj = {
		            				name: template.fragment_name,
		            				value: template.fragment_name,
		            			};
		            			return obj;
		            		}) : "",
		            	},
		            	{
			                type: "text",
			                name: "CustomUrl",
			                label: "Custom URL",
			                changeProp: true,
			              },
			              {
			                type: "select",
			                name: "BaseUrl",
			                label: "Base URL",
			                options: [
			                  { id: "baseUrl1", name: "Base URL #1" },
			                  { id: "baseUrl2", name: "Base URL #2" },
			                  { id: "baseUrl3", name: "Base URL #3" },
			                  { id: "baseUrl4", name: "Base URL #4" },
			                  { id: "baseUrl5", name: "Base URL #5" },
			                  { id: "null", name: "No Base URL" },
			                ],
			                changeProp: true,
			              },
			              {
			                type: "text",
			                name: "APIPath",
			                label: "API Path",
			                placeholder: "Don't start with '/'",
			                changeProp: true,
			              },
			              {
			                type: "text",
			                name: "BearerToken",
			                label: "Bearer Token",
			                placeholder: "No bearer token provided",
			                changeProp: true,
			              },
			              {
			                type: "text",
			                name: "Username",
			                label: "Username",
			                changeProp: true,
			              },
			              {
			                type: "text",
			                name: "Password",
			                label: "Password",
			                changeProp: true,
			              },
			              {
			                type: "text",
			                name: "MoreHeaders",
			                label: "*More Headers",
			                placeholder: "k1:v1,k2:v2,k3:v3,...",
			                changeProp: true,
			              },
			              {
			                type: "select",
			                name: "APIMethod",
			                label: "API Method",
			                options: [
			                  { id: "POST", name: "Post" },
			                  { id: "PUT", name: "Put" },
			                ],
			                // value: "GET",
			                changeProp: true,
			              },
			              // {
			              //   type: "text",
			              //   name: "APIBody",
			              //   label: "API Body",
			              //   placeholder: "Json format",
			              //   changeProp: true,
			              // },
			              {
			                type: "text",
			                name: "EmailFrom",
			                label: "*Email From",
			                changeProp: true,
			              },
			              {
			                type: "text",
			                name: "EmailTo",
			                label: "*Email To",
			                changeProp: true,
			              },
			              {
			                type: "text",
			                name: "EmailSubject",
			                label: "*Email Subject",
			                changeProp: true,
			              },
			              {
			                type: "text",
			                name: "EmailCC",
			                label: "Email CC",
			                placeholder: "a@b.com, c@d.com, ...",
			                changeProp: true,
			              },
			              {
			                type: "text",
			                name: "EmailBCC",
			                label: "Email BCC",
			                placeholder: "a@b.com, c@d.com, ...",
			                changeProp: true,
			              },
			              // {
			              //   type: "text",
			              //   name: "EmailBody",
			              //   label: "Email Body",
			              //   changeProp: true,
			              // },
			              {
			                type: "checkbox",
			                name: "DisplayComponent",
			                label: "Hide/Show Component",
			                changeProp: true,
			              },
		            ],

		            changeProp: true,
		            "script-props": [
		            	"API_HOST",
		            	"EmailTemplate",
		            	"StringifiedFragment",
		            	"projectId",
		            	"CustomUrl",
			            "BaseUrl",
			            "APIPath",
			            "BearerToken",
			            "Username",
			            "Password",
			            "MoreHeaders",
			            "APIMethod",
			            // "APIBody",
			            "EmailFrom",
			            "EmailTo",
			            "EmailSubject",
			            "EmailCC",
			            "EmailBCC",
			            // "EmailBody",
			            "DisplayComponent",
		            ],
	    		},

	    		init() {
	    			this.on("change:EmailTemplate", this.emailTemplateHandler);
	    			this.on("change:DisplayComponent", this.displayComponentHandler);
	    		},

	    		async emailTemplateHandler() {
	    			let {API_HOST, EmailTemplate, projectId} = this.props();

	    			if (EmailTemplate !== "") {
	    				try {
		    				// make an api call
		    				const response = await fetch(`${API_HOST}template_fragment/get_all_fragments`, {
		    					method: "POST",
		    					headers: {
		    						"Content-Type": "application/json",
		    						"Authorization": `Bearer ${JSON.parse(localStorage.getItem('userInfo'))['token']}`,
		    					},
		    					body: JSON.stringify({projectId: projectId}),
		    				});

		    				let responseData = await response.json();

		    				const selectedFragment = responseData.filter((temp) => temp.fragment_name === EmailTemplate);
		    				console.log('selectedFragment', selectedFragment);

		    				const parser = new DOMParser();

		    				// parse html document from graepsjs string
		    				const htmlDocument = parser.parseFromString(selectedFragment[0]['fragment_content']['my-html'], 'text/html');
		    				console.log('htmlDocument', htmlDocument);

		    				// Create an iframe to execute the script in a sandboxed environment
					        const iframe = document.createElement('iframe');
					        iframe.style.display = 'none';
					        document.body.appendChild(iframe);

					        // Write the HTML, CSS, and JS into the iframe
					        const iframeDoc = iframe.contentDocument || iframe.contentWindow.document;
					        iframeDoc.open();
					        iframeDoc.write('<html><head></head><body></body></html>');
					        iframeDoc.close();

					        // Append CDN links to the iframe's head
					        const cdnLinks = [
					            'https://cdn.quilljs.com/1.3.6/quill.snow.css', // CSS CDN
					            'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css', // CSS CDN
					            "https://cdnjs.cloudflare.com/ajax/libs/uikit/2.27.5/css/uikit.min.css",
					            "https://cdnjs.cloudflare.com/ajax/libs/uikit/2.27.5/css/uikit.almost-flat.min.css",
					            "https://cdnjs.cloudflare.com/ajax/libs/uikit/2.27.5/css/uikit.gradient.min.css",
					            "https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/css/bootstrap.min.css",

					            'https://code.jquery.com/jquery-3.7.1.min.js', // JS CDN
					            'https://unpkg.com/xlsx/dist/xlsx.full.min.js', // JS CDN
					            "https://unpkg.com/vis-graph3d@latest/dist/vis-graph3d.min.js",
					            "https://cdn.jsdelivr.net/npm/chart.js",
								"https://cdnjs.cloudflare.com/ajax/libs/uikit/2.27.5/js/uikit.min.js",
					            "https://cdnjs.cloudflare.com/ajax/libs/uikit/2.27.5/js/components/pagination.min.js",
								"https://cdn.quilljs.com/1.3.6/quill.js",
								"https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/js/bootstrap.min.js",
								"https://cdn.jsdelivr.net/npm/@popperjs/core@2.11.6/dist/umd/popper.min.js",
					            // Add more CDN links as needed
					        ];

					        const appendResource = (link) => {
				                return new Promise((resolve, reject) => {
				                    const isCss = link.endsWith('.css');
				                    const element = iframeDoc.createElement(isCss ? 'link' : 'script');
				                    if (isCss) {
				                        element.rel = 'stylesheet';
				                        element.href = link;
				                        element.onload = () => {
				                            console.log(`Loaded CSS: ${link}`);
				                            resolve();
				                        };
				                        element.onerror = (error) => {
				                            console.error(`Failed to load CSS: ${link}`, error);
				                            reject(error);
				                        };
				                    } else {
				                        element.src = link;
				                        element.onload = () => {
				                            console.log(`Loaded JS: ${link}`);
				                            resolve();
				                        };
				                        element.onerror = (error) => {
				                            console.error(`Failed to load JS: ${link}`, error);
				                            reject(error);
				                        };
				                    }
				                    iframeDoc.head.appendChild(element);
				                    console.log(`Appending ${isCss ? 'CSS' : 'JS'}: ${link}`);
				                });
				            };

				            const jqueryLink = 'https://code.jquery.com/jquery-3.7.1.min.js';
				            await appendResource(jqueryLink);

				            const cdnPromises = cdnLinks.filter(link => link !== jqueryLink).map(link => appendResource(link));

				            const stylePromise = new Promise((resolve, reject) => {
				                try {
				                    const styleElement = iframeDoc.createElement('style');
				                    styleElement.textContent = selectedFragment[0]['fragment_content']['my-css'];
				                    iframeDoc.head.appendChild(styleElement);
				                    console.log("Appended style element");
				                    resolve();
				                } catch (error) {
				                    console.error("Failed to append style element", error);
				                    reject(error);
				                }
				            });

				            console.log("Appending HTML content...");
				            iframeDoc.body.innerHTML = htmlDocument.body.innerHTML;

				            // Wait for all resources to load
				            console.log("Waiting for all resources to load...");
				            await Promise.all([...cdnPromises, stylePromise]);

				            // Append the script element after resources have loaded
				            const scriptElement = iframeDoc.createElement('script');
				            scriptElement.setAttribute('type', 'text/javascript');
				            scriptElement.textContent = `
				                try {
				                    console.log("Inside script element before executing custom JS.");
				                    ${selectedFragment[0]['fragment_content']['my-js']}
				                    console.log("Inside script element after executing custom JS.");
				                } catch (error) {
				                    console.error("Error executing script element", error);
				                }
				            `;
				            iframeDoc.body.appendChild(scriptElement);
				            console.log("Script element executed");

				            // Add a small delay to ensure all scripts have executed
				            await new Promise(resolve => setTimeout(resolve, 5000));

				            // Extract the CSS rules from the style element
				            const styleSheets = Array.from(iframeDoc.styleSheets);
				            const cssRules = [];
				            styleSheets.forEach(sheet => {
				                try {
				                    Array.from(sheet.cssRules).forEach(rule => {
				                        cssRules.push(rule);
				                    });
				                } catch (e) {
				                    console.warn('Access to stylesheet rules is restricted', e);
				                }
				            });

				            // Function to apply CSS rules as inline styles
				            function applyInlineStyles(element, cssRules) {
				                cssRules.forEach(rule => {
				                    if (rule.type === CSSRule.STYLE_RULE) {
				                        const { selectorText, style } = rule;
				                        try {
				                            if (element.matches(selectorText)) {
				                                for (let i = 0; i < style.length; i++) {
				                                    const styleName = style[i];
				                                    if (styleName === 'background-image') {
				                                        const url = style.getPropertyValue(styleName);
				                                        element.style[styleName] = url.replace(/&quot;/g, '"');
				                                    } else {
				                                        element.style[styleName] = style[styleName];
				                                    }
				                                }
				                            }
				                        } catch (e) {
				                            console.warn(`Invalid selector: ${selectorText}`);
				                        }
				                    }
				                });

				                Array.from(element.children).forEach(child => applyInlineStyles(child, cssRules));
				            }

				            let fragmentStringified = '';
				            const emailTemplateWrapper = iframeDoc.querySelector('.email-template-wrapper');

				            if (emailTemplateWrapper) {
				                const emailTemplateInnerWrapper = emailTemplateWrapper.querySelector('.email-template-inner-wrapper');
				                if (emailTemplateInnerWrapper) {
				                    applyInlineStyles(emailTemplateWrapper, cssRules);
				                    // Serialize the email-template-wrapper and its inner HTML with inline styles
				                    fragmentStringified = new XMLSerializer().serializeToString(emailTemplateWrapper);
				                }
				            }

				            console.log('fragmentStringified', fragmentStringified);

				            this.set('StringifiedFragment', fragmentStringified);
				            document.body.removeChild(iframe);

				            if (fragmentStringified === "") {
				                toast.error("This template fragment did not render properly in the email body. Please try again, by slowly changing between your template fragments in the trait manager.", notifyTheme);
				            } else {
				                toast.success(`This template fragment has rendered in the email body.`, successTheme);
				            }
				        } catch (e) {
				            console.error(e);
				        }
				    }
				},

	    		displayComponentHandler() {
	    			let {DisplayComponent} = this.props();

	    			if (DisplayComponent) {
	    				this.setStyle({ display: 'none' });
	    			} else {
	    				this.setStyle({ display: 'block' });
	    			}
	    		},

	    		removed() {
			    	// only remove keys related to this component
			    	for (let j = 0; j < localStorage.length; j++) {
				        const key = localStorage.key(j);

				        // Check if the key contains the substring '_selected_column'
				        if (key && (key.includes('templateEmailStatus_'))) {
				            localStorage.removeItem(key);
				        }
				    }
			    },
	    	},
	    });
    }
};

export default CustomEmailTemplatePlugin;