import uniqid from "uniqid";
import UIkit from "uikit";
import $ from "jquery";
import { API_HOST } from "../api_utils/index";

const CustomPopupPlugin_PreviousVersion = (editor, options) => {
	console.log('CustomEmailPlugin options', options);

	editor.Blocks.add("button-popup-new", {
        label: "Popup Button (v2)",
        category: "Buttons",
        select: true,
        media: `<svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="currentColor" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"/></svg>`,
        content: { type: "button-popup-new" },
    });

    if (editor !== null && editor !== undefined) {
    	editor.DomComponents.addType("button-popup-new", {
	        // modal: {
	        //   defaults: {
	        //     tagName: "div",
	        //     // type: "button-popup-new",
	        //     attributes: {
	        //       class: "button-popup-new",
	        //     },

	        //     components: [
	        //       {
	        //         tagName: "button",
	        //         type: "default",
	        //         editable: true,
	        //         attributes: {
	        //           type: "button",
	        //           class: "btn btn-primary button-popup",
	        //           'data-bs-toggle': "modal",
	        //           'data-bs-target': "#staticBackdrop",
	        //         },

	        //         "style-default": {
	        //           width: "max-content",
	        //           padding: "0.7%",
	        //           "border-radius": "25px",
	        //           "background-color": "#1991E7",
	        //           border: "2px solid #1991E7",
	        //           color: "white",
	        //           cursor: "pointer",
	        //         },
	  
	        //         style: {
	        //           width: "max-content",
	        //           padding: "0.7%",
	        //           "border-radius": "25px",
	        //           "background-color": "#1991E7",
	        //           border: "2px solid #1991E7",
	        //           color: "white",
	        //           cursor: "pointer",
	        //         },

	        //         components: {
	        //           tagName: "p",
	        //           type: "text",

	        //           components: {
	        //             type: "textnode",
	        //             content: "Open",
	        //           },

	        //           "style-default": {
	        //             margin: "auto",
	        //             "text-align": "center",
	        //           },
	  
	        //           style: {
	        //             margin: "auto",
	        //             "text-align": "center",
	        //           },
	        //         },
	        //       }, {
	        //         tagName: "div",
	        //         type: "default",
	        //         attributes: {
	        //           class: "modal fade extra-popup-class",
	        //           id: "staticBackdrop",
	        //           'data-bs-backdrop': "static",
	        //           'data-bs-keyboard': "false",
	        //           tabindex: "-1",
	        //           'aria-labelledby': "staticBackdropLabel",
	        //           'aria-hidden': "true",
	        //         },

	        //         components: [
	        //           {
	        //             tagName: "div",
	        //             type: "default",
	        //             attributes: {
	        //               class: "modal-dialog",
	        //             },

	        //             components: [
	        //               {
	        //                 tagName: "div",
	        //                 type: "default",
	        //                 attributes: {
	        //                   class: "modal-content",
	        //                 },

	        //                 components: [
	        //                   {
	        //                     tagName: "div",
	        //                     type: "default",
	        //                     attributes: {
	        //                       class: "modal-header",
	        //                     },

	        //                     components: [
	        //                       {
	        //                         tagName: "h1",
	        //                         attributes: {
	        //                           class: "modal-title fs-5",
	        //                         },

	        //                         components: {
	        //                           tagName: "p",
	        //                           type: "text",
	        //                           editable: true,

	        //                           components: {
	        //                             type: "textnode",
	        //                             content: "Modal title",
	        //                           },
	        //                         },
	        //                       }, {
	        //                         tagName: "button",
	        //                         type: "default",
	        //                         attributes: {
	        //                           type: "button",
	        //                           class: "btn-close",
	        //                           'data-bs-dismiss': "modal",
	        //                           'aria-label': "Close",
	        //                         },
	        //                       },
	        //                     ],
	        //                   }, {
	        //                     tagName: "div",
	        //                     type: "default",
	        //                     attributes: {
	        //                       class: "modal-body",
	        //                     },
	        //                   }, {
	        //                     tagName: "div",
	        //                     type: "default",
	        //                     attributes: {
	        //                       class: "modal-footer",
	        //                     },

	        //                     components: [
	        //                       {
	        //                         tagName: "button",
	        //                         type: "default",
	        //                         attributes: {
	        //                           type: "button",
	        //                           class: "btn btn-secondary",
	        //                           'data-bs-dismiss': "modal",
	        //                         },

	        //                         components: {
	        //                           tagName: "p",
	        //                           type: "text",
	        //                           editable: true,

	        //                           components: {
	        //                             type: "textnode",
	        //                             content: "Close",
	        //                           },
	        //                         },
	        //                       }, {
	        //                         tagName: "button",
	        //                         attributes: {
	        //                           type: "button",
	        //                           class: "btn btn-primary",
	        //                         },

	        //                         components: {
	        //                           tagName: "p",
	        //                           type: "text",
	        //                           editable: true,

	        //                           components: {
	        //                             type: "textnode",
	        //                             content: "Action",
	        //                           },
	        //                         },
	        //                       },
	        //                     ],
	        //                   },
	        //                 ],
	        //               },
	        //             ],
	        //           },
	        //         ],
	        //       },
	        //     ],
	        //   },

	        //   init() {
	        //     console.log('popup-button-new');
	        //   },
	        // },

	        model: {
	          defaults: {
	            tagName: "span",

	            attributes: {
	              class: "contain-popup-button-new",
	            },

	            // traits
	            // blockModal: false,

	            // traits: [
	            // 	{
	            // 		type: "checkbox",
	            // 		label: "Block Modal",
	            // 		name: "blockModal",
	            // 		changeProp: true,
	            // 	},
	            // ],

	            // changeProp: true,
	            // "script-props": ["blockModal"],

	            components: [
	              {
	                // editable: true,
	                tagName: "popup-button-btn-new",
	                type: "default",
	                editable: true,
	                contenteditable: "true",
	                // components: `<button class="btn popup-button" id="popup-button" data-bs-toggle= "modal" data-bs-target= "#exampleModal">Open</button> `,
	                components: [
	                  {
	                    tagName: "button",
	                    attributes: {
	                      class: "btn popup-button-new",
	                      // id: "popup-button",
	                      "data-bs-toggle": "modal",
	                      "data-bs-target": "#exampleModal",
	                    },

	                    components: {
	                      tagName: "p",
	                      type: "text",
	                      editable: true,

	                      components: {
	                        type: "textnode",
	                        content: "Open",
	                      },
	                    },
	                  },
	                ],

	                "style-default": {
	                  width: "max-content",
		                  padding: "0.7%",
		                  "border-radius": "25px",
		                  "background-color": "#1991E7",
		                  border: "2px solid #1991E7",
		                  color: "white",
		                  cursor: "pointer",
	                },

	                style: {
	                  width: "max-content",
		                  padding: "0.7%",
		                  "border-radius": "25px",
		                  "background-color": "#1991E7",
		                  border: "2px solid #1991E7",
		                  color: "white",
		                  cursor: "pointer",
	                },
	              },
	              {
	                tagName: "div",
	                type: "default",
	                attributes: {
	                  class: "modal fade popup-button-modal-new",

	                  id: "exampleModal",

	                  tabindex: "-1",

	                  "aria-labelledby": "exampleModalLabel",

	                  "aria-hidden": "true",
	                },

	                "style-default": {
	                  position: "absolute !important",

	                  width: "100% !important",

	                  height: "100% !important",

	                  padding: "0.5em !important",

	                  border: "none !important",

	                  top: "0 !important",

	                  bottom: "0 !important",

	                  left: "0 !important",

	                  right: "0 !important",
	                },

	                style: {
	                  position: "absolute !important",

	                  width: "100% !important",

	                  height: "100% !important",

	                  padding: "0.5em !important",

	                  border: "none !important",

	                  top: "0 !important",

	                  bottom: "0 !important",

	                  left: "0 !important",

	                  right: "0 !important",
	                },

	                components: {
	                  tagName: "div",
	                  type: "default",
	                  attributes: { class: "modal-dialog modal-dialog-centered modal-dialog-scrollable" },

	                  components: {
	                    tagName: "div",
	                    type: "default",
	                    attributes: { class: "modal-content" },

	                    "style-default": {
	                      padding: "0.5em !important",
	                    },

	                    style: {
	                      padding: "0.5em !important",
	                    },

	                    components: [
	                      {
	                        tagName: "div",
	                        type: "default",
	                        attributes: { class: "modal-header" },

	                        components: [
	                          {
	                            tagName: "button",
	                            type: "default",
	                            attributes: {
	                              class: "btn-close",

	                              "data-bs-dismiss": "modal",

	                              "aria-label": "Close",
	                            },

	                            traits: ["data-bs-dismiss"],
	                          },
	                        ],
	                      },
	                      {
	                        tagName: "div",
	                        type: "default",
	                        attributes: { class: "modal-body" },
	                      },
	                      {
	                        tagName: "div",
	                        type: "default",
	                        attributes: { class: "modal-footer" },
	                      },
	                    ],
	                  },
	                },
	              },
	            ],
	          },

	          // init() {
	          // 	let uniqueId = uniqid('modal_');
	          // 	console.log('this new popup', this, uniqueId, this.components().models);

	          // 	let mainContainer = this;
	          // 	let popupBtn = mainContainer.components().models[0].find(".btn.popup-button-new")[0];
	          // 	let modal = mainContainer.components().models[1].find(".modal.fade.popup-button-modal-new")[0];

	          // 	// modal.setAttributes('id', uniqueId);
	          // 	// popupBtn.setAttributes('data-bs-target', `#${uniqueId}`);

	          // 	console.log(popupBtn, modal);
	          // },
	        },

	        view: {
	        	// init({ model }) {
	        	// 	this.listenTo(model, "change:blockModal", this.handlePropChange);
	        	// },

	        	// handlePropChange() {
	        	// 	let blockModal = this.model.props();
	        	// 	let modal = this.model.find('.popup-button-modal-new');
	        	// 	let button = this.model.find('.popup-button-new');
	        	// 	console.log('modal, button', modal, button);

	        	// 	if (blockModal) {
	        	// 		if (modal.length > 0) {
	        	// 			modal[0].setStyle({ display: 'none' });
	        	// 			console.log('modal btn blocked');
	        	// 		}
	        	// 	}
	        	// },

	        	onRender({ el, model }) {
		          	if (el.className === "contain-popup-button-new") {
		          		let uniqueId = uniqid('modal');
		          		console.log('this new popup', el, uniqueId);

		          		let mainContainer = model;
			          	let popupBtn = mainContainer.find('popup-button-btn-new')[0].find(".popup-button-new")[0];
			          	let modal = mainContainer.find(".popup-button-modal-new")[0];

			          	console.log('modal.getAttributes(), popupBtn', modal.getAttributes(), popupBtn.getAttributes());

			          	if(!('id' in modal.getAttributes())) {
			          		modal.addAttributes({id: uniqueId});

			          		popupBtn.setAttributes({
			          			'data-bs-target': `#${uniqueId}`,
			          			// reappend other attributes also even if they are not updating, because setAttributes will otherwise remove these and only set that one which is mentioned in this method
			          			class: "btn popup-button-new",
			          			"data-bs-toggle": "modal",
			          		});
			          		console.log('modified 1');
			          	}

			          	// if the modal element has ID but it doesn't start with substring 'modal', then update it
			          	else if('id' in modal.getAttributes() && !modal.getAttributes().id.startsWith('modal')) {
			          		modal.setAttributes({
			          			id: uniqueId,
			          			// reappend other attributes also even if they are not updating, because setAttributes will otherwise remove these and only set that one which is mentioned in this method
			          			class: "modal fade popup-button-modal-new",
			          			tabindex: "-1",
			          			"aria-labelledby": `${uniqueId}Label`,
			          			"aria-hidden": "true",
			          		});

			          		popupBtn.setAttributes({
			          			'data-bs-target': `#${uniqueId}`,
			          			// reappend other attributes also even if they are not updating, because setAttributes will otherwise remove these and only set that one which is mentioned in this method
			          			class: "btn popup-button-new",
			          			"data-bs-toggle": "modal",
			          		});
			          		console.log('modified 2');
			          	}			          	

			          	console.log(popupBtn, modal);
			        }
		        },
	        },
	    });
    }
};

export default CustomPopupPlugin_PreviousVersion;