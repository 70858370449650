import { useDrop } from 'react-dnd'
import { ItemTypes } from '../Constants/Constants'

const style = {
    height: '12rem',
    width: '12rem',
    marginRight: '1.5rem',
    marginLeft: '1.5rem',
    color: 'white',
    padding: '1rem',
    textAlign: 'center',
    fontSize: '1rem',
    lineHeight: 'normal',
    float: 'left'
}


export const Dustbin = () => {
    const [{ isOver, canDrop }, drop] = useDrop(() => ({
        accept: ItemTypes.BOX,
        drop: (item, monitor) => {
            console.log("DRiooedd ", item)
            return { name: 'Dustbin' }
        },
        collect: (monitor) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop()
        })
    }))

    const isActive = canDrop && isOver

    let backgroundColor = '#222'

    if(isActive) {
        backgroundColor = 'darkgreen'
    } else if(canDrop) {
        backgroundColor = 'darkkhaki'
    }

    return (
        <div ref={drop} style={{...style, backgroundColor}} data-testid="dustbin" >
            {isActive ? 'Release to drop' : 'Drag a box here'}
        </div>
    )
}