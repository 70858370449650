import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux';
import Loader from './Loader'
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { API_HOST } from '../api_utils/index'

import styles from './ScreensStylings/ProjectAnalytics.module.css';

const notifyTheme = {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
}

const successTheme = {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
}

// toast notification for empty tables
const infoTheme = {
    position: "top-right",
    autoClose: 10000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
}

const ProjectAnalytics = (props) => {
	const [data, setData] = useState([]);

	// get the id of the currently opened project
	const { projectId } = useParams();
	console.log('project id:', projectId);

	// get the list of all the projects
	const { allProjectData } = useSelector(state => state.allProjectData);
	console.log('allProjectData:', allProjectData);

	// filter that list using the current ID to get the details of the current project
	const targetProject = allProjectData.filter((project) => project['_id'] === projectId);
	console.log('targetProject[0]:', targetProject[0]);

	// google variables
	const gaAnalyticsPropId = targetProject[0].analytics_code;
	console.log('gaAnalyticsPropId:', gaAnalyticsPropId);

	const keyFileName = targetProject[0].keyFileName;
	console.log('keyFileName:', keyFileName);

	// const gCloudServiceAccId = targetProject[0].gCloudServiceAccId;
	// console.log('gCloudServiceAccId:', gCloudServiceAccId);

	// const gCloudProjectId = targetProject[0].gCloudProjectId;
	// console.log('gCloudProjectId:', gCloudProjectId);

	// const gmailAccount = targetProject[0].gmailAccount;
	// console.log('gmailAccount:', gmailAccount);

	// use this object that contains the VIEW_ID to make an api call to get the real time data
	useEffect(() => {
		fetch(`${API_HOST}redslinganalytics`, {
	    	method: 'post',
	    	headers: {
	    		'Content-Type': 'application/json',
	    	},
	    	body: JSON.stringify({ 
	    		gaAnalyticsPropId: gaAnalyticsPropId,
	    		keyFileName: keyFileName,
	    	}),
	    }).then((response) => response.json()).then((data) => {
	    	setData(data);
	    }).catch(error => {
	    	toast.error('An error occurred while fetching analytics data.\nPlease refresh this page or choose another project.', infoTheme);
	    });
	}, [projectId]);

	return(
		<div className={styles.projectAnalytics}>
			RedSling Project ID: {projectId}
			<div className={styles.tableContainer}>
		        <table className={styles.dataTable}>
		          <thead>
		            <tr>
		              <th>Page Title</th>
		              <th>Active Users</th>
		              <th>Active 1-Day Users</th>
		              <th>New Users</th>
		              <th>Total Users</th>
		              <th>Screen Page Views</th>
		              <th>Scrolled Users</th>
		            </tr>
		          </thead>
		          <tbody>
		            {data.map((row, index) => (
		              <tr key={index} className={index % 2 === 0 ? styles.evenRow : ''}>
		                <td>{row.dimensionValues[0].value}</td>
		                {row.metricValues.map((metric, metricIndex) => (
		                  <td key={metricIndex}>{metric.value}</td>
		                ))}
		              </tr>
		            ))}
		          </tbody>
		        </table>
		    </div>
		    <ToastContainer autoClose={5000} />
		</div>
	);
	// return data.map((row) => (
	//     <div key={row.date}>{`${row.date}: ${row.visits} visits`}</div>//(3)
	// ));
};

export default ProjectAnalytics;