const MobileTabPlugin = (editor, opts = {}) => {
    editor.DomComponents.addType("ionToolbarHome", {
        model: {
            defaults: {
            components: [
                {
                tagName: "ion-title",
                type: "text",
                content:"Listen now",
                attributes: { contenteditable: "true" },
                }
            ],
            },
        },
    });
    editor.DomComponents.addType("customIonHeaderHome", {
        model: {
            defaults: {
            components: [
                {
                tagName: "ion-toolbar",
                type: "ionToolbarHome",
                attributes: { contenteditable: "true" },
                }
            ],
            },
        },
    });

    editor.DomComponents.addType("customIonNavHome", {
        model: {
            defaults: {
            components: [
                {
                tagName: "div",
                type: "text",
                attributes: {
                    class: "example-content",
                },
                //content: "Listen now content",
                attributes: { contenteditable: "true" },
                }
            ],
            },
        },
    });

    editor.DomComponents.addType("customHomePageDiv", {
        model: {
            defaults: {
            components: [
                {
                    tagName: "ion-header",
                    type: "customIonHeaderHome",
                    style: {
                        padding: "0.5em",
                    },
                },
            ],
            },
        },
    });

    editor.DomComponents.addType("customIonTabHome", {
        model: {
          defaults: {
            components: [
              {
                tagName: "ion-nav",
                type: "customIonNavHome",
                attributes: {
                  id: "home-nav",
                },
                attributes: { contenteditable: "true" },
                
              },
              {
                tagName: "div",
                type: "customHomePageDiv",
                attributes: {
                  id: "home-page",
                },
              }
            ],
          },
        },
      });

      //radio
      editor.DomComponents.addType("ionToolbarRadio", {
        model: {
            defaults: {
            components: [
                {
                tagName: "ion-title",
                type: "text",
                content:"Radio",
                attributes: { contenteditable: "true" },
                }
            ],
            },
        },
    });
    editor.DomComponents.addType("customIonHeaderRadio", {
        model: {
            defaults: {
            components: [
                {
                tagName: "ion-toolbar",
                type: "ionToolbarRadio",
                attributes: { contenteditable: "true" },
                }
            ],
            },
        },
    });

    editor.DomComponents.addType("customIonNavRadio", {
        model: {
            defaults: {
            components: [
                {
                tagName: "div",
                type: "text",
                attributes: {
                    class: "example-content",
                },
                //content: "Radio content",
                attributes: { contenteditable: "true" },
                }
            ],
            },
        },
    });

    editor.DomComponents.addType("customRadioPageDiv", {
        model: {
            defaults: {
            components: [
                {
                    tagName: "ion-header",
                    type: "customIonHeaderRadio",
                    style: {
                        padding: "0.5em",
                    },
                },
            ],
            },
        },
    });

    editor.DomComponents.addType("customIonTabRadio", {
        model: {
          defaults: {
            components: [
              {
                tagName: "ion-nav",
                type: "customIonNavRadio",
                attributes: {
                  id: "radio-nav",
                },
              },
              {
                tagName: "div",
                type: "customRadioPageDiv",
                attributes: {
                  id: "radio-page",
                },
              }
            ],
          },
        },
      });

      //library
      editor.DomComponents.addType("ionToolbarLibrary", {
        model: {
            defaults: {
            components: [
                {
                tagName: "ion-title",
                type: "text",
                content:"Library",
                attributes: { contenteditable: "true" },
                }
            ],
            },
        },
    });
    editor.DomComponents.addType("customIonHeaderLibrary", {
        model: {
            defaults: {
            components: [
                {
                tagName: "ion-toolbar",
                type: "ionToolbarLibrary",
                attributes: { contenteditable: "true" },
                }
            ],
            },
        },
    });

    editor.DomComponents.addType("customIonNavLibrary", {
        model: {
            defaults: {
            components: [
                {
                tagName: "div",
                type: "text",
                attributes: {
                    class: "example-content",
                },
                //content: "Library content",
                attributes: { contenteditable: "true" },
                }
            ],
            },
        },
    });

    editor.DomComponents.addType("customLibraryPageDiv", {
        model: {
            defaults: {
            components: [
                {
                    tagName: "ion-header",
                    type: "customIonHeaderLibrary",
                    style: {
                        padding: "0.5em",
                    },
                },
            ],
            },
        },
    });

    editor.DomComponents.addType("customIonTabLibrary", {
        model: {
          defaults: {
            components: [
              {
                tagName: "ion-nav",
                type: "customIonNavLibrary",
                attributes: {
                  id: "library-nav",
                },
              },
              {
                tagName: "div",
                type: "customLibraryPageDiv",
                attributes: {
                  id: "library-page",
                },
              }
            ],
          },
        },
      });

      //search

      editor.DomComponents.addType("ionToolbarSearch", {
        model: {
            defaults: {
            components: [
                {
                tagName: "ion-title",
                type: "text",
                content:"Search",
                attributes: { contenteditable: "true" },
                }
            ],
            },
        },
    });
    editor.DomComponents.addType("customIonHeaderSearch", {
        model: {
            defaults: {
            components: [
                {
                tagName: "ion-toolbar",
                type: "ionToolbarSearch",
                attributes: { contenteditable: "true" },
                }
            ],
            },
        },
    });

    editor.DomComponents.addType("customIonNavSearch", {
        model: {
            defaults: {
            components: [
                {
                tagName: "div",
                type: "text",
                attributes: {
                    class: "example-content",
                },
                //content: "Search content",
                attributes: { contenteditable: "true" },
                }
            ],
            },
        },
    });

    editor.DomComponents.addType("customSearchPageDiv", {
        model: {
            defaults: {
            components: [
                {
                    tagName: "ion-header",
                    type: "customIonHeaderSearch",
                    style: {
                        padding: "0.5em",
                    },
                },
            ],
            },
        },
    });

    editor.DomComponents.addType("customIonTabSearch", {
        model: {
          defaults: {
            components: [
              {
                tagName: "ion-nav",
                type: "customIonNavSearch",
                attributes: {
                  id: "search-nav",
                },
              },
              {
                tagName: "div",
                type: "customSearchPageDiv",
                attributes: {
                  id: "search-page",
                },
              }
            ],
          },
        },
      });

    //bottom home button

    // editor.DomComponents.addType("customButtonContentHome", {
    //     model: {
    //       defaults: {
    //         components: [
    //           {
    //             tagName: "ion-icon",
    //             type: "text",
    //             attributes: {
    //               name: "play-circle",
    //             },
    //           }
    //         ],
    //       },
    //     },
    //   });
    editor.DomComponents.addType("customIonButtonHome", {
        model: {
          defaults: {
            components: [
              
              {
                tagName: "ion-icon",
                type: "text",
                attributes: {
                  name: "play-circle",
                },
              },
              {
                tagName: "p",
                type: "text",
                style: {
                    margin: 0,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                },
                content: "Listen Now",
              },
            ],
          },
        },
      });

      //radio
    //   editor.DomComponents.addType("customButtonContentRadio", {
    //     model: {
    //       defaults: {
    //         components: [
    //           {
    //             tagName: "ion-icon",
    //             type: "text",
    //             attributes: {
    //               name: "radio",
    //             },
    //           }
    //         ],
    //       },
    //     },
    //   });
    editor.DomComponents.addType("customIonButtonRadio", {
        model: {
          defaults: {
            components: [
                {
                    tagName: "ion-icon",
                    type: "text",
                    attributes: {
                      name: "radio",
                    },
                  },
              {
                tagName: "p",
                type: "text",
                style: {
                    margin: 0,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                },
                content: "Radio",
              }
            ],
          },
        },
      });

      //library
    //   editor.DomComponents.addType("customButtonContentLibrary", {
    //     model: {
    //       defaults: {
    //         components: [
    //           {
    //             tagName: "ion-icon",
    //             type: "text",
    //             attributes: {
    //               name: "library",
    //             },
    //           }
    //         ],
    //       },
    //     },
    //   });
    editor.DomComponents.addType("customIonButtonLibrary", {
        model: {
          defaults: {
            components: [
            {
                tagName: "ion-icon",
                type: "text",
                attributes: {
                    name: "library",
                },
                },
              {
                tagName: "p",
                type: "text",
                style: {
                    margin: 0,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                },
                content: "Library",
              }
            ],
          },
        },
      });

      // search
    //   editor.DomComponents.addType("customButtonContentSearch", {
    //     model: {
    //       defaults: {
    //         components: [
    //           {
    //             tagName: "ion-icon",
    //             type: "text",
    //             attributes: {
    //               name: "search",
    //             },
    //           }
    //         ],
    //       },
    //     },
    //   });
    editor.DomComponents.addType("customIonButtonSearch", {
        model: {
          defaults: {
            components: [
                {
                    tagName: "ion-icon",
                    type: "text",
                    attributes: {
                      name: "search",
                    },
                  },
              {
                tagName: "p",
                type: "text",
                style: {
                    margin: 0,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                },
                content: "Search",
              }
            ],
          },
        },
      });

    //bottom tab bar
    editor.DomComponents.addType("customIonTabBar", {
        model: {
          defaults: {
            components: [
              {
                tagName: "ion-tab-button",
                type: "customIonButtonHome",
                attributes: {
                  tab: "home",
                },
                //attributes: { contenteditable: "true" },
              },
              {
                tagName: "ion-tab-button",
                type: "customIonButtonRadio",
                attributes: {
                  tab: "radio",
                },
                //attributes: { contenteditable: "true" },
              },
              {
                tagName: "ion-tab-button",
                type: "customIonButtonLibrary",
                attributes: {
                  tab: "library",
                },
                //attributes: { contenteditable: "true" },
              },
              {
                tagName: "ion-tab-button",
                type: "customIonButtonSearch",
                attributes: {
                  tab: "search",
                },
                //attributes: { contenteditable: "true" },
              },
            ],
          },
        },
      });

    editor.DomComponents.addType("customIonTabMain", {
        model: {
          defaults: {
            components: [
              // TOP ION TAB
              {
                type: "customIonTabHome",
                tagName: "ion-tab",
                attributes: {
                    tab: "home",
                  },
              },
              {
                type: "customIonTabRadio",
                tagName: "ion-tab",
                attributes: {
                    tab: "radio",
                  },
              },
              {
                type: "customIonTabLibrary",
                tagName: "ion-tab",
                attributes: {
                    tab: "library",
                  },
              },
              {
                type: "customIonTabSearch",
                tagName: "ion-tab",
                attributes: {
                    tab: "search",
                  },
              },
              {
                type: "customIonTabBar",
                tagName: "ion-tab-bar",
                attributes: {
                    slot: "bottom",
                  },
              }
            ]
          },
        },
        
      });

      editor.DomComponents.addType("MobileTabWrapper", {
        model: {
          defaults: {
            components: [
              // TOP ION TAB
              {
                type: "customIonTabMain",
                tagName: "ion-tabs",
                style: {
                    position: "absolute",
                    padding: '0.5em !important',
                    margin: 'auto',
                    height: '100%'
                },
              }
            ]
          },
        },
        
      });

    function mobileTabScript (){
        const homeNav = document.querySelector('#home-nav');
        const homePage = document.querySelector('#home-page');
        homeNav.root = homePage;

        const radioNav = document.querySelector('#radio-nav');
        const radioPage = document.querySelector('#radio-page');
        radioNav.root = radioPage;

        const libraryNav = document.querySelector('#library-nav');
        const libraryPage = document.querySelector('#library-page');
        libraryNav.root = libraryPage;

        const searchNav = document.querySelector('#search-nav');
        const searchPage = document.querySelector('#search-page');
        searchNav.root = searchPage;
    }
    editor.BlockManager.add("MobileTabWrapper", {
        label: "Mobile Tabs",
        content: { type: "MobileTabWrapper" },
        category: "Tabs",
        media:
          '<svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="currentColor" viewBox="0 0 16 16"><path d="M2.5 7a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 1 0V8h10v.5a.5.5 0 0 0 1 0v-1a.5.5 0 0 0-.5-.5h-11Z"/><path d="M1 5a1 1 0 0 0-1 1v3.191a1 1 0 0 0 .553.894l1.618.81a1 1 0 0 0 .447.105H15a1 1 0 0 0 1-1V6a1 1 0 0 0-1-1H1Zm0 1h14v4H2.618L1 9.191V6Z"/></svg>',        
        selected: true,
        draggable: true,
        script: mobileTabScript,
      });

}

export default MobileTabPlugin;