import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Editor from "./Editor";
import DownloadProject from "./Screens/DownloadProject";
import Home from "./Home";
import Login from "./Screens/Login";
import SignUp from "./Screens/SignUp";
import ErrorPage from "./Screens/ErrorPage";
import CreateProjectScreen from "./Screens/CreateProjectScreen";
import AddNewPageScreen from "./Screens/AddNewPageScreen";
import AllProjects from "./Screens/AllProjects";
import AllPages from "./Screens/AllPages";
import ProjectDetail from "./Screens/ProjectDetail";
import PrivateRoute from "./Screens/PrivateRoute";
import DashboardScreen from "./Screens/DashboardScreen";
import AnalyticsScreen from "./Screens/AnalyticsScreen";
import UserProfileScreen from "./Screens/UserProfileScreen";
import CreateTableModal from "./Screens/CreateTableModal";
import CreateTableModal2 from "./Screens/CreateTableModal2";
import ModalTable from "./Screens/ModalTable";
import SAPTest from "./SAPTest";
import AllCustomModels from "./Screens/AllCustomModels.js";
import ExternalDataModels from "./Screens/ExternalDataModels.js";
import ChartTable from "./Screens/ChartTable";
import CustomChart from "./Screens/CustomChart";
import IndexScreen from "./Screens/IndexScreen";
import CompanyAdminScreen from "./Screens/CompanyAdminScreen";
import LogicBuilder from "./LogicBuilder/LogicBuilder";
import Main from "./Screens/Charts/Main";
import LogicBuilder2 from "./LogicBuilder2/LogicBuilder2";
import CalendyScreen from "./Screens/CalendyScreen";

import Test2index from "./Testing/Test2/Test2index";
import Test from "./Testing/Test4/Test";
import Mobile from "./PreviewApp/Mobile";
import Tab from "./PreviewApp/Tab";
import Laptop from "./PreviewApp/Laptop";
import AddUserToProject from "./Screens/AddUserToProject";
import CronJobScreen from "./Screens/CronJobsScreen";
import CreateCronJob from "./Screens/CreateCronJob";
import CronJobDetail from "./Screens/CronJobDetail";
import LoginNew from "./Screens/LoginNew";
import TestTable from "./Screens/TestTable";
import ProjectAnalytics from "./Screens/ProjectAnalytics";
import DynamicRouteLb from "./Screens/BackendLbFiles/DynamicRouteLb.js";
import CreateNewDynamicRoute from "./Screens/BackendLbFiles/CreateNewDynamicRoute.js";
import AllFragments from "./Screens/TemplateFragment/AllFragments.js";
import AllCrons from "./Screens/Crons/AllCrons.js";
import CreateTemplateFragment from "./Screens/TemplateFragment/CreateTemplateFragment.js";
import FragmentEditor from "./Screens/TemplateFragment/FragmentEditor.js";
import ErrorBoundary from "./ErrorBoundary.js";
import GenerateToken from "./Screens/dynamicJwtFiles/GenerateToken.js";
import CreateMiddleWare from "./Screens/dynamicJwtFiles/CreateMiddleWare.js";
import NotificationList from "./Screens/NotificationCenter/NotificationList.js";
import CreateNewConfigForPush from "./Screens/NotificationCenter/CreateNewConfigForPush.js";

// import BlocklyEditor from './Screens/Blockly';

function App() {
  return (
    <Router>
      {/* <ErrorBoundary> */}
      <Routes>
        <Route exact path="/newLogin" element={<LoginNew />} />
        <Route exact path="/testTable" element={<TestTable />} />
        <Route
          exact
          path="/dashboard"
          element={<PrivateRoute Component={DashboardScreen} />}
        />
        {/*<Route exact path="/" element={<PrivateRoute Component={IndexScreen} />} />*/}
        <Route exact path="/" element={<IndexScreen />} />
        {/*<Route exact path="/createProject" element={<PrivateRoute Component={CreateProjectScreen} />} />*/}
        <Route
          exact
          path="/createProject/:app"
          element={<CreateProjectScreen />}
        />
        <Route
          exact
          path="/projectList/:app/:secretToken?"
          element={<PrivateRoute Component={AllProjects} />}
        />
        <Route
          exact
          path="/cronJob/:projectId"
          element={<PrivateRoute Component={CronJobScreen} />}
        />

        {/* MOBILE NOTIFICATION ROUTE */}
        <Route
          exact
          path="/mobile_notification/:project"
          element={<PrivateRoute Component={NotificationList} />}
        />
        <Route
          exact
          path="/mobile_notification/create_new_config/:project"
          element={<PrivateRoute Component={CreateNewConfigForPush} />}
        />

        <Route
          exact
          path="/calendy"
          element={<PrivateRoute Component={CalendyScreen} />}
        />
        <Route
          exact
          path="/create_cron_job/:projectId"
          element={<PrivateRoute Component={CreateCronJob} />}
        />
        <Route
          exact
          path="/cron_job_detail/:id"
          element={<PrivateRoute Component={CronJobDetail} />}
        />
        <Route exact path="/sap" element={<SAPTest />} />
        <Route
          exact
          path="/customtables"
          element={<PrivateRoute Component={AllCustomModels} />}
        />
        <Route
          exact
          path="/company_admin"
          element={<PrivateRoute Component={CompanyAdminScreen} />}
        />
        <Route
          exact
          path="/project_page/:projectId"
          element={<PrivateRoute Component={ProjectDetail} />}
        />
        {/*<Route exact path="/project_page/:projectId/project_analytics" element={<PrivateRoute Component={ProjectAnalytics} />} />*/}
        <Route
          exact
          path="/project_page/:projectId/project_analytics"
          element={<ProjectAnalytics />}
        />
        <Route
          exact
          path="/addUserToProject/:projectId"
          element={<PrivateRoute Component={AddUserToProject} />}
        />
        <Route
          exact
          path="/pageList"
          element={<PrivateRoute Component={AllPages} />}
        />
        <Route
          exact
          path="/chart_table"
          element={<PrivateRoute Component={ChartTable} />}
        />
        <Route
          exact
          path="/custom_chart"
          element={<PrivateRoute Component={CustomChart} />}
        />
        {/*<Route exact path="/addNewPage/:projectId" element={<PrivateRoute Component={AddNewPageScreen} />} />*/}
        <Route
          exact
          path="/addNewPage/:projectId"
          element={<AddNewPageScreen />}
        />
        <Route
          exact
          path="/userProfile"
          element={<PrivateRoute Component={UserProfileScreen} />}
        />
        {/* <Route exact path="/home" element={<Home />} /> */}
        <Route
          exact
          path="/analytics"
          element={<PrivateRoute Component={AnalyticsScreen} />}
        />
        {/* <Route exact path="/create_modal/:project" element={<PrivateRoute Component={CreateTableModal} />} /> */}
        <Route
          exact
          path="/create_modal/:project"
          element={<PrivateRoute Component={CreateTableModal} />}
        />
        <Route
          exact
          path="/alltables/:project"
          element={<PrivateRoute Component={AllCustomModels} />}
        />
        <Route
          exact
          path="/dynamic_route/:project"
          element={<PrivateRoute Component={DynamicRouteLb} />}
        />
        <Route
          exact
          path="/create_new_route/:project"
          element={<PrivateRoute Component={CreateNewDynamicRoute} />}
        />
        <Route
          exact
          path="/view_crons/:project"
          element={<PrivateRoute Component={AllCrons} />}
        />
        <Route
          exact
          path="/create_cron/:project"
          element={<PrivateRoute Component={CreateTemplateFragment} />}
        />
        <Route
          exact
          path="/allfragments/:project"
          element={<PrivateRoute Component={AllFragments} />}
        />
        <Route
          exact
          path="/create_new_fragment/:project"
          element={<PrivateRoute Component={CreateTemplateFragment} />}
        />
        <Route
          exact
          path="/fragment_editor/:fragment_id"
          element={<FragmentEditor />}
        />

        {/* JWT MIDDLEWARE ROUTES */}
        <Route
          exact
          path="/generate_project_token/:projectId"
          element={<PrivateRoute Component={GenerateToken} />}
        />

        <Route
          exact
          path="/create_project_middleware/:projectId"
          element={<PrivateRoute Component={CreateMiddleWare} />}
        />

        <Route
          exact
          path="/externaltables/:project"
          element={<PrivateRoute Component={ExternalDataModels} />}
        />
        <Route
          exact
          path="/modal_table/:project"
          element={<PrivateRoute Component={ModalTable} />}
        />
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/signUp" element={<SignUp />} />
        <Route
          exact
          path="/editor/:pageId/:secretToken?"
          element={<Editor />}
        />
        <Route
          exact
          path="/BuildProject/:projectId/:secretToken?"
          element={<PrivateRoute Component={DownloadProject} />}
        />
        <Route exact path="/admin_chart" element={<Main />} />
        <Route exact path="/editor/:pageId/logic" element={<LogicBuilder />} />
        <Route
          exact
          path="/editor/:pageId/logic2"
          element={<LogicBuilder2 />}
        />
        <Route exact path="/editor/:pageId/logic3" element={<Test />} />
        <Route exact path="/logic2" element={<Test2index />} />

        {/* Preview routes */}
        <Route exact path="/mobile_preview/:id" element={<Mobile />} />
        <Route exact path="/tab_preview/:id" element={<Tab />} />
        <Route exact path="/large_preview/:id" element={<Laptop />} />
        <Route exact path="/large_preview/:width/:id" element={<Laptop />} />
        {/*
                <Route exact path="/large_preview/:width/:height/:id" element={<Laptop />} />*/}

        <Route path="*" element={<ErrorPage />}></Route>
      </Routes>
      {/* </ErrorBoundary> */}
    </Router>
  );
}

export default App;
