import uniqid from "uniqid";
import UIkit from "uikit";
import $ from "jquery";
import { API_HOST } from "../api_utils/index";

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const CustomExcelPlugin = (editor, options) => {
	console.log('CustomExcelPlugin options', options);

	const XLSX = require('xlsx');

	const notifyTheme = {
		position: "top-right",
		autoClose: 7500,
		hideProgressBar: false,
		closeOnClick: true,
		pauseOnHover: true,
		draggable: true,
		progress: undefined,
		theme: "light",
	};

	const notifyTheme2 = {
		position: "top-right",
		autoClose: 2000,
		hideProgressBar: false,
		closeOnClick: true,
		pauseOnHover: true,
		draggable: true,
		progress: undefined,
		theme: "light",
	};

	const successTheme = {
	  position: "top-right",
	  autoClose: 5000,
	  hideProgressBar: false,
	  closeOnClick: true,
	  pauseOnHover: true,
	  draggable: true,
	  progress: undefined,
	  theme: "light",
	};

	// toast notification for empty tables
	const infoTheme = {
	  position: "top-right",
	  autoClose: 7500,
	  hideProgressBar: false,
	  closeOnClick: true,
	  pauseOnHover: true,
	  draggable: true,
	  progress: undefined,
	  theme: "light",
	};

    if (editor !== null && editor !== undefined) {
    	// EXCEL EXPORT
		editor.Blocks.add("excel-downloader-container", {
	        label: "Download Excel",
	        category: "Buttons",
	        select: true,
	        media: `<svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="currentColor" viewBox="0 0 16 16"><path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2M9.5 3A1.5 1.5 0 0 0 11 4.5h2V9H3V2a1 1 0 0 1 1-1h5.5zM3 12v-2h2v2zm0 1h2v2H4a1 1 0 0 1-1-1zm3 2v-2h3v2zm4 0v-2h3v1a1 1 0 0 1-1 1zm3-3h-3v-2h3zm-7 0v-2h3v2z"/></svg>`,
	        content: { type: "excel-downloader-container" },
	    });

    	const downloadExcelScript = function(props) {
    		// console.log('excel-downloader-container props', props);

    		let excelDownloaderContainer = this;

    		async function initLib(props) {
    			console.log('excel-downloader-container initLib props', props);

    			// fetching API data
				let apiData;
				let bu, bubt; // base url bearer token, temp var

    			let {allowDownload,
    				excelFileName,
    				sheetColumns,
	    			apiResponseColumnKeynames,
	    			API_HOST,
	    			options,
	    			projectId,
	    			customUrlValue,
					baseUrlValue,
					apiPathValue,
					apiMethodValue,
					apiUsernameValue,
					apiPasswordValue,
					apiBearerTokenValue,
					apiMoreHeadersValue,
					apiBodyValue,
					nestedArrOfObjKeynameValue,
	    		} = props;

    			// base url bearer token taken directly from local storage after getting downloaded
	            let baseUrlData,
	            baseUrl1BearerToken,
				baseUrl2BearerToken,
				baseUrl3BearerToken,
				baseUrl4BearerToken,
				baseUrl5BearerToken;
	            // getting baseUrl data from local storage for run-time access
				let baseUrlDataFromStorage = localStorage.getItem("baseUrlData");
				if (baseUrlDataFromStorage) {
				  baseUrlData = JSON.parse(baseUrlDataFromStorage);
				}
				// extracting token values from base URLs from baseUrlData
				if (baseUrlData) {
				    try {
					      if (`baseUrl1_${projectId}` in baseUrlData) {
					        baseUrl1BearerToken = baseUrlData[`baseUrl1_${projectId}`].token;
					      }
					      if (`baseUrl2_${projectId}` in baseUrlData) {
					        baseUrl2BearerToken = baseUrlData[`baseUrl2_${projectId}`].token;
					      }
					      if (`baseUrl3_${projectId}` in baseUrlData) {
					        baseUrl3BearerToken = baseUrlData[`baseUrl3_${projectId}`].token;
					      }
					      if (`baseUrl4_${projectId}` in baseUrlData) {
					        baseUrl4BearerToken = baseUrlData[`baseUrl4_${projectId}`].token;
					      }
					      if (`baseUrl5_${projectId}` in baseUrlData) {
					        baseUrl5BearerToken = baseUrlData[`baseUrl5_${projectId}`].token;
					      }
				    } catch (e) {
					      // statements
					      console.log(e);
				    }
				}

				// if only custom url is provided and its a GET call
				if (customUrlValue && (!baseUrlValue || baseUrlValue === 'null') && !apiPathValue && apiMethodValue === 'GET') {
					// if nothing is provided, for authentication
					if (!apiUsernameValue && !apiPasswordValue && !apiBearerTokenValue) {
						// add extra headers, if needed
		                let config = {
		                  	headers: {},
		                };

		                if (apiMoreHeadersValue) {
			                // ['a:b', "c:d"]
			                // extracting headers from More_Headers trait
			                apiMoreHeadersValue.split(/,\s*/).forEach((header) => {
			                    let key, val;
			                    [key, val] = header.split(":");
			                    console.log("[key, val]:\n", key, val);

			                    config.headers[key] = val;
			                });
		                }

		                console.log("config:", config);

		                try {
			                // GET call to get the data
			                let response = await fetch(`${customUrlValue}`, config);
			                let responseData = await response.json();
			                apiData = responseData;
		                } catch (e) {
			                // statements
			                console.log(e);
			            }
					}

					// if bearer token is provided but nothing else, for authentication
					else if (!apiUsernameValue && !apiPasswordValue && apiBearerTokenValue) {
						// add extra headers, if needed
		                let config = {
			                headers: {
			                  	"Content-Type": "application/json",
		                    	Authorization: `Bearer ${apiBearerTokenValue}`,
			                },
		                };

		                if (apiMoreHeadersValue) {
			                // ['a:b', "c:d"]
			                // extracting headers from More_Headers trait
			                apiMoreHeadersValue.split(/,\s*/).forEach((header) => {
			                    let key, val;
			                    [key, val] = header.split(":");
			                    console.log("[key, val]:\n", key, val);

			                    config.headers[key] = val;
			                });
		                }

		                console.log("config:", config);

		                try {
			                // GET call to get the data
			                let response = await fetch(`${customUrlValue}`, config);
			                let responseData = await response.json();
			                apiData = responseData;
		                } catch (e) {
			                // statements
			                console.log(e);
			            }
					}

					// if username & password are provided but nothing else, for authentication
					else if (apiUsernameValue && apiPasswordValue && !apiBearerTokenValue) {
						// add extra headers, if needed
		                let config = {
			                headers: {
			                  	Authorization: "Basic " + btoa(apiUsernameValue + ":" + apiPasswordValue),
			                },
		                };

		                if (apiMoreHeadersValue) {
			                // ['a:b', "c:d"]
			                // extracting headers from More_Headers trait
			                apiMoreHeadersValue.split(/,\s*/).forEach((header) => {
			                    let key, val;
			                    [key, val] = header.split(":");
			                    console.log("[key, val]:\n", key, val);

			                    config.headers[key] = val;
			                });
		                }

		                console.log("config:", config);

		                try {
			                // GET call to get the data
			                let response = await fetch(`${customUrlValue}`, config);
			                let responseData = await response.json();
			                apiData = responseData;
		                } catch (e) {
			                // statements
			                console.log(e);
			            }
					}
				}

				// if only base url is provided and its a GET call
				else if (!customUrlValue && (baseUrlValue || !baseUrlValue === 'null') && apiPathValue && apiMethodValue === 'GET') {
					// concatenating base url and its remaining part
		            let url, baseUrlBearerToken;

		            if (baseUrlValue === "baseUrl1") {
		                url = options.baseUrl1 + apiPathValue;
		                baseUrlBearerToken = baseUrl1BearerToken;
		            } else if (baseUrlValue === "baseUrl2") {
		                url = options.baseUrl2 + apiPathValue;
		                baseUrlBearerToken = baseUrl2BearerToken;
		            } else if (baseUrlValue === "baseUrl3") {
		                url = options.baseUrl3 + apiPathValue;
		                baseUrlBearerToken = baseUrl3BearerToken;
		            } else if (baseUrlValue === "baseUrl4") {
		                url = options.baseUrl4 + apiPathValue;
		                baseUrlBearerToken = baseUrl4BearerToken;
		            } else if (baseUrlValue === "baseUrl5") {
		                url = options.baseUrl5 + apiPathValue;
		                baseUrlBearerToken = baseUrl5BearerToken;
		            }
		            bu = url;
		            bubt = baseUrlBearerToken
		            console.log("url, baseUrlBearerToken", url, baseUrlBearerToken);

					// if nothing is provided, for authentication
					if (!apiUsernameValue && !apiPasswordValue && !apiBearerTokenValue && !baseUrlBearerToken) {
						// add extra headers, if needed
		                let config = {
		                  	headers: {},
		                };

		                if (apiMoreHeadersValue) {
			                // ['a:b', "c:d"]
			                // extracting headers from More_Headers trait
			                apiMoreHeadersValue.split(/,\s*/).forEach((header) => {
			                    let key, val;
			                    [key, val] = header.split(":");
			                    console.log("[key, val]:\n", key, val);

			                    config.headers[key] = val;
			                });
		                }

		                console.log("config:", config);

		                try {
			                // GET call to get the data
			                let response = await fetch(`${url}`, config);
			                let responseData = await response.json();
			                apiData = responseData;
		                } catch (e) {
			                // statements
			                console.log(e);
			            }
					}

					// if bearer token is provided but nothing else, for authentication
					else if (!apiUsernameValue && !apiPasswordValue && !apiBearerTokenValue && baseUrlBearerToken) {
						// add extra headers, if needed
		                let config = {
			                headers: {
			                  	"Content-Type": "application/json",
		                    	Authorization: `Bearer ${baseUrlBearerToken}`,
			                },
		                };

		                if (apiMoreHeadersValue) {
			                // ['a:b', "c:d"]
			                // extracting headers from More_Headers trait
			                apiMoreHeadersValue.split(/,\s*/).forEach((header) => {
			                    let key, val;
			                    [key, val] = header.split(":");
			                    console.log("[key, val]:\n", key, val);

			                    config.headers[key] = val;
			                });
		                }

		                console.log("config:", config);

		                try {
			                // GET call to get the data
			                let response = await fetch(`${url}`, config);
			                let responseData = await response.json();
			                apiData = responseData;
		                } catch (e) {
			                // statements
			                console.log(e);
			            }
					}

					// if username & password are provided but nothing else, for authentication
					else if (apiUsernameValue && apiPasswordValue && !apiBearerTokenValue && !baseUrlBearerToken) {
						// add extra headers, if needed
		                let config = {
			                headers: {
			                  	Authorization: "Basic " + btoa(apiUsernameValue + ":" + apiPasswordValue),
			                },
		                };

		                if (apiMoreHeadersValue) {
			                // ['a:b', "c:d"]
			                // extracting headers from More_Headers trait
			                apiMoreHeadersValue.split(/,\s*/).forEach((header) => {
			                    let key, val;
			                    [key, val] = header.split(":");
			                    console.log("[key, val]:\n", key, val);

			                    config.headers[key] = val;
			                });
		                }

		                console.log("config:", config);

		                try {
			                // GET call to get the data
			                let response = await fetch(`${url}`, config);
			                let responseData = await response.json();
			                apiData = responseData;
		                } catch (e) {
			                // statements
			                console.log(e);
			            }
					}
				}

				// if only custom url is provided and its a POST call
				else if (customUrlValue && apiBodyValue && (!baseUrlValue || baseUrlValue === 'null') && !apiPathValue && apiMethodValue === 'POST') {
					// if nothing is provided, for authentication
					if (!apiUsernameValue && !apiPasswordValue && !apiBearerTokenValue) {
						// add extra headers, if needed
		                let config = {
		                	method: "POST",
              				body: apiBodyValue,
		                  	headers: {
		                  		"Content-Type": "application/json",
		                  	},
		                };

		                if (apiMoreHeadersValue) {
			                // ['a:b', "c:d"]
			                // extracting headers from More_Headers trait
			                apiMoreHeadersValue.split(/,\s*/).forEach((header) => {
			                    let key, val;
			                    [key, val] = header.split(":");
			                    console.log("[key, val]:\n", key, val);

			                    config.headers[key] = val;
			                });
		                }

		                console.log("config:", config);

		                try {
			                // GET call to get the data
			                let response = await fetch(`${customUrlValue}`, config);
			                let responseData = await response.json();
			                apiData = responseData;
		                } catch (e) {
			                // statements
			                console.log(e);
			            }
					}

					// if bearer token is provided but nothing else, for authentication
					else if (!apiUsernameValue && !apiPasswordValue && apiBearerTokenValue) {
						// add extra headers, if needed
		                let config = {
		                	method: "POST",
                  			body: apiBodyValue,
			                headers: {
                  				"Content-Type": "application/json",
		                    	Authorization: `Bearer ${apiBearerTokenValue}`,
			                },
		                };

		                if (apiMoreHeadersValue) {
			                // ['a:b', "c:d"]
			                // extracting headers from More_Headers trait
			                apiMoreHeadersValue.split(/,\s*/).forEach((header) => {
			                    let key, val;
			                    [key, val] = header.split(":");
			                    console.log("[key, val]:\n", key, val);

			                    config.headers[key] = val;
			                });
		                }

		                console.log("config:", config);

		                try {
			                // GET call to get the data
			                let response = await fetch(`${customUrlValue}`, config);
			                let responseData = await response.json();
			                apiData = responseData;
		                } catch (e) {
			                // statements
			                console.log(e);
			            }
					}

					// if username & password are provided but nothing else, for authentication
					else if (apiUsernameValue && apiPasswordValue && !apiBearerTokenValue) {
						// add extra headers, if needed
		                let config = {
		                	method: "POST",
                  			body: apiBodyValue,
			                headers: {
                  				"Content-Type": "application/json",
			                  	Authorization: "Basic " + btoa(apiUsernameValue + ":" + apiPasswordValue),
			                },
		                };

		                if (apiMoreHeadersValue) {
			                // ['a:b', "c:d"]
			                // extracting headers from More_Headers trait
			                apiMoreHeadersValue.split(/,\s*/).forEach((header) => {
			                    let key, val;
			                    [key, val] = header.split(":");
			                    console.log("[key, val]:\n", key, val);

			                    config.headers[key] = val;
			                });
		                }

		                console.log("config:", config);

		                try {
			                // GET call to get the data
			                let response = await fetch(`${customUrlValue}`, config);
			                let responseData = await response.json();
			                apiData = responseData;
		                } catch (e) {
			                // statements
			                console.log(e);
			            }
					}
				}

				// if only base url is provided and its a POST call
				else if (!customUrlValue && apiBodyValue && (baseUrlValue || !baseUrlValue === 'null') && apiPathValue && apiMethodValue === 'POST') {
					// concatenating base url and its remaining part
		            let url, baseUrlBearerToken;

		            if (baseUrlValue === "baseUrl1") {
		                url = options.baseUrl1 + apiPathValue;
		                baseUrlBearerToken = baseUrl1BearerToken;
		            } else if (baseUrlValue === "baseUrl2") {
		                url = options.baseUrl2 + apiPathValue;
		                baseUrlBearerToken = baseUrl2BearerToken;
		            } else if (baseUrlValue === "baseUrl3") {
		                url = options.baseUrl3 + apiPathValue;
		                baseUrlBearerToken = baseUrl3BearerToken;
		            } else if (baseUrlValue === "baseUrl4") {
		                url = options.baseUrl4 + apiPathValue;
		                baseUrlBearerToken = baseUrl4BearerToken;
		            } else if (baseUrlValue === "baseUrl5") {
		                url = options.baseUrl5 + apiPathValue;
		                baseUrlBearerToken = baseUrl5BearerToken;
		            }
		            bu = url;
		            bubt = baseUrlBearerToken
		            console.log("url, baseUrlBearerToken", url, baseUrlBearerToken);

					// if nothing is provided, for authentication
					if (!apiUsernameValue && !apiPasswordValue && !apiBearerTokenValue && !baseUrlBearerToken) {
						// add extra headers, if needed
		                let config = {
		                  	method: "POST",
              				body: apiBodyValue,
		                  	headers: {
		                  		"Content-Type": "application/json",
		                  	},
		                };

		                if (apiMoreHeadersValue) {
			                // ['a:b', "c:d"]
			                // extracting headers from More_Headers trait
			                apiMoreHeadersValue.split(/,\s*/).forEach((header) => {
			                    let key, val;
			                    [key, val] = header.split(":");
			                    console.log("[key, val]:\n", key, val);

			                    config.headers[key] = val;
			                });
		                }

		                console.log("config:", config);

		                try {
			                // GET call to get the data
			                let response = await fetch(`${url}`, config);
			                let responseData = await response.json();
			                apiData = responseData;
		                } catch (e) {
			                // statements
			                console.log(e);
			            }
					}

					// if bearer token is provided but nothing else, for authentication
					else if (!apiUsernameValue && !apiPasswordValue && !apiBearerTokenValue && baseUrlBearerToken) {
						// add extra headers, if needed
		                let config = {
		                	method: "POST",
                  			body: apiBodyValue,
			                headers: {
			                  	"Content-Type": "application/json",
		                    	Authorization: `Bearer ${baseUrlBearerToken}`,
			                },
		                };

		                if (apiMoreHeadersValue) {
			                // ['a:b', "c:d"]
			                // extracting headers from More_Headers trait
			                apiMoreHeadersValue.split(/,\s*/).forEach((header) => {
			                    let key, val;
			                    [key, val] = header.split(":");
			                    console.log("[key, val]:\n", key, val);

			                    config.headers[key] = val;
			                });
		                }

		                console.log("config:", config);

		                try {
			                // GET call to get the data
			                let response = await fetch(`${url}`, config);
			                let responseData = await response.json();
			                apiData = responseData;
		                } catch (e) {
			                // statements
			                console.log(e);
			            }
					}

					// if username & password are provided but nothing else, for authentication
					else if (apiUsernameValue && apiPasswordValue && !apiBearerTokenValue && !baseUrlBearerToken) {
						// add extra headers, if needed
		                let config = {
		                	method: "POST",
                  			body: apiBodyValue,
			                headers: {
			                	"Content-Type": "application/json",
			                  	Authorization: "Basic " + btoa(apiUsernameValue + ":" + apiPasswordValue),
			                },
		                };

		                if (apiMoreHeadersValue) {
			                // ['a:b', "c:d"]
			                // extracting headers from More_Headers trait
			                apiMoreHeadersValue.split(/,\s*/).forEach((header) => {
			                    let key, val;
			                    [key, val] = header.split(":");
			                    console.log("[key, val]:\n", key, val);

			                    config.headers[key] = val;
			                });
		                }

		                console.log("config:", config);

		                try {
			                // GET call to get the data
			                let response = await fetch(`${url}`, config);
			                let responseData = await response.json();
			                apiData = responseData;
		                } catch (e) {
			                // statements
			                console.log(e);
			            }
					}
				}

				console.log('apiData:', apiData);

	    		let excelDownloaderButton = excelDownloaderContainer.getElementsByClassName("excel-downloader-button")[0];

	    		// if the column and keyname arrays are equal only then prepare data for download
	    		if (sheetColumns.length > 0 && apiResponseColumnKeynames.length > 0 && sheetColumns.length === apiResponseColumnKeynames.length) {
		    		// if allowDownload is true only then make the button downloadable so that otherwise users can edit it
		    		if (allowDownload) {
			    		excelDownloaderButton.onclick = function(e) {
			    			// Create workbook and worksheet
				            const wb = XLSX.utils.book_new();
				            const ws = XLSX.utils.json_to_sheet([], { header: sheetColumns });

				            // Prepare data for Excel sheet
				            const data = apiData.map(obj => {
				                return apiResponseColumnKeynames.map(key => {
				                    // Check if the column name contains a ':' for reference entries
				                    if (key.includes(':')) {
				                        // Split the column name into table name and reference key
				                        const [tableName, refKey] = key.split(':').map(str => str.trim());
				                        console.log('tableName, refKey', tableName, refKey);

				                        // Check if the table name exists in the object
				                        if (obj[tableName] !== null && typeof obj[`${tableName}_data`] === 'object' && refKey) {
				                            // Get the value of the reference key from the table object
				                            return obj[`${tableName}_data`][refKey] || '';
				                        }
				                    }
				                    // If no ':' is present or table name doesn't exist, return the value as is
				                    return obj[key];
				                });
				            });

					        console.log('data for excel file', data);

				            XLSX.utils.sheet_add_json(ws, data, { origin: 'A2', skipHeader: true });

				            // Add worksheet to workbook
				            XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

				            // Generate Excel file
				            const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

				            // Create a Blob from workbook data
				            const blob = new Blob([wbout], { type: 'application/octet-stream' });

				            // Create a download link
				            const url = URL.createObjectURL(blob);
				            const a = document.createElement('a');
				            a.href = url;
				            a.download = excelFileName ? excelFileName + '.xlsx' : 'redSling_excel_export.xlsx';

				            // Trigger a click event on the download link
				            a.click();

				            // Cleanup
				            URL.revokeObjectURL(url);

				            console.log('downloaded');
			    		};
		    		} else {
		    			excelDownloaderButton.onclick = '';
		    		}
	    		}
    		}
    		
    		if (typeof XLSX == "undefined") {
                const script = document.createElement("script");
                script.onload = function() {
                	initLib(props);
                };
                script.src = "https://unpkg.com/xlsx/dist/xlsx.full.min.js";
                document.body.appendChild(script);
            } else {
                console.log("InitLib");
                initLib(props);
            }
    	};

    	editor.DomComponents.addType("excel-downloader-container", {
    		model: {
		        defaults: {
		            tagName: "div",
		            attributes: {
		              	class: "excel-downloader-container",
		            },
		            type: "excel-downloader-container",
		            script: downloadExcelScript,

		            // traits
		            API_HOST: API_HOST,
		            options: options,
		            projectId: localStorage.getItem("project_id") ? localStorage.getItem("project_id") : "",

		            customUrlValue: "",
					baseUrlValue: "",
					apiPathValue: "",
					apiMethodValue: "",
					apiUsernameValue: "",
					apiPasswordValue: "",
					apiBearerTokenValue: "",
					apiMoreHeadersValue: "",
					apiBodyValue: "",
					nestedArrOfObjKeynameValue: "",

					sheetColumns: [], // Name of the actual columns in the sheet
					apiResponseColumnKeynames: [], // Keynames to extract data from api response for those particular column

					excelFileName: `redSling_excel_export`,
					allowDownload: false,

					traits: [
						{
							type: 'checkbox',
							label: "Allow Download",
							name: "allowDownload",
							changeProp: true,
						},
					],

					changeProp: true,
					"script-props": ["allowDownload", "excelFileName", "API_HOST", "options", "projectId", "sheetColumns", "apiResponseColumnKeynames", "customUrlValue", "baseUrlValue", "apiPathValue", "apiMethodValue", "apiUsernameValue", "apiPasswordValue", "apiBearerTokenValue", "apiMoreHeadersValue", "apiBodyValue", "nestedArrOfObjKeynameValue"],

		            components: [
			            {
			                tagName: "button",
			                type: "excel-downloader-button",
			                attributes: { class: "excel-downloader-button" },
			                editable: true,
			  
			                "style-default": {
				                width: "max-content",
				                padding: "0.7%",
				                "border-radius": "10px",
				                "background-color": "#1D6F42",
				                border: "2px solid #1D6F42",
				                color: "white",
				                cursor: "pointer",

				                display: 'flex',
				                'align-items': 'center',
				                'justify-content': 'center',
				                gap: '3%',
			                },
			  
			                style: {
				                width: "max-content",
				                padding: "0.7%",
				                "border-radius": "10px",
				                "background-color": "#1D6F42",
				                border: "2px solid #1D6F42",
				                color: "white",
				                cursor: "pointer",

				                display: 'flex',
				                'align-items': 'center',
				                'justify-content': 'center',
				                gap: '3%',
			                },
			  
			                components: [
			                	`<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" viewBox="0 0 16 16"><path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2M9.5 3A1.5 1.5 0 0 0 11 4.5h2V9H3V2a1 1 0 0 1 1-1h5.5zM3 12v-2h2v2zm0 1h2v2H4a1 1 0 0 1-1-1zm3 2v-2h3v2zm4 0v-2h3v1a1 1 0 0 1-1 1zm3-3h-3v-2h3zm-7 0v-2h3v2z"/></svg>`,
				                {
					                tagName: "p",
					                type: "text",
					  
					                components: {
					                    type: "textnode",
					                    content: "Download",
					                },
					  
					                "style-default": {
					                    margin: "auto",
					                    "text-align": "left",
					                    color: "inherit",
					                },
					  
					                style: {
					                    margin: "auto",
					                    "text-align": "left",
					                    color: "inherit",
					                },
				                }
			                ],
			            }
		            ],
		        },
	        },

	        view: {
	        	events: {
					'click .customize-excel-btn': 'openModal',
				},

				openModal(event) {
					event.stopPropagation();

					const form = document.createElement("div");
		            form.setAttribute("class", "custom-excel-form");

                    form.innerHTML = `
                    	<ul class="nav nav-tabs" id="custom-excel-form" role="tablist">
							<li class="nav-item" role="presentation">
							    <button class="nav-link active" id="excel-api-functionality-tab" data-bs-toggle="tab" data-bs-target="#excel-api-functionality-tab-pane" type="button" role="tab" aria-controls="excel-api-functionality-tab-pane" aria-selected="true">Define Excel's Data Source</button>
							</li>

							<li class="nav-item" role="presentation">
							    <button class="nav-link" id="excel-sheet-structure-tab" data-bs-toggle="tab" data-bs-target="#excel-sheet-structure-tab-pane" type="button" role="tab" aria-controls="excel-sheet-structure-tab-pane" aria-selected="true">Define Excel Sheet's Structure</button>
							</li>
						</ul>

						<div class="tab-content" id="custom-excel-form-content">
							<div class="tab-pane fade show active" id="excel-api-functionality-tab-pane" role="tabpanel" aria-labelledby="excel-api-functionality-tab-pane" tabindex="0">
                    			<br>
						  		
						  		<!-- custom url -->
		                        <div>
		                        	<span>
		                            	<label for="custom-url">Custom URL</label>
		                            </span>
		                            <input type="text" name="custom-url" id="custom-url" class="custom-url" placeholder="A complete API URL">
		                        </div>

		                        <!-- base urls -->
		                        <div>
		                        	<span>
		                            	<label for="base-url">Base URL</label>
		                            </span>
		                            <select name="base-url" id="base-url" class="base-url">
		                            	<option disabled selected>-- select a base URL --</option>
		                            	<option value="baseUrl1">Base URL #1</option>
		                            	<option value="baseUrl2">Base URL #2</option>
		                            	<option value="baseUrl3">Base URL #3</option>
		                            	<option value="baseUrl4">Base URL #4</option>
		                            	<option value="baseUrl5">Base URL #5</option>
		                            	<option value="null">No Base URL</option>
		                            </select>
		                        </div>

		                        <!-- api path -->
		                        <div>
		                        	<span>
		                            	<label for="api-path" title="Don't start with '/'">API Path</label>
		                            </span>
		                            <input type="text" name="api-path" id="api-path" class="api-path" placeholder="Remaining API path of the Base URL">
		                        </div>

		                        <!-- api method -->
		                        <div>
		                        	<span>
		                            	<label for="api-method">*API Method</label>
		                            </span>
		                            <select name="api-method" id="api-method" class="api-method">
		                            	<option value="GET" selected>GET</option>
		                            	<option value="POST">POST</option>
		                            </select>
		                        </div>

		                        <!-- api username -->
		                        <div>
		                        	<span>
		                            	<label for="api-username">API Username</label>
		                            </span>
		                            <input type="text" name="api-username" id="api-username" class="api-username" placeholder="Username for API authentication">
		                        </div>

		                        <!-- api password -->
		                        <div>
		                        	<span>
		                            	<label for="api-password">API Password</label>
		                            </span>
		                            <input type="text" name="api-password" id="api-password" class="api-password" placeholder="Password for API authentication">
		                        </div>

		                        <!-- api bearer token -->
		                        <div>
		                        	<span>
		                            	<label for="api-bearer-token" title="Note: Provide it without the word 'Bearer'">Bearer Token</label>
		                            </span>
		                            <input type="text" name="api-bearer-token" id="api-bearer-token" class="api-bearer-token" placeholder="Bearer token for Custom URL">
		                        </div>

		                        <!-- api more headers -->
		                        <div>
		                        	<span>
		                            	<label for="api-more-headers" title="Note: With/Without spaces after commas">More Headers</label>
		                            </span>
		                            <input type="text" name="api-more-headers" id="api-more-headers" class="api-more-headers" placeholder="k1:v1,k2:v2,k3:v3,...">
		                        </div>

		                        <!-- api body -->
		                        <div>
		                        	<span>
		                            	<label for="api-body" title="Note: API body content for a POST call">API Body</label>
		                            </span>
		                            <textarea id="api-body" name="api-body" class="api-body" rows="4" cols="50"></textarea>
		                        </div>

		                        <!-- nested array-of-objects keyname -->
		                        <div>
		                        	<span>
		                            	<label for="nested-array-of-objects-keyname" title="kename for nested array-of-objects in API response">Keyname for nested array-of-objects</label>
		                            </span>
		                            <input type="text" name="nested-array-of-objects-keyname" id="nested-array-of-objects-keyname" class="nested-array-of-objects-keyname" placeholder="k in { k : [{k1:v1}, {k2, v2}] }">
		                        </div>

		                        <!-- submit button -->
		                        <button class="update-excel-btn">Save</button>
                    		</div>

                    		<div class="tab-pane fade" id="excel-sheet-structure-tab-pane" role="tabpanel" aria-labelledby="excel-sheet-structure-tab-pane" tabindex="0">
                    			<br>

                    			<!-- Excel file name -->
		                        <form class="sheet-header-column-container">
			                        <div>
			                        	<span><label for="excel-filename" title="Name of your excel file before downloading">Excel file name</label></span>
			                            <input type="text" name="excel-filename" id="excel-filename" class="excel-filename" placeholder="Name of your excel file before downloading">
			                        </div>

		                            <!-- apply button -->
		                        	<button class="apply-excel-filename">Apply</button>
		                        </form>

		                         <br>

                    			<form class="sheet-header-column-container">
                    				<!-- sheet header column name -->
	                    			<div>
			                        	<span><label for="sheet-header-column-name" title="Name of the header column in the excel sheet">Column name</label></span>
			                            <input type="text" name="sheet-header-column-name" id="sheet-header-column-name" class="sheet-header-column-name" placeholder="Name of the header column in the excel sheet">
			                        </div>

			                        <!-- API response keyname -->
			                        <div>
			                        	<span><label for="sheet-header-response-keyname" title="Name of the key from the API response to use for this column. For reference key names, provide the table and keyname in the format - <table name> : <key name>">API response key name</label></span>
			                            <input type="text" name="sheet-header-response-keyname" id="sheet-header-response-keyname" class="sheet-header-response-keyname" placeholder="Name of the key from the API response to use for this column">
			                        </div>

			                        <!-- add button -->
		                        	<button class="add-column-btn">Add Column</button>
		                        </form>
		                        <hr>

		                        <form class="edit-sheet-header-column-container">
                    				<!-- sheet header column name -->
	                    			<div>
			                        	<span><label for="edit-sheet-header-column-name" title="Name of the header column in the excel sheet">Edit column name</label></span>
			                            <input type="text" name="edit-sheet-header-column-name" id="edit-sheet-header-column-name" class="edit-sheet-header-column-name" placeholder="Name of the header column in the excel sheet">
			                        </div>

			                        <!-- API response keyname -->
			                        <div>
			                        	<span><label for="edit-sheet-header-response-keyname" title="Name of the key from the API response to use for this column. For reference key names, provide the table and keyname in the format - <table name> : <key name>">Edit API response key name</label></span>
			                            <input type="text" name="edit-sheet-header-response-keyname" id="edit-sheet-header-response-keyname" class="edit-sheet-header-response-keyname" placeholder="Name of the key from the API response to use for this column">
			                        </div>

			                        <!-- edit button -->
		                        	<button class="edit-column-btn">Edit Column</button>
		                        </form>

		                        <div class="excel-sheet-preview">
		                        	<p>Structure</p>
		                        	<br>
		                        	
		                        	<table>
			                        	<thead>
			                        		<tr class="header-row"></tr>
			                        	</thead>
		                        	</table>
		                        </div>
                    		</div>
						</div>
                    `;

                    editor.Modal.open({
		              	title: "Custom Excel Form",
		              	content: `${form.outerHTML}`,
		              	attributes: {
		              		thisModelRef: this.model,
		              		// thisTableRef: this.model.components().models.find(comp => comp.attributes.type === "custom-table"),
		              	},
		            });
				},

	        	onRender({el, model}) {
	        		console.log('onRender el, model', el, model);

	        		// to avoid showing in preview
	        		if (!window.top.location.href.includes('large_preview/')) {
	        			if (el.className === "excel-downloader-container") {
		        			let customizeExcelBtn = document.createElement('button');
		        			customizeExcelBtn.style.marginRight = '1%';
	        				customizeExcelBtn.setAttribute('class', "customize-excel-btn");
	        				customizeExcelBtn.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pen" viewBox="0 0 16 16"><path d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001m-.644.766a.5.5 0 0 0-.707 0L1.95 11.756l-.764 3.057 3.057-.764L14.44 3.854a.5.5 0 0 0 0-.708l-1.585-1.585z"/></svg>';

	        				// Insert the customizeExcelBtn element next to the custom-email element
	        				el.getElementsByClassName('excel-downloader-button')[0].insertAdjacentElement('beforebegin', customizeExcelBtn);
	        			}
	        		}
	        	},
	        },
    	});

		// EXCEL IMPORT
		editor.Blocks.add("excel-import-container", {
	        label: "Import Excel",
	        category: "Buttons",
	        select: true,
	        media: `<svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="currentColor" viewBox="0 0 16 16"><path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2M9.5 3A1.5 1.5 0 0 0 11 4.5h2V9H3V2a1 1 0 0 1 1-1h5.5zM3 12v-2h2v2zm0 1h2v2H4a1 1 0 0 1-1-1zm3 2v-2h3v2zm4 0v-2h3v1a1 1 0 0 1-1 1zm3-3h-3v-2h3zm-7 0v-2h3v2z"/></svg>`,
	        content: { type: "excel-import-container" },
	    });

	    // to make it work in download
	    const importExcelScript = function (props) {
	        console.log("importExcelScript", props, this);

	        let {allowDownload} = props;

	        // For LB download manipulation
	        // checking if the url or script is not running in the redsling editor, only then run the script to plot checkboxes
	        let idEl = this.id;
	        const urlDownload = window.top.location.href;
	        const isSubstringPresent = (urlDownload.indexOf("/editor/") !== -1 && urlDownload.indexOf("?projectId=") !== -1) || urlDownload.indexOf("/large_preview/") !== -1 || urlDownload.indexOf("/tab_preview/") !== -1 || urlDownload.indexOf("/mobile_preview/") !== -1 || urlDownload.indexOf("/fragment_editor/") !== -1;
	        if (!isSubstringPresent) {
	          setInterval(function () {
	            Object.keys(props).forEach(function (key) {
	              if (window[`${key}${idEl}`]) {
	                props[key] = window[`${key}${idEl}`];
	              }
	            });
	          }, 500);
	        }

	        // to show notification on errors and successes
	        function showNotification(type, message) {
			    // Create a notification element
			    const notification = document.createElement('div');

			    // Apply styles directly
			    notification.style.display = 'block';
			    notification.style.position = 'fixed';
			    notification.style.top = '20px';
			    notification.style.right = '20px';
			    notification.style.padding = '20px';
			    notification.style.backgroundColor = 'white';
			    notification.style.border = '2px solid';
			    notification.style.borderRadius = '10px';
			    notification.style.boxShadow = '0 0 10px rgba(0,0,0,0.1)';
			    notification.style.zIndex = '1000';
			    notification.style.maxWidth = '300px';
			    notification.style.textAlign = 'left';
			    notification.style.fontFamily = 'Arial, sans-serif';
			    notification.style.fontSize = '14px';
			    notification.style.lineHeight = '1.5';

			    // Set the message
			    notification.textContent = message;

			    // Apply styles based on the notification type
			    if (type === 'success') {
			        notification.style.borderColor = '#4CAF50';
			        notification.style.color = '#4CAF50';
			        notification.style.backgroundColor = '#e7f6e7';
			    } else if (type === 'error') {
			        notification.style.borderColor = '#F44336';
			        notification.style.color = '#F44336';
			        notification.style.backgroundColor = '#fcebea';
			    } else if (type === 'info') {
			        notification.style.borderColor = '#2196F3';
			        notification.style.color = '#2196F3';
			        notification.style.backgroundColor = '#e7f3fe';
			    }

			    // Append the notification to the document body
			    document.body.appendChild(notification);

			    // Hide and remove the notification after 5 seconds
			    setTimeout(() => {
			        document.body.removeChild(notification);
			    }, 5000);
			}

	        // function to upload data to model
	        async function uploadToDataModel(file) {
	        	let {baseUrlValue,
          		dataModelNameValue, 
          		apiMethodValue,
          		apiMoreHeadersValue,
          		primaryKey,
          		sheetColumns,
          		apiResponseColumnKeynames,
          		requiredFields,
          		invalidValue,
          		projectId,
          		API_HOST,
          		options} = props;

          		// base url bearer token taken directly from local storage after getting downloaded
				let baseUrlData, dynamicBaseUrlBearerToken, baseUrl1BearerToken, baseUrl2BearerToken, baseUrl3BearerToken, baseUrl4BearerToken, baseUrl5BearerToken;
				// getting baseUrl data from local storage for run-time access
				let baseUrlDataFromStorage = localStorage.getItem("baseUrlData");
				if (baseUrlDataFromStorage) {
					baseUrlData = JSON.parse(baseUrlDataFromStorage);
				}
				// extracting token values from base URLs from baseUrlData
				if (baseUrlData) {
					try {
						if (`baseUrl1_${projectId}` in baseUrlData) {
							baseUrl1BearerToken = baseUrlData[`baseUrl1_${projectId}`].token;
						}
						if (`baseUrl2_${projectId}` in baseUrlData) {
							baseUrl2BearerToken = baseUrlData[`baseUrl2_${projectId}`].token;
						}
						if (`baseUrl3_${projectId}` in baseUrlData) {
							baseUrl3BearerToken = baseUrlData[`baseUrl3_${projectId}`].token;
						}
						if (`baseUrl4_${projectId}` in baseUrlData) {
							baseUrl4BearerToken = baseUrlData[`baseUrl4_${projectId}`].token;
						}
						if (`baseUrl5_${projectId}` in baseUrlData) {
							baseUrl5BearerToken = baseUrlData[`baseUrl5_${projectId}`].token;
						}
					} catch (e) {
						// statements
						console.log(e);
					}
				}

				let url;
				if (baseUrlValue === "baseUrl1") {
					url = options.baseUrl1;
					dynamicBaseUrlBearerToken = baseUrl1BearerToken;
				} else if (baseUrlValue === "baseUrl2") {
					url = options.baseUrl2;
					dynamicBaseUrlBearerToken = baseUrl2BearerToken;
				} else if (baseUrlValue === "baseUrl3") {
					url = options.baseUrl3;
					dynamicBaseUrlBearerToken = baseUrl3BearerToken;
				} else if (baseUrlValue === "baseUrl4") {
					url = options.baseUrl4;
					dynamicBaseUrlBearerToken = baseUrl4BearerToken;
				} else if (baseUrlValue === "baseUrl5") {
					url = options.baseUrl5;
					dynamicBaseUrlBearerToken = baseUrl5BearerToken;
				}

	          	try {
				  let formData = new FormData();
				  formData.append("file", file);
				  formData.append("jsonData", JSON.stringify({
				    baseUrl: url,
				    baseUrlValue,
				    dataModelNameValue,
				    dynamicBaseUrlBearerToken,
				    apiMethodValue,
				    apiMoreHeadersValue,
				    primaryKey,
				    sheetColumns,
				    apiResponseColumnKeynames,
				    requiredFields,
				    invalidValue,
				    projectId,
				  }));

				  	// notify the user to proceed with other tasks while a reponse is returned
					showNotification("info", "File parsing is in progress. Please proceed with other tasks in the meantime.");

				  let response = await fetch(`${API_HOST}importIntoDataModel`, {
				    method: "POST",
				    body: formData
				  });

				  if (response.ok) {
				    let responseData = await response.json();
				    console.log("Response Data:", responseData);
				    showNotification("success", "File parsing has been successful.");
				  } else {
				    showNotification("error", "Error importing the provided file. Please try again.");
				  }
				} catch (error) {
				  console.error("Error during the fetch operation:", error);
				  showNotification("error", "An error occurred during the import process. Please try again.");
				}
	        }

	        const inputFileContainer = this;
	        const inputFileLabel = inputFileContainer.getElementsByClassName("excel-import-button")[0];
	        // const inputFile = inputFileContainer.getElementsByClassName("input_file")[0];

	        inputFileLabel.onclick = async function (event) {
	        	console.log('inputFileLabel clicked in download');
	          let inputFile = document.createElement("input");
	          inputFile.type = "file";
	          inputFile.style.display = "none";

	          inputFile.addEventListener("change", async function (event) {
	            let file = inputFile.files[0];
	            console.log("this++", this);
	            console.log("file is:", file);

	            try {
	              	if (!file) return;

	              	const validFileTypes = [
			          "application/vnd.ms-excel", // .xls
			          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // .xlsx
			          "text/csv" // .csv
			        ];

			        if (!validFileTypes.includes(file.type)) {
			          // toast.error(`Invalid file type. Please upload an Excel or CSV file.`, successTheme);
			        	showNotification("error", "Invalid file type. Please upload an Excel or CSV file.");
			          return;
			        }

			        const reader = new FileReader();
			        reader.onload = (e) => {
			          // const data = new Uint8Array(e.target.result);
			          // const workbook = XLSX.read(data, { type: 'array' });

			          // const firstSheetName = workbook.SheetNames[0];
			          // const worksheet = workbook.Sheets[firstSheetName];
			          // const jsonData = XLSX.utils.sheet_to_json(worksheet);

			          // Upload to data model
			          uploadToDataModel(file);
			        };
			        reader.readAsArrayBuffer(file);
	              } catch (error) {
	                console.error("Error uploading file:", error);
	              } finally {
	                // Remove the inputFile element after API call completes
	                if (inputFile && inputFile.parentNode) {
	                  inputFile.parentNode.removeChild(inputFile);
	                }
	              }
	          });

	          inputFileContainer.appendChild(inputFile);
	          inputFile.click();
	        };
	    };

	    editor.Components.addType("excel-import-container", {
	        model: {
	          defaults: {
	            tagName: "div",
	            attributes: {
	              class: "excel-import-container",
	            },
	            type: "excel-import-container",
	            script: importExcelScript,

	            API_HOST: API_HOST,
	            options: options,
		        projectId: localStorage.getItem("project_id") ? localStorage.getItem("project_id") : "",

		        // customUrlValue: "",
				baseUrlValue: "",
				// apiPathValue: "",
				dataModelNameValue: "",
				apiMethodValue: "",
				// apiUsernameValue: "",
				// apiPasswordValue: "",
				// apiBearerTokenValue: "",
				apiMoreHeadersValue: "",
				primaryKey: "_id",

				sheetColumns: [], // Name of the actual columns in the sheet
				apiResponseColumnKeynames: [], // Keynames to extract data from api response for those particular column
				requiredFields: [], // field names that are 'required'
				invalidValue: {}, // field names that are 'required'

				allowUpload: false,

				traits: [
					{
						type: 'checkbox',
						label: "Allow Upload",
						name: "allowUpload",
						changeProp: true,
					},
				],

	            changeProp: true,
	            "script-props": ["API_HOST", "options", "projectId", "baseUrlValue", "dataModelNameValue", "apiMethodValue", "apiMoreHeadersValue", "primaryKey", "sheetColumns", "apiResponseColumnKeynames", "allowUpload", "sheetColumns", "apiResponseColumnKeynames", "requiredFields", "invalidValue"],

	            components: [
	              {
	                tagName: "label",
	                type: "excel-import-button",
	                attributes: {
	                  for: "input_file",
	                  class: "excel-import-button",
	                },

	                style: {
		                width: "max-content",
		                padding: "0.7%",
		                "border-radius": "10px",
		                "background-color": "#1D6F42",
		                border: "2px solid #1D6F42",
		                color: "white",
		                cursor: "pointer",

		                display: 'flex',
		                'align-items': 'center',
		                'justify-content': 'center',
		                gap: '3%',
	                },

	                "style-default": {
		                width: "max-content",
		                padding: "0.7%",
		                "border-radius": "10px",
		                "background-color": "#1D6F42",
		                border: "2px solid #1D6F42",
		                color: "white",
		                cursor: "pointer",

		                display: 'flex',
		                'align-items': 'center',
		                'justify-content': 'center',
		                gap: '3%',
	                },

	                components: [
	                	`<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" viewBox="0 0 16 16"><path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2M9.5 3A1.5 1.5 0 0 0 11 4.5h2V9H3V2a1 1 0 0 1 1-1h5.5zM3 12v-2h2v2zm0 1h2v2H4a1 1 0 0 1-1-1zm3 2v-2h3v2zm4 0v-2h3v1a1 1 0 0 1-1 1zm3-3h-3v-2h3zm-7 0v-2h3v2z"/></svg>`,
		                {
			                tagName: "p",
			                type: "text",
			  
			                components: {
			                    type: "textnode",
			                    content: "Import Excel",
			                },
			  
			                "style-default": {
			                    margin: "auto",
			                    "text-align": "left",
			                    color: "inherit",
			                },
			  
			                style: {
			                    margin: "auto",
			                    "text-align": "left",
			                    color: "inherit",
			                },
		                }
	                ],
	              },
	            ],
	          },
	        },

	        view: {
	        	// init() {
	        	// 	this.listenTo(this, "change:allowUpload", this.openSystemFileDialog);
	        	// 	// this.listenTo(this, "change:customUrlValue", this.openSystemFileDialog);
	        	// 	this.listenTo(this, "change:baseUrlValue", this.openSystemFileDialog);
	        	// 	// this.listenTo(this, "change:apiPathValue", this.openSystemFileDialog);
	        	// 	this.listenTo(this, "change:dataModelNameValue", this.openSystemFileDialog);
	        	// 	this.listenTo(this, "change:apiMethodValue", this.openSystemFileDialog);
	        	// 	this.listenTo(this, "change:apiUsernameValue", this.openSystemFileDialog);
	        	// 	this.listenTo(this, "change:apiPasswordValue", this.openSystemFileDialog);
	        	// 	this.listenTo(this, "change:apiBearerTokenValue", this.openSystemFileDialog);
	        	// 	this.listenTo(this, "change:apiMoreHeadersValue", this.openSystemFileDialog);
	        	// },

	          events: {
	            "click .excel-import-button": "openSystemFileDialog",
	            'click .customize-excel-import-btn': 'openModal',
	          },

	          	openModal(event) {
					event.stopPropagation();

					const form = document.createElement("div");
		            form.setAttribute("class", "custom-excel-import-form");

	                form.innerHTML = `
	                	<ul class="nav nav-tabs" id="custom-excel-import-form" role="tablist">
							<li class="nav-item" role="presentation">
							    <button class="nav-link active" id="excel-import-api-functionality-tab" data-bs-toggle="tab" data-bs-target="#excel-import-api-functionality-tab-pane" type="button" role="tab" aria-controls="excel-import-api-functionality-tab-pane" aria-selected="true">Excel Settings</button>
							</li>

							<li class="nav-item" role="presentation">
							    <button class="nav-link" id="map-structure-tab" data-bs-toggle="tab" data-bs-target="#map-structure-tab-pane" type="button" role="tab" aria-controls="map-structure-tab-pane" aria-selected="true">Column Mapping</button>
							</li>
						</ul>

						<div class="tab-content" id="custom-excel-import-form-content">
							<div class="tab-pane fade show active" id="excel-import-api-functionality-tab-pane" role="tabpanel" aria-labelledby="excel-import-api-functionality-tab-pane" tabindex="0">
	                			<br>
						  		
						  		<!-- custom url -->
		                        <!-- <div> <span> <label for="custom-url" title="Note: If a data model name is provided, then please do not provide 'Base URL' or 'Data Model Name'.">Custom URL</label> </span> <input type="text" name="custom-url" id="custom-url" class="custom-url" placeholder="A complete API URL"> </div> -->

		                        <!-- base urls -->
		                        <div>
		                        	<span>
		                            	<label for="base-url" title="Note: If a data model name is provided, then please do not provide 'Custom URL' or 'Data Model Name'.">Base URL</label>
		                            </span>
		                            <select name="base-url" id="base-url" class="base-url">
		                            	<option disabled selected>-- select a base URL --</option>
		                            	<option value="baseUrl1">Base URL #1</option>
		                            	<option value="baseUrl2">Base URL #2</option>
		                            	<option value="baseUrl3">Base URL #3</option>
		                            	<option value="baseUrl4">Base URL #4</option>
		                            	<option value="baseUrl5">Base URL #5</option>
		                            	<option value="null">No Base URL</option>
		                            </select>
		                        </div>

		                        <!-- api path -->
		                        <!-- <div> <span> <label for="api-path" title="Don't start with '/'">API Path</label> </span> <input type="text" name="api-path" id="api-path" class="api-path" placeholder="Remaining API path of the Base URL"> </div> -->

		                        <!-- table name -->
		                        <div>
		                        	<span>
		                            	<label for="data-model-name" title="Note: If a 'Data Model Name' is provided, then please do not provide 'Custom URL' or 'Base URL'.">Data Model Name</label>
		                            </span>
		                            <input type="text" name="data-model-name" id="data-model-name" class="data-model-name" placeholder="Name of the redSling custom data model">
		                        </div>

		                        <!-- api method -->
		                        <div>
		                        	<span>
		                            	<label for="api-method">*Operation</label>
		                            </span>
		                            <select name="api-method" id="api-method" class="api-method">
		                            	<option disabled selected>-- select an operation --</option>
		                            	<option value="IncrementalImport" selected>Incremental Import</option>
		                            	<option value="Clear&Import" selected>Clear & Import</option>
		                            	<option value="Upsert" selected>Upsert</option>
		                            </select>
		                        </div>

		                        <!-- api username -->
		                        <div> <span> <label for="api-username">API Username</label> </span> <input type="text" name="api-username" id="api-username" class="api-username" placeholder="Username for API authentication"> </div>

		                        <!-- api password -->
		                         <!-- <div> <span> <label for="api-password">API Password</label> </span> <input type="text" name="api-password" id="api-password" class="api-password" placeholder="Password for API authentication"> </div> -->

		                        <!-- api bearer token -->
		                         <!-- <div> <span> <label for="api-bearer-token" title="Note: Provide it without the word 'Bearer'">Bearer Token</label> </span> <input type="text" name="api-bearer-token" id="api-bearer-token" class="api-bearer-token" placeholder="Bearer token for Custom URL"> </div> -->

		                        <!-- api more headers -->
		                        <div>
		                        	<span>
		                            	<label for="api-more-headers" title="Note: With/Without spaces after commas">More Headers</label>
		                            </span>
		                            <input type="text" name="api-more-headers" id="api-more-headers" class="api-more-headers" placeholder="k1:v1,k2:v2,k3:v3,...">
		                        </div>

		                        <!-- primary key -->
		                        <div>
		                        	<span>
		                            	<label for="api-primary-key" title="Note: This is the key/column in the excel file, to identify row data for 'Incremental Import' & 'Upsert' import operations. It is usually the first column of the excel file or an ID attribute of the excel table.">Primary Key</label>
		                            </span>
		                            <input type="text" name="api-primary-key" id="api-primary-key" class="api-primary-key" placeholder="For example, _id">
		                        </div>

		                        <!-- submit button -->
		                        <button class="update-db-model-btn">Save</button>
	                		</div>

	                		<div class="tab-pane fade show" id="map-structure-tab-pane" role="tabpanel" aria-labelledby="map-structure-tab-pane" tabindex="0">
	                			<br>
						  		
						  		<form class="mapping-form">
						  			<!-- sheet header column name -->
		                    			<div>
				                        	<span><label for="sheet-header-column-name" title="Name of the header column in the excel sheet">Enter Column Name</label></span>
				                            <input type="text" name="sheet-header-column-name" id="sheet-header-column-name" class="sheet-header-column-name" placeholder="Name of the header column in the excel sheet">
				                        </div>

				                        <!-- API response keyname -->
				                        <div>
				                        	<span><label for="sheet-header-response-keyname" title="Name of the key from the API response to use for this column. For reference key names, provide the table and keyname in the format - <table name> : <key name>">Enter Field Name</label></span>
				                            <input type="text" name="sheet-header-response-keyname" id="sheet-header-response-keyname" class="sheet-header-response-keyname" placeholder="Name of the key from the API response to use for this column">
				                        </div>

				                        <!-- Required field? -->
				                        <div>
				                        	<span><label for="required-field" title="If this field is not found, break the import operation.">Required</label></span>
				                            <input type="checkbox" name="required-field" id="required-field" class="required-field">
				                        </div>

				                        <!-- On invalid value -->
				                        <div>
				                        	<span>
				                            	<label for="invalid-value" title="If a value is invalid, should the import in progress be broken or should it be skipped to import next values.">On Invalid Value</label>
				                            </span>
				                            <select name="invalid-value" id="invalid-value" class="invalid-value">
				                            	<option disabled>-- select an action --</option>
				                            	<option value="ImportFails" selected>Import Fails</option>
				                            	<option value="SkipValue">Skip Value</option>
				                            </select>
				                        </div>

				                        <!-- add button -->
			                        	<button class="add-column-btn">Add Column</button>
						  		</form>

		                        <!-- submit button -->
		                        <!-- <button class="map-structure-btn">Save</button> -->
		                        
		                        <hr>
		                        <br>

		                        <div class="excel-import-sheet-preview">
		                        	<p>Structure</p>
		                        	<br>
		                        	
		                        	<table>
			                        	<thead>
			                        		<tr class="header-row"></tr>
			                        	</thead>
		                        	</table>
		                        </div>
	                		</div>
						</div>
	                `;

	                editor.Modal.open({
		              	title: "Custom Excel Form",
		              	content: `${form.outerHTML}`,
		              	attributes: {
		              		thisModelRef: this.model,
		              		// thisTableRef: this.model.components().models.find(comp => comp.attributes.type === "custom-table"),
		              	},
		            });
				},

	        	onRender({el, model}) {
	        		console.log('onRender el, model', el, model);

	        		// to avoid showing in preview
	        		if (!window.top.location.href.includes('large_preview/')) {
	        			if (el.className === "excel-import-container") {
		        			let customizeExcelBtn = document.createElement('button');
		        			customizeExcelBtn.style.marginRight = '1%';
	        				customizeExcelBtn.setAttribute('class', "customize-excel-import-btn");
	        				customizeExcelBtn.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pen" viewBox="0 0 16 16"><path d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001m-.644.766a.5.5 0 0 0-.707 0L1.95 11.756l-.764 3.057 3.057-.764L14.44 3.854a.5.5 0 0 0 0-.708l-1.585-1.585z"/></svg>';

	        				// Insert the customizeExcelBtn element next to the custom-email element
	        				el.getElementsByClassName('excel-import-button')[0].insertAdjacentElement('beforebegin', customizeExcelBtn);
	        			}
	        		}
	        	},

	          	// to make it work only in development environment
		        openSystemFileDialog(event) {
		            // event.stopPropagation();
		        	console.log('inputFileLabel clicked in development');

		            let {allowUpload} = this.model.props();

		            let container = document.querySelector(".excel-import-container");

		            if (allowUpload) {
			            let inputFile = document.createElement("input");
			            inputFile.type = "file";
			            inputFile.addEventListener("change", async (event) => {
			              let file = inputFile.files[0];

			              console.log("file is:", file);

			              try {
			              	if (!file) return;

			              	const validFileTypes = [
					          "application/vnd.ms-excel", // .xls
					          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // .xlsx
					          "text/csv" // .csv
					        ];

					        if (!validFileTypes.includes(file.type)) {
					          toast.error(`Invalid file type. Please upload an Excel or CSV file.`, successTheme);
					          return;
					        }

					        const reader = new FileReader();
					        reader.onload = (e) => {
					          // const data = new Uint8Array(e.target.result);
					          // const workbook = XLSX.read(data, { type: 'array' });

					          // const firstSheetName = workbook.SheetNames[0];
					          // const worksheet = workbook.Sheets[firstSheetName];
					          // const jsonData = XLSX.utils.sheet_to_json(worksheet);

					          // Upload to data model
					          this.uploadToDataModel(file);
					        };
					        reader.readAsArrayBuffer(file);
			              } catch (error) {
			                console.error("Error uploading file:", error);
			              } finally {
			                // Remove the inputFile element after API call completes
			                if (inputFile && inputFile.parentNode) {
			                  inputFile.parentNode.removeChild(inputFile);
			                }
			              }
			            });
			            container?.appendChild(inputFile);
			            inputFile.click();
		            }	            
		        },

		        async uploadToDataModel(file) {
		          	let {baseUrlValue,
	          		dataModelNameValue, 
	          		apiMethodValue,
	          		apiMoreHeadersValue,
	          		primaryKey,
	          		sheetColumns,
	          		apiResponseColumnKeynames,
	          		requiredFields,
	          		invalidValue,
	          		projectId,
	          		API_HOST,
	          		options} = this.model.props();

	          		// base url bearer token taken directly from local storage after getting downloaded
					let baseUrlData, dynamicBaseUrlBearerToken, baseUrl1BearerToken, baseUrl2BearerToken, baseUrl3BearerToken, baseUrl4BearerToken, baseUrl5BearerToken;
					// getting baseUrl data from local storage for run-time access
					let baseUrlDataFromStorage = localStorage.getItem("baseUrlData");
					if (baseUrlDataFromStorage) {
						baseUrlData = JSON.parse(baseUrlDataFromStorage);
					}
					// extracting token values from base URLs from baseUrlData
					if (baseUrlData) {
						try {
							if (`baseUrl1_${projectId}` in baseUrlData) {
								baseUrl1BearerToken = baseUrlData[`baseUrl1_${projectId}`].token;
							}
							if (`baseUrl2_${projectId}` in baseUrlData) {
								baseUrl2BearerToken = baseUrlData[`baseUrl2_${projectId}`].token;
							}
							if (`baseUrl3_${projectId}` in baseUrlData) {
								baseUrl3BearerToken = baseUrlData[`baseUrl3_${projectId}`].token;
							}
							if (`baseUrl4_${projectId}` in baseUrlData) {
								baseUrl4BearerToken = baseUrlData[`baseUrl4_${projectId}`].token;
							}
							if (`baseUrl5_${projectId}` in baseUrlData) {
								baseUrl5BearerToken = baseUrlData[`baseUrl5_${projectId}`].token;
							}
						} catch (e) {
							// statements
							console.log(e);
						}
					}

					let url;
					if (baseUrlValue === "baseUrl1") {
						url = options.baseUrl1;
						dynamicBaseUrlBearerToken = baseUrl1BearerToken;
					} else if (baseUrlValue === "baseUrl2") {
						url = options.baseUrl2;
						dynamicBaseUrlBearerToken = baseUrl2BearerToken;
					} else if (baseUrlValue === "baseUrl3") {
						url = options.baseUrl3;
						dynamicBaseUrlBearerToken = baseUrl3BearerToken;
					} else if (baseUrlValue === "baseUrl4") {
						url = options.baseUrl4;
						dynamicBaseUrlBearerToken = baseUrl4BearerToken;
					} else if (baseUrlValue === "baseUrl5") {
						url = options.baseUrl5;
						dynamicBaseUrlBearerToken = baseUrl5BearerToken;
					}

		          	let formData = new FormData();
					formData.append("file", file);
					formData.append("jsonData", JSON.stringify({
					    baseUrl: url,
					    baseUrlValue,
					    dataModelNameValue,
					    dynamicBaseUrlBearerToken,
					    apiMethodValue,
					    apiMoreHeadersValue,
					    primaryKey,
					    sheetColumns,
					    apiResponseColumnKeynames,
					    requiredFields,
					    invalidValue,
				    	projectId,
					}));

					// notify the user to proceed with other tasks while a reponse is returned
					toast.info("File parsing is in progress. Please proceed with other tasks in the meantime.", successTheme);

					let response = await fetch(`${API_HOST}importIntoDataModel`, {
					    method: "POST",
					    body: formData,
					});

					if (response.ok) {
						let responseData = await response.json();
						console.log("Response Data:", responseData);
						toast.success("File parsing has been successful.", successTheme);
					} else {
						toast.error(`Error importing the provided file. Please try again.`, successTheme);
					}
		        },
	        },
	    });

		// listening to modal opening
		editor.on("modal", (props) => {
			console.log('modal props', props);

			// excel file export handling
			if (document.getElementsByClassName("gjs-mdl-content")[0] !== undefined && document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("custom-excel-form")[0] !== undefined ) {
				// API trait modal inputs pointers
		        const customUrl = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("custom-url")[0];
		        const baseUrl = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("base-url")[0];
		        const apiPath = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("api-path")[0];
		        const apiMethod = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("api-method")[0];
		        const apiUsername = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("api-username")[0];
		        const apiPassword = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("api-password")[0];
		        const apiBearerToken = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("api-bearer-token")[0];
		        const apiMoreHeaders = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("api-more-headers")[0];
		        const apiBody = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("api-body")[0];
		        const nestedArrOfObjKeyname = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("nested-array-of-objects-keyname")[0];
		        const updateExcelBtn = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("update-excel-btn")[0];
				
		        // User input to define excel sheet columns
		        const sheetHeaderColumnName = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("sheet-header-column-name")[0];
		        const sheetHeaderResponseKeyname = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("sheet-header-response-keyname")[0];
		        const addColumnBtn = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("add-column-btn")[0];

		        // filename input
		        const excelFileName = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("excel-filename")[0];
		        const excelFileNameApplyBtn = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("apply-excel-filename")[0];

		        // reinserting saved values for the user to see
		        try {
		        	customUrl.value = props.attributes.thisModelRef.get('customUrlValue');
			        baseUrl.value = props.attributes.thisModelRef.get('baseUrlValue');
			        apiPath.value = props.attributes.thisModelRef.get('apiPathValue');
			        apiMethod.value = props.attributes.thisModelRef.get('apiMethodValue');
			        apiUsername.value = props.attributes.thisModelRef.get('apiUsernameValue');
			        apiPassword.value = props.attributes.thisModelRef.get('apiPasswordValue');
			        apiBearerToken.value = props.attributes.thisModelRef.get('apiBearerTokenValue');
			        apiMoreHeaders.value = props.attributes.thisModelRef.get('apiMoreHeadersValue');
			        apiBody.value = props.attributes.thisModelRef.get('apiBodyValue');
			        nestedArrOfObjKeyname.value = props.attributes.thisModelRef.get('nestedArrOfObjKeynameValue');
			        
			        excelFileName.value = props.attributes.thisModelRef.get('excelFileName');

			        // PREVIEW of excel sheet columns
			        let table = document.getElementsByClassName('excel-sheet-preview')[0].getElementsByTagName('table')[0];
					// Apply general styling to the table
					table.style.borderCollapse = 'collapse';
					table.style.width = '100%';
					table.style.border = '1px solid #ddd';
					// Apply horizontal scrolling to the table if it exceeds the modal boundary
					table.style.overflowX = 'auto';

			        let headerRow = document.getElementsByClassName('excel-sheet-preview')[0].getElementsByClassName('header-row')[0];
			        // clear headerRow and readd all to avoid duplicates
			        headerRow.innerHTML = "";
			        let sheetColumns = props.attributes.thisModelRef.get('sheetColumns');
			        sheetColumns.forEach((col, colIdx) => {
			        	let headerCell = document.createElement('th');
			        	// Apply styling to header cells
					    headerCell.style.border = '1px solid #ddd';
					    headerCell.style.padding = '0.7%';
					    headerCell.style.textAlign = 'left';
					    headerCell.style.minWidth = 'max-content';
					    headerCell.style.color = 'black';
					    headerCell.style.fontWeight = 'normal';
					    headerCell.style.cursor = 'pointer';
					    headerCell.style.position = 'relative';
			        	headerCell.textContent = col;

			        	headerCell.addEventListener('mouseover', (e) => headerCell.style.backgroundColor = 'rgba(0, 0, 0, 0.2)');
			        	headerCell.addEventListener('mouseleave', (e) => headerCell.style.backgroundColor = 'transparent');
			        	
			        	// adding edit/delete icons on every cell of the table
			        	let editColumnOperation = document.createElement('div');
			        	editColumnOperation.style.position = 'absolute';
			        	editColumnOperation.style.bottom = '110%';
			        	editColumnOperation.style.left = '2%';
			        	editColumnOperation.style.width = 'max-content';
			        	editColumnOperation.style.display = 'flex';
			        	editColumnOperation.style.alignItems = 'center';
			        	editColumnOperation.style.justifyContent = 'flex-start';
			        	editColumnOperation.style.gap = '30%';
			        	editColumnOperation.innerHTML += `<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-pencil" viewBox="0 0 16 16" title="Please double click on it."><path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325"/></svg>`;
			        	editColumnOperation.innerHTML += `<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-trash3" viewBox="0 0 16 16"><path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5M11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47M8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5"/></svg>`;

			        	editColumnOperation.getElementsByClassName('bi-pencil')[0].addEventListener('mouseover', (e) => editColumnOperation.getElementsByClassName('bi-pencil')[0].style.color = 'rgba(0, 0, 0, 0.2)');
			        	editColumnOperation.getElementsByClassName('bi-pencil')[0].addEventListener('mouseleave', (e) => editColumnOperation.getElementsByClassName('bi-pencil')[0].style.color = '#990011');
			        	// if they click on 'edit'
			        	editColumnOperation.getElementsByClassName('bi-pencil')[0].addEventListener('click', (e) => {
			        		e.preventDefault();
			        		let editForm = document.getElementsByClassName('edit-sheet-header-column-container')[0];
			        		if (editForm.style.display === 'none') {
			        			editForm.style.display = 'flex';
			        		} else {
			        			editForm.style.display = 'none';
			        		}

			        		// get the values from edit-fields
					        const editSheetHeaderColumnName = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("edit-sheet-header-column-name")[0];
					        const editSheetHeaderResponseKeyname = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("edit-sheet-header-response-keyname")[0];
					        const editColumnBtn = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("edit-column-btn")[0];
			        		
					        editSheetHeaderColumnName.value = props.attributes.thisModelRef.get('sheetColumns')[colIdx];
					        editSheetHeaderResponseKeyname.value = props.attributes.thisModelRef.get('apiResponseColumnKeynames')[colIdx];

			        		editColumnBtn.onclick = function(e) {
			        			e.preventDefault();
				        		if (editSheetHeaderColumnName.value && editSheetHeaderResponseKeyname.value) {
				        			editExcelColumn(editor, props.attributes.thisModelRef, col, colIdx, editSheetHeaderColumnName.value, editSheetHeaderResponseKeyname.value);
				        		} else {
				        			toast.error("Cannot edit a column unless both column's name and API response's key name are provided.", notifyTheme);
				        		}
			        		};
			        	});
			        	
			        	editColumnOperation.getElementsByClassName('bi-trash3')[0].addEventListener('mouseover', (e) => editColumnOperation.getElementsByClassName('bi-trash3')[0].style.color = 'rgba(0, 0, 0, 0.2)');
			        	editColumnOperation.getElementsByClassName('bi-trash3')[0].addEventListener('mouseleave', (e) => editColumnOperation.getElementsByClassName('bi-trash3')[0].style.color = '#990011');
			        	// if they click on 'delete'
			        	editColumnOperation.getElementsByClassName('bi-trash3')[0].addEventListener('click', (e) => deleteExcelColumn(editor, props.attributes.thisModelRef, col, colIdx));

			        	headerCell.appendChild(editColumnOperation);

			        	headerRow.appendChild(headerCell);
			        });
		        } catch(e) {
		        	// statements
		        	console.log(e);
		        }

				// adding event listeners for taking action
		        try {
		        	updateExcelBtn.addEventListener('click', (e) => {
		        		e.preventDefault();
		        		if (customUrl.value || baseUrl.value || apiPath.value || apiMethod.value || apiUsername.value || apiPassword.value || apiBearerToken.value || apiMoreHeaders.value || apiBody.value || nestedArrOfObjKeyname.value) {
		        			updateExcelButton(editor, props.attributes.thisModelRef, customUrl.value, baseUrl.value, apiPath.value, apiMethod.value, apiUsername.value, apiPassword.value, apiBearerToken.value, apiMoreHeaders.value, apiBody.value, nestedArrOfObjKeyname.value);
		        		} else {
		        			toast.warn(`Please provide some information about the excel's data source before saing.`, notifyTheme );
		        		}
		        	});

		        	addColumnBtn.addEventListener('click', (e) => {
		        		e.preventDefault();
		        		// to make sure length of array - sheetColumns, apiResponseColumnKeynames are the same
		        		if (sheetHeaderColumnName.value && sheetHeaderResponseKeyname.value) {
		        			addColumn(editor, props.attributes.thisModelRef, sheetHeaderColumnName.value, sheetHeaderResponseKeyname.value);
		        			sheetHeaderColumnName.value = "";
		        			sheetHeaderResponseKeyname.value = "";

		        			// Update the PREVIEW of excel sheet columns
					        let table = document.getElementsByClassName('excel-sheet-preview')[0].getElementsByTagName('table')[0];
							// Apply general styling to the table
							table.style.borderCollapse = 'collapse';
							table.style.width = '100%';
							table.style.border = '1px solid #ddd';
							// Apply horizontal scrolling to the table if it exceeds the modal boundary
							table.style.overflowX = 'auto';

					        let headerRow = document.getElementsByClassName('excel-sheet-preview')[0].getElementsByClassName('header-row')[0];
					        // clear headerRow and readd all to avoid duplicates
					        headerRow.innerHTML = "";
					        let sheetColumns = props.attributes.thisModelRef.get('sheetColumns');
					        sheetColumns.forEach((col, colIdx) => {
					        	let headerCell = document.createElement('th');
					        	// Apply styling to header cells
							    headerCell.style.border = '1px solid #ddd';
							    headerCell.style.padding = '0.7%';
							    headerCell.style.textAlign = 'left';
							    headerCell.style.minWidth = 'max-content';
							    headerCell.style.color = 'black';
							    headerCell.style.fontWeight = 'normal';
							    headerCell.style.cursor = 'pointer';
							    headerCell.style.position = 'relative';
					        	headerCell.textContent = col;

					        	headerCell.addEventListener('mouseover', (e) => headerCell.style.backgroundColor = 'rgba(0, 0, 0, 0.2)');
					        	headerCell.addEventListener('mouseleave', (e) => headerCell.style.backgroundColor = 'transparent');
					        	
					        	// adding edit/delete icons on every cell of the table
					        	let editColumnOperation = document.createElement('div');
					        	editColumnOperation.style.position = 'absolute';
					        	editColumnOperation.style.bottom = '110%';
					        	editColumnOperation.style.left = '2%';
					        	editColumnOperation.style.width = 'max-content';
					        	editColumnOperation.style.display = 'flex';
					        	editColumnOperation.style.alignItems = 'center';
					        	editColumnOperation.style.justifyContent = 'flex-start';
					        	editColumnOperation.style.gap = '30%';
					        	editColumnOperation.innerHTML += `<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-pencil" viewBox="0 0 16 16"><path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325"/></svg>`;
					        	editColumnOperation.innerHTML += `<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-trash3" viewBox="0 0 16 16"><path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5M11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47M8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5"/></svg>`;

					        	editColumnOperation.getElementsByClassName('bi-pencil')[0].addEventListener('mouseover', (e) => editColumnOperation.getElementsByClassName('bi-pencil')[0].style.color = 'rgba(0, 0, 0, 0.2)');
					        	editColumnOperation.getElementsByClassName('bi-pencil')[0].addEventListener('mouseleave', (e) => editColumnOperation.getElementsByClassName('bi-pencil')[0].style.color = '#990011');
					        	// if they click on 'edit'
					        	editColumnOperation.getElementsByClassName('bi-pencil')[0].addEventListener('click', (e) => {
					        		e.preventDefault();
					        		let editForm = document.getElementsByClassName('edit-sheet-header-column-container')[0];
					        		if (editForm.style.display === 'none') {
					        			editForm.style.display = 'flex';
					        		} else {
					        			editForm.style.display = 'none';
					        		}

					        		// get the values from edit-fields
							        const editSheetHeaderColumnName = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("edit-sheet-header-column-name")[0];
							        const editSheetHeaderResponseKeyname = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("edit-sheet-header-response-keyname")[0];
							        const editColumnBtn = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("edit-column-btn")[0];
					        		
							        editSheetHeaderColumnName.value = props.attributes.thisModelRef.get('sheetColumns')[colIdx];
							        editSheetHeaderResponseKeyname.value = props.attributes.thisModelRef.get('apiResponseColumnKeynames')[colIdx];

					        		editColumnBtn.onclick = function(e) {
					        			e.preventDefault();
						        		if (editSheetHeaderColumnName.value && editSheetHeaderResponseKeyname.value) {
						        			editExcelColumn(editor, props.attributes.thisModelRef, col, colIdx, editSheetHeaderColumnName.value, editSheetHeaderResponseKeyname.value);
						        		} else {
						        			toast.error("Cannot edit a column unless both column's name and API response's key name are provided.", notifyTheme);
						        		}
					        		};
					        	});
					        	
					        	editColumnOperation.getElementsByClassName('bi-trash3')[0].addEventListener('mouseover', (e) => editColumnOperation.getElementsByClassName('bi-trash3')[0].style.color = 'rgba(0, 0, 0, 0.2)');
					        	editColumnOperation.getElementsByClassName('bi-trash3')[0].addEventListener('mouseleave', (e) => editColumnOperation.getElementsByClassName('bi-trash3')[0].style.color = '#990011');
					        	// if they click on 'delete'
					        	editColumnOperation.getElementsByClassName('bi-trash3')[0].addEventListener('click', (e) => deleteExcelColumn(editor, props.attributes.thisModelRef, col, colIdx));

					        	headerCell.appendChild(editColumnOperation);

					        	headerRow.appendChild(headerCell);
					        });
		        		} else {
		        			toast.error("Cannot add a column unless both column's name and API response's key name are provided.", notifyTheme);
		        		}
		        	});

		        	excelFileNameApplyBtn.addEventListener('click', (e) => {
		        		e.preventDefault();
		        		// to make sure length of array - sheetColumns, apiResponseColumnKeynames are the same
		        		if (excelFileName.value) {
		        			applyExcelFileName(editor, props.attributes.thisModelRef, excelFileName.value);
		        		} else {
		        			toast.error("Cannot apply an empty input. Please provide a filename for an excel export before applying or a default one will be provided by the platform.", notifyTheme);
		        		}
		        	});
		        } catch(e) {
		        	// statements
		        	console.log(e);
		        }
			}

			// excel file import handling
			if (document.getElementsByClassName("gjs-mdl-content")[0] !== undefined && document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("custom-excel-import-form")[0] !== undefined ) {
				// API trait modal inputs pointers
		        // const customUrl = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("custom-url")[0];
		        const baseUrl = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("base-url")[0];
		        // const apiPath = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("api-path")[0];
		        const dataModelName = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("data-model-name")[0];
		        const apiMethod = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("api-method")[0];
		        // const apiUsername = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("api-username")[0];
		        // const apiPassword = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("api-password")[0];
		        // const apiBearerToken = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("api-bearer-token")[0];
		        const apiMoreHeaders = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("api-more-headers")[0];
		        const apiPrimaryKey = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("api-primary-key")[0];
		        const updateExcelImportBtn = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("update-db-model-btn")[0];
		        
		        const colName = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("mapping-form")[0].getElementsByClassName("sheet-header-column-name")[0];
		        const fieldName = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("mapping-form")[0].getElementsByClassName("sheet-header-response-keyname")[0];
		        const requiredField = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("mapping-form")[0].getElementsByClassName("required-field")[0];
		        const invalidValue = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("mapping-form")[0].getElementsByClassName("invalid-value")[0];
		        const addColBtn = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("mapping-form")[0].getElementsByClassName("add-column-btn")[0];
				
				// reinserting saved values for the user to see
		        try {
		        	// customUrl.value = props.attributes.thisModelRef.get('customUrlValue');
			        baseUrl.value = props.attributes.thisModelRef.get('baseUrlValue');
			        // apiPath.value = props.attributes.thisModelRef.get('apiPathValue');
			        dataModelName.value = props.attributes.thisModelRef.get('dataModelNameValue');
			        apiMethod.value = props.attributes.thisModelRef.get('apiMethodValue');
			        // apiUsername.value = props.attributes.thisModelRef.get('apiUsernameValue');
			        // apiPassword.value = props.attributes.thisModelRef.get('apiPasswordValue');
			        // apiBearerToken.value = props.attributes.thisModelRef.get('apiBearerTokenValue');
			        apiMoreHeaders.value = props.attributes.thisModelRef.get('apiMoreHeadersValue');
			        apiPrimaryKey.value = props.attributes.thisModelRef.get('primaryKey');

			        // PREVIEW of excel import sheet columns
			        let importTablePrv = document.getElementsByClassName('excel-import-sheet-preview')[0].getElementsByTagName('table')[0];
					// Apply general styling to the table
					importTablePrv.style.borderCollapse = 'collapse';
					importTablePrv.style.width = '100%';
					importTablePrv.style.border = '1px solid #ddd';
					// Apply horizontal scrolling to the importTablePrv if it exceeds the modal boundary
					importTablePrv.style.overflowX = 'auto';

			        let headerRow = document.getElementsByClassName('excel-import-sheet-preview')[0].getElementsByClassName('header-row')[0];
			        // clear headerRow and readd all to avoid duplicates
			        headerRow.innerHTML = "";
			        let apiResponseColumnKeynames = props.attributes.thisModelRef.get('apiResponseColumnKeynames');
			        apiResponseColumnKeynames.forEach((col, colIdx) => {
			        	let headerCell = document.createElement('th');
			        	// Apply styling to header cells
					    headerCell.style.border = '1px solid #ddd';
					    headerCell.style.padding = '0.7%';
					    headerCell.style.textAlign = 'left';
					    headerCell.style.minWidth = 'max-content';
					    headerCell.style.color = 'black';
					    headerCell.style.fontWeight = 'normal';
					    headerCell.style.cursor = 'pointer';
					    headerCell.style.position = 'relative';
			        	headerCell.textContent = col;

			        	headerCell.addEventListener('mouseover', (e) => headerCell.style.backgroundColor = 'rgba(0, 0, 0, 0.2)');
			        	headerCell.addEventListener('mouseleave', (e) => headerCell.style.backgroundColor = 'transparent');
			        	
			        	// adding delete icons on every cell of the table
			        	let editColumnOperation = document.createElement('div');
			        	editColumnOperation.style.position = 'absolute';
			        	editColumnOperation.style.bottom = '110%';
			        	// editColumnOperation.style.left = '2%';
			        	editColumnOperation.style.width = 'max-content';
			        	editColumnOperation.style.display = 'flex';
			        	editColumnOperation.style.alignItems = 'center';
			        	editColumnOperation.style.justifyContent = 'flex-start';
			        	editColumnOperation.style.gap = '30%';
			        	editColumnOperation.innerHTML += `<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-trash3" viewBox="0 0 16 16"><path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5M11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47M8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5"/></svg>`;
       	
			        	editColumnOperation.getElementsByClassName('bi-trash3')[0].addEventListener('mouseover', (e) => editColumnOperation.getElementsByClassName('bi-trash3')[0].style.color = 'rgba(0, 0, 0, 0.2)');
			        	editColumnOperation.getElementsByClassName('bi-trash3')[0].addEventListener('mouseleave', (e) => editColumnOperation.getElementsByClassName('bi-trash3')[0].style.color = '#990011');
			        	// if they click on 'delete'
			        	editColumnOperation.getElementsByClassName('bi-trash3')[0].addEventListener('click', (e) => deleteExcelImportColumn(editor, props.attributes.thisModelRef, col, colIdx));

			        	headerCell.appendChild(editColumnOperation);

			        	headerRow.appendChild(headerCell);
			        });
			    } catch(e) {
		        	// statements
		        	console.log(e);
		        }

				// adding event listeners for taking action
		        try {
		        	updateExcelImportBtn.addEventListener('click', (e) => {
		        		e.preventDefault();
		        		if (baseUrl.value || dataModelName.value || apiMethod.value || apiMoreHeaders.value || apiPrimaryKey.value) {
		        			updateExcelImportButton(editor, props.attributes.thisModelRef, baseUrl.value, dataModelName.value, apiMethod.value, apiMoreHeaders.value, apiPrimaryKey.value);
		        		} else {
		        			toast.warn(`Please provide some information about the excel's data storage model.`, notifyTheme );
		        		}

		        		// close the modal on clicking the button, at the end
						// editor.Modal.close();
		        	});

		        	// button to only set mapping (not edit/delete)
		        	addColBtn.addEventListener('click', (e) => {
		        		e.preventDefault();
		        		if (colName.value && fieldName.value) {
		        			setMapping(editor, props.attributes.thisModelRef, colName.value, fieldName.value, requiredField.checked, invalidValue.value);
		        			
		        			// PREVIEW of excel import sheet columns
					        let importTablePrv = document.getElementsByClassName('excel-import-sheet-preview')[0].getElementsByTagName('table')[0];
							// Apply general styling to the table
							importTablePrv.style.borderCollapse = 'collapse';
							importTablePrv.style.width = '100%';
							importTablePrv.style.border = '1px solid #ddd';
							// Apply horizontal scrolling to the importTablePrv if it exceeds the modal boundary
							importTablePrv.style.overflowX = 'auto';

					        let headerRow = document.getElementsByClassName('excel-import-sheet-preview')[0].getElementsByClassName('header-row')[0];
					        // clear headerRow and readd all to avoid duplicates
					        headerRow.innerHTML = "";
					        let apiResponseColumnKeynames = props.attributes.thisModelRef.get('apiResponseColumnKeynames');
					        apiResponseColumnKeynames.forEach((col, colIdx) => {
					        	let headerCell = document.createElement('th');
					        	// Apply styling to header cells
							    headerCell.style.border = '1px solid #ddd';
							    headerCell.style.padding = '0.7%';
							    headerCell.style.textAlign = 'left';
							    headerCell.style.minWidth = 'max-content';
							    headerCell.style.color = 'black';
							    headerCell.style.fontWeight = 'normal';
							    headerCell.style.cursor = 'pointer';
							    headerCell.style.position = 'relative';
					        	headerCell.textContent = col;

					        	headerCell.addEventListener('mouseover', (e) => headerCell.style.backgroundColor = 'rgba(0, 0, 0, 0.2)');
					        	headerCell.addEventListener('mouseleave', (e) => headerCell.style.backgroundColor = 'transparent');
					        	
					        	// adding delete icons on every cell of the table
					        	let editColumnOperation = document.createElement('div');
					        	editColumnOperation.style.position = 'absolute';
					        	editColumnOperation.style.bottom = '110%';
					        	// editColumnOperation.style.left = '2%';
					        	editColumnOperation.style.width = 'max-content';
					        	editColumnOperation.style.display = 'flex';
					        	editColumnOperation.style.alignItems = 'center';
					        	editColumnOperation.style.justifyContent = 'flex-start';
					        	editColumnOperation.style.gap = '30%';
					        	editColumnOperation.innerHTML += `<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-trash3" viewBox="0 0 16 16"><path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5M11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47M8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5"/></svg>`;
		       	
					        	editColumnOperation.getElementsByClassName('bi-trash3')[0].addEventListener('mouseover', (e) => editColumnOperation.getElementsByClassName('bi-trash3')[0].style.color = 'rgba(0, 0, 0, 0.2)');
					        	editColumnOperation.getElementsByClassName('bi-trash3')[0].addEventListener('mouseleave', (e) => editColumnOperation.getElementsByClassName('bi-trash3')[0].style.color = '#990011');
					        	// if they click on 'delete'
					        	editColumnOperation.getElementsByClassName('bi-trash3')[0].addEventListener('click', (e) => deleteExcelImportColumn(editor, props.attributes.thisModelRef, col, colIdx));

					        	headerCell.appendChild(editColumnOperation);

					        	headerRow.appendChild(headerCell);
					        });
		        		} else {
		        			toast.warn(`Please fill both fields.`, successTheme);
		        		}
		        	});
		        } catch(e) {
		        	// statements
		        	console.log(e);
		        }
			}
		});

		// function to edit excel structure
		function editExcelColumn(editorRef, thisModelRef, col, colIdx, editSheetHeaderColumnNameValue, editSheetHeaderResponseKeynameValue) {
			console.log('editExcelColumn', col, colIdx, editSheetHeaderColumnNameValue, editSheetHeaderResponseKeynameValue);

			let sheetColumns = thisModelRef.get('sheetColumns');
			let apiResponseColumnKeynames = thisModelRef.get('apiResponseColumnKeynames');

			sheetColumns[colIdx] = editSheetHeaderColumnNameValue;
			apiResponseColumnKeynames[colIdx] = editSheetHeaderResponseKeynameValue;

			thisModelRef.set({
				sheetColumns,
				apiResponseColumnKeynames,
			});

			// Update the PREVIEW of excel sheet columns
	        let table = document.getElementsByClassName('excel-sheet-preview')[0].getElementsByTagName('table')[0];
			// Apply general styling to the table
			table.style.borderCollapse = 'collapse';
			table.style.width = '100%';
			table.style.border = '1px solid #ddd';
			// Apply horizontal scrolling to the table if it exceeds the modal boundary
			table.style.overflowX = 'auto';

	        let headerRow = document.getElementsByClassName('excel-sheet-preview')[0].getElementsByClassName('header-row')[0];
	        // clear headerRow and readd all to avoid duplicates
	        headerRow.innerHTML = "";
	        sheetColumns.forEach((col, colIdx) => {
	        	let headerCell = document.createElement('th');
	        	// Apply styling to header cells
			    headerCell.style.border = '1px solid #ddd';
			    headerCell.style.padding = '0.7%';
			    headerCell.style.textAlign = 'left';
			    headerCell.style.minWidth = 'max-content';
			    headerCell.style.color = 'black';
			    headerCell.style.fontWeight = 'normal';
			    headerCell.style.cursor = 'pointer';
			    headerCell.style.position = 'relative';
	        	headerCell.textContent = col;

	        	headerCell.addEventListener('mouseover', (e) => headerCell.style.backgroundColor = 'rgba(0, 0, 0, 0.2)');
	        	headerCell.addEventListener('mouseleave', (e) => headerCell.style.backgroundColor = 'transparent');
	        	
	        	// adding edit/delete icons on every cell of the table
	        	let editColumnOperation = document.createElement('div');
	        	editColumnOperation.style.position = 'absolute';
	        	editColumnOperation.style.bottom = '110%';
	        	editColumnOperation.style.left = '2%';
	        	editColumnOperation.style.width = 'max-content';
	        	editColumnOperation.style.display = 'flex';
	        	editColumnOperation.style.alignItems = 'center';
	        	editColumnOperation.style.justifyContent = 'flex-start';
	        	editColumnOperation.style.gap = '30%';
	        	editColumnOperation.innerHTML += `<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-pencil" viewBox="0 0 16 16"><path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325"/></svg>`;
	        	editColumnOperation.innerHTML += `<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-trash3" viewBox="0 0 16 16"><path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5M11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47M8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5"/></svg>`;

	        	editColumnOperation.getElementsByClassName('bi-pencil')[0].addEventListener('mouseover', (e) => editColumnOperation.getElementsByClassName('bi-pencil')[0].style.color = 'rgba(0, 0, 0, 0.2)');
	        	editColumnOperation.getElementsByClassName('bi-pencil')[0].addEventListener('mouseleave', (e) => editColumnOperation.getElementsByClassName('bi-pencil')[0].style.color = '#990011');
	        	// if they click on 'edit'
	        	editColumnOperation.getElementsByClassName('bi-pencil')[0].addEventListener('click', (e) => {
	        		e.preventDefault();
	        		let editForm = document.getElementsByClassName('edit-sheet-header-column-container')[0];
	        		if (editForm.style.display === 'none') {
	        			editForm.style.display = 'flex';
	        		} else {
	        			editForm.style.display = 'none';
	        		}

	        		// get the values from edit-fields
			        const editSheetHeaderColumnName = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("edit-sheet-header-column-name")[0];
			        const editSheetHeaderResponseKeyname = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("edit-sheet-header-response-keyname")[0];
			        const editColumnBtn = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("edit-column-btn")[0];
	        		
			        editSheetHeaderColumnName.value = thisModelRef.get('sheetColumns')[colIdx];
			        editSheetHeaderResponseKeyname.value = thisModelRef.get('apiResponseColumnKeynames')[colIdx];

	        		editColumnBtn.onclick = function(e) {
	        			e.preventDefault();
		        		if (editSheetHeaderColumnName.value && editSheetHeaderResponseKeyname.value) {
		        			editExcelColumn(editor, thisModelRef, col, colIdx, editSheetHeaderColumnName.value, editSheetHeaderResponseKeyname.value);
		        		} else {
		        			toast.error("Cannot edit a column unless both column's name and API response's key name are provided.", notifyTheme);
		        		}
	        		};
	        	});
	        	
	        	editColumnOperation.getElementsByClassName('bi-trash3')[0].addEventListener('mouseover', (e) => editColumnOperation.getElementsByClassName('bi-trash3')[0].style.color = 'rgba(0, 0, 0, 0.2)');
	        	editColumnOperation.getElementsByClassName('bi-trash3')[0].addEventListener('mouseleave', (e) => editColumnOperation.getElementsByClassName('bi-trash3')[0].style.color = '#990011');
	        	// if they click on 'delete'
	        	editColumnOperation.getElementsByClassName('bi-trash3')[0].addEventListener('click', (e) => deleteExcelColumn(editor, thisModelRef, col, colIdx));

	        	headerCell.appendChild(editColumnOperation);

	        	headerRow.appendChild(headerCell);
	        });

			toast.info(`This column has been edited.`, notifyTheme2);
			console.log('editExcelColumn thisModelRef', thisModelRef);
		}

		// function to delete excel columns
		function deleteExcelColumn(editorRef, thisModelRef, col, colIdx) {
			console.log('deleteExcelColumn', col, colIdx);

			let sheetColumns = thisModelRef.get('sheetColumns');
			let apiResponseColumnKeynames = thisModelRef.get('apiResponseColumnKeynames');

			// Create new arrays to hold modified values
		    let newSheetColumns = [...sheetColumns];
		    let newApiResponseColumnKeynames = [...apiResponseColumnKeynames];

			// Remove item at colIdx from both arrays
		    newSheetColumns.splice(colIdx, 1);
		    newApiResponseColumnKeynames.splice(colIdx, 1);

		    thisModelRef.set({
				sheetColumns: newSheetColumns,
				apiResponseColumnKeynames: newApiResponseColumnKeynames,
			});

			// Update the PREVIEW of excel sheet columns
	        let table = document.getElementsByClassName('excel-sheet-preview')[0].getElementsByTagName('table')[0];
			// Apply general styling to the table
			table.style.borderCollapse = 'collapse';
			table.style.width = '100%';
			table.style.border = '1px solid #ddd';
			// Apply horizontal scrolling to the table if it exceeds the modal boundary
			table.style.overflowX = 'auto';

	        let headerRow = document.getElementsByClassName('excel-sheet-preview')[0].getElementsByClassName('header-row')[0];
	        // clear headerRow and readd all to avoid duplicates
	        headerRow.innerHTML = "";
	        newSheetColumns.forEach((col, colIdx) => {
	        	let headerCell = document.createElement('th');
	        	// Apply styling to header cells
			    headerCell.style.border = '1px solid #ddd';
			    headerCell.style.padding = '0.7%';
			    headerCell.style.textAlign = 'left';
			    headerCell.style.minWidth = 'max-content';
			    headerCell.style.color = 'black';
			    headerCell.style.fontWeight = 'normal';
			    headerCell.style.cursor = 'pointer';
			    headerCell.style.position = 'relative';
	        	headerCell.textContent = col;

	        	headerCell.addEventListener('mouseover', (e) => headerCell.style.backgroundColor = 'rgba(0, 0, 0, 0.2)');
	        	headerCell.addEventListener('mouseleave', (e) => headerCell.style.backgroundColor = 'transparent');
	        	
	        	// adding edit/delete icons on every cell of the table
	        	let editColumnOperation = document.createElement('div');
	        	editColumnOperation.style.position = 'absolute';
	        	editColumnOperation.style.bottom = '110%';
	        	editColumnOperation.style.left = '2%';
	        	editColumnOperation.style.width = 'max-content';
	        	editColumnOperation.style.display = 'flex';
	        	editColumnOperation.style.alignItems = 'center';
	        	editColumnOperation.style.justifyContent = 'flex-start';
	        	editColumnOperation.style.gap = '30%';
	        	editColumnOperation.innerHTML += `<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-pencil" viewBox="0 0 16 16"><path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325"/></svg>`;
	        	editColumnOperation.innerHTML += `<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-trash3" viewBox="0 0 16 16"><path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5M11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47M8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5"/></svg>`;

	        	editColumnOperation.getElementsByClassName('bi-pencil')[0].addEventListener('mouseover', (e) => editColumnOperation.getElementsByClassName('bi-pencil')[0].style.color = 'rgba(0, 0, 0, 0.2)');
	        	editColumnOperation.getElementsByClassName('bi-pencil')[0].addEventListener('mouseleave', (e) => editColumnOperation.getElementsByClassName('bi-pencil')[0].style.color = '#990011');
	        	// if they click on 'edit'
	        	editColumnOperation.getElementsByClassName('bi-pencil')[0].addEventListener('click', (e) => {
	        		e.preventDefault();
	        		let editForm = document.getElementsByClassName('edit-sheet-header-column-container')[0];
	        		if (editForm.style.display === 'none') {
	        			editForm.style.display = 'flex';
	        		} else {
	        			editForm.style.display = 'none';
	        		}

	        		// get the values from edit-fields
			        const editSheetHeaderColumnName = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("edit-sheet-header-column-name")[0];
			        const editSheetHeaderResponseKeyname = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("edit-sheet-header-response-keyname")[0];
			        const editColumnBtn = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("edit-column-btn")[0];
	        		
			        editSheetHeaderColumnName.value = thisModelRef.get('sheetColumns')[colIdx];
			        editSheetHeaderResponseKeyname.value = thisModelRef.get('apiResponseColumnKeynames')[colIdx];

	        		editColumnBtn.onclick = function(e) {
	        			e.preventDefault();
		        		if (editSheetHeaderColumnName.value && editSheetHeaderResponseKeyname.value) {
		        			editExcelColumn(editor, thisModelRef, col, colIdx, editSheetHeaderColumnName.value, editSheetHeaderResponseKeyname.value);
		        		} else {
		        			toast.error("Cannot edit a column unless both column's name and API response's key name are provided.", notifyTheme);
		        		}
	        		};
	        	});
	        	
	        	editColumnOperation.getElementsByClassName('bi-trash3')[0].addEventListener('mouseover', (e) => editColumnOperation.getElementsByClassName('bi-trash3')[0].style.color = 'rgba(0, 0, 0, 0.2)');
	        	editColumnOperation.getElementsByClassName('bi-trash3')[0].addEventListener('mouseleave', (e) => editColumnOperation.getElementsByClassName('bi-trash3')[0].style.color = '#990011');
	        	// if they click on 'delete'
	        	editColumnOperation.getElementsByClassName('bi-trash3')[0].addEventListener('click', (e) => deleteExcelColumn(editor, thisModelRef, col, colIdx));

	        	headerCell.appendChild(editColumnOperation);

	        	headerRow.appendChild(headerCell);
	        });

			toast.info(`This column has been removed.`, notifyTheme2);
			console.log('deleteExcelColumn thisModelRef', thisModelRef);
		}

		// function to delete excel columns
		function deleteExcelImportColumn(editorRef, thisModelRef, col, colIdx) {
			console.log('deleteExcelImportColumn', col, colIdx);

			let sheetColumns = thisModelRef.get('sheetColumns');
			let apiResponseColumnKeynames = thisModelRef.get('apiResponseColumnKeynames');
			let requiredFields = thisModelRef.get('requiredFields');
			let invalidValue = thisModelRef.get('invalidValue');

			// Create new arrays to hold modified values
		    let newSheetColumns = [...sheetColumns];
		    let newApiResponseColumnKeynames = [...apiResponseColumnKeynames];
		    let newRequiredFields = [...requiredFields];
		    let newInvalidValue = {...invalidValue};

			// Remove item at colIdx from all arrays
		    newSheetColumns.splice(colIdx, 1);
		    newApiResponseColumnKeynames.splice(colIdx, 1);
		    newRequiredFields.splice(colIdx, 1);
		    delete newInvalidValue[col];

		    thisModelRef.set({
				sheetColumns: newSheetColumns,
				apiResponseColumnKeynames: newApiResponseColumnKeynames,
				requiredFields: newRequiredFields,
				invalidValue: newInvalidValue,
			});

			// Update the PREVIEW of excel sheet columns
	        let table = document.getElementsByClassName('excel-import-sheet-preview')[0].getElementsByTagName('table')[0];
			// Apply general styling to the table
			table.style.borderCollapse = 'collapse';
			table.style.width = '100%';
			table.style.border = '1px solid #ddd';
			// Apply horizontal scrolling to the table if it exceeds the modal boundary
			table.style.overflowX = 'auto';

	        let headerRow = document.getElementsByClassName('excel-import-sheet-preview')[0].getElementsByClassName('header-row')[0];
	        // clear headerRow and readd all to avoid duplicates
	        headerRow.innerHTML = "";
	        newSheetColumns.forEach((col, colIdx) => {
	        	let headerCell = document.createElement('th');
	        	// Apply styling to header cells
			    headerCell.style.border = '1px solid #ddd';
			    headerCell.style.padding = '0.7%';
			    headerCell.style.textAlign = 'left';
			    headerCell.style.minWidth = 'max-content';
			    headerCell.style.color = 'black';
			    headerCell.style.fontWeight = 'normal';
			    headerCell.style.cursor = 'pointer';
			    headerCell.style.position = 'relative';
	        	headerCell.textContent = col;

	        	headerCell.addEventListener('mouseover', (e) => headerCell.style.backgroundColor = 'rgba(0, 0, 0, 0.2)');
	        	headerCell.addEventListener('mouseleave', (e) => headerCell.style.backgroundColor = 'transparent');
	        	
	        	// adding delete icons on every cell of the table
	        	let editColumnOperation = document.createElement('div');
	        	editColumnOperation.style.position = 'absolute';
	        	editColumnOperation.style.bottom = '110%';
	        	// editColumnOperation.style.left = '2%';
	        	editColumnOperation.style.width = 'max-content';
	        	editColumnOperation.style.display = 'flex';
	        	editColumnOperation.style.alignItems = 'center';
	        	editColumnOperation.style.justifyContent = 'flex-start';
	        	editColumnOperation.style.gap = '30%';
	        	editColumnOperation.innerHTML += `<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-trash3" viewBox="0 0 16 16"><path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5M11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47M8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5"/></svg>`;
	        	
	        	editColumnOperation.getElementsByClassName('bi-trash3')[0].addEventListener('mouseover', (e) => editColumnOperation.getElementsByClassName('bi-trash3')[0].style.color = 'rgba(0, 0, 0, 0.2)');
	        	editColumnOperation.getElementsByClassName('bi-trash3')[0].addEventListener('mouseleave', (e) => editColumnOperation.getElementsByClassName('bi-trash3')[0].style.color = '#990011');
	        	// if they click on 'delete'
	        	editColumnOperation.getElementsByClassName('bi-trash3')[0].addEventListener('click', (e) => deleteExcelImportColumn(editor, thisModelRef, col, colIdx));

	        	headerCell.appendChild(editColumnOperation);

	        	headerRow.appendChild(headerCell);
	        });

			toast.info(`This column has been removed.`, notifyTheme2);
			console.log('deleteExcelImportColumn thisModelRef', thisModelRef);
		}

		// function to update excel button model
		function updateExcelButton(editorRef, thisModelRef, customUrlValue, baseUrlValue, apiPathValue, apiMethodValue, apiUsernameValue, apiPasswordValue, apiBearerTokenValue, apiMoreHeadersValue, apiBodyValue, nestedArrOfObjKeynameValue) {
			console.log('editorRef, thisModelRef, customUrlValue, baseUrlValue, apiPathValue, apiMethodValue, apiUsernameValue, apiPasswordValue, apiBearerTokenValue, apiMoreHeadersValue, apiBodyValue, nestedArrOfObjKeynameValue', editorRef, thisModelRef, customUrlValue, baseUrlValue, apiPathValue, apiMethodValue, apiUsernameValue, apiPasswordValue, apiBearerTokenValue, apiMoreHeadersValue, apiBodyValue, nestedArrOfObjKeynameValue);
			
			// updating model ref
			thisModelRef.set({
				customUrlValue,
				baseUrlValue,
				apiPathValue,
				apiMethodValue,
				apiUsernameValue,
				apiPasswordValue,
				apiBearerTokenValue,
				apiMoreHeadersValue,
				apiBodyValue,
				nestedArrOfObjKeynameValue,
			});

			toast.info(`The source definition has been updated. Please proceed to defining your excel sheet's structure in the second tab.`, infoTheme );
			// thisModelRef.view.render();
			console.log('updateExcelButton thisModelRef', thisModelRef);
		}

		// function to update excel import button model
		function updateExcelImportButton(editorRef, thisModelRef, baseUrlValue, dataModelNameValue, apiMethodValue, apiMoreHeadersValue, apiPrimaryKeyValue) {
			console.log('editorRef, thisModelRef, baseUrlValue, apiMethodValue, apiMoreHeadersValue', editorRef, thisModelRef, baseUrlValue, apiMethodValue, apiMoreHeadersValue);
			
			// updating model ref
			thisModelRef.set({
				baseUrlValue,
				dataModelNameValue,
				apiMethodValue,
				apiMoreHeadersValue,
				primaryKey: apiPrimaryKeyValue,
			});

			toast.info(`The storage model has been defined.`, infoTheme );
			// thisModelRef.view.render();
			console.log('updateExcelImportButton thisModelRef', thisModelRef);
		}

		// function to set the mapping - column name & field name
		function setMapping(editorRef, thisModelRef, colNameValue, fieldNameValue, requiredFieldChecked, invalidValueValue) {
			console.log('editorRef, colNameValue, fieldNameValue, requiredFieldChecked, invalidValueValue', editorRef, colNameValue, fieldNameValue, requiredFieldChecked, invalidValueValue);
			
			let sheetColumns = thisModelRef.get('sheetColumns');
			let apiResponseColumnKeynames = thisModelRef.get('apiResponseColumnKeynames');
			let requiredFields = thisModelRef.get('requiredFields');
			let invalidValue = thisModelRef.get('invalidValue');

			// Create new arrays to hold modified values and append new values
			if (colNameValue === fieldNameValue) {
				let newSheetColumns = [...sheetColumns];
			    let newApiResponseColumnKeynames = [...apiResponseColumnKeynames];
			    let newRequiredFields = [...requiredFields];
			    let newInvalidValue = {...invalidValue};

			    newSheetColumns.push(colNameValue);
			    newApiResponseColumnKeynames.push(fieldNameValue);
			    newInvalidValue[fieldNameValue] = invalidValueValue;

			    // if this field is 'required', add it to requiredFields array
			    if (requiredFieldChecked) {
			    	newRequiredFields.push(fieldNameValue);
			    }			    

			    thisModelRef.set({
					sheetColumns: newSheetColumns,
					apiResponseColumnKeynames: newApiResponseColumnKeynames,
					requiredFields: newRequiredFields,
					invalidValue: newInvalidValue,
				});
			} else {
				toast.error(`Failed to save. 'Column Name' and 'Field Name' were not the same. Please try again.`, notifyTheme);
			}

			console.log('thisModelRef', thisModelRef);
		}

		// function to add columns to make an excel script out of it
		function addColumn(editorRef, thisModelRef, sheetHeaderColumnNameValue, sheetHeaderResponseKeynameValue) {
			console.log('editorRef, thisModelRef, sheetHeaderColumnNameValue, sheetHeaderResponseKeynameValue', editorRef, thisModelRef, sheetHeaderColumnNameValue, sheetHeaderResponseKeynameValue);
		
			let sheetColumns = thisModelRef.get('sheetColumns');
			let apiResponseColumnKeynames = thisModelRef.get('apiResponseColumnKeynames');

			let updatedSheetColumns = [...sheetColumns, sheetHeaderColumnNameValue];
			let updatedColumnKeynames = [...apiResponseColumnKeynames, sheetHeaderResponseKeynameValue];

			thisModelRef.set({
				sheetColumns: updatedSheetColumns,
				apiResponseColumnKeynames: updatedColumnKeynames,
			});

			toast.info(`This column has been added.`, notifyTheme2);
			console.log('addColumn thisModelRef', thisModelRef);
		}

		// function to apply the filename to the excel file before downloading
		function applyExcelFileName(editorRef, thisModelRef, excelFileNameValue) {
			console.log('editorRef, thisModelRef, excelFileNameValue', editorRef, thisModelRef, excelFileNameValue);

			thisModelRef.set('excelFileName', excelFileNameValue);

			toast.info(`Filename is applied.`, notifyTheme2);
			console.log('applyExcelFileName thisModelRef', thisModelRef);
		}
    }
};

export default CustomExcelPlugin;