export default function manageInputTagTraits(editor) {
  // console.log("table data::::::::::", tableData);
  // const tableInsideProject = tableData?.filter((x) => x.project === project_id)

  // let tableName = tableInsideProject?.map((x) => {
  //     let arr = {
  //         name: x.table_name
  //     }

  //     return arr;
  // })

  // tableName?.push({
  //     name: 'none'
  // })

  ////console.log("tableName:::::", tableName);
  // editor.DomComponents.addType('input', {
  //     isComponent: el => el.tagName == 'INPUT',
  //     model: {
  //         defaults: {
  //             traits: [
  //                 // Strings are automatically converted to text types
  //                 'name', // Same as: { type: 'text', name: 'name' }
  //                 'placeholder',
  //                 {
  //                     type: 'select', // Type of the trait
  //                     label: 'Type', // The label you will see in Settings
  //                     name: 'type', // The name of the attribute/property to use on component
  //                     options: [
  //                         { id: 'text', name: 'Text' },
  //                         { id: 'email', name: 'Email' },
  //                         { id: 'password', name: 'Password' },
  //                         { id: 'number', name: 'Number' },
  //                     ]
  //                 }, {
  //                     type: 'checkbox',
  //                     name: 'required',
  //                 }, {
  //                     type: 'checkbox',
  //                     name: 'checked',
  //                 },
  //             ],
  //             // As by default, traits are binded to attributes, so to define
  //             // their initial value we can use attributes
  //             attributes: { type: 'text', required: true },
  //         },
  //     },
  // });

  // editor.DomComponents.addType('link', {
  //     model: {
  //         defaults: {
  //             traits: [{
  //                 type: 'href-next',
  //                 name: 'href',
  //                 label: 'New href',
  //             }, ]
  //         }
  //     }
  // });

  // editor.TraitManager.addType('href-next', {
  //     // Expects as return a simple HTML string or an HTML element
  //     createInput({ trait }) {
  //         // Here we can decide to use properties from the trait
  //         const traitOpts = trait.get('options') || [];
  //         const options = traitOpts.length ? traitOpts : [
  //             { id: 'url', name: 'URL' },
  //             { id: 'email', name: 'Email' },
  //         ];

  //         // Create a new element container and add some content
  //         const el = document.createElement('div');
  //         el.innerHTML = `
  //   <select class="href-next__type">
  //     ${options.map(opt => `<option value="${opt.id}">${opt.name}</option>`).join('')}
  //   </select>
  //   <div class="href-next__url-inputs">
  //     <input class="href-next__url" placeholder="Insert URL"/>
  //   </div>
  //   <div class="href-next__email-inputs">
  //     <input class="href-next__email" placeholder="Insert email"/>
  //     <input class="href-next__email-subject" placeholder="Insert subject"/>
  //   </div>
  // `;

  //         // Let's make our content interactive
  //         const inputsUrl = el.querySelector('.href-next__url-inputs');
  //         const inputsEmail = el.querySelector('.href-next__email-inputs');
  //         const inputType = el.querySelector('.href-next__type');
  //         inputType.addEventListener('change', ev => {
  //             switch (ev.target.value) {
  //                 case 'url':
  //                     inputsUrl.style.display = '';
  //                     inputsEmail.style.display = 'none';
  //                     break;
  //                 case 'email':
  //                     inputsUrl.style.display = 'none';
  //                     inputsEmail.style.display = '';
  //                     break;
  //             }
  //         });

  //         return el;
  //     },

  //     // Update the component based on element changes
  //     // `elInput` is the result HTMLElement you get from `createInput`
  //     onEvent({ elInput, component, event }) {
  //         const inputType = elInput.querySelector('.href-next__type');
  //         let href = '';

  //         switch (inputType.value) {
  //             case 'url':
  //                 const valUrl = elInput.querySelector('.href-next__url').value;
  //                 href = valUrl;
  //                 break;
  //             case 'email':
  //                 const valEmail = elInput.querySelector('.href-next__email').value;
  //                 const valSubj = elInput.querySelector('.href-next__email-subject').value;
  //                 href = `mailto:${valEmail}${valSubj ? `?subject=${valSubj}` : ''}`;
  //                 break;
  //         }

  //         component.addAttributes({ href })
  //     },
  // });

  // Description: when the editor space is loaded or updated, all the componenets in the current state of the editor space is collected and added traits to and then shown in the traits manager
  const getAllComponents = (model, result = []) => {
    result.push(model);
    model.components().each((mod) => getAllComponents(mod, result));
    return result;
  };
  const allComps = getAllComponents(editor.DomComponents.getWrapper());

  // //console.log('allComps', allComps);
  // //console.log('selected comp:', editor.getSelected());

  allComps.forEach((ele) => {
    // console.log('ele.attributes:', ele.attributes);
    // //console.log('ele.attributes.element:', ele.attributes.editable);
    // //console.log('tagname:', ele.attributes.tagName);

    // adding traits for input elements only
    if (ele.attributes.tagName === "input") {
      // adding REST API traits for all input elements inside REST API form
      // if (ele.parent().attributes.attributes.id.startsWith('ft-')) {
      //      .log('>>>> parent is rest api form');
      // }

      // adding & filtering traits only for: CHECKBOX, RADIO input component
      // if (
      //   ele.attributes.attributes.type === "checkbox" ||
      //   ele.attributes.attributes.type === "radio"
      // ) {
      //   // check if traits are already present, if not then .addtrait, otherwise show the already present ones
      //   let setNewProp1 = true;
      //   const traits = ele.get("traits");
      //   traits.forEach((trait) => {
      //     const curProps = trait.props();
      //     // // .log('props:', curProps);
      //     if (
      //       curProps.label === "Checked" ||
      //       curProps.label === "Required" ||
      //       curProps.label === "Disabled"
      //     ) {
      //       setNewProp1 = false;
      //     }
      //   });

      //   if (setNewProp1 !== false) {
      //     ele.addTrait({ name: "name" }, { at: 0 });

      //     ele.addTrait(
      //       {
      //         type: "checkbox",
      //         label: "Checked",
      //         name: "checked",
      //       },
      //       { at: 1 }
      //     );

      //     ele.addTrait(
      //       {
      //         type: "checkbox",
      //         label: "Required",
      //         name: "required",
      //       },
      //       { at: 2 }
      //     );

      //     ele.addTrait(
      //       {
      //         type: "checkbox",
      //         label: "Disabled",
      //         name: "disabled",
      //       },
      //       { at: 3 }
      //     );
      //   }
      // }
      // .log("here=======")
      // adding & filtering traits only for: TEXT input component
      if (ele.attributes.attributes.type === "text") {
        // check if traits are already present, if not then .addtrait, otherwise show the already present ones
        let setNewProp2 = true;
        const traits = ele.get("traits");
        traits.forEach((trait) => {
          const curProps = trait.props();
          // .log('props:', curProps);
          if (
            curProps.label === "Input type" ||
            curProps.label === "Required" ||
            curProps.label === "Disabled" ||
            curProps.label === "Auto-Complete" ||
            curProps.label === "Auto-Focus"
          ) {
            setNewProp2 = false;
          }
        });

        if (setNewProp2 !== false) {
          ele.addTrait({ name: "name" }, { at: 0 });

          ele.addTrait({ name: "placeholder" }, { at: 1 });

          ele.addTrait(
            {
              type: "select", // Type of the trait
              label: "Input type", // The label you will see in Settings
              name: "type", // The name of the attribute/property to use on component
              options: [
                { id: "text", name: "Text" },
                { id: "url", name: "URL" },
                { id: "email", name: "Email" },
                { id: "password", name: "Password" },
                { id: "hidden", name: "Hidden" },
                { id: "number", name: "Number" },
              ],
            },
            { at: 2 }
          );

          ele.addTrait(
            {
              type: "checkbox",
              label: "Required",
              name: "required",
            },
            { at: 3 }
          );

          ele.addTrait(
            {
              type: "checkbox",
              label: "Disabled",
              name: "disabled",
            },
            { at: 4 }
          );

          ele.addTrait(
            {
              type: "checkbox",
              label: "Auto-Complete",
              name: "autocomplete",
            },
            { at: 5 }
          );

          ele.addTrait(
            {
              type: "checkbox",
              label: "Auto-Focus",
              name: "autofocus",
            },
            { at: 6 }
          );
        }
      }

      // adding & filtering traits only for: EMAIL, PWD, TEL, URL input component
      if (
        ele.attributes.attributes.type === "email" ||
        ele.attributes.attributes.type === "password" ||
        ele.attributes.attributes.type === "tel" ||
        ele.attributes.attributes.type === "url"
      ) {
        // check if traits are already present, if not then .addtrait, otherwise show the already present ones
        let setNewProp3 = true;
        const traits = ele.get("traits");
        traits.forEach((trait) => {
          const curProps = trait.props();
          // .log('props:', curProps);
          if (
            curProps.label === "Required" ||
            curProps.label === "Disabled" ||
            curProps.label === "Auto-Complete" ||
            curProps.label === "Auto-Focus"
          ) {
            setNewProp3 = false;
          }
        });

        if (setNewProp3 !== false) {
          ele.addTrait({ name: "name" }, { at: 0 });

          ele.addTrait({ name: "placeholder" }, { at: 1 });

          ele.addTrait(
            {
              type: "checkbox",
              label: "Required",
              name: "required",
            },
            { at: 2 }
          );

          ele.addTrait(
            {
              type: "checkbox",
              label: "Disabled",
              name: "disabled",
            },
            { at: 3 }
          );

          ele.addTrait(
            {
              type: "checkbox",
              label: "Auto-Complete",
              name: "autocomplete",
            },
            { at: 4 }
          );

          ele.addTrait(
            {
              type: "checkbox",
              label: "Auto-Focus",
              name: "autofocus",
            },
            { at: 5 }
          );
        }
      }

      // adding & filtering traits only for: COLOR, DATE, WEEK, MONTH, TIME input component
      if (
        ele.attributes.attributes.type === "color" ||
        ele.attributes.attributes.type === "date" ||
        ele.attributes.attributes.type === "week" ||
        ele.attributes.attributes.type === "month" ||
        ele.attributes.attributes.type === "time" ||
        ele.attributes.attributes.type === "datetime-local"
      ) {
        // check if traits are already present, if not then .addtrait, otherwise show the already present ones
        let setNewProp4 = true;
        const traits = ele.get("traits");
        traits.forEach((trait) => {
          const curProps = trait.props();
          // .log('props:', curProps);
          if (curProps.label === "Required" || curProps.label === "Disabled") {
            setNewProp4 = false;
          }
        });

        if (setNewProp4 !== false) {
          ele.addTrait({ name: "name" }, { at: 0 });

          ele.addTrait(
            {
              type: "checkbox",
              label: "Required",
              name: "required",
            },
            { at: 1 }
          );

          ele.addTrait(
            {
              type: "checkbox",
              label: "Disabled",
              name: "disabled",
            },
            { at: 2 }
          );
        }
      }

      // adding & filtering traits only for: FILE input component
      if (ele.attributes.attributes.type === "file") {
        // check if traits are already present, if not then .addtrait, otherwise show the already present ones
        let setNewProp5 = true;
        const traits = ele.get("traits");
        traits.forEach((trait) => {
          const curProps = trait.props();
          // .log('props:', curProps);
          if (
            curProps.label === "Required" ||
            curProps.label === "Disabled" ||
            curProps.label === "Specific file type" ||
            curProps.label === "Accept multiple files"
          ) {
            setNewProp5 = false;
          }
        });

        if (setNewProp5 !== false) {
          ele.addTrait({ name: "name" }, { at: 0 });

          ele.addTrait(
            {
              type: "checkbox",
              label: "Required",
              name: "required",
            },
            { at: 1 }
          );

          ele.addTrait(
            {
              type: "checkbox",
              label: "Disabled",
              name: "disabled",
            },
            { at: 2 }
          );

          ele.addTrait(
            {
              // list of common extensions: https://www.computerhope.com/issues/ch001789.htm
              type: "select", // Type of the trait
              label: "Specific file type", // The label you will see in Settings
              name: "accept", // The name of the attribute/property to use on component
              options: [
                { id: "*", name: "Any file" },
                { id: "audio/*", name: "Audio file" },
                { id: "video/*", name: "Video file" },
                { id: "image/*", name: "Image file" },
                {
                  id: ".doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                  name: "MS Word Document",
                },
                { id: ".txt", name: "Text file" },
                { id: ".pdf", name: "PDF file" },
                { id: ".gif", name: "GIF file" },
                { id: ".ico", name: "Icon file" },
                {
                  id: ".7z, .arj, .deb, .pkg, .rar, .rpm, .tar.gz, .tar, .z, .zip",
                  name: "Compressed file",
                },
                { id: ".html", name: "HTML file" },
                { id: ".css", name: "CSS file" },
                { id: ".scss", name: "SCSS file" },
                { id: ".js", name: "Javascript file" },
              ],
            },
            { at: 3 }
          );

          ele.addTrait(
            {
              type: "checkbox",
              label: "Accept multiple files",
              name: "multiple",
            },
            { at: 4 }
          );
        }
      }

      // adding & filtering traits only for: NUMBER input component
      if (
        ele.attributes.attributes.type === "number" ||
        ele.attributes.attributes.type === "Number"
      ) {
        // check if traits are already present, if not then .addtrait, otherwise show the already present ones
        let setNewProp6 = true;
        const traits = ele.get("traits");
        traits.forEach((trait) => {
          const curProps = trait.props();
          // .log('props:', curProps);
          if (
            curProps.label === "Required" ||
            curProps.label === "Disabled" ||
            curProps.label === "Maximum limit (number)" ||
            curProps.label === "Minimum limit (number)"
          ) {
            setNewProp6 = false;
          }
        });

        if (setNewProp6 !== false) {
          ele.addTrait({ name: "name" }, { at: 0 });

          ele.addTrait({ name: "placeholder" }, { at: 1 });

          ele.addTrait(
            {
              type: "checkbox",
              label: "Required",
              name: "required",
            },
            { at: 2 }
          );

          ele.addTrait(
            {
              type: "checkbox",
              label: "Disabled",
              name: "disabled",
            },
            { at: 3 }
          );

          ele.addTrait(
            {
              type: "number",
              label: "Maximum limit (number)",
              name: "max",
            },
            { at: 4 }
          );

          ele.addTrait(
            {
              type: "number",
              label: "Minimum limit (number)",
              name: "min",
            },
            { at: 5 }
          );
        }
      }

      // adding & filtering traits only for: RANGE input component
      if (ele.attributes.attributes.type === "range") {
        // check if traits are already present, if not then .addtrait, otherwise show the already present ones
        let setNewProp7 = true;
        const traits = ele.get("traits");
        traits.forEach((trait) => {
          const curProps = trait.props();
          // .log('props:', curProps);
          if (
            curProps.label === "Required" ||
            curProps.label === "Disabled" ||
            curProps.label === "Maximum limit (number)" ||
            curProps.label === "Minimum limit (number)"
          ) {
            setNewProp7 = false;
          }
        });

        if (setNewProp7 !== false) {
          ele.addTrait({ name: "name" }, { at: 0 });

          ele.addTrait(
            {
              type: "checkbox",
              label: "Required",
              name: "required",
            },
            { at: 1 }
          );

          ele.addTrait(
            {
              type: "checkbox",
              label: "Disabled",
              name: "disabled",
            },
            { at: 2 }
          );

          ele.addTrait(
            {
              type: "number",
              label: "Maximum limit (number)",
              name: "max",
            },
            { at: 3 }
          );

          ele.addTrait(
            {
              type: "number",
              label: "Minimum limit (number)",
              name: "min",
            },
            { at: 4 }
          );
        }
      }

      // // adding traits individually
      // ele.addTrait({ name: 'name' }, { at: 0 });

      // ele.addTrait({ name: 'placeholder' }, { at: 1 });

      // ele.addTrait({
      //     type: 'select', // Type of the trait
      //     label: 'Input type', // The label you will see in Settings
      //     name: 'type', // The name of the attribute/property to use on component
      //     options: [
      //         { id: 'text', name: 'Text' },
      //         { id: 'url', name: 'URL' },
      //         { id: 'email', name: 'Email' },
      //         { id: 'password', name: 'Password' },
      //         { id: 'hidden', name: 'Hidden' },
      //         { id: 'number', name: 'Number' },
      //     ]
      // }, { at: 2 });

      // ele.addTrait({
      //     type: 'text',
      //     label: 'Value',
      //     name: 'value',
      // }, { at: 3 });

      // ele.addTrait({
      //     type: 'checkbox',
      //     label: 'Required',
      //     name: 'required',
      // }, { at: 4 });

      // ele.addTrait({
      //     type: 'checkbox',
      //     label: 'Checked',
      //     name: 'checked',
      // }, { at: 5 });

      // ele.addTrait({
      //     type: 'checkbox',
      //     label: 'Auto-Complete',
      //     name: 'autocomplete',
      // }, { at: 6 });

      // ele.addTrait({
      //     type: 'checkbox',
      //     label: 'Auto-Focus',
      //     name: 'autofocus',
      // }, { at: 7 });

      // ele.addTrait({
      //     type: 'checkbox',
      //     label: 'Disabled',
      //     name: 'disabled',
      // }, { at: 8 });

      // ele.addTrait({
      //     // list of common extensions: https://www.computerhope.com/issues/ch001789.htm
      //     type: 'select', // Type of the trait
      //     label: 'Specific file type', // The label you will see in Settings
      //     name: 'accept', // The name of the attribute/property to use on component
      //     options: [
      //         { id: '*', name: 'Any file' },
      //         { id: 'audio/*', name: 'Audio file' },
      //         { id: 'video/*', name: 'Video file' },
      //         { id: 'image/*', name: 'Image file' },
      //         { id: '.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document', name: 'MS Word Document' },
      //         { id: '.txt', name: 'Text file' },
      //         { id: '.pdf', name: 'PDF file' },
      //         { id: '.gif', name: 'GIF file' },
      //         { id: '.ico', name: 'Icon file' },
      //         { id: '.7z, .arj, .deb, .pkg, .rar, .rpm, .tar.gz, .tar, .z, .zip', name: 'Compressed file' },
      //         { id: '.html', name: 'HTML file' },
      //         { id: '.css', name: 'CSS file' },
      //         { id: '.scss', name: 'SCSS file' },
      //         { id: '.js', name: 'Javascript file' },
      //     ]
      // }, { at: 9 });

      // ele.addTrait({
      //     type: 'checkbox',
      //     label: 'Accept multiple files',
      //     name: 'multiple',
      // }, { at: 10 });

      // ele.addTrait({
      //     type: 'number',
      //     label: 'Maximum limit (number)',
      //     name: 'max',
      // }, { at: 11 });

      // ele.addTrait({
      //     type: 'number',
      //     label: 'Minimum limit (number)',
      //     name: 'min',
      // }, { at: 12 });
    }

    if (ele.attributes.editable === false) {
      // .log("inside if before:::::::::::::::", ele.attributes.editable);
      Object.assign(ele.attributes, {
        // ...ele.attributes,
        removable: true,
        type: "div",
        draggable: true,
        badgable: true,
        stylable: true,
        highlightable: true,
        selectable: true,
        copyable: false,
        resizable: true,
        editable: true,
        hoverable: true,
        propagate: [
          "removable",
          "draggable",
          "editable",
          "resizable",
          "highlightable",
          "stylable",
          "tagName",
          "type",
          "selectable",
        ],
        tagName: "div",
        // attributes: { class: 'btn gjs-button' },
      });
      // .log("inside if after:::::::::::::::", ele.attributes.editable);
      // .log('ele.attributes.element:', ele.attributes);
      // Object.assign(key, true)
    }

    // if (ele.attributes.tagName === 'select') {
    //     // check if traits are already present, if not then .addtrait, otherwise show the already present ones
    //     let setNewProp8 = true;
    //     const traits = ele.get('traits');
    //     traits.forEach(trait => {
    //         const curProps = trait.props();
    //         //  .log('props:', curProps);
    //         if (curProps.label === 'Auto-Focus' || curProps.label === 'Required' || curProps.label === 'Accept multiple options' || curProps.label === 'Disabled' || curProps.label === 'Number of visible options in drop-down list') {
    //             setNewProp8 = false;
    //         }
    //     });

    //     if (setNewProp8 !== false) {
    //         ele.addTrait({ name: 'name' }, { at: 0 });

    //         ele.addTrait({
    //             type: 'checkbox',
    //             label: 'Auto-Focus',
    //             name: 'autofocus',
    //         }, { at: 1 });

    //         ele.addTrait({
    //             type: 'checkbox',
    //             label: 'Disabled',
    //             name: 'disabled',
    //         }, { at: 2 });

    //         ele.addTrait({
    //             type: 'checkbox',
    //             label: 'Accept multiple options',
    //             name: 'multiple',
    //         }, { at: 3 });

    //         ele.addTrait({
    //             type: 'checkbox',
    //             label: 'Required',
    //             name: 'required',
    //         }, { at: 4 });

    //         ele.addTrait({
    //             type: 'number',
    //             label: 'Number of visible options in drop-down list',
    //             name: 'size',
    //         }, { at: 5 });

    //         // ele.addTrait({
    //         //     type: 'text',
    //         //     name: 'Name',
    //         //     changeProp: true,
    //         // }, { at: 6 });

    //         // ele.addTrait({
    //         //     type: 'text',
    //         //     name: 'REST_API_URL',
    //         //     changeProp: true,
    //         // }, { at: 7 });

    //         // ele.addTrait({
    //         //     type: 'text',
    //         //     name: 'Response_Key_To_Be_Captured',
    //         //     changeProp: true,
    //         // }, { at: 8 });

    //         // ele.addTrait({
    //         //     type: 'text',
    //         //     name: 'Captured_Response_Key_Datatype',
    //         //     changeProp: true,
    //         // }, { at: 9 });

    //         // ele.addTrait({
    //         //     type: 'text',
    //         //     name: 'Dependent_On',
    //         //     changeProp: true,
    //         // }, { at: 10 });
    //     }
    // }

    if (ele.attributes.tagName === "option") {
      // check if traits are already present, if not then .addtrait, otherwise show the already present ones
      let setNewProp9 = true;
      const traits = ele.get("traits");
      traits.forEach((trait) => {
        const curProps = trait.props();
        // .log('props:', curProps);
        if (
          curProps.label === "Label" ||
          curProps.label === "Value" ||
          curProps.label === "Disabled" ||
          curProps.label === "Selected"
        ) {
          setNewProp9 = false;
        }
      });

      if (setNewProp9 !== false) {
        ele.addTrait({ name: "name" }, { at: 0 });

        ele.addTrait(
          {
            type: "text",
            label: "Label",
            name: "label",
          },
          { at: 1 }
        );

        ele.addTrait(
          {
            type: "text",
            label: "Value",
            name: "value",
          },
          { at: 2 }
        );

        ele.addTrait(
          {
            type: "checkbox",
            label: "Disabled",
            name: "disabled",
          },
          { at: 3 }
        );

        ele.addTrait(
          {
            type: "checkbox",
            label: "Selected",
            name: "selected",
          },
          { at: 4 }
        );
      }
    }

    if (
      ele.attributes.tagName === "form" &&
      ele.attributes.attributes.id === "basicForm"
    ) {
      // check if traits are already present, if not then .addtrait, otherwise show the already present ones
      let setNewProp10 = true;
      const traits = ele.get("traits");
      traits.forEach((trait) => {
        const curProps = trait.props();
        // .log('props:', curProps);
        if (
          curProps.label === "Action" ||
          curProps.label === "Form method" ||
          curProps.label === "Target" ||
          curProps.label === "Autocomplete" ||
          curProps.label === "Novalidate"
        ) {
          setNewProp10 = false;
        }
      });

      if (setNewProp10 !== false) {
        ele.addTrait({ name: "name" }, { at: 0 });

        ele.addTrait(
          {
            type: "text",
            label: "Action",
            name: "action",
          },
          { at: 1 }
        );

        ele.addTrait(
          {
            type: "select",
            label: "Form method",
            name: "method",
            options: [
              { id: "get", name: "GET" },
              { id: "post", name: "POST" },
            ],
          },
          { at: 2 }
        );

        ele.addTrait(
          {
            type: "select",
            label: "Target",
            name: "target",
            options: [
              { id: "_blank", name: "_blank" },
              { id: "_self", name: "_self" },
              { id: "_parent", name: "_parent" },
              { id: "_top", name: "_top" },
            ],
          },
          { at: 3 }
        );

        ele.addTrait(
          {
            type: "select",
            label: "Autocomplete",
            name: "autocomplete",
            options: [
              { id: "on", name: "on" },
              { id: "off", name: "off" },
            ],
          },
          { at: 4 }
        );

        ele.addTrait(
          {
            type: "checkbox",
            label: "Novalidate",
            name: "novalidate",
          },
          { at: 5 }
        );
      }
    }

    // if (ele.attributes.tagName === 'div' && ele.attributes.attributes['data-hovering'] === 'false') {
    // // check if traits are already present, if not then .addtrait, otherwise show the already present ones
    //     //console.log('inside dropdown--');
    //     let setNewProp11 = true;
    //     const traits = ele.get('traits');
    //     traits.forEach(trait => {
    //         const curProps = trait.props();
    //         //console.log('props:', curProps);
    //         if (curProps.label === 'Expand Dropdown') {
    //             setNewProp11 = false;
    //         }
    //     });

    // if (ele.attributes.tagName === 'div' && ele.attributes.attributes['data-hovering'] === 'false') {
    //     // check if traits are already present, if not then .addtrait, otherwise show the already present ones
    //     //console.log('inside dropdown--');
    //     let setNewProp11 = true;
    //     const traits = ele.get('traits');
    //     traits.forEach(trait => {
    //         const curProps = trait.props();
    //         //console.log('props:', curProps);
    //         if (curProps.label === 'Keep hovering') {
    //             setNewProp11 = false;
    //         }
    //     });

    //     if (setNewProp11 !== false) {
    //         ele.addTrait({ name: 'name' }, { at: 0 });

    //         ele.addTrait({
    //             type: 'select',
    //             label: 'Expand Dropdown',
    //             name: 'data-hovering',
    //             options: [
    //                 { id: 'true', name: 'True' },
    //                 { id: 'false', name: 'False' },
    //             ]
    //         }, { at: 1 });
    //     }
    // }

    if (
      ele.attributes.tagName === "div" &&
      ele.attributes.attributes["data-hovering"] === "false" &&
      ele.attributes.attributes["data-direction"] === "under"
    ) {
      // check if traits are already present, if not then .addtrait, otherwise show the already present ones
      //console.log('inside directional-dropdown--');
      let setNewProp12 = true;
      const traits = ele.get("traits");
      traits.forEach((trait) => {
        const curProps = trait.props();
        //console.log('props:', curProps);
        if (
          curProps.label === "Expand Dropdown" ||
          curProps.label === "Drop Direction"
        ) {
          setNewProp12 = false;
        }
      });

      if (setNewProp12 !== false) {
        ele.addTrait({ name: "name" }, { at: 0 });

        ele.addTrait(
          {
            type: "select",
            label: "Expand Dropdown",
            name: "data-hovering",
            options: [
              { id: "true", name: "True" },
              { id: "false", name: "False" },
            ],
          },
          { at: 1 }
        );

        ele.addTrait(
          {
            type: "select",
            label: "Drop Direction",
            name: "data-direction",
            options: [
              { id: "over", name: "Over" },
              { id: "under", name: "Under" },
              { id: "left", name: "Left" },
              { id: "right", name: "Right" },
            ],
          },
          { at: 2 }
        );
      }
    }

    if (ele.attributes.tagName === "ion-toggle") {
      // check if traits are already present, if not then .addtrait, otherwise show the already present ones
      let setNewProp13 = true;
      const traits = ele.get("traits");
      traits.forEach((trait) => {
        const curProps = trait.props();
        //console.log('props:', curProps);
        if (
          curProps.label === "Disabled" ||
          curProps.label === "Required" ||
          curProps.label === "Checked" ||
          curProps.label === "Enable On-Off Labels" ||
          curProps.label === "Theme"
        ) {
          setNewProp13 = false;
        }
      });

      if (setNewProp13 !== false) {
        ele.addTrait({ name: "name" }, { at: 0 });

        ele.addTrait(
          {
            type: "checkbox",
            label: "Disabled",
            name: "disabled",
          },
          { at: 1 }
        );

        ele.addTrait(
          {
            type: "checkbox",
            label: "Checked",
            name: "checked",
          },
          { at: 2 }
        );

        ele.addTrait(
          {
            type: "checkbox",
            label: "Required",
            name: "required",
          },
          { at: 3 }
        );

        ele.addTrait(
          {
            type: "checkbox",
            label: "Enable On-Off Labels",
            name: "enable-on-off-labels",
          },
          { at: 4 }
        );

        ele.addTrait(
          {
            type: "select",
            label: "Theme",
            name: "color",
            options: [
              { id: "primary", name: "Primary" },
              { id: "secondary", name: "Secondary" },
              { id: "tertiary", name: "Tertiary" },
              { id: "success", name: "Success" },
              { id: "warning", name: "Warning" },
              { id: "danger", name: "Danger" },
              { id: "light", name: "Light" },
              { id: "medium", name: "Medium" },
              { id: "dark", name: "Dark" },
            ],
          },
          { at: 5 }
        );
      }
    }

    if (ele.attributes.tagName === "ion-segment") {
      // check if traits are already present, if not then .addtrait, otherwise show the already present ones
      let setNewProp14 = true;
      const traits = ele.get("traits");
      traits.forEach((trait) => {
        const curProps = trait.props();
        //console.log('props:', curProps);
        if (
          curProps.label === "Disabled" ||
          curProps.label === "Theme" ||
          curProps.label === "Value" ||
          curProps.label === "Mode"
        ) {
          setNewProp14 = false;
        }
      });

      if (setNewProp14 !== false) {
        ele.addTrait({ name: "name" }, { at: 0 });

        ele.addTrait(
          {
            type: "select",
            label: "Disabled",
            name: "disabled",
            options: [
              { id: "true", name: "True" },
              { id: "false", name: "False" },
            ],
          },
          { at: 1 }
        );

        ele.addTrait(
          {
            type: "select",
            label: "Theme",
            name: "value",
            options: [
              { id: "default", name: "Default" },
              { id: "custom", name: "Custom" },
              { id: "segment", name: "Segment" },
              { id: "primary", name: "Primary" },
              { id: "secondary", name: "Secondary" },
              { id: "tertiary", name: "Tertiary" },
              { id: "success", name: "Success" },
              { id: "warning", name: "Warning" },
              { id: "danger", name: "Danger" },
              { id: "light", name: "Light" },
              { id: "medium", name: "Medium" },
              { id: "dark", name: "Dark" },
            ],
          },
          { at: 2 }
        );

        ele.addTrait(
          {
            type: "text",
            label: "Value",
            name: "value",
          },
          { at: 3 }
        );

        ele.addTrait(
          {
            type: "select",
            label: "Mode",
            name: "mode",
            options: [
              { id: "ios", name: "IOS" },
              { id: "md", name: "Android" },
            ],
          },
          { at: 4 }
        );
      }
    }

    if (ele.attributes.tagName === "ion-segment-button") {
      // check if traits are already present, if not then .addtrait, otherwise show the already present ones
      let setNewProp15 = true;
      const traits = ele.get("traits");
      traits.forEach((trait) => {
        const curProps = trait.props();
        //console.log('props:', curProps);
        if (
          curProps.label === "Button Interaction" ||
          curProps.label === "Theme" ||
          curProps.label === "Value"
        ) {
          setNewProp15 = false;
        }
      });

      if (setNewProp15 !== false) {
        ele.addTrait({ name: "name" }, { at: 0 });

        // ele.addTrait({
        //     type: 'select',
        //     label: 'Button Interaction',
        //     name: 'value',
        //     options: [
        //         { id: 'default', name: 'Default' },
        //         { id: 'segment', name: 'Segment' },
        //         { id: 'disabled', name: 'Disabled' },
        //     ]
        // }, { at: 1 });

        ele.addTrait(
          {
            type: "select",
            label: "Theme",
            name: "value",
            options: [
              { id: "default", name: "Default" },
              { id: "segment", name: "Segment" },
              { id: "primary", name: "Primary" },
              { id: "secondary", name: "Secondary" },
              { id: "tertiary", name: "Tertiary" },
              { id: "success", name: "Success" },
              { id: "warning", name: "Warning" },
              { id: "danger", name: "Danger" },
              { id: "light", name: "Light" },
              { id: "medium", name: "Medium" },
              { id: "dark", name: "Dark" },
            ],
          },
          { at: 1 }
        );

        ele.addTrait(
          {
            type: "text",
            label: "Value",
            name: "value",
          },
          { at: 2 }
        );
      }
    }

    // if (ele.attributes.tagName === "label") {
    //   // check if traits are already present, if not then .addtrait, otherwise show the already present ones
    //   let setNewProp9 = true;
    //   const traits = ele.get("traits");
    //   traits.forEach((trait) => {
    //     const curProps = trait.props();
    //     //console.log('props:', curProps);
    //     if (curProps.label === "name") {
    //       setNewProp9 = false;
    //     }
    //   });

    //   if (setNewProp9 !== false) {
    //     ele.addTrait(
    //       {
    //         type: "text",
    //         label: "name",
    //         name: "Name",
    //       },
    //       { at: 0 }
    //     );
    //   }
    // }

    // adding REST API traits for all input elements inside REST API form
    // if (ele.attributes.tagName === 'input' || ele.attributes.tagName === 'select' && ele.parent().parent().parent().attributes.attributes.id.startsWith('ft-')) {
    //     console.log('>>> parent is rest api form');

    //     let setNewProp16 = true;
    //     const traits = ele.get('traits');
    //     traits.forEach(trait => {
    //         const curProps = trait.props();
    //         console.log('props:', curProps);
    //         if (curProps.label === 'REST API URL' || curProps.label === 'Name' || curProps.label === 'Dependent On') {
    //             setNewProp16 = false;
    //         }
    //     });

    //     if (setNewProp16 !== false) {
    //         ele.addTrait({
    //             type: 'text',
    //             label: 'REST API URL',
    //             name: 'data-value',
    //         }, { at: 1 });

    //         ele.addTrait({
    //             type: 'select',
    //             label: 'Dependent On',
    //             name: 'name',
    //         }, { at: 2 });
    //     }
    // }

    // ele.addTrait({
    //     name: 'name',
    // }, { at: 0 });
  });
}
