import axios from "axios";
import { toast } from "react-toastify";
import { API_HOST } from "../../api_utils";
import { LOAD_FUNCTIONS_FAILED, LOAD_FUNCTIONS_LOADING, LOAD_FUNCTIONS_SUCCESS } from "../Constants/functionsConstants";
const errorTheme = {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
}


export const getAllFunctionsOfProject  = (projectId) => async (dispatch, getState) => {
    try {
        dispatch({ type: LOAD_FUNCTIONS_LOADING })
        const { userLogin: { userInfo } } = getState()
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const reqData = {
            "user": userInfo,
            "project": projectId
        }

        const { data } = await axios.post(`${API_HOST}functions`, reqData, config)
        dispatch({ type: LOAD_FUNCTIONS_SUCCESS, payload: data })
        toast.success(`Functions Loaded Successfully `, errorTheme)

        // data.map(item => {
        //     console.log(item.execute[0])
        //     const to_execute = item.execute[0]
        //     switch(item.execute[0].id.name) {
        //         case "sum":
        //             console.log("sum")
        //             const params = to_execute.parameters.map(par => {
        //                 return  par.currentValue;
        //             })
        //             let sum = 0;
        //             params.map(it => {
        //                 console.log(it)
        //                 sum = sum + Number(it);
        //                 console.log(sum)
        //                 return sum;
        //             })

        //             // console.log(sum)


        //             // console.log(params)

        //         default: 
        //             console.log("none")
        //     }   
        // })


    } catch (error) {
        dispatch({ type: LOAD_FUNCTIONS_FAILED, payload: 'Load functions failed' })
        toast.error(`something went wrong`, errorTheme)
    }
}