import { useState } from 'react'
import { useDrop } from 'react-dnd'
import { ItemTypes } from '../LogicBuilder4/ItemTypes.js'
import { NewCanvas1 } from './NewCanvas1.js'
import { NewCanvas3 } from './NewCanvas3.js'
import { NewCanvas4 } from './NewCanvas4Return.js'
function getStyle(backgroundColor) {
  return {
    border: '1px solid rgba(0,0,0,0.2)',
    minHeight: '2rem',
    minWidth: '8rem',
    color: 'white',
    backgroundColor,
    padding: '2rem',
    paddingTop: '1rem',
 //   margin: '1rem',
    textAlign: 'center',
    // float: 'left',
    fontSize: '1rem',
  }
}
export const NewCanvas2 = ({ greedy, children, name, prevItem, isParam, setParametersDropped, setConditionDropped, setExecutionItemsDropped }) => {
  const [hasDropped, setHasDropped] = useState(false)
  const [hasDroppedOnChild, setHasDroppedOnChild] = useState(false)
  const [items, addItems] = useState([])
  const [textValue, setTextValue] = useState("")
  const [{ isOver, isOverCurrent }, drop] = useDrop(
    () => ({
    //   accept: ["parameters", "conditional", "logicalOperator"],
      accept: ['blocks', ItemTypes.BOX, 'return'],
      drop: (_item, monitor) => {
        const didDrop = monitor.didDrop()
        // console.log("name - ", name)
        // console.log("Greeedy ", greedy)
        // console.log("Did drop  ", didDrop)
        // console.log("DROP RESULT ", _item)
        if (didDrop && !greedy) {
            // console.log("not greedy")
            // console.log("has Dropped ", hasDropped)
            // console.log("has Dropped on Children ", hasDroppedOnChild)
            return
        }
        // console.log("Setting has dropped to true")
        setHasDropped(true)
        // console.log("Setting has dropped on child to true")
        setHasDroppedOnChild(didDrop)
        // if(_item.name !== "Text box") {
            if(items.length > 0) {
                
                addItems(prevState => {
                        // console.log("Previtems ---- ", prevState)
                        let initItems = [...prevState]
                        if(setParametersDropped) {
                            console.log("Item lenght > 0 parameters dropped initItems ", initItems )
                            // setConditionDropped(prevItem, _item.name)
                            setParametersDropped(prevItem, [...initItems, _item.name])
                        } else if(setExecutionItemsDropped && _item.name!=="Text box" && _item.name==="if") {
                            console.log("Item lenght > 0 execution dropped initItems ", initItems )
                            console.log("Item lenght > 0 execution dropped prevItem ", prevItem )
                            console.log("Item lenght > 0 execution dropped _item.name ", _item.name )
                            // let newInitItems = initItems.remove("Block")
                            var index = initItems.indexOf("Block");
                            console.log("Index ==== ", index)
                            initItems.splice(index, 1);
                            // let newInitItems = initItems.splice("")
                            // console.log("NEWINITTT T====== ", newInitItems)
                            console.log("Initiiiii ------ ", initItems)
                            setExecutionItemsDropped(prevItem, [...initItems, _item.name])
                        }
                        return [...initItems, _item.name]
                    }
                )
            } else {
                // console.log("Zerroooo")
                addItems([_item.name])
                // console.log("New Canvas2 item === ", prevItem)
                if(setParametersDropped) {
                    console.log("Item lenght = 0 parameters dropped _item.name ", _item.name )
                    console.log("Item lenght = 0 parameters dropped prevItem ", prevItem )
                    setParametersDropped(prevItem, [_item.name])
                    // setConditionDropped(prevItem)
                } else if(setExecutionItemsDropped && _item.name!=="Text box") {
                    console.log("Item lenght = 0 execution dropped _item.name ", _item.name )
                    console.log("Item lenght = 0 execution dropped prevItem ", prevItem)
                    // setExecutionItemsDropped(prevItem, [_item.name])
                }
                
            }
        // } else {

        // }
        
      },
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        // didDrop: monitor.didDrop(),
        isOverCurrent: monitor.isOver({ shallow: true }),
      }),
    }),
    [greedy, hasDropped, hasDroppedOnChild, items.length],
  )
  const text = greedy ? 'greedy' : 'not greedy'
  let backgroundColor = 'rgba(0, 0, 0, .5)'
  if (isOverCurrent || (isOver && greedy)) {
    backgroundColor = 'darkgreen'
  }

  const setValue = (val) => {
    console.log('New Canvas2 set value ', val)
    if(setExecutionItemsDropped) {
        if(items.length > 0) {
            let initItems = [...items]
            let newItems = initItems.filter(e => e!== "Text box")
            setExecutionItemsDropped(prevItem, [...newItems, val])
        }
    }
  }

  
  const undo = () => {
        addItems(prevItems => {
            let poppedItem = prevItems.pop();
            return prevItems;
        })
  }
//   console.log("HAS Dropped on CHiled == ", hasDroppedOnChild)
//   console.log("Previtem ", prevItem)
  return (
    <div ref={drop} style={getStyle(backgroundColor)}>
      {/* text */}
      
      {items.length > 0 ? (
        items.map((item, i) => {
            if(item === "Text box") {
                return (
                    <div> 
                        <input type="text" value={textValue} 
                            onChange={(e) => setTextValue(e.target.value)} 
                            onBlur={(e) => setValue(e.target.value)}    
                        /> </div>
                )
            } else if(item === "Block" && isParam) {
                console.log("ISPARMAMAMMMAMAMMAM ------- ")
                return (
                    <div key={i}>
                      <NewCanvas3 prevItem={prevItem} setParametersDropped={setParametersDropped} /> 
                    </div>
                )
            } else if(item== "Block" && !isParam) {
                return (
                    <div key={i}>
                        {/* <NewCanvas3 prevItem={prevItem} setExecutionItemsDropped={setExecutionItemsDropped} /> */}
                        <NewCanvas3 prevItem={i} setExecutionItemsDropped={setExecutionItemsDropped} />
                    </div>
                )
            }
            else if(item==="If") {

            }
            else if(item === "Return" && !isParam) {
                return (
                  <div key={i} >
                  
                      {/* <NewCanvas3 prevItem={prevItem} setExecutionItemsDropped={setExecutionItemsDropped} /> */}
                      <NewCanvas4 prevItem={i} setExecutionItemsDropped={setExecutionItemsDropped} />
                  </div>
              )
            }
            else {
                console.log("IF????????????========= ")
                return (
                    <div key={i}>
                    <div  style={{display: "flex"}}> {item} 
                    ( <NewCanvas2 prevItem={i} setConditionDropped={setConditionDropped}  /> ) <div style={{ fontSize: "12px" }}> Delete </div> </div>
                         <NewCanvas2 prevItem={i} setExecutionItemsDropped={setExecutionItemsDropped} />
                    </div>
                )
            }
        })) : ""
    }
      <br />
      {/* 
        Mainly used if you want to show that item has been dropped in a child. 
        This for the first one in the documentation */}
      {/* hasDropped && <p>dropped {hasDroppedOnChild && ' on child'}</p> */}

      <div>{children}</div>
    </div>
  )
}
