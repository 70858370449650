import { GET_ALL_MODAL_TABLE_EMPTY, GET_ALL_MODAL_TABLE_ERROR, GET_ALL_MODAL_TABLE_LOADING, GET_ALL_MODAL_TABLE_SUCCESS, UPDATE_MODAL_TABLE_ERROR, UPDATE_MODAL_TABLE_LOADING, UPDATE_MODAL_TABLE_SUCCESS } from "../Constants/modalTableConstants"



export const getAllModalReducer = (state = { emptPage: '' }, action) => {
    switch (action.type) {
        case GET_ALL_MODAL_TABLE_LOADING:
            return { loading: true }
        case GET_ALL_MODAL_TABLE_SUCCESS:
            return { loading: false, allModalData: action.payload }
        case GET_ALL_MODAL_TABLE_ERROR:
            return { loading: false, error: action.payload }

        default:
            return state;
    }
}


export const updateAllModalReducer = (state = {}, action) => {
    switch (action.type) {
        case UPDATE_MODAL_TABLE_LOADING:
            return { loading: true }
        case UPDATE_MODAL_TABLE_SUCCESS:
            return { loading: false, updatedModalData: action.payload }
        case UPDATE_MODAL_TABLE_ERROR:
            return { loading: false, error: action.payload }
        default:
            return state;
    }
}