import React,{useEffect} from 'react'
import 'bootstrap/dist/css/bootstrap.css';

function LoginNew() {


    useEffect(() => {
        const script1 = document.createElement('script');
        script1.src = '/assets/js/jquery-3.5.1.min.js';
        script1.async = true;
        document.body.appendChild(script1);
    
        const script2 = document.createElement('script');
        script2.src = '/assets/js/bootstrap/popper.min.js';
        script2.async = true;
        document.body.appendChild(script2);
    
        const script3 = document.createElement('script');
        script3.src = '/assets/js/bootstrap/bootstrap.js';
        script3.async = true;
        document.body.appendChild(script3);
    
        const script4 = document.createElement('script');
        script4.src = '/assets/js/icons/feather-icon/feather.min.js';
        script4.async = true;
        document.body.appendChild(script4);
    
        const script5 = document.createElement('script');
        script5.src = '/assets/js/icons/feather-icon/feather-icon.js';
        script5.async = true;
        document.body.appendChild(script5);
    
        const script6 = document.createElement('script');
        script6.src = '/assets/js/sidebar-menu.js';
        script6.async = true;
        document.body.appendChild(script6);
    
        const script7 = document.createElement('script');
        script7.src = '/assets/js/config.js';
        script7.async = true;
        document.body.appendChild(script7);
    
        const script8 = document.createElement('script');
        script8.src = '/assets/js/login.js';
        script8.async = true;
        document.body.appendChild(script8);
    
        const script9 = document.createElement('script');
        script9.src = '/assets/js/script.js';
        script9.async = true;
        document.body.appendChild(script9);
    
        return () => {
          // Cleanup the dynamically added scripts
          document.body.removeChild(script1);
          document.body.removeChild(script2);
          document.body.removeChild(script3);
          document.body.removeChild(script4);
          document.body.removeChild(script5);
          document.body.removeChild(script6);
          document.body.removeChild(script7);
          document.body.removeChild(script8);
          document.body.removeChild(script9);
        };
      }, []);


  return (
    <div className="page-wrapper">
    <div className="container-fluid p-0">
      <div className="authentication-main">
        <div className="row">
          <div className="col-md-12">
            <div className="auth-innerright">
              <div className="authentication-box">
                <div className="mt-4">
                  <div className="card-body">
                    <div className="cont text-center">
                      <div>
                        <form className="theme-form">
                          <h4>LOGIN</h4>
                          <h6>Enter your Username and Password</h6>
                          <div className="form-group">
                            <label className="col-form-label pt-0">Your Name</label>
                            <input className="form-control" type="text" required="" />
                          </div>
                          <div className="form-group">
                            <label className="col-form-label">Password</label>
                            <input className="form-control" type="password" required="" />
                          </div>
                          <div className="checkbox p-0">
                            <input id="checkbox1" type="checkbox" />
                            <label htmlFor="checkbox1">Remember me</label>
                          </div>
                          <div className="form-group form-row mt-3 mb-0">
                            <button className="btn btn-primary btn-block" type="submit">LOGIN</button>
                          </div>
                          <div className="login-divider"></div>
                          <div className="social mt-3">
                            <div className="form-row btn-showcase">
                              <div className="col-md-4 col-sm-6">
                                <button className="btn social-btn btn-fb">Facebook</button>
                              </div>
                              <div className="col-md-4 col-sm-6">
                                <button className="btn social-btn btn-twitter">Twitter</button>
                              </div>
                              <div className="col-md-4 col-sm-6">
                                <button className="btn social-btn btn-google">Google + </button>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                      <div className="sub-cont">
                        <div className="img">
                          <div className="img__text m--up">
                            <h2>New User?</h2>
                            <p>Sign up and discover a great amount of new opportunities!</p>
                          </div>
                          <div className="img__text m--in">
                            <h2>One of us?</h2>
                            <p>If you already have an account, just sign in. We've missed you!</p>
                          </div>
                          <div className="img__btn"><span className="m--up">Sign up</span><span className="m--in">Sign in</span></div>
                        </div>
                        <div>
                          <form className="theme-form">
                            <h4 className="text-center">NEW USER</h4>
                            <h6 className="text-center">Enter your Username and Password For Signup</h6>
                            <div className="form-row">
                              <div className="col-md-12">
                                <div className="form-group">
                                  <input className="form-control" type="text" placeholder="First Name" />
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="form-group">
                                  <input className="form-control" type="text" placeholder="Last Name" />
                                </div>
                              </div>
                            </div>
                            <div className="form-group">
                              <input className="form-control" type="text" placeholder="User Name" />
                            </div>
                            <div className="form-group">
                              <input className="form-control" type="password" placeholder="Password" />
                            </div>
                            <div className="form-row">
                              <div className="col-sm-4">
                                <button className="btn btn-primary" type="submit">Sign Up</button>
                              </div>
                              <div className="col-sm-8">
                                <div className="text-left mt-2 m-l-20">Are you already a user? <a className="btn-link text-capitalize" href="login.html">Login</a></div>
                              </div>
                            </div>
                            <div className="form-divider"></div>
                            <div className="social mt-3">
                              <div className="form-row btn-showcase">
                                <div className="col-sm-4">
                                  <button className="btn social-btn btn-fb">Facebook</button>
                                </div>
                                <div className="col-sm-4">
                                  <button className="btn social-btn btn-twitter">Twitter</button>
                                </div>
                                <div className="col-sm-4">
                                  <button className="btn social-btn btn-google">Google +</button>
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  )
}

export default LoginNew