// LOGIN FUNCTIONALITY ----------------------------------------------------------------
import React, { useEffect, useState } from 'react';
import { loginUser } from '../Redux/Actions/userActions';
import { useDispatch, useSelector } from 'react-redux';
import redSlingLogo from './component_assets/images/redSlingLogo.png';
import darkBubble from './component_assets/images/darkBubble.png';
import lightBubble from './component_assets/images/lightBubble.png';
import vector from './component_assets/images/details_close.png';
// import vector from './component_assets/images/Vector.png';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import $ from 'jquery';

// SIGN UP FUNCTIONALITY --------------------------------------------------------------
import { registerUser } from '../Redux/Actions/userActions';
import { Button, Paper, TextField } from '@mui/material';
import { API_HOST } from '../api_utils';
import axios from 'axios';

import './style.css';

// import './component_assets/assets/css/fontawesome.css';
// import styles1 from './component_assets/assets/css/icofont.css';
// import styles2 from './component_assets/assets/css/themify.css';
// import styles3 from './component_assets/assets/css/flag-icon.css';
// import styles4 from './component_assets/assets/css/feather-icon.css';
// import './component_assets/assets/css/bootstrap.css';
// import styles5 from './component_assets/assets/css/style.css';
// import './component_assets/assets/css/color-1.css';
// import styles6 from './component_assets/assets/css/responsive.css';


import classes from './ScreensStylings/Login.module.css';


function Login() {
    // DOM MANIPULATION
    const [isLogin, setIsLogin] = useState(true);

    // LOGIN FUNCTIONALITY ----------------------------------------------------------------
    let dispatch = useDispatch();
    let navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [pass, setPass] = useState('');

    const userInfoFromStorage = JSON.parse(localStorage.getItem('userInfo'));
    // LOGIN FUNCTIONALITY ----------------------------------------------------------------

    // SIGN UP FUNCTIONALITY --------------------------------------------------------------
    const [newEmail, setNewEmail] = useState('')
    const [newName, setNewName] = useState('')
    const [newPass, setNewPass] = useState('')
    const [repass, setRepass] = useState('')
    const [company, setCompany] = useState('')
    const { userInfo, loading, error } = useSelector(state => state.userLogin);
    // SIGN UP FUNCTIONALITY --------------------------------------------------------------

    const errorTheme = {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
    }

    // LOGIN FUNCTIONALITY ----------------------------------------------------------------
    useEffect(() => {
        if (userInfoFromStorage?.token) {
            navigate('/');
        }
    }, [userInfoFromStorage]);

    const loginHandler = (e) => {
        e.preventDefault();

        if (pass.length > 4 && email.includes('@')) {
            dispatch(loginUser({ email: email, password: pass }))
        } else if (pass.length < 4 && email.length > 1) {
            toast.error('password length should be greater than 4 !!!', errorTheme)
        } else if (pass.length > 4 && !email.includes('@')) {
            toast.error('please check the entered email !!!', errorTheme)
        } else if (!pass.length || !email.length) {
            toast.error("fields can't be empty !!!", errorTheme)
        }

        setPass('');
        setEmail('');
    };
    // LOGIN FUNCTIONALITY ----------------------------------------------------------------


    // SIGN UP FUNCTIONALITY --------------------------------------------------------------
    const signUpHandler = async (e) => {
        e.preventDefault()
        console.log('99\n', newEmail, newName, newPass, repass, company, userInfo, loading, error);
        if (newEmail.includes('@') && newPass.length && newPass === repass && newName.length && company.length) {
            const userData = {
                name: newName,
                email: newEmail,
                password: newPass,
                company: company,
            }

            const config = {
                headers: {
                    "Content-Type": "application/json",
                }
            }
            
            const data = await axios.post(`${API_HOST}user`, userData, config);
            toast.success("application sent !!!", errorTheme)
        } else if (!newEmail.includes('@') && newPass.length && newPass === repass && newName.length && company.length) {
            toast.error("email must include '@'", errorTheme)
        } else if (newPass !== repass) {
            toast.error("passwords do not match", errorTheme)
        } else if (!newName.length || !newEmail.length || !newPass.length || !repass.length || !company.length) {
            toast.error("fields can't be empty !!!", errorTheme)
        }

        setNewName('');
        setNewPass('');
        setNewEmail('');
        setRepass('');
        setCompany('');
    }

    useEffect(() => {
        if (userInfoFromStorage?.token) {
            navigate('/')
        }
    }, [userInfoFromStorage])
    // SIGN UP FUNCTIONALITY --------------------------------------------------------------


    // return (
    //     <div className={classes["login-body">

    //         <div className={classes["wrapperLogin">
    //             <nav>
    //                 <ul>
    //                     <li><a href="#" style={{color:'black',textDecoration:'none'}} target="_blank">About</a></li>
    //                     <li><a href="#" style={{color:'black',textDecoration:'none'}} target="_blank">Pricing</a></li>
    //                     <a href="#"><img src={redSlingLogo} /></a>
    //                     <li><a href="#" style={{color:'black',textDecoration:'none'}} target="_blank">Training</a></li>
    //                     <li><a href="#modal-btn" style={{color:'black',textDecoration:'none'}}>Contact Us</a></li>
    //                 </ul>
    //             </nav>
    //         </div>


    //         <a href="#"><img src={redSlingLogo} /></a>
    //         <div className={classes["bi bi-list other-hamburger-menu">
    //             <ul>
    //                 <li><a href="#"  target="_blank">About</a></li>
    //                 <li><a href="#" target="_blank">Pricing</a></li>
    //                 <li><a href="#" target="_blank">Training</a></li>
    //                 <li><a href="#modal-btn">Contact</a></li>
    //             </ul>
    //         </div>


    //         <img className={classes["login-d-bb" src={darkBubble} />



    //         <img className={classes["login-l-bb" src={lightBubble} />


    //         <div className={classes["login-wrapper-form">
    //             <form className={classes["login-form">
    //                 <img src={vector} alt="person-img" className={classes["vector" />
    //                 <input type="email" value={email} className={classes["emailInput" placeholder="Email" onChange={(e) => setEmail(e.target.value)} />
    //                 <input type="password" placeholder="Password" className={classes["emailInput" value={pass} onChange={(e) => setPass(e.target.value)} />
    //                 <button className={classes["login-button" type="submit" onClick={loginHandler}>Login</button>
    //                 <p className={classes["form-text">
    //                     New user? Sign up and build your website in minutes!
    //                     <a href="/signUp" style={{marginLeft:'0.5vw'}}>Sign up</a>
    //                 </p>
    //             </form>
    //         </div>
    //         <ToastContainer autoClose={2000} />
    //     </div>
    // );




    return (
        <div className={classes['login-wrapper']}>
            <div className={classes['form-card-container']}>
                <div className={classes['login-form-container']}>
                    
                    <form className={classes['login-form']} autocomplete="on">
                        <h5>LOGIN</h5>
                        <h6>Enter your Username and Password</h6>
                        <div className={classes["login-form-group"]}>
                            <label>Your Email</label>
                            <input autofocus className={classes["form-input"]} type="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
                        </div>

                        <div className={classes["login-form-group"]}>
                            <label>Your Password</label>
                            <input className={classes["form-input"]} type="password" value={pass} onChange={(e) => setPass(e.target.value)} required />
                        </div>
                        <button className={classes["login-form-btn"]} type="submit" onClick={loginHandler}>LOGIN</button>
                    </form>

                    <div className={!isLogin ? `${classes['signup-form-container']} ${classes['slide-left']}` : `${classes['signup-form-container']} ${classes['slide-right']}`}>
                        
                        <div className={classes['form-edge']}>
                            <div className={isLogin ? classes['stay-x'] : classes['move-left']}>
                                <span>
                                    <h5>New User?</h5>
                                    <p>Sign up and discover great amount of new opportunities!</p>
                                </span>

                                <span>
                                    <h5>One of us?</h5>
                                    <p>If you already have an account, just sign in. We've missed you!</p>
                                </span>
                            </div>

                            <button className={classes['form-edge-signup-btn']} onClick={() => setIsLogin(!isLogin)}>
                                <span className={isLogin ? classes['stay-y'] : classes['move-up']}>SIGN UP</span>
                                <span className={!isLogin ? classes['move-up'] : classes['stay-y']}>SIGN IN</span>
                            </button>
                        </div>

                        <form className={classes['signup-form']} autocomplete="on">
                            <h5>NEW USER</h5>
                            <h6>Enter your Username and Password For Signup</h6>
                            <input className={classes["form-input"]} type="text" placeholder="Enter your name" onChange={(e) => setNewName(e.target.value)} id="signup-email-field" value={newName} required />
                            <input className={classes["form-input"]} type="email" placeholder="Enter your email address" onChange={(e) => setNewEmail(e.target.value)} id="signup-email-field" value={newEmail} required />
                            <input className={classes["form-input"]} type="password" placeholder="Enter your password" onChange={(e) => setNewPass(e.target.value)} id="signup-password-field" value={newPass} required />
                            <input className={classes["form-input"]} type="password" placeholder="Confirm your password" onChange={(e) => setRepass(e.target.value)} id="signup-repassword-field" value={repass} required />
                            <input className={classes["form-input"]} type="text" placeholder="Enter an organization's name" onChange={(e) => setCompany(e.target.value)} id="signup-company-field" value={company} />
                            <button className={classes["signup-form-btn"]} type="submit" onClick={signUpHandler}>SIGN UP</button>
                        </form>

                    </div>

                </div>                
            </div>
        </div>
    );
}

export default Login;