import axios from "axios";
import { toast } from "react-toastify";
import { API_HOST } from "../../api_utils";
import { ASSIGN_VARIABLES, CREATE_MATH_FUNC, SAVE_MATH_FUNC, CREATE_NEW_FUNC_NAME, SAVE_NEW_FUNC } from "../Constants/createFunctionsConstants";
import { CLEAR_NEW_FUNC } from "../Constants/functionsConstants";
import { v4 as uuid } from 'uuid';

export const assignParameters = (projectId, data) => async(dispatch, getState) => {
    try {
        dispatch({
            type: ASSIGN_VARIABLES, 
            payload: data
        })
    }
    catch (err)  {
        toast({
            message: "Error"
        })
    }
}

export const assignFunctionName = (projectId, data) => async(dispatch, getState) => {
    try {
        dispatch({
            type: CREATE_NEW_FUNC_NAME,
            payload: data
        })
    }
    catch(err) {
        toast({
            message: "Assign FunctionName Error"
        })
    }
}

export const createMathFunction = (projectId, data) => async (dispatch, getState) => {
    try {
        console.log("---heree---")
        const unique_id = uuid();
        const small_id = unique_id.slice(0,8)
        let newData = {
            isMathFunction: true,
            name: data,
            refId: small_id 
        }
        dispatch({
            type: CREATE_MATH_FUNC,
            payload: newData
        })
        return small_id;
    } catch(err) {
        toast ({
            message: "Create math function error"
        })
    }
}

export const clearAll = () => async (dispatch, getState) => {
    console.log("CLEAR ALL FUNCCCCCCCCC")
    try {
        dispatch({
            type: CLEAR_NEW_FUNC,
        })
    }
    catch(err) {
        console.log("CLEAR ERRORRR ")
    }
}