import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { API_HOST } from "../../api_utils";

import {
  GET_ALL_PAGES_FAILED,
  GET_ALL_PAGES_LOADING,
  GET_ALL_PAGES_SUCCESS,
  GET_PAGE_BY_PROJECT_ID_LOADING,
  GET_PAGE_BY_PROJECT_ID_FAILED,
  GET_PAGE_BY_PROJECT_ID_SUCCESS,
  UPDATE_PAGE_LOADING,
  UPDATE_PAGE_SUCCESS,
  UPDATE_PAGE_FAILED,
  GET_ALL_PAGES_EMPTY,
  GET_PAGE_BY_PROJECT_ID_EMPTY,
  DELETE_PAGE_FAILED,
  DELETE_PAGE_SUCCESS,
  DELETE_PAGE_LOADING,
  COPY_PAGE_FAILED,
  COPY_PAGE_LOADING,
  COPY_PAGE_SUCCESS,
} from "../Constants/pageConstants";

const errorTheme = {
  position: "top-right",
  autoClose: 2000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "colored",
};

// DONE
export const getPageByProjectId = (projectId) => async (dispatch, getState) => {
  try {
    dispatch({ type: GET_PAGE_BY_PROJECT_ID_LOADING });
    const {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await axios.get(
      `${API_HOST}pages/projectPage/${projectId}`,
      config
    );
    //console.log("#@*$%#($ data:", data);
    dispatch({ type: GET_PAGE_BY_PROJECT_ID_SUCCESS, payload: data });
  } catch (error) {
    if (error.response.data.message) {
      dispatch({ type: GET_PAGE_BY_PROJECT_ID_EMPTY });
    } else {
      dispatch({
        type: GET_PAGE_BY_PROJECT_ID_FAILED,
        paylaod: error.response.data.message,
      });
    }
  }
};

// DONE
export const getAllPages = () => async (dispatch, getState) => {
  try {
    dispatch({ type: GET_ALL_PAGES_LOADING });
    const {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await axios.get(`${API_HOST}pages/`, config);
    dispatch({ type: GET_ALL_PAGES_SUCCESS, payload: data.pageDataArray });
  } catch (error) {
    if (error?.response?.data?.message === "No pages found") {
      dispatch({ type: GET_ALL_PAGES_EMPTY });
    } else {
      dispatch({
        type: GET_ALL_PAGES_FAILED,
        payload: error?.response?.data?.message,
      });
      toast.error(`${error?.response?.data?.message}`, errorTheme);
    }
  }
};

export const updatePage = (pageData) => async (dispatch, getState) => {
  try {
    dispatch({ type: UPDATE_PAGE_LOADING });

    const {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await axios.put(
      `${API_HOST}pages/${pageData.pageId}`,
      pageData,
      config
    );

    //console.log("data", data);

    dispatch({ type: UPDATE_PAGE_SUCCESS, payload: data });
    toast.success(`Page Successfully Updated`, errorTheme);
  } catch (error) {
    dispatch({ type: UPDATE_PAGE_FAILED, payload: "Something went wrong" });
    toast.error(`Something went wrong`, errorTheme);
  }
};

export const deletePage = (pageId) => async (dispatch, getState) => {
  try {
    dispatch({ type: DELETE_PAGE_LOADING });
    const {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await axios.delete(`${API_HOST}pages/${pageId}`, config);
    dispatch({ type: DELETE_PAGE_SUCCESS, payload: data });
    toast.success("page deleted successfully", errorTheme);
  } catch (error) {
    dispatch({ type: DELETE_PAGE_FAILED, error: "Something went wrong" });
    toast.error("something went wrong", errorTheme);
  }
};

export const copyPageAction = (pageData) => async (dispatch, getState) => {
  try {
    dispatch({ type: COPY_PAGE_LOADING });

    const {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await axios.post(
      `${API_HOST}pages/${pageData.pageId}`,
      pageData,
      config
    );

    if (!data.message) {
      dispatch({ type: COPY_PAGE_SUCCESS, payload: data });
      toast.success(`Page Successfully Copied`, errorTheme);
    } else {
      dispatch({ type: COPY_PAGE_FAILED, payload: `${data.message}` });
      toast.error(`${data.message}`, errorTheme);
    }
  } catch (error) {}
};
