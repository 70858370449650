import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  useCallback,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllProject } from "../../Redux/Actions/projectActions";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../Loader";
import { Button, Grid, Typography, Modal, TextField } from "@mui/material";
import darkBubble from "../component_assets/images/darkBubble.png";
// import darkBubble from './component_assets/images/details_close.png'
// import lightBubble from './component_assets/images/details_close.png'
import lightBubble from "../component_assets/images/lightBubble.png";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import "../style.css";
import DeleteIcon from "@mui/icons-material/Delete";
import RateReviewIcon from "@mui/icons-material/RateReview";
import BarChartIcon from "@mui/icons-material/BarChart";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import CloseIcon from "@mui/icons-material/Close";
import { updateProject } from "../../Redux/Actions/projectActions";
// import errorSymbol from './component_assets/images/details_close.png'
import errorSymbol from "../component_assets/images/errorSymbol.svg";
// import btnGrp from './component_assets/images/details_close.png'
import btnGrp from "../component_assets/images/btnGrpRed.svg";
// import errorWave from './component_assets/images/details_close.png'
import errorWave from "../component_assets/images/errorWave.svg";
import DownloadIcon from "@mui/icons-material/Download";
import { deleteProject } from "../../Redux/Actions/projectActions";

import WysiwygIcon from "@mui/icons-material/Wysiwyg";
import RouteIcon from "@mui/icons-material/Route";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { FiSearch } from "react-icons/fi";
import { IoClose } from "react-icons/io5";

import { API_HOST } from "../../api_utils";
import { HiTemplate } from "react-icons/hi";

function AllFragments() {
  let dispatch = useDispatch();
  let navigate = useNavigate();
  const { project } = useParams();
  const [loading, setLoading] = useState(false);
  const [emptyProject, setEmptyProject] = useState(false);

  const { userInfo } = useSelector((state) => state.userLogin);
  const [allFragmentData, setAllFragmentData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const searchFilterOptionsShowNickname = useRef(true);
  const [projectNameFilter, setProjectNameFilter] = useState("");
  const [selectedProjectName, setSelectedProjectName] = useState("");
  const [finalProjectNameToFilterData, setFinalProjectNameToFilterData] =
    useState("");
  const [open, setOpen] = useState(false);
  const [createFragmentPayload, setCreateFragmentPayload] = useState({
    fragment_name: "",
    project: "",
    fragment_content: "",
  });

  const [createFragmentSuccess, setCreateFragmentSuccess] = useState(false);
  const [deleteFragmentSuccess, setDeleteFragmentSuccess] = useState(false);

  const errorTheme = {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
  };

  const successTheme = {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
  };

  const getAllFragmentDataHandler = async () => {
    setLoading(true);
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const payload = {
      projectId: project,
    };

    const { data } = await axios.post(
      `${API_HOST}template_fragment/get_all_fragments`,
      payload,
      config
    );

    if (data.message) {
      setLoading(false);
      toast.warn(data.message);
    } else {
      setLoading(false);
      setAllFragmentData(data);
      setFilteredData(data);
    }
  };

  useEffect(() => {
    getAllFragmentDataHandler();

    return () => {
      setCreateFragmentSuccess(false);
      setDeleteFragmentSuccess(false);
      setLoading(false);
    };
  }, [createFragmentSuccess, deleteFragmentSuccess]);

  const deleteFragmentHandler = async (fragmentId) => {
    setLoading(true);
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.delete(
      `${API_HOST}template_fragment/${fragmentId}`,
      config
    );

    if (data.message === "Fragment deleted successfully!!!") {
      setLoading(false);
      toast.success(data.message);
      setDeleteFragmentSuccess(true);
    } else {
      setLoading(false);
      toast.error(data.message);
    }
  };

  const columns = [
    { field: "id", headerName: "ID", width: 100 },
    {
      field: "fragmentName",
      headerName: "Fragment Name",
      width: 150,
      editable: true,
    },
    {
      field: "createdBy",
      headerName: "Created By",
      width: 150,
      editable: true,
    },
    {
      field: "createdAt",
      headerName: "Created At",
      width: 150,
      editable: true,
    },
    {
      field: "updatedBy",
      headerName: "Updated By",
      width: 150,
      editable: true,
    },
    {
      field: "updatedAt",
      headerName: "Updated At",
      width: 150,
      editable: true,
    },
  ];

  columns.push({
    field: "Detail",
    headerName: "Detail",
    width: 70,
    renderCell: (params) => {
      return (
        <Typography
          onClick={() => {
            console.log("params", params);
            navigate(`/fragment_editor/${params.row.id}?projectId=${project}`);
          }}
          sx={{
            color: "#9333ea",
            "&:hover": { color: "#c084fc", scale: "125%" },
          }}>
          <RateReviewIcon fontSize="medium" />
        </Typography>
      );
    },
  });

  columns.push({
    field: "Actions",
    headerName: "",
    width: 60,
    renderCell: (params) => {
      return (
        <Typography
          onClick={(event) => {
            event.stopPropagation();

            deleteFragmentHandler(params.id);
          }}
          sx={{
            color: "#e11d48",
            "&:hover": { color: "#fb7185", scale: "125%" },
          }}>
          <DeleteIcon fontSize="medium" />
        </Typography>
      );
    },
  });

  const rows = filteredData
    ? filteredData.map((x) => {
        // console.log('x', x);
        return {
          id: x._id,
          fragmentName: x.fragment_name,
          createdBy: x.created_by !== "undefined" ? x.created_by : "---",
          createdAt: x.createdAt !== "undefined" ? x.createdAt : "---",
          updatedBy: x.updated_by !== "undefined" ? x.updated_by : "---",
          updatedAt: x.updated_at !== "undefined" ? x.updated_at : "---",
        };
      })
    : [
        {
          id: 1,
          projectName: "Snow",
          facebookId: "Jon",
          twitterId: "twitterId",
          linkedInId: "linkedInId",
          instagramId: "instagramId",
          analyticsCode: "analyticsCode",
        },
        {
          id: 2,
          projectName: "Snow",
          facebookId: "Jon",
          twitterId: "twitterId",
          linkedInId: "linkedInId",
          instagramId: "instagramId",
          analyticsCode: "analyticsCode",
        },
        {
          id: 3,
          projectName: "Snow",
          facebookId: "Jon",
          twitterId: "twitterId",
          linkedInId: "linkedInId",
          instagramId: "instagramId",
          analyticsCode: "analyticsCode",
        },
        {
          id: 4,
          projectName: "Snow",
          facebookId: "Jon",
          twitterId: "twitterId",
          linkedInId: "linkedInId",
          instagramId: "instagramId",
          analyticsCode: "analyticsCode",
        },
        {
          id: 5,
          projectName: "Snow",
          facebookId: "Jon",
          twitterId: "twitterId",
          linkedInId: "linkedInId",
          instagramId: "instagramId",
          analyticsCode: "analyticsCode",
        },
      ];

  const handleClose = () => {
    setOpen(false);
  };

  const createFragmentHandler = async () => {
    if (createFragmentPayload.fragment_name) {
      setLoading(true);
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.post(
        `${API_HOST}template_fragment/`,
        createFragmentPayload,
        config
      );

      if (data.message) {
        setLoading(false);
        toast.warn(data.message);
      } else {
        setLoading(false);
        setOpen(false);
        setCreateFragmentSuccess(true);
      }
    } else {
      toast.warn("Please Provide a name for fragment !!!");
    }
  };

  const handleCustomerNicknameChange = useCallback((e) => {
    setProjectNameFilter(e.target.value);
  }, []);

  const filteredNicknameOptions = useMemo(() => {
    if (projectNameFilter && !selectedProjectName) {
      const searchTerm = projectNameFilter.toLowerCase();
      const optionsStartingWithSearchTerm = [];

      allFragmentData.forEach((option) => {
        if (
          option.fragment_name &&
          option.fragment_name.toLowerCase().startsWith(searchTerm)
        ) {
          optionsStartingWithSearchTerm.push(option);
        }
      });

      optionsStartingWithSearchTerm.sort((a, b) =>
        a.fragment_name.localeCompare(b.fragment_name, undefined, {
          sensitivity: "base",
        })
      );

      return optionsStartingWithSearchTerm;
    } else {
      if (selectedProjectName) {
        setFinalProjectNameToFilterData(selectedProjectName);
        setSelectedProjectName("");

        searchFilterOptionsShowNickname.current = false;
      } else {
        searchFilterOptionsShowNickname.current = true;
      }

      return [];
    }
  }, [projectNameFilter, selectedProjectName]);

  useEffect(() => {
    if (finalProjectNameToFilterData) {
      let newFilteredData = allFragmentData.filter(
        (item) => item.fragment_name === finalProjectNameToFilterData
      );
      setFilteredData(newFilteredData);
    }

    return () => {
      setFinalProjectNameToFilterData("");
    };
  }, [finalProjectNameToFilterData]);

  return (
    <div className="">
      {loading ? (
        <Loader />
      ) : emptyProject || filteredData.length === 0 ? (
        <Grid
          sx={{
            height: "87.5vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            position: "relative",
            overflow: "hidden",
          }}>
          <Grid
            width={"80vw"}
            height={"80%"}
            sx={{
              padding: "2vh 0 2vh 3vw",
              borderRadius: "8px",
              backdropFilter: "blur(25px)",
              boxShadow: 20,
              zIndex: 20,
            }}>
            <Grid
              xs={12}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}>
              <Typography
                sx={{ fontFamily: "Mont-SemiBold", fontSize: "1.5rem" }}>
                Fragments Created
              </Typography>
              <Grid
                xs={9}
                sx={{ backgroundColor: "red", height: "0.6vh" }}></Grid>
            </Grid>
            <Grid sx={{ display: "flex", justifyContent: "center", mt: 6 }}>
              <img src={errorSymbol} style={{ height: "30vh" }} />
            </Grid>
            <Typography
              sx={{
                textAlign: "center",
                mt: 4,
                fontSize: "1.2rem",
                fontFamily: "Mont-SemiBold",
              }}>
              Sorry! No Fragments have been created yet.{" "}
            </Typography>
            <Grid sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
              <Button
                onClick={() => setOpen(true)}
                variant="contained"
                sx={{
                  backgroundColor: "white",
                  color: "black",
                  fontFamily: "Mont-Regular",
                }}>
                <span style={{ marginRight: "1vw" }}>Create New Fragment</span>
                <img src={btnGrp} style={{ height: "4vh" }} />
              </Button>
            </Grid>
          </Grid>
          <Grid sx={{ position: "absolute", bottom: "-80vh", right: "-20vw" }}>
            <img src={lightBubble} style={{ height: "160vh" }} />
          </Grid>
          <Grid sx={{ position: "absolute", top: "-12vh", right: "0" }}>
            <img src={errorWave} style={{ height: "180vh" }} />
          </Grid>
        </Grid>
      ) : (
        <Grid
          sx={{ position: "relative", overflow: "hidden", height: "100vh" }}>
          <Grid
            sx={{
              position: "absolute",
              top: "-12vh",
              left: "-30vw",
              zIndex: "-10",
            }}>
            <img style={{ width: "55vw", height: "90%" }} src={darkBubble} />
          </Grid>

          <Grid sx={{ position: "absolute", bottom: "-60%", right: "-15%" }}>
            <img style={{ width: "55vw", height: "90%" }} src={lightBubble} />
          </Grid>

          <Grid
            sx={{
              display: "flex",
              justifyContent: "center",
              paddingTop: "5vh",
            }}>
            <Box
              sx={{
                minHeight: "67vh",
                width: "90%",
                backdropFilter: "blur(25)",
                backgroundColor: "",
              }}>
              <Grid
                xs={12}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
                container>
                <Typography
                  sx={{ fontFamily: "Mont-SemiBold", fontSize: "1.8rem" }}>
                  Fragments Created
                </Typography>
                <Grid
                  xs={9}
                  sx={{ height: "1.5vh", backgroundColor: "red" }}
                  item></Grid>
              </Grid>
              <Grid
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  mt: 1,
                  marginBottom: "3vh",
                }}>
                <div style={{ position: "relative", width: "50%" }}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      borderRadius: "10px",
                      border: "1.5px solid #c1c4c8",
                      paddingLeft: "0.5rem",
                      backgroundColor: "white",
                    }}>
                    <FiSearch fontSize="x-large" />
                    <input
                      type="text"
                      value={projectNameFilter}
                      placeholder="Search By Fragment Name"
                      onInput={(e) => {
                        console.log("e.target::::::", e.target.value);
                        handleCustomerNicknameChange(e);
                      }}
                      style={{
                        outline: "none",
                        border: "none",
                        width: "80%",
                        paddingTop: "0.5rem",
                        paddingBottom: "0.5rem",
                        marginLeft: "0.5rem",
                      }}
                    />
                  </div>

                  {filteredNicknameOptions.length > 0 && (
                    <div
                      style={{ zIndex: 11, top: "3rem" }}
                      className={
                        searchFilterOptionsShowNickname.current
                          ? "position-absolute  lg-max-h-12rem  xl-max-h-12rem  2xl-max-h-13rem overflow-auto w-100 rounded-lg shadow d-block "
                          : "d-none"
                      }>
                      {filteredNicknameOptions.map((option, index) => (
                        <div
                          className="custom-search-filter"
                          onClick={() => {
                            setSelectedProjectName(option.fragment_name);
                            setProjectNameFilter(option.fragment_name);
                          }}
                          key={index}>
                          {option.fragment_name}
                        </div>
                      ))}
                    </div>
                  )}
                </div>

                <Button
                  onClick={() => setOpen(true)}
                  variant="contained"
                  sx={{
                    backgroundColor: "red",
                    color: "white",
                    fontFamily: "Mont-Regular",
                    "&:hover": { backgroundColor: "white", color: "red" },
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}>
                  <span style={{ marginRight: "1vw" }}>
                    Create New Fragment
                  </span>
                  <img src={btnGrp} style={{ height: "4vh" }} />
                </Button>
              </Grid>
              <DataGrid
                rows={rows}
                columns={columns}
                pageSize={10}
                // error={error}
                rowsPerPageOptions={[10]}
                checkboxSelection
                disableSelectionOnClick
                initialState={{
                  sorting: {
                    sortModel: [{ field: "lastEdit", sort: "desc" }],
                  },
                }}
              />
            </Box>
          </Grid>
        </Grid>
      )}

      <Modal
        open={open}
        onClose={handleClose}
        sx={{
          border: "none",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}>
        <Box
          sx={{
            width: { lg: "80vw" },
            padding: "2vh 2vw 6vh 2vw",
            outline: "none",
            borderRadius: "6px",
            backgroundColor: "white",
            position: "relative",
          }}>
          <Grid sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              onClick={handleClose}
              sx={{ boxShadow: 2, color: "red", "&:hover": { boxShadow: 5 } }}>
              <CloseIcon />
            </Button>
          </Grid>
          <Grid
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mt: 2,
            }}>
            <Typography
              sx={{ fontFamily: "Mont-SemiBold", fontSize: "1.1rem" }}>
              Create Fragment
            </Typography>
            <Grid
              item
              xs={10.2}
              sx={{ backgroundColor: "red", height: "1vh" }}></Grid>
          </Grid>

          <Grid>
            <TextField
              onChange={(e) =>
                setCreateFragmentPayload((prevState) => ({
                  ...prevState,
                  fragment_name: e.target.value,
                  fragment_content: "",
                  project: project,
                }))
              }
              value={createFragmentPayload?.fragment_name}
              id="fragment_name"
              label="Provide Fragment name"
              variant="outlined"
              sx={{ width: "100%", mt: 4 }}
            />
          </Grid>

          <Grid
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              mt: 3,
            }}>
            <Button
              onClick={createFragmentHandler}
              variant="contained"
              sx={{
                backgroundColor: "red",
                "&:hover": { color: "red", backgroundColor: "white" },
              }}>
              Save Changes
            </Button>
          </Grid>
        </Box>
      </Modal>

      {/* delete modal */}
      {/* {deleteProjectModal && (
        <div
          style={{
            position: "absolute",
            top: "0",
            right: "0",
            bottom: "0",
            left: "0",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          }}>
          <div
            style={{
              position: "absolute",
              top: "30%",
              left: "40%",
              width: "25%",
              height: "max-content",
              background: "white",
              borderRadius: "10px",
              paddingTop: "2em",
              paddingBottom: "2em",
              paddingLeft: "1em",
              paddingRight: "1em",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}>
            <h4 style={{ textAlign: "left", lineHeight: "0.5" }}>
              Delete Project -
            </h4>
            <p
              style={{
                textAlign: "left",
                borderBottom: "1px solid black",
                lineHeight: "3",
                width: "100%",
              }}>
              {deletingName}
            </p>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                columnGap: "1vw",
              }}>
              <button
                onClick={(e) => {
                  deleteProjectHandler(deletingId);
                  setDeleteProjectModal(false);
                }}
                style={{
                  border: "none",
                  color: "white",
                  borderRadius: "10px",
                  padding: "0.4em",
                  background: "red",
                }}>
                Delete
              </button>

              <button
                onClick={(e) => setDeleteProjectModal(false)}
                style={{
                  border: "none",
                  color: "white",
                  borderRadius: "10px",
                  padding: "0.4em",
                  background: "red",
                }}>
                Cancel
              </button>
            </div>
          </div>
        </div>
      )} */}
    </div>
  );
}

export default AllFragments;
