import React from 'react';
import ReactDOM from 'react-dom';

import styles from './ScreensStylings/GCModal.module.css';

const GCModal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <div className={styles.gcModal}>
      <div className={styles.gcModalBackdrop} onClick={onClose}></div>
      <div className={styles.gcModalContent}>
        <button className={styles.gcModalClose} onClick={onClose}>Close</button>
        <br/>
        <hr/>
        {children}
      </div>
    </div>,
    document.getElementById('modal-root')
  );
};

export default GCModal;
