import uniqid from 'uniqid';
import { API_HOST } from '../api_utils/index';

const PatchupProviderPlugin = (editor, options = {}) => {
	// function createPatchupProviderTable() {
	// 	console.log('getting data from local storage');

	// 	const selectedServer = JSON.parse(localStorage.getItem('selectedServer'));
	// 	const checkedSlotValue = localStorage.getItem('checkedSlotValue');

	// 	console.log('selectedServer:', selectedServer, '\ncheckedSlotValue:', checkedSlotValue);

	// 	// creating table
	// 	let patchupProviderTableContainer = document.getElementsByClassName('patchup-provider-table-container')[0];
	// 	patchupProviderTableContainer. innerHTML = "";

	// 	let patchupProviderTable = document.createElement('table');

	// 	// <thead>
	// 	let thead = document.createElement('thead');

	// 	let tr1 = document.createElement('tr');

	// 	let th1 = document.createElement('th');
	// 	th1.innerHTML = '<p>Server Name</p>';
	// 	tr1.appendChild(th1);

	// 	let th2 = document.createElement('th');
	// 	th2.innerHTML = '<p>Server Owner</p>';
	// 	tr1.appendChild(th2);

	// 	let th3 = document.createElement('th');
	// 	th3.innerHTML = '<p>Preferred Timeslot</p>';
	// 	tr1.appendChild(th3);

	// 	let th4 = document.createElement('th');
	// 	th4.innerHTML = '<p>Patchup Start</p>';
	// 	tr1.appendChild(th4);

	// 	let th5 = document.createElement('th');
	// 	th5.innerHTML = '<p>Action</p>';
	// 	tr1.appendChild(th5);

	// 	let th6 = document.createElement('th');
	// 	th6.innerHTML = '<p>Feedback</p>';
	// 	tr1.appendChild(th6);

	// 	thead.appendChild(tr1);

	// 	patchupProviderTable.appendChild(thead);

	// 	// <tbody>
	// 	let tbody = document.createElement('tbody');

	// 	let tr2 = document.createElement('tr');

	// 	let td1 = document.createElement('td');
	// 	td1.innerHTML = `<p>${selectedServer.server_name}</p>`;
	// 	tr2.appendChild(td1);

	// 	let td2 = document.createElement('td');
	// 	td2.innerHTML = `<p>${selectedServer.server_owner}</p>`;
	// 	tr2.appendChild(td2);

	// 	let td3 = document.createElement('td');
	// 	td3.innerHTML = `<p>${checkedSlotValue}</p>`;
	// 	tr2.appendChild(td3);

	// 	let td4 = document.createElement('td');
	// 	td4.innerHTML = `<p>No</p>`;
	// 	tr2.appendChild(td4);

	// 	let td5 = document.createElement('td');
	// 	let startPatchupBtn = document.createElement('button');
	// 	startPatchupBtn.style.padding = "0.3em";
	// 	startPatchupBtn.style.borderRadius = "10px";
	// 	startPatchupBtn.innerHTML = '<p>Start Patchup</p>';
	// 	td5.appendChild(startPatchupBtn);
	// 	tr2.appendChild(td5);

	// 	let td6 = document.createElement('td');
	// 	td6.innerHTML = `<p>No</p>`;
	// 	tr2.appendChild(td6);

	// 	tbody.appendChild(tr2);

	// 	patchupProviderTable.appendChild(tbody);

	// 	patchupProviderTableContainer.appendChild(patchupProviderTable);

	// 	localStorage.removeItem('selectedServer');
	// 	localStorage.removeItem('checkedSlotValue');
	// }

	// adding its block in the block manager
	editor.Blocks.add('patchup-provider-plugin', {
		label: "Patchup Provider",
        category: "Basic",
        select: true,
        media: `<svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="currentColor" viewBox="0 0 16 16"><path d="M4.5 5a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1zM3 4.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0zm2 7a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0zm-2.5.5a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z"/>  <path d="M2 2a2 2 0 0 0-2 2v1a2 2 0 0 0 2 2h1v2H2a2 2 0 0 0-2 2v1a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-1a2 2 0 0 0-2-2h-1V7h1a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H2zm13 2v1a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1zm0 7v1a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1v-1a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1zm-3-4v2H4V7h8z"/></svg>`,
        content: { type: "patchup-provider-plugin" },
	});	

	// adding the component definition for the template
	editor.DomComponents.addType("patchup-provider-plugin", {
		model: {
			defaults: {
				tagName: "div",
				
				// script: createPatchupProviderTable,
				
				attributes: {
					class: "patchup-provider-table-container",
				},
			},

			// on initializing a component, present a form
			async init() {
				// getting a unique id for the input-select
				const uniqueId = uniqid('selectServer-');
  				console.log('selectServer ID for class:', uniqueId);

  				// getting server data
  				let serverData;
  				let response = await fetch(`${API_HOST}user/get_server_poc`);

  				if (response.ok) {
                    serverData = await response.json();
                    console.log('serverData received ok:', serverData);
                } else {
                    console.error('Error receiving serverData:', response.statusText);
                }

  				// creating the patchup provider form
				const patchupForm = document.createElement('form');
				patchupForm.setAttribute('class', 'patchup-form');

				const selectServerLabel = document.createElement('label');
				selectServerLabel.setAttribute('for', `${uniqueId}`);
				selectServerLabel.innerHTML = 'Select a server:';

				patchupForm.appendChild(selectServerLabel);

				const selectServer = document.createElement('select');
				selectServer.setAttribute('name', `${uniqueId}`);
				selectServer.setAttribute('id', `${uniqueId}`);
				selectServer.setAttribute('class', 'patchup-form-options');

				serverData.forEach((server) => {
					let serverOption = document.createElement('option');
					serverOption.setAttribute('value', `${server._id}`);
					serverOption.innerHTML = `${server.server_name}`;

					selectServer.appendChild(serverOption);
				});
				
				patchupForm.appendChild(selectServer);

				// opening a modal with the form
				editor.Modal.open({
					title: 'Patchup Provider Form',
					content: `${patchupForm.outerHTML}`,
					attributes: {
				        class: 'patchup-provider-modal',
				    },
				});

				console.log('modal opened');
			},
		},

		view: {
			onRender({el, model}) {
				console.log('el:', el);

				editor.Modal.onceClose(() => {
					console.log('getting data from local storage');

					const selectedServer = JSON.parse(localStorage.getItem('selectedServer'))[0];
					const checkedSlotValue = localStorage.getItem('checkedSlotValue');

					console.log('selectedServer:', selectedServer, '\ncheckedSlotValue:', checkedSlotValue);

					// creating table
					let patchupProviderTableContainer = el;

					let patchupProviderTable = document.createElement('table');

					// <thead>
					let thead = document.createElement('thead');

					let tr1 = document.createElement('tr');

					let th1 = document.createElement('th');
					th1.innerHTML = '<p>Server Name</p>';
					tr1.appendChild(th1);

					let th2 = document.createElement('th');
					th2.innerHTML = '<p>Server Owner</p>';
					tr1.appendChild(th2);

					let th3 = document.createElement('th');
					th3.innerHTML = '<p>Preferred Timeslot</p>';
					tr1.appendChild(th3);

					let th4 = document.createElement('th');
					th4.innerHTML = '<p>Patchup Start</p>';
					tr1.appendChild(th4);

					let th5 = document.createElement('th');
					th5.innerHTML = '<p>Action</p>';
					tr1.appendChild(th5);

					let th6 = document.createElement('th');
					th6.innerHTML = '<p>Feedback</p>';
					tr1.appendChild(th6);

					thead.appendChild(tr1);

					patchupProviderTable.appendChild(thead);

					// <tbody>
					let tbody = document.createElement('tbody');

					let tr2 = document.createElement('tr');

					let td1 = document.createElement('td');
					td1.innerHTML = `<p>${selectedServer.server_name}</p>`;
					tr2.appendChild(td1);

					let td2 = document.createElement('td');
					td2.innerHTML = `<p>${selectedServer.server_owner}</p>`;
					tr2.appendChild(td2);

					let td3 = document.createElement('td');
					td3.innerHTML = `<p>${checkedSlotValue}</p>`;
					tr2.appendChild(td3);

					let td4 = document.createElement('td');
					td4.innerHTML = `<p>No</p>`;
					tr2.appendChild(td4);

					let td5 = document.createElement('td');
					let startPatchupBtn = document.createElement('button');
					startPatchupBtn.setAttribute('class', 'start-patchup-btn');
					startPatchupBtn.style.padding = "0.2em";
					startPatchupBtn.style.borderRadius = "10px";
					startPatchupBtn.style.border = "1px solid black";
					startPatchupBtn.style.zIndex = "10";
					startPatchupBtn.style.cursor = "pointer";
					startPatchupBtn.innerHTML = '<p>Start Patchup</p>';

					startPatchupBtn.addEventListener('click', async (event) => {
						td4.innerHTML = `<p>Yes</p>`;

						// making a fetch api call
						let serverData;
		  				let response = await fetch(`${API_HOST}user/patch_server_poc`);

		  				if (response.ok) {

		                    serverData = await response.json();
		                    console.log('serverData received ok:', serverData);

		                    td6.innerHTML = `<p>Yes</p>`;

		                    // sending email on button click
		                    try {
				                // Send a POST request to your server endpoint
				                let response = await fetch(`${API_HOST}user/custom_email`, {
				                  	method: "POST",
				                  	headers: {
				                    	"Content-Type": "application/json",
				                  	},
				                  	
				                  	body: JSON.stringify({
				                    	to: "bindu.a@wemapac.com,anup.doddamani@wemapac.com".split(","), // pass [comma] separated emails (not [comma-space]) (e.g., abc@gmail.com,xyz@gmail.com)
				                    	// to: "moksh.nigam@wemapac.com, mokshsatsangidei@gmail.com".split(","), // pass [comma] separated emails (not [comma-space]) (e.g., abc@gmail.com,xyz@gmail.com)
				                    	// from: emailFrom,
				                    	from: "",
				                    	subject: "Server Patch Start Triggered",
				                    	body: `User@wemapac.com, has successfully patched with your server: ${selectedServer.server_name} for the timeslot: ${checkedSlotValue}.`,
				                  	}),
				                });

				                let responseData = await response.json();

				                console.log("Email sent successfully:", responseData);
				            } catch (error) {
				                console.error("Failed to send email:", error);
				            }

		                } else {
		                    console.error('Error receiving serverData:', response.statusText);
		                }
					});
					
					td5.appendChild(startPatchupBtn);
					tr2.appendChild(td5);

					let td6 = document.createElement('td');
					td6.innerHTML = `<p>No</p>`;
					tr2.appendChild(td6);

					tbody.appendChild(tr2);

					patchupProviderTable.appendChild(tbody);

					patchupProviderTableContainer.appendChild(patchupProviderTable);

					localStorage.removeItem('selectedServer');
					localStorage.removeItem('checkedSlotValue');

					// this.startPatchup();
				});
			},

			startPatchup() {
				let startPatchupBtn = document.querySelector('.start-patchup-btn');
				console.log('startPatchupBtn:', startPatchupBtn);

				startPatchupBtn.addEventListener('click', (event) => {
					console.log('startPatchupBtn CLICKED');
				});
			},
		},
	});
}

export default PatchupProviderPlugin;