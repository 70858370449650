import { Avatar, Button, Grid, Paper, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React, { useEffect } from 'react'
import Logo from './component_assets/images/details_close.png'
// import Logo from './component_assets/images/logo.svg'
import { DataGrid } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from 'react-router-dom';
import axios from 'axios'



function ChartTable() {
    let navigate = useNavigate()


    const sapApiFunction = async () => {

        const config = {
            method: 'get',
            url: 'http://eccehp7.4vm.com:8000/wem/customer_master?sap-client=800&ID=175',
            host: 'eccehp7.4vm.com',
            port: 8080,
            withCredentials: true,
            auth: {
                username: 'sltech1',
                password: 'Svsbsc_143'
            }

        };

        await axios(config)
            .then(function (response) {
                console.log(JSON.stringify(response.data));
            })
            .catch(function (error) {
                console.log(error);
            });


    }

    useEffect(() => {
        sapApiFunction()
    }, [])

    const columns = [
        { field: 'id', headerName: 'ID', width: 90 },
        {
            field: 'invoiceNumber',
            headerName: 'Invoice Number',
            width: 170,

        },
        {
            field: 'invoiceDate',
            headerName: 'Invoice Date',
            width: 170,

        },
        {
            field: 'viewDetails',
            headerName: ' ',
            width: 170,
            renderCell: () => {
                return (
                    <Button onClick={() => navigate('/custom_chart')} sx={{ color: 'white', backgroundColor: '#0284c7', '&:hover': { color: '#0284c7', backgroundColor: 'white' }, transitionDuration: '0.5s' }}>
                        View Details
                    </Button>
                )
            }
        },

        {
            field: 'Actions',
            headerName: '',
            width: 170,
            renderCell: () => {
                return (
                    <Button sx={{ color: 'white', backgroundColor: 'red', '&:hover': { color: 'red', backgroundColor: 'white' }, display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', transitionDuration: '0.5s' }}>
                        <DeleteIcon />
                        <Typography sx={{ fontSize: '0.8rem' }}>Delete Invoice</Typography>
                    </Button>

                )
            }
        }
    ];

    const rowData = [
        {
            invoice_number: '789456',
            invoice_date: '12/02/2021',
            month: 'february',
            id: 1
        },
        {
            invoice_number: '125469',
            invoice_date: '8/08/2021',
            month: 'august',
            id: 2
        },
        {
            invoice_number: '458796',
            invoice_date: '18/01/2021',
            month: 'january',
            id: 3
        },
        {
            invoice_number: '6587946',
            invoice_date: '21/12/2021',
            month: 'december',
            id: 4
        },
        {
            invoice_number: '325487',
            invoice_date: '16/04/2021',
            month: 'april',
            id: 5
        },
        {
            invoice_number: '854698',
            invoice_date: '4/06/2021',
            month: 'june',
            id: 6
        },
        {
            invoice_number: '785469',
            invoice_date: '14/09/2021',
            month: 'september',
            id: 7
        },
        {
            invoice_number: '154879',
            invoice_date: '8/07/2021',
            month: 'july',
            id: 8
        },
        {
            invoice_number: '946873',
            invoice_date: '26/05/2021',
            month: 'may',
            id: 9
        },
        {
            invoice_number: '579312',
            invoice_date: '10/10/2021',
            month: 'october',
            id: 10
        },
    ]



    const rows = rowData.map((x) => {
        return {
            invoiceNumber: x.invoice_number,
            invoiceDate: x.invoice_date,
            month: x.month,
            id: x.id

        }
    })

    return (
        <Grid >
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Avatar sx={{ width: 100, height: 100 }} src={Logo} />
                <Typography sx={{ fontFamily: 'Mont-Heavy', fontSize: '2rem', ml: 4, lineHeight: '1.8rem', textAlign: 'center' }}>A RANDOM COMPANY NAME  <br /><span style={{ fontSize: '1rem', fontFamily: 'Mont-Regular' }}>WE CARE ABOUT OUR PEOPLES</span></Typography>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 4, }}>
                <Paper elevation={4} sx={{ padding: '0.7vh 2vw', backgroundColor: '#0284c7', color: 'white', fontFamily: 'Mont-Regular', '&:hover': { backgroundColor: 'white', color: '#0284c7' } }}>Create Invoice</Paper>
                <Paper elevation={4} sx={{ padding: '0.7vh 2vw', backgroundColor: 'red', color: 'white', fontFamily: 'Mont-Regular', margin: '0 1vw', '&:hover': { backgroundColor: 'white', color: 'red' } }}>Cancel</Paper>
                <Paper elevation={4} sx={{ padding: '0.7vh 2vw', backgroundColor: '#0284c7', color: 'white', fontFamily: 'Mont-Regular', '&:hover': { backgroundColor: 'white', color: '#0284c7' } }}>Products Trend</Paper>
            </Box>
            <Grid sx={{ height: '60vh', display: 'flex', justifyContent: 'center', mt: 6, mb: 8 }}>
                <Grid xs={10}>
                    <DataGrid
                        rows={rows}
                        columns={columns}
                        pageSize={5}
                        // onRowClick={(params, event) => handleRowEventHandler(params, event)}

                        rowsPerPageOptions={[5]}
                        checkboxSelection
                        disableSelectionOnClick
                        experimentalFeatures={{ newEditingApi: true }}
                    />
                </Grid>
            </Grid>
        </Grid>
    )
}

export default ChartTable