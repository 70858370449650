import { useEffect, useRef } from "react";
import $ from "jquery";


// Import necessary styles
import "datatables.net-dt/css/jquery.dataTables.min.css";


// Initialize jquery and Datatable


const TestTable = () => {
    const tableRef = useRef();

    const dataSet = [
        [
            "Tiger Nixon",
            "System Architect",
            "Edinburgh",
            "5421",
            "2011/04/25",
            "$320,800",
        ],
        [
            "Ashton Cox",
            "Junior Technical Author",
            "San Francisco",
            "1562",
            "2009/01/12",
            "$86,000",
        ]
    ]

  

    // Create a reference for the table
    return <table ref={tableRef}></table>;
};

export default TestTable;