import React, { useEffect, useState, useRef } from "react";
import * as Blockly from "blockly";
import { javascriptGenerator } from "blockly/javascript";
import { toolbox, customTheme } from "./ConfigFiles";
import "blockly/blocks";
import "./customCss.css";
import { useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Paper,
  Step,
  Stepper,
  StepLabel,
  Button,
  TextField,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { basicSetup } from "@codemirror/basic-setup";
import { EditorState } from "@codemirror/state";
import { EditorView } from "@codemirror/view";
import { javascript } from "@codemirror/lang-javascript";
import { IoIosArrowDropupCircle } from "react-icons/io";
import { useSelector } from "react-redux";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import Switch from "@mui/material/Switch";

import { API_HOST } from "../../api_utils";

const steps = ["Create Dynamic Route", "Add Controller Logic"];

function CreateNewDynamicRoute() {
  let navigate = useNavigate();
  const [generatedCode, setGeneratedCode] = useState("");
  const [workspace, setWorkspace] = useState(null);
  const [activeStep, setActiveStep] = useState(0);
  const [open, setOpen] = useState(false);
  const [showCodeViewer, setShowCodeViewer] = useState(false);
  let { project } = useParams();
  const codeEditor = useRef();
  const { userInfo } = useSelector((state) => state.userLogin);
  const [pageId, setPageId] = useState("");
  const [isEditFlow, setIsEditFlow] = useState(false);
  const [routeDetail, setRouteDetail] = useState({});
  const [isUpdated, setIsUpdated] = useState(false);

  const [generateRoutePayload, setGenerateRoutePayload] = useState({
    route: "",
    controllerName: "",
    controllerLogic: "",
    project: project?.split("?")[0],
    method: "",
    blb_xml: "",
    isJwt: false,
  });

  // STATES FOR INVIEW SCROLL AND HIDING OTHER ELE'S OTHER THAN LB:
  const [hideStepAndButton, setHideStepAndButton] = useState(false);

  const handleChange = (event) => {
    setGenerateRoutePayload((prevState) => ({
      ...prevState,
      isJwt: event.target.checked,
    }));
  };

  const getRouteDetailHandler = async () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(
      `${API_HOST}create_custom_route/get_route_detail/${pageId}`,
      config
    );

    if (!data.message) {
      setRouteDetail(data);

      let newObj = {
        controllerName: data.controllerName,
        controllerLogic: data.controllerLogic,
        method: data.method,
        route: data.route.split("/").splice(1).join("/"),
        project: data.project,
        user: data.user,
        _id: data._id,
        created_by: data.name,
      };
      setGenerateRoutePayload(newObj);
    }
  };

  useEffect(() => {
    let isPageEdit = window.location.search.split("?")[1];
    if (isPageEdit === "create_new") {
      setIsEditFlow(false);
    } else {
      setIsEditFlow(true);
      let pageIdNew = isPageEdit.split("=")[1];
      setPageId(pageIdNew);
    }
  }, []);

  useEffect(() => {
    if (activeStep === 1) {
      const initBlockly = () => {
        const workspace = Blockly.inject("blocklyDiv", {
          toolbox: toolbox,
          scrollbars: true,
          zoom: {
            controls: true,
            wheel: true,
            startScale: 1.0,
            maxScale: 3,
            minScale: 0.3,
            scaleSpeed: 1.2,
          },
          theme: customTheme,
        });

        setWorkspace(workspace);

        workspace.addChangeListener(() => {
          const code = javascriptGenerator.workspaceToCode(workspace);
          console.log("code", code);
          setGeneratedCode(code);
          let xml = Blockly.Xml.workspaceToDom(workspace);

          console.log("xml:::::::", xml);
          // Convert the XML to text
          let xml_text = Blockly.Xml.domToText(xml);

          if (xml_text) {
            setGenerateRoutePayload((prevState) => ({
              ...prevState,
              blb_xml: xml_text,
            }));
          }
        });
      };

      initBlockly();

      // SCROLL TO LB VIEW AUTOMATICALLY WHEN STEP IS 1:
      setTimeout(() => {
        setHideStepAndButton(true);
      }, 1000);

      return () => {
        if (workspace) {
          workspace.dispose();
        }
      };
    }
  }, [activeStep]);

  useEffect(() => {
    if (routeDetail && routeDetail.blb_xml && workspace) {
      workspace.clear();

      try {
        // Convert the code to blocks
        const xml = Blockly.utils.xml.textToDom(routeDetail?.blb_xml);
        console.log("wprkspace", workspace);
        console.log("routeDetail.controllerLogic", routeDetail.controllerLogic);
        Blockly.Xml.domToWorkspace(xml, workspace);
      } catch (error) {
        console.error("Error rendering blocks from code:", error);
        // Handle error gracefully, e.g., show an error message to the user
      }
    }
  }, [routeDetail, workspace]);

  useEffect(() => {
    if (generatedCode) {
      setGenerateRoutePayload((prevState) => ({
        ...prevState,
        controllerLogic: generatedCode,
      }));
      const state = EditorState.create({
        doc: generatedCode,
        extensions: [basicSetup, javascript()],
        readOnly: "nocursor",
      });

      console.log("state:::::::::", state);
      const view = new EditorView({ state, parent: codeEditor.current });

      console.log("view::::::", view);
      return () => {
        view.destroy();
        // editor.current.removeEventListener("input", log);
      };
    }
  }, [generatedCode]);

  useEffect(() => {
    if (pageId) {
      getRouteDetailHandler();
    }

    // cleanup funcn for refresh check after update
    return () => {
      setIsUpdated(false);
    };
  }, [pageId, isUpdated]);

  const handleStepAndLbSize = () => {
    if (hideStepAndButton) {
      setHideStepAndButton(false);
    } else {
      setHideStepAndButton(true);
    }
  };

  const generateDynamicRouteHandler = async () => {
    let newProject = project.split("?")[0];
    if (
      generateRoutePayload.route &&
      generateRoutePayload.controllerName &&
      generateRoutePayload.method &&
      generateRoutePayload.controllerLogic &&
      generateRoutePayload.blb_xml
    ) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.post(
        `${API_HOST}create_custom_route/`,
        generateRoutePayload,
        config
      );

      if (
        data.message &&
        data.message === "Route and controller created successfully"
      ) {
        toast.success("Dynamic Route Created Successfully!!!");
        navigate(`/dynamic_route/${newProject}`);
      } else {
        toast.error(data.message);
      }
    } else {
      toast.warn(
        "Make sure to provide Route,Method,ControllerName and Controller Logic !!!"
      );
    }
  };

  const updateDynamicRouteHandler = async () => {
    if (
      generateRoutePayload.route ||
      generateRoutePayload.controllerName ||
      generateRoutePayload.method ||
      generateRoutePayload.controllerLogic ||
      generateRoutePayload.blb_xml
    ) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.post(
        `${API_HOST}create_custom_route/update_route/${pageId}`,
        generateRoutePayload,
        config
      );

      if (
        data.message &&
        data.message === "Route and controller Updated successfully"
      ) {
        toast.success("Dynamic Route Updated Successfully!!!");
        setIsUpdated(true);
        setActiveStep(0);
        // navigate(`/dynamic_route/${newProject}`);
      } else {
        toast.error(data.message);
      }
    } else {
      toast.warn(
        "Make sure to provide Route,Method,ControllerName and Controller Logic !!!"
      );
    }
  };

  return (
    <div
      style={
        hideStepAndButton
          ? {
              alignItems: "center",
              width: "100%",
              height: "87vh",
              position: "relative",
            }
          : {
              alignItems: "center",
              width: "100%",
              height: "90vh",
              position: "relative",
            }
      }>
      <div
        style={
          hideStepAndButton
            ? {
                height: "100%",
                width: "100%",
                position: "relative",
              }
            : {
                height: "100%",
                width: "100%",
                position: "relative",
              }
        }>
        <Box
          className={
            hideStepAndButton && activeStep === 1
              ? "hideButtonIcons"
              : activeStep === 1
              ? "showButtonIcons"
              : ""
          }
          sx={{ width: "90%", m: "auto", pt: "1rem", display: "block" }}>
          <Stepper activeStep={activeStep}>
            {steps.map((label, index) => {
              const stepProps = {};
              const labelProps = {};
              return (
                <Step key={label} {...stepProps}>
                  <StepLabel
                    sx={{ fontSize: "1.5rem !important" }}
                    {...labelProps}>
                    {label}
                  </StepLabel>
                </Step>
              );
            })}
          </Stepper>
          {activeStep === 1 ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                mt: "1rem",
                mb: "1rem",
              }}>
              <Button
                variant="contained"
                onClick={() => setActiveStep(0)}
                sx={{
                  pb: "0.5rem",
                  pt: "0.5rem",
                  mr: "0.8rem",
                  letterSpacing: "0.2rem",
                  backgroundColor: "#f43f5e",
                  "&:hover": {
                    backgroundColor: "#e11d48",
                    transitionDuration: "0.5s",
                  },
                }}>
                Back
              </Button>
              {isEditFlow ? (
                <Button
                  variant="contained"
                  onClick={() => updateDynamicRouteHandler()}
                  sx={{
                    pb: "0.5rem",
                    pt: "0.5rem",
                    mr: "0.8rem",
                    letterSpacing: "0.2rem",
                    backgroundColor: "#f43f5e",
                    "&:hover": {
                      backgroundColor: "#e11d48",
                      transitionDuration: "0.5s",
                    },
                  }}>
                  Update
                </Button>
              ) : (
                <Button
                  variant="contained"
                  onClick={() => generateDynamicRouteHandler()}
                  sx={{
                    pb: "0.5rem",
                    pt: "0.5rem",
                    mr: "0.8rem",
                    letterSpacing: "0.2rem",
                    backgroundColor: "#f43f5e",
                    "&:hover": {
                      backgroundColor: "#e11d48",
                      transitionDuration: "0.5s",
                    },
                  }}>
                  Save
                </Button>
              )}
              {/* <Button
                variant="contained"
                onClick={() => setOpen(true)}
                sx={{
                  pb: "0.5rem",
                  pt: "0.5rem",

                  letterSpacing: "0.2rem",
                  backgroundColor: "#f43f5e",
                  "&:hover": {
                    backgroundColor: "#e11d48",
                    transitionDuration: "0.5s",
                  },
                }}>
                View Code
              </Button> */}
            </Box>
          ) : (
            ""
          )}
        </Box>

        {activeStep === 1 ? (
          <div
            className={
              hideStepAndButton ? "expandLBContainer" : "collapseLBContainer"
            }
            style={{
              width: "100%",
            }}>
            <div
              id="blocklyDiv"
              style={{
                height: "100%",
                width: "100%",
              }}></div>

            {/* CODE VIEWER */}
            {/* <div
              style={
                generatedCode
                  ? {
                      display: "flex",
                      width: "100%",
                      height: "100%",
                      position: "absolute",
                      top: "0",
                      justifyContent: "center",
                      alignItems: "center",
                    }
                  : { display: "none" }
              }
              className={generatedCode ? "modalBackendLB" : ""}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "70%",
                  height: "80%",
                  backgroundColor: "white",
                  padding: "0.5rem",
                  borderRadius: "10px",
                }}>
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                  }}
                  ref={codeEditor}></div>
              </div>
            </div> */}
          </div>
        ) : (
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              mt: "4rem",
            }}>
            <Paper
              elevation={2}
              sx={{
                width: "60%",
                borderRadius: "10px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                padding: "1.5rem",
              }}>
              <TextField
                onChange={(e) =>
                  setGenerateRoutePayload((prevState) => ({
                    ...prevState,
                    route: e.target.value,
                  }))
                }
                value={generateRoutePayload?.route}
                id="outlined-basic"
                label="Name of Route"
                variant="outlined"
                sx={{ mb: "2rem", width: "100%" }}
              />

              <FormControl sx={{ width: "100%", mb: "2rem" }}>
                <InputLabel id="apiMethodName">Api Method</InputLabel>
                <Select
                  labelId="apiMethodName"
                  id="apiMethod"
                  value={generateRoutePayload?.method}
                  label="Api Method"
                  onChange={(e) =>
                    setGenerateRoutePayload((prevState) => ({
                      ...prevState,
                      method: e.target.value,
                    }))
                  }>
                  <MenuItem key={1} value="GET">
                    GET
                  </MenuItem>
                  <MenuItem key={1} value="POST">
                    POST
                  </MenuItem>
                  <MenuItem key={1} value="PUT">
                    PUT
                  </MenuItem>
                  <MenuItem key={1} value="DELETE">
                    DELETE
                  </MenuItem>
                </Select>
              </FormControl>

              <TextField
                onChange={(e) =>
                  setGenerateRoutePayload((prevState) => ({
                    ...prevState,
                    controllerName: e.target.value,
                  }))
                }
                disabled={isEditFlow ? true : false}
                value={generateRoutePayload?.controllerName}
                id="outlined-basic"
                label="Controller Name"
                variant="outlined"
                sx={{ width: "100%", mb: "2rem" }}
              />

              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  mb: "4rem",
                }}>
                <Typography
                  sx={{
                    color: "gray",
                    fontSize: "1.2rem",
                    letterSpacing: "0.2rem",
                  }}>
                  Want To Make This Route Authenticated ?
                </Typography>
                <Switch
                  checked={generateRoutePayload?.isJwt}
                  onChange={handleChange}
                  inputProps={{ "aria-label": "controlled" }}
                  sx={{ ml: 3 }}
                  color="secondary"
                />
              </Box>

              <Button
                variant="contained"
                onClick={() => setActiveStep(1)}
                sx={{
                  width: "100%",
                  pb: "0.8rem",
                  pt: "0.8rem",
                  fontSize: "1.1rem",
                  letterSpacing: "0.2rem",
                  backgroundColor: "#f43f5e",
                  "&:hover": {
                    backgroundColor: "#e11d48",
                    transitionDuration: "0.5s",
                  },
                }}>
                Proceed to Controller
              </Button>
            </Paper>
          </Box>
        )}
      </div>

      {activeStep === 1 ? (
        <p
          onClick={() => handleStepAndLbSize()}
          style={
            hideStepAndButton
              ? {
                  position: "fixed",
                  bottom: "0px",
                  left: "10px",
                  zIndex: 99999,
                  fontSize: "3rem",
                  color: "#f43f5e",
                  opacity: "75%",
                }
              : {
                  position: "fixed",
                  bottom: "0px",
                  left: "10px",
                  zIndex: 99999,
                  fontSize: "3rem",
                  color: "#f43f5e",
                  opacity: "75%",
                  rotate: "180deg",
                }
          }>
          <IoIosArrowDropupCircle />
        </p>
      ) : (
        ""
      )}
      <ToastContainer autoClose={2000} />
    </div>
  );
}

export default CreateNewDynamicRoute;
