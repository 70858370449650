import React, { useEffect, useState } from 'react';

import classes from './LogicBuilder.module.css';

import WorkSpace from './WorkSpace';
import StatementPanel from './StatementPanel';
import FunctionPanel from './FunctionPanel';
import MathPanel from './MathPanel';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import VariablesPanel from './VariablesPanel';
import { clearAll } from '../Redux/Actions/createFunctionAction';
import { useDispatch } from 'react-redux';
import { connect } from 'react-redux';
import LogicalOperators from './LogicalOperators';


const LogicBuilder = (props) => {

	let dispatch = useDispatch();

	// for StatementPanel
	const [isStatementPanel, setIsStatementPanel] = useState(false);
	const [isStatementPanelOpen, setIsStatementPanelOpen] = useState(false);

	// for FunctionPanel
	const [isFunctionPanel, setIsFunctionPanel] = useState(false);
	const [isFunctionPanelOpen, setIsFunctionPanelOpen] = useState(false);

	// mathPanel
	const [isMathPanel, setIsMathPanel] = useState(false);
	const [isMathPanelOpen, setIsMathPanelOpen] = useState(false)

	const [isVariablesPanel, setIsVariablesPanel] = useState(false);
	const [isVariablesPanelOpen, setIsVariablesPanelOpen] = useState(false);

	const [isLogicalOperatorPanel, setIsLogicalOperatorPanel] = useState(false)
	const [isLogicalOperatorPanelOpen, setIsLogicalOperatorPanelOpen] = useState(false)

	const [undoClicked, setUndoClicked] = useState(false)

	// set inside 'svg.statement-panel-btn' with onClick attribute; it opens the statement panel
	function statementPanelOpenHandler(event) {
		// show statement_panel
		setIsStatementPanel(true);
		setIsStatementPanelOpen(true);

		// and hide function_panel at the same time
		setIsFunctionPanel(false);
		setIsFunctionPanelOpen(false);

		setIsMathPanel(false);
		setIsMathPanelOpen(false);

		setIsVariablesPanel(false);
		setIsVariablesPanelOpen(false)
	}

	// set inside 'svg.function-panel-btn' with onClick attribute; it opens the statement panel
	function functionPanelOpenHandler(event) {
		// show function_panel
		setIsFunctionPanel(true);
		setIsFunctionPanelOpen(true);

		// hide statement_panel at the same time
		setIsStatementPanel(false);
		setIsStatementPanelOpen(false);

		setIsMathPanel(false);
		setIsMathPanelOpen(false);

		setIsVariablesPanel(false);
		setIsVariablesPanelOpen(false)
	}

	function mathPanelOpenHandler(event) {
		setIsMathPanel(true);
		setIsMathPanelOpen(true);

		setIsStatementPanel(false);
		setIsStatementPanelOpen(false);
		setIsFunctionPanel(false);
		setIsFunctionPanelOpen(false);

		setIsVariablesPanel(false);
		setIsVariablesPanelOpen(false)
	}

	function variablesOpenHandler (event) {
		setIsVariablesPanel(true)
		setIsVariablesPanelOpen(true)

		setIsStatementPanel(false);
		setIsStatementPanelOpen(false);
		setIsFunctionPanel(false);
		setIsFunctionPanelOpen(false);
		setIsMathPanel(false);
		setIsMathPanelOpen(false);


	}

	function logicalOperatorOpenHandler (event) {
		setIsLogicalOperatorPanel(true)
		setIsLogicalOperatorPanelOpen(true)

		setIsStatementPanel(false);
		setIsStatementPanelOpen(false);
		setIsFunctionPanel(false);
		setIsFunctionPanelOpen(false);
		setIsMathPanel(false);
		setIsMathPanelOpen(false);
		setIsVariablesPanel(false)
		setIsVariablesPanelOpen(false)

		
	}


	// sending as a prop to <StatementPanel /> so that it is called from inside that component's onClick attribute
	function statementPanelCloseHandler(event) {
		setIsStatementPanel(false);
		setIsStatementPanelOpen(false);
	}

	// sending as a prop to <FunctionPanel /> so that it is called from inside that component's onClick attribute
	function functionPanelCloseHandler(event) {
		setIsFunctionPanel(false);
		setIsFunctionPanelOpen(false);
	}

	// sending as a prop to <MathPanel /> so that it is called from inside that component's onClick attribute
	function mathPanelCloseHandler(event) {
		setIsMathPanel(false)
		setIsMathPanelOpen(false)
	}

	function variablePanelCloseHander(event) {
		setIsVariablesPanel(false)
		setIsVariablesPanelOpen(false)
	}

	function logicalOperatorCloseHandler(event) {
		setIsLogicalOperatorPanel(false)
		setIsLogicalOperatorPanelOpen(false)
	}

	// useEffect(() => {
	// 	console.log("++++CALLED CLEAR ALL ===")
	// 	dispatch(clearAll())
	// })
	


	function clearAllFunc () {
		// e.preventDefault();
		console.log("CLEAR ALL TO REDUCER")
		if(props.createFunc.droppedItems.length > 0 || props.createFunc.execute.length > 0 || props.createFunc.funcName) {
			dispatch(clearAll()).then(res => {
				console.log("DDDDDONNNNNEEEE")
			})
			return ;
		} else {
			console.log("none")
		}

		
	}

	const undoBtnClicked = (e) => {
		e.preventDefault();
		console.log("Undo clciked")
		setUndoClicked(true);
		setUndoClicked(false)
	}


	return (

		<div className={classes.body}>
			<div className={classes.nav}></div>

			<div className={classes.header}>
				{/* save icon */}
				<svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className={'bi bi-cloud-download ' + classes.lb_svg} viewBox="0 0 16 16">
				  	<path d="M4.406 1.342A5.53 5.53 0 0 1 8 0c2.69 0 4.923 2 5.166 4.579C14.758 4.804 16 6.137 16 7.773 16 9.569 14.502 11 12.687 11H10a.5.5 0 0 1 0-1h2.688C13.979 10 15 8.988 15 7.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 2.825 10.328 1 8 1a4.53 4.53 0 0 0-2.941 1.1c-.757.652-1.153 1.438-1.153 2.055v.448l-.445.049C2.064 4.805 1 5.952 1 7.318 1 8.785 2.23 10 3.781 10H6a.5.5 0 0 1 0 1H3.781C1.708 11 0 9.366 0 7.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383z"/>
				  	<path d="M7.646 15.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 14.293V5.5a.5.5 0 0 0-1 0v8.793l-2.146-2.147a.5.5 0 0 0-.708.708l3 3z"/>
				</svg>

				{/* delete icon */}
				<svg onClick={clearAllFunc} xmlns="http://www.w3.org/2000/svg" fill="currentColor" className={'bi bi-trash3 ' + classes.lb_svg} viewBox="0 0 16 16">
						<path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z"/>
				</svg>
				

				{/* undo icon */}
				<svg 
				onClick={undoBtnClicked}
				xmlns="http://www.w3.org/2000/svg" fill="currentColor" className={'bi bi-arrow-counterclockwise ' + classes.lb_svg} viewBox="0 0 16 16">
				  	<path fillRule="evenodd" d="M8 3a5 5 0 1 1-4.546 2.914.5.5 0 0 0-.908-.417A6 6 0 1 0 8 2v1z"/>
				  	<path d="M8 4.466V.534a.25.25 0 0 0-.41-.192L5.23 2.308a.25.25 0 0 0 0 .384l2.36 1.966A.25.25 0 0 0 8 4.466z"/>
				</svg>

				{/* redo icon */}
				<svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className={'bi bi-arrow-clockwise ' + classes.lb_svg} viewBox="0 0 16 16">
				  	<path fillRule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z"/>
				  	<path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z"/>
				</svg>

				{/* download icon */}
				<svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className={'bi bi-download ' + classes.lb_svg} viewBox="0 0 16 16">
				  	<path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"/>
				  	<path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z"/>
				</svg>
			</div>

			<section className={classes.section}>
				{/* left panel */}
				<DndProvider backend={HTML5Backend }>
				<div className={classes.left_panel}>
					{/* Statement/Variable panel */}

					<svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" 
						className={!isStatementPanelOpen ? "bi bi-signpost-split statement-panel-btn " + classes.lb_svg : "bi bi-signpost-split statement-panel-btn statement-panel-open " + classes.lb_svg} viewBox="0 0 16 16" 
						onClick={statementPanelOpenHandler}>
					  	<path d="M7 7V1.414a1 1 0 0 1 2 0V2h5a1 1 0 0 1 .8.4l.975 1.3a.5.5 0 0 1 0 .6L14.8 5.6a1 1 0 0 1-.8.4H9v10H7v-5H2a1 1 0 0 1-.8-.4L.225 9.3a.5.5 0 0 1 0-.6L1.2 7.4A1 1 0 0 1 2 7h5zm1 3V8H2l-.75 1L2 10h6zm0-5h6l.75-1L14 3H8v2z"/>
					</svg>

					<svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" 
						className={"bi bi-percent " + classes.lb_svg} viewBox="0 0 16 16"
						onClick={mathPanelOpenHandler}>
					  	<path d="M13.442 2.558a.625.625 0 0 1 0 .884l-10 10a.625.625 0 1 1-.884-.884l10-10a.625.625 0 0 1 .884 0zM4.5 6a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm0 1a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5zm7 6a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm0 1a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5z"/>
					</svg>

					<svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" 
						className={"bi bi-app-indicator " + classes.lb_svg} viewBox="0 0 16 16"
						onClick={variablesOpenHandler}
						>
					  	<path d="M5.5 2A3.5 3.5 0 0 0 2 5.5v5A3.5 3.5 0 0 0 5.5 14h5a3.5 3.5 0 0 0 3.5-3.5V8a.5.5 0 0 1 1 0v2.5a4.5 4.5 0 0 1-4.5 4.5h-5A4.5 4.5 0 0 1 1 10.5v-5A4.5 4.5 0 0 1 5.5 1H8a.5.5 0 0 1 0 1H5.5z"/>
					  	<path d="M16 3a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"/>
					</svg>

					<svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" 
						className={"bi bi-app-indicator " + classes.lb_svg} viewBox="0 0 16 16"
						onClick={logicalOperatorOpenHandler}
						>
					  	<path d="M5.5 2A3.5 3.5 0 0 0 2 5.5v5A3.5 3.5 0 0 0 5.5 14h5a3.5 3.5 0 0 0 3.5-3.5V8a.5.5 0 0 1 1 0v2.5a4.5 4.5 0 0 1-4.5 4.5h-5A4.5 4.5 0 0 1 1 10.5v-5A4.5 4.5 0 0 1 5.5 1H8a.5.5 0 0 1 0 1H5.5z"/>
					  	<path d="M16 3a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"/>
					</svg>

					<svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" 
						className={"bi bi-app-indicator " + classes.lb_svg} viewBox="0 0 16 16">
					  	<path d="M5.5 2A3.5 3.5 0 0 0 2 5.5v5A3.5 3.5 0 0 0 5.5 14h5a3.5 3.5 0 0 0 3.5-3.5V8a.5.5 0 0 1 1 0v2.5a4.5 4.5 0 0 1-4.5 4.5h-5A4.5 4.5 0 0 1 1 10.5v-5A4.5 4.5 0 0 1 5.5 1H8a.5.5 0 0 1 0 1H5.5z"/>
					  	<path d="M16 3a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"/>
					</svg>

					<svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" 
						className={"bi bi-app-indicator " + classes.lb_svg} viewBox="0 0 16 16">
					  	<path d="M5.5 2A3.5 3.5 0 0 0 2 5.5v5A3.5 3.5 0 0 0 5.5 14h5a3.5 3.5 0 0 0 3.5-3.5V8a.5.5 0 0 1 1 0v2.5a4.5 4.5 0 0 1-4.5 4.5h-5A4.5 4.5 0 0 1 1 10.5v-5A4.5 4.5 0 0 1 5.5 1H8a.5.5 0 0 1 0 1H5.5z"/>
					  	<path d="M16 3a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"/>
					</svg>

					<svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" 
						className={!isFunctionPanelOpen ? "bi bi-database functions-panel-btn " + classes.lb_svg : "bi bi-database functions-panel-btn function-panel-open " + classes.lb_svg} viewBox="0 0 16 16" 
						onClick={functionPanelOpenHandler}>
					  	<path d="M4.318 2.687C5.234 2.271 6.536 2 8 2s2.766.27 3.682.687C12.644 3.125 13 3.627 13 4c0 .374-.356.875-1.318 1.313C10.766 5.729 9.464 6 8 6s-2.766-.27-3.682-.687C3.356 4.875 3 4.373 3 4c0-.374.356-.875 1.318-1.313ZM13 5.698V7c0 .374-.356.875-1.318 1.313C10.766 8.729 9.464 9 8 9s-2.766-.27-3.682-.687C3.356 7.875 3 7.373 3 7V5.698c.271.202.58.378.904.525C4.978 6.711 6.427 7 8 7s3.022-.289 4.096-.777A4.92 4.92 0 0 0 13 5.698ZM14 4c0-1.007-.875-1.755-1.904-2.223C11.022 1.289 9.573 1 8 1s-3.022.289-4.096.777C2.875 2.245 2 2.993 2 4v9c0 1.007.875 1.755 1.904 2.223C4.978 15.71 6.427 16 8 16s3.022-.289 4.096-.777C13.125 14.755 14 14.007 14 13V4Zm-1 4.698V10c0 .374-.356.875-1.318 1.313C10.766 11.729 9.464 12 8 12s-2.766-.27-3.682-.687C3.356 10.875 3 10.373 3 10V8.698c.271.202.58.378.904.525C4.978 9.71 6.427 10 8 10s3.022-.289 4.096-.777A4.92 4.92 0 0 0 13 8.698Zm0 3V13c0 .374-.356.875-1.318 1.313C10.766 14.729 9.464 15 8 15s-2.766-.27-3.682-.687C3.356 13.875 3 13.373 3 13v-1.302c.271.202.58.378.904.525C4.978 12.71 6.427 13 8 13s3.022-.289 4.096-.777c.324-.147.633-.323.904-.525Z"/>
					</svg>
				</div>

				{/* rendering <StatementPanel /> conditionally, for on/off effect on button/svg click */}
				{ isStatementPanel && !isFunctionPanel && !isMathPanel && <StatementPanel onClose={statementPanelCloseHandler} /> }
				
				{/* rendering <FunctionPanel /> conditionally, for on/off effect on button/svg click */}
				{ isFunctionPanel && !isStatementPanel && <FunctionPanel onClose={functionPanelCloseHandler} /> }

				{ isMathPanel && !isStatementPanel && !isFunctionPanel && <MathPanel onClose={mathPanelCloseHandler} /> }

				{ isLogicalOperatorPanel && !isMathPanel && !isStatementPanel && !isFunctionPanel && <LogicalOperators onClose={logicalOperatorCloseHandler} /> }
				{/* middle screen, white space */}

				{isVariablesPanel && !isStatementPanel && !isFunctionPanel && !isMathPanel && <VariablesPanel onClose={variablePanelCloseHander} /> }
				<WorkSpace undoParent={undoBtnClicked} />
				</DndProvider>
			</section>
		</div>
		
	);
};

const mapStateToProps = state => {
	console.log(state)
    return {
        createFunc: state.createNewFunc,
        selected_project: state.selectedProject
    }
}

export default connect(mapStateToProps, {})(LogicBuilder);
// export default LogicBuilder