const mainBody = document.createElement('div');

// for bootstrap icons in social media
const cdn = document.createElement('link');
cdn.setAttribute('rel', 'stylesheet');
cdn.setAttribute('href', 'https://cdn.jsdelivr.net/npm/bootstrap-icons@1.9.1/font/bootstrap-icons.css');
mainBody.appendChild(cdn);

const header = document.createElement('div');
header.setAttribute('class', 'header');
mainBody.appendChild(header);

header.innerHTML = `
	<div>
        <img src="/templates/images/redSlingLogo.png" />
      </div>
      <div class="header-right-section1">
        <p>Admin</p>
        <img src="/templates/images/Vector.png" />
    </div>
`;

const recieptwhiteWaveOverlay1 = document.createElement('div');
recieptwhiteWaveOverlay1.setAttribute('class', 'recieptwhiteWaveOverlay1');
mainBody.appendChild(recieptwhiteWaveOverlay1);

recieptwhiteWaveOverlay1.innerHTML = `
	<img src="/templates/images/Ellipse 6.png" />
    <img class="recieptwhiteWave2" src="/templates/images/white waves 2.png" />
`;

const checkoutDivR = document.createElement('div');
checkoutDivR.setAttribute('class', 'checkoutDivR');
mainBody.appendChild(checkoutDivR);

checkoutDivR.innerHTML = `
	<!-- dark bubble -->
      <img class="checkout-d-bb" src="/templates/images/darkBubble.png" />

      <p class="thankText">Thank You for Your Purchase!</p>
      <p class="purchaseDesc">
        Your order will be processed in the next 24 hours. We will notify you
        via email/text alerts once your order has been shipped.
      </p>
      <div class="vectorBackground">
        <img src="/templates/images/recieptGroup.png" />
      </div>

      <div class="recieptBottom">
        <button>Track your Order</button>
        <button>Continue Shopping</button>
      </div>

      <style>
        @media (max-width: 768px) {
          .header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            position: relative;
            padding: 2vh 2.5vw;
          }
          .header-right-section1 img {
            width: 4vw;
            height: 3vh;
            margin-left: 5%;
          }
          .header-right-section1 p {
            font-family: Mont-Regular;
            font-size: 1rem;
            margin-right: 5%;
          }

          .recieptwhiteWaveOverlay1 img:first-child {
            width: 14rem;
          }

          .recieptwhiteWaveOverlay1 {
            position: absolute;
            top: 0;
            right: 0;
          }

          .checkoutDivR {
            padding-left: 4vw;
            padding-right: 4vw;
          }

          .checkout-d-bb {
            display: none;
          }

          .thankText {
            font-family: Mont-SemiBold;
            font-size: 1.5rem;
            margin-top: 4vh;
          }

          .purchaseDesc {
            margin-top: 3vh;
            width: 100%;
            font-family: Mont-Regular;
            font-size: 1rem;
            line-height: 150%;
          }

          .vectorBackground {
            margin-left: 0%;
            width: 90vw;
            margin-top: 8%;
          }

          .vectorBackground img {
            width: 100%;
          }

          .recieptBottom {
            margin-top: 8%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            padding-bottom: 4vh;
          }

          .recieptBottom button:first-child {
            width: 100%;
            margin-bottom: 3%;
          }

          .recieptBottom button:last-child {
            width: 100%;
          }
        }
      </style>
`;

const templateReceiptPage = mainBody.outerHTML;
export { templateReceiptPage };