import { legacy_createStore as createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension';
import { loginReducer, registerUserReducer } from './Reducer/userReducer';
import { deleteProjectReducer, getAllProjectReducer, updateProjectReducer } from './Reducer/projectReducer'
import { pageByProjectIdReducer, getAllPagesReducer, updatePageReducer, deletePageReducer, copyPageReducer } from './Reducer/pageReducer'
import { buildByProjectIdReducer, generateBuildByProjectIdReducer } from './Reducer/buildReducer'
import { getUserProfileReducer } from './Reducer/userProfileReducer'

import { getAllModalReducer, updateAllModalReducer } from './Reducer/modalTableReducer';
import { getAllCustomTablesReducer } from './Reducer/customTablesReducer';
import { getRequestListReducer, updateRequestListReducer, deleteRequestListReducer, getUsersUnderCompanyReducer, updateUsersUnderCompanyReducer, deleteUsersUnderCompanyReducer } from './Reducer/adminScreenReducer'

import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import { selectProjectReducer } from './Reducer/selectedProjectReducer';
import { getFunctionsReducer } from './Reducer/functionsReducer';
import { createFunctionsReducer } from './Reducer/createFunctionsReducer';

// import { getAllCustomTablesReducer } from './Reducer/customTablesReducer';


const reducer = combineReducers({
    userLogin: loginReducer,
    userRegister: registerUserReducer,
    allProjectData: getAllProjectReducer,
    pageByProject_id: pageByProjectIdReducer,
    buildByProject_id: buildByProjectIdReducer,
    generateBuildByProject_id: generateBuildByProjectIdReducer,
    allPagesList: getAllPagesReducer,
    updatedPage: updatePageReducer,
    updatedProject: updateProjectReducer,
    delProject: deleteProjectReducer,
    delPage: deletePageReducer,
    userProfile: getUserProfileReducer,
    allModal: getAllModalReducer,
    customTables: getAllCustomTablesReducer,
    adminRequest: getRequestListReducer,
    updateRequest: updateRequestListReducer,
    deleteRequest: deleteRequestListReducer,
    getUserList: getUsersUnderCompanyReducer,
    updateUserList: updateUsersUnderCompanyReducer,
    deleteUserList: deleteUsersUnderCompanyReducer,
    updateModal: updateAllModalReducer,
    copyPage: copyPageReducer,
    selectedProject: selectProjectReducer,
    functions: getFunctionsReducer,
    createNewFunc: createFunctionsReducer
})

const userInfoFromStorage = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : null;

const initialState = {
    userLogin: { userInfo: userInfoFromStorage },


}

const middleware = [thunk]


const persistConfig = {
    key: 'root',
    storage,
}

const persistedReducer = persistReducer(persistConfig, reducer)



// export default () => {
export const store = createStore(
    persistedReducer,
    initialState,
    composeWithDevTools(applyMiddleware(...middleware))
)

export const persistor = persistStore(store)
// return { store, persistor }
// }



// const store = createStore(
//     reducer,
//     initialState,
//     composeWithDevTools(applyMiddleware(...middleware))
// )

export default store;