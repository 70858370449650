import { SELECT_PROJECT_SUCCESS, SELECT_PROJECT_FAILED } from "../Constants/selectedProjectConstants";

export const selectProjectReducer = (state = { selected_project: "" }, action) => {
    switch(action.type) {
        case SELECT_PROJECT_SUCCESS:
            return {
                selected_project: action.payload.data
            }

        case SELECT_PROJECT_FAILED: {
            return {
                error: "Failed"
            }
        }
        default: 
            return state;
    }
}