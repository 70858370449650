import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  useCallback,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPageByProjectId } from "../Redux/Actions/pageActions";
import { getAllProject } from "../Redux/Actions/projectActions";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "./Loader";
import { Button, Grid, Typography, Modal, TextField } from "@mui/material";
import darkBubble from "./component_assets/images/darkBubble.png";
// import darkBubble from './component_assets/images/details_close.png'
// import lightBubble from './component_assets/images/details_close.png'
import lightBubble from "./component_assets/images/lightBubble.png";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import "./style.css";
// import errorSymbol from './component_assets/images/details_close.png'
import errorSymbol from "./component_assets/images/errorSymbol.svg";
// import btnGrp from './component_assets/images/details_close.png'
import btnGrp from "./component_assets/images/btnGrpRed.svg";
// import errorWave from './component_assets/images/details_close.png'
import errorWave from "./component_assets/images/errorWave.svg";
import DeleteIcon from "@mui/icons-material/Delete";
import RateReviewIcon from "@mui/icons-material/RateReview";
import { ToastContainer } from "react-toastify";
import {
  updatePage,
  copyPageAction,
  deletePage,
} from "../Redux/Actions/pageActions";
import CloseIcon from "@mui/icons-material/Close";
import HomeIcon from "@mui/icons-material/Home";
import WysiwygIcon from "@mui/icons-material/Wysiwyg";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import { FiSearch } from "react-icons/fi";
import axios from "axios";
import { API_HOST } from "./../api_utils";

function ProjectDetail() {
  let dispatch = useDispatch();
  let navigate = useNavigate();

  const { projectId } = useParams();
  const [isUpdateFlow, setIsUpdateFlow] = useState(false);
  const { pageByProjectData, loading, error, emptyPage } = useSelector(
    (state) => state.pageByProject_id
  );
  const { allProjectData } = useSelector((state) => state.allProjectData);
  const { delPage } = useSelector((state) => state.delPage);
  const { success } = useSelector((state) => state.updatedPage);
  const { copyPageData } = useSelector((state) => state.copyPage);

  const [modalData, setModalData] = useState({});
  const [open, setOpen] = useState(false);
  const [name, setName] = useState("");
  const [metaDesc, setMetaDesc] = useState("");
  const [metaKey, setMetaKey] = useState("");
  const [metaTag, setMetaTag] = useState("");
  const [seo, setSeo] = useState("");
  const [openCopyModal, setOpenCopyModal] = useState(false);
  const [copyPageName, setCopyPageName] = useState("");
  const [copyPageId, setCopyPageId] = useState();
  const [isProjectAdmin, setIsProjectAdmin] = useState(false);

  const [deletePageModal, setDeletePageModal] = useState(false);
  const [deletingId, setDelectingId] = useState(null);
  const [deletingName, setDelectingName] = useState(null);

  const [filteredData, setFilteredData] = useState([]);
  const searchFilterOptionsShowNickname = useRef(true);
  const [pageNameFilter, setPageNameFilter] = useState("");
  const [selectedPageName, setSelectedPageName] = useState("");
  const [finalPageNameToFilterData, setFinalPageNameToFilterData] =
    useState("");
  const { userInfo } = useSelector((state) => state.userLogin);

  const grabMiddlewareInfo = async (project) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(
      `${API_HOST}create_custom_route/get_middleware/${project}`,
      config
    );

    if (!data.message) {
      setIsUpdateFlow(true);
    }
  };

  useEffect(() => {
    dispatch(getPageByProjectId(projectId));
    dispatch(getAllProject());
    grabMiddlewareInfo(projectId);
  }, [success, delPage, copyPageData]);

  const projectData = allProjectData?.find((x) => x._id === projectId);

  const editPageHandler = (id) => {
    navigate(`/editor/${id}?projectId=${projectId}`);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const deletePageHandler = (id) => {
    dispatch(deletePage(id));
    // e.stopPropagation()
  };

  const modalHandler = (project) => {
    navigate(`/alltables/${project}`);
  };

  useEffect(() => {
    const userInfoFromStorage = JSON.parse(localStorage.getItem("userInfo"));
    if (projectData && userInfoFromStorage) {
      if (projectData.admin === userInfoFromStorage.name) {
        setIsProjectAdmin(true);
      }
    }
  }, [projectData]);

  const columns = [
    { field: "id", headerName: "ID", width: 100 },

    {
      field: "pageName",
      headerName: "Page Name",
      width: 200,
      editable: true,
    },
    {
      field: "isHome",
      headerName: "Home Page",
      width: 100,
      editable: true,
    },
    // {
    //     field: 'seoTitle',
    //     headerName: 'Seo Title',
    //     width: 130,
    //     editable: true,
    // },
    // {
    //     field: 'metaDescription',
    //     headerName: 'Meta Description',
    //     width: 130,
    //     editable: true,
    // },
    // {
    //     field: 'metaTags',
    //     headerName: 'Meta Tags',
    //     width: 130,
    //     editable: true,
    // },
    // {
    //     field: 'metaKeywords',
    //     headerName: 'Meta Keywords',
    //     width: 130,
    //     editable: true,
    // },
    {
      field: "lastEdit",
      headerName: "Last Edit",
      width: 130,
      editable: true,
    },
    {
      field: "editPage",
      headerName: "Edit ",
      width: 70,
      renderCell: (params) => {
        return (
          <Typography
            onClick={(e) => editPageHandler(params.id, e)}
            sx={{
              color: "#9333ea",
              "&:hover": { color: "#c084fc", scale: "125%" },
            }}>
            <RateReviewIcon fontSize="medium" />
          </Typography>
        );
      },
    },
    {
      field: "Detail",
      headerName: "Detail",
      width: 70,
      renderCell: (params) => {
        return (
          <Typography
            onClick={() => {
              handleRowEventHandler(params);
            }}
            sx={{
              color: "#9333ea",
              "&:hover": { color: "#c084fc", scale: "125%" },
            }}>
            <RateReviewIcon fontSize="medium" />
          </Typography>
        );
      },
    },
    {
      field: "Modals",
      headerName: "Database Tables",
      width: 100,
      renderCell: (params) => {
        return (
          <Typography
            onClick={(e) => {
              e.stopPropagation();
              modalHandler(projectId);
            }}
            sx={{
              color: "#9333ea",
              "&:hover": { color: "#c084fc", scale: "125%" },
            }}>
            <WysiwygIcon />
          </Typography>
        );
      },
    },
    {
      field: "author",
      headerName: "Author",
      width: 130,
      editable: true,
    },
    {
      field: "Copy",
      headerName: "Copy Page",
      width: 100,
      renderCell: (params) => {
        return (
          <Typography
            onClick={(e) => {
              e.stopPropagation();
              setCopyPageId(params.id);
              setOpenCopyModal(true);
            }}
            sx={{
              color: "#ca8a04",
              "&:hover": { color: "#fde047", scale: "125%" },
            }}>
            <FileCopyIcon />
          </Typography>
        );
      },
    },
    {
      field: "Actions",
      headerName: "",
      width: 60,
      renderCell: (params) => {
        return (
          <Typography
            onClick={(event) => {
              event.stopPropagation();
              setDeletePageModal(true);
              setDelectingId(params.id);
              setDelectingName(params.row.pageName);
              setOpen(false);
              // deletePageHandler(params.id)
            }}
            sx={{
              color: "#e11d48",
              "&:hover": { color: "#fb7185", scale: "125%" },
            }}>
            <DeleteIcon fontSize="medium" />
          </Typography>
        );
      },
    },
  ];

  const rows =
    filteredData?.length > 0
      ? filteredData?.map((x) => {
          return {
            id: x._id,
            pageName: x.name !== "undefined" ? x.name : "---",
            seoTitle: x.seo_title !== "undefined" ? x.seo_title : "---",
            metaDescription:
              x.meta_description !== "undefined" ? x.meta_description : "---",
            metaKeywords:
              x.meta_keywords !== "undefined" ? x.meta_keywords : "---",
            metaTags: x.meta_tags !== "undefined" ? x.meta_tags : "---",
            author: x.author !== "undefined" ? x.author : "---",
            lastEdit: x.updatedAt !== "undefined" ? x.updatedAt : "---",
            isHome: x.is_home !== "undefined" ? x.is_home : false,
          };
        })
      : [
          {
            id: 1,
            projectName: "Snow",
            facebookId: "Jon",
            twitterId: "twitterId",
            linkedInId: "linkedInId",
            instagramId: "instagramId",
            analyticsCode: "analyticsCode",
          },
          {
            id: 2,
            projectName: "Snow",
            facebookId: "Jon",
            twitterId: "twitterId",
            linkedInId: "linkedInId",
            instagramId: "instagramId",
            analyticsCode: "analyticsCode",
          },
          {
            id: 3,
            projectName: "Snow",
            facebookId: "Jon",
            twitterId: "twitterId",
            linkedInId: "linkedInId",
            instagramId: "instagramId",
            analyticsCode: "analyticsCode",
          },
          {
            id: 4,
            projectName: "Snow",
            facebookId: "Jon",
            twitterId: "twitterId",
            linkedInId: "linkedInId",
            instagramId: "instagramId",
            analyticsCode: "analyticsCode",
          },
          {
            id: 5,
            projectName: "Snow",
            facebookId: "Jon",
            twitterId: "twitterId",
            linkedInId: "linkedInId",
            instagramId: "instagramId",
            analyticsCode: "analyticsCode",
          },
        ];

  const createPageHandler = () => {
    navigate(`/addNewPage/${projectId}`);
  };

  const generateJwtHandler = () => {
    navigate(`/generate_project_token/${projectId}`);
  };

  const handleRowEventHandler = (params) => {
    console.log("params", params);
    setModalData({
      name: params.row.pageName,
      seo_title: params.row.seoTitle,
      meta_description: params.row.metaDescription,
      meta_keywords: params.row.metaKeywords,
      meta_tags: params.row.metaTags,
      pageId: params.row.id,
      is_home: params.row.isHome,
    });
    setOpen(true);
  };

  const updateChangeHandler = () => {
    dispatch(
      updatePage({
        pageId: modalData.pageId,
        seo_title: seo,
        meta_description: metaDesc,
        meta_keywords: metaKey,
        meta_tags: metaTag,
        name: name,
      })
    );
    setOpen(false);
  };

  const handleCloseCopyModal = () => {
    setOpenCopyModal(false);
  };

  const createCopyPageHandler = () => {
    dispatch(copyPageAction({ pageId: copyPageId, page_name: copyPageName }));
    setOpenCopyModal(false);
    setCopyPageName("");
  };

  const handleCustomerNicknameChange = useCallback((e) => {
    setPageNameFilter(e.target.value);
  }, []);

  const filteredNicknameOptions = useMemo(() => {
    if (pageNameFilter && !selectedPageName) {
      const searchTerm = pageNameFilter.toLowerCase();
      const optionsStartingWithSearchTerm = [];

      pageByProjectData.forEach((option) => {
        if (option.name && option.name.toLowerCase().startsWith(searchTerm)) {
          optionsStartingWithSearchTerm.push(option);
        }
      });

      optionsStartingWithSearchTerm.sort((a, b) =>
        a.name.localeCompare(b.name, undefined, {
          sensitivity: "base",
        })
      );

      return optionsStartingWithSearchTerm;
    } else {
      if (selectedPageName) {
        setFinalPageNameToFilterData(selectedPageName);
        setSelectedPageName("");

        searchFilterOptionsShowNickname.current = false;
      } else {
        searchFilterOptionsShowNickname.current = true;
      }

      return [];
    }
  }, [pageNameFilter, selectedPageName]);

  useEffect(() => {
    if (!pageNameFilter) {
      setFilteredData(pageByProjectData);
    }
  }, [pageNameFilter]);

  useEffect(() => {
    if (finalPageNameToFilterData) {
      let newFilteredData = pageByProjectData.filter(
        (item) => item.name === finalPageNameToFilterData
      );
      setFilteredData(newFilteredData);
    }

    return () => {
      setFinalPageNameToFilterData("");
    };
  }, [finalPageNameToFilterData]);

  useEffect(() => {
    if (pageByProjectData) {
      setFilteredData(pageByProjectData);
    }
  }, [pageByProjectData]);

  return (
    <div className="">
      {loading ? (
        <Loader />
      ) : emptyPage ? (
        <Grid
          sx={{
            height: "87.5vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            position: "relative",
            overflow: "hidden",
          }}>
          <Grid
            width={"80vw"}
            height={"80%"}
            sx={{
              padding: "2vh 0 2vh 3vw",
              borderRadius: "8px",
              backdropFilter: "blur(25px)",
              boxShadow: 20,
              zIndex: 20,
            }}>
            <Grid
              xs={12}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}>
              <Typography
                sx={{ fontFamily: "Mont-SemiBold", fontSize: "1.5rem" }}>
                Pages Created
              </Typography>
              <Grid
                xs={10}
                sx={{ backgroundColor: "red", height: "0.6vh" }}></Grid>
            </Grid>
            <Grid sx={{ display: "flex", justifyContent: "center", mt: 6 }}>
              <img src={errorSymbol} style={{ height: "30vh" }} />
            </Grid>
            <Typography
              sx={{
                textAlign: "center",
                mt: 4,
                fontSize: "1.2rem",
                fontFamily: "Mont-SemiBold",
              }}>
              Sorry! No pages have been created yet.{" "}
            </Typography>
            <Grid sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
              <Button
                onClick={createPageHandler}
                variant="contained"
                sx={{
                  backgroundColor: "white",
                  color: "black",
                  fontFamily: "Mont-Regular",
                }}>
                <span style={{ marginRight: "1vw" }}>Create New Page</span>
                <img src={btnGrp} style={{ height: "4vh" }} />
              </Button>
            </Grid>
          </Grid>
          <Grid sx={{ position: "absolute", bottom: "-80vh", right: "-20vw" }}>
            <img src={lightBubble} style={{ height: "160vh" }} />
          </Grid>
          <Grid sx={{ position: "absolute", top: "-12vh", right: "0" }}>
            <img src={errorWave} style={{ height: "180vh" }} />
          </Grid>
        </Grid>
      ) : (
        <Grid
          sx={{ position: "relative", overflow: "hidden", height: "100vh" }}>
          <Grid
            sx={{
              position: "absolute",
              top: "-12vh",
              left: "-30vw",
              zIndex: "-10",
            }}>
            <img style={{ width: "55vw", height: "90%" }} src={darkBubble} />
          </Grid>

          <Grid sx={{ position: "absolute", bottom: "-60%", right: "-15%" }}>
            <img style={{ width: "55vw", height: "90%" }} src={lightBubble} />
          </Grid>

          <Grid
            sx={{
              display: "flex",
              justifyContent: "center",
              paddingTop: "5vh",
            }}>
            <Box
              sx={{
                minHeight: "67vh",
                width: "90%",
                backdropFilter: "blur(25)",
                backgroundColor: "",
              }}>
              <Grid
                xs={12}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
                container>
                <Typography
                  sx={{ fontFamily: "Mont-SemiBold", fontSize: "1.8rem" }}>
                  Project Name : {projectData?.project_name}
                </Typography>
                <Grid
                  xs={8}
                  sx={{ height: "1.5vh", backgroundColor: "red" }}
                  item></Grid>
              </Grid>
              <Grid sx={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ position: "relative", width: "50%" }}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      borderRadius: "10px",
                      border: "1.5px solid #c1c4c8",
                      paddingLeft: "0.5rem",
                      backgroundColor: "white",
                    }}>
                    <FiSearch fontSize="x-large" />
                    <input
                      type="text"
                      value={pageNameFilter}
                      placeholder="Search By Page Name"
                      onInput={(e) => {
                        handleCustomerNicknameChange(e);
                      }}
                      style={{
                        outline: "none",
                        border: "none",
                        width: "80%",
                        paddingTop: "0.5rem",
                        paddingBottom: "0.5rem",
                        marginLeft: "0.5rem",
                      }}
                    />
                  </div>

                  {filteredNicknameOptions.length > 0 && (
                    <div
                      style={{ zIndex: 11, top: "3rem" }}
                      className={
                        searchFilterOptionsShowNickname.current
                          ? "position-absolute  lg-max-h-12rem  xl-max-h-12rem  2xl-max-h-13rem overflow-auto w-100 rounded-lg shadow d-block "
                          : "d-none"
                      }>
                      {filteredNicknameOptions.map((option, index) => (
                        <div
                          className="custom-search-filter"
                          onClick={() => {
                            setSelectedPageName(option.name);
                            setPageNameFilter(option.name);
                          }}
                          key={index}>
                          {option.name}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
                <div style={{ display: "flex" }}>
                  <Button
                    onClick={createPageHandler}
                    variant="contained"
                    sx={{
                      fontFamily: "Mont-Regular",
                      backgroundColor: "red",
                      marginBottom: "3vh",
                      "&:hover": { color: "red", backgroundColor: "white" },
                      transitionDuration: "0.5s",
                    }}>
                    Create New Page
                  </Button>

                  <Button
                    onClick={generateJwtHandler}
                    variant="contained"
                    sx={{
                      fontFamily: "Mont-Regular",
                      backgroundColor: "red",
                      marginBottom: "3vh",
                      "&:hover": { color: "red", backgroundColor: "white" },
                      transitionDuration: "0.5s",
                      ml: 2,
                    }}>
                    {isUpdateFlow ? " Update Jwt Auth" : " Create Jwt Auth"}
                  </Button>
                  {isProjectAdmin && (
                    <Button
                      onClick={() => navigate(`/addUserToProject/${projectId}`)}
                      variant="contained"
                      sx={{
                        fontFamily: "Mont-Regular",
                        backgroundColor: "red",
                        marginBottom: "3vh",
                        "&:hover": { color: "red", backgroundColor: "white" },
                        transitionDuration: "0.5s",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        ml: 2,
                      }}>
                      <span style={{ marginRight: "0.5rem" }}>Add Users</span>
                      <PersonAddAlt1Icon />
                    </Button>
                  )}
                </div>
              </Grid>
              <DataGrid
                rows={rows}
                columns={columns}
                pageSize={10}
                onRowClick={(params, event) =>
                  handleRowEventHandler(params, event)
                }
                error={error}
                rowsPerPageOptions={[10]}
                checkboxSelection
                disableSelectionOnClick
                experimentalFeatures={{ newEditingApi: true }}
                initialState={{
                  sorting: {
                    sortModel: [{ field: "lastEdit", sort: "desc" }],
                  },
                }}
                // sx={{outline: '5px solid purple'}}
              />
            </Box>
          </Grid>
        </Grid>
      )}

      <Modal
        open={open}
        onClose={handleClose}
        sx={{
          border: "none",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}>
        <Box
          sx={{
            width: { lg: "80vw" },
            padding: "2vh 2vw 6vh 2vw",
            outline: "none",
            borderRadius: "6px",
            backgroundColor: "white",
          }}>
          <Grid sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              onClick={handleClose}
              sx={{ boxShadow: 2, color: "red", "&:hover": { boxShadow: 5 } }}>
              <CloseIcon />
            </Button>
          </Grid>
          <Grid
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mt: 2,
            }}>
            <Typography
              sx={{ fontFamily: "Mont-SemiBold", fontSize: "1.3rem" }}>
              Update Page
            </Typography>
            <Grid
              item
              xs={10.2}
              sx={{ backgroundColor: "red", height: "1vh" }}></Grid>
          </Grid>
          <Grid
            sx={{ mt: 4, display: "flex", justifyContent: "space-between" }}>
            <TextField
              onChange={(e) => setName(e.target.value)}
              id="outlined-basic"
              label="Name"
              variant="outlined"
              sx={{ width: "36vw" }}
              defaultValue={modalData?.name}
            />
            <TextField
              onChange={(e) => setSeo(e.target.value)}
              defaultValue={modalData?.seo_title}
              id="outlined-basic"
              label="Seo Title"
              variant="outlined"
              sx={{ width: "36vw" }}
            />
          </Grid>
          <Grid
            sx={{ mt: 4, display: "flex", justifyContent: "space-between" }}>
            <TextField
              onChange={(e) => setMetaDesc(e.target.value)}
              defaultValue={modalData?.meta_description}
              id="outlined-basic"
              label="Meta Description"
              variant="outlined"
              sx={{ width: "36vw" }}
            />
            <TextField
              onChange={(e) => setMetaTag(e.target.value)}
              defaultValue={modalData?.meta_tags}
              id="outlined-basic"
              label="Meta Tags"
              variant="outlined"
              sx={{ width: "36vw" }}
            />
          </Grid>
          <Grid
            sx={{ mt: 4, display: "flex", justifyContent: "space-between" }}>
            <TextField
              onChange={(e) => setMetaKey(e.target.value)}
              defaultValue={modalData?.meta_keywords}
              id="outlined-basic"
              label="Meta Keywords"
              variant="outlined"
              sx={{ width: "36vw" }}
            />
            <TextField
              onChange={(e) => setMetaTag(e.target.value)}
              defaultValue={modalData?.is_home}
              id="outlined-basic"
              label="Home Page"
              variant="outlined"
              sx={{ width: "36vw" }}
            />
          </Grid>
          <Grid sx={{ mt: 4, display: "flex", justifyContent: "flex-end" }}>
            <Button
              onClick={updateChangeHandler}
              variant="contained"
              sx={{
                backgroundColor: "red",
                "&:hover": { color: "red", backgroundColor: "white" },
              }}>
              Save Changes
            </Button>
          </Grid>
        </Box>
      </Modal>

      <Modal
        open={openCopyModal}
        onClose={handleCloseCopyModal}
        sx={{
          border: "none",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}>
        <Box
          sx={{
            width: { lg: "80vw" },
            padding: "2vh 2vw 6vh 2vw",
            outline: "none",
            borderRadius: "6px",
            backgroundColor: "white",
          }}>
          <Grid sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              onClick={handleCloseCopyModal}
              sx={{ boxShadow: 2, color: "red", "&:hover": { boxShadow: 5 } }}>
              <CloseIcon />
            </Button>
          </Grid>
          <Grid
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mt: 2,
            }}>
            <Typography
              sx={{ fontFamily: "Mont-SemiBold", fontSize: "1.3rem" }}>
              Set Page Name
            </Typography>
            <Grid
              item
              xs={10.2}
              sx={{ backgroundColor: "red", height: "1vh" }}></Grid>
          </Grid>
          <Grid
            sx={{ mt: 4, display: "flex", justifyContent: "space-between" }}>
            <TextField
              onChange={(e) => setCopyPageName(e.target.value)}
              defaultValue={copyPageName}
              id="outlined-basic"
              label="Provide a Page Name"
              variant="outlined"
              sx={{ width: "36vw" }}
            />
            <Button
              onClick={createCopyPageHandler}
              disabled={!copyPageName.length}
              variant="contained"
              sx={{
                backgroundColor: "red",
                "&:hover": { color: "red", backgroundColor: "white" },
              }}>
              Create Copy
            </Button>
          </Grid>
        </Box>
      </Modal>

      {/* delete modal */}
      {deletePageModal && (
        <div
          style={{
            position: "absolute",
            top: "0",
            right: "0",
            bottom: "0",
            left: "0",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          }}>
          <div
            style={{
              position: "absolute",
              top: "30%",
              left: "40%",
              width: "25%",
              height: "max-content",
              background: "white",
              borderRadius: "10px",
              paddingTop: "2em",
              paddingBottom: "2em",
              paddingLeft: "1em",
              paddingRight: "1em",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}>
            <h4 style={{ textAlign: "left", lineHeight: "0.5" }}>
              Delete Page -
            </h4>
            <p
              style={{
                textAlign: "left",
                borderBottom: "1px solid black",
                lineHeight: "3",
                width: "100%",
              }}>
              {deletingName}
            </p>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                columnGap: "1vw",
              }}>
              <button
                onClick={(e) => {
                  deletePageHandler(deletingId);
                  setDeletePageModal(false);
                }}
                style={{
                  border: "none",
                  color: "white",
                  borderRadius: "10px",
                  padding: "0.4em",
                  background: "red",
                }}>
                Delete
              </button>

              <button
                onClick={(e) => setDeletePageModal(false)}
                style={{
                  border: "none",
                  color: "white",
                  borderRadius: "10px",
                  padding: "0.4em",
                  background: "red",
                }}>
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}

      <ToastContainer autoClose={2000} />
    </div>
  );
}

export default ProjectDetail;
