import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getPageByProjectId } from '../Redux/Actions/pageActions'
import { getAllProject } from '../Redux/Actions/projectActions'
import { useNavigate, useParams } from 'react-router-dom'
import Loader from './Loader'
import { Button, Grid, Typography, Modal, TextField, Select, MenuItem } from '@mui/material'
import darkBubble from './component_assets/images/darkBubble.png'
// import darkBubble from './component_assets/images/details_close.png'
// import lightBubble from './component_assets/images/details_close.png'
import lightBubble from './component_assets/images//lightBubble.png'
import './style.css'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import axios from 'axios'
import { API_HOST } from '../api_utils'
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';


function AddUserToProject() {

    const [userListUnderCompany, setUserListUnderCompany] = useState([])
    const [loading, setLoading] = useState(false)
    const [rows, setRows] = useState([])
    const [addUserProjectPayload, setAddUserProjectPayload] = useState({
        access: "",
        _id: ''
    })



    const errorTheme = {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
    }

    const { projectId } = useParams()



    const getListOfUsersUnderCompany = async () => {
        setLoading(true)
        const userInfo = JSON.parse(localStorage.getItem('userInfo'))
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }
        const { data } = await axios.get(`${API_HOST}projects/user_under_company/${projectId}`, config);

        if (data.message) {
            setLoading(false)
            toast.error(data.message, errorTheme)
        } else {
            setLoading(false)
            setUserListUnderCompany(data)
        }
    }

    useEffect(() => {
        getListOfUsersUnderCompany()
    }, [])

    function createData(id, name) {
        return { id, name, access: "" };
    }

    useEffect(() => {
        if (userListUnderCompany.length) {
            const userInfo = JSON.parse(localStorage.getItem('userInfo'))
            const filteredUser = userListUnderCompany.filter((user) => user._id !== userInfo._id)
            const rows1 = filteredUser.map((user) => {
                return createData(user._id, user.name)
            })
            setRows(rows1)
        }
    }, [userListUnderCompany])

    const handleAccessChange = (event, row) => {
        const { value } = event.target;
        setRows((prevRows) =>
            prevRows.map((prevRow) =>
                prevRow.id === row.id ? { ...prevRow, access: value } : prevRow
            )
        );
    };


    const addUserToProjectHandler = async (rowId) => {
        setLoading(true);
        const userInfo = JSON.parse(localStorage.getItem('userInfo'));
        console.log("rows", rows);
        const selectedRows = rows.filter((row) => row.access && row.access !== 'none' && row.id);

        console.log('selectedRows', selectedRows)

        if (selectedRows.length > 0) {
            const filteredSelectedRows = selectedRows.find((row) => row.id === rowId)
            if (filteredSelectedRows) {
                const config = {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${userInfo.token}`,
                    },
                };

                const payload = {
                    project_id: projectId,
                    user: {
                        _id: filteredSelectedRows.id,
                        access: filteredSelectedRows.access
                    }
                };

                try {
                    const { data } = await axios.post(`${API_HOST}projects/${projectId}`, payload, config);

                    if (data.message) {
                        setLoading(false);
                        toast.error(data.message, errorTheme);
                    } else {
                        setLoading(false);
                        toast.success('Users Added To The Project Successfully !!!', errorTheme);
                    }
                } catch (error) {
                    setLoading(false);
                    toast.error('An error occurred while adding the users to the project.', errorTheme);
                }
            } else {
                toast.warn('Please Assign User Roles for the selected user !!!', errorTheme);
            }
        } else {
            toast.warn('Please Assign User Roles for the Project !!!', errorTheme);
        }
    };


    return (
        <div>
            <Grid sx={{ position: 'relative', overflow: 'hidden', height: '100vh', }}>
                <Grid sx={{ position: 'absolute', top: '-12vh', left: '-30vw', zIndex: '-10' }}>
                    <img style={{ width: '55vw', height: '90%' }} src={darkBubble} />
                </Grid>


                <Grid sx={{ position: 'absolute', bottom: '-60%', right: '-15%', zIndex: '-10' }}>
                    <img style={{ width: '55vw', height: '90%' }} src={lightBubble} />
                </Grid>

                <Grid sx={{ display: 'flex', justifyContent: "center", paddingTop: '10vh' }}>
                    <Grid xs={12} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '0 3.5rem' }} container>
                        <Typography sx={{ fontFamily: 'Mont-SemiBold', fontSize: '1.8rem' }}>ADD USER'S TO PROJECT</Typography>
                        <Grid xs={8.5} sx={{ height: '1.5vh', backgroundColor: 'red' }} item ></Grid>
                    </Grid>
                </Grid>

                <Grid xs={12} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: '20', padding: '0 3.5rem 5rem 3.5rem', mt: 4 }} container>
                    <TableContainer component={Paper} sx={{ backgroundColor: 'rgba(255, 255, 255, 0.5)', backdropFilter: 'blur(5px)', height: '500px', overflowY: 'scroll' }} >
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center" sx={{ fontWeight: 600, fontSize: '1rem' }}>Employee ID</TableCell>
                                    <TableCell align="center" sx={{ fontWeight: 600, fontSize: '1rem' }}>Employee Name</TableCell>
                                    <TableCell align="center" sx={{ fontWeight: 600, fontSize: '1rem' }}>Assign Access</TableCell>
                                    <TableCell align="center" sx={{ fontWeight: 600, fontSize: '1rem' }}>Add Employee</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows.length && rows.map((row, index) => (
                                    <TableRow
                                        key={`${row.id}+${index}`}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell align="center" component="th" scope="row">
                                            {row.id}
                                        </TableCell>
                                        <TableCell align="center">{row.name}</TableCell>
                                        <TableCell align="center">
                                            <Select sx={{ width: '5rem' }} value={row?.access} onChange={(event) => handleAccessChange(event, row)}>
                                                <MenuItem value="none">None</MenuItem>
                                                <MenuItem value="edit">Edit</MenuItem>
                                                <MenuItem value="view">View</MenuItem>
                                            </Select>
                                        </TableCell>
                                        <TableCell align="center" sx={{ display: "flex", justifyContent: 'center', alignItems: 'center' }}>
                                            <Button onClick={() => addUserToProjectHandler(row.id)} variant='contained' sx={{ fontFamily: 'Mont-Regular', backgroundColor: 'red', marginBottom: '3vh', '&:hover': { color: 'red', backgroundColor: 'white' }, transitionDuration: '0.5s', display: "flex", justifyContent: "space-between", alignItems: 'center', ml: 2 }}>
                                                <span style={{ marginRight: '0.5rem' }}>Add User</span>
                                                <PersonAddAlt1Icon />
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>

            </Grid>
            <ToastContainer autoClose={2000} />
        </div>
    )
}

export default AddUserToProject