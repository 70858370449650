export default class BlocklyEditor {
  constructor(div, opts = {}, editor, blockTypes, projectId) {
    console.log("editor::::::::::123", editor);
    console.log("this::::::::", this);

    console.log("editor selector:::::", editor.getSelected());

    console.log("traits of selected", editor.getSelected().getTraits());

    console.log("projectId inside LB::::::::::", projectId);
    let categoryXml = "";

    let baseUrlData = editor.config.baseUrl1;
    let baseUrlData1 = editor.config.baseUrl2;
    let baseUrlData2 = editor.config.baseUrl3;
    let baseUrlData3 = editor.config.baseUrl4;
    let baseUrlData4 = editor.config.baseUrl5;
    let baseUrl1BearerToken = editor.config.baseUrl1BearerToken;
    let baseUrl1BearerToken1 = editor.config.baseUrl2BearerToken;
    let baseUrl1BearerToken2 = editor.config.baseUrl3BearerToken;
    let baseUrl1BearerToken3 = editor.config.baseUrl4BearerToken;
    let baseUrl1BearerToken4 = editor.config.baseUrl5BearerToken;

    if (baseUrlData) {
      categoryXml += `<category name="baseUrlsBlocks" colour="210">`;
      categoryXml += `<block type="baseUrls"></block>`;
      // console.log("baseUrlData p::::::::::::", baseUrlData);

      Blockly.Blocks["baseUrls"] = {
        init: function () {
          this.appendDummyInput()
            .appendField("baseUrls")
            .appendField(
              new Blockly.FieldDropdown([
                [`baseUrl1`, `${baseUrlData}`],
                [`baseUrl2`, `${baseUrlData1}`],
                [`baseUrl3`, `${baseUrlData2}`],
                [`baseUrl4`, `${baseUrlData3}`],
                [`baseUrl5`, `${baseUrlData4}`],
                // Add more options as needed
              ]),
              "MY_DROPDOWN"
            );

          this.appendValueInput("VALUE")
            .setCheck(["String", "Variable"])
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField("Value");

          this.setOutput(true, null); // Keep this line to make it an output block
          this.setColour(160);
          this.setTooltip(`Redirect to ${baseUrlData} page`);
          this.setHelpUrl("");
        },
      };

      Blockly.JavaScript["baseUrls"] = function (block) {
        const dropdownValue = block.getFieldValue("MY_DROPDOWN");
        const blockValue = Blockly.JavaScript.valueToCode(
          block,
          "VALUE",
          Blockly.JavaScript.ORDER_ATOMIC
        );

        // Ensure proper string representation and handle spaces
        const formattedBlockValue = blockValue
          ? `${blockValue.slice(1, -1)}`
          : "";

        let baseUrlSelected;

        if (dropdownValue === `${baseUrlData}`) {
          baseUrlSelected = "{{API_URL1}}";
        } else if (dropdownValue === `${baseUrlData1}`) {
          baseUrlSelected = "{{API_URL2}}";
        } else if (dropdownValue === `${baseUrlData2}`) {
          baseUrlSelected = "{{API_URL3}}";
        } else if (dropdownValue === `${baseUrlData3}`) {
          baseUrlSelected = "{{API_URL4}}";
        } else if (dropdownValue === `${baseUrlData4}`) {
          baseUrlSelected = "{{API_URL5}}";
        } else {
          baseUrlSelected = "{{API_URL6}}";
        }

        // let valNew = `${dropdownValue}${formattedBlockValue}`;

        let valNew = `
           if(window.location.href.includes("editor") || window.location.href.includes("large_preview")){
            return "${dropdownValue}${formattedBlockValue}"
          }else{
           return "${baseUrlSelected}${formattedBlockValue}"
          }
        `;
        return [`${valNew}`, Blockly.JavaScript.ORDER_ATOMIC];
      };

      categoryXml += `<block type="baseUrlsV2"></block>`;
      // console.log("baseUrlData p::::::::::::", baseUrlData);

      Blockly.Blocks["baseUrlsV2"] = {
        init: function () {
          this.appendDummyInput()
            .appendField("baseUrls V2")
            .appendField(
              new Blockly.FieldDropdown([
                [`baseUrl1`, `${baseUrlData}`],
                [`baseUrl2`, `${baseUrlData1}`],
                [`baseUrl3`, `${baseUrlData2}`],
                [`baseUrl4`, `${baseUrlData3}`],
                [`baseUrl5`, `${baseUrlData4}`],
                // Add more options as needed
              ]),
              "MY_DROPDOWN"
            );

          this.appendValueInput("VALUE")
            .setCheck(["String", "Variable"])
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField("Value");
          this.appendValueInput("RES_VAR")
            .setCheck(null)
            .appendField("Store Result In Variable :");

          this.setPreviousStatement(true, null);
          this.setNextStatement(true, null);
          this.setColour(160);
          this.setTooltip(`Redirect to ${baseUrlData} page`);
          this.setHelpUrl("");
        },
      };

      Blockly.JavaScript["baseUrlsV2"] = function (block) {
        const dropdownValue = block.getFieldValue("MY_DROPDOWN");
        const blockValue = Blockly.JavaScript.valueToCode(
          block,
          "VALUE",
          Blockly.JavaScript.ORDER_ATOMIC
        );

        const resVar = Blockly.JavaScript.valueToCode(
          block,
          "RES_VAR",
          Blockly.JavaScript.ORDER_ATOMIC
        );

        // Ensure proper string representation and handle spaces
        const formattedBlockValue = blockValue
          ? `${blockValue.slice(1, -1)}`
          : "";

        let baseUrlSelected;

        if (dropdownValue === `${baseUrlData}`) {
          baseUrlSelected = "{{API_URL1}}";
        } else if (dropdownValue === `${baseUrlData1}`) {
          baseUrlSelected = "{{API_URL2}}";
        } else if (dropdownValue === `${baseUrlData2}`) {
          baseUrlSelected = "{{API_URL3}}";
        } else if (dropdownValue === `${baseUrlData3}`) {
          baseUrlSelected = "{{API_URL4}}";
        } else if (dropdownValue === `${baseUrlData4}`) {
          baseUrlSelected = "{{API_URL5}}";
        } else {
          baseUrlSelected = "{{API_URL6}}";
        }

        // let valNew = `${dropdownValue}${formattedBlockValue}`;

        let valNew = `
           if(window.location.href.includes("editor") || window.location.href.includes("large_preview")){
            ${resVar}= "${dropdownValue}${formattedBlockValue}"
          }else{
           ${resVar}= "${baseUrlSelected}${formattedBlockValue}"
          }
        `;
        return `${valNew}`;
      };

      categoryXml += `<block type="tokens"></block>`;

      Blockly.Blocks["tokens"] = {
        init: function () {
          this.appendDummyInput()
            .appendField("tokens")
            .appendField(
              new Blockly.FieldDropdown([
                [`token1`, `${baseUrl1BearerToken}`],
                [`token2`, `${baseUrl1BearerToken1}`],
                [`token3`, `${baseUrl1BearerToken2}`],
                [`token4`, `${baseUrl1BearerToken3}`],
                [`token5`, `${baseUrl1BearerToken4}`],
                // Add more options as needed
              ]),
              "MY_DROPDOWN"
            );

          this.setOutput(true, null);
          this.setColour(160);
          this.setTooltip(`Redirect to ${baseUrlData} page`);
          this.setHelpUrl("");
        },
      };

      Blockly.JavaScript["tokens"] = function (block) {
        const dropdownValue = block.getFieldValue("MY_DROPDOWN");

        let valNew = `${dropdownValue}`;
        return [`"Bearer ${valNew}"`, Blockly.JavaScript.ORDER_ATOMIC];
      };

      categoryXml += `<block type="tokensV2"></block>`;

      Blockly.Blocks["tokensV2"] = {
        init: function () {
          this.appendDummyInput()
            .appendField("tokens V2")
            .appendField(
              new Blockly.FieldDropdown([
                [`token1`, "baseUrl1_"],
                [`token2`, "baseUrl2_"],
                [`token3`, "baseUrl3_"],
                [`token4`, "baseUrl4_"],
                [`token5`, "baseUrl5_"],
                // Add more options as needed
              ]),
              "MY_DROPDOWN"
            );
          this.appendValueInput("RES_VAR")
            .setCheck(null)
            .appendField("Store Result In Variable :");

          this.setPreviousStatement(true, null);
          this.setNextStatement(true, null);
          this.setColour(160);
          this.setTooltip(`Redirect to ${baseUrlData} page`);
          this.setHelpUrl("");
        },
      };

      Blockly.JavaScript["tokensV2"] = function (block) {
        const dropdownValue = block.getFieldValue("MY_DROPDOWN");
        const resVar = Blockly.JavaScript.valueToCode(
          block,
          "RES_VAR",
          Blockly.JavaScript.ORDER_ATOMIC
        );

        let valNew = `
        let ${dropdownValue}Ref = localStorage.getItem("baseUrlData");
        if(${dropdownValue}Ref){
          let ${dropdownValue}ParsedRef = JSON.parse(${dropdownValue}Ref);
         let ${resVar}Ref=${dropdownValue}ParsedRef["${dropdownValue}${projectId}"];
         if(${resVar}Ref){
        let ${resVar}Token=${resVar}Ref.token;
          ${resVar}= "Bearer"+' '+${resVar}Token
         }
        }
        `;
        return valNew;
      };

      categoryXml += `</category>`;
    }

    if (blockTypes) {
      const projectId = window.location.search.split("=")[1];

      blockTypes.forEach((blockType) => {
        Blockly.Blocks[blockType.name] = {
          init: function () {
            this.appendDummyInput().appendField(blockType.name);
            this.setPreviousStatement(true, null);
            this.setNextStatement(true, null);
            this.setColour(160);
            this.setTooltip(`Redirect to ${blockType.name} page`);
            this.setHelpUrl("");
          },
        };
      });

      for (const blockType of blockTypes) {
        Blockly.JavaScript[blockType.name] = function (block) {
          let pageId = blockType.id;

          let code;

          code = `
            if(window.location.href.includes("editor")){
              let origin ="${window.location.origin}/editor/${pageId}?projectId=${projectId}";
              return window.top.location.href=origin
            }else if (window.location.href.includes("large_preview")){
              let origin ="${window.location.origin}/large_preview/${pageId}";
              return window.top.location.href=origin
            }else{
              let origin ="{{HOST_URL}}/${pageId}.html";
              return window.top.location.href=origin
            }
            `;

          return code;
        };
      }

      categoryXml += `<category name="Project Pages" colour="210">
        ${blockTypes
          .map((blockType) => `<block type="${blockType.name}"></block>`)
          .join("\n")}
    </category>`;

      // GETTING PAGE LINK:
      blockTypes.forEach((blockType, index) => {
        let blockName = `${blockType.name}${index}`;
        Blockly.Blocks[blockName] = {
          init: function () {
            this.appendDummyInput().appendField(blockType.name);
            this.appendValueInput("RES_VAR")
              .appendField("Store Value In Variable : ")
              .setCheck(null);
            this.setColour(160);
            this.setTooltip(`Redirect to ${blockType.name}${index} page`);
            this.setHelpUrl("");
          },
        };

        Blockly.JavaScript[blockName] = function (block) {
          let pageId = blockType.id;
          let resVar = Blockly.JavaScript.valueToCode(
            block,
            "RES_VAR",
            Blockly.JavaScript.ORDER_ATOMIC
          );

          let code;

          code = `
            if(window.location.href.includes("editor")){
              ${resVar} ="${window.location.origin}/editor/${pageId}?projectId=${projectId}";
            }else if (window.location.href.includes("large_preview")){
              ${resVar} ="${window.location.origin}/large_preview/${pageId}";
            }else{
              ${resVar} ="{{HOST_URL}}/${pageId}.html";
            }
            `;

          return code;
        };
      });

      categoryXml += `<category name="Project Page Urls" colour="210">
        ${blockTypes
          .map(
            (blockType, index) =>
              `<block type="${blockType.name}${index}"></block>`
          )
          .join("\n")}
    </category>`;
    }

    const htmlContent = editor.DomComponents.getComponents();

    // console.log("htmlContent:::::::", htmlContent);
    categoryXml += '<category name="dynamicWrapper" colour="210">';

    function recursiveBlockAddition(parentComp, index) {
      // console.log("parentComp:::::::", parentComp);
      // console.log(` view comp childs-${index}`, parentComp.children);

      Array.from(parentComp.children).forEach((child, i) => {
        // console.log("child inside parentComp:::::::::", child);
        if (child.tagName === "SELECT" || child.tagName === "select") {
          categoryXml += `<block type="${child.tagName}-${child.id}"></block>`;

          Blockly.Blocks[`${child.tagName}-${child.id}`] = {
            init: function () {
              this.appendDummyInput().appendField(
                `${child.tagName}-${child.id}`
              );
              this.setOutput(true, "String");
              this.setColour(5);
              this.setTooltip(
                `Get the textContent of the ${child.tagName}-${child.id} `
              );
              this.setHelpUrl("");
            },
          };

          Blockly.JavaScript[`${child.tagName}-${child.id}`] = function (
            block
          ) {
            let val = child.value;
            const code = `'${val}'`;

            return [code, Blockly.JavaScript.ORDER_ATOMIC];
          };

          //   component instance
          categoryXml += `<block type="component_instance-${child.tagName}-${child.id}"></block>`;

          Blockly.Blocks[`component_instance-${child.tagName}-${child.id}`] = {
            init: function () {
              this.appendDummyInput().appendField(
                `component_instance-${child.tagName}-${child.id}`
              );
              this.appendStatementInput("Properties");
              this.setPreviousStatement(true, null);
              this.setNextStatement(true, null);
              this.setColour(5);
              this.setTooltip("Get Instance of the Component");
            },
          };

          Blockly.JavaScript[
            `component_instance-${child.tagName}-${child.id}`
          ] = function (block) {
            const properties = Blockly.JavaScript.statementToCode(
              block,
              "Properties"
            );

            var code = `
                                      var childElement${child.id} = document.querySelector("#${child.id}");
                                      if(childElement${child.id}){
                                          ${properties}
                                      }
                                      `;

            return code;
          };

          categoryXml += `<block type="manipulate-value-${child.tagName}-${child.id}"></block>`;

          Blockly.Blocks[`manipulate-value-${child.tagName}-${child.id}`] = {
            init: function () {
              this.appendDummyInput().appendField(
                `Set Property of ${child.tagName}-${child.id}`
              );
              this.appendDummyInput()
                .appendField("Property:")
                .appendField(
                  new Blockly.FieldDropdown([
                    ["value", "value"],
                    ["textContent", "textContent"],
                    ["multiple", "multiple"],
                  ]),
                  "PROPERTY"
                );
              this.appendValueInput("VALUE")
                .setCheck(null)
                .appendField("Value:");
              this.setPreviousStatement(true, null);
              this.setNextStatement(true, null);
              this.setColour(5);
              this.setTooltip("Set Property of the Component");
            },
          };

          Blockly.JavaScript[`manipulate-value-${child.tagName}-${child.id}`] =
            function (block) {
              const property = block.getFieldValue("PROPERTY");
              const value = Blockly.JavaScript.valueToCode(
                block,
                "VALUE",
                Blockly.JavaScript.ORDER_ATOMIC
              );

              var code = `
                  childElement${child.id}.${property} = ${value};
                  `;

              return code;
            };

          categoryXml += `<block type="custom_css_property-${child.tagName}-${child.id}"></block>`;

          //   for style manipulation:
          //   bbql
          Blockly.Blocks[`custom_css_property-${child.tagName}-${child.id}`] = {
            init: function () {
              this.appendDummyInput().appendField(
                `customise-styles-${child.tagName}-${child.id}`
              );
              this.appendValueInput("PROPERTY_NAME")
                .setCheck("String")
                .appendField("Property Name:");

              this.appendValueInput("PROPERTY_VALUE")
                .setCheck("String")
                .appendField("Value:");
              this.setPreviousStatement(true, null);
              this.setNextStatement(true, null);
              this.setColour(5);
              this.setTooltip("Specify a CSS property, its type, and value.");
            },
          };

          Blockly.JavaScript[
            `custom_css_property-${child.tagName}-${child.id}`
          ] = function (block) {
            const propertyName = Blockly.JavaScript.valueToCode(
              block,
              "PROPERTY_NAME",
              Blockly.JavaScript.ORDER_ATOMIC
            );

            const propertyValue = Blockly.JavaScript.valueToCode(
              block,
              "PROPERTY_VALUE",
              Blockly.JavaScript.ORDER_ATOMIC
            );

            var code = `
                                      childElement${child.id}.style[${propertyName}]=${propertyValue}
                                      `;

            return code;
          };

          //   js manipulation:
          categoryXml += `<block type="events-${child.tagName}-${child.id}"></block>`;

          //   for style manipulation:
          Blockly.Blocks[`events-${child.tagName}-${child.id}`] = {
            init: function () {
              this.appendDummyInput().appendField(
                `Component Events-${child.tagName}-${child.id}`
              );
              this.appendDummyInput()
                .appendField("On Event")
                .appendField(
                  new Blockly.FieldDropdown([
                    ["Click", "click"],
                    ["Change", "change"],
                  ]),
                  "EVENT"
                );
              this.appendStatementInput("DO").appendField("Do");
              this.setPreviousStatement(true, null);
              this.setNextStatement(true, null);
              this.setColour(5);
              this.setTooltip("Add event handler To the component");
              this.setHelpUrl("");
            },
          };

          Blockly.JavaScript[`events-${child.tagName}-${child.id}`] = function (
            block
          ) {
            var event = block.getFieldValue("EVENT");
            var code = "";
            var actionCode = Blockly.JavaScript.statementToCode(block, "DO");

            if (event && actionCode) {
              code += `
                  
                      childElement${child.id}.addEventListener('${event}', function(event) {
                        ${actionCode}
                      });
                  
                  `;
            }

            return code;
          };

          categoryXml += `<block type="target-property-${child.tagName}-${child.id}"></block>`;
          Blockly.Blocks[`target-property-${child.tagName}-${child.id}`] = {
            init: function () {
              this.appendDummyInput().appendField(
                `target-property-${child.tagName}-${child.id}`
              );
              this.setOutput(true, "String");
              this.setColour(5);
              this.setTooltip(
                `Get the event value of the ${child.tagName}-${child.id} Input`
              );
              this.setHelpUrl("");
            },
          };

          Blockly.JavaScript[`target-property-${child.tagName}-${child.id}`] =
            function (block) {
              const code = "event.target.value";

              return [code, Blockly.JavaScript.ORDER_ATOMIC];
            };

          categoryXml += `<block type="disable_component-${child.tagName}-${child.id}"></block>`;
          Blockly.Blocks[`disable_component-${child.tagName}-${child.id}`] = {
            init: function () {
              this.appendDummyInput().appendField(`Disable ${child.tagName}`);
              this.setPreviousStatement(true, null);
              this.setNextStatement(true, null);
              this.setColour(5);
              this.setTooltip("Disable the Component");
            },
          };

          Blockly.JavaScript[`disable_component-${child.tagName}-${child.id}`] =
            function (block) {
              var code = `
                          
                                childElement${child.id}.disabled = true;
                            
                        `;

              return code;
            };

          categoryXml += `<block type="selected-options-${child.tagName}-${child.id}"></block>`;
          Blockly.Blocks[`selected-options-${child.tagName}-${child.id}`] = {
            init: function () {
              this.appendDummyInput().appendField(
                `selected-options-${child.tagName}-${child.id}`
              );
              this.appendValueInput("RESPONSE_VARIABLE")
                .setCheck("Variable")
                .appendField("Store Response Data in Variable:");
              this.setPreviousStatement(true, null);
              this.setNextStatement(true, null);
              this.setColour(5);
              this.setTooltip(
                `Grab Selected Options From  ${child.tagName}-${child.id}`
              );
            },
          };

          Blockly.JavaScript[`selected-options-${child.tagName}-${child.id}`] =
            function (block) {
              const responseVariable = Blockly.JavaScript.valueToCode(
                block,
                "RESPONSE_VARIABLE",
                Blockly.JavaScript.ORDER_ASSIGNMENT
              );
              var code = `
              const selectedOptions = childElement${child.id}.selectedOptions;
              
              const uniqueValuesSet = new Set();
    
              for (const option of selectedOptions) {
                  uniqueValuesSet.add(option.value);
              }
    
              ${responseVariable} = Array.from(uniqueValuesSet);
            `;
              return code;
            };

          categoryXml += `<block type="selected-options-with-id-${child.tagName}-${child.id}"></block>`;
          Blockly.Blocks[
            `selected-options-with-id-${child.tagName}-${child.id}`
          ] = {
            init: function () {
              this.appendDummyInput().appendField(
                `selected-options-with-id-${child.tagName}-${child.id}`
              );
              this.appendValueInput("RESPONSE_VARIABLE")
                .setCheck("Variable")
                .appendField("Store Response Data in Variable:");
              this.setPreviousStatement(true, null);
              this.setNextStatement(true, null);
              this.setColour(5);
              this.setTooltip(
                `Grab Selected Options From  ${child.tagName}-${child.id}`
              );
            },
          };

          Blockly.JavaScript[
            `selected-options-with-id-${child.tagName}-${child.id}`
          ] = function (block) {
            const responseVariable = Blockly.JavaScript.valueToCode(
              block,
              "RESPONSE_VARIABLE",
              Blockly.JavaScript.ORDER_ASSIGNMENT
            );
            var code = `
            const selectedOptions = childElement${child.id}.selectedOptions;
            
            const uniqueValuesSet = new Set();
        
            for (const option of selectedOptions) {
                uniqueValuesSet.add({ value: option.value, _id: option.getAttribute('data_db_id') });
            }
        
            ${responseVariable} = Array.from(uniqueValuesSet);
          `;
            return code;
          };

          categoryXml += `<block type="component_instance-for-db-data-${child.tagName}-${child.id}"></block>`;

          Blockly.Blocks[
            `component_instance-for-db-data-${child.tagName}-${child.id}`
          ] = {
            init: function () {
              this.appendDummyInput().appendField(
                `component_instance-for-db-data-${child.tagName}-${child.id}`
              );
              this.appendStatementInput("Properties");
              this.setPreviousStatement(true, null);
              this.setNextStatement(true, null);
              this.setColour(5);
              this.setTooltip("Get Instance of the Component");
            },
          };

          Blockly.JavaScript[
            `component_instance-for-db-data-${child.tagName}-${child.id}`
          ] = function (block) {
            const properties = Blockly.JavaScript.statementToCode(
              block,
              "Properties"
            );

            var code = `
                    setTimeout(function() {
                                      var childElement${child.id} = document.querySelector("#${child.id}");
                                      if(childElement${child.id}){
                                          ${properties}
                                      }
                                    },2000)
                                      `;

            return code;
          };

          categoryXml += `<block type="grab-options-from-database-${child.tagName}-${child.id}"></block>`;
          Blockly.Blocks[
            `grab-options-from-database-${child.tagName}-${child.id}`
          ] = {
            init: function () {
              this.appendDummyInput().appendField(
                `grab-options-from-database-${child.tagName}-${child.id}`
              );
              this.appendValueInput("OPTION_LIST")
                .setCheck("Array")
                .appendField("Set Array Of Selected Options:");
              this.setPreviousStatement(true, null);
              this.setNextStatement(true, null);
              this.setColour(5);
              this.setTooltip(`Grab Selected Options from database`);
            },
          };

          Blockly.JavaScript[
            `grab-options-from-database-${child.tagName}-${child.id}`
          ] = function (block) {
            const optionList = Blockly.JavaScript.valueToCode(
              block,
              "OPTION_LIST",
              Blockly.JavaScript.ORDER_ASSIGNMENT
            );

            var code = `
              const selectedOptions = childElement${child.id}.options;
              const optionValues = ${optionList};
            
              for (const option of selectedOptions) {
                if (optionValues.includes(option.getAttribute('data_db_id'))) {
                  option.selected = true;
                }
              }
            `;

            return code;
          };
        } else if (child.childElementCount > 0) {
          // console.log("sub inner child::::::::::", child);
          let childParentAttribute = child.getAttribute("data-gjs-type");
          console.log("childParentAttribute::::::", childParentAttribute);

          console.log("child parent tagname:::::", child.tagName);
          console.log("child parent id:::::", child.id);
          categoryXml += `<category name="${childParentAttribute}-${child.id}" colour="210">`;
          recursiveBlockAddition(child, i + 1);

          if (childParentAttribute === "custom-dynamic-carousel-container") {
            let childTypesRef = [];
            let childTypesTagRef = [];
            let childTypesRefForTraits = [];

            function traverseManipulate(childNew) {
              if (childNew.childElementCount > 0) {
                console.log("child.childnodes:::::", childNew.childNodes);
                if (
                  childNew.tagName === "BUTTON" ||
                  childNew.tagName === "button"
                ) {
                  let childKeyToBePushed = [
                    `${childNew.tagName}|${childNew.id}`,
                    `${childNew.id}`,
                  ];
                  childTypesRef.push(childKeyToBePushed);

                  let childTagToBePushed = [
                    `${childNew.tagName}|${childNew.id}`,
                    `${childNew.tagName}|${childNew.id}`,
                  ];
                  childTypesTagRef.push(childTagToBePushed);
                }

                console.log("child.classLis::::", childNew.classList);

                if (
                  childNew.tagName === "DIV" &&
                  (childNew.classList.contains("gjs-row") ||
                    childNew.classList.contains("gjs-cell"))
                ) {
                  let childKeyToBePushed = [
                    `${childNew.tagName}|${childNew.id}`,
                    `${childNew.id}`,
                  ];
                  childTypesRef.push(childKeyToBePushed);

                  let childTagToBePushed = [
                    `${childNew.tagName}|${childNew.id}`,
                    `${childNew.tagName}|${childNew.id}`,
                  ];
                  childTypesTagRef.push(childTagToBePushed);
                }
                childNew.childNodes.forEach((a) => traverseManipulate(a));
              } else {
                let childKeyToBePushed = [
                  `${childNew.tagName}|${childNew.id}`,
                  `${childNew.id}`,
                ];
                childTypesRef.push(childKeyToBePushed);

                let childTagToBePushed = [
                  `${childNew.tagName}|${childNew.id}`,
                  `${childNew.tagName}|${childNew.id}`,
                ];
                childTypesTagRef.push(childTagToBePushed);
              }
            }

            let childNodesRef = [child.childNodes[0]];

            if (childNodesRef.length) {
              childNodesRef.forEach((childNew) => traverseManipulate(childNew));
            }

            function traverseManipulateTraits(childNew) {
              if (childNew.childElementCount > 0) {
                let childParentAttributeTrait =
                  childNew.getAttribute("data-gjs-type");
                if (childParentAttributeTrait === "custom-table-container") {
                  let childKeyToBePushed = [
                    `${childNew.tagName}|${childNew.id}|${childParentAttributeTrait}`,
                    `${childNew.id}`,
                  ];
                  childTypesRefForTraits.push(childKeyToBePushed);
                }

                childNew.childNodes.forEach((a) => traverseManipulateTraits(a));
              }
            }

            let childNodesRefForTraits = [child.childNodes[0]];

            if (childNodesRefForTraits.length) {
              childNodesRefForTraits.forEach((childNew) =>
                traverseManipulateTraits(childNew)
              );
            }

            categoryXml += `<block type="manipulate-value-${child.id}"></block>`;

            Blockly.Blocks[`manipulate-value-${child.id}`] = {
              init: function () {
                let generateUniqueFiveDigitNumber = () => {
                  return Math.floor(10000 + Math.random() * 90000);
                };

                this.uniqueId = generateUniqueFiveDigitNumber();
                this.appendDummyInput().appendField(
                  `Set Property of ${child.id}`
                );
                this.appendValueInput("ARRAY_INPUT")
                  .setCheck(null)
                  .appendField("Insert Array Data/Api Response:");
                this.appendValueInput("ARRAY_KEY")
                  .setCheck(null)
                  .appendField(
                    "Insert Key From Array Data/Api Response To Be Used:"
                  );

                this.appendDummyInput()
                  .appendField("Select element :")
                  .appendField(
                    new Blockly.FieldDropdown(childTypesTagRef), // Now using childTypesTagRef
                    "CHILD_TYPE"
                  );

                this.appendDummyInput()
                  .appendField("Property:")
                  .appendField(
                    new Blockly.FieldDropdown([
                      ["value", "value"],
                      ["textContent", "textContent"],
                      ["isChecked", "isChecked"],
                    ]),
                    "PROPERTY"
                  );
                this.appendValueInput("VALUE")
                  .setCheck(null)
                  .appendField("Value:");
                this.setPreviousStatement(true, null);
                this.setNextStatement(true, null);
                this.setColour(160);
                this.setTooltip("Set Property of the Component");
              },
            };

            // Blockly.JavaScript[`manipulate-value-${child.id}`] = function (block) {
            //   let uniqueAlphaNumericId = block.uniqueId;
            //   const property = block.getFieldValue("PROPERTY");
            //   const childTag = block.getFieldValue("CHILD_TYPE");
            //   const value = Blockly.JavaScript.valueToCode(
            //     block,
            //     "VALUE",
            //     Blockly.JavaScript.ORDER_ATOMIC
            //   );

            //   const arrayInput = Blockly.JavaScript.valueToCode(
            //     block,
            //     "ARRAY_INPUT",
            //     Blockly.JavaScript.ORDER_ATOMIC
            //   );

            //   const keyInput = Blockly.JavaScript.valueToCode(
            //     block,
            //     "ARRAY_KEY",
            //     Blockly.JavaScript.ORDER_ATOMIC
            //   );

            //   let code;

            //   // Define isElementNode function inside the code block scope
            //   if (arrayInput && keyInput) {
            //     // If array input and key input are provided
            //     code = `
            //       function isElementNode(node) {
            //         return node.nodeType === 1;
            //       }

            //       function traverseManipulate${uniqueAlphaNumericId}(child) {
            //         if (child && isElementNode(child) && child.childElementCount > 0) {
            //           // Iterate over each child node recursively
            //           Array.from(child.childNodes).forEach((a) => traverseManipulate${uniqueAlphaNumericId}(a));
            //         } else {
            //           let childwithIdRef;
            //           if (isElementNode(child) && child.hasAttribute('data-repl-id')) {
            //             let attrName = child.getAttribute('data-repl-id');
            //             console.log('attrName 1', attrName);
            //             if (attrName.includes('${childTag}')) {
            //               console.log('attrName 2', attrName);
            //               let indexRef = counter${uniqueAlphaNumericId}.index;
            //               let newIdSplitRef = attrName.split('_')[0];
            //               childwithIdRef = newIdSplitRef + '_' + indexRef;
            //             }
            //           } else if (isElementNode(child) && child.id === '${childTag}') {
            //             childwithIdRef = '${childTag}';
            //           }

            //           if (childwithIdRef) {
            //             if (${arrayInput}[counter${uniqueAlphaNumericId}.index]) {
            //               child.${property} = ${arrayInput}[counter${uniqueAlphaNumericId}.index][${keyInput}] || ' ';
            //               counter${uniqueAlphaNumericId}.index++;
            //             }
            //           }
            //         }
            //       }

            //       let childNodes${uniqueAlphaNumericId} = document.querySelector('#${child.id}')?.childNodes || [];
            //       console.log("document.querySelector('#${child.id}'), childNodes${uniqueAlphaNumericId}", document.querySelector('#${child.id}'), childNodes${uniqueAlphaNumericId});

            //       let counter${uniqueAlphaNumericId} = { index: 0 };

            //       // If there is only one element, we still want to process it
            //       if (childNodes${uniqueAlphaNumericId}.length === 0) {
            //         traverseManipulate${uniqueAlphaNumericId}(document.querySelector('#${child.id}'));
            //       } else {
            //         Array.from(childNodes${uniqueAlphaNumericId}).forEach((childNew) => traverseManipulate${uniqueAlphaNumericId}(childNew));
            //       }
            //     `;
            //   } else {
            //     // If only a value is provided
            //     code = `
            //       function isElementNode(node) {
            //         return node.nodeType === 1;
            //       }

            //       function traverseManipulate${uniqueAlphaNumericId}(child) {
            //         if (child && isElementNode(child) && child.childElementCount > 0) {
            //           // Iterate over each child node recursively
            //           Array.from(child.childNodes).forEach((a) => traverseManipulate${uniqueAlphaNumericId}(a));
            //         } else {
            //           let childwithIdRef;
            //           if (isElementNode(child) && child.hasAttribute('data-repl-id')) {
            //             let attrName = child.getAttribute('data-repl-id');
            //             if (attrName.includes('${childTag}')) {
            //               childwithIdRef = attrName;
            //             }
            //           } else if (isElementNode(child) && child.id === '${childTag}') {
            //             childwithIdRef = '${childTag}';
            //           }

            //           if (childwithIdRef) {
            //             child.${property} = ${value};
            //           }
            //         }
            //       }

            //       let childNodes${uniqueAlphaNumericId} = document.querySelector('#${child.id}')?.childNodes || [];
            //       console.log("document.querySelector('#${child.id}'), childNodes${uniqueAlphaNumericId}", document.querySelector('#${child.id}'), childNodes${uniqueAlphaNumericId});

            //       let counter${uniqueAlphaNumericId} = { index: 0 };

            //       // If there is only one element, we still want to process it
            //       if (childNodes${uniqueAlphaNumericId}.length === 0) {
            //         traverseManipulate${uniqueAlphaNumericId}(document.querySelector('#${child.id}'));
            //       } else {
            //         Array.from(childNodes${uniqueAlphaNumericId}).forEach((childNew) => traverseManipulate${uniqueAlphaNumericId}(childNew));
            //       }
            //     `;
            //   }

            //   return code;
            // };

            // Blockly.JavaScript[`manipulate-value-${child.id}`] = function (block) {
            //   let uniqueAlphaNumericId = block.uniqueId;
            //   const property = block.getFieldValue("PROPERTY");
            //   const childTag = block.getFieldValue("CHILD_TYPE");
            //   const value = Blockly.JavaScript.valueToCode(block, "VALUE", Blockly.JavaScript.ORDER_ATOMIC);
            //   const arrayInput = Blockly.JavaScript.valueToCode(block, "ARRAY_INPUT", Blockly.JavaScript.ORDER_ATOMIC);
            //   const keyInput = Blockly.JavaScript.valueToCode(block, "ARRAY_KEY", Blockly.JavaScript.ORDER_ATOMIC);

            //   let code;

            //   // Define isElementNode function inside the code block scope
            //   if (arrayInput && keyInput) {
            //     // If array input and key input are provided
            //     code = `
            //       function isElementNode(node) {
            //         return node && node.nodeType === 1;
            //       }

            //       function traverseManipulate${uniqueAlphaNumericId}(child) {
            //         if (isElementNode(child) && child.childElementCount > 0) {
            //           // Iterate over each child node recursively
            //           Array.from(child.childNodes).forEach((a) => traverseManipulate${uniqueAlphaNumericId}(a));
            //         } else if (isElementNode(child)) {
            //           let childwithIdRef;
            //           if (child.hasAttribute('data-repl-id')) {
            //             let attrName = child.getAttribute('data-repl-id');
            //             if (attrName.includes('${childTag}')) {
            //               let indexRef = counter${uniqueAlphaNumericId}.index;
            //               let newIdSplitRef = attrName.split('_')[0];
            //               childwithIdRef = newIdSplitRef + '_' + indexRef;
            //             }
            //           } else if (child.id === '${childTag}') {
            //             childwithIdRef = '${childTag}';
            //           }

            //           if (childwithIdRef) {
            //             if (${arrayInput}[counter${uniqueAlphaNumericId}.index]) {
            //               child.${property} = ${arrayInput}[counter${uniqueAlphaNumericId}.index][${keyInput}] || ' ';
            //               counter${uniqueAlphaNumericId}.index++;
            //             }
            //           }
            //         }
            //       }

            //       let childNodes${uniqueAlphaNumericId} = document.querySelector('#${child.id}')?.childNodes || [];
            //       let counter${uniqueAlphaNumericId} = { index: 0 };

            //       // If there is only one element, we still want to process it
            //       if (childNodes${uniqueAlphaNumericId}.length === 0) {
            //         traverseManipulate${uniqueAlphaNumericId}(document.querySelector('#${child.id}'));
            //       } else {
            //         Array.from(childNodes${uniqueAlphaNumericId}).forEach((childNew) => traverseManipulate${uniqueAlphaNumericId}(childNew));
            //       }
            //     `;
            //   } else {
            //     // If only a value is provided
            //     code = `
            //       function isElementNode(node) {
            //         return node && node.nodeType === 1;
            //       }

            //       function traverseManipulate${uniqueAlphaNumericId}(child) {
            //         if (isElementNode(child) && child.childElementCount > 0) {
            //           // Iterate over each child node recursively
            //           Array.from(child.childNodes).forEach((a) => traverseManipulate${uniqueAlphaNumericId}(a));
            //         } else if (isElementNode(child)) {
            //           let childwithIdRef;
            //           if (child.hasAttribute('data-repl-id')) {
            //             let attrName = child.getAttribute('data-repl-id');
            //             if (attrName.includes('${childTag}')) {
            //               childwithIdRef = attrName;
            //             }
            //           } else if (child.id === '${childTag}') {
            //             childwithIdRef = '${childTag}';
            //           }

            //           if (childwithIdRef) {
            //             child.${property} = ${value};
            //           }
            //         }
            //       }

            //       let childNodes${uniqueAlphaNumericId} = document.querySelector('#${child.id}')?.childNodes || [];
            //       let counter${uniqueAlphaNumericId} = { index: 0 };

            //       // If there is only one element, we still want to process it
            //       if (childNodes${uniqueAlphaNumericId}.length === 0) {
            //         traverseManipulate${uniqueAlphaNumericId}(document.querySelector('#${child.id}'));
            //       } else {
            //         Array.from(childNodes${uniqueAlphaNumericId}).forEach((childNew) => traverseManipulate${uniqueAlphaNumericId}(childNew));
            //       }
            //     `;
            //   }

            //   return code;
            // };

            Blockly.JavaScript[`manipulate-value-${child.id}`] = function (block) {
              let uniqueAlphaNumericId = block.uniqueId;
              const property = block.getFieldValue("PROPERTY");
              const childTag = block.getFieldValue("CHILD_TYPE"); // This will be something like 'INPUT|iagk'
              const value = Blockly.JavaScript.valueToCode(block, "VALUE", Blockly.JavaScript.ORDER_ATOMIC);
              const arrayInput = Blockly.JavaScript.valueToCode(block, "ARRAY_INPUT", Blockly.JavaScript.ORDER_ATOMIC);
              const keyInput = Blockly.JavaScript.valueToCode(block, "ARRAY_KEY", Blockly.JavaScript.ORDER_ATOMIC);

              // Split the childTag into tag name and id (e.g., 'INPUT|iagk' -> ['INPUT', 'iagk'])
              const [elementTag, elementId] = childTag.split('|');

              let code = `
                (function() {
                  // Anonymous function for traversing and applying values
                  function traverseAndApply(child) {
                    if (!child) return;
                    
                    // Recursively traverse child elements if there are children
                    if (child.childElementCount > 0) {
                      Array.from(child.childNodes).forEach((nestedChild) => traverseAndApply(nestedChild));
                    } else {
                      // Check if this element has a 'data-repl-id' and matches the pattern for the target elements
                      const replId = child.getAttribute('data-repl-id');
                      console.log("replId", replId);
                      // if (replId && replId.startsWith('${elementId}_')) {
                      if (replId && replId.includes('_')) {
                        // Extract the index from the 'data-repl-id'
                        const index = replId.split('_')[1]; // Get the index after the underscore
                        console.log("index", index);

                        if (${arrayInput}[index]) {
                          console.log("arrayInput[index]", ${arrayInput}[index]);
                          // Set value based on array and key input
                          child.${property} = ${arrayInput}[index][${keyInput}] || '';
                        }
                      }
                    }
                    console.log('traverseAndApply complete.', child);
                  }

                  // Find all dynamic carousel containers
                  let allDynamicContainers = document.querySelectorAll('.dynamic-carousel-inner-container');

                  // Loop through each dynamic carousel container clone
                  allDynamicContainers.forEach((container, containerIndex) => {
                    // Check for the element mentioned in childTag directly (without 'data-repl-id')
                    let directTarget = container.querySelector('${elementTag}#${elementId}');
                    if (directTarget) {
                      // Apply the property value from arrayInput based on containerIndex
                      if (${arrayInput}[containerIndex]) {
                        directTarget.${property} = ${arrayInput}[containerIndex][${keyInput}] || '';
                        console.log("property, ${property}, ${arrayInput}[containerIndex][${keyInput}]");
                      }
                    }

                    // Traverse through each child element in the container and apply the values
                    traverseAndApply(container);
                  });

                  // let subString = window.location.href.indexOf("/editor/") !== -1 &&
                  //               window.location.href.indexOf("?projectId=") !== -1 ||
                  //               window.location.href.indexOf("/large_preview/") !== -1 ||
                  //               window.location.href.indexOf("/tab_preview/") !== -1 ||
                  //               window.location.href.indexOf("/mobile_preview/") !== -1 ||
                  //               window.location.href.indexOf("/fragment_editor/") !== -1;
                
                  // if(subString) {
                  //   let allComponents = window.top.editor.getWrapper();
                  //   let childCompEditor = allComponents.find('#${child.id}')[0];
                  //   if (childCompEditor) {
                  //     childCompEditor.view.render();
                  //   }
                  // }
                })();
              `;

              return code;
            };

            categoryXml += `<block type="grab-initial-value-${child.id}"></block>`;
            Blockly.Blocks[`grab-initial-value-${child.id}`] = {
              init: function () {
                let generateUniqueFiveDigitNumber = () => {
                  return Math.floor(10000 + Math.random() * 90000);
                };

                this.uniqueId = generateUniqueFiveDigitNumber();
                this.appendDummyInput().appendField(
                  `Grab Initial Value of ${child.id}`
                );

                this.appendDummyInput()
                  .appendField("Select element :")
                  .appendField(
                    new Blockly.FieldDropdown(childTypesRef),
                    "CHILD_TYPE"
                  );

                this.appendDummyInput()
                  .appendField("Property to Grab:")
                  .appendField(
                    new Blockly.FieldDropdown([
                      ["value", "value"],
                      ["textContent", "textContent"],
                    ]),
                    "PROPERTY"
                  );
                this.appendValueInput("RESPONSE_VAR")
                  .setCheck(null)
                  .appendField("Store Values in an Array:");
                this.setPreviousStatement(true, null);
                this.setNextStatement(true, null);
                this.setColour(160);
                this.setTooltip("Grab Values of the Component");
              },
            };

            Blockly.JavaScript[`grab-initial-value-${child.id}`] = function (
              block
            ) {
              let uniqueAlphaNumericId = block.uniqueId;
              const property = block.getFieldValue("PROPERTY");
              const childTag = block.getFieldValue("CHILD_TYPE");
              const value = Blockly.JavaScript.valueToCode(
                block,
                "RESPONSE_VAR",
                Blockly.JavaScript.ORDER_ATOMIC
              );

              // let childId

              var code = `

              function traverseManipulate${uniqueAlphaNumericId}(child) {
      if (child.childElementCount > 0) {
        child.childNodes.forEach((a) => traverseManipulate${uniqueAlphaNumericId}(a));
      } else {
        let childwithIdRef;
      if(child.hasAttribute('data-repl-id')){
      let attrName=child.getAttribute('data-repl-id')
        if(attrName.includes('${childTag}')){
        childwithIdRef=attrName
        }
      }else{
       if(child.id === '${childTag}'){
        childwithIdRef='${childTag}'
       }
      }
        if(childwithIdRef){
      ${value}.push(child['${property}'])
         }
      }
    }
                            let childNodes${uniqueAlphaNumericId}=document.querySelector('#${child.id}').childNodes;

                           childNodes${uniqueAlphaNumericId}.forEach((childNew)=>traverseManipulate${uniqueAlphaNumericId}(childNew))

                            `;

              return code;
            };

            categoryXml += `<block type="apply-events-to-${childParentAttribute}-${child.id}"></block>`;

            //   for style manipulation:
            Blockly.Blocks[
              `apply-events-to-${childParentAttribute}-${child.id}`
            ] = {
              init: function () {
                let generateUniqueFiveDigitNumber = () => {
                  return Math.floor(10000 + Math.random() * 90000);
                };

                this.uniqueId = generateUniqueFiveDigitNumber();
                this.appendDummyInput().appendField(
                  `Component Events for  ${childParentAttribute}-${child.id}`
                );

                this.appendDummyInput()
                  .appendField("Select element :")
                  .appendField(
                    new Blockly.FieldDropdown(childTypesRef),
                    "CHILD_TYPE"
                  );

                this.appendDummyInput()
                  .appendField("On Event")
                  .appendField(
                    new Blockly.FieldDropdown([
                      ["Click", "click"],
                      ["Change", "change"],
                    ]),
                    "EVENT"
                  );
                this.appendValueInput("RESPONSE_VAR")
                  .setCheck(null)
                  .appendField(
                    "Grab Target Property An Store In an Array (optional)"
                  );
                this.appendStatementInput("DO").appendField("Do");
                this.setPreviousStatement(true, null);
                this.setNextStatement(true, null);
                this.setColour(230);
                this.setTooltip("Add event handler To the component");
                this.setHelpUrl("");
              },
            };

            Blockly.JavaScript[
              `apply-events-to-${childParentAttribute}-${child.id}`
            ] = function (block) {
              let uniqueAlphaNumericId = block.uniqueId;
              var event = block.getFieldValue("EVENT");
              const childTag = block.getFieldValue("CHILD_TYPE");
              const targetValue = Blockly.JavaScript.valueToCode(
                block,
                "RESPONSE_VAR",
                Blockly.JavaScript.ORDER_ATOMIC
              );
              var code = "";
              var actionCode = Blockly.JavaScript.statementToCode(block, "DO");

              if (event && actionCode) {
                code += `
                  
                   function traverseManipulate${uniqueAlphaNumericId}(child) {
      if (child.childElementCount > 0) {
         if(child.tagName === "BUTTON" || child.tagName === "button"){
            let childwithIdRef;
      if(child.hasAttribute('data-repl-id')){
      let attrName=child.getAttribute('data-repl-id')
        if(attrName.includes('${childTag}')){
        childwithIdRef=attrName
        }
      }else{
       if(child.id === '${childTag}'){
        childwithIdRef='${childTag}'
       }
      }
        if(childwithIdRef){
          child.addEventListener('${event}', function(event) {

                ${actionCode}
              });
         }
         }

         child.childNodes.forEach((a) => traverseManipulate${uniqueAlphaNumericId}(a));
      } else {
       let childwithIdRef;
      if(child.hasAttribute('data-repl-id')){
      let attrName=child.getAttribute('data-repl-id')
        if(attrName.includes('${childTag}')){
        childwithIdRef=attrName
        }
      }else{
       if(child.id === '${childTag}'){
        childwithIdRef='${childTag}'
       }
      }
        if(childwithIdRef){
          child.addEventListener('${event}', function(event) {
              if(child.tagName==='INPUT' && (child.type=='radio' || child.type=='checkbox')){
               ${targetValue}.push(event.target.checked)
              }else if (child.tagName !='button' || child.tagName !='BUTTON'){
              ${targetValue}.push(event.target.value)
              }

                ${actionCode}
              });
         }
      }
    }
                            let childNodes${uniqueAlphaNumericId}=document.querySelector('#${child.id}').childNodes;

                           childNodes${uniqueAlphaNumericId}.forEach((childNew)=>traverseManipulate${uniqueAlphaNumericId}(childNew))


                  `;
              }

              return code;
            };

            categoryXml += `<block type="add_styles-${childParentAttribute}-${child.id}"></block>`;

            //   for style manipulation:
            //   bbql
            Blockly.Blocks[`add_styles-${childParentAttribute}-${child.id}`] = {
              init: function () {
                let generateUniqueFiveDigitNumber = () => {
                  return Math.floor(10000 + Math.random() * 90000);
                };

                this.uniqueId = generateUniqueFiveDigitNumber();
                this.appendDummyInput().appendField(
                  `add_styles-${childParentAttribute}-${child.id}`
                );
                this.appendDummyInput()
                  .appendField("Select element :")
                  .appendField(
                    new Blockly.FieldDropdown(childTypesRef),
                    "CHILD_TYPE"
                  );
                this.appendValueInput("PROPERTY_NAME")
                  .setCheck("String")
                  .appendField("Property Name:");

                this.appendValueInput("PROPERTY_VALUE")
                  .setCheck("String")
                  .appendField("Value:");
                this.setPreviousStatement(true, null);
                this.setNextStatement(true, null);
                this.setColour(160);
                this.setTooltip("Specify a CSS property, its type, and value.");
              },
            };

            Blockly.JavaScript[
              `add_styles-${childParentAttribute}-${child.id}`
            ] = function (block) {
              let uniqueAlphaNumericId = block.uniqueId;
              const childTag = block.getFieldValue("CHILD_TYPE");
              const propertyName = Blockly.JavaScript.valueToCode(
                block,
                "PROPERTY_NAME",
                Blockly.JavaScript.ORDER_ATOMIC
              );

              const propertyValue = Blockly.JavaScript.valueToCode(
                block,
                "PROPERTY_VALUE",
                Blockly.JavaScript.ORDER_ATOMIC
              );

              var code = `
                
               function traverseManipulate${uniqueAlphaNumericId}(child) {
      if (child.childElementCount > 0) {
         if(child.tagName === "DIV" && (child.classList.contains('gjs-row') || child.classList.contains('gjs-cell'))){
            let childwithIdRef;
      if(child.hasAttribute('data-repl-id')){
      let attrName=child.getAttribute('data-repl-id')
        if(attrName.includes('${childTag}')){
        childwithIdRef=attrName
        }
      }else{
       if(child.id === '${childTag}'){
        childwithIdRef='${childTag}'
       }
      }
        if(childwithIdRef){
          child.style[${propertyName}] = ${propertyValue}
         }
         }

         child.childNodes.forEach((a) => traverseManipulate${uniqueAlphaNumericId}(a));
      } else {
        let childwithIdRef;
      if(child.hasAttribute('data-repl-id')){
      let attrName=child.getAttribute('data-repl-id')
        if(attrName.includes('${childTag}')){
        childwithIdRef=attrName
        }
      }else{
       if(child.id === '${childTag}'){
        childwithIdRef='${childTag}'
       }
      }
        if(childwithIdRef){
          child.style[${propertyName}] = ${propertyValue}
         }
      }
    }
                            let childNodes${uniqueAlphaNumericId}=document.querySelector('#${child.id}').childNodes;

                           childNodes${uniqueAlphaNumericId}.forEach((childNew)=>traverseManipulate${uniqueAlphaNumericId}(childNew))

                                        `;

              return code;
            };

            // component event return blocks
            categoryXml += `<block type="return-event-target-details-${childParentAttribute}-${child.id}"></block>`;

            Blockly.Blocks[`return-event-target-details-${childParentAttribute}-${child.id}`] = {
              init: function () {
                let generateUniqueFiveDigitNumber = () => {
                  return Math.floor(10000 + Math.random() * 90000);
                };

                this.uniqueId = generateUniqueFiveDigitNumber();

                this.appendDummyInput().appendField(`Return Event's Target's Details of ${childParentAttribute}-${child.id}`);
                this.appendDummyInput().appendField("Select an element :").appendField(new Blockly.FieldDropdown(childTypesTagRef), "CHILD_TYPE_REF");
                this.appendDummyInput().appendField("On Event").appendField(
                  new Blockly.FieldDropdown([
                    ["Click", "click"],
                    ["Change", "change"],
                  ]),
                  "EVENT"
                );
                this.appendValueInput("EVENT_TARGET_REF").setCheck(null).appendField("Insert a variable to store the event's target:");
                this.appendValueInput("ATTRIBUTES_REF").setCheck(null).appendField("Insert a variable to store the attributes:");
                this.appendStatementInput("DO").appendField("Do");
                this.setPreviousStatement(true, null);
                this.setNextStatement(true, null);
                this.setColour(230);
                this.setTooltip("Get the event's target's details and attributes");
                this.setHelpUrl("");
              },
            };

            Blockly.JavaScript[`return-event-target-details-${childParentAttribute}-${child.id}`] = function (block) {
              var childEle = block.getFieldValue("CHILD_TYPE_REF");
              var event = block.getFieldValue("EVENT");
              var eventTarget = Blockly.JavaScript.valueToCode(block, "EVENT_TARGET_REF", Blockly.JavaScript.ORDER_ATOMIC);
              var attributesRef = Blockly.JavaScript.valueToCode(block, "ATTRIBUTES_REF", Blockly.JavaScript.ORDER_ATOMIC);
              var actionCode = Blockly.JavaScript.statementToCode(block, "DO");
              var code = "";

              if (event && actionCode) {
                  // Generate the inline function directly within the event listener
                  code += `
                      (function() {
                          let startElement = document.querySelector('#${child.id}');
                          if (startElement) {
                              function traverseAndAttach(element) {
                                  if (element.tagName === "${childEle.split('|')[0].toUpperCase()}") {
                                      element.addEventListener('${event}', function(event) {
                                          if (${eventTarget} !== 'null') {
                                              ${eventTarget} = event.target;
                                          }
                                          if (${attributesRef} !== 'null') {
                                              ${attributesRef} = {};
                                              Array.from(event.target.attributes).forEach(attr => {
                                                  ${attributesRef}[attr.name] = attr.value;
                                              });
                                          }
                                          ${actionCode}
                                      });
                                  }
                                  Array.from(element.children).forEach(child => traverseAndAttach(child));
                              }
                              traverseAndAttach(startElement);
                          }
                      })();
                  `;
              }

              return code;
            };

            // blocks to set multiple css properties as javascript object
            categoryXml += `<block type="set-css-properties-${childParentAttribute}-${child.id}"></block>`;

            Blockly.Blocks[`set-css-properties-${childParentAttribute}-${child.id}`] = {
              init: function () {
                let generateUniqueFiveDigitNumber = () => {
                  return Math.floor(10000 + Math.random() * 90000);
                };

                this.uniqueId = generateUniqueFiveDigitNumber();

                this.appendDummyInput().appendField(`Set Styling Properties of ${childParentAttribute}-${child.id}`);
                this.appendDummyInput().appendField("Select an element:").appendField(new Blockly.FieldDropdown(childTypesTagRef), "CHILD_TYPE_REF");
                this.appendValueInput("CSS_PROPERTIES")
                  .setCheck("Object")
                  .appendField("Set CSS properties as an object:");
                this.setPreviousStatement(true, null);
                this.setNextStatement(true, null);
                this.setColour(230);
                this.setTooltip("Set CSS properties on an element and apply recursively");
                this.setHelpUrl("");
              },
            };

            Blockly.JavaScript[`set-css-properties-${childParentAttribute}-${child.id}`] = function (block) {
              var childEle = block.getFieldValue("CHILD_TYPE_REF");
              var cssProperties = Blockly.JavaScript.valueToCode(block, "CSS_PROPERTIES", Blockly.JavaScript.ORDER_ATOMIC);
              var code = "";

              // Ensure cssProperties is a valid object
              cssProperties = cssProperties.replace(/'/g, '"'); // Convert single quotes to double quotes for consistency

              if (cssProperties) {
                code += `
                  (function() {
                    let startElement = document.querySelector('#${child.id}');
                    if (startElement) {
                      function traverseAndApplyCSS(element) {
                        let applied = false;
                        if (element.tagName === "${childEle.split('|')[0].toUpperCase()}") {
                          try {
                            let cssProps = ${cssProperties};
                            for (let key in cssProps) {
                              if (cssProps.hasOwnProperty(key)) {
                                element.style[key] = cssProps[key];
                              }
                            }
                            applied = true; // Mark that properties were applied
                          } catch (e) {
                            console.error("Error applying CSS properties:", e);
                          }
                        }
                        for (let child of element.children) {
                          if (traverseAndApplyCSS(child)) {
                            applied = true; // Mark if any child had properties applied
                          }
                        }
                        return applied; // Return if properties were applied at any level
                      }
                      return traverseAndApplyCSS(startElement);
                    }
                    return false; // Return false if startElement is not found
                  })();
                `;
              }

              return code;
            };

            // DC set trait block
            categoryXml += `<block type="set-traits-${childParentAttribute}-${child.id}"></block>`;

            // Define the custom trait updating block
            Blockly.Blocks[`set-traits-${childParentAttribute}-${child.id}`] = {
              init: function () {
                this.appendDummyInput()
                    .appendField(`Customise Traits of ${childParentAttribute}-${child.id}`);
                this.appendValueInput("TRAITS_OBJECT")
                    .setCheck("Object")
                    .appendField("Traits object:");
                this.setPreviousStatement(true, null);
                this.setNextStatement(true, null);
                this.setColour(180);
                this.setTooltip("Update multiple traits using key-value pairs.");
              }
            };

            // JavaScript code generation for the trait customization block
            Blockly.JavaScript[`set-traits-${childParentAttribute}-${child.id}`] = function(block) {
              const traitsObject = Blockly.JavaScript.valueToCode(block, 'TRAITS_OBJECT', Blockly.JavaScript.ORDER_ATOMIC);

              let code = `
                let subString = window.location.href.indexOf("/editor/") !== -1 &&
                                window.location.href.indexOf("?projectId=") !== -1 ||
                                window.location.href.indexOf("/large_preview/") !== -1 ||
                                window.location.href.indexOf("/tab_preview/") !== -1 ||
                                window.location.href.indexOf("/mobile_preview/") !== -1 ||
                                window.location.href.indexOf("/fragment_editor/") !== -1;
                
                if(subString) {
                  let allComponents = window.top.editor.getWrapper();
                  let childCompEditor = allComponents.find('#${child.id}')[0];
                  if (childCompEditor) {
                    childCompEditor.set(${traitsObject});
                    childCompEditor.view.render();
                  }
                } else {
                  if (typeof ${traitsObject} === 'object') {
                    Object.keys(${traitsObject}).forEach(function(key) {
                      window[key + "${child.id}"] = ${traitsObject}[key];
                    });
                  }
                }
              `;

              return code;
            };

            // DC get trait blocks
            categoryXml += `<block type="get-traits-${childParentAttribute}-${child.id}"></block>`;
            
            // Define the custom trait getting block
            Blockly.Blocks[`get-traits-${childParentAttribute}-${child.id}`] = {
              init: function () {
                this.appendDummyInput()
                    .appendField(`Get Trait of ${childParentAttribute}-${child.id}`);
                this.appendValueInput("TRAIT_KEY")
                    .setCheck("String")
                    .appendField("Trait key:");
                this.setOutput(true, "String"); // Returns the value of the trait
                this.setColour(180);
                this.setTooltip("Retrieve the value of a trait using the key.");
              }
            };

            // JavaScript code generation for the trait getting block
            Blockly.JavaScript[`get-traits-${childParentAttribute}-${child.id}`] = function(block) {
              const traitKey = Blockly.JavaScript.valueToCode(block, 'TRAIT_KEY', Blockly.JavaScript.ORDER_ATOMIC);

              let code = `
                (function() {
                  let traitValue = null;
                  let subString = window.location.href.indexOf("/editor/") !== -1 &&
                                  window.location.href.indexOf("?projectId=") !== -1 ||
                                  window.location.href.indexOf("/large_preview/") !== -1 ||
                                  window.location.href.indexOf("/tab_preview/") !== -1 ||
                                  window.location.href.indexOf("/mobile_preview/") !== -1 ||
                                  window.location.href.indexOf("/fragment_editor/") !== -1;

                  if(subString) {
                    let allComponents = window.top.editor.getWrapper();
                    let childCompEditor = allComponents.find('#${child.id}')[0];
                    if (childCompEditor) {
                      traitValue = childCompEditor.get(${traitKey});
                    }
                  } else {
                    traitValue = window[${traitKey} + "${child.id}"] || null;
                  }

                  return traitValue;
                })()
              `;

              return [code, Blockly.JavaScript.ORDER_ATOMIC];
            };

            if (childTypesRefForTraits.length) {
              categoryXml += `<block type="customise-traits-${childParentAttribute}-${child.id}"></block>`;

              //   for style manipulation:
              //   bbql
              Blockly.Blocks[`customise-traits-${childParentAttribute}-${child.id}`] = {
                init: function () {
                  let generateUniqueFiveDigitNumber = () => {
                    return Math.floor(10000 + Math.random() * 90000);
                  };

                  this.uniqueId = generateUniqueFiveDigitNumber();
                  this.appendDummyInput()
                      .appendField(`customise-traits-${childParentAttribute}-${child.id}-inner-element`);

                  this.appendDummyInput()
                      .appendField("Select element:")
                      .appendField(new Blockly.FieldDropdown(childTypesRefForTraits), "CHILD_TYPE");

                  this.appendValueInput("TRAIT_OBJECT")
                      .setCheck(null)
                      .appendField("Trait object or value:");

                  this.appendValueInput("ATTRIBUTE_FOR_VALUE")
                      .setCheck("String")
                      .appendField("Attribute for value in traits:");

                  this.appendValueInput("TRAIT_TO_UPDATE")
                      .setCheck("String")
                      .appendField("Trait to update (e.g., 'apiBodyValue'):");

                  this.setPreviousStatement(true, null);
                  this.setNextStatement(true, null);
                  this.setColour(160);
                  this.setTooltip("Manipulate traits of component dynamically with key-value pairs or direct values.");
                }
              };

              Blockly.JavaScript[`customise-traits-${childParentAttribute}-${child.id}`] = function (block) {
                let uniqueAlphaNumericId = block.uniqueId;
                const childTag = block.getFieldValue("CHILD_TYPE");
                const traitObject = Blockly.JavaScript.valueToCode(block, "TRAIT_OBJECT", Blockly.JavaScript.ORDER_ATOMIC);
                const attributeForValue = Blockly.JavaScript.valueToCode(block, "ATTRIBUTE_FOR_VALUE", Blockly.JavaScript.ORDER_ATOMIC) || null;
                const traitToUpdate = Blockly.JavaScript.valueToCode(block, "TRAIT_TO_UPDATE", Blockly.JavaScript.ORDER_ATOMIC);

                var code = `
                  // Anonymous function for traversing and manipulating traits
                  (function traverseManipulate(child) {
                    // Ensure 'child' is a valid DOM element
                    if (child && child.childElementCount > 0) {
                      let childWithIdRef;
                      if (child.hasAttribute('data-repl-id')) {
                        let attrName = child.getAttribute('data-repl-id');
                        if (attrName.includes('${childTag}')) {
                          childWithIdRef = attrName;
                        }
                      } else if (child.id === '${childTag}') {
                        childWithIdRef = '${childTag}';
                      }

                      if (childWithIdRef) {
                        let subString${childTag} = window.location.href.includes("/editor/");

                        if (subString${childTag}) {
                          let allComponents${childTag} = window.top.editor.getWrapper();
                          let childCompEditor${childTag};
                          if (childWithIdRef.includes('_')) {
                            childCompEditor${childTag} = allComponents${childTag}.find('[data-repl-id=' + childWithIdRef + ']')[0];
                          } else {
                            childCompEditor${childTag} = allComponents${childTag}.find('#' + childWithIdRef)[0];
                          }

                          if (childCompEditor${childTag}) {
                            let updatedTraitObject = ${traitObject};

                            // Try to find the parent dynamic-carousel-inner-container
                            try {
                              let parentContainer = child.closest('[data-gjs-type="dynamic-carousel-inner-container"]');
                              // console.log("+parentContainer+", parentContainer);
                              if (parentContainer || parentContainer.hasAttribute(${attributeForValue})) {
                                let dataDbIdValue = parentContainer.getAttribute(${attributeForValue});

                                // Handle both object and primitive types
                                if (typeof updatedTraitObject === 'object' && updatedTraitObject !== null) {
                                  // Merge with existing trait if it already exists
                                  let currentTraits = childCompEditor${childTag}.get(${traitToUpdate}) || {};
                                  if (typeof currentTraits === 'string') {
                                    currentTraits = JSON.parse(currentTraits);
                                  }

                                  // Merge current traits with new traits, overwriting keys with new values
                                  updatedTraitObject = Object.assign({}, currentTraits, updatedTraitObject);

                                  // Inject dataDbIdValue into the specified attribute in the trait object
                                  ${attributeForValue ? `
                                  if (updatedTraitObject && typeof updatedTraitObject === 'object') {
                                    if (updatedTraitObject.hasOwnProperty(${attributeForValue})) {                                      
                                      // updatedTraitObject[${attributeForValue}] = dataDbIdValue;

                                      var firstKeyInitPayload = Object.keys(updatedTraitObject)[0];
                                      var firstNestedKeyInitPayload = Object.keys(updatedTraitObject[firstKeyInitPayload])[0];
                                      var firstKeyTest = Object.keys(dataDbIdValue)[0];

                                      updatedTraitObject[firstKeyInitPayload][firstNestedKeyInitPayload] = dataDbIdValue;
                                    } else {
                                      // If the specified attribute does not exist, create it and assign the dataDbIdValue
                                      // updatedTraitObject[${attributeForValue}] = dataDbIdValue;

                                      var firstKeyInitPayload = Object.keys(updatedTraitObject)[0];
                                      var firstNestedKeyInitPayload = Object.keys(updatedTraitObject[firstKeyInitPayload])[0];
                                      var firstKeyTest = Object.keys(dataDbIdValue)[0];

                                      updatedTraitObject[firstKeyInitPayload][firstNestedKeyInitPayload] = dataDbIdValue;
                                    }
                                  }
                                  ` : ''}

                                  childCompEditor${childTag}.set({
                                    ${traitToUpdate}: JSON.stringify(updatedTraitObject),
                                  });

                                  console.log("+updatedTraitObject, traitToUpdate+ 2", updatedTraitObject, ${traitToUpdate});
                                } else {
                                  // Set directly if not an object (number, string, etc.)
                                  console.log("+updatedTraitObject, traitToUpdate+ 1", updatedTraitObject, ${traitToUpdate});
                                  childCompEditor${childTag}.set({
                                    ${traitToUpdate}: (updatedTraitObject !== undefined) ? updatedTraitObject : dataDbIdValue,
                                  });
                                }
                              }

                            } catch (error) {
                              console.error('Error updating trait:', error);
                            }

                            try {
                              childCompEditor${childTag}.attributes.script.call(
                                childCompEditor${childTag}.getEl(),
                                childCompEditor${childTag}.props()
                              );

                              childCompEditor${childTag}.Paginate();
                            } catch (error) {
                              console.error('Error in component script or pagination:', error);
                            }
                          }
                        }
                      }

                      // Recursively apply the same to child nodes
                      child.childNodes.forEach((a) => traverseManipulate(a));
                    }
                  })(document.querySelector('#${child.id}'));
                `;

                return code;
              };

              // to get the attributes in recursive manner
              categoryXml += `<block type="get-attributes-${childParentAttribute}-${child.id}"></block>`;

              Blockly.Blocks[`get-attributes-${childParentAttribute}-${child.id}`] = {
                init: function() {
                  this.appendDummyInput()
                      .appendField(`Get attributes of ${childParentAttribute}-${child.id}`);
                  this.appendValueInput("QUERY_ATTRIBUTE")
                      .setCheck("String")
                      .appendField("Attribute for query:");
                  this.appendValueInput("QUERY_VALUE")
                      .setCheck("String")
                      .appendField("Value for query:");
                  this.appendValueInput("TARGET_ATTRIBUTE")
                      .setCheck("String")
                      .appendField("Attribute to get:");
                  this.setOutput(true, "String");
                  this.setColour(230);
                  this.setTooltip('Recursively get the value of a specified attribute from an element that matches the query.');
                  this.setHelpUrl('');
                }
              };

              Blockly.JavaScript[`get-attributes-${childParentAttribute}-${child.id}`] = function(block) {
                const queryAttribute = Blockly.JavaScript.valueToCode(block, "QUERY_ATTRIBUTE", Blockly.JavaScript.ORDER_ATOMIC);
                const queryValue = Blockly.JavaScript.valueToCode(block, "QUERY_VALUE", Blockly.JavaScript.ORDER_ATOMIC);
                const targetAttribute = Blockly.JavaScript.valueToCode(block, "TARGET_ATTRIBUTE", Blockly.JavaScript.ORDER_ATOMIC);

                const code = `
                  function getRecursiveAttribute(parent) {
                    let targetElement = null;

                    function traverse(child) {
                      if (child.getAttribute(${queryAttribute}) === ${queryValue}) {
                        targetElement = child;
                      } else {
                        child.childNodes.forEach((nestedChild) => traverse(nestedChild));
                      }
                    }

                    parent.childNodes.forEach((child) => traverse(child));

                    return targetElement ? targetElement.getAttribute(${targetAttribute}) : null;
                  }

                  let parentElement = document.querySelector('#${child.id}');
                  let attributeValue = getRecursiveAttribute(parentElement);
                  attributeValue;
                `;

                return [code, Blockly.JavaScript.ORDER_ATOMIC];
              };

              // to attach events to custom-table-container elements
              categoryXml += `<block type="attach-events-${childParentAttribute}-${child.id}"></block>`;

              Blockly.Blocks[`attach-events-${childParentAttribute}-${child.id}`] = {
                init: function() {
                  this.appendDummyInput()
                      .appendField(`Attach Event to ${childParentAttribute}-${child.id}`);
                  this.appendDummyInput()
                      .appendField("Event")
                      .appendField(new Blockly.FieldDropdown([["Click", "click"], ["Change", "change"]]), "EVENT_TYPE");
                  this.appendValueInput("QUERY_ATTRIBUTE")
                      .setCheck("String")
                      .appendField("Attribute for query:");
                  this.appendValueInput("QUERY_VALUE")
                      .setCheck("String")
                      .appendField("Value for query:");
                  this.appendStatementInput("DO")
                      .appendField("Do");
                  this.setPreviousStatement(true, null);
                  this.setNextStatement(true, null);
                  this.setColour(230);
                  this.setTooltip('Attach an event to elements matching the query in all clones of dynamic-carousel-inner-container and wait for the element if it’s not present.');
                  this.setHelpUrl('');
                }
              };

              Blockly.JavaScript[`attach-events-${childParentAttribute}-${child.id}`] = function(block) {
                const queryAttribute = Blockly.JavaScript.valueToCode(block, "QUERY_ATTRIBUTE", Blockly.JavaScript.ORDER_ATOMIC);
                const queryValue = Blockly.JavaScript.valueToCode(block, "QUERY_VALUE", Blockly.JavaScript.ORDER_ATOMIC);
                const eventType = block.getFieldValue("EVENT_TYPE");
                const doCode = Blockly.JavaScript.statementToCode(block, "DO");

                const code = `
                  function attachEventToTargetElement(targetElement) {
                    if (!targetElement) return;

                    targetElement.addEventListener('${eventType}', function(event) {
                      ${doCode}
                    });
                  }

                  function waitForElementAndAttach() {
                    const dynamicContainers = document.querySelectorAll('.dynamic-carousel-inner-container');

                    let targetElement = null;

                    dynamicContainers.forEach(container => {
                      const potentialTarget = container.querySelector('[' + ${queryAttribute} + '="' + ${queryValue} + '"]');
                      if (potentialTarget) {
                        targetElement = potentialTarget;
                      }
                    });

                    if (targetElement) {
                      attachEventToTargetElement(targetElement);
                    } else {
                      // Use MutationObserver to wait for the element to be added if it's not present
                      const observer = new MutationObserver((mutations, obs) => {
                        dynamicContainers.forEach(container => {
                          const newTarget = container.querySelector('[' + ${queryAttribute} + '="' + ${queryValue} + '"]');
                          if (newTarget) {
                            attachEventToTargetElement(newTarget);
                            obs.disconnect(); // Stop observing after attaching the event
                          }
                        });
                      });

                      dynamicContainers.forEach(container => {
                        observer.observe(container, { childList: true, subtree: true });
                      });
                    }
                  }

                  waitForElementAndAttach();
                `;

                return code;
              };

            }
            console.log("+childTypesRef, childTypesTagRef, childTypesRefForTraits+", childTypesRef, childTypesTagRef, childTypesRefForTraits);
          }

          if (childParentAttribute === "input_select_with_search") {
            console.log("select picker:::::");

            let selectEl = child.querySelector("select");
            let selectElId;

            if (selectEl) {
              selectElId = selectEl.id;
            }
            //   component instance
            categoryXml += `<block type="component_instance-${childParentAttribute}-${child.id}"></block>`;

            Blockly.Blocks[
              `component_instance-${childParentAttribute}-${child.id}`
            ] = {
              init: function () {
                this.appendDummyInput().appendField(
                  `Component Instance of ${childParentAttribute}-${child.id}`
                );
                this.appendStatementInput("Properties");
                this.setPreviousStatement(true, null);
                this.setNextStatement(true, null);
                this.setColour(43);
                this.setTooltip("Get Instance of the Component");
              },
            };

            Blockly.JavaScript[
              `component_instance-${childParentAttribute}-${child.id}`
            ] = function (block) {
              const properties = Blockly.JavaScript.statementToCode(
                block,
                "Properties"
              );

              var code = `
              var childElement${child.id} = document.querySelector("#${child.id}");
              if(childElement${child.id}){
                let select${child.id}=childElement${child.id}.querySelector('select');

                if(select${child.id}){
                  ${properties}
                }
                 
              }
                                      `;

              return code;
            };

            //   js manipulation:
            categoryXml += `<block type="events-${childParentAttribute}-${child.id}"></block>`;

            //   for style manipulation:
            Blockly.Blocks[`events-${childParentAttribute}-${child.id}`] = {
              init: function () {
                this.appendDummyInput().appendField(
                  `Component Events for ${childParentAttribute}-${child.id}`
                );
                this.appendDummyInput()
                  .appendField("On Event")
                  .appendField(
                    new Blockly.FieldDropdown([
                      ["show", "show"],
                      ["shown", "shown"],
                      ["hide", "hide"],
                      ["hidden", "hidden"],
                      ["loaded", "loaded"],
                      ["rendered", "rendered"],
                      ["refreshed", "refreshed"],
                      ["changed", "changed"],
                    ]),
                    "EVENT"
                  );
                this.appendStatementInput("DO").appendField("Do");
                this.setPreviousStatement(true, null);
                this.setNextStatement(true, null);
                this.setColour(230);
                this.setTooltip("Add event handler To the component");
                this.setHelpUrl("");
              },
            };

            Blockly.JavaScript[`events-${childParentAttribute}-${child.id}`] =
              function (block) {
                var event = block.getFieldValue("EVENT");
                var code = "";
                var actionCode = Blockly.JavaScript.statementToCode(
                  block,
                  "DO"
                );

                if (event && actionCode) {
                  code += `
              
                 window.$("#${selectElId}").on('${event}.bs.select', function (e, clickedIndex, isSelected, previousValue) {
                   ${actionCode}
                 });
                  
                  `;
                }

                return code;
              };

            categoryXml += `<block type="target-property-${childParentAttribute}-${child.id}"></block>`;
            Blockly.Blocks[
              `target-property-${childParentAttribute}-${child.id}`
            ] = {
              init: function () {
                this.appendDummyInput().appendField(
                  `target-property-${childParentAttribute}-${child.id}`
                );
                this.setOutput(true, "String");
                this.setColour(230);
                this.setTooltip(
                  `Get the event value of the ${childParentAttribute}-${child.id} Input`
                );
                this.setHelpUrl("");
              },
            };

            Blockly.JavaScript[
              `target-property-${childParentAttribute}-${child.id}`
            ] = function (block) {
              const code = "window.$(this).val()";

              return [code, Blockly.JavaScript.ORDER_ATOMIC];
            };

            categoryXml += `<block type="manipulate_value-${childParentAttribute}-${child.id}"></block>`;
            Blockly.Blocks[
              `manipulate_value-${childParentAttribute}-${child.id}`
            ] = {
              init: function () {
                this.appendDummyInput().appendField(
                  `Manipulate Value of ${childParentAttribute}-${child.id}`
                );
                this.appendValueInput("APPEND_VALUE")
                  .setCheck(null)
                  .appendField("Provide Single Value or Array of String :");
                this.setPreviousStatement(true, null);
                this.setNextStatement(true, null);
                this.setColour(230);
                this.setTooltip("Manipulate Value of Select Picker");
              },
            };

            Blockly.JavaScript[
              `manipulate_value-${childParentAttribute}-${child.id}`
            ] = function (block) {
              const appendValues = Blockly.JavaScript.valueToCode(
                block,
                "APPEND_VALUE",
                Blockly.JavaScript.ORDER_ASSIGNMENT
              );

              var code = `
      
      if(${appendValues}){
      window.$("#${selectElId}").val(${appendValues}).change();
      window.$("#${selectElId}").selectpicker('refresh'); 
      }
                              
                          `;

              return code;
            };
          }

          if (childParentAttribute === "input_file_container") {
            categoryXml += `<block type="${childParentAttribute}-${child.id}-url" colour="210"></block>`;
            categoryXml += `<block type="${childParentAttribute}-${child.id}-set-url" colour="210"></block>`;
            categoryXml += `<block type="${childParentAttribute}-${child.id}-fileName" colour="210"></block>`;
            categoryXml += `<block type="${childParentAttribute}-${child.id}-set-fileName" colour="210"></block>`;
            categoryXml += `<block type="${childParentAttribute}-${child.id}-mutation_observer" colour="210"></block>`;
            
            // getAttribute('data_file_url') value from DOM tag
            Blockly.Blocks[`${childParentAttribute}-${child.id}-url`] = {
              init: function () {
                this.appendDummyInput().appendField(
                  `get File Url from ${childParentAttribute}-${child.id}`
                );
                this.setOutput(true, "String");
                this.setColour(83);
                this.setTooltip(
                  `get File Url from ${childParentAttribute}-${child.id}`
                );
                this.setHelpUrl("");
              },
            };

            Blockly.JavaScript[`${childParentAttribute}-${child.id}-url`] =
              function (block) {
                var code = `document.getElementById("${child.id}").getAttribute('data_file_url')`;

                return [code, Blockly.JavaScript.ORDER_ATOMIC];
            };

            // setAttribute('data_file_url') value from DOM tag
            Blockly.Blocks[`${childParentAttribute}-${child.id}-set-url`] = {
              init: function () {
                this.appendValueInput("URL_VALUE")
                    .setCheck("String")
                    .appendField(`set File Url for ${childParentAttribute}-${child.id} to`);
                this.appendDummyInput()
                    .appendField("Clear session storage")
                    .appendField(new Blockly.FieldCheckbox("FALSE"), "CLEAR_SESSION");
                this.setPreviousStatement(true, null);
                this.setNextStatement(true, null);
                this.setColour(83);
                this.setTooltip(`Set the 'data_file_url' attribute of ${childParentAttribute}-${child.id}`);
                this.setHelpUrl("");
              },
            };

            Blockly.JavaScript[`${childParentAttribute}-${child.id}-set-url`] = function (block) {
              var value = Blockly.JavaScript.valueToCode(block, 'URL_VALUE', Blockly.JavaScript.ORDER_ATOMIC) || "''";
              var clearSession = block.getFieldValue('CLEAR_SESSION') === 'TRUE';

              var code = `document.getElementById("${child.id}").setAttribute('data_file_url', ${value});\n`;

              if (clearSession) {
                code += `
                  if (sessionStorage.getItem('data_file_url_${child.id}')) {
                    sessionStorage.removeItem('data_file_url_${child.id}');
                  }
                `;
              }

              return code;
            };

            // getAttribute('data_file_name') value from DOM tag
            Blockly.Blocks[`${childParentAttribute}-${child.id}-fileName`] = {
              init: function () {
                this.appendDummyInput().appendField(
                  `get File Name from ${childParentAttribute}-${child.id}`
                );
                this.setOutput(true, "String");
                this.setColour(83);
                this.setTooltip(
                  `get File Name from ${childParentAttribute}-${child.id}`
                );
                this.setHelpUrl("");
              },
            };

            Blockly.JavaScript[`${childParentAttribute}-${child.id}-fileName`] =
              function (block) {
                var code = `document.getElementById("${child.id}").getAttribute('data_file_name')`;

                return [code, Blockly.JavaScript.ORDER_ATOMIC];
            };

            // setAttribute('data_file_name') value from DOM tag
            Blockly.Blocks[`${childParentAttribute}-${child.id}-set-fileName`] = {
              init: function () {
                this.appendValueInput("FILENAME_VALUE")
                    .setCheck("String")
                    .appendField(`set File Name for ${childParentAttribute}-${child.id} to`);
                this.appendDummyInput()
                    .appendField("Clear session storage?")
                    .appendField(new Blockly.FieldCheckbox("FALSE"), "CLEAR_SESSION");
                this.setPreviousStatement(true, null);
                this.setNextStatement(true, null);
                this.setColour(83);
                this.setTooltip(`Set the 'data_file_name' attribute of ${childParentAttribute}-${child.id}`);
                this.setHelpUrl("");
              },
            };

            Blockly.JavaScript[`${childParentAttribute}-${child.id}-set-fileName`] = function (block) {
              var value = Blockly.JavaScript.valueToCode(block, 'FILENAME_VALUE', Blockly.JavaScript.ORDER_ATOMIC) || "''";
              var clearSession = block.getFieldValue('CLEAR_SESSION') === 'TRUE';
              var code = `document.getElementById("${child.id}").setAttribute('data_file_name', ${value});\n`;

              if (clearSession) {
                code += `
                  if (sessionStorage.getItem('data_file_name_${child.id}')) {
                    sessionStorage.removeItem('data_file_name_${child.id}');
                  }
                `;
              }

              return code;
            };

            // mutation observer instead of 'change' event for a label of input type file
            Blockly.Blocks[`${childParentAttribute}-${child.id}-mutation_observer`] = {
              init: function() {
                this.appendDummyInput()
                    .appendField("Observe element with ID")
                    .appendField(new Blockly.FieldTextInput("element_id"), "ELEMENT_ID");
                this.appendDummyInput()
                    .appendField("Attributes to monitor (comma-separated)")
                    .appendField(new Blockly.FieldTextInput("attribute1,attribute2"), "ATTRIBUTES");
                this.appendStatementInput("DO")
                    .setCheck(null)
                    .appendField("Do");
                this.appendDummyInput()
                    .appendField("Stop observer")
                    .appendField(new Blockly.FieldCheckbox("FALSE"), "STOP_OBSERVER");
                this.setPreviousStatement(true, null);
                this.setNextStatement(true, null);
                this.setColour(230);
                this.setTooltip('Observes changes to specified attributes of a specified element and executes the contained statements. Optionally, can stop the observer.');
                this.setHelpUrl('');
              }
            };

            Blockly.JavaScript[`${childParentAttribute}-${child.id}-mutation_observer`] = function(block) {
              var elementId = block.getFieldValue('ELEMENT_ID');
              var attributesToMonitor = block.getFieldValue('ATTRIBUTES').split(',').map(attr => attr.trim());
              var statements_do = Blockly.JavaScript.statementToCode(block, 'DO');
              var stopObserver = block.getFieldValue('STOP_OBSERVER') === 'TRUE';

              var code = `
                (function() {
                  var targetNode = document.getElementById("${elementId}");
                  if (!targetNode) {
                    console.warn("Element with ID ${elementId} not found.");
                    return;
                  }

                  var observerOptions = {
                    childList: false,
                    attributes: true,
                    attributeFilter: ${JSON.stringify(attributesToMonitor)},
                    subtree: false
                  };

                  var previousValues = {};

                  var observerCallback = function(mutationsList, observer) {
                    for (var mutation of mutationsList) {
                      if (mutation.type === 'attributes' && ${JSON.stringify(attributesToMonitor)}.includes(mutation.attributeName)) {
                        var newValue = targetNode.getAttribute(mutation.attributeName);
                        var oldValue = previousValues[mutation.attributeName];

                        if (newValue !== oldValue) {
                          previousValues[mutation.attributeName] = newValue;
                          
                          ${statements_do}
                          
                          if (${stopObserver}) {
                            observer.disconnect();
                            console.log("Observer stopped.");
                          }
                          break;
                        }
                      }
                    }
                  };

                  ${JSON.stringify(attributesToMonitor)}.forEach(attr => {
                    previousValues[attr] = targetNode.getAttribute(attr);
                  });

                  var observer = new MutationObserver(observerCallback);
                  observer.observe(targetNode, observerOptions);

                  var reconnectObserver = function() {
                    observer.observe(targetNode, observerOptions);
                  };
                  
                  if (${!stopObserver}) {
                    setTimeout(reconnectObserver, 0);
                  }
                })();
              `;
              return code;
            };
          }

          if (childParentAttribute === "input_file_api_container") {
            categoryXml += `<block type="${childParentAttribute}-${child.id}-url" colour="210"></block>`;
            Blockly.Blocks[`${childParentAttribute}-${child.id}-url`] = {
              init: function () {
                this.appendDummyInput().appendField(
                  `get File Url from ${childParentAttribute}-${child.id}`
                );
                this.setOutput(true, "String");
                this.setColour(83);
                this.setTooltip(
                  `get File Url from ${childParentAttribute}-${child.id}`
                );
                this.setHelpUrl("");
              },
            };

            Blockly.JavaScript[`${childParentAttribute}-${child.id}-url`] =
              function (block) {
                var code = `document.getElementById("${child.id}").getAttribute('data_file_api_url')`;

                return [code, Blockly.JavaScript.ORDER_ATOMIC];
              };

            categoryXml += `<block type="${childParentAttribute}-${child.id}-api_trait"></block>`;

            Blockly.Blocks[`${childParentAttribute}-${child.id}-api_trait`] = {
              init: function () {
                this.appendDummyInput().appendField(
                  `Customise Traits of ${childParentAttribute}-${child.id}`
                );
                this.appendValueInput("PROPERTY_VALUE").appendField("Value:");
                this.setPreviousStatement(true, null);
                this.setNextStatement(true, null);
                this.setColour(180);
                this.setTooltip("Manipulate Traits of a File Upload comp");
              },
            };

            Blockly.JavaScript[
              `${childParentAttribute}-${child.id}-api_trait`
            ] = function (block) {
              const propertyValue = Blockly.JavaScript.valueToCode(
                block,
                "PROPERTY_VALUE",
                Blockly.JavaScript.ORDER_ATOMIC
              );

              let code = `
              let subString${child.id}= window.location.href.indexOf("/editor/") !== -1 &&
              window.location.href.indexOf("?projectId=") !== -1 ||
            window.location.href.indexOf("/large_preview/") !== -1 ||
            window.location.href.indexOf("/tab_preview/") !== -1 ||
            window.location.href.indexOf("/mobile_preview/") !== -1 ||
            window.location.href.indexOf("/fragment_editor/") !== -1;
              if(subString${child.id}){
                let allComponents${child.id} = window.top.editor.getWrapper();
              let childCompEditor${child.id}=allComponents${child.id}.find('#${child.id}')[0];
              if(childCompEditor${child.id}){
                childCompEditor${child.id}.set( ${propertyValue});
              }
              }else{
                if(typeof ${propertyValue} === 'object'){
                  Object.keys(${propertyValue}).forEach(function(key) {
                    window[key + "${child.id}"] = ${propertyValue}[key];
                });
                }
              }
            
              `;
              return code;
            };
          }

          if (childParentAttribute === "3d-urdf") {
            categoryXml += `<block type="${childParentAttribute}-${child.id}-api_trait"></block>`;

            Blockly.Blocks[`${childParentAttribute}-${child.id}-api_trait`] = {
              init: function () {
                this.appendDummyInput().appendField(
                  `Customise Traits of ${childParentAttribute}-${child.id}`
                );
                this.appendValueInput("PROPERTY_VALUE").appendField("Value:");
                this.setPreviousStatement(true, null);
                this.setNextStatement(true, null);
                this.setColour(180);
                this.setTooltip("Manipulate Traits of a File Upload comp");
              },
            };

            Blockly.JavaScript[
              `${childParentAttribute}-${child.id}-api_trait`
            ] = function (block) {
              const propertyValue = Blockly.JavaScript.valueToCode(
                block,
                "PROPERTY_VALUE",
                Blockly.JavaScript.ORDER_ATOMIC
              );

              let code = `
              let subString${child.id}= window.location.href.indexOf("/editor/") !== -1 &&
              window.location.href.indexOf("?projectId=") !== -1 ||
            window.location.href.indexOf("/large_preview/") !== -1 ||
            window.location.href.indexOf("/tab_preview/") !== -1 ||
            window.location.href.indexOf("/mobile_preview/") !== -1 ||
            window.location.href.indexOf("/fragment_editor/") !== -1;
              if(subString${child.id}){
                let allComponents${child.id} = window.top.editor.getWrapper();
              let childCompEditor${child.id}=allComponents${child.id}.find('#${child.id}')[0];
              if(childCompEditor${child.id}){
                childCompEditor${child.id}.set( ${propertyValue});
              }
              }else{
                if(typeof ${propertyValue} === 'object'){
                  Object.keys(${propertyValue}).forEach(function(key) {
                    window[key + "${child.id}"] = ${propertyValue}[key];
                });
                }
              }
            
              `;
              return code;
            };
          }

          if (childParentAttribute === "input_select") {
            //   component instance
            categoryXml += `<block type="component_instance-${childParentAttribute}-${child.id}"></block>`;

            Blockly.Blocks[
              `component_instance-${childParentAttribute}-${child.id}`
            ] = {
              init: function () {
                this.appendDummyInput().appendField(
                  `component_instance-${childParentAttribute}-${child.id}`
                );
                this.appendStatementInput("Properties");
                this.setPreviousStatement(true, null);
                this.setNextStatement(true, null);
                this.setColour(83);
                this.setTooltip("Get Instance of the Component");
              },
            };

            Blockly.JavaScript[
              `component_instance-${childParentAttribute}-${child.id}`
            ] = function (block) {
              const properties = Blockly.JavaScript.statementToCode(
                block,
                "Properties"
              );

              var code = `
                                      var childElement${child.id} = document.querySelector("#${child.id}");
                                      if(childElement${child.id}){
                                        let selectChild${child.id}=childElement${child.id}.querySelector('select');

                                        if(selectChild${child.id}){
                                          ${properties}
                                        }
                                         
                                      }
                                      `;

              return code;
            };

            categoryXml += `<block type="manipulate-value-${childParentAttribute}-${child.id}"></block>`;

            Blockly.Blocks[
              `manipulate-value-${childParentAttribute}-${child.id}`
            ] = {
              init: function () {
                this.appendDummyInput().appendField(
                  `Set Property of ${childParentAttribute}-${child.id}`
                );
                this.appendDummyInput()
                  .appendField("Property:")
                  .appendField(
                    new Blockly.FieldDropdown([
                      ["value", "value"],
                      ["textContent", "textContent"],
                      ["multiple", "multiple"],
                    ]),
                    "PROPERTY"
                  );
                this.appendValueInput("VALUE")
                  .setCheck(null)
                  .appendField("Value:");
                this.setPreviousStatement(true, null);
                this.setNextStatement(true, null);
                this.setColour(172);
                this.setTooltip("Set Property of the Component");
              },
            };

            Blockly.JavaScript[
              `manipulate-value-${childParentAttribute}-${child.id}`
            ] = function (block) {
              const property = block.getFieldValue("PROPERTY");
              const value = Blockly.JavaScript.valueToCode(
                block,
                "VALUE",
                Blockly.JavaScript.ORDER_ATOMIC
              );

              var code = `
                    selectChild${child.id}.${property} = ${value};
                  `;

              return code;
            };

            categoryXml += `<block type="custom_css_property-${childParentAttribute}-${child.id}"></block>`;

            //   for style manipulation:
            //   bbql
            Blockly.Blocks[
              `custom_css_property-${childParentAttribute}-${child.id}`
            ] = {
              init: function () {
                this.appendDummyInput().appendField(
                  `customise-styles-${childParentAttribute}-${child.id}`
                );
                this.appendValueInput("PROPERTY_NAME")
                  .setCheck("String")
                  .appendField("Property Name:");

                this.appendValueInput("PROPERTY_VALUE")
                  .setCheck("String")
                  .appendField("Value:");
                this.setPreviousStatement(true, null);
                this.setNextStatement(true, null);
                this.setColour(160);
                this.setTooltip("Specify a CSS property, its type, and value.");
              },
            };

            Blockly.JavaScript[
              `custom_css_property-${childParentAttribute}-${child.id}`
            ] = function (block) {
              const propertyName = Blockly.JavaScript.valueToCode(
                block,
                "PROPERTY_NAME",
                Blockly.JavaScript.ORDER_ATOMIC
              );

              const propertyValue = Blockly.JavaScript.valueToCode(
                block,
                "PROPERTY_VALUE",
                Blockly.JavaScript.ORDER_ATOMIC
              );

              var code = `
              selectChild${child.id}.style[${propertyName}]=${propertyValue}
                                      `;

              return code;
            };

            //   js manipulation:
            categoryXml += `<block type="events-${childParentAttribute}-${child.id}"></block>`;

            //   for style manipulation:
            Blockly.Blocks[`events-${childParentAttribute}-${child.id}`] = {
              init: function () {
                this.appendDummyInput().appendField(
                  `Component Events-${childParentAttribute}-${child.id}`
                );
                this.appendDummyInput()
                  .appendField("On Event")
                  .appendField(
                    new Blockly.FieldDropdown([
                      ["Click", "click"],
                      ["Change", "change"],
                    ]),
                    "EVENT"
                  );
                this.appendStatementInput("DO").appendField("Do");
                this.setPreviousStatement(true, null);
                this.setNextStatement(true, null);
                this.setColour(160);
                this.setTooltip("Add event handler To the component");
                this.setHelpUrl("");
              },
            };

            Blockly.JavaScript[`events-${childParentAttribute}-${child.id}`] =
              function (block) {
                var event = block.getFieldValue("EVENT");
                var code = "";
                var actionCode = Blockly.JavaScript.statementToCode(
                  block,
                  "DO"
                );

                if (event && actionCode) {
                  code += `
              selectChild${child.id}.addEventListener('${event}', function(event) {
                ${actionCode}
              });
                  `;
                }

                return code;
              };

            categoryXml += `<block type="target-property-${childParentAttribute}-${child.id}"></block>`;
            Blockly.Blocks[
              `target-property-${childParentAttribute}-${child.id}`
            ] = {
              init: function () {
                this.appendDummyInput().appendField(
                  `target-property-${childParentAttribute}-${child.id}`
                );
                this.setOutput(true, "String");
                this.setColour(160);
                this.setTooltip(
                  `Get the event value of the ${childParentAttribute}-${child.id} Input`
                );
                this.setHelpUrl("");
              },
            };

            Blockly.JavaScript[
              `target-property-${childParentAttribute}-${child.id}`
            ] = function (block) {
              const code = "event.target.value";

              return [code, Blockly.JavaScript.ORDER_ATOMIC];
            };

            categoryXml += `<block type="disable_component-${childParentAttribute}-${child.id}"></block>`;
            Blockly.Blocks[
              `disable_component-${childParentAttribute}-${child.id}`
            ] = {
              init: function () {
                this.appendDummyInput().appendField(
                  `Disable ${childParentAttribute}`
                );
                this.setPreviousStatement(true, null);
                this.setNextStatement(true, null);
                this.setColour(160);
                this.setTooltip("Disable the Component");
              },
            };

            Blockly.JavaScript[
              `disable_component-${childParentAttribute}-${child.id}`
            ] = function (block) {
              var code = `
                          
              selectChild${child.id}.disabled = true;
                            
                        `;

              return code;
            };

            categoryXml += `<block type="selected-options-${childParentAttribute}-${child.id}"></block>`;
            Blockly.Blocks[
              `selected-options-${childParentAttribute}-${child.id}`
            ] = {
              init: function () {
                this.appendDummyInput().appendField(
                  `selected-options-${childParentAttribute}-${child.id}`
                );
                this.appendValueInput("RESPONSE_VARIABLE")
                  .setCheck("Variable")
                  .appendField("Store Response Data in Variable:");
                this.setPreviousStatement(true, null);
                this.setNextStatement(true, null);
                this.setColour(160);
                this.setTooltip(
                  `Grab Selected Options From  ${childParentAttribute}-${child.id}`
                );
              },
            };

            Blockly.JavaScript[
              `selected-options-${childParentAttribute}-${child.id}`
            ] = function (block) {
              const responseVariable = Blockly.JavaScript.valueToCode(
                block,
                "RESPONSE_VARIABLE",
                Blockly.JavaScript.ORDER_ASSIGNMENT
              );
              var code = `
              const selectedOptions = selectChild${child.id}.selectedOptions;
              
              const uniqueValuesSet = new Set();
    
              for (const option of selectedOptions) {
                  uniqueValuesSet.add(option.value);
              }
    
              ${responseVariable} = Array.from(uniqueValuesSet);
            `;
              return code;
            };

            categoryXml += `<block type="selected-options-with-id-${childParentAttribute}-${child.id}"></block>`;
            Blockly.Blocks[
              `selected-options-with-id-${childParentAttribute}-${child.id}`
            ] = {
              init: function () {
                this.appendDummyInput().appendField(
                  `selected-options-with-id-${childParentAttribute}-${child.id}`
                );
                this.appendValueInput("RESPONSE_VARIABLE")
                  .setCheck("Variable")
                  .appendField("Store Response Data in Variable:");
                this.setPreviousStatement(true, null);
                this.setNextStatement(true, null);
                this.setColour(160);
                this.setTooltip(
                  `Grab Selected Options From  ${childParentAttribute}-${child.id}`
                );
              },
            };

            Blockly.JavaScript[
              `selected-options-with-id-${childParentAttribute}-${child.id}`
            ] = function (block) {
              const responseVariable = Blockly.JavaScript.valueToCode(
                block,
                "RESPONSE_VARIABLE",
                Blockly.JavaScript.ORDER_ASSIGNMENT
              );
              var code = `
            const selectedOptions = selectChild${child.id}.selectedOptions;
            
            const uniqueValuesSet = new Set();
        
            for (const option of selectedOptions) {
                uniqueValuesSet.add({ value: option.value, _id: option.getAttribute('data_db_id') });
            }
        
            ${responseVariable} = Array.from(uniqueValuesSet);
          `;
              return code;
            };

            categoryXml += `<block type="component_instance-for-db-data-${childParentAttribute}-${child.id}"></block>`;

            Blockly.Blocks[
              `component_instance-for-db-data-${childParentAttribute}-${child.id}`
            ] = {
              init: function () {
                this.appendDummyInput().appendField(
                  `component_instance-for-db-data-${childParentAttribute}-${child.id}`
                );
                this.appendStatementInput("Properties");
                this.setPreviousStatement(true, null);
                this.setNextStatement(true, null);
                this.setColour(160);
                this.setTooltip("Get Instance of the Component");
              },
            };

            Blockly.JavaScript[
              `component_instance-for-db-data-${childParentAttribute}-${child.id}`
            ] = function (block) {
              const properties = Blockly.JavaScript.statementToCode(
                block,
                "Properties"
              );

              var code = `
                    setTimeout(function() {
                                      var childElement${child.id} = document.querySelector("#${child.id}");
                                      if(childElement${child.id}){
                                        let selectChild${child.id}=childElement${child.id}.querySelector('select');

                                        if(selectChild${child.id}){
                                          ${properties}
                                        }
                                      }
                                    },2000)
                                      `;

              return code;
            };

            categoryXml += `<block type="grab-options-from-database-${childParentAttribute}-${child.id}"></block>`;
            Blockly.Blocks[
              `grab-options-from-database-${childParentAttribute}-${child.id}`
            ] = {
              init: function () {
                this.appendDummyInput().appendField(
                  `grab-options-from-database-${childParentAttribute}-${child.id}`
                );
                this.appendValueInput("OPTION_LIST")
                  .setCheck("Array")
                  .appendField("Set Array Of Selected Options:");
                this.setPreviousStatement(true, null);
                this.setNextStatement(true, null);
                this.setColour(160);
                this.setTooltip(`Grab Selected Options from database`);
              },
            };

            Blockly.JavaScript[
              `grab-options-from-database-${childParentAttribute}-${child.id}`
            ] = function (block) {
              const optionList = Blockly.JavaScript.valueToCode(
                block,
                "OPTION_LIST",
                Blockly.JavaScript.ORDER_ASSIGNMENT
              );

              var code = `
              const selectedOptions = selectChild${child.id}.options;
              const optionValues = ${optionList};
            
              for (const option of selectedOptions) {
                if (optionValues.includes(option.getAttribute('data_db_id'))) {
                  option.selected = true;
                }
              }
            `;

              return code;
            };

            categoryXml += `<block type="clear-value-${child.tagName}-${child.id}"></block>`;

            Blockly.Blocks[`clear-value-${child.tagName}-${child.id}`] = {
              init: function () {
                this.appendDummyInput().appendField(
                  `Clear Selection of ${child.tagName}-${child.id}`
                );
                this.setPreviousStatement(true, null);
                this.setNextStatement(true, null);
                this.setColour(160);
                this.setTooltip("Clear Selection of the Component");
              },
            };

            Blockly.JavaScript[`clear-value-${child.tagName}-${child.id}`] =
              function (block) {
                var code = `
                    const selectedOptions = childElement${child.id}.options;      
                    for (const option of selectedOptions) {
                      option.selected = false;
                    }
                                  `;

                return code;
              };
          }

          if (childParentAttribute === "input_select_v2") {
            categoryXml += `<block type="${childParentAttribute}-${child.id}-api_trait"></block>`;

            Blockly.Blocks[`${childParentAttribute}-${child.id}-api_trait`] = {
              init: function () {
                this.appendDummyInput().appendField(
                  `Customise Traits of ${childParentAttribute}-${child.id}`
                );
                this.appendValueInput("PROPERTY_VALUE").appendField("Value:");
                this.setPreviousStatement(true, null);
                this.setNextStatement(true, null);
                this.setColour(180);
                this.setTooltip("Manipulate Traits of a select comp");
              },
            };

            Blockly.JavaScript[
              `${childParentAttribute}-${child.id}-api_trait`
            ] = function (block) {
              const propertyValue = Blockly.JavaScript.valueToCode(
                block,
                "PROPERTY_VALUE",
                Blockly.JavaScript.ORDER_ATOMIC
              );

              let code = `
              let subString${child.id}= window.location.href.indexOf("/editor/") !== -1 &&
              window.location.href.indexOf("?projectId=") !== -1 ||
            window.location.href.indexOf("/large_preview/") !== -1 ||
            window.location.href.indexOf("/tab_preview/") !== -1 ||
            window.location.href.indexOf("/mobile_preview/") !== -1 ||
            window.location.href.indexOf("/fragment_editor/") !== -1;
              if(subString${child.id}){
                let allComponents${child.id} = window.top.editor.getWrapper();
              let childCompEditor${child.id}=allComponents${child.id}.find('#${child.id}')[0];
              if(childCompEditor${child.id}){
                childCompEditor${child.id}.set( ${propertyValue});
              }
              }else{
                if(typeof ${propertyValue} === 'object'){
                  Object.keys(${propertyValue}).forEach(function(key) {
                    window[key + "${child.id}"] = ${propertyValue}[key];
                });
                }
              }
            
              `;
              return code;
            };
            //   component instance
            categoryXml += `<block type="component_instance-${childParentAttribute}-${child.id}"></block>`;

            Blockly.Blocks[
              `component_instance-${childParentAttribute}-${child.id}`
            ] = {
              init: function () {
                this.appendDummyInput().appendField(
                  `component_instance-${childParentAttribute}-${child.id}`
                );
                this.appendStatementInput("Properties");
                this.setPreviousStatement(true, null);
                this.setNextStatement(true, null);
                this.setColour(83);
                this.setTooltip("Get Instance of the Component");
              },
            };

            Blockly.JavaScript[
              `component_instance-${childParentAttribute}-${child.id}`
            ] = function (block) {
              const properties = Blockly.JavaScript.statementToCode(
                block,
                "Properties"
              );

              var code = `
                                      var childElement${child.id} = document.querySelector("#${child.id}");
                                      if(childElement${child.id}){
                                        let selectChild${child.id}=childElement${child.id}.querySelector('select');

                                        if(selectChild${child.id}){
                                          ${properties}
                                        }
                                         
                                      }
                                      `;

              return code;
            };

            categoryXml += `<block type="manipulate-value-${childParentAttribute}-${child.id}"></block>`;

            Blockly.Blocks[
              `manipulate-value-${childParentAttribute}-${child.id}`
            ] = {
              init: function () {
                this.appendDummyInput().appendField(
                  `Set Property of ${childParentAttribute}-${child.id}`
                );
                this.appendDummyInput()
                  .appendField("Property:")
                  .appendField(
                    new Blockly.FieldDropdown([
                      ["value", "value"],
                      ["textContent", "textContent"],
                      ["multiple", "multiple"],
                    ]),
                    "PROPERTY"
                  );
                this.appendValueInput("VALUE")
                  .setCheck(null)
                  .appendField("Value:");
                this.setPreviousStatement(true, null);
                this.setNextStatement(true, null);
                this.setColour(172);
                this.setTooltip("Set Property of the Component");
              },
            };

            Blockly.JavaScript[
              `manipulate-value-${childParentAttribute}-${child.id}`
            ] = function (block) {
              const property = block.getFieldValue("PROPERTY");
              const value = Blockly.JavaScript.valueToCode(
                block,
                "VALUE",
                Blockly.JavaScript.ORDER_ATOMIC
              );

              var code = `
                    selectChild${child.id}.${property} = ${value};
                  `;

              return code;
            };

            categoryXml += `<block type="custom_css_property-${childParentAttribute}-${child.id}"></block>`;

            //   for style manipulation:
            //   bbql
            Blockly.Blocks[
              `custom_css_property-${childParentAttribute}-${child.id}`
            ] = {
              init: function () {
                this.appendDummyInput().appendField(
                  `customise-styles-${childParentAttribute}-${child.id}`
                );
                this.appendValueInput("PROPERTY_NAME")
                  .setCheck("String")
                  .appendField("Property Name:");

                this.appendValueInput("PROPERTY_VALUE")
                  .setCheck("String")
                  .appendField("Value:");
                this.setPreviousStatement(true, null);
                this.setNextStatement(true, null);
                this.setColour(160);
                this.setTooltip("Specify a CSS property, its type, and value.");
              },
            };

            Blockly.JavaScript[
              `custom_css_property-${childParentAttribute}-${child.id}`
            ] = function (block) {
              const propertyName = Blockly.JavaScript.valueToCode(
                block,
                "PROPERTY_NAME",
                Blockly.JavaScript.ORDER_ATOMIC
              );

              const propertyValue = Blockly.JavaScript.valueToCode(
                block,
                "PROPERTY_VALUE",
                Blockly.JavaScript.ORDER_ATOMIC
              );

              var code = `
              selectChild${child.id}.style[${propertyName}]=${propertyValue}
                                      `;

              return code;
            };

            //   js manipulation:
            categoryXml += `<block type="events-${childParentAttribute}-${child.id}"></block>`;

            //   for style manipulation:
            Blockly.Blocks[`events-${childParentAttribute}-${child.id}`] = {
              init: function () {
                this.appendDummyInput().appendField(
                  `Component Events-${childParentAttribute}-${child.id}`
                );
                this.appendDummyInput()
                  .appendField("On Event")
                  .appendField(
                    new Blockly.FieldDropdown([
                      ["Click", "click"],
                      ["Change", "change"],
                    ]),
                    "EVENT"
                  );
                this.appendStatementInput("DO").appendField("Do");
                this.setPreviousStatement(true, null);
                this.setNextStatement(true, null);
                this.setColour(160);
                this.setTooltip("Add event handler To the component");
                this.setHelpUrl("");
              },
            };

            Blockly.JavaScript[`events-${childParentAttribute}-${child.id}`] =
              function (block) {
                var event = block.getFieldValue("EVENT");
                var code = "";
                var actionCode = Blockly.JavaScript.statementToCode(
                  block,
                  "DO"
                );

                if (event && actionCode) {
                  code += `
              selectChild${child.id}.addEventListener('${event}', function(event) {
                ${actionCode}
              });
                  `;
                }

                return code;
              };

            categoryXml += `<block type="target-property-${childParentAttribute}-${child.id}"></block>`;
            Blockly.Blocks[
              `target-property-${childParentAttribute}-${child.id}`
            ] = {
              init: function () {
                this.appendDummyInput().appendField(
                  `target-property-${childParentAttribute}-${child.id}`
                );
                this.setOutput(true, "String");
                this.setColour(160);
                this.setTooltip(
                  `Get the event value of the ${childParentAttribute}-${child.id} Input`
                );
                this.setHelpUrl("");
              },
            };

            Blockly.JavaScript[
              `target-property-${childParentAttribute}-${child.id}`
            ] = function (block) {
              const code = "event.target.value";

              return [code, Blockly.JavaScript.ORDER_ATOMIC];
            };

            categoryXml += `<block type="disable_component-${childParentAttribute}-${child.id}"></block>`;
            Blockly.Blocks[
              `disable_component-${childParentAttribute}-${child.id}`
            ] = {
              init: function () {
                this.appendDummyInput().appendField(
                  `Disable ${childParentAttribute}`
                );
                this.setPreviousStatement(true, null);
                this.setNextStatement(true, null);
                this.setColour(160);
                this.setTooltip("Disable the Component");
              },
            };

            Blockly.JavaScript[
              `disable_component-${childParentAttribute}-${child.id}`
            ] = function (block) {
              var code = `
                          
              selectChild${child.id}.disabled = true;
                            
                        `;

              return code;
            };

            categoryXml += `<block type="selected-options-${childParentAttribute}-${child.id}"></block>`;
            Blockly.Blocks[
              `selected-options-${childParentAttribute}-${child.id}`
            ] = {
              init: function () {
                this.appendDummyInput().appendField(
                  `selected-options-${childParentAttribute}-${child.id}`
                );
                this.appendValueInput("RESPONSE_VARIABLE")
                  .setCheck("Variable")
                  .appendField("Store Response Data in Variable:");
                this.setPreviousStatement(true, null);
                this.setNextStatement(true, null);
                this.setColour(160);
                this.setTooltip(
                  `Grab Selected Options From  ${childParentAttribute}-${child.id}`
                );
              },
            };

            Blockly.JavaScript[
              `selected-options-${childParentAttribute}-${child.id}`
            ] = function (block) {
              const responseVariable = Blockly.JavaScript.valueToCode(
                block,
                "RESPONSE_VARIABLE",
                Blockly.JavaScript.ORDER_ASSIGNMENT
              );
              var code = `
              const selectedOptions = selectChild${child.id}.selectedOptions;
              
              const uniqueValuesSet = new Set();
    
              for (const option of selectedOptions) {
                  uniqueValuesSet.add(option.value);
              }
    
              ${responseVariable} = Array.from(uniqueValuesSet);
            `;
              return code;
            };

            categoryXml += `<block type="selected-options-with-id-${childParentAttribute}-${child.id}"></block>`;
            Blockly.Blocks[
              `selected-options-with-id-${childParentAttribute}-${child.id}`
            ] = {
              init: function () {
                this.appendDummyInput().appendField(
                  `selected-options-with-id-${childParentAttribute}-${child.id}`
                );
                this.appendValueInput("RESPONSE_VARIABLE")
                  .setCheck("Variable")
                  .appendField("Store Response Data in Variable:");
                this.setPreviousStatement(true, null);
                this.setNextStatement(true, null);
                this.setColour(160);
                this.setTooltip(
                  `Grab Selected Options From  ${childParentAttribute}-${child.id}`
                );
              },
            };

            Blockly.JavaScript[
              `selected-options-with-id-${childParentAttribute}-${child.id}`
            ] = function (block) {
              const responseVariable = Blockly.JavaScript.valueToCode(
                block,
                "RESPONSE_VARIABLE",
                Blockly.JavaScript.ORDER_ASSIGNMENT
              );
              var code = `
            const selectedOptions = selectChild${child.id}.selectedOptions;
            
            const uniqueValuesSet = new Set();
        
            for (const option of selectedOptions) {
                uniqueValuesSet.add({ value: option.value, _id: option.getAttribute('data_db_id') });
            }
        
            ${responseVariable} = Array.from(uniqueValuesSet);
          `;
              return code;
            };

            categoryXml += `<block type="component_instance-for-db-data-${childParentAttribute}-${child.id}"></block>`;

            Blockly.Blocks[
              `component_instance-for-db-data-${childParentAttribute}-${child.id}`
            ] = {
              init: function () {
                this.appendDummyInput().appendField(
                  `component_instance-for-db-data-${childParentAttribute}-${child.id}`
                );
                this.appendStatementInput("Properties");
                this.setPreviousStatement(true, null);
                this.setNextStatement(true, null);
                this.setColour(160);
                this.setTooltip("Get Instance of the Component");
              },
            };

            Blockly.JavaScript[
              `component_instance-for-db-data-${childParentAttribute}-${child.id}`
            ] = function (block) {
              const properties = Blockly.JavaScript.statementToCode(
                block,
                "Properties"
              );

              var code = `
                    setTimeout(function() {
                                      var childElement${child.id} = document.querySelector("#${child.id}");
                                      if(childElement${child.id}){
                                        let selectChild${child.id}=childElement${child.id}.querySelector('select');

                                        if(selectChild${child.id}){
                                          ${properties}
                                        }
                                      }
                                    },2000)
                                      `;

              return code;
            };

            categoryXml += `<block type="grab-options-from-database-${childParentAttribute}-${child.id}"></block>`;
            Blockly.Blocks[
              `grab-options-from-database-${childParentAttribute}-${child.id}`
            ] = {
              init: function () {
                this.appendDummyInput().appendField(
                  `grab-options-from-database-${childParentAttribute}-${child.id}`
                );
                this.appendValueInput("OPTION_LIST")
                  .setCheck("Array")
                  .appendField("Set Array Of Selected Options:");
                this.setPreviousStatement(true, null);
                this.setNextStatement(true, null);
                this.setColour(160);
                this.setTooltip(`Grab Selected Options from database`);
              },
            };

            Blockly.JavaScript[
              `grab-options-from-database-${childParentAttribute}-${child.id}`
            ] = function (block) {
              const optionList = Blockly.JavaScript.valueToCode(
                block,
                "OPTION_LIST",
                Blockly.JavaScript.ORDER_ASSIGNMENT
              );

              var code = `
              const selectedOptions = selectChild${child.id}.options;
              const optionValues = ${optionList};
            
              for (const option of selectedOptions) {
                if (optionValues.includes(option.getAttribute('data_db_id'))) {
                  option.selected = true;
                }
              }
            `;

              return code;
            };
          }

          if (childParentAttribute === "input_select_v2DOT1") {
            categoryXml += `<block type="${childParentAttribute}-${child.id}-api_trait"></block>`;

            Blockly.Blocks[`${childParentAttribute}-${child.id}-api_trait`] = {
              init: function () {
                this.appendDummyInput().appendField(
                  `Customise Traits of ${childParentAttribute}-${child.id}`
                );
                this.appendValueInput("PROPERTY_VALUE").appendField("Value:");
                this.setPreviousStatement(true, null);
                this.setNextStatement(true, null);
                this.setColour(180);
                this.setTooltip("Manipulate Traits of a select comp");
              },
            };

            Blockly.JavaScript[
              `${childParentAttribute}-${child.id}-api_trait`
            ] = function (block) {
              const propertyValue = Blockly.JavaScript.valueToCode(
                block,
                "PROPERTY_VALUE",
                Blockly.JavaScript.ORDER_ATOMIC
              );

              let code = `
              let subString${child.id}= window.location.href.indexOf("/editor/") !== -1 &&
              window.location.href.indexOf("?projectId=") !== -1 ||
            window.location.href.indexOf("/large_preview/") !== -1 ||
            window.location.href.indexOf("/tab_preview/") !== -1 ||
            window.location.href.indexOf("/mobile_preview/") !== -1 ||
            window.location.href.indexOf("/fragment_editor/") !== -1;
              if(subString${child.id}){
                let allComponents${child.id} = window.top.editor.getWrapper();
              let childCompEditor${child.id}=allComponents${child.id}.find('#${child.id}')[0];
              if(childCompEditor${child.id}){
                childCompEditor${child.id}.set( ${propertyValue});
              }
              }else{
                if(typeof ${propertyValue} === 'object'){
                  Object.keys(${propertyValue}).forEach(function(key) {
                    window[key + "${child.id}"] = ${propertyValue}[key];
                });
                }
              }
            
              `;
              return code;
            };
            //   component instance
            categoryXml += `<block type="component_instance-${childParentAttribute}-${child.id}"></block>`;

            Blockly.Blocks[
              `component_instance-${childParentAttribute}-${child.id}`
            ] = {
              init: function () {
                this.appendDummyInput().appendField(
                  `component_instance-${childParentAttribute}-${child.id}`
                );
                this.appendStatementInput("Properties");
                this.setPreviousStatement(true, null);
                this.setNextStatement(true, null);
                this.setColour(83);
                this.setTooltip("Get Instance of the Component");
              },
            };

            Blockly.JavaScript[
              `component_instance-${childParentAttribute}-${child.id}`
            ] = function (block) {
              const properties = Blockly.JavaScript.statementToCode(
                block,
                "Properties"
              );

              var code = `
                                      var childElement${child.id} = document.querySelector("#${child.id}");
                                      if(childElement${child.id}){
                                        let selectChild${child.id}=childElement${child.id}.querySelector('select');

                                        if(selectChild${child.id}){
                                          ${properties}
                                        }
                                         
                                      }
                                      `;

              return code;
            };

            categoryXml += `<block type="manipulate-value-${childParentAttribute}-${child.id}"></block>`;

            Blockly.Blocks[
              `manipulate-value-${childParentAttribute}-${child.id}`
            ] = {
              init: function () {
                this.appendDummyInput().appendField(
                  `Set Property of ${childParentAttribute}-${child.id}`
                );
                this.appendDummyInput()
                  .appendField("Property:")
                  .appendField(
                    new Blockly.FieldDropdown([
                      ["value", "value"],
                      ["textContent", "textContent"],
                      ["multiple", "multiple"],
                    ]),
                    "PROPERTY"
                  );
                this.appendValueInput("VALUE")
                  .setCheck(null)
                  .appendField("Value:");
                this.setPreviousStatement(true, null);
                this.setNextStatement(true, null);
                this.setColour(172);
                this.setTooltip("Set Property of the Component");
              },
            };

            Blockly.JavaScript[
              `manipulate-value-${childParentAttribute}-${child.id}`
            ] = function (block) {
              const property = block.getFieldValue("PROPERTY");
              const value = Blockly.JavaScript.valueToCode(
                block,
                "VALUE",
                Blockly.JavaScript.ORDER_ATOMIC
              );

              var code = `
                    selectChild${child.id}.${property} = ${value};
                  `;

              return code;
            };

            categoryXml += `<block type="custom_css_property-${childParentAttribute}-${child.id}"></block>`;

            //   for style manipulation:
            //   bbql
            Blockly.Blocks[
              `custom_css_property-${childParentAttribute}-${child.id}`
            ] = {
              init: function () {
                this.appendDummyInput().appendField(
                  `customise-styles-${childParentAttribute}-${child.id}`
                );
                this.appendValueInput("PROPERTY_NAME")
                  .setCheck("String")
                  .appendField("Property Name:");

                this.appendValueInput("PROPERTY_VALUE")
                  .setCheck("String")
                  .appendField("Value:");
                this.setPreviousStatement(true, null);
                this.setNextStatement(true, null);
                this.setColour(160);
                this.setTooltip("Specify a CSS property, its type, and value.");
              },
            };

            Blockly.JavaScript[
              `custom_css_property-${childParentAttribute}-${child.id}`
            ] = function (block) {
              const propertyName = Blockly.JavaScript.valueToCode(
                block,
                "PROPERTY_NAME",
                Blockly.JavaScript.ORDER_ATOMIC
              );

              const propertyValue = Blockly.JavaScript.valueToCode(
                block,
                "PROPERTY_VALUE",
                Blockly.JavaScript.ORDER_ATOMIC
              );

              var code = `
              selectChild${child.id}.style[${propertyName}]=${propertyValue}
                                      `;

              return code;
            };

            //   js manipulation:
            categoryXml += `<block type="events-${childParentAttribute}-${child.id}"></block>`;

            //   for style manipulation:
            Blockly.Blocks[`events-${childParentAttribute}-${child.id}`] = {
              init: function () {
                this.appendDummyInput().appendField(
                  `Component Events-${childParentAttribute}-${child.id}`
                );
                this.appendDummyInput()
                  .appendField("On Event")
                  .appendField(
                    new Blockly.FieldDropdown([
                      ["Click", "click"],
                      ["Change", "change"],
                    ]),
                    "EVENT"
                  );
                this.appendStatementInput("DO").appendField("Do");
                this.setPreviousStatement(true, null);
                this.setNextStatement(true, null);
                this.setColour(160);
                this.setTooltip("Add event handler To the component");
                this.setHelpUrl("");
              },
            };

            Blockly.JavaScript[`events-${childParentAttribute}-${child.id}`] =
              function (block) {
                var event = block.getFieldValue("EVENT");
                var code = "";
                var actionCode = Blockly.JavaScript.statementToCode(
                  block,
                  "DO"
                );

                if (event && actionCode) {
                  code += `
              selectChild${child.id}.addEventListener('${event}', function(event) {
                ${actionCode}
              });
                  `;
                }

                return code;
              };

            categoryXml += `<block type="target-property-${childParentAttribute}-${child.id}"></block>`;
            Blockly.Blocks[
              `target-property-${childParentAttribute}-${child.id}`
            ] = {
              init: function () {
                this.appendDummyInput().appendField(
                  `target-property-${childParentAttribute}-${child.id}`
                );
                this.setOutput(true, "String");
                this.setColour(160);
                this.setTooltip(
                  `Get the event value of the ${childParentAttribute}-${child.id} Input`
                );
                this.setHelpUrl("");
              },
            };

            Blockly.JavaScript[
              `target-property-${childParentAttribute}-${child.id}`
            ] = function (block) {
              const code = "event.target.value";

              return [code, Blockly.JavaScript.ORDER_ATOMIC];
            };

            categoryXml += `<block type="disable_component-${childParentAttribute}-${child.id}"></block>`;
            Blockly.Blocks[
              `disable_component-${childParentAttribute}-${child.id}`
            ] = {
              init: function () {
                this.appendDummyInput().appendField(
                  `Disable ${childParentAttribute}`
                );
                this.setPreviousStatement(true, null);
                this.setNextStatement(true, null);
                this.setColour(160);
                this.setTooltip("Disable the Component");
              },
            };

            Blockly.JavaScript[
              `disable_component-${childParentAttribute}-${child.id}`
            ] = function (block) {
              var code = `
                          
              selectChild${child.id}.disabled = true;
                            
                        `;

              return code;
            };

            categoryXml += `<block type="selected-options-${childParentAttribute}-${child.id}"></block>`;
            Blockly.Blocks[
              `selected-options-${childParentAttribute}-${child.id}`
            ] = {
              init: function () {
                this.appendDummyInput().appendField(
                  `selected-options-${childParentAttribute}-${child.id}`
                );
                this.appendValueInput("RESPONSE_VARIABLE")
                  .setCheck("Variable")
                  .appendField("Store Response Data in Variable:");
                this.setPreviousStatement(true, null);
                this.setNextStatement(true, null);
                this.setColour(160);
                this.setTooltip(
                  `Grab Selected Options From  ${childParentAttribute}-${child.id}`
                );
              },
            };

            Blockly.JavaScript[
              `selected-options-${childParentAttribute}-${child.id}`
            ] = function (block) {
              const responseVariable = Blockly.JavaScript.valueToCode(
                block,
                "RESPONSE_VARIABLE",
                Blockly.JavaScript.ORDER_ASSIGNMENT
              );
              var code = `
              const selectedOptions = selectChild${child.id}.selectedOptions;
              
              const uniqueValuesSet = new Set();
    
              for (const option of selectedOptions) {
                  uniqueValuesSet.add(option.value);
              }
    
              ${responseVariable} = Array.from(uniqueValuesSet);
            `;
              return code;
            };

            categoryXml += `<block type="selected-options-with-id-${childParentAttribute}-${child.id}"></block>`;
            Blockly.Blocks[
              `selected-options-with-id-${childParentAttribute}-${child.id}`
            ] = {
              init: function () {
                this.appendDummyInput().appendField(
                  `selected-options-with-id-${childParentAttribute}-${child.id}`
                );
                this.appendValueInput("RESPONSE_VARIABLE")
                  .setCheck("Variable")
                  .appendField("Store Response Data in Variable:");
                this.setPreviousStatement(true, null);
                this.setNextStatement(true, null);
                this.setColour(160);
                this.setTooltip(
                  `Grab Selected Options From  ${childParentAttribute}-${child.id}`
                );
              },
            };

            Blockly.JavaScript[
              `selected-options-with-id-${childParentAttribute}-${child.id}`
            ] = function (block) {
              const responseVariable = Blockly.JavaScript.valueToCode(
                block,
                "RESPONSE_VARIABLE",
                Blockly.JavaScript.ORDER_ASSIGNMENT
              );
              var code = `
            const selectedOptions = selectChild${child.id}.selectedOptions;
            
            const uniqueValuesSet = new Set();
        
            for (const option of selectedOptions) {
                uniqueValuesSet.add({ value: option.value, _id: option.getAttribute('data_db_id') });
            }
        
            ${responseVariable} = Array.from(uniqueValuesSet);
          `;
              return code;
            };

            categoryXml += `<block type="component_instance-for-db-data-${childParentAttribute}-${child.id}"></block>`;

            Blockly.Blocks[
              `component_instance-for-db-data-${childParentAttribute}-${child.id}`
            ] = {
              init: function () {
                this.appendDummyInput().appendField(
                  `component_instance-for-db-data-${childParentAttribute}-${child.id}`
                );
                this.appendStatementInput("Properties");
                this.setPreviousStatement(true, null);
                this.setNextStatement(true, null);
                this.setColour(160);
                this.setTooltip("Get Instance of the Component");
              },
            };

            Blockly.JavaScript[
              `component_instance-for-db-data-${childParentAttribute}-${child.id}`
            ] = function (block) {
              const properties = Blockly.JavaScript.statementToCode(
                block,
                "Properties"
              );

              var code = `
                    setTimeout(function() {
                                      var childElement${child.id} = document.querySelector("#${child.id}");
                                      if(childElement${child.id}){
                                        let selectChild${child.id}=childElement${child.id}.querySelector('select');

                                        if(selectChild${child.id}){
                                          ${properties}
                                        }
                                      }
                                    },2000)
                                      `;

              return code;
            };

            categoryXml += `<block type="grab-options-from-database-${childParentAttribute}-${child.id}"></block>`;
            Blockly.Blocks[
              `grab-options-from-database-${childParentAttribute}-${child.id}`
            ] = {
              init: function () {
                this.appendDummyInput().appendField(
                  `grab-options-from-database-${childParentAttribute}-${child.id}`
                );
                this.appendValueInput("OPTION_LIST")
                  .setCheck("Array")
                  .appendField("Set Array Of Selected Options:");
                this.setPreviousStatement(true, null);
                this.setNextStatement(true, null);
                this.setColour(160);
                this.setTooltip(`Grab Selected Options from database`);
              },
            };

            Blockly.JavaScript[
              `grab-options-from-database-${childParentAttribute}-${child.id}`
            ] = function (block) {
              const optionList = Blockly.JavaScript.valueToCode(
                block,
                "OPTION_LIST",
                Blockly.JavaScript.ORDER_ASSIGNMENT
              );

              var code = `
              const selectedOptions = selectChild${child.id}.options;
              const optionValues = ${optionList};
            
              for (const option of selectedOptions) {
                if (optionValues.includes(option.getAttribute('data_db_id'))) {
                  option.selected = true;
                }
              }
            `;

              return code;
            };

            categoryXml += `<block type="clear-value-${childParentAttribute}-${child.id}"></block>`;
            Blockly.Blocks[`clear-value-${childParentAttribute}-${child.id}`] =
              {
                init: function () {
                  this.appendDummyInput().appendField(
                    `Clear Selection-${childParentAttribute}-${child.id}`
                  );
                  this.setPreviousStatement(true, null);
                  this.setNextStatement(true, null);
                  this.setColour(160);
                  this.setTooltip(`Grab Selected Options from database`);
                },
              };

            Blockly.JavaScript[
              `clear-value-${childParentAttribute}-${child.id}`
            ] = function (block) {
              var code = `
              var childElement${child.id} = document.querySelector("#${child.id}");
              if(childElement${child.id}){
                const selectedOptions = childElement${child.id}.querySelectorAll('select');
                for (const option of selectedOptions) {
                  option.value = '';
                }
              }
              
             
            `;

              return code;
            };
          }

          if (childParentAttribute === "customCardWrapperFH_new") {
            categoryXml += `<block type="${childParentAttribute}-${child.id}-api_trait"></block>`;

            Blockly.Blocks[`${childParentAttribute}-${child.id}-api_trait`] = {
              init: function () {
                this.appendDummyInput().appendField(
                  `Customise Traits of ${childParentAttribute}-${child.id}`
                );
                this.appendValueInput("PROPERTY_VALUE").appendField("Value:");
                this.setPreviousStatement(true, null);
                this.setNextStatement(true, null);
                this.setColour(180);
                this.setTooltip("Manipulate Traits of a select comp");
              },
            };

            Blockly.JavaScript[
              `${childParentAttribute}-${child.id}-api_trait`
            ] = function (block) {
              const propertyValue = Blockly.JavaScript.valueToCode(
                block,
                "PROPERTY_VALUE",
                Blockly.JavaScript.ORDER_ATOMIC
              );

              let code = `
              let subString${child.id}= window.location.href.indexOf("/editor/") !== -1 &&
              window.location.href.indexOf("?projectId=") !== -1 ||
            window.location.href.indexOf("/large_preview/") !== -1 ||
            window.location.href.indexOf("/tab_preview/") !== -1 ||
            window.location.href.indexOf("/mobile_preview/") !== -1 ||
            window.location.href.indexOf("/fragment_editor/") !== -1;
              if(subString${child.id}){
                let allComponents${child.id} = window.top.editor.getWrapper();
              let childCompEditor${child.id}=allComponents${child.id}.find('#${child.id}')[0];
              if(childCompEditor${child.id}){
                childCompEditor${child.id}.set( ${propertyValue});
              }
              }else{
                if(typeof ${propertyValue} === 'object'){
                  Object.keys(${propertyValue}).forEach(function(key) {
                    window[key + "${child.id}"] = ${propertyValue}[key];
                });
                }
              }
            
              `;
              return code;
            };
          }

          if (childParentAttribute === "email-template-container") {
            categoryXml += `<block type="${childParentAttribute}-${child.id}-api_trait"></block>`;

            Blockly.Blocks[`${childParentAttribute}-${child.id}-api_trait`] = {
              init: function () {
                this.appendDummyInput().appendField(
                  `Customise Traits of ${childParentAttribute}-${child.id}`
                );
                this.appendValueInput("PROPERTY_VALUE").appendField("Value:");
                this.setPreviousStatement(true, null);
                this.setNextStatement(true, null);
                this.setColour(180);
                this.setTooltip("Manipulate Traits ");
              },
            };

            Blockly.JavaScript[
              `${childParentAttribute}-${child.id}-api_trait`
            ] = function (block) {
              const propertyValue = Blockly.JavaScript.valueToCode(
                block,
                "PROPERTY_VALUE",
                Blockly.JavaScript.ORDER_ATOMIC
              );

              let code = `
              let subString${child.id}= window.location.href.indexOf("/editor/") !== -1 &&
              window.location.href.indexOf("?projectId=") !== -1 ||
            window.location.href.indexOf("/large_preview/") !== -1 ||
            window.location.href.indexOf("/tab_preview/") !== -1 ||
            window.location.href.indexOf("/mobile_preview/") !== -1 ||
            window.location.href.indexOf("/fragment_editor/") !== -1;
              if(subString${child.id}){
                let allComponents${child.id} = window.top.editor.getWrapper();
              let childCompEditor${child.id}=allComponents${child.id}.find('#${child.id}')[0];
              if(childCompEditor${child.id}){
                childCompEditor${child.id}.set( ${propertyValue}); ;
                let el${child.id}=childCompEditor${child.id}.getEl();
                let emailBtn${child.id}=el${child.id}.getElementsByClassName('email-template-button')[0];
                if(emailBtn${child.id}){
                  emailBtn${child.id}.click()
                }
              }
              }else{
                if(typeof ${propertyValue} === 'object'){
                  Object.keys(${propertyValue}).forEach(function(key) {
                    window[key + "${child.id}"] = ${propertyValue}[key];
                });
                }
              }
            
              `;
              return code;
            };
          }

          if (childParentAttribute === "rich_text_area_container") {
            categoryXml += `<block type="${childParentAttribute}-${child.id}-api_trait"></block>`;

            Blockly.Blocks[`${childParentAttribute}-${child.id}-api_trait`] = {
              init: function () {
                this.appendDummyInput().appendField(
                  `Customise Traits of ${childParentAttribute}-${child.id}`
                );
                this.appendValueInput("PROPERTY_VALUE").appendField("Value:");
                this.setPreviousStatement(true, null);
                this.setNextStatement(true, null);
                this.setColour(180);
                this.setTooltip("Manipulate Traits of a select comp");
              },
            };

            Blockly.JavaScript[
              `${childParentAttribute}-${child.id}-api_trait`
            ] = function (block) {
              const propertyValue = Blockly.JavaScript.valueToCode(
                block,
                "PROPERTY_VALUE",
                Blockly.JavaScript.ORDER_ATOMIC
              );

              let code = `
              let subString${child.id}= window.location.href.indexOf("/editor/") !== -1 &&
              window.location.href.indexOf("?projectId=") !== -1 ||
            window.location.href.indexOf("/large_preview/") !== -1 ||
            window.location.href.indexOf("/tab_preview/") !== -1 ||
            window.location.href.indexOf("/mobile_preview/") !== -1 ||
            window.location.href.indexOf("/fragment_editor/") !== -1;
              if(subString${child.id}){
                let allComponents${child.id} = window.top.editor.getWrapper();
                let childCompEditor${child.id}=allComponents${child.id}.find('#${child.id}')[0];
                if(childCompEditor${child.id}){
                  childCompEditor${child.id}.set(${propertyValue});
  
                  childCompEditor${child.id}.attributes.script.call(
                    childCompEditor${child.id}.getEl(),
                    childCompEditor${child.id}.props()
                  );
                }
              }else{
                if(typeof ${propertyValue} === 'object'){
                  Object.keys(${propertyValue}).forEach(function(key) {
                    window[key + "${child.id}"] = ${propertyValue}[key];
                });
                }
              }
            
              `;
              return code;
            };
          }

          if (childParentAttribute === "autocomplete_custom") {
            //   component instance
            categoryXml += `<block type="component_instance-${childParentAttribute}-${child.id}"></block>`;

            Blockly.Blocks[
              `component_instance-${childParentAttribute}-${child.id}`
            ] = {
              init: function () {
                this.appendDummyInput().appendField(
                  `component_instance-${childParentAttribute}-${child.id}`
                );
                this.appendStatementInput("Properties");
                this.setPreviousStatement(true, null);
                this.setNextStatement(true, null);
                this.setColour(83);
                this.setTooltip("Get Instance of the Component");
              },
            };

            Blockly.JavaScript[
              `component_instance-${childParentAttribute}-${child.id}`
            ] = function (block) {
              const properties = Blockly.JavaScript.statementToCode(
                block,
                "Properties"
              );

              var code = `
                                      var childElement${child.id} = document.querySelector("#${child.id}");
                                      if(childElement${child.id}){
                                        let autoChild${child.id}=childElement${child.id}.querySelector('input');

                                        if(autoChild${child.id}){
                                          ${properties}
                                        }
                                         
                                      }
                                      `;

              return code;
            };

            categoryXml += `<block type="get_selected_value-${childParentAttribute}-${child.id}"></block>`;

            //   for style manipulation:
            //   bbql
            Blockly.Blocks[
              `get_selected_value-${childParentAttribute}-${child.id}`
            ] = {
              init: function () {
                this.appendDummyInput().appendField(
                  `get_selected_value-${childParentAttribute}-${child.id}`
                );
                this.setOutput(true, null);
                this.setColour(160);
                this.setTooltip("Get selected value from Autocomplete !!!");
              },
            };

            Blockly.JavaScript[
              `get_selected_value-${childParentAttribute}-${child.id}`
            ] = function (block) {
              var code = `
              autoChild${child.id}.getAttribute('data_value')
                                      `;

              return [code, Blockly.JavaScript.ORDER_ATOMIC];
            };

            categoryXml += `<block type="get_selected_label-${childParentAttribute}-${child.id}"></block>`;

            //   for style manipulation:
            //   bbql
            Blockly.Blocks[
              `get_selected_label-${childParentAttribute}-${child.id}`
            ] = {
              init: function () {
                this.appendDummyInput().appendField(
                  `get_selected_label-${childParentAttribute}-${child.id}`
                );
                this.setOutput(true, null);
                this.setColour(160);
                this.setTooltip("Get selected label from Autocomplete !!!");
              },
            };

            Blockly.JavaScript[
              `get_selected_label-${childParentAttribute}-${child.id}`
            ] = function (block) {
              var code = `
              autoChild${child.id}.getAttribute('keyname')
                                      `;

              return [code, Blockly.JavaScript.ORDER_ATOMIC];
            };
          }

          if (childParentAttribute === "radio_regular_form") {
            categoryXml += `<block type="${childParentAttribute}-${child.id}-api_trait"></block>`;

            Blockly.Blocks[`${childParentAttribute}-${child.id}-api_trait`] = {
              init: function () {
                this.appendDummyInput().appendField(
                  `Customise Traits of ${childParentAttribute}-${child.id}`
                );
                this.appendValueInput("PROPERTY_VALUE").appendField("Value:");
                this.setPreviousStatement(true, null);
                this.setNextStatement(true, null);
                this.setColour(180);
                this.setTooltip("Manipulate Traits of a select comp");
              },
            };

            Blockly.JavaScript[
              `${childParentAttribute}-${child.id}-api_trait`
            ] = function (block) {
              const propertyValue = Blockly.JavaScript.valueToCode(
                block,
                "PROPERTY_VALUE",
                Blockly.JavaScript.ORDER_ATOMIC
              );

              let code = `
              let subString${child.id}= window.location.href.indexOf("/editor/") !== -1 &&
              window.location.href.indexOf("?projectId=") !== -1 ||
            window.location.href.indexOf("/large_preview/") !== -1 ||
            window.location.href.indexOf("/tab_preview/") !== -1 ||
            window.location.href.indexOf("/mobile_preview/") !== -1 ||
            window.location.href.indexOf("/fragment_editor/") !== -1;
              if(subString${child.id}){
                let allComponents${child.id} = window.top.editor.getWrapper();
                let childCompEditor${child.id}=allComponents${child.id}.find('#${child.id}')[0];
                if(childCompEditor${child.id}){
                  childCompEditor${child.id}.set( ${propertyValue});
                }
              }else{
                if(typeof ${propertyValue} === 'object'){
                  Object.keys(${propertyValue}).forEach(function(key) {
                    window[key + "${child.id}"] = ${propertyValue}[key];
                });
                }
              }
            
              `;
              return code;
            };

            categoryXml += `<block type="${childParentAttribute}-${child.id}-label" colour="210"></block>`;
            Blockly.Blocks[`${childParentAttribute}-${child.id}-label`] = {
              init: function () {
                this.appendDummyInput().appendField(
                  `get selected value from ${childParentAttribute}-${child.id}-label`
                );
                this.setOutput(true, "String");
                this.setColour(83);
                this.setTooltip(
                  `Get the value of the ${childParentAttribute}-${child.id} button label`
                );
                this.setHelpUrl("");
              },
            };

            Blockly.JavaScript[`${childParentAttribute}-${child.id}-label`] =
              function (block) {
                var code = `document.getElementById("${child.id}").getAttribute('value')`;

                return [code, Blockly.JavaScript.ORDER_ATOMIC];
              };

            categoryXml += `<block type="${childParentAttribute}-${child.id}-value" colour="210"></block>`;

            Blockly.Blocks[`${childParentAttribute}-${child.id}-value`] = {
              init: function () {
                this.appendDummyInput().appendField(
                  `get selected value from ${childParentAttribute}-${child.id}-value`
                );
                this.setOutput(true, "String");
                this.setColour(160);
                this.setTooltip(
                  `Get the value of the ${childParentAttribute}-${child.id} button value`
                );
                this.setHelpUrl("");
              },
            };

            Blockly.JavaScript[`${childParentAttribute}-${child.id}-value`] =
              function (block) {
                var code = `document.getElementById("${child.id}").getAttribute('data_db_id')`;

                return [code, Blockly.JavaScript.ORDER_ATOMIC];
              };

            categoryXml += `<block type="component_instance-for-db-data-${child.tagName}-${child.id}"></block>`;

            Blockly.Blocks[
              `component_instance-for-db-data-${child.tagName}-${child.id}`
            ] = {
              init: function () {
                this.appendDummyInput().appendField(
                  `component_instance-for-db-data-${child.tagName}-${child.id}`
                );
                this.appendStatementInput("Properties");
                this.setPreviousStatement(true, null);
                this.setNextStatement(true, null);
                this.setColour(160);
                this.setTooltip("Get Instance of the Component");
              },
            };

            Blockly.JavaScript[
              `component_instance-for-db-data-${child.tagName}-${child.id}`
            ] = function (block) {
              const properties = Blockly.JavaScript.statementToCode(
                block,
                "Properties"
              );

              var code = `
                        setTimeout(function() {
                                          var childElement${child.id} = document.querySelector("#${child.id}");
                                          if(childElement${child.id}){
                                              ${properties}
                                          }
                                        },2000)
                                          `;

              return code;
            };

            categoryXml += `<block type="grab-option-from-database-${child.tagName}-${child.id}"></block>`;
            Blockly.Blocks[
              `grab-option-from-database-${child.tagName}-${child.id}`
            ] = {
              init: function () {
                this.appendDummyInput().appendField(
                  `grab-option-from-database-${child.tagName}-${child.id}`
                );
                this.appendValueInput("OPTION_LIST")
                  .setCheck("String")
                  .appendField("Set Selected Option:");
                this.setPreviousStatement(true, null);
                this.setNextStatement(true, null);
                this.setColour(160);
                this.setTooltip(`Grab Selected Option from database`);
              },
            };

            Blockly.JavaScript[
              `grab-option-from-database-${child.tagName}-${child.id}`
            ] = function (block) {
              const optionList = Blockly.JavaScript.valueToCode(
                block,
                "OPTION_LIST",
                Blockly.JavaScript.ORDER_ASSIGNMENT
              );

              var code = `
                  const selectedOptions = childElement${child.id}.querySelectorAll('input');
                  const optionValues = ${optionList};

                  let valueCheck='';
                
                  for (const option of selectedOptions) {
                    if (option.getAttribute('data_db_id')===optionValues) {
                      option.checked = true;
                      valueCheck=option.value
                    }
                  }

                  childElement${child.id}.setAttribute("value", valueCheck);
                  childElement${child.id}.setAttribute("data_db_id", optionValues);
                `;

              return code;
            };

            categoryXml += `<block type="grab-value-onchange-${child.tagName}-${child.id}"></block>`;
            Blockly.Blocks[`grab-value-onchange-${child.tagName}-${child.id}`] =
              {
                init: function () {
                  this.appendDummyInput().appendField(
                    `grab-value-onchange-${child.tagName}-${child.id}`
                  );
                  this.appendValueInput("VALUE_RADIO")
                    .setCheck(null)
                    .appendField("Variable to store value:");
                  this.appendValueInput("DB_ID")
                    .setCheck(null)
                    .appendField("Variable to store db Id:");
                  // this.appendValueInput("TARGET")
                  //   .setCheck(null)
                  //   .appendField("Variable to store current target:");
                  this.appendStatementInput("LOGIC_EVENT")
                    .setCheck(null)
                    .appendField("Logic to be triggered on change :");
                  this.setPreviousStatement(true, null);
                  this.setNextStatement(true, null);
                  this.setColour(160);
                  this.setTooltip(`Grab Selected Option from database`);
                },
              };

            Blockly.JavaScript[
              `grab-value-onchange-${child.tagName}-${child.id}`
            ] = function (block) {
              const radioValue = Blockly.JavaScript.valueToCode(
                block,
                "VALUE_RADIO",
                Blockly.JavaScript.ORDER_ASSIGNMENT
              );

              const radioId = Blockly.JavaScript.valueToCode(
                block,
                "DB_ID",
                Blockly.JavaScript.ORDER_ASSIGNMENT
              );

              // const targetBlock = Blockly.JavaScript.valueToCode(
              //   block,
              //   "TARGET",
              //   Blockly.JavaScript.ORDER_ASSIGNMENT
              // );

              var logicPart = Blockly.JavaScript.statementToCode(
                block,
                "LOGIC_EVENT"
              );

              var code;

              if (radioValue && radioId) {
                code = `
                const selectedOptions = childElement${child.id}.querySelectorAll('input');
              
                for (const option of selectedOptions) {
                  option.addEventListener('change',(event)=>{
                    if(event.target.checked===true){
${radioValue}=event.target.value;
${radioId}=event.target.getAttribute('data_db_id')

${logicPart}

                    }
                  })
                }

              `;
              } else if (radioValue && !radioId) {
                code = `
                const selectedOptions = childElement${child.id}.querySelectorAll('input');
              
                for (const option of selectedOptions) {
                  option.addEventListener('change',(event)=>{
                    if(event.target.checked===true){
${radioValue}=event.target.value;

${logicPart}

                    }
                  })
                }

              `;
              } else {
                code = ``;
              }

              return code;
            };

            categoryXml += `<block type="clear-value-${child.tagName}-${child.id}"></block>`;

            Blockly.Blocks[`clear-value-${child.tagName}-${child.id}`] = {
              init: function () {
                this.appendDummyInput().appendField(
                  `Clear Selection of ${child.tagName}-${child.id}`
                );
                this.setPreviousStatement(true, null);
                this.setNextStatement(true, null);
                this.setColour(160);
                this.setTooltip("Clear Selection of the Component");
              },
            };

            Blockly.JavaScript[`clear-value-${child.tagName}-${child.id}`] =
              function (block) {
                var code = `
                var childElement${child.id} = document.querySelector("#${child.id}");
                if(childElement${child.id}){
                  const selectedOptions = childElement${child.id}.querySelectorAll('input');
                  for (const option of selectedOptions) {
                    option.checked=false;
                  }
                }
                              `;

                return code;
              };
          }

          if (childParentAttribute === "checkbox_regular_form") {
            categoryXml += `<block type="${childParentAttribute}-${child.id}-api_trait"></block>`;

            Blockly.Blocks[`${childParentAttribute}-${child.id}-api_trait`] = {
              init: function () {
                this.appendDummyInput().appendField(
                  `Customise Traits of ${childParentAttribute}-${child.id}`
                );
                this.appendValueInput("PROPERTY_VALUE").appendField("Value:");
                this.setPreviousStatement(true, null);
                this.setNextStatement(true, null);
                this.setColour(180);
                this.setTooltip("Manipulate Traits of a select comp");
              },
            };

            Blockly.JavaScript[
              `${childParentAttribute}-${child.id}-api_trait`
            ] = function (block) {
              const propertyValue = Blockly.JavaScript.valueToCode(
                block,
                "PROPERTY_VALUE",
                Blockly.JavaScript.ORDER_ATOMIC
              );

              let code = `
              let subString${child.id}= window.location.href.indexOf("/editor/") !== -1 &&
              window.location.href.indexOf("?projectId=") !== -1 ||
            window.location.href.indexOf("/large_preview/") !== -1 ||
            window.location.href.indexOf("/tab_preview/") !== -1 ||
            window.location.href.indexOf("/mobile_preview/") !== -1 ||
            window.location.href.indexOf("/fragment_editor/") !== -1;
              if(subString${child.id}){
                let allComponents${child.id} = window.top.editor.getWrapper();
                let childCompEditor${child.id}=allComponents${child.id}.find('#${child.id}')[0];
                if(childCompEditor${child.id}){
                  childCompEditor${child.id}.set( ${propertyValue});
                }
              }else{
                if(typeof ${propertyValue} === 'object'){
                  Object.keys(${propertyValue}).forEach(function(key) {
                    window[key + "${child.id}"] = ${propertyValue}[key];
                });
                }
              }
            
              `;
              return code;
            };

            categoryXml += `<block type="${childParentAttribute}-${child.id}-label" colour="210"></block>`;
            Blockly.Blocks[`${childParentAttribute}-${child.id}-label`] = {
              init: function () {
                this.appendDummyInput().appendField(
                  `get selected value from ${childParentAttribute}-${child.id}-label`
                );
                this.setOutput(true, "Array");
                this.setColour(83);
                this.setTooltip(
                  `Get the value of the ${childParentAttribute}-${child.id} button label`
                );
                this.setHelpUrl("");
              },
            };

            Blockly.JavaScript[`${childParentAttribute}-${child.id}-label`] =
              function (block) {
                var code = `document.getElementById("${child.id}").getAttribute('value')`;
                let newCode;
                if (code) {
                  newCode = `${code}.split(',')`;
                } else {
                  newCode = code;
                }

                console.log("newCode::::11111", newCode);

                return [newCode, Blockly.JavaScript.ORDER_ATOMIC];
              };

            categoryXml += `<block type="${childParentAttribute}-${child.id}-labelV2" colour="210"></block>`;
            Blockly.Blocks[`${childParentAttribute}-${child.id}-labelV2`] = {
              init: function () {
                this.appendDummyInput().appendField(
                  `get selected value from ${childParentAttribute}-${child.id}-labelV2`
                );
                this.appendValueInput("VALUE_VAR_CHECKBOX")
                  .setCheck("Variable")
                  .appendField("Store Selected Checkbox  In Variable:");
                this.setPreviousStatement(true, null);
                this.setNextStatement(true, null);
                this.setColour(83);
                this.setTooltip(
                  `Get the value of the ${childParentAttribute}-${child.id} button labelV2`
                );
                this.setHelpUrl("");
              },
            };

            Blockly.JavaScript[`${childParentAttribute}-${child.id}-labelV2`] =
              function (block) {
                var checkbox_var = Blockly.JavaScript.valueToCode(
                  block,
                  "VALUE_VAR_CHECKBOX",
                  Blockly.JavaScript.ORDER_ASSIGNMENT
                );

                var code = `
                let selectedCheckbox${child.id}label=document.getElementById("${child.id}").getAttribute('value');
                if(selectedCheckbox${child.id}label){
${checkbox_var}=selectedCheckbox${child.id}label.split(',')
                }else{
                  ${checkbox_var}=[]
                }
                `;

                return code;
              };

            categoryXml += `<block type="${childParentAttribute}-${child.id}-value" colour="210"></block>`;

            Blockly.Blocks[`${childParentAttribute}-${child.id}-value`] = {
              init: function () {
                this.appendDummyInput().appendField(
                  `get selected value from ${childParentAttribute}-${child.id}-value`
                );
                this.setOutput(true, "Array");
                this.setColour(230);
                this.setTooltip(
                  `Get the value of the ${childParentAttribute}-${child.id} button value`
                );
                this.setHelpUrl("");
              },
            };

            Blockly.JavaScript[`${childParentAttribute}-${child.id}-value`] =
              function (block) {
                var code = `document.getElementById("${child.id}").getAttribute('data_db_id')`;
                let newCode;
                if (code) {
                  newCode = `${code}.split(',')`;
                } else {
                  newCode = code;
                }

                console.log("newCode::::", newCode);

                return [newCode, Blockly.JavaScript.ORDER_ATOMIC];
              };

            categoryXml += `<block type="${childParentAttribute}-${child.id}-valueV2" colour="210"></block>`;

            Blockly.Blocks[`${childParentAttribute}-${child.id}-valueV2`] = {
              init: function () {
                this.appendDummyInput().appendField(
                  `get selected value from ${childParentAttribute}-${child.id}-valueV2`
                );
                this.appendValueInput("LABEL_VAR_CHECKBOX")
                  .setCheck("Variable")
                  .appendField("Store Selected Checkbox  In Variable:");
                this.setPreviousStatement(true, null);
                this.setNextStatement(true, null);
                this.setColour(230);
                this.setTooltip(
                  `Get the value of the ${childParentAttribute}-${child.id} checkbox valueV2`
                );
                this.setHelpUrl("");
              },
            };

            Blockly.JavaScript[`${childParentAttribute}-${child.id}-valueV2`] =
              function (block) {
                var checkbox_var = Blockly.JavaScript.valueToCode(
                  block,
                  "LABEL_VAR_CHECKBOX",
                  Blockly.JavaScript.ORDER_ASSIGNMENT
                );

                var code = `
                  let selectedCheckbox${child.id}value=document.getElementById("${child.id}").getAttribute('data_db_id');
                  if(selectedCheckbox${child.id}value){
  ${checkbox_var}=selectedCheckbox${child.id}value.split(',')
                  }else{
                    ${checkbox_var}=[]
                  }
                  `;

                return code;
              };

            categoryXml += `<block type="component_instance-for-db-data-${child.tagName}-${child.id}"></block>`;

            Blockly.Blocks[
              `component_instance-for-db-data-${child.tagName}-${child.id}`
            ] = {
              init: function () {
                this.appendDummyInput().appendField(
                  `component_instance-for-db-data-${child.tagName}-${child.id}`
                );
                this.appendStatementInput("Properties");
                this.setPreviousStatement(true, null);
                this.setNextStatement(true, null);
                this.setColour(230);
                this.setTooltip("Get Instance of the Component");
              },
            };

            Blockly.JavaScript[
              `component_instance-for-db-data-${child.tagName}-${child.id}`
            ] = function (block) {
              const properties = Blockly.JavaScript.statementToCode(
                block,
                "Properties"
              );

              var code = `
                          setTimeout(function() {
                                            var childElement${child.id} = document.querySelector("#${child.id}");
                                            if(childElement${child.id}){
                                                ${properties}
                                            }
                                          },2000)
                                            `;

              return code;
            };

            categoryXml += `<block type="grab-option-from-database-${child.tagName}-${child.id}"></block>`;
            Blockly.Blocks[
              `grab-option-from-database-${child.tagName}-${child.id}`
            ] = {
              init: function () {
                this.appendDummyInput().appendField(
                  `grab-option-from-database-${child.tagName}-${child.id}`
                );
                this.appendValueInput("OPTION_LIST")
                  .setCheck("Array")
                  .appendField("Set Array of Selected Options:");
                this.setPreviousStatement(true, null);
                this.setNextStatement(true, null);
                this.setColour(230);
                this.setTooltip(`Grab Selected Option from database`);
              },
            };

            Blockly.JavaScript[
              `grab-option-from-database-${child.tagName}-${child.id}`
            ] = function (block) {
              const optionList = Blockly.JavaScript.valueToCode(
                block,
                "OPTION_LIST",
                Blockly.JavaScript.ORDER_ASSIGNMENT
              );

              var code = `
                    const selectedOptions = childElement${child.id}.querySelectorAll('input');
                    const optionValues = ${optionList};
                    const valueList=[];
                  
                    for (const option of selectedOptions) {
                      if (optionValues.includes(option.getAttribute('data_db_id'))) {
                        option.checked = true;
                        valueList.push(option.value)
                      }
                    }
                    
                    if(valueList.length>0){
                      childElement${child.id}.setAttribute("value", valueList.join(","));
                      childElement${child.id}.setAttribute("data_db_id", optionValues.join(","));
                    }
                  `;

              return code;
            };

            categoryXml += `<block type="clear-value-${child.tagName}-${child.id}"></block>`;

            Blockly.Blocks[`clear-value-${child.tagName}-${child.id}`] = {
              init: function () {
                this.appendDummyInput().appendField(
                  `Clear Selection of ${child.tagName}-${child.id}`
                );
                this.setPreviousStatement(true, null);
                this.setNextStatement(true, null);
                this.setColour(160);
                this.setTooltip("Clear Selection of the Component");
              },
            };

            Blockly.JavaScript[`clear-value-${child.tagName}-${child.id}`] =
              function (block) {
                var code = `
                var childElement${child.id} = document.querySelector("#${child.id}");
                if(childElement${child.id}){
                  const selectedOptions = childElement${child.id}.querySelectorAll('input');
                  for (const option of selectedOptions) {
                    option.checked=false;
                  }

                childElement${child.id}.removeAttribute('value');
                 childElement${child.id}.removeAttribute('data_db_id');

                }
                    
                                `;

                return code;
              };
          }

          if (childParentAttribute === "checkbox_regular_form_new") {
            categoryXml += `<block type="${childParentAttribute}-${child.id}-api_trait"></block>`;

            Blockly.Blocks[`${childParentAttribute}-${child.id}-api_trait`] = {
              init: function () {
                this.appendDummyInput().appendField(
                  `Customise Traits of ${childParentAttribute}-${child.id}`
                );
                this.appendValueInput("PROPERTY_VALUE").appendField("Value:");
                this.setPreviousStatement(true, null);
                this.setNextStatement(true, null);
                this.setColour(180);
                this.setTooltip("Manipulate Traits of a select comp");
              },
            };

            Blockly.JavaScript[
              `${childParentAttribute}-${child.id}-api_trait`
            ] = function (block) {
              const propertyValue = Blockly.JavaScript.valueToCode(
                block,
                "PROPERTY_VALUE",
                Blockly.JavaScript.ORDER_ATOMIC
              );

              let code = `
              let subString${child.id}= window.location.href.indexOf("/editor/") !== -1 &&
              window.location.href.indexOf("?projectId=") !== -1 ||
            window.location.href.indexOf("/large_preview/") !== -1 ||
            window.location.href.indexOf("/tab_preview/") !== -1 ||
            window.location.href.indexOf("/mobile_preview/") !== -1 ||
            window.location.href.indexOf("/fragment_editor/") !== -1;
              if(subString${child.id}){
                let allComponents${child.id} = window.top.editor.getWrapper();
                let childCompEditor${child.id}=allComponents${child.id}.find('#${child.id}')[0];
                if(childCompEditor${child.id}){
                  childCompEditor${child.id}.set( ${propertyValue});
                }
              }else{
                if(typeof ${propertyValue} === 'object'){
                  Object.keys(${propertyValue}).forEach(function(key) {
                    window[key + "${child.id}"] = ${propertyValue}[key];
                });
                }
              }
            
              `;
              return code;
            };

            categoryXml += `<block type="${childParentAttribute}-${child.id}-label" colour="210"></block>`;
            Blockly.Blocks[`${childParentAttribute}-${child.id}-label`] = {
              init: function () {
                this.appendDummyInput().appendField(
                  `get selected value from ${childParentAttribute}-${child.id}-labelV2`
                );
                this.appendValueInput("VALUE_VAR_CHECKBOX")
                  .setCheck("Variable")
                  .appendField("Store Selected Checkbox  In Variable:");
                this.setPreviousStatement(true, null);
                this.setNextStatement(true, null);
                this.setColour(83);
                this.setTooltip(
                  `Get the value of the ${childParentAttribute}-${child.id} button labelV2`
                );
                this.setHelpUrl("");
              },
            };

            Blockly.JavaScript[`${childParentAttribute}-${child.id}-label`] =
              function (block) {
                var checkbox_var = Blockly.JavaScript.valueToCode(
                  block,
                  "VALUE_VAR_CHECKBOX",
                  Blockly.JavaScript.ORDER_ASSIGNMENT
                );

                var code = `
                let selectedCheckbox${child.id}label=document.getElementById("${child.id}").getAttribute('value');
                if(selectedCheckbox${child.id}label){
${checkbox_var}=selectedCheckbox${child.id}label.split('‰')
                }else{
                  ${checkbox_var}=[]
                }
                `;

                return code;
              };

            categoryXml += `<block type="${childParentAttribute}-${child.id}-value" colour="210"></block>`;

            Blockly.Blocks[`${childParentAttribute}-${child.id}-value`] = {
              init: function () {
                this.appendDummyInput().appendField(
                  `get selected value from ${childParentAttribute}-${child.id}-valueV2`
                );
                this.appendValueInput("LABEL_VAR_CHECKBOX")
                  .setCheck("Variable")
                  .appendField("Store Selected Checkbox  In Variable:");
                this.setPreviousStatement(true, null);
                this.setNextStatement(true, null);
                this.setColour(230);
                this.setTooltip(
                  `Get the value of the ${childParentAttribute}-${child.id} checkbox valueV2`
                );
                this.setHelpUrl("");
              },
            };

            Blockly.JavaScript[`${childParentAttribute}-${child.id}-value`] =
              function (block) {
                var checkbox_var = Blockly.JavaScript.valueToCode(
                  block,
                  "LABEL_VAR_CHECKBOX",
                  Blockly.JavaScript.ORDER_ASSIGNMENT
                );

                var code = `
                  let selectedCheckbox${child.id}value=document.getElementById("${child.id}").getAttribute('data_db_id');
                  if(selectedCheckbox${child.id}value){
  ${checkbox_var}=selectedCheckbox${child.id}value.split('‰')
                  }else{
                    ${checkbox_var}=[]
                  }
                  `;

                return code;
              };

            categoryXml += `<block type="component_instance-for-db-data-${child.tagName}-${child.id}"></block>`;

            Blockly.Blocks[
              `component_instance-for-db-data-${child.tagName}-${child.id}`
            ] = {
              init: function () {
                this.appendDummyInput().appendField(
                  `component_instance-for-db-data-${child.tagName}-${child.id}`
                );
                this.appendStatementInput("Properties");
                this.setPreviousStatement(true, null);
                this.setNextStatement(true, null);
                this.setColour(230);
                this.setTooltip("Get Instance of the Component");
              },
            };

            Blockly.JavaScript[
              `component_instance-for-db-data-${child.tagName}-${child.id}`
            ] = function (block) {
              const properties = Blockly.JavaScript.statementToCode(
                block,
                "Properties"
              );

              var code = `
                          setTimeout(function() {
                                            var childElement${child.id} = document.querySelector("#${child.id}");
                                            if(childElement${child.id}){
                                                ${properties}
                                            }
                                          },2000)
                                            `;

              return code;
            };

            categoryXml += `<block type="grab-option-from-database-${child.tagName}-${child.id}"></block>`;
            Blockly.Blocks[
              `grab-option-from-database-${child.tagName}-${child.id}`
            ] = {
              init: function () {
                this.appendDummyInput().appendField(
                  `grab-option-from-database-${child.tagName}-${child.id}`
                );
                this.appendValueInput("OPTION_LIST")
                  .setCheck("Array")
                  .appendField("Set Array of Selected Options:");
                this.setPreviousStatement(true, null);
                this.setNextStatement(true, null);
                this.setColour(230);
                this.setTooltip(`Grab Selected Option from database`);
              },
            };

            Blockly.JavaScript[
              `grab-option-from-database-${child.tagName}-${child.id}`
            ] = function (block) {
              const optionList = Blockly.JavaScript.valueToCode(
                block,
                "OPTION_LIST",
                Blockly.JavaScript.ORDER_ASSIGNMENT
              );

              var code = `
                    const selectedOptions = childElement${child.id}.querySelectorAll('input');
                    const optionValues = ${optionList};
                    const valueList=[];
                  
                    for (const option of selectedOptions) {
                      if (optionValues.includes(option.getAttribute('data_db_id'))) {
                        option.checked = true;
                        valueList.push(option.value)
                      }
                    }
                    
                    if(valueList.length>0){
                      childElement${child.id}.setAttribute("value", valueList.join("‰"));
                      childElement${child.id}.setAttribute("data_db_id", optionValues.join("‰"));
                    }
                  `;

              return code;
            };

            categoryXml += `<block type="clear-value-${child.tagName}-${child.id}"></block>`;

            Blockly.Blocks[`clear-value-${child.tagName}-${child.id}`] = {
              init: function () {
                this.appendDummyInput().appendField(
                  `Clear Selection of ${child.tagName}-${child.id}`
                );
                this.setPreviousStatement(true, null);
                this.setNextStatement(true, null);
                this.setColour(160);
                this.setTooltip("Clear Selection of the Component");
              },
            };

            Blockly.JavaScript[`clear-value-${child.tagName}-${child.id}`] =
              function (block) {
                var code = `
                var childElement${child.id} = document.querySelector("#${child.id}");
                if(childElement${child.id}){
                  const selectedOptions = childElement${child.id}.querySelectorAll('input');
                  for (const option of selectedOptions) {
                    option.checked=false;
                  }

                  childElement${child.id}.removeAttribute('value');
                 childElement${child.id}.removeAttribute('data_db_id');
                }
                    
                                `;

                return code;
              };
          }

          if (childParentAttribute === "custom-table-container") {
            categoryXml += `<block type="${childParentAttribute}-api_body_trait-${child.id}"></block>`;

            Blockly.Blocks[
              `${childParentAttribute}-api_body_trait-${child.id}`
            ] = {
              init: function () {
                this.appendDummyInput().appendField(
                  `Customise Traits of ${childParentAttribute}-${child.id}-V2`
                );
                this.appendValueInput("PROPERTY_VALUE").appendField("Value:");
                this.setPreviousStatement(true, null);
                this.setNextStatement(true, null);
                this.setColour(180);
                this.setTooltip("Manipulate Traits of a select comp");
              },
            };

            Blockly.JavaScript[
              `${childParentAttribute}-api_body_trait-${child.id}`
            ] = function (block) {
              const propertyValue = Blockly.JavaScript.valueToCode(
                block,
                "PROPERTY_VALUE",
                Blockly.JavaScript.ORDER_ATOMIC
              );
              window.top.editor = editor;

              var code = `
                let subString${child.id}= window.location.href.indexOf("/editor/") !== -1 &&
                window.location.href.indexOf("?projectId=") !== -1 ||
              window.location.href.indexOf("/large_preview/") !== -1 ||
              window.location.href.indexOf("/tab_preview/") !== -1 ||
              window.location.href.indexOf("/mobile_preview/") !== -1 ||
              window.location.href.indexOf("/fragment_editor/") !== -1;
                if(subString${child.id}){
                  let allComponents${child.id} = window.top.editor.getWrapper();
                  let childCompEditor${child.id}=allComponents${child.id}.find('#${child.id}')[0];
                  console.log('tableCompHere',childCompEditor${child.id})
                  if(childCompEditor${child.id}){
                    for (var attributeName in ${propertyValue}) {
                      if (${propertyValue}.hasOwnProperty(attributeName)) {
                        var attributeValue = ${propertyValue}[attributeName];
                        childCompEditor${child.id}.attributes[attributeName] = attributeValue;
                      }
                    }
                    childCompEditor${child.id}.attributes.script.call(
                      childCompEditor${child.id}.getEl(),
                      childCompEditor${child.id}.props()
                    );

                    childCompEditor${child.id}.Paginate()
                  }
                }else{
                  if(typeof ${propertyValue} === 'object'){
                    Object.keys(${propertyValue}).forEach(function(key) {
                      window[key + "${child.id}"] = ${propertyValue}[key];
                  });
                  }
                }
                                          `;
              return code;
            };
          }

          if (childParentAttribute === "custom-calendar-container") {
            categoryXml += `<block type="${childParentAttribute}-api_body_trait-${child.id}"></block>`;

            Blockly.Blocks[`${childParentAttribute}-api_body_trait-${child.id}` ] = {
              init: function () {
                this.appendDummyInput().appendField(
                  `Customise Traits of ${childParentAttribute}-${child.id}-V2`
                );
                this.appendValueInput("PROPERTY_VALUE").appendField("Value:");
                this.setPreviousStatement(true, null);
                this.setNextStatement(true, null);
                this.setColour(180);
                this.setTooltip("Manipulate Traits of a select comp");
              },
            };

            Blockly.JavaScript[`${childParentAttribute}-api_body_trait-${child.id}` ] = function (block) {
              const propertyValue = Blockly.JavaScript.valueToCode(
                block,
                "PROPERTY_VALUE",
                Blockly.JavaScript.ORDER_ATOMIC
              );
              window.top.editor = editor;

              var code = `
                let subString${child.id}= window.location.href.indexOf("/editor/") !== -1 &&
                window.location.href.indexOf("?projectId=") !== -1 ||
              window.location.href.indexOf("/large_preview/") !== -1 ||
              window.location.href.indexOf("/tab_preview/") !== -1 ||
              window.location.href.indexOf("/mobile_preview/") !== -1 ||
              window.location.href.indexOf("/fragment_editor/") !== -1;
                if(subString${child.id}){
                  let allComponents${child.id} = window.top.editor.getWrapper();
                  let childCompEditor${child.id}=allComponents${child.id}.find('#${child.id}')[0];
                  if(childCompEditor${child.id}){
                    // for (var attributeName in ${propertyValue}) {
                    //   if (${propertyValue}.hasOwnProperty(attributeName)) {
                    //     var attributeValue = ${propertyValue}[attributeName];
                    //     childCompEditor${child.id}.attributes[attributeName] = attributeValue;
                    //   }
                    // }

                    // Get current props
                    let currentProps = childCompEditor${child.id}.props();
                    
                    // Merge current props with new updates
                    let updatedProps = { ...currentProps, ...${propertyValue} };
                    
                    // Set the updated props back to the element
                    childCompEditor${child.id}.set(updatedProps);
                    
                    childCompEditor${child.id}.set(${propertyValue});

                    // childCompEditor${child.id}.attributes.script.call(
                    //   childCompEditor${child.id}.getEl(),
                    //   childCompEditor${child.id}.props()
                    // );
                  }
                }else{
                  if(typeof ${propertyValue} === 'object'){
                    Object.keys(${propertyValue}).forEach(function(key) {
                      window[key + "${child.id}"] = ${propertyValue}[key];
                  });
                  }
                }
                                          `;
              return code;
            };
          }

          if (childParentAttribute === "excel-downloader-container") {
            categoryXml += `<block type="${childParentAttribute}-api_body_trait-${child.id}"></block>`;

            Blockly.Blocks[
              `${childParentAttribute}-api_body_trait-${child.id}`
            ] = {
              init: function () {
                this.appendDummyInput().appendField(
                  `Customise Traits of ${childParentAttribute}-${child.id}`
                );
                this.appendValueInput("PROPERTY_VALUE").appendField("Value:");
                this.setPreviousStatement(true, null);
                this.setNextStatement(true, null);
                this.setColour(180);
                this.setTooltip("Manipulate Traits of a Excel comp");
              },
            };

            Blockly.JavaScript[
              `${childParentAttribute}-api_body_trait-${child.id}`
            ] = function (block) {
              const propertyValue = Blockly.JavaScript.valueToCode(
                block,
                "PROPERTY_VALUE",
                Blockly.JavaScript.ORDER_ATOMIC
              );
              window.top.editor = editor;

              var code = `
                let subString${child.id}= window.location.href.indexOf("/editor/") !== -1 &&
                window.location.href.indexOf("?projectId=") !== -1 ||
              window.location.href.indexOf("/large_preview/") !== -1 ||
              window.location.href.indexOf("/tab_preview/") !== -1 ||
              window.location.href.indexOf("/mobile_preview/") !== -1 ||
              window.location.href.indexOf("/fragment_editor/") !== -1;
                if(subString${child.id}){
                  let allComponents${child.id} = window.top.editor.getWrapper();
                  let childCompEditor${child.id}=allComponents${child.id}.find('#${child.id}')[0];
                  console.log('tableCompHere',childCompEditor${child.id})
                  if(childCompEditor${child.id}){
                    for (var attributeName in ${propertyValue}) {
                      if (${propertyValue}.hasOwnProperty(attributeName)) {
                        var attributeValue = ${propertyValue}[attributeName];
                        childCompEditor${child.id}.attributes[attributeName] = attributeValue;
                      }
                    }
                    childCompEditor${child.id}.attributes.script.call(
                      childCompEditor${child.id}.getEl(),
                      childCompEditor${child.id}.props()
                    );
                  }
                }else{
                  if(typeof ${propertyValue} === 'object'){
                    Object.keys(${propertyValue}).forEach(function(key) {
                      window[key + "${child.id}"] = ${propertyValue}[key];
                  });
                  }
                }
                                          `;
              return code;
            };
          }

          if (childParentAttribute === "custom-table") {
            categoryXml += `<block type="${childParentAttribute}-api_body_trait"></block>`;

            Blockly.Blocks[`${childParentAttribute}-api_body_trait`] = {
              init: function () {
                this.appendDummyInput().appendField(
                  `Customise Traits of ${childParentAttribute}-${child.id}`
                );
                this.appendValueInput("PROPERTY_VALUE").appendField("Value:");
                this.setPreviousStatement(true, null);
                this.setNextStatement(true, null);
                this.setColour(0);
                this.setTooltip("Manipulate Traits of a select comp");
              },
            };

            Blockly.JavaScript[`${childParentAttribute}-api_body_trait`] =
              function (block) {
                const propertyValue = Blockly.JavaScript.valueToCode(
                  block,
                  "PROPERTY_VALUE",
                  Blockly.JavaScript.ORDER_ATOMIC
                );
                window.top.editor = editor;

                var code = `
                let subString${child.id}= window.location.href.indexOf("/editor/") !== -1 &&
                window.location.href.indexOf("?projectId=") !== -1 ||
              window.location.href.indexOf("/large_preview/") !== -1 ||
              window.location.href.indexOf("/tab_preview/") !== -1 ||
              window.location.href.indexOf("/mobile_preview/") !== -1 ||
              window.location.href.indexOf("/fragment_editor/") !== -1;
                if(subString${child.id}){
                  let allComponents${child.id} = window.top.editor.getWrapper();
                  let childCompEditor${child.id}=allComponents${child.id}.find('#${child.id}')[0];
                  console.log('tableCompHere',childCompEditor${child.id})
                  if(childCompEditor${child.id}){
                    for (var attributeName in ${propertyValue}) {
                      if (${propertyValue}.hasOwnProperty(attributeName)) {
                        var attributeValue = ${propertyValue}[attributeName];
                        childCompEditor${child.id}.attributes[attributeName] = attributeValue;
                      }
                    }
                    childCompEditor${child.id}.attributes.script.call(
                      childCompEditor${child.id}.getEl(),
                      childCompEditor${child.id}.props()
                    );

                    childCompEditor${child.id}.Paginate()
                  }
                }else{
                  if(typeof ${propertyValue} === 'object'){
                    Object.keys(${propertyValue}).forEach(function(key) {
                      window[key + "${child.id}"] = ${propertyValue}[key];
                  });
                  }
                }
                                          `;
                return code;
              };

            categoryXml += `<block type="${childParentAttribute}-${child.id}"></block>`;

            Blockly.Blocks[`${childParentAttribute}-${child.id}`] = {
              init: function () {
                this.appendDummyInput().appendField(
                  `${childParentAttribute}-${child.id}`
                );
                this.setOutput(true, "String");
                this.setColour(83);
                this.setTooltip(
                  `Get the textContent of the ${childParentAttribute}-${child.id} button`
                );
                this.setHelpUrl("");
              },
            };

            Blockly.JavaScript[`${childParentAttribute}-${child.id}`] =
              function (block) {
                let val = child.value;
                const code = `'${val}'`;

                return [code, Blockly.JavaScript.ORDER_ATOMIC];
              };

            //   component instance
            categoryXml += `<block type="component_instance-${childParentAttribute}-${child.id}"></block>`;

            Blockly.Blocks[
              `component_instance-${childParentAttribute}-${child.id}`
            ] = {
              init: function () {
                this.appendDummyInput().appendField("Component Instance");
                this.appendStatementInput("Properties");
                this.setPreviousStatement(true, null);
                this.setNextStatement(true, null);
                this.setColour(43);
                this.setTooltip("Get Instance of the Component");
              },
            };

            Blockly.JavaScript[
              `component_instance-${childParentAttribute}-${child.id}`
            ] = function (block) {
              const properties = Blockly.JavaScript.statementToCode(
                block,
                "Properties"
              );

              var code = `
                                        var childElement${child.id} = document.querySelector("#${child.id}");
                                        ${properties}
                                        `;

              return code;
            };

            categoryXml += `<block type="custom_css_property-${childParentAttribute}-${child.id}"></block>`;

            //   for style manipulation:
            Blockly.Blocks[
              `custom_css_property-${childParentAttribute}-${child.id}`
            ] = {
              init: function () {
                this.appendValueInput("PROPERTY_NAME")
                  .setCheck("String")
                  .appendField("Property Name:");

                this.appendValueInput("PROPERTY_VALUE")
                  .setCheck("String")
                  .appendField("Value:");
                this.setPreviousStatement(true, null);
                this.setNextStatement(true, null);
                this.setColour(172);
                this.setTooltip("Specify a CSS property, its type, and value.");
              },
            };

            Blockly.JavaScript[
              `custom_css_property-${childParentAttribute}-${child.id}`
            ] = function (block) {
              const propertyName = Blockly.JavaScript.valueToCode(
                block,
                "PROPERTY_NAME",
                Blockly.JavaScript.ORDER_ATOMIC
              );

              const propertyValue = Blockly.JavaScript.valueToCode(
                block,
                "PROPERTY_VALUE",
                Blockly.JavaScript.ORDER_ATOMIC
              );

              var code = `
                                        childElement${child.id}.style[${propertyName}]=${propertyValue}
                                        `;

              return code;
            };

            //   js manipulation:
            categoryXml += `<block type="events-${childParentAttribute}-${child.id}"></block>`;

            //   for style manipulation:
            Blockly.Blocks[`events-${childParentAttribute}-${child.id}`] = {
              init: function () {
                this.appendDummyInput().appendField("Component Events");
                this.appendDummyInput()
                  .appendField("On Event")
                  .appendField(
                    new Blockly.FieldDropdown([
                      ["Click", "click"],
                      ["Change", "change"],
                    ]),
                    "EVENT"
                  );
                this.appendStatementInput("DO").appendField("Do");
                this.setPreviousStatement(true, null);
                this.setNextStatement(true, null);
                this.setColour(230);
                this.setTooltip("Add event handler To the component");
                this.setHelpUrl("");
              },
            };

            Blockly.JavaScript[`events-${childParentAttribute}-${child.id}`] =
              function (block) {
                var event = block.getFieldValue("EVENT");
                var code = "";
                var actionCode = Blockly.JavaScript.statementToCode(
                  block,
                  "DO"
                );

                if (event && actionCode) {
                  code += `
                    
                        childElement${child.id}.addEventListener('${event}', function(event) {
                          ${actionCode}
                        });
                    
                    `;
                }

                return code;
              };
          }

          if (childParentAttribute === "custom-table-tbody-row") {
            let rowId = child.getAttribute("data-db-id");
            categoryXml += `<block type="rowId-${child.id}"></block>`;
            // console.log("child button::::::::::::", child);

            // categoryXml += `<block type="events-${child.type}-${i}"></block>`;

            Blockly.Blocks[`rowId-${child.id}`] = {
              init: function () {
                this.appendDummyInput().appendField(`rowId-${child.id}`);
                this.setOutput(true, "String");
                this.setColour(83);
                this.setTooltip(`Get the Id Associated with selected row`);
                this.setHelpUrl("");
              },
            };

            Blockly.JavaScript[`rowId-${child.id}`] = function (block) {
              let val = rowId;
              const code = `'${val}'`;

              return [code, Blockly.JavaScript.ORDER_ATOMIC];
            };

            categoryXml += `<block type="component_instance-row-${child.id}"></block>`;

            Blockly.Blocks[`component_instance-row-${child.id}`] = {
              init: function () {
                this.appendDummyInput().appendField(
                  `component_instance-row-${child.id}`
                );
                this.appendStatementInput("Properties");
                this.setPreviousStatement(true, null);
                this.setNextStatement(true, null);
                this.setColour(43);
                this.setTooltip("Get Instance of the Component");
              },
            };

            Blockly.JavaScript[`component_instance-row-${child.id}`] =
              function (block) {
                const properties = Blockly.JavaScript.statementToCode(
                  block,
                  "Properties"
                );

                var code = `
                                        var childElement${child.id} = document.querySelector("#${child.id}");
                                        if(childElement${child.id}){
                                            ${properties}
                                        }
                                        `;

                return code;
              };

            categoryXml += `<block type="events-row-${child.id}"></block>`;

            //   for style manipulation:
            Blockly.Blocks[`events-row-${child.id}`] = {
              init: function () {
                this.appendDummyInput().appendField(
                  `Component Events-row-${child.id}`
                );
                this.appendDummyInput()
                  .appendField("On Event")
                  .appendField(
                    new Blockly.FieldDropdown([
                      ["Click", "click"],
                      ["Change", "change"],
                    ]),
                    "EVENT"
                  );
                this.appendStatementInput("DO").appendField("Do");
                this.setPreviousStatement(true, null);
                this.setNextStatement(true, null);
                this.setColour(262);
                this.setTooltip("Add event handler To the component");
                this.setHelpUrl("");
              },
            };

            Blockly.JavaScript[`events-row-${child.id}`] = function (block) {
              var event = block.getFieldValue("EVENT");
              var code = "";
              var actionCode = Blockly.JavaScript.statementToCode(block, "DO");

              if (event && actionCode) {
                code += `
                      
                          childElement${child.id}.addEventListener('${event}', function(event) {
                            ${actionCode}
                          });
                      
                      `;
              }

              return code;
            };
          }

          if (childParentAttribute === "cell") {
            categoryXml += `<block type="${child.tagName}-${child.id}"></block>`;

            // categoryXml += `<block type="events-${child.type}-${i}"></block>`;

            Blockly.Blocks[`${child.tagName}-${child.id}`] = {
              init: function () {
                this.appendDummyInput().appendField(
                  `${child.tagName}-${child.id}`
                );
                this.setOutput(true, "String");
                this.setColour(83);
                this.setTooltip(
                  `Get the textContent of the ${child.tagName}-${child.id} `
                );
                this.setHelpUrl("");
              },
            };

            Blockly.JavaScript[`${child.tagName}-${child.id}`] = function (
              block
            ) {
              let val = child.textContent;
              const code = `'${val}'`;

              return [code, Blockly.JavaScript.ORDER_ATOMIC];
            };

            //   component instance
            categoryXml += `<block type="component_instance-${child.tagName}-${child.id}"></block>`;

            Blockly.Blocks[`component_instance-${child.tagName}-${child.id}`] =
              {
                init: function () {
                  this.appendDummyInput().appendField(
                    `component_instance-${child.tagName}-${child.id}`
                  );
                  this.appendStatementInput("Properties");
                  this.setPreviousStatement(true, null);
                  this.setNextStatement(true, null);
                  this.setColour(43);
                  this.setTooltip("Get Instance of the Component");
                },
              };

            Blockly.JavaScript[
              `component_instance-${child.tagName}-${child.id}`
            ] = function (block) {
              const properties = Blockly.JavaScript.statementToCode(
                block,
                "Properties"
              );

              var code = `
                                        var childElement${child.id} = document.querySelector("#${child.id}");
                                        if(childElement${child.id}){
                                            ${properties}
                                        }
                                        `;

              return code;
            };

            categoryXml += `<block type="manipulate-value-${child.tagName}-${child.id}"></block>`;

            Blockly.Blocks[`manipulate-value-${child.tagName}-${child.id}`] = {
              init: function () {
                this.appendDummyInput().appendField(
                  `Set Property of ${child.tagName}-${child.id}`
                );
                this.appendDummyInput()
                  .appendField("Property:")
                  .appendField(
                    new Blockly.FieldDropdown([
                      ["value", "value"],
                      ["textContent", "textContent"],
                    ]),
                    "PROPERTY"
                  );
                this.appendValueInput("VALUE")
                  .setCheck(null)
                  .appendField("Value:");
                this.setPreviousStatement(true, null);
                this.setNextStatement(true, null);
                this.setColour(172);
                this.setTooltip("Set Property of the Component");
              },
            };

            Blockly.JavaScript[
              `manipulate-value-${child.tagName}-${child.id}`
            ] = function (block) {
              const property = block.getFieldValue("PROPERTY");
              const value = Blockly.JavaScript.valueToCode(
                block,
                "VALUE",
                Blockly.JavaScript.ORDER_ATOMIC
              );

              var code = `
                    childElement${child.id}.${property} = ${value};
                    `;

              return code;
            };

            categoryXml += `<block type="custom_css_property-${child.tagName}-${child.id}"></block>`;

            //   for style manipulation:
            //   bbql
            Blockly.Blocks[`custom_css_property-${child.tagName}-${child.id}`] =
              {
                init: function () {
                  this.appendDummyInput().appendField(
                    `customise-styles-${child.tagName}-${child.id}`
                  );
                  this.appendValueInput("PROPERTY_NAME")
                    .setCheck("String")
                    .appendField("Property Name:");

                  this.appendValueInput("PROPERTY_VALUE")
                    .setCheck("String")
                    .appendField("Value:");
                  this.setPreviousStatement(true, null);
                  this.setNextStatement(true, null);
                  this.setColour(180);
                  this.setTooltip(
                    "Specify a CSS property, its type, and value."
                  );
                },
              };

            Blockly.JavaScript[
              `custom_css_property-${child.tagName}-${child.id}`
            ] = function (block) {
              const propertyName = Blockly.JavaScript.valueToCode(
                block,
                "PROPERTY_NAME",
                Blockly.JavaScript.ORDER_ATOMIC
              );

              const propertyValue = Blockly.JavaScript.valueToCode(
                block,
                "PROPERTY_VALUE",
                Blockly.JavaScript.ORDER_ATOMIC
              );

              var code = `
                                        childElement${child.id}.style[${propertyName}]=${propertyValue}
                                        `;

              return code;
            };

            //   js manipulation:
            categoryXml += `<block type="events-${child.tagName}-${child.id}"></block>`;

            //   for style manipulation:
            Blockly.Blocks[`events-${child.tagName}-${child.id}`] = {
              init: function () {
                this.appendDummyInput().appendField(
                  `Component Events-${child.tagName}-${child.id}`
                );
                this.appendDummyInput()
                  .appendField("On Event")
                  .appendField(
                    new Blockly.FieldDropdown([
                      ["Click", "click"],
                      ["Change", "change"],
                    ]),
                    "EVENT"
                  );
                this.appendStatementInput("DO").appendField("Do");
                this.setPreviousStatement(true, null);
                this.setNextStatement(true, null);
                this.setColour(230);
                this.setTooltip("Add event handler To the component");
                this.setHelpUrl("");
              },
            };

            Blockly.JavaScript[`events-${child.tagName}-${child.id}`] =
              function (block) {
                var event = block.getFieldValue("EVENT");
                var code = "";
                var actionCode = Blockly.JavaScript.statementToCode(
                  block,
                  "DO"
                );

                if (event && actionCode) {
                  code += `
                    
                        childElement${child.id}.addEventListener('${event}', function(event) {
                          ${actionCode}
                        });
                    
                    `;
                }

                return code;
              };
          }

          if (childParentAttribute === "send-email-button-container") {
            categoryXml += `<block type="${childParentAttribute}-${child.id}"></block>`;
            Blockly.Blocks[`${childParentAttribute}-${child.id}`] = {
              init: function () {
                this.appendDummyInput().appendField(
                  `Customise Traits of ${childParentAttribute}-${child.id}`
                );

                this.appendValueInput("PROPERTY_VALUE").appendField("Value:");
                this.setPreviousStatement(true, null);
                this.setNextStatement(true, null);
                this.setColour(83);
                this.setTooltip("Manipulate Traits of a select comp");
              },
            };

            Blockly.JavaScript[`${childParentAttribute}-${child.id}`] =
              function (block) {
                const propertyValue = Blockly.JavaScript.valueToCode(
                  block,
                  "PROPERTY_VALUE",
                  Blockly.JavaScript.ORDER_ATOMIC
                );

                var code = `
                let subString${child.id}= window.location.href.indexOf("/editor/") !== -1 &&
              window.location.href.indexOf("?projectId=") !== -1 ||
            window.location.href.indexOf("/large_preview/") !== -1 ||
            window.location.href.indexOf("/tab_preview/") !== -1 ||
            window.location.href.indexOf("/mobile_preview/") !== -1 ||
            window.location.href.indexOf("/fragment_editor/") !== -1;
              if(subString${child.id}){
                let allComponents${child.id} = window.top.editor.getWrapper();
                let childCompEditor${child.id}=allComponents${child.id}.find('#${child.id}')[0];
                console.log('email compHere::::::',childCompEditor${child.id})
                if(childCompEditor${child.id}){
                  for (var attributeName in ${propertyValue}) {
                    if (${propertyValue}.hasOwnProperty(attributeName)) {
                      var attributeValue = ${propertyValue}[attributeName];
                      childCompEditor${child.id}.attributes[attributeName] = attributeValue;
                    }
                  }
                  childCompEditor${child.id}.attributes.script.call(
                    childCompEditor${child.id}.getEl(),
                    childCompEditor${child.id}.props()
                  );
                }
              }else{
                if(typeof ${propertyValue} === 'object'){
                  Object.keys(${propertyValue}).forEach(function(key) {
                    window[key + "${child.id}"] = ${propertyValue}[key];
                });
                }
              }
                                          `;

                return code;
              };
          }

          // Chart Blocks
          // 2D BAR CHART
          if (childParentAttribute === "bar-chart") {
            categoryXml += `<block type="chart-${child.id}"></block>`;
            Blockly.Blocks[`chart-${child.id}`] = {
              init: function () {
                this.appendDummyInput().appendField(
                  `Customise Traits of ${childParentAttribute}-${child.id}`
                );

                this.appendValueInput("PROPERTY_VALUE").appendField("Value:");
                this.setPreviousStatement(true, null);
                this.setNextStatement(true, null);
                this.setColour(83);
                this.setTooltip("Manipulate Traits of a select comp");
              },
            };

            Blockly.JavaScript[`chart-${child.id}`] = function (block) {
              const propertyValue = Blockly.JavaScript.valueToCode(
                block,
                "PROPERTY_VALUE",
                Blockly.JavaScript.ORDER_ATOMIC
              );
              window.top.editor = editor;

              var code = `
             function findComponentsByTypeRecursive(
                rootComponent,
                type,
                foundComponents = []
              ) {
                if (rootComponent.get("type") === type) {
                  foundComponents.push(rootComponent);
                }

                const components = rootComponent.get("components");

                if (components) {
                  components.forEach((childComponent) => {
                   
                    findComponentsByTypeRecursive(
                      childComponent,
                      type,
                      foundComponents
                    );
                  });
                }

                return foundComponents;
              }

              function findComponentsByType(type) {
                const allComponents = window.top.editor.DomComponents.getComponents();
                const foundComponents = [];
                allComponents.forEach((rootComponent) => {
                  findComponentsByTypeRecursive(
                    rootComponent,
                    type,
                    foundComponents
                  );
                });
                return foundComponents;
              }
              const componentTypeToFind = "bar-chart" ;
              const foundComponents =
                findComponentsByType(componentTypeToFind);
              if (foundComponents.length > 0) {
               foundComponents.forEach((el)=>{
                let newEl=el.getEl();
                if(newEl.id===${child.id}){
                  el.set(${propertyValue});
                }
               })
              } else {
                console.log("No  components found");
              }
                                          `;

              return code;
            };

            categoryXml += `<block type="${childParentAttribute}-${child.id}-api_trait"></block>`;

            Blockly.Blocks[`${childParentAttribute}-${child.id}-api_trait`] = {
              init: function () {
                this.appendDummyInput().appendField(
                  `Customise Traits of ${childParentAttribute}-${child.id}`
                );
                this.appendValueInput("PROPERTY_VALUE").appendField("Value:");
                this.setPreviousStatement(true, null);
                this.setNextStatement(true, null);
                this.setColour(180);
                this.setTooltip("Manipulate Traits of a Chart comp");
              },
            };

            Blockly.JavaScript[
              `${childParentAttribute}-${child.id}-api_trait`
            ] = function (block) {
              const propertyValue = Blockly.JavaScript.valueToCode(
                block,
                "PROPERTY_VALUE",
                Blockly.JavaScript.ORDER_ATOMIC
              );

              let code = `
              let subString${child.id}= window.location.href.indexOf("/editor/") !== -1 &&
              window.location.href.indexOf("?projectId=") !== -1 ||
            window.location.href.indexOf("/large_preview/") !== -1 ||
            window.location.href.indexOf("/tab_preview/") !== -1 ||
            window.location.href.indexOf("/mobile_preview/") !== -1 ||
            window.location.href.indexOf("/fragment_editor/") !== -1;
              if(subString${child.id}){
                let allComponents${child.id} = window.top.editor.getWrapper();
                let childCompEditor${child.id}=allComponents${child.id}.find('#${child.id}')[0];
                if(childCompEditor${child.id}){
                  childCompEditor${child.id}.set( ${propertyValue});
                }
              }else{
                if(typeof ${propertyValue} === 'object'){
                  Object.keys(${propertyValue}).forEach(function(key) {
                    window[key + "${child.id}"] = ${propertyValue}[key];
                });
                }
              }
            
              `;
              return code;
            };

            // NEW working trait block versions
            // 2D BAR CHART
            categoryXml += `<block type="customise-traits-bar-chart-${child.id}"></block>`;

            Blockly.Blocks[`customise-traits-bar-chart-${child.id}`] = {
              init: function () {
                this.appendDummyInput()
                  .appendField("Customise Traits of Bar Chart");
                
                this.appendValueInput("TRAIT_OBJECT")
                  .setCheck("Object")
                  .appendField("Key-Value Trait Object:");

                this.setPreviousStatement(true, null);
                this.setNextStatement(true, null);

                this.setColour(230);
                this.setTooltip("Update traits of the bar chart block.");
                this.setHelpUrl("");
              },
            };

            Blockly.JavaScript[`customise-traits-bar-chart-${child.id}`] = function (block) {
              const propertyValue = Blockly.JavaScript.valueToCode(block, "TRAIT_OBJECT", Blockly.JavaScript.ORDER_ATOMIC);

              window.top.editor = editor;

              let code = `
              try {
                let substring${child.id} = window.location.href.includes("/editor/") &&
                  window.location.href.includes("?projectId=") ||
                  window.location.href.includes("/large_preview/") ||
                  window.location.href.includes("/tab_preview/") ||
                  window.location.href.includes("/mobile_preview/") ||
                  window.location.href.includes("/fragment_editor/");

                if (substring${child.id}) {
                  let allComponents${child.id} = window.top.editor.getWrapper();
                  let childEle${child.id} = allComponents${child.id}.find('#${child.id}')[0];
                  
                  if (childEle${child.id}) {
                    // Get current props
                    let currentProps = childEle${child.id}.props();
                    
                    // Merge current props with new updates
                    let updatedProps = { ...currentProps, ...${propertyValue} };
                    
                    // Set the updated props back to the element
                    childEle${child.id}.set(updatedProps);
                    
                    // // Call the script with the updated props
                    // childEle${child.id}.attributes.script.call(
                    //   childEle${child.id}.getEl(),
                    //   childEle${child.id}.props()
                    // );
                  } else {
                    console.error('Component with id ${child.id} not found.');
                  }
                } else {
                  // Handle the else case where substring is false
                  let chartId = '${child.id}';
                  let chartProps = ${propertyValue};
                  
                  if (typeof chartProps === 'object') {
                    // Object.keys(chartProps).forEach(function(key) {
                    //   let keyToUpdate = key + chartId;
                    //   window[\`\${keyToUpdate}\`] = chartProps[key];
                    // });

                    Object.keys(${propertyValue}).forEach(function(key) {
                      window[key + "${child.id}"] = ${propertyValue}[key];
                    });
                  } else {
                    console.error('Invalid chartProps for id ${child.id}. Expected an object.');
                  }
                }
              } catch (error) {
                console.error('Error updating bar chart traits:', error);
              }
              `;

              return code;
            };
          } else {
            categoryXml += `<block type="wrapper-instance-${childParentAttribute}-${child.id}"></block>`;
            Blockly.Blocks[
              `wrapper-instance-${childParentAttribute}-${child.id}`
            ] = {
              init: function () {
                this.appendDummyInput().appendField(
                  `Customise styles of ${childParentAttribute}-${child.id}`
                );
                this.appendValueInput("PROPERTY_NAME")
                  .setCheck("String")
                  .appendField("Property Name:");

                this.appendValueInput("PROPERTY_VALUE")
                  .setCheck("String")
                  .appendField("Value:");
                this.setPreviousStatement(true, null);
                this.setNextStatement(true, null);
                this.setColour(43);
                this.setTooltip("Specify a CSS property, its type, and value.");
              },
            };

            Blockly.JavaScript[
              `wrapper-instance-${childParentAttribute}-${child.id}`
            ] = function (block) {
              const propertyName = Blockly.JavaScript.valueToCode(
                block,
                "PROPERTY_NAME",
                Blockly.JavaScript.ORDER_ATOMIC
              );

              const propertyValue = Blockly.JavaScript.valueToCode(
                block,
                "PROPERTY_VALUE",
                Blockly.JavaScript.ORDER_ATOMIC
              );

              var code = `
                      
                    let  childElement${child.id} =document.querySelector("#${child.id}")
                                          if(childElement${child.id}){
                                            childElement${child.id}.style[${propertyName}]=${propertyValue}
                                          }
                                          `;

              return code;
            };
          }

          // 2D LINE CHART
          if (childParentAttribute === "line-chart") {
            categoryXml += `<block type="customise-traits-line-chart-${child.id}"></block>`;

            Blockly.Blocks[`customise-traits-line-chart-${child.id}`] = {
              init: function () {
                this.appendDummyInput()
                  .appendField("Customise Traits of line Chart");
                
                this.appendValueInput("TRAIT_OBJECT")
                  .setCheck("Object")
                  .appendField("Key-Value Trait Object:");

                this.setPreviousStatement(true, null);
                this.setNextStatement(true, null);

                this.setColour(230);
                this.setTooltip("Update traits of the line chart block.");
                this.setHelpUrl("");
              },
            };

            Blockly.JavaScript[`customise-traits-line-chart-${child.id}`] = function (block) {
              const propertyValue = Blockly.JavaScript.valueToCode(block, "TRAIT_OBJECT", Blockly.JavaScript.ORDER_ATOMIC);

              window.top.editor = editor;

              let code = `
              try {
                let substring${child.id} = window.location.href.includes("/editor/") &&
                  window.location.href.includes("?projectId=") ||
                  window.location.href.includes("/large_preview/") ||
                  window.location.href.includes("/tab_preview/") ||
                  window.location.href.includes("/mobile_preview/") ||
                  window.location.href.includes("/fragment_editor/");

                if (substring${child.id}) {
                  let allComponents${child.id} = window.top.editor.getWrapper();
                  let childEle${child.id} = allComponents${child.id}.find('#${child.id}')[0];
                  
                  if (childEle${child.id}) {
                    // Get current props
                    let currentProps = childEle${child.id}.props();
                    
                    // Merge current props with new updates
                    let updatedProps = { ...currentProps, ...${propertyValue} };
                    
                    // Set the updated props back to the element
                    childEle${child.id}.set(updatedProps);
                    
                    // // Call the script with the updated props
                    // childEle${child.id}.attributes.script.call(
                    //   childEle${child.id}.getEl(),
                    //   childEle${child.id}.props()
                    // );
                  } else {
                    console.error('Component with id ${child.id} not found.');
                  }
                } else {
                  // Handle the else case where substring is false
                  let chartId = '${child.id}';
                  let chartProps = ${propertyValue};
                  
                  if (typeof chartProps === 'object') {
                    // Object.keys(chartProps).forEach(function(key) {
                    //   let keyToUpdate = key + chartId;
                    //   window[\`\${keyToUpdate}\`] = chartProps[key];
                    // });

                    Object.keys(${propertyValue}).forEach(function(key) {
                      window[key + "${child.id}"] = ${propertyValue}[key];
                    });
                  } else {
                    console.error('Invalid chartProps for id ${child.id}. Expected an object.');
                  }
                }
              } catch (error) {
                console.error('Error updating line chart traits:', error);
              }
              `;

              return code;
            };
          }

          // 2D POLAR-AREA CHART
          if (childParentAttribute === "polarAreaChart-chart") {
            categoryXml += `<block type="customise-traits-polarAreaChart-chart-${child.id}"></block>`;

            Blockly.Blocks[`customise-traits-polarAreaChart-chart-${child.id}`] = {
              init: function () {
                this.appendDummyInput()
                  .appendField("Customise Traits of polar area chart");
                
                this.appendValueInput("TRAIT_OBJECT")
                  .setCheck("Object")
                  .appendField("Key-Value Trait Object:");

                this.setPreviousStatement(true, null);
                this.setNextStatement(true, null);

                this.setColour(230);
                this.setTooltip("Update traits of the polarAreaChart chart block.");
                this.setHelpUrl("");
              },
            };

            Blockly.JavaScript[`customise-traits-polarAreaChart-chart-${child.id}`] = function (block) {
              const propertyValue = Blockly.JavaScript.valueToCode(block, "TRAIT_OBJECT", Blockly.JavaScript.ORDER_ATOMIC);

              window.top.editor = editor;

              let code = `
              try {
                let substring${child.id} = window.location.href.includes("/editor/") &&
                  window.location.href.includes("?projectId=") ||
                  window.location.href.includes("/large_preview/") ||
                  window.location.href.includes("/tab_preview/") ||
                  window.location.href.includes("/mobile_preview/") ||
                  window.location.href.includes("/fragment_editor/");

                if (substring${child.id}) {
                  let allComponents${child.id} = window.top.editor.getWrapper();
                  let childEle${child.id} = allComponents${child.id}.find('#${child.id}')[0];
                  
                  if (childEle${child.id}) {
                    // Get current props
                    let currentProps = childEle${child.id}.props();
                    
                    // Merge current props with new updates
                    let updatedProps = { ...currentProps, ...${propertyValue} };
                    
                    // Set the updated props back to the element
                    childEle${child.id}.set(updatedProps);
                    
                    // // Call the script with the updated props
                    // childEle${child.id}.attributes.script.call(
                    //   childEle${child.id}.getEl(),
                    //   childEle${child.id}.props()
                    // );
                  } else {
                    console.error('Component with id ${child.id} not found.');
                  }
                } else {
                  // Handle the else case where substring is false
                  let chartId = '${child.id}';
                  let chartProps = ${propertyValue};
                  
                  if (typeof chartProps === 'object') {
                    // Object.keys(chartProps).forEach(function(key) {
                    //   let keyToUpdate = key + chartId;
                    //   window[\`\${keyToUpdate}\`] = chartProps[key];
                    // });

                    Object.keys(${propertyValue}).forEach(function(key) {
                      window[key + "${child.id}"] = ${propertyValue}[key];
                    });
                  } else {
                    console.error('Invalid chartProps for id ${child.id}. Expected an object.');
                  }
                }
              } catch (error) {
                console.error('Error updating polarAreaChart chart traits:', error);
              }
              `;

              return code;
            };
          }

          // 2D BUBBLE CHART
          if (childParentAttribute === "bubble-chart") {
            categoryXml += `<block type="customise-traits-bubble-chart-${child.id}"></block>`;

            Blockly.Blocks[`customise-traits-bubble-chart-${child.id}`] = {
              init: function () {
                this.appendDummyInput()
                  .appendField("Customise Traits of bubble chart");
                
                this.appendValueInput("TRAIT_OBJECT")
                  .setCheck("Object")
                  .appendField("Key-Value Trait Object:");

                this.setPreviousStatement(true, null);
                this.setNextStatement(true, null);

                this.setColour(230);
                this.setTooltip("Update traits of the bubble chart block.");
                this.setHelpUrl("");
              },
            };

            Blockly.JavaScript[`customise-traits-bubble-chart-${child.id}`] = function (block) {
              const propertyValue = Blockly.JavaScript.valueToCode(block, "TRAIT_OBJECT", Blockly.JavaScript.ORDER_ATOMIC);

              window.top.editor = editor;

              let code = `
              try {
                let substring${child.id} = window.location.href.includes("/editor/") &&
                  window.location.href.includes("?projectId=") ||
                  window.location.href.includes("/large_preview/") ||
                  window.location.href.includes("/tab_preview/") ||
                  window.location.href.includes("/mobile_preview/") ||
                  window.location.href.includes("/fragment_editor/");

                if (substring${child.id}) {
                  let allComponents${child.id} = window.top.editor.getWrapper();
                  let childEle${child.id} = allComponents${child.id}.find('#${child.id}')[0];
                  
                  if (childEle${child.id}) {
                    // Get current props
                    let currentProps = childEle${child.id}.props();
                    
                    // Merge current props with new updates
                    let updatedProps = { ...currentProps, ...${propertyValue} };
                    
                    // Set the updated props back to the element
                    childEle${child.id}.set(updatedProps);
                    
                    // // Call the script with the updated props
                    // childEle${child.id}.attributes.script.call(
                    //   childEle${child.id}.getEl(),
                    //   childEle${child.id}.props()
                    // );
                  } else {
                    console.error('Component with id ${child.id} not found.');
                  }
                } else {
                  // Handle the else case where substring is false
                  let chartId = '${child.id}';
                  let chartProps = ${propertyValue};
                  
                  if (typeof chartProps === 'object') {
                    // Object.keys(chartProps).forEach(function(key) {
                    //   let keyToUpdate = key + chartId;
                    //   window[\`\${keyToUpdate}\`] = chartProps[key];
                    // });

                    Object.keys(${propertyValue}).forEach(function(key) {
                      window[key + "${child.id}"] = ${propertyValue}[key];
                    });
                  } else {
                    console.error('Invalid chartProps for id ${child.id}. Expected an object.');
                  }
                }
              } catch (error) {
                console.error('Error updating bubble chart traits:', error);
              }
              `;

              return code;
            };
          }

          // 2D DOUGHNUT CHART
          if (childParentAttribute === "doughnut-chart") {
            categoryXml += `<block type="customise-traits-doughnut-chart-${child.id}"></block>`;

            Blockly.Blocks[`customise-traits-doughnut-chart-${child.id}`] = {
              init: function () {
                this.appendDummyInput()
                  .appendField("Customise Traits of doughnut chart");
                
                this.appendValueInput("TRAIT_OBJECT")
                  .setCheck("Object")
                  .appendField("Key-Value Trait Object:");

                this.setPreviousStatement(true, null);
                this.setNextStatement(true, null);

                this.setColour(230);
                this.setTooltip("Update traits of the doughnut chart block.");
                this.setHelpUrl("");
              },
            };

            Blockly.JavaScript[`customise-traits-doughnut-chart-${child.id}`] = function (block) {
              const propertyValue = Blockly.JavaScript.valueToCode(block, "TRAIT_OBJECT", Blockly.JavaScript.ORDER_ATOMIC);

              window.top.editor = editor;

              let code = `
              try {
                let substring${child.id} = window.location.href.includes("/editor/") &&
                  window.location.href.includes("?projectId=") ||
                  window.location.href.includes("/large_preview/") ||
                  window.location.href.includes("/tab_preview/") ||
                  window.location.href.includes("/mobile_preview/") ||
                  window.location.href.includes("/fragment_editor/");

                if (substring${child.id}) {
                  let allComponents${child.id} = window.top.editor.getWrapper();
                  let childEle${child.id} = allComponents${child.id}.find('#${child.id}')[0];
                  
                  if (childEle${child.id}) {
                    // Get current props
                    let currentProps = childEle${child.id}.props();
                    
                    // Merge current props with new updates
                    let updatedProps = { ...currentProps, ...${propertyValue} };
                    
                    // Set the updated props back to the element
                    childEle${child.id}.set(updatedProps);
                    
                    // // Call the script with the updated props
                    // childEle${child.id}.attributes.script.call(
                    //   childEle${child.id}.getEl(),
                    //   childEle${child.id}.props()
                    // );
                  } else {
                    console.error('Component with id ${child.id} not found.');
                  }
                } else {
                  // Handle the else case where substring is false
                  let chartId = '${child.id}';
                  let chartProps = ${propertyValue};
                  
                  if (typeof chartProps === 'object') {
                    // Object.keys(chartProps).forEach(function(key) {
                    //   let keyToUpdate = key + chartId;
                    //   window[\`\${keyToUpdate}\`] = chartProps[key];
                    // });

                    Object.keys(${propertyValue}).forEach(function(key) {
                      window[key + "${child.id}"] = ${propertyValue}[key];
                    });
                  } else {
                    console.error('Invalid chartProps for id ${child.id}. Expected an object.');
                  }
                }
              } catch (error) {
                console.error('Error updating doughnut chart traits:', error);
              }
              `;

              return code;
            };
          }

          // 2D PIE CHART
          if (childParentAttribute === "pie-chart") {
            categoryXml += `<block type="customise-traits-pie-chart-${child.id}"></block>`;

            Blockly.Blocks[`customise-traits-pie-chart-${child.id}`] = {
              init: function () {
                this.appendDummyInput()
                  .appendField("Customise Traits of pie chart");
                
                this.appendValueInput("TRAIT_OBJECT")
                  .setCheck("Object")
                  .appendField("Key-Value Trait Object:");

                this.setPreviousStatement(true, null);
                this.setNextStatement(true, null);

                this.setColour(230);
                this.setTooltip("Update traits of the pie chart block.");
                this.setHelpUrl("");
              },
            };

            Blockly.JavaScript[`customise-traits-pie-chart-${child.id}`] = function (block) {
              const propertyValue = Blockly.JavaScript.valueToCode(block, "TRAIT_OBJECT", Blockly.JavaScript.ORDER_ATOMIC);

              window.top.editor = editor;

              let code = `
              try {
                let substring${child.id} = window.location.href.includes("/editor/") &&
                  window.location.href.includes("?projectId=") ||
                  window.location.href.includes("/large_preview/") ||
                  window.location.href.includes("/tab_preview/") ||
                  window.location.href.includes("/mobile_preview/") ||
                  window.location.href.includes("/fragment_editor/");

                if (substring${child.id}) {
                  let allComponents${child.id} = window.top.editor.getWrapper();
                  let childEle${child.id} = allComponents${child.id}.find('#${child.id}')[0];
                  
                  if (childEle${child.id}) {
                    // Get current props
                    let currentProps = childEle${child.id}.props();
                    
                    // Merge current props with new updates
                    let updatedProps = { ...currentProps, ...${propertyValue} };
                    
                    // Set the updated props back to the element
                    childEle${child.id}.set(updatedProps);
                    
                    // // Call the script with the updated props
                    // childEle${child.id}.attributes.script.call(
                    //   childEle${child.id}.getEl(),
                    //   childEle${child.id}.props()
                    // );
                  } else {
                    console.error('Component with id ${child.id} not found.');
                  }
                } else {
                  // Handle the else case where substring is false
                  let chartId = '${child.id}';
                  let chartProps = ${propertyValue};
                  
                  if (typeof chartProps === 'object') {
                    // Object.keys(chartProps).forEach(function(key) {
                    //   let keyToUpdate = key + chartId;
                    //   window[\`\${keyToUpdate}\`] = chartProps[key];
                    // });

                    Object.keys(${propertyValue}).forEach(function(key) {
                      window[key + "${child.id}"] = ${propertyValue}[key];
                    });
                  } else {
                    console.error('Invalid chartProps for id ${child.id}. Expected an object.');
                  }
                }
              } catch (error) {
                console.error('Error updating pie chart traits:', error);
              }
              `;

              return code;
            };
          }

          // 3D CHART
          if (childParentAttribute === "3d-chart") {
            categoryXml += `<block type="customise-traits-3d-chart-${child.id}"></block>`;

            Blockly.Blocks[`customise-traits-3d-chart-${child.id}`] = {
              init: function () {
                this.appendDummyInput()
                  .appendField("Customise Traits of 3d chart");
                
                this.appendValueInput("TRAIT_OBJECT")
                  .setCheck("Object")
                  .appendField("Key-Value Trait Object:");

                this.setPreviousStatement(true, null);
                this.setNextStatement(true, null);

                this.setColour(230);
                this.setTooltip("Update traits of the 3d chart block.");
                this.setHelpUrl("");
              },
            };

            Blockly.JavaScript[`customise-traits-3d-chart-${child.id}`] = function (block) {
              const propertyValue = Blockly.JavaScript.valueToCode(block, "TRAIT_OBJECT", Blockly.JavaScript.ORDER_ATOMIC);

              window.top.editor = editor;

              let code = `
              try {
                let substring${child.id} = window.location.href.includes("/editor/") &&
                  window.location.href.includes("?projectId=") ||
                  window.location.href.includes("/large_preview/") ||
                  window.location.href.includes("/tab_preview/") ||
                  window.location.href.includes("/mobile_preview/") ||
                  window.location.href.includes("/fragment_editor/");

                if (substring${child.id}) {
                  let allComponents${child.id} = window.top.editor.getWrapper();
                  let childEle${child.id} = allComponents${child.id}.find('#${child.id}')[0];
                  
                  if (childEle${child.id}) {
                    // Get current props
                    let currentProps = childEle${child.id}.props();
                    
                    // Merge current props with new updates
                    let updatedProps = { ...currentProps, ...${propertyValue} };
                    
                    // Set the updated props back to the element
                    childEle${child.id}.set(updatedProps);
                    
                    // // Call the script with the updated props
                    // childEle${child.id}.attributes.script.call(
                    //   childEle${child.id}.getEl(),
                    //   childEle${child.id}.props()
                    // );
                  } else {
                    console.error('Component with id ${child.id} not found.');
                  }
                } else {
                  // Handle the else case where substring is false
                  let chartId = '${child.id}';
                  let chartProps = ${propertyValue};
                  
                  if (typeof chartProps === 'object') {
                    // Object.keys(chartProps).forEach(function(key) {
                    //   let keyToUpdate = key + chartId;
                    //   window[\`\${keyToUpdate}\`] = chartProps[key];
                    // });

                    Object.keys(${propertyValue}).forEach(function(key) {
                      window[key + "${child.id}"] = ${propertyValue}[key];
                    });
                  } else {
                    console.error('Invalid chartProps for id ${child.id}. Expected an object.');
                  }
                }
              } catch (error) {
                console.error('Error updating 3d chart traits:', error);
              }
              `;

              return code;
            };
          }

          if (childParentAttribute === "text") {
            console.log("Reached here recursion skipped ::::::");
            console.log("comp type::::::::::", child.id);
            console.log("comp tagname::::::::::", child.tagName);
            console.log("comp child::::::::::", child);
            let firstChildElement = child.firstChild;
            if (
              (child.id && firstChildElement.tagName === "P") ||
              firstChildElement.tagName === "H2" ||
              firstChildElement.tagName === "H1"
            ) {
              categoryXml += `<block type="${child.tagName}-${child.id}"></block>`;

              // categoryXml += `<block type="events-${child.type}-${i}"></block>`;

              Blockly.Blocks[`${child.tagName}-${child.id}`] = {
                init: function () {
                  this.appendDummyInput().appendField(
                    `${child.tagName}-${child.id}`
                  );
                  this.setOutput(true, "String");
                  this.setColour("#b91c1c");
                  this.setTooltip(
                    `Get the textContent of the ${child.tagName}-${child.id} `
                  );
                  this.setHelpUrl("");
                },
              };

              Blockly.JavaScript[`${child.tagName}-${child.id}`] = function (
                block
              ) {
                let val = child.textContent;
                const code = `'${val}'`;

                return [code, Blockly.JavaScript.ORDER_ATOMIC];
              };

              //   component instance
              categoryXml += `<block type="component_instance-${child.tagName}-${child.id}"></block>`;

              Blockly.Blocks[
                `component_instance-${child.tagName}-${child.id}`
              ] = {
                init: function () {
                  this.appendDummyInput().appendField(
                    `component_instance-${child.tagName}-${child.id}`
                  );
                  this.appendStatementInput("Properties");
                  this.setPreviousStatement(true, null);
                  this.setNextStatement(true, null);
                  this.setColour("#b91c1c");
                  this.setTooltip("Get Instance of the Component");
                },
              };

              Blockly.JavaScript[
                `component_instance-${child.tagName}-${child.id}`
              ] = function (block) {
                const properties = Blockly.JavaScript.statementToCode(
                  block,
                  "Properties"
                );

                var code = `
                                          var childElement${child.id} = document.querySelector("#${child.id}");
                                          if(childElement${child.id}){
                                              ${properties}
                                          }
                                          `;

                return code;
              };

              categoryXml += `<block type="manipulate-value-${child.tagName}-${child.id}"></block>`;

              Blockly.Blocks[`manipulate-value-${child.tagName}-${child.id}`] =
                {
                  init: function () {
                    this.appendDummyInput().appendField(
                      `Set Property of ${child.tagName}-${child.id}`
                    );
                    this.appendDummyInput()
                      .appendField("Property:")
                      .appendField(
                        new Blockly.FieldDropdown([
                          ["value", "value"],
                          ["textContent", "textContent"],
                        ]),
                        "PROPERTY"
                      );
                    this.appendValueInput("VALUE")
                      .setCheck(null)
                      .appendField("Value:");
                    this.setPreviousStatement(true, null);
                    this.setNextStatement(true, null);
                    this.setColour("#b91c1c");
                    this.setTooltip("Set Property of the Component");
                  },
                };

              Blockly.JavaScript[
                `manipulate-value-${child.tagName}-${child.id}`
              ] = function (block) {
                const property = block.getFieldValue("PROPERTY");
                const value = Blockly.JavaScript.valueToCode(
                  block,
                  "VALUE",
                  Blockly.JavaScript.ORDER_ATOMIC
                );

                var code = `
                      childElement${child.id}.${property} = ${value};
                      `;

                return code;
              };

              categoryXml += `<block type="custom_css_property-${child.tagName}-${child.id}"></block>`;

              //   for style manipulation:
              //   bbql
              Blockly.Blocks[
                `custom_css_property-${child.tagName}-${child.id}`
              ] = {
                init: function () {
                  this.appendDummyInput().appendField(
                    `customise-styles-${child.tagName}-${child.id}`
                  );
                  this.appendValueInput("PROPERTY_NAME")
                    .setCheck("String")
                    .appendField("Property Name:");

                  this.appendValueInput("PROPERTY_VALUE")
                    .setCheck("String")
                    .appendField("Value:");
                  this.setPreviousStatement(true, null);
                  this.setNextStatement(true, null);
                  this.setColour("#b91c1c");
                  this.setTooltip(
                    "Specify a CSS property, its type, and value."
                  );
                },
              };

              Blockly.JavaScript[
                `custom_css_property-${child.tagName}-${child.id}`
              ] = function (block) {
                const propertyName = Blockly.JavaScript.valueToCode(
                  block,
                  "PROPERTY_NAME",
                  Blockly.JavaScript.ORDER_ATOMIC
                );

                const propertyValue = Blockly.JavaScript.valueToCode(
                  block,
                  "PROPERTY_VALUE",
                  Blockly.JavaScript.ORDER_ATOMIC
                );

                var code = `
                                          childElement${child.id}.style[${propertyName}]=${propertyValue}
                                          `;

                return code;
              };

              //   js manipulation:
              categoryXml += `<block type="events-${child.tagName}-${child.id}"></block>`;

              //   for style manipulation:
              Blockly.Blocks[`events-${child.tagName}-${child.id}`] = {
                init: function () {
                  this.appendDummyInput().appendField(
                    `Component Events-${child.tagName}-${child.id}`
                  );
                  this.appendDummyInput()
                    .appendField("On Event")
                    .appendField(
                      new Blockly.FieldDropdown([
                        ["Click", "click"],
                        ["Change", "change"],
                      ]),
                      "EVENT"
                    );
                  this.appendStatementInput("DO").appendField("Do");
                  this.setPreviousStatement(true, null);
                  this.setNextStatement(true, null);
                  this.setColour("#b91c1c");
                  this.setTooltip("Add event handler To the component");
                  this.setHelpUrl("");
                },
              };

              Blockly.JavaScript[`events-${child.tagName}-${child.id}`] =
                function (block) {
                  var event = block.getFieldValue("EVENT");
                  var code = "";
                  var actionCode = Blockly.JavaScript.statementToCode(
                    block,
                    "DO"
                  );

                  if (event && actionCode) {
                    code += `
                      
                          childElement${child.id}.addEventListener('${event}', function(event) {
                            ${actionCode}
                          });
                      
                      `;
                  }

                  return code;
                };
            } else if (child.id && child.tagName === "DIV") {
              let parentDivId = child.id;
              categoryXml += `<block type="textContent-${parentDivId}"></block>`;

              // categoryXml += `<block type="events-${child.type}-${i}"></block>`;

              Blockly.Blocks[`textContent-${parentDivId}`] = {
                init: function () {
                  this.appendDummyInput().appendField(
                    `textContent-${parentDivId}`
                  );
                  this.setOutput(true, "String");
                  this.setColour(83);
                  this.setTooltip(
                    `Get the textContent of the textContent-${parentDivId} `
                  );
                  this.setHelpUrl("");
                },
              };

              Blockly.JavaScript[`textContent-${parentDivId}`] = function (
                block
              ) {
                let val = child.textContent;
                const code = `'${val}'`;

                return [code, Blockly.JavaScript.ORDER_ATOMIC];
              };

              //   component instance
              categoryXml += `<block type="component_instance-textContent-${parentDivId}"></block>`;

              Blockly.Blocks[`component_instance-textContent-${parentDivId}`] =
                {
                  init: function () {
                    this.appendDummyInput().appendField(
                      `component_instance-textContent-${parentDivId}`
                    );
                    this.appendStatementInput("Properties");
                    this.setPreviousStatement(true, null);
                    this.setNextStatement(true, null);
                    this.setColour(43);
                    this.setTooltip("Get Instance of the Component");
                  },
                };

              Blockly.JavaScript[
                `component_instance-textContent-${parentDivId}`
              ] = function (block) {
                const properties = Blockly.JavaScript.statementToCode(
                  block,
                  "Properties"
                );

                var code = `
                                            var childElement${parentDivId} = document.querySelector("#${parentDivId}");
                                            if(childElement${parentDivId}){
                                              ${properties}
                                                
                                            }
                                            `;

                return code;
              };

              categoryXml += `<block type="manipulate-value-textContent-${parentDivId}"></block>`;

              Blockly.Blocks[`manipulate-value-textContent-${parentDivId}`] = {
                init: function () {
                  this.appendDummyInput().appendField(
                    `Set Property of textContent-${parentDivId}`
                  );
                  this.appendDummyInput()
                    .appendField("Property:")
                    .appendField(
                      new Blockly.FieldDropdown([
                        ["value", "value"],
                        ["textContent", "textContent"],
                      ]),
                      "PROPERTY"
                    );
                  this.appendValueInput("VALUE")
                    .setCheck(null)
                    .appendField("Value:");
                  this.setPreviousStatement(true, null);
                  this.setNextStatement(true, null);
                  this.setColour(172);
                  this.setTooltip("Set Property of the Component");
                },
              };

              Blockly.JavaScript[
                `manipulate-value-textContent-${parentDivId}`
              ] = function (block) {
                const property = block.getFieldValue("PROPERTY");
                const value = Blockly.JavaScript.valueToCode(
                  block,
                  "VALUE",
                  Blockly.JavaScript.ORDER_ATOMIC
                );

                var code = `
                        childElement${parentDivId}.${property} = ${value};
                        `;

                return code;
              };

              categoryXml += `<block type="custom_css_property-textContent-${parentDivId}"></block>`;

              Blockly.Blocks[`custom_css_property-textContent-${parentDivId}`] =
                {
                  init: function () {
                    this.appendDummyInput().appendField(
                      `customise-styles-textContent-${parentDivId}`
                    );
                    this.appendValueInput("PROPERTY_NAME")
                      .setCheck("String")
                      .appendField("Property Name:");

                    this.appendValueInput("PROPERTY_VALUE")
                      .setCheck("String")
                      .appendField("Value:");
                    this.setPreviousStatement(true, null);
                    this.setNextStatement(true, null);
                    this.setColour(30);
                    this.setTooltip(
                      "Specify a CSS property, its type, and value."
                    );
                  },
                };

              Blockly.JavaScript[
                `custom_css_property-textContent-${parentDivId}`
              ] = function (block) {
                const propertyName = Blockly.JavaScript.valueToCode(
                  block,
                  "PROPERTY_NAME",
                  Blockly.JavaScript.ORDER_ATOMIC
                );

                const propertyValue = Blockly.JavaScript.valueToCode(
                  block,
                  "PROPERTY_VALUE",
                  Blockly.JavaScript.ORDER_ATOMIC
                );

                var code = `
                                            childElement${parentDivId}.style[${propertyName}]=${propertyValue}
                                            `;

                return code;
              };

              //   js manipulation:
              categoryXml += `<block type="events-textContent-${parentDivId}"></block>`;

              //   for style manipulation:
              Blockly.Blocks[`events-textContent-${parentDivId}`] = {
                init: function () {
                  this.appendDummyInput().appendField(
                    `Component Events-textContent-${parentDivId}`
                  );
                  this.appendDummyInput()
                    .appendField("On Event")
                    .appendField(
                      new Blockly.FieldDropdown([
                        ["Click", "click"],
                        ["Change", "change"],
                      ]),
                      "EVENT"
                    );
                  this.appendStatementInput("DO").appendField("Do");
                  this.setPreviousStatement(true, null);
                  this.setNextStatement(true, null);
                  this.setColour(30);
                  this.setTooltip("Add event handler To the component");
                  this.setHelpUrl("");
                },
              };

              Blockly.JavaScript[`events-textContent-${parentDivId}`] =
                function (block) {
                  var event = block.getFieldValue("EVENT");
                  var code = "";
                  var actionCode = Blockly.JavaScript.statementToCode(
                    block,
                    "DO"
                  );

                  if (event && actionCode) {
                    code += `
                        
                            childElement${parentDivId}.addEventListener('${event}', function(event) {
                              ${actionCode}
                            });
                        
                        `;
                  }

                  return code;
                };
            } else {
              let parentDivId = child.parentNode.id;
              categoryXml += `<block type="textContent-${parentDivId}"></block>`;

              // categoryXml += `<block type="events-${child.type}-${i}"></block>`;

              Blockly.Blocks[`textContent-${parentDivId}`] = {
                init: function () {
                  this.appendDummyInput().appendField(
                    `textContent-${parentDivId}`
                  );
                  this.setOutput(true, "String");
                  this.setColour(83);
                  this.setTooltip(
                    `Get the textContent of the textContent-${parentDivId} `
                  );
                  this.setHelpUrl("");
                },
              };

              Blockly.JavaScript[`textContent-${parentDivId}`] = function (
                block
              ) {
                let val = child.textContent;
                const code = `'${val}'`;

                return [code, Blockly.JavaScript.ORDER_ATOMIC];
              };

              //   component instance
              categoryXml += `<block type="component_instance-textContent-${parentDivId}"></block>`;

              Blockly.Blocks[`component_instance-textContent-${parentDivId}`] =
                {
                  init: function () {
                    this.appendDummyInput().appendField(
                      `component_instance-textContent-${parentDivId}`
                    );
                    this.appendStatementInput("Properties");
                    this.setPreviousStatement(true, null);
                    this.setNextStatement(true, null);
                    this.setColour(43);
                    this.setTooltip("Get Instance of the Component");
                  },
                };

              Blockly.JavaScript[
                `component_instance-textContent-${parentDivId}`
              ] = function (block) {
                const properties = Blockly.JavaScript.statementToCode(
                  block,
                  "Properties"
                );

                var code = `
                                          var childElement${parentDivId} = document.querySelector("#${parentDivId}");
                                          if(childElement${parentDivId}){
                                            let childElementOne${parentDivId}=childElement${parentDivId}.firstElementChild
                                              ${properties}
                                          }
                                          `;

                return code;
              };

              categoryXml += `<block type="manipulate-value-textContent-${parentDivId}"></block>`;

              Blockly.Blocks[`manipulate-value-textContent-${parentDivId}`] = {
                init: function () {
                  this.appendDummyInput().appendField(
                    `Set Property of textContent-${parentDivId}`
                  );
                  this.appendDummyInput()
                    .appendField("Property:")
                    .appendField(
                      new Blockly.FieldDropdown([
                        ["value", "value"],
                        ["textContent", "textContent"],
                      ]),
                      "PROPERTY"
                    );
                  this.appendValueInput("VALUE")
                    .setCheck(null)
                    .appendField("Value:");
                  this.setPreviousStatement(true, null);
                  this.setNextStatement(true, null);
                  this.setColour(172);
                  this.setTooltip("Set Property of the Component");
                },
              };

              Blockly.JavaScript[
                `manipulate-value-textContent-${parentDivId}`
              ] = function (block) {
                const property = block.getFieldValue("PROPERTY");
                const value = Blockly.JavaScript.valueToCode(
                  block,
                  "VALUE",
                  Blockly.JavaScript.ORDER_ATOMIC
                );

                var code = `
                      childElementOne${parentDivId}.${property} = ${value};
                      `;

                return code;
              };

              categoryXml += `<block type="custom_css_property-textContent-${parentDivId}"></block>`;

              //   for style manipulation:
              //   bbql
              Blockly.Blocks[`custom_css_property-textContent-${parentDivId}`] =
                {
                  init: function () {
                    this.appendDummyInput().appendField(
                      `customise-styles-textContent-${parentDivId}`
                    );
                    this.appendValueInput("PROPERTY_NAME")
                      .setCheck("String")
                      .appendField("Property Name:");

                    this.appendValueInput("PROPERTY_VALUE")
                      .setCheck("String")
                      .appendField("Value:");
                    this.setPreviousStatement(true, null);
                    this.setNextStatement(true, null);
                    this.setColour(30);
                    this.setTooltip(
                      "Specify a CSS property, its type, and value."
                    );
                  },
                };

              Blockly.JavaScript[
                `custom_css_property-textContent-${parentDivId}`
              ] = function (block) {
                const propertyName = Blockly.JavaScript.valueToCode(
                  block,
                  "PROPERTY_NAME",
                  Blockly.JavaScript.ORDER_ATOMIC
                );

                const propertyValue = Blockly.JavaScript.valueToCode(
                  block,
                  "PROPERTY_VALUE",
                  Blockly.JavaScript.ORDER_ATOMIC
                );

                var code = `
                                          childElementOne${parentDivId}.style[${propertyName}]=${propertyValue}
                                          `;

                return code;
              };

              //   js manipulation:
              categoryXml += `<block type="events-textContent-${parentDivId}"></block>`;

              //   for style manipulation:
              Blockly.Blocks[`events-textContent-${parentDivId}`] = {
                init: function () {
                  this.appendDummyInput().appendField(
                    `Component Events-textContent-${parentDivId}`
                  );
                  this.appendDummyInput()
                    .appendField("On Event")
                    .appendField(
                      new Blockly.FieldDropdown([
                        ["Click", "click"],
                        ["Change", "change"],
                      ]),
                      "EVENT"
                    );
                  this.appendStatementInput("DO").appendField("Do");
                  this.setPreviousStatement(true, null);
                  this.setNextStatement(true, null);
                  this.setColour(30);
                  this.setTooltip("Add event handler To the component");
                  this.setHelpUrl("");
                },
              };

              Blockly.JavaScript[`events-textContent-${parentDivId}`] =
                function (block) {
                  var event = block.getFieldValue("EVENT");
                  var code = "";
                  var actionCode = Blockly.JavaScript.statementToCode(
                    block,
                    "DO"
                  );

                  if (event && actionCode) {
                    code += `
                      
                          childElementOne${parentDivId}.addEventListener('${event}', function(event) {
                            ${actionCode}
                          });
                      
                      `;
                  }

                  return code;
                };
            }
          }

          if (!child.id && (child.tagName === "p" || child.tagName === "P")) {
            let parentDivId = child.parentNode.id;
            categoryXml += `<block type="textContent-${parentDivId}"></block>`;

            // categoryXml += `<block type="events-${child.type}-${i}"></block>`;

            Blockly.Blocks[`textContent-${parentDivId}`] = {
              init: function () {
                this.appendDummyInput().appendField(
                  `textContent-${parentDivId}`
                );
                this.setOutput(true, "String");
                this.setColour("#b91c1c");
                this.setTooltip(
                  `Get the textContent of the textContent-${parentDivId} `
                );
                this.setHelpUrl("");
              },
            };

            Blockly.JavaScript[`textContent-${parentDivId}`] = function (
              block
            ) {
              let val = child.textContent;
              const code = `'${val}'`;

              return [code, Blockly.JavaScript.ORDER_ATOMIC];
            };

            //   component instance
            categoryXml += `<block type="component_instance-textContent-${parentDivId}"></block>`;

            Blockly.Blocks[`component_instance-textContent-${parentDivId}`] = {
              init: function () {
                this.appendDummyInput().appendField(
                  `component_instance-textContent-${parentDivId}`
                );
                this.appendStatementInput("Properties");
                this.setPreviousStatement(true, null);
                this.setNextStatement(true, null);
                this.setColour("#b91c1c");
                this.setTooltip("Get Instance of the Component");
              },
            };

            Blockly.JavaScript[
              `component_instance-textContent-${parentDivId}`
            ] = function (block) {
              const properties = Blockly.JavaScript.statementToCode(
                block,
                "Properties"
              );

              var code = `
                                          var childElement${parentDivId} = document.querySelector("#${parentDivId}");
                                          if(childElement${parentDivId}){
                                            let childElementOne${parentDivId}=childElement${parentDivId}.firstElementChild
                                              ${properties}
                                          }
                                          `;

              return code;
            };

            categoryXml += `<block type="manipulate-value-textContent-${parentDivId}"></block>`;

            Blockly.Blocks[`manipulate-value-textContent-${parentDivId}`] = {
              init: function () {
                this.appendDummyInput().appendField(
                  `Set Property of textContent-${parentDivId}`
                );
                this.appendDummyInput()
                  .appendField("Property:")
                  .appendField(
                    new Blockly.FieldDropdown([
                      ["value", "value"],
                      ["textContent", "textContent"],
                    ]),
                    "PROPERTY"
                  );
                this.appendValueInput("VALUE")
                  .setCheck(null)
                  .appendField("Value:");
                this.setPreviousStatement(true, null);
                this.setNextStatement(true, null);
                this.setColour("#b91c1c");
                this.setTooltip("Set Property of the Component");
              },
            };

            Blockly.JavaScript[`manipulate-value-textContent-${parentDivId}`] =
              function (block) {
                const property = block.getFieldValue("PROPERTY");
                const value = Blockly.JavaScript.valueToCode(
                  block,
                  "VALUE",
                  Blockly.JavaScript.ORDER_ATOMIC
                );

                var code = `
                      childElementOne${parentDivId}.${property} = ${value};
                      `;

                return code;
              };

            categoryXml += `<block type="custom_css_property-textContent-${parentDivId}"></block>`;

            //   for style manipulation:
            //   bbql
            Blockly.Blocks[`custom_css_property-textContent-${parentDivId}`] = {
              init: function () {
                this.appendDummyInput().appendField(
                  `customise-styles-textContent-${parentDivId}`
                );
                this.appendValueInput("PROPERTY_NAME")
                  .setCheck("String")
                  .appendField("Property Name:");

                this.appendValueInput("PROPERTY_VALUE")
                  .setCheck("String")
                  .appendField("Value:");
                this.setPreviousStatement(true, null);
                this.setNextStatement(true, null);
                this.setColour("#b91c1c");
                this.setTooltip("Specify a CSS property, its type, and value.");
              },
            };

            Blockly.JavaScript[
              `custom_css_property-textContent-${parentDivId}`
            ] = function (block) {
              const propertyName = Blockly.JavaScript.valueToCode(
                block,
                "PROPERTY_NAME",
                Blockly.JavaScript.ORDER_ATOMIC
              );

              const propertyValue = Blockly.JavaScript.valueToCode(
                block,
                "PROPERTY_VALUE",
                Blockly.JavaScript.ORDER_ATOMIC
              );

              var code = `
                                          childElementOne${parentDivId}.style[${propertyName}]=${propertyValue}
                                          `;

              return code;
            };

            //   js manipulation:
            categoryXml += `<block type="events-textContent-${parentDivId}"></block>`;

            //   for style manipulation:
            Blockly.Blocks[`events-textContent-${parentDivId}`] = {
              init: function () {
                this.appendDummyInput().appendField(
                  `Component Events-textContent-${parentDivId}`
                );
                this.appendDummyInput()
                  .appendField("On Event")
                  .appendField(
                    new Blockly.FieldDropdown([
                      ["Click", "click"],
                      ["Change", "change"],
                    ]),
                    "EVENT"
                  );
                this.appendStatementInput("DO").appendField("Do");
                this.setPreviousStatement(true, null);
                this.setNextStatement(true, null);
                this.setColour("#b91c1c");
                this.setTooltip("Add event handler To the component");
                this.setHelpUrl("");
              },
            };

            Blockly.JavaScript[`events-textContent-${parentDivId}`] = function (
              block
            ) {
              var event = block.getFieldValue("EVENT");
              var code = "";
              var actionCode = Blockly.JavaScript.statementToCode(block, "DO");

              if (event && actionCode) {
                code += `
                      
                          childElementOne${parentDivId}.addEventListener('${event}', function(event) {
                            ${actionCode}
                          });
                      
                      `;
              }

              return code;
            };
          }

          if (childParentAttribute === "stellar-button") {
            if (child.tagName === "BUTTON" || child.tagName === "button") {
              let parentDivId = child.parentNode.id;

              categoryXml += `<block type="${child.tagName}-api_body_trait-${child.id}"></block>`;

              Blockly.Blocks[`${child.tagName}-api_body_trait-${child.id}`] = {
                init: function () {
                  this.appendDummyInput().appendField(
                    `Customise Traits of ${child.tagName}-${parentDivId}`
                  );
                  this.appendValueInput("PROPERTY_VALUE").appendField("Value:");
                  this.setPreviousStatement(true, null);
                  this.setNextStatement(true, null);
                  this.setColour(180);
                  this.setTooltip("Manipulate Traits of stellar button");
                },
              };

              Blockly.JavaScript[
                `${child.tagName}-api_body_trait-${child.id}`
              ] = function (block) {
                const propertyValue = Blockly.JavaScript.valueToCode(
                  block,
                  "PROPERTY_VALUE",
                  Blockly.JavaScript.ORDER_ATOMIC
                );
                window.top.editor = editor;

                var code = `

                let subString${child.id}= window.location.href.indexOf("/editor/") !== -1 &&
                window.location.href.indexOf("?projectId=") !== -1 ||
              window.location.href.indexOf("/large_preview/") !== -1 ||
              window.location.href.indexOf("/tab_preview/") !== -1 ||
              window.location.href.indexOf("/mobile_preview/") !== -1 ||
              window.location.href.indexOf("/fragment_editor/") !== -1;
                if(subString${child.id}){
                  let allComponents${parentDivId} = window.top.editor.getWrapper();
                let childCompEditor${parentDivId}=allComponents${parentDivId}.find('#${parentDivId}')[0];
                if(childCompEditor${parentDivId}){
                  childCompEditor${parentDivId}.set( ${propertyValue});
                }
                }else{
                  if(typeof ${propertyValue} === 'object'){
                    Object.keys(${propertyValue}).forEach(function(key) {
                      window[key + "${parentDivId}"] = ${propertyValue}[key];
                  });
                  }
                }
                                            `;
                return code;
              };
              categoryXml += `<block type="${child.tagName}-${child.id}"></block>`;
              // console.log("child button::::::::::::", child);

              // categoryXml += `<block type="events-${child.type}-${i}"></block>`;

              Blockly.Blocks[`${child.tagName}-${child.id}`] = {
                init: function () {
                  this.appendDummyInput().appendField(
                    `${child.tagName}-${child.id}`
                  );
                  this.setOutput(true, "String");
                  this.setColour(83);
                  this.setTooltip(
                    `Get the value of the ${child.tagName}-${child.id} button`
                  );
                  this.setHelpUrl("");
                },
              };

              Blockly.JavaScript[`${child.tagName}-${child.id}`] = function (
                block
              ) {
                let val = child.value;
                const code = `'${val}'`;

                return [code, Blockly.JavaScript.ORDER_ATOMIC];
              };

              //   component instance
              categoryXml += `<block type="component_instance-${child.tagName}-${child.id}"></block>`;

              Blockly.Blocks[
                `component_instance-${child.tagName}-${child.id}`
              ] = {
                init: function () {
                  this.appendDummyInput().appendField(
                    `Component Instance-${child.tagName}-${child.id}`
                  );
                  this.appendStatementInput("Properties");
                  this.setPreviousStatement(true, null);
                  this.setNextStatement(true, null);
                  this.setColour(43);
                  this.setTooltip("Get Instance of the Component");
                },
              };

              Blockly.JavaScript[
                `component_instance-${child.tagName}-${child.id}`
              ] = function (block) {
                const properties = Blockly.JavaScript.statementToCode(
                  block,
                  "Properties"
                );

                var code = `
                                          var childElement${child.id} = document.querySelector("#${child.id}");
                                          if(childElement${child.id}){
                                              ${properties}
                                          }
                                          `;

                return code;
              };

              categoryXml += `<block type="custom_css_property-${child.tagName}-${child.id}"></block>`;

              //   for style manipulation:
              Blockly.Blocks[
                `custom_css_property-${child.tagName}-${child.id}`
              ] = {
                init: function () {
                  this.appendDummyInput().appendField(
                    `custom_css_property-${child.tagName}-${child.id}`
                  );
                  this.appendValueInput("PROPERTY_NAME")
                    .setCheck("String")
                    .appendField("Property Name:");

                  this.appendValueInput("PROPERTY_VALUE")
                    .setCheck("String")
                    .appendField("Value:");
                  this.setPreviousStatement(true, null);
                  this.setNextStatement(true, null);
                  this.setColour(172);
                  this.setTooltip(
                    "Specify a CSS property, its type, and value."
                  );
                },
              };

              Blockly.JavaScript[
                `custom_css_property-${child.tagName}-${child.id}`
              ] = function (block) {
                const propertyName = Blockly.JavaScript.valueToCode(
                  block,
                  "PROPERTY_NAME",
                  Blockly.JavaScript.ORDER_ATOMIC
                );

                const propertyValue = Blockly.JavaScript.valueToCode(
                  block,
                  "PROPERTY_VALUE",
                  Blockly.JavaScript.ORDER_ATOMIC
                );

                var code = `
                                          childElement${child.id}.style[${propertyName}]=${propertyValue}
                                          `;

                return code;
              };

              //   js manipulation:
              categoryXml += `<block type="events-${child.tagName}-${child.id}"></block>`;

              //   for style manipulation:
              Blockly.Blocks[`events-${child.tagName}-${child.id}`] = {
                init: function () {
                  this.appendDummyInput().appendField(
                    `Component Events-${child.tagName}-${child.id}`
                  );
                  this.appendDummyInput()
                    .appendField("On Event")
                    .appendField(
                      new Blockly.FieldDropdown([
                        ["Click", "click"],
                        ["Change", "change"],
                      ]),
                      "EVENT"
                    );
                  this.appendStatementInput("DO").appendField("Do");
                  this.setPreviousStatement(true, null);
                  this.setNextStatement(true, null);
                  this.setColour(230);
                  this.setTooltip("Add event handler To the component");
                  this.setHelpUrl("");
                },
              };

              Blockly.JavaScript[`events-${child.tagName}-${child.id}`] =
                function (block) {
                  var event = block.getFieldValue("EVENT");
                  var code = "";
                  var actionCode = Blockly.JavaScript.statementToCode(
                    block,
                    "DO"
                  );

                  if (event && actionCode) {
                    code += `
                      
                          childElement${child.id}.addEventListener('${event}', function(event) {
                            ${actionCode}
                          });
                      
                      `;
                  }

                  return code;
                };

              categoryXml += `<block type="prevent-default-behaviour-${child.tagName}-${child.id}"></block>`;
              Blockly.Blocks[
                `prevent-default-behaviour-${child.tagName}-${child.id}`
              ] = {
                init: function () {
                  this.appendDummyInput().appendField(
                    `prevent-default-behaviour-${child.tagName}-${child.id}`
                  );
                  this.setPreviousStatement(true, null);
                  this.setNextStatement(true, null);
                  this.setColour(230);
                  this.setTooltip(
                    `prevent-default-behaviour of the ${child.tagName}-${child.id} Input`
                  );
                  this.setHelpUrl("");
                },
              };

              Blockly.JavaScript[
                `prevent-default-behaviour-${child.tagName}-${child.id}`
              ] = function (block) {
                const code = `
                event.preventDefault();

                // space_to_avoid_conflict

                
                `;

                return code;
              };

              categoryXml += `<block type="disable-${child.tagName}-${child.id}"></block>`;

              //   for style manipulation:
              Blockly.Blocks[`disable-${child.tagName}-${child.id}`] = {
                init: function () {
                  this.appendDummyInput().appendField(
                    `disable-${child.tagName}-${child.id}`
                  );
                  this.appendValueInput("PROPERTY_VALUE").appendField("Value:");
                  this.setPreviousStatement(true, null);
                  this.setNextStatement(true, null);
                  this.setColour(172);
                  this.setTooltip("Disable a Button");
                },
              };

              Blockly.JavaScript[`disable-${child.tagName}-${child.id}`] =
                function (block) {
                  const propertyValue = Blockly.JavaScript.valueToCode(
                    block,
                    "PROPERTY_VALUE",
                    Blockly.JavaScript.ORDER_ATOMIC
                  );

                  var code = `
                                            childElement${child.id}.disabled=${propertyValue}
                                            `;

                  return code;
                };

              //   remove events :
              categoryXml += `<block type="remove-events-${child.tagName}-${child.id}"></block>`;

              Blockly.Blocks[`remove-events-${child.tagName}-${child.id}`] = {
                init: function () {
                  this.appendDummyInput().appendField("Remove Component Event");
                  this.appendDummyInput()
                    .appendField("Remove Event")
                    .appendField(
                      new Blockly.FieldDropdown([
                        ["Click", "click"],
                        ["Change", "change"],
                      ]),
                      "EVENT"
                    );
                  this.setPreviousStatement(true, null);
                  this.setNextStatement(true, null);
                  this.setColour(230);
                  this.setTooltip("Remove event handler from the component");
                  this.setHelpUrl("");
                },
              };

              Blockly.JavaScript[`remove-events-${child.tagName}-${child.id}`] =
                function (block) {
                  var event = block.getFieldValue("EVENT");
                  var code = "";

                  if (event) {
                    code += `
                        // Assuming you have a reference to the element
                      
                          // Retrieve the event listener function from the element
                          var eventListener = childElement${child.id}._eventListeners['${event}'];
                  
                          // Check if the event listener function exists before trying to remove it
                          if (eventListener) {
                            childElement${child.id}.removeEventListener('${event}', eventListener);
                          }
                        
                      `;
                  }

                  return code;
                };
            }
          }

          if (childParentAttribute === "stellar-btn") {
            if (child.tagName === "BUTTON" || child.tagName === "button") {
              let parentDivId = child.parentNode.id;

              categoryXml += `<block type="${child.tagName}-api_body_trait-${child.id}"></block>`;

              Blockly.Blocks[`${child.tagName}-api_body_trait-${child.id}`] = {
                init: function () {
                  this.appendDummyInput().appendField(
                    `Customise Traits of ${child.tagName}-${parentDivId}`
                  );
                  this.appendValueInput("PROPERTY_VALUE").appendField("Value:");
                  this.setPreviousStatement(true, null);
                  this.setNextStatement(true, null);
                  this.setColour(180);
                  this.setTooltip("Manipulate Traits of stellar button");
                },
              };

              Blockly.JavaScript[
                `${child.tagName}-api_body_trait-${child.id}`
              ] = function (block) {
                const propertyValue = Blockly.JavaScript.valueToCode(
                  block,
                  "PROPERTY_VALUE",
                  Blockly.JavaScript.ORDER_ATOMIC
                );
                window.top.editor = editor;

                var code = `

                let subString${child.id}= window.location.href.indexOf("/editor/") !== -1 &&
                window.location.href.indexOf("?projectId=") !== -1 ||
              window.location.href.indexOf("/large_preview/") !== -1 ||
              window.location.href.indexOf("/tab_preview/") !== -1 ||
              window.location.href.indexOf("/mobile_preview/") !== -1 ||
              window.location.href.indexOf("/fragment_editor/") !== -1;
                if(subString${child.id}){
                  let allComponents${parentDivId} = window.top.editor.getWrapper();
                let childCompEditor${parentDivId}=allComponents${parentDivId}.find('#${parentDivId}')[0];
                if(childCompEditor${parentDivId}){
                  childCompEditor${parentDivId}.set( ${propertyValue});
                }
                }else{
                  if(typeof ${propertyValue} === 'object'){
                    Object.keys(${propertyValue}).forEach(function(key) {
                      window[key + "${parentDivId}"] = ${propertyValue}[key];
                  });
                  }
                }
                                            `;
                return code;
              };
              categoryXml += `<block type="${child.tagName}-${child.id}"></block>`;
              // console.log("child button::::::::::::", child);

              // categoryXml += `<block type="events-${child.type}-${i}"></block>`;

              Blockly.Blocks[`${child.tagName}-${child.id}`] = {
                init: function () {
                  this.appendDummyInput().appendField(
                    `${child.tagName}-${child.id}`
                  );
                  this.setOutput(true, "String");
                  this.setColour(83);
                  this.setTooltip(
                    `Get the value of the ${child.tagName}-${child.id} button`
                  );
                  this.setHelpUrl("");
                },
              };

              Blockly.JavaScript[`${child.tagName}-${child.id}`] = function (
                block
              ) {
                let val = child.value;
                const code = `'${val}'`;

                return [code, Blockly.JavaScript.ORDER_ATOMIC];
              };

              //   component instance
              categoryXml += `<block type="component_instance-${child.tagName}-${child.id}"></block>`;

              Blockly.Blocks[
                `component_instance-${child.tagName}-${child.id}`
              ] = {
                init: function () {
                  this.appendDummyInput().appendField(
                    `Component Instance-${child.tagName}-${child.id}`
                  );
                  this.appendStatementInput("Properties");
                  this.setPreviousStatement(true, null);
                  this.setNextStatement(true, null);
                  this.setColour(43);
                  this.setTooltip("Get Instance of the Component");
                },
              };

              Blockly.JavaScript[
                `component_instance-${child.tagName}-${child.id}`
              ] = function (block) {
                const properties = Blockly.JavaScript.statementToCode(
                  block,
                  "Properties"
                );

                var code = `
                                          var childElement${child.id} = document.querySelector("#${child.id}");
                                          if(childElement${child.id}){
                                              ${properties}
                                          }
                                          `;

                return code;
              };

              categoryXml += `<block type="custom_css_property-${child.tagName}-${child.id}"></block>`;

              //   for style manipulation:
              Blockly.Blocks[
                `custom_css_property-${child.tagName}-${child.id}`
              ] = {
                init: function () {
                  this.appendDummyInput().appendField(
                    `custom_css_property-${child.tagName}-${child.id}`
                  );
                  this.appendValueInput("PROPERTY_NAME")
                    .setCheck("String")
                    .appendField("Property Name:");

                  this.appendValueInput("PROPERTY_VALUE")
                    .setCheck("String")
                    .appendField("Value:");
                  this.setPreviousStatement(true, null);
                  this.setNextStatement(true, null);
                  this.setColour(172);
                  this.setTooltip(
                    "Specify a CSS property, its type, and value."
                  );
                },
              };

              Blockly.JavaScript[
                `custom_css_property-${child.tagName}-${child.id}`
              ] = function (block) {
                const propertyName = Blockly.JavaScript.valueToCode(
                  block,
                  "PROPERTY_NAME",
                  Blockly.JavaScript.ORDER_ATOMIC
                );

                const propertyValue = Blockly.JavaScript.valueToCode(
                  block,
                  "PROPERTY_VALUE",
                  Blockly.JavaScript.ORDER_ATOMIC
                );

                var code = `
                                          childElement${child.id}.style[${propertyName}]=${propertyValue}
                                          `;

                return code;
              };

              //   js manipulation:
              categoryXml += `<block type="events-${child.tagName}-${child.id}"></block>`;

              //   for style manipulation:
              Blockly.Blocks[`events-${child.tagName}-${child.id}`] = {
                init: function () {
                  this.appendDummyInput().appendField(
                    `Component Events-${child.tagName}-${child.id}`
                  );
                  this.appendDummyInput()
                    .appendField("On Event")
                    .appendField(
                      new Blockly.FieldDropdown([
                        ["Click", "click"],
                        ["Change", "change"],
                      ]),
                      "EVENT"
                    );
                  this.appendStatementInput("DO").appendField("Do");
                  this.setPreviousStatement(true, null);
                  this.setNextStatement(true, null);
                  this.setColour(230);
                  this.setTooltip("Add event handler To the component");
                  this.setHelpUrl("");
                },
              };

              Blockly.JavaScript[`events-${child.tagName}-${child.id}`] =
                function (block) {
                  var event = block.getFieldValue("EVENT");
                  var code = "";
                  var actionCode = Blockly.JavaScript.statementToCode(
                    block,
                    "DO"
                  );

                  if (event && actionCode) {
                    code += `
                      
                          childElement${child.id}.addEventListener('${event}', function(event) {
                            ${actionCode}
                          });
                      
                      `;
                  }

                  return code;
                };

              categoryXml += `<block type="prevent-default-behaviour-${child.tagName}-${child.id}"></block>`;
              Blockly.Blocks[
                `prevent-default-behaviour-${child.tagName}-${child.id}`
              ] = {
                init: function () {
                  this.appendDummyInput().appendField(
                    `prevent-default-behaviour-${child.tagName}-${child.id}`
                  );
                  this.setPreviousStatement(true, null);
                  this.setNextStatement(true, null);
                  this.setColour(230);
                  this.setTooltip(
                    `prevent-default-behaviour of the ${child.tagName}-${child.id} Input`
                  );
                  this.setHelpUrl("");
                },
              };

              Blockly.JavaScript[
                `prevent-default-behaviour-${child.tagName}-${child.id}`
              ] = function (block) {
                const code = `
                event.preventDefault();

                // space_to_avoid_conflict

                
                `;

                return code;
              };

              categoryXml += `<block type="disable-${child.tagName}-${child.id}"></block>`;

              //   for style manipulation:
              Blockly.Blocks[`disable-${child.tagName}-${child.id}`] = {
                init: function () {
                  this.appendDummyInput().appendField(
                    `disable-${child.tagName}-${child.id}`
                  );
                  this.appendValueInput("PROPERTY_VALUE").appendField("Value:");
                  this.setPreviousStatement(true, null);
                  this.setNextStatement(true, null);
                  this.setColour(172);
                  this.setTooltip("Disable a Button");
                },
              };

              Blockly.JavaScript[`disable-${child.tagName}-${child.id}`] =
                function (block) {
                  const propertyValue = Blockly.JavaScript.valueToCode(
                    block,
                    "PROPERTY_VALUE",
                    Blockly.JavaScript.ORDER_ATOMIC
                  );

                  var code = `
                                            childElement${child.id}.disabled=${propertyValue}
                                            `;

                  return code;
                };

              //   remove events :
              categoryXml += `<block type="remove-events-${child.tagName}-${child.id}"></block>`;

              Blockly.Blocks[`remove-events-${child.tagName}-${child.id}`] = {
                init: function () {
                  this.appendDummyInput().appendField("Remove Component Event");
                  this.appendDummyInput()
                    .appendField("Remove Event")
                    .appendField(
                      new Blockly.FieldDropdown([
                        ["Click", "click"],
                        ["Change", "change"],
                      ]),
                      "EVENT"
                    );
                  this.setPreviousStatement(true, null);
                  this.setNextStatement(true, null);
                  this.setColour(230);
                  this.setTooltip("Remove event handler from the component");
                  this.setHelpUrl("");
                },
              };

              Blockly.JavaScript[`remove-events-${child.tagName}-${child.id}`] =
                function (block) {
                  var event = block.getFieldValue("EVENT");
                  var code = "";

                  if (event) {
                    code += `
                        // Assuming you have a reference to the element
                      
                          // Retrieve the event listener function from the element
                          var eventListener = childElement${child.id}._eventListeners['${event}'];
                  
                          // Check if the event listener function exists before trying to remove it
                          if (eventListener) {
                            childElement${child.id}.removeEventListener('${event}', eventListener);
                          }
                        
                      `;
                  }

                  return code;
                };
            }
          }

          if (childParentAttribute === "normal_text_area_regular_form") {
            //   component instance
            categoryXml += `<block type="component_instance-${childParentAttribute}-${child.id}"></block>`;

            Blockly.Blocks[
              `component_instance-${childParentAttribute}-${child.id}`
            ] = {
              init: function () {
                this.appendDummyInput().appendField(
                  `Component Instance of ${childParentAttribute}-${child.id}`
                );
                this.appendStatementInput("Properties");
                this.setPreviousStatement(true, null);
                this.setNextStatement(true, null);
                this.setColour(43);
                this.setTooltip("Get Instance of the Component");
              },
            };

            Blockly.JavaScript[
              `component_instance-${childParentAttribute}-${child.id}`
            ] = function (block) {
              const properties = Blockly.JavaScript.statementToCode(
                block,
                "Properties"
              );

              var code = `
              var childElement${child.id} = document.querySelector("#${child.id}");
              if(childElement${child.id}){
                let textareaChild${child.id}=childElement${child.id}.querySelector('textarea');

                if(textareaChild${child.id}){
                  ${properties}
                }
                 
              }
                                      `;

              return code;
            };

            categoryXml += `<block type="manipulate-value-${childParentAttribute}-${child.id}"></block>`;

            Blockly.Blocks[
              `manipulate-value-${childParentAttribute}-${child.id}`
            ] = {
              init: function () {
                this.appendDummyInput().appendField(
                  `Set Property of ${childParentAttribute}-${child.id}`
                );
                this.appendDummyInput()
                  .appendField("Property:")
                  .appendField(
                    new Blockly.FieldDropdown([
                      ["value", "value"],
                      ["textContent", "textContent"],
                    ]),
                    "PROPERTY"
                  );
                this.appendValueInput("VALUE")
                  .setCheck(null)
                  .appendField("Value:");
                this.setPreviousStatement(true, null);
                this.setNextStatement(true, null);
                this.setColour(160);
                this.setTooltip("Set Property of the Component");
              },
            };

            Blockly.JavaScript[
              `manipulate-value-${childParentAttribute}-${child.id}`
            ] = function (block) {
              const property = block.getFieldValue("PROPERTY");
              const value = Blockly.JavaScript.valueToCode(
                block,
                "VALUE",
                Blockly.JavaScript.ORDER_ATOMIC
              );

              var code = `
              textareaChild${child.id}.${property} = ${value};
                            `;

              return code;
            };

            categoryXml += `<block type="custom_css_property-${childParentAttribute}-${child.id}"></block>`;

            //   for style manipulation:
            Blockly.Blocks[
              `custom_css_property-${childParentAttribute}-${child.id}`
            ] = {
              init: function () {
                this.appendDummyInput().appendField(
                  `Customise styles of ${childParentAttribute}-${child.id}`
                );
                this.appendValueInput("PROPERTY_NAME")
                  .setCheck("String")
                  .appendField("Property Name:");

                this.appendValueInput("PROPERTY_VALUE")
                  .setCheck("String")
                  .appendField("Value:");
                this.setPreviousStatement(true, null);
                this.setNextStatement(true, null);
                this.setColour(180);
                this.setTooltip("Specify a CSS property, its type, and value.");
              },
            };

            Blockly.JavaScript[
              `custom_css_property-${childParentAttribute}-${child.id}`
            ] = function (block) {
              const propertyName = Blockly.JavaScript.valueToCode(
                block,
                "PROPERTY_NAME",
                Blockly.JavaScript.ORDER_ATOMIC
              );

              const propertyValue = Blockly.JavaScript.valueToCode(
                block,
                "PROPERTY_VALUE",
                Blockly.JavaScript.ORDER_ATOMIC
              );

              var code = `
              textareaChild${child.id}.style[${propertyName}]=${propertyValue}
                                      `;

              return code;
            };

            //   js manipulation:
            categoryXml += `<block type="events-${childParentAttribute}-${child.id}"></block>`;

            //   for style manipulation:
            Blockly.Blocks[`events-${childParentAttribute}-${child.id}`] = {
              init: function () {
                this.appendDummyInput().appendField(
                  `Component Events for ${childParentAttribute}-${child.id}`
                );
                this.appendDummyInput()
                  .appendField("On Event")
                  .appendField(
                    new Blockly.FieldDropdown([
                      ["Click", "click"],
                      ["Change", "change"],
                    ]),
                    "EVENT"
                  );
                this.appendStatementInput("DO").appendField("Do");
                this.setPreviousStatement(true, null);
                this.setNextStatement(true, null);
                this.setColour(230);
                this.setTooltip("Add event handler To the component");
                this.setHelpUrl("");
              },
            };

            Blockly.JavaScript[`events-${childParentAttribute}-${child.id}`] =
              function (block) {
                var event = block.getFieldValue("EVENT");
                var code = "";
                var actionCode = Blockly.JavaScript.statementToCode(
                  block,
                  "DO"
                );

                if (event && actionCode) {
                  code += `
                  
                  textareaChild${child.id}.addEventListener('${event}', function(event) {
                        ${actionCode}
                      });
                  
                  `;
                }

                return code;
              };

            categoryXml += `<block type="target-property-${childParentAttribute}-${child.id}"></block>`;
            Blockly.Blocks[
              `target-property-${childParentAttribute}-${child.id}`
            ] = {
              init: function () {
                this.appendDummyInput().appendField(
                  `target-property-${childParentAttribute}-${child.id}`
                );
                this.setOutput(true, "String");
                this.setColour(230);
                this.setTooltip(
                  `Get the event value of the ${childParentAttribute}-${child.id} Input`
                );
                this.setHelpUrl("");
              },
            };

            Blockly.JavaScript[
              `target-property-${childParentAttribute}-${child.id}`
            ] = function (block) {
              const code = "event.target.value";

              return [code, Blockly.JavaScript.ORDER_ATOMIC];
            };

            categoryXml += `<block type="disable_component-${childParentAttribute}-${child.id}"></block>`;
            Blockly.Blocks[
              `disable_component-${childParentAttribute}-${child.id}`
            ] = {
              init: function () {
                this.appendDummyInput().appendField(
                  `Disable ${childParentAttribute}-${child.id}`
                );
                this.setPreviousStatement(true, null);
                this.setNextStatement(true, null);
                this.setColour(230);
                this.setTooltip("Disable the Component");
              },
            };

            Blockly.JavaScript[
              `disable_component-${childParentAttribute}-${child.id}`
            ] = function (block) {
              var code = `
                             
              textareaChild${child.id}.disabled = true;
                              
                          `;

              return code;
            };
          }

          if (childParentAttribute === "popup-close-button") {
            if (child.tagName === "BUTTON" || child.tagName === "button") {
              let parentDivId = child.parentNode.id;

              // component instance
              categoryXml += `<block type="component_instance-${child.tagName}-${child.id}"></block>`;

              Blockly.Blocks[`component_instance-${child.tagName}-${child.id}`] = {
                init: function () {
                  this.appendDummyInput().appendField(`Component Instance-${child.tagName}-${child.id}`);
                  this.appendStatementInput("Properties");
                  this.setPreviousStatement(true, null);
                  this.setNextStatement(true, null);
                  this.setColour(43);
                  this.setTooltip("Get Instance of the Component");
                },
              };

              Blockly.JavaScript[`component_instance-${child.tagName}-${child.id}` ] = function (block) {
                const properties = Blockly.JavaScript.statementToCode(block, "Properties");

                var code = `
                  var childElement${child.id} = document.querySelector("#${child.id}");
                  if(childElement${child.id}){
                    ${properties}
                  }
                `;

                return code;
              };

              // trigger button click block
              categoryXml += `<block type="trigger-click-${child.tagName}-${child.id}"></block>`;

              Blockly.Blocks[`trigger-click-${child.tagName}-${child.id}`] = {
                init: function () {
                  this.appendDummyInput().appendField(`Trigger Click-${child.tagName}-${child.id}`);                  
                  this.setPreviousStatement(true, null);
                  this.setColour(230);
                  this.setTooltip("Trigger a click event on the button programmatically.");
                  this.setHelpUrl("");
                },
              };

              Blockly.JavaScript[`trigger-click-${child.tagName}-${child.id}`] = function (block) {                
                var code = `childElement${child.id}.click();\n`;

                return code;
              };

              // js manipulation:
              categoryXml += `<block type="events-${child.tagName}-${child.id}"></block>`;

              Blockly.Blocks[`events-${child.tagName}-${child.id}`] = {
                init: function () {
                  this.appendDummyInput().appendField(`Component Events-${child.tagName}-${child.id}`);
                  this.appendDummyInput().appendField("On Event").appendField(
                    new Blockly.FieldDropdown([
                      ["Click", "click"],
                      // ["Change", "change"],
                    ]),
                    "EVENT"
                  );
                  this.appendStatementInput("DO").appendField("Do");
                  this.setPreviousStatement(true, null);
                  this.setNextStatement(true, null);
                  this.setColour(230);
                  this.setTooltip("Add event handler to the component");
                  this.setHelpUrl("");
                },
              };

              Blockly.JavaScript[`events-${child.tagName}-${child.id}`] = function (block) {
                var event = block.getFieldValue("EVENT");
                var code = "";
                var actionCode = Blockly.JavaScript.statementToCode(block, "DO");

                if (event && actionCode) {
                  code += `                    
                    childElement${child.id}.addEventListener('${event}', function(event) {
                      ${actionCode}
                    });                    
                  `;
                }

                return code;
              };
            }
          }

          categoryXml += `</category>`;
        } else {
          if (child.tagName === "TD" || child.tagName === "td") {
            console.log("td child here", child);
            categoryXml += `<category name=${child.tagName}-${child.id} colour="210">`;
            categoryXml += `<block type="${child.tagName}-${child.id}"></block>`;

            // categoryXml += `<block type="events-${child.type}-${i}"></block>`;

            Blockly.Blocks[`${child.tagName}-${child.id}`] = {
              init: function () {
                this.appendDummyInput().appendField(
                  `${child.tagName}-${child.id}`
                );
                this.setOutput(true, "String");
                this.setColour(83);
                this.setTooltip(
                  `Get the textContent of the ${child.tagName}-${child.id} `
                );
                this.setHelpUrl("");
              },
            };

            Blockly.JavaScript[`${child.tagName}-${child.id}`] = function (
              block
            ) {
              let val = child.textContent;
              const code = `'${val}'`;

              return [code, Blockly.JavaScript.ORDER_ATOMIC];
            };

            //   component instance
            categoryXml += `<block type="component_instance-${child.tagName}-${child.id}"></block>`;

            Blockly.Blocks[`component_instance-${child.tagName}-${child.id}`] =
              {
                init: function () {
                  this.appendDummyInput().appendField(
                    `component_instance-${child.tagName}-${child.id}`
                  );
                  this.appendStatementInput("Properties");
                  this.setPreviousStatement(true, null);
                  this.setNextStatement(true, null);
                  this.setColour(43);
                  this.setTooltip("Get Instance of the Component");
                },
              };

            Blockly.JavaScript[
              `component_instance-${child.tagName}-${child.id}`
            ] = function (block) {
              const properties = Blockly.JavaScript.statementToCode(
                block,
                "Properties"
              );

              var code = `
                                        var childElement${child.id} = document.querySelector("#${child.id}");
                                        if(childElement${child.id}){
                                            ${properties}
                                        }
                                        `;

              return code;
            };

            categoryXml += `<block type="manipulate-value-${child.tagName}-${child.id}"></block>`;

            Blockly.Blocks[`manipulate-value-${child.tagName}-${child.id}`] = {
              init: function () {
                this.appendDummyInput().appendField(
                  `Set Property of ${child.tagName}-${child.id}`
                );
                this.appendDummyInput()
                  .appendField("Property:")
                  .appendField(
                    new Blockly.FieldDropdown([
                      ["value", "value"],
                      ["textContent", "textContent"],
                    ]),
                    "PROPERTY"
                  );
                this.appendValueInput("VALUE")
                  .setCheck(null)
                  .appendField("Value:");
                this.setPreviousStatement(true, null);
                this.setNextStatement(true, null);
                this.setColour(172);
                this.setTooltip("Set Property of the Component");
              },
            };

            Blockly.JavaScript[
              `manipulate-value-${child.tagName}-${child.id}`
            ] = function (block) {
              const property = block.getFieldValue("PROPERTY");
              const value = Blockly.JavaScript.valueToCode(
                block,
                "VALUE",
                Blockly.JavaScript.ORDER_ATOMIC
              );

              var code = `
                    childElement${child.id}.${property} = ${value};
                    `;

              return code;
            };

            categoryXml += `<block type="custom_css_property-${child.tagName}-${child.id}"></block>`;

            //   for style manipulation:
            //   bbql
            Blockly.Blocks[`custom_css_property-${child.tagName}-${child.id}`] =
              {
                init: function () {
                  this.appendDummyInput().appendField(
                    `customise-styles-${child.tagName}-${child.id}`
                  );
                  this.appendValueInput("PROPERTY_NAME")
                    .setCheck("String")
                    .appendField("Property Name:");

                  this.appendValueInput("PROPERTY_VALUE")
                    .setCheck("String")
                    .appendField("Value:");
                  this.setPreviousStatement(true, null);
                  this.setNextStatement(true, null);
                  this.setColour(180);
                  this.setTooltip(
                    "Specify a CSS property, its type, and value."
                  );
                },
              };

            Blockly.JavaScript[
              `custom_css_property-${child.tagName}-${child.id}`
            ] = function (block) {
              const propertyName = Blockly.JavaScript.valueToCode(
                block,
                "PROPERTY_NAME",
                Blockly.JavaScript.ORDER_ATOMIC
              );

              const propertyValue = Blockly.JavaScript.valueToCode(
                block,
                "PROPERTY_VALUE",
                Blockly.JavaScript.ORDER_ATOMIC
              );

              var code = `
                                        childElement${child.id}.style[${propertyName}]=${propertyValue}
                                        `;

              return code;
            };

            //   js manipulation:
            categoryXml += `<block type="events-${child.tagName}-${child.id}"></block>`;

            //   for style manipulation:
            Blockly.Blocks[`events-${child.tagName}-${child.id}`] = {
              init: function () {
                this.appendDummyInput().appendField(
                  `Component Events-${child.tagName}-${child.id}`
                );
                this.appendDummyInput()
                  .appendField("On Event")
                  .appendField(
                    new Blockly.FieldDropdown([
                      ["Click", "click"],
                      ["Change", "change"],
                    ]),
                    "EVENT"
                  );
                this.appendStatementInput("DO").appendField("Do");
                this.setPreviousStatement(true, null);
                this.setNextStatement(true, null);
                this.setColour(230);
                this.setTooltip("Add event handler To the component");
                this.setHelpUrl("");
              },
            };

            Blockly.JavaScript[`events-${child.tagName}-${child.id}`] =
              function (block) {
                var event = block.getFieldValue("EVENT");
                var code = "";
                var actionCode = Blockly.JavaScript.statementToCode(
                  block,
                  "DO"
                );

                if (event && actionCode) {
                  code += `
                    
                        childElement${child.id}.addEventListener('${event}', function(event) {
                          ${actionCode}
                        });
                    
                    `;
                }

                return code;
              };

            categoryXml += `</category>`;
          }

          if (child.tagName === "BUTTON" || child.tagName === "button") {
            categoryXml += `<block type="${child.tagName}-${child.id}"></block>`;
            // console.log("child button::::::::::::", child);

            // categoryXml += `<block type="events-${child.type}-${i}"></block>`;

            Blockly.Blocks[`${child.tagName}-${child.id}`] = {
              init: function () {
                this.appendDummyInput().appendField(
                  `${child.tagName}-${child.id}`
                );
                this.setOutput(true, "String");
                this.setColour(83);
                this.setTooltip(
                  `Get the value of the ${child.tagName}-${child.id} button`
                );
                this.setHelpUrl("");
              },
            };

            Blockly.JavaScript[`${child.tagName}-${child.id}`] = function (
              block
            ) {
              let val = child.value;
              const code = `'${val}'`;

              return [code, Blockly.JavaScript.ORDER_ATOMIC];
            };

            //   component instance
            categoryXml += `<block type="component_instance-${child.tagName}-${child.id}"></block>`;

            Blockly.Blocks[`component_instance-${child.tagName}-${child.id}`] =
              {
                init: function () {
                  this.appendDummyInput().appendField(
                    `Component Instance-${child.tagName}-${child.id}`
                  );
                  this.appendStatementInput("Properties");
                  this.setPreviousStatement(true, null);
                  this.setNextStatement(true, null);
                  this.setColour(43);
                  this.setTooltip("Get Instance of the Component");
                },
              };

            Blockly.JavaScript[
              `component_instance-${child.tagName}-${child.id}`
            ] = function (block) {
              const properties = Blockly.JavaScript.statementToCode(
                block,
                "Properties"
              );

              var code = `
                                        var childElement${child.id} = document.querySelector("#${child.id}");
                                        if(childElement${child.id}){
                                            ${properties}
                                        }
                                        `;

              return code;
            };

            categoryXml += `<block type="custom_css_property-${child.tagName}-${child.id}"></block>`;

            //   for style manipulation:
            Blockly.Blocks[`custom_css_property-${child.tagName}-${child.id}`] =
              {
                init: function () {
                  this.appendDummyInput().appendField(
                    `custom_css_property-${child.tagName}-${child.id}`
                  );
                  this.appendValueInput("PROPERTY_NAME")
                    .setCheck("String")
                    .appendField("Property Name:");

                  this.appendValueInput("PROPERTY_VALUE")
                    .setCheck("String")
                    .appendField("Value:");
                  this.setPreviousStatement(true, null);
                  this.setNextStatement(true, null);
                  this.setColour(172);
                  this.setTooltip(
                    "Specify a CSS property, its type, and value."
                  );
                },
              };

            Blockly.JavaScript[
              `custom_css_property-${child.tagName}-${child.id}`
            ] = function (block) {
              const propertyName = Blockly.JavaScript.valueToCode(
                block,
                "PROPERTY_NAME",
                Blockly.JavaScript.ORDER_ATOMIC
              );

              const propertyValue = Blockly.JavaScript.valueToCode(
                block,
                "PROPERTY_VALUE",
                Blockly.JavaScript.ORDER_ATOMIC
              );

              var code = `
                                        childElement${child.id}.style[${propertyName}]=${propertyValue}
                                        `;

              return code;
            };

            //   js manipulation:
            categoryXml += `<block type="events-${child.tagName}-${child.id}"></block>`;

            //   for style manipulation:
            Blockly.Blocks[`events-${child.tagName}-${child.id}`] = {
              init: function () {
                this.appendDummyInput().appendField(
                  `Component Events-${child.tagName}-${child.id}`
                );
                this.appendDummyInput()
                  .appendField("On Event")
                  .appendField(
                    new Blockly.FieldDropdown([
                      ["Click", "click"],
                      ["Change", "change"],
                    ]),
                    "EVENT"
                  );
                this.appendStatementInput("DO").appendField("Do");
                this.setPreviousStatement(true, null);
                this.setNextStatement(true, null);
                this.setColour(230);
                this.setTooltip("Add event handler To the component");
                this.setHelpUrl("");
              },
            };

            Blockly.JavaScript[`events-${child.tagName}-${child.id}`] =
              function (block) {
                var event = block.getFieldValue("EVENT");
                var code = "";
                var actionCode = Blockly.JavaScript.statementToCode(
                  block,
                  "DO"
                );

                if (event && actionCode) {
                  code += `
                    
                        childElement${child.id}.addEventListener('${event}', function(event) {
                          ${actionCode}
                        });
                    
                    `;
                }

                return code;
              };

            categoryXml += `<block type="prevent-default-behaviour-${child.tagName}-${child.id}"></block>`;
            Blockly.Blocks[
              `prevent-default-behaviour-${child.tagName}-${child.id}`
            ] = {
              init: function () {
                this.appendDummyInput().appendField(
                  `prevent-default-behaviour-${child.tagName}-${child.id}`
                );
                this.setPreviousStatement(true, null);
                this.setNextStatement(true, null);
                this.setColour(230);
                this.setTooltip(
                  `prevent-default-behaviour of the ${child.tagName}-${child.id} Input`
                );
                this.setHelpUrl("");
              },
            };

            Blockly.JavaScript[
              `prevent-default-behaviour-${child.tagName}-${child.id}`
            ] = function (block) {
              const code = "event.preventDefault()";

              return code;
            };

            categoryXml += `<block type="disable-${child.tagName}-${child.id}"></block>`;

            //   for style manipulation:
            Blockly.Blocks[`disable-${child.tagName}-${child.id}`] = {
              init: function () {
                this.appendDummyInput().appendField(
                  `disable-${child.tagName}-${child.id}`
                );
                this.appendValueInput("PROPERTY_VALUE").appendField("Value:");
                this.setPreviousStatement(true, null);
                this.setNextStatement(true, null);
                this.setColour(172);
                this.setTooltip("Disable a Button");
              },
            };

            Blockly.JavaScript[`disable-${child.tagName}-${child.id}`] =
              function (block) {
                const propertyValue = Blockly.JavaScript.valueToCode(
                  block,
                  "PROPERTY_VALUE",
                  Blockly.JavaScript.ORDER_ATOMIC
                );

                var code = `
                                            childElement${child.id}.disabled=${propertyValue}
                                            `;

                return code;
              };

            //   remove events :
            categoryXml += `<block type="remove-events-${child.tagName}-${child.id}"></block>`;

            Blockly.Blocks[`remove-events-${child.tagName}-${child.id}`] = {
              init: function () {
                this.appendDummyInput().appendField("Remove Component Event");
                this.appendDummyInput()
                  .appendField("Remove Event")
                  .appendField(
                    new Blockly.FieldDropdown([
                      ["Click", "click"],
                      ["Change", "change"],
                    ]),
                    "EVENT"
                  );
                this.setPreviousStatement(true, null);
                this.setNextStatement(true, null);
                this.setColour(230);
                this.setTooltip("Remove event handler from the component");
                this.setHelpUrl("");
              },
            };

            Blockly.JavaScript[`remove-events-${child.tagName}-${child.id}`] =
              function (block) {
                var event = block.getFieldValue("EVENT");
                var code = "";

                if (event) {
                  code += `
                      // Assuming you have a reference to the element
                    
                        // Retrieve the event listener function from the element
                        var eventListener = childElement${child.id}._eventListeners['${event}'];
                
                        // Check if the event listener function exists before trying to remove it
                        if (eventListener) {
                          childElement${child.id}.removeEventListener('${event}', eventListener);
                        }
                      
                    `;
                }

                return code;
              };
          }

          if (child.id && (child.tagName === "p" || child.tagName === "P")) {
            categoryXml += `<block type="${child.tagName}-${child.id}"></block>`;

            // categoryXml += `<block type="events-${child.type}-${i}"></block>`;

            Blockly.Blocks[`${child.tagName}-${child.id}`] = {
              init: function () {
                this.appendDummyInput().appendField(
                  `${child.tagName}-${child.id}`
                );
                this.setOutput(true, "String");
                this.setColour(83);
                this.setTooltip(
                  `Get the textContent of the ${child.tagName}-${child.id} `
                );
                this.setHelpUrl("");
              },
            };

            Blockly.JavaScript[`${child.tagName}-${child.id}`] = function (
              block
            ) {
              let val = child.textContent;
              const code = `'${val}'`;

              return [code, Blockly.JavaScript.ORDER_ATOMIC];
            };

            //   component instance
            categoryXml += `<block type="component_instance-${child.tagName}-${child.id}"></block>`;

            Blockly.Blocks[`component_instance-${child.tagName}-${child.id}`] =
              {
                init: function () {
                  this.appendDummyInput().appendField(
                    `component_instance-${child.tagName}-${child.id}`
                  );
                  this.appendStatementInput("Properties");
                  this.setPreviousStatement(true, null);
                  this.setNextStatement(true, null);
                  this.setColour(43);
                  this.setTooltip("Get Instance of the Component");
                },
              };

            Blockly.JavaScript[
              `component_instance-${child.tagName}-${child.id}`
            ] = function (block) {
              const properties = Blockly.JavaScript.statementToCode(
                block,
                "Properties"
              );

              var code = `
                                        var childElement${child.id} = document.querySelector("#${child.id}");
                                        if(childElement${child.id}){
                                            ${properties}
                                        }
                                        `;

              return code;
            };

            categoryXml += `<block type="manipulate-value-${child.tagName}-${child.id}"></block>`;

            Blockly.Blocks[`manipulate-value-${child.tagName}-${child.id}`] = {
              init: function () {
                this.appendDummyInput().appendField(
                  `Set Property of ${child.tagName}-${child.id}`
                );
                this.appendDummyInput()
                  .appendField("Property:")
                  .appendField(
                    new Blockly.FieldDropdown([
                      ["value", "value"],
                      ["textContent", "textContent"],
                    ]),
                    "PROPERTY"
                  );
                this.appendValueInput("VALUE")
                  .setCheck(null)
                  .appendField("Value:");
                this.setPreviousStatement(true, null);
                this.setNextStatement(true, null);
                this.setColour(172);
                this.setTooltip("Set Property of the Component");
              },
            };

            Blockly.JavaScript[
              `manipulate-value-${child.tagName}-${child.id}`
            ] = function (block) {
              const property = block.getFieldValue("PROPERTY");
              const value = Blockly.JavaScript.valueToCode(
                block,
                "VALUE",
                Blockly.JavaScript.ORDER_ATOMIC
              );

              var code = `
                    childElement${child.id}.${property} = ${value};
                    `;

              return code;
            };

            categoryXml += `<block type="custom_css_property-${child.tagName}-${child.id}"></block>`;

            //   for style manipulation:
            //   bbql
            Blockly.Blocks[`custom_css_property-${child.tagName}-${child.id}`] =
              {
                init: function () {
                  this.appendDummyInput().appendField(
                    `customise-styles-${child.tagName}-${child.id}`
                  );
                  this.appendValueInput("PROPERTY_NAME")
                    .setCheck("String")
                    .appendField("Property Name:");

                  this.appendValueInput("PROPERTY_VALUE")
                    .setCheck("String")
                    .appendField("Value:");
                  this.setPreviousStatement(true, null);
                  this.setNextStatement(true, null);
                  this.setColour(180);
                  this.setTooltip(
                    "Specify a CSS property, its type, and value."
                  );
                },
              };

            Blockly.JavaScript[
              `custom_css_property-${child.tagName}-${child.id}`
            ] = function (block) {
              const propertyName = Blockly.JavaScript.valueToCode(
                block,
                "PROPERTY_NAME",
                Blockly.JavaScript.ORDER_ATOMIC
              );

              const propertyValue = Blockly.JavaScript.valueToCode(
                block,
                "PROPERTY_VALUE",
                Blockly.JavaScript.ORDER_ATOMIC
              );

              var code = `
                                        childElement${child.id}.style[${propertyName}]=${propertyValue}
                                        `;

              return code;
            };

            //   js manipulation:
            categoryXml += `<block type="events-${child.tagName}-${child.id}"></block>`;

            //   for style manipulation:
            Blockly.Blocks[`events-${child.tagName}-${child.id}`] = {
              init: function () {
                this.appendDummyInput().appendField(
                  `Component Events-${child.tagName}-${child.id}`
                );
                this.appendDummyInput()
                  .appendField("On Event")
                  .appendField(
                    new Blockly.FieldDropdown([
                      ["Click", "click"],
                      ["Change", "change"],
                    ]),
                    "EVENT"
                  );
                this.appendStatementInput("DO").appendField("Do");
                this.setPreviousStatement(true, null);
                this.setNextStatement(true, null);
                this.setColour(230);
                this.setTooltip("Add event handler To the component");
                this.setHelpUrl("");
              },
            };

            Blockly.JavaScript[`events-${child.tagName}-${child.id}`] =
              function (block) {
                var event = block.getFieldValue("EVENT");
                var code = "";
                var actionCode = Blockly.JavaScript.statementToCode(
                  block,
                  "DO"
                );

                if (event && actionCode) {
                  code += `
                    
                        childElement${child.id}.addEventListener('${event}', function(event) {
                          ${actionCode}
                        });
                    
                    `;
                }

                return code;
              };
          }

          if (!child.id) {
            let parentDivId = child.parentNode.id;
            categoryXml += `<block type="textContent-${parentDivId}"></block>`;

            // categoryXml += `<block type="events-${child.type}-${i}"></block>`;

            Blockly.Blocks[`textContent-${parentDivId}`] = {
              init: function () {
                this.appendDummyInput().appendField(
                  `textContent-${parentDivId}`
                );
                this.setOutput(true, "String");
                this.setColour("#b91c1c");
                this.setTooltip(
                  `Get the textContent of the textContent-${parentDivId} `
                );
                this.setHelpUrl("");
              },
            };

            Blockly.JavaScript[`textContent-${parentDivId}`] = function (
              block
            ) {
              let val = child.textContent;
              const code = `'${val}'`;

              return [code, Blockly.JavaScript.ORDER_ATOMIC];
            };

            //   component instance
            categoryXml += `<block type="component_instance-textContent-${parentDivId}"></block>`;

            Blockly.Blocks[`component_instance-textContent-${parentDivId}`] = {
              init: function () {
                this.appendDummyInput().appendField(
                  `component_instance-textContent-${parentDivId}`
                );
                this.appendStatementInput("Properties");
                this.setPreviousStatement(true, null);
                this.setNextStatement(true, null);
                this.setColour("#b91c1c");
                this.setTooltip("Get Instance of the Component");
              },
            };

            Blockly.JavaScript[
              `component_instance-textContent-${parentDivId}`
            ] = function (block) {
              const properties = Blockly.JavaScript.statementToCode(
                block,
                "Properties"
              );

              var code = `
                                        var childElement${parentDivId} = document.querySelector("#${parentDivId}");
                                        if(childElement${parentDivId}){
                                          let childElementOne${parentDivId}=childElement${parentDivId}.firstElementChild
                                            ${properties}
                                        }
                                        `;

              return code;
            };

            categoryXml += `<block type="manipulate-value-textContent-${parentDivId}"></block>`;

            Blockly.Blocks[`manipulate-value-textContent-${parentDivId}`] = {
              init: function () {
                this.appendDummyInput().appendField(
                  `Set Property of textContent-${parentDivId}`
                );
                this.appendDummyInput()
                  .appendField("Property:")
                  .appendField(
                    new Blockly.FieldDropdown([
                      ["value", "value"],
                      ["textContent", "textContent"],
                    ]),
                    "PROPERTY"
                  );
                this.appendValueInput("VALUE")
                  .setCheck(null)
                  .appendField("Value:");
                this.setPreviousStatement(true, null);
                this.setNextStatement(true, null);
                this.setColour("#b91c1c");
                this.setTooltip("Set Property of the Component");
              },
            };

            Blockly.JavaScript[`manipulate-value-textContent-${parentDivId}`] =
              function (block) {
                const property = block.getFieldValue("PROPERTY");
                const value = Blockly.JavaScript.valueToCode(
                  block,
                  "VALUE",
                  Blockly.JavaScript.ORDER_ATOMIC
                );

                var code = `
                    childElementOne${parentDivId}.${property} = ${value};
                    `;

                return code;
              };

            categoryXml += `<block type="custom_css_property-textContent-${parentDivId}"></block>`;

            //   for style manipulation:
            //   bbql
            Blockly.Blocks[`custom_css_property-textContent-${parentDivId}`] = {
              init: function () {
                this.appendDummyInput().appendField(
                  `customise-styles-textContent-${parentDivId}`
                );
                this.appendValueInput("PROPERTY_NAME")
                  .setCheck("String")
                  .appendField("Property Name:");

                this.appendValueInput("PROPERTY_VALUE")
                  .setCheck("String")
                  .appendField("Value:");
                this.setPreviousStatement(true, null);
                this.setNextStatement(true, null);
                this.setColour("#b91c1c");
                this.setTooltip("Specify a CSS property, its type, and value.");
              },
            };

            Blockly.JavaScript[
              `custom_css_property-textContent-${parentDivId}`
            ] = function (block) {
              const propertyName = Blockly.JavaScript.valueToCode(
                block,
                "PROPERTY_NAME",
                Blockly.JavaScript.ORDER_ATOMIC
              );

              const propertyValue = Blockly.JavaScript.valueToCode(
                block,
                "PROPERTY_VALUE",
                Blockly.JavaScript.ORDER_ATOMIC
              );

              var code = `
                                        childElementOne${parentDivId}.style[${propertyName}]=${propertyValue}
                                        `;

              return code;
            };

            //   js manipulation:
            categoryXml += `<block type="events-textContent-${parentDivId}"></block>`;

            //   for style manipulation:
            Blockly.Blocks[`events-textContent-${parentDivId}`] = {
              init: function () {
                this.appendDummyInput().appendField(
                  `Component Events-textContent-${parentDivId}`
                );
                this.appendDummyInput()
                  .appendField("On Event")
                  .appendField(
                    new Blockly.FieldDropdown([
                      ["Click", "click"],
                      ["Change", "change"],
                    ]),
                    "EVENT"
                  );
                this.appendStatementInput("DO").appendField("Do");
                this.setPreviousStatement(true, null);
                this.setNextStatement(true, null);
                this.setColour("#b91c1c");
                this.setTooltip("Add event handler To the component");
                this.setHelpUrl("");
              },
            };

            Blockly.JavaScript[`events-textContent-${parentDivId}`] = function (
              block
            ) {
              var event = block.getFieldValue("EVENT");
              var code = "";
              var actionCode = Blockly.JavaScript.statementToCode(block, "DO");

              if (event && actionCode) {
                code += `
                    
                        childElementOne${parentDivId}.addEventListener('${event}', function(event) {
                          ${actionCode}
                        });
                    
                    `;
              }

              return code;
            };
          }

          if (child.tagName === "div" || child.tagName === "DIV") {
            console.log("child div:::::", child);
            let typeCheck = child.getAttribute("data-gjs-type");

            if (typeCheck === "stellar-button") {
              categoryXml += `<block type="${child[0].tagName}-api_body_trait"></block>`;

              Blockly.Blocks[`${child[0].tagName}-api_body_trait`] = {
                init: function () {
                  this.appendDummyInput().appendField(
                    `Customise Traits of ${child[0].tagName}-${child.id}`
                  );
                  this.appendValueInput("PROPERTY_VALUE").appendField("Value:");
                  this.setPreviousStatement(true, null);
                  this.setNextStatement(true, null);
                  this.setColour(180);
                  this.setTooltip("Manipulate Traits of a select comp");
                },
              };

              Blockly.JavaScript[`${child[0].tagName}-api_body_trait`] =
                function (block) {
                  const propertyValue = Blockly.JavaScript.valueToCode(
                    block,
                    "PROPERTY_VALUE",
                    Blockly.JavaScript.ORDER_ATOMIC
                  );

                  var code = `
                  let subString${child.id}= window.location.href.indexOf("/editor/") !== -1 &&
                  window.location.href.indexOf("?projectId=") !== -1 ||
                window.location.href.indexOf("/large_preview/") !== -1 ||
                window.location.href.indexOf("/tab_preview/") !== -1 ||
                window.location.href.indexOf("/mobile_preview/") !== -1 ||
                window.location.href.indexOf("/fragment_editor/") !== -1;
                  if(subString${child.id}){
                    let allComponents${parentDivId} = window.top.editor.getWrapper();
                  let childCompEditor${parentDivId}=allComponents${parentDivId}.find('#${parentDivId}')[0];
                  if(childCompEditor${parentDivId}){
                    childCompEditor${parentDivId}.set( ${propertyValue});
                  }
                  }else{
                    if(typeof ${propertyValue} === 'object'){
                      Object.keys(${propertyValue}).forEach(function(key) {
                        window[key + "${child.id}"] = ${propertyValue}[key];
                    });
                    }
                  }
                                            `;
                  return code;
                };
              categoryXml += `<block type="${child[0].innerText}-${child.id}"></block>`;

              // categoryXml += `<block type="events-${child.type}-${i}"></block>`;

              Blockly.Blocks[`${child[0].innerText}-${child.id}`] = {
                init: function () {
                  this.appendDummyInput().appendField(
                    `${child.type}-${child.id}`
                  );
                  this.setOutput(true, "String");
                  this.setColour(83);
                  this.setTooltip(
                    `Get the textContent of the ${child[0].innerText}-${child.id} button`
                  );
                  this.setHelpUrl("");
                },
              };

              Blockly.JavaScript[`${child[0].innerText}-${child.id}`] =
                function (block) {
                  let val = child.value;
                  const code = `'${val}'`;

                  return [code, Blockly.JavaScript.ORDER_ATOMIC];
                };

              console.log("button::::::::::");

              //   component instance
              categoryXml += `<block type="component_instance-${child[0].innerText}-${child.id}"></block>`;

              Blockly.Blocks[
                `component_instance-${child[0].innerText}-${child.id}`
              ] = {
                init: function () {
                  this.appendDummyInput().appendField(
                    `Component Instance ${child[0].innerText}-${child.id}`
                  );
                  this.appendStatementInput("Properties");
                  this.setPreviousStatement(true, null);
                  this.setNextStatement(true, null);
                  this.setColour(43);
                  this.setTooltip("Get Instance of the Component");
                },
              };

              Blockly.JavaScript[
                `component_instance-${child[0].innerText}-${child.id}`
              ] = function (block) {
                const properties = Blockly.JavaScript.statementToCode(
                  block,
                  "Properties"
                );

                var code = `
                                        var childElement${child.id} = document.querySelector("#${child.id}");
                                       if(childElement${child.id}){
                                         ${properties}
                                       }
                                        `;

                return code;
              };

              categoryXml += `<block type="custom_css_property-${child[0].innerText}-${child.id}"></block>`;

              //   for style manipulation:
              Blockly.Blocks[
                `custom_css_property-${child[0].innerText}-${child.id}`
              ] = {
                init: function () {
                  this.appendDummyInput().appendField(
                    `Customise styles ${child[0].innerText}-${child.id}`
                  );
                  this.appendValueInput("PROPERTY_NAME")
                    .setCheck("String")
                    .appendField("Property Name:");

                  this.appendValueInput("PROPERTY_VALUE")
                    .setCheck("String")
                    .appendField("Value:");
                  this.setPreviousStatement(true, null);
                  this.setNextStatement(true, null);
                  this.setColour(180);
                  this.setTooltip(
                    "Specify a CSS property, its type, and value."
                  );
                },
              };

              Blockly.JavaScript[
                `custom_css_property-${child[0].innerText}-${child.id}`
              ] = function (block) {
                const propertyName = Blockly.JavaScript.valueToCode(
                  block,
                  "PROPERTY_NAME",
                  Blockly.JavaScript.ORDER_ATOMIC
                );

                const propertyValue = Blockly.JavaScript.valueToCode(
                  block,
                  "PROPERTY_VALUE",
                  Blockly.JavaScript.ORDER_ATOMIC
                );

                var code = `
                                        childElement${child.id}.style[${propertyName}]=${propertyValue}
                                        `;

                return code;
              };

              //   js manipulation:
              categoryXml += `<block type="events-${child.type}-${child.id}"></block>`;

              //   for style manipulation:
              Blockly.Blocks[`events-${child.type}-${child.id}`] = {
                init: function () {
                  this.appendDummyInput().appendField(
                    `Component Events ${child[0].innerText}-${child.id}`
                  );
                  this.appendDummyInput()
                    .appendField("On Event")
                    .appendField(
                      new Blockly.FieldDropdown([
                        ["Click", "click"],
                        ["Change", "change"],
                      ]),
                      "EVENT"
                    );
                  this.appendStatementInput("DO").appendField("Do");
                  this.setPreviousStatement(true, null);
                  this.setNextStatement(true, null);
                  this.setColour(230);
                  this.setTooltip("Add event handler To the component");
                  this.setHelpUrl("");
                },
              };

              Blockly.JavaScript[`events-${child[0].innerText}-${child.id}`] =
                function (block) {
                  var event = block.getFieldValue("EVENT");
                  var code = "";
                  var actionCode = Blockly.JavaScript.statementToCode(
                    block,
                    "DO"
                  );

                  if (event && actionCode) {
                    code += `
                    
                        childElement${child.id}.addEventListener('${event}', function(event) {
                          ${actionCode}
                        });
                    
                    `;
                  }

                  return code;
                };
            } else if (typeCheck === "text") {
              let parentDivId = child.id;
              categoryXml += `<block type="textContent-${parentDivId}"></block>`;

              // categoryXml += `<block type="events-${child.type}-${i}"></block>`;

              Blockly.Blocks[`textContent-${parentDivId}`] = {
                init: function () {
                  this.appendDummyInput().appendField(
                    `textContent-${parentDivId}`
                  );
                  this.setOutput(true, "String");
                  this.setColour(83);
                  this.setTooltip(
                    `Get the textContent of the textContent-${parentDivId} `
                  );
                  this.setHelpUrl("");
                },
              };

              Blockly.JavaScript[`textContent-${parentDivId}`] = function (
                block
              ) {
                let val = child.textContent;
                const code = `'${val}'`;

                return [code, Blockly.JavaScript.ORDER_ATOMIC];
              };

              //   component instance
              categoryXml += `<block type="component_instance-textContent-${parentDivId}"></block>`;

              Blockly.Blocks[`component_instance-textContent-${parentDivId}`] =
                {
                  init: function () {
                    this.appendDummyInput().appendField(
                      `component_instance-textContent-${parentDivId}`
                    );
                    this.appendStatementInput("Properties");
                    this.setPreviousStatement(true, null);
                    this.setNextStatement(true, null);
                    this.setColour(43);
                    this.setTooltip("Get Instance of the Component");
                  },
                };

              Blockly.JavaScript[
                `component_instance-textContent-${parentDivId}`
              ] = function (block) {
                const properties = Blockly.JavaScript.statementToCode(
                  block,
                  "Properties"
                );

                var code = `
                                          var childElement${parentDivId} = document.querySelector("#${parentDivId}");
                                          if(childElement${parentDivId}){
                                            ${properties}
                                              
                                          }
                                          `;

                return code;
              };

              categoryXml += `<block type="manipulate-value-textContent-${parentDivId}"></block>`;

              Blockly.Blocks[`manipulate-value-textContent-${parentDivId}`] = {
                init: function () {
                  this.appendDummyInput().appendField(
                    `Set Property of textContent-${parentDivId}`
                  );
                  this.appendDummyInput()
                    .appendField("Property:")
                    .appendField(
                      new Blockly.FieldDropdown([
                        ["value", "value"],
                        ["textContent", "textContent"],
                      ]),
                      "PROPERTY"
                    );
                  this.appendValueInput("VALUE")
                    .setCheck(null)
                    .appendField("Value:");
                  this.setPreviousStatement(true, null);
                  this.setNextStatement(true, null);
                  this.setColour(172);
                  this.setTooltip("Set Property of the Component");
                },
              };

              Blockly.JavaScript[
                `manipulate-value-textContent-${parentDivId}`
              ] = function (block) {
                const property = block.getFieldValue("PROPERTY");
                const value = Blockly.JavaScript.valueToCode(
                  block,
                  "VALUE",
                  Blockly.JavaScript.ORDER_ATOMIC
                );

                var code = `
                      childElement${parentDivId}.${property} = ${value};
                      `;

                return code;
              };

              categoryXml += `<block type="custom_css_property-textContent-${parentDivId}"></block>`;

              Blockly.Blocks[`custom_css_property-textContent-${parentDivId}`] =
                {
                  init: function () {
                    this.appendDummyInput().appendField(
                      `customise-styles-textContent-${parentDivId}`
                    );
                    this.appendValueInput("PROPERTY_NAME")
                      .setCheck("String")
                      .appendField("Property Name:");

                    this.appendValueInput("PROPERTY_VALUE")
                      .setCheck("String")
                      .appendField("Value:");
                    this.setPreviousStatement(true, null);
                    this.setNextStatement(true, null);
                    this.setColour(30);
                    this.setTooltip(
                      "Specify a CSS property, its type, and value."
                    );
                  },
                };

              Blockly.JavaScript[
                `custom_css_property-textContent-${parentDivId}`
              ] = function (block) {
                const propertyName = Blockly.JavaScript.valueToCode(
                  block,
                  "PROPERTY_NAME",
                  Blockly.JavaScript.ORDER_ATOMIC
                );

                const propertyValue = Blockly.JavaScript.valueToCode(
                  block,
                  "PROPERTY_VALUE",
                  Blockly.JavaScript.ORDER_ATOMIC
                );

                var code = `
                                          childElement${parentDivId}.style[${propertyName}]=${propertyValue}
                                          `;

                return code;
              };

              //   js manipulation:
              categoryXml += `<block type="events-textContent-${parentDivId}"></block>`;

              //   for style manipulation:
              Blockly.Blocks[`events-textContent-${parentDivId}`] = {
                init: function () {
                  this.appendDummyInput().appendField(
                    `Component Events-textContent-${parentDivId}`
                  );
                  this.appendDummyInput()
                    .appendField("On Event")
                    .appendField(
                      new Blockly.FieldDropdown([
                        ["Click", "click"],
                        ["Change", "change"],
                      ]),
                      "EVENT"
                    );
                  this.appendStatementInput("DO").appendField("Do");
                  this.setPreviousStatement(true, null);
                  this.setNextStatement(true, null);
                  this.setColour(30);
                  this.setTooltip("Add event handler To the component");
                  this.setHelpUrl("");
                },
              };

              Blockly.JavaScript[`events-textContent-${parentDivId}`] =
                function (block) {
                  var event = block.getFieldValue("EVENT");
                  var code = "";
                  var actionCode = Blockly.JavaScript.statementToCode(
                    block,
                    "DO"
                  );

                  if (event && actionCode) {
                    code += `
                      
                          childElement${parentDivId}.addEventListener('${event}', function(event) {
                            ${actionCode}
                          });
                      
                      `;
                  }

                  return code;
                };
            } else {
              console.log("child div else:::::", child);
              categoryXml += `<block type="${child.type}-${child.id}"></block>`;

              // categoryXml += `<block type="events-${child.type}-${i}"></block>`;

              Blockly.Blocks[`${child.type}-${child.id}`] = {
                init: function () {
                  this.appendDummyInput().appendField(
                    `${child.type}-${child.id}`
                  );
                  this.setOutput(true, "String");
                  this.setColour(83);
                  this.setTooltip(
                    `Get the textContent of the ${child.type}-${child.id} button`
                  );
                  this.setHelpUrl("");
                },
              };

              Blockly.JavaScript[`${child.type}-${child.id}`] = function (
                block
              ) {
                let val = child.value;
                const code = `'${val}'`;

                return [code, Blockly.JavaScript.ORDER_ATOMIC];
              };

              //   component instance
              categoryXml += `<block type="component_instance-${child.type}-${child.id}"></block>`;

              Blockly.Blocks[`component_instance-${child.type}-${child.id}`] = {
                init: function () {
                  this.appendDummyInput().appendField(
                    `component_instance-${child.type}-${child.id}`
                  );
                  this.appendStatementInput("Properties");
                  this.setPreviousStatement(true, null);
                  this.setNextStatement(true, null);
                  this.setColour(43);
                  this.setTooltip("Get Instance of the Component");
                },
              };

              Blockly.JavaScript[
                `component_instance-${child.type}-${child.id}`
              ] = function (block) {
                const properties = Blockly.JavaScript.statementToCode(
                  block,
                  "Properties"
                );

                var code = `
                                        var childElement${child.id} = document.querySelector("#${child.id}");
                                       if(childElement${child.id}){
                                         ${properties}
                                       }
                                        `;

                return code;
              };

              categoryXml += `<block type="custom_css_property-${child.type}-${child.id}"></block>`;

              //   for style manipulation:
              Blockly.Blocks[`custom_css_property-${child.type}-${child.id}`] =
                {
                  init: function () {
                    this.appendValueInput("PROPERTY_NAME")
                      .setCheck("String")
                      .appendField("Property Name:");

                    this.appendValueInput("PROPERTY_VALUE")
                      .setCheck("String")
                      .appendField("Value:");
                    this.setPreviousStatement(true, null);
                    this.setNextStatement(true, null);
                    this.setColour(180);
                    this.setTooltip(
                      "Specify a CSS property, its type, and value."
                    );
                  },
                };

              Blockly.JavaScript[
                `custom_css_property-${child.type}-${child.id}`
              ] = function (block) {
                const propertyName = Blockly.JavaScript.valueToCode(
                  block,
                  "PROPERTY_NAME",
                  Blockly.JavaScript.ORDER_ATOMIC
                );

                const propertyValue = Blockly.JavaScript.valueToCode(
                  block,
                  "PROPERTY_VALUE",
                  Blockly.JavaScript.ORDER_ATOMIC
                );

                var code = `
                                        childElement${child.id}.style[${propertyName}]=${propertyValue}
                                        `;

                return code;
              };

              //   js manipulation:
              categoryXml += `<block type="events-${child.type}-${child.id}"></block>`;

              //   for style manipulation:
              Blockly.Blocks[`events-${child.type}-${child.id}`] = {
                init: function () {
                  this.appendDummyInput().appendField(
                    `events-${child.type}-${child.id}`
                  );
                  this.appendDummyInput()
                    .appendField("On Event")
                    .appendField(
                      new Blockly.FieldDropdown([
                        ["Click", "click"],
                        ["Change", "change"],
                      ]),
                      "EVENT"
                    );
                  this.appendStatementInput("DO").appendField("Do");
                  this.setPreviousStatement(true, null);
                  this.setNextStatement(true, null);
                  this.setColour(230);
                  this.setTooltip("Add event handler To the component");
                  this.setHelpUrl("");
                },
              };

              Blockly.JavaScript[`events-${child.type}-${child.id}`] =
                function (block) {
                  var event = block.getFieldValue("EVENT");
                  var code = "";
                  var actionCode = Blockly.JavaScript.statementToCode(
                    block,
                    "DO"
                  );

                  if (event && actionCode) {
                    code += `
                    
                        childElement${child.id}.addEventListener('${event}', function(event) {
                          ${actionCode}
                        });
                    
                    `;
                  }

                  return code;
                };
            }
          }

          if (child.tagName === "INPUT" && child.id) {
            if (child.type === "range") {
              console.log("range", child);

              categoryXml += `<block type="${child.name}-${child.id}"></block>`;

              Blockly.Blocks[`${child.name}-${child.id}`] = {
                init: function () {
                  this.appendDummyInput().appendField(
                    `${child.name}-${child.id}`
                  );
                  this.setOutput(true, "String");
                  this.setColour(83);
                  this.setTooltip(
                    `Get the value of the ${child.name}-${child.id} Input`
                  );
                  this.setHelpUrl("");
                },
              };

              Blockly.JavaScript[`${child.name}-${child.id}`] = function (
                block
              ) {
                let val = child.value;
                const code = `'${val}'`;

                return [code, Blockly.JavaScript.ORDER_ATOMIC];
              };

              //   component instance
              categoryXml += `<block type="component_instance-${child.name}-${child.id}"></block>`;

              Blockly.Blocks[`component_instance-${child.name}-${child.id}`] = {
                init: function () {
                  this.appendDummyInput().appendField(
                    `Component Instance of ${child.name}-${child.id}`
                  );
                  this.appendStatementInput("Properties");
                  this.setPreviousStatement(true, null);
                  this.setNextStatement(true, null);
                  this.setColour(43);
                  this.setTooltip("Get Instance of the Component");
                },
              };

              Blockly.JavaScript[
                `component_instance-${child.name}-${child.id}`
              ] = function (block) {
                const properties = Blockly.JavaScript.statementToCode(
                  block,
                  "Properties"
                );

                var code = `
                                        var childElement${child.id} = document.querySelector("#${child.id}");
                                        if(childElement${child.id}){
                                             ${properties}
                                        }
                                        `;

                return code;
              };

              categoryXml += `<block type="manipulate-value-${child.name}-${child.id}"></block>`;

              Blockly.Blocks[`manipulate-value-${child.name}-${child.id}`] = {
                init: function () {
                  this.appendDummyInput().appendField(
                    `Set Property of ${child.name}-${child.id}`
                  );
                  this.appendDummyInput()
                    .appendField("Property:")
                    .appendField(
                      new Blockly.FieldDropdown([
                        ["value", "value"],
                        ["textContent", "textContent"],
                      ]),
                      "PROPERTY"
                    );
                  this.appendValueInput("VALUE")
                    .setCheck(null)
                    .appendField("Value:");
                  this.setPreviousStatement(true, null);
                  this.setNextStatement(true, null);
                  this.setColour(160);
                  this.setTooltip("Set Property of the Component");
                },
              };

              Blockly.JavaScript[`manipulate-value-${child.name}-${child.id}`] =
                function (block) {
                  const property = block.getFieldValue("PROPERTY");
                  const value = Blockly.JavaScript.valueToCode(
                    block,
                    "VALUE",
                    Blockly.JavaScript.ORDER_ATOMIC
                  );

                  var code = `
                              childElement${child.id}.${property} = ${value};
                              `;

                  return code;
                };

              categoryXml += `<block type="custom_css_property-${child.name}-${child.id}"></block>`;

              //   for style manipulation:
              Blockly.Blocks[`custom_css_property-${child.name}-${child.id}`] =
                {
                  init: function () {
                    this.appendDummyInput().appendField(
                      `Customise styles of ${child.name}-${child.id}`
                    );
                    this.appendValueInput("PROPERTY_NAME")
                      .setCheck("String")
                      .appendField("Property Name:");

                    this.appendValueInput("PROPERTY_VALUE")
                      .setCheck("String")
                      .appendField("Value:");
                    this.setPreviousStatement(true, null);
                    this.setNextStatement(true, null);
                    this.setColour(180);
                    this.setTooltip(
                      "Specify a CSS property, its type, and value."
                    );
                  },
                };

              Blockly.JavaScript[
                `custom_css_property-${child.name}-${child.id}`
              ] = function (block) {
                const propertyName = Blockly.JavaScript.valueToCode(
                  block,
                  "PROPERTY_NAME",
                  Blockly.JavaScript.ORDER_ATOMIC
                );

                const propertyValue = Blockly.JavaScript.valueToCode(
                  block,
                  "PROPERTY_VALUE",
                  Blockly.JavaScript.ORDER_ATOMIC
                );

                var code = `
                                        childElement${child.id}.style[${propertyName}]=${propertyValue}
                                        `;

                return code;
              };

              //   js manipulation:
              categoryXml += `<block type="events-${child.name}-${child.id}"></block>`;

              //   for style manipulation:
              Blockly.Blocks[`events-${child.name}-${child.id}`] = {
                init: function () {
                  this.appendDummyInput().appendField(
                    `Component Events for ${child.name}-${child.id}`
                  );
                  this.appendDummyInput()
                    .appendField("On Event")
                    .appendField(
                      new Blockly.FieldDropdown([
                        ["Click", "click"],
                        ["Change", "change"],
                      ]),
                      "EVENT"
                    );
                  this.appendStatementInput("DO").appendField("Do");
                  this.setPreviousStatement(true, null);
                  this.setNextStatement(true, null);
                  this.setColour(230);
                  this.setTooltip("Add event handler To the component");
                  this.setHelpUrl("");
                },
              };

              Blockly.JavaScript[`events-${child.name}-${child.id}`] =
                function (block) {
                  var event = block.getFieldValue("EVENT");
                  var code = "";
                  var actionCode = Blockly.JavaScript.statementToCode(
                    block,
                    "DO"
                  );

                  if (event && actionCode) {
                    code += `
                    
                        childElement${child.id}.addEventListener('${event}', function(event) {
                          ${actionCode}
                        });
                    
                    `;
                  }

                  return code;
                };

              categoryXml += `<block type="target-property-${child.name}-${child.id}"></block>`;
              Blockly.Blocks[`target-property-${child.name}-${child.id}`] = {
                init: function () {
                  this.appendDummyInput().appendField(
                    `target-property-${child.name}-${child.id}`
                  );
                  this.setOutput(true, "String");
                  this.setColour(230);
                  this.setTooltip(
                    `Get the event value of the ${child.name}-${child.id} Input`
                  );
                  this.setHelpUrl("");
                },
              };

              Blockly.JavaScript[`target-property-${child.name}-${child.id}`] =
                function (block) {
                  const code = "event.target.value";

                  return [code, Blockly.JavaScript.ORDER_ATOMIC];
                };

              categoryXml += `<block type="disable_component-${child.name}-${child.id}"></block>`;
              Blockly.Blocks[`disable_component-${child.name}-${child.id}`] = {
                init: function () {
                  this.appendDummyInput().appendField(
                    `Disable ${child.name}-${child.id}`
                  );
                  this.setPreviousStatement(true, null);
                  this.setNextStatement(true, null);
                  this.setColour(230);
                  this.setTooltip("Disable the Component");
                },
              };

              Blockly.JavaScript[
                `disable_component-${child.name}-${child.id}`
              ] = function (block) {
                var code = `
                               
                                    childElement${child.id}.disabled = true;
                                
                            `;

                return code;
              };

              categoryXml += `<block type="combined_change_event_block-${child.name}-${child.id}"></block>`;

              Blockly.Blocks[
                `combined_change_event_block-${child.name}-${child.id}`
              ] = {
                init: function () {
                  this.appendDummyInput().appendField(
                    `Combined Change Event ${child.name}-${child.id}`
                  );
                  this.appendValueInput("RES_VAR")
                    .setCheck(null)
                    .appendField("Store Target Value In Variable:");
                  this.setPreviousStatement(true, null);
                  this.setNextStatement(true, null);
                  this.setColour(43);
                  this.setTooltip("Store Target Value In Variable");
                },
              };

              Blockly.JavaScript[
                `combined_change_event_block-${child.name}-${child.id}`
              ] = function (block) {
                const resVar = Blockly.JavaScript.valueToCode(
                  block,
                  "RES_VAR",
                  Blockly.JavaScript.ORDER_ATOMIC
                );

                var code = `
                                          var childElement${child.id} = document.querySelector("#${child.id}");
                                          if(childElement${child.id}){
                                               childElement${child.id}.addEventListener('change',function(event){
                                                 ${resVar} = event.target.value
                                               })
                                          }
                                          `;

                return code;
              };
            } else if (child.type === "date") {
              console.log("child input");
              categoryXml += `<block type="${child.tagName}-${child.id}"></block>`;

              Blockly.Blocks[`${child.tagName}-${child.id}`] = {
                init: function () {
                  this.appendDummyInput().appendField(
                    `${child.tagName}-${child.id}`
                  );
                  this.setOutput(true, "String");
                  this.setColour(83);
                  this.setTooltip(
                    `Get the value of the ${child.tagName}-${child.id} Input`
                  );
                  this.setHelpUrl("");
                },
              };

              Blockly.JavaScript[`${child.tagName}-${child.id}`] = function (
                block
              ) {
                let val = child.value;
                const code = `'${val}'`;

                return [code, Blockly.JavaScript.ORDER_ATOMIC];
              };

              //   component instance
              categoryXml += `<block type="component_instance-${child.tagName}-${child.id}"></block>`;

              Blockly.Blocks[
                `component_instance-${child.tagName}-${child.id}`
              ] = {
                init: function () {
                  this.appendDummyInput().appendField(
                    `Component Instance of ${child.tagName}-${child.id}`
                  );
                  this.appendStatementInput("Properties");
                  this.setPreviousStatement(true, null);
                  this.setNextStatement(true, null);
                  this.setColour(43);
                  this.setTooltip("Get Instance of the Component");
                },
              };

              Blockly.JavaScript[
                `component_instance-${child.tagName}-${child.id}`
              ] = function (block) {
                const properties = Blockly.JavaScript.statementToCode(
                  block,
                  "Properties"
                );

                var code = `
                                        var childElement${child.id} = document.querySelector("#${child.id}");
                                        if(childElement${child.id}){
                                             ${properties}
                                        }
                                        `;

                return code;
              };

              categoryXml += `<block type="manipulate-value-${child.tagName}-${child.id}"></block>`;

              Blockly.Blocks[`manipulate-value-${child.tagName}-${child.id}`] =
                {
                  init: function () {
                    this.appendDummyInput().appendField(
                      `Set Property of ${child.tagName}-${child.id}`
                    );
                    this.appendValueInput("VALUE")
                      .setCheck(null)
                      .appendField("Value:");
                    this.setPreviousStatement(true, null);
                    this.setNextStatement(true, null);
                    this.setColour(160);
                    this.setTooltip("Set Property of the Component");
                  },
                };

              Blockly.JavaScript[
                `manipulate-value-${child.tagName}-${child.id}`
              ] = function (block) {
                const value = Blockly.JavaScript.valueToCode(
                  block,
                  "VALUE",
                  Blockly.JavaScript.ORDER_ATOMIC
                );

                var code = `
                              childElement${child.id}.valueAsDate = ${value};
                              `;

                return code;
              };

              categoryXml += `<block type="custom_css_property-${child.tagName}-${child.id}"></block>`;

              //   for style manipulation:
              Blockly.Blocks[
                `custom_css_property-${child.tagName}-${child.id}`
              ] = {
                init: function () {
                  this.appendDummyInput().appendField(
                    `Customise styles of ${child.tagName}-${child.id}`
                  );
                  this.appendValueInput("PROPERTY_NAME")
                    .setCheck("String")
                    .appendField("Property Name:");

                  this.appendValueInput("PROPERTY_VALUE")
                    .setCheck("String")
                    .appendField("Value:");
                  this.setPreviousStatement(true, null);
                  this.setNextStatement(true, null);
                  this.setColour(180);
                  this.setTooltip(
                    "Specify a CSS property, its type, and value."
                  );
                },
              };

              Blockly.JavaScript[
                `custom_css_property-${child.tagName}-${child.id}`
              ] = function (block) {
                const propertyName = Blockly.JavaScript.valueToCode(
                  block,
                  "PROPERTY_NAME",
                  Blockly.JavaScript.ORDER_ATOMIC
                );

                const propertyValue = Blockly.JavaScript.valueToCode(
                  block,
                  "PROPERTY_VALUE",
                  Blockly.JavaScript.ORDER_ATOMIC
                );

                var code = `
                                        childElement${child.id}.style[${propertyName}]=${propertyValue}
                                        `;

                return code;
              };

              //   js manipulation:
              categoryXml += `<block type="events-${child.tagName}-${child.id}"></block>`;

              //   for style manipulation:
              Blockly.Blocks[`events-${child.tagName}-${child.id}`] = {
                init: function () {
                  this.appendDummyInput().appendField(
                    `Component Events for ${child.tagName}-${child.id}`
                  );
                  this.appendDummyInput()
                    .appendField("On Event")
                    .appendField(
                      new Blockly.FieldDropdown([
                        ["Click", "click"],
                        ["Change", "change"],
                      ]),
                      "EVENT"
                    );
                  this.appendStatementInput("DO").appendField("Do");
                  this.setPreviousStatement(true, null);
                  this.setNextStatement(true, null);
                  this.setColour(230);
                  this.setTooltip("Add event handler To the component");
                  this.setHelpUrl("");
                },
              };

              Blockly.JavaScript[`events-${child.tagName}-${child.id}`] =
                function (block) {
                  var event = block.getFieldValue("EVENT");
                  var code = "";
                  var actionCode = Blockly.JavaScript.statementToCode(
                    block,
                    "DO"
                  );

                  if (event && actionCode) {
                    code += `
                    
                        childElement${child.id}.addEventListener('${event}', function(event) {
                          ${actionCode}
                        });
                    
                    `;
                  }

                  return code;
                };

              categoryXml += `<block type="target-property-${child.tagName}-${child.id}"></block>`;
              Blockly.Blocks[`target-property-${child.tagName}-${child.id}`] = {
                init: function () {
                  this.appendDummyInput().appendField(
                    `target-property-${child.tagName}-${child.id}`
                  );
                  this.setOutput(true, "String");
                  this.setColour(230);
                  this.setTooltip(
                    `Get the event value of the ${child.tagName}-${child.id} Input`
                  );
                  this.setHelpUrl("");
                },
              };

              Blockly.JavaScript[
                `target-property-${child.tagName}-${child.id}`
              ] = function (block) {
                const code = "event.target.value";

                return [code, Blockly.JavaScript.ORDER_ATOMIC];
              };

              categoryXml += `<block type="disable_component-${child.tagName}-${child.id}"></block>`;
              Blockly.Blocks[`disable_component-${child.tagName}-${child.id}`] =
                {
                  init: function () {
                    this.appendDummyInput().appendField(
                      `Disable ${child.tagName}-${child.id}`
                    );
                    this.setPreviousStatement(true, null);
                    this.setNextStatement(true, null);
                    this.setColour(230);
                    this.setTooltip("Disable the Component");
                  },
                };

              Blockly.JavaScript[
                `disable_component-${child.tagName}-${child.id}`
              ] = function (block) {
                var code = `
                               
                                    childElement${child.id}.disabled = true;
                                
                            `;

                return code;
              };

              categoryXml += `<block type="clear-value-${child.tagName}-${child.id}"></block>`;

              Blockly.Blocks[`clear-value-${child.tagName}-${child.id}`] = {
                init: function () {
                  this.appendDummyInput().appendField(
                    `Clear Selection of ${child.tagName}-${child.id}`
                  );
                  this.setPreviousStatement(true, null);
                  this.setNextStatement(true, null);
                  this.setColour(160);
                  this.setTooltip("Clear Selection of the Component");
                },
              };

              Blockly.JavaScript[`clear-value-${child.tagName}-${child.id}`] =
                function (block) {
                  var code = `
                              childElement${child.id}.value =" ";
                              `;

                  return code;
                };

              categoryXml += `<block type="combined_change_event_block-${child.name}-${child.id}"></block>`;

              Blockly.Blocks[
                `combined_change_event_block-${child.name}-${child.id}`
              ] = {
                init: function () {
                  this.appendDummyInput().appendField(
                    `Combined Change Event ${child.name}-${child.id}`
                  );
                  this.appendValueInput("RES_VAR")
                    .setCheck(null)
                    .appendField("Store Target Value In Variable:");
                  this.setPreviousStatement(true, null);
                  this.setNextStatement(true, null);
                  this.setColour(43);
                  this.setTooltip("Store Target Value In Variable");
                },
              };

              Blockly.JavaScript[
                `combined_change_event_block-${child.name}-${child.id}`
              ] = function (block) {
                const resVar = Blockly.JavaScript.valueToCode(
                  block,
                  "RES_VAR",
                  Blockly.JavaScript.ORDER_ATOMIC
                );

                var code = `
                                            var childElement${child.id} = document.querySelector("#${child.id}");
                                            if(childElement${child.id}){
                                                 childElement${child.id}.addEventListener('change',function(event){
                                                   ${resVar} = event.target.value
                                                 })
                                            }
                                            `;

                return code;
              };
            } else if (child.type === "radio" || child.type === "checkbox") {
              console.log("child input");
              categoryXml += `<block type="isChecked-${child.tagName}-${child.id}"></block>`;

              Blockly.Blocks[`isChecked-${child.tagName}-${child.id}`] = {
                init: function () {
                  this.appendDummyInput().appendField(
                    `${child.tagName}-${child.id}`
                  );
                  this.setOutput(true, "String");
                  this.setColour(83);
                  this.setTooltip(
                    `Get the Checked Property of the ${child.tagName}-${child.id} Input`
                  );
                  this.setHelpUrl("");
                },
              };

              Blockly.JavaScript[`isChecked-${child.tagName}-${child.id}`] =
                function (block) {
                  let val = child.checked;
                  const code = `${val}`;

                  return [code, Blockly.JavaScript.ORDER_ATOMIC];
                };

              //   component instance
              categoryXml += `<block type="component_instance-${child.tagName}-${child.id}"></block>`;

              Blockly.Blocks[
                `component_instance-${child.tagName}-${child.id}`
              ] = {
                init: function () {
                  this.appendDummyInput().appendField(
                    `Component Instance of ${child.tagName}-${child.id}`
                  );
                  this.appendStatementInput("Properties");
                  this.setPreviousStatement(true, null);
                  this.setNextStatement(true, null);
                  this.setColour(43);
                  this.setTooltip("Get Instance of the Component");
                },
              };

              Blockly.JavaScript[
                `component_instance-${child.tagName}-${child.id}`
              ] = function (block) {
                const properties = Blockly.JavaScript.statementToCode(
                  block,
                  "Properties"
                );

                var code = `
                                        var childElement${child.id} = document.querySelector("#${child.id}");
                                        if(childElement${child.id}){
                                             ${properties}
                                        }
                                        `;

                return code;
              };

              categoryXml += `<block type="manipulate-value-${child.tagName}-${child.id}"></block>`;

              Blockly.Blocks[`manipulate-value-${child.tagName}-${child.id}`] =
                {
                  init: function () {
                    this.appendDummyInput().appendField(
                      `Set Property of ${child.tagName}-${child.id}`
                    );
                    this.appendDummyInput()
                      .appendField("Property:")
                      .appendField(
                        new Blockly.FieldDropdown([
                          ["value", "value"],
                          ["textContent", "textContent"],
                          ["checked", "checked"],
                        ]),
                        "PROPERTY"
                      );
                    this.appendValueInput("VALUE")
                      .setCheck(null)
                      .appendField("Value:");
                    this.setPreviousStatement(true, null);
                    this.setNextStatement(true, null);
                    this.setColour(172);
                    this.setTooltip("Set Property of the Component");
                  },
                };

              Blockly.JavaScript[
                `manipulate-value-${child.tagName}-${child.id}`
              ] = function (block) {
                const property = block.getFieldValue("PROPERTY");
                const value = Blockly.JavaScript.valueToCode(
                  block,
                  "VALUE",
                  Blockly.JavaScript.ORDER_ATOMIC
                );

                var code = `
                              childElement${child.id}.${property} = ${value};
                              `;

                return code;
              };

              categoryXml += `<block type="custom_css_property-${child.tagName}-${child.id}"></block>`;

              //   for style manipulation:
              Blockly.Blocks[
                `custom_css_property-${child.tagName}-${child.id}`
              ] = {
                init: function () {
                  this.appendDummyInput().appendField(
                    `Customise styles of ${child.tagName}-${child.id}`
                  );
                  this.appendValueInput("PROPERTY_NAME")
                    .setCheck("String")
                    .appendField("Property Name:");

                  this.appendValueInput("PROPERTY_VALUE")
                    .setCheck("String")
                    .appendField("Value:");
                  this.setPreviousStatement(true, null);
                  this.setNextStatement(true, null);
                  this.setColour(27);
                  this.setTooltip(
                    "Specify a CSS property, its type, and value."
                  );
                },
              };

              Blockly.JavaScript[
                `custom_css_property-${child.tagName}-${child.id}`
              ] = function (block) {
                const propertyName = Blockly.JavaScript.valueToCode(
                  block,
                  "PROPERTY_NAME",
                  Blockly.JavaScript.ORDER_ATOMIC
                );

                const propertyValue = Blockly.JavaScript.valueToCode(
                  block,
                  "PROPERTY_VALUE",
                  Blockly.JavaScript.ORDER_ATOMIC
                );

                var code = `
                                        childElement${child.id}.style[${propertyName}]=${propertyValue}
                                        `;

                return code;
              };

              //   js manipulation:
              categoryXml += `<block type="events-${child.tagName}-${child.id}"></block>`;

              //   for style manipulation:
              Blockly.Blocks[`events-${child.tagName}-${child.id}`] = {
                init: function () {
                  this.appendDummyInput().appendField(
                    `Component Events for ${child.tagName}-${child.id}`
                  );
                  this.appendDummyInput()
                    .appendField("On Event")
                    .appendField(
                      new Blockly.FieldDropdown([
                        ["Click", "click"],
                        ["Change", "change"],
                      ]),
                      "EVENT"
                    );
                  this.appendStatementInput("DO").appendField("Do");
                  this.setPreviousStatement(true, null);
                  this.setNextStatement(true, null);
                  this.setColour(262);
                  this.setTooltip("Add event handler To the component");
                  this.setHelpUrl("");
                },
              };

              Blockly.JavaScript[`events-${child.tagName}-${child.id}`] =
                function (block) {
                  var event = block.getFieldValue("EVENT");
                  var code = "";
                  var actionCode = Blockly.JavaScript.statementToCode(
                    block,
                    "DO"
                  );

                  if (event && actionCode) {
                    code += `
                    
                        childElement${child.id}.addEventListener('${event}', function(event) {
                          ${actionCode}
                        });
                    
                    `;
                  }

                  return code;
                };

              categoryXml += `<block type="target-checked-${child.tagName}-${child.id}"></block>`;
              Blockly.Blocks[`target-checked-${child.tagName}-${child.id}`] = {
                init: function () {
                  this.appendDummyInput().appendField(
                    `target-checked-${child.tagName}-${child.id}`
                  );
                  this.setOutput(true, "String");
                  this.setColour(142);
                  this.setTooltip(
                    `Get the event value of the ${child.tagName}-${child.id} Input`
                  );
                  this.setHelpUrl("");
                },
              };

              Blockly.JavaScript[
                `target-checked-${child.tagName}-${child.id}`
              ] = function (block) {
                const code = "event.target.checked";

                return [code, Blockly.JavaScript.ORDER_ATOMIC];
              };

              categoryXml += `<block type="target-property-${child.tagName}-${child.id}"></block>`;
              Blockly.Blocks[`target-property-${child.tagName}-${child.id}`] = {
                init: function () {
                  this.appendDummyInput().appendField(
                    `target-property-${child.tagName}-${child.id}`
                  );
                  this.setOutput(true, "String");
                  this.setColour(142);
                  this.setTooltip(
                    `Get the event value of the ${child.tagName}-${child.id} Input`
                  );
                  this.setHelpUrl("");
                },
              };

              Blockly.JavaScript[
                `target-property-${child.tagName}-${child.id}`
              ] = function (block) {
                const code = "event.target.value";

                return [code, Blockly.JavaScript.ORDER_ATOMIC];
              };

              categoryXml += `<block type="disable_component-${child.tagName}-${child.id}"></block>`;
              Blockly.Blocks[`disable_component-${child.tagName}-${child.id}`] =
                {
                  init: function () {
                    this.appendDummyInput().appendField(
                      `Disable ${child.tagName}-${child.id}`
                    );
                    this.setPreviousStatement(true, null);
                    this.setNextStatement(true, null);
                    this.setColour(0);
                    this.setTooltip("Disable the Component");
                  },
                };

              Blockly.JavaScript[
                `disable_component-${child.tagName}-${child.id}`
              ] = function (block) {
                var code = `
                               
                                    childElement${child.id}.disabled = true;
                                
                            `;

                return code;
              };

              categoryXml += `<block type="combined_change_event_block-${child.name}-${child.id}"></block>`;

              Blockly.Blocks[
                `combined_change_event_block-${child.name}-${child.id}`
              ] = {
                init: function () {
                  this.appendDummyInput().appendField(
                    `Combined Change Event ${child.name}-${child.id}`
                  );
                  this.appendValueInput("RES_VAR")
                    .setCheck(null)
                    .appendField("Store Target Value In Variable:");
                  this.setPreviousStatement(true, null);
                  this.setNextStatement(true, null);
                  this.setColour(43);
                  this.setTooltip("Store Target Value In Variable");
                },
              };

              Blockly.JavaScript[
                `combined_change_event_block-${child.name}-${child.id}`
              ] = function (block) {
                const resVar = Blockly.JavaScript.valueToCode(
                  block,
                  "RES_VAR",
                  Blockly.JavaScript.ORDER_ATOMIC
                );

                var code = `
                                            var childElement${child.id} = document.querySelector("#${child.id}");
                                            if(childElement${child.id}){
                                                 childElement${child.id}.addEventListener('change',function(event){
                                                   ${resVar} = event.target.checked
                                                 })
                                            }
                                            `;

                return code;
              };
            } else if (child.type === "time") {
              console.log("child input");
              categoryXml += `<block type="${child.tagName}-${child.id}"></block>`;

              Blockly.Blocks[`${child.tagName}-${child.id}`] = {
                init: function () {
                  this.appendDummyInput().appendField(
                    `${child.tagName}-${child.id}`
                  );
                  this.setOutput(true, "String");
                  this.setColour(83);
                  this.setTooltip(
                    `Get the value of the ${child.tagName}-${child.id} Input`
                  );
                  this.setHelpUrl("");
                },
              };

              Blockly.JavaScript[`${child.tagName}-${child.id}`] = function (
                block
              ) {
                let val = child.value;
                const code = `'${val}'`;

                return [code, Blockly.JavaScript.ORDER_ATOMIC];
              };

              //   component instance
              categoryXml += `<block type="component_instance-${child.tagName}-${child.id}"></block>`;

              Blockly.Blocks[
                `component_instance-${child.tagName}-${child.id}`
              ] = {
                init: function () {
                  this.appendDummyInput().appendField(
                    `Component Instance of ${child.tagName}-${child.id}`
                  );
                  this.appendStatementInput("Properties");
                  this.setPreviousStatement(true, null);
                  this.setNextStatement(true, null);
                  this.setColour(43);
                  this.setTooltip("Get Instance of the Component");
                },
              };

              Blockly.JavaScript[
                `component_instance-${child.tagName}-${child.id}`
              ] = function (block) {
                const properties = Blockly.JavaScript.statementToCode(
                  block,
                  "Properties"
                );

                var code = `
                                        var childElement${child.id} = document.querySelector("#${child.id}");
                                        if(childElement${child.id}){
                                             ${properties}
                                        }
                                        `;

                return code;
              };

              categoryXml += `<block type="manipulate-value-${child.tagName}-${child.id}"></block>`;

              Blockly.Blocks[`manipulate-value-${child.tagName}-${child.id}`] =
                {
                  init: function () {
                    this.appendDummyInput().appendField(
                      `Set Property of ${child.tagName}-${child.id}`
                    );
                    this.appendValueInput("VALUE")
                      .setCheck(null)
                      .appendField("Value:");
                    this.setPreviousStatement(true, null);
                    this.setNextStatement(true, null);
                    this.setColour(160);
                    this.setTooltip("Set Property of the Component");
                  },
                };

              Blockly.JavaScript[
                `manipulate-value-${child.tagName}-${child.id}`
              ] = function (block) {
                const value = Blockly.JavaScript.valueToCode(
                  block,
                  "VALUE",
                  Blockly.JavaScript.ORDER_ATOMIC
                );

                var code = `
                              childElement${child.id}.valueAsDate = ${value};
                              `;

                return code;
              };

              categoryXml += `<block type="custom_css_property-${child.tagName}-${child.id}"></block>`;

              //   for style manipulation:
              Blockly.Blocks[
                `custom_css_property-${child.tagName}-${child.id}`
              ] = {
                init: function () {
                  this.appendDummyInput().appendField(
                    `Customise styles of ${child.tagName}-${child.id}`
                  );
                  this.appendValueInput("PROPERTY_NAME")
                    .setCheck("String")
                    .appendField("Property Name:");

                  this.appendValueInput("PROPERTY_VALUE")
                    .setCheck("String")
                    .appendField("Value:");
                  this.setPreviousStatement(true, null);
                  this.setNextStatement(true, null);
                  this.setColour(180);
                  this.setTooltip(
                    "Specify a CSS property, its type, and value."
                  );
                },
              };

              Blockly.JavaScript[
                `custom_css_property-${child.tagName}-${child.id}`
              ] = function (block) {
                const propertyName = Blockly.JavaScript.valueToCode(
                  block,
                  "PROPERTY_NAME",
                  Blockly.JavaScript.ORDER_ATOMIC
                );

                const propertyValue = Blockly.JavaScript.valueToCode(
                  block,
                  "PROPERTY_VALUE",
                  Blockly.JavaScript.ORDER_ATOMIC
                );

                var code = `
                                        childElement${child.id}.style[${propertyName}]=${propertyValue}
                                        `;

                return code;
              };

              //   js manipulation:
              categoryXml += `<block type="events-${child.tagName}-${child.id}"></block>`;

              //   for style manipulation:
              Blockly.Blocks[`events-${child.tagName}-${child.id}`] = {
                init: function () {
                  this.appendDummyInput().appendField(
                    `Component Events for ${child.tagName}-${child.id}`
                  );
                  this.appendDummyInput()
                    .appendField("On Event")
                    .appendField(
                      new Blockly.FieldDropdown([
                        ["Click", "click"],
                        ["Change", "change"],
                      ]),
                      "EVENT"
                    );
                  this.appendStatementInput("DO").appendField("Do");
                  this.setPreviousStatement(true, null);
                  this.setNextStatement(true, null);
                  this.setColour(230);
                  this.setTooltip("Add event handler To the component");
                  this.setHelpUrl("");
                },
              };

              Blockly.JavaScript[`events-${child.tagName}-${child.id}`] =
                function (block) {
                  var event = block.getFieldValue("EVENT");
                  var code = "";
                  var actionCode = Blockly.JavaScript.statementToCode(
                    block,
                    "DO"
                  );

                  if (event && actionCode) {
                    code += `
                    
                        childElement${child.id}.addEventListener('${event}', function(event) {
                          ${actionCode}
                        });
                    
                    `;
                  }

                  return code;
                };

              categoryXml += `<block type="target-property-${child.tagName}-${child.id}"></block>`;
              Blockly.Blocks[`target-property-${child.tagName}-${child.id}`] = {
                init: function () {
                  this.appendDummyInput().appendField(
                    `target-property-${child.tagName}-${child.id}`
                  );
                  this.setOutput(true, "String");
                  this.setColour(230);
                  this.setTooltip(
                    `Get the event value of the ${child.tagName}-${child.id} Input`
                  );
                  this.setHelpUrl("");
                },
              };

              Blockly.JavaScript[
                `target-property-${child.tagName}-${child.id}`
              ] = function (block) {
                const code = "event.target.value";

                return [code, Blockly.JavaScript.ORDER_ATOMIC];
              };

              categoryXml += `<block type="format-target-property-${child.tagName}-${child.id}"></block>`;
              Blockly.Blocks[
                `format-target-property-${child.tagName}-${child.id}`
              ] = {
                init: function () {
                  this.appendDummyInput().appendField(
                    `format-target-property-${child.tagName}-${child.id}`
                  );
                  this.appendDummyInput()
                    .appendField("Format")
                    .appendField(
                      new Blockly.FieldDropdown([
                        ["IST", "ist"],
                        ["Universal", "utf"],
                        ["Australian Eastern Standard Time (AEST) ", "aest"],
                        ["Australian Central Standard Time (ACST)", "acst"],
                        ["Australian Western Standard Time (AWST) ", "awst"],
                        ["Thailand", "thailand"],
                      ]),
                      "FORMAT"
                    );
                  this.setOutput(true, "String");
                  this.setColour(142);
                  this.setTooltip(
                    `Format the event value of the ${child.tagName}-${child.id} Input`
                  );
                  this.setHelpUrl("");
                },
              };

              Blockly.JavaScript[
                `format-target-property-${child.tagName}-${child.id}`
              ] = function (block) {
                const formatType = block.getFieldValue("FORMAT");
                let code;

                if (formatType === "ist") {
                  code =
                    "String(Number(event.target.value).toLocaleString('en-IN'))";
                } else if (formatType === "utf") {
                  code =
                    "String(Number(event.target.value).toLocaleString('en-US'))";
                } else if (formatType === "aest") {
                  code =
                    "String(Number(event.target.value).toLocaleString('en-US',{timeZone: 'Australia/Sydney'}))";
                } else if (formatType === "acst") {
                  code =
                    "String(Number(event.target.value).toLocaleString('en-US',{timeZone: 'Australia/Adelaide'}))";
                } else if (formatType === "awst") {
                  code =
                    "String(Number(event.target.value).toLocaleString('en-US',{timeZone: 'Australia/Perth'}))";
                } else {
                  code =
                    "String(Number(event.target.value).toLocaleString('en-US',{timeZone: 'Asia/Bangkok'}))";
                }

                return [code, Blockly.JavaScript.ORDER_ATOMIC];
              };

              categoryXml += `<block type="disable_component-${child.tagName}-${child.id}"></block>`;
              Blockly.Blocks[`disable_component-${child.tagName}-${child.id}`] =
                {
                  init: function () {
                    this.appendDummyInput().appendField(
                      `Disable ${child.tagName}-${child.id}`
                    );
                    this.setPreviousStatement(true, null);
                    this.setNextStatement(true, null);
                    this.setColour(230);
                    this.setTooltip("Disable the Component");
                  },
                };

              Blockly.JavaScript[
                `disable_component-${child.tagName}-${child.id}`
              ] = function (block) {
                var code = `
                               
                                    childElement${child.id}.disabled = true;
                                
                            `;

                return code;
              };

              categoryXml += `<block type="combined_change_event_block-${child.name}-${child.id}"></block>`;

              Blockly.Blocks[
                `combined_change_event_block-${child.name}-${child.id}`
              ] = {
                init: function () {
                  this.appendDummyInput().appendField(
                    `Combined Change Event ${child.name}-${child.id}`
                  );
                  this.appendValueInput("RES_VAR")
                    .setCheck(null)
                    .appendField("Store Target Value In Variable:");
                  this.setPreviousStatement(true, null);
                  this.setNextStatement(true, null);
                  this.setColour(43);
                  this.setTooltip("Store Target Value In Variable");
                },
              };

              Blockly.JavaScript[
                `combined_change_event_block-${child.name}-${child.id}`
              ] = function (block) {
                const resVar = Blockly.JavaScript.valueToCode(
                  block,
                  "RES_VAR",
                  Blockly.JavaScript.ORDER_ATOMIC
                );

                var code = `
                                            var childElement${child.id} = document.querySelector("#${child.id}");
                                            if(childElement${child.id}){
                                                 childElement${child.id}.addEventListener('change',function(event){
                                                   ${resVar} = event.target.value
                                                 })
                                            }
                                            `;

                return code;
              };
            } else {
              console.log("child input");
              categoryXml += `<block type="${child.tagName}-${child.id}"></block>`;

              Blockly.Blocks[`${child.tagName}-${child.id}`] = {
                init: function () {
                  this.appendDummyInput().appendField(
                    `${child.tagName}-${child.id}`
                  );
                  this.setOutput(true, "String");
                  this.setColour(83);
                  this.setTooltip(
                    `Get the value of the ${child.tagName}-${child.id} Input`
                  );
                  this.setHelpUrl("");
                },
              };

              Blockly.JavaScript[`${child.tagName}-${child.id}`] = function (
                block
              ) {
                let val = child.value;
                const code = `'${val}'`;

                return [code, Blockly.JavaScript.ORDER_ATOMIC];
              };

              //   component instance
              categoryXml += `<block type="component_instance-${child.tagName}-${child.id}"></block>`;

              Blockly.Blocks[
                `component_instance-${child.tagName}-${child.id}`
              ] = {
                init: function () {
                  this.appendDummyInput().appendField(
                    `Component Instance of ${child.tagName}-${child.id}`
                  );
                  this.appendStatementInput("Properties");
                  this.setPreviousStatement(true, null);
                  this.setNextStatement(true, null);
                  this.setColour(43);
                  this.setTooltip("Get Instance of the Component");
                },
              };

              Blockly.JavaScript[
                `component_instance-${child.tagName}-${child.id}`
              ] = function (block) {
                const properties = Blockly.JavaScript.statementToCode(
                  block,
                  "Properties"
                );

                var code = `
                                        var childElement${child.id} = document.querySelector("#${child.id}");
                                        if(childElement${child.id}){
                                             ${properties}
                                        }
                                        `;

                return code;
              };

              categoryXml += `<block type="manipulate-value-${child.tagName}-${child.id}"></block>`;

              Blockly.Blocks[`manipulate-value-${child.tagName}-${child.id}`] =
                {
                  init: function () {
                    this.appendDummyInput().appendField(
                      `Set Property of ${child.tagName}-${child.id}`
                    );
                    this.appendDummyInput()
                      .appendField("Property:")
                      .appendField(
                        new Blockly.FieldDropdown([
                          ["value", "value"],
                          ["textContent", "textContent"],
                          ["attribute", "textContent"],
                        ]),
                        "PROPERTY"
                      );
                    this.appendValueInput("VALUE")
                      .setCheck(null)
                      .appendField("Value:");
                    this.setPreviousStatement(true, null);
                    this.setNextStatement(true, null);
                    this.setColour(172);
                    this.setTooltip("Set Property of the Component");
                  },
                };

              Blockly.JavaScript[
                `manipulate-value-${child.tagName}-${child.id}`
              ] = function (block) {
                const property = block.getFieldValue("PROPERTY");
                const value = Blockly.JavaScript.valueToCode(
                  block,
                  "VALUE",
                  Blockly.JavaScript.ORDER_ATOMIC
                );

                var code = `
                              childElement${child.id}.${property} = ${value};
                              `;

                return code;
              };

              categoryXml += `<block type="change-attribute-${child.tagName}-${child.id}"></block>`;

              Blockly.Blocks[`change-attribute-${child.tagName}-${child.id}`] =
                {
                  init: function () {
                    this.appendDummyInput().appendField(
                      `Change Attribute of ${child.tagName}-${child.id}`
                    );

                    this.appendValueInput("ATTRIBUTE_NAME")
                      .setCheck(null)
                      .appendField("Attribute Name:");

                    this.appendValueInput("VALUE")
                      .setCheck(null)
                      .appendField("Attribute Value:");
                    this.setPreviousStatement(true, null);
                    this.setNextStatement(true, null);
                    this.setColour(172);
                    this.setTooltip("Change Attribute of the Component");
                  },
                };

              Blockly.JavaScript[
                `change-attribute-${child.tagName}-${child.id}`
              ] = function (block) {
                const value = Blockly.JavaScript.valueToCode(
                  block,
                  "VALUE",
                  Blockly.JavaScript.ORDER_ATOMIC
                );
                const attributeName = Blockly.JavaScript.valueToCode(
                  block,
                  "ATTRIBUTE_NAME",
                  Blockly.JavaScript.ORDER_ATOMIC
                );

                var code = `
                                childElement${child.id}.setAttribute(${attributeName},${value});
                                
                                `;

                return code;
              };

              categoryXml += `<block type="custom_css_property-${child.tagName}-${child.id}"></block>`;

              //   for style manipulation:
              Blockly.Blocks[
                `custom_css_property-${child.tagName}-${child.id}`
              ] = {
                init: function () {
                  this.appendDummyInput().appendField(
                    `Customise styles of ${child.tagName}-${child.id}`
                  );
                  this.appendValueInput("PROPERTY_NAME")
                    .setCheck("String")
                    .appendField("Property Name:");

                  this.appendValueInput("PROPERTY_VALUE")
                    .setCheck("String")
                    .appendField("Value:");
                  this.setPreviousStatement(true, null);
                  this.setNextStatement(true, null);
                  this.setColour(27);
                  this.setTooltip(
                    "Specify a CSS property, its type, and value."
                  );
                },
              };

              Blockly.JavaScript[
                `custom_css_property-${child.tagName}-${child.id}`
              ] = function (block) {
                const propertyName = Blockly.JavaScript.valueToCode(
                  block,
                  "PROPERTY_NAME",
                  Blockly.JavaScript.ORDER_ATOMIC
                );

                const propertyValue = Blockly.JavaScript.valueToCode(
                  block,
                  "PROPERTY_VALUE",
                  Blockly.JavaScript.ORDER_ATOMIC
                );

                var code = `
                                        childElement${child.id}.style[${propertyName}]=${propertyValue}
                                        `;

                return code;
              };

              //   js manipulation:
              categoryXml += `<block type="events-${child.tagName}-${child.id}"></block>`;

              //   for style manipulation:
              Blockly.Blocks[`events-${child.tagName}-${child.id}`] = {
                init: function () {
                  this.appendDummyInput().appendField(
                    `Component Events for ${child.tagName}-${child.id}`
                  );
                  this.appendDummyInput()
                    .appendField("On Event")
                    .appendField(
                      new Blockly.FieldDropdown([
                        ["Click", "click"],
                        ["Change", "change"],
                      ]),
                      "EVENT"
                    );
                  this.appendStatementInput("DO").appendField("Do");
                  this.setPreviousStatement(true, null);
                  this.setNextStatement(true, null);
                  this.setColour(262);
                  this.setTooltip("Add event handler To the component");
                  this.setHelpUrl("");
                },
              };

              Blockly.JavaScript[`events-${child.tagName}-${child.id}`] =
                function (block) {
                  var event = block.getFieldValue("EVENT");
                  var code = "";
                  var actionCode = Blockly.JavaScript.statementToCode(
                    block,
                    "DO"
                  );

                  if (event && actionCode) {
                    code += `
                    
                        childElement${child.id}.addEventListener('${event}', function(event) {
                          ${actionCode}
                        });
                    
                    `;
                  }

                  return code;
                };

              categoryXml += `<block type="target-property-${child.tagName}-${child.id}"></block>`;
              Blockly.Blocks[`target-property-${child.tagName}-${child.id}`] = {
                init: function () {
                  this.appendDummyInput().appendField(
                    `target-property-${child.tagName}-${child.id}`
                  );
                  this.setOutput(true, "String");
                  this.setColour(142);
                  this.setTooltip(
                    `Get the event value of the ${child.tagName}-${child.id} Input`
                  );
                  this.setHelpUrl("");
                },
              };

              Blockly.JavaScript[
                `target-property-${child.tagName}-${child.id}`
              ] = function (block) {
                const code = "event.target.value";

                return [code, Blockly.JavaScript.ORDER_ATOMIC];
              };

              categoryXml += `<block type="format-target-property-${child.tagName}-${child.id}"></block>`;
              Blockly.Blocks[
                `format-target-property-${child.tagName}-${child.id}`
              ] = {
                init: function () {
                  this.appendDummyInput().appendField(
                    `format-target-property-${child.tagName}-${child.id}`
                  );
                  this.appendDummyInput()
                    .appendField("Format")
                    .appendField(
                      new Blockly.FieldDropdown([
                        ["IST", "ist"],
                        ["Universal", "utf"],
                        ["Australian Eastern Standard Time (AEST) ", "aest"],
                        ["Australian Central Standard Time (ACST)", "acst"],
                        ["Australian Western Standard Time (AWST) ", "awst"],
                        ["Thailand", "thailand"],
                      ]),
                      "FORMAT"
                    );
                  this.setOutput(true, "String");
                  this.setColour(142);
                  this.setTooltip(
                    `Format the event value of the ${child.tagName}-${child.id} Input`
                  );
                  this.setHelpUrl("");
                },
              };

              Blockly.JavaScript[
                `format-target-property-${child.tagName}-${child.id}`
              ] = function (block) {
                const formatType = block.getFieldValue("FORMAT");
                let code;

                if (formatType === "ist") {
                  code =
                    "String(Number(event.target.value).toLocaleString('en-IN'))";
                } else if (formatType === "utf") {
                  code =
                    "String(Number(event.target.value).toLocaleString('en-US'))";
                } else if (formatType === "aest") {
                  code =
                    "String(Number(event.target.value).toLocaleString('en-US',{timeZone: 'Australia/Sydney'}))";
                } else if (formatType === "acst") {
                  code =
                    "String(Number(event.target.value).toLocaleString('en-US',{timeZone: 'Australia/Adelaide'}))";
                } else if (formatType === "awst") {
                  code =
                    "String(Number(event.target.value).toLocaleString('en-US',{timeZone: 'Australia/Perth'}))";
                } else {
                  code =
                    "String(Number(event.target.value).toLocaleString('en-US',{timeZone: 'Asia/Bangkok'}))";
                }

                return [code, Blockly.JavaScript.ORDER_ATOMIC];
              };

              categoryXml += `<block type="disable_component-${child.tagName}-${child.id}"></block>`;
              Blockly.Blocks[`disable_component-${child.tagName}-${child.id}`] =
                {
                  init: function () {
                    this.appendDummyInput().appendField(
                      `Disable ${child.tagName}-${child.id}`
                    );
                    this.setPreviousStatement(true, null);
                    this.setNextStatement(true, null);
                    this.setColour(0);
                    this.setTooltip("Disable the Component");
                  },
                };

              Blockly.JavaScript[
                `disable_component-${child.tagName}-${child.id}`
              ] = function (block) {
                var code = `
                               
                                    childElement${child.id}.disabled = true;
                                
                            `;

                return code;
              };

              categoryXml += `<block type="combined_change_event_block-${child.name}-${child.id}"></block>`;

              Blockly.Blocks[
                `combined_change_event_block-${child.name}-${child.id}`
              ] = {
                init: function () {
                  this.appendDummyInput().appendField(
                    `Combined Change Event ${child.name}-${child.id}`
                  );
                  this.appendValueInput("RES_VAR")
                    .setCheck(null)
                    .appendField("Store Target Value In Variable:");
                  this.setPreviousStatement(true, null);
                  this.setNextStatement(true, null);
                  this.setColour(43);
                  this.setTooltip("Store Target Value In Variable");
                },
              };

              Blockly.JavaScript[
                `combined_change_event_block-${child.name}-${child.id}`
              ] = function (block) {
                const resVar = Blockly.JavaScript.valueToCode(
                  block,
                  "RES_VAR",
                  Blockly.JavaScript.ORDER_ATOMIC
                );

                var code = `
                                            var childElement${child.id} = document.querySelector("#${child.id}");
                                            if(childElement${child.id}){
                                                 childElement${child.id}.addEventListener('change',function(event){
                                                   ${resVar} = event.target.value
                                                 })
                                            }
                                            `;

                return code;
              };
            }
          }

          if (child.tagName === "TEXTAREA" || child.tagName === "textarea") {
            categoryXml += `<block type="${child.tagName}-${child.id}"></block>`;

            Blockly.Blocks[`${child.tagName}-${child.id}`] = {
              init: function () {
                this.appendDummyInput().appendField(
                  `${child.tagName}-${child.id}`
                );
                this.setOutput(true, "String");
                this.setColour(0);
                this.setTooltip(
                  `Get the value of the ${child.tagName}-${child.id} Input`
                );
                this.setHelpUrl("");
              },
            };

            Blockly.JavaScript[`${child.tagName}-${child.id}`] = function (
              block
            ) {
              let val = child.value;
              const code = `'${val}'`;

              return [code, Blockly.JavaScript.ORDER_ATOMIC];
            };

            //   component instance
            categoryXml += `<block type="component_instance-${child.tagName}-${child.id}"></block>`;

            Blockly.Blocks[`component_instance-${child.tagName}-${child.id}`] =
              {
                init: function () {
                  this.appendDummyInput().appendField(
                    `Component Instance of ${child.tagName}-${child.id}`
                  );
                  this.appendStatementInput("Properties");
                  this.setPreviousStatement(true, null);
                  this.setNextStatement(true, null);
                  this.setColour(0);
                  this.setTooltip("Get Instance of the Component");
                },
              };

            Blockly.JavaScript[
              `component_instance-${child.tagName}-${child.id}`
            ] = function (block) {
              const properties = Blockly.JavaScript.statementToCode(
                block,
                "Properties"
              );

              var code = `
                                      var childElement${child.id} = document.querySelector("#${child.id}");
                                      if(childElement${child.id}){
                                           ${properties}
                                      }
                                      `;

              return code;
            };

            categoryXml += `<block type="manipulate-value-${child.tagName}-${child.id}"></block>`;

            Blockly.Blocks[`manipulate-value-${child.tagName}-${child.id}`] = {
              init: function () {
                this.appendDummyInput().appendField(
                  `Set Property of ${child.tagName}-${child.id}`
                );
                this.appendDummyInput()
                  .appendField("Property:")
                  .appendField(
                    new Blockly.FieldDropdown([
                      ["value", "value"],
                      ["textContent", "textContent"],
                    ]),
                    "PROPERTY"
                  );
                this.appendValueInput("VALUE")
                  .setCheck(null)
                  .appendField("Value:");
                this.setPreviousStatement(true, null);
                this.setNextStatement(true, null);
                this.setColour(0);
                this.setTooltip("Set Property of the Component");
              },
            };

            Blockly.JavaScript[
              `manipulate-value-${child.tagName}-${child.id}`
            ] = function (block) {
              const property = block.getFieldValue("PROPERTY");
              const value = Blockly.JavaScript.valueToCode(
                block,
                "VALUE",
                Blockly.JavaScript.ORDER_ATOMIC
              );

              var code = `
                            childElement${child.id}.${property} = ${value};
                            `;

              return code;
            };

            categoryXml += `<block type="custom_css_property-${child.tagName}-${child.id}"></block>`;

            //   for style manipulation:
            Blockly.Blocks[`custom_css_property-${child.tagName}-${child.id}`] =
              {
                init: function () {
                  this.appendDummyInput().appendField(
                    `Customise styles of ${child.tagName}-${child.id}`
                  );
                  this.appendValueInput("PROPERTY_NAME")
                    .setCheck("String")
                    .appendField("Property Name:");

                  this.appendValueInput("PROPERTY_VALUE")
                    .setCheck("String")
                    .appendField("Value:");
                  this.setPreviousStatement(true, null);
                  this.setNextStatement(true, null);
                  this.setColour(0);
                  this.setTooltip(
                    "Specify a CSS property, its type, and value."
                  );
                },
              };

            Blockly.JavaScript[
              `custom_css_property-${child.tagName}-${child.id}`
            ] = function (block) {
              const propertyName = Blockly.JavaScript.valueToCode(
                block,
                "PROPERTY_NAME",
                Blockly.JavaScript.ORDER_ATOMIC
              );

              const propertyValue = Blockly.JavaScript.valueToCode(
                block,
                "PROPERTY_VALUE",
                Blockly.JavaScript.ORDER_ATOMIC
              );

              var code = `
                                      childElement${child.id}.style[${propertyName}]=${propertyValue}
                                      `;

              return code;
            };

            //   js manipulation:
            categoryXml += `<block type="events-${child.tagName}-${child.id}"></block>`;

            //   for style manipulation:
            Blockly.Blocks[`events-${child.tagName}-${child.id}`] = {
              init: function () {
                this.appendDummyInput().appendField(
                  `Component Events for ${child.tagName}-${child.id}`
                );
                this.appendDummyInput()
                  .appendField("On Event")
                  .appendField(
                    new Blockly.FieldDropdown([
                      ["Click", "click"],
                      ["Change", "change"],
                    ]),
                    "EVENT"
                  );
                this.appendStatementInput("DO").appendField("Do");
                this.setPreviousStatement(true, null);
                this.setNextStatement(true, null);
                this.setColour(0);
                this.setTooltip("Add event handler To the component");
                this.setHelpUrl("");
              },
            };

            Blockly.JavaScript[`events-${child.tagName}-${child.id}`] =
              function (block) {
                var event = block.getFieldValue("EVENT");
                var code = "";
                var actionCode = Blockly.JavaScript.statementToCode(
                  block,
                  "DO"
                );

                if (event && actionCode) {
                  code += `
                  
                      childElement${child.id}.addEventListener('${event}', function(event) {
                        ${actionCode}
                      });
                  
                  `;
                }

                return code;
              };

            categoryXml += `<block type="target-property-${child.tagName}-${child.id}"></block>`;
            Blockly.Blocks[`target-property-${child.tagName}-${child.id}`] = {
              init: function () {
                this.appendDummyInput().appendField(
                  `target-property-${child.tagName}-${child.id}`
                );
                this.setOutput(true, "String");
                this.setColour(0);
                this.setTooltip(
                  `Get the event value of the ${child.tagName}-${child.id} Input`
                );
                this.setHelpUrl("");
              },
            };

            Blockly.JavaScript[`target-property-${child.tagName}-${child.id}`] =
              function (block) {
                const code = "event.target.value";

                return [code, Blockly.JavaScript.ORDER_ATOMIC];
              };

            categoryXml += `<block type="disable_component-${child.tagName}-${child.id}"></block>`;
            Blockly.Blocks[`disable_component-${child.tagName}-${child.id}`] = {
              init: function () {
                this.appendDummyInput().appendField(
                  `Disable ${child.tagName}-${child.id}`
                );
                this.setPreviousStatement(true, null);
                this.setNextStatement(true, null);
                this.setColour(0);
                this.setTooltip("Disable the Component");
              },
            };

            Blockly.JavaScript[
              `disable_component-${child.tagName}-${child.id}`
            ] = function (block) {
              var code = `
                             
                                  childElement${child.id}.disabled = true;
                              
                          `;

              return code;
            };
          }

          if (child.tagName === "IMG" || child.tagName === "img") {
            categoryXml += `<block type="component_instance-${child.tagName}-${child.id}"></block>`;
            console.log("child::::::::::::", child);

            // categoryXml += `<block type="events-${child.type}-${i}"></block>`;

            Blockly.Blocks[`component_instance-${child.tagName}-${child.id}`] =
              {
                init: function () {
                  this.appendDummyInput().appendField(
                    `Component Instance of ${child.tagName}-${child.id}`
                  );
                  this.appendStatementInput("Properties");
                  this.setPreviousStatement(true, null);
                  this.setNextStatement(true, null);
                  this.setColour(43);
                  this.setTooltip("Get Instance of the Component");
                },
              };

            Blockly.JavaScript[
              `component_instance-${child.tagName}-${child.id}`
            ] = function (block) {
              const properties = Blockly.JavaScript.statementToCode(
                block,
                "Properties"
              );

              var code = `
                                    var childElement${child.id} = document.querySelector("#${child.id}");
                                    if(childElement${child.id}){
                                         ${properties}
                                    }
                                    `;

              return code;
            };

            categoryXml += `<block type="manipulate-value-${child.tagName}-${child.id}"></block>`;

            Blockly.Blocks[`manipulate-value-${child.tagName}-${child.id}`] = {
              init: function () {
                this.appendDummyInput().appendField(
                  `Set source for ${child.tagName}-${child.id}`
                );
                this.appendValueInput("VALUE")
                  .setCheck(null)
                  .appendField("Value:");
                this.setPreviousStatement(true, null);
                this.setNextStatement(true, null);
                this.setColour(172);
                this.setTooltip("Set Property of the Component");
              },
            };

            Blockly.JavaScript[
              `manipulate-value-${child.tagName}-${child.id}`
            ] = function (block) {
              const value = Blockly.JavaScript.valueToCode(
                block,
                "VALUE",
                Blockly.JavaScript.ORDER_ATOMIC
              );

              var code = `
                          childElement${child.id}.src = ${value};
                          `;

              return code;
            };
          }
        }
      });
    }

    htmlContent.forEach((component, index) => {
      //   console.log("component:::::", component);

      // dont generate blocks if the wrapper div of the editor is empty
      let viewComp = component.view.$el[0];

      //   console.log("viewComp:::::::::", viewComp);
      //   console.log("view children count");
      if (viewComp.childElementCount > 0) {
        let parentAttribute = viewComp.getAttribute("data-gjs-type");
        console.log("parentAttribute", parentAttribute);
        categoryXml += `<category name="${parentAttribute}-${index}" colour="210">`;
        recursiveBlockAddition(viewComp, index);
        categoryXml += `</category>`;
      }
    });

    categoryXml += `</category>`;

    const toolboxXml = `
              <xml xmlns="https://developers.google.com/blockly/xml" id="toolbox">
              <category name="Logic" colour="%{BKY_LOGIC_HUE}">
              <category name="If">
                  <block type="controls_if"></block>
                  <block type="controls_if">
                      <mutation else="1"></mutation>
                  </block>
                  <block type="controls_if">
                      <mutation elseif="1" else="1"></mutation>
                  </block>
              </category>
              <category name="Boolean" colour="%{BKY_LOGIC_HUE}">
                  <block type="logic_compare"></block>
                  <block type="logic_operation"></block>
                  <block type="logic_negate"></block>
                  <block type="logic_boolean"></block>
                  <block type="logic_null"></block>
                  <block type="logic_ternary"></block>
              </category>
          </category>
          <category name="Loops" colour="%{BKY_LOOPS_HUE}">
              <block type="controls_repeat_ext">
                  <value name="TIMES">
                  <block type="math_number">
                      <field name="NUM">10</field>
                  </block>
                  </value>
              </block>
              <block type="controls_whileUntil"></block>
              <block type="bi_for"></block>
              <block type="controls_for">
                  <field name="VAR">i</field>
                  <value name="FROM">
                  <block type="math_number">
                      <field name="NUM">1</field>
                  </block>
                  </value>
                  <value name="TO">
                  <block type="math_number">
                      <field name="NUM">10</field>
                  </block>
                  </value>
                  <value name="BY">
                  <block type="math_number">
                      <field name="NUM">1</field>
                  </block>
                  </value>
              </block>
              <block type="controls_forEach"></block>
              <block type="controls_flow_statements"></block>
          </category>
          <category name="Math" colour="%{BKY_MATH_HUE}">
              <block type="math_number">
                  <field name="NUM">123</field>
              </block>
              <block type="bi_parenthesis"></block>
              <block type="bi_unary"></block>
              <block type="bi_unary_return"></block>
              <block type="bi_unary_postfix"></block>
              <block type="bi_unary_postfix_return"></block>
              <block type="math_arithmetic"></block>
              <block type="math_single"></block>
              <block type="math_trig"></block>
              <block type="math_constant"></block>
              <block type="math_number_property"></block>
              <block type="math_round"></block>
              <block type="math_on_list"></block>
              <block type="math_modulo"></block>
              <block type="math_constrain">
                  <value name="LOW">
                  <block type="math_number">
                      <field name="NUM">1</field>
                  </block>
                  </value>
                  <value name="HIGH">
                  <block type="math_number">
                      <field name="NUM">100</field>
                  </block>
                  </value>
              </block>
              <block type="math_random_int">
                  <value name="FROM">
                  <block type="math_number">
                      <field name="NUM">1</field>
                  </block>
                  </value>
                  <value name="TO">
                  <block type="math_number">
                      <field name="NUM">100</field>
                  </block>
                  </value>
              </block>
              <block type="math_random_float"></block>
              <block type="math_atan2"></block>
          </category>
          <category   name="Lists" colour="%{BKY_LISTS_HUE}">
              <block type="lists_create_empty"></block>
              <block type="lists_create_with"></block>
              <block type="lists_repeat">
                  <value name="NUM">
                  <block type="math_number">
                      <field name="NUM">5</field>
                  </block>
                  </value>
              </block>
              <block type="lists_length"></block>
              <block type="lists_isEmpty"></block>
              <block type="lists_indexOf"></block>
              <block type="lists_getIndex"></block>
              <block type="lists_setIndex"></block>
              <block type="api_call_block"></block>
              <block type="response_handler_block"></block>
              <block type="response_code_block"></block>
              <block type="response_data_block"></block>

              <block type="slice_list"></block>
          </category>
          <sep></sep>
          <category name="Text" colour="%{BKY_TEXTS_HUE}">
            <block type="concate_string"></block>
            <block type="add_line_break"></block>
              <block type="text"></block>
              <block type="multilineText"></block>
              <block type="text_join"></block>
              <block type="text_append">
                  <value name="TEXT">
                  <shadow type="text"></shadow>
                  </value>
              </block>
              <block type="text_length">
                  <value name="VALUE">
                  <shadow type="text">
                      <field name="TEXT">abc</field>
                  </shadow>
                  </value>
              </block>
              <block type="text_isEmpty">
                  <value name="VALUE">
                  <shadow type="text">
                      <field name="TEXT"></field>
                  </shadow>
                  </value>
              </block>
              <block type="text_indexOf">
                  <value name="VALUE">
                  <block type="variables_get">
                      <field name="VAR">{textVariable}</field>
                  </block>
                  </value>
                  <value name="FIND">
                  <shadow type="text">
                      <field name="TEXT">abc</field>
                  </shadow>
                  </value>
              </block>
              <block type="text_charAt">
                  <value name="VALUE">
                  <block type="variables_get">
                      <field name="VAR">{textVariable}</field>
                  </block>
                  </value>
              </block>
              <block type="text_getSubstring">
                  <value name="STRING">
                  <block type="variables_get">
                      <field name="VAR">{textVariable}</field>
                  </block>
                  </value>
              </block>
              <block type="text_changeCase">
                  <value name="TEXT">
                  <shadow type="text">
                      <field name="TEXT">abc</field>
                  </shadow>
                  </value>
              </block>
              <block type="text_trim">
                  <value name="TEXT">
                  <shadow type="text">
                      <field name="TEXT">abc</field>
                  </shadow>
                  </value>
              </block>
              <block type="text_print">
                  <value name="TEXT">
                  <shadow type="text">
                      <field name="TEXT">abc</field>
                  </shadow>
                  </value>
              </block>
              <block type="text_prompt_ext">
                  <value name="TEXT">
                  <shadow type="text">
                      <field name="TEXT">abc</field>
                  </shadow>
                  </value>
              </block>
            <block type="text_split_substring"></block>
          </category>
          <category name="Variables" custom="VARIABLE" colour="%{BKY_VARIABLES_HUE}">
          </category>
          <category name="Functions" custom="PROCEDURE" colour="%{BKY_PROCEDURES_HUE}">
          </category>
          <category id="catColour" name="Color" colour="20">
              <block type="colour_picker"></block>
              <block type="colour_random"></block>
              <block type="colour_rgb">
                  <value name="RED">
                      <shadow type="math_number">
                          <field name="NUM">100</field>
                      </shadow>
                  </value>
                  <value name="GREEN">
                      <shadow type="math_number">
                          <field name="NUM">50</field>
                      </shadow>
                  </value>
                  <value name="BLUE">
                      <shadow type="math_number">
                          <field name="NUM">0</field>
                      </shadow>
                  </value>
              </block>
              <block type="colour_blend">
                  <value name="COLOUR1">
                      <shadow type="colour_picker">
                          <field name="COLOUR">#ff0000</field>
                      </shadow>
                  </value>
                  <value name="COLOUR2">
                      <shadow type="colour_picker">
                          <field name="COLOUR">#3333ff</field>
                      </shadow>
                  </value>
                  <value name="RATIO">
                      <shadow type="math_number">
                          <field name="NUM">0.5</field>
                      </shadow>
                  </value>
              </block>
          </category>
          <sep></sep>
          <category name="Modern" colour="230">
              <block type="bi_var"></block>
              <block type="bi_var_name"></block>
              <block type="bi_assignment"></block>      
              <block type="bi_assignment_return"></block>      
              <block type="bi_field"></block>
              <block type="bi_field_return"></block>
              <block type="bi_return"></block>
              <block type="bi_spread"></block>
          </category>
          <category name="Advanced js" colour="90">
              <block type="bi_new"></block>
              <block type="bi_anonymous_class"></block>
              <block type="bi_class"></block>
              <block type="bi_static"></block>      
              <block type="bi_get"></block>
              <block type="bi_set"></block>
              <block type="bi_try_catch"></block>      
              <block type="bi_catch"></block>
              <block type="bi_comment"></block>
          </category>
          <category name="Library" expanded="true">
              <category name="Randomize">
                  <block type="procedures_defnoreturn">
                  <mutation>
                      <arg name="list"></arg>
                  </mutation>
                  <field name="NAME">randomize</field>
                  <statement name="STACK">
                      <block type="controls_for" inline="true">
                      <field name="VAR">x</field>
                      <value name="FROM">
                          <block type="math_number">
                          <field name="NUM">1</field>
                          </block>
                      </value>
                      <value name="TO">
                          <block type="lists_length" inline="false">
                          <value name="VALUE">
                              <block type="variables_get">
                              <field name="VAR">list</field>
                              </block>
                          </value>
                          </block>
                      </value>
                      <value name="BY">
                          <block type="math_number">
                          <field name="NUM">1</field>
                          </block>
                      </value>
                      <statement name="DO">
                          <block type="variables_set" inline="false">
                          <field name="VAR">y</field>
                          <value name="VALUE">
                              <block type="math_random_int" inline="true">
                              <value name="FROM">
                                  <block type="math_number">
                                  <field name="NUM">1</field>
                                  </block>
                              </value>
                              <value name="TO">
                                  <block type="lists_length" inline="false">
                                  <value name="VALUE">
                                      <block type="variables_get">
                                      <field name="VAR">list</field>
                                      </block>
                                  </value>
                                  </block>
                              </value>
                              </block>
                          </value>
                          <next>
                              <block type="variables_set" inline="false">
                              <field name="VAR">temp</field>
                              <value name="VALUE">
                                  <block type="lists_getIndex" inline="true">
                                  <mutation statement="false" at="true"></mutation>
                                  <field name="MODE">GET</field>
                                  <field name="WHERE">FROM_START</field>
                                  <value name="AT">
                                      <block type="variables_get">
                                      <field name="VAR">y</field>
                                      </block>
                                  </value>
                                  <value name="VALUE">
                                      <block type="variables_get">
                                      <field name="VAR">list</field>
                                      </block>
                                  </value>
                                  </block>
                              </value>
                              <next>
                                  <block type="lists_setIndex" inline="false">
                                  <value name="AT">
                                      <block type="variables_get">
                                      <field name="VAR">y</field>
                                      </block>
                                  </value>
                                  <value name="LIST">
                                      <block type="variables_get">
                                      <field name="VAR">list</field>
                                      </block>
                                  </value>
                                  <value name="TO">
                                      <block type="lists_getIndex" inline="true">
                                      <mutation statement="false" at="true"></mutation>
                                      <field name="MODE">GET</field>
                                      <field name="WHERE">FROM_START</field>
                                      <value name="AT">
                                          <block type="variables_get">
                                          <field name="VAR">x</field>
                                          </block>
                                      </value>
                                      <value name="VALUE">
                                          <block type="variables_get">
                                          <field name="VAR">list</field>
                                          </block>
                                      </value>
                                      </block>
                                  </value>
                                  <next>
                                      <block type="lists_setIndex" inline="false">
                                      <value name="AT">
                                          <block type="variables_get">
                                          <field name="VAR">x</field>
                                          </block>
                                      </value>
                                      <value name="LIST">
                                          <block type="variables_get">
                                          <field name="VAR">list</field>
                                          </block>
                                      </value>
                                      <value name="TO">
                                          <block type="variables_get">
                                          <field name="VAR">temp</field>
                                          </block>
                                      </value>
                                      </block>
                                  </next>
                                  </block>
                              </next>
                              </block>
                          </next>
                          </block>
                      </statement>
                      </block>
                  </statement>
                  </block>
              </category>
              <category name="Jabberwocky">
                  <block type="text_print">
                  <value name="TEXT">
                      <block type="text">
                      <field name="TEXT">'Twas brillig, and the slithy toves</field>
                      </block>
                  </value>
                  <next>
                      <block type="text_print">
                      <value name="TEXT">
                          <block type="text">
                          <field name="TEXT">  Did gyre and gimble in the wabe:</field>
                          </block>
                      </value>
                      <next>
                          <block type="text_print">
                          <value name="TEXT">
                              <block type="text">
                              <field name="TEXT">All mimsy were the borogroves,</field>
                              </block>
                          </value>
                          <next>
                              <block type="text_print">
                              <value name="TEXT">
                                  <block type="text">
                                  <field name="TEXT">  And the mome raths outgrabe.</field>
                                  </block>
                              </value>
                              </block>
                          </next>
                          </block>
                      </next>
                      </block>
                  </next>
                  </block>
                  <block type="text_print">
                  <value name="TEXT">
                      <block type="text">
                      <field name="TEXT">"Beware the Jabberwock, my son!</field>
                      </block>
                  </value>
                  <next>
                      <block type="text_print">
                      <value name="TEXT">
                          <block type="text">
                          <field name="TEXT">  The jaws that bite, the claws that catch!</field>
                          </block>
                      </value>
                      <next>
                          <block type="text_print">
                          <value name="TEXT">
                              <block type="text">
                              <field name="TEXT">Beware the Jubjub bird, and shun</field>
                              </block>
                          </value>
                          <next>
                              <block type="text_print">
                              <value name="TEXT">
                                  <block type="text">
                                  <field name="TEXT">  The frumious Bandersnatch!"</field>
                                  </block>
                              </value>
                              </block>
                          </next>
                          </block>
                      </next>
                      </block>
                  </next>
                  </block>
              </category>
          </category>
                
                   </category>
          <category name="typeCasting"  colour="%{BKY_LISTS_HUE}">
          <block type="to_number"></block>
          <block type="to_string"></block>
          <block type="check_nan"></block>
          <block type="typeof"></block>
          <block type="string_includes"></block>
          
          </category>
          <category name="style_manipulation"   colour="%{BKY_LISTS_HUE}">
          <block type="grapesjs_display"></block>
          <block type="grapesjs_width"></block>
          <block type="grapesjs_height"></block>
          <block type="grapesjs_margin"></block>
          <block type="grapesjs_padding"></block>
          <block type="grapesjs_border"></block>
          <block type="grapesjs_border_radius"></block>
          <block type="grapesjs_color"></block>
          <block type="grapesjs_background_color"></block>
          <block type="grapesjs_background_image"></block>
          
          
          </category>
          
          <category name="component_events"   colour="%{BKY_LISTS_HUE}">
          <block type="onclick_event"></block>
          <block type="onchange_event"></block>
          <block type="onmouseover_event"></block>
          <block type="onmouseout_event"></block>
          
          <block type="get_property_from_event_target"></block>
          <block type="set_property_of_event_target"></block>
          <block type="set_css_property"></block>
          
          </category>
          <category name="form_elements"   colour="%{BKY_LISTS_HUE}">
          <block type="email_input"></block>
          <block type="text_input"></block>
          <block type="number_input"></block>
          <block type="phone_input"></block>
          <block type="password_input"></block>
          <block type="submit_button"></block>
          </category>
          <category name="async_calls" colour="%{BKY_LISTS_HUE}">
          <block type="create_variable"></block>
          <block type="create_function"></block>
          <block type="api_call"></block>
          <block type="header_block"></block>
          <block type="basic_auth"></block>
          <block type="payload_block"></block>
          <block type="nested_payload_block"></block>
          <block type="async_function_invoked"></block>
          <block type="async_function_mesh"></block>
          <block type="async_function_promise"></block>
          <block type="array_push_method"></block>
       
          <block type="handle_response"></block>
          <block type="get_response_variable"></block>
          <block type="iterate_array"></block>
          <block type="array_manipulation_method"></block>
          <block type="get_property"></block>
          </category>
          <category name="local_storage" colour="%{BKY_LISTS_HUE}">
          <block type="create_variable_from_local_storage"></block>
          <block type="get_variable_from_local_storage"></block>
          <block type="remove_variable_from_local_storage"></block>
          </category>
          <category name="session_storage" colour="%{BKY_LISTS_HUE}">
          <block type="create_session_variable"></block>
          <block type="get_session_variable"></block>
          <block type="remove_session_variable"></block>
          <block type="parse_json"></block>
          <block type="stringify_json"></block>
          <block type="storage_event_handler"></block>
          <block type="storage_event_storage_area"></block>
          <block type="storage_event_new_value"></block>
          <block type="storage_event_old_value"></block>
          <block type="storage_event_key"></block>
          </category>
          <category name="custom_wrapper" colour="%{BKY_LISTS_HUE}">
          <block type="custom_logic_block"></block>
          <block type="customize_component"></block>
          <block type="css_property"></block>
          <block type="js_block"></block>
          <block type="event_onChange"></block>
          <block type="event_onClick"></block>
          </category>
          ${categoryXml}
          <category name="time" colour="%{BKY_LISTS_HUE}">
          <block type="timestamp"></block>
          <block type="convert_datetime"></block>
          <block type="increment_decrement_date"></block>
          <block type="increment_decrement_time"></block>
          <block type="current date"></block>
          
          <block type="local date"></block>
          <block type="utc date"></block>
          <block type="current dateV2"></block>
          <block type="local dateV2"></block>
          <block type="utc dateV2"></block>
          <block type="convert_to_date_object"></block>
          <block type="date_formats_to_ui"></block>
          <block type="set_timeout"></block>
          <block type="set_interval"></block>
          <block type="set_interval_new"></block>
          <block type="clear_interval"></block>
          <block type="store_session_time"></block>
          <block type="is_session_expired"></block>
          <block type="remaining_session_time"></block>
          <block type="session_client_track"></block>
          <block type="nearest_matching_day"></block>
         
          </category>
          <category name="Objects">
          <block type="create_object"></block>
          <block type="create_object_v_2"></block>
          <block type="create_object_v_3"></block>
          <block type="merge_objects"></block>
         
          </category>
        
          <category name="Miscellaneous">
       
          <block type="try_catch_block"></block>
          <block type="formatnumber"></block>
          <block type="convert_to_number"></block>
          <block type="grab_query_from_url"></block>
          <block type="grab_query_parameter_from_url"></block>
          <block type="set_last_page"></block>
          <block type="manipulate_window_location"></block>
          <block type="navigate_back_shorthand"></block>
          <block type="grab_geolocation"></block>
          <block type="calc_distance_of_geo"></block>
          <!-- <block type="number_formats"></block> -->
          <block type="generalized_mutation_observer"></block>
          <block type="get_attributes"></block>
          <block type="set_attributes"></block>
          <block type="get_parent_id"></block>

          </category>

          <category name="Notification Center">
           <block type="register_push_notification"></block>
  <block type="send_push_notification"></block>
  <block type="random_variable_generator"></block>
          </category>
              </xml>
          `;

    this.workspace = Blockly.inject(div, {
      modalTitle: "Redsling",
      toolbox: toolboxXml,
      zoom: {
        controls: true,
        wheel: true,
        startScale: 1.0,
        maxScale: 3,
        minScale: 0.2,
        scaleSpeed: 1.2,
      },
      grid: {
        spacing: 20,
        length: 3,
        color: "#ccc",
        snap: true,
      },
      trashcan: true,
      categoryToolboxSelectedItem: false, // Disable block selection on category click
      ...opts.workspaceOptions,
    });
  }
}
