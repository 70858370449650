import { useState } from 'react'
import { useDrop } from 'react-dnd'
import { ItemTypes } from '../LogicBuilder4/ItemTypes.js'
import { NewCanvas2 } from './NewCanvas2.js'
function getStyle(backgroundColor) {
  return {
    border: '1px solid rgba(0,0,0,0.2)',
    minHeight: '18rem',
    minWidth: '18rem',
    color: 'white',
    backgroundColor,
    padding: '2rem',
    paddingTop: '1rem',
    margin: '1rem',
    textAlign: 'center',
    float: 'left',
    fontSize: '1rem',
  }
}
export const NewCanvas1 = ({ greedy, children, name, workspaceUndoBtn }) => {
  const [hasDropped, setHasDropped] = useState(false)
  const [hasDroppedOnChild, setHasDroppedOnChild] = useState(false)
  const [items, addItems] = useState([])
  const [condition, setCondition] = useState()
  const [{ isOver, isOverCurrent }, drop] = useDrop(
    () => ({
      accept: ItemTypes.BOX,
      drop: (_item, monitor) => {
        const didDrop = monitor.didDrop()
        console.log("name - ", name)
        console.log("Greeedy ", greedy)
        console.log("Did drop  ", didDrop)
        console.log("DROP RESULT ", _item)
        if (didDrop && !greedy) {
            // console.log("not greedy")
            // console.log("has Dropped ", hasDropped)
            // console.log("has Dropped on Children ", hasDroppedOnChild)
            return
        }
        // console.log("Setting has dropped to true")
        setHasDropped(true)
        // console.log("Setting has dropped on child to true")
        setHasDroppedOnChild(didDrop)
        if(items.length > 0) {
            
            addItems(prevState => {
                    // console.log("Previtems ---- ", prevState)
                    let initItems = [...prevState]
                    return [...initItems, _item]
                }
            )
        } else {
            // console.log("Zerroooo")
            addItems([_item])
        }
        
      },
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        // didDrop: monitor.didDrop(),
        isOverCurrent: monitor.isOver({ shallow: true }),
      }),
    }),
    [greedy, hasDropped, hasDroppedOnChild, items],
  )
  const text = greedy ? 'greedy' : 'not greedy'
  let backgroundColor = 'rgba(0, 0, 0, .5)'
  if (isOverCurrent || (isOver && greedy)) {
    backgroundColor = 'darkgreen'
  }

  const saveClicked = () => {
    console.log("Save clicked")
    console.log(items)
  }

  const setConditionDropped = (val, item) => {
    console.log("VALLL ==== ", val)
    addItems((prevItems) => {
        let newItems = item.join(' ').replace(/,/g, '')
        setCondition(newItems)
        prevItems[val].dropped = true;
        prevItems.condition = newItems;
        return prevItems 
    })
  }

  const setExecutionItemsDropped = (val, item) => {
    let currItems = [...items];
    // let newItems = [...items, item]
    currItems[val].execute = item;
    addItems(prevItems => {
        
        currItems[val].execute = item;
        return currItems
    })


  }

  console.log("condition  ====" , condition)
  console.log("Exceute items ==== ", items)
//   console.log("HAS Dropped on CHiled == ", hasDroppedOnChild)
  return (
    <div ref={drop} style={getStyle(backgroundColor)}>
      {/* text */}
      
      
      {items.length > 0 ? items.map((item, i) => {
        console.log("SDFSD ", item)
        if(item.droppable && item.dropped){
            // console.log("YESA drioooabke")
            return (
                <div key={i}>
                <div  style={{display: "flex"}}> {item.name} ( <NewCanvas2 prevItem={i} setConditionDropped={setConditionDropped} newCanvasUndo={workspaceUndoBtn} /> ) <div style={{ fontSize: "12px" }}> Delete </div> </div>
                    <NewCanvas2 prevItem={i} setExecutionItemsDropped={setExecutionItemsDropped} />
                </div>
            )
        } else if(item.droppable && !item.dropped) {
            return (
                <div key={i}>
                <div  style={{display: "flex"}}> {item.name} ( <NewCanvas2 prevItem={i} setConditionDropped={setConditionDropped} newCanvasUndo={workspaceUndoBtn} /> ) <div style={{ fontSize: "12px" }}> Delete </div> </div>
                  
                </div>
            )
        } else {
            return (<div key={i}> {item.name} </div>)
        }
        }) : ""}
      <br />
      {/* 
        Mainly used if you want to show that item has been dropped in a child. 
        This for the first one in the documentation */}
      {/* hasDropped && <p>dropped {hasDroppedOnChild && ' on child'}</p> */}

      <div>{children}</div>
    </div>
  )
}
