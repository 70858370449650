import uniqid from "uniqid";

const ThreeDCharts = (editor, options) => {
	console.log('options', options);

	const vis = require("vis-graph3d");
	// console.log(vis);

	// adding its block in the block manager
	editor.Blocks.add('3d-chart', {
		label: "3D Graphs",
        category: "Charts",
        select: true,
        media: '<svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" viewBox="0 0 16 16"><path d="M7.765 1.559a.5.5 0 0 1 .47 0l7.5 4a.5.5 0 0 1 0 .882l-7.5 4a.5.5 0 0 1-.47 0l-7.5-4a.5.5 0 0 1 0-.882l7.5-4z"/>  <path d="m2.125 8.567-1.86.992a.5.5 0 0 0 0 .882l7.5 4a.5.5 0 0 0 .47 0l7.5-4a.5.5 0 0 0 0-.882l-1.86-.992-5.17 2.756a1.5 1.5 0 0 1-1.41 0l-5.17-2.756z"/></svg>',
        // content: `<div id="3d-chart" class="3d-chart" style="width: 500px; height: 500px;" data-gjs-type="3d-chart"></div>`,
        content: {type: "3d-chart-container"},
	});

	if (editor !== null && editor !== undefined) {
	    const defaultType = editor.DomComponents.getType("default");
	    const defaultModel = defaultType.model;
	    const defaultView = defaultType.view;

	    editor.DomComponents.addType("3d-chart-container", {
	    	model: {
	    		defaults: {
	    			tagName: 'div',
	    			attributes: {
	    				class: "3d-chart-container",
	    				id: "3d-chart-container",
	    				style: 'width: max-content',
	    			},

	    			components: [
						{
							type: "3d-chart-uf",
						}, {
							type: "3d-chart",
						},
					],

					traits: ["id"],
	    		},
	    	},
	    });

	    editor.DomComponents.addType("3d-chart-uf", {
	    	model: {
	    		defaults: {
	    			tagName: "span",
	    			attributes: {
	    				id: "3d-chart-uf",
	    				class: "3d-chart-uf",
	    				style: "width: max-content;",
	    			},

	    			components: [
	    				{
	    					tagName: "select",
	    					attributes: {
			    				id: "3d-chart-uf-opts",
			    				name: "3d-chart-uf-opts",
			    				class: "3d-chart-uf-opts",
			    			},

			    			components: [
			    				{
			    					tagName: "option",
			    					attributes: {
			    						value: "",
			    						disabled: "true",
			    						selected: "true",
			    					},

			    					components: [
										{
								            tagName: "p",
								            type: "text",
								            editable: true,

								            components: {
								                type: "textnode",
								                content: "-uf-",
								            },
								        },
									],
			    				}, {
			    					tagName: "option",
			    					type: "uf-option",
			    					attributes: {
			    						value: "5000",
			    					},

			    					components: [
										{
								            tagName: "p",
								            type: "text",
								            editable: true,

								            components: {
								                type: "textnode",
								                content: "5s",
								            },
								        },
									],
			    				}, {
			    					tagName: "option",
			    					type: "uf-option",
			    					attributes: {
			    						value: "10000",
			    					},

			    					components: [
										{
								            tagName: "p",
								            type: "text",
								            editable: true,

								            components: {
								                type: "textnode",
								                content: "10s",
								            },
								        },
									],
			    				}, {
			    					tagName: "option",
			    					type: "uf-option",
			    					attributes: {
			    						value: "20000",
			    					},

			    					components: [
										{
								            tagName: "p",
								            type: "text",
								            editable: true,

								            components: {
								                type: "textnode",
								                content: "20s",
								            },
								        },
									],
			    				}, {
			    					tagName: "option",
			    					attributes: {
			    						value: "30000",
			    					},

			    					components: [
										{
								            tagName: "p",
								            type: "text",
								            editable: true,

								            components: {
								                type: "textnode",
								                content: "30s",
								            },
								        },
									],
			    				}, {
			    					tagName: "option",
			    					attributes: {
			    						value: "60000",
			    					},

			    					components: [
										{
								            tagName: "p",
								            type: "text",
								            editable: true,

								            components: {
								                type: "textnode",
								                content: "60s",
								            },
								        },
									],
			    				}, {
			    					tagName: "option",
			    					attributes: {
			    						value: "120000",
			    					},

			    					components: [
										{
								            tagName: "p",
								            type: "text",
								            editable: true,

								            components: {
								                type: "textnode",
								                content: "2min",
								            },
								        },
									],
			    				}, {
			    					tagName: "option",
			    					attributes: {
			    						value: "300000",
			    					},

			    					components: [
										{
								            tagName: "p",
								            type: "text",
								            editable: true,

								            components: {
								                type: "textnode",
								                content: "5min",
								            },
								        },
									],
			    				}, {
			    					tagName: "option",
			    					attributes: {
			    						value: "600000",
			    					},

			    					components: [
										{
								            tagName: "p",
								            type: "text",
								            editable: true,

								            components: {
								                type: "textnode",
								                content: "10min",
								            },
								        },
									],
			    				}, {
			    					tagName: "option",
			    					attributes: {
			    						value: "Stop",
			    					},

			    					components: [
										{
								            tagName: "p",
								            type: "text",
								            editable: true,

								            components: {
								                type: "textnode",
								                content: "Stop",
								            },
								        },
									],
			    				},
			    			],

			    			// traits
			    			traits: ["id"],
			    			changeProp: true,
	    				},
	    			],

	    			traits: [
	    				"id",
	    			],
			    	changeProp: true,
	    		},
	    	},
	    });

		editor.DomComponents.addType("3d-chart", {
			model: {
		        defaults: {
		        	tagName: "div",
					attributes: {
						// id: "3d-chart",
						class: "3d-chart",
						// style: "width: 500px; height: 500px;",
						'data-gjs-type': "3d-chart",
					},

					"style-default": {
						width: '500px',
						height: '500px',
					},

					style: {
						width: '500px',
						height: '500px',
					},

		           	script: function (props) {
		           		console.log('this', this);
		           		let container = this;
		           		let idEl = this.id;
						const url = window.top.location.href;
						const isSubstringPresent = (url.indexOf("/editor/") !== -1 && url.indexOf("?projectId=") !== -1) || url.indexOf("/large_preview/") !== -1 || url.indexOf("/tab_preview/") !== -1 || url.indexOf("/mobile_preview/") !== -1 || url.indexOf("/fragment_editor/") !== -1;

			            const initLib = async function () {
	              			console.log('props.BaseVars', props.BaseVars);

	              			// base url bearer token taken directly from local storage after getting downloaded
							let baseUrlData, dynamicBaseUrlBearerToken, baseUrl1BearerToken, baseUrl2BearerToken, baseUrl3BearerToken, baseUrl4BearerToken, baseUrl5BearerToken;
							// getting baseUrl data from local storage for run-time access
							let baseUrlDataFromStorage = localStorage.getItem("baseUrlData");
							if (baseUrlDataFromStorage) {
								baseUrlData = JSON.parse(baseUrlDataFromStorage);
							}
							// extracting token values from base URLs from baseUrlData
							if (baseUrlData) {
								try {
									if (`baseUrl1_${props.projectId}` in baseUrlData) {
										baseUrl1BearerToken = baseUrlData[`baseUrl1_${props.projectId}`].token;
									}
									if (`baseUrl2_${props.projectId}` in baseUrlData) {
										baseUrl2BearerToken = baseUrlData[`baseUrl2_${props.projectId}`].token;
									}
									if (`baseUrl3_${props.projectId}` in baseUrlData) {
										baseUrl3BearerToken = baseUrlData[`baseUrl3_${props.projectId}`].token;
									}
									if (`baseUrl4_${props.projectId}` in baseUrlData) {
										baseUrl4BearerToken = baseUrlData[`baseUrl4_${props.projectId}`].token;
									}
									if (`baseUrl5_${props.projectId}` in baseUrlData) {
										baseUrl5BearerToken = baseUrlData[`baseUrl5_${props.projectId}`].token;
									}
								} catch (e) {
									// statements
									console.log(e);
								}
							}

			            	try {
			            		console.log('props:', props);
								var data = null;
							    var graph = null;
							    let apiData;

							    let GraphAPI = props.CustomUrl;
				                // to rename a variable because API var is getting used in many places in this block scope
					            let API;
					            if (props.APIPath) {
					              API = props.APIPath;
					            }

							    // helper function to check if a variable is an array-of-objects or not
				            	function isArrayofObjects(variable) {
									// Check if the variable is an array
									if (!Array.isArray(variable)) {
									    return false;
									}

									// Check if all elements in the array are objects
									for (const element of variable) {
									    if (typeof element !== 'object' || Array.isArray(element) || element === null) {
									      return false;
									    }
									}
									return true;
								}

								// helper function to get value of a key in nested object
								function findKeyValue(obj, key) {
								    if (obj.hasOwnProperty(key)) {
								        // If the current object has the key, return its value
								        return obj[key];
								    }

								    for (var prop in obj) {
								        if (obj.hasOwnProperty(prop) && typeof obj[prop] === 'object') {
								            // If the current property is an object, recursively search within it
								            var result = findKeyValue(obj[prop], key);
								            if (result !== undefined) {
								                return result;
								            }
								        }
								    }
								    // Key not found in the object
								    return undefined;
								}

							    // Called when the Visualization API is loaded to collect data for plotting.
							    async function drawVisualization() {
							    	// Create and populate a data table.
							    	if (vis)
							        	data = new vis.DataSet();

							    	// to populate dataset - check if its an array-of-objects
							    	if (apiData !== undefined && isArrayofObjects(apiData)) {
							    		if (props.Abscissa && props.Ordinate && props.Applicate) {
							    			console.log('plotting array of objects');
								    		try {
								    			// apiData.forEach((obj, idx) => {
								    			// 	data.add({
								    			// 		id: idx,
								    			// 		x: obj[`${props.Abscissa}`],
								    			// 		y: obj[`${props.Ordinate}`],
								    			// 		z: obj[`${props.Applicate}`],
								    			// 	});
								    			// });

								    			for (let i = 0; i < apiData.length; i++) {
								    				data.add({
								    					id: i,
								    					x: Number(apiData[i][`${props.Abscissa}`]),
								    					y: Number(apiData[i][`${props.Ordinate}`]),
								    					z: Number(apiData[i][`${props.Applicate}`]),
								    				});

								    				// data.push({
								    				// 	x: apiData[i][`${props.Abscissa}`],
								    				// 	y: apiData[i][`${props.Ordinate}`],
								    				// 	z: apiData[i][`${props.Applicate}`],
								    				// });
								    			}
								    		} catch(e) {
								    			console.log(e);
								    		}
							    		}
							    	}

							    	// to populate dataset - check if its an object & not an array-of-objects
							    	else if (apiData !== undefined && typeof apiData === 'object' && !isArrayofObjects(apiData)) {
							    		// first check if these values are provided or not / if no nested array-of-objects is not to be plotted by the user
							    		if (props.Abscissa && props.Ordinate && props.Applicate && !props.NestedArrOfObj) {
								    		// by checking the type of these values, make the if-cases
								    		let abscissaVal = findKeyValue(apiData, props.Abscissa);
								    		let ordinateVal = findKeyValue(apiData, props.Ordinate);
								    		let applicateVal = findKeyValue(apiData, props.Applicate);
		
								    		// if these values of keynames are single numbers, not arrays of anything or objects
								    		if (typeof abscissaVal !== 'object' && !isArrayofObjects(abscissaVal) && typeof ordinateVal !== 'object' && !isArrayofObjects(ordinateVal) && typeof applicateVal !== 'object' && !isArrayofObjects(applicateVal) ) {
								    			console.log('plotting single values, in an object');
								    			try {
								    				// just add these values as 1 object to vis.Dataset
									    			for (let i = 0; i < 1; i++) {
									    				data.add({ id: i, x: Number(abscissaVal), y: Number(ordinateVal), z: Number(applicateVal) });
									    			}
								    			} catch(e) {
								    				console.log(e);
								    			}						    			
								    		}
								    		// if these values of keynames are arrays of single values, not just single values or array of objects
								    		else if (Array.isArray(abscissaVal) && !isArrayofObjects(abscissaVal) && Array.isArray(ordinateVal) && !isArrayofObjects(ordinateVal) && Array.isArray(applicateVal) && !isArrayofObjects(applicateVal) ) {
								    			console.log('plotting array of single values, in an object');
								    			try {
								    				for (let i = 0; i <= abscissaVal.length; i++) {
								    					data.add({
								    						id: i,
								    						x: Number(abscissaVal[i]),
								    						y: Number(ordinateVal[i]),
								    						z: Number(applicateVal[i]),
								    					});
								    				}
								    			} catch(e) {
								    				console.log(e);
								    			}
								    		}
								    		// if these values are an object, not array-of-objects
								    		else if ((typeof abscissaVal === 'object' && !Array.isArray(abscissaVal)) && (typeof ordinateVal === 'object' && !Array.isArray(ordinateVal)) && (typeof applicateVal === 'object' && !Array.isArray(applicateVal))) {
								    			console.log('###');
					                        	// if both their lengths are equal, then plot
					                        	if (Object.keys(abscissaVal).length === Object.keys(ordinateVal).length && Object.keys(applicateVal).length === Object.keys(applicateVal).length) {
					                        		for (let i = 0; i <= Object.keys(abscissaVal).length; i++) {
								    					data.add({
								    						id: i,
								    						x: Number(abscissaVal[`${i}`]),
								    						y: Number(ordinateVal[`${i}`]),
								    						z: Number(applicateVal[`${i}`]),
								    					});
								    				}
					                        	}
								    		}
								    	}
								    	// if there is a nested array-of-objects in the response object and user wants to plot that
								    	else if (props.Abscissa && props.Ordinate && props.Applicate && props.NestedArrOfObj) {
								    		let nestedArrOfObjVal = findKeyValue(apiData, props.NestedArrOfObj);
								    		console.log('nestedArrOfObjVal:', nestedArrOfObjVal);

								    		// if this value of key is an array-of-objects
								    		if (isArrayofObjects(nestedArrOfObjVal)) {
								    			console.log('plotting nested array-of-objects, in an object');
								    			try {
								    				for (let i = 0; i < nestedArrOfObjVal.length; i++) {
									    				data.add({
									    					id: i,
									    					x: Number(nestedArrOfObjVal[i][`${props.Abscissa}`]),
									    					y: Number(nestedArrOfObjVal[i][`${props.Ordinate}`]),
									    					z: Number(nestedArrOfObjVal[i][`${props.Applicate}`]),
									    				});

									    				// data.push({
									    				// 	x: nestedArrOfObjVal[i][`${props.Abscissa}`],
									    				// 	y: nestedArrOfObjVal[i][`${props.Ordinate}`],
									    				// 	z: nestedArrOfObjVal[i][`${props.Applicate}`],
									    				// });
									    			}
								    			} catch(e) {
								    				console.log(e);
								    			}
								    		}
								    	}
							    	}

							        console.log('plotting data');
							    }

							    let apiMethod = props.APIMethod;
	                			let apiBody = props.APIBody;

	                			// GET method
							    if (GraphAPI && (!props.BaseUrl || props.BaseUrl === 'null') && apiMethod === 'GET') {
							    	if (props.BearerToken) {
							    		if (props.MoreHeaders) {
							    			// add extra headers, if needed
							    			let config = {
							    				headers: {
									    			'Content-Type': 'application/json',
									    			Authorization: `Bearer ${props.BearerToken}`,
									    		},
							    			};

							    			// ['a:b', "c:d"]
						                    // extracting headers from More_Headers trait
					                        props.MoreHeaders.split(",").forEach((header) => {
						                        let key, val;
						                        [key, val] = header.split(":");
						                        console.log("[key, val]:\n", key, val);

						                        config.headers[key] = val;
					                        });

						                    console.log("config:", config);

							    			// GET call to get the data
									    	let response = await fetch(`${GraphAPI}`, config);
									    	let responseData = await response.json();
									    	apiData = responseData;
							    		} else {
							    			// if no extra-headers provided
							    			// GET call to get the data
									    	let response = await fetch(`${GraphAPI}`, {
									    		headers: {
									    			'Content-Type': 'application/json',
									    			Authorization: `Bearer ${props.BearerToken}`,
									    		},
									    	});
									    	let responseData = await response.json();
									    	apiData = responseData;
							    		}
								    } else {
								    	if (props.MoreHeaders) {
								    		// add extra headers, if needed
							    			let config = {
							    				headers: {},
							    			};

							    			// ['a:b', "c:d"]
						                    // extracting headers from More_Headers trait
					                        props.MoreHeaders.split(",").forEach((header) => {
						                        let key, val;
						                        [key, val] = header.split(":");
						                        console.log("[key, val]:\n", key, val);

						                        config.headers[key] = val;
					                        });

						                    console.log("config:", config);

								    		// GET call to get the data
									    	let response = await fetch(`${GraphAPI}`, config);
									    	let responseData = await response.json();
									    	apiData = responseData;
								    	} else {
								    		// if no extra-headers provided
								    		// GET call to get the data
									    	let response = await fetch(`${GraphAPI}`);
									    	let responseData = await response.json();
									    	apiData = responseData;
								    	}
								    }
	    						} else if (props.BaseUrl && API && !GraphAPI && apiMethod === 'GET') {
	    							let url, baseUrlBearerToken;
						              let forDownloadUrl;
						              if (props.BaseUrl === 'baseUrl1') {
						                url = props.BaseVars.baseUrl1 + API;
										forDownloadUrl = "{{API_URL1}}" + API;
						                // baseUrlBearerToken = props.BaseVars.baseUrl1BearerToken;
						                baseUrlBearerToken = baseUrl1BearerToken;
						              } else if (props.BaseUrl === 'baseUrl2') {
						                url = props.BaseVars.baseUrl2 + API;
										forDownloadUrl = "{{API_URL2}}" + API;
						                // baseUrlBearerToken = props.BaseVars.baseUrl2BearerToken;
						                baseUrlBearerToken = baseUrl2BearerToken;
						              } else if (props.BaseUrl === 'baseUrl3') {
						                url = props.BaseVars.baseUrl3 + API;
										forDownloadUrl = "{{API_URL3}}" + API;
						                // baseUrlBearerToken = props.BaseVars.baseUrl3BearerToken;
						                baseUrlBearerToken = baseUrl3BearerToken;
						              } else if (props.BaseUrl === 'baseUrl4') {
						                url = props.BaseVars.baseUrl4 + API;
										forDownloadUrl = "{{API_URL4}}" + API;
						                // baseUrlBearerToken = props.BaseVars.baseUrl4BearerToken;
						                baseUrlBearerToken = baseUrl4BearerToken;
						              } else if (props.BaseUrl === 'baseUrl5') {
						                url = props.BaseVars.baseUrl5 + API;
										forDownloadUrl = "{{API_URL5}}" + API;
						                // baseUrlBearerToken = props.BaseVars.baseUrl5BearerToken;
						                baseUrlBearerToken = baseUrl5BearerToken;
						              }

						              const urlDownload = window.top.location.href;
						              const isSubstringPresent = (urlDownload.indexOf("/editor/") !== -1 && urlDownload.indexOf("?projectId=") !== -1) || urlDownload.indexOf("/large_preview/") !== -1 || urlDownload.indexOf("/tab_preview/") !== -1 || urlDownload.indexOf("/mobile_preview/") !== -1 || urlDownload.indexOf("/fragment_editor/") !== -1;
				       			 		if (!isSubstringPresent) {
				       			 			// running in download
				       			 			url = forDownloadUrl;
				       			 		}

						              console.log('url, baseUrlBearerToken', url, baseUrlBearerToken);

							    	if (baseUrlBearerToken && !props.BearerToken) {
							    		if (props.MoreHeaders) {
							    			// add extra headers, if needed
							    			let config = {
							    				headers: {
									    			'Content-Type': 'application/json',
									    			Authorization: `Bearer ${baseUrlBearerToken}`,
									    		},
							    			};

							    			// ['a:b', "c:d"]
						                    // extracting headers from More_Headers trait
					                        props.MoreHeaders.split(",").forEach((header) => {
						                        let key, val;
						                        [key, val] = header.split(":");
						                        console.log("[key, val]:\n", key, val);

						                        config.headers[key] = val;
					                        });

						                    console.log("config:", config);

							    			// GET call to get the data
									    	let response = await fetch(`${url}`, config);
									    	let responseData = await response.json();
									    	apiData = responseData;
							    		} else {
							    			// if no extra-headers provided
							    			// GET call to get the data
									    	let response = await fetch(`${url}`, {
									    		headers: {
									    			'Content-Type': 'application/json',
									    			Authorization: `Bearer ${baseUrlBearerToken}`,
									    		},
									    	});
									    	let responseData = await response.json();
									    	apiData = responseData;
							    		}
								    } else {
								    	if (props.MoreHeaders) {
								    		// add extra headers, if needed
							    			let config = {
							    				headers: {},
							    			};

							    			// ['a:b', "c:d"]
						                    // extracting headers from More_Headers trait
					                        props.MoreHeaders.split(",").forEach((header) => {
						                        let key, val;
						                        [key, val] = header.split(":");
						                        console.log("[key, val]:\n", key, val);

						                        config.headers[key] = val;
					                        });

						                    console.log("config:", config);

								    		// GET call to get the data
									    	let response = await fetch(`${url}`, config);
									    	let responseData = await response.json();
									    	apiData = responseData;
								    	} else {
								    		// if no extra-headers provided
								    		// GET call to get the data
									    	let response = await fetch(`${url}`);
									    	let responseData = await response.json();
									    	apiData = responseData;
								    	}
								    }
	    						}

	    						// POST method
							    else if (GraphAPI && (!props.BaseUrl || props.BaseUrl === 'null') && apiMethod === 'POST') {
							    	if (props.BearerToken) {
							    		if (props.MoreHeaders) {
							    			// add extra headers, if needed
							    			let config = {
						                      	method: "POST",
						                      	body: apiBody,
							    				headers: {
									    			'Content-Type': 'application/json',
									    			Authorization: `Bearer ${props.BearerToken}`,
									    		},
							    			};

							    			// ['a:b', "c:d"]
						                    // extracting headers from More_Headers trait
					                        props.MoreHeaders.split(",").forEach((header) => {
						                        let key, val;
						                        [key, val] = header.split(":");
						                        console.log("[key, val]:\n", key, val);

						                        config.headers[key] = val;
					                        });

						                    console.log("config:", config);

							    			// GET call to get the data
									    	let response = await fetch(`${GraphAPI}`, config);
									    	let responseData = await response.json();
									    	apiData = responseData;
							    		} else {
							    			// if no extra-headers provided
							    			// GET call to get the data
									    	let response = await fetch(`${GraphAPI}`, {
						                      	method: "POST",
						                      	body: apiBody,
									    		headers: {
									    			'Content-Type': 'application/json',
									    			Authorization: `Bearer ${props.BearerToken}`,
									    		},
									    	});
									    	let responseData = await response.json();
									    	apiData = responseData;
							    		}
								    } else {
								    	if (props.MoreHeaders) {
								    		// add extra headers, if needed
							    			let config = {
					                      	method: "POST",
					                      	body: apiBody,
							    				headers: {},
							    			};

							    			// ['a:b', "c:d"]
						                    // extracting headers from More_Headers trait
					                        props.MoreHeaders.split(",").forEach((header) => {
						                        let key, val;
						                        [key, val] = header.split(":");
						                        console.log("[key, val]:\n", key, val);

						                        config.headers[key] = val;
					                        });

						                    console.log("config:", config);

								    		// GET call to get the data
									    	let response = await fetch(`${GraphAPI}`, config);
									    	let responseData = await response.json();
									    	apiData = responseData;
								    	} else {
								    		// if no extra-headers provided
								    		// GET call to get the data
									    	let response = await fetch(`${GraphAPI}`, {									    		
						                      	method: "POST",
						                      	body: apiBody,
									    	});
									    	let responseData = await response.json();
									    	apiData = responseData;
								    	}
								    }
	    						} else if (props.BaseUrl && API && !GraphAPI && apiMethod === 'POST') {
	    							let url, baseUrlBearerToken;
						              let forDownloadUrl;
						              if (props.BaseUrl === 'baseUrl1') {
						                url = props.BaseVars.baseUrl1 + API;
										forDownloadUrl = "{{API_URL1}}" + API;
						                // baseUrlBearerToken = props.BaseVars.baseUrl1BearerToken;
						                baseUrlBearerToken = baseUrl1BearerToken;
						              } else if (props.BaseUrl === 'baseUrl2') {
						                url = props.BaseVars.baseUrl2 + API;
										forDownloadUrl = "{{API_URL2}}" + API;
						                // baseUrlBearerToken = props.BaseVars.baseUrl2BearerToken;
						                baseUrlBearerToken = baseUrl2BearerToken;
						              } else if (props.BaseUrl === 'baseUrl3') {
						                url = props.BaseVars.baseUrl3 + API;
										forDownloadUrl = "{{API_URL3}}" + API;
						                // baseUrlBearerToken = props.BaseVars.baseUrl3BearerToken;
						                baseUrlBearerToken = baseUrl3BearerToken;
						              } else if (props.BaseUrl === 'baseUrl4') {
						                url = props.BaseVars.baseUrl4 + API;
										forDownloadUrl = "{{API_URL4}}" + API;
						                // baseUrlBearerToken = props.BaseVars.baseUrl4BearerToken;
						                baseUrlBearerToken = baseUrl4BearerToken;
						              } else if (props.BaseUrl === 'baseUrl5') {
						                url = props.BaseVars.baseUrl5 + API;
										forDownloadUrl = "{{API_URL5}}" + API;
						                // baseUrlBearerToken = props.BaseVars.baseUrl5BearerToken;
						                baseUrlBearerToken = baseUrl5BearerToken;
						              }

						              const urlDownload = window.top.location.href;
						              const isSubstringPresent = (urlDownload.indexOf("/editor/") !== -1 && urlDownload.indexOf("?projectId=") !== -1) || urlDownload.indexOf("/large_preview/") !== -1 || urlDownload.indexOf("/tab_preview/") !== -1 || urlDownload.indexOf("/mobile_preview/") !== -1 || urlDownload.indexOf("/fragment_editor/") !== -1;
				       			 		if (!isSubstringPresent) {
				       			 			// running in download
				       			 			url = forDownloadUrl;
				       			 		}

						              console.log('url, baseUrlBearerToken', url, baseUrlBearerToken);

							    	if (baseUrlBearerToken && !props.BearerToken) {
							    		if (props.MoreHeaders) {
							    			// add extra headers, if needed
							    			let config = {
						                      	method: "POST",
						                      	body: apiBody,
							    				headers: {
									    			'Content-Type': 'application/json',
									    			Authorization: `Bearer ${baseUrlBearerToken}`,
									    		},
							    			};

							    			// ['a:b', "c:d"]
						                    // extracting headers from More_Headers trait
					                        props.MoreHeaders.split(",").forEach((header) => {
						                        let key, val;
						                        [key, val] = header.split(":");
						                        console.log("[key, val]:\n", key, val);

						                        config.headers[key] = val;
					                        });

						                    console.log("config:", config);

							    			// GET call to get the data
									    	let response = await fetch(`${url}`, config);
									    	let responseData = await response.json();
									    	apiData = responseData;
							    		} else {
							    			// if no extra-headers provided
							    			// GET call to get the data
									    	let response = await fetch(`${url}`, {
						                      	method: "POST",
						                      	body: apiBody,
									    		headers: {
									    			'Content-Type': 'application/json',
									    			Authorization: `Bearer ${baseUrlBearerToken}`,
									    		},
									    	});
									    	let responseData = await response.json();
									    	apiData = responseData;
							    		}
								    } else {
								    	if (props.MoreHeaders) {
								    		// add extra headers, if needed
							    			let config = {
						                      	method: "POST",
						                      	body: apiBody,
							    				headers: {},
							    			};

							    			// ['a:b', "c:d"]
						                    // extracting headers from More_Headers trait
					                        props.MoreHeaders.split(",").forEach((header) => {
						                        let key, val;
						                        [key, val] = header.split(":");
						                        console.log("[key, val]:\n", key, val);

						                        config.headers[key] = val;
					                        });

						                    console.log("config:", config);

								    		// GET call to get the data
									    	let response = await fetch(`${url}`, config);
									    	let responseData = await response.json();
									    	apiData = responseData;
								    	} else {
								    		// if no extra-headers provided
								    		// GET call to get the data
									    	let response = await fetch(`${url}`, {									    		
						                      	method: "POST",
						                      	body: apiBody,
									    	});
									    	let responseData = await response.json();
									    	apiData = responseData;
								    	}
								    }
	    						}
						    	
						    	// call drawVisualization when these traits are provided so that the graph can only be plotted when all the values are present in the vis.Dataset
						    	if (props.Abscissa && props.Ordinate && props.Applicate) {
						    		console.log('3d chart apiData:', apiData);
	    						    drawVisualization();
	    						}

						   		// specify options
						        var options = {
						        	// the width and height of chart, not the <div> containing it
							        width: "100%",
							        height: "100%",
							        style: props.ChartType ? props.ChartType : "line",
							        showPerspective: true,
							        showGrid: true,
							        showShadow: false,
							        keepAspectRatio: true,
							        tooltip: true,
							        verticalRatio: 0.5,
						        };

						        // Instantiate our graph object.
						        // var mainContainer = document.getElementById("3d-chart-container");
						        // var container = document.getElementById("3d-chart");
						        if (data !== null)
						        	graph = new vis.Graph3d(container, data, options);

						        console.log('plotted data:', data);

		              			document.addEventListener('mouseup', function(event) {
		              				console.log("event.target", event.target, event.target.tagName);
								  	if (event.target.tagName === 'CANVAS') {
									  	event.stopPropagation();
									  	console.log('++ stop ++');
									}
								});
			            	} catch(e) {
			            		// statements
			            		console.error(e);
			            	}
			            	

					        // if (data !== null)
					        // 	container.setAttribute('id', `3d-${Date.now()}-chart`);
			            };
	              
		                // to update chart props as soon as window props is updated and then trigger rendering so that the chart updates
						if (!isSubstringPresent) {
					        setInterval(function () {
					          Object.keys(props).forEach(function (key) {
					            if (window[`${key}${idEl}`]) {
					              if(props[key]===window[`${key}${idEl}`]){
					                // console.log('');
					              }else{
					                props[key] = window[`${key}${idEl}`];
					               	initLib();
					              }
					            }
					          });
					        }, 500);
					    }

			            if (typeof vis == "undefined") {
			                const script = document.createElement("script");
			                script.onload = initLib;
			                script.src = "https://unpkg.com/vis-graph3d@latest/dist/vis-graph3d.min.js";
			                document.body.appendChild(script);
			            } else {
			                console.log("InitLib");
			                initLib();

			                let updateFrequencyValue, updateFrequencyRef;
		            		let updateFrequency = (props.UpdateFrequencyDropdownID) ? document.getElementById(`${props.UpdateFrequencyDropdownID}`) : document.getElementById('3d-chart-uf-opts');
		            			
		            		if (updateFrequency) {
			            		updateFrequency.addEventListener('change', (event) => {
			            			console.log('updateFrequency value:', updateFrequency.value);
			            			updateFrequencyValue = updateFrequency.value;

			            			// Clear the previous interval if it exists
								    if (updateFrequencyRef) {
								        clearInterval(updateFrequencyRef);
								    }

								    if (updateFrequencyValue !== 'Stop') {
								        // Set a new interval
								        updateFrequencyRef = setInterval(initLib, parseInt(updateFrequencyValue)); // Ensure updateFrequencyValue is a number
								    } else {
								        console.log('STOPPED & running one last time');
								        initLib();
								    }

								    console.log('updateFrequencyValue, updateFrequencyRef', updateFrequencyValue, updateFrequencyRef);
			            		});
		            		}
		            		
			            }
		            },

		            // traits
		            CustomUrl: "",
		            BaseUrl: "",
		            APIPath: "",
		            APIMethod: "GET",
		            BearerToken: "",
		            MoreHeaders: "",
		            APIBody: "",
		            NestedArrOfObj: "",
		            Abscissa: "",
		            Ordinate: "",
		            Applicate: "",
		            ChartType: "",
		            UpdateFrequencyDropdownID: "3d-chart-uf-opts",
		            // StopUpdate: "",
		            BaseVars: options,

		            traits: [
			            {
			                type: "text",
			                name: "CustomUrl",
			                label: "Custom URL",
			                changeProp: true,
			              },
			              {
			                type: "select",
			                name: "BaseUrl",
			                label: "Base URL",
			                options: [
			                  { id: "baseUrl1", name: "Base URL #1" },
			                  { id: "baseUrl2", name: "Base URL #2" },
			                  { id: "baseUrl3", name: "Base URL #3" },
			                  { id: "baseUrl4", name: "Base URL #4" },
			                  { id: "baseUrl5", name: "Base URL #5" },
			                  { id: "null", name: "No Base URL" },
			                ],
			                changeProp: true,
			              },
			              {
			                type: "text",
			                name: "APIPath",
			                label: "API Path",
			                placeholder: "Don't start with '/'",
			                changeProp: true,
			              },
			              {
			                type: "select",
			                name: "APIMethod",
			                label: "API Method",
			                options: [
			                  { id: "GET", name: "GET" },
			                  { id: "POST", name: "POST" },
			                ],
			                changeProp: true,
			              },
			            {
			                type: "text",
			                name: "BearerToken",
			                label: "Bearer Token",
			                placeholder: "Bearer",
			                changeProp: true,
			            },
			            {
			                type: "text",
			                name: "MoreHeaders",
			                label: "More Headers",
			                placeholder: "k1:v1,k2:v2,k3:v3",
			                changeProp: true,
			            },
			              {
			                type: "text",
			                name: "APIBody",
			                label: "API Body",
			                changeProp: true,
			              },
			            {
			                type: "text",
			                name: "NestedArrOfObj",
			                label: "Nested Array Of Object",
			                placeholder: "Nested array of object keyname",
			                changeProp: true,
			            },
			            {
			                type: "text",
			                name: "Abscissa",
			                label: "Abscissa",
			                changeProp: true,
			            },
			            {
			                type: "text",
			                name: "Ordinate",
			                label: "Ordinates",
			                changeProp: true,
			            },
			            {
			                type: "text",
			                name: "Applicate",
			                label: "Applicate",
			                changeProp: true,
			            },
			            {
			                type: "select",
			                name: "ChartType",
			                label: "Chart Type",
			                options: [
				                { value: "bar", name: "Bar" },
				                { value: "bar-color", name: "Bar-Color" },
				                { value: "bar-size", name: "Bar-Size" },
				                { value: "dot", name: "Dot" },
				                { value: "dot-line", name: "Dot-Line" },
				                // { value: "dot-color", name: "Dot-Color" },
				                // { value: "dot-size", name: "Dot-Size" },
				                { value: "line", name: "Line" },
				                { value: "grid", name: "Grid" },
				                { value: "surface", name: "Surface" },
			                ],
			                changeProp: true,
			            },
			            {
			                type: "text",
			                name: "UpdateFrequencyDropdownID",
			                label: "UpdateFrequency Dropdown ID",
			                placeholder: "For example: 3d-chart-uf-opts",
			                changeProp: true,
			            },
			            // {
			            //     type: "checkbox",
			            //     name: "StopUpdate",
			            //     label: "Stop Update",
			            //     changeProp: true,
			            // },
			            "id",
		            ],

		            changeProp: true,

		            "script-props": [
			            "CustomUrl",
			            "BaseUrl",
			            "APIPath",
			            "APIMethod",
			            "BearerToken",
			            "MoreHeaders",
			            "APIBody",
			            "BaseVars",
			            "NestedArrOfObj",
			            "Abscissa",
			            "Ordinate",
			            "Applicate",
			            "ChartType",
			            "UpdateFrequencyDropdownID",
			            // "StopUpdate",
		            ],
		        },

		        // init() {
		        // 	this.on('change:GraphAPI', this.handlePropChange);
		        // 	this.on('change:BearerToken', this.handlePropChange);
		        // 	this.on('change:MoreHeaders', this.handlePropChange);
		        // 	this.on('change:NestedArrOfObj', this.handlePropChange);
		        // 	this.on('change:Abscissa', this.handlePropChange);
		        // 	this.on('change:Ordinate', this.handlePropChange);
		        // 	this.on('change:Applicate', this.handlePropChange);
		        // 	this.on('change:ChartType', this.handlePropChange);
		        // },

		        // async handlePropChange() {
		        // 	const { GraphAPI, BearerToken, MoreHeaders, NestedArrOfObj, Abscissa, Ordinate, Applicate, ChartType } = this.props();
		        // 	console.log('GraphAPI, BearerToken, MoreHeaders, NestedArrOfObj, Abscissa, Ordinate, Applicate, ChartType:', GraphAPI, BearerToken, MoreHeaders, NestedArrOfObj, Abscissa, Ordinate, Applicate, ChartType);

		        // 	let container = this.getEl();
		        // 	console.log('container', container);
		        	
		        // 	const initLib = async function () {
		        // 		console.log('$$');
		        //     	try {
		        //     		// console.log('props:', props);
				// 			var data = [];
				// 		    var graph = null;
				// 		    let apiData;

				// 		    // helper function to check if a variable is an array-of-objects or not
			    //         	function isArrayofObjects(variable) {
				// 				// Check if the variable is an array
				// 				if (!Array.isArray(variable)) {
				// 				    return false;
				// 				}

				// 				// Check if all elements in the array are objects
				// 				for (const element of variable) {
				// 				    if (typeof element !== 'object' || Array.isArray(element) || element === null) {
				// 				      return false;
				// 				    }
				// 				}
				// 				return true;
				// 			}

				// 			// helper function to get value of a key in nested object
				// 			function findKeyValue(obj, key) {
				// 			    if (obj.hasOwnProperty(key)) {
				// 			        // If the current object has the key, return its value
				// 			        return obj[key];
				// 			    }

				// 			    for (var prop in obj) {
				// 			        if (obj.hasOwnProperty(prop) && typeof obj[prop] === 'object') {
				// 			            // If the current property is an object, recursively search within it
				// 			            var result = findKeyValue(obj[prop], key);
				// 			            if (result !== undefined) {
				// 			                return result;
				// 			            }
				// 			        }
				// 			    }
				// 			    // Key not found in the object
				// 			    return undefined;
				// 			}

				// 		    // Called when the Visualization API is loaded to collect data for plotting.
				// 		    async function drawVisualization() {
				// 		    	// Create and populate a data table.
				// 		        // data = new vis.DataSet();

				// 		    	// to populate dataset - check if its an array-of-objects
				// 		    	if (apiData !== undefined && isArrayofObjects(apiData)) {
				// 		    		if (Abscissa && Ordinate && Applicate) {
				// 		    			console.log('plotting array of objects');
				// 			    		try {
				// 			    			// apiData.forEach((obj, idx) => {
				// 			    			// 	data.add({
				// 			    			// 		id: idx,
				// 			    			// 		x: obj[`${props.Abscissa}`],
				// 			    			// 		y: obj[`${props.Ordinate}`],
				// 			    			// 		z: obj[`${props.Applicate}`],
				// 			    			// 	});
				// 			    			// });

				// 			    			for (let i = 0; i < apiData.length; i++) {
				// 			    				// data.add({
				// 			    				// 	id: i,
				// 			    				// 	x: Number(apiData[i][`${Abscissa}`]),
				// 			    				// 	y: Number(apiData[i][`${Ordinate}`]),
				// 			    				// 	z: Number(apiData[i][`${Applicate}`]),
				// 			    				// });

				// 			    				data.push({
				// 			    					x: Number(apiData[i][`${Abscissa}`]),
				// 			    					y: Number(apiData[i][`${Ordinate}`]),
				// 			    					z: Number(apiData[i][`${Applicate}`],)
				// 			    				});
				// 			    			}
				// 			    		} catch(e) {
				// 			    			console.log(e);
				// 			    		}
				// 		    		}
				// 		    	}

				// 		    	// to populate dataset - check if its an object & not an array-of-objects
				// 		    	else if (apiData !== undefined && typeof apiData === 'object' && !isArrayofObjects(apiData)) {
				// 		    		// first check if these values are provided or not / if no nested array-of-objects is not to be plotted by the user
				// 		    		if (Abscissa && Ordinate && Applicate && !NestedArrOfObj) {
				// 			    		// by checking the type of these values, make the if-cases
				// 			    		let abscissaVal = findKeyValue(apiData, Abscissa);
				// 			    		let ordinateVal = findKeyValue(apiData, Ordinate);
				// 			    		let applicateVal = findKeyValue(apiData, Applicate);
	
				// 			    		// if these values of keynames are single numbers, not arrays of anything or objects
				// 			    		if (
				// 			    			typeof abscissaVal !== 'object' && !isArrayofObjects(abscissaVal) &&
				// 			    			typeof ordinateVal !== 'object' && !isArrayofObjects(ordinateVal) &&
				// 			    			typeof applicateVal !== 'object' && !isArrayofObjects(applicateVal)
				// 			    		) {
				// 			    			console.log('plotting single values, in an object');
				// 			    			try {
				// 			    				// just add these values as 1 object to vis.Dataset
				// 				    			for (let i = 0; i < 1; i++) {
				// 				    				// data.add({ id: i, x: abscissaVal, y: ordinateVal, z: applicateVal });
								    				
				// 				    				data.push({ x: Number(abscissaVal), y: Number(ordinateVal), z: Number(applicateVal) });
				// 				    			}
				// 			    			} catch(e) {
				// 			    				console.log(e);
				// 			    			}						    			
				// 			    		}
				// 			    		// if these values of keynames are arrays of single values, not single values or array-of-objects
				// 			    		else if (
				// 			    			Array.isArray(abscissaVal) && !isArrayofObjects(abscissaVal) &&
				// 			    			Array.isArray(ordinateVal) && !isArrayofObjects(ordinateVal) &&
				// 			    			Array.isArray(applicateVal) && !isArrayofObjects(applicateVal)
				// 			    		) {
				// 			    			console.log('plotting array of single values, in an object');
				// 			    			try {
				// 			    				for (let i = 0; i <= abscissaVal.length; i++) {
				// 			    					// data.add({
				// 			    					// 	id: i,
				// 			    					// 	x: Number(abscissaVal[i]),
				// 			    					// 	y: Number(ordinateVal[i]),
				// 			    					// 	z: Number(applicateVal[i]),
				// 			    					// });

				// 			    					data.push({
				// 			    						x: Number(abscissaVal[i]),
				// 			    						y: Number(ordinateVal[i]),
				// 			    						z: Number(applicateVal[i]),
				// 			    					});
				// 			    				}
				// 			    			} catch(e) {
				// 			    				console.log(e);
				// 			    			}
				// 			    		}
				// 			    	}
				// 			    	// if there is a nested array-of-objects in the response object and user wants to plot that
				// 			    	else if (Abscissa && Ordinate && Applicate && NestedArrOfObj) {
				// 			    		let nestedArrOfObjVal = findKeyValue(apiData, NestedArrOfObj);
				// 			    		console.log('nestedArrOfObjVal:', nestedArrOfObjVal);

				// 			    		// if this value of key is an array-of-objects
				// 			    		if (isArrayofObjects(nestedArrOfObjVal)) {
				// 			    			console.log('plotting nested array-of-objects, in an object');
				// 			    			try {
				// 			    				for (let i = 0; i < nestedArrOfObjVal.length; i++) {
				// 				    				// data.add({
				// 				    				// 	id: i,
				// 				    				// 	x: Number(nestedArrOfObjVal[i][`${Abscissa}`]),
				// 				    				// 	y: Number(nestedArrOfObjVal[i][`${Ordinate}`]),
				// 				    				// 	z: Number(nestedArrOfObjVal[i][`${Applicate}`]),
				// 				    				// });

				// 				    				data.push({
				// 				    					x: Number(nestedArrOfObjVal[i][`${Abscissa}`]),
				// 				    					y: Number(nestedArrOfObjVal[i][`${Ordinate}`]),
				// 				    					z: Number(nestedArrOfObjVal[i][`${Applicate}`]),
				// 				    				});
				// 				    			}
				// 			    			} catch(e) {
				// 			    				console.log(e);
				// 			    			}
				// 			    		}
				// 			    	}
				// 		    	}

				// 		        console.log('plotting data');
				// 		    }

				// 		    if (GraphAPI) {
				// 		    	if (BearerToken) {
				// 		    		if (MoreHeaders) {
				// 		    			// add extra headers, if needed
				// 		    			let config = {
				// 		    				headers: {
				// 				    			'Content-Type': 'application/json',
				// 				    			Authorization: `Bearer ${BearerToken}`,
				// 				    		},
				// 		    			};

				// 		    			// ['a:b', "c:d"]
				// 	                    // extracting headers from More_Headers trait
				//                         MoreHeaders.split(",").forEach((header) => {
				// 	                        let key, val;
				// 	                        [key, val] = header.split(":");
				// 	                        console.log("[key, val]:\n", key, val);

				// 	                        config.headers[key] = val;
				//                         });

				// 	                    console.log("config:", config);

				// 		    			// GET call to get the data
				// 				    	let response = await fetch(`${GraphAPI}`, config);
				// 				    	let responseData = await response.json();
				// 				    	apiData = responseData;
				// 		    		} else {
				// 		    			// if no extra-headers provided
				// 		    			// GET call to get the data
				// 				    	let response = await fetch(`${GraphAPI}`, {
				// 				    		headers: {
				// 				    			'Content-Type': 'application/json',
				// 				    			Authorization: `Bearer ${BearerToken}`,
				// 				    		},
				// 				    	});
				// 				    	let responseData = await response.json();
				// 				    	apiData = responseData;
				// 		    		}
				// 			    } else {
				// 			    	if (MoreHeaders) {
				// 			    		// add extra headers, if needed
				// 		    			let config = {
				// 		    				headers: {},
				// 		    			};

				// 		    			// ['a:b', "c:d"]
				// 	                    // extracting headers from More_Headers trait
				//                         MoreHeaders.split(",").forEach((header) => {
				// 	                        let key, val;
				// 	                        [key, val] = header.split(":");
				// 	                        console.log("[key, val]:\n", key, val);

				// 	                        config.headers[key] = val;
				//                         });

				// 	                    console.log("config:", config);

				// 			    		// GET call to get the data
				// 				    	let response = await fetch(`${GraphAPI}`, config);
				// 				    	let responseData = await response.json();
				// 				    	apiData = responseData;
				// 			    	} else {
				// 			    		// if no extra-headers provided
				// 			    		// GET call to get the data
				// 				    	let response = await fetch(`${GraphAPI}`);
				// 				    	let responseData = await response.json();
				// 				    	apiData = responseData;
				// 			    	}
				// 			    }

				// 		    	// call drawVisualization when these traits are provided so that the graph can only be plotted when all the values are present in the vis.Dataset
				// 		    	if (Abscissa && Ordinate && Applicate) {
				// 		    		console.log('3d chart apiData:', apiData);
	    		// 				    drawVisualization();
	    		// 				}
    			// 			}

				// 	   		// specify options
				// 	        var options = {
				// 	        	// the width and height of chart, not the <div> containing it
				// 		        width: "100%",
				// 		        height: "100%",
				// 		        style: ChartType ? ChartType : "line",
				// 		        showPerspective: true,
				// 		        showGrid: true,
				// 		        showShadow: false,
				// 		        keepAspectRatio: true,
				// 		        tooltip: true,
				// 		        verticalRatio: 0.5,
				// 	        };

				// 	        // unique ID
				// 	        const uniqueId = uniqid('3d-chart-');

				// 	        // Instantiate our graph object.
				// 	        // var mainContainer = document.getElementById("3d-chart-container");
				// 	        // var container = document.getElementById("3d-chart");
				// 	        // container.setAttribute('id', `${uniqueId}`);
				// 	        // console.log('container', container);
				// 	        if (data !== undefined || data !== null || data.length !== 0)
				// 	        	graph = new vis.Graph3d(container, data, options);

				// 	        console.log('plotted data:', data);
		        //     	} catch(e) {
		        //     		// statements
		        //     		console.error(e);
		        //     	}
		        //     };

		        //     if (typeof vis == "undefined") {
		        //         const script = document.createElement("script");
		        //         script.onload = initLib;
		        //         script.src = "https://unpkg.com/vis-graph3d@latest/dist/vis-graph3d.min.js";
		        //         document.body.appendChild(script);
		        //     } else {
		        //         console.log("InitLib");
		        //         initLib();

		        //         // let updateFrequencyValue, updateFrequencyRef;
	            // 		// let updateFrequency = document.getElementById('3d-chart-uf-opts');
	            // 		// updateFrequency.addEventListener('change', (event) => {
	            // 		// 	console.log('updateFrequency value:', updateFrequency.value);
	            // 		// 	updateFrequencyValue = updateFrequency.value;

	            // 		// 	// Clear the previous interval if it exists
				// 		//     if (updateFrequencyRef) {
				// 		//         clearInterval(updateFrequencyRef);
				// 		//     }

				// 		//     if (updateFrequencyValue !== 'Stop') {
				// 		//         // Set a new interval
				// 		//         updateFrequencyRef = setInterval(initLib, parseInt(updateFrequencyValue)); // Ensure updateFrequencyValue is a number
				// 		//     } else {
				// 		//         console.log('STOPPED & running one last time');
				// 		//         initLib();
				// 		//     }

				// 		//     console.log('updateFrequencyValue, updateFrequencyRef', updateFrequencyValue, updateFrequencyRef);
	            // 		// });
		        //     }
		        // }
	        },

	        isComponent: (el) => {
		        if (el.getAttribute && el.getAttribute("data-gjs-type") == "3d-chart") {
		            return "3d-chart";
		        }
	        },

	        view: defaultView.extend({
	          	init({ model }) {
	          		// this.listenTo(model, 'change:GraphAPI', this.handlePropChange);
		        	// this.listenTo(model, 'change:BearerToken', this.handlePropChange);
		        	// this.listenTo(model, 'change:MoreHeaders', this.handlePropChange);
		        	// this.listenTo(model, 'change:NestedArrOfObj', this.handlePropChange);
		        	// this.listenTo(model, 'change:Abscissa', this.handlePropChange);
		        	// this.listenTo(model, 'change:Ordinate', this.handlePropChange);
		        	// this.listenTo(model, 'change:Applicate', this.handlePropChange);
		        	// this.listenTo(model, 'change:ChartType', this.handlePropChange);
	          	},

	          	// async handlePropChange() {
		        // 	// const { GraphAPI, BearerToken, MoreHeaders, NestedArrOfObj, Abscissa, Ordinate, Applicate, ChartType } = this.props();
		        // 	const GraphAPI = this.model.get("GraphAPI");
		        // 	const BearerToken = this.model.get("BearerToken");
		        // 	const MoreHeaders = this.model.get("MoreHeaders");
		        // 	const NestedArrOfObj = this.model.get("NestedArrOfObj");
		        // 	const Abscissa = this.model.get("Abscissa");
		        // 	const Ordinate = this.model.get("Ordinate");
		        // 	const Applicate = this.model.get("Applicate");
		        // 	const ChartType = this.model.get("ChartType");

		        // 	const initLib1 = async function () {
	      		// 		console.log('New props: ', GraphAPI, BearerToken, MoreHeaders, NestedArrOfObj, Abscissa, Ordinate, Applicate, ChartType);

	      		// 		const uniqueId = uniqid("3d-chart-");
	  			// 		console.log("gridTable ID for class:", uniqueId);

	  			// 		// let container = document.getElementById("3d-chart");
	  			// 		let container = editor.getWrapper().find(".3d-chart")[0].getEl();
	  			// 		console.log('container', container);
	  			// 		container.setAttribute('id', `${uniqueId}`);

	  			// 		var data = null;
				// 	    var graph = null;
				// 	    let apiData;

				// 	    // helper function to check if a variable is an array-of-objects or not
		        //     	function isArrayofObjects(variable) {
				// 			// Check if the variable is an array
				// 			if (!Array.isArray(variable)) {
				// 			    return false;
				// 			}

				// 			// Check if all elements in the array are objects
				// 			for (const element of variable) {
				// 			    if (typeof element !== 'object' || Array.isArray(element) || element === null) {
				// 			      return false;
				// 			    }
				// 			}
				// 			return true;
				// 		}

				// 		// helper function to get value of a key in nested object
				// 		function findKeyValue(obj, key) {
				// 		    if (obj.hasOwnProperty(key)) {
				// 		        // If the current object has the key, return its value
				// 		        return obj[key];
				// 		    }

				// 		    for (var prop in obj) {
				// 		        if (obj.hasOwnProperty(prop) && typeof obj[prop] === 'object') {
				// 		            // If the current property is an object, recursively search within it
				// 		            var result = findKeyValue(obj[prop], key);
				// 		            if (result !== undefined) {
				// 		                return result;
				// 		            }
				// 		        }
				// 		    }
				// 		    // Key not found in the object
				// 		    return undefined;
				// 		}

				// 	    // Called when the Visualization API is loaded to collect data for plotting.
				// 	    async function drawVisualization() {
				// 	    	// Create and populate a data table.
				// 	        data = new vis.DataSet();

				// 	    	// to populate dataset - check if its an array-of-objects
				// 	    	if (apiData !== undefined && isArrayofObjects(apiData)) {
				// 	    		if (Abscissa && Ordinate && Applicate) {
				// 	    			console.log('plotting array of objects');
				// 		    		try {
				// 		    			// apiData.forEach((obj, idx) => {
				// 		    			// 	data.add({
				// 		    			// 		id: idx,
				// 		    			// 		x: obj[`${props.Abscissa}`],
				// 		    			// 		y: obj[`${props.Ordinate}`],
				// 		    			// 		z: obj[`${props.Applicate}`],
				// 		    			// 	});
				// 		    			// });

				// 		    			for (let i = 0; i < apiData.length; i++) {
				// 		    				data.add({
				// 		    					id: i,
				// 		    					x: apiData[i][`${Abscissa}`],
				// 		    					y: apiData[i][`${Ordinate}`],
				// 		    					z: apiData[i][`${Applicate}`],
				// 		    				});

				// 		    				// data.push({
				// 		    				// 	x: apiData[i][`${props.Abscissa}`],
				// 		    				// 	y: apiData[i][`${props.Ordinate}`],
				// 		    				// 	z: apiData[i][`${props.Applicate}`],
				// 		    				// });
				// 		    			}
				// 		    		} catch(e) {
				// 		    			console.log(e);
				// 		    		}
				// 	    		}
				// 	    	}

				// 	    	// to populate dataset - check if its an object & not an array-of-objects
				// 	    	else if (apiData !== undefined && typeof apiData === 'object' && !isArrayofObjects(apiData)) {
				// 	    		// first check if these values are provided or not / if no nested array-of-objects is not to be plotted by the user
				// 	    		if (Abscissa && Ordinate && Applicate && !NestedArrOfObj) {
				// 		    		// by checking the type of these values, make the if-cases
				// 		    		let abscissaVal = findKeyValue(apiData, Abscissa);
				// 		    		let ordinateVal = findKeyValue(apiData, Ordinate);
				// 		    		let applicateVal = findKeyValue(apiData, Applicate);

				// 		    		// if these values of keynames are single numbers, not arrays of anything or objects
				// 		    		if (
				// 		    			typeof abscissaVal !== 'object' && !isArrayofObjects(abscissaVal) &&
				// 		    			typeof ordinateVal !== 'object' && !isArrayofObjects(ordinateVal) &&
				// 		    			typeof applicateVal !== 'object' && !isArrayofObjects(applicateVal)
				// 		    		) {
				// 		    			console.log('plotting single values, in an object');
				// 		    			try {
				// 		    				// just add these values as 1 object to vis.Dataset
				// 			    			for (let i = 0; i < 1; i++) {
				// 			    				data.add({ id: i, x: abscissaVal, y: ordinateVal, z: applicateVal });
				// 			    			}
				// 		    			} catch(e) {
				// 		    				console.log(e);
				// 		    			}						    			
				// 		    		}
				// 		    		// if these values of keynames are arrays of single values, not single values or array of objects
				// 		    		else if (
				// 		    			Array.isArray(abscissaVal) && !isArrayofObjects(abscissaVal) &&
				// 		    			Array.isArray(ordinateVal) && !isArrayofObjects(ordinateVal) &&
				// 		    			Array.isArray(applicateVal) && !isArrayofObjects(applicateVal)
				// 		    		) {
				// 		    			console.log('plotting array of single values, in an object');
				// 		    			try {
				// 		    				for (let i = 0; i <= abscissaVal.length; i++) {
				// 		    					data.add({
				// 		    						id: i,
				// 		    						x: abscissaVal[i],
				// 		    						y: ordinateVal[i],
				// 		    						z: applicateVal[i],
				// 		    					});
				// 		    				}
				// 		    			} catch(e) {
				// 		    				console.log(e);
				// 		    			}
				// 		    		}
				// 		    	}
				// 		    	// if there is a nested array-of-objects in the response object and user wants to plot that
				// 		    	else if (Abscissa && Ordinate && Applicate && NestedArrOfObj) {
				// 		    		let nestedArrOfObjVal = findKeyValue(apiData, NestedArrOfObj);
				// 		    		console.log('nestedArrOfObjVal:', nestedArrOfObjVal);

				// 		    		// if this value of key is an array-of-objects
				// 		    		if (isArrayofObjects(nestedArrOfObjVal)) {
				// 		    			console.log('plotting nested array-of-objects, in an object');
				// 		    			try {
				// 		    				for (let i = 0; i < nestedArrOfObjVal.length; i++) {
				// 			    				data.add({
				// 			    					id: i,
				// 			    					x: nestedArrOfObjVal[i][`${Abscissa}`],
				// 			    					y: nestedArrOfObjVal[i][`${Ordinate}`],
				// 			    					z: nestedArrOfObjVal[i][`${Applicate}`],
				// 			    				});

				// 			    				// data.push({
				// 			    				// 	x: nestedArrOfObjVal[i][`${props.Abscissa}`],
				// 			    				// 	y: nestedArrOfObjVal[i][`${props.Ordinate}`],
				// 			    				// 	z: nestedArrOfObjVal[i][`${props.Applicate}`],
				// 			    				// });
				// 			    			}
				// 		    			} catch(e) {
				// 		    				console.log(e);
				// 		    			}
				// 		    		}
				// 		    	}
				// 	    	}

				// 	        console.log('plotting data');
				// 	    }

				// 	    // var update = setInterval(drawVisualization, props.UpdateExpression ? props.UpdateExpression : 5000);
				// 	    // if (props.StopUpdate) clearInterval(update);
				// 	    if (GraphAPI) {
				// 	    	if (BearerToken) {
				// 	    		if (MoreHeaders) {
				// 	    			// add extra headers, if needed
				// 	    			let config = {
				// 	    				headers: {
				// 			    			'Content-Type': 'application/json',
				// 			    			Authorization: `Bearer ${BearerToken}`,
				// 			    		},
				// 	    			};

				// 	    			// ['a:b', "c:d"]
				//                     // extracting headers from More_Headers trait
			    //                     MoreHeaders.split(",").forEach((header) => {
				//                         let key, val;
				//                         [key, val] = header.split(":");
				//                         console.log("[key, val]:\n", key, val);

				//                         config.headers[key] = val;
			    //                     });

				//                     console.log("config:", config);

				// 	    			// GET call to get the data
				// 			    	let response = await fetch(`${GraphAPI}`, config);
				// 			    	let responseData = await response.json();
				// 			    	apiData = responseData;
				// 	    		} else {
				// 	    			// if no extra-headers provided
				// 	    			// GET call to get the data
				// 			    	let response = await fetch(`${GraphAPI}`, {
				// 			    		headers: {
				// 			    			'Content-Type': 'application/json',
				// 			    			Authorization: `Bearer ${BearerToken}`,
				// 			    		},
				// 			    	});
				// 			    	let responseData = await response.json();
				// 			    	apiData = responseData;
				// 	    		}
				// 		    } else {
				// 		    	if (MoreHeaders) {
				// 		    		// add extra headers, if needed
				// 	    			let config = {
				// 	    				headers: {},
				// 	    			};

				// 	    			// ['a:b', "c:d"]
				//                     // extracting headers from More_Headers trait
			    //                     MoreHeaders.split(",").forEach((header) => {
				//                         let key, val;
				//                         [key, val] = header.split(":");
				//                         console.log("[key, val]:\n", key, val);

				//                         config.headers[key] = val;
			    //                     });

				//                     console.log("config:", config);

				// 		    		// GET call to get the data
				// 			    	let response = await fetch(`${GraphAPI}`, config);
				// 			    	let responseData = await response.json();
				// 			    	apiData = responseData;
				// 		    	} else {
				// 		    		// if no extra-headers provided
				// 		    		// GET call to get the data
				// 			    	let response = await fetch(`${GraphAPI}`);
				// 			    	let responseData = await response.json();
				// 			    	apiData = responseData;
				// 		    	}
				// 		    }

				// 	    	// call drawVisualization when these traits are provided so that the graph can only be plotted when all the values are present in the vis.Dataset
				// 	    	if (Abscissa && Ordinate && Applicate) {
				// 	    		console.log('3d chart apiData:', apiData);
				// 			    drawVisualization();
				// 			}
				// 		}

				//    		// specify options
				//         var options = {
				//         	// the width and height of chart, not the <div> containing it
				// 	        width: "100%",
				// 	        height: "100%",
				// 	        style: ChartType ? ChartType : "line",
				// 	        showPerspective: true,
				// 	        showGrid: true,
				// 	        showShadow: false,
				// 	        keepAspectRatio: true,
				// 	        tooltip: true,
				// 	        verticalRatio: 0.5,
				//         };

				//         // Instantiate our graph object.
				//         // var container = document.getElementById("3d-chart");
				//         graph = new vis.Graph3d(container, data, options);

				//         console.log('plotted data:', data);
		        // 	};
		        	
		        // 	if (typeof vis == "undefined") {
		        //         const script = document.createElement("script");
		        //         script.onload = initLib1;
		        //         script.src = "https://unpkg.com/vis-graph3d@latest/dist/vis-graph3d.min.js";
		        //         document.body.appendChild(script);
		        //     } else {
		        //         console.log("InitLib");
		        //         initLib1();
		        //     }
		        // }
	        }),
		});
	}
};

export default ThreeDCharts;