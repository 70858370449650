import { API_HOST } from "../api_utils/index";

const GlobalConstantsPlugin = async (editor, options) => {
	console.log('GlobalConstantsPlugin options', options);

	if (editor !== null && editor !== undefined) {
		// make an API call to get the Button-Constants and show them in the traits
		// let buttonConstants = [
	    //     {
	    //     	buttonType: "button-1",
	    //         buttonText: "Button 1",
	    //         buttonFontSize: "2",
	    //         buttonFontColor: "white",
	    //         buttonWidth: "20",
	    //         buttonHeight: "",
	    //         buttonPadding: "2",
	    //         buttonBorderWidth: "1",
	    //         buttonBorderType: "solid",
	    //         buttonBorderColor: "transparent",
	    //         buttonBorderRadius: "20",
	    //         buttonBackgroundColor: "green",
	    //         buttonGradient1Color: '',
	    //         buttonGradient2Color: '',
	    //         buttonHoverColor: "darkgreen",
	    //         buttonIcon: '<i class="fa fa-youtube"></i>',
	    //         buttonInnerOrientation: "icon-left-text-right",
	    //     }, {
	    //     	buttonType: "button-2",
	    //         buttonText: "Button 2",
	    //         buttonFontSize: "2",
	    //         buttonFontColor: "white",
	    //         buttonWidth: "20",
	    //         buttonHeight: "",
	    //         buttonPadding: "2",
	    //         buttonBorderWidth: "1",
	    //         buttonBorderType: "solid",
	    //         buttonBorderColor: "transparent",
	    //         buttonBorderRadius: "50",
	    //         buttonBackgroundColor: "",
	    //         buttonGradient1Color: 'red',
	    //         buttonGradient2Color: 'purple',
	    //         buttonHoverColor: "black",
	    //         buttonIcon: '<i class="fa fa-ambulance"></i>',
	    //         buttonInnerOrientation: "text-left-icon-right",
	    //     },
        // ];
        let buttonConstants;
        let response = await fetch(`${API_HOST}button_constants/get`, {
        	method: "POST",
        	headers: {
        		"Content-Type": "application/json",
        		"Authorization": `Bearer ${JSON.parse(localStorage.getItem('userInfo')).token}`,
        	},
        	body: JSON.stringify({
        		project: options.projectId,
        	}),
        });
        let responseData = await response.json();
        buttonConstants = responseData;

        function convertButtonConstants(buttonConstants) {
		    return buttonConstants.map(button => {
		        return {
		            id: button.buttonType,
		            value: button.buttonType,
		            name: button.buttonType
		        };
		    });
		}

		// to attach traits to all buttons when they are mounted in the editor
		editor.on("component:mount", (component) => {
			// Add trait to all buttons
			if (component.get('type') === "stellar-button" ||
				component.get('type') === "custom-table-action-button" ||
				component.get('type') === "send-email-button-container" ||
				component.get('type') === "button-popup-new-v3" ||
				component.get('type') === "popup-close-button-container" ||
				component.get('type') === "popup-close-button-container" ||
				component.get('type') === "excel-downloader-container" ||
				component.get('type') === "excel-import-container" ||
				component.get('type') === "pdf-downloader-container" ||
				component.get('type') === "predefined-email-dispatch" ||
				component.get('type') === "customize-receiver-email-dispatch" ||
				component.get('type') === "popup-button" ||
				component.get('type') === "email-template-container") {
				console.log("GlobalConstantsPlugin mounted component, buttonConstants", component, buttonConstants);

				let trait = component.addTrait({
					type: 'select',
					name: 'ButtonType',
					label: 'Button Type Constant',
					options: buttonConstants ? convertButtonConstants(buttonConstants) : [],
					changeProp: true,
				}, { at: 0 });

				// Add a listener for the change event of the ButtonType trait
				component.on("change:ButtonType", () => {
					let buttonTypeValue = component.get('ButtonType');
					console.log("ButtonType changed to:", buttonTypeValue);

					// filter through buttonConstants and get the constants based on buttonTypeValue
					let selectedConstant;
					if (buttonConstants) selectedConstant = buttonConstants.filter((ct) => ct.buttonType === buttonTypeValue)[0];
					console.log("selectedConstant:", selectedConstant);

					if (selectedConstant) {
						// check if there is a child 'button' tagName inside component 'type' OR if the 'type' itself is a 'button' tagName & apply selectedConstant styles
						let theButton = component.find('button')[0];
						// console.log("theButton:", theButton);
						if (theButton) {
							let innerText = theButton.find('p')[0];
							// console.log("innerText", innerText);

							// Clear existing components in the innerText to prevent duplication
				            innerText.components().reset();

				            // Add the icon component if buttonIcon is defined
				            if (selectedConstant.buttonIcon) {
				                innerText.append({
				                    tagName: 'i',
				                    attributes: {			
				                        class: selectedConstant.buttonIcon.match(/class="([^"]+)"/)[1],
				                    },
				                });
				            }

				            // Add the button text component
				            innerText.append({
				                type: "textnode",
				                content: selectedConstant.buttonText,
				            });

							// Apply the correct flex-direction based on buttonInnerOrientation
				            const flexDirection = selectedConstant.buttonInnerOrientation === "icon-left-text-right" ? "row" : "row-reverse";

				            innerText.setStyle({
				                display: "flex",
				                "flex-direction": flexDirection,
				                "align-items": "center",
				                "justify-content": "center",
				                "column-gap": "5%",
				            });

							// Generate a unique ID for the button if it doesn't already have one
				            let buttonId = theButton.getId();
				            if (!buttonId) {
				                buttonId = `button-${component.cid}`;
				                theButton.setId(buttonId);
				            }

							theButton.setStyle({
								"font-size": selectedConstant.buttonFontSize + 'vw',
								color: selectedConstant.buttonFontColor,
								width: selectedConstant.buttonWidth + 'vw',
								height: selectedConstant.buttonHeight + 'vh',
								padding: selectedConstant.buttonPadding + '%',
								border: `${selectedConstant.buttonBorderWidth}px ${selectedConstant.buttonBorderType} ${selectedConstant.buttonBorderColor}`,
								"border-radius": selectedConstant.buttonBorderRadius + 'px',
								"background-color": selectedConstant.buttonBackgroundColor,
								"background": `linear-gradient(to left, ${selectedConstant.buttonGradient1Color}, ${selectedConstant.buttonGradient2Color})`,
							});

				            // Explicitly add the hover rule
				            const cssRule = editor.Css.setRule(`#${buttonId}:hover`, {
				                'background-color': selectedConstant.buttonHoverColor,
				            });
						} else {
							// button is itself the 'type'
							let innerText = component.find('p')[0];
							// console.log("innerText", innerText);

							// Clear existing components in the innerText to prevent duplication
				            innerText.components().reset();

				            // Add the icon component if buttonIcon is defined
				            if (selectedConstant.buttonIcon) {
				                innerText.append({
				                    tagName: 'i',
				                    attributes: {			
				                        class: selectedConstant.buttonIcon.match(/class="([^"]+)"/)[1],
				                    },
				                });
				            }

				            // Add the button text component
				            innerText.append({
				                type: "textnode",
				                content: selectedConstant.buttonText,
				            });

							// Apply the correct flex-direction based on buttonInnerOrientation
				            const flexDirection = selectedConstant.buttonInnerOrientation === "icon-left-text-right" ? "row" : "row-reverse";

				            innerText.setStyle({
				                display: "flex",
				                "flex-direction": flexDirection,
				                "align-items": "center",
				                "justify-content": "center",
				                "column-gap": "5%",
				            });

							// Generate a unique ID for the button if it doesn't already have one
				            let buttonId = component.getId();
				            if (!buttonId) {
				                buttonId = `button-${component.cid}`;
				                component.setId(buttonId);
				            }

							component.setStyle({
								"font-size": selectedConstant.buttonFontSize + 'vw',
								color: selectedConstant.buttonFontColor,
								width: selectedConstant.buttonWidth + 'vw',
								height: selectedConstant.buttonHeight + 'vh',
								padding: selectedConstant.buttonPadding + '%',
								border: `${selectedConstant.buttonBorderWidth}px ${selectedConstant.buttonBorderType} ${selectedConstant.buttonBorderColor}`,
								"border-radius": selectedConstant.buttonBorderRadius + 'px',
								"background-color": selectedConstant.buttonBackgroundColor,
								"background": `linear-gradient(to left, ${selectedConstant.buttonGradient1Color}, ${selectedConstant.buttonGradient2Color})`,
							});

				            // Explicitly add the hover rule
				            const cssRule = editor.Css.setRule(`#${buttonId}:hover`, {
				                'background-color': selectedConstant.buttonHoverColor,
				            });
						}
					}					
				});
			}
		});

		editor.on("load", () => {
			// DEFINING COMPONENTS -

			// defining navBarInit instance for initializing a nav-bar-init on editor loading
			let navBarInit = {
				tagName: "div",
				// type: "nav-bar-init", // giving it a custom-type so that it can be added to LB
				attributes: {
					class: "nav-bar-init",
				},

				"style-default": {
					display: "flex",
					"align-items": "center",
					"justify-content": 'space-evenly',
					padding: "0.5%",
				},

				style: {
					display: "flex",
					"align-items": "center",
					"justify-content": 'space-evenly',
					padding: "0.5%",
				},

				// *** THESE ARE UNEDITABLE RIGHT NOW (accept image) ***
				components: [
					{
						type: "image",
					}, {
						tagName: "p",
						content: "home",
					},
				],
			};

			// appending Pages in this Project to the nav-bar-init
			options.pages.forEach((page) => {
				// *** THESE ARE UNEDITABLE RIGHT NOW ***
				if (page.children && !page.children.length > 0) {
					navBarInit.components.push({
						tagName: "p",
						content: page.name,
					});
				} 
				// else if there are child pages, then add them as dropdown menus to the nav-bar-init
				else {
					// this script runs like this because the component is appended first and then its able to get the 'this' instance
					let navBarInitDropdownScript = function () {
						console.log("GC this", this);

				        let button = this.getElementsByClassName("nav-bar-init-dropdown-btn")[0];
				        let itemContainer = this.getElementsByClassName("nav-bar-init-dropdown-item-container" )[0];

				        console.log('GC button, itemContainer', button, itemContainer);

				        // on click, the dropdown-item-container should appear and again on click, it should disappear
				        button.addEventListener("click", function (event) {
				          	// itemContainer.classList.toggle('toggle-dropdown-item-container');
				          	itemContainer.style.display = itemContainer.style.display === "none" ? "inline" : "none";
				        });
					};

					let dropdownMenu = {
						tagName: "div",
						attributes: {
							class: "nav-bar-init-dropdown-menu"
						},
						script: navBarInitDropdownScript,

						"style-default": {
				              width: "max-content",
				              padding: "0.5em",
				              position: "relative",
			            },

			            style: {
				              width: "max-content",
				              padding: "0.5em",
				              position: "relative",
			            },

		                components: [
			            {
			                tagName: "button",
			                type: "nav-bar-init-dropdown-btn",
			                attributes: {
			                  class: "nav-bar-init-dropdown-btn",
			                },

			                "style-default": {
			                  width: "max-content",
			                  padding: "0.5em",
			                  cursor: "pointer",
			                },

			                style: {
			                  width: "max-content",
			                  padding: "0.5em",
			                  cursor: "pointer",
			                },

			                components: {
			                  tagName: "p",
			                  type: "text",
			                  editable: true,

			                  components: {
			                    type: "textnode",
			                    content: "Menu Button",
			                  },
			                },
			            }, {
			                tagName: "div",
			                type: "nav-bar-init-dropdown-item-container",
			                attributes: {
			                  class: "nav-bar-init-dropdown-item-container",
			                },

			                "style-default": {
			                  display: "none",
			                  padding: "0.5em",
			                  width: "15vw",
			                  position: "absolute !important",
			                  top: "105%",
			                  left: "0",
			                  "border-radius": "10px",
			                  "background-color": "white !important",
			                  border: "1px solid gray",
			                },

			                style: {
			                  display: "none",
			                  padding: "0.5em",
			                  width: "15vw",
			                  position: "absolute !important",
			                  top: "105%",
			                  left: "0",
			                  "border-radius": "10px",
			                  "background-color": "white !important",
			                  border: "1px solid gray",
			                },

			                components: [],
			              },
			            ],
					};

					page.children.forEach(childPage => {
						let dropdownMenuItem = {
				            tagName: "div",
				            // type: "nav-bar-init-dropdown-item",
				            // script: dropdownItemScript,
				            // type: "link",
				            attributes: {
				              class: "nav-bar-init-dropdown-item",
				            },

				            "style-default": {
				              padding: "0.5em",
				              cursor: "pointer",
				            },

				            style: {
				              padding: "0.5em",
				              cursor: "pointer",
				            },

				            components: {
				              tagName: "p",
				              type: "text",
				              editable: true,

				              components: {
				                type: "textnode",
				                content: `${childPage.name}`,
				              },
				            },
				        }

				        // adding it as component-type to the component collection, so that it can be added to LB. This will not append it to the editor, only add its type to the collection. Appending is done later
						editor.DomComponents.addType('nav-bar-init-dropdown-item', dropdownMenuItem);

						dropdownMenu.components[1].components.push(dropdownMenuItem);
					});

					 // adding it as component-type to the component collection, so that it can be added to LB. This will not append it to the editor, only add its type to the collection. Appending is done later
					editor.DomComponents.addType('nav-bar-init-dropdown-menu', dropdownMenu);

					navBarInit.components.push(dropdownMenu);
				}
			});

			// adding it as component-type to the component collection, so that it can be added to LB. This will not append it to the editor, only add its type to the collection. Appending is done later
			editor.DomComponents.addType('nav-bar-init', navBarInit);


			// APPENDING COMPONENTS -

			// // check if checkbox from Project-Creation-Section is on for appending a nav-bar-init
			// if (options.appendNavCheckbox) {
			// 	// if nav-bar-init is not already in the editor page, only then append/initialize it in the editor
			// 	if (!editor.getWrapper().find(".nav-bar-init")[0]) {
			// 		editor.getWrapper().append(navBarInit, { at: 0 });

			// 		console.log('navBarInit appended');
			// 	}
			// } else {
			// 	// skip appending the editor with nav-bar-init and;
			// 	// if there is already one nav-bar-init appended and checkbox is turned off, then delete it
			// 	if (editor.getWrapper().find(".nav-bar-init")[0]) {
			// 		editor.getWrapper().find(".nav-bar-init")[0].remove();

			// 		console.log('navBarInit removed');
			// 	}
			// }
		});

		// triggered only when a new component is added to the editor, doesn't trigger when components are already present
		// editor.on('component:add', (model) => {
		// 	console.log('model added', model);

		// 	// setting constants for STELLAR BUTTON
		// 	if (model.get('type') === 'stellar-button' && model.getAttributes()['class'] === 'stellar-button-container') {
		// 		console.log('stellar button container detected');
		// 		model.addTrait({
		// 			type: 'select',
		// 			name: "buttonType",
		// 			label: "Button Type",
		// 			options: [
		//                 { id: "true", value: true, name: "True" },
		//                 { id: "false", value: false, name: "False" },
	    //             ],
		// 			changeProp: true,
		// 		}, {at: 0});
		// 	}
		// });
	}
};

export default GlobalConstantsPlugin;