import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import { Box, Grid, Typography, Button, TextField, Modal, Backdrop, Fade } from '@mui/material';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Tooltip } from "@mui/material";
import darkBubble from "./component_assets/images/darkBubble.png";
import lightBubble from "./component_assets/images/lightBubble.png";
import { API_HOST } from "../api_utils";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const CopyButton = ({ text }) => (
  <CopyToClipboard text={text}>
    <Button
      variant="contained"
      color="primary"
      sx={{ marginRight: "0.5vw", backgroundColor: "red" }}
    >
      Copy
    </Button>
  </CopyToClipboard>
);

export default function AllCustomModels() {
  const { project } = useParams();
  const [externalTables1, setExternalTables1] = useState([]);
  const [open, setOpen] = useState(false);
  const [connectionString, setConnectionString] = useState("");
  const [databaseType, setDatabaseType] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async () => {
    try {
      const response = await fetch(`${API_HOST}/DB`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ connectionString, databaseType }),
      });
      if (response.ok) {
        window.location.reload(); // Reload the page on successful response
      } else {
        console.error("Failed to submit form");
      }
    } catch (error) {
      console.error("Failed to submit form:", error);
    }
  };

  useEffect(() => {
    const fetchExternalModels = async () => {
      try {
        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYzN2M3MzY5ZTUzZWQ0NzlmOGE1YjVkZSIsImlhdCI6MTY5MzgzNzcyMywiZXhwIjoxNjk2NDI5NzIzfQ.SO13auJHAOrOK5HKgE445KWQkHoS4xUf3v2-9JPCvHc`,
          },
        };

        const response = await fetch(
          `${API_HOST}DB/DBModelInfo/${project}?page=${currentPage}`,
          config
        );
        if (!response.ok) {
          throw new Error("Failed to fetch data.");
        }
        const data = await response.json();
        //if (!data.length) {
          //console.log("No data found.");
        //} else {
          setTotalPages(Math.ceil(data.totalCount / 5)); 
          const formattedData = data.tablesInfo.map((item, index) => ({
            id: `${item._id}-${index}`,
            customTableName:
              item.table_name.indexOf("_") !== -1
                ? item.table_name.substring(item.table_name.indexOf("_") + 1)
                : item.table_name,
            customTableNameOriginal: item.table_name,
            fields: item.fields,
            userToken:
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYzN2M3MzY5ZTUzZWQ0NzlmOGE1YjVkZSIsImlhdCI6MTY5MzgzNzcyMywiZXhwIjoxNjk2NDI5NzIzfQ.SO13auJHAOrOK5HKgE445KWQkHoS4xUf3v2-9JPCvHc",
            api: `${API_HOST}DB/data/${item.table_name}`,
          }));
          setExternalTables1(formattedData);
        //}
      } catch (error) {
        console.error("Failed to fetch data:", error);
      }
    };

    fetchExternalModels();
  }, [project, currentPage]);

  // useEffect(() => {
  //   // Fetch total count of tables for pagination
  //   const fetchTotalCount = async () => {
  //     try {
  //       const response = await fetch(`${API_HOST}DB/DBModelInfo/${project}`);
  //       if (!response.ok) {
  //         throw new Error("Failed to fetch total count.");
  //       }
  //       const data = await response.json();
  //       setTotalPages(Math.ceil(data.totalCount / 5)); 
  //     } catch (error) {
  //       console.error("Failed to fetch total count:", error);
  //     }
  //   };

  //   fetchTotalCount();
  // }, [project]);


  const columns = [
    {
      field: "customTableName",
      headerName: "Table Name",
      width: 180,
      editable: false,
    },
    {
      field: "fields",
      headerName: "Fields",
      width: 500,
      renderCell: (params) => (
        <Box
          sx={{
            padding: "1rem 0.5rem",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "start",
          }}
        >
          {params.value.map((field, index) => (
            <div
              style={{
                display: "flex",
                justifyContent: "start",
                width: "100%",
              }}
              key={field._id}
            >
              <Typography sx={{ width: "60%" }}>
                <strong>Field Name</strong>: {field.name}
              </Typography>
              <Typography>
                <strong>Field Type</strong>: {field.type}
              </Typography>
            </div>
          ))}
        </Box>
      ),
    },
    {
      field: "userToken",
      headerName: "User Token",
      width: 200,
      editable: false,
      renderCell: (params) => (
        <Tooltip title={params.value}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            <CopyButton text={params.value} />
            <Typography>{params.value}</Typography>
          </Box>
        </Tooltip>
      ),
    },
    {
      field: "api",
      headerName: "API",
      width: 500,
      editable: false,
      renderCell: (params) => (
        <Tooltip title={params.value}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            <CopyButton text={params.value} />
            <Typography>{params.value}</Typography>
          </Box>
        </Tooltip>
      ),
    },
  ];
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };
  return (
    <div style={{ height: "100vh" }}>
      <Grid container justifyContent="center">
        <Box
          sx={{
            height: "100vh",
            width: "90%",
            backdropFilter: "blur(25)",
            backgroundColor: "",
          }}
        >
          <Grid container justifyContent="space-between" alignItems="center">
            <Typography
              sx={{ fontFamily: "Mont-SemiBold", fontSize: "1.8rem" }}
            >
              External Data Models
            </Typography>
            <Grid
              item
              xs={10}
              sx={{ height: "1.5vh", backgroundColor: "red" }}
            ></Grid>
            
      <Button variant="contained" onClick={handleOpen} sx={{ mt: 2 }} style={{backgroundColor: "#e41d1d"}}>
        Connect to Database
      </Button>
          </Grid>
          
          <DataGrid
            rows={externalTables1}
            columns={columns}
            pageSize={5}
            rowsPerPageOptions={[5]}
            checkboxSelection
            disableSelectionOnClick
            getRowHeight={() => "auto"}
            localeText={{ noRowsLabel: " " }}
            pagination
            paginationMode="server"
            onPageChange={handlePageChange}
            rowCount={totalPages * 5}
            rowKey={(row) => row.id}
          />
        </Box>
      </Grid>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              bgcolor: "background.paper",
              border: "2px solid #000",
              boxShadow: 24,
              p: 4,
            }}
          >
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Enter Connection Details
            </Typography>
            <TextField
              fullWidth
              margin="normal"
              label="Connection String"
              value={connectionString}
              onChange={(e) => setConnectionString(e.target.value)}
            />
            <TextField
              fullWidth
              margin="normal"
              label="Database Type"
              value={databaseType}
              onChange={(e) => setDatabaseType(e.target.value)}
            />
            <Button variant="contained" onClick={handleSubmit} sx={{ mt: 2 }}style={{backgroundColor: "#e41d1d"}}>
              Submit
            </Button>
          </Box>
        </Fade>
      </Modal>
      <ToastContainer autoClose={2000} />
    </div>
  );
}
