import React, { useState, useEffect } from 'react'
import './style.css'
import darkBubble from './component_assets/images/darkBubble.png'
// import darkBubble from './component_assets/images/details_close.png'
// import lightBubble from './component_assets/images/details_close.png'
import lightBubble from './component_assets/images/lightBubble.png'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"
import axios from 'axios'
import { API_HOST } from '../api_utils'
import { FormControl, FormControlLabel, InputLabel, MenuItem, Select, Switch } from '@mui/material'
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import DoDisturbOnIcon from '@mui/icons-material/DoDisturbOn';
import { getAllModels } from '../Redux/Actions/customTableActions';


function CreateTableModal() {
    const { project } = useParams()
    //console.log("project", project);


    let navigate = useNavigate()
    let dispatch = useDispatch()

    const { userInfo } = useSelector(state => state.userLogin)
    const { customTables } = useSelector(state => state.customTables);
    const [tableReferenceList, setTableReferenceList] = useState([])

    // states 
    const [success, setSuccess] = useState(false)
    const [field, setField] = useState({
        tableName: "",
        fieldName: "",
        fieldType: "",
        fieldType1: '',
        fields: [],
        success: false,
        referenceTables: [],
        referenceTableId: '',
    })

    const [is_user_table, setIs_user_table] = useState(false)


    const setFields = () => {

        let savingFields = [...field.fields, { field_name: "", field_type: "", fieldType1: '' }]
        let referenceFields = [...field.referenceTables, { referenceTableId: "" }]

        setField(prevState => ({
            ...prevState,
            fields: savingFields,
            referenceTables: referenceFields
        }))


    }

    function hasDuplicateValues(array, key) {
        const values = new Set();
        for (let i = 0; i < array.length; i++) {
            const value = array[i][key];
            if (values.has(value)) {
                return true;
            }
            values.add(value);
        }
        return false;
    }



    const removeField = (index) => {

        let newState = field.fields.filter((item, i) => i !== index);

        setField(prevState => ({
            ...prevState,
            fields: [...newState]
        }))
    }

    const errorTheme = {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
    }

    const createModalHandler = async () => {
        if (field.tableName && field.fieldName && field.fieldType && field.fields.length === 0 && field.referenceTables.length === 0) {
            try {
                let modalData = {};
                if (field.referenceTableId) {
                    modalData = { table_name: field.tableName, fields: [{ field_name: field.fieldName, field_type: field.fieldType }], projectId: project, referenceTables: [{ referenceTableId: field.referenceTableId }], is_user_table: is_user_table }
                } else {
                    modalData = { table_name: field.tableName, fields: [{ field_name: field.fieldName, field_type: field.fieldType }], projectId: project, is_user_table: is_user_table }
                }

                let config = {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${userInfo.token}`
                    }
                }
                let response = await axios.post(`${API_HOST}customroutes/`, modalData, config);
                setSuccess(true)
                toast.success(`Table Successfully Created`, errorTheme)
            } catch (error) {
                toast.error(`${error}`, errorTheme)
            }
        } else if (field.fields.length > 0) {

            try {
                let filterFieldType1 = [];
                let removeFieldType1 = []
                let fieldNameAndType = {}

                if (field.referenceTables.length > 0) {

                    filterFieldType1 = field.fields.filter((x) =>
                        x.field_name.length > 0 && x.field_type.length > 0
                    )

                    removeFieldType1 = filterFieldType1.map((x) => {
                        let fieldData = {
                            field_name: x.field_name,
                            field_type: x.field_type
                        }
                        return fieldData
                    })
                    fieldNameAndType = {
                        field_name: field.fieldName,
                        field_type: field.fieldType
                    }
                    removeFieldType1.push(fieldNameAndType)
                    //console.log('post field.referenceTables', field.referenceTables)
                    //console.log('post field.fields', field.fields)

                    let filterReferenceType = field.referenceTables.filter((x) => x.referenceTableId.length > 0)
                    //console.log('post filterReferenceType', filterReferenceType)
                    let removeReferenceType = filterReferenceType.map((x) => {
                        let fieldData = {
                            referenceTableId: x.referenceTableId,
                        }
                        return fieldData
                    })

                    //console.log('post removeReferenceType', removeReferenceType)
                    let newReferenceTable = {}
                    let checkDuplicate;

                    if (field.referenceTableId.length > 0) {
                        //console.log('when field.referenceTableId > 0')
                        newReferenceTable = {
                            referenceTableId: field.referenceTableId,
                        }
                        removeReferenceType.push(newReferenceTable)
                        checkDuplicate = hasDuplicateValues(removeReferenceType, 'referenceTableId');
                    } else {
                        checkDuplicate = hasDuplicateValues(removeReferenceType, 'referenceTableId');
                    }

                    if (checkDuplicate === true) {
                        return toast.warn("Same Project References Can't be Taken Inside The Same Model!!!", errorTheme)
                    } else {
                        let modalData = { table_name: field.tableName, fields: removeFieldType1, projectId: project, referenceTables: removeReferenceType, is_user_table: is_user_table }
                        let config = {
                            headers: {
                                'Content-Type': 'application/json',
                                Authorization: `Bearer ${userInfo.token}`
                            }
                        }
                        let response = await axios.post(`${API_HOST}customroutes/`, modalData, config);
                        setSuccess(true)
                        toast.success(`Data Model Successfully Created`, errorTheme)
                    }






                } else {
                    filterFieldType1 = field.fields.filter((x) =>
                        x.field_name.length > 0 && x.field_type.length > 0
                    )

                    removeFieldType1 = filterFieldType1.map((x) => {
                        let fieldData = {
                            field_name: x.field_name,
                            field_type: x.field_type
                        }
                        return fieldData
                    })
                    fieldNameAndType = {
                        field_name: field.fieldName,
                        field_type: field.fieldType
                    }
                    removeFieldType1.push(fieldNameAndType)

                    let modalData = { table_name: field.tableName, fields: removeFieldType1, projectId: project, is_user_table: is_user_table }
                    let config = {
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${userInfo.token}`
                        }
                    }
                    let response = await axios.post(`${API_HOST}customroutes/`, modalData, config);
                    setSuccess(true)
                    toast.success(`Data Model Successfully Created`, errorTheme)
                }

            } catch (error) {
                toast.error(`${error}`, errorTheme)
            }
        } else {
            toast.error("fields can't be empty !!!", errorTheme)
        }
    }

    useEffect(() => {
        dispatch(getAllModels(project));
    }, [])

    const handleChange = (event) => {
        if (event.target.value === 10) {
            setField((prevState) => ({
                ...prevState,
                fieldType: 'String',
                fieldType1: event.target.value
            }));
        } else if (event.target.value === 20) {
            setField((prevState) => ({
                ...prevState,
                fieldType: 'Boolean',
                fieldType1: event.target.value
            }));
        } else if (event.target.value === 30) {
            setField((prevState) => ({
                ...prevState,
                fieldType: 'Number',
                fieldType1: event.target.value
            }));
        } else if (event.target.value === 40) {
            setField((prevState) => ({
                ...prevState,
                fieldType: 'Reference',
                fieldType1: event.target.value,

            }));

        } else if (event.target.value === 50) {
            setField((prevState) => ({
                ...prevState,
                fieldType: 'Date-Time',
                fieldType1: event.target.value
            }));
        } else if (event.target.value === 60) {
            setField((prevState) => ({
                ...prevState,
                fieldType: 'Time',
                fieldType1: event.target.value
            }));
        } else if (event.target.value === 70) {
            setField((prevState) => ({
                ...prevState,
                fieldType: 'File',
                fieldType1: event.target.value
            }));
        }

    };

    const handleChange1 = (event, index) => {
        if (event.target.value === 10) {
            const addNewFieldType = field.fields.find((x, i) => i === index)
            if (addNewFieldType) {
                const newField = {
                    ...addNewFieldType,
                    field_type: 'String',
                    fieldType1: 10
                }

                const updatedFields = field.fields.map((x, i) => {
                    if (i === index) {
                        return newField
                    } else {
                        return x
                    }
                })
                setField((prevState) => ({
                    ...prevState,
                    fields: updatedFields
                }));
            }
        } else if (event.target.value === 20) {
            const addNewFieldType = field.fields.find((x, i) => i === index)
            if (addNewFieldType) {
                const newField = {
                    ...addNewFieldType,
                    field_type: 'Boolean',
                    fieldType1: 20
                }

                const updatedFields = field.fields.map((x, i) => {
                    if (i === index) {
                        return newField
                    } else {
                        return x
                    }
                })
                setField((prevState) => ({
                    ...prevState,
                    fields: updatedFields
                }));
            }
        } else if (event.target.value === 30) {
            const addNewFieldType = field.fields.find((x, i) => i === index)
            if (addNewFieldType) {
                const newField = {
                    ...addNewFieldType,
                    field_type: 'Number',
                    fieldType1: 30
                }

                const updatedFields = field.fields.map((x, i) => {
                    if (i === index) {
                        return newField
                    } else {
                        return x
                    }
                })
                setField((prevState) => ({
                    ...prevState,
                    fields: updatedFields
                }));
            }
        } else if (event.target.value === 40) {
            const addNewFieldType = field.fields.find((x, i) => i === index)
            if (addNewFieldType) {
                const newField = {
                    ...addNewFieldType,
                    field_type: 'Reference',
                    fieldType1: 40,
                }
                const updatedFields = field.fields.map((x, i) => {
                    if (i === index) {
                        return newField
                    } else {
                        return x
                    }
                })
                setField((prevState) => ({
                    ...prevState,
                    fields: updatedFields,
                }));
            }
        } else if (event.target.value === 50) {
            const addNewFieldType = field.fields.find((x, i) => i === index)
            if (addNewFieldType) {
                const newField = {
                    ...addNewFieldType,
                    field_type: 'Date-Time',
                    fieldType1: 50
                }

                const updatedFields = field.fields.map((x, i) => {
                    if (i === index) {
                        return newField
                    } else {
                        return x
                    }
                })
                setField((prevState) => ({
                    ...prevState,
                    fields: updatedFields
                }));
            }
        } else if (event.target.value === 60) {
            const addNewFieldType = field.fields.find((x, i) => i === index)
            if (addNewFieldType) {
                const newField = {
                    ...addNewFieldType,
                    field_type: 'Time',
                    fieldType1: 60
                }

                const updatedFields = field.fields.map((x, i) => {
                    if (i === index) {
                        return newField
                    } else {
                        return x
                    }
                })
                setField((prevState) => ({
                    ...prevState,
                    fields: updatedFields
                }));
            }
        } else if (event.target.value === 70) {
            const addNewFieldType = field.fields.find((x, i) => i === index)
            if (addNewFieldType) {
                const newField = {
                    ...addNewFieldType,
                    field_type: 'File',
                    fieldType1: 70
                }

                const updatedFields = field.fields.map((x, i) => {
                    if (i === index) {
                        return newField
                    } else {
                        return x
                    }
                })
                setField((prevState) => ({
                    ...prevState,
                    fields: updatedFields
                }));
            }
        }
    }

    const handleFieldName = (event, index) => {
        const addNewFieldName = field.fields.find((x, i) => i === index);
        if (addNewFieldName) {
            const newField = event.target.value
            const updatedFields = field.fields.map((x, i) => {
                if (i === index) {
                    return { ...x, field_name: newField }
                } else {
                    return x
                }
            })
            setField((prevState) => ({
                ...prevState,
                fields: updatedFields
            }));
        }
    }

    const handleReferenceChange1 = (e, index) => {
        //console.log("indexRef", index)
        //console.log('field.referenceTables[index]', field.referenceTables[index])
        const addNewFieldType = field.referenceTables.find((x, i) => i === index)
        if (addNewFieldType) {
            const updatedFields = field.referenceTables.map((x, i) => {
                const newField = {
                    ...addNewFieldType,
                    referenceTableId: e.target.value,
                }
                if (i === index) {
                    return newField
                } else {
                    return x
                }
            })
            setField((prevState) => ({
                ...prevState,
                referenceTables: updatedFields,
            }));
        }

    }

    const handleReferenceChange = (e) => {
        setField((prevState) => ({
            ...prevState,
            referenceTableId: e.target.value,
        }));
    }


    if (success) {
        navigate(`/alltables/${project}`)
    }

    useEffect(() => {
        if (customTables.length) {
            const tableInsideProject = customTables.filter((x) => x.project === project);
            let tableName = tableInsideProject?.map((x) => {
                let arr = {
                    name: x.table_name,
                    value: x._id
                }
                return arr;
            })
            tableName?.push({
                name: 'none',
                value: "none"
            })
            setTableReferenceList(tableName)
        }
    }, [customTables])


    return (
        <div>
            <div className="flex-container">

                <div className="hero">
                    <img className="login-d-bb" src={darkBubble} />
                    <img className="login-l-bb" src={lightBubble} />
                    <div className="profile-wrapper-form">
                        <div className="morph-div-profile">
                            <div className="morph-header-project">
                                <p className="morph-head-text">ADD NEW MODEL</p>
                                <p className="morph-head-project"></p>
                            </div>
                            <div className="wrap-project-form" style={{ position: 'relative' }}>
                                <div style={{ display: 'flex', justifyContent: 'start', alignItems: 'center', fontFamily: 'Mont-Regular', position: 'absolute', top: '0', right: '0' }}>
                                    <p>
                                        <label for="userTable">Default User Table For Project</label>
                                    </p>
                                    <FormControlLabel sx={{ ml: 2,mb:3 }} control={<Switch checked={is_user_table} onChange={(event) => setIs_user_table(event.target.checked)} />} />

                                </div>
                                <form className="project-form">
                                    <div className="user-form-first">
                                        <div className="form-element">
                                            <p>
                                                <label for="TableName">Table Name</label>
                                            </p>
                                            <input value={field.tableName} onChange={(e) => setField((prevState) => ({
                                                ...prevState,
                                                tableName: e.target.value
                                            }))} id="TableName" placeholder="Add Table Name" />

                                        </div>


                                    </div>

                                    <div style={{ display: "flex", marginTop: "10px", alignItems: "center" }}>
                                        <div className="form-element">
                                            <p>
                                                <label for="FieldName">Field Name</label>
                                            </p>
                                            <input
                                                type="text"
                                                id="FieldName"
                                                style={{ height: '55px' }}
                                                placeholder="Add Field Name"
                                                onChange={(e) => setField((prevState) => ({
                                                    ...prevState,
                                                    fieldName: e.target.value
                                                }))}
                                                value={field.fieldName}
                                            />
                                        </div>

                                        <FormControl sx={{ width: '23vw', ml: "10px !important", mt: "42px !important" }}>
                                            <InputLabel id="demo-simple-select-label">Data Type</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"

                                                value={field.fieldType1}
                                                label="Data Type"
                                                onChange={handleChange}
                                            >
                                                <MenuItem value={10}>String</MenuItem>
                                                <MenuItem value={20}>Boolean</MenuItem>
                                                <MenuItem value={30}>Number</MenuItem>
                                                <MenuItem value={40}>Reference</MenuItem>
                                                <MenuItem value={50}>Date-Time</MenuItem>
                                                <MenuItem value={60}>Time</MenuItem>
                                                <MenuItem value={70}>File</MenuItem>
                                            </Select>
                                        </FormControl>

                                        {field.fieldType1 === 40 && <FormControl sx={{ width: '23vw', ml: "10px !important", mt: "42px !important" }}>
                                            <InputLabel >Reference Table</InputLabel>
                                            <Select
                                                labelId={`demo-simple-select-label`}
                                                id={`demo-simple-select`}
                                                label="Select Reference Table"
                                                value={field?.referenceTableId}
                                                onChange={(e) => handleReferenceChange(e)}
                                            >
                                                {
                                                    tableReferenceList.length ? tableReferenceList.map((x) =>

                                                        <MenuItem value={x.value}>{x.name}</MenuItem>) : ""
                                                }

                                                {/* Add the options for the reference select */}
                                            </Select>
                                        </FormControl>
                                        }
                                        {
                                            field.fields.length > 0 ? <div style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                marginTop: "42px ",
                                                fontSize: '50px',
                                                marginLeft: '8px',
                                                color: 'red'
                                            }} onClick={() => removeField(0)}>
                                                <DoDisturbOnIcon fontSize='1rem' />
                                            </div> : <div style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                marginTop: "42px ",
                                                fontSize: '50px',
                                                marginLeft: '8px',
                                                color: 'red'
                                            }} onClick={setFields}>
                                                <AddCircleIcon fontSize='1rem' />
                                            </div>
                                        }
                                    </div>
                                    {

                                        field.fields.length > 0 ? field.fields.map((x, index) => {
                                            //console.log('field', field)
                                            //console.log('fields', field.fields)
                                            //console.log('field.referenceTables', field.referenceTables)
                                            //console.log('field.referenceTableId', field.referenceTableId)
                                            return (
                                                <div key={index} style={{ display: "flex", marginTop: "10px", alignItems: "center", justifyContent: "space-around" }}>
                                                    <div className="form-element">
                                                        <p>
                                                            <label for="FieldName">Field Name</label>
                                                        </p>
                                                        <input
                                                            type="text"
                                                            id="FieldName"
                                                            style={{ height: '55px' }}
                                                            placeholder="Add Field Name"
                                                            onChange={(e) => handleFieldName(e, index)}
                                                            value={x.field_name}

                                                        />
                                                    </div>
                                                    <FormControl key={index * 2} sx={{ width: '23vw', ml: "10px !important", mt: "42px !important" }}>
                                                        <InputLabel id="demo-simple-select-label">Data Type</InputLabel>
                                                        <Select
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"

                                                            value={x.fieldType1}
                                                            label="Data Type"
                                                            onChange={(event) => handleChange1(event, index)}

                                                        >
                                                            <MenuItem value={10}>String</MenuItem>
                                                            <MenuItem value={20}>Boolean</MenuItem>
                                                            <MenuItem value={30}>Number</MenuItem>
                                                            <MenuItem value={40}>Reference</MenuItem>
                                                            <MenuItem value={50}>Date-Time</MenuItem>
                                                            <MenuItem value={60}>Time</MenuItem>
                                                            <MenuItem value={70}>File</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                    {field.fields.length > 0 && x.fieldType1 === 40 && field.referenceTables.length > 0 && <FormControl sx={{ width: '23vw', ml: "10px !important", mt: "42px !important" }}>
                                                        <InputLabel >Reference Table</InputLabel>
                                                        <Select
                                                            labelId={`demo-simple-select-label`}
                                                            id={`demo-simple-select`}
                                                            label="Select Reference Table"
                                                            value={field.referenceTables[index].referenceTableId}
                                                            onChange={(e) => handleReferenceChange1(e, index)}
                                                        >
                                                            {
                                                                tableReferenceList.length ? tableReferenceList.map((y, i) =>

                                                                    <MenuItem key={`${y.value}-${i}`} data-id={i} data-category={y.name} value={y.value}>{y.name}</MenuItem>) : ""
                                                            }

                                                            {/* Add the options for the reference select */}
                                                        </Select>
                                                    </FormControl>}
                                                    {
                                                        index === field.fields.length - 1 ? <div key={index * 3} style={{
                                                            display: "flex",
                                                            justifyContent: "center",
                                                            marginTop: "42px ",
                                                            fontSize: '50px',
                                                            marginLeft: '8px',
                                                            color: 'red'
                                                        }}
                                                            // onClick={() => this.setFields()}
                                                            onClick={() => setFields(index)}
                                                        >
                                                            <AddCircleIcon fontSize='1rem' />
                                                        </div> : <div key={index * 3} style={{
                                                            display: "flex",
                                                            justifyContent: "center",
                                                            marginTop: "42px ",
                                                            fontSize: '50px',
                                                            marginLeft: '8px',
                                                            color: 'red'
                                                        }}
                                                            onClick={() => removeField(index)}
                                                        >
                                                            <RemoveCircleIcon fontSize='1rem' />
                                                        </div>
                                                    }
                                                </div>
                                            )
                                        }) : ""
                                    }
                                </form>
                                <div className="project-form-last" style={{ marginTop: "8px" }}>
                                    <div className="submit-div-project">
                                        <button onClick={createModalHandler} style={{ padding: '1rem 2rem' }} className="project-form-submit">
                                            Create Model
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CreateTableModal