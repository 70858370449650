import React, { useState, useEffect } from "react";
import {
  Box,
  Paper,
  Step,
  Stepper,
  StepLabel,
  Button,
  TextField,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { API_HOST } from "../../api_utils";
import InfoIcon from "@mui/icons-material/Info";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate, useParams } from "react-router-dom";

const errorTheme = {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "colored",
};

function GenerateToken() {
  const [modelList, setModelList] = useState([]);
  const { userInfo } = useSelector((state) => state.userLogin);
  const { projectId } = useParams();

  const [isMiddleware, setIsMiddleware] = useState({});
  const [isUpdateFlow, setIsUpdateFlow] = useState(false);

  let navigate = useNavigate();

  const [createMiddlewarePayload, setCreateMiddlewarePayload] = useState({
    secretToken: "",
    expiryValue: "",
    expiryUnit: "",
    modalName: "",
    projectId: projectId,
  });

  // GRAB ALL CUSTOM MODELS
  const grabCustomModelsHandler = async (project) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const payload = {
      projectId: project,
    };

    const { data } = await axios.post(
      `${API_HOST}customroutes/get`,
      payload,
      config
    );

    if (!data.message) {
      let tableNameNew = data.map((x) => {
        return x.table_name;
      });

      setModelList(tableNameNew);
    } else {
      toast.error("Error Getting List Of Custom Modals", errorTheme);
    }
  };

  const grabMiddlewareInfo = async (project) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(
      `${API_HOST}create_custom_route/get_middleware/${project}`,
      config
    );

    if (!data.message) {
      setIsUpdateFlow(true);
      setIsMiddleware(data);
    } else {
      toast.error(`${data.message}`, errorTheme);
    }
  };

  useEffect(() => {
    grabCustomModelsHandler(projectId);
    grabMiddlewareInfo(projectId);
  }, []);

  // GENERATE TOKEN AND MIDDLEWARE HOF
  // const CreateJwtTokenForm = () => {
  //   return (

  //   );
  // };

  // SHOW TOKEN DATA IF FOUND
  const ShownJwtTokenFormWithData = () => {
    return (
      <div>
        <p>Token Data Found</p>
      </div>
    );
  };

  const generateMiddlewareHandler = async () => {
    if (
      createMiddlewarePayload.secretToken &&
      createMiddlewarePayload.expiryUnit &&
      createMiddlewarePayload.expiryValue &&
      createMiddlewarePayload.modalName
    ) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.post(
        `${API_HOST}create_custom_route/generateMiddleware`,
        createMiddlewarePayload,
        config
      );

      if (data.message === 'Middleware created successfully') {
        toast.success(`${data.message}`);
        navigate(`/project_page/${projectId}`);
      } else {
        toast.error(`${data.message}`, errorTheme);
      }
    } else {
      toast.warn("All Form Fields Are Required !!!");
    }
  };

  return (
    <div style={{ width: "100%", height: "90vh" }}>
      <Box sx={{ width: "90%", m: "auto", pt: "2rem", display: "block" }}>
        {!isUpdateFlow ? (
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
              mt: "4rem",
            }}>
            <Paper
              elevation={2}
              sx={{
                width: "60%",
                borderRadius: "10px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                padding: "1.5rem",
              }}>
              <Typography
                sx={{ fontSize: "1.8rem", letterSpacing: "0.1rem", mb: 3 }}>
                Jwt Middleware Generation Form
              </Typography>
              <TextField
                onChange={(e) => {
                  setCreateMiddlewarePayload((prevState) => ({
                    ...prevState,
                    secretToken: e.target.value,
                  }));
                }}
                value={createMiddlewarePayload?.secretToken}
                id="secretToken"
                label="Token"
                variant="outlined"
                sx={{ mb: "2rem", width: "100%" }}
              />

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  mb: "2rem",
                  width: "100%",
                }}>
                <TextField
                  onChange={(e) => {
                    setCreateMiddlewarePayload((prevState) => ({
                      ...prevState,
                      expiryValue: e.target.value,
                    }));
                  }}
                  value={createMiddlewarePayload?.expiryValue}
                  id="expiryValue"
                  label="Provide Token Expiry Value"
                  variant="outlined"
                  sx={{ width: "50%" }}
                />
                <FormControl sx={{ ml: 3, width: "50%" }}>
                  <InputLabel id="tokenExpiry">
                    Select Token Expiry Unit
                  </InputLabel>
                  <Select
                    labelId="tokenExpiry"
                    id="tokenExpirySelect"
                    value={createMiddlewarePayload?.expiryUnit}
                    label="Select Token Expiry Unit"
                    onChange={(e) =>
                      setCreateMiddlewarePayload((prevState) => ({
                        ...prevState,
                        expiryUnit: e.target.value,
                      }))
                    }>
                    <MenuItem value={"h"}>Hours</MenuItem>
                    <MenuItem value={"m"}>Minutes</MenuItem>
                  </Select>
                </FormControl>
              </Box>

              <FormControl sx={{ width: "100%", mb: 4 }}>
                <InputLabel id="modalName">Modal To Link With Auth</InputLabel>
                <Select
                  labelId="modalName"
                  id="modalNameSelect"
                  value={createMiddlewarePayload?.modalName}
                  label="Modal To Link With Auth"
                  onChange={(e) =>
                    setCreateMiddlewarePayload((prevState) => ({
                      ...prevState,
                      modalName: e.target.value,
                    }))
                  }>
                  {modelList.length &&
                    modelList.map((x, index) => (
                      <MenuItem key={index} value={x}>
                        {x}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>

              <Button
                onClick={() => generateMiddlewareHandler()}
                variant="contained"
                sx={{
                  width: "100%",
                  pb: "0.8rem",
                  pt: "0.8rem",
                  fontSize: "1.1rem",
                  letterSpacing: "0.2rem",
                  backgroundColor: "#f43f5e",
                  "&:hover": {
                    backgroundColor: "#e11d48",
                    transitionDuration: "0.5s",
                  },
                }}>
                Proceed to Add Middleware
              </Button>
            </Paper>

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: "gray",
                mt: 3,
              }}>
              <span>
                <InfoIcon />
              </span>
              <span style={{ marginLeft: "0.5rem", letterSpacing: "0.1rem" }}>
                Only One Jwt Middleware Can be Added Inside A Project .Once
                Middleware Is Added User Can Only Edit or Delete It.
              </span>
            </Box>
          </Box>
        ) : (
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
              mt: "4rem",
            }}>
            <Paper
              elevation={2}
              sx={{
                width: "60%",
                borderRadius: "10px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                padding: "1.5rem",
              }}>
              <Typography
                sx={{ fontSize: "1.8rem", letterSpacing: "0.1rem", mb: 3 }}>
                Update Middleware Configuration
              </Typography>
              <TextField
                onChange={(e) => {
                  setIsMiddleware((prevState) => ({
                    ...prevState,
                    secretToken: e.target.value,
                  }));
                }}
                value={isMiddleware?.secretToken}
                id="secretToken"
                label="Token"
                variant="outlined"
                sx={{ mb: "2rem", width: "100%" }}
              />

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  mb: "2rem",
                  width: "100%",
                }}>
                <TextField
                  onChange={(e) => {
                    setIsMiddleware((prevState) => ({
                      ...prevState,
                      expiryValue: e.target.value,
                    }));
                  }}
                  value={isMiddleware?.expiryValue}
                  id="expiryValue"
                  label="Provide Token Expiry Value"
                  variant="outlined"
                  sx={{ width: "50%" }}
                />
                <FormControl sx={{ ml: 3, width: "50%" }}>
                  <InputLabel id="tokenExpiry">
                    Select Token Expiry Unit
                  </InputLabel>
                  <Select
                    labelId="tokenExpiry"
                    id="tokenExpirySelect"
                    value={isMiddleware?.expiryUnit}
                    label="Select Token Expiry Unit"
                    onChange={(e) =>
                      setIsMiddleware((prevState) => ({
                        ...prevState,
                        expiryUnit: e.target.value,
                      }))
                    }>
                    <MenuItem value={"h"}>Hours</MenuItem>
                    <MenuItem value={"m"}>Minutes</MenuItem>
                  </Select>
                </FormControl>
              </Box>

              <FormControl sx={{ width: "100%", mb: 4 }}>
                <InputLabel id="modalName">Modal To Link With Auth</InputLabel>
                <Select
                  labelId="modalName"
                  id="modalNameSelect"
                  value={isMiddleware?.modalName}
                  label="Modal To Link With Auth"
                  onChange={(e) =>
                    setIsMiddleware((prevState) => ({
                      ...prevState,
                      modalName: e.target.value,
                    }))
                  }>
                  {modelList.length &&
                    modelList.map((x, index) => (
                      <MenuItem key={index} value={x}>
                        {x}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>

              <Button
                onClick={() => generateMiddlewareHandler()}
                variant="contained"
                sx={{
                  width: "100%",
                  pb: "0.8rem",
                  pt: "0.8rem",
                  fontSize: "1.1rem",
                  letterSpacing: "0.2rem",
                  backgroundColor: "#f43f5e",
                  "&:hover": {
                    backgroundColor: "#e11d48",
                    transitionDuration: "0.5s",
                  },
                }}>
                Update Configuration
              </Button>
            </Paper>

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: "gray",
                mt: 3,
              }}>
              <span>
                <InfoIcon />
              </span>
              <span style={{ marginLeft: "0.5rem", letterSpacing: "0.1rem" }}>
                Edit Previously Created Middleware Configuration.
              </span>
            </Box>
          </Box>
        )}
      </Box>

      {/* Create jwt token form */}
    </div>
  );
}

export default GenerateToken;
