import axios from 'axios'
import { API_HOST } from "../../api_utils";
import { APPROVE_REQUEST_LIST_FAILED, APPROVE_REQUEST_LIST_LOADING, APPROVE_REQUEST_LIST_SUCCESS, DELETE_LIST_OF_USER_UNDER_COMPANY_FAILED, DELETE_LIST_OF_USER_UNDER_COMPANY_LOADING, DELETE_LIST_OF_USER_UNDER_COMPANY_SUCCESS, DELETE_REQUEST_LIST_FAILED, DELETE_REQUEST_LIST_LOADING, DELETE_REQUEST_LIST_SUCCESS, GET_LIST_OF_USER_UNDER_COMPANY_FAILED, GET_LIST_OF_USER_UNDER_COMPANY_LOADING, GET_LIST_OF_USER_UNDER_COMPANY_SUCCESS, GET_REQUEST_LIST_FAILED, GET_REQUEST_LIST_LOADING, GET_REQUEST_LIST_SUCCESS, UPDATE_LIST_OF_USER_UNDER_COMPANY_FAILED, UPDATE_LIST_OF_USER_UNDER_COMPANY_LOADING, UPDATE_LIST_OF_USER_UNDER_COMPANY_SUCCESS } from '../Constants/adminScreenConstants';
import { toast } from "react-toastify";



const errorTheme = {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
}



export const getAdminRequest = () => async (dispatch, getState) => {
    try {

        dispatch({ type: GET_REQUEST_LIST_LOADING })
        const { userLogin: { userInfo } } = getState()
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }
        const { data } = await axios.get(`${API_HOST}adminRequests`, config)
        dispatch({ type: GET_REQUEST_LIST_SUCCESS, payload: data })
    } catch (error) {
        dispatch({ type: GET_REQUEST_LIST_FAILED, payload: 'Invalid Email or Password' })

    }
}

export const updateAdminRequest = (request_id) => async (dispatch, getState) => {
    try {

        dispatch({ type: APPROVE_REQUEST_LIST_LOADING })
        const { userLogin: { userInfo } } = getState()
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }
        const { data } = await axios.put(`${API_HOST}adminRequests`, request_id, config)
        dispatch({ type: APPROVE_REQUEST_LIST_SUCCESS, payload: data })
        toast.success(`Application Approved successfully  .`, errorTheme)
    } catch (error) {
        dispatch({ type: APPROVE_REQUEST_LIST_FAILED, payload: 'Invalid Email or Password' })

    }
}

export const deleteAdminRequest = (request_id) => async (dispatch, getState) => {

    console.log("request_id:::::::", request_id);
    try {

        dispatch({ type: DELETE_REQUEST_LIST_LOADING })
        const { userLogin: { userInfo } } = getState()
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        console.log("config::::", config);
        const { data } = await axios.delete(`${API_HOST}adminRequests/${request_id}`, config)
        dispatch({ type: DELETE_REQUEST_LIST_SUCCESS, payload: data })
        toast.success(`Application Discarded successfully  .`, errorTheme)
    } catch (error) {
        dispatch({ type: DELETE_REQUEST_LIST_FAILED, payload: 'Invalid Email or Password' })

    }
}

export const getUsersUnderCompany = () => async (dispatch, getState) => {
    try {

        dispatch({ type: GET_LIST_OF_USER_UNDER_COMPANY_LOADING })
        const { userLogin: { userInfo } } = getState()
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }
        const { data } = await axios.get(`${API_HOST}adminRequests/user_list`, config)
        dispatch({ type: GET_LIST_OF_USER_UNDER_COMPANY_SUCCESS, payload: data })
    } catch (error) {
        dispatch({ type: GET_LIST_OF_USER_UNDER_COMPANY_FAILED, payload: 'Invalid Email or Password' })

    }
}

export const updateUsersUnderCompany = (userData) => async (dispatch, getState) => {
    try {
        console.log("here::::::::::");

        dispatch({ type: UPDATE_LIST_OF_USER_UNDER_COMPANY_LOADING })
        const { userLogin: { userInfo } } = getState()
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }
        const { data } = await axios.put(`${API_HOST}adminRequests/user_list`, userData, config)
        dispatch({ type: UPDATE_LIST_OF_USER_UNDER_COMPANY_SUCCESS, payload: data })
        toast.success(`user successfully updated .`, errorTheme)
    } catch (error) {
        dispatch({ type: UPDATE_LIST_OF_USER_UNDER_COMPANY_FAILED, payload: 'Invalid Email or Password' })
    }
}

export const deleteUsersUnderCompany = (user_id) => async (dispatch, getState) => {
    try {

        dispatch({ type: DELETE_LIST_OF_USER_UNDER_COMPANY_LOADING })
        const { userLogin: { userInfo } } = getState()
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }
        const { data } = await axios.delete(`${API_HOST}adminRequests/user_list/${user_id}`, config)
        dispatch({ type: DELETE_LIST_OF_USER_UNDER_COMPANY_SUCCESS, payload: data })
        toast.success(`user successfully deleted .`, errorTheme)
    } catch (error) {
        dispatch({ type: DELETE_LIST_OF_USER_UNDER_COMPANY_FAILED, payload: 'Invalid Email or Password' })

    }
}