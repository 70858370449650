function BusinessDashboardTemplate(editor, options) {
	// adding its block in the block manager
	editor.Blocks.add('business-dashboard-template', {
		label: "Business Dashboard Template",
        category: "Business",
        select: true,
        media: `<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" viewBox="0 0 16 16"><path d="M5 10.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5zm0-2a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm0-2a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm0-2a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5z"/><path d="M3 0h10a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-1h1v1a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v1H1V2a2 2 0 0 1 2-2z"/><path d="M1 5v-.5a.5.5 0 0 1 1 0V5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1zm0 3v-.5a.5.5 0 0 1 1 0V8h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1zm0 3v-.5a.5.5 0 0 1 1 0v.5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1z"/></svg>`,
        content: { type: "business-dashboard-template" },
	});

	// function businessDashboardFunctionality(props) {
	const businessDashboardFunctionality = async function (component, props) {
		console.log('running businessDashboardFunctionality()');

		// TRAIT FUNCTIONS -
		// api call function for Todays_API trait-set
		async function todaysApiCall(api, config) {
			let apiData;

            console.log('api:', api);

			const response = await fetch(`${api}`, config);
			const responseData = await response.json();
            
            apiData = responseData;
            
            console.log("apiData:", apiData);

            // if apiData is not 'an array, ar array of objects, an array of anything'
            if (!Array.isArray(apiData)) {
            	let dateResponseKeyValue = findValueByKey(apiData, dateResponseKeyname);
            	let todaysSaleResponseKeyValue = findValueByKey(apiData, todaysSaleResponseKeyname);
            	let todaysEarningsResponseKeyValue = findValueByKey(apiData, todaysEarningsResponseKeyname);

            	console.log('dateResponseKeyValue:', dateResponseKeyValue, 'todaysSaleResponseKeyValue:', todaysSaleResponseKeyValue, 'todaysEarningsResponseKeyValue:', todaysEarningsResponseKeyValue);

            	// replacing the content in the template
            	// Find an element within the editor by its selector
				let datePTag = component.find('.card .card-header .media.mt-5 .media-body h5:not(.mb-0) p')[0];
				console.log('datePTag:', datePTag);
				// Check if the component was found
				if (datePTag) {
				    // Manipulate the component
				    datePTag.getEl().innerHTML = (dateResponseKeyValue !== undefined) ? dateResponseKeyValue : datePTag.getEl().innerHTML;
				}

				let todaysSaleTag = component.find('.card .card-header .media.mt-5 .media-body p span.font-success p')[0];
				console.log('todaysSaleTag:', todaysSaleTag);
				// Check if the component was found
				if (todaysSaleTag) {
				    // Manipulate the component
				    todaysSaleTag.getEl().innerHTML = (todaysSaleResponseKeyValue !== undefined) ? todaysSaleResponseKeyValue : todaysSaleTag.getEl().innerHTML;
				}

				let todaysEarningsTag = component.find('.card .card-header .media.mt-5 .media-body .sales-widgets h3 span.counter p')[0];
				console.log('todaysEarningsTag:', todaysEarningsTag);
				// Check if the component was found
				if (todaysEarningsTag) {
				    // Manipulate the component
				    todaysEarningsTag.getEl().innerHTML = (todaysEarningsResponseKeyValue !== undefined) ? todaysEarningsResponseKeyValue : todaysEarningsTag.getEl().innerHTML;
				}
            } else {
            	console.error('The provided keyname(s) in the trait(s) are not in a javascript object. Please try any other key(s).');
            }
		}

		// api call function for Total_Sale_Last_Year_API trait-set
		async function totalSaleLastYearAPICall(api, config) {
			let apiData;

            console.log('api:', api);

			const response = await fetch(`${totalSaleLastYearAPI}`, config);
			const responseData = await response.json();
            
            apiData = responseData;
            
            console.log("apiData:", apiData);

            // if apiData is not 'an array, ar array of objects, an array of anything'
            if (!Array.isArray(apiData)) {
            	let totalSaleLastYearPercentageKeyValue = findValueByKey(apiData, totalSaleLastYearPercentage);
            	let totalSaleLastYearAmountKeyValue = findValueByKey(apiData, totalSaleLastYearAmount);

            	console.log('totalSaleLastYearPercentageKeyValue:', totalSaleLastYearPercentageKeyValue, 'totalSaleLastYearAmountKeyValue:', totalSaleLastYearAmountKeyValue);

            	// replacing the content in the template
            	// Find an element within the editor by its selector
				let totalSaleLastYearPercentageTag = component.find('.total-sale-widget .media .media-body span p p')[0];
				console.log('totalSaleLastYearPercentageTag:', totalSaleLastYearPercentageTag);
				// Check if the component was found
				if (totalSaleLastYearPercentageTag) {
				    // Manipulate the component
				    totalSaleLastYearPercentageTag.getEl().innerHTML = (totalSaleLastYearPercentageKeyValue !== undefined) ? totalSaleLastYearPercentageKeyValue + '%' : totalSaleLastYearPercentageTag.getEl().innerHTML;
				}

				let totalSaleLastYearAmountTag = component.find('.total-sale-widget .media .media-body .sales-widgets h3 span.counter p')[0];
				console.log('totalSaleLastYearAmountTag:', totalSaleLastYearAmountTag);
				// Check if the component was found
				if (totalSaleLastYearAmountTag) {
				    // Manipulate the component
				    totalSaleLastYearAmountTag.getEl().innerHTML = (totalSaleLastYearAmountKeyValue !== undefined) ? totalSaleLastYearAmountKeyValue : totalSaleLastYearAmountTag.getEl().innerHTML;
				}
            } else {
            	console.error('The provided keyname(s) in the trait(s) are not in a javascript object. Please try any other key(s).');
            }
		}

		// api call function for Active_Customer_Last_Day_API trait-set
		async function activeCustomerLastDayAPICall(api, config) {
			let apiData;

            console.log('api:', api);

			const response = await fetch(`${activeCustomerLastDayAPI}`, config);
			const responseData = await response.json();
            
            apiData = responseData;
            
            console.log("apiData:", apiData);

            // if apiData is not 'an array, ar array of objects, an array of anything'
            if (!Array.isArray(apiData)) {
            	let activeCustomerLastDayPercentageKeyValue = findValueByKey(apiData, activeCustomerLastDayPercentage);
            	let activeCustomerLastDayAmountKeyValue = findValueByKey(apiData, activeCustomerLastDayAmount);

            	console.log('activeCustomerLastDayPercentageKeyValue:', activeCustomerLastDayPercentageKeyValue, 'activeCustomerLastDayAmountKeyValue:', activeCustomerLastDayAmountKeyValue);

            	// replacing the content in the template
            	// Find an element within the editor by its selector
				let activeCustomerLastDayPercentageTag = component.find('.total-sale-col .total-sale-widget .media .media-body p span p')[0];
				console.log('activeCustomerLastDayPercentageTag:', activeCustomerLastDayPercentageTag);
				// Check if the component was found
				if (activeCustomerLastDayPercentageTag) {
				    // Manipulate the component
				    activeCustomerLastDayPercentageTag.getEl().innerHTML = (activeCustomerLastDayPercentageKeyValue !== undefined) ? activeCustomerLastDayPercentageKeyValue + '%' : activeCustomerLastDayPercentageTag.getEl().innerHTML;
				}

				let activeCustomerLastDayAmountTag = component.find('.total-sale-col .total-sale-widget .media .media-body .sales-widgets h3 span.counter p')[0];
				console.log('activeCustomerLastDayAmountTag:', activeCustomerLastDayAmountTag);
				// Check if the component was found
				if (activeCustomerLastDayAmountTag) {
				    // Manipulate the component
				    activeCustomerLastDayAmountTag.getEl().innerHTML = (activeCustomerLastDayAmountKeyValue !== undefined) ? activeCustomerLastDayAmountKeyValue : activeCustomerLastDayAmountTag.getEl().innerHTML;
				}
            } else {
            	console.error('The provided keyname(s) in the trait(s) are not in a javascript object. Please try any other key(s).');
            }
		}

		// api call function for Total_Sales_API trait-set
		async function totalSalesAPICall(api, config) {
			let apiData;

            console.log('api:', api);

			const response = await fetch(`${totalSalesAPI}`, config);
			const responseData = await response.json();
            
            apiData = responseData;
            
            console.log("apiData:", apiData);

            // if apiData is not 'an array, ar array of objects, an array of anything'
            if (!Array.isArray(apiData)) {
            	let totalSalesKeyValue = findValueByKey(apiData, totalSales);
            	let salesGoalReachedPercentageKeyValue = findValueByKey(apiData, salesGoalReachedPercentage);
            	let salesGoalReachedCountKeyValue = findValueByKey(apiData, salesGoalReachedCount);

            	console.log('totalSalesKeyValue:', totalSalesKeyValue, 'salesGoalReachedPercentageKeyValue:', salesGoalReachedPercentageKeyValue, 'salesGoalReachedCountKeyValue:', salesGoalReachedCountKeyValue);

            	// replacing the content in the html template
            	// Find an element within the editor by its selector
				let totalSalesTag = component.find('.card.ecommerce-widget .card-body.support-ticket-font .d-flex .data-left-ticket h5.total-num span.counter p')[0];
				console.log('totalSalesTag:', totalSalesTag);
				// Check if the component was found
				if (totalSalesTag) {
				    // Manipulate the component
				    totalSalesTag.getEl().innerHTML = (totalSalesKeyValue !== undefined) ? totalSalesKeyValue : totalSalesTag.getEl().innerHTML;
				}

				let salesGoalReachedPercentageTag = component.find('.monthly-growth .card-footer .growth-revenue-footer .ecommerce-widget .data-right-ticket ul li span p')[0];
				console.log('salesGoalReachedPercentageTag:', salesGoalReachedPercentageTag);
				// Check if the component was found
				if (salesGoalReachedPercentageTag) {
				    // Manipulate the component
				    salesGoalReachedPercentageTag.getEl().innerHTML = (salesGoalReachedPercentageKeyValue !== undefined) ? salesGoalReachedPercentageKeyValue + '% goal reached' : salesGoalReachedPercentageTag.getEl().innerHTML;
				}

				// progress bar
				let totalSalesProgressBar = component.find('.progress-showcase .progress.sm-progress-bar .progress-bar')[0];
				console.log('totalSalesProgressBar:', totalSalesProgressBar);
				totalSalesProgressBar.getEl().style.width = (salesGoalReachedPercentageKeyValue !== undefined) ? salesGoalReachedPercentageKeyValue + '%' : totalSalesProgressBar.getEl().style.width;

				let salesGoalReachedCountTag = component.find('.monthly-growth .card-footer .growth-revenue-footer .ecommerce-widget h6 p')[0];
				console.log('salesGoalReachedCountTag:', salesGoalReachedCountTag);
				// Check if the component was found
				if (salesGoalReachedCountTag) {
				    // Manipulate the component
				    salesGoalReachedCountTag.getEl().innerHTML = (salesGoalReachedCountKeyValue !== undefined) ? salesGoalReachedCountKeyValue : salesGoalReachedCountTag.getEl().innerHTML;
				}
            } else {
            	console.error('The provided keyname(s) in the trait(s) are not in a javascript object. Please try any other key(s).');
            }
		}

		// api call function for Total_Income_API trait-set
		async function totalIncomeAPICall(api, config) {
			let apiData;

            console.log('api:', api);

			const response = await fetch(`${totalIncomeAPI}`, config);
			const responseData = await response.json();
            
            apiData = responseData;
            
            console.log("apiData:", apiData);

            // if apiData is not 'an array, ar array of objects, an array of anything'
            if (!Array.isArray(apiData)) {
            	let totalIncomeKeyValue = findValueByKey(apiData, totalIncome);
            	let incomeGoalReachedPercentageKeyValue = findValueByKey(apiData, incomeGoalReachedPercentage);
            	let incomeGoalReachedCountKeyValue = findValueByKey(apiData, incomeGoalReachedCount);

            	console.log('totalIncomeKeyValue:', totalIncomeKeyValue, 'incomeGoalReachedPercentageKeyValue:', incomeGoalReachedPercentageKeyValue, 'incomeGoalReachedCountKeyValue:', incomeGoalReachedCountKeyValue);

            	// replacing the content in the html template
            	// Find an element within the editor by its selector
				let totalIncomeTag = component.find('.card.ecommerce-widget:nth-child(1) .card-body.support-ticket-font .d-flex .data-left-ticket h5.total-num span.counter p')[0];
				console.log('totalIncomeTag:', totalIncomeTag);
				// Check if the component was found
				if (totalIncomeTag) {
				    // Manipulate the component
				    totalIncomeTag.getEl().innerHTML = (totalIncomeKeyValue !== undefined) ? totalIncomeKeyValue : totalIncomeTag.getEl().innerHTML;
				}

				let incomeGoalReachedPercentageTag = component.find('.monthly-growth .card-footer .growth-revenue-footer .ecommerce-widget:nth-child(1) .data-right-ticket ul li span p')[0];
				console.log('incomeGoalReachedPercentageTag:', incomeGoalReachedPercentageTag);
				// Check if the component was found
				if (incomeGoalReachedPercentageTag) {
				    // Manipulate the component
				    incomeGoalReachedPercentageTag.getEl().innerHTML = (incomeGoalReachedPercentageKeyValue !== undefined) ? incomeGoalReachedPercentageKeyValue + '% goal reached' : incomeGoalReachedPercentageTag.getEl().innerHTML;
				}

				// progress bar
				let totalIncomeProgressBar = component.find('.progress-showcase .progress.sm-progress-bar .progress-bar')[0];
				console.log('totalIncomeProgressBar:', totalIncomeProgressBar);
				totalIncomeProgressBar.getEl().style.width = (incomeGoalReachedPercentageKeyValue !== undefined) ? incomeGoalReachedPercentageKeyValue + '%' : totalIncomeProgressBar.getEl().style.width;

				let incomeGoalReachedCountTag = component.find('.monthly-growth .card-footer .growth-revenue-footer .ecommerce-widget:nth-child(1) h6 p')[0];
				console.log('incomeGoalReachedCountTag:', incomeGoalReachedCountTag);
				// Check if the component was found
				if (incomeGoalReachedCountTag) {
				    // Manipulate the component
				    incomeGoalReachedCountTag.getEl().innerHTML = (incomeGoalReachedCountKeyValue !== undefined) ? incomeGoalReachedCountKeyValue : incomeGoalReachedCountTag.getEl().innerHTML;
				}
            } else {
            	console.error('The provided keyname(s) in the trait(s) are not in a javascript object. Please try any other key(s).');
            }
		}

		// api call function for Active_User_API trait-set
		async function activeUserAPICall(api, config) {
			let apiData;

            console.log('api:', api);

			const response = await fetch(`${activeUserAPI}`, config);
			const responseData = await response.json();
            
            apiData = responseData;
            
            console.log("apiData:", apiData);

            // if apiData is not 'an array, ar array of objects, an array of anything'
            if (!Array.isArray(apiData)) {
            	let activeUserKeyValue = findValueByKey(apiData, activeUser);
            	let activeUserGoalReachedPercentageKeyValue = findValueByKey(apiData, activeUserGoalReachedPercentage);
            	let activeUserGoalReachedCountKeyValue = findValueByKey(apiData, activeUserGoalReachedCount);

            	console.log('activeUserKeyValue:', activeUserKeyValue, 'activeUserGoalReachedPercentageKeyValue:', activeUserGoalReachedPercentageKeyValue, 'activeUserGoalReachedCountKeyValue:', activeUserGoalReachedCountKeyValue);

            	// replacing the content in the html template
            	// Find an element within the editor by its selector
				let activeUserTag = component.find('.card.ecommerce-widget:nth-child(1) .card-body.support-ticket-font .d-flex .data-left-ticket h5.total-num span.counter p')[0];
				console.log('activeUserTag:', activeUserTag);
				// Check if the component was found
				if (activeUserTag) {
				    // Manipulate the component
				    activeUserTag.getEl().innerHTML = (activeUserKeyValue !== undefined) ? activeUserKeyValue : activeUserTag.getEl().innerHTML;
				}

				let activeUserGoalReachedPercentageTag = component.find('.monthly-growth .card-footer .growth-revenue-footer .ecommerce-widget:nth-child(1) .data-right-ticket ul li span p')[0];
				console.log('activeUserGoalReachedPercentageTag:', activeUserGoalReachedPercentageTag);
				// Check if the component was found
				if (activeUserGoalReachedPercentageTag) {
				    // Manipulate the component
				    activeUserGoalReachedPercentageTag.getEl().innerHTML = (activeUserGoalReachedPercentageKeyValue !== undefined) ? activeUserGoalReachedPercentageKeyValue + '% goal reached' : activeUserGoalReachedPercentageTag.getEl().innerHTML;
				}

				// progress bar
				let activeUserGoalReachedPercentageProgressBar = component.find('.progress-showcase .progress.sm-progress-bar .progress-bar')[0];
				console.log('activeUserGoalReachedPercentageProgressBar:', activeUserGoalReachedPercentageProgressBar);
				activeUserGoalReachedPercentageProgressBar.getEl().style.width = (activeUserGoalReachedPercentageKeyValue !== undefined) ? activeUserGoalReachedPercentageKeyValue + '%' : activeUserGoalReachedPercentageProgressBar.getEl().style.width;

				let activeUserGoalReachedCountKeyValueTag = component.find('.monthly-growth .card-footer .growth-revenue-footer .ecommerce-widget:nth-child(1) h6 p')[0];
				console.log('activeUserGoalReachedCountKeyValueTag:', activeUserGoalReachedCountKeyValueTag);
				// Check if the component was found
				if (activeUserGoalReachedCountKeyValueTag) {
				    // Manipulate the component
				    activeUserGoalReachedCountKeyValueTag.getEl().innerHTML = (activeUserGoalReachedCountKeyValue !== undefined) ? activeUserGoalReachedCountKeyValue : activeUserGoalReachedCountKeyValueTag.getEl().innerHTML;
				}
            } else {
            	console.error('The provided keyname(s) in the trait(s) are not in a javascript object. Please try any other key(s).');
            }
		}

		// api call function for Total_Tax_API trait-set
		async function totalTaxAPICall(api, config) {
			let apiData;

            console.log('api:', api);

			const response = await fetch(`${totalTaxAPI}`, config);
			const responseData = await response.json();
            
            apiData = responseData;
            
            console.log("apiData:", apiData);

            // if apiData is not 'an array, ar array of objects, an array of anything'
            if (!Array.isArray(apiData)) {
            	let totalTaxKeyValue = findValueByKey(apiData, totalTax);
            	let taxGoalReachedPercentageKeyValue = findValueByKey(apiData, taxGoalReachedPercentage);
            	let taxGoalReachedCountKeyValue = findValueByKey(apiData, taxGoalReachedCount);

            	console.log('totalTaxKeyValue:', totalTaxKeyValue, 'taxGoalReachedPercentageKeyValue:', taxGoalReachedPercentageKeyValue, 'taxGoalReachedCountKeyValue:', taxGoalReachedCountKeyValue);

            	// replacing the content in the html template
            	// Find an element within the editor by its selector
				let totalTaxTag = component.find('.card.ecommerce-widget:nth-child(1) .card-body.support-ticket-font .d-flex .data-left-ticket h5.total-num span.counter p')[0];
				console.log('totalTaxTag:', totalTaxTag);
				// Check if the component was found
				if (totalTaxTag) {
				    // Manipulate the component
				    totalTaxTag.getEl().innerHTML = (totalTaxKeyValue !== undefined) ? totalTaxKeyValue : totalTaxTag.getEl().innerHTML;
				}

				let taxGoalReachedPercentageTag = component.find('.monthly-growth .card-footer .growth-revenue-footer .ecommerce-widget:nth-child(1) .data-right-ticket ul li span p')[0];
				console.log('taxGoalReachedPercentageTag:', taxGoalReachedPercentageTag);
				// Check if the component was found
				if (taxGoalReachedPercentageTag) {
				    // Manipulate the component
				    taxGoalReachedPercentageTag.getEl().innerHTML = (taxGoalReachedPercentageKeyValue !== undefined) ? taxGoalReachedPercentageKeyValue + '% goal reached' : taxGoalReachedPercentageTag.getEl().innerHTML;
				}

				// progress bar
				let taxGoalReachedPercentageProgressBar = component.find('.progress-showcase .progress.sm-progress-bar .progress-bar')[0];
				console.log('taxGoalReachedPercentageProgressBar:', taxGoalReachedPercentageProgressBar);
				taxGoalReachedPercentageProgressBar.getEl().style.width = (taxGoalReachedPercentageKeyValue !== undefined) ? taxGoalReachedPercentageKeyValue + '%' : taxGoalReachedPercentageProgressBar.getEl().style.width;

				let taxGoalReachedCountTag = component.find('.monthly-growth .card-footer .growth-revenue-footer .ecommerce-widget:nth-child(1) h6 p')[0];
				console.log('taxGoalReachedCountTag:', taxGoalReachedCountTag);
				// Check if the component was found
				if (taxGoalReachedCountTag) {
				    // Manipulate the component
				    taxGoalReachedCountTag.getEl().innerHTML = (taxGoalReachedCountKeyValue !== undefined) ? taxGoalReachedCountKeyValue : taxGoalReachedCountTag.getEl().innerHTML;
				}
            } else {
            	console.error('The provided keyname(s) in the trait(s) are not in a javascript object. Please try any other key(s).');
            }
		}

		// api call function for Table1_API trait-set
		async function table1APICall(api, config) {
			let apiData;

            console.log('api:', api);

			const response = await fetch(`${table1API}`, config);
			const responseData = await response.json();
            
            apiData = responseData;
            
            console.log("apiData:", apiData);

            // if apiData is not 'an array, ar array of objects, an array of anything'
            if (isArrayofObjects(apiData)) {
            	let mainTable = component.find('.table1')[0];
				console.log('mainTable:', mainTable);

				// creating a table with data
				let thead = document.createElement('thead');
				let tr1 = document.createElement('tr');
				// looping over keynames to create <th>
				Object.keys(apiData[0]).forEach((keyname) => {
					let th = document.createElement('th');
					th.innerHTML = `${keyname}`;
					tr1.appendChild(th);
				});

				thead.appendChild(tr1);
				mainTable.getEl().innerHTML = thead.outerHTML;

				let tbody = document.createElement('tbody');
				// looping over objects in the array to create <tr>
				apiData.forEach((rowObj) => {
					let tr = document.createElement('tr');
					// looping over object values in that array to create <td>
					Object.values(rowObj).forEach((value) => {
						let td = document.createElement('td');

						let divOuter = document.createElement('div');
						divOuter.setAttribute('class', 'd-inline-block align-middle');

						let divInner = document.createElement('div');
						divInner.setAttribute('class', 'd-inline-block');

						let spanInner = document.createElement('span');
						spanInner.innerHTML = `${value}`;

						divInner.appendChild(spanInner);
						divOuter.appendChild(divInner);
						td.appendChild(divOuter);
						tr.appendChild(td);
					});

					tbody.appendChild(tr);
				});

				mainTable.getEl().appendChild(tbody);

				// mainTable.getEl().innerHTML = 'dafdfa';
            }

            // if apiData is a javascript object {...} and not an array or any other data-type
            else if (typeof apiData === 'object' && !Array.isArray(apiData) && apiData !== null) {
            	if (table1Keyname !== undefined || table1Keyname !== "") {
            		let arrObj = findValueByKey(apiData, table1Keyname);
            		console.log('arrObj:', arrObj);

            		// if arrObj is not an array, ar array of objects, an array of anything
                    if (isArrayofObjects(arrObj)) {
                    	let mainTable = component.find('.table1')[0];
						console.log('mainTable:', mainTable);

						// creating a table with data
						let thead = document.createElement('thead');
						let tr1 = document.createElement('tr');
						// looping over keynames to create <th>
						Object.keys(arrObj[0]).forEach((keyname) => {
							let th = document.createElement('th');
							th.innerHTML = `${keyname}`;
							tr1.appendChild(th);
						});

						thead.appendChild(tr1);
						mainTable.getEl().innerHTML = thead.outerHTML;

						let tbody = document.createElement('tbody');
						// looping over objects in the array to create <tr>
						arrObj.forEach((rowObj) => {
							let tr = document.createElement('tr');
							// looping over object values in that array to create <td>
							Object.values(rowObj).forEach((value) => {
								let td = document.createElement('td');

								let divOuter = document.createElement('div');
								divOuter.setAttribute('class', 'd-inline-block align-middle');

								let divInner = document.createElement('div');
								divInner.setAttribute('class', 'd-inline-block');

								let spanInner = document.createElement('span');
								spanInner.innerHTML = `${value}`;

								divInner.appendChild(spanInner);
								divOuter.appendChild(divInner);
								td.appendChild(divOuter);
								tr.appendChild(td);
							});

							tbody.appendChild(tr);
						});

						mainTable.getEl().appendChild(tbody);

						// mainTable.getEl().innerHTML = 'dafdfa';
                    } else {
                		console.error("The value of this keyname is not an array of objects. Please try another keyname.");
                	}
            	} else {
            		console.error("Keyname trait not provided for an array of objects' plotting.");
            	}
            } else {
            	console.error('The response caught has no array of objects. Please try another API.');
            }
		}

		// api call function for Table2_API trait-set
		async function table2APICall(api, config) {
			let apiData;

            console.log('api:', api);

			const response = await fetch(`${table2API}`, config);
			const responseData = await response.json();
            
            apiData = responseData;
            
            console.log("apiData:", apiData);

            // if apiData is not 'an array, ar array of objects, an array of anything'
            if (isArrayofObjects(apiData)) {
            	let mainTable = component.find('.table2')[0];
				console.log('mainTable:', mainTable);

				// creating a table with data
				let thead = document.createElement('thead');
				let tr1 = document.createElement('tr');
				// looping over keynames to create <th>
				Object.keys(apiData[0]).forEach((keyname) => {
					let th = document.createElement('th');
					th.innerHTML = `${keyname}`;
					tr1.appendChild(th);
				});

				thead.appendChild(tr1);
				mainTable.getEl().innerHTML = thead.outerHTML;

				let tbody = document.createElement('tbody');
				// looping over objects in the array to create <tr>
				apiData.forEach((rowObj) => {
					let tr = document.createElement('tr');
					// looping over object values in that array to create <td>
					Object.values(rowObj).forEach((value) => {
						let td = document.createElement('td');

						let divOuter = document.createElement('div');
						divOuter.setAttribute('class', 'd-inline-block align-middle');

						let divInner = document.createElement('div');
						divInner.setAttribute('class', 'd-inline-block');

						let spanInner = document.createElement('span');
						spanInner.innerHTML = `${value}`;

						divInner.appendChild(spanInner);
						divOuter.appendChild(divInner);
						td.appendChild(divOuter);
						tr.appendChild(td);
					});

					tbody.appendChild(tr);
				});

				mainTable.getEl().appendChild(tbody);

				// mainTable.getEl().innerHTML = 'dafdfa';
            }

            // if apiData is a javascript object {...} and not an array or any other data-type
            else if (typeof apiData === 'object' && !Array.isArray(apiData) && apiData !== null) {
            	if (table1Keyname !== undefined || table1Keyname !== "") {
            		let arrObj = findValueByKey(apiData, table1Keyname);
            		console.log('arrObj:', arrObj);

            		// if arrObj is not an array, ar array of objects, an array of anything
                    if (isArrayofObjects(arrObj)) {
                    	let mainTable = component.find('.table2')[0];
						console.log('mainTable:', mainTable);

						// creating a table with data
						let thead = document.createElement('thead');
						let tr1 = document.createElement('tr');
						// looping over keynames to create <th>
						Object.keys(arrObj[0]).forEach((keyname) => {
							let th = document.createElement('th');
							th.innerHTML = `${keyname}`;
							tr1.appendChild(th);
						});

						thead.appendChild(tr1);
						mainTable.getEl().innerHTML = thead.outerHTML;

						let tbody = document.createElement('tbody');
						// looping over objects in the array to create <tr>
						arrObj.forEach((rowObj) => {
							let tr = document.createElement('tr');
							// looping over object values in that array to create <td>
							Object.values(rowObj).forEach((value) => {
								let td = document.createElement('td');

								let divOuter = document.createElement('div');
								divOuter.setAttribute('class', 'd-inline-block align-middle');

								let divInner = document.createElement('div');
								divInner.setAttribute('class', 'd-inline-block');

								let spanInner = document.createElement('span');
								spanInner.innerHTML = `${value}`;

								divInner.appendChild(spanInner);
								divOuter.appendChild(divInner);
								td.appendChild(divOuter);
								tr.appendChild(td);
							});

							tbody.appendChild(tr);
						});

						mainTable.getEl().appendChild(tbody);

						// mainTable.getEl().innerHTML = 'dafdfa';
                    } else {
                		console.error("The value of this keyname is not an array of objects. Please try another keyname.");
                	}
            	} else {
            		console.error("Keyname trait not provided for an array of objects' plotting.");
            	}
            } else {
            	console.error('The response caught has no array of objects. Please try another API.');
            }
		}

		// api call function for Table3_API trait-set
		async function table3APICall(api, config) {
			let apiData;

            console.log('api:', api);

			const response = await fetch(`${table3API}`, config);
			const responseData = await response.json();
            
            apiData = responseData;
            
            console.log("apiData:", apiData);

            // if apiData is not 'an array, ar array of objects, an array of anything'
            if (isArrayofObjects(apiData)) {
            	let mainTable = component.find('.table3')[0];
				console.log('mainTable:', mainTable);

				// creating a table with data
				let thead = document.createElement('thead');
				let tr1 = document.createElement('tr');
				// looping over keynames to create <th>
				Object.keys(apiData[0]).forEach((keyname) => {
					let th = document.createElement('th');
					th.innerHTML = `${keyname}`;
					tr1.appendChild(th);
				});

				thead.appendChild(tr1);
				mainTable.getEl().innerHTML = thead.outerHTML;

				let tbody = document.createElement('tbody');
				// looping over objects in the array to create <tr>
				apiData.forEach((rowObj) => {
					let tr = document.createElement('tr');
					// looping over object values in that array to create <td>
					Object.values(rowObj).forEach((value) => {
						let td = document.createElement('td');

						let divOuter = document.createElement('div');
						divOuter.setAttribute('class', 'd-inline-block align-middle');

						let divInner = document.createElement('div');
						divInner.setAttribute('class', 'd-inline-block');

						let spanInner = document.createElement('span');
						spanInner.innerHTML = `${value}`;

						divInner.appendChild(spanInner);
						divOuter.appendChild(divInner);
						td.appendChild(divOuter);
						tr.appendChild(td);
					});

					tbody.appendChild(tr);
				});

				mainTable.getEl().appendChild(tbody);

				// mainTable.getEl().innerHTML = 'dafdfa';
            }

            // if apiData is a javascript object {...} and not an array or any other data-type
            else if (typeof apiData === 'object' && !Array.isArray(apiData) && apiData !== null) {
            	if (table1Keyname !== undefined || table1Keyname !== "") {
            		let arrObj = findValueByKey(apiData, table1Keyname);
            		console.log('arrObj:', arrObj);

            		// if arrObj is not an array, ar array of objects, an array of anything
                    if (isArrayofObjects(arrObj)) {
                    	let mainTable = component.find('.table3')[0];
						console.log('mainTable:', mainTable);

						// creating a table with data
						let thead = document.createElement('thead');
						let tr1 = document.createElement('tr');
						// looping over keynames to create <th>
						Object.keys(arrObj[0]).forEach((keyname) => {
							let th = document.createElement('th');
							th.innerHTML = `${keyname}`;
							tr1.appendChild(th);
						});

						thead.appendChild(tr1);
						mainTable.getEl().innerHTML = thead.outerHTML;

						let tbody = document.createElement('tbody');
						// looping over objects in the array to create <tr>
						arrObj.forEach((rowObj) => {
							let tr = document.createElement('tr');
							// looping over object values in that array to create <td>
							Object.values(rowObj).forEach((value) => {
								let td = document.createElement('td');

								let divOuter = document.createElement('div');
								divOuter.setAttribute('class', 'd-inline-block align-middle');

								let divInner = document.createElement('div');
								divInner.setAttribute('class', 'd-inline-block');

								let spanInner = document.createElement('span');
								spanInner.innerHTML = `${value}`;

								divInner.appendChild(spanInner);
								divOuter.appendChild(divInner);
								td.appendChild(divOuter);
								tr.appendChild(td);
							});

							tbody.appendChild(tr);
						});

						mainTable.getEl().appendChild(tbody);

						// mainTable.getEl().innerHTML = 'dafdfa';
                    } else {
                		console.error("The value of this keyname is not an array of objects. Please try another keyname.");
                	}
            	} else {
            		console.error("Keyname trait not provided for an array of objects' plotting.");
            	}
            } else {
            	console.error('The response caught has no array of objects. Please try another API.');
            }
		}

		// api call function for Total_Investment_API trait-set
		async function totalInvestmentCardAPICall(api, config) {
			let apiData;

            console.log('api:', api);

			const response = await fetch(`${api}`, config);
			const responseData = await response.json();
            
            apiData = responseData;
            
            console.log("apiData:", apiData);

            // if apiData is not 'an array, ar array of objects, an array of anything'
            if (!Array.isArray(apiData)) {
            	let totalResponseKeyValue = findValueByKey(apiData, totalResponseKeyname);
            	let monthlyResponseKeyValue = findValueByKey(apiData, monthlyResponseKeyname);
            	let dailyResponseKeyValue = findValueByKey(apiData, dailyResponseKeyname);

            	console.log('totalResponseKeyValue:', totalResponseKeyValue, 'monthlyResponseKeyValue:', monthlyResponseKeyValue, 'dailyResponseKeyValue:', dailyResponseKeyValue);

            	// replacing the content in the template
            	// Find an element within the editor by its selector
				let totalResponseTag = component.find('.investments .card-footer ul li:nth-child(1) h6')[0];
				console.log('totalResponseTag:', totalResponseTag);
				// Check if the component was found
				if (totalResponseTag) {
				    // Manipulate the component
				    totalResponseTag.getEl().innerHTML = (totalResponseKeyValue !== undefined) ? totalResponseKeyValue : totalResponseTag.getEl().innerHTML;
				}

				let monthlyResponseTag = component.find('.investments .card-footer ul li:nth-child(2) h6')[0];
				console.log('monthlyResponseTag:', monthlyResponseTag);
				// Check if the component was found
				if (monthlyResponseTag) {
				    // Manipulate the component
				    monthlyResponseTag.getEl().innerHTML = (monthlyResponseKeyValue !== undefined) ? monthlyResponseKeyValue : monthlyResponseTag.getEl().innerHTML;
				}

				let dailyResponseTag = component.find('.investments .card-footer ul li:nth-child(3) h6')[0];
				console.log('dailyResponseTag:', dailyResponseTag);
				// Check if the component was found
				if (dailyResponseTag) {
				    // Manipulate the component
				    dailyResponseTag.getEl().innerHTML = (dailyResponseKeyValue !== undefined) ? dailyResponseKeyValue : dailyResponseTag.getEl().innerHTML;
				}
            } else {
            	console.error('The provided keyname(s) in the trait(s) are not in a javascript object. Please try any other key(s).');
            }
		}
		

		// helper functions - to get the value of a keyname in an object of nested objects
		function findValueByKey(obj, keyToFind) {
		    // Base case: if the object is not an object or is empty, return undefined
		    if (typeof obj !== 'object' || obj === null || Object.keys(obj).length === 0) {
		        return undefined;
		    }

		    // Check if the current object has the key we're looking for
		    if (obj.hasOwnProperty(keyToFind)) {
		        return obj[keyToFind];
		    }

		    // Recursively search through nested objects
		    for (let key in obj) {
		        const foundValue = findValueByKey(obj[key], keyToFind);
		        if (foundValue !== undefined) {
		            return foundValue;
		        }
		    }

		    // Key not found in the current object or its descendants
		    return undefined;
		}

		// helper function - to check if the response is an array of objects
		// stricter version of isArrayofObjects()
		function isArrayofObjects(arr) {
		    if (!Array.isArray(arr)) {
		        return false; // Not an array
		    }

		    for (const obj of arr) {
		        if (typeof obj !== 'object' || obj === null || Array.isArray(obj)) {
		            return false; // Not an object or contains an array
		        }

		        // Check if the object has no properties with values that are not plain
		        for (const key in obj) {
		            if (obj.hasOwnProperty(key)) {
		                if (typeof obj[key] !== 'string' && typeof obj[key] !== 'number' && typeof obj[key] !== 'boolean' && obj[key] !== null) {
		                    return false; // Value is not a string, number, boolean, or null
		                }
		            }
		        }
		    }

		    return true;
		}

		// lenient version of isArrayofObjects()
		// function isArrayofObjects(arr) {
		//     if (Array.isArray(arr)) {
		//         for (let i = 0; i < arr.length; i++) {
		//             if (typeof arr[i] !== 'object' || Array.isArray(arr[i]) || arr[i] === null) {
		//                 return false;
		//             }
		//         }
		//         return true;
		//     }
		//     return false;
		// }

		// const main = document.querySelector('.page-body');
		// const styling = document.createElement('style');

		// styling.innerHTML = `
		// 	.card {
		// 		margin-bottom: 30px;
		// 		border: none;
		// 		-webkit-transition: all 0.3s ease;
		// 		transition: all 0.3s ease;
		// 		letter-spacing: 0.5px;
		// 		border-radius: 10px;
		// 		-webkit-box-shadow: 0 0 41px 18px #f9fafb;
		// 		box-shadow: 0 0 41px 18px #f9fafb;
		// 	}

		// 	.card .card-header {
		// 		background-color: #fff;
		// 		padding: 30px;
		// 		border-bottom: 1px solid #dfe5f5;
		// 		border-top-left-radius: 10px;
		// 		border-top-right-radius: 10px;
		// 		position: relative;
		// 	}

		// 	.card .card-header.card-no-border {
		// 		border-bottom: none !important;
		// 	}

		// 	.card .card-header h5 {
		// 		font-size: 18px;
		// 	}

		// 	.card .card-header h5:not(.mb-0) {
		// 		margin-bottom: 0;
		// 		text-transform: capitalize;
		// 		font-weight: 600;
		// 		line-height: 1;
		// 	}

		// 	.card .card-header>span {
		// 		font-size: 12px;
		// 		font-family: Roboto, sans-serif;
		// 		color: #8392a5;
		// 		margin-top: 5px;
		// 		display: block;
		// 		letter-spacing: 1px;
		// 	}

		// 	.card .card-body {
		// 		padding: 30px;
		// 		background-color: rgba(0, 0, 0, 0);
		// 	}

		// 	.card .card-body p:last-child {
		// 		margin-bottom: 0;
		// 	}

		// 	.card .sub-title {
		// 		border-bottom: 1px solid rgba(0, 0, 0, 0.1);
		// 		padding-bottom: 5px;
		// 		margin-bottom: 8px;
		// 		font-size: 18px;
		// 	}

		// 	.card .card-footer {
		// 		background-color: #fff;
		// 		border-top: 1px solid #dfe5f5;
		// 		padding: 30px;
		// 		border-bottom-left-radius: 10px;
		// 		border-bottom-right-radius: 10px;
		// 	}

		// 	.card.card-with-border {
		// 		border: 1px solid #dfe5f5;
		// 		-webkit-box-shadow: none;
		// 		box-shadow: none;
		// 	}

		// 	.card.card-with-border .card-header {
		// 		border-bottom: 1px solid #dfe5f5;
		// 	}

		// 	.card .card-header .card-header-right {
		// 		border-radius: 0 0 0 7px;
		// 		right: 20px;
		// 		top: 22px;
		// 		display: inline-block;
		// 		float: right;
		// 		padding: 8px 0;
		// 		position: absolute;
		// 		background-color: #fff;
		// 		z-index: 1;
		// 	}

		// 	.card .card-header .card-header-right .card-option {
		// 		text-align: right;
		// 		width: 35px;
		// 		height: 20px;
		// 		overflow: hidden;
		// 		-webkit-transition: 0.3s ease-in-out;
		// 		transition: 0.3s ease-in-out;
		// 	}

		// 	.card .card-header .card-header-right .card-option li {
		// 		display: inline-block;
		// 	}

		// 	.card .card-header .card-header-right .card-option li:first-child i {
		// 		font-size: 18px;
		// 	}

		// 	.card .card-header .card-header-right i {
		// 		margin: 0 5px;
		// 		cursor: pointer;
		// 		color: #2c323f;
		// 		line-height: 20px;
		// 	}

		// 	.card .card-header .card-header-right i.icofont-refresh {
		// 		font-size: 13px;
		// 	}

		// 	.box-col-12 {
		// 			-webkit-box-flex: 0;
		// 			-ms-flex: 0 0 100%;
		// 			flex: 0 0 100%;
		// 			max-width: 100%;
		// 		}

		// 	.total-sale-widget .media-left {
		// 		width: 40%;
		// 		outline: 5px solid red;
		// 	}

		// 	.total-sale-widget .media-right {
		// 		width: calc(60% - 20px);
		// 		overflow: hidden;
		// 	}

		// 	.total-sale-widget .media .media-body p {
		// 		color: #59667a;
		// 		font-weight: 500;
		// 	}

		// 	.total-sale-widget .media .media-body p span {
		// 		margin-left: 10px;
		// 	}

		// 	.total-sale-widget .media .media-body p span i {
		// 		margin-left: 6px;
		// 	}

		// 	.total-sale-widget .media .media-body .sales-widgets {
		// 		-webkit-box-align: center;
		// 		-ms-flex-align: center;
		// 		align-items: center;
		// 	}

		// 	.total-sale-widget .media .media-body .sales-widgets h3 {
		// 		display: -webkit-box;
		// 		display: -ms-flexbox;
		// 		display: flex;
		// 		-webkit-box-align: center;
		// 		-ms-flex-align: center;
		// 		align-items: center;
		// 	}

		// 	.total-sale-widget .media .media-body .sales-widgets h3 svg {
		// 		width: 22px;
		// 		height: 22px;
		// 		stroke-width: 3;
		// 	}

		// 	.total-sale-widget .media .media-body .sales-widgets p {
		// 		margin-left: 20px;
		// 	}

		// 	.o-hidden {
		// 		overflow: hidden;
		// 	}

		// 	.media-widgets .media-body {
		// 		margin-bottom: 30px;
		// 	}

		// 	.f-w-400 {
		// 		font-weight: 400;
		// 	}

		// 	.f-w-600 {
		// 		font-weight: 600;
		// 	}

		// 	.f-w-700 {
		// 		font-weight: 700;
		// 	}

		// 	.f-w-900 {
		// 		font-weight: 900;
		// 	}

		// 	.txt-primary {
		// 		color: #655af3 !important;
		// 	}

		// 	.f-12 {
		// 		font-size: 12px;
		// 	}

		// 	.font-warning {
		// 		color: #ff5f24 !important;
		// 	}

		// 	.chart-value-box span {
		// 		font-size: 12px;
		// 		font-family: Roboto, sans-serif;
		// 		color: #8392a5;
		// 	}

		// 	.card .card-header h6 .pull-right i {
		// 		position: absolute;
		// 		top: 4px;
		// 		left: 0;
		// 		font-size: 10px;
		// 		width: 100%;
		// 		height: 10px;
		// 		text-align: right;
		// 		cursor: pointer;
		// 	}

		// 	.value-square-box-primary {
		// 		background-color: #655af3;
		// 		width: 8px;
		// 		display: inline-block;
		// 		height: 8px;
		// 		margin-right: 8px;
		// 		border-radius: 100%;
		// 	}

		// 	.value-square-box-secondary {
		// 		background-color: #148df6;
		// 		width: 8px;
		// 		display: inline-block;
		// 		height: 8px;
		// 		margin-right: 8px;
		// 		border-radius: 100%;
		// 	}

		// 	.value-square-box-light {
		// 		background-color: #e8ebf2;
		// 		width: 8px;
		// 		display: inline-block;
		// 		height: 8px;
		// 		margin-right: 8px;
		// 		border-radius: 100%;
		// 	}

		// 	.dashboard-rounded-chart svg {
		// 		height: 260px !important;
		// 	}

		// 	.flot-chart-container {
		// 		-webkit-box-sizing: border-box;
		// 		box-sizing: border-box;
		// 		width: 100%;
		// 		height: 400px;
		// 	}

		// 	.dashboard-rounded-chart.flot-chart-container {
		// 		height: 245px;
		// 	}

		// 	.monthly-growth .card-footer .growth-revenue-footer {
		// 		margin: 0;
		// 	}

		// 	.monthly-growth .card-footer .growth-revenue-footer .ecommerce-widget {
		// 		border: none;
		// 	}

		// 	.monthly-growth .card-footer .growth-revenue-footer .ecommerce-widget .progress-showcase {
		// 		margin-top: 20px;
		// 	}

		// 	.monthly-growth .card-footer .growth-revenue-footer .ecommerce-widget .progress-showcase .sm-progress-bar {
		// 		height: 3px;
		// 	}

		// 	.monthly-growth .card-footer .growth-revenue-footer .ecommerce-widget .support-ticket-font>div {
		// 		-webkit-box-pack: justify;
		// 		-ms-flex-pack: justify;
		// 		justify-content: space-between;
		// 	}

		// 	.monthly-growth .card-footer .growth-revenue-footer .ecommerce-widget ul li:last-child {
		// 		margin-top: 15px;
		// 	}

		// 	.monthly-growth .card-footer .growth-revenue-footer .ecommerce-widget .total-num {
		// 		font-weight: normal;
		// 		margin-top: 5px;
		// 	}

		// 	.monthly-growth .card-footer .growth-revenue-footer .ecommerce-widget .total-num span {
		// 		color: #1b3155;
		// 		font-weight: 600;
		// 	}

		// 	.monthly-growth .card-footer .growth-revenue-footer .ecommerce-widget svg {
		// 		-webkit-filter: none;
		// 		filter: none;
		// 		stroke-width: 3;
		// 		width: 22px;
		// 		height: 22px;
		// 	}

		// 	.monthly-growth .card-footer .growth-revenue-footer .ecommerce-widget span,
		// 	.monthly-growth .card-footer .growth-revenue-footer .ecommerce-widget h6 {
		// 		color: #59667a;
		// 		font-weight: 500;
		// 	}

		// 	.monthly-growth .card-footer .growth-revenue-footer .col-6 {
		// 		padding: 0;
		// 	}

		// 	.monthly-growth .card-footer .growth-revenue-footer .col-6:nth-child(odd) {
		// 		border-right: 1px solid #e8ebf2;
		// 	}

		// 	.monthly-growth .card-footer .growth-revenue-footer .col-6 {
		// 		padding: 0;
		// 	}

		// 	.monthly-growth .card-footer .growth-revenue-footer .ecommerce-widget {
		// 		border: none;
		// 	}

		// 	.monthly-growth .card-footer .growth-revenue-footer .ecommerce-widget .progress-showcase {
		// 		margin-top: 20px;
		// 	}

		// 	.monthly-growth .card-footer .growth-revenue-footer .ecommerce-widget .progress-showcase .sm-progress-bar {
		// 		height: 3px;
		// 	}

		// 	.monthly-growth .card-footer .growth-revenue-footer .ecommerce-widget .support-ticket-font>div {
		// 		-webkit-box-pack: justify;
		// 		-ms-flex-pack: justify;
		// 		justify-content: space-between;
		// 	}

		// 	.monthly-growth .card-footer .growth-revenue-footer .ecommerce-widget ul li:last-child {
		// 		margin-top: 15px;
		// 	}

		// 	.monthly-growth .card-footer .growth-revenue-footer .ecommerce-widget .total-num {
		// 		font-weight: normal;
		// 		margin-top: 5px;
		// 	}

		// 	.monthly-growth .card-footer .growth-revenue-footer .ecommerce-widget .total-num span {
		// 		color: #1b3155;
		// 		font-weight: 600;
		// 	}

		// 	.monthly-growth .card-footer .growth-revenue-footer .ecommerce-widget svg {
		// 		-webkit-filter: none;
		// 		filter: none;
		// 		stroke-width: 3;
		// 		width: 22px;
		// 		height: 22px;
		// 	}

		// 	.monthly-growth .card-footer .growth-revenue-footer .ecommerce-widget span,
		// 	.monthly-growth .card-footer .growth-revenue-footer .ecommerce-widget h6 {
		// 		color: #59667a;
		// 		font-weight: 500;
		// 	}

		// 	.ecommerce-widget {
		// 		-webkit-box-shadow: none;
		// 		box-shadow: none;
		// 		border: 1px solid #f1f3ff;
		// 		border-radius: 5px;
		// 	}

		// 	.ecommerce-widget .total-num {
		// 		font-family: Montserrat, sans-serif;
		// 		font-weight: 600;
		// 		color: #1b3155;
		// 		margin: 0;
		// 		letter-spacing: 1px;
		// 	}

		// 	.ecommerce-widget .total-num span {
		// 		color: #000;
		// 	}

		// 	.ecommerce-widget .row {
		// 		-webkit-box-align: center;
		// 		-ms-flex-align: center;
		// 		align-items: center;
		// 	}

		// 	.ecommerce-widget .progress-showcase {
		// 		margin-top: 30px;
		// 	}

		// 	.ecommerce-widget span {
		// 		color: #8392a5;
		// 		margin: 0;
		// 	}

		// 	.ecommerce-widget .icon {
		// 		color: #efefef;
		// 		font-size: 40px;
		// 	}

		// 	.ecommerce-widget .product-stts {
		// 		font-family: Montserrat, sans-serif;
		// 	}

		// 	.ecommerce-widget .flot-chart-container {
		// 		height: 100px;
		// 		padding: 0;
		// 		margin: 0;
		// 		border: transparent;
		// 	}

		// 	.ecommerce-widget .morris-default-style {
		// 		display: none !important;
		// 	}

		// 	.ecommerce-widget svg {
		// 		-webkit-filter: drop-shadow(12px 12px 7px rgba(0, 0, 0, 0.3));
		// 		filter: drop-shadow(12px 12px 7px rgba(0, 0, 0, 0.3));
		// 	}

		// 	.monthly-growth .card-footer .growth-revenue-footer .ecommerce-widget .support-ticket-font>div {
		// 		-webkit-box-pack: justify;
		// 		-ms-flex-pack: justify;
		// 		justify-content: space-between;
		// 	}

		// 	.monthly-growth .card-footer .growth-revenue-footer .ecommerce-widget .total-num {
		// 		font-weight: normal;
		// 		margin-top: 5px;
		// 	}

		// 	.monthly-growth .card-footer .growth-revenue-footer .ecommerce-widget .total-num span {
		// 		color: #1b3155;
		// 		font-weight: 600;
		// 	}

		// 	.table-bordernone td {
		// 		border: 0;
		// 	}

		// 	.table-bordernone .bd-t-none {
		// 		border-top: none;
		// 	}

		// 	.table-bordernone .u-s-tb {
		// 		padding: 11px;
		// 	}

		// 	.table {
		// 		margin-bottom: 0px;
		// 	}

		// 	.table th,
		// 	.table td {
		// 		padding: .75rem;
		// 	}

		// 	.table th {
		// 		color: #1b3155;
		// 	}

		// 	.table td {
		// 		color: #1b3155;
		// 	}

		// 	.users-total table thead tr th {
		// 		font-size: 15px;
		// 		padding: 20px 30px;
		// 		font-weight: 500;
		// 		border-top: none;
		// 		border-bottom: none;
		// 		background-color: #f8faff;
		// 		font-family: Roboto, sans-serif;
		// 	}

		// 	.users-total table tbody tr td {
		// 		font-weight: 500;
		// 		vertical-align: middle;
		// 		padding: 15px 30px;
		// 		border-bottom: 1px solid #f2f4ff;
		// 	}

		// 	.users-total table tbody tr td .badge-secondary {
		// 		-webkit-box-shadow: -0.5px 4.33px 16px 0px rgba(20, 141, 246, 0.25);
		// 		box-shadow: -0.5px 4.33px 16px 0px rgba(20, 141, 246, 0.25)
		// 	}

		// 	.users-total table tbody tr td .d-flex {
		// 		-webkit-box-align: center;
		// 		-ms-flex-align: center;
		// 		align-items: center;
		// 	}

		// 	.users-total table tbody tr td .round-product {
		// 		width: 40px;
		// 		height: 40px;
		// 		border-radius: 100%;
		// 		background-color: rgba(255, 95, 36, 0.08);
		// 		text-align: center;
		// 		line-height: 3.5;
		// 	}

		// 	.users-total table tbody tr td i.icofont {
		// 		font-size: 22px;
		// 	}

		// 	.users-total table tbody tr td .round-product-dark {
		// 		background-color: #f0f1f3;
		// 	}

		// 	.users-total table tbody tr td:last-child,
		// 	.users-total table tbody tr td:nth-child(3) {
		// 		font-family: Roboto, sans-serif;
		// 		font-weight: 500;
		// 	}

		// 	.users-total table tbody tr td .align-middle img {
		// 		-webkit-filter: none;
		// 		filter: none;
		// 	}

		// 	.users-total table tbody tr td .align-middle span {
		// 		margin-top: 10px;
		// 		display: inline-block;
		// 	}

		// 	.users-total table tbody tr:last-child td {
		// 		border-bottom: none !important;
		// 	}

		// 	.img-40 {
		// 		width: 40px !important;
		// 	}

		// 	.product-sales .table tr td .badge {
		// 		font-weight: 500;
		// 	}

		// 	.product-sales .table tr td+td {
		// 		font-family: Roboto, sans-serif;
		// 	}

		// 	.txt-success {
		// 		color: #51bb25 !important;
		// 	}

		// 	.txt-danger {
		// 		color: #fd2e64 !important;
		// 	}
		// `;

		// console.log('styles added');
		// main.appendChild(styling);

		// FUNCTIONALITY -----

		// WELCOME CARD SECTION -
		// getting all the trait values
		// example - https://swapi.dev/api/planets/3/
		let todaysApi = storedProps.Todays_API;
		let todaysApiUsername = storedProps.Todays_API_Username;
		let todaysApiPassword = storedProps.Todays_API_Password;
		let todaysApiApiKey = storedProps.Todays_API_API_Key;
		let todaysApiMoreHeaders = storedProps.Todays_API_More_Headers;
		let todaysAPIUpdateFrequencyP24H = storedProps.Todays_API_Update_Frequency_P24H;

		let dateResponseKeyname = storedProps.Date_Response_Keyname;
		let todaysSaleResponseKeyname = storedProps.Todays_Sale_Response_Keyname;
		let todaysEarningsResponseKeyname = storedProps.Todays_Earnings_Response_Keyname;

		console.log('Template storedProps (1):\n',storedProps, '\ncomponent:', component);

		// get the api response is api is provided;
		// this api has to be run at regular time intervals to get the update
		if (todaysApi !== '') {
			try {
				// if api url is provided and nothing else
				if (todaysApiUsername === "" && todaysApiPassword === "" && todaysApiApiKey === "") {
					console.log('<<<##');					

		            let config = {
		                headers: {},
		            };
		            
		            // ['a:b', "c:d"]
		            // extracting headers from todaysApiMoreHeaders trait, if provided
		            if (todaysApiMoreHeaders) {
		                todaysApiMoreHeaders.split(",").forEach((header) => {
		                    let key, val;
		                    [key, val] = header.split(":");
		                    console.log("[key, val]:\n", key, val);

		                  config.headers[key] = val;
		                });
		            }

		            console.log("config:", config, '\ntodaysApi:', todaysApi);

					todaysApiCall(todaysApi, config);

					// Schedule the API call to run 8 times in every 24 hours
				    // const intervalInMilliseconds = (24 * 60 * 60 * 1000) / 8; // 24 hours divided by 8
				    const intervalInMilliseconds = (24 * 60 * 60 * 1000) / todaysAPIUpdateFrequencyP24H; // 24 hours divided by 8
				    for (let i = 1; i < todaysAPIUpdateFrequencyP24H; i++) {
				        setTimeout(() => todaysApiCall(todaysApi, config), i * intervalInMilliseconds);
				    }
				}

				// if api url is provided, username & password is provided, but api key is not provided
				else if (todaysApiUsername !== "" && todaysApiPassword !== "" && todaysApiApiKey === "") {
					console.log('<<<##');

                    let config = {
                        headers: {
                        	Authorization: "Basic " + btoa(todaysApiUsername + ":" + todaysApiPassword),
                        },
                    };
                    
                    // ['a:b', "c:d"]
                    // extracting headers from todaysApiMoreHeaders trait, if provided
                    if (todaysApiMoreHeaders) {
                        todaysApiMoreHeaders.split(",").forEach((header) => {
	                        let key, val;
	                        [key, val] = header.split(":");
	                        console.log("[key, val]:\n", key, val);

                          config.headers[key] = val;
                        });
                    }

                    console.log("config:", config, '\ntodaysApi:', todaysApi);

					todaysApiCall(todaysApi, config);

					// Schedule the API call to run 8 times in every 24 hours
				    // const intervalInMilliseconds = (24 * 60 * 60 * 1000) / 8; // 24 hours divided by 8
				    const intervalInMilliseconds = (24 * 60 * 60 * 1000) / todaysAPIUpdateFrequencyP24H; // 24 hours divided by 8
				    for (let i = 1; i < todaysAPIUpdateFrequencyP24H; i++) {
				        setTimeout(() => todaysApiCall(todaysApi, config), i * intervalInMilliseconds);
				    }
				}

				// if api url is provided, username & password is not provided, but api key is provided
				else if (todaysApiUsername === "" && todaysApiPassword === "" && todaysApiApiKey !== "") {
					console.log('<<<##');

                    let config = {
                        headers: {
                        	"X-API-KEY": `${todaysApiApiKey}`,
                        },
                    };
                    
                    // ['a:b', "c:d"]
                    // extracting headers from todaysApiMoreHeaders trait, if provided
                    if (todaysApiMoreHeaders) {
                        todaysApiMoreHeaders.split(",").forEach((header) => {
	                        let key, val;
	                        [key, val] = header.split(":");
	                        console.log("[key, val]:\n", key, val);

                          config.headers[key] = val;
                        });
                    }

                    console.log("config:", config, '\ntodaysApi:', todaysApi);

					todaysApiCall(todaysApi, config);

					// Schedule the API call to run 8 times in every 24 hours
				    // const intervalInMilliseconds = (24 * 60 * 60 * 1000) / 8; // 24 hours divided by 8
				    const intervalInMilliseconds = (24 * 60 * 60 * 1000) / todaysAPIUpdateFrequencyP24H; // 24 hours divided by 8
				    for (let i = 1; i < todaysAPIUpdateFrequencyP24H; i++) {
				        setTimeout(() => todaysApiCall(todaysApi, config), i * intervalInMilliseconds);
				    }
				}

				// if api url is provided, username & password is provided, but api key is also provided
				else if (todaysApiUsername !== "" && todaysApiPassword !== "" && todaysApiApiKey !== "") {
					console.log('<<<##');

                    let config = {
                        headers: {
                        	Authorization: "Basic " + btoa(todaysApiUsername + ":" + todaysApiPassword),
                        	"X-API-KEY": `${todaysApiApiKey}`,
                        },
                    };
                    
                    // ['a:b', "c:d"]
                    // extracting headers from todaysApiMoreHeaders trait, if provided
                    if (todaysApiMoreHeaders) {
                        todaysApiMoreHeaders.split(",").forEach((header) => {
	                        let key, val;
	                        [key, val] = header.split(":");
	                        console.log("[key, val]:\n", key, val);

                          config.headers[key] = val;
                        });
                    }

                    console.log("config:", config, '\ntodaysApi:', todaysApi);

					todaysApiCall(todaysApi, config);

					// Schedule the API call to run 8 times in every 24 hours
				    // const intervalInMilliseconds = (24 * 60 * 60 * 1000) / 8; // 24 hours divided by 8
				    const intervalInMilliseconds = (24 * 60 * 60 * 1000) / todaysAPIUpdateFrequencyP24H; // 24 hours divided by 8
				    for (let i = 1; i < todaysAPIUpdateFrequencyP24H; i++) {
				        setTimeout(() => todaysApiCall(todaysApi, config), i * intervalInMilliseconds);
				    }
				}
			} catch(e) {
				console.log(e);
			}
		}


		// TOTAL-SALE-WIDGET SECTION -
		// getting all the trait values
		// example - https://swapi.dev/api/planets/3/
		let totalSaleLastYearAPI = storedProps.Total_Sale_Last_Year_API;
		let totalSaleLastYearAPIUsername = storedProps.Total_Sale_Last_Year_API_Username;
		let totalSaleLastYearAPIPassword = storedProps.Total_Sale_Last_Year_API_Password;
		let totalSaleLastYearAPIAPIKey = storedProps.Total_Sale_Last_Year_API_API_Key;
		let totalSaleLastYearAPIMoreHeaders = storedProps.Total_Sale_Last_Year_API_More_Headers;
		let totalSaleLastYearAPIUpdateFrequencyP24H = storedProps.Total_Sale_Last_Year_API_Update_Frequency_P24H;

		let totalSaleLastYearPercentage = storedProps.Total_Sale_Last_Year_Percentage;
		let totalSaleLastYearAmount = storedProps.Total_Sale_Last_Year_Amount;

		console.log('Template storedProps (2):\n',storedProps, '\ncomponent:', component);

		// get the api response is api is provided;
		// this api has to be run at regular time intervals to get the update
		if (totalSaleLastYearAPI !== '') {
			try {
				// if api url is provided and nothing else
				if (totalSaleLastYearAPIUsername === "" && totalSaleLastYearAPIPassword === "" && totalSaleLastYearAPIAPIKey === "") {
					console.log('<<<##');

                    let config = {
                        headers: {},
                    };
                    
                    // ['a:b', "c:d"]
                    // extracting headers from totalSaleLastYearAPIMoreHeaders trait, if provided
                    if (totalSaleLastYearAPIMoreHeaders) {
                        totalSaleLastYearAPIMoreHeaders.split(",").forEach((header) => {
	                        let key, val;
	                        [key, val] = header.split(":");
	                        console.log("[key, val]:\n", key, val);

                          config.headers[key] = val;
                        });
                    }

                    console.log("config:", config, '\ntotalSaleLastYearAPI:', totalSaleLastYearAPI);

					totalSaleLastYearAPICall(totalSaleLastYearAPI, config);

					// Schedule the API call to run 8 times in every 24 hours
				    // const intervalInMilliseconds = (24 * 60 * 60 * 1000) / 8; // 24 hours divided by 8
				    const intervalInMilliseconds = (24 * 60 * 60 * 1000) / totalSaleLastYearAPIUpdateFrequencyP24H; // 24 hours divided by 8
				    for (let i = 1; i < totalSaleLastYearAPIUpdateFrequencyP24H; i++) {
				        setTimeout(() => totalSaleLastYearAPICall(totalSaleLastYearAPI, config), i * intervalInMilliseconds);
				    }
				}

				// if api url is provided, username & password is provided, but api key is not provided
				else if (totalSaleLastYearAPIUsername !== "" && totalSaleLastYearAPIPassword !== "" && totalSaleLastYearAPIAPIKey === "") {
					console.log('<<<##');

                    let config = {
                        headers: {
                        	Authorization: "Basic " + btoa(totalSaleLastYearAPIUsername + ":" + totalSaleLastYearAPIPassword),
                        },
                    };
                    
                    // ['a:b', "c:d"]
                    // extracting headers from totalSaleLastYearAPIMoreHeaders trait, if provided
                    if (totalSaleLastYearAPIMoreHeaders) {
                        totalSaleLastYearAPIMoreHeaders.split(",").forEach((header) => {
	                        let key, val;
	                        [key, val] = header.split(":");
	                        console.log("[key, val]:\n", key, val);

                          config.headers[key] = val;
                        });
                    }

                    console.log("config:", config, '\ntotalSaleLastYearAPI:', totalSaleLastYearAPI);

					totalSaleLastYearAPICall(totalSaleLastYearAPI, config);

					// Schedule the API call to run 8 times in every 24 hours
				    // const intervalInMilliseconds = (24 * 60 * 60 * 1000) / 8; // 24 hours divided by 8
				    const intervalInMilliseconds = (24 * 60 * 60 * 1000) / totalSaleLastYearAPIUpdateFrequencyP24H; // 24 hours divided by 8
				    for (let i = 1; i < totalSaleLastYearAPIUpdateFrequencyP24H; i++) {
				        setTimeout(() => totalSaleLastYearAPICall(totalSaleLastYearAPI, config), i * intervalInMilliseconds);
				    }
				}

				// if api url is provided, username & password is not provided, but api key is provided
				else if (totalSaleLastYearAPIUsername === "" && totalSaleLastYearAPIPassword === "" && totalSaleLastYearAPIAPIKey !== "") {
					console.log('<<<##');

                    let config = {
                        headers: {
                        	"X-API-KEY": `${totalSaleLastYearAPIAPIKey}`,
                        },
                    };
                    
                    // ['a:b', "c:d"]
                    // extracting headers from totalSaleLastYearAPIMoreHeaders trait, if provided
                    if (totalSaleLastYearAPIMoreHeaders) {
                        totalSaleLastYearAPIMoreHeaders.split(",").forEach((header) => {
	                        let key, val;
	                        [key, val] = header.split(":");
	                        console.log("[key, val]:\n", key, val);

                          config.headers[key] = val;
                        });
                    }

                    console.log("config:", config, '\ntotalSaleLastYearAPI:', totalSaleLastYearAPI);

					totalSaleLastYearAPICall(totalSaleLastYearAPI, config);

					// Schedule the API call to run 8 times in every 24 hours
				    // const intervalInMilliseconds = (24 * 60 * 60 * 1000) / 8; // 24 hours divided by 8
				    const intervalInMilliseconds = (24 * 60 * 60 * 1000) / totalSaleLastYearAPIUpdateFrequencyP24H; // 24 hours divided by 8
				    for (let i = 1; i < totalSaleLastYearAPIUpdateFrequencyP24H; i++) {
				        setTimeout(() => totalSaleLastYearAPICall(totalSaleLastYearAPI, config), i * intervalInMilliseconds);
				    }
				}

				// if api url is provided, username & password is provided, but api key is also provided
				else if (totalSaleLastYearAPIUsername !== "" && totalSaleLastYearAPIPassword !== "" && totalSaleLastYearAPIAPIKey !== "") {
					console.log('<<<##');

                    let config = {
                        headers: {
                        	Authorization: "Basic " + btoa(totalSaleLastYearAPIUsername + ":" + totalSaleLastYearAPIPassword),
                        	"X-API-KEY": `${totalSaleLastYearAPIAPIKey}`,
                        },
                    };
                    
                    // ['a:b', "c:d"]
                    // extracting headers from totalSaleLastYearAPIMoreHeaders trait, if provided
                    if (totalSaleLastYearAPIMoreHeaders) {
                        totalSaleLastYearAPIMoreHeaders.split(",").forEach((header) => {
	                        let key, val;
	                        [key, val] = header.split(":");
	                        console.log("[key, val]:\n", key, val);

                          config.headers[key] = val;
                        });
                    }

                    console.log("config:", config, '\ntotalSaleLastYearAPI:', totalSaleLastYearAPI);

					totalSaleLastYearAPICall(totalSaleLastYearAPI, config);

					// Schedule the API call to run 8 times in every 24 hours
				    // const intervalInMilliseconds = (24 * 60 * 60 * 1000) / 8; // 24 hours divided by 8
				    const intervalInMilliseconds = (24 * 60 * 60 * 1000) / totalSaleLastYearAPIUpdateFrequencyP24H; // 24 hours divided by 8
				    for (let i = 1; i < totalSaleLastYearAPIUpdateFrequencyP24H; i++) {
				        setTimeout(() => totalSaleLastYearAPICall(totalSaleLastYearAPI, config), i * intervalInMilliseconds);
				    }
				}
			} catch(e) {
				console.log(e);
			}
		}

		// getting all the trait values
		// example - https://swapi.dev/api/planets/3/
		let activeCustomerLastDayAPI = storedProps.Active_Customer_Last_Day_API;
		let activeCustomerLastDayAPIUsername = storedProps.Active_Customer_Last_Day_API_Username;
		let activeCustomerLastDayAPIPassword = storedProps.Active_Customer_Last_Day_API_Password;
		let activeCustomerLastDayAPIAPIKey = storedProps.Active_Customer_Last_Day_API_API_Key;
		let activeCustomerLastDayAPIMoreHeaders = storedProps.Active_Customer_Last_Day_API_More_Headers;
		let activeCustomerLastDayAPIUpdateFrequencyP24H = storedProps.Active_Customer_Last_Day_API_Update_Frequency_P24H;

		let activeCustomerLastDayPercentage = storedProps.Active_Customer_Last_Day_Percentage;
		let activeCustomerLastDayAmount = storedProps.Active_Customer_Last_Day_Amount;

		console.log('Template storedProps (3):\n',storedProps, '\ncomponent:', component);

		// get the api response is api is provided;
		// this api has to be run at regular time intervals to get the update
		if (activeCustomerLastDayAPI !== '') {
			try {
				// if api url is provided and nothing else
				if (activeCustomerLastDayAPIUsername === "" && activeCustomerLastDayAPIPassword === "" && activeCustomerLastDayAPIAPIKey === "") {
					console.log('<<<##');

                    let config = {
                        headers: {},
                    };
                    
                    // ['a:b', "c:d"]
                    // extracting headers from activeCustomerLastDayAPIMoreHeaders trait, if provided
                    if (activeCustomerLastDayAPIMoreHeaders) {
                        activeCustomerLastDayAPIMoreHeaders.split(",").forEach((header) => {
	                        let key, val;
	                        [key, val] = header.split(":");
	                        console.log("[key, val]:\n", key, val);

                          config.headers[key] = val;
                        });
                    }

                    console.log("config:", config, '\nactiveCustomerLastDayAPI:', activeCustomerLastDayAPI);

					activeCustomerLastDayAPICall(activeCustomerLastDayAPI, config);

					// Schedule the API call to run 8 times in every 24 hours
				    // const intervalInMilliseconds = (24 * 60 * 60 * 1000) / 8; // 24 hours divided by 8
				    const intervalInMilliseconds = (24 * 60 * 60 * 1000) / activeCustomerLastDayAPIUpdateFrequencyP24H; // 24 hours divided by 8
				    for (let i = 1; i < activeCustomerLastDayAPIUpdateFrequencyP24H; i++) {
				        setTimeout(() => activeCustomerLastDayAPICall(activeCustomerLastDayAPI, config), i * intervalInMilliseconds);
				    }
				}

				// if api url is provided, username & password is provided, but api key is not provided
				else if (activeCustomerLastDayAPIUsername !== "" && activeCustomerLastDayAPIPassword !== "" && activeCustomerLastDayAPIAPIKey === "") {
					console.log('<<<##');

                    let config = {
                        headers: {
                        	Authorization: "Basic " + btoa(activeCustomerLastDayAPIUsername + ":" + activeCustomerLastDayAPIPassword),
                        },
                    };
                    
                    // ['a:b', "c:d"]
                    // extracting headers from activeCustomerLastDayAPIMoreHeaders trait, if provided
                    if (activeCustomerLastDayAPIMoreHeaders) {
                        activeCustomerLastDayAPIMoreHeaders.split(",").forEach((header) => {
	                        let key, val;
	                        [key, val] = header.split(":");
	                        console.log("[key, val]:\n", key, val);

                          config.headers[key] = val;
                        });
                    }

                    console.log("config:", config, '\nactiveCustomerLastDayAPI:', activeCustomerLastDayAPI);

					activeCustomerLastDayAPICall(activeCustomerLastDayAPI, config);

					// Schedule the API call to run 8 times in every 24 hours
				    // const intervalInMilliseconds = (24 * 60 * 60 * 1000) / 8; // 24 hours divided by 8
				    const intervalInMilliseconds = (24 * 60 * 60 * 1000) / activeCustomerLastDayAPIUpdateFrequencyP24H; // 24 hours divided by 8
				    for (let i = 1; i < activeCustomerLastDayAPIUpdateFrequencyP24H; i++) {
				        setTimeout(() => activeCustomerLastDayAPICall(activeCustomerLastDayAPI, config), i * intervalInMilliseconds);
				    }
				}

				// if api url is provided, username & password is not provided, but api key is provided
				else if (activeCustomerLastDayAPIUsername === "" && activeCustomerLastDayAPIPassword === "" && activeCustomerLastDayAPIAPIKey !== "") {
					console.log('<<<##');

                    let config = {
                        headers: {
                        	"X-API-KEY": `${activeCustomerLastDayAPIAPIKey}`,
                        },
                    };
                    
                    // ['a:b', "c:d"]
                    // extracting headers from activeCustomerLastDayAPIMoreHeaders trait, if provided
                    if (activeCustomerLastDayAPIMoreHeaders) {
                        activeCustomerLastDayAPIMoreHeaders.split(",").forEach((header) => {
	                        let key, val;
	                        [key, val] = header.split(":");
	                        console.log("[key, val]:\n", key, val);

                          config.headers[key] = val;
                        });
                    }

                    console.log("config:", config, '\nactiveCustomerLastDayAPI:', activeCustomerLastDayAPI);

					activeCustomerLastDayAPICall(activeCustomerLastDayAPI, config);

					// Schedule the API call to run 8 times in every 24 hours
				    // const intervalInMilliseconds = (24 * 60 * 60 * 1000) / 8; // 24 hours divided by 8
				    const intervalInMilliseconds = (24 * 60 * 60 * 1000) / activeCustomerLastDayAPIUpdateFrequencyP24H; // 24 hours divided by 8
				    for (let i = 1; i < activeCustomerLastDayAPIUpdateFrequencyP24H; i++) {
				        setTimeout(() => activeCustomerLastDayAPICall(activeCustomerLastDayAPI, config), i * intervalInMilliseconds);
				    }
				}

				// if api url is provided, username & password is provided, but api key is also provided
				else if (activeCustomerLastDayAPIUsername !== "" && activeCustomerLastDayAPIPassword !== "" && activeCustomerLastDayAPIAPIKey !== "") {
					console.log('<<<##');

                    let config = {
                        headers: {
                        	Authorization: "Basic " + btoa(activeCustomerLastDayAPIUsername + ":" + activeCustomerLastDayAPIPassword),
                        	"X-API-KEY": `${activeCustomerLastDayAPIAPIKey}`,
                        },
                    };
                    
                    // ['a:b', "c:d"]
                    // extracting headers from activeCustomerLastDayAPIMoreHeaders trait, if provided
                    if (activeCustomerLastDayAPIMoreHeaders) {
                        activeCustomerLastDayAPIMoreHeaders.split(",").forEach((header) => {
	                        let key, val;
	                        [key, val] = header.split(":");
	                        console.log("[key, val]:\n", key, val);

                          config.headers[key] = val;
                        });
                    }

                    console.log("config:", config, '\nactiveCustomerLastDayAPI:', activeCustomerLastDayAPI);

					activeCustomerLastDayAPICall(activeCustomerLastDayAPI, config);

					// Schedule the API call to run 8 times in every 24 hours
				    // const intervalInMilliseconds = (24 * 60 * 60 * 1000) / 8; // 24 hours divided by 8
				    const intervalInMilliseconds = (24 * 60 * 60 * 1000) / activeCustomerLastDayAPIUpdateFrequencyP24H; // 24 hours divided by 8
				    for (let i = 1; i < activeCustomerLastDayAPIUpdateFrequencyP24H; i++) {
				        setTimeout(() => activeCustomerLastDayAPICall(activeCustomerLastDayAPI, config), i * intervalInMilliseconds);
				    }
				}
			} catch(e) {
				console.log(e);
			}
		}


		// SUPPORT-TICKET-FONT SECTION -
		// getting all the trait values
		// example - https://swapi.dev/api/planets/3/
		let totalSalesAPI = storedProps.Total_Sales_API;
		let totalSalesAPIUsername = storedProps.Total_Sales_API_Username;
		let totalSalesAPIPassword = storedProps.Total_Sales_API_Password;
		let totalSalesAPIAPIKey = storedProps.Total_Sales_API_API_Key;
		let totalSalesAPIMoreHeaders = storedProps.Total_Sales_API_More_Headers;
		let totalSalesAPIUpdateFrequencyP24H = storedProps.Total_Sales_API_Update_Frequency_P24H;

		let totalSales = storedProps.Total_Sales;
		let salesGoalReachedPercentage = storedProps.Sales_Goal_Reached_Percentage;
		let salesGoalReachedCount = storedProps.Sales_Goal_Reached_Count;

		console.log('Template storedProps (4):\n',storedProps, '\ncomponent:', component);

		// get the api response is api is provided;
		// this api has to be run at regular time intervals to get the update
		if (totalSalesAPI !== '') {
			try {
				// if api url is provided and nothing else
				if (totalSalesAPIUsername === "" && totalSalesAPIPassword === "" && totalSalesAPIAPIKey === "") {
					console.log('<<<##');

                    let config = {
                        headers: {},
                    };
                    
                    // ['a:b', "c:d"]
                    // extracting headers from totalSalesAPIMoreHeaders trait, if provided
                    if (totalSalesAPIMoreHeaders) {
                        totalSalesAPIMoreHeaders.split(",").forEach((header) => {
	                        let key, val;
	                        [key, val] = header.split(":");
	                        console.log("[key, val]:\n", key, val);

                          config.headers[key] = val;
                        });
                    }

                    console.log("config:", config, '\ntotalSalesAPI:', totalSalesAPI);

					totalSalesAPICall(totalSalesAPI, config);

					// Schedule the API call to run 8 times in every 24 hours
				    // const intervalInMilliseconds = (24 * 60 * 60 * 1000) / 8; // 24 hours divided by 8
				    const intervalInMilliseconds = (24 * 60 * 60 * 1000) / totalSalesAPIUpdateFrequencyP24H; // 24 hours divided by 8
				    for (let i = 1; i < totalSalesAPIUpdateFrequencyP24H; i++) {
				        setTimeout(() => totalSalesAPICall(totalSalesAPI, config), i * intervalInMilliseconds);
				    }
				}

				// if api url is provided, username & password is provided, but api key is not provided
				else if (totalSalesAPIUsername !== "" && totalSalesAPIPassword !== "" && totalSalesAPIAPIKey === "") {
					console.log('<<<##');

                    let config = {
                        headers: {
                        	Authorization: "Basic " + btoa(totalSalesAPIUsername + ":" + totalSalesAPIPassword),
                        },
                    };
                    
                    // ['a:b', "c:d"]
                    // extracting headers from totalSalesAPIMoreHeaders trait, if provided
                    if (totalSalesAPIMoreHeaders) {
                        totalSalesAPIMoreHeaders.split(",").forEach((header) => {
	                        let key, val;
	                        [key, val] = header.split(":");
	                        console.log("[key, val]:\n", key, val);

                          config.headers[key] = val;
                        });
                    }

                    console.log("config:", config, '\ntotalSalesAPI:', totalSalesAPI);

					totalSalesAPICall(totalSalesAPI, config);

					// Schedule the API call to run 8 times in every 24 hours
				    // const intervalInMilliseconds = (24 * 60 * 60 * 1000) / 8; // 24 hours divided by 8
				    const intervalInMilliseconds = (24 * 60 * 60 * 1000) / totalSalesAPIUpdateFrequencyP24H; // 24 hours divided by 8
				    for (let i = 1; i < totalSalesAPIUpdateFrequencyP24H; i++) {
				        setTimeout(() => totalSalesAPICall(totalSalesAPI, config), i * intervalInMilliseconds);
				    }
				}

				// if api url is provided, username & password is not provided, but api key is provided
				else if (totalSalesAPIUsername === "" && totalSalesAPIPassword === "" && totalSalesAPIAPIKey !== "") {
					console.log('<<<##');

                    let config = {
                        headers: {
                        	"X-API-KEY": `${totalSalesAPIAPIKey}`,
                        },
                    };
                    
                    // ['a:b', "c:d"]
                    // extracting headers from totalSalesAPIMoreHeaders trait, if provided
                    if (totalSalesAPIMoreHeaders) {
                        totalSalesAPIMoreHeaders.split(",").forEach((header) => {
	                        let key, val;
	                        [key, val] = header.split(":");
	                        console.log("[key, val]:\n", key, val);

                          config.headers[key] = val;
                        });
                    }

                    console.log("config:", config, '\ntotalSalesAPI:', totalSalesAPI);

					totalSalesAPICall(totalSalesAPI, config);

					// Schedule the API call to run 8 times in every 24 hours
				    // const intervalInMilliseconds = (24 * 60 * 60 * 1000) / 8; // 24 hours divided by 8
				    const intervalInMilliseconds = (24 * 60 * 60 * 1000) / totalSalesAPIUpdateFrequencyP24H; // 24 hours divided by 8
				    for (let i = 1; i < totalSalesAPIUpdateFrequencyP24H; i++) {
				        setTimeout(() => totalSalesAPICall(totalSalesAPI, config), i * intervalInMilliseconds);
				    }
				}

				// if api url is provided, username & password is provided, but api key is also provided
				else if (totalSalesAPIUsername !== "" && totalSalesAPIPassword !== "" && totalSalesAPIAPIKey !== "") {
					console.log('<<<##');

                    let config = {
                        headers: {
                        	Authorization: "Basic " + btoa(totalSalesAPIUsername + ":" + totalSalesAPIPassword),
                        	"X-API-KEY": `${totalSalesAPIAPIKey}`,
                        },
                    };
                    
                    // ['a:b', "c:d"]
                    // extracting headers from totalSalesAPIMoreHeaders trait, if provided
                    if (totalSalesAPIMoreHeaders) {
                        totalSalesAPIMoreHeaders.split(",").forEach((header) => {
	                        let key, val;
	                        [key, val] = header.split(":");
	                        console.log("[key, val]:\n", key, val);

                          config.headers[key] = val;
                        });
                    }

                    console.log("config:", config, '\ntotalSalesAPI:', totalSalesAPI);

					totalSalesAPICall(totalSalesAPI, config);

					// Schedule the API call to run 8 times in every 24 hours
				    // const intervalInMilliseconds = (24 * 60 * 60 * 1000) / 8; // 24 hours divided by 8
				    const intervalInMilliseconds = (24 * 60 * 60 * 1000) / totalSalesAPIUpdateFrequencyP24H; // 24 hours divided by 8
				    for (let i = 1; i < totalSalesAPIUpdateFrequencyP24H; i++) {
				        setTimeout(() => totalSalesAPICall(totalSalesAPI, config), i * intervalInMilliseconds);
				    }
				}
			} catch(e) {
				console.log(e);
			}
		}

		// getting all the trait values
		// example - https://swapi.dev/api/planets/3/
		let totalIncomeAPI = storedProps.Total_Income_API;
		let totalIncomeAPIUsername = storedProps.Total_Income_API_Username;
		let totalIncomeAPIPassword = storedProps.Total_Income_API_Password;
		let totalIncomeAPIAPIKey = storedProps.Total_Income_API_API_Key;
		let totalIncomeAPIMoreHeaders = storedProps.Total_Income_API_More_Headers;
		let totalIncomeAPIUpdateFrequencyP24H = storedProps.Total_Income_API_Update_Frequency_P24H;

		let totalIncome = storedProps.Total_Income;
		let incomeGoalReachedPercentage = storedProps.Income_Goal_Reached_Percentage;
		let incomeGoalReachedCount = storedProps.Income_Goal_Reached_Count;

		console.log('Template storedProps (5):\n',storedProps, '\ncomponent:', component);

		// get the api response is api is provided;
		// this api has to be run at regular time intervals to get the update
		if (totalIncomeAPI !== '') {
			try {
				// if api url is provided and nothing else
				if (totalIncomeAPIUsername === "" && totalIncomeAPIPassword === "" && totalIncomeAPIAPIKey === "") {
					console.log('<<<##');

                    let config = {
                        headers: {},
                    };
                    
                    // ['a:b', "c:d"]
                    // extracting headers from totalIncomeAPIMoreHeaders trait, if provided
                    if (totalIncomeAPIMoreHeaders) {
                        totalIncomeAPIMoreHeaders.split(",").forEach((header) => {
	                        let key, val;
	                        [key, val] = header.split(":");
	                        console.log("[key, val]:\n", key, val);

                          config.headers[key] = val;
                        });
                    }

                    console.log("config:", config, '\ntotalIncomeAPI:', totalIncomeAPI);

					totalIncomeAPICall(totalIncomeAPI, config);

					// Schedule the API call to run 8 times in every 24 hours
				    // const intervalInMilliseconds = (24 * 60 * 60 * 1000) / 8; // 24 hours divided by 8
				    const intervalInMilliseconds = (24 * 60 * 60 * 1000) / totalIncomeAPIUpdateFrequencyP24H; // 24 hours divided by 8
				    for (let i = 1; i < totalIncomeAPIUpdateFrequencyP24H; i++) {
				        setTimeout(() => totalIncomeAPICall(totalIncomeAPI, config), i * intervalInMilliseconds);
				    }
				}

				// if api url is provided, username & password is provided, but api key is not provided
				else if (totalIncomeAPIUsername !== "" && totalIncomeAPIPassword !== "" && totalIncomeAPIAPIKey === "") {
					console.log('<<<##');

                    let config = {
                        headers: {
                        	Authorization: "Basic " + btoa(totalIncomeAPIUsername + ":" + totalIncomeAPIPassword),
                        },
                    };
                    
                    // ['a:b', "c:d"]
                    // extracting headers from totalIncomeAPIMoreHeaders trait, if provided
                    if (totalIncomeAPIMoreHeaders) {
                        totalIncomeAPIMoreHeaders.split(",").forEach((header) => {
	                        let key, val;
	                        [key, val] = header.split(":");
	                        console.log("[key, val]:\n", key, val);

                          config.headers[key] = val;
                        });
                    }

                    console.log("config:", config, '\ntotalIncomeAPI:', totalIncomeAPI);

					totalIncomeAPICall(totalIncomeAPI, config);

					// Schedule the API call to run 8 times in every 24 hours
				    // const intervalInMilliseconds = (24 * 60 * 60 * 1000) / 8; // 24 hours divided by 8
				    const intervalInMilliseconds = (24 * 60 * 60 * 1000) / totalIncomeAPIUpdateFrequencyP24H; // 24 hours divided by 8
				    for (let i = 1; i < totalIncomeAPIUpdateFrequencyP24H; i++) {
				        setTimeout(() => totalIncomeAPICall(totalIncomeAPI, config), i * intervalInMilliseconds);
				    }
				}

				// if api url is provided, username & password is not provided, but api key is provided
				else if (totalIncomeAPIUsername === "" && totalIncomeAPIPassword === "" && totalIncomeAPIAPIKey !== "") {
					console.log('<<<##');

                    let config = {
                        headers: {
                        	"X-API-KEY": `${totalIncomeAPIAPIKey}`,
                        },
                    };
                    
                    // ['a:b', "c:d"]
                    // extracting headers from totalIncomeAPIMoreHeaders trait, if provided
                    if (totalIncomeAPIMoreHeaders) {
                        totalIncomeAPIMoreHeaders.split(",").forEach((header) => {
	                        let key, val;
	                        [key, val] = header.split(":");
	                        console.log("[key, val]:\n", key, val);

                          config.headers[key] = val;
                        });
                    }

                    console.log("config:", config, '\ntotalIncomeAPI:', totalIncomeAPI);

					totalIncomeAPICall(totalIncomeAPI, config);

					// Schedule the API call to run 8 times in every 24 hours
				    // const intervalInMilliseconds = (24 * 60 * 60 * 1000) / 8; // 24 hours divided by 8
				    const intervalInMilliseconds = (24 * 60 * 60 * 1000) / totalIncomeAPIUpdateFrequencyP24H; // 24 hours divided by 8
				    for (let i = 1; i < totalIncomeAPIUpdateFrequencyP24H; i++) {
				        setTimeout(() => totalIncomeAPICall(totalIncomeAPI, config), i * intervalInMilliseconds);
				    }
				}

				// if api url is provided, username & password is provided, but api key is also provided
				else if (totalIncomeAPIUsername !== "" && totalIncomeAPIPassword !== "" && totalIncomeAPIAPIKey !== "") {
					console.log('<<<##');

                    let config = {
                        headers: {
                        	Authorization: "Basic " + btoa(totalIncomeAPIUsername + ":" + totalIncomeAPIPassword),
                        	"X-API-KEY": `${totalIncomeAPIAPIKey}`,
                        },
                    };
                    
                    // ['a:b', "c:d"]
                    // extracting headers from totalIncomeAPIMoreHeaders trait, if provided
                    if (totalIncomeAPIMoreHeaders) {
                        totalIncomeAPIMoreHeaders.split(",").forEach((header) => {
	                        let key, val;
	                        [key, val] = header.split(":");
	                        console.log("[key, val]:\n", key, val);

                          config.headers[key] = val;
                        });
                    }

                    console.log("config:", config, '\ntotalIncomeAPI:', totalIncomeAPI);

					totalIncomeAPICall(totalIncomeAPI, config);

					// Schedule the API call to run 8 times in every 24 hours
				    // const intervalInMilliseconds = (24 * 60 * 60 * 1000) / 8; // 24 hours divided by 8
				    const intervalInMilliseconds = (24 * 60 * 60 * 1000) / totalIncomeAPIUpdateFrequencyP24H; // 24 hours divided by 8
				    for (let i = 1; i < totalIncomeAPIUpdateFrequencyP24H; i++) {
				        setTimeout(() => totalIncomeAPICall(totalIncomeAPI, config), i * intervalInMilliseconds);
				    }
				}
			} catch(e) {
				console.log(e);
			}
		}

		// getting all the trait values
		// example - https://swapi.dev/api/planets/3/
		let activeUserAPI = storedProps.Active_User_API;
		let activeUserAPIUsername = storedProps.Active_User_API_Username;
		let activeUserAPIPassword = storedProps.Active_User_API_Password;
		let activeUserAPIAPIKey = storedProps.Active_User_API_API_Key;
		let activeUserAPIMoreHeaders = storedProps.Active_User_API_More_Headers;
		let activeUserAPIUpdateFrequencyP24H = storedProps.Active_User_API_Update_Frequency_P24H;

		let activeUser = storedProps.Active_User;
		let activeUserGoalReachedPercentage = storedProps.Active_User_Goal_Reached_Percentage;
		let activeUserGoalReachedCount = storedProps.Active_User_Goal_Reached_Count;

		console.log('Template storedProps (6):\n',storedProps, '\ncomponent:', component);

		// get the api response is api is provided;
		// this api has to be run at regular time intervals to get the update
		if (activeUserAPI !== '') {
			try {
				// if api url is provided and nothing else
				if (activeUserAPIUsername === "" && activeUserAPIPassword === "" && activeUserAPIAPIKey === "") {
					console.log('<<<##');

                    let config = {
                        headers: {},
                    };
                    
                    // ['a:b', "c:d"]
                    // extracting headers from activeUserAPIMoreHeaders trait, if provided
                    if (activeUserAPIMoreHeaders) {
                        activeUserAPIMoreHeaders.split(",").forEach((header) => {
	                        let key, val;
	                        [key, val] = header.split(":");
	                        console.log("[key, val]:\n", key, val);

                          config.headers[key] = val;
                        });
                    }

                    console.log("config:", config, '\nactiveUserAPI:', activeUserAPI);

					activeUserAPICall(activeUserAPI, config);

					// Schedule the API call to run 8 times in every 24 hours
				    // const intervalInMilliseconds = (24 * 60 * 60 * 1000) / 8; // 24 hours divided by 8
				    const intervalInMilliseconds = (24 * 60 * 60 * 1000) / activeUserAPIUpdateFrequencyP24H; // 24 hours divided by 8
				    for (let i = 1; i < activeUserAPIUpdateFrequencyP24H; i++) {
				        setTimeout(() => activeUserAPICall(activeUserAPI, config), i * intervalInMilliseconds);
				    }
				}

				// if api url is provided, username & password is provided, but api key is not provided
				else if (activeUserAPIUsername !== "" && activeUserAPIPassword !== "" && activeUserAPIAPIKey === "") {
					console.log('<<<##');

                    let config = {
                        headers: {
                        	Authorization: "Basic " + btoa(activeUserAPIUsername + ":" + activeUserAPIPassword),
                        },
                    };
                    
                    // ['a:b', "c:d"]
                    // extracting headers from activeUserAPIMoreHeaders trait, if provided
                    if (activeUserAPIMoreHeaders) {
                        activeUserAPIMoreHeaders.split(",").forEach((header) => {
	                        let key, val;
	                        [key, val] = header.split(":");
	                        console.log("[key, val]:\n", key, val);

                          config.headers[key] = val;
                        });
                    }

                    console.log("config:", config, '\nactiveUserAPI:', activeUserAPI);

					activeUserAPICall(activeUserAPI, config);

					// Schedule the API call to run 8 times in every 24 hours
				    // const intervalInMilliseconds = (24 * 60 * 60 * 1000) / 8; // 24 hours divided by 8
				    const intervalInMilliseconds = (24 * 60 * 60 * 1000) / activeUserAPIUpdateFrequencyP24H; // 24 hours divided by 8
				    for (let i = 1; i < activeUserAPIUpdateFrequencyP24H; i++) {
				        setTimeout(() => activeUserAPICall(activeUserAPI, config), i * intervalInMilliseconds);
				    }
				}

				// if api url is provided, username & password is not provided, but api key is provided
				else if (activeUserAPIUsername === "" && activeUserAPIPassword === "" && activeUserAPIAPIKey !== "") {
					console.log('<<<##');

                    let config = {
                        headers: {
                        	"X-API-KEY": `${activeUserAPIAPIKey}`,
                        },
                    };
                    
                    // ['a:b', "c:d"]
                    // extracting headers from activeUserAPIMoreHeaders trait, if provided
                    if (activeUserAPIMoreHeaders) {
                        activeUserAPIMoreHeaders.split(",").forEach((header) => {
	                        let key, val;
	                        [key, val] = header.split(":");
	                        console.log("[key, val]:\n", key, val);

                          config.headers[key] = val;
                        });
                    }

                    console.log("config:", config, '\nactiveUserAPI:', activeUserAPI);

					activeUserAPICall(activeUserAPI, config);

					// Schedule the API call to run 8 times in every 24 hours
				    // const intervalInMilliseconds = (24 * 60 * 60 * 1000) / 8; // 24 hours divided by 8
				    const intervalInMilliseconds = (24 * 60 * 60 * 1000) / activeUserAPIUpdateFrequencyP24H; // 24 hours divided by 8
				    for (let i = 1; i < activeUserAPIUpdateFrequencyP24H; i++) {
				        setTimeout(() => activeUserAPICall(activeUserAPI, config), i * intervalInMilliseconds);
				    }
				}

				// if api url is provided, username & password is provided, but api key is also provided
				else if (activeUserAPIUsername !== "" && activeUserAPIPassword !== "" && activeUserAPIAPIKey !== "") {
					console.log('<<<##');

                    let config = {
                        headers: {
                        	Authorization: "Basic " + btoa(activeUserAPIUsername + ":" + activeUserAPIPassword),
                        	"X-API-KEY": `${activeUserAPIAPIKey}`,
                        },
                    };
                    
                    // ['a:b', "c:d"]
                    // extracting headers from activeUserAPIMoreHeaders trait, if provided
                    if (activeUserAPIMoreHeaders) {
                        activeUserAPIMoreHeaders.split(",").forEach((header) => {
	                        let key, val;
	                        [key, val] = header.split(":");
	                        console.log("[key, val]:\n", key, val);

                          config.headers[key] = val;
                        });
                    }

                    console.log("config:", config, '\nactiveUserAPI:', activeUserAPI);

					activeUserAPICall(activeUserAPI, config);

					// Schedule the API call to run 8 times in every 24 hours
				    // const intervalInMilliseconds = (24 * 60 * 60 * 1000) / 8; // 24 hours divided by 8
				    const intervalInMilliseconds = (24 * 60 * 60 * 1000) / activeUserAPIUpdateFrequencyP24H; // 24 hours divided by 8
				    for (let i = 1; i < activeUserAPIUpdateFrequencyP24H; i++) {
				        setTimeout(() => activeUserAPICall(activeUserAPI, config), i * intervalInMilliseconds);
				    }
				}
			} catch(e) {
				console.log(e);
			}
		}

		// getting all the trait values
		// example - https://swapi.dev/api/planets/3/
		let totalTaxAPI = storedProps.Total_Tax_API;
		let totalTaxAPIUsername = storedProps.Total_Tax_API_Username;
		let totalTaxAPIPassword = storedProps.Total_Tax_API_Password;
		let totalTaxAPIAPIKey = storedProps.Total_Tax_API_API_Key;
		let totalTaxAPIMoreHeaders = storedProps.Total_Tax_API_More_Headers;
		let totalTaxAPIUpdateFrequencyP24H = storedProps.Total_Tax_API_Update_Frequency_P24H;

		let totalTax = storedProps.Total_Tax;
		let taxGoalReachedPercentage = storedProps.Tax_Goal_Reached_Percentage;
		let taxGoalReachedCount = storedProps.Tax_Goal_Reached_Count;

		console.log('Template storedProps (7):\n',storedProps, '\ncomponent:', component);

		// get the api response is api is provided;
		// this api has to be run at regular time intervals to get the update
		if (totalTaxAPI !== '') {
			try {
				// if api url is provided and nothing else
				if (totalTaxAPIUsername === "" && totalTaxAPIPassword === "" && totalTaxAPIAPIKey === "") {
					console.log('<<<##');

                    let config = {
                        headers: {},
                    };
                    
                    // ['a:b', "c:d"]
                    // extracting headers from totalTaxAPIMoreHeaders trait, if provided
                    if (totalTaxAPIMoreHeaders) {
                        totalTaxAPIMoreHeaders.split(",").forEach((header) => {
	                        let key, val;
	                        [key, val] = header.split(":");
	                        console.log("[key, val]:\n", key, val);

                          config.headers[key] = val;
                        });
                    }

                    console.log("config:", config, '\ntotalTaxAPI:', totalTaxAPI);

					totalTaxAPICall(totalTaxAPI, config);

					// Schedule the API call to run 8 times in every 24 hours
				    // const intervalInMilliseconds = (24 * 60 * 60 * 1000) / 8; // 24 hours divided by 8
				    const intervalInMilliseconds = (24 * 60 * 60 * 1000) / totalTaxAPIUpdateFrequencyP24H; // 24 hours divided by 8
				    for (let i = 1; i < totalTaxAPIUpdateFrequencyP24H; i++) {
				        setTimeout(() => totalTaxAPICall(totalTaxAPI, config), i * intervalInMilliseconds);
				    }
				}

				// if api url is provided, username & password is provided, but api key is not provided
				else if (totalTaxAPIUsername !== "" && totalTaxAPIPassword !== "" && totalTaxAPIAPIKey === "") {
					console.log('<<<##');

                    let config = {
                        headers: {
                        	Authorization: "Basic " + btoa(totalTaxAPIUsername + ":" + totalTaxAPIPassword),
                        },
                    };
                    
                    // ['a:b', "c:d"]
                    // extracting headers from totalTaxAPIMoreHeaders trait, if provided
                    if (totalTaxAPIMoreHeaders) {
                        totalTaxAPIMoreHeaders.split(",").forEach((header) => {
	                        let key, val;
	                        [key, val] = header.split(":");
	                        console.log("[key, val]:\n", key, val);

                          config.headers[key] = val;
                        });
                    }

                    console.log("config:", config, '\ntotalTaxAPI:', totalTaxAPI);

					totalTaxAPICall(totalTaxAPI, config);

					// Schedule the API call to run 8 times in every 24 hours
				    // const intervalInMilliseconds = (24 * 60 * 60 * 1000) / 8; // 24 hours divided by 8
				    const intervalInMilliseconds = (24 * 60 * 60 * 1000) / totalTaxAPIUpdateFrequencyP24H; // 24 hours divided by 8
				    for (let i = 1; i < totalTaxAPIUpdateFrequencyP24H; i++) {
				        setTimeout(() => totalTaxAPICall(totalTaxAPI, config), i * intervalInMilliseconds);
				    }
				}

				// if api url is provided, username & password is not provided, but api key is provided
				else if (totalTaxAPIUsername === "" && totalTaxAPIPassword === "" && totalTaxAPIAPIKey !== "") {
					console.log('<<<##');

                    let config = {
                        headers: {
                        	"X-API-KEY": `${totalTaxAPIAPIKey}`,
                        },
                    };
                    
                    // ['a:b', "c:d"]
                    // extracting headers from totalTaxAPIMoreHeaders trait, if provided
                    if (totalTaxAPIMoreHeaders) {
                        totalTaxAPIMoreHeaders.split(",").forEach((header) => {
	                        let key, val;
	                        [key, val] = header.split(":");
	                        console.log("[key, val]:\n", key, val);

                          config.headers[key] = val;
                        });
                    }

                    console.log("config:", config, '\ntotalTaxAPI:', totalTaxAPI);

					totalTaxAPICall(totalTaxAPI, config);

					// Schedule the API call to run 8 times in every 24 hours
				    // const intervalInMilliseconds = (24 * 60 * 60 * 1000) / 8; // 24 hours divided by 8
				    const intervalInMilliseconds = (24 * 60 * 60 * 1000) / totalTaxAPIUpdateFrequencyP24H; // 24 hours divided by 8
				    for (let i = 1; i < totalTaxAPIUpdateFrequencyP24H; i++) {
				        setTimeout(() => totalTaxAPICall(totalTaxAPI, config), i * intervalInMilliseconds);
				    }
				}

				// if api url is provided, username & password is provided, but api key is also provided
				else if (totalTaxAPIUsername !== "" && totalTaxAPIPassword !== "" && totalTaxAPIAPIKey !== "") {
					console.log('<<<##');

                    let config = {
                        headers: {
                        	Authorization: "Basic " + btoa(totalTaxAPIUsername + ":" + totalTaxAPIPassword),
                        	"X-API-KEY": `${totalTaxAPIAPIKey}`,
                        },
                    };
                    
                    // ['a:b', "c:d"]
                    // extracting headers from totalTaxAPIMoreHeaders trait, if provided
                    if (totalTaxAPIMoreHeaders) {
                        totalTaxAPIMoreHeaders.split(",").forEach((header) => {
	                        let key, val;
	                        [key, val] = header.split(":");
	                        console.log("[key, val]:\n", key, val);

                          config.headers[key] = val;
                        });
                    }

                    console.log("config:", config, '\ntotalTaxAPI:', totalTaxAPI);

					totalTaxAPICall(totalTaxAPI, config);

					// Schedule the API call to run 8 times in every 24 hours
				    // const intervalInMilliseconds = (24 * 60 * 60 * 1000) / 8; // 24 hours divided by 8
				    const intervalInMilliseconds = (24 * 60 * 60 * 1000) / totalTaxAPIUpdateFrequencyP24H; // 24 hours divided by 8
				    for (let i = 1; i < totalTaxAPIUpdateFrequencyP24H; i++) {
				        setTimeout(() => totalTaxAPICall(totalTaxAPI, config), i * intervalInMilliseconds);
				    }
				}
			} catch(e) {
				console.log(e);
			}
		}

		// TABLE 1 SECTION (first table in the template) -
		// getting all the trait values
		// example - https://swapi.dev/api/planets/
		// example - https://swapi.dev/api/planets/3/
		// example - http://0.0.0.0:8080/183.82.1.220:8000/wem_invoice/invoice?sap-client=800&MONTH=MAR
		let table1API = storedProps.Table1_API;
		let table1APIUsername = storedProps.Table1_API_Username;
		let table1APIPassword = storedProps.Table1_API_Password;
		let table1APIAPIKey = storedProps.Table1_API_API_Key;
		let table1APIMoreHeaders = storedProps.Table1_API_More_Headers;
		let table1APIUpdateFrequencyP24H = storedProps.Table1_API_Update_Frequency_P24H;

		let table1Keyname = storedProps.Table1_Keyname;

		console.log('Template storedProps (8):\n',storedProps, '\ncomponent:', component);

		// get the api response is api is provided;
		// this api has to be run at regular time intervals to get the update
		if (table1API !== '') {
			try {
				// if api url is provided and nothing else
				if (table1APIUsername === "" && table1APIPassword === "" && table1APIAPIKey === "") {
					console.log('<<<##');

                    let config = {
                        headers: {},
                    };
                    
                    // ['a:b', "c:d"]
                    // extracting headers from table1APIMoreHeaders trait, if provided
                    if (table1APIMoreHeaders) {
                        table1APIMoreHeaders.split(",").forEach((header) => {
	                        let key, val;
	                        [key, val] = header.split(":");
	                        console.log("[key, val]:\n", key, val);

                          config.headers[key] = val;
                        });
                    }

                    console.log("config:", config, '\ntable1API:', table1API);

					table1APICall(table1API, config);

					// Schedule the API call to run 8 times in every 24 hours
				    // const intervalInMilliseconds = (24 * 60 * 60 * 1000) / 8; // 24 hours divided by 8
				    const intervalInMilliseconds = (24 * 60 * 60 * 1000) / table1APIUpdateFrequencyP24H; // 24 hours divided by 8
				    for (let i = 1; i < table1APIUpdateFrequencyP24H; i++) {
				        setTimeout(() => table1APICall(table1API, config), i * intervalInMilliseconds);
				    }
				}

				// if api url is provided, username & password is provided, but api key is not provided
				else if (table1APIUsername !== "" && table1APIPassword !== "" && table1APIAPIKey === "") {
					console.log('<<<##');

                    let config = {
                        headers: {
                        	Authorization: "Basic " + btoa(table1APIUsername + ":" + table1APIPassword),
                        },
                    };
                    
                    // ['a:b', "c:d"]
                    // extracting headers from table1APIMoreHeaders trait, if provided
                    if (table1APIMoreHeaders) {
                        table1APIMoreHeaders.split(",").forEach((header) => {
	                        let key, val;
	                        [key, val] = header.split(":");
	                        console.log("[key, val]:\n", key, val);

                          config.headers[key] = val;
                        });
                    }

                    console.log("config:", config, '\ntable1API:', table1API);

					table1APICall(table1API, config);

					// Schedule the API call to run 8 times in every 24 hours
				    // const intervalInMilliseconds = (24 * 60 * 60 * 1000) / 8; // 24 hours divided by 8
				    const intervalInMilliseconds = (24 * 60 * 60 * 1000) / table1APIUpdateFrequencyP24H; // 24 hours divided by 8
				    for (let i = 1; i < table1APIUpdateFrequencyP24H; i++) {
				        setTimeout(() => table1APICall(table1API, config), i * intervalInMilliseconds);
				    }
				}

				// if api url is provided, username & password is not provided, but api key is provided
				else if (table1APIUsername === "" && table1APIPassword === "" && table1APIAPIKey !== "") {
					console.log('<<<##');

                    let config = {
                        headers: {
                        	"X-API-KEY": `${table1APIAPIKey}`,
                        },
                    };
                    
                    // ['a:b', "c:d"]
                    // extracting headers from table1APIMoreHeaders trait, if provided
                    if (table1APIMoreHeaders) {
                        table1APIMoreHeaders.split(",").forEach((header) => {
	                        let key, val;
	                        [key, val] = header.split(":");
	                        console.log("[key, val]:\n", key, val);

                          config.headers[key] = val;
                        });
                    }

                    console.log("config:", config, '\ntable1API:', table1API);

					table1APICall(table1API, config);

					// Schedule the API call to run 8 times in every 24 hours
				    // const intervalInMilliseconds = (24 * 60 * 60 * 1000) / 8; // 24 hours divided by 8
				    const intervalInMilliseconds = (24 * 60 * 60 * 1000) / table1APIUpdateFrequencyP24H; // 24 hours divided by 8
				    for (let i = 1; i < table1APIUpdateFrequencyP24H; i++) {
				        setTimeout(() => table1APICall(table1API, config), i * intervalInMilliseconds);
				    }
				}

				// if api url is provided, username & password is provided, but api key is also provided
				else if (table1APIUsername !== "" && table1APIPassword !== "" && table1APIAPIKey !== "") {
					console.log('<<<##');

                    let config = {
                        headers: {
                        	Authorization: "Basic " + btoa(table1APIUsername + ":" + table1APIPassword),
                        	"X-API-KEY": `${table1APIAPIKey}`,
                        },
                    };
                    
                    // ['a:b', "c:d"]
                    // extracting headers from table1APIMoreHeaders trait, if provided
                    if (table1APIMoreHeaders) {
                        table1APIMoreHeaders.split(",").forEach((header) => {
	                        let key, val;
	                        [key, val] = header.split(":");
	                        console.log("[key, val]:\n", key, val);

                          config.headers[key] = val;
                        });
                    }

                    console.log("config:", config, '\ntable1API:', table1API);

					table1APICall(table1API, config);

					// Schedule the API call to run 8 times in every 24 hours
				    // const intervalInMilliseconds = (24 * 60 * 60 * 1000) / 8; // 24 hours divided by 8
				    const intervalInMilliseconds = (24 * 60 * 60 * 1000) / table1APIUpdateFrequencyP24H; // 24 hours divided by 8
				    for (let i = 1; i < table1APIUpdateFrequencyP24H; i++) {
				        setTimeout(() => table1APICall(table1API, config), i * intervalInMilliseconds);
				    }
				}
			} catch(e) {
				console.log(e);
			}
		}

		// TABLE 2 SECTION (second table in the template) -
		// getting all the trait values
		// example - https://swapi.dev/api/planets/
		// example - https://swapi.dev/api/planets/3/
		// example - http://0.0.0.0:8080/183.82.1.220:8000/wem_invoice/invoice?sap-client=800&MONTH=MAR
		let table2API = storedProps.Table2_API;
		let table2APIUsername = storedProps.Table2_API_Username;
		let table2APIPassword = storedProps.Table2_API_Password;
		let table2APIAPIKey = storedProps.Table2_API_API_Key;
		let table2APIMoreHeaders = storedProps.Table2_API_More_Headers;
		let table2APIUpdateFrequencyP24H = storedProps.Table2_API_Update_Frequency_P24H;

		let table2Keyname = storedProps.Table2_Keyname;

		console.log('Template storedProps (9):\n',storedProps, '\ncomponent:', component);

		// get the api response is api is provided;
		// this api has to be run at regular time intervals to get the update
		if (table2API !== '') {
			try {
				// if api url is provided and nothing else
				if (table2APIUsername === "" && table2APIPassword === "" && table2APIAPIKey === "") {
					console.log('<<<##');

                    let config = {
                        headers: {},
                    };
                    
                    // ['a:b', "c:d"]
                    // extracting headers from table2APIMoreHeaders trait, if provided
                    if (table2APIMoreHeaders) {
                        table2APIMoreHeaders.split(",").forEach((header) => {
	                        let key, val;
	                        [key, val] = header.split(":");
	                        console.log("[key, val]:\n", key, val);

                          config.headers[key] = val;
                        });
                    }

                    console.log("config:", config, '\ntable2API:', table2API);

					table2APICall(table2API, config);

					// Schedule the API call to run 8 times in every 24 hours
				    // const intervalInMilliseconds = (24 * 60 * 60 * 1000) / 8; // 24 hours divided by 8
				    const intervalInMilliseconds = (24 * 60 * 60 * 1000) / table2APIUpdateFrequencyP24H; // 24 hours divided by 8
				    for (let i = 1; i < table2APIUpdateFrequencyP24H; i++) {
				        setTimeout(() => table2APICall(table2API, config), i * intervalInMilliseconds);
				    }
				}

				// if api url is provided, username & password is provided, but api key is not provided
				else if (table2APIUsername !== "" && table2APIPassword !== "" && table2APIAPIKey === "") {
					console.log('<<<##');

                    let config = {
                        headers: {
                        	Authorization: "Basic " + btoa(table2APIUsername + ":" + table2APIPassword),
                        },
                    };
                    
                    // ['a:b', "c:d"]
                    // extracting headers from table2APIMoreHeaders trait, if provided
                    if (table2APIMoreHeaders) {
                        table2APIMoreHeaders.split(",").forEach((header) => {
	                        let key, val;
	                        [key, val] = header.split(":");
	                        console.log("[key, val]:\n", key, val);

                          config.headers[key] = val;
                        });
                    }

                    console.log("config:", config, '\ntable2API:', table2API);

					table2APICall(table2API, config);

					// Schedule the API call to run 8 times in every 24 hours
				    // const intervalInMilliseconds = (24 * 60 * 60 * 1000) / 8; // 24 hours divided by 8
				    const intervalInMilliseconds = (24 * 60 * 60 * 1000) / table2APIUpdateFrequencyP24H; // 24 hours divided by 8
				    for (let i = 1; i < table2APIUpdateFrequencyP24H; i++) {
				        setTimeout(() => table2APICall(table2API, config), i * intervalInMilliseconds);
				    }
				}

				// if api url is provided, username & password is not provided, but api key is provided
				else if (table2APIUsername === "" && table2APIPassword === "" && table2APIAPIKey !== "") {
					console.log('<<<##');

                    let config = {
                        headers: {
                        	"X-API-KEY": `${table2APIAPIKey}`,
                        },
                    };
                    
                    // ['a:b', "c:d"]
                    // extracting headers from table2APIMoreHeaders trait, if provided
                    if (table2APIMoreHeaders) {
                        table2APIMoreHeaders.split(",").forEach((header) => {
	                        let key, val;
	                        [key, val] = header.split(":");
	                        console.log("[key, val]:\n", key, val);

                          config.headers[key] = val;
                        });
                    }

                    console.log("config:", config, '\ntable2API:', table2API);

					table2APICall(table2API, config);

					// Schedule the API call to run 8 times in every 24 hours
				    // const intervalInMilliseconds = (24 * 60 * 60 * 1000) / 8; // 24 hours divided by 8
				    const intervalInMilliseconds = (24 * 60 * 60 * 1000) / table2APIUpdateFrequencyP24H; // 24 hours divided by 8
				    for (let i = 1; i < table2APIUpdateFrequencyP24H; i++) {
				        setTimeout(() => table2APICall(table2API, config), i * intervalInMilliseconds);
				    }
				}

				// if api url is provided, username & password is provided, but api key is also provided
				else if (table2APIUsername !== "" && table2APIPassword !== "" && table2APIAPIKey !== "") {
					console.log('<<<##');

                    let config = {
                        headers: {
                        	Authorization: "Basic " + btoa(table2APIUsername + ":" + table2APIPassword),
                        	"X-API-KEY": `${table2APIAPIKey}`,
                        },
                    };
                    
                    // ['a:b', "c:d"]
                    // extracting headers from table2APIMoreHeaders trait, if provided
                    if (table2APIMoreHeaders) {
                        table2APIMoreHeaders.split(",").forEach((header) => {
	                        let key, val;
	                        [key, val] = header.split(":");
	                        console.log("[key, val]:\n", key, val);

                          config.headers[key] = val;
                        });
                    }

                    console.log("config:", config, '\ntable2API:', table2API);

					table2APICall(table2API, config);

					// Schedule the API call to run 8 times in every 24 hours
				    // const intervalInMilliseconds = (24 * 60 * 60 * 1000) / 8; // 24 hours divided by 8
				    const intervalInMilliseconds = (24 * 60 * 60 * 1000) / table2APIUpdateFrequencyP24H; // 24 hours divided by 8
				    for (let i = 1; i < table2APIUpdateFrequencyP24H; i++) {
				        setTimeout(() => table2APICall(table2API, config), i * intervalInMilliseconds);
				    }
				}
			} catch(e) {
				console.log(e);
			}
		}

		// TABLE 3 SECTION (third table in the template) -
		// getting all the trait values
		// example - https://swapi.dev/api/planets/
		// example - https://swapi.dev/api/planets/3/
		// example - http://0.0.0.0:8080/183.82.1.220:8000/wem_invoice/invoice?sap-client=800&MONTH=MAR
		let table3API = storedProps.Table3_API;
		let table3APIUsername = storedProps.Table3_API_Username;
		let table3APIPassword = storedProps.Table3_API_Password;
		let table3APIAPIKey = storedProps.Table3_API_API_Key;
		let table3APIMoreHeaders = storedProps.Table3_API_More_Headers;
		let table3APIUpdateFrequencyP24H = storedProps.Table3_API_Update_Frequency_P24H;

		let table3Keyname = storedProps.Table3_Keyname;

		console.log('Template props (10):\n',storedProps, '\ncomponent:', component);

		// get the api response is api is provided;
		// this api has to be run at regular time intervals to get the update
		if (table3API !== '') {
			try {
				// if api url is provided and nothing else
				if (table3APIUsername === "" && table3APIPassword === "" && table3APIAPIKey === "") {
					console.log('<<<##');

                    let config = {
                        headers: {},
                    };
                    
                    // ['a:b', "c:d"]
                    // extracting headers from table3APIMoreHeaders trait, if provided
                    if (table3APIMoreHeaders) {
                        table3APIMoreHeaders.split(",").forEach((header) => {
	                        let key, val;
	                        [key, val] = header.split(":");
	                        console.log("[key, val]:\n", key, val);

                          config.headers[key] = val;
                        });
                    }

                    console.log("config:", config, '\ntable3API:', table3API);

					table3APICall(table3API, config);

					// Schedule the API call to run 8 times in every 24 hours
				    // const intervalInMilliseconds = (24 * 60 * 60 * 1000) / 8; // 24 hours divided by 8
				    const intervalInMilliseconds = (24 * 60 * 60 * 1000) / table3APIUpdateFrequencyP24H; // 24 hours divided by 8
				    for (let i = 1; i < table3APIUpdateFrequencyP24H; i++) {
				        setTimeout(() => table3APICall(table3API, config), i * intervalInMilliseconds);
				    }
				}

				// if api url is provided, username & password is provided, but api key is not provided
				else if (table3APIUsername !== "" && table3APIPassword !== "" && table3APIAPIKey === "") {
					console.log('<<<##');

                    let config = {
                        headers: {
                        	Authorization: "Basic " + btoa(table3APIUsername + ":" + table3APIPassword),
                        },
                    };
                    
                    // ['a:b', "c:d"]
                    // extracting headers from table3APIMoreHeaders trait, if provided
                    if (table3APIMoreHeaders) {
                        table3APIMoreHeaders.split(",").forEach((header) => {
	                        let key, val;
	                        [key, val] = header.split(":");
	                        console.log("[key, val]:\n", key, val);

                          config.headers[key] = val;
                        });
                    }

                    console.log("config:", config, '\ntable3API:', table3API);

					table3APICall(table3API, config);

					// Schedule the API call to run 8 times in every 24 hours
				    // const intervalInMilliseconds = (24 * 60 * 60 * 1000) / 8; // 24 hours divided by 8
				    const intervalInMilliseconds = (24 * 60 * 60 * 1000) / table3APIUpdateFrequencyP24H; // 24 hours divided by 8
				    for (let i = 1; i < table3APIUpdateFrequencyP24H; i++) {
				        setTimeout(() => table3APICall(table3API, config), i * intervalInMilliseconds);
				    }
				}

				// if api url is provided, username & password is not provided, but api key is provided
				else if (table3APIUsername === "" && table3APIPassword === "" && table3APIAPIKey !== "") {
					console.log('<<<##');

                    let config = {
                        headers: {
                        	"X-API-KEY": `${table3APIAPIKey}`,
                        },
                    };
                    
                    // ['a:b', "c:d"]
                    // extracting headers from table3APIMoreHeaders trait, if provided
                    if (table3APIMoreHeaders) {
                        table3APIMoreHeaders.split(",").forEach((header) => {
	                        let key, val;
	                        [key, val] = header.split(":");
	                        console.log("[key, val]:\n", key, val);

                          config.headers[key] = val;
                        });
                    }

                    console.log("config:", config, '\ntable3API:', table3API);

					table3APICall(table3API, config);

					// Schedule the API call to run 8 times in every 24 hours
				    // const intervalInMilliseconds = (24 * 60 * 60 * 1000) / 8; // 24 hours divided by 8
				    const intervalInMilliseconds = (24 * 60 * 60 * 1000) / table3APIUpdateFrequencyP24H; // 24 hours divided by 8
				    for (let i = 1; i < table3APIUpdateFrequencyP24H; i++) {
				        setTimeout(() => table3APICall(table3API, config), i * intervalInMilliseconds);
				    }
				}

				// if api url is provided, username & password is provided, but api key is also provided
				else if (table3APIUsername !== "" && table3APIPassword !== "" && table3APIAPIKey !== "") {
					console.log('<<<##');

                    let config = {
                        headers: {
                        	Authorization: "Basic " + btoa(table3APIUsername + ":" + table3APIPassword),
                        	"X-API-KEY": `${table3APIAPIKey}`,
                        },
                    };
                    
                    // ['a:b', "c:d"]
                    // extracting headers from table3APIMoreHeaders trait, if provided
                    if (table3APIMoreHeaders) {
                        table3APIMoreHeaders.split(",").forEach((header) => {
	                        let key, val;
	                        [key, val] = header.split(":");
	                        console.log("[key, val]:\n", key, val);

                          config.headers[key] = val;
                        });
                    }

                    console.log("config:", config, '\ntable3API:', table3API);

					table3APICall(table3API, config);

					// Schedule the API call to run 8 times in every 24 hours
				    // const intervalInMilliseconds = (24 * 60 * 60 * 1000) / 8; // 24 hours divided by 8
				    const intervalInMilliseconds = (24 * 60 * 60 * 1000) / table3APIUpdateFrequencyP24H; // 24 hours divided by 8
				    for (let i = 1; i < table3APIUpdateFrequencyP24H; i++) {
				        setTimeout(() => table3APICall(table3API, config), i * intervalInMilliseconds);
				    }
				}
			} catch(e) {
				console.log(e);
			}
		}


		// TOTAL INVESTMENT SECTION -
		// getting all the trait values
		// example - https://swapi.dev/api/planets/3/
		let totalInvestmentCardAPI = storedProps.Total_Investment_API;
		let totalInvestmentAPIUsername = storedProps.Total_Investment_API_Username;
		let totalInvestmentAPIPassword = storedProps.Total_Investment_API_Password;
		let totalInvestmentAPIAPIKey = storedProps.Total_Investment_API_API_Key;
		let totalInvestmentAPIMoreHeaders = storedProps.Total_Investment_API_More_Headers;
		let totalInvestmentAPIUpdateFrequencyP24H = storedProps.Total_Investment_API_Update_Frequency_P24H;

		let totalResponseKeyname = storedProps.Total_Response_Keyname;
		let monthlyResponseKeyname = storedProps.Monthly_Response_Keyname;
		let dailyResponseKeyname = storedProps.Daily_Response_Keyname;

		console.log('Template storedProps (11):\n',storedProps, '\ncomponent:', component);

		// get the api response is api is provided;
		// this api has to be run at regular time intervals to get the update
		if (totalInvestmentCardAPI !== '') {
			try {
				// if api url is provided and nothing else
				if (totalInvestmentAPIUsername === "" && totalInvestmentAPIPassword === "" && totalInvestmentAPIAPIKey === "") {
					console.log('<<<##');					

		            let config = {
		                headers: {},
		            };
		            
		            // ['a:b', "c:d"]
		            // extracting headers from totalInvestmentAPIMoreHeaders trait, if provided
		            if (totalInvestmentAPIMoreHeaders) {
		                totalInvestmentAPIMoreHeaders.split(",").forEach((header) => {
		                    let key, val;
		                    [key, val] = header.split(":");
		                    console.log("[key, val]:\n", key, val);

		                  config.headers[key] = val;
		                });
		            }

		            console.log("config:", config, '\ntotalInvestmentCardAPI:', totalInvestmentCardAPI);

					totalInvestmentCardAPICall(totalInvestmentCardAPI, config);

					// Schedule the API call to run 8 times in every 24 hours
				    // const intervalInMilliseconds = (24 * 60 * 60 * 1000) / 8; // 24 hours divided by 8
				    const intervalInMilliseconds = (24 * 60 * 60 * 1000) / totalInvestmentAPIUpdateFrequencyP24H; // 24 hours divided by 8
				    for (let i = 1; i < totalInvestmentAPIUpdateFrequencyP24H; i++) {
				        setTimeout(() => totalInvestmentCardAPICall(totalInvestmentCardAPI, config), i * intervalInMilliseconds);
				    }
				}

				// if api url is provided, username & password is provided, but api key is not provided
				else if (totalInvestmentAPIUsername !== "" && totalInvestmentAPIPassword !== "" && totalInvestmentAPIAPIKey === "") {
					console.log('<<<##');

                    let config = {
                        headers: {
                        	Authorization: "Basic " + btoa(totalInvestmentAPIUsername + ":" + totalInvestmentAPIPassword),
                        },
                    };
                    
                    // ['a:b', "c:d"]
                    // extracting headers from totalInvestmentAPIMoreHeaders trait, if provided
                    if (totalInvestmentAPIMoreHeaders) {
                        totalInvestmentAPIMoreHeaders.split(",").forEach((header) => {
	                        let key, val;
	                        [key, val] = header.split(":");
	                        console.log("[key, val]:\n", key, val);

                          config.headers[key] = val;
                        });
                    }

                    console.log("config:", config, '\ntotalInvestmentCardAPI:', totalInvestmentCardAPI);

					totalInvestmentCardAPICall(totalInvestmentCardAPI, config);

					// Schedule the API call to run 8 times in every 24 hours
				    // const intervalInMilliseconds = (24 * 60 * 60 * 1000) / 8; // 24 hours divided by 8
				    const intervalInMilliseconds = (24 * 60 * 60 * 1000) / totalInvestmentAPIUpdateFrequencyP24H; // 24 hours divided by 8
				    for (let i = 1; i < totalInvestmentAPIUpdateFrequencyP24H; i++) {
				        setTimeout(() => totalInvestmentCardAPICall(totalInvestmentCardAPI, config), i * intervalInMilliseconds);
				    }
				}

				// if api url is provided, username & password is not provided, but api key is provided
				else if (totalInvestmentAPIUsername === "" && totalInvestmentAPIPassword === "" && totalInvestmentAPIAPIKey !== "") {
					console.log('<<<##');

                    let config = {
                        headers: {
                        	"X-API-KEY": `${totalInvestmentAPIAPIKey}`,
                        },
                    };
                    
                    // ['a:b', "c:d"]
                    // extracting headers from totalInvestmentAPIMoreHeaders trait, if provided
                    if (totalInvestmentAPIMoreHeaders) {
                        totalInvestmentAPIMoreHeaders.split(",").forEach((header) => {
	                        let key, val;
	                        [key, val] = header.split(":");
	                        console.log("[key, val]:\n", key, val);

                          config.headers[key] = val;
                        });
                    }

                    console.log("config:", config, '\ntotalInvestmentCardAPI:', totalInvestmentCardAPI);

					totalInvestmentCardAPICall(totalInvestmentCardAPI, config);

					// Schedule the API call to run 8 times in every 24 hours
				    // const intervalInMilliseconds = (24 * 60 * 60 * 1000) / 8; // 24 hours divided by 8
				    const intervalInMilliseconds = (24 * 60 * 60 * 1000) / totalInvestmentAPIUpdateFrequencyP24H; // 24 hours divided by 8
				    for (let i = 1; i < totalInvestmentAPIUpdateFrequencyP24H; i++) {
				        setTimeout(() => totalInvestmentCardAPICall(totalInvestmentCardAPI, config), i * intervalInMilliseconds);
				    }
				}

				// if api url is provided, username & password is provided, but api key is also provided
				else if (totalInvestmentAPIUsername !== "" && totalInvestmentAPIPassword !== "" && totalInvestmentAPIAPIKey !== "") {
					console.log('<<<##');

                    let config = {
                        headers: {
                        	Authorization: "Basic " + btoa(totalInvestmentAPIUsername + ":" + totalInvestmentAPIPassword),
                        	"X-API-KEY": `${totalInvestmentAPIAPIKey}`,
                        },
                    };
                    
                    // ['a:b', "c:d"]
                    // extracting headers from totalInvestmentAPIMoreHeaders trait, if provided
                    if (totalInvestmentAPIMoreHeaders) {
                        totalInvestmentAPIMoreHeaders.split(",").forEach((header) => {
	                        let key, val;
	                        [key, val] = header.split(":");
	                        console.log("[key, val]:\n", key, val);

                          config.headers[key] = val;
                        });
                    }

                    console.log("config:", config, '\ntotalInvestmentCardAPI:', totalInvestmentCardAPI);

					totalInvestmentCardAPICall(totalInvestmentCardAPI, config);

					// Schedule the API call to run 8 times in every 24 hours
				    // const intervalInMilliseconds = (24 * 60 * 60 * 1000) / 8; // 24 hours divided by 8
				    const intervalInMilliseconds = (24 * 60 * 60 * 1000) / totalInvestmentAPIUpdateFrequencyP24H; // 24 hours divided by 8
				    for (let i = 1; i < totalInvestmentAPIUpdateFrequencyP24H; i++) {
				        setTimeout(() => totalInvestmentCardAPICall(totalInvestmentCardAPI, config), i * intervalInMilliseconds);
				    }
				}
			} catch(e) {
				console.log(e);
			}
		}
	};

	let storedProps = {
		Todays_API: '',
		Todays_API_Username: '',
		Todays_API_Password: '',
		Todays_API_API_Key: '',
		Todays_API_More_Headers: '',
		Todays_API_Update_Frequency_P24H: 1,
		Date_Response_Keyname: '',
		Todays_Sale_Response_Keyname: '',
		Todays_Earnings_Response_Keyname: '',


		Total_Sale_Last_Year_API: '',
		Total_Sale_Last_Year_API_Username: '',
		Total_Sale_Last_Year_API_Password: '',
		Total_Sale_Last_Year_API_API_Key: '',
		Total_Sale_Last_Year_API_More_Headers: '',
		Total_Sale_Last_Year_API_Update_Frequency_P24H: 1,
		Total_Sale_Last_Year_Percentage: '',
		Total_Sale_Last_Year_Amount: '',


		Active_Customer_Last_Day_API: '',
		Active_Customer_Last_Day_API_Username: '',
		Active_Customer_Last_Day_API_Password: '',
		Active_Customer_Last_Day_API_API_Key: '',
		Active_Customer_Last_Day_API_More_Headers: '',
		Active_Customer_Last_Day_API_Update_Frequency_P24H: 1,
		Active_Customer_Last_Day_Percentage: '',
		Active_Customer_Last_Day_Amount: '',


		Total_Sales_API: '',
		Total_Sales_API_Username: '',
		Total_Sales_API_Password: '',
		Total_Sales_API_API_Key: '',
		Total_Sales_API_More_Headers: '',
		Total_Sales_API_Update_Frequency_P24H: 1,
		Total_Sales: '',
		Sales_Goal_Reached_Percentage: '',
		Sales_Goal_Reached_Count: '',


		Total_Income_API: '',
		Total_Income_API_Username: '',
		Total_Income_API_Password: '',
		Total_Income_API_API_Key: '',
		Total_Income_API_More_Headers: '',        		
		Total_Income_API_Update_Frequency_P24H: 1,        		
		Total_Income: '',
		Income_Goal_Reached_Percentage: '',
		Income_Goal_Reached_Count: '',
		

		Active_User_API: '',
		Active_User_API_Username: '',
		Active_User_API_Password: '',
		Active_User_API_API_Key: '',
		Active_User_API_More_Headers: '',
		Active_User_API_Update_Frequency_P24H: 1,
		Active_User: '',
		Active_User_Goal_Reached_Percentage: '',
		Active_User_Goal_Reached_Count: '',
		

		Total_Tax_API: '',
		Total_Tax_API_Username: '',
		Total_Tax_API_Password: '',
		Total_Tax_API_API_Key: '',
		Total_Tax_API_More_Headers: '',
		Total_Tax_API_Update_Frequency_P24H: 1,
		Total_Tax: '',
		Tax_Goal_Reached_Percentage: '',
		Tax_Goal_Reached_Count: '',
		

		Table1_API: '',
		Table1_API_Username: '',
		Table1_API_Password: '',
		Table1_API_API_Key: '',
		Table1_API_More_Headers: '',
		Table1_API_Update_Frequency_P24H: 1,
		Table1_Keyname: '',
		

		Table2_API: '',
		Table2_API_Username: '',
		Table2_API_Password: '',
		Table2_API_API_Key: '',
		Table2_API_More_Headers: '',
		Table2_API_Update_Frequency_P24H: 1,
		Table2_Keyname: '',
		

		Table3_API: '',
		Table3_API_Username: '',
		Table3_API_Password: '',
		Table3_API_API_Key: '',
		Table3_API_More_Headers: '',
		Table3_API_Update_Frequency_P24H: 1,
		Table3_Keyname: '',


		Total_Investment_API: '',
		Total_Investment_API_Username: '',
		Total_Investment_API_Password: '',
		Total_Investment_API_API_Key: '',
		Total_Investment_API_More_Headers: '',
		Total_Investment_API_Update_Frequency_P24H: 1,
		Total_Response_Keyname: '',
		Monthly_Response_Keyname: '',
		Daily_Response_Keyname: '',
	};

	function updateProps(component) {
		let todaysApiTrait = component.getTrait('Todays_API');
    	let todaysApiTraitValue = todaysApiTrait ? todaysApiTrait.attributes.value : ''; // Check if the trait exists

		let todaysApiUsernameTrait = component.getTrait('Todays_API_Username');
    	let todaysApiUsernameTraitValue = todaysApiUsernameTrait ? todaysApiUsernameTrait.attributes.value : '';

		let todaysApiPasswordTrait = component.getTrait('Todays_API_Password');
    	let todaysApiPasswordTraitValue = todaysApiPasswordTrait ? todaysApiPasswordTrait.attributes.value : '';

		let todaysApiApiKeyTrait = component.getTrait('Todays_API_API_Key');
    	let todaysApiApiKeyTraitValue =todaysApiApiKeyTrait ? todaysApiApiKeyTrait.attributes.value : '';

		let todaysApiMoreHeadersTrait = component.getTrait('Todays_API_More_Headers');
    	let todaysApiMoreHeadersTraitValue = todaysApiMoreHeadersTrait ? todaysApiMoreHeadersTrait.attributes.value : '';

		let todaysAPIUpdateFrequencyP24HTrait = component.getTrait('Todays_API_Update_Frequency_P24H');
    	let todaysAPIUpdateFrequencyP24HTraitValue = todaysAPIUpdateFrequencyP24HTrait ? todaysAPIUpdateFrequencyP24HTrait.attributes.value : 1;

		let dataResponseKeynameTrait = component.getTrait('Date_Response_Keyname');
    	let dataResponseKeynameTraitValue = dataResponseKeynameTrait ? dataResponseKeynameTrait.attributes.value : '';

		let todaysSaleResponseKeynameTrait = component.getTrait('Todays_Sale_Response_Keyname');
    	let todaysSaleResponseKeynameTraitValue = todaysSaleResponseKeynameTrait ? todaysSaleResponseKeynameTrait.attributes.value : '';

		let todaysEarningsResponseKeynameTrait = component.getTrait('Todays_Earnings_Response_Keyname');
    	let todaysEarningsResponseKeynameTraitValue = todaysEarningsResponseKeynameTrait ? todaysEarningsResponseKeynameTrait.attributes.value : '';


  		// TOTAL-SALE-WIDGET SECTION -
  		let totalSaleLastYearAPITrait = component.getTrait('Total_Sale_Last_Year_API');
    	let totalSaleLastYearAPITraitValue = totalSaleLastYearAPITrait ? totalSaleLastYearAPITrait.attributes.value : '';

  		let totalSaleLastYearAPIUsernameTrait = component.getTrait('Total_Sale_Last_Year_API_Username');
    	let totalSaleLastYearAPIUsernameTraitValue = totalSaleLastYearAPIUsernameTrait ? totalSaleLastYearAPIUsernameTrait.attributes.value : '';

  		let totalSaleLastYearAPIPasswordTrait = component.getTrait('Total_Sale_Last_Year_API_Password');
    	let totalSaleLastYearAPIPasswordTraitValue = totalSaleLastYearAPIPasswordTrait ? totalSaleLastYearAPIPasswordTrait.attributes.value : '';

  		let totalSaleLastYearAPIAPIKeyTrait = component.getTrait('Total_Sale_Last_Year_API_API_Key');
    	let totalSaleLastYearAPIAPIKeyTraitValue = totalSaleLastYearAPIAPIKeyTrait ? totalSaleLastYearAPIAPIKeyTrait.attributes.value : '';

  		let totalSaleLastYearAPIMoreHeadersTrait = component.getTrait('Total_Sale_Last_Year_API_More_Headers');
    	let totalSaleLastYearAPIMoreHeadersTraitValue = totalSaleLastYearAPIMoreHeadersTrait ? totalSaleLastYearAPIMoreHeadersTrait.attributes.value : '';

  		let totalSaleLastYearAPIUpdateFrequencyP24HTrait = component.getTrait('Total_Sale_Last_Year_API_Update_Frequency_P24H');
    	let totalSaleLastYearAPIUpdateFrequencyP24HTraitValue = totalSaleLastYearAPIUpdateFrequencyP24HTrait ? totalSaleLastYearAPIUpdateFrequencyP24HTrait.attributes.value : 1;

  		let totalSaleLastYearPercentageTrait = component.getTrait('Total_Sale_Last_Year_Percentage');
    	let totalSaleLastYearPercentageTraitValue = totalSaleLastYearPercentageTrait ? totalSaleLastYearPercentageTrait.attributes.value : '';

  		let totalSaleLastYearAmountTrait = component.getTrait('Total_Sale_Last_Year_Amount');
    	let totalSaleLastYearAmountTraitValue = totalSaleLastYearAmountTrait ? totalSaleLastYearAmountTrait.attributes.value : '';


  		let activeCustomerLastDayAPITrait = component.getTrait('Active_Customer_Last_Day_API');
    	let activeCustomerLastDayAPITraitValue = activeCustomerLastDayAPITrait ? activeCustomerLastDayAPITrait.attributes.value : '';

  		let activeCustomerLastDayAPIUsernameTrait = component.getTrait('Active_Customer_Last_Day_API_Username');
    	let activeCustomerLastDayAPIUsernameTraitValue = activeCustomerLastDayAPIUsernameTrait ? activeCustomerLastDayAPIUsernameTrait.attributes.value : '';

  		let activeCustomerLastDayAPIPasswordTrait = component.getTrait('Active_Customer_Last_Day_API_Password');
    	let activeCustomerLastDayAPIPasswordTraitValue = activeCustomerLastDayAPIPasswordTrait ? activeCustomerLastDayAPIPasswordTrait.attributes.value : '';

  		let activeCustomerLastDayAPIAPIKeyTrait = component.getTrait('Active_Customer_Last_Day_API_API_Key');
    	let activeCustomerLastDayAPIAPIKeyTraitValue = activeCustomerLastDayAPIAPIKeyTrait ? activeCustomerLastDayAPIAPIKeyTrait.attributes.value : '';

  		let activeCustomerLastDayAPIMoreHeadersTrait = component.getTrait('Active_Customer_Last_Day_API_More_Headers');
    	let activeCustomerLastDayAPIMoreHeadersTraitValue = activeCustomerLastDayAPIMoreHeadersTrait ? activeCustomerLastDayAPIMoreHeadersTrait.attributes.value : '';

  		let activeCustomerLastDayAPIUpdateFrequencyP24HTrait = component.getTrait('Active_Customer_Last_Day_API_Update_Frequency_P24H');
    	let activeCustomerLastDayAPIUpdateFrequencyP24HTraitValue = activeCustomerLastDayAPIUpdateFrequencyP24HTrait ? activeCustomerLastDayAPIUpdateFrequencyP24HTrait.attributes.value : 1;

  		let activeCustomerLastDayPercentageTrait = component.getTrait('Active_Customer_Last_Day_Percentage');
    	let activeCustomerLastDayPercentageTraitValue = activeCustomerLastDayPercentageTrait ? activeCustomerLastDayPercentageTrait.attributes.value : '';

  		let activeCustomerLastDayAmountTrait = component.getTrait('Active_Customer_Last_Day_Amount');
    	let activeCustomerLastDayAmountTraitValue = activeCustomerLastDayAmountTrait ? activeCustomerLastDayAmountTrait.attributes.value : '';


    	// SUPPORT-TICKET-FONT SECTION -
    	let totalSalesAPITrait = component.getTrait('Total_Sales_API');
    	let totalSalesAPITraitValue = totalSalesAPITrait ? totalSalesAPITrait.attributes.value : '';

    	let totalSalesAPIUsernameTrait = component.getTrait('Total_Sales_API_Username');
    	let totalSalesAPIUsernameTraitValue = totalSalesAPIUsernameTrait ? totalSalesAPIUsernameTrait.attributes.value : '';
    	
    	let totalSalesAPIPasswordTrait = component.getTrait('Total_Sales_API_Password');
    	let totalSalesAPIPasswordTraitValue = totalSalesAPIPasswordTrait ? totalSalesAPIPasswordTrait.attributes.value : '';
    	
    	let totalSalesAPIAPIKeyTrait = component.getTrait('Total_Sales_API_API_Key');
    	let totalSalesAPIAPIKeyTraitValue = totalSalesAPIAPIKeyTrait ? totalSalesAPIAPIKeyTrait.attributes.value : '';
    	
    	let totalSalesAPIMoreHeadersTrait = component.getTrait('Total_Sales_API_More_Headers');
    	let totalSalesAPIMoreHeadersTraitValue = totalSalesAPIMoreHeadersTrait ? totalSalesAPIMoreHeadersTrait.attributes.value : '';
    	
    	let totalSalesAPIUpdateFrequencyP24HTrait = component.getTrait('Total_Sales_API_Update_Frequency_P24H');
    	let totalSalesAPIUpdateFrequencyP24HTraitValue = totalSalesAPIUpdateFrequencyP24HTrait ? totalSalesAPIUpdateFrequencyP24HTrait.attributes.value : 1;

		let totalSalesTrait = component.getTrait('Total_Sales');
    	let totalSalesTraitValue = totalSalesTrait ? totalSalesTrait.attributes.value : '';

		let salesGoalReachedPercentageTrait = component.getTrait('Sales_Goal_Reached_Percentage');
    	let salesGoalReachedPercentageTraitValue = salesGoalReachedPercentageTrait ? salesGoalReachedPercentageTrait.attributes.value : '';

		let salesGoalReachedCountTrait = component.getTrait('Sales_Goal_Reached_Count');
    	let salesGoalReachedCountTraitValue = salesGoalReachedCountTrait ? salesGoalReachedCountTrait.attributes.value : '';


    	let totalIncomeAPITrait = component.getTrait('Total_Income_API');
    	let totalIncomeAPITraitValue = totalIncomeAPITrait ? totalIncomeAPITrait.attributes.value : '';

    	let totalIncomeAPIUsernameTrait = component.getTrait('Total_Income_API_Username');
    	let totalIncomeAPIUsernameTraitValue = totalIncomeAPIUsernameTrait ? totalIncomeAPIUsernameTrait.attributes.value : '';
    	
    	let totalIncomeAPIPasswordTrait = component.getTrait('Total_Income_API_Password');
    	let totalIncomeAPIPasswordTraitValue = totalIncomeAPIPasswordTrait ? totalIncomeAPIPasswordTrait.attributes.value : '';
    	
    	let totalIncomeAPIAPIKeyTrait = component.getTrait('Total_Income_API_API_Key');
    	let totalIncomeAPIAPIKeyTraitValue = totalIncomeAPIAPIKeyTrait ? totalIncomeAPIAPIKeyTrait.attributes.value : '';
    	
    	let totalIncomeAPIMoreHeadersTrait = component.getTrait('Total_Income_API_More_Headers');
    	let totalIncomeAPIMoreHeadersTraitValue = totalIncomeAPIMoreHeadersTrait ? totalIncomeAPIMoreHeadersTrait.attributes.value : '';
    	
    	let totalIncomeAPIUpdateFrequencyP24H = component.getTrait('Total_Income_API_Update_Frequency_P24H');
    	let totalIncomeAPIUpdateFrequencyP24HValue = totalIncomeAPIUpdateFrequencyP24H ? totalIncomeAPIUpdateFrequencyP24H.attributes.value : 1;

		let totalIncomeTrait = component.getTrait('Total_Income');
    	let totalIncomeTraitValue = totalIncomeTrait ? totalIncomeTrait.attributes.value : '';

		let incomeGoalReachedPercentageTrait = component.getTrait('Income_Goal_Reached_Percentage');
    	let incomeGoalReachedPercentageTraitValue = incomeGoalReachedPercentageTrait ? incomeGoalReachedPercentageTrait.attributes.value : '';

		let incomeGoalReachedCountTrait = component.getTrait('Income_Goal_Reached_Count');
    	let incomeGoalReachedCountTraitValue = incomeGoalReachedCountTrait ? incomeGoalReachedCountTrait.attributes.value : '';


    	let activeUserAPITrait = component.getTrait('Active_User_API');
    	let activeUserAPITraitValue = activeUserAPITrait ? activeUserAPITrait.attributes.value : '';

    	let activeUserAPIUsernameTrait = component.getTrait('Active_User_API_Username');
    	let activeUserAPIUsernameTraitValue = activeUserAPIUsernameTrait ? activeUserAPIUsernameTrait.attributes.value : '';

    	let activeUserAPIPasswordTrait = component.getTrait('Active_User_API_Password');
    	let activeUserAPIPasswordTraitValue = activeUserAPIPasswordTrait ? activeUserAPIPasswordTrait.attributes.value : '';

    	let activeUserAPIAPIKeyTrait = component.getTrait('Active_User_API_API_Key');
    	let activeUserAPIAPIKeyTraitValue = activeUserAPIAPIKeyTrait ? activeUserAPIAPIKeyTrait.attributes.value : '';

    	let activeUserAPIMoreHeadersTrait = component.getTrait('Active_User_API_More_Headers');
    	let activeUserAPIMoreHeadersTraitValue = activeUserAPIMoreHeadersTrait ? activeUserAPIMoreHeadersTrait.attributes.value : '';

    	let activeUserAPIUpdateFrequencyP24HTrait = component.getTrait('Active_User_API_Update_Frequency_P24H');
    	let activeUserAPIUpdateFrequencyP24HTraitValue = activeUserAPIUpdateFrequencyP24HTrait ? activeUserAPIUpdateFrequencyP24HTrait.attributes.value : 1;

		let activeUserTrait = component.getTrait('Active_User');
    	let activeUserTraitValue = activeUserTrait ? activeUserTrait.attributes.value : '';

		let activeUserGoalReachedPercentageTrait = component.getTrait('Active_User_Goal_Reached_Percentage');
    	let activeUserGoalReachedPercentageTraitValue = activeUserGoalReachedPercentageTrait ? activeUserGoalReachedPercentageTrait.attributes.value : '';

		let activeUserGoalReachedCountTrait = component.getTrait('Active_User_Goal_Reached_Count');
    	let activeUserGoalReachedCountTraitValue = activeUserGoalReachedCountTrait ? activeUserGoalReachedCountTrait.attributes.value : '';


		let totalTaxAPITrait = component.getTrait('Total_Tax_API');
    	let totalTaxAPITraitValue = totalTaxAPITrait ? totalTaxAPITrait.attributes.value : '';

		let totalTaxAPIUsernameTrait = component.getTrait('Total_Tax_API_Username');
    	let totalTaxAPIUsernameTraitValue = totalTaxAPIUsernameTrait ? totalTaxAPIUsernameTrait.attributes.value : '';

		let totalTaxAPIPasswordTrait = component.getTrait('Total_Tax_API_Password');
    	let totalTaxAPIPasswordTraitValue = totalTaxAPIPasswordTrait ? totalTaxAPIPasswordTrait.attributes.value : '';

		let totalTaxAPIAPIKeyrait = component.getTrait('Total_Tax_API_API_Key');
    	let totalTaxAPIAPIKeyraitValue = totalTaxAPIAPIKeyrait ? totalTaxAPIAPIKeyrait.attributes.value : '';

		let totalTaxAPIMoreHeadersTrait = component.getTrait('Total_Tax_API_More_Headers');
    	let totalTaxAPIMoreHeadersTraitValue = totalTaxAPIMoreHeadersTrait ? totalTaxAPIMoreHeadersTrait.attributes.value : '';

		let totalTaxAPIUpdateFrequencyP24HTrait = component.getTrait('Total_Tax_API_Update_Frequency_P24H');
    	let totalTaxAPIUpdateFrequencyP24HTraitValue = totalTaxAPIUpdateFrequencyP24HTrait ? totalTaxAPIUpdateFrequencyP24HTrait.attributes.value : 1;

		let totalTaxTrait = component.getTrait('Total_Tax');
    	let totalTaxTraitValue = totalTaxTrait ? totalTaxTrait.attributes.value : '';

		let taxGoalReachedPercentageTrait = component.getTrait('Tax_Goal_Reached_Percentage');
    	let taxGoalReachedPercentageTraitValue = taxGoalReachedPercentageTrait ? taxGoalReachedPercentageTrait.attributes.value : '';

		let taxGoalReachedCountTrait = component.getTrait('Tax_Goal_Reached_Count');
    	let taxGoalReachedCountTraitValue = taxGoalReachedCountTrait ? taxGoalReachedCountTrait.attributes.value : '';


    	// TABLE 1 SECTION (first table in the template) -
    	let table1APITrait = component.getTrait('Table1_API');
    	let table1APITraitValue = table1APITrait ? table1APITrait.attributes.value : '';

		let table1APIUsernameTrait = component.getTrait('Table1_API_Username');
    	let table1APIUsernameTraitValue = table1APIUsernameTrait ? table1APIUsernameTrait.attributes.value : '';

		let table1APIPasswordTrait = component.getTrait('Table1_API_Password');
    	let table1APIPasswordTraitValue = table1APIPasswordTrait ? table1APIPasswordTrait.attributes.value : '';

		let table1APIAPIKeyTrait = component.getTrait('Table1_API_API_Key');
    	let table1APIAPIKeyTraitValue = table1APIAPIKeyTrait ? table1APIAPIKeyTrait.attributes.value : '';

		let table1APIMoreHeadersTrait = component.getTrait('Table1_API_More_Headers');
    	let table1APIMoreHeadersTraitValue = table1APIMoreHeadersTrait ? table1APIMoreHeadersTrait.attributes.value : '';

		let table1APIUpdateFrequencyP24HTrait = component.getTrait('Table1_API_Update_Frequency_P24H');
    	let table1APIUpdateFrequencyP24HTraitValue = table1APIUpdateFrequencyP24HTrait ? table1APIUpdateFrequencyP24HTrait.attributes.value : 1;

		let table1KeynameTrait = component.getTrait('Table1_Keyname');
    	let table1KeynameTraitValue = table1KeynameTrait ? table1KeynameTrait.attributes.value : '';


    	// TABLE 2 SECTION (second table in the template) -
    	let table2APITrait = component.getTrait('Table2_API');
    	let table2APITraitValue = table2APITrait ? table2APITrait.attributes.value : '';

		let table2APIUsernameTrait = component.getTrait('Table2_API_Username');
    	let table2APIUsernameTraitValue = table2APIUsernameTrait ? table2APIUsernameTrait.attributes.value : '';

		let table2APIPasswordTrait = component.getTrait('Table2_API_Password');
    	let table2APIPasswordTraitValue = table2APIPasswordTrait ? table2APIPasswordTrait.attributes.value : '';

		let table2APIAPIKeyTrait = component.getTrait('Table2_API_API_Key');
    	let table2APIAPIKeyTraitValue = table2APIAPIKeyTrait ? table2APIAPIKeyTrait.attributes.value : '';

		let table2APIMoreHeadersTrait = component.getTrait('Table2_API_More_Headers');
    	let table2APIMoreHeadersTraitValue = table2APIMoreHeadersTrait ? table2APIMoreHeadersTrait.attributes.value : '';

		let table2APIUpdateFrequencyP24HTrait = component.getTrait('Table2_API_Update_Frequency_P24H');
    	let table2APIUpdateFrequencyP24HTraitValue = table2APIUpdateFrequencyP24HTrait ? table2APIUpdateFrequencyP24HTrait.attributes.value : 1;

		let table2KeynameTrait = component.getTrait('Table2_Keyname');
    	let table2KeynameTraitValue = table2KeynameTrait ? table2KeynameTrait.attributes.value : '';


    	// TABLE 3 SECTION (third table in the template) -
    	let table3APITrait = component.getTrait('Table3_API');
    	let table3APITraitValue = table3APITrait ? table3APITrait.attributes.value : '';

		let table3APIUsernameTrait = component.getTrait('Table3_API_Username');
    	let table3APIUsernameTraitValue = table3APIUsernameTrait ? table3APIUsernameTrait.attributes.value : '';

		let table3APIPasswordTrait = component.getTrait('Table3_API_Password');
    	let table3APIPasswordTraitValue = table3APIPasswordTrait ? table3APIPasswordTrait.attributes.value : '';

		let table3APIAPIKeyTrait = component.getTrait('Table3_API_API_Key');
    	let table3APIAPIKeyTraitValue = table3APIAPIKeyTrait ? table3APIAPIKeyTrait.attributes.value : '';

		let table3APIMoreHeadersTrait = component.getTrait('Table3_API_More_Headers');
    	let table3APIMoreHeadersTraitValue = table3APIMoreHeadersTrait ? table3APIMoreHeadersTrait.attributes.value : '';

		let table3APIUpdateFrequencyP24HTrait = component.getTrait('Table3_API_Update_Frequency_P24H');
    	let table3APIUpdateFrequencyP24HTraitValue = table3APIUpdateFrequencyP24HTrait ? table3APIUpdateFrequencyP24HTrait.attributes.value : 1;

		let table3KeynameTrait = component.getTrait('Table3_Keyname');
    	let table3KeynameTraitValue = table3KeynameTrait ? table3KeynameTrait.attributes.value : '';


    	// TOTAL INVESTMENT SECTION -
    	let totalInvestmentAPITrait = component.getTrait('Total_Investment_API');
    	let totalInvestmentAPITraitValue = totalInvestmentAPITrait ? totalInvestmentAPITrait.attributes.value : ''; // Check if the trait exists

		let totalInvestmentAPIUsernameTrait = component.getTrait('Total_Investment_API_Username');
    	let totalInvestmentAPIUsernameTraitValue = totalInvestmentAPIUsernameTrait ? totalInvestmentAPIUsernameTrait.attributes.value : '';

		let totalInvestmentAPIPasswordTrait = component.getTrait('Total_Investment_API_Password');
    	let totalInvestmentAPIPasswordTraitValue = totalInvestmentAPIPasswordTrait ? totalInvestmentAPIPasswordTrait.attributes.value : '';

		let totalInvestmentAPIAPIKeyTrait = component.getTrait('Total_Investment_API_API_Key');
    	let totalInvestmentAPIAPIKeyTraitValue = totalInvestmentAPIAPIKeyTrait ? totalInvestmentAPIAPIKeyTrait.attributes.value : '';

		let totalInvestmentAPIMoreHeadersTrait = component.getTrait('Total_Investment_API_More_Headers');
    	let totalInvestmentAPIMoreHeadersTraitValue = totalInvestmentAPIMoreHeadersTrait ? totalInvestmentAPIMoreHeadersTrait.attributes.value : '';

		let totalInvestmentAPIUpdateFrequencyP24HTrait = component.getTrait('Total_Investment_API_Update_Frequency_P24H');
    	let totalInvestmentAPIUpdateFrequencyP24HTraitValue = totalInvestmentAPIUpdateFrequencyP24HTrait ? totalInvestmentAPIUpdateFrequencyP24HTrait.attributes.value : 1;

		let totalResponseKeynameTrait = component.getTrait('Total_Response_Keyname');
    	let totalResponseKeynameTraitValue = totalResponseKeynameTrait ? totalResponseKeynameTrait.attributes.value : '';

		let monthlyResponseKeynameTrait = component.getTrait('Monthly_Response_Keyname');
    	let monthlyResponseKeynameTraitValue = monthlyResponseKeynameTrait ? monthlyResponseKeynameTrait.attributes.value : '';

		let dailyResponseKeynameTrait = component.getTrait('Daily_Response_Keyname');
    	let dailyResponseKeynameTraitValue = dailyResponseKeynameTrait ? dailyResponseKeynameTrait.attributes.value : '';

    	storedProps = {
    		Todays_API: todaysApiTraitValue,
    		Todays_API_Username: todaysApiUsernameTraitValue,
    		Todays_API_Password: todaysApiPasswordTraitValue,
    		Todays_API_API_Key: todaysApiApiKeyTraitValue,
    		Todays_API_More_Headers: todaysApiMoreHeadersTraitValue,
    		Todays_API_Update_Frequency_P24H: todaysAPIUpdateFrequencyP24HTraitValue,
    		Date_Response_Keyname: dataResponseKeynameTraitValue,
    		Todays_Sale_Response_Keyname: todaysSaleResponseKeynameTraitValue,
    		Todays_Earnings_Response_Keyname: todaysEarningsResponseKeynameTraitValue,


    		Total_Sale_Last_Year_API: totalSaleLastYearAPITraitValue,
    		Total_Sale_Last_Year_API_Username: totalSaleLastYearAPIUsernameTraitValue,
    		Total_Sale_Last_Year_API_Password: totalSaleLastYearAPIPasswordTraitValue,
    		Total_Sale_Last_Year_API_API_Key: totalSaleLastYearAPIAPIKeyTraitValue,
    		Total_Sale_Last_Year_API_More_Headers: totalSaleLastYearAPIMoreHeadersTraitValue,
    		Total_Sale_Last_Year_API_Update_Frequency_P24H: totalSaleLastYearAPIUpdateFrequencyP24HTraitValue,
    		Total_Sale_Last_Year_Percentage: totalSaleLastYearPercentageTraitValue,
    		Total_Sale_Last_Year_Amount: totalSaleLastYearAmountTraitValue,


    		Active_Customer_Last_Day_API: activeCustomerLastDayAPITraitValue,
    		Active_Customer_Last_Day_API_Username: activeCustomerLastDayAPIUsernameTraitValue,
    		Active_Customer_Last_Day_API_Password: activeCustomerLastDayAPIPasswordTraitValue,
    		Active_Customer_Last_Day_API_API_Key: activeCustomerLastDayAPIAPIKeyTraitValue,
    		Active_Customer_Last_Day_API_More_Headers: activeCustomerLastDayAPIMoreHeadersTraitValue,
    		Active_Customer_Last_Day_API_Update_Frequency_P24H: activeCustomerLastDayAPIUpdateFrequencyP24HTraitValue,
    		Active_Customer_Last_Day_Percentage: activeCustomerLastDayPercentageTraitValue,
    		Active_Customer_Last_Day_Amount: activeCustomerLastDayAmountTraitValue,


    		Total_Sales_API: totalSalesAPITraitValue,
    		Total_Sales_API_Username: totalSalesAPIUsernameTraitValue,
    		Total_Sales_API_Password: totalSalesAPIPasswordTraitValue,
    		Total_Sales_API_API_Key: totalSalesAPIAPIKeyTraitValue,
    		Total_Sales_API_More_Headers: totalSalesAPIMoreHeadersTraitValue,
    		Total_Sales_API_Update_Frequency_P24H: totalSalesAPIUpdateFrequencyP24HTraitValue,
    		Total_Sales: totalSalesTraitValue,
    		Sales_Goal_Reached_Percentage: salesGoalReachedPercentageTraitValue,
    		Sales_Goal_Reached_Count: salesGoalReachedCountTraitValue,


    		Total_Income_API: totalIncomeAPITraitValue,
    		Total_Income_API_Username: totalIncomeAPIUsernameTraitValue,
    		Total_Income_API_Password: totalIncomeAPIPasswordTraitValue,
    		Total_Income_API_API_Key: totalIncomeAPIAPIKeyTraitValue,
    		Total_Income_API_More_Headers: totalIncomeAPIMoreHeadersTraitValue,        		
    		Total_Income_API_Update_Frequency_P24H: totalIncomeAPIUpdateFrequencyP24HValue,        		
    		Total_Income: totalIncomeTraitValue,
    		Income_Goal_Reached_Percentage: incomeGoalReachedPercentageTraitValue,
    		Income_Goal_Reached_Count: incomeGoalReachedCountTraitValue,
    		

    		Active_User_API: activeUserAPITraitValue,
    		Active_User_API_Username: activeUserAPIUsernameTraitValue,
    		Active_User_API_Password: activeUserAPIPasswordTraitValue,
    		Active_User_API_API_Key: activeUserAPIAPIKeyTraitValue,
    		Active_User_API_More_Headers: activeUserAPIMoreHeadersTraitValue,
    		Active_User_API_Update_Frequency_P24H: activeUserAPIUpdateFrequencyP24HTraitValue,
    		Active_User: activeUserTraitValue,
    		Active_User_Goal_Reached_Percentage: activeUserGoalReachedPercentageTraitValue,
    		Active_User_Goal_Reached_Count: activeUserGoalReachedCountTraitValue,
    		

    		Total_Tax_API: totalTaxAPITraitValue,
    		Total_Tax_API_Username: totalTaxAPIUsernameTraitValue,
    		Total_Tax_API_Password: totalTaxAPIPasswordTraitValue,
    		Total_Tax_API_API_Key: totalTaxAPIAPIKeyraitValue,
    		Total_Tax_API_More_Headers: totalTaxAPIMoreHeadersTraitValue,
    		Total_Tax_API_Update_Frequency_P24H: totalTaxAPIUpdateFrequencyP24HTraitValue,
    		Total_Tax: totalTaxTraitValue,
    		Tax_Goal_Reached_Percentage: taxGoalReachedPercentageTraitValue,
    		Tax_Goal_Reached_Count: taxGoalReachedCountTraitValue,
    		

    		Table1_API: table1APITraitValue,
    		Table1_API_Username: table1APIUsernameTraitValue,
    		Table1_API_Password: table1APIPasswordTraitValue,
    		Table1_API_API_Key: table1APIAPIKeyTraitValue,
    		Table1_API_More_Headers: table1APIMoreHeadersTraitValue,
    		Table1_API_Update_Frequency_P24H: table1APIUpdateFrequencyP24HTraitValue,
    		Table1_Keyname: table1KeynameTraitValue,
    		

    		Table2_API: table2APITraitValue,
    		Table2_API_Username: table2APIUsernameTraitValue,
    		Table2_API_Password: table2APIPasswordTraitValue,
    		Table2_API_API_Key: table2APIAPIKeyTraitValue,
    		Table2_API_More_Headers: table2APIMoreHeadersTraitValue,
    		Table2_API_Update_Frequency_P24H: table2APIUpdateFrequencyP24HTraitValue,
    		Table2_Keyname: table2KeynameTraitValue,
    		

    		Table3_API: table3APITraitValue,
    		Table3_API_Username: table3APIUsernameTraitValue,
    		Table3_API_Password: table3APIPasswordTraitValue,
    		Table3_API_API_Key: table3APIAPIKeyTraitValue,
    		Table3_API_More_Headers: table3APIMoreHeadersTraitValue,
    		Table3_API_Update_Frequency_P24H: table3APIUpdateFrequencyP24HTraitValue,
    		Table3_Keyname: table3KeynameTraitValue,


			Total_Investment_API: totalInvestmentAPITraitValue,
			Total_Investment_API_Username: totalInvestmentAPIUsernameTraitValue,
			Total_Investment_API_Password: totalInvestmentAPIPasswordTraitValue,
			Total_Investment_API_API_Key: totalInvestmentAPIAPIKeyTraitValue,
			Total_Investment_API_More_Headers: totalInvestmentAPIMoreHeadersTraitValue,
			Total_Investment_API_Update_Frequency_P24H: totalInvestmentAPIUpdateFrequencyP24HTraitValue,
			Total_Response_Keyname: totalResponseKeynameTraitValue,
			Monthly_Response_Keyname: monthlyResponseKeynameTraitValue,
			Daily_Response_Keyname: dailyResponseKeynameTraitValue,
    	};
	}

	// Use the 'component:mount' event to run your script initially
    editor.on('component:mount', async (component) => {
        // Check if the added component matches your component type
        if (component.get('type') === 'business-dashboard-template') {
        	// accessing trait values

        	// WELCOME CARD SECTION -
        	// let todaysApiTrait = component.getTrait('Todays_API');
        	// let todaysApiTraitValue = todaysApiTrait ? todaysApiTrait.attributes.value : ''; // Check if the trait exists

			// let todaysApiUsernameTrait = component.getTrait('Todays_API_Username');
        	// let todaysApiUsernameTraitValue = todaysApiUsernameTrait ? todaysApiUsernameTrait.attributes.value : '';

			// let todaysApiPasswordTrait = component.getTrait('Todays_API_Password');
        	// let todaysApiPasswordTraitValue = todaysApiPasswordTrait ? todaysApiPasswordTrait.attributes.value : '';

			// let todaysApiApiKeyTrait = component.getTrait('Todays_API_API_Key');
        	// let todaysApiApiKeyTraitValue =todaysApiApiKeyTrait ? todaysApiApiKeyTrait.attributes.value : '';

			// let todaysApiMoreHeadersTrait = component.getTrait('Todays_API_More_Headers');
        	// let todaysApiMoreHeadersTraitValue = todaysApiMoreHeadersTrait ? todaysApiMoreHeadersTrait.attributes.value : '';


			// let dataResponseKeynameTrait = component.getTrait('Date_Response_Keyname');
        	// let dataResponseKeynameTraitValue = dataResponseKeynameTrait ? dataResponseKeynameTrait.attributes.value : '';

			// let todaysSaleResponseKeynameTrait = component.getTrait('Todays_Sale_Response_Keyname');
        	// let todaysSaleResponseKeynameTraitValue = todaysSaleResponseKeynameTrait ? todaysSaleResponseKeynameTrait.attributes.value : '';

			// let todaysEarningsResponseKeynameTrait = component.getTrait('Todays_Earnings_Response_Keyname');
        	// let todaysEarningsResponseKeynameTraitValue = todaysEarningsResponseKeynameTrait ? todaysEarningsResponseKeynameTrait.attributes.value : '';


      		// // TOTAL-SALE-WIDGET SECTION -
      		// let totalSaleLastYearAPITrait = component.getTrait('Total_Sale_Last_Year_API');
        	// let totalSaleLastYearAPITraitValue = totalSaleLastYearAPITrait ? totalSaleLastYearAPITrait.attributes.value : '';

      		// let totalSaleLastYearAPIUsernameTrait = component.getTrait('Total_Sale_Last_Year_API_Username');
        	// let totalSaleLastYearAPIUsernameTraitValue = totalSaleLastYearAPIUsernameTrait ? totalSaleLastYearAPIUsernameTrait.attributes.value : '';

      		// let totalSaleLastYearAPIPasswordTrait = component.getTrait('Total_Sale_Last_Year_API_Password');
        	// let totalSaleLastYearAPIPasswordTraitValue = totalSaleLastYearAPIPasswordTrait ? totalSaleLastYearAPIPasswordTrait.attributes.value : '';

      		// let totalSaleLastYearAPIAPIKeyTrait = component.getTrait('Total_Sale_Last_Year_API_API_Key');
        	// let totalSaleLastYearAPIAPIKeyTraitValue = totalSaleLastYearAPIAPIKeyTrait ? totalSaleLastYearAPIAPIKeyTrait.attributes.value : '';

      		// let totalSaleLastYearAPIMoreHeadersTrait = component.getTrait('Total_Sale_Last_Year_API_More_Headers');
        	// let totalSaleLastYearAPIMoreHeadersTraitValue = totalSaleLastYearAPIMoreHeadersTrait ? totalSaleLastYearAPIMoreHeadersTrait.attributes.value : '';

      		// let totalSaleLastYearPercentageTrait = component.getTrait('Total_Sale_Last_Year_Percentage');
        	// let totalSaleLastYearPercentageTraitValue = totalSaleLastYearPercentageTrait ? totalSaleLastYearPercentageTrait.attributes.value : '';

      		// let totalSaleLastYearAmountTrait = component.getTrait('Total_Sale_Last_Year_Amount');
        	// let totalSaleLastYearAmountTraitValue = totalSaleLastYearAmountTrait ? totalSaleLastYearAmountTrait.attributes.value : '';


      		// let activeCustomerLastDayAPITrait = component.getTrait('Active_Customer_Last_Day_API');
        	// let activeCustomerLastDayAPITraitValue = activeCustomerLastDayAPITrait ? activeCustomerLastDayAPITrait.attributes.value : '';

      		// let activeCustomerLastDayAPIUsernameTrait = component.getTrait('Active_Customer_Last_Day_API_Username');
        	// let activeCustomerLastDayAPIUsernameTraitValue = activeCustomerLastDayAPIUsernameTrait ? activeCustomerLastDayAPIUsernameTrait.attributes.value : '';

      		// let activeCustomerLastDayAPIPasswordTrait = component.getTrait('Active_Customer_Last_Day_API_Password');
        	// let activeCustomerLastDayAPIPasswordTraitValue = activeCustomerLastDayAPIPasswordTrait ? activeCustomerLastDayAPIPasswordTrait.attributes.value : '';

      		// let activeCustomerLastDayAPIAPIKeyTrait = component.getTrait('Active_Customer_Last_Day_API_API_Key');
        	// let activeCustomerLastDayAPIAPIKeyTraitValue = activeCustomerLastDayAPIAPIKeyTrait ? activeCustomerLastDayAPIAPIKeyTrait.attributes.value : '';

      		// let activeCustomerLastDayAPIMoreHeadersTrait = component.getTrait('Active_Customer_Last_Day_API_More_Headers');
        	// let activeCustomerLastDayAPIMoreHeadersTraitValue = activeCustomerLastDayAPIMoreHeadersTrait ? activeCustomerLastDayAPIMoreHeadersTrait.attributes.value : '';

      		// let activeCustomerLastDayPercentageTrait = component.getTrait('Active_Customer_Last_Day_Percentage');
        	// let activeCustomerLastDayPercentageTraitValue = activeCustomerLastDayPercentageTrait ? activeCustomerLastDayPercentageTrait.attributes.value : '';

      		// let activeCustomerLastDayAmountTrait = component.getTrait('Active_Customer_Last_Day_Amount');
        	// let activeCustomerLastDayAmountTraitValue = activeCustomerLastDayAmountTrait ? activeCustomerLastDayAmountTrait.attributes.value : '';


        	// // SUPPORT-TICKET-FONT SECTION -
        	// let totalSalesAPITrait = component.getTrait('Total_Sales_API');
        	// let totalSalesAPITraitValue = totalSalesAPITrait ? totalSalesAPITrait.attributes.value : '';

        	// let totalSalesAPIUsernameTrait = component.getTrait('Total_Sales_API_Username');
        	// let totalSalesAPIUsernameTraitValue = totalSalesAPIUsernameTrait ? totalSalesAPIUsernameTrait.attributes.value : '';
        	
        	// let totalSalesAPIPasswordTrait = component.getTrait('Total_Sales_API_Password');
        	// let totalSalesAPIPasswordTraitValue = totalSalesAPIPasswordTrait ? totalSalesAPIPasswordTrait.attributes.value : '';
        	
        	// let totalSalesAPIAPIKeyTrait = component.getTrait('Total_Sales_API_API_Key');
        	// let totalSalesAPIAPIKeyTraitValue = totalSalesAPIAPIKeyTrait ? totalSalesAPIAPIKeyTrait.attributes.value : '';
        	
        	// let totalSalesAPIMoreHeadersTrait = component.getTrait('Total_Sales_API_More_Headers');
        	// let totalSalesAPIMoreHeadersTraitValue = totalSalesAPIMoreHeadersTrait ? totalSalesAPIMoreHeadersTrait.attributes.value : '';

			// let totalSalesTrait = component.getTrait('Total_Sales');
        	// let totalSalesTraitValue = totalSalesTrait ? totalSalesTrait.attributes.value : '';

			// let salesGoalReachedPercentageTrait = component.getTrait('Sales_Goal_Reached_Percentage');
        	// let salesGoalReachedPercentageTraitValue = salesGoalReachedPercentageTrait ? salesGoalReachedPercentageTrait.attributes.value : '';

			// let salesGoalReachedCountTrait = component.getTrait('Sales_Goal_Reached_Count');
        	// let salesGoalReachedCountTraitValue = salesGoalReachedCountTrait ? salesGoalReachedCountTrait.attributes.value : '';


        	// let totalIncomeAPITrait = component.getTrait('Total_Income_API');
        	// let totalIncomeAPITraitValue = totalIncomeAPITrait ? totalIncomeAPITrait.attributes.value : '';

        	// let totalIncomeAPIUsernameTrait = component.getTrait('Total_Income_API_Username');
        	// let totalIncomeAPIUsernameTraitValue = totalIncomeAPIUsernameTrait ? totalIncomeAPIUsernameTrait.attributes.value : '';
        	
        	// let totalIncomeAPIPasswordTrait = component.getTrait('Total_Income_API_Password');
        	// let totalIncomeAPIPasswordTraitValue = totalIncomeAPIPasswordTrait ? totalIncomeAPIPasswordTrait.attributes.value : '';
        	
        	// let totalIncomeAPIAPIKeyTrait = component.getTrait('Total_Income_API_API_Key');
        	// let totalIncomeAPIAPIKeyTraitValue = totalIncomeAPIAPIKeyTrait ? totalIncomeAPIAPIKeyTrait.attributes.value : '';
        	
        	// let totalIncomeAPIMoreHeadersTrait = component.getTrait('Total_Income_API_More_Headers');
        	// let totalIncomeAPIMoreHeadersTraitValue = totalIncomeAPIMoreHeadersTrait ? totalIncomeAPIMoreHeadersTrait.attributes.value : '';

			// let totalIncomeTrait = component.getTrait('Total_Income');
        	// let totalIncomeTraitValue = totalIncomeTrait ? totalIncomeTrait.attributes.value : '';

			// let incomeGoalReachedPercentageTrait = component.getTrait('Income_Goal_Reached_Percentage');
        	// let incomeGoalReachedPercentageTraitValue = incomeGoalReachedPercentageTrait ? incomeGoalReachedPercentageTrait.attributes.value : '';

			// let incomeGoalReachedCountTrait = component.getTrait('Income_Goal_Reached_Count');
        	// let incomeGoalReachedCountTraitValue = incomeGoalReachedCountTrait ? incomeGoalReachedCountTrait.attributes.value : '';


        	// let activeUserAPITrait = component.getTrait('Active_User_API');
        	// let activeUserAPITraitValue = activeUserAPITrait ? activeUserAPITrait.attributes.value : '';

        	// let activeUserAPIUsernameTrait = component.getTrait('Active_User_API_Username');
        	// let activeUserAPIUsernameTraitValue = activeUserAPIUsernameTrait ? activeUserAPIUsernameTrait.attributes.value : '';

        	// let activeUserAPIPasswordTrait = component.getTrait('Active_User_API_Password');
        	// let activeUserAPIPasswordTraitValue = activeUserAPIPasswordTrait ? activeUserAPIPasswordTrait.attributes.value : '';

        	// let activeUserAPIAPIKeyTrait = component.getTrait('Active_User_API_API_Key');
        	// let activeUserAPIAPIKeyTraitValue = activeUserAPIAPIKeyTrait ? activeUserAPIAPIKeyTrait.attributes.value : '';

        	// let activeUserAPIMoreHeadersTrait = component.getTrait('Active_User_API_More_Headers');
        	// let activeUserAPIMoreHeadersTraitValue = activeUserAPIMoreHeadersTrait ? activeUserAPIMoreHeadersTrait.attributes.value : '';

			// let activeUserTrait = component.getTrait('Active_User');
        	// let activeUserTraitValue = activeUserTrait ? activeUserTrait.attributes.value : '';

			// let activeUserGoalReachedPercentageTrait = component.getTrait('Active_User_Goal_Reached_Percentage');
        	// let activeUserGoalReachedPercentageTraitValue = activeUserGoalReachedPercentageTrait ? activeUserGoalReachedPercentageTrait.attributes.value : '';

			// let activeUserGoalReachedCountTrait = component.getTrait('Active_User_Goal_Reached_Count');
        	// let activeUserGoalReachedCountTraitValue = activeUserGoalReachedCountTrait ? activeUserGoalReachedCountTrait.attributes.value : '';


			// let totalTaxAPITrait = component.getTrait('Total_Tax_API');
        	// let totalTaxAPITraitValue = totalTaxAPITrait ? totalTaxAPITrait.attributes.value : '';

			// let totalTaxAPIUsernameTrait = component.getTrait('Total_Tax_API_Username');
        	// let totalTaxAPIUsernameTraitValue = totalTaxAPIUsernameTrait ? totalTaxAPIUsernameTrait.attributes.value : '';

			// let totalTaxAPIPasswordTrait = component.getTrait('Total_Tax_API_Password');
        	// let totalTaxAPIPasswordTraitValue = totalTaxAPIPasswordTrait ? totalTaxAPIPasswordTrait.attributes.value : '';

			// let totalTaxAPIAPIKeyrait = component.getTrait('Total_Tax_API_API_Key');
        	// let totalTaxAPIAPIKeyraitValue = totalTaxAPIAPIKeyrait ? totalTaxAPIAPIKeyrait.attributes.value : '';

			// let totalTaxAPIMoreHeadersTrait = component.getTrait('Total_Tax_API_More_Headers');
        	// let totalTaxAPIMoreHeadersTraitValue = totalTaxAPIMoreHeadersTrait ? totalTaxAPIMoreHeadersTrait.attributes.value : '';

			// let totalTaxTrait = component.getTrait('Total_Tax');
        	// let totalTaxTraitValue = totalTaxTrait ? totalTaxTrait.attributes.value : '';

			// let taxGoalReachedPercentageTrait = component.getTrait('Tax_Goal_Reached_Percentage');
        	// let taxGoalReachedPercentageTraitValue = taxGoalReachedPercentageTrait ? taxGoalReachedPercentageTrait.attributes.value : '';

			// let taxGoalReachedCountTrait = component.getTrait('Tax_Goal_Reached_Count');
        	// let taxGoalReachedCountTraitValue = taxGoalReachedCountTrait ? taxGoalReachedCountTrait.attributes.value : '';


        	// // TABLE 1 SECTION (first table in the template) -
        	// let table1APITrait = component.getTrait('Table1_API');
        	// let table1APITraitValue = table1APITrait ? table1APITrait.attributes.value : '';

			// let table1APIUsernameTrait = component.getTrait('Table1_API_Username');
        	// let table1APIUsernameTraitValue = table1APIUsernameTrait ? table1APIUsernameTrait.attributes.value : '';

			// let table1APIPasswordTrait = component.getTrait('Table1_API_Password');
        	// let table1APIPasswordTraitValue = table1APIPasswordTrait ? table1APIPasswordTrait.attributes.value : '';

			// let table1APIAPIKeyTrait = component.getTrait('Table1_API_API_Key');
        	// let table1APIAPIKeyTraitValue = table1APIAPIKeyTrait ? table1APIAPIKeyTrait.attributes.value : '';

			// let table1APIMoreHeadersTrait = component.getTrait('Table1_API_More_Headers');
        	// let table1APIMoreHeadersTraitValue = table1APIMoreHeadersTrait ? table1APIMoreHeadersTrait.attributes.value : '';

			// let table1KeynameTrait = component.getTrait('Table1_Keyname');
        	// let table1KeynameTraitValue = table1KeynameTrait ? table1KeynameTrait.attributes.value : '';


        	// // TABLE 2 SECTION (second table in the template) -
        	// let table2APITrait = component.getTrait('Table2_API');
        	// let table2APITraitValue = table2APITrait ? table2APITrait.attributes.value : '';

			// let table2APIUsernameTrait = component.getTrait('Table2_API_Username');
        	// let table2APIUsernameTraitValue = table2APIUsernameTrait ? table2APIUsernameTrait.attributes.value : '';

			// let table2APIPasswordTrait = component.getTrait('Table2_API_Password');
        	// let table2APIPasswordTraitValue = table2APIPasswordTrait ? table2APIPasswordTrait.attributes.value : '';

			// let table2APIAPIKeyTrait = component.getTrait('Table2_API_API_Key');
        	// let table2APIAPIKeyTraitValue = table2APIAPIKeyTrait ? table2APIAPIKeyTrait.attributes.value : '';

			// let table2APIMoreHeadersTrait = component.getTrait('Table2_API_More_Headers');
        	// let table2APIMoreHeadersTraitValue = table2APIMoreHeadersTrait ? table2APIMoreHeadersTrait.attributes.value : '';

			// let table2KeynameTrait = component.getTrait('Table2_Keyname');
        	// let table2KeynameTraitValue = table2KeynameTrait ? table2KeynameTrait.attributes.value : '';


        	// // TABLE 3 SECTION (third table in the template) -
        	// let table3APITrait = component.getTrait('Table3_API');
        	// let table3APITraitValue = table3APITrait ? table3APITrait.attributes.value : '';

			// let table3APIUsernameTrait = component.getTrait('Table3_API_Username');
        	// let table3APIUsernameTraitValue = table3APIUsernameTrait ? table3APIUsernameTrait.attributes.value : '';

			// let table3APIPasswordTrait = component.getTrait('Table3_API_Password');
        	// let table3APIPasswordTraitValue = table3APIPasswordTrait ? table3APIPasswordTrait.attributes.value : '';

			// let table3APIAPIKeyTrait = component.getTrait('Table3_API_API_Key');
        	// let table3APIAPIKeyTraitValue = table3APIAPIKeyTrait ? table3APIAPIKeyTrait.attributes.value : '';

			// let table3APIMoreHeadersTrait = component.getTrait('Table3_API_More_Headers');
        	// let table3APIMoreHeadersTraitValue = table3APIMoreHeadersTrait ? table3APIMoreHeadersTrait.attributes.value : '';

			// let table3KeynameTrait = component.getTrait('Table3_Keyname');
        	// let table3KeynameTraitValue = table3KeynameTrait ? table3KeynameTrait.attributes.value : '';


        	// const props = {
        	// 	Todays_API: todaysApiTraitValue,
        	// 	Todays_API_Username: todaysApiUsernameTraitValue,
        	// 	Todays_API_Password: todaysApiPasswordTraitValue,
        	// 	Todays_API_API_Key: todaysApiApiKeyTraitValue,
        	// 	Todays_API_More_Headers: todaysApiMoreHeadersTraitValue,
        	// 	Date_Response_Keyname: dataResponseKeynameTraitValue,
        	// 	Todays_Sale_Response_Keyname: todaysSaleResponseKeynameTraitValue,
        	// 	Todays_Earnings_Response_Keyname: todaysEarningsResponseKeynameTraitValue,


        	// 	Total_Sale_Last_Year_API: totalSaleLastYearAPITraitValue,
        	// 	Total_Sale_Last_Year_API_Username: totalSaleLastYearAPIUsernameTraitValue,
        	// 	Total_Sale_Last_Year_API_Password: totalSaleLastYearAPIPasswordTraitValue,
        	// 	Total_Sale_Last_Year_API_API_Key: totalSaleLastYearAPIAPIKeyTraitValue,
        	// 	Total_Sale_Last_Year_API_More_Headers: totalSaleLastYearAPIMoreHeadersTraitValue,
        	// 	Total_Sale_Last_Year_Percentage: totalSaleLastYearPercentageTraitValue,
        	// 	Total_Sale_Last_Year_Amount: totalSaleLastYearAmountTraitValue,


        	// 	Active_Customer_Last_Day_API: activeCustomerLastDayAPITraitValue,
        	// 	Active_Customer_Last_Day_API_Username: activeCustomerLastDayAPIUsernameTraitValue,
        	// 	Active_Customer_Last_Day_API_Password: activeCustomerLastDayAPIPasswordTraitValue,
        	// 	Active_Customer_Last_Day_API_API_Key: activeCustomerLastDayAPIAPIKeyTraitValue,
        	// 	Active_Customer_Last_Day_API_More_Headers: activeCustomerLastDayAPIMoreHeadersTraitValue,
        	// 	Active_Customer_Last_Day_Percentage: activeCustomerLastDayPercentageTraitValue,
        	// 	Active_Customer_Last_Day_Amount: activeCustomerLastDayAmountTraitValue,


        	// 	Total_Sales_API: totalSalesAPITraitValue,
        	// 	Total_Sales_API_Username: totalSalesAPIUsernameTraitValue,
        	// 	Total_Sales_API_Password: totalSalesAPIPasswordTraitValue,
        	// 	Total_Sales_API_API_Key: totalSalesAPIAPIKeyTraitValue,
        	// 	Total_Sales_API_More_Headers: totalSalesAPIMoreHeadersTraitValue,
        	// 	Total_Sales: totalSalesTraitValue,
        	// 	Sales_Goal_Reached_Percentage: salesGoalReachedPercentageTraitValue,
        	// 	Sales_Goal_Reached_Count: salesGoalReachedCountTraitValue,


        	// 	Total_Income_API: totalIncomeAPITraitValue,
        	// 	Total_Income_API_Username: totalIncomeAPIUsernameTraitValue,
        	// 	Total_Income_API_Password: totalIncomeAPIPasswordTraitValue,
        	// 	Total_Income_API_API_Key: totalIncomeAPIAPIKeyTraitValue,
        	// 	Total_Income_API_More_Headers: totalIncomeAPIMoreHeadersTraitValue,        		
        	// 	Total_Income: totalIncomeTraitValue,
        	// 	Income_Goal_Reached_Percentage: incomeGoalReachedPercentageTraitValue,
        	// 	Income_Goal_Reached_Count: incomeGoalReachedCountTraitValue,
        		

        	// 	Active_User_API: activeUserAPITraitValue,
        	// 	Active_User_API_Username: activeUserAPIUsernameTraitValue,
        	// 	Active_User_API_Password: activeUserAPIPasswordTraitValue,
        	// 	Active_User_API_API_Key: activeUserAPIAPIKeyTraitValue,
        	// 	Active_User_API_More_Headers: activeUserAPIMoreHeadersTraitValue,
        	// 	Active_User: activeUserTraitValue,
        	// 	Active_User_Goal_Reached_Percentage: activeUserGoalReachedPercentageTraitValue,
        	// 	Active_User_Goal_Reached_Count: activeUserGoalReachedCountTraitValue,
        		

        	// 	Total_Tax_API: totalTaxAPITraitValue,
        	// 	Total_Tax_API_Username: totalTaxAPIUsernameTraitValue,
        	// 	Total_Tax_API_Password: totalTaxAPIPasswordTraitValue,
        	// 	Total_Tax_API_API_Key: totalTaxAPIAPIKeyraitValue,
        	// 	Total_Tax_API_More_Headers: totalTaxAPIMoreHeadersTraitValue,
        	// 	Total_Tax: totalTaxTraitValue,
        	// 	Tax_Goal_Reached_Percentage: taxGoalReachedPercentageTraitValue,
        	// 	Tax_Goal_Reached_Count: taxGoalReachedCountTraitValue,
        		

        	// 	Table1_API: table1APITraitValue,
        	// 	Table1_API_Username: table1APIUsernameTraitValue,
        	// 	Table1_API_Password: table1APIPasswordTraitValue,
        	// 	Table1_API_API_Key: table1APIAPIKeyTraitValue,
        	// 	Table1_API_More_Headers: table1APIMoreHeadersTraitValue,
        	// 	Table1_Keyname: table1KeynameTraitValue,
        		

        	// 	Table2_API: table2APITraitValue,
        	// 	Table2_API_Username: table2APIUsernameTraitValue,
        	// 	Table2_API_Password: table2APIPasswordTraitValue,
        	// 	Table2_API_API_Key: table2APIAPIKeyTraitValue,
        	// 	Table2_API_More_Headers: table2APIMoreHeadersTraitValue,
        	// 	Table2_Keyname: table2KeynameTraitValue,
        		

        	// 	Table3_API: table3APITraitValue,
        	// 	Table3_API_Username: table3APIUsernameTraitValue,
        	// 	Table3_API_Password: table3APIPasswordTraitValue,
        	// 	Table3_API_API_Key: table3APIAPIKeyTraitValue,
        	// 	Table3_API_More_Headers: table3APIMoreHeadersTraitValue,
        	// 	Table3_Keyname: table3KeynameTraitValue,
        	// };

        	// Call the function to update props
        	updateProps(component);

            // Run your script logic when your component is added
            await businessDashboardFunctionality(component, storedProps);
        }
    });

    // Use the 'component:update' event to run your script when traits (props) change
    editor.on('component:update', async (component) => {
        // Check if the added component matches your component type
        if (component.get('type') === 'business-dashboard-template') {
        	// accessing trait values

        	// WELCOME CARD SECTION -
        	// let todaysApiTrait = component.getTrait('Todays_API');
        	// let todaysApiTraitValue = todaysApiTrait ? todaysApiTrait.attributes.value : ''; // Check if the trait exists

			// let todaysApiUsernameTrait = component.getTrait('Todays_API_Username');
        	// let todaysApiUsernameTraitValue = todaysApiUsernameTrait ? todaysApiUsernameTrait.attributes.value : '';

			// let todaysApiPasswordTrait = component.getTrait('Todays_API_Password');
        	// let todaysApiPasswordTraitValue = todaysApiPasswordTrait ? todaysApiPasswordTrait.attributes.value : '';

			// let todaysApiApiKeyTrait = component.getTrait('Todays_API_API_Key');
        	// let todaysApiApiKeyTraitValue =todaysApiApiKeyTrait ? todaysApiApiKeyTrait.attributes.value : '';

			// let todaysApiMoreHeadersTrait = component.getTrait('Todays_API_More_Headers');
        	// let todaysApiMoreHeadersTraitValue = todaysApiMoreHeadersTrait ? todaysApiMoreHeadersTrait.attributes.value : '';

			// let dataResponseKeynameTrait = component.getTrait('Date_Response_Keyname');
        	// let dataResponseKeynameTraitValue = dataResponseKeynameTrait ? dataResponseKeynameTrait.attributes.value : '';

			// let todaysSaleResponseKeynameTrait = component.getTrait('Todays_Sale_Response_Keyname');
        	// let todaysSaleResponseKeynameTraitValue = todaysSaleResponseKeynameTrait ? todaysSaleResponseKeynameTrait.attributes.value : '';

			// let todaysEarningsResponseKeynameTrait = component.getTrait('Todays_Earnings_Response_Keyname');
        	// let todaysEarningsResponseKeynameTraitValue = todaysEarningsResponseKeynameTrait ? todaysEarningsResponseKeynameTrait.attributes.value : '';


      		// // TOTAL-SALE-WIDGET SECTION -
      		// let totalSaleLastYearAPITrait = component.getTrait('Total_Sale_Last_Year_API');
        	// let totalSaleLastYearAPITraitValue = totalSaleLastYearAPITrait ? totalSaleLastYearAPITrait.attributes.value : '';

      		// let totalSaleLastYearAPIUsernameTrait = component.getTrait('Total_Sale_Last_Year_API_Username');
        	// let totalSaleLastYearAPIUsernameTraitValue = totalSaleLastYearAPIUsernameTrait ? totalSaleLastYearAPIUsernameTrait.attributes.value : '';

      		// let totalSaleLastYearAPIPasswordTrait = component.getTrait('Total_Sale_Last_Year_API_Password');
        	// let totalSaleLastYearAPIPasswordTraitValue = totalSaleLastYearAPIPasswordTrait ? totalSaleLastYearAPIPasswordTrait.attributes.value : '';

      		// let totalSaleLastYearAPIAPIKeyTrait = component.getTrait('Total_Sale_Last_Year_API_API_Key');
        	// let totalSaleLastYearAPIAPIKeyTraitValue = totalSaleLastYearAPIAPIKeyTrait ? totalSaleLastYearAPIAPIKeyTrait.attributes.value : '';

      		// let totalSaleLastYearAPIMoreHeadersTrait = component.getTrait('Total_Sale_Last_Year_API_More_Headers');
        	// let totalSaleLastYearAPIMoreHeadersTraitValue = totalSaleLastYearAPIMoreHeadersTrait ? totalSaleLastYearAPIMoreHeadersTrait.attributes.value : '';

      		// let totalSaleLastYearPercentageTrait = component.getTrait('Total_Sale_Last_Year_Percentage');
        	// let totalSaleLastYearPercentageTraitValue = totalSaleLastYearPercentageTrait ? totalSaleLastYearPercentageTrait.attributes.value : '';

      		// let totalSaleLastYearAmountTrait = component.getTrait('Total_Sale_Last_Year_Amount');
        	// let totalSaleLastYearAmountTraitValue = totalSaleLastYearAmountTrait ? totalSaleLastYearAmountTrait.attributes.value : '';


      		// let activeCustomerLastDayAPITrait = component.getTrait('Active_Customer_Last_Day_API');
        	// let activeCustomerLastDayAPITraitValue = activeCustomerLastDayAPITrait ? activeCustomerLastDayAPITrait.attributes.value : '';

      		// let activeCustomerLastDayAPIUsernameTrait = component.getTrait('Active_Customer_Last_Day_API_Username');
        	// let activeCustomerLastDayAPIUsernameTraitValue = activeCustomerLastDayAPIUsernameTrait ? activeCustomerLastDayAPIUsernameTrait.attributes.value : '';

      		// let activeCustomerLastDayAPIPasswordTrait = component.getTrait('Active_Customer_Last_Day_API_Password');
        	// let activeCustomerLastDayAPIPasswordTraitValue = activeCustomerLastDayAPIPasswordTrait ? activeCustomerLastDayAPIPasswordTrait.attributes.value : '';

      		// let activeCustomerLastDayAPIAPIKeyTrait = component.getTrait('Active_Customer_Last_Day_API_API_Key');
        	// let activeCustomerLastDayAPIAPIKeyTraitValue = activeCustomerLastDayAPIAPIKeyTrait ? activeCustomerLastDayAPIAPIKeyTrait.attributes.value : '';

      		// let activeCustomerLastDayAPIMoreHeadersTrait = component.getTrait('Active_Customer_Last_Day_API_More_Headers');
        	// let activeCustomerLastDayAPIMoreHeadersTraitValue = activeCustomerLastDayAPIMoreHeadersTrait ? activeCustomerLastDayAPIMoreHeadersTrait.attributes.value : '';

      		// let activeCustomerLastDayPercentageTrait = component.getTrait('Active_Customer_Last_Day_Percentage');
        	// let activeCustomerLastDayPercentageTraitValue = activeCustomerLastDayPercentageTrait ? activeCustomerLastDayPercentageTrait.attributes.value : '';

      		// let activeCustomerLastDayAmountTrait = component.getTrait('Active_Customer_Last_Day_Amount');
        	// let activeCustomerLastDayAmountTraitValue = activeCustomerLastDayAmountTrait ? activeCustomerLastDayAmountTrait.attributes.value : '';


        	// // SUPPORT-TICKET-FONT SECTION -
        	// let totalSalesAPITrait = component.getTrait('Total_Sales_API');
        	// let totalSalesAPITraitValue = totalSalesAPITrait ? totalSalesAPITrait.attributes.value : '';

        	// let totalSalesAPIUsernameTrait = component.getTrait('Total_Sales_API_Username');
        	// let totalSalesAPIUsernameTraitValue = totalSalesAPIUsernameTrait ? totalSalesAPIUsernameTrait.attributes.value : '';
        	
        	// let totalSalesAPIPasswordTrait = component.getTrait('Total_Sales_API_Password');
        	// let totalSalesAPIPasswordTraitValue = totalSalesAPIPasswordTrait ? totalSalesAPIPasswordTrait.attributes.value : '';
        	
        	// let totalSalesAPIAPIKeyTrait = component.getTrait('Total_Sales_API_API_Key');
        	// let totalSalesAPIAPIKeyTraitValue = totalSalesAPIAPIKeyTrait ? totalSalesAPIAPIKeyTrait.attributes.value : '';
        	
        	// let totalSalesAPIMoreHeadersTrait = component.getTrait('Total_Sales_API_More_Headers');
        	// let totalSalesAPIMoreHeadersTraitValue = totalSalesAPIMoreHeadersTrait ? totalSalesAPIMoreHeadersTrait.attributes.value : '';

			// let totalSalesTrait = component.getTrait('Total_Sales');
        	// let totalSalesTraitValue = totalSalesTrait ? totalSalesTrait.attributes.value : '';

			// let salesGoalReachedPercentageTrait = component.getTrait('Sales_Goal_Reached_Percentage');
        	// let salesGoalReachedPercentageTraitValue = salesGoalReachedPercentageTrait ? salesGoalReachedPercentageTrait.attributes.value : '';

			// let salesGoalReachedCountTrait = component.getTrait('Sales_Goal_Reached_Count');
        	// let salesGoalReachedCountTraitValue = salesGoalReachedCountTrait ? salesGoalReachedCountTrait.attributes.value : '';


        	// let totalIncomeAPITrait = component.getTrait('Total_Income_API');
        	// let totalIncomeAPITraitValue = totalIncomeAPITrait ? totalIncomeAPITrait.attributes.value : '';

        	// let totalIncomeAPIUsernameTrait = component.getTrait('Total_Income_API_Username');
        	// let totalIncomeAPIUsernameTraitValue = totalIncomeAPIUsernameTrait ? totalIncomeAPIUsernameTrait.attributes.value : '';
        	
        	// let totalIncomeAPIPasswordTrait = component.getTrait('Total_Income_API_Password');
        	// let totalIncomeAPIPasswordTraitValue = totalIncomeAPIPasswordTrait ? totalIncomeAPIPasswordTrait.attributes.value : '';
        	
        	// let totalIncomeAPIAPIKeyTrait = component.getTrait('Total_Income_API_API_Key');
        	// let totalIncomeAPIAPIKeyTraitValue = totalIncomeAPIAPIKeyTrait ? totalIncomeAPIAPIKeyTrait.attributes.value : '';
        	
        	// let totalIncomeAPIMoreHeadersTrait = component.getTrait('Total_Income_API_More_Headers');
        	// let totalIncomeAPIMoreHeadersTraitValue = totalIncomeAPIMoreHeadersTrait ? totalIncomeAPIMoreHeadersTrait.attributes.value : '';

			// let totalIncomeTrait = component.getTrait('Total_Income');
        	// let totalIncomeTraitValue = totalIncomeTrait ? totalIncomeTrait.attributes.value : '';

			// let incomeGoalReachedPercentageTrait = component.getTrait('Income_Goal_Reached_Percentage');
        	// let incomeGoalReachedPercentageTraitValue = incomeGoalReachedPercentageTrait ? incomeGoalReachedPercentageTrait.attributes.value : '';

			// let incomeGoalReachedCountTrait = component.getTrait('Income_Goal_Reached_Count');
        	// let incomeGoalReachedCountTraitValue = incomeGoalReachedCountTrait ? incomeGoalReachedCountTrait.attributes.value : '';


        	// let activeUserAPITrait = component.getTrait('Active_User_API');
        	// let activeUserAPITraitValue = activeUserAPITrait ? activeUserAPITrait.attributes.value : '';

        	// let activeUserAPIUsernameTrait = component.getTrait('Active_User_API_Username');
        	// let activeUserAPIUsernameTraitValue = activeUserAPIUsernameTrait ? activeUserAPIUsernameTrait.attributes.value : '';

        	// let activeUserAPIPasswordTrait = component.getTrait('Active_User_API_Password');
        	// let activeUserAPIPasswordTraitValue = activeUserAPIPasswordTrait ? activeUserAPIPasswordTrait.attributes.value : '';

        	// let activeUserAPIAPIKeyTrait = component.getTrait('Active_User_API_API_Key');
        	// let activeUserAPIAPIKeyTraitValue = activeUserAPIAPIKeyTrait ? activeUserAPIAPIKeyTrait.attributes.value : '';

        	// let activeUserAPIMoreHeadersTrait = component.getTrait('Active_User_API_More_Headers');
        	// let activeUserAPIMoreHeadersTraitValue = activeUserAPIMoreHeadersTrait ? activeUserAPIMoreHeadersTrait.attributes.value : '';

			// let activeUserTrait = component.getTrait('Active_User');
        	// let activeUserTraitValue = activeUserTrait ? activeUserTrait.attributes.value : '';

			// let activeUserGoalReachedPercentageTrait = component.getTrait('Active_User_Goal_Reached_Percentage');
        	// let activeUserGoalReachedPercentageTraitValue = activeUserGoalReachedPercentageTrait ? activeUserGoalReachedPercentageTrait.attributes.value : '';

			// let activeUserGoalReachedCountTrait = component.getTrait('Active_User_Goal_Reached_Count');
        	// let activeUserGoalReachedCountTraitValue = activeUserGoalReachedCountTrait ? activeUserGoalReachedCountTrait.attributes.value : '';


			// let totalTaxAPITrait = component.getTrait('Total_Tax_API');
        	// let totalTaxAPITraitValue = totalTaxAPITrait ? totalTaxAPITrait.attributes.value : '';

			// let totalTaxAPIUsernameTrait = component.getTrait('Total_Tax_API_Username');
        	// let totalTaxAPIUsernameTraitValue = totalTaxAPIUsernameTrait ? totalTaxAPIUsernameTrait.attributes.value : '';

			// let totalTaxAPIPasswordTrait = component.getTrait('Total_Tax_API_Password');
        	// let totalTaxAPIPasswordTraitValue = totalTaxAPIPasswordTrait ? totalTaxAPIPasswordTrait.attributes.value : '';

			// let totalTaxAPIAPIKeyrait = component.getTrait('Total_Tax_API_API_Key');
        	// let totalTaxAPIAPIKeyraitValue = totalTaxAPIAPIKeyrait ? totalTaxAPIAPIKeyrait.attributes.value : '';

			// let totalTaxAPIMoreHeadersTrait = component.getTrait('Total_Tax_API_More_Headers');
        	// let totalTaxAPIMoreHeadersTraitValue = totalTaxAPIMoreHeadersTrait ? totalTaxAPIMoreHeadersTrait.attributes.value : '';

			// let totalTaxTrait = component.getTrait('Total_Tax');
        	// let totalTaxTraitValue = totalTaxTrait ? totalTaxTrait.attributes.value : '';

			// let taxGoalReachedPercentageTrait = component.getTrait('Tax_Goal_Reached_Percentage');
        	// let taxGoalReachedPercentageTraitValue = taxGoalReachedPercentageTrait ? taxGoalReachedPercentageTrait.attributes.value : '';

			// let taxGoalReachedCountTrait = component.getTrait('Tax_Goal_Reached_Count');
        	// let taxGoalReachedCountTraitValue = taxGoalReachedCountTrait ? taxGoalReachedCountTrait.attributes.value : '';


        	// // TABLE 1 SECTION (first table in the template) -
        	// let table1APITrait = component.getTrait('Table1_API');
        	// let table1APITraitValue = table1APITrait ? table1APITrait.attributes.value : '';

			// let table1APIUsernameTrait = component.getTrait('Table1_API_Username');
        	// let table1APIUsernameTraitValue = table1APIUsernameTrait ? table1APIUsernameTrait.attributes.value : '';

			// let table1APIPasswordTrait = component.getTrait('Table1_API_Password');
        	// let table1APIPasswordTraitValue = table1APIPasswordTrait ? table1APIPasswordTrait.attributes.value : '';

			// let table1APIAPIKeyTrait = component.getTrait('Table1_API_API_Key');
        	// let table1APIAPIKeyTraitValue = table1APIAPIKeyTrait ? table1APIAPIKeyTrait.attributes.value : '';

			// let table1APIMoreHeadersTrait = component.getTrait('Table1_API_More_Headers');
        	// let table1APIMoreHeadersTraitValue = table1APIMoreHeadersTrait ? table1APIMoreHeadersTrait.attributes.value : '';

			// let table1KeynameTrait = component.getTrait('Table1_Keyname');
        	// let table1KeynameTraitValue = table1KeynameTrait ? table1KeynameTrait.attributes.value : '';


        	// // TABLE 2 SECTION (second table in the template) -
        	// let table2APITrait = component.getTrait('Table2_API');
        	// let table2APITraitValue = table2APITrait ? table2APITrait.attributes.value : '';

			// let table2APIUsernameTrait = component.getTrait('Table2_API_Username');
        	// let table2APIUsernameTraitValue = table2APIUsernameTrait ? table2APIUsernameTrait.attributes.value : '';

			// let table2APIPasswordTrait = component.getTrait('Table2_API_Password');
        	// let table2APIPasswordTraitValue = table2APIPasswordTrait ? table2APIPasswordTrait.attributes.value : '';

			// let table2APIAPIKeyTrait = component.getTrait('Table2_API_API_Key');
        	// let table2APIAPIKeyTraitValue = table2APIAPIKeyTrait ? table2APIAPIKeyTrait.attributes.value : '';

			// let table2APIMoreHeadersTrait = component.getTrait('Table2_API_More_Headers');
        	// let table2APIMoreHeadersTraitValue = table2APIMoreHeadersTrait ? table2APIMoreHeadersTrait.attributes.value : '';

			// let table2KeynameTrait = component.getTrait('Table2_Keyname');
        	// let table2KeynameTraitValue = table2KeynameTrait ? table2KeynameTrait.attributes.value : '';


        	// // TABLE 3 SECTION (third table in the template) -
        	// let table3APITrait = component.getTrait('Table3_API');
        	// let table3APITraitValue = table3APITrait ? table3APITrait.attributes.value : '';

			// let table3APIUsernameTrait = component.getTrait('Table3_API_Username');
        	// let table3APIUsernameTraitValue = table3APIUsernameTrait ? table3APIUsernameTrait.attributes.value : '';

			// let table3APIPasswordTrait = component.getTrait('Table3_API_Password');
        	// let table3APIPasswordTraitValue = table3APIPasswordTrait ? table3APIPasswordTrait.attributes.value : '';

			// let table3APIAPIKeyTrait = component.getTrait('Table3_API_API_Key');
        	// let table3APIAPIKeyTraitValue = table3APIAPIKeyTrait ? table3APIAPIKeyTrait.attributes.value : '';

			// let table3APIMoreHeadersTrait = component.getTrait('Table3_API_More_Headers');
        	// let table3APIMoreHeadersTraitValue = table3APIMoreHeadersTrait ? table3APIMoreHeadersTrait.attributes.value : '';

			// let table3KeynameTrait = component.getTrait('Table3_Keyname');
        	// let table3KeynameTraitValue = table3KeynameTrait ? table3KeynameTrait.attributes.value : '';


        	// const props = {
        	// 	Todays_API: todaysApiTraitValue,
        	// 	Todays_API_Username: todaysApiUsernameTraitValue,
        	// 	Todays_API_Password: todaysApiPasswordTraitValue,
        	// 	Todays_API_API_Key: todaysApiApiKeyTraitValue,
        	// 	Todays_API_More_Headers: todaysApiMoreHeadersTraitValue,
        	// 	Date_Response_Keyname: dataResponseKeynameTraitValue,
        	// 	Todays_Sale_Response_Keyname: todaysSaleResponseKeynameTraitValue,
        	// 	Todays_Earnings_Response_Keyname: todaysEarningsResponseKeynameTraitValue,


        	// 	Total_Sale_Last_Year_API: totalSaleLastYearAPITraitValue,
        	// 	Total_Sale_Last_Year_API_Username: totalSaleLastYearAPIUsernameTraitValue,
        	// 	Total_Sale_Last_Year_API_Password: totalSaleLastYearAPIPasswordTraitValue,
        	// 	Total_Sale_Last_Year_API_API_Key: totalSaleLastYearAPIAPIKeyTraitValue,
        	// 	Total_Sale_Last_Year_API_More_Headers: totalSaleLastYearAPIMoreHeadersTraitValue,
        	// 	Total_Sale_Last_Year_Percentage: totalSaleLastYearPercentageTraitValue,
        	// 	Total_Sale_Last_Year_Amount: totalSaleLastYearAmountTraitValue,


        	// 	Active_Customer_Last_Day_API: activeCustomerLastDayAPITraitValue,
        	// 	Active_Customer_Last_Day_API_Username: activeCustomerLastDayAPIUsernameTraitValue,
        	// 	Active_Customer_Last_Day_API_Password: activeCustomerLastDayAPIPasswordTraitValue,
        	// 	Active_Customer_Last_Day_API_API_Key: activeCustomerLastDayAPIAPIKeyTraitValue,
        	// 	Active_Customer_Last_Day_API_More_Headers: activeCustomerLastDayAPIMoreHeadersTraitValue,
        	// 	Active_Customer_Last_Day_Percentage: activeCustomerLastDayPercentageTraitValue,
        	// 	Active_Customer_Last_Day_Amount: activeCustomerLastDayAmountTraitValue,


        	// 	Total_Sales_API: totalSalesAPITraitValue,
        	// 	Total_Sales_API_Username: totalSalesAPIUsernameTraitValue,
        	// 	Total_Sales_API_Password: totalSalesAPIPasswordTraitValue,
        	// 	Total_Sales_API_API_Key: totalSalesAPIAPIKeyTraitValue,
        	// 	Total_Sales_API_More_Headers: totalSalesAPIMoreHeadersTraitValue,
        	// 	Total_Sales: totalSalesTraitValue,
        	// 	Sales_Goal_Reached_Percentage: salesGoalReachedPercentageTraitValue,
        	// 	Sales_Goal_Reached_Count: salesGoalReachedCountTraitValue,


        	// 	Total_Income_API: totalIncomeAPITraitValue,
        	// 	Total_Income_API_Username: totalIncomeAPIUsernameTraitValue,
        	// 	Total_Income_API_Password: totalIncomeAPIPasswordTraitValue,
        	// 	Total_Income_API_API_Key: totalIncomeAPIAPIKeyTraitValue,
        	// 	Total_Income_API_More_Headers: totalIncomeAPIMoreHeadersTraitValue,        		
        	// 	Total_Income: totalIncomeTraitValue,
        	// 	Income_Goal_Reached_Percentage: incomeGoalReachedPercentageTraitValue,
        	// 	Income_Goal_Reached_Count: incomeGoalReachedCountTraitValue,
        		

        	// 	Active_User_API: activeUserAPITraitValue,
        	// 	Active_User_API_Username: activeUserAPIUsernameTraitValue,
        	// 	Active_User_API_Password: activeUserAPIPasswordTraitValue,
        	// 	Active_User_API_API_Key: activeUserAPIAPIKeyTraitValue,
        	// 	Active_User_API_More_Headers: activeUserAPIMoreHeadersTraitValue,
        	// 	Active_User: activeUserTraitValue,
        	// 	Active_User_Goal_Reached_Percentage: activeUserGoalReachedPercentageTraitValue,
        	// 	Active_User_Goal_Reached_Count: activeUserGoalReachedCountTraitValue,
        		

        	// 	Total_Tax_API: totalTaxAPITraitValue,
        	// 	Total_Tax_API_Username: totalTaxAPIUsernameTraitValue,
        	// 	Total_Tax_API_Password: totalTaxAPIPasswordTraitValue,
        	// 	Total_Tax_API_API_Key: totalTaxAPIAPIKeyraitValue,
        	// 	Total_Tax_API_More_Headers: totalTaxAPIMoreHeadersTraitValue,
        	// 	Total_Tax: totalTaxTraitValue,
        	// 	Tax_Goal_Reached_Percentage: taxGoalReachedPercentageTraitValue,
        	// 	Tax_Goal_Reached_Count: taxGoalReachedCountTraitValue,
        		

        	// 	Table1_API: table1APITraitValue,
        	// 	Table1_API_Username: table1APIUsernameTraitValue,
        	// 	Table1_API_Password: table1APIPasswordTraitValue,
        	// 	Table1_API_API_Key: table1APIAPIKeyTraitValue,
        	// 	Table1_API_More_Headers: table1APIMoreHeadersTraitValue,
        	// 	Table1_Keyname: table1KeynameTraitValue,
        		

        	// 	Table2_API: table2APITraitValue,
        	// 	Table2_API_Username: table2APIUsernameTraitValue,
        	// 	Table2_API_Password: table2APIPasswordTraitValue,
        	// 	Table2_API_API_Key: table2APIAPIKeyTraitValue,
        	// 	Table2_API_More_Headers: table2APIMoreHeadersTraitValue,
        	// 	Table2_Keyname: table2KeynameTraitValue,
        		

        	// 	Table3_API: table3APITraitValue,
        	// 	Table3_API_Username: table3APIUsernameTraitValue,
        	// 	Table3_API_Password: table3APIPasswordTraitValue,
        	// 	Table3_API_API_Key: table3APIAPIKeyTraitValue,
        	// 	Table3_API_More_Headers: table3APIMoreHeadersTraitValue,
        	// 	Table3_Keyname: table3KeynameTraitValue,
        	// };

        	// Call the function to update props
        	updateProps(component);

            // Run your script logic when your component is added
            await businessDashboardFunctionality(component, storedProps);
        }
    });

	// adding the component definition for the template
	editor.DomComponents.addType("business-dashboard-template", {
		model: {
			defaults: {
				tagName: 'div',
				attributes: {
					class: 'page-body',
				},

				// script: businessDashboardFunctionality,

				components: [
					{
						tagName: 'div',
						attributes: {
							class: 'container-fluid',
						},

						components: [
							{
								tagName: 'div',
								attributes: {
									class: 'row',
								},

								components: [
									{
										tagName: 'div',
										attributes: {
											class: 'col-lg-12 xl-100 box-col-12',
										},

										components: [
											{
												tagName: 'div',
												attributes: {
													class: 'row total-sale-col',
												},

												components: [
													{
														tagName: 'div',
														attributes: {
															class: 'col-xl-5 box-col-12 xl-100',
														},

														components: [
															{
																tagName: 'div',
																attributes: {
																	class: 'row',
																},

																components: [
																	{
																		tagName: 'div',
																		attributes: {
																			class: 'col-12 xl-50',
																		},

																		components: [
																			{
																				tagName: 'div',
																				attributes: {
																					class: 'card card-with-border welcome-card o-hidden',
																				},

																				// styles: `
																				// 	.card {
																				// 		margin-bottom: 30px;
																				// 		border: none;
																				// 		-webkit-transition: all 0.3s ease;
																				// 		transition: all 0.3s ease;
																				// 		letter-spacing: 0.5px;
																				// 		border-radius: 10px;
																				// 		-webkit-box-shadow: 0 0 41px 18px #f9fafb;
																				// 		box-shadow: 0 0 41px 18px #f9fafb;
																				// 		padding: 0.5em;
																				// 	}

																				// 	.card-with-border {
																				// 		border: 1px solid #dfe5f5;
																				// 		-webkit-box-shadow: none;
																				// 		box-shadow: none;
																				// 	}

																				// 	.welcome-card {
																				// 		background-color: #f7f7fe;
																				// 	}

																				// 	.welcome-card p {
																				// 		width: 60%;
																				// 	}

																				// 	.o-hidden {
																				// 		overflow: hidden;
																				// 	}
																				// `,

																				style: {
																			        // card: {
																			          marginBottom: "30px",
																			          border: "none",
																			          transition: "all 0.3s ease",
																			          letterSpacing: "0.5px",
																			          borderRadius: "10px",
																			          WebkitBoxShadow: "0 0 41px 18px #f9fafb",
																			          boxShadow: "0 0 41px 18px #f9fafb",
																			          padding: "0.5em",
																			          background: "black",
																			        // },
																			        // "card-with-border": {
																			          border: "1px solid #dfe5f5",
																			          WebkitBoxShadow: "none",
																			          boxShadow: "none",
																			        // },
																			        // "welcome-card": {
																			          backgroundColor: "#f7f7fe",
																			        // },
																			        // "welcome-card p": {
																			          width: "60%",
																			        // },
																			        // "o-hidden": {
																			          overflow: "hidden",
																			        // },
																			      },

																				// traits for card section
																				Todays_API: "",
																				Todays_API_Username: "",
																	            Todays_API_Password: "",
																	            Todays_API_API_Key: "",
																	            Todays_API_More_Headers: "", // input should be like: a:b,c:d,e:f
																	            Todays_API_Update_Frequency_P24H: 1,

																	            Date_Response_Keyname: "",
																	            Todays_Sale_Response_Keyname: "",
																	            Todays_Earnings_Response_Keyname: "",

																	            traits: [
																		            {
																		                type: "text",
																		                name: "Todays_API",
																		                label: "Today's API",
																		                changeProp: true,
																		            }, {
																		                type: "text",
																		                name: "Todays_API_Username",
																		                label: "Today's API Username",
																		                changeProp: true,
																		            }, {
																		                type: "text",
																		                name: "Todays_API_Password",
																		                label: "Today's API Password",
																		                changeProp: true,
																		            }, {
																		                type: "text",
																		                name: "Todays_API_API_Key",
																		                label: "Today's API API Key",
																		                changeProp: true,
																		            }, {
																		                type: "text",
																		                name: "Todays_API_More_Headers",
																		                label: "Today's API More Headers",
																		                changeProp: true,
																		            }, {
																		                type: "number",
																		                name: "Todays_API_Update_Frequency_P24H",
																		                label: "Update Frequency / 24 Hrs",
																		                min: "1",
																		                changeProp: true,
																		            }, {
																		                type: "text",
																		                name: "Date_Response_Keyname",
																		                label: "Date Response Keyname",
																		                changeProp: true,
																		            }, {
																		                type: "text",
																		                name: "Todays_Sale_Response_Keyname",
																		                label: "Today's Sale Response Keyname",
																		                changeProp: true,
																		            }, {
																		                type: "text",
																		                name: "Todays_Earnings_Response_Keyname",
																		                label: "Today's Earnings Response Keyname",
																		                changeProp: true,
																		            },
																		        ],

																		        changeProp: true,

																		        "script-props": ["Todays_API", "Todays_API_Username", "Todays_API_Password", "Todays_API_API_Key", "Todays_API_More_Headers", "Todays_API_Update_Frequency_P24H", "Date_Response_Keyname", "Todays_Sale_Response_Keyname", "Todays_Earnings_Response_Keyname"],

																				components: [
																					{
																						tagName: 'img',
																						attributes: {
																							class: 'main',
																							src: "/assets/images/dashboard/welcome.png",
																						},

																						style: {
																							position: 'absolute',
																							right: '0',
																							'z-index': '2',
																						},

																						// styles: `
																						// 	.main {
																						// 		position: absolute;
																						// 		right: 0;
																						// 		z-index: 2;
																						// 	}
																						// `,
																					}, {
																						tagName: 'img',
																						attributes: {
																							class: 'decore1',
																							src: "/assets/images/dashboard/d1.png",
																						},

																						style: {
																							WebkitAnimation: "welcomezoom 4s linear 2s infinite alternate",
																						      animation: "welcomezoom 4s linear 2s infinite alternate",
																						      position: "absolute",
																						      right: "40px",
																						      top: "60px",
																						      zIndex: "1",
																						},

																						// styles: `
																						// 	.decore1 {
																						// 		-webkit-animation: welcomezoom 4s linear 2s infinite alternate;
																						// 		animation: welcomezoom 4s linear 2s infinite alternate;
																						// 		position: absolute;
																						// 		right: 40px;
																						// 		top: 60px;
																						// 		z-index: 1;
																						// 	}
																						// `,
																					}, {
																						tagName: 'img',
																						attributes: {
																							class: 'decore3',
																							src: "/assets/images/dashboard/d1.png",
																						},

																						style: {
																							WebkitAnimation: "welcomezoom 2s linear 1s infinite alternate",
																						      animation: "welcomezoom 2s linear 1s infinite alternate",
																						      position: "absolute",
																						      right: "160px",
																						      top: "30px",
																						      zIndex: "1",
																						},

																						// styles: `
																						// 	.decore3 {
																						// 		-webkit-animation: welcomezoom 2s linear 1s infinite alternate;
																						// 		animation: welcomezoom 2s linear 1s infinite alternate;
																						// 		position: absolute;
																						// 		right: 160px;
																						// 		top: 30px;
																						// 		z-index: 1;
																						// 	}
																						// `,
																					}, {
																						tagName: 'img',
																						attributes: {
																							class: 'decore4',
																							src: "/assets/images/dashboard/d1.png",
																						},

																						style: {
																							WebkitAnimation: "welcomezoom 2s linear 1s infinite alternate",
																						      animation: "welcomezoom 2s linear 1s infinite alternate",
																						      position: "absolute",
																						      right: "200px",
																						      top: "70px",
																						      zIndex: "2",
																						},

																						// styles: `
																						// 	.decore4 {
																						// 		-webkit-animation: welcomezoom 2s linear 1s infinite alternate;
																						// 		animation: welcomezoom 2s linear 1s infinite alternate;
																						// 		position: absolute;
																						// 		right: 200px;
																						// 		top: 70px;
																						// 		z-index: 2;
																						// 	}
																						// `,
																					}, {
																						tagName: 'img',
																						attributes: {
																							class: 'decore2',
																							src: "/assets/images/dashboard/d1.png",
																						},

																						style: {
																							position: "absolute",
																						      right: "50px",
																						      bottom: "0",
																						      zIndex: "1",
																						      WebkitAnimation: "welcome 4s linear 2s infinite alternate",
																						      animation: "welcome 4s linear 2s infinite alternate",
																						},

																						// styles: `
																						// 	.decore2 {
																						// 		position: absolute;
																						// 		right: 50px;
																						// 		bottom: 0;
																						// 		z-index: 1;
																						// 		-webkit-animation: welcome 4s linear 2s infinite alternate;
																						// 		animation: welcome 4s linear 2s infinite alternate;
																						// 	}
																						// `,
																					}, {
																						tagName: 'div',
																						attributes: {
																							class: 'card-header card-no-border o-hidden total-sale-widget',
																						},

																						style: {
																						    // cardHeader: {
																						      backgroundColor: "#fff",
																						      padding: "30px",
																						      borderBottom: "1px solid #dfe5f5",
																						      borderTopLeftRadius: "10px",
																						      borderTopRightRadius: "10px",
																						      position: "relative",
																						    // },
																						    // cardNoBorder: {
																						      borderBottom: "none !important",
																						    // },
																						    // totalSaleWidget: {
																						      textAlign: "left",
																						    // },
																						  },

																						// styles: `
																						// 	.card-header {
																						// 		background-color: #fff;
																						// 		padding: 30px;
																						// 		border-bottom: 1px solid #dfe5f5;
																						// 		border-top-left-radius: 10px;
																						// 		border-top-right-radius: 10px;
																						// 		position: relative;
																						// 	}

																						// 	.card-no-border {
																						// 		border-bottom: none !important;
																						// 	}

																						// 	.total-sale-widget {
																						// 		text-align: left;
																						// 	}
																						// `,

																						components: [
																							{
																								tagName: 'div',
																								attributes: {
																									class: 'media',
																								},

																								components: [
																									{
																										tagName: 'div',
																										attributes: {
																											class: 'media-body',
																										},

																										components: [
																											{
																												tagName: 'h3',
																												attributes: {
																													class: 'f-w-600',
																												},

																												// styles: `
																												// 	.f-w-600 {
																												// 		font-weight: 600 !important;
																												// 	}
																												// `,

																												components: {
																										            tagName: "p",
																										            type: "text",
																										            editable: true,

																										            attributes: {
																														class: 'f-w-600',
																													},

																													// styles: `
																													// 	.f-w-600 {
																													// 		font-weight: 600 !important;
																													// 	}
																													// `,																													

																										            components: {
																										                type: "textnode",
																										                content: "Hello Johan Deo!",
																										            },
																										        },
																											}, {
																												tagName: 'p',
																												attributes: {
																													class: 'f-w-600',
																												},

																												components: {
																										            tagName: "p",
																										            type: "text",
																										            editable: true,

																										            style: {
																														'font-weight': '600',
																													},

																										            components: {
																										                type: "textnode",
																										                content: "Welcome back to your redSling dashboard",
																										            },
																										        },
																											},
																										],
																									}
																								],
																							}, {
																								tagName: "div",
																								attributes: {
																									class: 'media mt-5',
																								},

																								components: [
																									{
																										tagName: 'div',
																										attributes: {
																											class: 'media-body',
																										},

																										components: [
																											{
																												tagName: 'h5',
																												attributes: {
																													class: 'f-w-400',
																												},

																												// styles: `
																												// 	.f-w-400 {
																												// 		font-weight: 400;
																												// 	}
																												// `,

																												components: {
																										            tagName: "p",
																										            type: "text",
																										            editable: true,

																										            style: {
																														'font-weight': '600',
																													},

																										            components: {
																										                type: "textnode",
																										                content: "Wednesday, 20",
																										            },
																										        },
																											}, {
																												tagName: 'p',
																												attributes: {
																													class: "mb-0",
																												},

																												components: [
																													{
																											            tagName: "p",
																											            type: "text",
																											            editable: true,

																											            components: {
																											                type: "textnode",
																											                content: "Today's Sale",
																											            },
																											        }, {
																											        	tagName: "span",
																											            attributes: {
																															class: "font-success",
																														},
																											            editable: true,

																											            // styles: `
																											            // 	.font-success {
																														// 		color: #51bb25 !important;
																														// 	}
																											            // `,

																											            components: [
																												            {
																													            tagName: "p",
																													            type: "text",
																													            editable: true,

																													            style: {
																													            	color: '#51bb25 !important',
																													            },

																													            components: {
																													                type: "textnode",
																													                content: "3.56%",
																													            },
																													        }, {
																													        	tagName: "i",
																													        	attributes: {
																																	class: "fa fa-caret-up",
																																},
																													        },
																												        ],
																											        }
																										        ],
																											}, {
																												tagName: "div",
																												attributes: {
																													class: "sales-widgets d-flex mt-1",
																												},

																												style: {
																													WebkitBoxAlign: "center",
																												      MsFlexAlign: "center",
																												      alignItems: "center",
																												},

																												// styles: `
																												// 	.total-sale-widget .media .media-body .sales-widgets {
																												// 		-webkit-box-align: center;
																												// 		-ms-flex-align: center;
																												// 		align-items: center;
																												// 	}
																												// `,

																												components: [
																													{
																														tagName: 'h3',
																														attributes: {
																															class: 'mt-1 mb-0 f-w-600',
																														},

																														style: {
																															display: "-webkit-box",
																														      display: "-ms-flexbox",
																														      display: "flex",
																														      WebkitBoxAlign: "center",
																														      MsFlexAlign: "center",
																														      alignItems: "center",
																														},

																														// styles: `
																														// 	.total-sale-widget .media .media-body .sales-widgets h3 {
																														// 		display: -webkit-box;
																														// 		display: -ms-flexbox;
																														// 		display: flex;
																														// 		-webkit-box-align: center;
																														// 		-ms-flex-align: center;
																														// 		align-items: center;
																														// 	}
																														// `,

																														components: [
																															{
																													        	tagName: "i",
																													        	attributes: {
																																	class: 'mr-1 txt-primary',
																																	"data-feather": "dollar-sign",
																																},
																													        }, {
																													        	tagName: "span",
																													        	attributes: {
																																	class: 'counter txt-primary',
																																},

																																styles: `
																																	.txt-primary {
																																		color: #655af3 !important;
																																	}
																																`,

																																components: [
																																	{
																															            tagName: "p",
																															            attributes: {
																																			class: 'counter txt-primary',
																																		},

																															            type: "text",
																															            editable: true,

																															            style: {
																															            	color: '#655af3 !important',
																																			'font-weight': '600',
																															            },

																															            // styles: `
																																		// 	.txt-primary p {
																																		// 		color: #655af3 !important;
																																		// 		font-weight: 600;
																																		// 	}
																																		// `,

																															            components: {
																															                type: "textnode",
																															                content: "405,",
																															            },
																															        },
																																],
																													        }, {
																													        	tagName: "span",
																													        	attributes: {
																																	class: 'ml-2 f-12 f-w-400',
																																},

																																components: [
																																	{
																															            tagName: "p",
																															            type: "text",
																															            editable: true,

																															            components: {
																															                type: "textnode",
																															                content: "Earning,",
																															            },
																															        },
																																],
																													        },
																												        ],
																													},
																												],
																											}, {
																												tagName: 'p',
																												attributes: {
																													class: "mb-0",
																												},

																												style: {
																													'margin-left': '20px',
																												},

																												// styles: `
																												// 	.total-sale-widget .media .media-body .sales-widgets p {
																												// 		margin-left: 20px;
																												// 	}
																												// `,

																												components: [
																													{
																											            tagName: "p",
																											            type: "text",
																											            editable: true,

																											            components: {
																											                type: "textnode",
																											                content: "your sales & earning over the last 24 hours",
																											            },
																											        },
																												],
																											},
																										],
																									},
																								],
																							}
																						],
																					},
																				],
																			},
																		],
																	}, {
																		tagName: "div",
																		attributes: {
																			class: 'col-sm-12 xl-50',
																		},

																		components: [
																			{
																				tagName: "div",
																				attributes: {
																					class: 'card card-with-border o-hidden total-sale-widget',
																				},

																				// traits
																				Total_Sale_Last_Year_API: "",
																				Total_Sale_Last_Year_API_Username: "",
																				Total_Sale_Last_Year_API_Password: "",
																				Total_Sale_Last_Year_API_API_Key: "",
																				Total_Sale_Last_Year_API_More_Headers: "",
																				Total_Sale_Last_Year_API_Update_Frequency_P24H: 1,
																				
																				Total_Sale_Last_Year_Percentage: "",
																				Total_Sale_Last_Year_Amount: "",

																				traits: [
																		            {
																		                type: "text",
																		                name: "Total_Sale_Last_Year_API",
																		                label: "Total Sale Last Year API",
																		                changeProp: true,
																		            }, {
																		                type: "text",
																		                name: "Total_Sale_Last_Year_API_Username",
																		                label: "Total Sale Last Year API Username",
																		                changeProp: true,
																		            }, {
																		                type: "text",
																		                name: "Total_Sale_Last_Year_API_Password",
																		                label: "Total Sale Last Year API Password",
																		                changeProp: true,
																		            }, {
																		                type: "text",
																		                name: "Total_Sale_Last_Year_API_API_Key",
																		                label: "Total Sale Last Year API API Key",
																		                changeProp: true,
																		            }, {
																		                type: "text",
																		                name: "Total_Sale_Last_Year_API_More_Headers",
																		                label: "Total Sale Last Year API More Headers",
																		                changeProp: true,
																		            }, {
																		                type: "number",
																		                name: "Total_Sale_Last_Year_API_Update_Frequency_P24H",
																		                label: "Update Frequency / 24 Hrs",
																		                min: "1",
																		                changeProp: true,
																		            }, {
																		                type: "text",
																		                name: "Total_Sale_Last_Year_Percentage",
																		                label: "Total Sale Last Year Percentage",
																		                changeProp: true,
																		            }, {
																		                type: "text",
																		                name: "Total_Sale_Last_Year_Amount",
																		                label: "Total Sale Last Year Amount",
																		                changeProp: true,
																		            },
																		        ],

																		        changeProp: true,

																		        "script-props": ["Total_Sale_Last_Year_API", "Total_Sale_Last_Year_API_Username", "Total_Sale_Last_Year_API_Password", "Total_Sale_Last_Year_API_API_Key", "Total_Sale_Last_Year_API_More_Headers", "Total_Sale_Last_Year_API_Update_Frequency_P24H", "Total_Sale_Last_Year_Percentage", "Total_Sale_Last_Year_Amount"],

																				components: [
																					{
																						tagName: "div",
																						attributes: {
																							class: 'media align-items-center',
																						},

																						components: [
																							{
																								tagName: "div",
																								attributes: {
																									class: 'media-left',
																								},

																								style: {
																									width: '40%',
																								},

																								// styles: `
																								// 	.total-sale-widget .media-left {
																								// 		width: 40%;
																								// 	}
																								// `,

																								components: [
																									{
																										tagName: "div",
																										attributes: {
																											class: 'card-no-border p-20',
																										},

																										components: [
																											{
																												tagName: "div",
																												attributes: {
																													class: 'media',
																												},

																												components: [
																													{
																														tagName: "div",
																														attributes: {
																															class: 'media-body',
																														},

																														components: [
																															{
																																tagName: 'p',
																																attributes: {
																																	class: "mb-0",
																																},

																																components: [
																																	{
																															            tagName: "p",
																															            type: "text",
																															            editable: true,

																															            components: {
																															                type: "textnode",
																															                content: "Total Sale",
																															            },
																															        }, {
																															            tagName: "span",
																															            attributes: {
																																			class: "font-warning",
																																		},
																															            editable: true,

																															            components: [
																															            	{
																																				tagName: 'p',
																																				attributes: {
																																					class: "mb-0",
																																				},

																																				components: [
																																					{
																																			            tagName: "p",
																																			            type: "text",
																																			            editable: true,

																																			            components: {
																																			                type: "textnode",
																																			                content: "3.56%",
																																			            },
																																			        },
																																				],
																																			}, {
																																				tagName: "i",
																																				attributes: {
																																					class: "fa fa-caret-up",
																																				},
																																			},
																															            ],
																															        },
																																],
																															}, {
																																tagName: "div",
																																attributes: {
																																	class: "sales-widgets d-flex",
																																},

																																styles: `
																																	.total-sale-widget .media .media-body .sales-widgets {
																																		-webkit-box-align: center;
																																		-ms-flex-align: center;
																																		align-items: center;
																																	}
																																`,

																																components: [
																																	{
																																		tagName: 'h3',
																																		attributes: {
																																			class: 'mt-1 mb-0 f-w-600',
																																		},

																																		styles: `
																																			.total-sale-widget .media .media-body .sales-widgets h3 {
																																				display: -webkit-box;
																																				display: -ms-flexbox;
																																				display: flex;
																																				-webkit-box-align: center;
																																				-ms-flex-align: center;
																																				align-items: center;
																																			}
																																		`,

																																		components: [
																																			{
																																	        	tagName: "i",
																																	        	attributes: {
																																					class: 'mr-1',
																																					"data-feather": "dollar-sign",
																																				},
																																	        }, {
																																	        	tagName: "span",
																																	        	attributes: {
																																					class: 'counter',
																																				},

																																				components: [
																																					{
																																			            tagName: "p",
																																			            type: "text",
																																			            editable: true,

																																			            components: {
																																			                type: "textnode",
																																			                content: "12,461",
																																			            },
																																			        },
																																				],
																																	        },
																																        ],
																																	}, {
																																		tagName: 'p',
																																		attributes: {
																																			class: "mb-0",
																																		},

																																		styles: `
																																			.total-sale-widget .media .media-body .sales-widgets p {
																																				margin-left: 20px;
																																			}
																																		`,

																																		components: [
																																			{
																																	            tagName: "p",
																																	            type: "text",
																																	            editable: true,

																																	            components: {
																																	                type: "textnode",
																																	                content: "Last Year",
																																	            },
																																	        },
																																		],
																																	},
																																],
																															},
																														],
																													},
																												],
																											},
																										],
																									},
																								],
																							}, {
																								tagName: "div",
																								attributes: {
																									class: "media-right",
																								},

																								styles: `
																									.total-sale-widget .media-right {
																										width: calc(60% - 20px);
																										overflow: hidden;
																									}
																								`,

																								components: [
																									{
																										tagName: "div",
																										attributes: {
																											class: "card-body p-0",
																										},

																										components: [
																											{
																												tagName: "div",
																												attributes: {
																													class: "apex-chart-container",
																												},

																												components: [
																													{
																														tagName: "div",
																														attributes: {
																															class: "timeline-chart1",
																															style: "min-height: 134px;"
																														},

																														components: [
																															{
																																tagName: "div",
																																attributes: {
																																	id: "apexchartsevpzzj7m",
																																	class: "apexcharts-canvas apexchartsevpzzj7m zoomable",
																																	style: "width: 324px; height: 120px;"
																																},

																																components: [
																																	// {
																																	// 	tagName: "svg",
																																	// 	attributes: {
																																	// 		id: "SvgjsSvg2080",
																																	// 		width: "305",
																																	// 		height: "120",
																																	// 		xmlns: "http://www.w3.org/2000/svg",
																																	// 		version: "1.1",
																																	// 		"xmlns:xlink": "http://www.w3.org/1999/xlink",
																																	// 		"xmlns:svgjs": "http://svgjs.com/svgjs",
																																	// 		class: "apexcharts-svg",
																																	// 		"xmlns:data": "ApexChartsNS",
																																	// 		transform: "translate(0, 0)",
																																	// 		style: "background: transparent;"
																																	// 	},
																																	// },

																																	{
																																		tagName: "div",																						
																																		attributes: {
																																			id: "chart-container-2",
																																			style: "width: 90%; height: 90%; margin: auto; display: flex; justify-content: center; align-items: center;",
																																		},

																																		components: [
																																			{
																																	            tagName: "p",
																																	            type: "text",
																																	            editable: true,

																																	            components: {
																																	                type: "textnode",
																																	                content: "Space for a 'Chart/Graph' component",
																																	            },
																																	        },
																																		],
																																	},
																																],
																															},
																														],
																													},
																												],
																											},
																										],
																									},
																								],
																							},
																						],
																					},
																				],
																			}, {
																				tagName: "div",
																				attributes: {
																					class: "card card-with-border o-hidden total-sale-widget",
																				},

																				// traits
																				Active_Customer_Last_Day_API: "",
																				Active_Customer_Last_Day_API_Username: "",
																				Active_Customer_Last_Day_API_Password: "",
																				Active_Customer_Last_Day_API_API_Key: "",
																				Active_Customer_Last_Day_API_More_Headers: "",
																				Active_Customer_Last_Day_API_Update_Frequency_P24H: 1,
																				
																				Active_Customer_Last_Day_Percentage: "",
																				Active_Customer_Last_Day_Amount: "",

																				traits: [
																		            {
																		                type: "text",
																		                name: "Active_Customer_Last_Day_API",
																		                label: "Active Customer Last Day API",
																		                changeProp: true,
																		            }, {
																		                type: "text",
																		                name: "Active_Customer_Last_Day_API_Username",
																		                label: "Active Customer Last Day API Username",
																		                changeProp: true,
																		            }, {
																		                type: "text",
																		                name: "Active_Customer_Last_Day_API_Password",
																		                label: "Active Customer Last Day API Password",
																		                changeProp: true,
																		            }, {
																		                type: "text",
																		                name: "Active_Customer_Last_Day_API_API_Key",
																		                label: "Active Customer Last Day API API Key",
																		                changeProp: true,
																		            }, {
																		                type: "text",
																		                name: "Active_Customer_Last_Day_API_More_Headers",
																		                label: "Active Customer Last Day API More Headers",
																		                changeProp: true,
																		            }, {
																		                type: "number",
																		                name: "Active_Customer_Last_Day_API_Update_Frequency_P24H",
																		                label: "Update Frequency / 24 Hrs",
																		                min: "1",
																		                changeProp: true,
																		            }, {
																		                type: "text",
																		                name: "Active_Customer_Last_Day_Percentage",
																		                label: "Active Customer Last Day Percentage",
																		                changeProp: true,
																		            }, {
																		                type: "text",
																		                name: "Active_Customer_Last_Day_Amount",
																		                label: "Active Customer Last Day Amount",
																		                changeProp: true,
																		            },
																		        ],

																		        changeProp: true,

																		        "script-props": ["Active_Customer_Last_Day_API", "Active_Customer_Last_Day_API_Username", "Active_Customer_Last_Day_API_Password", "Active_Customer_Last_Day_API_API_Key", "Active_Customer_Last_Day_API_More_Headers", "Active_Customer_Last_Day_API_Update_Frequency_P24H", "Active_Customer_Last_Day_Percentage", "Active_Customer_Last_Day_Amount"],

																				components: [
																					{
																						tagName: "div",
																						attributes: {
																							class: "media align-items-center",
																						},

																						components: [
																							{
																								tagName: "div",
																								attributes: {
																									class: "media-left",
																								},

																								components: [
																									{
																										tagName: "div",
																										attributes: {
																											class: "card-no-border p-20",
																										},

																										components: [
																											{
																												tagName: "div",
																												attributes: {
																													class: "media",
																												},

																												components: [
																													{
																														tagName: "div",
																														attributes: {
																															class: "media-body",
																														},

																														components: [
																															{
																																tagName: 'p',
																																attributes: {
																																	class: "mb-0",
																																},

																																components: [
																																	{
																															            tagName: "p",
																															            type: "text",
																															            editable: true,

																															            components: {
																															                type: "textnode",
																															                content: "Active Customer",
																															            },
																															        }, {
																															            tagName: "span",
																															            attributes: {
																																			class: "font-success",
																																		},
																															            editable: true,

																															            styles: `
																															            	.font-success {
																																				color: #51bb25 !important;
																																			}
																															            `,

																															            components: [
																															            	{
																																	            tagName: "p",
																																	            type: "text",
																																	            editable: true,

																																	            components: {
																																	                type: "textnode",
																																	                content: "01.56%",
																																	            },
																																	        }, {
																																				tagName: "i",
																																				attributes: {
																																					class: "fa fa-caret-up",
																																				},
																																			},
																															            ],
																															        },
																																],
																															}, {
																																tagName: "div",
																																attributes: {
																																	class: "sales-widgets d-flex",
																																},

																																components: [
																																	{
																																		tagName: 'h3',
																																		attributes: {
																																			class: 'mt-1 mb-0 f-w-600',
																																		},

																																		components: [
																																			{
																																	        	tagName: "span",
																																	        	attributes: {
																																					class: 'counter',
																																				},

																																				components: [
																																					{
																																			            tagName: "p",
																																			            type: "text",
																																			            editable: true,

																																			            components: {
																																			                type: "textnode",
																																			                content: "10,14,12",
																																			            },
																																			        },
																																				],
																																	        },
																																        ],
																																	}, {
																																		tagName: 'p',
																																		attributes: {
																																			class: "mb-0",
																																		},

																																		components: [
																																			{
																																	            tagName: "p",
																																	            type: "text",
																																	            editable: true,

																																	            components: {
																																	                type: "textnode",
																																	                content: "Last Day",
																																	            },
																																	        },
																																		],
																																	},
																																],
																															},
																														],
																													},
																												],
																											},
																										],
																									},
																								],
																							}, {
																								tagName: "div",
																								attributes: {
																									class: "media-right",
																								},

																								components: [
																									{
																										tagName: "div",
																										attributes: {
																											class: "card-body p-0",
																										},

																										components: [
																											{
																												tagName: "div",
																												attributes: {
																													class: "apex-chart-container",
																												},

																												components: [
																													{
																														tagName: "div",
																														attributes: {
																															class: "timeline-chart2",																															
																															style: "min-height: 134px;"
																														},

																														components: [
																															{
																																tagName: "div",
																																attributes: {
																																	id: "apexchartsevpzzj7m",
																																	class: "apexcharts-canvas apexchartsevpzzj7m zoomable",
																																	style: "width: 324px; height: 120px;"
																																},

																																components: [
																																	// {
																																	// 	tagName: "svg",
																																	// 	attributes: {
																																	// 		id: "SvgjsSvg2080",
																																	// 		width: "305",
																																	// 		height: "120",
																																	// 		xmlns: "http://www.w3.org/2000/svg",
																																	// 		version: "1.1",
																																	// 		"xmlns:xlink": "http://www.w3.org/1999/xlink",
																																	// 		"xmlns:svgjs": "http://svgjs.com/svgjs",
																																	// 		class: "apexcharts-svg",
																																	// 		"xmlns:data": "ApexChartsNS",
																																	// 		transform: "translate(0, 0)",
																																	// 		style: "background: transparent;"
																																	// 	},
																																	// },

																																	{
																																		tagName: "div",																						
																																		attributes: {
																																			id: "chart-container-3",
																																			style: "width: 90%; height: 90%; margin: auto; display: flex; justify-content: center; align-items: center;",
																																		},

																																		components: [
																																			{
																																	            tagName: "p",
																																	            type: "text",
																																	            editable: true,

																																	            components: {
																																	                type: "textnode",
																																	                content: "Space for a 'Chart/Graph' component",
																																	            },
																																	        },
																																		],
																																	},
																																],
																															},
																														],
																													},
																												],
																											},
																										],
																									},
																								],
																							}
																						],
																					},
																				],
																			},
																		],
																	},
																],
															},
														],
													}, {
														tagName: "div",
														attributes: {
															class: "col-xl-7 xl-100 box-col-12",
														},

														components: [
															{
																tagName: "div",
																attributes: {
																	class: "card card-with-border monthly-growth o-hidden",
																},

																components: [
																	{
																		tagName: "div",
																		attributes: {
																			class: "card-header card-no-border",
																		},

																		components: [
																			{
																				tagName: "h5",
																				attributes: {
																					class: "card-header card-no-border",
																				},

																				components: [
																					{
																			            tagName: "p",
																			            type: "text",
																			            editable: true,

																			            components: {
																			                type: "textnode",
																			                content: "Monthly Revenue Growth",
																			            },
																			        },
																				],
																			}, {
																				tagName: "span",

																				components: [
																					{
																			            tagName: "p",
																			            type: "text",
																			            editable: true,

																			            components: {
																			                type: "textnode",
																			                content: "Number of This Monthly Revenue Growth",
																			            },
																			        },
																				],
																			},
																		],
																	}, {
																		tagName: "div",
																		attributes: {
																			class: "card-body pt-0 px-0",
																		},

																		styles: `
																			.card .card-body {
																				padding: 30px;
																				background-color: rgba(0, 0, 0, 0);
																			}

																			.card .card-body p:last-child {
																				margin-bottom: 0;
																			}
																		`,

																		components: [
																			{
																				tagName: "div",
																				attributes: {
																					class: "dashboard-rounded-chart flot-chart-container",
																				},

																				styles: `
																					.dashboard-rounded-chart.flot-chart-container {
																						height: 245px;
																					}
																				`,

																				// THE BAR GRAPH COMPONENT

																				components: [
																					{
																						tagName: "div",																						
																						attributes: {
																							id: "chart-container-1",
																							style: "width: 900px; height: 200px; margin: auto; display: flex; justify-content: center; align-items: center;",
																						},
																						editable: true,

																						components: [
																							{
																					            tagName: "p",
																					            type: "text",
																					            editable: true,

																					            components: {
																					                type: "textnode",
																					                content: "Space for a 'Chart/Graph' component",
																					            },
																					        },
																						],
																					},
																				],
																			},
																		],
																	}, {
																		tagName: "div",
																		attributes: {
																			class: "card-footer p-0",
																		},

																		styles: `
																			.card .card-footer {
																				background-color: #fff;
																				border-top: 1px solid #dfe5f5;
																				padding: 30px;
																				border-bottom-left-radius: 10px;
																				border-bottom-right-radius: 10px;
																			}
																		`,

																		components: [
																			{
																				tagName: "div",
																				attributes: {
																					class: "row growth-revenue-footer",
																				},

																				styles: `
																					.monthly-growth .card-footer .growth-revenue-footer {
																						margin: 0;
																					}
																				`,

																				components: [
																					{
																						tagName: "div",
																						attributes: {
																							class: "col-6",
																						},

																						components: [
																							{
																								tagName: "div",
																								attributes: {
																									class: "card ecommerce-widget mb-0",
																								},

																								styles: `
																									.monthly-growth .card-footer .growth-revenue-footer .ecommerce-widget {
																										border: none;
																									}
																								`,

																								components: [
																									{
																										tagName: "div",
																										attributes: {
																											class: "card-body support-ticket-font",
																										},

																										// traits
																										Total_Sales_API: "",
																										Total_Sales_API_Username: "",
																							            Total_Sales_API_Password: "",
																							            Total_Sales_API_API_Key: "",
																							            Total_Sales_API_More_Headers: "",
																							            Total_Sales_API_Update_Frequency_P24H: 1,

																										Total_Sales: "",
																										Sales_Goal_Reached_Percentage: "",
																										Sales_Goal_Reached_Count: "",

																										traits: [
																											{
																								                type: "text",
																								                name: "Total_Sales_API",
																								                label: "Total Sales API",
																								                changeProp: true,
																								            }, {
																								                type: "text",
																								                name: "Total_Sales_API_Username",
																								                label: "Total Sales API Username",
																								                changeProp: true,
																								            }, {
																								                type: "text",
																								                name: "Total_Sales_API_Password",
																								                label: "Total Sales API Password",
																								                changeProp: true,
																								            }, {
																								                type: "text",
																								                name: "Total_Sales_API_API_Key",
																								                label: "Total Sales API API Key",
																								                changeProp: true,
																								            }, {
																								                type: "text",
																								                name: "Total_Sales_API_More_Headers",
																								                label: "Total Sales API More Headers",
																								                changeProp: true,
																								            }, {
																								                type: "number",
																								                name: "Total_Sales_API_Update_Frequency_P24H",
																								                label: "Update Frequency / 24 Hrs",
																								                min: "1",
																								                changeProp: true,
																								            }, {
																								                type: "text",
																								                name: "Total_Sales",
																								                label: "Total Sales",
																								                changeProp: true,
																								            }, {
																								                type: "text",
																								                name: "Sales_Goal_Reached_Percentage",
																								                label: "Sales Goal Reached Percentage",
																								                changeProp: true,
																								            }, {
																								                type: "text",
																								                name: "Sales_Goal_Reached_Count",
																								                label: "Sales Goal Reached Count",
																								                changeProp: true,
																								            },
																										],

																										changeProp: true,

																										"script-props": ["Total_Sales_API", "Total_Sales_API_Username", "Total_Sales_API_Password", "Total_Sales_API_API_Key", "Total_Sales_API_More_Headers", "Total_Sales_API_Update_Frequency_P24H", "Total_Sales", "Sales_Goal_Reached_Percentage", "Sales_Goal_Reached_Count"],

																										components: [
																											{
																												tagName: "div",
																												attributes: {
																													class: "d-flex",
																												},

																												styles: `
																													.monthly-growth .card-footer .growth-revenue-footer .ecommerce-widget .support-ticket-font>div {
																														-webkit-box-pack: justify;
																														-ms-flex-pack: justify;
																														justify-content: space-between;
																													}
																												`,

																												components: [
																													{
																														tagName: "div",
																														attributes: {
																															class: "data-left-ticket",
																														},

																														components: [
																															{
																																tagName: "span",
																																attributes: {
																																	class: "f-12",
																																},

																																components: [
																																	{
																															            tagName: "p",
																															            type: "text",
																															            editable: true,

																															            components: {
																															                type: "textnode",
																															                content: "Total sales",
																															            },
																															        },
																																],
																															}, {
																																tagName: "h5",
																																attributes: {
																																	class: "total-num",
																																},

																																components: [
																																	{
																																		tagName: "span",
																																		attributes: {
																																			class: "mr-1",
																																		},

																																		components: [
																																			{
																																	            tagName: "p",
																																	            type: "text",
																																	            editable: true,

																																	            components: {
																																	                type: "textnode",
																																	                content: "$",
																																	            },
																																	        },
																																		],
																																	}, {
																																		tagName: "span",
																																		attributes: {
																																			class: "counter",
																																		},

																																		components: [
																																			{
																																	            tagName: "p",
																																	            type: "text",
																																	            editable: true,

																																	            components: {
																																	                type: "textnode",
																																	                content: "8,162",
																																	            },
																																	        },
																																		],
																																	},
																																],
																															},
																														],
																													}, {
																														tagName: "div",
																														attributes: {
																															class: "data-right-ticket",
																														},

																														components: [
																															{
																																tagName: "div",
																																attributes: {
																																	class: "text-md-right",
																																},

																																components: [
																																	{
																																		tagName: "ul",

																																		components: [
																																			{
																																				tagName: "li",

																																				components: [
																																					{
																																						tagName: "span",
																																						attributes: {
																																							class: "f-12",
																																						},

																																						components: [
																																							{
																																					            tagName: "p",
																																					            type: "text",
																																					            editable: true,

																																					            components: {
																																					                type: "textnode",
																																					                content: "85% goal reached",
																																					            },
																																					        },
																																						],
																																					},
																																				],
																																			}, {
																																				tagName: "li",

																																				components: [
																																					{
																																						tagName: "h6",
																																						attributes: {
																																							class: "mb-0",
																																						},

																																						components: [
																																							{
																																					            tagName: "p",
																																					            type: "text",
																																					            editable: true,

																																					            components: {
																																					                type: "textnode",
																																					                content: "1,00,000",
																																					            },
																																					        },
																																						],
																																					},
																																				],
																																			},
																																		],
																																	},
																																],
																															},
																														],
																													},
																												],
																											}, {
																												tagName: "div",
																												attributes: {
																													class: "progress-showcase",
																												},

																												components: [
																													{
																														tagName: "div",
																														attributes: {
																															class: "progress sm-progress-bar",
																														},

																														components: [
																															{
																																tagName: "div",
																																attributes: {
																																	class: "progress-bar bg-primary",
																																	role: "progressbar",
																																	style: "width: 75%",
																																	"aria-valuenow": "25",
																																	"aria-valuemin": "0",
																																	"aria-valuemax": "100",
																																},
																															},
																														],
																													},
																												],
																											},
																										],
																									},
																								],
																							},
																						],
																					}, {
																						tagName: "div",
																						attributes: {
																							class: "col-6",
																						},

																						components: [
																							{
																								tagName: "div",
																								attributes: {
																									class: "card ecommerce-widget mb-0",
																								},

																								components: [
																									{
																										tagName: "div",
																										attributes: {
																											class: "card-body support-ticket-font",
																										},

																										// traits
																										Total_Income_API: "",
																										Total_Income_API_Username: "",
																							            Total_Income_API_Password: "",
																							            Total_Income_API_API_Key: "",
																							            Total_Income_API_More_Headers: "",
																							            Total_Income_API_Update_Frequency_P24H: 1,

																										Total_Income: "",
																										Income_Goal_Reached_Percentage: "",
																										Income_Goal_Reached_Count: "",

																										traits: [
																											{
																								                type: "text",
																								                name: "Total_Income_API",
																								                label: "Total Income API",
																								                changeProp: true,
																								            }, {
																								                type: "text",
																								                name: "Total_Income_API_Username",
																								                label: "Total Income API Username",
																								                changeProp: true,
																								            }, {
																								                type: "text",
																								                name: "Total_Income_API_Password",
																								                label: "Total Income API Password",
																								                changeProp: true,
																								            }, {
																								                type: "text",
																								                name: "Total_Income_API_API_Key",
																								                label: "Total_Income API API Key",
																								                changeProp: true,
																								            }, {
																								                type: "text",
																								                name: "Total_Income_API_More_Headers",
																								                label: "Total Income API More Headers",
																								                changeProp: true,
																								            }, {
																								                type: "number",
																								                name: "Total_Income_API_Update_Frequency_P24H",
																								                label: "Update Frequency / 24 Hrs",
																								                min: "1",
																								                changeProp: true,
																								            }, {
																								                type: "text",
																								                name: "Total_Income",
																								                label: "Total Income",
																								                changeProp: true,
																								            }, {
																								                type: "text",
																								                name: "Income_Goal_Reached_Percentage",
																								                label: "Income Goal Reached Percentage",
																								                changeProp: true,
																								            }, {
																								                type: "text",
																								                name: "Income_Goal_Reached_Count",
																								                label: "Income Goal Reached Count",
																								                changeProp: true,
																								            },
																										],

																										changeProp: true,

																										"script-props": ["Total_Income_API", "Total_Income_API_Username", "Total_Income_API_Password", "Total_Income_API_API_Key", "Total_Income_API_More_Headers", "Total_Income_API_Update_Frequency_P24H", "Total_Income", "Income_Goal_Reached_Percentage", "Income_Goal_Reached_Count"],

																										components: [
																											{
																												tagName: "div",
																												attributes: {
																													class: "d-flex",
																												},

																												components: [
																													{
																														tagName: "div",
																														attributes: {
																															class: "data-left-ticket",
																														},

																														components: [
																															{
																																tagName: "span",
																																attributes: {
																																	class: "f-12",
																																},

																																components: [
																																	{
																															            tagName: "p",
																															            type: "text",
																															            editable: true,

																															            components: {
																															                type: "textnode",
																															                content: "Total Income",
																															            },
																															        },
																																],
																															}, {
																																tagName: "h5",
																																attributes: {
																																	class: "total-num",
																																},

																																components: [
																																	{
																																		tagName: "span",
																																		attributes: {
																																			class: "mr-1",
																																		},

																																		components: [
																																			{
																																	            tagName: "p",
																																	            type: "text",
																																	            editable: true,

																																	            components: {
																																	                type: "textnode",
																																	                content: "$",
																																	            },
																																	        },
																																		],
																																	}, {
																																		tagName: "span",
																																		attributes: {
																																			class: "counter",
																																		},

																																		components: [
																																			{
																																	            tagName: "p",
																																	            type: "text",
																																	            editable: true,

																																	            components: {
																																	                type: "textnode",
																																	                content: "4,685",
																																	            },
																																	        },
																																		],
																																	},
																																],
																															},
																														],
																													}, {
																														tagName: "div",
																														attributes: {
																															class: "data-right-ticket",
																														},

																														components: [
																															{
																																tagName: "div",
																																attributes: {
																																	class: "text-md-right",
																																},

																																components: [
																																	{
																																		tagName: "ul",

																																		components: [
																																			{
																																				tagName: "li",

																																				components: [
																																					{
																																						tagName: "span",
																																						attributes: {
																																							class: "f-12",
																																						},

																																						components: [
																																							{
																																					            tagName: "p",
																																					            type: "text",
																																					            editable: true,

																																					            components: {
																																					                type: "textnode",
																																					                content: "45% goal reached",
																																					            },
																																					        },
																																						],
																																					},
																																				],
																																			}, {
																																				tagName: "li",

																																				components: [
																																					{
																																						tagName: "h6",
																																						attributes: {
																																							class: "mb-0",
																																						},

																																						components: [
																																							{
																																					            tagName: "p",
																																					            type: "text",
																																					            editable: true,

																																					            components: {
																																					                type: "textnode",
																																					                content: "1,00,000",
																																					            },
																																					        },
																																						],
																																					},
																																				],
																																			},
																																		],
																																	},
																																],
																															},
																														],
																													},
																												],
																											}, {
																												tagName: "div",
																												attributes: {
																													class: "progress-showcase",
																												},

																												components: [
																													{
																														tagName: "div",
																														attributes: {
																															class: "progress sm-progress-bar",
																														},

																														components: [
																															{
																																tagName: "div",
																																attributes: {
																																	class: "progress-bar bg-primary",
																																	role: "progressbar",
																																	style: "width: 75%",
																																	"aria-valuenow": "25",
																																	"aria-valuemin": "0",
																																	"aria-valuemax": "100",
																																},
																															},
																														],
																													},
																												],
																											},
																										],
																									},
																								],
																							},
																						],
																					}, {
																						tagName: "div",
																						attributes: {
																							class: "col-6",
																						},

																						components: [
																							{
																								tagName: "div",
																								attributes: {
																									class: "card ecommerce-widget mb-0",
																								},

																								components: [
																									{
																										tagName: "div",
																										attributes: {
																											class: "card-body support-ticket-font",
																										},

																										// traits
																										Active_User_API: "",
																										Active_User_API_Username: "",
																							            Active_User_API_Password: "",
																							            Active_User_API_API_Key: "",
																							            Active_User_API_More_Headers: "",
																							            Active_User_API_Update_Frequency_P24H: 1,

																										Active_User: "",
																										Active_User_Goal_Reached_Percentage: "",
																										Active_User_Goal_Reached_Count: "",

																										traits: [
																											{
																								                type: "text",
																								                name: "Active_User_API",
																								                label: "Active User API",
																								                changeProp: true,
																								            }, {
																								                type: "text",
																								                name: "Active_User_API_Username",
																								                label: "Active User API Username",
																								                changeProp: true,
																								            }, {
																								                type: "text",
																								                name: "Active_User_API_Password",
																								                label: "Active User API Password",
																								                changeProp: true,
																								            }, {
																								                type: "text",
																								                name: "Active_User_API_API_Key",
																								                label: "Active User API API Key",
																								                changeProp: true,
																								            }, {
																								                type: "text",
																								                name: "Active_User_API_More_Headers",
																								                label: "Active User API More Headers",
																								                changeProp: true,
																								            }, {
																								                type: "number",
																								                name: "Active_User_API_Update_Frequency_P24H",
																								                label: "Update Frequency / 24 Hrs",
																								                min: "1",
																								                changeProp: true,
																								            }, {
																								                type: "text",
																								                name: "Active_User",
																								                label: "Active User",
																								                changeProp: true,
																								            }, {
																								                type: "text",
																								                name: "Active_User_Goal_Reached_Percentage",
																								                label: "Active User Goal Reached Percentage",
																								                changeProp: true,
																								            }, {
																								                type: "text",
																								                name: "Active_User_Goal_Reached_Count",
																								                label: "Active User Goal Reached Count",
																								                changeProp: true,
																								            },
																										],

																										changeProp: true,

																										"script-props": ["Active_User_API", "Active_User_API_Username", "Active_User_API_Password", "Active_User_API_API_Key", "Active_User_API_More_Headers", "Active_User_API_Update_Frequency_P24H", "Active_User", "Active_User_Goal_Reached_Percentage", "Active_User_Goal_Reached_Count"],

																										components: [
																											{
																												tagName: "div",
																												attributes: {
																													class: "d-flex",
																												},

																												components: [
																													{
																														tagName: "div",
																														attributes: {
																															class: "data-left-ticket",
																														},

																														components: [
																															{
																																tagName: "span",
																																attributes: {
																																	class: "f-12",
																																},

																																components: [
																																	{
																															            tagName: "p",
																															            type: "text",
																															            editable: true,

																															            components: {
																															                type: "textnode",
																															                content: "Active User",
																															            },
																															        },
																																],
																															}, {
																																tagName: "h5",
																																attributes: {
																																	class: "total-num",
																																},

																																components: [
																																	{
																																		tagName: "span",
																																		attributes: {
																																			class: "mr-1",
																																		},

																																		components: [
																																			{
																																	            tagName: "p",
																																	            type: "text",
																																	            editable: true,

																																	            components: {
																																	                type: "textnode",
																																	                content: "$",
																																	            },
																																	        },
																																		],
																																	}, {
																																		tagName: "span",
																																		attributes: {
																																			class: "counter",
																																		},

																																		components: [
																																			{
																																	            tagName: "p",
																																	            type: "text",
																																	            editable: true,

																																	            components: {
																																	                type: "textnode",
																																	                content: "3,513",
																																	            },
																																	        },
																																		],
																																	},
																																],
																															},
																														],
																													}, {
																														tagName: "div",
																														attributes: {
																															class: "data-right-ticket",
																														},

																														components: [
																															{
																																tagName: "div",
																																attributes: {
																																	class: "text-md-right",
																																},

																																components: [
																																	{
																																		tagName: "ul",

																																		components: [
																																			{
																																				tagName: "li",

																																				components: [
																																					{
																																						tagName: "span",
																																						attributes: {
																																							class: "f-12",
																																						},

																																						components: [
																																							{
																																					            tagName: "p",
																																					            type: "text",
																																					            editable: true,

																																					            components: {
																																					                type: "textnode",
																																					                content: "70% goal reached",
																																					            },
																																					        },
																																						],
																																					},
																																				],
																																			}, {
																																				tagName: "li",

																																				components: [
																																					{
																																						tagName: "h6",
																																						attributes: {
																																							class: "mb-0",
																																						},

																																						components: [
																																							{
																																					            tagName: "p",
																																					            type: "text",
																																					            editable: true,

																																					            components: {
																																					                type: "textnode",
																																					                content: "1,00,000",
																																					            },
																																					        },
																																						],
																																					},
																																				],
																																			},
																																		],
																																	},
																																],
																															},
																														],
																													},
																												],
																											}, {
																												tagName: "div",
																												attributes: {
																													class: "progress-showcase",
																												},

																												components: [
																													{
																														tagName: "div",
																														attributes: {
																															class: "progress sm-progress-bar",
																														},

																														components: [
																															{
																																tagName: "div",
																																attributes: {
																																	class: "progress-bar bg-primary",
																																	role: "progressbar",
																																	style: "width: 75%",
																																	"aria-valuenow": "25",
																																	"aria-valuemin": "0",
																																	"aria-valuemax": "100",
																																},
																															},
																														],
																													},
																												],
																											},
																										],
																									},
																								],
																							},
																						],
																					}, {
																						tagName: "div",
																						attributes: {
																							class: "col-6",
																						},

																						components: [
																							{
																								tagName: "div",
																								attributes: {
																									class: "card ecommerce-widget mb-0",
																								},

																								components: [
																									{
																										tagName: "div",
																										attributes: {
																											class: "card-body support-ticket-font",
																										},

																										// traits
																										Total_Tax_API: "",
																										Total_Tax_API_Username: "",
																							            Total_Tax_API_Password: "",
																							            Total_Tax_API_API_Key: "",
																							            Total_Tax_API_More_Headers: "",
																							            Total_Tax_API_Update_Frequency_P24H: 1,

																										Total_Tax: "",
																										Tax_Goal_Reached_Percentage: "",
																										Tax_Goal_Reached_Count: "",

																										traits: [
																											{
																								                type: "text",
																								                name: "Total_Tax_API",
																								                label: "Total Tax API",
																								                changeProp: true,
																								            }, {
																								                type: "text",
																								                name: "Total_Tax_API_Username",
																								                label: "Total Tax API Username",
																								                changeProp: true,
																								            }, {
																								                type: "text",
																								                name: "Total_Tax_API_Password",
																								                label: "Total Tax API Password",
																								                changeProp: true,
																								            }, {
																								                type: "text",
																								                name: "Total_Tax_API_API_Key",
																								                label: "Total Tax API API Key",
																								                changeProp: true,
																								            }, {
																								                type: "text",
																								                name: "Total_Tax_API_More_Headers",
																								                label: "Total Tax API More Headers",
																								                changeProp: true,
																								            }, {
																								                type: "number",
																								                name: "Total_Tax_API_Update_Frequency_P24H",
																								                label: "Update Frequency / 24 Hrs",
																								                min: "1",
																								                changeProp: true,
																								            }, {
																								                type: "text",
																								                name: "Total_Tax",
																								                label: "Total Tax",
																								                changeProp: true,
																								            }, {
																								                type: "text",
																								                name: "Tax_Goal_Reached_Percentage",
																								                label: "Tax Goal Reached Percentage",
																								                changeProp: true,
																								            }, {
																								                type: "text",
																								                name: "Tax_Goal_Reached_Count",
																								                label: "Tax Goal Reached Count",
																								                changeProp: true,
																								            },
																										],

																										changeProp: true,

																										"script-props": ["Total_Tax_API", "Total_Tax_API_Username", "Total_Tax_API_Password", "Total_Tax_API_API_Key", "Total_Tax_API_More_Headers", "Total_Tax_API_Update_Frequency_P24H", "Total_Tax", "Tax_Goal_Reached_Percentage", "Tax_Goal_Reached_Count"],

																										components: [
																											{
																												tagName: "div",
																												attributes: {
																													class: "d-flex",
																												},

																												components: [
																													{
																														tagName: "div",
																														attributes: {
																															class: "data-left-ticket",
																														},

																														components: [
																															{
																																tagName: "span",
																																attributes: {
																																	class: "f-12",
																																},

																																components: [
																																	{
																															            tagName: "p",
																															            type: "text",
																															            editable: true,

																															            components: {
																															                type: "textnode",
																															                content: "Total Tax",
																															            },
																															        },
																																],
																															}, {
																																tagName: "h5",
																																attributes: {
																																	class: "total-num",
																																},

																																components: [
																																	{
																																		tagName: "span",
																																		attributes: {
																																			class: "mr-1",
																																		},

																																		components: [
																																			{
																																	            tagName: "p",
																																	            type: "text",
																																	            editable: true,

																																	            components: {
																																	                type: "textnode",
																																	                content: "$",
																																	            },
																																	        },
																																		],
																																	}, {
																																		tagName: "span",
																																		attributes: {
																																			class: "counter",
																																		},

																																		components: [
																																			{
																																	            tagName: "p",
																																	            type: "text",
																																	            editable: true,

																																	            components: {
																																	                type: "textnode",
																																	                content: "2,749",
																																	            },
																																	        },
																																		],
																																	},
																																],
																															},
																														],
																													}, {
																														tagName: "div",
																														attributes: {
																															class: "data-right-ticket",
																														},

																														components: [
																															{
																																tagName: "div",
																																attributes: {
																																	class: "text-md-right",
																																},

																																components: [
																																	{
																																		tagName: "ul",

																																		components: [
																																			{
																																				tagName: "li",

																																				components: [
																																					{
																																						tagName: "span",
																																						attributes: {
																																							class: "f-12",
																																						},

																																						components: [
																																							{
																																					            tagName: "p",
																																					            type: "text",
																																					            editable: true,

																																					            components: {
																																					                type: "textnode",
																																					                content: "52% goal reached",
																																					            },
																																					        },
																																						],
																																					},
																																				],
																																			}, {
																																				tagName: "li",

																																				components: [
																																					{
																																						tagName: "h6",
																																						attributes: {
																																							class: "mb-0",
																																						},

																																						components: [
																																							{
																																					            tagName: "p",
																																					            type: "text",
																																					            editable: true,

																																					            components: {
																																					                type: "textnode",
																																					                content: "1,00,000",
																																					            },
																																					        },
																																						],
																																					},
																																				],
																																			},
																																		],
																																	},
																																],
																															},
																														],
																													},
																												],
																											}, {
																												tagName: "div",
																												attributes: {
																													class: "progress-showcase",
																												},

																												components: [
																													{
																														tagName: "div",
																														attributes: {
																															class: "progress sm-progress-bar",
																														},

																														components: [
																															{
																																tagName: "div",
																																attributes: {
																																	class: "progress-bar bg-primary",
																																	role: "progressbar",
																																	style: "width: 75%",
																																	"aria-valuenow": "25",
																																	"aria-valuemin": "0",
																																	"aria-valuemax": "100",
																																},
																															},
																														],
																													},
																												],
																											},
																										],
																									},
																								],
																							},
																						],
																					},
																				],
																			},
																		],
																	},
																],
															},
														],
													},
												],
											},
										],
									}, {
										tagName: "div",
										attributes: {
											class: "col-xl-6 xl-100 box-col-12",
										},

										components: [
											{
												tagName: "div",
												attributes: {
													class: "card card-with-border total-users-lists",
												},

												// traits
												Table1_API: "",
												Table1_API_Username: "",
									            Table1_API_Password: "",
									            Table1_API_API_Key: "",
									            Table1_API_More_Headers: "",
									            Table1_API_Update_Frequency_P24H: 1,

									            Table1_Keyname: "",

									            traits: [
													{
										                type: "text",
										                name: "Table1_API",
										                label: "Table1 API",
										                changeProp: true,
										            }, {
										                type: "text",
										                name: "Table1_API_Username",
										                label: "Table1 API Username",
										                changeProp: true,
										            }, {
										                type: "text",
										                name: "Table1_API_Password",
										                label: "Table1 API Password",
										                changeProp: true,
										            }, {
										                type: "text",
										                name: "Table1_API_API_Key",
										                label: "Table1 API API_Key",
										                changeProp: true,
										            }, {
										                type: "text",
										                name: "Table1_API_More_Headers",
										                label: "Table1 API More Headers",
										                changeProp: true,
										            }, {
										                type: "number",
										                name: "Table1_API_Update_Frequency_P24H",
										                label: "Update Frequency / 24 Hrs",
										                min: "1",
										                changeProp: true,
										            }, {
										                type: "text",
										                name: "Table1_Keyname",
										                label: "Table1 Keyname",
										                changeProp: true,
										            },
												],

												changeProp: true,

												"script-props": ["Table1_API", "Table1_API_Username", "Table1_API_Password", "Table1_API_API_Key", "Table1_API_More_Headers", "Table1_API_Update_Frequency_P24H", "Table1_Keyname"],

												components: [
													{
														tagName: "div",
														attributes: {
															class: "card-header card-no-border",
														},
														
														components: [
															{
																tagName: "h5",
																
																components: [
																	{
															            tagName: "p",
															            type: "text",
															            editable: true,

															            components: {
															                type: "textnode",
															                content: "Total New Venders",
															            },
															        },
																],
															}, {
																tagName: "span",

																components: [
																	{
															            tagName: "p",
															            type: "text",
															            editable: true,

															            components: {
															                type: "textnode",
															                content: "Number of Totally Users",
															            },
															        },
																],
															}
														],
													}, {
														tagName: "div",
														attributes: {
															class: "card-body p-0",
														},

														components: [
															{
																tagName: "div",
																attributes: {
																	class: "users-total table-responsive",
																},

																components: [
																	{
																		tagName: "table",
																		attributes: {
																			class: "table table-bordernone table1",
																		},

																		components: [
																			{
																				tagName: "thead",

																				components: [
																					{
																						tagName: "tr",

																						components: [
																							{
																								tagName: "th",

																								components: [
																									{
																							            tagName: "p",
																							            type: "text",
																							            editable: true,

																							            components: {
																							                type: "textnode",
																							                content: "Name",
																							            },
																							        },
																								],
																							}, {
																								tagName: "th",

																								components: [
																									{
																							            tagName: "p",
																							            type: "text",
																							            editable: true,

																							            components: {
																							                type: "textnode",
																							                content: "Country",
																							            },
																							        },
																								],
																							}, {
																								tagName: "th",

																								components: [
																									{
																							            tagName: "p",
																							            type: "text",
																							            editable: true,

																							            components: {
																							                type: "textnode",
																							                content: "Date",
																							            },
																							        },
																								],
																							}, {
																								tagName: "th",

																								components: [
																									{
																							            tagName: "p",
																							            type: "text",
																							            editable: true,

																							            components: {
																							                type: "textnode",
																							                content: "Payment",
																							            },
																							        },
																								],
																							}, {
																								tagName: "th",

																								components: [
																									{
																							            tagName: "p",
																							            type: "text",
																							            editable: true,

																							            components: {
																							                type: "textnode",
																							                content: "Total",
																							            },
																							        },
																								],
																							},
																						],
																					},
																				],
																			}, {
																				tagName: "tbody",

																				components: [
																					{
																						tagName: "tr",

																						components: [
																							{
																								tagName: "td",

																								components: [
																									{
																										tagName: "div",

																										attributes: {
																											class: "d-inline-block align-middle",
																										},

																										components: [
																											{
																												tagName: "img",
																												attributes: {
																													class: "img-40 align-top m-r-15 b-r-10",
																													src: "/assets/images/user/2.png",
																												},
																											}, {
																												tagName: "div",
																												attributes: {
																													class: "d-inline-block",
																												},

																												components: [
																													{
																														tagName: "span",

																														components: [
																															{
																													            tagName: "p",
																													            type: "text",
																													            editable: true,

																													            components: {
																													                type: "textnode",
																													                content: "Nick Stone",
																													            },
																													        },
																														],
																													},
																												],
																											},
																										],
																									},
																								],
																							}, {
																								tagName: "td",

																								components: [
																									{
																										tagName: "i",
																										attributes: {
																											class: "flag-icon flag-icon-gb",
																										},
																									},
																								],
																							}, {
																								tagName: "td",

																								components: [
																									{
																										tagName: "span",
																										attributes: {
																											class: "f-12",
																										},

																										components: [
																											{
																									            tagName: "p",
																									            type: "text",
																									            editable: true,

																									            components: {
																									                type: "textnode",
																									                content: "10 May 2018",
																									            },
																									        },
																										],
																									},
																								],
																							}, {
																								tagName: "td",

																								components: [
																									{
																										tagName: "i",

																										components: [
																											{
																									            tagName: "p",
																									            type: "text",
																									            editable: true,

																									            components: {
																									                type: "textnode",
																									                content: "COD",
																									            },
																									        },
																										],
																									},
																								],
																							}, {
																								tagName: "td",

																								components: [
																									{
																										tagName: "span",

																										components: [
																											{
																									            tagName: "p",
																									            type: "text",
																									            editable: true,

																									            components: {
																									                type: "textnode",
																									                content: "8652.00 $",
																									            },
																									        },
																										],
																									},
																								],
																							},
																						],
																					}, {
																						tagName: "tr",

																						components: [
																							{
																								tagName: "td",

																								components: [
																									{
																										tagName: "div",

																										attributes: {
																											class: "d-inline-block align-middle",
																										},

																										components: [
																											{
																												tagName: "img",
																												attributes: {
																													class: "img-40 align-top m-r-15 b-r-10",
																													src: "/assets/images/user/5.png",
																												},
																											}, {
																												tagName: "div",
																												attributes: {
																													class: "d-inline-block",
																												},

																												components: [
																													{
																														tagName: "span",

																														components: [
																															{
																													            tagName: "p",
																													            type: "text",
																													            editable: true,

																													            components: {
																													                type: "textnode",
																													                content: "Milano Esco",
																													            },
																													        },
																														],
																													},
																												],
																											},
																										],
																									},
																								],
																							}, {
																								tagName: "td",

																								components: [
																									{
																										tagName: "i",
																										attributes: {
																											class: "flag-icon flag-icon-lr",
																										},
																									},
																								],
																							}, {
																								tagName: "td",

																								components: [
																									{
																										tagName: "span",
																										attributes: {
																											class: "f-12",
																										},

																										components: [
																											{
																									            tagName: "p",
																									            type: "text",
																									            editable: true,

																									            components: {
																									                type: "textnode",
																									                content: "10 May 2018",
																									            },
																									        },
																										],
																									},
																								],
																							}, {
																								tagName: "td",

																								components: [
																									{
																										tagName: "i",

																										components: [
																											{
																									            tagName: "p",
																									            type: "text",
																									            editable: true,

																									            components: {
																									                type: "textnode",
																									                content: "COD",
																									            },
																									        },
																										],
																									},
																								],
																							}, {
																								tagName: "td",

																								components: [
																									{
																										tagName: "span",

																										components: [
																											{
																									            tagName: "p",
																									            type: "text",
																									            editable: true,

																									            components: {
																									                type: "textnode",
																									                content: "102.00 $",
																									            },
																									        },
																										],
																									},
																								],
																							},
																						],
																					}, {
																						tagName: "tr",

																						components: [
																							{
																								tagName: "td",

																								components: [
																									{
																										tagName: "div",

																										attributes: {
																											class: "d-inline-block align-middle",
																										},

																										components: [
																											{
																												tagName: "img",
																												attributes: {
																													class: "img-40 align-top m-r-15 b-r-10",
																													src: "/assets/images/user/3.png",
																												},
																											}, {
																												tagName: "div",
																												attributes: {
																													class: "d-inline-block",
																												},

																												components: [
																													{
																														tagName: "span",

																														components: [
																															{
																													            tagName: "p",
																													            type: "text",
																													            editable: true,

																													            components: {
																													                type: "textnode",
																													                content: "Wiltor Noice",
																													            },
																													        },
																														],
																													},
																												],
																											},
																										],
																									},
																								],
																							}, {
																								tagName: "td",

																								components: [
																									{
																										tagName: "i",
																										attributes: {
																											class: "flag-icon flag-icon-za",
																										},
																									},
																								],
																							}, {
																								tagName: "td",

																								components: [
																									{
																										tagName: "span",
																										attributes: {
																											class: "f-12",
																										},

																										components: [
																											{
																									            tagName: "p",
																									            type: "text",
																									            editable: true,

																									            components: {
																									                type: "textnode",
																									                content: "10 May 2018",
																									            },
																									        },
																										],
																									},
																								],
																							}, {
																								tagName: "td",

																								components: [
																									{
																										tagName: "i",

																										components: [
																											{
																									            tagName: "p",
																									            type: "text",
																									            editable: true,

																									            components: {
																									                type: "textnode",
																									                content: "Cash",
																									            },
																									        },
																										],
																									},
																								],
																							}, {
																								tagName: "td",

																								components: [
																									{
																										tagName: "span",

																										components: [
																											{
																									            tagName: "p",
																									            type: "text",
																									            editable: true,

																									            components: {
																									                type: "textnode",
																									                content: "2315.00 $",
																									            },
																									        },
																										],
																									},
																								],
																							},
																						],
																					},
																				],
																			},
																		],
																	},
																],
															},
														],
													},
												],
											},
										],
									}, {
										tagName: "div",
										attributes: {
											class: "col-xl-6 xl-100 box-col-12",
										},

										components: [
											{
												tagName: "div",
												attributes: {
													class: "card card-with-border total-users-lists",
												},

												// traits
												Table2_API: "",
												Table2_API_Username: "",
									            Table2_API_Password: "",
									            Table2_API_API_Key: "",
									            Table2_API_More_Headers: "",
									            Table2_API_Update_Frequency_P24H: 1,

									            Table1_Keyname: "",

									            traits: [
													{
										                type: "text",
										                name: "Table2_API",
										                label: "Table2 API",
										                changeProp: true,
										            }, {
										                type: "text",
										                name: "Table2_API_Username",
										                label: "Table2 API Username",
										                changeProp: true,
										            }, {
										                type: "text",
										                name: "Table2_API_Password",
										                label: "Table2 API Password",
										                changeProp: true,
										            }, {
										                type: "text",
										                name: "Table2_API_API_Key",
										                label: "Table2 API API_Key",
										                changeProp: true,
										            }, {
										                type: "text",
										                name: "Table2_API_More_Headers",
										                label: "Table2 API More Headers",
										                changeProp: true,
										            }, {
										                type: "number",
										                name: "Table2_API_Update_Frequency_P24H",
										                label: "Update Frequency / 24 Hrs",
										                min: "1",
										                changeProp: true,
										            }, {
										                type: "text",
										                name: "Table2_Keyname",
										                label: "Table2 Keyname",
										                changeProp: true,
										            },
												],

												changeProp: true,

												"script-props": ["Table2_API", "Table2_API_Username", "Table2_API_Password", "Table2_API_API_Key", "Table2_API_More_Headers", "Table2_API_Update_Frequency_P24H", "Table2_Keyname"],

												components: [
													{
														tagName: "div",
														attributes: {
															class: "card-header card-no-border",
														},
														
														components: [
															{
																tagName: "h5",
																
																components: [
																	{
															            tagName: "p",
															            type: "text",
															            editable: true,

															            components: {
															                type: "textnode",
															                content: "Today Transaction Details",
															            },
															        },
																],
															}, {
																tagName: "span",

																components: [
																	{
															            tagName: "p",
															            type: "text",
															            editable: true,

															            components: {
															                type: "textnode",
															                content: "Today's transaction",
															            },
															        },
																],
															}
														],
													}, {
														tagName: "div",
														attributes: {
															class: "card-body p-0",
														},

														components: [
															{
																tagName: "div",
																attributes: {
																	class: "users-total table-responsive",
																},

																components: [
																	{
																		tagName: "table",
																		attributes: {
																			class: "table table-bordernone table2",
																		},

																		components: [
																			{
																				tagName: "thead",

																				components: [
																					{
																						tagName: "tr",

																						components: [
																							{
																								tagName: "th",

																								components: [
																									{
																							            tagName: "p",
																							            type: "text",
																							            editable: true,

																							            components: {
																							                type: "textnode",
																							                content: "Name",
																							            },
																							        },
																								],
																							}, {
																								tagName: "th",

																								components: [
																									{
																							            tagName: "p",
																							            type: "text",
																							            editable: true,

																							            components: {
																							                type: "textnode",
																							                content: "Country",
																							            },
																							        },
																								],
																							}, {
																								tagName: "th",

																								components: [
																									{
																							            tagName: "p",
																							            type: "text",
																							            editable: true,

																							            components: {
																							                type: "textnode",
																							                content: "Date",
																							            },
																							        },
																								],
																							}, {
																								tagName: "th",

																								components: [
																									{
																							            tagName: "p",
																							            type: "text",
																							            editable: true,

																							            components: {
																							                type: "textnode",
																							                content: "Payment",
																							            },
																							        },
																								],
																							}, {
																								tagName: "th",

																								components: [
																									{
																							            tagName: "p",
																							            type: "text",
																							            editable: true,

																							            components: {
																							                type: "textnode",
																							                content: "Total",
																							            },
																							        },
																								],
																							},
																						],
																					},
																				],
																			}, {
																				tagName: "tbody",

																				components: [
																					{
																						tagName: "tr",

																						components: [
																							{
																								tagName: "td",

																								components: [
																									{
																										tagName: "div",

																										attributes: {
																											class: "d-inline-block align-middle",
																										},

																										components: [
																											{
																												tagName: "img",
																												attributes: {
																													class: "img-40 align-top m-r-15 b-r-10",
																													src: "/assets/images/user/2.png",
																												},
																											}, {
																												tagName: "div",
																												attributes: {
																													class: "d-inline-block",
																												},

																												components: [
																													{
																														tagName: "span",

																														components: [
																															{
																													            tagName: "p",
																													            type: "text",
																													            editable: true,

																													            components: {
																													                type: "textnode",
																													                content: "Nick Stone",
																													            },
																													        },
																														],
																													},
																												],
																											},
																										],
																									},
																								],
																							}, {
																								tagName: "td",

																								components: [
																									{
																										tagName: "i",
																										attributes: {
																											class: "flag-icon flag-icon-gb",
																										},
																									},
																								],
																							}, {
																								tagName: "td",

																								components: [
																									{
																										tagName: "span",
																										attributes: {
																											class: "f-12",
																										},

																										components: [
																											{
																									            tagName: "p",
																									            type: "text",
																									            editable: true,

																									            components: {
																									                type: "textnode",
																									                content: "10 May 2018",
																									            },
																									        },
																										],
																									},
																								],
																							}, {
																								tagName: "td",

																								components: [
																									{
																										tagName: "i",

																										components: [
																											{
																									            tagName: "p",
																									            type: "text",
																									            editable: true,

																									            components: {
																									                type: "textnode",
																									                content: "COD",
																									            },
																									        },
																										],
																									},
																								],
																							}, {
																								tagName: "td",

																								components: [
																									{
																										tagName: "span",

																										components: [
																											{
																									            tagName: "p",
																									            type: "text",
																									            editable: true,

																									            components: {
																									                type: "textnode",
																									                content: "8652.00 $",
																									            },
																									        },
																										],
																									},
																								],
																							},
																						],
																					}, {
																						tagName: "tr",

																						components: [
																							{
																								tagName: "td",

																								components: [
																									{
																										tagName: "div",

																										attributes: {
																											class: "d-inline-block align-middle",
																										},

																										components: [
																											{
																												tagName: "img",
																												attributes: {
																													class: "img-40 align-top m-r-15 b-r-10",
																													src: "/assets/images/user/5.png",
																												},
																											}, {
																												tagName: "div",
																												attributes: {
																													class: "d-inline-block",
																												},

																												components: [
																													{
																														tagName: "span",

																														components: [
																															{
																													            tagName: "p",
																													            type: "text",
																													            editable: true,

																													            components: {
																													                type: "textnode",
																													                content: "Milano Esco",
																													            },
																													        },
																														],
																													},
																												],
																											},
																										],
																									},
																								],
																							}, {
																								tagName: "td",

																								components: [
																									{
																										tagName: "i",
																										attributes: {
																											class: "flag-icon flag-icon-lr",
																										},
																									},
																								],
																							}, {
																								tagName: "td",

																								components: [
																									{
																										tagName: "span",
																										attributes: {
																											class: "f-12",
																										},

																										components: [
																											{
																									            tagName: "p",
																									            type: "text",
																									            editable: true,

																									            components: {
																									                type: "textnode",
																									                content: "10 May 2018",
																									            },
																									        },
																										],
																									},
																								],
																							}, {
																								tagName: "td",

																								components: [
																									{
																										tagName: "i",

																										components: [
																											{
																									            tagName: "p",
																									            type: "text",
																									            editable: true,

																									            components: {
																									                type: "textnode",
																									                content: "COD",
																									            },
																									        },
																										],
																									},
																								],
																							}, {
																								tagName: "td",

																								components: [
																									{
																										tagName: "span",

																										components: [
																											{
																									            tagName: "p",
																									            type: "text",
																									            editable: true,

																									            components: {
																									                type: "textnode",
																									                content: "102.00 $",
																									            },
																									        },
																										],
																									},
																								],
																							},
																						],
																					}, {
																						tagName: "tr",

																						components: [
																							{
																								tagName: "td",

																								components: [
																									{
																										tagName: "div",

																										attributes: {
																											class: "d-inline-block align-middle",
																										},

																										components: [
																											{
																												tagName: "img",
																												attributes: {
																													class: "img-40 align-top m-r-15 b-r-10",
																													src: "/assets/images/user/3.png",
																												},
																											}, {
																												tagName: "div",
																												attributes: {
																													class: "d-inline-block",
																												},

																												components: [
																													{
																														tagName: "span",

																														components: [
																															{
																													            tagName: "p",
																													            type: "text",
																													            editable: true,

																													            components: {
																													                type: "textnode",
																													                content: "Wiltor Noice",
																													            },
																													        },
																														],
																													},
																												],
																											},
																										],
																									},
																								],
																							}, {
																								tagName: "td",

																								components: [
																									{
																										tagName: "i",
																										attributes: {
																											class: "flag-icon flag-icon-za",
																										},
																									},
																								],
																							}, {
																								tagName: "td",

																								components: [
																									{
																										tagName: "span",
																										attributes: {
																											class: "f-12",
																										},

																										components: [
																											{
																									            tagName: "p",
																									            type: "text",
																									            editable: true,

																									            components: {
																									                type: "textnode",
																									                content: "10 May 2018",
																									            },
																									        },
																										],
																									},
																								],
																							}, {
																								tagName: "td",

																								components: [
																									{
																										tagName: "i",

																										components: [
																											{
																									            tagName: "p",
																									            type: "text",
																									            editable: true,

																									            components: {
																									                type: "textnode",
																									                content: "Cash",
																									            },
																									        },
																										],
																									},
																								],
																							}, {
																								tagName: "td",

																								components: [
																									{
																										tagName: "span",

																										components: [
																											{
																									            tagName: "p",
																									            type: "text",
																									            editable: true,

																									            components: {
																									                type: "textnode",
																									                content: "2315.00 $",
																									            },
																									        },
																										],
																									},
																								],
																							},
																						],
																					},
																				],
																			},
																		],
																	},
																],
															},
														],
													},
												],
											},
										],
									}, {
										tagName: "div",
										attributes: {
											class: "col-xl-5 xl-100 box-col-12",
										},

										components: [
											{
												tagName: "div",
												attributes: {
													class: "card investments card-with-border",
												},

												// traits
												Total_Investment_API: "",
												Total_Investment_API_Username: "",
									            Total_Investment_API_Password: "",
									            Total_Investment_API_API_Key: "",
									            Total_Investment_API_More_Headers: "", // input should be like: a:b,c:d,e:f
									            Total_Investment_API_Update_Frequency_P24H: 1,

									            Total_Response_Keyname: "",
									            Monthly_Response_Keyname: "",
									            Daily_Response_Keyname: "",

									            traits: [
										            {
										                type: "text",
										                name: "Total_Investment_API",
										                label: "Total Investment API",
										                changeProp: true,
										            }, {
										                type: "text",
										                name: "Total_Investment_API_Username",
										                label: "Total Investment API Username",
										                changeProp: true,
										            }, {
										                type: "text",
										                name: "Total_Investment_API_Password",
										                label: "Total Investment API Password",
										                changeProp: true,
										            }, {
										                type: "text",
										                name: "Total_Investment_API_API_Key",
										                label: "Total Investment API API Key",
										                changeProp: true,
										            }, {
										                type: "text",
										                name: "Total_Investment_API_More_Headers",
										                label: "Total Investment API More Headers",
										                changeProp: true,
										            }, {
										                type: "number",
										                name: "Total_Investment_API_Update_Frequency_P24H",
										                label: "Total Investment API Update Frequency P24H",
										                min: "1",
										                changeProp: true,
										            }, {
										                type: "text",
										                name: "Total_Response_Keyname",
										                label: "Total Response Keyname",
										                changeProp: true,
										            }, {
										                type: "text",
										                name: "Monthly_Response_Keyname",
										                label: "Monthly Response Keyname",
										                changeProp: true,
										            }, {
										                type: "text",
										                name: "Daily_Response_Keyname",
										                label: "Daily Response Keyname",
										                changeProp: true,
										            },
										        ],

										        changeProp: true,

										        "script-props": ["Total_Investment_API", "Total_Investment_API_Username", "Total_Investment_API_Password", "Total_Investment_API_API_Key", "Total_Investment_API_More_Headers", "Total_Investment_API_Update_Frequency_P24H", "Total_Response_Keyname", "Monthly_Response_Keyname", "Daily_Response_Keyname"],

												components: [
													{
														tagName: "div",
														attributes: {
															class: "card-header card-no-border",
														},

														components: [
															{
																tagName: "h5",
																
																components: [
																	{
															            tagName: "p",
															            type: "text",
															            editable: true,

															            components: {
															                type: "textnode",
															                content: "Total Investment",
															            },
															        },
																],
															}, {
																tagName: "span",

																components: [
																	{
															            tagName: "p",
															            type: "text",
															            editable: true,

															            components: {
															                type: "textnode",
															                content: "Number of  Revenue Growth",
															            },
															        },
																],
															},
														],
													}, {
														tagName: "div",
														attributes: {
															class: "card-body p-0",
														},

														components: [
															// PIE CHART COMPONENT
															{
																tagName: "div",
																attributes: {
																	class: "circlechart",
																	style: "min-height: 375px;",
																},

																components: [
																	{
																		tagName: "div",
																		attributes: {
																			id: "apexcharts0i8qat3n",
																			class: "apexcharts-canvas apexcharts0i8qat3n",
																			style: "width: 100%; height: 30em; margin: auto;"
																		},

																		components: [
																			// {
																			// 	tagName: "svg",
																			// 	attributes: {
																			// 		id: "SvgjsSvg2080",
																			// 		width: "305",
																			// 		height: "120",
																			// 		xmlns: "http://www.w3.org/2000/svg",
																			// 		version: "1.1",
																			// 		"xmlns:xlink": "http://www.w3.org/1999/xlink",
																			// 		"xmlns:svgjs": "http://svgjs.com/svgjs",
																			// 		class: "apexcharts-svg",
																			// 		"xmlns:data": "ApexChartsNS",
																			// 		transform: "translate(0, 0)",
																			// 		style: "background: transparent;"
																			// 	},
																			// },

																			{
																				tagName: "div",																						
																				attributes: {
																					id: "chart-container-4",
																					style: "width: 90%; height: 90%; margin: auto; display: flex; justify-content: center; align-items: center;",
																				},

																				components: [
																					{
																			            tagName: "p",
																			            type: "text",
																			            editable: true,

																			            components: {
																			                type: "textnode",
																			                content: "Space for a 'Chart/Graph' component",
																			            },
																			        },
																				],
																			},
																		],
																	},
																],
															},
														],
													}, {
														tagName: "div",
														attributes: {
															class: "card-footer p-0",
														},

														components: [
															{
																tagName: "ul",

																components: [
																	{
																		tagName: "li",
																		attributes: {
																			class: "text-center",
																		},

																		components: [
																			{
																				tagName: "span",
																				attributes: {
																					class: "f-12",
																				},

																				components: [
																					{
																			            tagName: "p",
																			            type: "text",
																			            editable: true,

																			            components: {
																			                type: "textnode",
																			                content: "Total",
																			            },
																			        },
																				],
																			}, {
																				tagName: "h6",
																				attributes: {
																					class: "f-w-600 mb-0",
																				},

																				components: [
																					{
																			            tagName: "p",
																			            type: "text",
																			            editable: true,

																			            components: {
																			                type: "textnode",
																			                content: "$34,4562",
																			            },
																			        },
																				],
																			},
																		],
																	}, {
																		tagName: "li",
																		attributes: {
																			class: "text-center",
																		},

																		components: [
																			{
																				tagName: "span",
																				attributes: {
																					class: "f-12",
																				},

																				components: [
																					{
																			            tagName: "p",
																			            type: "text",
																			            editable: true,

																			            components: {
																			                type: "textnode",
																			                content: "Monthly",
																			            },
																			        },
																				],
																			}, {
																				tagName: "h6",
																				attributes: {
																					class: "f-w-600 mb-0",
																				},

																				components: [
																					{
																			            tagName: "p",
																			            type: "text",
																			            editable: true,

																			            components: {
																			                type: "textnode",
																			                content: "$12,463",
																			            },
																			        },
																				],
																			},
																		],
																	}, {
																		tagName: "li",
																		attributes: {
																			class: "text-center",
																		},

																		components: [
																			{
																				tagName: "span",
																				attributes: {
																					class: "f-12",
																				},

																				components: [
																					{
																			            tagName: "p",
																			            type: "text",
																			            editable: true,

																			            components: {
																			                type: "textnode",
																			                content: "Daily",
																			            },
																			        },
																				],
																			}, {
																				tagName: "h6",
																				attributes: {
																					class: "f-w-600 mb-0",
																				},

																				components: [
																					{
																			            tagName: "p",
																			            type: "text",
																			            editable: true,

																			            components: {
																			                type: "textnode",
																			                content: "$5000",
																			            },
																			        },
																				],
																			},
																		],
																	},
																],
															},
														],
													},
												],
											},
										],
									}, {
										tagName: "div",
										attributes: {
											class: "col-xl-7 xl-100 box-col-12",
										},

										components: [
											{
												tagName: "div",
												attributes: {
													class: "card card-with-border total-users-lists",
												},

												// traits
												Table3_API: "",
												Table3_API_Username: "",
									            Table3_API_Password: "",
									            Table3_API_API_Key: "",
									            Table3_API_More_Headers: "",
									            Table3_API_Update_Frequency_P24H: 1,

									            Table1_Keyname: "",

									            traits: [
													{
										                type: "text",
										                name: "Table3_API",
										                label: "Table3 API",
										                changeProp: true,
										            }, {
										                type: "text",
										                name: "Table3_API_Username",
										                label: "Table3 API Username",
										                changeProp: true,
										            }, {
										                type: "text",
										                name: "Table3_API_Password",
										                label: "Table3 API Password",
										                changeProp: true,
										            }, {
										                type: "text",
										                name: "Table3_API_API_Key",
										                label: "Table3 API API_Key",
										                changeProp: true,
										            }, {
										                type: "text",
										                name: "Table3_API_More_Headers",
										                label: "Table3 API More Headers",
										                changeProp: true,
										            }, {
										                type: "number",
										                name: "Table3_API_Update_Frequency_P24H",
										                label: "Update Frequency / 24 Hrs",
										                min: "1",
										                changeProp: true,
										            }, {
										                type: "text",
										                name: "Table3_Keyname",
										                label: "Table3 Keyname",
										                changeProp: true,
										            },
												],

												changeProp: true,

												"script-props": ["Table3_API", "Table3_API_Username", "Table3_API_Password", "Table3_API_API_Key", "Table3_API_More_Headers", "Table3_API_Update_Frequency_P24H", "Table3_Keyname"],

												components: [
													{
														tagName: "div",
														attributes: {
															class: "card-header card-no-border",
														},

														components: [
															{
																tagName: "h5",

																components: [
																	{
															            tagName: "p",
															            type: "text",
															            editable: true,

															            components: {
															                type: "textnode",
															                content: "Product Details",
															            },
															        },
																],
															}, {
																tagName: "span",
											
																components: [
																	{
															            tagName: "p",
															            type: "text",
															            editable: true,

															            components: {
															                type: "textnode",
															                content: "Details of our best sale product",
															            },
															        },
																],
															},
														],
													}, {
														tagName: "div",
														attributes: {
															class: "card-body p-0",
														},

														components: [
															{
																tagName: "div",
																attributes: {
																	class: "users-total table-responsive product-sales",
																},

																components: [
																	{
																		tagName: "table",
																		attributes: {
																			class: "table table-bordernone table3",
																		},

																		components: [
																			{
																				tagName: "thead",

																				components: [
																					{
																						tagName: "tr",

																						components: [
																							{
																								tagName: "th",

																								components: [
																									{
																							            tagName: "p",
																							            type: "text",
																							            editable: true,

																							            components: {
																							                type: "textnode",
																							                content: "Name",
																							            },
																							        },
																								],
																							}, {
																								tagName: "th",

																								components: [
																									{
																							            tagName: "p",
																							            type: "text",
																							            editable: true,

																							            components: {
																							                type: "textnode",
																							                content: "Sale",
																							            },
																							        },
																								],
																							}, {
																								tagName: "th",

																								components: [
																									{
																							            tagName: "p",
																							            type: "text",
																							            editable: true,

																							            components: {
																							                type: "textnode",
																							                content: "Shipping",
																							            },
																							        },
																								],
																							}, {
																								tagName: "th",

																								components: [
																									{
																							            tagName: "p",
																							            type: "text",
																							            editable: true,

																							            components: {
																							                type: "textnode",
																							                content: "MRP",
																							            },
																							        },
																								],
																							}, {
																								tagName: "th",

																								components: [
																									{
																							            tagName: "p",
																							            type: "text",
																							            editable: true,

																							            components: {
																							                type: "textnode",
																							                content: "Avaiablility",
																							            },
																							        },
																								],
																							},
																						],
																					},
																				],
																			}, {
																				tagName: "tbody",

																				components: [
																					{
																						tagName: "tr",

																						components: [
																							{
																								tagName: "td",

																								components: [
																									{
																										tagName: "div",
																										attributes: {
																											class: "d-flex",
																										},

																										components: [
																											{
																												tagName: "div",
																												attributes: {
																													class: "round-product m-r-15",
																												},

																												components: [
																													{
																														tagName: "i",
																														attributes: {
																															class: "icofont icofont-jersey",
																														},
																													},
																												],
																											}, {
																												tagName: "span",

																												components: [
																													{
																											            tagName: "p",
																											            type: "text",
																											            editable: true,

																											            components: {
																											                type: "textnode",
																											                content: "Solid Men Turtle Neck Black",
																											            },
																											        },
																												],
																											},
																										],
																									},
																								],
																							}, {
																								tagName: "td",

																								components: [
																									{
																										tagName: "span",
																										attributes: {
																											class: "f-12",
																										},

																										components: [
																											{
																									            tagName: "p",
																									            type: "text",
																									            editable: true,

																									            components: {
																									                type: "textnode",
																									                content: "11",
																									            },
																									        },
																										],
																									},
																								],
																							}, {
																								tagName: "td",

																								components: [
																									{
																										tagName: "div",
																										attributes: {
																											class: "progress-showcase",
																										},

																										components: [
																											{
																									            tagName: "div",
																									            attributes: {
																													class: "progress sm-progress-bar",
																												},

																												components: [
																													{
																											            tagName: "div",
																											            attributes: {
																															class: "progress-bar bg-success",
																															role: "progressbar",
																															style: "width: 75%",
																															"aria-valuenow": "25",
																															"aria-valuemin": "0",
																															"aria-valuemax": "100",
																														},
																											        },
																												],
																									        },
																										],
																									},
																								],
																							}, {
																								tagName: "td",

																								components: [
																									{
																							            tagName: "p",
																							            type: "text",
																							            editable: true,

																							            components: {
																							                type: "textnode",
																							                content: "$5.15",
																							            },
																							        },
																								],
																							}, {
																								tagName: "td",
																								attributes: {
																									class: "txt-success",
																								},

																								components: [
																									{
																							            tagName: "p",
																							            type: "text",
																							            editable: true,

																							            components: {
																							                type: "textnode",
																							                content: "In Stock",
																							            },
																							        },
																								],
																							},
																						],
																					}, {
																						tagName: "tr",

																						components: [
																							{
																								tagName: "td",

																								components: [
																									{
																										tagName: "div",
																										attributes: {
																											class: "d-flex",
																										},

																										components: [
																											{
																												tagName: "div",
																												attributes: {
																													class: "round-product round-product-dark m-r-15",
																												},

																												components: [
																													{
																														tagName: "i",
																														attributes: {
																															class: "icofont icofont-time",
																														},
																													},
																												],
																											}, {
																												tagName: "span",

																												components: [
																													{
																											            tagName: "p",
																											            type: "text",
																											            editable: true,

																											            components: {
																											                type: "textnode",
																											                content: "Solid Wall clock",
																											            },
																											        },
																												],
																											},
																										],
																									},
																								],
																							}, {
																								tagName: "td",

																								components: [
																									{
																										tagName: "span",
																										attributes: {
																											class: "f-12",
																										},

																										components: [
																											{
																									            tagName: "p",
																									            type: "text",
																									            editable: true,

																									            components: {
																									                type: "textnode",
																									                content: "31",
																									            },
																									        },
																										],
																									},
																								],
																							}, {
																								tagName: "td",

																								components: [
																									{
																										tagName: "div",
																										attributes: {
																											class: "progress-showcase",
																										},

																										components: [
																											{
																									            tagName: "div",
																									            attributes: {
																													class: "progress sm-progress-bar",
																												},

																												components: [
																													{
																											            tagName: "div",
																											            attributes: {
																															class: "progress-bar bg-success",
																															role: "progressbar",
																															style: "width: 75%",
																															"aria-valuenow": "25",
																															"aria-valuemin": "0",
																															"aria-valuemax": "100",
																														},
																											        },
																												],
																									        },
																										],
																									},
																								],
																							}, {
																								tagName: "td",

																								components: [
																									{
																							            tagName: "p",
																							            type: "text",
																							            editable: true,

																							            components: {
																							                type: "textnode",
																							                content: "$56.55",
																							            },
																							        },
																								],
																							}, {
																								tagName: "td",
																								attributes: {
																									class: "txt-success",
																								},

																								components: [
																									{
																							            tagName: "p",
																							            type: "text",
																							            editable: true,

																							            components: {
																							                type: "textnode",
																							                content: "In Stock",
																							            },
																							        },
																								],
																							},
																						],
																					}, {
																						tagName: "tr",

																						components: [
																							{
																								tagName: "td",

																								components: [
																									{
																										tagName: "div",
																										attributes: {
																											class: "d-flex",
																										},

																										components: [
																											{
																												tagName: "div",
																												attributes: {
																													class: "round-product round-product-dark m-r-15",
																												},

																												components: [
																													{
																														tagName: "i",
																														attributes: {
																															class: "icofont icofont-time",
																														},
																													},
																												],
																											}, {
																												tagName: "span",

																												components: [
																													{
																											            tagName: "p",
																											            type: "text",
																											            editable: true,

																											            components: {
																											                type: "textnode",
																											                content: "Solid Tennis ball",
																											            },
																											        },
																												],
																											},
																										],
																									},
																								],
																							}, {
																								tagName: "td",

																								components: [
																									{
																										tagName: "span",
																										attributes: {
																											class: "f-12",
																										},

																										components: [
																											{
																									            tagName: "p",
																									            type: "text",
																									            editable: true,

																									            components: {
																									                type: "textnode",
																									                content: "25",
																									            },
																									        },
																										],
																									},
																								],
																							}, {
																								tagName: "td",

																								components: [
																									{
																										tagName: "div",
																										attributes: {
																											class: "progress-showcase",
																										},

																										components: [
																											{
																									            tagName: "div",
																									            attributes: {
																													class: "progress sm-progress-bar",
																												},

																												components: [
																													{
																											            tagName: "div",
																											            attributes: {
																															class: "progress-bar bg-success",
																															role: "progressbar",
																															style: "width: 75%",
																															"aria-valuenow": "25",
																															"aria-valuemin": "0",
																															"aria-valuemax": "100",
																														},
																											        },
																												],
																									        },
																										],
																									},
																								],
																							}, {
																								tagName: "td",

																								components: [
																									{
																							            tagName: "p",
																							            type: "text",
																							            editable: true,

																							            components: {
																							                type: "textnode",
																							                content: "$1.556",
																							            },
																							        },
																								],
																							}, {
																								tagName: "td",
																								attributes: {
																									class: "txt-danger",
																								},

																								components: [
																									{
																							            tagName: "p",
																							            type: "text",
																							            editable: true,

																							            components: {
																							                type: "textnode",
																							                content: "Out Of Stock",
																							            },
																							        },
																								],
																							},
																						],
																					},
																				],
																			},
																		],
																	},
																],
															},
														],
													},
												],
											},
										],
									},
								],
							},
						],
					},
				],
			},
		},

		view: {
			onRender({el, model}) {},
		},
	});
}

export default BusinessDashboardTemplate;