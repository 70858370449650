import axios from 'axios'
import { useState } from 'react'
import { useDrop } from 'react-dnd'
import { ItemTypes } from '../LogicBuilder4/ItemTypes.js'
import { NewCanvas1 } from './NewCanvas1.js'
import { NewCanvas2 } from './NewCanvas2.js'
import { API_HOST } from '../api_utils/index.js'

function getStyle(backgroundColor) {
  return {
    border: '1px solid rgba(0,0,0,0.2)',
    minHeight: '18rem',
    minWidth: '18rem',
    color: 'white',
    backgroundColor,
    padding: '2rem',
    paddingTop: '1rem',
    margin: '1rem',
    textAlign: 'center',
    float: 'left',
    fontSize: '1rem',
  }
}
export const NewCanvas = ({ greedy, children, name, workspaceUndoBtn }) => {
  const [hasDropped, setHasDropped] = useState(false)
  const [hasDroppedOnChild, setHasDroppedOnChild] = useState(false)
  const [items, addItems] = useState([])
  const [condition, setCondition] = useState()
  const [funcName, setFuncName] = useState("")

  const [{ isOver, isOverCurrent }, drop] = useDrop(
    () => ({
      accept: "New_Function",
      drop: (_item, monitor) => {
        const didDrop = monitor.didDrop()
        // console.log("name - ", name)
        // console.log("Greeedy ", greedy)
        // console.log("Did drop  ", didDrop)
        // console.log("DROP RESULT ", _item)
        if (didDrop && !greedy) {
            // console.log("not greedy")
            // console.log("has Dropped ", hasDropped)
            // console.log("has Dropped on Children ", hasDroppedOnChild)
            return
        }
        // console.log("Setting has dropped to true")
        setHasDropped(true)
        // console.log("Setting has dropped on child to true")
        setHasDroppedOnChild(didDrop)
        if(items.length > 0) {
            
            addItems(prevState => {
                    // console.log("Previtems ---- ", prevState)
                    let initItems = [...prevState]
                    return [...initItems, _item]
                }
            )
        } else {
            // console.log("Zerroooo")
            addItems([_item])
        }
        
      },
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        // didDrop: monitor.didDrop(),
        isOverCurrent: monitor.isOver({ shallow: true }),
      }),
    }),
    [greedy, hasDropped, hasDroppedOnChild, items],
  )
  const text = greedy ? 'greedy' : 'not greedy'
  let backgroundColor = 'rgba(0, 0, 0, .5)'
  if (isOverCurrent || (isOver && greedy)) {
    backgroundColor = 'darkgreen'
  }

  const saveClicked = () => {
    console.log("Save clicked")
    console.log(items)
  }

  const setParametersDropped = (val, item) => {
    console.log("VALLL ==== ", val)
    addItems((prevItems) => {
        let newItems = item.join(',')
        //setCondition(newItems)
        prevItems[val].dropped = true;
        prevItems[val].parameters = newItems;
        return prevItems 
    })
  }

  const setExecutionItemsDropped = (val, item, returnVar=false, number=false) => {
    console.log("New Canvas1 val  ", val)
    console.log("New Canvas1 item ", item)
    let currItems = [...items];
    // let newItems = [...items, item]
    if(returnVar === true) {
        if(currItems[0].returnVar) {
            currItems[0].returnVar[val] = item
        } else {
            currItems[0].returnVar = [];
            currItems[0].returnVar[0] = item;
        }
    } else {
        // if(currItems[0].execute) {
        //   // currItems[val].execute[val] = item;
        //   if(number) {
        //     currItems[0].execute[val] = 'Number('+item+')'
        //   } else {
        //     currItems[0].execute[val] = item
        //   }
           
        // } else {
        //     // let toExecute = { "execute"[0]: item }
        //     currItems[val].execute = [];
        //     // Object.assign(currItems[val], "execute"[0] = item)
        //     currItems[val].execute[0] = item;
        // }
        
        addItems(prevItems => {
            let newCurrItems = prevItems[0].execute;
            console.log("new curr items ", newCurrItems )
            console.log(val);
            // if(number) {
            //   // prevItems[0].execute[val] = 'Number('+item+')';  
            //   // prevItems[0].execute[val] = [...newCurrItems, 'Number('+item+')'];
            //   prevItems[0].execute[val].push('Number('+item+')')
            // } else {
              if(prevItems[0].execute) {
                if(prevItems[0].execute[val]) {
                  if(number) {
                    // prevItems[0].execute[val] = 'Number('+item+')';  
                    // prevItems[0].execute[val] = [...newCurrItems, 'Number('+item+')'];
                    prevItems[0].execute[val].push('Number('+item+')')
                  } else {
                    prevItems[0].execute[val].push(item);              
                  }
                  
                } else {
                  console.log("prevItems[0].execute[val]=[]")
                  prevItems[0].execute[val] = []
                  prevItems[0].execute[val] = item;
                }
 
              } else {
                console.log("Not execute ")
                prevItems[0].execute = [];
                prevItems[0].execute[0] = item;
              }
  
            // }
            return prevItems
        })
    } 


  }

//   console.log("condition  ====" , condition)
//   console.log("Exceute items ==== ", items)
//   console.log("HAS Dropped on CHiled == ", hasDroppedOnChild)

    const saveFunc = () => {
        const currItems = items;

        currItems[0].name = funcName;
        const projectId = localStorage.getItem("projectId")
        const parameters = currItems[0].parameters
        console.log("Exceute map --- ", currItems[0].execute)
        if(currItems[0].name) {
          if(currItems[0].returnVar) {
            let stringRepresentation = currItems[0].execute.map(subArr => subArr.join(' ') + ';').join(' ');
            let result = currItems[0].returnVar.reduce((accumulator, item, index) => {
              return `${accumulator}${index > 0 ? ', ' : ''}${item}`;
          }, '');
            result = `return { ${result} }`;
            console.log("Saved ", currItems)
            console.log("String representation ", stringRepresentation)
            console.log("Return Var ", result)
            const newObj = {
              'name': funcName,
              'code': "function " + funcName + "(" + (parameters ? parameters : "") + ") {" + stringRepresentation + " " + result + " }",
              'resultVar': result,
              'project': projectId
            }
    
            const userInfo = JSON.parse(localStorage.getItem("userInfo"))
            console.log("userifno  ", userInfo)
            console.log("userifno token ", userInfo.token)
            const config = {
              headers: {
                  'Content-Type': 'application/json',
                  Authorization: `Bearer ${userInfo.token}`
              }
            }
    
    
            console.log("New obj ===== ", newObj)
            console.log("config ===== ", config)
    
            const { data } = axios.post(`${API_HOST}custom_functions/create`, newObj, config).then(res => {
              console.log("RESS ===== ", res)
              alert("Function Saved Succesfully ")
            })

          } else {
            alert("Please enter the return values")
          }
          
        } else {
            alert("Please enter the name of the function")
        }
        
        // axios.post(`${API_HOST}functions/create`, newObj)
    }

  return (
    <div ref={drop} style={getStyle(backgroundColor)}>
      {/* text */}
      <div>Save</div> 
      
      {items.length > 0 ? items.map((item, i) => {
        console.log("New Canvas1 SDFSD ", item)
       
            // console.log("YESA drioooabke")
            return (
                <div key={i}>
                <div  style={{display: "flex"}}> {item.name} <input type="text" onChange={e => setFuncName(e.target.value)} />
                    ( <NewCanvas2 prevItem={i} isParam={true} setParametersDropped={setParametersDropped} /> ) 
                    <div style={{ fontSize: "12px" }}> Delete </div> 
                    <div style={{ fontSize: "14px" }} onClick={saveFunc} >  Save </div>
                </div>
                   <NewCanvas2 prevItem={i} setExecutionItemsDropped={setExecutionItemsDropped} /> 
                </div>
            )

        }) : ""}
      <br />
      {/* 
        Mainly used if you want to show that item has been dropped in a child. 
        This for the first one in the documentation */}
      {/* hasDropped && <p>dropped {hasDroppedOnChild && ' on child'}</p> */}

      <div>{children}</div>
    </div>
  )
}
