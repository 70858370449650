
import {  GET_PROFILE_DATA_FAILED, GET_PROFILE_DATA_LOADING, GET_PROFILE_DATA_SUCCESS,  UPDATE_PROFILE_FAILED, UPDATE_PROFILE_LOADING, UPDATE_PROFILE_SUCCESS } from "../Constants/userProfileConstants";


export const getUserProfileReducer = ( state = {}, action ) => {
    switch (action.type) {
        case GET_PROFILE_DATA_LOADING:
            return { loading: true }
        case GET_PROFILE_DATA_SUCCESS:
            return { loading: false, userProfile: action.payload }
        case GET_PROFILE_DATA_FAILED:
            return { loading: false, error: action.payload }
        default:
            return state;
    }
}

export const updateUserProfileReducer = (state = {}, action ) => {
    switch (action.type) {
        case UPDATE_PROFILE_LOADING:
            return { loading: true }
        case UPDATE_PROFILE_SUCCESS:
            return { loading: false, profileData: action.payload }
        case UPDATE_PROFILE_FAILED:
            return { loading: false, error: action.payload }
        default:
            return state;
    }
}