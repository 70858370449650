export const CREATE_NEW_FUNC_NAME = 'CREATE_NEW_FUNC_NAME'

export const ASSIGN_VARIABLES = 'ASSIGN_VARIABLES'

export const CREATE_MATH_FUNC = 'CREATE_MATH_FUNC'

export const SAVE_MATH_FUNC = 'SAVE_MATH_FUNC'

export const SAVE_MATH_PARAMS = 'SAVE_MATH_PARAMS'

export const SAVE_NEW_FUNC = 'SAVE_NEW_FUNC'

export const OPEN_NEW_FUNC = 'OPEN_NEW_FUNC'

