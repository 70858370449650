const mainBody = document.createElement('div');

// for bootstrap icons in social media
const cdn = document.createElement('link');
cdn.setAttribute('rel', 'stylesheet');
cdn.setAttribute('href', 'https://cdn.jsdelivr.net/npm/bootstrap-icons@1.9.1/font/bootstrap-icons.css');
mainBody.appendChild(cdn);

const header = document.createElement('div');
header.setAttribute('class', 'header');
mainBody.appendChild(header);

header.innerHTML = `
	<div>
        <img src="/templates/images/redSlingLogo.png" />
      </div>
      <div class="header-right-section1">
        <p>Admin</p>
        <img src="/templates/images/Vector.png" />
    </div>
`;

const whiteWaveOverlay1 = document.createElement('div');
whiteWaveOverlay1.setAttribute('class', 'whiteWaveOverlay1');
mainBody.appendChild(whiteWaveOverlay1);

whiteWaveOverlay1.innerHTML = `
	<img src="/templates/images/Ellipse 6.png" />
    <img class="whiteWave2" src="/templates/images/white waves 2.png" />
`;

const mainDiv1 = document.createElement('div');
mainDiv1.setAttribute('class', 'mainDiv1');
mainBody.appendChild(mainDiv1);

mainDiv1.innerHTML = `
<!-- dark bubble -->
    <img class="addProject-d-bb1" src="/templates/images/darkBubble.png" />

    <div class="mainDiv-head2">
        <div>
          <p >Product 1</p>
          <p >
            Lorem ipsum dolor sit amet consectetur <a href="#">More</a>
          </p>
        </div>
        <div >
          <p ><s>₹2,290</s></p>
          <p >₹1,450</p>
        </div>
    </div>

    <div class="overlayDiv2">
        <div>
          <p class="arrowProduct1">
            <img src="/templates/images/leftArrow.png"/>
          </p>
          <div class="overlay2-innerDiv">
            <img class="overlay2-1" src="/templates/images/unknownImg.png" />
            <img class="overlay2-0" src="/templates/images/unknownImg.png" />
            <img class="overlay2-1" src="/templates/images/unknownImg.png" />
          </div>
          <p class="arrowProduct1">
            <img src="/templates/images/rightArrow.png"/>
          </p>
        </div>
    </div>

    <div class="bottomDivWrapper">
        <p class="b-head">Details</p>
        <p class="b-para">
          Lorem ips dolor sit amet, consectetur adipisci elit, sed eiusmod
          tempor incidunt ut la Lorem ips dolor sit amet, consectetur adipisci
          elit, sed eiusmod tempor incidunt ut la Lorem ips dolor sit amet,
          consectetur adipisci elit, sed eiusmod tempor incidunt ut la Lorem ips
          dolor sit amet, consectetur adipisci elit, sed eiusmod tempor incidunt
          ut la Lorem ips dolor sit amet, consectetur adipisci elit, sed eiusmod
          tempor incidunt ut la Lorem ips dolor sit amet, consectetura
          <a href="#">More</a>
        </p>
        <p class="b-head1">Quality Promise</p>
        <p class="b-head1">Offers</p>
        <p class="b-head1">Reviews</p>
        <p class="b-head1">FAQs</p>
        <p class="b-head1">Return & Refunds</p>
        <div class="b-button-div">
          <button>Add To Cart</button>
          <button>Buy Now</button>
        </div>
    </div>

    <style>
    	@media (max-width: 768px) {
        .header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          position: relative;
          padding: 2vh 2.5vw;
        }
        .header-right-section1 {
          display: flex;
          justify-content: space-evenly;
          align-items: center;
          width: initial;
          z-index: 10;
        }
        .header-right-section1 img {
          width: 4vw;
          height: 3vh;
          margin-left: 5%;
        }
        .header-right-section1 p {
          font-family: Mont-Regular;
          font-size: 1rem;
          margin-right: 5%;
        }
        .whiteWaveOverlay1 {
          position: absolute;
          top: 0;
          right: 0;
        }
        .whiteWaveOverlay1 img:first-child {
          width: 50vw;
          position: absolute;
          top: 0;
          right: 0;
        }
        .whiteWave2 {
          width: 90vw;
          position: absolute;
          top: 0;
          right: 0;
        }
        .addProject-d-bb1 {
          display: none;
        }
        .mainDiv-head2 {
          align-items: flex-start;
        }
        .mainDiv-head2 div:first-child p:first-child {
          font-size: 1.8rem;
        }
        mainDiv-head2 div:first-child {
          margin-left: 4%;
        }
        .mainDiv-head2 div:first-child p {
          margin-top: 4.5%;
          width: 50vw;
        }
        .mainDiv-head2 div:first-child p a {
          color: red;
        }

        .mainDiv-head2 div:last-child {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          width: 37vw;
          margin-top: 4%;
        }

        .mainDiv-head2 div:last-child p:first-child {
          width: inherit;
          height: inherit;
          font-family: Mont-Regular;
          font-style: normal;
          font-weight: 700;
          font-size: 1.2rem;
          color: #5d6a75;
          margin-right: 15%;
        }

        .mainDiv-head2 div:last-child p:last-child {
          width: inherit;
          height: inherit;
          font-family: Mont-Regular;
          font-style: normal;
          font-weight: 700;
          font-size: 1.2rem;
          margin-right: 15%;
        }
        .overlayDiv2 {
          margin-top: 3.8vh;
          background: white;
          z-index: 20;
          padding-left: 5%;
          padding-top: 0.7%;
          height: initial;
          padding-bottom: 3vh;
        }
        .arrowProduct1 {
          display: none;
        }
        .overlay2-innerDiv {
          flex-direction: column;
        }
        .overlay2-1 {
          width: 40vw;
          height: 15vh;
        }

        .overlay2-0 {
          width: 70vw;
          height: 25vh;
        }
        .bottomDivWrapper {
          padding-left: 5vw;
          padding-right: 5vw;
          padding-bottom: 4vh;
        }
        .b-button-div {
          flex-direction: column;
          margin: 2vh 0 0 0;
        }
        .b-button-div button {
          width: 90%;
          margin-bottom: 4%;
        }  
      }
    </style>
`;

const templateProductPage = mainBody.outerHTML;
export { templateProductPage };