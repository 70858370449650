import React from 'react'
import { Component } from 'react'
import './style.css'
import darkBubble from './component_assets/images/darkBubble.png'
// import darkBubble from './component_assets/images/details_close.png'
// import lightBubble from './component_assets/images/details_close.png'
import lightBubble from './component_assets/images/lightBubble.png'
import { useNavigate, useParams } from 'react-router-dom'
import { withRouter } from "react-router";
import { connect, useDispatch, useSelector } from 'react-redux'
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"
import axios from 'axios'
import { API_HOST } from '../api_utils'
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
// import { Connect } from 'react-redux'

class CreateTableModal2 extends React.Component {

    state = {
        tableName: "",
        fieldName: "",
        fieldType: "",
        fields: [],
        success: false
    }

    constructor(props) {
        super(props)
        console.log(props)

    }

    componentDidMount() {
        // super(props)
        console.log(this.props)

        const getCustomModels = async () => {
            console.log("insdie asycn")
            const project_id = this.props.params.project
            const modalData = { project_id: this.props.params.project }
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${this.props.userInfo.token}`
                }
            }
            console.log("Before respose")
            const response = await axios.post(`${API_HOST}customroutes/get/`, modalData, config);
            console.log("After resposne")
            console.log(response)
        }

        // getCustomModels()
        // const { project } = useParams()
        // console.log(project)
    }


    errorTheme = {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
    }

    setTableName = (val) => {
        this.setState((prevState) => ({
            ...prevState,
            tableName: val
        }))
    }


    // setFields = (val) => {
    //     this.setState((prevState) => ({
    //         ...prevState,
    //         fields: [...prevState.fields, val]
    //     }))
    // }

    setSuccess = (val) => {
        this.setState(prevState => ({
            ...prevState,
            success: val
        }))
    }

    setFieldName = (val) => {
        this.setState(prevState => ({
            ...prevState,
            fieldName: val
        }))
    }

    handleChange = (event) => {
        if (event.target.value === 10) {
            this.setFieldType(10);
        } else if (event.target.value === 20) {
            // this.setFieldType('Boolean');
            this.setFieldType(20)
        } else if (event.target.value === 30) {
            // this.setFieldType('Number');
            this.setFieldType(30)
        } else if (event.target.value === 40) {
            this.setFieldType(40)
        }

    };


    setFieldType = (val) => {
        this.setState(prevState => ({
            ...prevState,
            fieldType: val
        }))
    }

    setFields = () => {
        let fieldName = this.state.fieldName;
        let fieldType = this.state.fieldType;
        let newFieldType = ""
        if (fieldType === 10) {
            // this.setFieldType(10);
            newFieldType = "String"
        } else if (fieldType === 20) {
            // this.setFieldType('Boolean');
            newFieldType = "Boolean"
        } else if (fieldType === 30) {
            // this.setFieldType('Number');
            newFieldType = "Number"
        } else if (fieldType === 40) {
            newFieldType = "Reference"
        }
        let savingFields = [...this.state.fields, { fieldName, fieldType: newFieldType }]
        let newFields = [...this.state.fields, { fieldName, fieldType }];

        if (fieldName === "" || newFieldType === '' || this.state.tableName === "") {

        } else {
            this.setState(prevState => ({
                ...prevState,
                // fields: newFields,
                fields: savingFields,
                fieldName: "",
                fieldType: ""
            }))
        }

    }



    createModalHandler = async () => {
        console.log("THIS ")

        const project_id = this.props.params.project
        console.log(project_id)
        if (this.state.tableName && this.state.fields.length > 0) {
            try {
                const modalData = { table_name: this.state.tableName, fields: [...this.state.fields], projectId: project_id }
                const config = {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${this.props.userInfo.token}`
                    }
                }

                const response = await axios.post(`${API_HOST}customroutes/`, modalData, config);
                this.setSuccess(true)
                toast.success(`Page Successfully Created`, this.errorTheme)
            } catch (error) {
                toast.error(`${error.response.data.message}`, this.errorTheme)
            }
        } else if (!this.state.tableName && !this.state.fieldName && !this.state.fieldType) {
            toast.error("fields can't be empty !!!", this.errorTheme)
        } else if (this.state.tableName && this.state.fieldName && this.state.fieldType) {
            let fieldName = this.state.fieldName;
            let fieldType = this.state.fieldType;
            let newFieldType = ""
            if (fieldType === 10) {
                // this.setFieldType(10);
                newFieldType = "String"
            } else if (fieldType === 20) {
                // this.setFieldType('Boolean');
                newFieldType = "Boolean"
            } else if (fieldType === 30) {
                // this.setFieldType('Number');
                newFieldType = "Number"
            } else if (fieldType === 40) {
                newFieldType = "Reference"
            }
            let savingFields = [...this.state.fields, { fieldName, fieldType: newFieldType }]
            const modalData = {
                table_name: this.state.tableName,
                fields: [...savingFields],
                projectId: project_id,
                // user: 
            }
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${this.props.userInfo.token}`
                }
            }
            const response = await axios.post(`${API_HOST}customroutes/`, modalData, config);
            this.setSuccess(true)
            toast.success(`Page Successfully Created`, this.errorTheme)
        }
    }


    removeField = (index) => {
        console.log(index)

        let currState = this.state.fields;
        let newState = this.state.fields.filter((item, i) => i !== index);

        this.setState(prevState => ({
            ...prevState,
            fields: [...newState]
        }))
    }




    render() {

        if (this.state.success) {
            // console.log("pRPS PUSH HERE")
            // navigate(`/modal_table/${project}`)
        }

        // console.log("------ HERE")
        // console.log(this.state.fields)
        return (
            <div>
                <div className="flex-container">


                    <div className="hero">

                        <img className="login-d-bb" src={darkBubble} />


                        <img className="login-l-bb" src={lightBubble} />



                        <div className="profile-wrapper-form">
                            <div className="morph-div-profile">
                                <div className="morph-header-project">
                                    <p className="morph-head-text">ADD NEW MODEL</p>
                                    <p className="morph-head-project"></p>
                                </div>

                                <div className="wrap-project-form">
                                    <form className="project-form">
                                        <div className="user-form-first">
                                            <div className="form-element">
                                                <p>
                                                    <label for="TableName">Table Name</label>
                                                </p>
                                                <input value={this.state.tableName} onChange={(e) => this.setTableName(e.target.value)} id="TableName" placeholder="Add Table Name" />
                                            </div>

                                        </div>
                                        {
                                            this.state.fields.length > 0 ?
                                                this.state.fields.map((item, i) => {
                                                    return (
                                                        <div style={{ display: "flex", marginTop: "10px", alignItems: "center", justifyContent: "space-around" }}>
                                                            <div className="form-element">
                                                                <p>
                                                                    <label for="FieldName">Field Name</label>
                                                                </p>
                                                                <input
                                                                    type="text"
                                                                    id="FieldName"
                                                                    placeholder="Add Field Name"
                                                                    // onChange={(e) => this.setFieldName(e.target.value)}
                                                                    value={item.fieldName}
                                                                    disabled={true}
                                                                />
                                                            </div>


                                                            <div className="form-element">
                                                                <p>
                                                                    <label for="FieldName">Field Type</label>
                                                                </p>
                                                                <input
                                                                    type="text"
                                                                    id="FieldType"
                                                                    // placeholder="Add Field Name"
                                                                    // onChange={(e) => this.setFieldName(e.target.value)}
                                                                    value={item.fieldType}
                                                                    disabled={true}
                                                                />
                                                            </div>
                                                            <div style={{
                                                                height: "55px", width: "55px", display: "flex",
                                                                justifyContent: "center",
                                                                border: "2px solid #000", marginTop: "10px", borderRadius: "50%",
                                                                alignItems: "center",
                                                                fontSize: "45px"
                                                            }}
                                                                // onClick={() => this.setFields()}
                                                                onClick={() => this.removeField(i)}
                                                            >
                                                                {/* <div></div> */}
                                                                {/* <IonIcon */}
                                                                {/* <div>+</div> */}
                                                                -
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                                : ""
                                        }
                                        <div style={{ display: "flex", marginTop: "10px", alignItems: "center", justifyContent: "space-around" }}>
                                            <div className="form-element">
                                                <p>
                                                    <label for="FieldName">Field Name</label>
                                                </p>
                                                <input
                                                    type="text"
                                                    id="FieldName"
                                                    placeholder="Add Field Name"
                                                    onChange={(e) => this.setFieldName(e.target.value)}
                                                    value={this.state.fieldName}
                                                />
                                            </div>

                                            <FormControl sx={{ width: '23vw', ml: "10px !important", mt: "42px !important" }}>
                                                <InputLabel id="demo-simple-select-label">Data Type</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={this.state.fieldType}
                                                    label="Data Type"
                                                    onChange={this.handleChange}
                                                >
                                                    <MenuItem value={10}>String</MenuItem>
                                                    <MenuItem value={20}>Boolean</MenuItem>
                                                    <MenuItem value={30}>Number</MenuItem>
                                                    <MenuItem value={40}>Reference</MenuItem>
                                                </Select>
                                            </FormControl>
                                            <div style={{
                                                height: "55px", width: "55px", display: "flex",
                                                justifyContent: "center",
                                                border: "2px solid #000", marginTop: "10px", borderRadius: "50%",
                                                alignItems: "center",
                                                fontSize: "45px"
                                            }} onClick={() => this.setFields()}>
                                                {/* <div></div> */}
                                                {/* <IonIcon */}
                                                {/* <div>+</div> */}
                                                +
                                            </div>
                                        </div>

                                    </form>
                                    <div className="project-form-last" style={{ marginTop: "8px" }}>
                                        <div className="submit-div-project">
                                            <button onClick={this.createModalHandler} style={{ padding: '1rem 2rem' }} className="project-form-submit">
                                                Create Model
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    console.log(state.userLogin)
    return {
        userInfo: state.userLogin.userInfo
    }
}

const mapDispatchToProps = (dispatch) => {
    return {


    };
}

// export default connect(mapStateToProps, null)(CreateTableModal2)
// const shareLocationWithRouter = withRouter(CreateTableModal2)
// export default connect(mapStateToProps, null)(shareLocationWithRouter);
// function createModalDef (CreateTableModal2) {
export default connect(mapStateToProps, null)((props) => {
    return (
        <CreateTableModal2  {...props}
            params={useParams()} />
    )
})



// export default (props) => (
//      <CreateTableModal2
//         {...props}
//         params={useParams()}
//     />
// );

// export default connect(mapStateToProps, null)(shareLocationWithRouter)
// export default connect(mapStateToProps, null)(createModalDef)