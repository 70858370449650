// DOC - https://github.com/nhn/tui.calendar/blob/main/docs/en/guide/getting-started.md#disable-to-collect-hostname-for-google-analyticsga

import uniqid from "uniqid";
import $ from "jquery";
import { API_HOST } from "../api_utils/index";

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Calendar related imports
// Load the css files of tui-date-picker and tui-time-picker to use the event creation popup.
import 'tui-date-picker/dist/tui-date-picker.css';
import 'tui-time-picker/dist/tui-time-picker.css';
// import Calendar from '@toast-ui/calendar';
import '@toast-ui/calendar/dist/toastui-calendar.min.css'; // Stylesheet for calendar

const CustomCalendarPlugin = (editor, options) => {
	console.log('CustomCalendarPlugin options', options);

	const notifyTheme = {
		position: "top-right",
		autoClose: 7500,
		hideProgressBar: false,
		closeOnClick: true,
		pauseOnHover: true,
		draggable: true,
		progress: undefined,
		theme: "light",
	};

	const notifyTheme2 = {
		position: "top-right",
		autoClose: 3000,
		hideProgressBar: false,
		closeOnClick: true,
		pauseOnHover: true,
		draggable: true,
		progress: undefined,
		theme: "light",
	};

	const successTheme = {
	  position: "top-right",
	  autoClose: 5000,
	  hideProgressBar: false,
	  closeOnClick: true,
	  pauseOnHover: true,
	  draggable: true,
	  progress: undefined,
	  theme: "light",
	};

	// toast notification for empty tables
	const infoTheme = {
	  position: "top-right",
	  autoClose: 7500,
	  hideProgressBar: false,
	  closeOnClick: true,
	  pauseOnHover: true,
	  draggable: true,
	  progress: undefined,
	  theme: "light",
	};

    if (editor !== null || editor !== undefined) {
    	editor.Blocks.add("custom-calendar-container", {
	        label: "Custom Calendar",
	        category: "Buttons",
	        select: true,
	        media: `<svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="currentColor" viewBox="0 0 16 16"><path d="M14 0H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2M1 3.857C1 3.384 1.448 3 2 3h12c.552 0 1 .384 1 .857v10.286c0 .473-.448.857-1 .857H2c-.552 0-1-.384-1-.857z"/>  <path d="M6.5 7a1 1 0 1 0 0-2 1 1 0 0 0 0 2m3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2m3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2m-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2m3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2m3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2m3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2m-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2m3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2m3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2"/></svg>`,
	        content: { type: "custom-calendar-container" },
	    });

	    const calendarScript = function(props) {
		    console.log('calendar props', props);

		    const calendarContainer = this;
		    const {projectId} = props;

		    let idEl = calendarContainer.id;
			const url = window.top.location.href;
			const isSubstringPresent = (url.indexOf("/editor/") !== -1 && url.indexOf("?projectId=") !== -1) || url.indexOf("/large_preview/") !== -1 || url.indexOf("/tab_preview/") !== -1 || url.indexOf("/mobile_preview/") !== -1 || url.indexOf("/fragment_editor/") !== -1;

		    // base url bearer token taken directly from local storage after getting downloaded
			let baseUrlData, dynamicBaseUrlBearerToken, baseUrl1BearerToken, baseUrl2BearerToken, baseUrl3BearerToken, baseUrl4BearerToken, baseUrl5BearerToken;
			// getting baseUrl data from local storage for run-time access
			let baseUrlDataFromStorage = localStorage.getItem("baseUrlData");
			if (baseUrlDataFromStorage) {
				baseUrlData = JSON.parse(baseUrlDataFromStorage);
			}
			// extracting token values from base URLs from baseUrlData
			if (baseUrlData) {
				try {
					if (`baseUrl1_${projectId}` in baseUrlData) {
						baseUrl1BearerToken = baseUrlData[`baseUrl1_${projectId}`].token;
					}
					if (`baseUrl2_${projectId}` in baseUrlData) {
						baseUrl2BearerToken = baseUrlData[`baseUrl2_${projectId}`].token;
					}
					if (`baseUrl3_${projectId}` in baseUrlData) {
						baseUrl3BearerToken = baseUrlData[`baseUrl3_${projectId}`].token;
					}
					if (`baseUrl4_${projectId}` in baseUrlData) {
						baseUrl4BearerToken = baseUrlData[`baseUrl4_${projectId}`].token;
					}
					if (`baseUrl5_${projectId}` in baseUrlData) {
						baseUrl5BearerToken = baseUrlData[`baseUrl5_${projectId}`].token;
					}
				} catch (e) {
					// statements
					console.log(e);
				}
			}

		    // helper function to check if a variable is an array-of-objects or not
			function isArrayofObjects(variable) {
				// Check if the variable is an array
				if (!Array.isArray(variable)) {
					return false;
				}

				// Check if all elements in the array are objects
				for (const element of variable) {
					if (typeof element !== "object" || Array.isArray(element) || element === null) {
						return false;
					}
				}
				return true;
			}

		    // Helper function to format time in 12-hour format with AM/PM
			function formatTime(date) {
			    const hours24 = date.getHours();
			    const minutes = date.getMinutes();
			    
			    // Convert 24-hour format to 12-hour format
			    const hours12 = hours24 % 12 || 12; // Convert '0' hour to '12'
			    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
			    const ampm = hours24 >= 12 ? 'PM' : 'AM';
			    
			    return `${hours12}:${formattedMinutes} ${ampm}`;
			}

		    // Helper function to manage 'calendar' specific dates
		    function formatDateString(dateObj) {
			    // Extract the date string from the nested object
			    const dateStr = dateObj.d.d;
			    // Create a new Date object
			    const date = new Date(dateStr);
			    
			    // Format the date into "YYYY-MM-DDTHH:MM:SS" format
			    const year = date.getFullYear();
			    const month = String(date.getMonth() + 1).padStart(2, '0');
			    const day = String(date.getDate()).padStart(2, '0');
			    const hours = String(date.getHours()).padStart(2, '0');
			    const minutes = String(date.getMinutes()).padStart(2, '0');
			    const seconds = String(date.getSeconds()).padStart(2, '0');
			    
			    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
			}

		    // Helper function to gather apiData to plot calendar with
    		async function gatherCalendarData(props) {
    			let {
    				customUrlValue,
		    		baseUrlValue,
		    		apiPathValue,
		    		apiMethodValue,
		    		apiUsernameValue,
		    		apiPasswordValue,
		    		apiBearerTokenValue,
		    		apiMoreHeadersValue,
		    		apiBodyValue,
		    		nestedArrOfObjKeynameValue,
		    	} = props;

		    	let apiData;
		    	let bu, bubt; // base url bearer token, temp var

		    	// Function to build the config object dynamically
				function buildConfig(apiMethodValue, apiBodyValue, apiHeaders) {
					let config = {
						method: apiMethodValue,
						headers: {
							"Content-Type": "application/json",
							...apiHeaders,
						},
					};

					// Add body only if the method is POST
					if (apiMethodValue === "POST") {
						config.body = apiBodyValue;
					}

					return config;
				}

				// if only custom url is provided
				if (customUrlValue && (!baseUrlValue || baseUrlValue === "null") && !apiPathValue) {
					// if nothing is provided, for authentication
					if (!apiUsernameValue && !apiPasswordValue && !apiBearerTokenValue) {
						let headers = {};
						if (apiMoreHeadersValue) {
							apiMoreHeadersValue.split(/,\s*/).forEach((header) => {
								let [key, val] = header.split(":");
								headers[key] = val;
							});
						}

						let config = buildConfig(apiMethodValue, apiBodyValue, headers);

						console.log("config:", config);

						try {
							let response = await fetch(`${customUrlValue}`, config);
							let responseData = await response.json();
							apiData = responseData;
						} catch (e) {
							console.log(e);
						}
					}

					// if bearer token is provided but nothing else, for authentication
					else if (!apiUsernameValue && !apiPasswordValue && apiBearerTokenValue) {
						let headers = {
							Authorization: `Bearer ${apiBearerTokenValue}`,
						};

						if (apiMoreHeadersValue) {
							apiMoreHeadersValue.split(/,\s*/).forEach((header) => {
								let [key, val] = header.split(":");
								headers[key] = val;
							});
						}

						let config = buildConfig(apiMethodValue, apiBodyValue, headers);

						console.log("config:", config);

						try {
							let response = await fetch(`${customUrlValue}`, config);
							let responseData = await response.json();
							apiData = responseData;
						} catch (e) {
							console.log(e);
						}
					}

					// if username & password are provided but nothing else, for authentication
					else if (apiUsernameValue && apiPasswordValue && !apiBearerTokenValue) {
						let headers = {
							Authorization: "Basic " + btoa(apiUsernameValue + ":" + apiPasswordValue),
						};

						if (apiMoreHeadersValue) {
							apiMoreHeadersValue.split(/,\s*/).forEach((header) => {
								let [key, val] = header.split(":");
								headers[key] = val;
							});
						}

						let config = buildConfig(apiMethodValue, apiBodyValue, headers);

						console.log("config:", config);

						try {
							let response = await fetch(`${customUrlValue}`, config);
							let responseData = await response.json();
							apiData = responseData;
						} catch (e) {
							console.log(e);
						}
					}
				}

				// if only base url is provided
				else if (!customUrlValue && (baseUrlValue || !baseUrlValue === "null") && apiPathValue) {
					let url, dynamicBaseUrlBearerToken;
					let forDownloadUrl;

					if (baseUrlValue === "baseUrl1") {
						url = options.baseUrl1 + apiPathValue;
						forDownloadUrl = "{{API_URL1}}" + apiPathValue;
						dynamicBaseUrlBearerToken = baseUrl1BearerToken;
					} else if (baseUrlValue === "baseUrl2") {
						url = options.baseUrl2 + apiPathValue;
						forDownloadUrl = "{{API_URL2}}" + apiPathValue;
						dynamicBaseUrlBearerToken = baseUrl2BearerToken;
					} else if (baseUrlValue === "baseUrl3") {
						url = options.baseUrl3 + apiPathValue;
						forDownloadUrl = "{{API_URL3}}" + apiPathValue;
						dynamicBaseUrlBearerToken = baseUrl3BearerToken;
					} else if (baseUrlValue === "baseUrl4") {
						url = options.baseUrl4 + apiPathValue;
						forDownloadUrl = "{{API_URL4}}" + apiPathValue;
						dynamicBaseUrlBearerToken = baseUrl4BearerToken;
					} else if (baseUrlValue === "baseUrl5") {
						url = options.baseUrl5 + apiPathValue;
						forDownloadUrl = "{{API_URL5}}" + apiPathValue;
						dynamicBaseUrlBearerToken = baseUrl5BearerToken;
					}

					const urlDownload4 = window.top.location.href;
					const isSubstringPresent4 = (urlDownload4.indexOf("/editor/") !== -1 && urlDownload4.indexOf("?projectId=") !== -1) || urlDownload4.indexOf("/large_preview/") !== -1 || urlDownload4.indexOf("/tab_preview/") !== -1 || urlDownload4.indexOf("/mobile_preview/") !== -1 || urlDownload4.indexOf("/fragment_editor/") !== -1;
					if (!isSubstringPresent4) {
						url = forDownloadUrl;
					}

					bu = url;
					bubt = dynamicBaseUrlBearerToken;
					console.log("url, dynamicBaseUrlBearerToken", url, dynamicBaseUrlBearerToken);

					// if nothing is provided, for authentication
					if (!apiUsernameValue && !apiPasswordValue && !apiBearerTokenValue && !dynamicBaseUrlBearerToken) {
						let headers = {};

						if (apiMoreHeadersValue) {
							apiMoreHeadersValue.split(/,\s*/).forEach((header) => {
								let [key, val] = header.split(":");
								headers[key] = val;
							});
						}

						let config = buildConfig(apiMethodValue, apiBodyValue, headers);

						console.log("config:", config);

						try {
							let response = await fetch(`${url}`, config);
							let responseData = await response.json();
							apiData = responseData;
						} catch (e) {
							console.log(e);
						}
					}

					// if bearer token is provided but nothing else, for authentication
					else if (!apiUsernameValue && !apiPasswordValue && !apiBearerTokenValue && dynamicBaseUrlBearerToken) {
						let headers = {
							Authorization: `Bearer ${dynamicBaseUrlBearerToken}`,
						};

						if (apiMoreHeadersValue) {
							apiMoreHeadersValue.split(/,\s*/).forEach((header) => {
								let [key, val] = header.split(":");
								headers[key] = val;
							});
						}

						let config = buildConfig(apiMethodValue, apiBodyValue, headers);

						console.log("config:", config);

						try {
							let response = await fetch(`${url}`, config);
							let responseData = await response.json();
							apiData = responseData;
						} catch (e) {
							console.log(e);
						}
					}

					// if username & password are provided but nothing else, for authentication
					else if (apiUsernameValue && apiPasswordValue && !apiBearerTokenValue && !dynamicBaseUrlBearerToken) {
						let headers = {
							Authorization: "Basic " + btoa(apiUsernameValue + ":" + apiPasswordValue),
						};

						if (apiMoreHeadersValue) {
							apiMoreHeadersValue.split(/,\s*/).forEach((header) => {
								let [key, val] = header.split(":");
								headers[key] = val;
							});
						}

						let config = buildConfig(apiMethodValue, apiBodyValue, headers);

						console.log("config:", config);

						try {
							let response = await fetch(`${url}`, config);
							let responseData = await response.json();
							apiData = responseData;
						} catch (e) {
							console.log(e);
						}
					}
				}

		    	return apiData;
    		}

    		// // Helper function to delete calendar events
    		// async function deleteCalendarData(props, eventIDToDelete) {
    		// 	let {
    		// 		deleteCustomUrlValue,
		    // 		deleteBaseUrlValue,
		    // 		deleteApiPathValue,
		    // 		deleteApiMethodValue,
		    // 		deleteApiUsernameValue,
		    // 		deleteApiPasswordValue,
		    // 		deleteApiBearerTokenValue,
		    // 		deleteApiMoreHeadersValue,
		    // 	} = props;

		    // 	let apiData;
		    // 	let bu, bubt; // base url bearer token, temp var

		    // 	// Function to build the config object dynamically
			// 	function buildConfig(apiMethodValue, apiHeaders) {
			// 		let config = {
			// 			method: apiMethodValue,
			// 			headers: {
			// 				"Content-Type": "application/json",
			// 				...apiHeaders,
			// 			},
			// 		};

			// 		return config;
			// 	}

		    // 	if (eventIDToDelete) {
		    // 		// if only custom url is provided
			// 		if (deleteCustomUrlValue && (!deleteBaseUrlValue || deleteBaseUrlValue === "null") && !deleteApiPathValue) {
			// 			// if bearer token is provided but nothing else, for authentication
			// 			if (!deleteApiUsernameValue && !deleteApiPasswordValue && deleteApiBearerTokenValue) {
			// 				let headers = {
			// 					Authorization: `Bearer ${deleteApiBearerTokenValue}`,
			// 				};

			// 				if (deleteApiMoreHeadersValue) {
			// 					deleteApiMoreHeadersValue.split(/,\s*/).forEach((header) => {
			// 						let [key, val] = header.split(":");
			// 						headers[key] = val;
			// 					});
			// 				}

			// 				let config = buildConfig(deleteApiMethodValue, headers);

			// 				console.log("config:", config);

			// 				try {
			// 					let response = await fetch(`${deleteCustomUrlValue}?id=${eventIDToDelete}`, config);
			// 					let responseData = await response.json();
			// 					apiData = responseData;
			// 				} catch (e) {
			// 					console.log(e);
			// 				}
			// 			}

			// 			// if username & password are provided but nothing else, for authentication
			// 			else if (deleteApiUsernameValue && deleteApiPasswordValue && !deleteApiBearerTokenValue) {
			// 				let headers = {
			// 					Authorization: "Basic " + btoa(deleteApiUsernameValue + ":" + deleteApiPasswordValue),
			// 				};

			// 				if (deleteApiMoreHeadersValue) {
			// 					deleteApiMoreHeadersValue.split(/,\s*/).forEach((header) => {
			// 						let [key, val] = header.split(":");
			// 						headers[key] = val;
			// 					});
			// 				}

			// 				let config = buildConfig(deleteApiMethodValue, headers);

			// 				console.log("config:", config);

			// 				try {
			// 					let response = await fetch(`${deleteCustomUrlValue}?id=${eventIDToDelete}`, config);
			// 					let responseData = await response.json();
			// 					apiData = responseData;
			// 				} catch (e) {
			// 					console.log(e);
			// 				}
			// 			}
			// 		}

			// 		// if only base url is provided
			// 		else if (!deleteCustomUrlValue && (deleteBaseUrlValue || !deleteBaseUrlValue === "null") && deleteApiPathValue) {
			// 			let url, dynamicBaseUrlBearerToken;
			// 			let forDownloadUrl;

			// 			if (deleteBaseUrlValue === "baseUrl1") {
			// 				url = options.baseUrl1 + deleteApiPathValue;
			// 				forDownloadUrl = "{{API_URL1}}" + deleteApiPathValue;
			// 				dynamicBaseUrlBearerToken = baseUrl1BearerToken;
			// 			} else if (deleteBaseUrlValue === "baseUrl2") {
			// 				url = options.baseUrl2 + deleteApiPathValue;
			// 				forDownloadUrl = "{{API_URL2}}" + deleteApiPathValue;
			// 				dynamicBaseUrlBearerToken = baseUrl2BearerToken;
			// 			} else if (deleteBaseUrlValue === "baseUrl3") {
			// 				url = options.baseUrl3 + deleteApiPathValue;
			// 				forDownloadUrl = "{{API_URL3}}" + deleteApiPathValue;
			// 				dynamicBaseUrlBearerToken = baseUrl3BearerToken;
			// 			} else if (deleteBaseUrlValue === "baseUrl4") {
			// 				url = options.baseUrl4 + deleteApiPathValue;
			// 				forDownloadUrl = "{{API_URL4}}" + deleteApiPathValue;
			// 				dynamicBaseUrlBearerToken = baseUrl4BearerToken;
			// 			} else if (deleteBaseUrlValue === "baseUrl5") {
			// 				url = options.baseUrl5 + deleteApiPathValue;
			// 				forDownloadUrl = "{{API_URL5}}" + deleteApiPathValue;
			// 				dynamicBaseUrlBearerToken = baseUrl5BearerToken;
			// 			}

			// 			const urlDownload4 = window.top.location.href;
			// 			const isSubstringPresent4 = (urlDownload4.indexOf("/editor/") !== -1 && urlDownload4.indexOf("?projectId=") !== -1) || urlDownload4.indexOf("/large_preview/") !== -1 || urlDownload4.indexOf("/tab_preview/") !== -1 || urlDownload4.indexOf("/mobile_preview/") !== -1 || urlDownload4.indexOf("/fragment_editor/") !== -1;
			// 			if (!isSubstringPresent4) {
			// 				url = forDownloadUrl;
			// 			}

			// 			bu = url;
			// 			bubt = dynamicBaseUrlBearerToken;
			// 			console.log("url, dynamicBaseUrlBearerToken", url, dynamicBaseUrlBearerToken);

			// 			// if bearer token is provided but nothing else, for authentication
			// 			if (!deleteApiUsernameValue && !deleteApiPasswordValue && !deleteApiPasswordValue && dynamicBaseUrlBearerToken) {
			// 				let headers = {
			// 					Authorization: `Bearer ${dynamicBaseUrlBearerToken}`,
			// 				};

			// 				if (deleteApiMoreHeadersValue) {
			// 					deleteApiMoreHeadersValue.split(/,\s*/).forEach((header) => {
			// 						let [key, val] = header.split(":");
			// 						headers[key] = val;
			// 					});
			// 				}

			// 				let config = buildConfig(deleteApiMethodValue, headers);

			// 				console.log("config:", config);

			// 				try {
			// 					let response = await fetch(`${url}?id=${eventIDToDelete}`, config);
			// 					let responseData = await response.json();
			// 					apiData = responseData;
			// 				} catch (e) {
			// 					console.log(e);
			// 				}
			// 			}

			// 			// if username & password are provided but nothing else, for authentication
			// 			else if (deleteApiUsernameValue && deleteApiPasswordValue && !deleteApiPasswordValue && !dynamicBaseUrlBearerToken) {
			// 				let headers = {
			// 					Authorization: "Basic " + btoa(deleteApiUsernameValue + ":" + deleteApiPasswordValue),
			// 				};

			// 				if (deleteApiMoreHeadersValue) {
			// 					deleteApiMoreHeadersValue.split(/,\s*/).forEach((header) => {
			// 						let [key, val] = header.split(":");
			// 						headers[key] = val;
			// 					});
			// 				}

			// 				let config = buildConfig(deleteApiMethodValue, headers);

			// 				console.log("config:", config);

			// 				try {
			// 					let response = await fetch(`${url}?id=${eventIDToDelete}`, config);
			// 					let responseData = await response.json();
			// 					apiData = responseData;
			// 				} catch (e) {
			// 					console.log(e);
			// 				}
			// 			}
			// 		}
		    // 	}
    		// }

    		// // Helper function to delete calendar events
    		// async function editCalendarData(props, event) {
    		// 	let {
    		// 		editCustomUrlValue,
		    // 		editBaseUrlValue,
		    // 		editApiPathValue,
		    // 		editApiMethodValue,
		    // 		editApiUsernameValue,
		    // 		editApiPasswordValue,
		    // 		editApiBearerTokenValue,
		    // 		editApiMoreHeadersValue,
		    // 		// editApiBodyValue,
		    // 	} = props;

		    // 	let apiData, changedEventBody = {};
		    // 	let bu, bubt; // base url bearer token, temp var

		    // 	if (event) {
		    // 		// changedEventBody = {
		    // 		// 	id: event["id"],
			//         //     calendarId: event["calendarId"],
			//         //     title: event["title"],
			//         //     body: event["body"],
			//         //     start: (typeof event["start"] === 'object') ? formatDateString(event["start"]) : event["start"],
			//         //     end: (typeof event["end"] === 'object') ? formatDateString(event["end"]) : event["end"],
			//         //     location: event["location"],
			//         //     category: event["category"],
			//         //     state: event["state"],
			//         //     isReadOnly: event["isReadOnly"] ? event["isReadOnly"] : false,
			//         //     attendees: event.attendees ? [event.attendees.Name] : [], // Assuming attendees data is in attendees_data
			//         //     color: '#fff', // Assuming default color, you can modify as needed
			//         //     backgroundColor: '#ccc', // Assuming default background color, you can modify as needed
			//         //     customStyle: {
			//         //         fontStyle: 'italic', // Assuming default custom style, you can modify as needed
			//         //         fontSize: '15px', // Assuming default custom style, you can modify as needed
			//         //     }
		    // 		// };

		    // 		changedEventBody[`${props.EvenCalendarId}`] = event["calendarId"];
		    // 		changedEventBody[`${props.EventTitle}`] = event["title"];
		    // 		changedEventBody[`${props.EvenCalendarId}`] = event["body"];
		    // 		changedEventBody[`${props.EventBody}`] = event["calendarId"];
		    // 		changedEventBody[`${props.EventStart}`] = (typeof event["start"] === 'object') ? formatDateString(event["start"]) : event["start"];
		    // 		changedEventBody[`${props.EventEnd}`] = (typeof event["end"] === 'object') ? formatDateString(event["end"]) : event["end"];
		    // 		changedEventBody[`${props.EventLocation}`] = event["location"];
		    // 		changedEventBody[`${props.EventCategory}`] = event["category"];
		    // 		changedEventBody[`${props.EventState}`] = event["state"];
		    // 		changedEventBody[`${props.EventIsReadOnly}`] = event["isReadOnly"] ? event["isReadOnly"] : false;
		    // 		changedEventBody[`color`] = '#fff';
		    // 		changedEventBody[`backgroundColor`] = '#ccc';
		    // 		changedEventBody[`backgroundColor`] = '#ccc';
		    // 		changedEventBody[`customStyle`] = {
		    //             fontStyle: 'italic', // Assuming default custom style, you can modify as needed
		    //             fontSize: '15px', // Assuming default custom style, you can modify as needed
		    //         };
		    // 	}

		    // 	console.log("changedEventBody", changedEventBody);

		    // 	// Function to build the config object dynamically
			// 	function buildConfig(apiMethodValue, changedEventBody, apiHeaders) {
			// 		let config = {
			// 			method: apiMethodValue,
			// 			headers: {
			// 				"Content-Type": "application/json",
			// 				...apiHeaders,
			// 			},
			// 			body: JSON.stringify({
			// 				to_insert: changedEventBody
			// 			}),
			// 		};

			// 		return config;
			// 	}

			// 	console.log('changedEventBody', changedEventBody);

		    // 	if (event["id"] && changedEventBody) {
		    // 		// if only custom url is provided
			// 		if (editCustomUrlValue && (!editBaseUrlValue || editBaseUrlValue === "null") && !editApiPathValue) {
			// 			// if nothing is provided, for authentication
			// 			if (!editApiUsernameValue && !editApiPasswordValue && !editApiBearerTokenValue) {
			// 				let headers = {};
			// 				if (editApiMoreHeadersValue) {
			// 					editApiMoreHeadersValue.split(/,\s*/).forEach((header) => {
			// 						let [key, val] = header.split(":");
			// 						headers[key] = val;
			// 					});
			// 				}

			// 				let config = buildConfig(editApiMethodValue, changedEventBody, headers);

			// 				console.log("config:", config);

			// 				try {
			// 					let response = await fetch(`${editCustomUrlValue}?id=${event["id"]}`, config);
			// 					let responseData = await response.json();
			// 					apiData = responseData;
			// 				} catch (e) {
			// 					console.log(e);
			// 				}
			// 			}

			// 			// if bearer token is provided but nothing else, for authentication
			// 			else if (!editApiUsernameValue && !editApiPasswordValue && editApiBearerTokenValue) {
			// 				let headers = {
			// 					Authorization: `Bearer ${editApiBearerTokenValue}`,
			// 				};

			// 				if (editApiMoreHeadersValue) {
			// 					editApiMoreHeadersValue.split(/,\s*/).forEach((header) => {
			// 						let [key, val] = header.split(":");
			// 						headers[key] = val;
			// 					});
			// 				}

			// 				let config = buildConfig(editApiMethodValue, changedEventBody, headers);

			// 				console.log("config:", config);

			// 				try {
			// 					let response = await fetch(`${editCustomUrlValue}?id=${event["id"]}`, config);
			// 					let responseData = await response.json();
			// 					apiData = responseData;
			// 				} catch (e) {
			// 					console.log(e);
			// 				}
			// 			}

			// 			// if username & password are provided but nothing else, for authentication
			// 			else if (editApiUsernameValue && editApiPasswordValue && !editApiBearerTokenValue) {
			// 				let headers = {
			// 					Authorization: "Basic " + btoa(editApiUsernameValue + ":" + editApiPasswordValue),
			// 				};

			// 				if (editApiMoreHeadersValue) {
			// 					editApiMoreHeadersValue.split(/,\s*/).forEach((header) => {
			// 						let [key, val] = header.split(":");
			// 						headers[key] = val;
			// 					});
			// 				}

			// 				let config = buildConfig(editApiMethodValue, headers);

			// 				console.log("config:", config);

			// 				try {
			// 					let response = await fetch(`${editCustomUrlValue}?id=${event["id"]}`, config);
			// 					let responseData = await response.json();
			// 					apiData = responseData;
			// 				} catch (e) {
			// 					console.log(e);
			// 				}
			// 			}
			// 		}

			// 		// if only base url is provided
			// 		else if (!editCustomUrlValue && (editBaseUrlValue || !editBaseUrlValue === "null") && editApiPathValue) {
			// 			let url, dynamicBaseUrlBearerToken;
			// 			let forDownloadUrl;

			// 			if (editBaseUrlValue === "baseUrl1") {
			// 				url = options.baseUrl1 + editApiPathValue;
			// 				forDownloadUrl = "{{API_URL1}}" + editApiPathValue;
			// 				dynamicBaseUrlBearerToken = baseUrl1BearerToken;
			// 			} else if (editBaseUrlValue === "baseUrl2") {
			// 				url = options.baseUrl2 + editApiPathValue;
			// 				forDownloadUrl = "{{API_URL2}}" + editApiPathValue;
			// 				dynamicBaseUrlBearerToken = baseUrl2BearerToken;
			// 			} else if (editBaseUrlValue === "baseUrl3") {
			// 				url = options.baseUrl3 + editApiPathValue;
			// 				forDownloadUrl = "{{API_URL3}}" + editApiPathValue;
			// 				dynamicBaseUrlBearerToken = baseUrl3BearerToken;
			// 			} else if (editBaseUrlValue === "baseUrl4") {
			// 				url = options.baseUrl4 + editApiPathValue;
			// 				forDownloadUrl = "{{API_URL4}}" + editApiPathValue;
			// 				dynamicBaseUrlBearerToken = baseUrl4BearerToken;
			// 			} else if (editBaseUrlValue === "baseUrl5") {
			// 				url = options.baseUrl5 + editApiPathValue;
			// 				forDownloadUrl = "{{API_URL5}}" + editApiPathValue;
			// 				dynamicBaseUrlBearerToken = baseUrl5BearerToken;
			// 			}

			// 			const urlDownload4 = window.top.location.href;
			// 			const isSubstringPresent4 = (urlDownload4.indexOf("/editor/") !== -1 && urlDownload4.indexOf("?projectId=") !== -1) || urlDownload4.indexOf("/large_preview/") !== -1 || urlDownload4.indexOf("/tab_preview/") !== -1 || urlDownload4.indexOf("/mobile_preview/") !== -1 || urlDownload4.indexOf("/fragment_editor/") !== -1;
			// 			if (!isSubstringPresent4) {
			// 				url = forDownloadUrl;
			// 			}

			// 			bu = url;
			// 			bubt = dynamicBaseUrlBearerToken;
			// 			console.log("url, dynamicBaseUrlBearerToken", url, dynamicBaseUrlBearerToken);

			// 			// if nothing is provided, for authentication
			// 			if (!editApiUsernameValue && !editApiPasswordValue && !editApiBearerTokenValue) {
			// 				let headers = {};
			// 				if (editApiMoreHeadersValue) {
			// 					editApiMoreHeadersValue.split(/,\s*/).forEach((header) => {
			// 						let [key, val] = header.split(":");
			// 						headers[key] = val;
			// 					});
			// 				}

			// 				let config = buildConfig(editApiMethodValue, changedEventBody, headers);

			// 				console.log("config:", config);

			// 				try {
			// 					let response = await fetch(`${url}?id=${event["id"]}`, config);
			// 					let responseData = await response.json();
			// 					apiData = responseData;
			// 				} catch (e) {
			// 					console.log(e);
			// 				}
			// 			}

			// 			// if bearer token is provided but nothing else, for authentication
			// 			else if (!editApiUsernameValue && !editApiPasswordValue && !editApiPasswordValue && dynamicBaseUrlBearerToken) {
			// 				let headers = {
			// 					Authorization: `Bearer ${dynamicBaseUrlBearerToken}`,
			// 				};

			// 				if (editApiMoreHeadersValue) {
			// 					editApiMoreHeadersValue.split(/,\s*/).forEach((header) => {
			// 						let [key, val] = header.split(":");
			// 						headers[key] = val;
			// 					});
			// 				}

			// 				let config = buildConfig(editApiMethodValue, headers);

			// 				console.log("config:", config);

			// 				try {
			// 					let response = await fetch(`${url}?id=${event["id"]}`, config);
			// 					let responseData = await response.json();
			// 					apiData = responseData;
			// 				} catch (e) {
			// 					console.log(e);
			// 				}
			// 			}

			// 			// if username & password are provided but nothing else, for authentication
			// 			else if (editApiUsernameValue && editApiPasswordValue && !editApiPasswordValue && !dynamicBaseUrlBearerToken) {
			// 				let headers = {
			// 					Authorization: "Basic " + btoa(editApiUsernameValue + ":" + editApiPasswordValue),
			// 				};

			// 				if (editApiMoreHeadersValue) {
			// 					editApiMoreHeadersValue.split(/,\s*/).forEach((header) => {
			// 						let [key, val] = header.split(":");
			// 						headers[key] = val;
			// 					});
			// 				}

			// 				let config = buildConfig(editApiMethodValue, headers);

			// 				console.log("config:", config);

			// 				try {
			// 					let response = await fetch(`${url}?id=${event["id"]}`, config);
			// 					let responseData = await response.json();
			// 					apiData = responseData;
			// 				} catch (e) {
			// 					console.log(e);
			// 				}
			// 			}
			// 		}
		    // 	}
    		// }

    		// // Helper function to delete calendar events
    		// async function addCalendarData(props, event) {
    		// 	let {
    		// 		addCustomUrlValue,
		    // 		addBaseUrlValue,
		    // 		addApiPathValue,
		    // 		addApiMethodValue,
		    // 		addApiUsernameValue,
		    // 		addApiPasswordValue,
		    // 		addApiBearerTokenValue,
		    // 		addApiMoreHeadersValue,
		    // 	} = props;

		    // 	let apiData, newEventBody = {};
		    // 	let bu, bubt; // base url bearer token, temp var

		    // 	if (event) {
		    // 		newEventBody[`${props.EvenCalendarId}`] = event["calendarId"];
		    // 		newEventBody[`${props.EventTitle}`] = event["title"];
		    // 		newEventBody[`${props.EvenCalendarId}`] = event["body"];
		    // 		newEventBody[`${props.EventBody}`] = event["body"];
		    // 		newEventBody[`${props.EventStart}`] = (typeof event["start"] === 'object') ? formatDateString(event["start"]) : event["start"];
		    // 		newEventBody[`${props.EventEnd}`] = (typeof event["end"] === 'object') ? formatDateString(event["end"]) : event["end"];
		    // 		newEventBody[`${props.EventLocation}`] = event["location"];
		    // 		newEventBody[`${props.EventCategory}`] = event["category"];
		    // 		newEventBody[`${props.EventState}`] = event["state"];
		    // 		newEventBody[`${props.EventIsReadOnly}`] = event["isReadOnly"] ? event["isReadOnly"] : false;
		    // 		newEventBody[`color`] = '#fff';
		    // 		newEventBody[`backgroundColor`] = '#ccc';
		    // 		newEventBody[`backgroundColor`] = '#ccc';
		    // 		newEventBody[`customStyle`] = {
		    //             fontStyle: 'italic', // Assuming default custom style, you can modify as needed
		    //             fontSize: '15px', // Assuming default custom style, you can modify as needed
		    //         };
		    // 	}

		    // 	console.log("newEventBody", newEventBody);

		    // 	// Function to build the config object dynamically
			// 	function buildConfig(apiMethodValue, newEventBody, apiHeaders) {
			// 		let config = {
			// 			method: apiMethodValue,
			// 			headers: {
			// 				"Content-Type": "application/json",
			// 				...apiHeaders,
			// 			},
			// 			body: JSON.stringify({
			// 				to_insert: newEventBody
			// 			}),
			// 		};

			// 		return config;
			// 	}

			// 	console.log('newEventBody', newEventBody);

		    // 	if (newEventBody) {
		    // 		// if only custom url is provided
			// 		if (addCustomUrlValue && (!addBaseUrlValue || addBaseUrlValue === "null") && !addApiPathValue) {
			// 			// if nothing is provided, for authentication
			// 			if (!addApiUsernameValue && !addApiPasswordValue && !addApiBearerTokenValue) {
			// 				let headers = {};
			// 				if (addApiMoreHeadersValue) {
			// 					addApiMoreHeadersValue.split(/,\s*/).forEach((header) => {
			// 						let [key, val] = header.split(":");
			// 						headers[key] = val;
			// 					});
			// 				}

			// 				let config = buildConfig(addApiMethodValue, newEventBody, headers);

			// 				console.log("config:", config);

			// 				try {
			// 					let response = await fetch(`${addCustomUrlValue}`, config);
			// 					let responseData = await response.json();
			// 					apiData = responseData;
			// 				} catch (e) {
			// 					console.log(e);
			// 				}
			// 			}

			// 			// if bearer token is provided but nothing else, for authentication
			// 			else if (!addApiUsernameValue && !addApiPasswordValue && addApiBearerTokenValue) {
			// 				let headers = {
			// 					Authorization: `Bearer ${addApiBearerTokenValue}`,
			// 				};

			// 				if (addApiMoreHeadersValue) {
			// 					addApiMoreHeadersValue.split(/,\s*/).forEach((header) => {
			// 						let [key, val] = header.split(":");
			// 						headers[key] = val;
			// 					});
			// 				}

			// 				let config = buildConfig(addApiMethodValue, newEventBody, headers);

			// 				console.log("config:", config);

			// 				try {
			// 					let response = await fetch(`${addCustomUrlValue}?id=${event["id"]}`, config);
			// 					let responseData = await response.json();
			// 					apiData = responseData;
			// 				} catch (e) {
			// 					console.log(e);
			// 				}
			// 			}

			// 			// if username & password are provided but nothing else, for authentication
			// 			else if (addApiUsernameValue && addApiPasswordValue && !addApiBearerTokenValue) {
			// 				let headers = {
			// 					Authorization: "Basic " + btoa(addApiUsernameValue + ":" + addApiPasswordValue),
			// 				};

			// 				if (addApiMoreHeadersValue) {
			// 					addApiMoreHeadersValue.split(/,\s*/).forEach((header) => {
			// 						let [key, val] = header.split(":");
			// 						headers[key] = val;
			// 					});
			// 				}

			// 				let config = buildConfig(addApiMethodValue, headers);

			// 				console.log("config:", config);

			// 				try {
			// 					let response = await fetch(`${addCustomUrlValue}?id=${event["id"]}`, config);
			// 					let responseData = await response.json();
			// 					apiData = responseData;
			// 				} catch (e) {
			// 					console.log(e);
			// 				}
			// 			}
			// 		}

			// 		// if only base url is provided
			// 		else if (!addCustomUrlValue && (addBaseUrlValue || !addBaseUrlValue === "null") && addApiPathValue) {
			// 			let url, dynamicBaseUrlBearerToken;
			// 			let forDownloadUrl;

			// 			if (addBaseUrlValue === "baseUrl1") {
			// 				url = options.baseUrl1 + addApiPathValue;
			// 				forDownloadUrl = "{{API_URL1}}" + addApiPathValue;
			// 				dynamicBaseUrlBearerToken = baseUrl1BearerToken;
			// 			} else if (addBaseUrlValue === "baseUrl2") {
			// 				url = options.baseUrl2 + addApiPathValue;
			// 				forDownloadUrl = "{{API_URL2}}" + addApiPathValue;
			// 				dynamicBaseUrlBearerToken = baseUrl2BearerToken;
			// 			} else if (addBaseUrlValue === "baseUrl3") {
			// 				url = options.baseUrl3 + addApiPathValue;
			// 				forDownloadUrl = "{{API_URL3}}" + addApiPathValue;
			// 				dynamicBaseUrlBearerToken = baseUrl3BearerToken;
			// 			} else if (addBaseUrlValue === "baseUrl4") {
			// 				url = options.baseUrl4 + addApiPathValue;
			// 				forDownloadUrl = "{{API_URL4}}" + addApiPathValue;
			// 				dynamicBaseUrlBearerToken = baseUrl4BearerToken;
			// 			} else if (addBaseUrlValue === "baseUrl5") {
			// 				url = options.baseUrl5 + addApiPathValue;
			// 				forDownloadUrl = "{{API_URL5}}" + addApiPathValue;
			// 				dynamicBaseUrlBearerToken = baseUrl5BearerToken;
			// 			}

			// 			const urlDownload4 = window.top.location.href;
			// 			const isSubstringPresent4 = (urlDownload4.indexOf("/addor/") !== -1 && urlDownload4.indexOf("?projectId=") !== -1) || urlDownload4.indexOf("/large_preview/") !== -1 || urlDownload4.indexOf("/tab_preview/") !== -1 || urlDownload4.indexOf("/mobile_preview/") !== -1 || urlDownload4.indexOf("/fragment_addor/") !== -1;
			// 			if (!isSubstringPresent4) {
			// 				url = forDownloadUrl;
			// 			}

			// 			bu = url;
			// 			bubt = dynamicBaseUrlBearerToken;
			// 			console.log("url, dynamicBaseUrlBearerToken", url, dynamicBaseUrlBearerToken);

			// 			// if nothing is provided, for authentication
			// 			if (!addApiUsernameValue && !addApiPasswordValue && !addApiBearerTokenValue) {
			// 				let headers = {};
			// 				if (addApiMoreHeadersValue) {
			// 					addApiMoreHeadersValue.split(/,\s*/).forEach((header) => {
			// 						let [key, val] = header.split(":");
			// 						headers[key] = val;
			// 					});
			// 				}

			// 				let config = buildConfig(addApiMethodValue, newEventBody, headers);

			// 				console.log("config:", config);

			// 				try {
			// 					let response = await fetch(`${url}?id=${event["id"]}`, config);
			// 					let responseData = await response.json();
			// 					apiData = responseData;
			// 				} catch (e) {
			// 					console.log(e);
			// 				}
			// 			}

			// 			// if bearer token is provided but nothing else, for authentication
			// 			else if (!addApiUsernameValue && !addApiPasswordValue && !addApiPasswordValue && dynamicBaseUrlBearerToken) {
			// 				let headers = {
			// 					Authorization: `Bearer ${dynamicBaseUrlBearerToken}`,
			// 				};

			// 				if (addApiMoreHeadersValue) {
			// 					addApiMoreHeadersValue.split(/,\s*/).forEach((header) => {
			// 						let [key, val] = header.split(":");
			// 						headers[key] = val;
			// 					});
			// 				}

			// 				let config = buildConfig(addApiMethodValue, headers);

			// 				console.log("config:", config);

			// 				try {
			// 					let response = await fetch(`${url}?id=${event["id"]}`, config);
			// 					let responseData = await response.json();
			// 					apiData = responseData;
			// 				} catch (e) {
			// 					console.log(e);
			// 				}
			// 			}

			// 			// if username & password are provided but nothing else, for authentication
			// 			else if (addApiUsernameValue && addApiPasswordValue && !addApiPasswordValue && !dynamicBaseUrlBearerToken) {
			// 				let headers = {
			// 					Authorization: "Basic " + btoa(addApiUsernameValue + ":" + addApiPasswordValue),
			// 				};

			// 				if (addApiMoreHeadersValue) {
			// 					addApiMoreHeadersValue.split(/,\s*/).forEach((header) => {
			// 						let [key, val] = header.split(":");
			// 						headers[key] = val;
			// 					});
			// 				}

			// 				let config = buildConfig(addApiMethodValue, headers);

			// 				console.log("config:", config);

			// 				try {
			// 					let response = await fetch(`${url}?id=${event["id"]}`, config);
			// 					let responseData = await response.json();
			// 					apiData = responseData;
			// 				} catch (e) {
			// 					console.log(e);
			// 				}
			// 			}
			// 		}
		    // 	}
    		// }

    		// Helper function to update the current date in the calendar nav bar
            function updateCurrentDate(calendar) {
			    const start = calendar.getDateRangeStart().toDate(); // Convert TZDate to Date
			    const end = calendar.getDateRangeEnd().toDate(); // Convert TZDate to Date
			    const currentDateRange = start.toDateString() + ' - ' + end.toDateString();

			    let calNavActiveDate = calendarContainer.getElementsByClassName('custom-calendar-nav-active-date')[0];
			    if (calNavActiveDate) {
			    	calNavActiveDate.innerText = currentDateRange;
			    }			    
		    }

    		// Helper function to convert the apiData into required structure for plotting events in the calendar
    		function transformApiResponse(apiResponse, props) {
			    if (!Array.isArray(apiResponse)) {
			        console.error("Expected an array for apiResponse but got:", apiResponse);
			        return [];
			    }

			    return apiResponse.map(event => {
			        const transformedEvent = {
			            id: event[props.EventId],
			            calendarId: event[props.EvenCalendarId] ? event[props.EvenCalendarId] : 'cal1',
			            title: event[props.EventTitle],
			            body: event[props.EventBody],
			            start: event[props.EventStart],
			            end: event[props.EventEnd],
			            location: event[props.EventLocation],
			            state: event[props.EventState],
			            isReadOnly: event[props.EventIsReadOnly] ? event[props.EventIsReadOnly] : false,
			            attendees: event.attendees_data ? [event.attendees_data.Name] : [], // Assuming attendees data is in attendees_data
			            color: event[props.EventTextColor],
			            backgroundColor: event[props.EventBackgroundColor],
			            dragBackgroundColor: event[props.EventDragBackgroundColor],
			            borderColor: event[props.EventBorderColor],
			            customStyle: {
			                fontStyle: event[props.EventFontStyle],
			                fontSize: event[props.EventFontSize] + 'px',
			            	borderRadius: event[props.EventBorderRadiusr],
			            }
			        };

			        // // Conditionally add the category property
			        // if (event[props.EventCategory]) {
			        //     transformedEvent.category = event[props.EventCategory];
			        // }

			        return transformedEvent;
			    });
			}

		    async function initLib(props) {
		    	let apiData;

		        apiData = await gatherCalendarData(props);
		        if(apiData && isArrayofObjects(apiData)) apiData = transformApiResponse(apiData, props);
		        // console.log('events data', apiData);

		        const options = {
		            defaultView: 'week',
		            useFormPopup: false,
		            useDetailPopup: false,
		            isReadOnly: true, // Disables event dragging and resizing for the whole calendar
		            template: {
		                time(event) {
				            const { start, end, title, body, location, state } = event;
				            return `
				                <span style="color: white; padding-top: 1%;">
				                    ${formatTime(start)} — ${formatTime(end)} 
				                    ${(title && props.DisplayEventTitle) ? `<br>${title}` : ''}
				                    ${(body && props.DisplayEventBody) ? `<br><strong>${props.BodyLabelText ? props.BodyLabelText : 'Description:'}</strong> ${body}` : ''}
				                    ${(location && props.DisplayEventLocation) ? `<br><strong>${props.LocationLabelText ? props.LocationLabelText : 'Location:'}</strong> ${location}` : ''}
				                    ${(state && props.DisplayEventState) ? `<br><strong>${props.StateLabelText ? props.StateLabelText : 'State:'}</strong> ${state}` : ''}
				                </span>
				            `;
				        },
				        allday(event) {
				            const { title, body, location, state } = event;
				            return `
				                <span style="color: gray; padding-top: 1%;">
				                    ${(title && props.DisplayEventTitle) ? `<br><strong>${title}</strong>` : ''}
				                    ${(body && props.DisplayEventBody) ? `<br><strong>${props.BodyLabelText ? props.BodyLabelText : 'Description:'}:</strong> ${body}` : ''}
				                    ${(location && props.DisplayEventLocation) ? `<br><strong>${props.LocationLabelText ? props.LocationLabelText : 'Location:'}</strong> ${location}` : ''}
				                    ${(state && props.DisplayEventState) ? `<br><strong>${props.StateLabelText ? props.StateLabelText : 'State:'}</strong> ${state}` : ''}
				                </span>
				            `;
				        },
				        milestone(event) {
				            const { title, body, location, state } = event;
				            return `
				                <span style="color: orange; padding-top: 1%;">
				                    ${(title && props.DisplayEventTitle) ? `<br><strong>Milestone:</strong> ${title}` : ''}
				                    ${(body && props.DisplayEventBody) ? `<br><strong>${props.BodyLabelText ? props.BodyLabelText : 'Description:'}</strong> ${body}` : ''}
				                    ${(location && props.DisplayEventLocation) ? `<br><strong>${props.LocationLabelText ? props.LocationLabelText : 'Location:'}</strong> ${location}` : ''}
				                    ${(state && props.DisplayEventState) ? `<br><strong>${props.StateLabelText ? props.StateLabelText : 'State:'}</strong> ${state}` : ''}
				                </span>
				            `;
				        },
				        task(event) {
				            const { title, body, location, state } = event;
				            return `
				                <span style="color: blue; padding-top: 1%;">
				                    ${(title && props.DisplayEventTitle) ? `<br><strong>Task:</strong> ${title}` : ''}
				                    ${(body && props.DisplayEventBody) ? `<br><strong>${props.BodyLabelText ? props.BodyLabelText : 'Description:'}</strong> ${body}` : ''}
				                    ${(location && props.DisplayEventLocation) ? `<br><strong>${props.LocationLabelText ? props.LocationLabelText : 'Location:'}</strong> ${location}` : ''}
				                    ${(state && props.DisplayEventState) ? `<br><strong>${props.StateLabelText ? props.StateLabelText : 'State:'}</strong> ${state}` : ''}
				                </span>
				            `;
				        },
				        month(event) {
				            const { start, end, title, body, location, state } = event;
				            return `
				                <span style="color: purple; padding-top: 1%;">
				                    ${formatTime(start)} — ${formatTime(end)}
				                	${(title && props.DisplayEventTitle) ? `<br>${title}` : ''}
				                    ${(body && props.DisplayEventBody) ? `<br><strong>${props.BodyLabelText ? props.BodyLabelText : 'Description:'}</strong> ${body}` : ''}
				                    ${(location && props.DisplayEventLocation) ? `<br><strong>${props.LocationLabelText ? props.LocationLabelText : 'Location:'}</strong> ${location}` : ''}
				                    ${(state && props.DisplayEventState) ? `<br><strong>${props.StateLabelText ? props.StateLabelText : 'State:'}</strong> ${state}` : ''}
				                </span>
				            `;
				        }
		            },
		            calendars: [
		                {
		                    id: 'cal1',
		                    name: 'Personal',
		                    backgroundColor: '#03bd9e',
		                },
		                {
		                    id: 'cal2',
		                    name: 'Work',
		                    backgroundColor: '#00a9ff',
		                },
		            ],
		        };

		        // Check if the calendar div is in the DOM
		        try {
		            const calendarElement = calendarContainer.getElementsByClassName('custom-calendar')[0];
		            // console.log('+calendarElement+', calendarElement);

		            if (calendarElement) {
		                // Remove any existing calendar instance
                        if (calendarElement._calendarInstance) {
                            calendarElement._calendarInstance.destroy();
                        }

                        const calendar = new window.tui.Calendar(calendarElement, options);

                        // plot events if there are any
		                if (calendar && apiData && isArrayofObjects(apiData)) {
		                	// console.log("+apiData+", apiData);
		                    calendar.createEvents(apiData);
		                }

		                // // attaching calendar event listeners for ADD, EDIT, DELETE
		                // // ADD: Creating an event through popup
						// calendar.on('beforeCreateEvent', (eventObj) => {
						// 	// to update the calendar view
						// 	calendar.createEvents([
						// 	    {
						// 		    ...eventObj,
						// 		    // id: uuid(),
						// 	    },
						// 	]);
						// 	// to update the backend database
						// 	addCalendarData(props, eventObj)
						// 	console.log('created', eventObj);
						// });

						// // EDIT: Editing an event through popup
						// calendar.on('beforeUpdateEvent', (obj) => {
						// 	let { event, changes } = obj;
							
						// 	// Merge changes into the event object
						//     event = {
						//         ...event,
						//         ...changes
						//     };

						//     // to update the calendar view
						// 	calendar.updateEvent(event.id, event.calendarId, changes);
						// 	// to update the backend database
						// 	editCalendarData(props, event);
						// 	console.log('edited', event, changes);
						// });

						// // DELETE: Deliting an event through popup
		                // calendar.on('beforeDeleteEvent', (eventObj) => {
		                // 	// to update the calendar view
						// 	calendar.deleteEvent(eventObj.id, eventObj.calendarId);
						// 	// to update the backend database
						// 	deleteCalendarData(props, eventObj.id);
						// 	console.log('deleted', eventObj);
						// });

		                // Store the calendar instance for later use/removal
                        calendarElement._calendarInstance = calendar;

		                console.log('TUI Calendar initialized:', calendar, calendarContainer);

		                // TODAY/NEXT/PREV button event listeners
					    // Update the date display whenever you change the view
					    let calNavPrevBtn = calendarContainer.getElementsByClassName('custom-calendar-nav-prev-btn')[0];
					    if (calNavPrevBtn) {
						    calNavPrevBtn.addEventListener('click', (e) => {
						      calendar.prev();
						      updateCurrentDate(calendar);
						    });
					    }					    

					    let calNavTodayBtn = calendarContainer.getElementsByClassName('custom-calendar-nav-today-btn')[0];
					    if (calNavTodayBtn) {
						    calNavTodayBtn.addEventListener('click', (e) => {
						      calendar.today();
						      updateCurrentDate(calendar);
						    });
					    }
					    
					    let calNavNextBtn = calendarContainer.getElementsByClassName('custom-calendar-nav-next-btn')[0];
					    if (calNavNextBtn) {
						    calNavNextBtn.addEventListener('click', (e) => {
						      calendar.next();
						      updateCurrentDate(calendar);
						    });
					    }					    

					    // Set the initial display of the current date range
					    updateCurrentDate(calendar);
		            } else {
		                console.error('Calendar element not found!');
		            }
		        } catch (e) {
		            console.error(e);
		        }
		    }

	        // to update table props as soon as window is updated and then trigger rendering
			if (!isSubstringPresent) {
		        setInterval(function () {
		          Object.keys(props).forEach(function (key) {
		            if (window[`${key}${idEl}`]) {
		              if(props[key]===window[`${key}${idEl}`]){
		                // console.log('');
		              }else{
		                props[key] = window[`${key}${idEl}`];
		                initLib();	                
		              }
		            }
		          });
		        }, 500);
		    }

		    // Function to load the TUI Calendar script dynamically
            function loadCalendarScript(callback) {
                const script = document.createElement('script');
                script.src = 'https://uicdn.toast.com/calendar/latest/toastui-calendar.min.js';
                script.onload = callback;
                script.onerror = function() {
                    console.error('Failed to load TUI Calendar script');
                };
                document.body.appendChild(script);
            }

            // Add the custom CSS styles to the document to hide 'all-day, milestones, tasks' section
            const hideMilestoneCalendarSection = () => {
                const style = document.createElement('style');
                style.textContent = `
                    .toastui-calendar-panel.toastui-calendar-milestone,
                    .toastui-calendar-panel.toastui-calendar-task,
                    .toastui-calendar-panel.toastui-calendar-allday,
                    .toastui-calendar-panel-resizer,
                    .toastui-calendar-panel-resizer-guide {
                        display: none;
                    }
                `;
                document.head.appendChild(style);
            };

            // Check if Calendar is loaded
            if (typeof window.tui === 'undefined' || typeof window.tui.Calendar === 'undefined') {
                loadCalendarScript(() => {
                    // Ensure the Calendar is available before calling initLib
                    if (typeof window.tui !== 'undefined' && typeof window.tui.Calendar !== 'undefined') {
                        initLib(props);
                        hideMilestoneCalendarSection();
                    } else {
                        console.error('Calendar is still not defined after script load');
                    }
                });
            } else {
                console.log('InitLib');
                initLib(props);
                hideMilestoneCalendarSection();
            }
		};
		
	    editor.DomComponents.addType("custom-calendar-container", {
	    	model: {
	    		defaults: {
	    			tagName: "div",
	    			type: "custom-calendar-container",
	    			attributes: {
	    				class: "custom-calendar-container",
	    			},
	    			script: calendarScript,

	    			// traits
	    			projectId: localStorage.getItem("project_id") ? localStorage.getItem("project_id") : "",

	    			EventId: "_id",
	    			EvenCalendarId: "calendarId",
	    			EventTitle: "title",
	    			EventBody: "body",
	    			EventStart: "start",
	    			EventEnd: "end",
	    			EventLocation: "location",
	    			EventCategory: "category",
	    			EventState: "state",
	    			EventIsReadOnly: "isReadOnly",

	    			EventTextColor: "color",
	    			EventBackgroundColor: "backgroundColor",
	    			EventDragBackgroundColor: "dragBackgroundColor",
	    			EventBorderColor: "borderColor",
	    			EventBorderRadiusr: "borderRadius",
	    			EventFontSize: "fontSize",
	    			EventFontStyle: "fontStyle",

	    			// traits to control what can be seen in event boxes plotted in the calendar
	    			DisplayEventTitle: true,
	    			DisplayEventBody: true,
	    			DisplayEventLocation: true,
	    			DisplayEventState: true,

	    			// traits to customize label text of labels: Description, Location, State; in the calendar event cards
	    			BodyLabelText: "Description:",
	    			LocationLabelText: "Location:",
	    			StateLabelText: "State:",

	    			// GET Events in calendar
					customUrlValue: "",
					baseUrlValue: "",
					apiPathValue: "",
					apiMethodValue: "",
					apiUsernameValue: "",
					apiPasswordValue: "",
					apiBearerTokenValue: "",
					apiMoreHeadersValue: "",
					apiBodyValue: "",
					nestedArrOfObjKeynameValue: "",

	    			// DELETE Events in calendar
					deleteCustomUrlValue: "",
					deleteBaseUrlValue: "",
					deleteApiPathValue: "",
					deleteApiMethodValue: "DELETE",
					deleteApiUsernameValue: "",
					deleteApiPasswordValue: "",
					deleteApiBearerTokenValue: "",
					deleteApiMoreHeadersValue: "",

	    			// EDIT Events in calendar
					editCustomUrlValue: "",
					editBaseUrlValue: "",
					editApiPathValue: "",
					editApiMethodValue: "PUT",
					editApiUsernameValue: "",
					editApiPasswordValue: "",
					editApiBearerTokenValue: "",
					editApiMoreHeadersValue: "",
					// editApiBodyValue: "",

	    			// ADD Events in calendar
					addCustomUrlValue: "",
					addBaseUrlValue: "",
					addApiPathValue: "",
					addApiMethodValue: "POST",
					addApiUsernameValue: "",
					addApiPasswordValue: "",
					addApiBearerTokenValue: "",
					addApiMoreHeadersValue: "",

					changeProp: true,
					"script-props": [
						"projectId",
						"EventId",
						"EvenCalendarId",
						"EventTitle",
						"EventBody",
						"EventStart",
						"EventEnd",
						"EventLocation",
						"EventCategory",
						"EventState",
						"EventIsReadOnly",
						"EventTextColor",
		    			"EventBackgroundColor",
		    			"EventDragBackgroundColor",
		    			"EventBorderColor",
		    			"EventBorderRadiusr",
		    			"EventFontSize",
		    			"EventFontStyle",
		    			"DisplayEventTitle",
		    			"DisplayEventBody",
		    			"DisplayEventLocation",
		    			"DisplayEventState",
		    			"BodyLabelText",
		    			"LocationLabelText",
		    			"StateLabelText",
						"customUrlValue",
						"baseUrlValue",
						"apiPathValue",
						"apiMethodValue",
						"apiUsernameValue",
						"apiPasswordValue",
						"apiBearerTokenValue",
						"apiMoreHeadersValue",
						"apiBodyValue",
						"nestedArrOfObjKeynameValue",
						"deleteCustomUrlValue",
						"deleteBaseUrlValue",
						"deleteApiPathValue",
						"deleteApiMethodValue",
						"deleteApiUsernameValue",
						"deleteApiPasswordValue",
						"deleteApiBearerTokenValue",
						"deleteApiMoreHeadersValue",
						"editCustomUrlValue",
						"editBaseUrlValue",
						"editApiPathValue",
						"editApiMethodValue",
						"editApiUsernameValue",
						"editApiPasswordValue",
						"editApiBearerTokenValue",
						"editApiMoreHeadersValue",
						// "editApiBodyValue",
						"addCustomUrlValue",
						"addBaseUrlValue",
						"addApiPathValue",
						"addApiMethodValue",
						"addApiUsernameValue",
						"addApiPasswordValue",
						"addApiBearerTokenValue",
						"addApiMoreHeadersValue",
					],

	    			components: [
	    				{
	    					tagName: "div",
	    					type: "custom-calendar-nav",
	    					attributes: {
	    						class: "custom-calendar-nav",
	    					},

	    					"style-default": {
	    						display: 'flex',
	    						"align-items": "center",
	    						"justify-content": "flex-start",
	    						"column-gap": "3%",
	    						"margin-bottom": "0.5%",
	    						"margin-bottom": "1%",
	    						width: "50%",
	    					},

	    					style: {
	    						display: 'flex',
	    						"align-items": "center",
	    						"justify-content": "flex-start",
	    						"column-gap": "3%",
	    						"margin-bottom": "0.5%",
	    						"margin-bottom": "1%",
	    						width: "50%",
	    					},

	    					components: [
	    						{
	    							tagName: "button",
	    							type: "custom-calendar-nav-today-btn",
			    					attributes: {
			    						class: "custom-calendar-nav-today-btn",
			    					},

			    					"style-default": {
			    						padding: "1% 2%",
			    						"border-radius": "20%",
			    						border: "1px solid lightgray",
			    						width: "max-content",
			    						cursor: "pointer",
			    					},

			    					style: {
			    						padding: "1% 2%",
			    						"border-radius": "20%",
			    						border: "1px solid lightgray",
			    						width: "max-content",
			    						cursor: "pointer",
			    					},

			    					components: {
						                tagName: "p",
						                type: "text",
						  
						                components: {
						                    type: "textnode",
						                    content: "Today",
						                },
						  
						                "style-default": {
						                    margin: "auto",
						                    "text-align": "center",
						                    color: "inherit",
						                },
						  
						                style: {
						                    margin: "auto",
						                    "text-align": "center",
						                    color: "inherit",
						                },
						            },
	    						}, {
	    							tagName: "button",
	    							type: "custom-calendar-nav-prev-btn",
			    					attributes: {
			    						class: "custom-calendar-nav-prev-btn",
			    					},

			    					"style-default": {
			    						padding: "1% 2%",
			    						"border-radius": "20%",
			    						border: "1px solid lightgray",
			    						width: "max-content",
			    						cursor: "pointer",
			    					},

			    					style: {
			    						padding: "1% 2%",
			    						"border-radius": "20%",
			    						border: "1px solid lightgray",
			    						width: "max-content",
			    						cursor: "pointer",
			    					},

			    					components: {
						                tagName: "p",
						                type: "text",

						                "style-default": {
						                    margin: "auto",
						                    "text-align": "center",
						                    color: "inherit",
						                },
						  
						                style: {
						                    margin: "auto",
						                    "text-align": "center",
						                    color: "inherit",
						                },
						  
						                components: '<i class="fa fa-chevron-left"></i>',
						            },
	    						}, {
	    							tagName: "button",
	    							type: "custom-calendar-nav-next-btn",
			    					attributes: {
			    						class: "custom-calendar-nav-next-btn",
			    					},

			    					"style-default": {
			    						padding: "1% 2%",
			    						"border-radius": "20%",
			    						border: "1px solid lightgray",
			    						width: "max-content",
			    						cursor: "pointer",
			    					},

			    					style: {
			    						padding: "1% 2%",
			    						"border-radius": "20%",
			    						border: "1px solid lightgray",
			    						width: "max-content",
			    						cursor: "pointer",
			    					},

			    					components: {
						                tagName: "p",
						                type: "text",

						                "style-default": {
						                    margin: "auto",
						                    "text-align": "center",
						                    color: "inherit",
						                },
						  
						                style: {
						                    margin: "auto",
						                    "text-align": "center",
						                    color: "inherit",
						                },
						  
						                components: '<i class="fa fa-chevron-right"></i>',
						            },
	    						}, {
					                tagName: "p",
					                type: "text",
			    					attributes: {
			    						class: "custom-calendar-nav-active-date",
			    					},

					                "style-default": {
					                    "margin-top": "auto",
					                    "margin-bottom": "auto",
					                    "text-align": "center",
					                    color: "inherit",
					                },
					  
					                style: {
					                    "margin-top": "auto",
					                    "margin-bottom": "auto",
					                    "text-align": "center",
					                    color: "inherit",
					                },
					  
					                components: '',
					            },
	    					],
	    				},{
	    					tagName: "div",
	    					type: "custom-calendar",
	    					attributes: {
	    						class: "custom-calendar",
	    						id: "calendar",
	    					},

	    					"style-default": {
	    						height: '100vh',
	    					},

	    					style: {
	    						height: '100vh',
	    					},
	    				},
	    			],
	    		},
	    	},

	    	view: {
	    		events: {
					"click .customize-calendar-btn": "openModal",
				},

				openModal(event) {
					event.stopPropagation();

					const form = document.createElement("div");
					form.setAttribute("class", "custom-calendar-form");

					form.innerHTML = `
						<ul class="nav nav-tabs" id="myTab" role="tablist">
							<li class="nav-item" role="presentation">
							    <button class="nav-link active" id="events-api-key-mapping-tab" data-bs-toggle="tab" data-bs-target="#events-api-key-mapping-tab-pane" type="button" role="tab" aria-controls="events-api-key-mapping-tab-pane" aria-selected="true">Events API Key Mapping</button>
							</li>

							<li class="nav-item" role="presentation">
							    <button class="nav-link" id="import-calendar-events-tab" data-bs-toggle="tab" data-bs-target="#import-calendar-events-tab-pane" type="button" role="tab" aria-controls="import-calendar-events-tab-pane" aria-selected="false">Import Calendar Events</button>
							</li>

							<!-- <li class="nav-item" role="presentation">
							    <button class="nav-link" id="delete-calendar-events-tab" data-bs-toggle="tab" data-bs-target="#delete-calendar-events-tab-pane" type="button" role="tab" aria-controls="delete-calendar-events-tab-pane" aria-selected="false">Delete Calendar Events</button>
							</li>

							<li class="nav-item" role="presentation">
							    <button class="nav-link" id="edit-calendar-events-tab" data-bs-toggle="tab" data-bs-target="#edit-calendar-events-tab-pane" type="button" role="tab" aria-controls="edit-calendar-events-tab-pane" aria-selected="false">Edit Calendar Events</button>
							</li>

							<li class="nav-item" role="presentation">
							    <button class="nav-link" id="add-calendar-events-tab" data-bs-toggle="tab" data-bs-target="#add-calendar-events-tab-pane" type="button" role="tab" aria-controls="add-calendar-events-tab-pane" aria-selected="false">Add Calendar Events</button>
							</li> -->
						</ul>

						<div class="tab-content" id="myTabContent">
							<div class="tab-pane fade show active" id="events-api-key-mapping-tab-pane" role="tabpanel" aria-labelledby="events-api-key-mapping-tab" tabindex="0">
								<div>
									<div class="left-col" id="left-col">
										<!-- id -->
				                        <div>
				                        	<span>
				                            	<label for="event-id" title="The keyname from the API response that can be used as a unique event ID.">Event Id Key</label>
				                            </span>
				                            <input type="text" name="event-id" id="event-id" class="event-id" placeholder="_id">
				                        </div>
								  		
								  		<!-- calendarId -->
				                        <div>
				                        	<span>
				                            	<label for="event-calendarId" title="The keyname from the API response that can be used as a calendar ID.">Calendar Id Key</label>
				                            </span>
				                            <input type="text" name="event-calendarId" id="event-calendarId" class="event-calendarId" placeholder="cal1">
				                        </div>
								  		
								  		<!-- title -->
				                        <div>
				                        	<span>
				                            	<label for="event-title" title="The keyname from the API response that can be used as a title for the event.">Event Title Key</label>
				                            </span>
				                            <input type="text" name="event-title" id="event-title" class="event-title" placeholder="Timed Event">
				                            <input type="checkbox" name="event-title-display" id="event-title-display" class="event-title-display" title="Uncheck to hide the event's title from the event box in the calendar." checked>
				                        </div>
								  		
								  		<!-- body -->
				                        <div>
				                        	<span>
				                            	<label for="event-body" title="The keyname from the API response that can be used as a description for the event.">Event Body Key</label>
				                            </span>

				                            <span id="event-body-span">
					                            <input type="text" name="event-body" id="event-body" class="event-body" placeholder="This event is organized as a catchup team meeting.">
					                            <input type="checkbox" name="event-body-display" id="event-body-display" class="event-body-display" body="Uncheck to hide the event's description from the event box in the calendar." checked>
					                        	<div>
					                        		<input type="text" name="event-body-label-text" id="event-body-label-text" class="event-body-label-text" placeholder="E.x., Description: ..." title="Edit label text for event body. E.x., Description: ...">
					                        		<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pen-fill" viewBox="0 0 16 16"><path d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001"/></svg>
					                        	</div>
					                        </span>
				                        </div>
								  		
								  		<!-- start -->
				                        <div>
				                        	<span>
				                            	<label for="event-start" title="The keyname from the API response that can be used as a starting date-time stamp for the event.">Event Start Key</label>
				                            </span>
				                            <input type="text" name="event-start" id="event-start" class="event-start" placeholder="2024-07-15T09:00:00">
				                        </div>
								  		
								  		<!-- end -->
				                        <div>
				                        	<span>
				                            	<label for="event-end" title="The keyname from the API response that can be used as a ending date-time stamp for the event.">Event End Key</label>
				                            </span>
				                            <input type="text" name="event-end" id="event-end" class="event-end" placeholder="2024-07-15T12:00:00">
				                        </div>
								  		
								  		<!-- location -->
				                        <div>
				                        	<span>
				                            	<label for="event-location" title="The keyname from the API response that can be used as a location to inform where the event is going to be held.">Event Location Key</label>
				                            </span>

				                            <span id="event-location-span">
					                            <input type="text" name="event-location" id="event-location" class="event-location" placeholder="Meeting Room A">
					                            <input type="checkbox" name="event-location-display" id="event-location-display" class="event-location-display" location="Uncheck to hide the event's location from the event box in the calendar." checked>
				                        		<div>
					                        		<input type="text" name="event-location-label-text" id="event-location-label-text" class="event-location-label-text" placeholder="E.x., Location: ..." title="Edit label text for event location. E.x., Location: ...">
					                        		<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pen-fill" viewBox="0 0 16 16"><path d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001"/></svg>
					                        	</div>
				                        	</span>
				                        </div>
								  		
								  		<!-- category -->
				                        <!-- <div>
				                        	<span>
				                            	<label for="event-category" title="Category of the event. The keyname from the API response that can be used as category. One of: milestone, task, allday, or time.">*Event Category Key</label>
				                            </span>
				                            <input type="text" name="event-category" id="event-category" class="event-category" placeholder="One of: milestone, task, allday, or time.">
				                        </div> -->
								  		
								  		<!-- state -->
				                        <div>
				                        	<span>
				                            	<label for="event-state" title="Status/State of the event. The keyname from the API response that can be used as state. One of: Busy and Free.">Event Status (State) Key</label>
				                            </span>

				                            <span id="event-state-span">
					                            <input type="text" name="event-state" id="event-state" class="event-state" placeholder="One of: Busy and Free.">
					                            <input type="checkbox" name="event-state-display" id="event-state-display" class="event-state-display" state="Uncheck to hide the event's state from the event box in the calendar." checked>
				                        		<div>
					                        		<input type="text" name="event-state-label-text" id="event-state-label-text" class="event-state-label-text" placeholder="E.x., State: ..." title="Edit label text for event state. E.x., State: ...">
					                        		<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pen-fill" viewBox="0 0 16 16"><path d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001"/></svg>
					                        	</div>
				                        	</span>
				                        </div>
								  		
								  		<!-- isReadOnly -->
				                        <div>
				                        	<span>
				                            	<label for="event-isReadOnly" title="Whether the event is read-only or not. The keyname from the API response that can be used as isReadOnly.">Event Is-Ready-Only-Or-Not Key</label>
				                            </span>
				                            <input type="text" name="event-isReadOnly" id="event-isReadOnly" class="event-isReadOnly" placeholder="isReadOnly">
				                        </div>

				                        <!-- save button -->
				                        <button class="save-events-api-key-mapping-btn">Save</button>
									</div>

									<div class="right-col" id="right-col">
										<!-- text color -->
				                        <div>
				                        	<span>
				                            	<label for="event-text-color" title="The keyname from the API response that can be used as text color for the events.">Event Text Color Key</label>
				                            </span>
				                            <input type="text" name="event-text-color" id="event-text-color" class="event-text-color" placeholder="color">
				                        </div>
								  		
								  		<!-- background color -->
				                        <div>
				                        	<span>
				                            	<label for="event-background-color" title="The keyname from the API response that can be used as background color for the events.">Event Background Color Key</label>
				                            </span>
				                            <input type="text" name="event-background-color" id="event-background-color" class="event-background-color" placeholder="backgroundColor">
				                        </div>
								  		
								  		<!-- drag background color -->
				                        <div>
				                        	<span>
				                            	<label for="event-drag-background-color" title="The keyname from the API response that can be used as background color for the events when they are being dragged.">Event Drag Background Color Key</label>
				                            </span>
				                            <input type="text" name="event-drag-background-color" id="event-drag-background-color" class="event-drag-background-color" placeholder="dragBackgroundColor">
				                        </div>
								  		
								  		<!-- left border color -->
				                        <div>
				                        	<span>
				                            	<label for="event-left-border-color" title="The keyname from the API response that can be used as the left border color for the events.">Event Left Border Color Key</label>
				                            </span>
				                            <input type="text" name="event-left-border-color" id="event-left-border-color" class="event-left-border-color" placeholder="borderColor">
				                        </div>
								  		
								  		<!-- border radius -->
				                        <div>
				                        	<span>
				                            	<label for="event-border-radius" title="The keyname from the API response that can be used as the border radius for the events.">Event Border Radius Key</label>
				                            </span>
				                            <input type="text" name="event-border-radius" id="event-border-radius" class="event-border-radius" placeholder="borderRadius">
				                        </div>
								  		
								  		<!-- font size -->
				                        <div>
				                        	<span>
				                            	<label for="event-font-size" title="The keyname from the API response that can be used as the font size for the events.">Event Font Size Key</label>
				                            </span>
				                            <input type="text" name="event-font-size" id="event-font-size" class="event-font-size" placeholder="fontSize">
				                        </div>
								  		
								  		<!-- font style -->
				                        <div>
				                        	<span>
				                            	<label for="event-font-style" title="The keyname from the API response that can be used as the font style for the events. One of: normal, italic.">Event Font Style Key</label>
				                            </span>
				                            <input type="text" name="event-font-style" id="event-font-style" class="event-font-style" placeholder="fontStyle">
				                        </div>
									</div>
								</div>								
							</div>

							<div class="tab-pane fade" id="import-calendar-events-tab-pane" role="tabpanel" aria-labelledby="import-calendar-events-tab" tabindex="0">
						  		<br>
						  		
						  		<!-- custom url -->
		                        <div>
		                        	<span>
		                            	<label for="custom-url">Custom URL</label>
		                            </span>
		                            <input type="text" name="custom-url" id="custom-url" class="custom-url" placeholder="A complete API URL">
		                        </div>

		                        <!-- base urls -->
		                        <div>
		                        	<span>
		                            	<label for="base-url">Base URL</label>
		                            </span>
		                            <select name="base-url" id="base-url" class="base-url">
		                            	<option selected disabled>-- Select a base URL --</option>
		                            	<option value="baseUrl1">Base URL #1</option>
		                            	<option value="baseUrl2">Base URL #2</option>
		                            	<option value="baseUrl3">Base URL #3</option>
		                            	<option value="baseUrl4">Base URL #4</option>
		                            	<option value="baseUrl5">Base URL #5</option>
		                            	<option value="null">No Base URL</option>
		                            </select>
		                        </div>

		                        <!-- api path -->
		                        <div>
		                        	<span>
		                            	<label for="api-path" title="Don't start with '/'">API Path</label>
		                            </span>
		                            <input type="text" name="api-path" id="api-path" class="api-path" placeholder="Remaining API path of the Base URL">
		                        </div>

		                        <!-- api method -->
		                        <div>
		                        	<span>
		                            	<label for="api-method">*API Method</label>
		                            </span>
		                            <select name="api-method" id="api-method" class="api-method">
		                            	<option value="GET" selected>GET</option>
		                            	<option value="POST">POST</option>
		                            </select>
		                        </div>

		                        <!-- api username -->
		                        <div>
		                        	<span>
		                            	<label for="api-username">API Username</label>
		                            </span>
		                            <input type="text" name="api-username" id="api-username" class="api-username" placeholder="Username for API authentication">
		                        </div>

		                        <!-- api password -->
		                        <div>
		                        	<span>
		                            	<label for="api-password">API Password</label>
		                            </span>
		                            <input type="text" name="api-password" id="api-password" class="api-password" placeholder="Password for API authentication">
		                        </div>

		                        <!-- api bearer token -->
		                        <div>
		                        	<span>
		                            	<label for="api-bearer-token" title="Note: Provide it without the word 'Bearer'">Bearer Token</label>
		                            </span>
		                            <input type="text" name="api-bearer-token" id="api-bearer-token" class="api-bearer-token" placeholder="Bearer token for Custom URL">
		                        </div>

		                        <!-- api more headers -->
		                        <div>
		                        	<span>
		                            	<label for="api-more-headers" title="Note: With/Without spaces after commas">More Headers</label>
		                            </span>
		                            <input type="text" name="api-more-headers" id="api-more-headers" class="api-more-headers" placeholder="k1:v1,k2:v2,k3:v3,...">
		                        </div>

		                        <!-- api body -->
		                        <div>
		                        	<span>
		                            	<label for="api-body" title="Note: API body content for a POST call">API Body</label>
		                            </span>
		                            <textarea id="api-body" name="api-body" class="api-body" rows="4" cols="50"></textarea>
		                        </div>

		                        <!-- nested array-of-objects keyname -->
		                        <div>
		                        	<span>
		                            	<label for="nested-array-of-objects-keyname" title="kename for nested array-of-objects in API response">Keyname for nested array-of-objects</label>
		                            </span>
		                            <input type="text" name="nested-array-of-objects-keyname" id="nested-array-of-objects-keyname" class="nested-array-of-objects-keyname" placeholder="k in { k : [{k1:v1}, {k2, v2}] }">
		                        </div>

		                        <!-- submit button -->
		                        <button class="import-calendar-events-btn">Save</button>
						  	</div>

						  	<!-- Do not need Add, Edit, Delete forms for calendar component -->
							<!-- <div class="tab-pane fade" id="delete-calendar-events-tab-pane" role="tabpanel" aria-labelledby="delete-calendar-events-tab" tabindex="0">
						  		<br>
						  		
						  		custom url
		                        <div>
		                        	<span>
		                            	<label for="delete-custom-url">Custom URL</label>
		                            </span>
		                            <input type="text" name="delete-custom-url" id="delete-custom-url" class="delete-custom-url" placeholder="A complete API URL">
		                        </div>

		                        base urls
		                        <div>
		                        	<span>
		                            	<label for="delete-base-url">Base URL</label>
		                            </span>
		                            <select name="delete-base-url" id="delete-base-url" class="delete-base-url">
		                            	<option selected disabled>-- Select a base URL --</option>
		                            	<option value="baseUrl1">Base URL #1</option>
		                            	<option value="baseUrl2">Base URL #2</option>
		                            	<option value="baseUrl3">Base URL #3</option>
		                            	<option value="baseUrl4">Base URL #4</option>
		                            	<option value="baseUrl5">Base URL #5</option>
		                            	<option value="null">No Base URL</option>
		                            </select>
		                        </div>

		                        api path
		                        <div>
		                        	<span>
		                            	<label for="delete-api-path" title="Don't start with '/'">API Path</label>
		                            </span>
		                            <input type="text" name="delete-api-path" id="delete-api-path" class="delete-api-path" placeholder="Remaining API path of the Base URL">
		                        </div>

		                        api method
		                        <div>
		                        	<span>
		                            	<label for="delete-api-method">*API Method</label>
		                            </span>
		                            <select name="delete-api-method" id="delete-api-method" class="delete-api-method">
		                            	<option selected disabled value="DELETE">DELETE</option>
		                            </select>
		                        </div>

		                        api username
		                        <div>
		                        	<span>
		                            	<label for="delete-api-username">API Username</label>
		                            </span>
		                            <input type="text" name="delete-api-username" id="delete-api-username" class="delete-api-username" placeholder="Username for API authentication">
		                        </div>

		                        api password
		                        <div>
		                        	<span>
		                            	<label for="delete-api-password">API Password</label>
		                            </span>
		                            <input type="text" name="delete-api-password" id="delete-api-password" class="delete-api-password" placeholder="Password for API authentication">
		                        </div>

		                        api bearer token
		                        <div>
		                        	<span>
		                            	<label for="delete-api-bearer-token" title="Note: Provide it without the word 'Bearer'">Bearer Token</label>
		                            </span>
		                            <input type="text" name="delete-api-bearer-token" id="delete-api-bearer-token" class="delete-api-bearer-token" placeholder="Bearer token for Custom URL">
		                        </div>

		                        api more headers
		                        <div>
		                        	<span>
		                            	<label for="delete-api-more-headers" title="Note: With/Without spaces after commas">More Headers</label>
		                            </span>
		                            <input type="text" name="delete-api-more-headers" id="delete-api-more-headers" class="delete-api-more-headers" placeholder="k1:v1,k2:v2,k3:v3,...">
		                        </div>

		                        submit button
		                        <button class="delete-calendar-events-btn">Save</button>
						  	</div>

							<div class="tab-pane fade" id="edit-calendar-events-tab-pane" role="tabpanel" aria-labelledby="edit-calendar-events-tab" tabindex="0">
						  		<br>
						  		
						  		custom url
		                        <div>
		                        	<span>
		                            	<label for="edit-custom-url">Custom URL</label>
		                            </span>
		                            <input type="text" name="edit-custom-url" id="edit-custom-url" class="edit-custom-url" placeholder="A complete API URL">
		                        </div>

		                        base urls
		                        <div>
		                        	<span>
		                            	<label for="edit-base-url">Base URL</label>
		                            </span>
		                            <select name="edit-base-url" id="edit-base-url" class="edit-base-url">
		                            	<option selected disabled>-- Select a base URL --</option>
		                            	<option value="baseUrl1">Base URL #1</option>
		                            	<option value="baseUrl2">Base URL #2</option>
		                            	<option value="baseUrl3">Base URL #3</option>
		                            	<option value="baseUrl4">Base URL #4</option>
		                            	<option value="baseUrl5">Base URL #5</option>
		                            	<option value="null">No Base URL</option>
		                            </select>
		                        </div>

		                        api path
		                        <div>
		                        	<span>
		                            	<label for="edit-api-path" title="Don't start with '/'">API Path</label>
		                            </span>
		                            <input type="text" name="edit-api-path" id="edit-api-path" class="edit-api-path" placeholder="Remaining API path of the Base URL">
		                        </div>

		                        api method
		                        <div>
		                        	<span>
		                            	<label for="edit-api-method">*API Method</label>
		                            </span>
		                            <select name="edit-api-method" id="edit-api-method" class="edit-api-method">
		                            	<option selected disabled value="PUT">PUT</option>
		                            </select>
		                        </div>

		                        api username
		                        <div>
		                        	<span>
		                            	<label for="edit-api-username">API Username</label>
		                            </span>
		                            <input type="text" name="edit-api-username" id="edit-api-username" class="edit-api-username" placeholder="Username for API authentication">
		                        </div>

		                        api password
		                        <div>
		                        	<span>
		                            	<label for="edit-api-password">API Password</label>
		                            </span>
		                            <input type="text" name="edit-api-password" id="edit-api-password" class="edit-api-password" placeholder="Password for API authentication">
		                        </div>

		                        api bearer token
		                        <div>
		                        	<span>
		                            	<label for="edit-api-bearer-token" title="Note: Provide it without the word 'Bearer'">Bearer Token</label>
		                            </span>
		                            <input type="text" name="edit-api-bearer-token" id="edit-api-bearer-token" class="edit-api-bearer-token" placeholder="Bearer token for Custom URL">
		                        </div>

		                        api more headers
		                        <div>
		                        	<span>
		                            	<label for="edit-api-more-headers" title="Note: With/Without spaces after commas">More Headers</label>
		                            </span>
		                            <input type="text" name="edit-api-more-headers" id="edit-api-more-headers" class="edit-api-more-headers" placeholder="k1:v1,k2:v2,k3:v3,...">
		                        </div>

		                        api body
		                        <div>
		                        	<span>
		                            	<label for="edit-api-body" title="Note: API body content for a PUT call">API Body</label>
		                            </span>
		                            <textarea id="edit-api-body" name="edit-api-body" class="edit-api-body" rows="4" cols="50"></textarea>
		                        </div>

		                        submit button
		                        <button class="edit-calendar-events-btn">Save</button>
						  	</div>

							<div class="tab-pane fade" id="add-calendar-events-tab-pane" role="tabpanel" aria-labelledby="add-calendar-events-tab" tabindex="0">
						  		<br>
						  		
						  		custom url
		                        <div>
		                        	<span>
		                            	<label for="add-custom-url">Custom URL</label>
		                            </span>
		                            <input type="text" name="add-custom-url" id="add-custom-url" class="add-custom-url" placeholder="A complete API URL">
		                        </div>

		                        base urls
		                        <div>
		                        	<span>
		                            	<label for="add-base-url">Base URL</label>
		                            </span>
		                            <select name="add-base-url" id="add-base-url" class="add-base-url">
		                            	<option selected disabled>-- Select a base URL --</option>
		                            	<option value="baseUrl1">Base URL #1</option>
		                            	<option value="baseUrl2">Base URL #2</option>
		                            	<option value="baseUrl3">Base URL #3</option>
		                            	<option value="baseUrl4">Base URL #4</option>
		                            	<option value="baseUrl5">Base URL #5</option>
		                            	<option value="null">No Base URL</option>
		                            </select>
		                        </div>

		                        api path
		                        <div>
		                        	<span>
		                            	<label for="add-api-path" title="Don't start with '/'">API Path</label>
		                            </span>
		                            <input type="text" name="add-api-path" id="add-api-path" class="add-api-path" placeholder="Remaining API path of the Base URL">
		                        </div>

		                        api method
		                        <div>
		                        	<span>
		                            	<label for="add-api-method">*API Method</label>
		                            </span>
		                            <select name="add-api-method" id="add-api-method" class="add-api-method">
		                            	<option selected disabled value="POST">POST</option>
		                            </select>
		                        </div>

		                        api username
		                        <div>
		                        	<span>
		                            	<label for="add-api-username">API Username</label>
		                            </span>
		                            <input type="text" name="add-api-username" id="add-api-username" class="add-api-username" placeholder="Username for API authentication">
		                        </div>

		                        api password
		                        <div>
		                        	<span>
		                            	<label for="add-api-password">API Password</label>
		                            </span>
		                            <input type="text" name="add-api-password" id="add-api-password" class="add-api-password" placeholder="Password for API authentication">
		                        </div>

		                        api bearer token
		                        <div>
		                        	<span>
		                            	<label for="add-api-bearer-token" title="Note: Provide it without the word 'Bearer'">Bearer Token</label>
		                            </span>
		                            <input type="text" name="add-api-bearer-token" id="add-api-bearer-token" class="add-api-bearer-token" placeholder="Bearer token for Custom URL">
		                        </div>

		                        api more headers
		                        <div>
		                        	<span>
		                            	<label for="add-api-more-headers" title="Note: With/Without spaces after commas">More Headers</label>
		                            </span>
		                            <input type="text" name="add-api-more-headers" id="add-api-more-headers" class="add-api-more-headers" placeholder="k1:v1,k2:v2,k3:v3,...">
		                        </div>

		                        api body
		                        <div>
		                        	<span>
		                            	<label for="add-api-body" title="Note: API body content for a PUT call">API Body</label>
		                            </span>
		                            <textarea id="add-api-body" name="add-api-body" class="add-api-body" rows="4" cols="50"></textarea>
		                        </div>

		                        submit button
		                        <button class="add-calendar-events-btn">Save</button>
						  	</div> -->
						</div>
					`;

					editor.Modal.open({
						title: "Custom Calendar Form",
						content: `${form.outerHTML}`,
						attributes: {
							thisModelRef: this.model,
						},
					});
				},

	    		onRender({ el, model }) {
	    			if (!window.top.location.href.includes("large_preview/")) {
	    				let customizeCalendarBtn = document.createElement("button");
						customizeCalendarBtn.setAttribute("class", "customize-calendar-btn");
						customizeCalendarBtn.style.marginLeft = '95%';
						customizeCalendarBtn.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pen" viewBox="0 0 16 16"><path d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001m-.644.766a.5.5 0 0 0-.707 0L1.95 11.756l-.764 3.057 3.057-.764L14.44 3.854a.5.5 0 0 0 0-.708l-1.585-1.585z"/></svg>';

						el.getElementsByClassName("custom-calendar-nav")[0].insertAdjacentElement("beforebegin", customizeCalendarBtn);
	    			}
	    		},
	    	},
	    });

		// listening to modal opening
		editor.on("modal", (props) => {
			// checking for custom-calendar-form
			if (document.getElementsByClassName("gjs-mdl-content")[0] !== undefined && document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("custom-calendar-form")[0] !== undefined) {
				// API trait modal inputs pointers
				const eventId = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("event-id")[0];
				const evenCalendarId = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("event-calendarId")[0];
				const eventTitle = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("event-title")[0];
				const eventBody = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("event-body")[0];
				const eventStart = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("event-start")[0];
				const eventEnd = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("event-end")[0];
				const eventLocation = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("event-location")[0];
				// const eventCategory = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("event-category")[0];
				const eventState = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("event-state")[0];
				const eventIsReadOnly = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("event-isReadOnly")[0];
				
				const eventColor = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("event-text-color")[0];
				const eventBackgroundColor = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("event-background-color")[0];
				const eventDragBackgroundColor = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("event-drag-background-color")[0];
				const eventBorderColor = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("event-left-border-color")[0];
				const eventBorderRadius = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("event-border-radius")[0];
				const eventFontSize = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("event-font-size")[0];
				const eventFontStyle = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("event-font-style")[0];
				
				const eventTitleDisplay = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("event-title-display")[0];
				const eventBodyDisplay = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("event-body-display")[0];
				const eventLocationDisplay = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("event-location-display")[0];
				const eventStateDisplay = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("event-state-display")[0];
				
				const eventBodyLabelText = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("event-body-label-text")[0];
				const eventLocationLabelText = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("event-location-label-text")[0];
				const eventStateLabelText = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("event-state-label-text")[0];
				
				const saveEventsAPIKeyMappingButton = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("save-events-api-key-mapping-btn")[0];

				const customUrl = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("custom-url")[0];
				const baseUrl = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("base-url")[0];
				const apiPath = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("api-path")[0];
				const apiMethod = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("api-method")[0];
				const apiUsername = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("api-username")[0];
				const apiPassword = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("api-password")[0];
				const apiBearerToken = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("api-bearer-token")[0];
				const apiMoreHeaders = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("api-more-headers")[0];
				const apiBody = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("api-body")[0];
				const nestedArrOfObjKeyname = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("nested-array-of-objects-keyname")[0];
				
				const importCalendarEventsButton = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("import-calendar-events-btn")[0];

				// const deleteCustomUrl = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("delete-custom-url")[0];
				// const deleteBaseUrl = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("delete-base-url")[0];
				// const deleteApiPath = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("delete-api-path")[0];
				// const deleteApiUsername = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("delete-api-username")[0];
				// const deleteApiPassword = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("delete-api-password")[0];
				// const deleteApiBearerToken = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("delete-api-bearer-token")[0];
				// const deleteApiMoreHeaders = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("delete-api-more-headers")[0];
				
				// const deleteCalendarEventsButton = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("delete-calendar-events-btn")[0];

				// const editCustomUrl = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("edit-custom-url")[0];
				// const editBaseUrl = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("edit-base-url")[0];
				// const editApiPath = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("edit-api-path")[0];
				// const editApiUsername = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("edit-api-username")[0];
				// const editApiPassword = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("edit-api-password")[0];
				// const editApiBearerToken = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("edit-api-bearer-token")[0];
				// const editApiMoreHeaders = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("edit-api-more-headers")[0];
				// // const editApiBody = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("edit-api-body")[0];
				
				// const editCalendarEventsButton = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("edit-calendar-events-btn")[0];

				// const addCustomUrl = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("add-custom-url")[0];
				// const addBaseUrl = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("add-base-url")[0];
				// const addApiPath = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("add-api-path")[0];
				// const addApiUsername = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("add-api-username")[0];
				// const addApiPassword = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("add-api-password")[0];
				// const addApiBearerToken = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("add-api-bearer-token")[0];
				// const addApiMoreHeaders = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("add-api-more-headers")[0];
				
				// const addCalendarEventsButton = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("add-calendar-events-btn")[0];

				try {
					// reattaching values in the modal's input fields for the user, so that they know what values they had entered previously
					eventId.value = props.attributes.thisModelRef.attributes.EventId;
					evenCalendarId.value = props.attributes.thisModelRef.attributes.EvenCalendarId;
					eventTitle.value = props.attributes.thisModelRef.attributes.EventTitle;
					eventBody.value = props.attributes.thisModelRef.attributes.EventBody;
					eventStart.value = props.attributes.thisModelRef.attributes.EventStart;
					eventEnd.value = props.attributes.thisModelRef.attributes.EventEnd;
					eventLocation.value = props.attributes.thisModelRef.attributes.EventLocation;
					// eventCategory.value = props.attributes.thisModelRef.attributes.EventCategory;
					eventState.value = props.attributes.thisModelRef.attributes.EventState;
					eventIsReadOnly.value = props.attributes.thisModelRef.attributes.EventIsReadOnly;

					eventColor.value = props.attributes.thisModelRef.attributes.EventTextColor;
					eventBackgroundColor.value = props.attributes.thisModelRef.attributes.EventBackgroundColor;
					eventDragBackgroundColor.value = props.attributes.thisModelRef.attributes.EventDragBackgroundColor;
					eventBorderColor.value = props.attributes.thisModelRef.attributes.EventBorderColor;
					eventBorderRadius.value = props.attributes.thisModelRef.attributes.EventBorderRadiusr;
					eventFontSize.value = props.attributes.thisModelRef.attributes.EventFontSize;
					eventFontStyle.value = props.attributes.thisModelRef.attributes.EventFontStyle;

					eventTitleDisplay.checked = props.attributes.thisModelRef.attributes.DisplayEventTitle;
					eventBodyDisplay.checked = props.attributes.thisModelRef.attributes.DisplayEventBody;
					eventLocationDisplay.checked = props.attributes.thisModelRef.attributes.DisplayEventLocation;
					eventStateDisplay.checked = props.attributes.thisModelRef.attributes.DisplayEventState;

					eventBodyLabelText.value = props.attributes.thisModelRef.attributes.BodyLabelText;
					eventLocationLabelText.value = props.attributes.thisModelRef.attributes.LocationLabelText;
					eventStateLabelText.value = props.attributes.thisModelRef.attributes.StateLabelText;

					customUrl.value = props.attributes.thisModelRef.attributes.customUrlValue;
					baseUrl.value = props.attributes.thisModelRef.attributes.baseUrlValue;
					apiPath.value = props.attributes.thisModelRef.attributes.apiPathValue;
					apiMethod.value = props.attributes.thisModelRef.attributes.apiMethodValue;
					apiUsername.value = props.attributes.thisModelRef.attributes.apiUsernameValue;
					apiPassword.value = props.attributes.thisModelRef.attributes.apiPasswordValue;
					apiBearerToken.value = props.attributes.thisModelRef.attributes.apiBearerTokenValue;
					apiMoreHeaders.value = props.attributes.thisModelRef.attributes.apiMoreHeadersValue;
					apiBody.value = props.attributes.thisModelRef.attributes.apiBodyValue;
					nestedArrOfObjKeyname.value = props.attributes.thisModelRef.attributes.nestedArrOfObjKeynameValue;
				
					// deleteCustomUrl.value = props.attributes.thisModelRef.attributes.deleteCustomUrlValue;
					// deleteBaseUrl.value = props.attributes.thisModelRef.attributes.deleteBaseUrlValue;
					// deleteApiPath.value = props.attributes.thisModelRef.attributes.deleteApiPathValue;
					// deleteApiUsername.value = props.attributes.thisModelRef.attributes.deleteApiUsernameValue;
					// deleteApiPassword.value = props.attributes.thisModelRef.attributes.deleteApiPasswordValue;
					// deleteApiBearerToken.value = props.attributes.thisModelRef.attributes.deleteApiBearerTokenValue;
					// deleteApiMoreHeaders.value = props.attributes.thisModelRef.attributes.deleteApiMoreHeadersValue;

					// editCustomUrl.value = props.attributes.thisModelRef.attributes.editCustomUrlValue;
					// editBaseUrl.value = props.attributes.thisModelRef.attributes.editBaseUrlValue;
					// editApiPath.value = props.attributes.thisModelRef.attributes.editApiPathValue;
					// editApiUsername.value = props.attributes.thisModelRef.attributes.editApiUsernameValue;
					// editApiPassword.value = props.attributes.thisModelRef.attributes.editApiPasswordValue;
					// editApiBearerToken.value = props.attributes.thisModelRef.attributes.editApiBearerTokenValue;
					// editApiMoreHeaders.value = props.attributes.thisModelRef.attributes.editApiMoreHeadersValue;
					// // editApiBody.value = props.attributes.thisModelRef.attributes.editApiBodyValue;

					// addCustomUrl.value = props.attributes.thisModelRef.attributes.addCustomUrlValue;
					// addBaseUrl.value = props.attributes.thisModelRef.attributes.addBaseUrlValue;
					// addApiPath.value = props.attributes.thisModelRef.attributes.addApiPathValue;
					// addApiUsername.value = props.attributes.thisModelRef.attributes.addApiUsernameValue;
					// addApiPassword.value = props.attributes.thisModelRef.attributes.addApiPasswordValue;
					// addApiBearerToken.value = props.attributes.thisModelRef.attributes.addApiBearerTokenValue;
					// addApiMoreHeaders.value = props.attributes.thisModelRef.attributes.addApiMoreHeadersValue;
				} catch(e) {
					// statements
					console.error(e);
				}

				try {
					// adding event listeners
					importCalendarEventsButton.addEventListener("click", (event) => {
						// don't run when none of them are provided
						if (customUrl.value || baseUrl.value || apiPath.value || apiMethod.value || apiUsername.value || apiPassword.value || apiBearerToken.value || apiMoreHeaders.value || apiBody.value || nestedArrOfObjKeyname.value) {
							// get data and update calendar
							gatherCalendarData(editor, props.attributes.thisModelRef, customUrl.value, baseUrl.value, apiPath.value, apiMethod.value, apiUsername.value, apiPassword.value, apiBearerToken.value, apiMoreHeaders.value, apiBody.value, nestedArrOfObjKeyname.value);
						}
					});
				} catch(e) {
					// statements
					console.log(e);
				}

				// try {
				// 	// adding event listeners
				// 	deleteCalendarEventsButton.addEventListener("click", (event) => {
				// 		// don't run when none of them are provided
				// 		if (deleteCustomUrl.value || deleteBaseUrl.value || deleteApiPath.value || deleteApiUsername.value || deleteApiPassword.value || deleteApiBearerToken.value || deleteApiMoreHeaders.value) {
				// 			// get data and update calendar
				// 			deleteCalendarData(editor, props.attributes.thisModelRef, deleteCustomUrl.value, deleteBaseUrl.value, deleteApiPath.value, deleteApiUsername.value, deleteApiPassword.value, deleteApiBearerToken.value, deleteApiMoreHeaders.value);
				// 		}
				// 	});
				// } catch(e) {
				// 	// statements
				// 	console.log(e);
				// }

				// try {
				// 	// adding event listeners
				// 	editCalendarEventsButton.addEventListener("click", (event) => {
				// 		// don't run when none of them are provided
				// 		if (editCustomUrl.value || editBaseUrl.value || editApiPath.value || editApiUsername.value || editApiPassword.value || editApiBearerToken.value || editApiMoreHeaders.value) {
				// 			// get data and update calendar
				// 			editCalendarData(editor, props.attributes.thisModelRef, editCustomUrl.value, editBaseUrl.value, editApiPath.value, editApiUsername.value, editApiPassword.value, editApiBearerToken.value, editApiMoreHeaders.value);
				// 		}
				// 	});
				// } catch(e) {
				// 	// statements
				// 	console.log(e);
				// }

				// try {
				// 	// adding event listeners
				// 	addCalendarEventsButton.addEventListener("click", (event) => {
				// 		// don't run when none of them are provided
				// 		if (addCustomUrl.value || addBaseUrl.value || addApiPath.value || addApiUsername.value || addApiPassword.value || addApiBearerToken.value || addApiMoreHeaders.value) {
				// 			// get data and update calendar
				// 			addCalendarData(editor, props.attributes.thisModelRef, addCustomUrl.value, addBaseUrl.value, addApiPath.value, addApiUsername.value, addApiPassword.value, addApiBearerToken.value, addApiMoreHeaders.value);
				// 		}
				// 	});
				// } catch(e) {
				// 	// statements
				// 	console.log(e);
				// }

				try {
					// adding event listeners
					saveEventsAPIKeyMappingButton.addEventListener("click", (event) => {
						// don't run when none of them are provided
						if (eventId.value || evenCalendarId.value || eventTitle.value || eventBody.value || eventStart.value || eventEnd.value || eventLocation.value || eventState.value || eventIsReadOnly.value || eventColor.value || eventBackgroundColor.value || eventDragBackgroundColor.value || eventBorderColor.value || eventBorderRadius.value || eventFontSize.value || eventFontStyle.value || eventTitleDisplay.checked || eventBodyDisplay.checked || eventLocationDisplay.checked || eventStateDisplay.checked || eventBodyLabelText.value || eventLocationLabelText.value || eventStateLabelText.value) {
							// get data and update calendar
							saveEventsAPIKeyMapping(editor, props.attributes.thisModelRef, eventId.value, evenCalendarId.value, eventTitle.value, eventBody.value, eventStart.value, eventEnd.value, eventLocation.value, eventState.value, eventIsReadOnly.value, eventColor.value, eventBackgroundColor.value, eventDragBackgroundColor.value, eventBorderColor.value, eventBorderRadius.value, eventFontSize.value, eventFontStyle.value, eventTitleDisplay.checked, eventBodyDisplay.checked, eventLocationDisplay.checked, eventStateDisplay.checked, eventBodyLabelText.value, eventLocationLabelText.value, eventStateLabelText.value);
						} else {
							toast.error("'Event Category Key' field is required.", notifyTheme2);
						}
					});
				} catch(e) {
					// statements
					console.log(e);
				}
			}
		});

		// function to save events api key mapping traits
		function saveEventsAPIKeyMapping(editorRef, thisModelRef, EventId, EvenCalendarId, EventTitle, EventBody, EventStart, EventEnd, EventLocation, EventState, EventIsReadOnly, EventTextColor, EventBackgroundColor, EventDragBackgroundColor, EventBorderColor, EventBorderRadiusr, EventFontSize, EventFontStyle, DisplayEventTitle, DisplayEventBody, DisplayEventLocation, DisplayEventState, BodyLabelText, LocationLabelText, StateLabelText) {
			try {
				thisModelRef.set({
		    		EventId,
		    		EvenCalendarId,
		    		EventTitle,
		    		EventBody,
		    		EventStart,
		    		EventEnd,
		    		EventLocation,
		    		// EventCategory,
		    		EventState,
		    		EventIsReadOnly,
		    		EventTextColor,
	    			EventBackgroundColor,
	    			EventDragBackgroundColor,
	    			EventBorderColor,
	    			EventBorderRadiusr,
	    			EventFontSize,
	    			EventFontStyle,
	    			DisplayEventTitle,
	    			DisplayEventBody,
	    			DisplayEventLocation,
	    			DisplayEventState,
	    			BodyLabelText,
	    			LocationLabelText,
	    			StateLabelText,
		    	});

		    	toast.info("The mapping has been saved. Please proceed.", successTheme);
		    	editorRef.Modal.close();
		    	console.log('saveEventsAPIKeyMapping thisModelRef', thisModelRef);
			} catch(e) {
				// statements
				console.error(e);
				toast.info("Error saving this mapping. Please check the provided values.", successTheme);
			}			
		}

	    // function to save apiData props to plot calendar with
	    async function gatherCalendarData(editorRef, thisModelRef, customUrlValue, baseUrlValue, apiPathValue, apiMethodValue, apiUsernameValue, apiPasswordValue, apiBearerTokenValue, apiMoreHeadersValue, apiBodyValue, nestedArrOfObjKeynameValue) {
	    	try {
		    	thisModelRef.set({
		    		customUrlValue,
		    		baseUrlValue,
		    		apiPathValue,
		    		apiMethodValue,
		    		apiUsernameValue,
		    		apiPasswordValue,
		    		apiBearerTokenValue,
		    		apiMoreHeadersValue,
		    		apiBodyValue,
		    		nestedArrOfObjKeynameValue,
		    	});

		    	toast.info("The API details have been saved. Please proceed.", successTheme);
		    	console.log('gatherCalendarData thisModelRef', thisModelRef);

		    	// editorRef.Modal.close();
	    	} catch(e) {
	    		// statements
	    		console.error(e);
				toast.info("Error saving API details. Please check the provided values.", successTheme);
	    	}
	    }

	    // // function to save delete-events apiData props to plot calendar with
	    // async function deleteCalendarData(editorRef, thisModelRef, deleteCustomUrlValue, deleteBaseUrlValue, deleteApiPathValue, deleteApiUsernameValue, deleteApiPasswordValue, deleteApiBearerTokenValue, deleteApiMoreHeadersValue) {
	    // 	try {
		//     	thisModelRef.set({
		//     		deleteCustomUrlValue, 
		//     		deleteBaseUrlValue, 
		//     		deleteApiPathValue, 
		//     		deleteApiUsernameValue, 
		//     		deleteApiPasswordValue, 
		//     		deleteApiBearerTokenValue, 
		//     		deleteApiMoreHeadersValue
		//     	});

		//     	toast.info("The Delete-API details have been saved. Please proceed.", successTheme);
		//     	console.log('deleteCalendarData thisModelRef', thisModelRef);

		//     	// editorRef.Modal.close();
	    // 	} catch(e) {
	    // 		// statements
	    // 		console.error(e);
		// 		toast.info("Error saving Delete-API details. Please check the provided values.", successTheme);
	    // 	}
	    // }

	    // // function to save delete-events apiData props to plot calendar with
	    // async function editCalendarData(editorRef, thisModelRef, editCustomUrlValue, editBaseUrlValue, editApiPathValue, editApiUsernameValue, editApiPasswordValue, editApiBearerTokenValue, editApiMoreHeadersValue, editApiBodyValue) {
	    // 	try {
		//     	thisModelRef.set({
		//     		editCustomUrlValue, 
		//     		editBaseUrlValue, 
		//     		editApiPathValue, 
		//     		editApiUsernameValue, 
		//     		editApiPasswordValue, 
		//     		editApiBearerTokenValue, 
		//     		editApiMoreHeadersValue,
		//     		// editApiBodyValue
		//     	});

		//     	toast.info("The Edit-API details have been saved. Please proceed.", successTheme);
		//     	console.log('editCalendarData thisModelRef', thisModelRef);

		//     	// editorRef.Modal.close();
	    // 	} catch(e) {
	    // 		// statements
	    // 		console.error(e);
		// 		toast.info("Error saving Edit-API details. Please check the provided values.", successTheme);
	    // 	}
	    // }

	    // // function to save delete-events apiData props to plot calendar with
	    // async function addCalendarData(editorRef, thisModelRef, addCustomUrlValue, addBaseUrlValue, addApiPathValue, addApiUsernameValue, addApiPasswordValue, addApiBearerTokenValue, addApiMoreHeadersValue, addApiBodyValue) {
	    // 	try {
		//     	thisModelRef.set({
		//     		addCustomUrlValue, 
		//     		addBaseUrlValue, 
		//     		addApiPathValue, 
		//     		addApiUsernameValue, 
		//     		addApiPasswordValue, 
		//     		addApiBearerTokenValue, 
		//     		addApiMoreHeadersValue,
		//     	});

		//     	toast.info("The Add-API details have been saved. Please proceed.", successTheme);
		//     	console.log('addCalendarData thisModelRef', thisModelRef);

		//     	// editorRef.Modal.close();
	    // 	} catch(e) {
	    // 		// statements
	    // 		console.error(e);
		// 		toast.info("Error saving Add-API details. Please check the provided values.", successTheme);
	    // 	}
	    // }
    }
};

export default CustomCalendarPlugin;