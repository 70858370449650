import React, { useEffect, useState, useRef } from "react";

import { useNavigate, useParams } from "react-router-dom";
import { Box, Paper, Button, TextField, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

import { API_HOST } from "../../api_utils";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";

function CreateNewConfigForPush() {
  let navigate = useNavigate();
  let { project } = useParams();
  const { userInfo } = useSelector((state) => state.userLogin);

  const [generateNewConfigPayload, setGenerateNewConfigPayload] = useState({
    project: project,
    title: "",
    body: "",
    url: "",
    icon: "",
    blb_endpoint: "",
  });

  const [iconLoading, setIconLoading] = useState(false);
  const [saveConfigLoading, setSaveConfigLoading] = useState(false);

  const generateNewConfigHandler = async () => {
    if (
      generateNewConfigPayload.title &&
      generateNewConfigPayload.icon &&
      generateNewConfigPayload.url &&
      generateNewConfigPayload.body
    ) {
      setSaveConfigLoading(true);
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      let projectRef = window.location.href.split("create_new_config/")[1];

      let newPayload = {
        project: projectRef,
        title: generateNewConfigPayload.title,
        url: generateNewConfigPayload.url,
        body: generateNewConfigPayload.body,
        icon: generateNewConfigPayload.icon,
      };

      const { data } = await axios.post(
        `${API_HOST}mobile_push_config/create_new_config`,
        newPayload,
        config
      );

      if (data.message && data.message === "Notification Config Failed !!!") {
        toast.error(data.message);
        setSaveConfigLoading(false);

        // navigate(`/dynamic_route/${newProject}`);
      } else {
        setSaveConfigLoading(false);
        toast.success("Configuration Saved Successfully!!!");
        navigate(`/mobile_notification/${project}`);
      }
    } else {
      toast.warn("Make sure to Provide All Fields !!!");
    }
  };

  const uploadIconHandler = async (files) => {
    setIconLoading(true);
    let file = files[0];
    let formData = new FormData();
    formData.append("file", file);
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${userInfo?.token}`,
      },
    };

    const { data } = await axios.post(
      `${API_HOST}uploadRoutes/upload`,
      formData,
      config
    );
    if (data) {
      console.log("data::::::", data);
      setIconLoading(false);
      toast.success("Icon Uploaded Successfully!!!");
      setGenerateNewConfigPayload((prevState) => ({
        ...prevState,
        icon: data.fileLocation,
      }));
    }
  };

  const triggerJsonUploadHandler = async (e) => {
    let files = e.target.files;
    let file = files[0];
    let formData = new FormData();
    formData.append("jsonFile", file);
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${userInfo?.token}`,
      },
    };

    const { data } = await axios.post(
      `${API_HOST}create_custom_route/upload_mob_push_json/${project}`,
      formData,
      config
    );
    if (data && data.message === "File uploaded successfully") {
      toast.success(data.message);
    }
  };

  const triggerJsonServiceUploadHandler = async (e) => {
    let files = e.target.files;
    let file = files[0];
    let formData = new FormData();
    formData.append("jsonFile", file);
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${userInfo?.token}`,
      },
    };

    const { data } = await axios.post(
      `${API_HOST}create_custom_route/upload_mob_push_service_json/${project}`,
      formData,
      config
    );
    if (data && data.message === "File uploaded successfully") {
      toast.success(data.message);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "87vh",
      }}>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}>
        <Paper
          elevation={2}
          sx={{
            width: "60%",
            borderRadius: "10px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            padding: "1.5rem",
          }}>
          <TextField
            onChange={(e) =>
              setGenerateNewConfigPayload((prevState) => ({
                ...prevState,
                title: e.target.value,
              }))
            }
            value={generateNewConfigPayload?.title}
            id="outlined-basic"
            label="Title for Notification"
            variant="outlined"
            sx={{ mb: "2rem", width: "100%" }}
          />

          <TextField
            onChange={(e) =>
              setGenerateNewConfigPayload((prevState) => ({
                ...prevState,
                body: e.target.value,
              }))
            }
            value={generateNewConfigPayload?.body}
            id="outlined-basic"
            label="Message For Notification"
            variant="outlined"
            sx={{ width: "100%", mb: "2rem" }}
          />

          <TextField
            onChange={(e) =>
              setGenerateNewConfigPayload((prevState) => ({
                ...prevState,
                url: e.target.value,
              }))
            }
            // disabled={isEditFlow ? true : false}
            value={generateNewConfigPayload?.url}
            id="outlined-basic"
            label="Url for Notification"
            variant="outlined"
            sx={{ width: "100%", mb: "2rem" }}
          />

          <TextField
            onChange={(e) =>
              setGenerateNewConfigPayload((prevState) => ({
                ...prevState,
                blb_route: e.target.value,
              }))
            }
            // disabled={isEditFlow ? true : false}
            value={generateNewConfigPayload?.blb_route}
            id="outlined-basic"
            label="BackendLB End-Point"
            variant="outlined"
            sx={{ width: "100%", mb: "2rem" }}
          />

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}>
            <div
              style={{
                display: "flex",
                justifyContent: "start",
                marginBottom: "2rem",
              }}>
              <label
                htmlFor="uploadIcon"
                style={{
                  padding: "0.5rem 1rem",
                  borderRadius: "27.5px",
                  color: "white",
                  backgroundColor: "#a855f7",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}>
                <span
                  style={
                    iconLoading ? { display: "none" } : { display: "block" }
                  }>
                  <DriveFolderUploadIcon sx={{ fontSize: "2rem" }} />
                </span>
                <span
                  style={
                    iconLoading
                      ? { display: "none" }
                      : {
                          fontSize: "1.2rem",
                          letterSpacing: "0.1rem",
                          marginTop: "0.3rem",
                          marginLeft: "1rem",
                          display: "block",
                        }
                  }>
                  Upload Icon
                </span>
                <span
                  style={
                    iconLoading
                      ? {
                          fontSize: "1.2rem",
                          letterSpacing: "0.1rem",
                          marginTop: "0.3rem",
                          marginLeft: "1rem",
                          display: "block",
                        }
                      : { display: "none" }
                  }>
                  Uploading Icon ...
                </span>
              </label>
              <input
                id="uploadIcon"
                type="file"
                style={{ display: "none" }}
                onChange={(e) => uploadIconHandler(e.target.files)}
              />
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "start",
                marginBottom: "2rem",
              }}>
              <label
                htmlFor="uploadService"
                style={{
                  padding: "0.5rem 1rem",
                  borderRadius: "27.5px",
                  color: "white",
                  backgroundColor: "#a855f7",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}>
                <span
                  style={
                    iconLoading ? { display: "none" } : { display: "block" }
                  }>
                  <DriveFolderUploadIcon sx={{ fontSize: "2rem" }} />
                </span>
                <span
                  style={
                    iconLoading
                      ? { display: "none" }
                      : {
                          fontSize: "1.2rem",
                          letterSpacing: "0.1rem",
                          marginTop: "0.3rem",
                          marginLeft: "1rem",
                          display: "block",
                        }
                  }>
                  Upload Google Services
                </span>
                <span
                  style={
                    iconLoading
                      ? {
                          fontSize: "1.2rem",
                          letterSpacing: "0.1rem",
                          marginTop: "0.3rem",
                          marginLeft: "1rem",
                          display: "block",
                        }
                      : { display: "none" }
                  }>
                  Uploading Google Services ...
                </span>
              </label>
              <input
                id="uploadService"
                type="file"
                style={{ display: "none" }}
                onChange={(e) => triggerJsonUploadHandler(e)}
              />
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "start",
                marginBottom: "2rem",
              }}>
              <label
                htmlFor="uploadGoogle"
                style={{
                  padding: "0.5rem 1rem",
                  borderRadius: "27.5px",
                  color: "white",
                  backgroundColor: "#a855f7",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}>
                <span
                  style={
                    iconLoading ? { display: "none" } : { display: "block" }
                  }>
                  <DriveFolderUploadIcon sx={{ fontSize: "2rem" }} />
                </span>
                <span
                  style={
                    iconLoading
                      ? { display: "none" }
                      : {
                          fontSize: "1.2rem",
                          letterSpacing: "0.1rem",
                          marginTop: "0.3rem",
                          marginLeft: "1rem",
                          display: "block",
                        }
                  }>
                  Upload Service Account
                </span>
                <span
                  style={
                    iconLoading
                      ? {
                          fontSize: "1.2rem",
                          letterSpacing: "0.1rem",
                          marginTop: "0.3rem",
                          marginLeft: "1rem",
                          display: "block",
                        }
                      : { display: "none" }
                  }>
                  Uploading Service Account ...
                </span>
              </label>
              <input
                id="uploadGoogle"
                type="file"
                style={{ display: "none" }}
                onChange={(e) => triggerJsonServiceUploadHandler(e)}
              />
            </div>
          </div>

          <Button
            variant="contained"
            onClick={() => generateNewConfigHandler()}
            sx={{
              width: "100%",
              pb: "0.8rem",
              pt: "0.8rem",
              fontSize: "1.1rem",
              letterSpacing: "0.2rem",
              backgroundColor: "#f43f5e",
              "&:hover": {
                backgroundColor: "#e11d48",
                transitionDuration: "0.5s",
              },
            }}>
            {saveConfigLoading
              ? "Saving Configuration ..."
              : "Save Configuration"}
          </Button>
        </Paper>
      </Box>

      <ToastContainer autoClose={2000} />
    </div>
  );
}

export default CreateNewConfigForPush;
