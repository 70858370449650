export const GET_REQUEST_LIST_LOADING = 'GET_REQUEST_LIST_LOADING'
export const GET_REQUEST_LIST_SUCCESS = 'GET_REQUEST_LIST_SUCCESS'
export const GET_REQUEST_LIST_FAILED = 'GET_REQUEST_LIST_FAILED'


export const APPROVE_REQUEST_LIST_LOADING = 'APPROVE_REQUEST_LIST_LOADING'
export const APPROVE_REQUEST_LIST_SUCCESS = 'APPROVE_REQUEST_LIST_SUCCESS'
export const APPROVE_REQUEST_LIST_FAILED = 'APPROVE_REQUEST_LIST_FAILED'


export const DELETE_REQUEST_LIST_LOADING = 'DELETE_REQUEST_LIST_LOADING'
export const DELETE_REQUEST_LIST_SUCCESS = 'DELETE_REQUEST_LIST_SUCCESS'
export const DELETE_REQUEST_LIST_FAILED = 'DELETE_REQUEST_LIST_FAILED'


export const GET_LIST_OF_USER_UNDER_COMPANY_LOADING = 'GET_LIST_OF_USER_UNDER_COMPANY_LOADING'
export const GET_LIST_OF_USER_UNDER_COMPANY_SUCCESS = 'GET_LIST_OF_USER_UNDER_COMPANY_SUCCESS'
export const GET_LIST_OF_USER_UNDER_COMPANY_FAILED = 'GET_LIST_OF_USER_UNDER_COMPANY_FAILED'


export const UPDATE_LIST_OF_USER_UNDER_COMPANY_LOADING = 'UPDATE_LIST_OF_USER_UNDER_COMPANY_LOADING'
export const UPDATE_LIST_OF_USER_UNDER_COMPANY_SUCCESS = 'UPDATE_LIST_OF_USER_UNDER_COMPANY_SUCCESS'
export const UPDATE_LIST_OF_USER_UNDER_COMPANY_FAILED = 'UPDATE_LIST_OF_USER_UNDER_COMPANY_FAILED'


export const DELETE_LIST_OF_USER_UNDER_COMPANY_LOADING = 'DELETE_LIST_OF_USER_UNDER_COMPANY_LOADING'
export const DELETE_LIST_OF_USER_UNDER_COMPANY_SUCCESS = 'DELETE_LIST_OF_USER_UNDER_COMPANY_SUCCESS'
export const DELETE_LIST_OF_USER_UNDER_COMPANY_FAILED = 'DELETE_LIST_OF_USER_UNDER_COMPANY_FAILED'

