const mainBody = document.createElement('div');

// for bootstrap icons in social media
const cdn = document.createElement('link');
cdn.setAttribute('rel', 'stylesheet');
cdn.setAttribute('href', 'https://cdn.jsdelivr.net/npm/bootstrap-icons@1.9.1/font/bootstrap-icons.css');
mainBody.appendChild(cdn);

const header = document.createElement('div');
header.setAttribute('class', 'header');
mainBody.appendChild(header);

header.innerHTML = `
	<div>
        <img src="/templates/images/redSlingLogo.png" />
      </div>
      <div class="header-right-section1">
        <p>Admin</p>
        <img src="/templates/images/Vector.png" />
    </div>
`;

const whiteWaveOverlay1 = document.createElement('div');
whiteWaveOverlay1.setAttribute('class', 'whiteWaveOverlay1');
mainBody.appendChild(whiteWaveOverlay1);

whiteWaveOverlay1.innerHTML = `
	<img src="/templates/images/Ellipse 6.png" />
    <img class="whiteWave2" src="/templates/images/white waves 2.png" />
`;

const checkoutDiv = document.createElement('div');
checkoutDiv.setAttribute('class', 'checkoutDiv');
mainBody.appendChild(checkoutDiv);

checkoutDiv.innerHTML = `
	<!-- dark bubble -->
    <img class="checkout-d-bb" src="/templates/images/darkBubble.png" />

      <!-- checkout bread -->
    <div class="checkout-bread">
        <div>
          <p class="circle1"></p>
          <p class="h-line"></p>
          <p class="circle2"></p>
          <p class="h-line"></p>
          <p class="circle2"></p>
        </div>
        <div>
          <p>Cart</p>
          <p>Details</p>
          <p>Payment</p>
        </div>
    </div>

    <div class="detail-Main">
        <p class="shippingAdd">Add Shipping Address</p>

        <!-- Name -->
        <div class="detailName">
          <p>
            <label for="name">Name</label>
          </p>
          <input id="name" type="text"  placeholder="Enter your full name "/>
        </div>

        <!-- Mobile Number -->
        <div class="detailMob">
          <p>
            <label for="mob">Mobile Number</label>
          </p>
          <input type="tel" id="mob" placeholder="Enter your mobile number"/>
        </div>

        <!-- PinCode -->

        <div class="detailMob">
          <p>
            <label for="pincode">Pincode</label>
          </p>
          <input type="tel" id="pincode" placeholder="Enter your pincode"/>
        </div>

        <!-- Address  -->

        <div class="detailAdd">
          <p>
            <label for="Add">Address</label>
          </p>
          <input type="text" id="Add" placeholder="Enter your address"/>
        </div>

        <div class="detailCityState">
          <!-- City -->
          <div class="detailCity">
            <p>
              <label for="city">City</label>
            </p>
            <input type="text" id="city" placeholder="Enter your city"/>
          </div>

          <!-- State -->
          <div class="detailState">
            <p>
              <label for="state">State</label>
            </p>
            <input type="text" id="state" placeholder="Enter your state"/>
          </div>
        </div>

        <p class="saveDetail">Save As</p>

        <div class="detailSaveDiv">
          <div class="detailSaveInner1">
            <p></p>
            <p>Home</p>
          </div>
          <div class="detailSaveInner">
            <p></p>
            <p>Office</p>
          </div>
          <div class="detailSaveInner">
            <p></p>
            <p>Other</p>
          </div>
        </div>

        <div class="detailFooter">
          <div>
            <p>
              <img src="/templates/images/rightTick.png"/>
            </p>
            <p>Billing address same as shipping address</p>
          </div>
          <button>Next</button>
        </div>
    </div>

    <style>
    @media (max-width: 768px) {
    	.header {
		    display: flex;
		    justify-content: space-between;
		    align-items: center;
		    position: relative;
		    padding: 2vh 2.5vw;
		}
		.header-right-section1 img {
		    width: 4vw;
		    height: 3vh;
		    margin-left: 5%;
		  }
		  .header-right-section1 p {
		    font-family: Mont-Regular;
		    font-size: 1rem;
		    margin-right: 5%;
		  }
		  .whiteWaveOverlay1 img:first-child {
		    width: 50vw;
		    position: absolute;
		    top: 0;
		    right: 0;
		  }
		  .whiteWave2 {
		    width: 90vw;
		    position: absolute;
		    top: 0;
		    right: 0;
		  }
		  .checkoutDiv {
		    padding-left: 4vw;
		    padding-right: 4vw;
		  }
		  .checkout-d-bb {
		    display: none;
		  }
		  .checkout-bread {
		    margin-top: 4vh;
		  }

		  .checkout-bread div:last-child {
		    font-size: 1.2rem;
		  }

		  .checkout-bread div:last-child p {
		    padding-left: 5vw;
		    text-align: end;
		  }

		  .circle1 {
		    width: 4vw;
		    height: 3vh;
		  }

		  .circle2 {
		    width: 4vw;
		    height: 2.5vh;
		    border: 4px solid rgba(237, 28, 36, 0.4);
		  }
		  .detail-Main {
		    padding-left: 4.6vw;
		    padding-bottom: 4vh;
		    margin-top: 12%;
		  }
		  .shippingAdd {
		    font-family: Mont-SemiBold;
		    font-size: 1.2rem;
		  }
		  .detailName {
		    margin-top: 4vh;
		  }

		  .detailName input {
		    margin-top: 1vh;
		    padding: 1.5vh 2vw;
		    width: 85%;
		  }
		  .detailMob input {
		    padding: 1.5vh 2vw;
		    width: 85%;
		  }
		  .detailAdd input {
		    padding: 1.5vh 2vw;
		    width: 85%;
		  }
		  .detailCityState {
		    display: flex;
		    flex-direction: column;
		    justify-content: space-between;
		    align-items: flex-start;
		    margin-top: 3vh;
		    height: 19vh;
		  }
		  .detailCity input {
		    padding: 1.5vh 2vw;
		    width: 74vw;
		    margin-right: 0;
		  }
		  .detailState input {
		    padding: 1.5vh 2vw;
		    width: 74vw;
		  }
		  .saveDetail {
		  	margin-top: 4vh;
		  }
		  .detailSaveDiv {
		    justify-content: space-between;
		    padding-right: 2vw;
		  }

		  .detailSaveInner p:first-child {
		    height: 2vh;
		    width: 4.5vw;
		    border: 1px solid black;
		    border-radius: 50% 50%;
		  }

		  .detailSaveInner p {
		    font-family: Mont-Regular;
		    font-size: 1rem;
		    margin-top: 1.5vh;
		  }

		  .detailSaveInner1 p {
		    font-family: Mont-Regular;
		    font-size: 1rem;
		    margin-top: 1.5vh;
		  }

		  .detailSaveInner1 p:first-child {
		    height: 2vh;
		    width: 4vw;
		    background-color: red;
		    border: 1px solid red;
		    border-radius: 50% 50%;
		  }

		  .detailFooter {
		    margin-top: 3vh;
		    display: flex;
		    flex-direction: column;
		    justify-content: space-between;
		    align-items: center;
		    height: 12vh;
		  }

		  .detailFooter div {
		    display: flex;
		    justify-content: space-between;
		    align-items: center;
		    font-family: Mont-Regular;
		    font-size: 1rem;
		    width: 100%;
		  }

		  .detailFooter div p:first-child {
		    border: 1px solid red;
		    padding: 0.8vh 2vw;
		    border-radius: 6px;
		    margin-right: 3vw;
		  }

		  .detailFooter button {
		    padding: 1.5vh 1.5vw;
		    width: 100%;
		  }
    }
    </style>
`;

const templateDetailsPage = mainBody.outerHTML;
export { templateDetailsPage };