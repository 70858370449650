import axios from 'axios'
import { USER_LOGIN_FAILED, USER_LOGOUT, USER_LOGIN_LOADING, USER_LOGIN_SUCCESS, USER_REGISTER_FAILED, USER_REGISTER_LOADING, USER_REGISTER_SUCCESS } from "../Constants/userConstants";
import { toast } from "react-toastify";
import { API_HOST } from "../../api_utils";
import { RESET_PAGE_BY_PROJECT_ID } from '../Constants/pageConstants';
import { RESET_ALL_PROJECT_BY_USER } from '../Constants/ProjectConstants';

const errorTheme = {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
}

export const loginUser = (userData) => async (dispatch) => {
    try {

        dispatch({ type: USER_LOGIN_LOADING })



        // const { data } = await axios.post(`${API_HOST}user/login`, userData)
        const { data } = await axios.post(`${API_HOST}user/login`, userData)




        dispatch({ type: USER_LOGIN_SUCCESS, payload: data })
        localStorage.setItem('userInfo', JSON.stringify(data))
        toast.success(`Welcome Back , ${data.name}`, errorTheme)
        setTimeout(() => { document.location.reload() }, 2000)


    } catch (error) {
        dispatch({ type: USER_LOGIN_FAILED, payload: 'Invalid Email or Password' })
        toast.error('Invalid Email or Password', errorTheme)
    }
}

export const registerUser = (userData) => async (dispatch) => {
    console.log('data', userData)
    try {
        dispatch({ type: USER_REGISTER_LOADING })

        const config = {
            headers: {
                "Content-Type": "application/json"
            }
        }


        const data = await axios.post(`${API_HOST}user`, userData, config)

        console.log("error1", data);
        if (data.data.message) {
            toast.error(`${data.data.message}`, errorTheme)
        } else {
            localStorage.setItem('userInfo', JSON.stringify(data.data))
            dispatch({ type: USER_REGISTER_SUCCESS, payload: data.data })
            dispatch({ type: USER_LOGIN_SUCCESS, payload: data.data })
            toast.success(`successFully logged-In as ${data.name}`, errorTheme)
        }

        // setTimeout(() => { document.location.reload() }, 2000)

    } catch (error) {
        console.log(error.response);
        dispatch({ type: USER_REGISTER_FAILED, payload: error.response.data })
        toast.error(error.response.data, errorTheme)
    }
}

export const logout = () => (dispatch) => {
    localStorage.removeItem('userInfo')
    dispatch({ type: RESET_PAGE_BY_PROJECT_ID })
    dispatch({ type: RESET_ALL_PROJECT_BY_USER })
    dispatch({ type: USER_LOGOUT })
}