import React, { useState } from 'react';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

const Box = ({ id, children }) => {
  const [{ isDragging }, drag] = useDrag({
    type: 'BOX',
    item: { id },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  console.log('isDragging:', isDragging);


  return (
    <div ref={drag} style={{ opacity: isDragging ? 0.5 : 1 }}>
      {children}
    </div>
  );
};

const Container = ({ id, children }) => {
  const [{ canDrop, isOver }, drop] = useDrop({
    accept: 'BOX',
    drop: () => ({ id }),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });
  console.log('canDrop:', canDrop);
  console.log('isOver:', isOver);
  return (
    <div
      ref={drop}
      style={{
        border: '1px solid black',
        padding: '10px',
        width: "100%",
        
        backgroundColor: canDrop ? 'lightgreen' : 'white',
        opacity: isOver ? 0.5 : 1,
      }}
    >
      {children}
    </div>
  );
};

const LogicBuilder2 = () => {
  const [boxes, setBoxes] = useState([]);

  const handleAddBox = () => {
    const newBox = { id: boxes.length };
    setBoxes([...boxes, newBox]);
  };
  console.log(boxes)
  return (
    <DndProvider backend={HTML5Backend}>
      <Container>
        {boxes.map((box) => (
          <Box key={box.id} id={box.id}>
            <Container>
              <button onClick={handleAddBox}>Add Box</button>
            </Container>
          </Box>
        ))}
      </Container>
    </DndProvider>
  );
};

export default LogicBuilder2;
