export const pdfPreviewButton = (editor, options) => {
  console.log('pdfPreviewButton disabled');
  // editor.Panels.addButton("editor-actions", {
  //   id: "pdf-preview",
  //   className: "fa fa-file-pdf-o",

  //   command: function (editor) {
  //     options.handlePreviewPdf(editor);
  //   },

  //   attributes: { title: "Preview PDF" },

  //   active: false,
  // });
};