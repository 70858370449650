let performingOperations = false;
let assignVariables2 = true;

export const getPerformingOperations = () => {
    // console.log("GET PERFORMING OPERATIONS ----", performingOperations)
    return performingOperations
}

export const setPerformingOperations2 = (val) => {
    // console.log("SET PERFORMING OPERATIONS --- ", val)
    performingOperations = val;
    return performingOperations
}

export const getAssignVariable = () => {
    // console.log("GETTTTTT")
    console.log(assignVariables2)
    return assignVariables2;
}

export const setAssignVariable = (val) => {
    // console.log("SETTTTTT-----")
    assignVariables2 = val;
    return assignVariables2;
}


// ITEMS IN function
let itemsInFunction = [];

export const setItemsInFunction = (arr) => {
    itemsInFunction = [...arr]
}

export const getItemsInFunction = () => {
    return itemsInFunction
}

