import {
    GET_ALL_CUSTOMTABLES_BY_USER_EMPTY,
    GET_ALL_CUSTOMTABLES_BY_USER_FAILED,
    GET_ALL_CUSTOMTABLES_BY_USER_LOADING,
    GET_ALL_CUSTOMTABLES_BY_USER_SUCCESS,

    GET_ALL_CUSTOMTABLES_OF_PROJECT,
    GET_ALL_CUSTOMTABLES_OF_PROJECT_SUCCESS,
    // GET_ALL_CUSTOMTABLES_BY_USER_FAILED,
    GET_ALL_CUSTOMTABLES_OF_PROJECT_FAILED
} from "../Constants/customTablesConstants";


export const getAllCustomTablesReducer = (state = { emptyTables: '', customTables: [] }, action) => {
    switch (action.type) {
        case GET_ALL_CUSTOMTABLES_BY_USER_LOADING:
            return { loading: true }
        case GET_ALL_CUSTOMTABLES_BY_USER_SUCCESS:
            return { loading: false, customTables: action.payload }
        case GET_ALL_CUSTOMTABLES_BY_USER_FAILED:
            return { loading: false, error: action.payload }
        case GET_ALL_CUSTOMTABLES_BY_USER_EMPTY:
            return { loading: false, emptyTables: true }

        case GET_ALL_CUSTOMTABLES_OF_PROJECT_FAILED:
            return { loading: false, emptyTables: true, customTables: [] }


        case GET_ALL_CUSTOMTABLES_OF_PROJECT_SUCCESS:
            return { loading: false, emptyTables: false, customTables: [...action.payload], error: "" }



        default:
            return state
    }
}