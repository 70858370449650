import React, { useEffect, useState } from 'react'
import { useDrop } from 'react-dnd'
import  MakeDroppable  from './FunctionDroppable'
import { useDispatch, connect } from 'react-redux'
import { assignFunctionName } from '../../../Redux/Actions/createFunctionAction';

function Canvas(props) {
    let dispatch = useDispatch();
    const [droppedItems, setDrop] = useState([])
    const [funcDefined, defineFunction] = useState()
    const [funcName, defineFuncName] = useState("")
    
    const [{ isOver, canDrop }, drop] = useDrop(() => ({
        // accept: ["ALL", "conditional"],
        accept: ["ALL"],
        drop: (item) => addToCanvas(item),
        // canDrop : (item) => {

        //     // console.log(item)
        //     // console.log(droppedItems)
        //     if( item.type==="conditional" && checkItemsInCanvas()) {
        //     // if(item.type=== "conditional") {
        //         return false
        //     } else {
        //         return true
        //     }
        // }, 
        collect: (monitor) => ({
            isOver: !!monitor.isOver(),
            canDrop: !!monitor.canDrop()
        })
    }))

    const checkItemsInCanvas =() => {
        console.log(droppedItems)
        if(droppedItems[0].type !== "conditional") {
            return false
        } else {
            return true
        }
    }

    const addToCanvas = (item) => {
        console.log(item)
        // item.isFunction ? defineFunction(true) : defineFunction(false)
        // item.isFunction ? canDrop (false) : canDrop(true)
        setDrop(prevState => ([...prevState, item]))
    }
    
    const defineFinalFunction = (params, mathFunc) => {
        console.log(funcName)
        // console.log(params)
        const obj = {
            name: funcName,
            execute: [
                {
                    id: mathFunc,
                    parameters: [
                        ...params
                    ]
                }
            ]

        }

        console.log(obj)
        // defineFunction(obj);
        // props.saveBtn()
        props.receiveFinalFuncDef(obj);
    }

    const defineFinalFunction2 = (mathFunc) => {
        console.log(mathFunc)
        console.log(droppedItems)
    }

    const isActive = canDrop && isOver

    let backgroundColor = '#222'

    if(isActive) {
        backgroundColor = 'darkgreen'
    } else if(canDrop) {
        backgroundColor = 'darkkhaki'
    }

    console.log(droppedItems)

    const saveFunctionName = () => {
        console.log("save clicked")
        console.log(funcName)
        let project_id = props.selected_project.selected_project._id
        dispatch(assignFunctionName(project_id, funcName))
    }


    useEffect(() => {
        if(!props.createFunc.funcName && props.createFunc.droppedItems.length === 0 && props.createFunc.execute.length === 0) {
            console.log(droppedItems)
            setDrop([])
        }
    }, [props.createFunc])

    return (
        <div ref={drop } style={{ height: "100%", width: "100%", isActive }}>
            { droppedItems.length > 0 ? droppedItems.map(dropItem => (
                <div
                key={dropItem.id ? dropItem.id : dropItem._id}
                style={{ 
                    display: "flex", 
                    width: "50%", 
                    justifyContent: "center", 
                    alignItems: "center", 
                    minHeight: "600px", 
                    maxHeight: "1000px",
                    borderRadius: "4px", 
                    backgroundColor: "#C7D7FF",
                    marginTop: "10px", 
                    }}
                >{dropItem.name} 
                {dropItem.id === 1 || dropItem.isMathFunction ? 
                (<MakeDroppable saveBtn={props.saveBtn} /> ) : ""} 
                {dropItem.isFunction ? 
                    ( 
                    <div>
                        <div style={{display: "flex"}}>
                            <input type="text" style={{ marginLeft: "5px"}} value={funcName}
                                onChange={(e) => defineFuncName(e.target.value)} /> 
                                <button onClick={saveFunctionName}>Save</button>
                         </div>
                      {/*  Passing function from here */}
                        <MakeDroppable height="50px" defineFinalFunction={defineFinalFunction2} /> 
                    </div>  ) 
                    : ""}
                </div>
            )) : ""}

            

        </div>
    )
}

const mapStateToProps = state => {
    console.log(state)
    return {
        createFunc: state.createNewFunc,
        selected_project: state.selectedProject
    }
}

export default connect(mapStateToProps, null)(Canvas)