import {
  Box,
  Grid,
  Paper,
  Typography,
  Modal,
  TextField,
  Button,
  OutlinedInput,
  InputLabel,
  MenuItem,
  FormControl,
  ListItemText,
  Checkbox,
  Select,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableContainer,
  TableBody,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import darkBubble from "./component_assets/images/darkBubble.png";
// import darkBubble from './component_assets/images/details_close.png'
// import lightBubble from './component_assets/images/details_close.png'
import lightBubble from "./component_assets/images/lightBubble.png";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import axios from "axios";
import { API_HOST } from "../api_utils";
import CloseIcon from "@mui/icons-material/Close";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function CronJobDetail() {
  const [cronJobData, setCronJobData] = useState([]);
  const [userList, setUserList] = useState([]);
  const [userUnderProject, setUserUnderProject] = useState([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [personName, setPersonName] = useState([]);
  const [functionName, setFunctionName] = useState([]);
  const [eventName, setEventName] = useState([]);
  const [userSuccess, setUserSuccess] = useState(false);
  const [functionList, setFunctionList] = useState([]);
  const [eventList, setEventList] = useState([]);
  const [functionModal, setFunctionModal] = useState(false);
  const [eventModal, setEventModal] = useState(false);
  const [functionSuccess, setFunctionSuccess] = useState(false);
  const [eventSuccess, setEventSuccess] = useState(false);
  const [editCron, setEditCron] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [cronJobName, setCronJobName] = useState("");
  const [editSuccess, setEditSuccess] = useState(false);
  const [rows, setRows] = useState([]);
  const [selectedFunction, setSelectedFunction] = useState("");
  const [selectedEvent, setSelectedEvent] = useState("");

  const errorTheme = {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
  };

  let navigate = useNavigate();
  let location = useLocation();

  const project_id = location.search ? location.search.split("=")[1] : "/";

  const { id } = useParams();

  const getAllUsers = async () => {
    setLoading(true);
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await axios.get(`${API_HOST}user/`, config);

    if (data.message) {
      setLoading(false);
      toast.error(data.message, errorTheme);
    } else {
      setLoading(false);
      setUserList(data);
    }
  };

  const getCronJobDetail = async () => {
    setLoading(true);
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await axios.get(`${API_HOST}cron_job/${id}`, config);

    if (data.message) {
      setLoading(false);
      toast.error(data.message, errorTheme);
    } else {
      setLoading(false);
      setCronJobData(data);
      var startDateTemp = new Date(data.start_date);
      startDateTemp.setMinutes(
        startDateTemp.getMinutes() - startDateTemp.getTimezoneOffset()
      );
      var sdTemp = startDateTemp.toISOString().slice(0, 16);
      setStartDate(sdTemp);
      var endDateTemp = new Date(data.end_date);
      endDateTemp.setMinutes(
        endDateTemp.getMinutes() - endDateTemp.getTimezoneOffset()
      );
      var edTemp = endDateTemp.toISOString().slice(0, 16);
      setEndDate(edTemp);
      setCronJobName(data.name);
    }
  };

  const getListOfUsersUnderProject = async () => {
    setLoading(true);
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await axios.get(
      `${API_HOST}cron_job/projectUser/${id}`,
      config
    );

    if (data.message) {
      setLoading(false);
      toast.error(data.message, errorTheme);
    } else {
      setLoading(false);
      setUserUnderProject(data);
    }
  };

  const getCustomFunctions = async () => {
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    let payload = {
      project: project_id,
    };
    const { data } = await axios.post(
      `${API_HOST}custom_functions/`,
      payload,
      config
    );
    if (data.message) {
      toast.error(data.message);
    } else {
      setFunctionList(data);
      // cronJobData.function_to_be_executed.forEach(function (item1) {
      // const funcName = data.find(item => item._id === item1._id);
      // item1.name = funcName.name;
      // });
    }
  };

  const getCustomEvents = async () => {
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    let payload = {
      projectId: project_id,
    };
    const { data } = await axios.post(`${API_HOST}temp_vars/`, payload, config);
    if (data.message) {
      toast.error(data.message);
    } else {
      setEventList(data);
    }
  };

  useEffect(() => {
    getCronJobDetail();
    getAllUsers();
    getListOfUsersUnderProject();
    getCustomFunctions();
    getCustomEvents();
  }, [userSuccess, functionSuccess, eventSuccess, deleteSuccess, editSuccess]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const addFunctionHandler = async () => {
    if (selectedFunction.length) {
      setLoading(true);
      const userInfo = JSON.parse(localStorage.getItem("userInfo"));
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const payload = {
        function_to_be_executed: selectedFunction,
        //project: id
      };
      const { data } = await axios.put(
        `${API_HOST}cron_job/${id}`,
        payload,
        config
      );

      if (data.message) {
        setLoading(false);
        toast.error(data.message, errorTheme);
      } else {
        setLoading(false);
        toast.success("Function Added Successfully !!!!", errorTheme);
        setFunctionModal(false);
        setFunctionName([]);
        setFunctionSuccess(true);
      }
    }
  };

  const addEventHandler = async () => {
    if (selectedEvent.length) {
      setLoading(true);
      const userInfo = JSON.parse(localStorage.getItem("userInfo"));
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const payload = {
        event_to_be_executed: selectedEvent,
        //project: id
      };
      const { data } = await axios.put(
        `${API_HOST}cron_job/${id}`,
        payload,
        config
      );

      if (data.message) {
        setLoading(false);
        toast.error(data.message, errorTheme);
      } else {
        setLoading(false);
        toast.success("Event Added Successfully!!!!", errorTheme);
        setEventModal(false);
        setEventName([]);
        setEventSuccess(true);
      }
    }
  };

  const deleteCronJobHandler = async () => {
    setLoading(true);
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const payload = {
      user: personName,
    };
    const { data } = await axios.delete(`${API_HOST}cron_job/${id}`, config);

    if (data.message) {
      setLoading(false);
      toast.error(data.message, errorTheme);
    } else {
      setLoading(false);
      toast.success("Users Added Successfully !!!!", errorTheme);
      setDeleteModal(false);
      setDeleteSuccess(true);
    }
  };

  const updateCronJobHandler = async () => {
    if (cronJobName && startDate && endDate) {
      setLoading(true);
      const userInfo = JSON.parse(localStorage.getItem("userInfo"));
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const payload = {
        name: cronJobName,
        start_date: startDate,
        end_date: endDate,
      };
      const { data } = await axios.put(
        `${API_HOST}cron_job/${id}`,
        payload,
        config
      );

      if (data.message) {
        setLoading(false);
        toast.error(data.message, errorTheme);
      } else {
        setLoading(false);
        toast.success("Cron Job Data Updated Successfully !!!!", errorTheme);
        setEditSuccess(true);
        setEditCron(false);
      }
    } else {
      toast.warn("editable fields cannot be empty!!!", errorTheme);
    }
  };

  function createData(id, name) {
    return { id, name, access: "" };
  }

  useEffect(() => {
    if (userUnderProject.length) {
      const userInfo = JSON.parse(localStorage.getItem("userInfo"));
      const filteredUser = userUnderProject.filter(
        (user) => user.user !== userInfo._id
      );
      console.log("filteredUser", filteredUser);
      const userNameFilter = userList.map((x) => {
        let userCheck = filteredUser.find((newUser) => newUser.user === x._id);
        console.log("userCheck", userCheck);
        if (userCheck) {
          return createData(x._id, x.name);
        }
      });

      const newRowData = userNameFilter.filter((x) => typeof x !== "undefined");

      console.log("newRowData", newRowData);
      setRows(newRowData);
    }
  }, [userUnderProject, userList]);

  const handleAccessChange = (event, row) => {
    const { value } = event.target;
    setRows((prevRows) =>
      prevRows.map((prevRow) =>
        prevRow.id === row.id ? { ...prevRow, access: value } : prevRow
      )
    );
  };

  const addUserHandler = async (rowId) => {
    setLoading(true);
    setUserSuccess(false);
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    console.log("rows", rows);
    const selectedRows = rows.filter(
      (row) => row.access && row.access !== "none" && row.id
    );

    console.log("selectedRows", selectedRows);

    if (selectedRows.length > 0) {
      const filteredSelectedRows = selectedRows.find((row) => row.id === rowId);
      if (filteredSelectedRows) {
        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userInfo.token}`,
          },
        };

        const payload = {
          user: {
            user: filteredSelectedRows.id,
            access: filteredSelectedRows.access,
          },
        };

        try {
          const { data } = await axios.post(
            `${API_HOST}cron_job/${id}`,
            payload,
            config
          );

          if (data.message) {
            setLoading(false);
            toast.error(data.message, errorTheme);
          } else {
            setLoading(false);
            setOpen(false);
            setUserSuccess(true);
            toast.success(
              "User Added To The Cron Job Successfully !!!",
              errorTheme
            );
          }
        } catch (error) {
          setLoading(false);
          toast.error(
            "An error occurred while adding the users to the Cron Job.",
            errorTheme
          );
        }
      } else {
        toast.warn(
          "Please Assign User Roles for the selected user !!!",
          errorTheme
        );
      }
    } else {
      toast.warn("Please Assign User Roles for the Project !!!", errorTheme);
    }
  };

  return (
    <div>
      <Grid
        sx={{ position: "relative", overflow: "hidden", minHeight: "100vh" }}>
        <Grid
          sx={{
            position: "absolute",
            top: "-12vh",
            left: "-30vw",
            zIndex: "-10",
          }}>
          <img style={{ width: "55vw", height: "90%" }} src={darkBubble} />
        </Grid>

        <Grid
          sx={{
            position: "absolute",
            bottom: "-60%",
            right: "-15%",
            zIndex: "-10",
          }}>
          <img style={{ width: "55vw", height: "90%" }} src={lightBubble} />
        </Grid>

        <Grid
          sx={{
            display: "flex",
            justifyContent: "center",
            paddingTop: "10vh",
          }}>
          <Grid
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "0 3.5rem",
            }}
            container>
            <Typography
              sx={{ fontFamily: "Mont-SemiBold", fontSize: "1.8rem" }}>
              Cron Job Detail
            </Typography>
            <Grid
              xs={8.5}
              sx={{ height: "1.5vh", backgroundColor: "red" }}
              item></Grid>
          </Grid>
        </Grid>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "start",
            alignItems: "flex-start",
            padding: "1rem",
          }}>
          <Grid
            sx={{
              width: "80%",
              height: "70vh",
              overflowY: "scroll",
              borderRadius: "8px",
              boxShadow: 20,
            }}>
            <Grid
              elevation={3}
              sx={{
                height: "100%",
                backdropFilter: "blur(25px)",
                padding: "0.5rem 1rem",
              }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                }}>
                <Typography
                  sx={{
                    fontSize: "1.1rem",
                    fontWeight: 600,
                    color: "#262626",
                    width: "30%",
                  }}>
                  Cron Job Id
                </Typography>
                <input
                  type="text"
                  value={cronJobData ? cronJobData._id : "-----"}
                  style={{
                    width: "70%",
                    padding: "0.5rem",
                    borderRadius: "8px",
                    color: "#262626",
                    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
                  }}
                  disabled
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                  mt: 2,
                }}>
                <Typography
                  sx={{
                    fontSize: "1.1rem",
                    fontWeight: 600,
                    color: "black",
                    width: "30%",
                  }}>
                  Cron Job Name
                </Typography>
                <input
                  type="text"
                  onChange={(e) => setCronJobName(e.target.value)}
                  value={cronJobName}
                  style={{
                    width: "70%",
                    padding: "0.5rem",
                    borderRadius: "8px",
                    color: "black",
                    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
                    outline: "none",
                  }}
                  disabled={!editCron ? true : false}
                  className={editCron ? "borderInput" : ""}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                  mt: 2,
                }}>
                <Typography
                  sx={{
                    fontSize: "1.1rem",
                    fontWeight: 600,
                    color: "black",
                    width: "30%",
                  }}>
                  Start Date
                </Typography>
                <input
                  type="datetime-local"
                  onChange={(e) => setStartDate(e.target.value)}
                  value={startDate ? startDate : ""}
                  style={{
                    width: "70%",
                    padding: "0.5rem",
                    borderRadius: "8px",
                    color: "black",
                    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
                    outline: "none",
                  }}
                  className={editCron ? "borderInput" : ""}
                  disabled={!editCron ? true : false}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                  mt: 2,
                }}>
                {/* .split('T')[0] */}
                <Typography
                  sx={{
                    fontSize: "1.1rem",
                    fontWeight: 600,
                    color: "black",
                    width: "30%",
                  }}>
                  End Date
                </Typography>
                <input
                  type="datetime-local"
                  onChange={(e) => setEndDate(e.target.value)}
                  value={endDate ? endDate : ""}
                  style={{
                    width: "70%",
                    padding: "0.5rem",
                    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
                    borderRadius: "8px",
                    color: "black",
                    outline: "none",
                  }}
                  className={editCron ? "borderInput" : ""}
                  disabled={!editCron ? true : false}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                  mt: 2,
                  backdropFilter: "blur(25px)",
                }}>
                <Typography
                  sx={{
                    fontSize: "1.1rem",
                    fontWeight: 600,
                    color: "black",
                    width: "30%",
                  }}>
                  Users Active
                </Typography>
                <div
                  style={{
                    width: "70%",
                    padding: "0.5rem",
                    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
                    borderRadius: "8px",
                    color: "black",
                  }}>
                  {userList.length && cronJobData ? (
                    <table style={{ width: "100%" }}>
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Access</th>
                          <th>Role</th>
                        </tr>
                      </thead>
                      <tbody>
                        {cronJobData.users &&
                          cronJobData.users.length &&
                          cronJobData.users.map((user) => {
                            let userCheck = userList.find(
                              (x) => x._id.toString() === user.user.toString()
                            );
                            if (userCheck) {
                              return (
                                <tr key={userCheck._id}>
                                  <td>{userCheck.name}</td>
                                  <td>{user.access}</td>
                                  <td>{user.role}</td>
                                </tr>
                              );
                            }
                            return null;
                          })}
                      </tbody>
                    </table>
                  ) : (
                    <p>No data available</p>
                  )}
                </div>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                  mt: 2,
                  backdropFilter: "blur(25px)",
                }}>
                <Typography
                  sx={{
                    fontSize: "1.1rem",
                    fontWeight: 600,
                    color: "black",
                    width: "30%",
                  }}>
                  Functions Added
                </Typography>
                <div
                  style={{
                    width: "70%",
                    padding: "0.5rem",
                    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
                    borderRadius: "8px",
                    color: "black",
                  }}>
                  {cronJobData.function_to_be_executed ? (
                    <table>
                      <thead>
                        <tr>
                          <th>Function Name</th>
                        </tr>
                      </thead>
                      <tbody>
                        {cronJobData.function_to_be_executed.map((func) => (
                          //alert(func._id)
                          <tr key={func._id}>
                            <td>{func.function.name}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <p>No Functions Available !!!</p>
                  )}
                </div>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                  mt: 2,
                  backdropFilter: "blur(25px)",
                }}>
                <Typography
                  sx={{
                    fontSize: "1.1rem",
                    fontWeight: 600,
                    color: "black",
                    width: "30%",
                  }}>
                  Events Added
                </Typography>
                <div
                  style={{
                    width: "70%",
                    padding: "0.5rem",
                    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
                    borderRadius: "8px",
                    color: "black",
                  }}>
                  {cronJobData.event_to_be_executed ? (
                    <table>
                      <thead>
                        <tr>
                          <th>Event Name</th>
                        </tr>
                      </thead>
                      <tbody>
                        {cronJobData.event_to_be_executed.map((eve) => (
                          //alert(func._id)
                          <tr key={eve._id}>
                            <td>{eve.event.name}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <p>No Events Available !!!</p>
                  )}
                </div>
              </Box>

              {editCron ? (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    mt: 2,
                  }}>
                  <Button
                    onClick={updateCronJobHandler}
                    variant="contained"
                    sx={{
                      backgroundColor: "red",
                      "&:hover": { color: "red", backgroundColor: "white" },
                    }}>
                    Save Changes
                  </Button>
                </Box>
              ) : (
                ""
              )}
            </Grid>
          </Grid>
          <Grid
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              marginLeft: "0.5rem",
            }}>
            <Typography
              onClick={() => setOpen(true)}
              sx={{
                width: "15rem",
                height: "4rem",
                boxShadow: 10,
                borderRadius: "10px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: "1.1rem",
                fontWeight: 600,
                backdropFilter: "blur(25px)",
                "&:hover": {
                  transitionDuration: "0.8sec",
                  color: "white",
                  backgroundColor: "red",
                },
                cursor: "pointer",
              }}>
              Users
            </Typography>
            <Typography
              onClick={() => setFunctionModal(true)}
              sx={{
                mt: 2,
                width: "15rem",
                height: "4rem",
                boxShadow: 10,
                borderRadius: "10px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: "1.1rem",
                fontWeight: 600,
                backdropFilter: "blur(25px)",
                "&:hover": {
                  transitionDuration: "0.8sec",
                  color: "white",
                  backgroundColor: "red",
                },
                cursor: "pointer",
              }}>
              Functions
            </Typography>
            <Typography
              onClick={() => setEventModal(true)}
              sx={{
                mt: 2,
                width: "15rem",
                height: "4rem",
                boxShadow: 10,
                borderRadius: "10px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: "1.1rem",
                fontWeight: 600,
                backdropFilter: "blur(25px)",
                "&:hover": {
                  transitionDuration: "0.8sec",
                  color: "white",
                  backgroundColor: "red",
                },
                cursor: "pointer",
              }}>
              Events
            </Typography>
            <Typography
              onClick={() => setEditCron(!editCron)}
              sx={{
                mt: 2,
                width: "15rem",
                height: "4rem",
                boxShadow: 10,
                borderRadius: "10px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: "1.1rem",
                fontWeight: 600,
                backdropFilter: "blur(25px)",
                "&:hover": {
                  transitionDuration: "0.8sec",
                  color: "white",
                  backgroundColor: "red",
                },
                cursor: "pointer",
              }}>
              Edit Cron Job
            </Typography>
            <Typography
              onClick={() => setDeleteModal(true)}
              sx={{
                mt: 2,
                width: "15rem",
                height: "4rem",
                boxShadow: 10,
                borderRadius: "10px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: "1.1rem",
                fontWeight: 600,
                backdropFilter: "blur(25px)",
                "&:hover": {
                  transitionDuration: "0.8sec",
                  color: "white",
                  backgroundColor: "red",
                },
                cursor: "pointer",
              }}>
              Delete Cron Job
            </Typography>
          </Grid>
        </Box>
      </Grid>

      <Modal
        open={open}
        onClose={() => setOpen(false)}
        sx={{
          border: "none",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}>
        <Box
          sx={{
            width: { lg: "80vw" },
            padding: "2vh 2vw 6vh 2vw",
            outline: "none",
            borderRadius: "6px",
            backgroundColor: "white",
            position: "relative",
          }}>
          <Grid sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              onClick={() => setOpen(false)}
              sx={{ boxShadow: 2, color: "red", "&:hover": { boxShadow: 5 } }}>
              <CloseIcon />
            </Button>
          </Grid>
          <Grid
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mt: 2,
            }}>
            <Typography
              sx={{ fontFamily: "Mont-SemiBold", fontSize: "1.3rem" }}>
              Add Users
            </Typography>
            <Grid
              item
              xs={10.2}
              sx={{ backgroundColor: "red", height: "1vh" }}></Grid>
          </Grid>
          <Grid
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              zIndex: "20",
              padding: "0 3.5rem 5rem 3.5rem",
              mt: 4,
            }}
            container>
            <TableContainer
              component={Paper}
              sx={{
                backgroundColor: "rgba(255, 255, 255, 0.5)",
                backdropFilter: "blur(5px)",
              }}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell
                      align="center"
                      sx={{ fontWeight: 600, fontSize: "1rem" }}>
                      Employee ID
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ fontWeight: 600, fontSize: "1rem" }}>
                      Employee Name
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ fontWeight: 600, fontSize: "1rem" }}>
                      Assign Access
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ fontWeight: 600, fontSize: "1rem" }}>
                      Add Employee
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.length &&
                    rows.map((row, index) => (
                      <TableRow
                        key={`${row.id}+${index}`}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}>
                        <TableCell align="center" component="th" scope="row">
                          {row.id}
                        </TableCell>
                        <TableCell align="center">{row.name}</TableCell>
                        <TableCell align="center">
                          <Select
                            sx={{ width: "5rem" }}
                            value={row?.access}
                            onChange={(event) =>
                              handleAccessChange(event, row)
                            }>
                            <MenuItem value="none">None</MenuItem>
                            <MenuItem value="edit">Edit</MenuItem>
                            <MenuItem value="view">View</MenuItem>
                          </Select>
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}>
                          <Button
                            onClick={() => addUserHandler(row.id)}
                            variant="contained"
                            sx={{
                              fontFamily: "Mont-Regular",
                              backgroundColor: "red",
                              marginBottom: "3vh",
                              "&:hover": {
                                color: "red",
                                backgroundColor: "white",
                              },
                              transitionDuration: "0.5s",
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              ml: 2,
                            }}>
                            <span style={{ marginRight: "0.5rem" }}>
                              Add User
                            </span>
                            <PersonAddAlt1Icon />
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          {/* <Grid sx={{ display: "flex", justifyContent: 'flex-end', alignItems: 'center', mt: 6 }}>
                        <Button onClick={addUserHandler} variant='contained' sx={{ backgroundColor: 'red', '&:hover': { color: 'red', backgroundColor: 'white' } }}>Add User's</Button>
                    </Grid> */}
        </Box>
      </Modal>

      <Modal
        open={functionModal}
        onClose={() => setFunctionModal(false)}
        sx={{
          border: "none",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}>
        <Box
          sx={{
            width: { lg: "80vw" },
            padding: "2vh 2vw 6vh 2vw",
            outline: "none",
            borderRadius: "6px",
            backgroundColor: "white",
            position: "relative",
          }}>
          <Grid sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              onClick={() => setFunctionModal(false)}
              sx={{ boxShadow: 2, color: "red", "&:hover": { boxShadow: 5 } }}>
              <CloseIcon />
            </Button>
          </Grid>
          <Grid
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mt: 2,
            }}>
            <Typography
              sx={{ fontFamily: "Mont-SemiBold", fontSize: "1.3rem" }}>
              Add Custom Functions
            </Typography>
            <Grid
              item
              xs={10.2}
              sx={{ backgroundColor: "red", height: "1vh" }}></Grid>
          </Grid>
          <Grid
            sx={{ mt: 4, display: "flex", justifyContent: "space-between" }}>
            <FormControl sx={{ m: 1, width: "100%" }}>
              <InputLabel id="demo-multiple-checkbox-label">
                Select Functions
              </InputLabel>
              {/* <Select
                                labelId="demo-multiple-checkbox-label"
                                id="demo-multiple-checkbox"
                                multiple
                                value={functionName}
                                onChange={handleChange}
                                input={<OutlinedInput label="Select Users" />}
                                renderValue={(selected) => {
                                    const selectedNames = selected.map((id) => {
                                        const functionRef = functionList.find((x) => x._id === id);
                                        return functionRef ? functionRef.name : '';
                                    });
                                    return selectedNames.join(', ');
                                }}
                                MenuProps={MenuProps}
                            >
                                {functionList.length ? functionList.map((func) => {
                                    <MenuItem key={func._id} value={func._id}>
                                        <Checkbox checked={functionName.indexOf(func) > -1} />
                                        <ListItemText primary={func.name} />
                                    </MenuItem>

                                }) : "No Functions Available !!!"
                                }
                            </Select> */}
              <Select
                value={selectedFunction}
                onChange={(e) => setSelectedFunction(e.target.value)}
                sx={{ width: "30rem", height: "3rem" }}>
                <MenuItem value="none">None</MenuItem>
                {functionList.length
                  ? functionList.map((x) => (
                      <MenuItem value={x._id}>{x.name}</MenuItem>
                    ))
                  : ""}
              </Select>
            </FormControl>
          </Grid>
          <Grid
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              mt: 6,
            }}>
            <Button
              onClick={addFunctionHandler}
              variant="contained"
              sx={{
                backgroundColor: "red",
                "&:hover": { color: "red", backgroundColor: "white" },
              }}>
              Add Functions
            </Button>
          </Grid>
        </Box>
      </Modal>
      <Modal
        open={eventModal}
        onClose={() => setEventModal(false)}
        sx={{
          border: "none",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}>
        <Box
          sx={{
            width: { lg: "80vw" },
            padding: "2vh 2vw 6vh 2vw",
            outline: "none",
            borderRadius: "6px",
            backgroundColor: "white",
            position: "relative",
          }}>
          <Grid sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              onClick={() => setEventModal(false)}
              sx={{ boxShadow: 2, color: "red", "&:hover": { boxShadow: 5 } }}>
              <CloseIcon />
            </Button>
          </Grid>
          <Grid
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mt: 2,
            }}>
            <Typography
              sx={{ fontFamily: "Mont-SemiBold", fontSize: "1.3rem" }}>
              Add Custom Events
            </Typography>
            <Grid
              item
              xs={10.2}
              sx={{ backgroundColor: "red", height: "1vh" }}></Grid>
          </Grid>
          <Grid
            sx={{ mt: 4, display: "flex", justifyContent: "space-between" }}>
            <FormControl sx={{ m: 1, width: "100%" }}>
              <InputLabel id="demo-multiple-checkbox-label">
                Select Events
              </InputLabel>
              <Select
                value={selectedEvent}
                onChange={(e) => setSelectedEvent(e.target.value)}
                sx={{ width: "30rem", height: "3rem" }}>
                <MenuItem value="none">None</MenuItem>
                {eventList.length
                  ? eventList.map((x) => (
                      <MenuItem value={x._id}>{x.name}</MenuItem>
                    ))
                  : ""}
              </Select>
            </FormControl>
          </Grid>
          <Grid
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              mt: 6,
            }}>
            <Button
              onClick={addEventHandler}
              variant="contained"
              sx={{
                backgroundColor: "red",
                "&:hover": { color: "red", backgroundColor: "white" },
              }}>
              Add Events
            </Button>
          </Grid>
        </Box>
      </Modal>

      <Modal
        open={deleteModal}
        onClose={() => setDeleteModal(false)}
        sx={{
          border: "none",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}>
        <Box
          sx={{
            width: { lg: "50vw" },
            padding: "2vh 2vw 6vh 2vw",
            outline: "none",
            borderRadius: "6px",
            backgroundColor: "white",
            position: "relative",
          }}>
          <Grid sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              onClick={() => setDeleteModal(false)}
              sx={{ boxShadow: 2, color: "red", "&:hover": { boxShadow: 5 } }}>
              <CloseIcon />
            </Button>
          </Grid>
          <Grid
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}>
            <Typography sx={{ fontSize: "1.3rem", fontWeight: 600 }}>
              Are You Sure You Want To Delete This Cron Job ?
            </Typography>
          </Grid>

          <Grid
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              mt: 6,
            }}>
            <Button
              onClick={() => setDeleteModal(false)}
              variant="contained"
              sx={{
                backgroundColor: "green",
                "&:hover": { color: "green", backgroundColor: "white" },
                width: "50%",
              }}>
              Cancel
            </Button>
            <Button
              onClick={() => deleteCronJobHandler()}
              variant="contained"
              sx={{
                backgroundColor: "red",
                "&:hover": { color: "red", backgroundColor: "white" },
                width: "50%",
                ml: 2,
              }}>
              Confirm
            </Button>
          </Grid>
        </Box>
      </Modal>

      <ToastContainer autoClose={2000} />
    </div>
  );
}

export default CronJobDetail;
