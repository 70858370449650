import { getMRP } from '../../analytics';

const mainBody = document.createElement('div');

// for bootstrap icons in social media
const cdn = document.createElement('link');
cdn.setAttribute('rel', 'stylesheet');
cdn.setAttribute('href', 'https://cdn.jsdelivr.net/npm/bootstrap-icons@1.9.1/font/bootstrap-icons.css');
mainBody.appendChild(cdn);


// const script = document.createElement('script');
// script.setAttribute('src', './index.js');
// // script.setAttribute('defer', true);
// mainBody.appendChild(script);


// const test = document.createElement('script')
// test.setAttribute('src', './test.js')

const DashHead = document.createElement('div');
DashHead.setAttribute('class', 'DashHead');
mainBody.appendChild(DashHead);



DashHead.innerHTML = `
<img src="/templates/images/redSlingLogo.png" />
<div>
  <p>Admin</p>
  <img src="/templates/images/Vector.png" />
</div>
`;



const dashHero = document.createElement('div');
dashHero.setAttribute('class', 'dashHero');
mainBody.appendChild(dashHero);

dashHero.innerHTML = `
<!-- left column (left sidebar) -->
<div class="heroLeft">
  <p>dashboard</p>
  <p>Orders</p>
  <p>Products</p>
</div>

<!-- Main Column (dashboard) -->
<div class="heroRight">
  <p class="heroRightTopText">Dashboard</p>

  <!-- main content -->
  <div class="dashMainContent">
    <!-- left column -->
    <div class="dashContentLeft">
      <div class="dashContentLeftHead">
        <button id="btn1" >Overview</button>
        <button>Analytics</button>
      </div>
      <p class="dashContentLeftText">
        Here’s an overview of what’s happening today
      </p>
      <div class="leftContentBlue">
        <div class="leftContentBlueHead">
          <p>TOTAL SALES</p>
          <p>
            <img src="/templates/images/AllSale.png" />
          </p>
        </div>
        <p class="leftContentBlueText">$4,330,606</p>
        <div class="leftContentBlueBottom">
          <p class="leftContentBottomLeft">
            <img src="/templates/images/upGreen.png" /> <span>124</span>
          </p>
          <div class="leftContentBottomMonth">
            <p>MONTHLY</p>
            <p><span>-</span>35343</p>
          </div>
          <div class="leftContentBottomMonth1">
            <p>WEEKLY</p>
            <p>+231</p>
          </div>
        </div>
      </div>

      <div class="leftContentPink">
        <div class="leftContentPinkHead">
          <p>Orders</p>
          <p class="pinkHead1">Ordered Items</p>
          <p class="pinkHead1">Returned Items</p>
          <p class="pinkHead1">Fulfilled Orders</p>
        </div>
        <div class="leftContentPinkText">
          <p>30 days</p>
          <img src="/templates/images/dropdownDash.png" />
        </div>
        <img class="pinkDivAbs" src="/templates/images/pinkDiv.png" />
      </div>

      <div class="leftContentStats">
        <p>Order Statics</p>
        <button>
          <img src="/templates/images/AllStats.png" />
        </button>
      </div>

      <div class="leftContentPurple">
        <div class="leftContentPurpleInner">
          <p>25</p>
          <p>Pending</p>
        </div>
        <div class="leftContentPurpleInner">
          <p>12</p>
          <p>Shipping</p>
        </div>
        <div class="leftContentPurpleInner">
          <p>23</p>
          <p>Completed</p>
        </div>
        <img
          src="/templates/images/purpleDiv.png"
          class="leftContentPurpleAbsolute"
        />
      </div>

      <p class="leftMainContentInvoice">Invoices</p>

      <div  class="leftMainContentInvoiceWrapper">
        

       
      <table class="dashBoardTable">
      <tr>
        <th class="dashBoardTh">
          <p>Invoice ID</p>
        </th>
        <th class="dashBoardTh">
          <p>Customer</p>
        </th>
        <th class="dashBoardTh">
          <p>Status</p>
        </th>
        <th class="dashBoardTh">
          <p>Due Date</p>
        </th>
        <th class="dashBoardTh">
          <p>Action</p>
        </th>
      </tr>
      <tr>
        <td class="dashboardTd">
          <p>INV-876543</p>
        </td>
        <td class="dashboardTd">
          <p>John Doe</p>
        </td>
        <td class="dashboardTd">
          <p class="contentP1">PAID</p>
        </td>
        <td class="dashboardTd">
          <p>24 November, 2022</p>
        </td>
        <td class="dashboardTd">
          <p><button>Details</button></p>
        </td>
      </tr>
      <tr>
        <td class="dashboardTd">
          <p>INV-876543</p>
        </td>
        <td class="dashboardTd">
          <p>John Doe</p>
        </td>
        <td class="dashboardTd">
          <p class="contentP1">PAID</p>
        </td>
        <td class="dashboardTd">
          <p>24 November, 2022</p>
        </td>
        <td class="dashboardTd">
          <p><button>Details</button></p>
        </td>
      </tr>
      <tr>
        <td class="dashboardTd">
          <p>INV-876543</p>
        </td>
        <td class="dashboardTd">
          <p>John Doe</p>
        </td>
        <td class="dashboardTd">
          <p class="contentP2">UNPAID</p>
        </td>
        <td class="dashboardTd">
          <p>24 November, 2022</p>
        </td>
        <td class="dashboardTd">
          <p><button>Details</button></p>
        </td>
      </tr>
      <tr>
        <td class="dashboardTd">
          <p>INV-876543</p>
        </td>
        <td class="dashboardTd">
          <p>John Doe</p>
        </td>
        <td class="dashboardTd">
          <p class="contentP1">PAID</p>
        </td>
        <td class="dashboardTd">
          <p>24 November, 2022</p>
        </td>
        <td class="dashboardTd">
          <p><button>Details</button></p>
        </td>
      </tr>
    </table>
      </div>
    </div>

    <!-- right column -->
    <div class="dashContentRight">
      <div class="dashContentRightHead">
        <p>Top 5 Products</p>
        <div>
          <p>October</p>
          <img src="/templates/images/dropdownDash.png" />
        </div>
      </div>
      <div class="dashContentRightImgWrapper">
        <div class="dashContentRightImg">
          <img src="/templates/images/unknownImg.png" />
          <p>Product 1</p>
        </div>
        <div class="dashContentRightImg">
          <img src="/templates/images/unknownImg.png" />
          <p>Product 1</p>
        </div>
        <div class="dashContentRightImg">
          <img src="/templates/images/unknownImg.png" />
          <p>Product 1</p>
        </div>
        <div class="dashContentRightImg">
          <img src="/templates/images/unknownImg.png" />
          <p>Product 1</p>
        </div>
        <div class="dashContentRightImg">
          <img src="/templates/images/unknownImg.png" />
          <p>Product 1</p>
        </div>
      </div>

      <div class="dashContentRightBottom">
        <p class="dashContentRightBottomHead">All Feedback</p>
        <p class="dashContentRightBottomHead1">CUSTOMER FEEDBACK</p>
        <div class="dashContentRightBottomSmiley">
          <img src="/templates/images/jam_smiley.png" />
          <p>95.5%</p>
        </div>
        <div class="dashContentRightBottomWrapper">
          <div class="dashContentRightBottomLeft">
            <div class="dashContentRightBottomLeft1">
              <p>Great product!</p>
              <img src="/templates/images/smileGreen.png" />
            </div>
            <div class="dashContentRightBottomLeft2">
              <p>Best solution</p>
              <p>
                <img src="/templates/images/Group 239.png" />
              </p>
            </div>
            <div class="dashContentRightBottomLeft3">
              <p>Can’t wait to</p>
            </div>
          </div>
          <div class="dashContentRightBottomRight">
            <div class="dashContentRightBottomRight1">
              <p>The product looks better in pictures</p>
              <img src="/templates/images/confused.png" />
            </div>
            <div class="dashContentRightBottomRight2">
              <p>Amazing product! Absolutely loved it!</p>
              <p>
                <img src="/templates/images/smileGreen.png" />
              </p>
            </div>
          </div>
        </div>
        <p class="dashBottomRect">
          <img src="/templates/images/dashboardRect1.png" />
        </p>
      </div>
    </div>
  </div>
  <button class="testBtn">TESTING</button>
  <h2>MRP: </h2>
</div>


<style>
@media (max-width: 768px) {
  .heroLeft {
    display: none;
  }

  .heroRight {
    overflow-x: hidden;
  }

  .dashMainContent {
    display: block;
  }

  .leftContentBlue {
    width: 90%;
    border-radius: 20px;
    padding: 3.5vh 4vw;
  }

  .leftContentBottomLeft {
    margin-right: 30vw;
    padding: 1vh 1vw;
  }

  .leftContentBottomMonth {
    margin-right: 4vw;
  }

  .leftContentBlueBottom {
    margin-top: 7vh;
  }

  .leftContentPink {
    width: 92vw;
    overflow: hidden;
  }

  .dashContentLeft {
    width: 92vw;
  }

  .pinkDivAbs {
    top: 24%;
  }

  .leftContentPinkHead {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.75rem;
    font-family: Mont-Regular;
    padding: 2.5vh 0.5vw 1vh 0.5vw;
    z-index: 10;
    position: relative;
    color: white;
  }

  .pinkHead1 {
    margin-left: 2.5vw;
  }

  .leftContentPinkText {
    padding-left: 2vw;
    margin-top: 8%;
  }

  .leftContentPinkText img {
    width: 3vw;
    height: 1vh;
    margin-left: 2vw;
  }

  .leftContentStats button {
    width: 24vw;
  }

  .leftContentPurple {
    overflow: hidden;
  }

  .leftMainContentInvoiceWrapper {
    padding-bottom: 4vh;
    margin-top: 4%;
  }

  .leftContentStats {
    width: 92vw;
  }

  .leftContentPurple {
    width: 88vw;
  }

  .dashBoardTable {
    width: 180vw;
  }

  .dashBoardTable p {
   
    width: 28vw;
  }

  .dashBoardTable button {
    width: 20vw;
  }

  .dashContentRight {
    width: 92vw;
    margin-top: 4%;
  }

  .dashContentRightHead div {
    width: 30%;
  }

  .dashContentRightBottomWrapper {
    display: block;
    margin-top: 8%;
    font-size: 1rem;
  }

  .dashContentRightBottomLeft {
    width: 100%;
    margin-right: 3%;
  }

  .dashContentRightBottomLeft1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    padding: 3vh 6vw 3vh 4vw;
    border-radius: 6px;
  }

  .dashContentRightBottomLeft2 {
    background-color: white;
    padding: 2vh 4vw 1vh 4vw;
    border-radius: 6px;
    margin: 2vh 0;
    height: 10vh;
  }

  .dashContentRightBottomLeft3 {
    padding: 3vh 4vw;
    margin-bottom: 5%;
  }

  .dashContentRightBottom {
    padding: 3vh 5vw;
    margin-bottom: 5%;
  }

  .dashContentRightBottomRight1 {
    padding: 3vh 4vw;
    border-radius: 6px;
    line-height: 150%;
  }

  .dashContentRightBottomRight2 {
    background-color: white;
    padding: 3vh 4vw 3vh 4vw;
    border-radius: 6px;
    margin: 2vh 0;
    height: 13vh;
    line-height: 180%;
  }

  .dashContentRightBottomRight2 p:last-child {
    display: flex;
    justify-content: end;
    margin-top: 8%;
  }
}
</style>

<script type="text/javascript">
  function test() {
    const mrp = ${getMRP(2562)};
    const mrpH2 = document.querySelector('.testBtn+h2');
    mrpH2.innerHTML += mrp;
    alert(mrp);
  }

  const testBtn = document.querySelector('.testBtn');
  testBtn.addEventListener('click', test);
</script>
`;

// if (typeof window !== 'undefined') {
// const btn = document.getElementsByClassName("btn1")
// btn.addEventListener('click', () => {
//   console.log("clicked")
// })
// }


// if (typeof window !== 'undefined') {
//   const btn = document.querySelector("button")
//   if (btn.classList.contains('btn1')) {
//     btn.addEventListener('click', () => {
//       console.log("clicked")
//     })
//   }
// }





// if (btn1 !== null || 'undefined') {
//   document.querySelector('#btn1').addEventListener('click', () => console.log("clicked"));
// }

const templateDashboardPage = mainBody.outerHTML;
export { templateDashboardPage };