const mainBody = document.createElement('div');

// for bootstrap icons in social media
const cdn = document.createElement('link');
cdn.setAttribute('rel', 'stylesheet');
cdn.setAttribute('href', 'https://cdn.jsdelivr.net/npm/bootstrap-icons@1.9.1/font/bootstrap-icons.css');
mainBody.appendChild(cdn);

const header = document.createElement('div');
header.setAttribute('class', 'header');
mainBody.appendChild(header);

header.innerHTML = `
	<div>
        <img src="/templates/images/redSlingLogo.png" />
      </div>
      <div class="header-right-section1">
        <p>Admin</p>
        <img src="/templates/images/Vector.png" />
    </div>
`;

const whiteWaveOverlay1 = document.createElement('div');
whiteWaveOverlay1.setAttribute('class', 'whiteWaveOverlay1');
mainBody.appendChild(whiteWaveOverlay1);

whiteWaveOverlay1.innerHTML = `
	<img src="/templates/images/Ellipse 6.png" />
    <img class="whiteWave2" src="/templates/images/white waves 2.png" />
`;

const mainDiv1 = document.createElement('div');
mainDiv1.setAttribute('class', 'mainDiv1');
mainBody.appendChild(mainDiv1);

mainDiv1.innerHTML = `
	<!-- dark bubble -->
	<img class="addProject-d-bb1" src="/templates/images/darkBubble.png" />

      <div class="mainDiv-head1">
        <p>Add New Product</p>
        <p>Save</p>
      </div>

      <div class="overlayDiv">
        <p class="overlayHead">Product Details</p>
        <div class="overlayInnerDiv">
          <p class="overlayInnerPara">
            <label for="productName">Product Name</label>
          </p>
          <input
            id="productName"
            type="text"
            class="input1"
            placeholder="Add Product Name"
          />
        </div>
        <div class="overlayInnerDiv">
          <p class="overlayInnerPara">
            <label for="ProductDesc">Product Description</label>
          </p>
          <input
            id="ProductDesc"
            type="text"
            placeholder="Add Product Description"
            class="input1"
          />
        </div>

        <div class="productGallery1">
          <div class="productGallery2">
            <img src="/templates/images/unknownImg.png" />
            <div>
              <p class="overlayInnerPara">
                <label for="ProductImg">Product Image</label>
              </p>
              <input
                class="input1"
                id="ProductImg"
                placeholder="Upload Product Image"
                type="text"
              />
            </div>
          </div>
          <div class="productGallery2 galleryWidth">
            <div class="wrapperGridImage">
              <img src="/templates/images/unknownImg.png" />
              <img src="/templates/images/unknownImg.png" />
              <img src="/templates/images/unknownImg.png" />

              <img src="/templates/images/unknownImg.png" />
              <img src="/templates/images/unknownImg.png" />
              <img src="/templates/images/unknownImg.png" />
            </div>
            <div class="galleryLastDiv">
              <p class="overlayInnerPara">
                <label for="productGallery">Product Gallery</label>
              </p>
              <input
                id="productGallery"
                type="text"
                placeholder="Upload Product Carousel images"
                class="input2"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="bottomDivWrapper">
        <div class="overlayInnerDiv1">
          <div>
            <p class="overlayInnerPara">
              <label for="RegularPrice">Regular Price</label>
            </p>
            <input
              class="input1"
              type="text"
              placeholder="Add Regular Price of the Product"
              id="RegularPrice"
            />
          </div>
          <div>
            <p class="overlayInnerPara">
              <label for="SalePrice">Sale Price</label>
            </p>
            <input
              class="input1"
              type="text"
              id="SalePrice"
              placeholder="Add Sale Price of the Product"
            />
          </div>
        </div>
        <div class="overlayInnerDiv1">
          <div>
            <p class="overlayInnerPara">
              <label for="Inventory">Inventory</label>
            </p>
            <input
              class="input1"
              type="text"
              placeholder="Add Inventory of the Product"
              id="Inventory"
            />
          </div>
          <div>
            <p class="overlayInnerPara">
              <label for="shipping">Shipping</label>
            </p>
            <input
              class="input1"
              type="text"
              id="shipping"
              placeholder="Add List of shipping pin codes/countries"
            />
          </div>
        </div>
        <div class="overlayInnerDiv1">
          <div>
            <p class="overlayInnerPara">
              <label for="BaseShipping">Base Shipping Price</label>
            </p>
            <input
              class="input1"
              type="text"
              placeholder="Add Base shipping Price of the Product"
              id="BaseShipping"
            />
          </div>
          <div>
            <p class="overlayInnerPara">
              <label for="CrossSell">Cross Sells</label>
            </p>
            <input
              class="input1"
              type="text"
              placeholder="Add Cross Sells of the Product"
              id="CrossSell"
            />
          </div>
        </div>
        <div class="overlayInnerDiv1">
          <div>
            <p class="overlayInnerPara">
              <label for="Category">Product Category</label>
            </p>
            <input
              class="input1"
              type="text"
              placeholder="Select Product Category"
              id="Category"
            />
          </div>
          <div>
            <p class="overlayInnerPara">
              <label for="productTag">Product Tags</label>
            </p>
            <input
              class="input1"
              type="text"
              placeholder="Add Product Tags"
              id="productTag"
            />
          </div>
        </div>
    </div>

    <style>
    	@media (max-width: 768px) {
    		.header {
	          display: flex;
	          justify-content: space-between;
	          align-items: center;
	          position: relative;
	          padding: 2vh 2.5vw;
	        }
	        .header-right-section1 {
	          display: flex;
	          justify-content: space-evenly;
	          align-items: center;
	          width: initial;
	          z-index: 10;
	        }
	        .header-right-section1 img {
	          width: 4vw;
	          height: 3vh;
	          margin-left: 5%;
	        }
	        .header-right-section1 p {
	          font-family: Mont-Regular;
	          font-size: 1rem;
	          margin-right: 5%;
	        }
	        .whiteWaveOverlay1 {
	          position: absolute;
	          top: 0;
	          right: 0;
	        }
	        .whiteWaveOverlay1 img:first-child {
	          width: 50vw;
	          position: absolute;
	          top: 0;
	          right: 0;
	        }
	        .whiteWave2 {
	          width: 90vw;
	          position: absolute;
	          top: 0;
	          right: 0;
	        }
	        .addProject-d-bb1 {
	          display: none;
	        }

	        .mainDiv-head1 {
  			    display: flex;
  			    justify-content: space-between;
  			    align-items: start;
  			    padding: 0vh 0vw;
  			    margin-top: 1.7%;
  			    width: 100vw;
  			  }

  			  .mainDiv-head1 p:first-child {
  			    font-family: Mont-SemiBold;
  			    font-style: normal;
  			    font-size: 1.4rem;
  			    padding-left: 5%;
  			  }

  			  .mainDiv-head1 p:last-child {
  			    font-family: Mont-Regular;
  			    font-size: 0.9rem;
  			    padding: 2% 4%;
  			    margin-right: 2%;
  			    background-color: red;
  			    color: white;
  			    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.35);
  			    border-radius: 27.5px;
  			  }

  			  .overlayDiv {
  			    margin-top: 3.8vh;
  			    background: white;
  			    z-index: 20;
  			    padding-left: 5%;
  			    padding-top: 0.7%;
  			    height: initial;
  			    padding-bottom: 3vh;
  			  }

  			  .overlayHead {
  			    font-family: Mont-SemiBold;
  			    font-size: 1.8rem;
  			  }

  			  .overlayInnerPara {
  			    margin-top: 5%;
  			  }

  			  .input1 {
  			    width: 80vw;
  			    height: 35px;
  			    border-radius: 6px;
  			    padding-left: 1vw;
  			    font-family: Mont-Regular;
  			  }

  			  .productGallery1 {
  			    display: block;
  			    margin-top: 5vh;
  			  }

  			  .productGallery2 {
  			    display: block;
  			  }

  			  .galleryWidth {
  			    margin-left: 0vw;
  			  }

  			  .wrapperGridImage {
  			    margin-top: 6%;
  			  }
  			  .wrapperGridImage img {
  			    width: 15vw;
  			  }

  			  .bottomDivWrapper {
  			    padding-left: 5vw;
  			    padding-right: 5vw;
  			    padding-bottom: 4vh;
  			  }

  			  .overlayInnerDiv1 {
  			    display: flex;
            flex-direction: column;
  			  }
    	}
    </style>
`;

const templateAddNewProductPage = mainBody.outerHTML;
export { templateAddNewProductPage };