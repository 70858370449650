import React from "react";
// import MatrixParallax from "react-matrix-parallax";

function ErrorScreen() {
  return (
    <React.Fragment>
      {/* <MatrixParallax color="#00AA00" backgroundColor="rgba(0,0,0,1)">
        <div style={{ fontSize: "1.7rem" }}>
          <h2>Something Went Wrong !!!</h2>
          <h6>Please Refer The Page and Try Again !!!</h6>
        </div>
      </MatrixParallax> */}
    </React.Fragment>
  );
}

export default ErrorScreen;
