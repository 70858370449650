import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";

function CalendyScreen() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const code = searchParams.get("code");
  localStorage.setItem("authorizationCode", code);

  let codeS = localStorage.getItem("authorizationCode");
  if (typeof codeS !== "undefined") {
    window.close();
  }
 

  return (
    <div>
      <h2>Parameter from URL:</h2>
      <p>{code}</p>
    </div>
  );
}
export default CalendyScreen;
