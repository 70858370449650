import { API_HOST } from "../api_utils/index";

const GeolocationPlugin = (editor, options) => {
  editor.Blocks.add("geolocation_custom", {
    label: "Geolocation",
    category: "Form-Control",
    select: true,
    media: `<svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="currentColor" viewBox="0 0 16 16"><path d="M8.941.435a2 2 0 0 0-1.882 0l-6 3.2A2 2 0 0 0 0 5.4v.314l6.709 3.932L8 8.928l1.291.718L16 5.714V5.4a2 2 0 0 0-1.059-1.765zM16 6.873l-5.693 3.337L16 13.372v-6.5Zm-.059 7.611L8 10.072.059 14.484A2 2 0 0 0 2 16h12a2 2 0 0 0 1.941-1.516M0 13.373l5.693-3.163L0 6.873z"/></svg>`,
    content: { type: "geolocation_custom" },
  });

  if (editor !== null && editor !== undefined) {
    const addGeoLocation = function (props) {
      console.log("$props", props, this);

      let idEl = this.id;
      const url = window.top.location.href;
      const isSubstringPresent =
        (url.indexOf("/editor/") !== -1 && url.indexOf("?projectId=") !== -1) ||
        url.indexOf("/large_preview/") !== -1 ||
        url.indexOf("/tab_preview/") !== -1 ||
        url.indexOf("/mobile_preview/") !== -1 ||
        url.indexOf("/fragment_editor/") !== -1;
      if (!isSubstringPresent) {
        setInterval(function () {
          Object.keys(props).forEach(function (key) {
            if (window[`${key}${idEl}`]) {
              props[key] = window[`${key}${idEl}`];
            }
          });
        }, 500);
      }

      this.setAttribute("geolocation_id", `${this.id}`);
      let locationTrackerObj = {
        geolocation_container_id: this.id,
        lat: 108,
        long: 100,
      };

      localStorage.setItem(
        `geolocation_custom_${this.id}`,
        JSON.stringify(locationTrackerObj)
      );
    };

    editor.DomComponents.addType("geolocation_custom", {
      model: {
        defaults: {
          tagName: "div",
          attributes: {
            class: "geolocation_custom_container",
          },
          script: addGeoLocation,

          components: [
            {
              tagName: "div",
              attributes: { class: "geolocation_custom_pointer" },
            },
          ],

          // traits
          API_HOST: "abc",

          changeProp: true,
          "script-props": ["API_HOST"],
        },
      },

      view: {
        onRender({ el, model }) {
          console.log("onRender el, model", el, model);

          // to avoid showing in preview
          if (!window.top.location.href.includes("large_preview/")) {
            if (el.className === "geolocation_custom_container") {
              el.setAttribute("geolocation_id", `${el.id}`);
              let locationTrackerObj = {
                geolocation_container_id: el.id,
                lat: 108,
                long: 100,
              };

              localStorage.setItem(
                `geolocation_custom_${el.id}`,
                JSON.stringify(locationTrackerObj)
              );
            }
          }
        },
      },
    });
  }
};

export default GeolocationPlugin;
